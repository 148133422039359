import ExcelJS from 'exceljs';
import moment from 'moment-timezone';
import FileSaver from 'file-saver';
import {
  CustomBodyRenderCurrency,
  CustomBodyRenderDate,
} from '../../../components/Table/CustomBodyRender';
import { formatCurrency, formatPbDate } from '../../../lib/fmt';

const BalanceSummaryExcelDownload = async (data, cols) => {
  // Create xlsx file
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Balance Summary');
  worksheet.properties.defaultColWidth = 20;

  // headers
  for (let columnCounter = 0; columnCounter < cols.length; columnCounter++) {
    const col = cols[columnCounter];
    if (col.hidden) continue;

    const csvColumn = worksheet.getColumn(columnCounter + 1); // Get column in csv file
    csvColumn.header = col.label; // Set label value to column header
  }

  // rows
  let rowCounterx = 2;

  for (let mainIndex = 0; mainIndex < data.length; mainIndex++) {
    const mainRow = worksheet.getRow(rowCounterx); // Get row in csv file

    // main row values
    for (let columnCounter = 0; columnCounter < cols.length; columnCounter++) {
      const col = cols[columnCounter];
      if (col.hidden) continue;

      const rawValue = data[mainIndex].data[columnCounter];
      const customBodyRender = col.customBodyRender;

      mainRow.getCell(columnCounter + 1).value = getFormattedColumnValue(
        rawValue,
        customBodyRender
      );

      mainRow.getCell(columnCounter + 1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'b6c1ff' },
      };
    }

    // sub data is expected to be on the last index
    const subData = data[mainIndex].data[cols.length - 1];

    // Check if main record has a sub record
    if (subData.length) {
      for (let subIndex = 0; subIndex < subData.length; subIndex++) {
        // Set subrows value to row cells
        rowCounterx++;
        const subRow = worksheet.getRow(rowCounterx);
        subRow.outlineLevel = 1;

        for (let subHeaderindex = 2; subHeaderindex <= 8; subHeaderindex++) {
          subRow.getCell(subHeaderindex).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'dae0ff' },
          };
        }

        subRow.getCell(2).value = subData[subIndex].correspondent;
        subRow.getCell(3).value = subData[subIndex].accountNo;
        subRow.getCell(4).value = subData[subIndex].accountName;
        subRow.getCell(5).value = formatCurrency(subData[subIndex].cashBalance);
        subRow.getCell(6).value = formatCurrency(subData[subIndex].shortMarketValue);
        subRow.getCell(7).value = formatCurrency(subData[subIndex].longMarketValue);
        subRow.getCell(8).value = formatCurrency(subData[subIndex].equity);

        // Check if the sub record has a sub account balance list (second sub report)
        const subSubData = subData[subIndex].subAccountBalancesList;

        if (subSubData.length) {
          for (let subSubIndex = 0; subSubIndex < subSubData.length; subSubIndex++) {
            rowCounterx++;
            const subSubRow = worksheet.getRow(rowCounterx);
            // Set sub account row group to 2
            subSubRow.outlineLevel = 2;
            // Set sub account row color
            for (let rc2 = 3; rc2 <= 8; rc2++) {
              subSubRow.getCell(rc2).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'f2f4ff' },
              };
            }
            // Set subrows value to row cells
            subSubRow.getCell(4).value = subSubData[subSubIndex].subAccountNo;
            subSubRow.getCell(5).value = formatCurrency(subData[subIndex].cashBalance);
            subSubRow.getCell(6).value = formatCurrency(
              subData[subIndex].shortMarketValue
            );
            subSubRow.getCell(7).value = formatCurrency(
              subData[subIndex].longMarketValue
            );
            subSubRow.getCell(8).value = formatCurrency(subData[subIndex].equity);
          }
        }
      }
    }
  }

  // save csv file
  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    FileSaver.saveAs(blob, 'BalanceSummary_' + moment().format('MMMM Do YYYY') + '.xlsx');
  });
};

const getFormattedColumnValue = (rawValue, customBodyRender) => {
  switch (customBodyRender) {
    case CustomBodyRenderCurrency:
      return formatCurrency(rawValue);
    case CustomBodyRenderDate:
      return formatPbDate(rawValue);
    default:
      return rawValue;
  }
};

export default BalanceSummaryExcelDownload;
