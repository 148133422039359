import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Text from '../Typography/Text';
import Slider from '@material-ui/core/Slider';
import color from '../../assets/css/colors';

const useStyles = makeStyles(() => ({
  sliderLabel: {
        fontWeight: 600,
        color: color.default.main,
        lineHeight: '17px',
        display: 'flex',
        width: '100%',
        marginBottom: 10,
        '& h6': {
            fontWeight: 600,
            color: color.default.main,
            lineHeight: '17px',
        },
        '& .grd-cell-none > h6': {
            fontSize: 13,
            fontStyle: 'italic',
            lineHeight: '19px',
        }
    },
}));

const PrettoSlider = withStyles({
    root: {
      color: color.primary.main,
      height: 8,
    },
    thumb: {
      height: 20,
      width: 20,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 0px)',
    },
    track: {
      height: 4,
      borderRadius: 10,
    },
    rail: {
      height: 4,
      borderRadius: 10,
    },
})(Slider);

const marks = [
    {
        value: 0,
        label: '0%',
    },
    {
        value: 25,
        label: '25%',
    },
    {
        value: 50,
        label: '50%',
    },
    {
        value: 75,
        label: '75%',
    },
    {
        value: 100,
        label: '100%',
    },
];

function valuetext(value) {
  return `${value}%`;
}

export default function RangeSlider(props) {
  const classes = useStyles();

  return (
    <div>
        <div className={classes.sliderLabel}>
            <div className="grd-cell">
                <Text
                    mb={10}
                    required={props.required}
                    error={props.error}
                    variant="subtitle1"
                    label={props.label}
                />
            </div>
            {props.error && (
                <div className="grd-cell-none">
                    <Text
                        error={props.error}
                        variant="subtitle1"
                        label={props.msg}
                    />
                </div>
            )}
        </div>
        <div style={{padding: '0 10px'}}>
            <PrettoSlider 
                valueLabelDisplay="auto" 
                value={props.value}
                getAriaValueText={valuetext}
                onChange={(e, newValue) => props.onChange({currentTarget: {name: props.name, value: newValue}})}
                step={props.step}
                marks={marks}
            />
        </div>
    </div>
  );
}