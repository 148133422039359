import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import Button from '../Buttons/Button';
import Text from '../Typography/Text';
import color from '../../assets/css/colors';
import fontFamily from '../../assets/css/fonts';
import html2pdf from 'html2pdf.js';
import { readDisclosure } from '../../services/DisclosureService';
import { downloadBlankReport } from '../../services/MarginDisclosureService';
import { getCorrespondentByUrl } from '../../services/TemplateService';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px',
    maxHeight: '80vh',
    overflowY: 'auto',
    '& p': {
      fontSize: 16,
      fontFamily: fontFamily.headers,
      fontWeight: 400,
      lineHeight: '26px',
      color: color.default.main,
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: 25,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'max-content',
    },
  },
  wrapper: {
    margin: '0 auto',
    fontFamily: fontFamily.headers,
    fontSize: 15,
    '& h1': {
      color: color.default.main,
      marginTop: '0px !important',
    },
    '& h3': {
      color: color.default.main,
    },
    '& p': {
      color: color.default.main,
      lineHeight: '27px',
    },
    '& .MuiFormControl-root label': {
      fontFamily: fontFamily.headers,
      fontWeight: 400,
    },
    '& .MuiInputBase-root': {
      border: 'none',
      backgroundColor: 'unset',
      padding: 0,
      marginTop: '24px !important',
      fontFamily: fontFamily.headers,
      fontSize: 16,
      fontWeight: 700,
      '& input': {
        padding: 0,
      },
    },
  },
  maTable: {
    width: '100%',
    border: '1px solid #333333',
    borderCollapse: 'collapse',
    marginBottom: 30,
    color: color.default.main,
    '& tr td': {
      border: '1px solid #333333',
      padding: 10,
      '& label': {
        display: 'block',
      },
      '& label:first-child': {
        marginBottom: 7,
      },
      '& label:last-child': {
        fontWeight: 700,
        fontSize: 16,
      },
    },
  },
  buttons: {
    display: 'flex',
    marginTop: 20,
    width: 'max-content',
    float: 'right',
  },
  customerAccountAgreement: {
    '& p': {
      fontSize: 16,
      fontFamily: fontFamily.headers,
      fontWeight: 400,
      lineHeight: '26px',
      color: color.default.main,
    },
  },
}));

export default function MarginAgreementModal(props) {
  const classes = useStyles();

  const {
    open,
    onClose,
    customerAccountAgreementData,
    setAttachments,
    setCustomerAccountAgreementData,
    modalTitle,
  } = props;

  const [mounted, setMounted] = React.useState(false);
  const [disclosure, setDisclosure] = React.useState('');
  const [loading, setLoading] = React.useState({
    disclosure: false,
    accept: false,
    download: false,
  });
  const modalData = {
    correspondent: 'SASS',
    reportType: 'Customer Account Agreement',
  };

  React.useEffect(() => {
    if (!mounted) {
      async function init() {
        try {
          setLoading({ ...loading, disclosure: true });
          let url = window.location.origin;
          //get correspondent
          const serverCorrespondent = await getCorrespondentByUrl(url);
          let correspondent = serverCorrespondent.correspondent.correspondent;

          const data = await readDisclosure('Customer Account Agreement', correspondent);

          setDisclosure(data.disclosure.disclosure);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading({ ...loading, disclosure: false });
        }
      }

      init();
    }
    return () => {
      setMounted(true);
    };
    // eslint-disable-next-line
  }, [mounted]);

  const acceptCustomerAccountAgreement = async () => {
    const htmlElement = document.getElementById('customerAccountAgreementForm');
    const options = {
      margin: [45, 45],
      filename: 'Customer Account Agreement.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 1, letterRendering: true },
      jsPDF: { unit: 'pt', format: 'letter', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
    };

    try {
      setLoading({ ...loading, accept: true });

      await html2pdf()
        .set(options)
        .from(htmlElement)
        .outputPdf('blob')
        .then((result) => {
          const file = new File([result], 'Customer Account Agreement.pdf', {
            type: result.type,
          });

          let newFiles = [file];

          setAttachments(newFiles);
        });

      setCustomerAccountAgreementData({
        ...customerAccountAgreementData,
        acceptCustomerAgreement: true,
      });

      onClose('agreement accepted');
    } catch (error) {
      console.log(error?.message);
    } finally {
      setLoading({ ...loading, accept: false });
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div style={{ width: 800 }}>
          <div className={classes.modalHeader}>
            <div>
              <Text mt={3} variant="h2" label={modalTitle} />
            </div>
          </div>
          <div className={classes.modalBody}>
            {disclosure !== '' ? (
              <div
                id="customerAccountAgreementForm"
                className={classes.customerAccountAgreement}
                dangerouslySetInnerHTML={{ __html: disclosure }}
              ></div>
            ) : (
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  padding: '30px 0px',
                }}
              >
                <p>
                  {loading.disclosure
                    ? 'Fetching customer account agreement. Please wait...'
                    : 'No Customer Account Agreement Found.'}
                </p>
              </div>
            )}
          </div>
          <div className={classes.modalFooter}>
            <div style={{ marginRight: 10 }}>
              <Button
                style={{ marginRight: 10 }}
                loading={loading.download}
                color="secondary"
                label={loading.download ? 'Downloading...' : 'Print PDF'}
                onClick={async () => {
                  setLoading({ download: true });
                  await downloadBlankReport({ ...modalData }, disclosure);
                  setLoading({ download: false });
                }}
              />
            </div>
            <div className={classes.buttons}>
              <div style={{ marginRight: 10 }}>
                <Button
                  label={'Close'}
                  onClick={onClose}
                />
              </div>
            </div>
            {customerAccountAgreementData.acceptCustomerAgreement === false && disclosure.trim() !== '' ? (
              <div className={classes.buttons}>
                <div style={{ marginRight: 10 }}>
                  <Button
                    disabled={loading.accept || loading.disclosure || props.disabled}
                    loading={loading.accept}
                    label={loading.accept ? 'Accepting...' : 'I Accept'}
                    onClick={acceptCustomerAccountAgreement}
                  />
                </div>
              </div>
            ) : (
              <div className={classes.buttons}>
                <div style={{ marginRight: 10 }}>
                  <Button
                    disabled
                    label={customerAccountAgreementData.acceptCustomerAgreement === true ? 'Accepted' : disclosure.trim() === '' ? 'I Accept' : ''}
                    onClick={acceptCustomerAccountAgreement}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
