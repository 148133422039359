// src/components/FinancialIcons.js
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ClipboardCheckIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path d="M11.2501 16.4357L16.2804 11.4054L15.2197 10.3447L11.2501 14.3144L9.53039 12.5947L8.46973 13.6554L11.2501 16.4357Z" fill="#4A5560" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 2.625H9.87803C10.1869 1.75111 11.0203 1.125 12 1.125C12.9797 1.125 13.8131 1.75111 14.122 2.625H18C18.8284 2.625 19.5 3.29657 19.5 4.125V21.375C19.5 22.2034 18.8284 22.875 18 22.875H6C5.17157 22.875 4.5 22.2034 4.5 21.375V4.125C4.5 3.29657 5.17157 2.625 6 2.625ZM12 4.125C12.4142 4.125 12.75 3.78921 12.75 3.375C12.75 2.96079 12.4142 2.625 12 2.625C11.5858 2.625 11.25 2.96079 11.25 3.375C11.25 3.78921 11.5858 4.125 12 4.125ZM18 4.125H15.75C15.75 4.95343 15.0784 5.625 14.25 5.625H9.75C8.92157 5.625 8.25 4.95343 8.25 4.125H6V21.375H18V4.125Z" fill="#4A5560" />
    </SvgIcon>
);

const FileIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path d="M12.8787 1.5C13.2765 1.5 13.658 1.65804 13.9393 1.93934L19.0607 7.06066C19.342 7.34196 19.5 7.7235 19.5 8.12132V21C19.5 21.8284 18.8284 22.5 18 22.5H6C5.17157 22.5 4.5 21.8284 4.5 21V3C4.5 2.17157 5.17157 1.5 6 1.5H12.8787ZM12 3H6V21H18V9H14.25C13.0805 9 12.1193 8.10767 12.0103 6.96669L12 6.75V3ZM16.5 16.5V18H7.5V16.5H16.5ZM16.5 13.5V15H7.5V13.5H16.5ZM16.5 10.5V12H7.5V10.5H16.5ZM13.5 3.6225V6.75C13.5 7.11819 13.7653 7.42441 14.1152 7.48792L14.25 7.5H17.379L13.5 3.6225Z" fill="#4A5560"/>
</SvgIcon>
);

const DiskIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path d="M15.8787 3C16.2765 3 16.658 3.15804 16.9393 3.43934L20.5607 7.06066C20.842 7.34196 21 7.7235 21 8.12132V19.5C21 20.3284 20.3284 21 19.5 21H4.5C3.67157 21 3 20.3284 3 19.5V4.5C3 3.67157 3.67157 3 4.5 3H15.8787ZM7.5 4.5H4.5V19.5H7.5V13.5C7.5 12.6716 8.17157 12 9 12H15C15.8284 12 16.5 12.6716 16.5 13.5V19.5H19.5V8.12132L16.5 5.1225V7.5C16.5 8.32843 15.8284 9 15 9H9C8.17157 9 7.5 8.32843 7.5 7.5V4.5ZM15 13.5H9V19.5H15V13.5ZM9 4.5V7.5H15V4.5H9Z" fill="#4A5560"/>
</SvgIcon>
);




export {
    ClipboardCheckIcon,
    FileIcon,
    DiskIcon
};
