import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Paper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import color from '../../assets/css/colors';
import fontFamily from '../../assets/css/fonts';
import { getPositions } from '../../services/OrderEntryService';

const useStyles = makeStyles(() => ({
  autocompleteWrapper: {
    '& .MuiAutocomplete-inputRoot': {
      padding: '7px 15px 8px 15px !important',
      height: 48,
    },
    '& .MuiAutocomplete-option': {
      backgroundColor: 'red',
    },
    '& .MuiAutocomplete-endAdornment': {
      top: 'calc(50% - 15px)',
      '& svg': {
        width: '22px !important',
        height: '22px !important',
      },
    },
  },
  paper: {
    fontFamily: fontFamily.label,
    fontSize: 15,
    fontWeight: 600,
    backgroundColor: color.light.main,
  },
}));

export default function AutoCompletePosition(props) {
  const classes = useStyles();
  const { assetType, accountNo, action } = props;
  const [options, setOptions] = React.useState([]);

  React.useEffect(async () => {
    if (
      [
        'Sell',
        'Sell All',
        'ICE',
        'Sell %',
        'Sell $',
        'Sell Share',
        'Buy Close',
        'Sell Open',
        'Sell Close',
      ].includes(action)
    ) {
      const res = await getPositions(accountNo, assetType);
      setOptions(res.positionsList);
    }
  }, [accountNo, action, assetType]);

  return (
    <Autocomplete
      style={{
        marginTop: props.mt ? props.mt : 0,
        marginBottom: props.mb ? props.mb : 0,
      }}
      className={classes.autocompleteWrapper}
      disabled={props.disabled}
      freeSolo={props.freeSolo}
      id={props.name}
      blurOnSelect={false}
      getOptionSelected={(option, value) => option.symbol === value.symbol}
      getOptionLabel={(option) => {
        if (option.symbolDescription) {
          return option.symbol + ' - ' + option.symbolDescription;
        }

        return option.symbol || '';
      }}
      options={options}
      name={props.name}
      autoHighlight={true}
      clearOnEscape={true}
      value={options.find((o) => o.symbol === props.value) || {}}
      onChange={(_, obj) =>
        props.onChange({
          currentTarget: { name: props.name, value: obj ? obj.symbol : '' },
        })
      }
      PaperComponent={({ children }) => (
        <Paper className={classes.paper}>{children}</Paper>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          error={props.error}
          helperText={props.error ? props.msg : ''}
          name={props.name}
          required={props.required}
          disabled={props.disabled}
          label={props.label}
          placeholder={props.placeholder}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
}
