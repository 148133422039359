import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';

import QueryParam from '../../services/QueryParamService';
import { getSystemDate } from '../../services/ProfileService';
import { listCharge, processCharge } from '../../services/StockBorrowChargeService';

import ClientGrid from '../../components/ClientGrid/ClientGrid';
import ClientGrid2 from '../../components/ClientGrid/ClientGrid2'
import Table, { columnType } from '../../components/ClearingTable/Table';
import AutoCompleteCorrespondent from '../../components/AutoComplete/AutoCompleteCorrespondent';
import AutoCompleteAccountNo from '../../components/AutoComplete/AutoCompleteAccountNo';
import AutoCompleteSymbol from '../../components/AutoComplete/AutoCompleteSymbol';
import SelectCusip from '../../components/AutoComplete/SelectCusip';
import Button from '../../components/Buttons/Button';
import Text from '../../components/Typography/Text';
import { SetCellPropsWidth } from '../../components/Table/SetCellProps';
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from '../../components/Messages/Notification';
import {
  pbDate,
  currency,
  qty,
  percentage,
  pbDateSorter,
} from '../../components/ClientGrid/CellRenderer';
import {
  CustomBodyRenderCurrency,
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderDate,
  CustomBodyRenderDateTime,
  CustomBodyRenderBoolean,
  CustomBodyRenderAlign,
  CustomBodyRenderHtmlString,
  CustomBodyRenderCenter,
  CustomBodyRenderQty,
  CustomBodyRenderPercentage,
} from '../../components/Table/CustomBodyRender';
import { formatPbDate, formatPbDateTime } from '../../lib/fmt';

export default function StockBorrowCharge() {
  // const columns = [
  //   {
  //     id: 'stockBorrowId',
  //     field: 'stockBorrowId',
  //     label: 'Stock Borrow ID',
  //     visible: false,
  //   },
  //   {
  //     id: 'firstBorrowDate',
  //     field: 'firstBorrowDate',
  //     label: 'First Borrow Date',
  //     cellRenderer: pbDate,
  //     sort: pbDateSorter,
  //   },
  //   {
  //     id: 'tradeDate',
  //     field: 'tradeDate',
  //     label: 'Trade Date',
  //     cellRenderer: pbDate,
  //     sort: pbDateSorter,
  //   },
  //   {
  //     id: 'accountId',
  //     field: 'accountId',
  //     label: 'Account ID',
  //   },
  //   {
  //     id: 'correspondent',
  //     field: 'correspondent',
  //     label: 'Correspondent',
  //   },
  //   {
  //     id: 'accountNo',
  //     field: 'accountNo',
  //     label: 'Account No',
  //   },
  //   {
  //     id: 'originalCusip',
  //     field: 'originalCusip',
  //     label: 'Original Cusip',
  //   },
  //   {
  //     id: 'symbol',
  //     field: 'symbol',
  //     label: 'Symbol',
  //   },
  //   {
  //     id: 'symbolDescription',
  //     field: 'symbolDescription',
  //     label: 'Symbol Description',
  //   },
  //   {
  //     id: 'cusip',
  //     field: 'cusip',
  //     label: 'Cusip',
  //   },
  //   {
  //     id: 'qty',
  //     field: 'qty',
  //     label: 'Qty',
  //     cellRenderer: qty,
  //   },
  //   {
  //     id: 'rate',
  //     field: 'rate',
  //     label: 'Rate',
  //     cellRenderer: percentage,
  //   },
  //   {
  //     id: 'fee',
  //     field: 'fee',
  //     label: 'Fee',
  //     cellRenderer: currency,
  //     addFooter: true,
  //     options: {
  //       setCellProps: SetCellPropsWidth(100),
  //       customBodyRender: CustomBodyRenderCurrency,
  //     },
  //   },
  //   {
  //     id: 'marketValue',
  //     field: 'marketValue',
  //     label: 'Market Value',
  //     cellRenderer: currency,
  //   },
  //   {
  //     id: 'age',
  //     field: 'age',
  //     label: 'Age',
  //   },
  // ];

  const columns = [
    {
      name: 'stockBorrowId',
      label: 'Stock Borrow ID',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      }
    },
    {
      // Already converted to string in handlesearch
      name: 'firstBorrowDate',
      label: 'First Borrow Date',
    },
    {
      // Already converted to string in handlesearch
      name: 'tradeDate',
      label: 'Trade Date',
    },
    {
      name: 'accountId',
      label: 'Account ID',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'accountNo',
      label: 'Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'originalCusip',
      label: 'Original Cusip',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'symbol',
      label: 'Symbol',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'symbolDescription',
      label: 'Symbol Description',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'cusip',
      label: 'Cusip',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'qty',
      label: 'Qty',
      options: {
        customBodyRender: CustomBodyRenderQty,
      },
      addFooter: true,
      type: 'qty',
    },
    {
      name: 'rate',
      label: 'Rate',
      options: {
        customBodyRender: CustomBodyRenderPercentage,
      },
      addFooter: true,
      type: 'percentage'
    },
    {
      name: 'fee',
      label: 'Fee',
      options: {
        customBodyRender: CustomBodyRenderCurrency,
      },
    },
    {
      name: 'marketValue',
      label: 'Market Value',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'age',
      label: 'Age',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'dateType',
      label: 'Date Type',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      }
    },
  ];

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
  };

  const [loading, setLoading] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);
  const [rows, setRows] = useState([]);
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      firstBorrowDate: '',
      fromTradeDate: '',
      toTradeDate: '',
      dateType: '',
      tradeDate: '',
      correspondent: '',
      accountNo: '',
      symbol: '',
      cusip: '',
    })
  );

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setSearchData({
      ...searchData,
      [input.name]: input.value,
    });
  };

  const handleSearch = async () => {
    try {
      let valid = true;
      if (!searchData.fromTradeDate) {
        notifyError('From Date is required.');
        valid = false;
      }

      if (!valid) {
        return;
      }
      setLoading(true);
      QueryParam.set(searchData);
      const data = await listCharge(searchData);
      
      let rowsCopy = data.chargesList;

      const rowsCopyOutput = rowsCopy.map((data) => {
        let tempDate = formatPbDate(data.firstBorrowDate);
        data.firstBorrowDate = tempDate

        let tempDate2 = formatPbDate(data.tradeDate);
        data.tradeDate = tempDate2

        return data;
      })

      setRows(rowsCopyOutput);
      notifyInfo(data.chargesList.length + ' search results.');
    } catch (error) {
      console.error(error);
      notifyError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleProcess = async () => {
    try {
      setProcessing(true);
      const report = await processCharge(searchData);
      handleSearch();
      notifySuccess(report.success);
    } catch (error) {
      notifyError(error.message);
    } finally {
      setProcessing(false);
    }
  };

  const getCsvData = () => {
    return rows;
  };

  useEffect(() => {
    async function initSystemDate() {
      let systemDate = await getSystemDate();
      setSearchData({
        ...searchData,
        fromTradeDate: systemDate,
        toTradeDate: systemDate,
      });
    }

    initSystemDate();
  }, []);

  /*=========================================================================================
  Table component
  ===========================================================================================*/
  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Stock Borrow Charge" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading}
              loading={loading}
              type="search"
              label={loading ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
          <div className="grd-cell-none">
            <Button
              label="Process"
              onClick={() => {
                handleProcess();
              }}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <TextField
              name="fromTradeDate"
              fullWidth
              label="From Trade Date"
              type="date"
              value={searchData.fromTradeDate}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className="grd-cell">
            <TextField
              name="toTradeDate"
              fullWidth
              label="To Trade Date"
              type="date"
              value={searchData.toTradeDate}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteCorrespondent
              isAccessibleOnly={true}
              name="correspondent"
              value={searchData.correspondent}
              onChange={handleChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteAccountNo
              isAccessibleOnly={true}
              name="accountNo"
              value={searchData.accountNo}
              correspondent={searchData.correspondent}
              onChange={handleChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteSymbol
              name="symbol"
              value={searchData.symbol}
              onChange={handleChange}
            />
          </div>
          <div className="grd-cell">
            <SelectCusip
              name="cusip"
              label="Cusip"
              value={searchData.cusip}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="mt-20">
        {/* <ClientGrid
          title="Stock Borrow Charge"
          rowIdField="stockBorrowId"
          columns={columns}
          rows={rows}
          getCsvData={getCsvData}
          isLoading={loading}
          setRows={setRows}
        /> */}
        {/* <ClientGrid2
          title="Stock Borrow Charge"
          data={rows}
          columns={columns}
          options={options}
        /> */}
        <Table
          title={'Stock Borrow Charge'}
          data={rows}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
}
