import React from 'react';
import ClientGrid from '../ClientGrid/ClientGrid';
import FileManagerComp from '../FileManager/FileManagerComp';
import Button from '../Buttons/Button';
import Text from '../Typography/Text';
import SelectMonth from "../Dropdown/SelectMonth";
import TextField from "../Textfields/TextField";
import { fileColumns } from '../../lib/data/columns';
import QueryParam from '../../services/QueryParamService';
import { notifyInfo, notifyError } from '../../components/Messages/Notification';
import { listFile } from "../../services/FileService";

export default function FileBrowser({
  title: pageTitle,
  type: fileType
}) {
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      type: fileType,
      keyword: '',
      month: '',
      year: '',
    })
  );

  const init = async () => {
    const searchParameters = localStorage.getItem('search_data');
    
    const {
      keyword, 
      month, 
      year,
    } = searchParameters ? JSON.parse(searchParameters) : searchData;
    
    setSearchData({
      ...searchData, 
      type: fileType,
      keyword: keyword ? keyword : '',
      month: month ? month : '', 
      year: year ? year : '',
    });
  }

  React.useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  const handleSearchDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setSearchData({...searchData, [input.name]: input.value });
  };

  const handleSearch = async () => {
    try {
      setLoading(true);

      localStorage.setItem('search-data', JSON.stringify(searchData));

      const data = await listFile(searchData);

      setRows(data.filesList);
      
      notifyInfo(data.filesList.length > 0 ? data.length + ' search result(s)' : 'No records found.');
    }
    catch (error) {
      setLoading(false);
      notifyError(error.message);
      console.log(error);
      return;
    } 
    finally {
      setLoading(false);
    }
  }

  const getCsvData = async () => {
    const data = await listFile(searchData);

    return data.filesList;
  }

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text
              mt={8}
              variant="h1"
              label={pageTitle}
            />
          </div>
        </div>
      </div>
      <div className="mt-20">
        <FileManagerComp />
      </div>
    </div>
  );
}