import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Text from '../../../../components/Typography/Text';
import Button from '../../../../components/Buttons/Button';
import CloseButton from '../../../../components/Buttons/CloseButton';
import fontFamily from '../../../../assets/css/fonts';
import color from '../../../../assets/css/colors';
import { notifyWarning } from '../../../../components/Messages/Notification';
import lodash from 'lodash';
import OrderEntryModalDetails from './OrderEntryModalDetails';
import OrderEntryModalFields from './OrderEntryModalFields';
import {
  createOrderEntry,
  getAccountDetails,
  getIceSymbol,
  getLastTrades,
  getOptionDetails,
  getPositionDetails,
  getSymbolDetails,
  getTradingPrivilege,
} from '../../../../services/OrderEntryService';
import { protoDateObjectToString } from '../../../../services/ConvertService';
import OrderEntryAddEditPreviewModal from './OrderEntryAddEditPreviewModal';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 1200,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    maxHeight: '80vh',
    overflowY: 'auto',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
}));

const defaultAccountDetails = {
  rep: '', // DISPLAYS IN FORM
  accountName: '', // DISPLAYS IN FORM
  cashBalance: 0, // DISPLAYS IN DETAILS
  accountValue: 0, // DISPLAYS IN DETAILS
  additionalCash: 0, // DISPLAYS IN DETAILS
};

const defaultSymbolDetails = {
  symbolDescription: '',
  lastPrice: 0,
  bidPrice: 0, //assetType === 'O'
  askPrice: 0, //assetType === 'O'
};

const defaultFormData = {
  accountNo: '',
  assetType: '',
  solicited: false,
  action: '',
  symbol: '',
  symbol2: '',
  dollar: 0,
  share: 0,
  qty: 0,
  orderType: '', // Timing
  expirationDate: '',
  putCall: '',
  strikePrice: 0,
  stopPrice: 0,
  trailingStop: 0,
  limitPrice: 0,
  tif: '',
  userNote: '',
  settlementType: '',
};

const defaultPositionDetails = {
  positionQty: 0,
  positionValue: 0,
  underlyingPosition: 0,
};

const defaultTradingPrivilege = {
  stocks: false,
  option: false,
  mutualFunds: false,
  fixedIncome: false,
};

export default function OrderEntryModal(props) {
  const classes = useStyles();
  const { data, open, close, loading, openPreview, setOpenPreview } = props;
  const isEdit = !!data.accountNo;
  const [modalData, setModalData] = React.useState({
    ...data,
    expirationDate: '',
  });

  const [retainData, setRetainData] = React.useState({
    assetType: false,
    action: false,
    timing: false,
    qty: false,
    expirationDate: false,
    putCall: false,
  });

  const [symbolDetails, setSymbolDetails] = React.useState(defaultSymbolDetails);
  const [symbolDescription, setSymbolDescription] = React.useState('');
  const [accountDetails, setAccountDetails] = React.useState(defaultAccountDetails);
  const [tradingPrivilege, setTradingPrivilege] = React.useState(defaultTradingPrivilege);
  const [lastTrades, setLastTrades] = React.useState([]);
  const [positionDetails, setPositionDetails] = React.useState({
    positionQty: data.positionQty || 0,
    positionValue: data.positionValue || 0,
    underlyingPosition: data.underlyingPosition || 0,
  });

  // PENDING DETAILS, ONLY USED IN SHARE CALCULATOR ('Dollar', 'Percentage')
  // amt: 0, //
  // share: 0, //assetType :== 'MF'
  // dollar: 0, //assetType === 'MF'

  // SET VALUES ON LOAD OF MODAL
  React.useEffect(async () => {
    // GET LAST TRADES
    const resLastTrades = await getLastTrades();
    setLastTrades(resLastTrades.lastTradesList);

    // GET OPTION DETAILS
    if (data.assetType === 'O' && modalData.symbol) {
      const optionDetails = await getOptionDetails(modalData.symbol);
      setSymbolDescription(optionDetails.symbolDescription);
      setModalData({
        ...modalData,
        expirationDate: protoDateObjectToString(optionDetails.expirationDate),
        strikePrice: optionDetails.strikePrice,
        putCall: optionDetails.putCall,
      });
    }
  }, []);

  React.useEffect(async () => {
    await applySymbolDetails();
  }, [modalData.orderType]);

  const applySymbolDetails = async () => {
    if (['SL', 'S', 'L'].includes(modalData.orderType) || modalData.assetType === 'O') {
      if (modalData.symbol && modalData.symbol.length > 15) {
        const details = await getSymbolDetails(modalData.symbol);
        setSymbolDetails({
          lastPrice: details.lastPrice,
          askPrice: details.askPrice,
          bidPrice: details.bidPrice,
        });
        setSymbolDescription(details.symbolDescription);
      } else {
        setSymbolDescription('');
        setSymbolDetails(defaultSymbolDetails);
      }
    }
  };

  React.useEffect(async () => {
    if (modalData.symbol && modalData.assetType && modalData.accountNo) {
      const details = await getPositionDetails(
        modalData.symbol,
        modalData.assetType,
        modalData.accountNo
      );
      setPositionDetails({
        positionQty: details.positionQty,
        positionValue: details.positionValue,
        underlyingPosition: details.underlyingPosition,
      });
    } else {
      setPositionDetails(defaultPositionDetails);
    }

    await applySymbolDetails();

    if (modalData.action === 'ICE' && modalData.symbol) {
      if (modalData.symbol) {
        const res = await getIceSymbol(modalData.symbol);
        console.log(res);
        setModalData({ ...modalData, symbol2: res.symbol2 });
      }
    }
  }, [modalData.symbol]);

  // GET ACCOUNT DETAILS ON CHANGE OF ACCOUNT NO
  React.useEffect(async () => {
    if (modalData.accountNo) {
      const res = await getAccountDetails(modalData.accountNo);
      setAccountDetails(res);
    } else {
      setAccountDetails(defaultAccountDetails);
    }
  }, [modalData.accountNo]);

  // GET TRADING PRIVILEGE THAT CHANGES THE ASSET TYPE DROPDOWN ITEMS ON CHANGE OF ACCOUNT NO
  React.useEffect(async () => {
    if (modalData.accountNo) {
      const res = await getTradingPrivilege(modalData.accountNo);
      setTradingPrivilege(res);
    } else {
      setTradingPrivilege(defaultTradingPrivilege);
    }

  }, [modalData.accountNo]);

  const handleSave = async (data) => {
      await close({ ...data, status: 'P' });
      checkRetainData();
      setOpenPreview(false);
  };

  const handleSavePreview = async () => {
    const valid = isValid();

    if (valid) {
      setOpenPreview(true);
    }
  };

  const handleSaveToPending = async () => {
    const valid = isValid();

    if (valid) {
      await close({ ...modalData, status: 'PP' });
      checkRetainData();
    }
  };

  const checkRetainData = async () => {
    setModalData({});

    let retainFieldsData = {
      assetType: modalData.assetType,
      action: modalData.action,
      timing: modalData.timing,
      qty: modalData.qty,
      expirationDate: modalData.expirationDate,
      putCall: modalData.putCall,
    };

    let details = await createOrderEntry();

    details.accountNo = modalData.accountNo;

    if (retainData.assetType === true) {
      details.assetType = retainFieldsData.assetType;
    }

    if (retainData.action === true) {
      details.action = retainFieldsData.action;
    }

    if (retainData.timing === true) {
      details.timing = retainFieldsData.timing;
    }

    if (retainData.qty === true) {
      details.qty = retainFieldsData.qty;
    }

    if (retainData.expirationDate === true) {
      details.expirationDate = retainFieldsData.expirationDate;
    }

    if (retainData.putCall === true) {
      details.putCall = retainFieldsData.putCall;
    }

    setModalData(details);
  };

  const isValid = () => {
    const valid = checkRequiredFields(['accountNo', 'assetType', 'action', 'symbol', 'share']);
    if (
      (modalData.share === '0' || modalData.share === '') &&
      (modalData.quantity === '0' || modalData.quantity === '')
    ) {
      notifyWarning('Share/Quantity is required.');
    }

    return valid;
  };

  const checkRequiredFields = (fieldNames) => {
    let valid = true;
    for (let index = 0; index < fieldNames.length; index++) {
      const field = fieldNames[index];
      if (!modalData[field]) {
        notifyWarning(`${lodash.startCase(field)} is required.`);
        valid = false;
      }
    }

    return valid;
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => close()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div style={{ width: 1200 }}>
          <div className={classes.modalHeader}>
            <div>
              <Text mt={4} variant="h2" label={isEdit ? 'Edit Order' : 'Add Order'} />
            </div>
            <div className={classes.closeButton}>
              <CloseButton onClick={() => close()} />
            </div>
          </div>
          <div className={classes.modalBody}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <OrderEntryModalFields
                  data={modalData}
                  setData={setModalData}
                  retainData={retainData}
                  setRetainData={setRetainData}
                  isEdit={isEdit}
                  accountDetails={accountDetails}
                  tradingPrivilege={tradingPrivilege}
                />
              </Grid>
              <Grid item xs={3}>
                <OrderEntryModalDetails
                  assetType={data.assetType}
                  lastTrades={lastTrades}
                  accountDetails={accountDetails}
                  symbolDescription={symbolDescription}
                  positionDetails={positionDetails}
                  symbolDetails={symbolDetails}
                />
              </Grid>
            </Grid>
          </div>
          <div className={classes.modalFooter}>
            <Button
              disabled={loading}
              loading={loading}
              label={loading ? 'Saving...' : 'Save To Pending'}
              onClick={handleSaveToPending}
            />
            <div style={{ marginLeft: '10px' }}>
              <Button
                disabled={loading}
                loading={loading}
                label={loading ? 'Saving...' : 'Save'}
                onClick={handleSavePreview}
              />
            </div>
            <div style={{ marginLeft: '10px' }}>
              <Button
                loading={loading}
                label={'Close'}
                onClick={() => {
                  close();
                }}
              />
            </div>
          </div>

          {openPreview && (
            <OrderEntryAddEditPreviewModal
              data={modalData}
              accountDetails={accountDetails}
              isEdit={isEdit}
              open={openPreview}
              close={() => {
                setOpenPreview(false);
              }}
              loading={loading}
              handleSave={handleSave}
            ></OrderEntryAddEditPreviewModal>
          )}
        </div>
      </Fade>
    </Modal>
  );
}
