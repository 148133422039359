import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactComponent as Menu } from '../../assets/icons/menu.svg';
import { ReactComponent as Dashboard } from '../../assets/icons/dashboard.svg';
import { ReactComponent as AccountSetup } from '../../assets/icons/account-setup.svg';
import { ReactComponent as Banking } from '../../assets/icons/bank.svg';
import { ReactComponent as TaxFile } from '../../assets/icons/taxes.svg';
import { ReactComponent as Reports } from '../../assets/icons/reports.svg';
import { ReactComponent as Invoice } from '../../assets/icons/invoice.svg';
import { ReactComponent as Disclosure } from '../../assets/icons/disclosure.svg';
import { ReactComponent as Lending } from '../../assets/icons/lending.svg';
import { ReactComponent as ContactUs } from '../../assets/icons/contact.svg';
import { ReactComponent as Options } from '../../assets/icons/file.svg';
import { ReactComponent as Model } from '../../assets/icons/scale.svg';
import { ReactComponent as Trade } from '../../assets/icons/exchange.svg';
import { ReactComponent as File } from '../../assets/icons/file.svg';
import DisclosureModal from '../../components/Modals/DisclosureModal';
import ContactUsModal from '../Modals/ContactAddUsModal';
import color from '../../assets/css/colors';
import fontFamily from '../../assets/css/fonts';
import { IconButton } from '@material-ui/core';
import { pages } from '../../lib/data/pages';
import { ConsoleSqlOutlined } from '@ant-design/icons';

const useStyles = makeStyles({
  toggleMenu: {
    margin: '10px 20px 10px -10px',
    padding: 10,
    '& svg': {
      width: 18,
      height: 18,
    },
  },
  list: {
    padding: 0,
    width: 280,
    backgroundColor: color.light.main,
    '& a': {
      textDecoration: 'none',
      display: 'block',
      '&.active': {
        backgroundColor: color.primary.main,
        '& span': {
          color: color.dark.opacity,
        },
        '& svg path': {
          fill: color.light.main,
        },
      },
    },
    '& svg': {
      width: 22,
      height: 22,
    },
    '& span': {
      fontFamily: fontFamily.headers,
      fontSize: 15,
      fontWeight: 600,
    },
    '& .MuiAccordionSummary-root': {
      padding: '0px 20px 0px 0px',
      minHeight: 'unset',
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
    '& .Mui-expanded': {
      marginTop: 0,
      marginBottom: 0,
      minHeight: 'unset',
    },
    '& .MuiAccordion-root:before': {
      top: 0,
      height: 0,
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
      flexDirection: 'column',
      backgroundColor: color.light.dark,
    },
  },
  fullList: {
    width: 'auto',
  },
});

export default function SideBar(props) {
  const classes = useStyles();

  const { userType, pageAccess, checkAccessUpdate, setToggled, status } = props;

  const [expanded, setExpanded] = React.useState('');

  const [open, setOpen] = React.useState({
    sideBar: status === 'Active' ? true : false,
    contactUsModal: false,
    disclosureModal: false,
  });

  const closeSideBar = () => {
    setOpen({
      ...open,
      contactUsModal: false,
      disclosureModal: false,
    });
  };

  const toggleSideBar = (isOpen) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    checkAccessUpdate();

    setOpen({ ...open, sideBar: !isOpen });
    setToggled(!isOpen);
  };

  const handleExpand = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const accessiblePages = (menu) => {
    const accessiblePages = [];
    pages
      .filter((page) => {
        if (menu === 'Option') {
          return page.groupLabel === menu || page.groupLabel === 'Options';
        }
        return page.groupLabel === menu;

      })
      .forEach((page) => {
        const key = (page.menu + page.groupLabel + page.page).replace(/ /g, '');

        pageAccess.forEach((access) => {
          if (access.pageKey === key) {
            accessiblePages.push({ label: page.label, link: page.link });
          }
        });
      });
    return accessiblePages;
  };

  return (
    <div className="side-drawer-wrapper">
      <div className="drawer-toggle">
        <IconButton className={classes.toggleMenu} onClick={toggleSideBar(open.sideBar)}>
          <Menu />
        </IconButton>
      </div>
      <Drawer anchor={'left'} variant="persistent" open={open.sideBar}>
        <List className={classes.list}>
          <NavLink className="grd-cell" to="/app/dashboard">
            <ListItem button>
              <ListItemIcon>
                <Dashboard />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </NavLink>
          {accessiblePages('Account Setup').length > 0 && (
            <Accordion
              square
              expanded={expanded === 'Account Setup'}
              onChange={handleExpand('Account Setup')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <ListItem button>
                  <ListItemIcon>
                    <AccountSetup />
                  </ListItemIcon>
                  <ListItemText primary="Account Setup" />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                {accessiblePages('Account Setup').map((page, index) => {
                  return (
                    <NavLink
                      className="grd-cell"
                      key={page.label + '_' + index}
                      to={page.link}
                    >
                      <ListItem button>
                        <ListItemText primary={page.label} />
                      </ListItem>
                    </NavLink>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          )}
          {accessiblePages('Banking').length > 0 && (
            <Accordion
              square
              expanded={expanded === 'Banking'}
              onChange={handleExpand('Banking')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <ListItem button>
                  <ListItemIcon>
                    <Banking />
                  </ListItemIcon>
                  <ListItemText primary="Banking" />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                {accessiblePages('Banking').map((page, index) => {
                  return (
                    <NavLink
                      className="grd-cell"
                      key={page.label + '_' + index}
                      to={page.link}
                    >
                      <ListItem button>
                        <ListItemText primary={page.label} />
                      </ListItem>
                    </NavLink>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          )}
          {accessiblePages('Reports').length > 0 && (
            <Accordion
              square
              expanded={expanded === 'Reports'}
              onChange={handleExpand('Reports')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <ListItem button>
                  <ListItemIcon>
                    <Reports />
                  </ListItemIcon>
                  <ListItemText primary="Reports" />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                {accessiblePages('Reports').map((page, index) => {
                  return (
                    <NavLink
                      className="grd-cell"
                      key={page.label + '_' + index}
                      to={page.link}
                    >
                      <ListItem button>
                        <ListItemText primary={page.label} />
                      </ListItem>
                    </NavLink>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          )}
          {accessiblePages('Reports').length > 0 && (
            <Accordion
              square
              expanded={expanded === 'Tax Filing'}
              onChange={handleExpand('Tax Filing')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <ListItem button>
                  <ListItemIcon>
                    <TaxFile />
                  </ListItemIcon>
                  <ListItemText primary="Tax Filing" />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                {accessiblePages('Tax Filing').map((page, index) => {
                  return (
                    <NavLink
                      className="grd-cell"
                      key={page.label + '_' + index}
                      to={page.link}
                    >
                      <ListItem button>
                        <ListItemText primary={page.label} />
                      </ListItem>
                    </NavLink>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          )}
          {accessiblePages('Option').length > 0 && (
            <Accordion
              square
              expanded={expanded === 'Options'}
              onChange={handleExpand('Options')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <ListItem button>
                  <ListItemIcon>
                    <Options />
                  </ListItemIcon>
                  <ListItemText primary="Options" />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                {accessiblePages('Option').map((page, index) => {
                  return (
                    <NavLink
                      className="grd-cell"
                      key={page.label + '_' + index}
                      to={page.link}
                    >
                      <ListItem button>
                        <ListItemText primary={page.label} />
                      </ListItem>
                    </NavLink>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          )}
          {accessiblePages('File').length > 0 && (
            <>
              <NavLink className="grd-cell" to="/app/file-manager">
                <ListItem button>
                  <ListItemIcon>
                    <File />
                  </ListItemIcon>
                  <ListItemText primary="File Manager" />
                </ListItem>
              </NavLink>
            </>
          )}
          {userType !== 'Employer Member' && (
            <>
              {/* <NavLink className="grd-cell" to="/app/tax-files">
                <ListItem button>
                  <ListItemIcon>
                    <TaxFile />
                  </ListItemIcon>
                  <ListItemText primary="Tax Files" />
                </ListItem>
              </NavLink> */}
              <NavLink className="grd-cell" to="/app/invoices">
                <ListItem button>
                  <ListItemIcon>
                    <Invoice />
                  </ListItemIcon>
                  <ListItemText primary="Invoices" />
                </ListItem>
              </NavLink>
              <NavLink
                className="grd-cell"
                to="/app/disclosure/#"
                onClick={(e) => {
                  e.preventDefault();
                  setOpen({ ...open, disclosureModal: true });
                }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <Disclosure />
                  </ListItemIcon>
                  <ListItemText primary="Disclosure" />
                </ListItem>
              </NavLink>
              <NavLink className="grd-cell" to="/app/stock-borrow">
                <ListItem button>
                  <ListItemIcon>
                    <Lending />
                  </ListItemIcon>
                  <ListItemText primary="Stock Borrow" />
                </ListItem>
              </NavLink>
              {/* <NavLink
                className="grd-cell"
                to="/app/contactUs/#"
                onClick={(e) => {
                  e.preventDefault();
                  setOpen({ ...open, contactUsModal: true });
                }}
              >
                <ListItem button>
                  <ListItemIcon>
                    <ContactUs />
                  </ListItemIcon>
                  <ListItemText primary="Contact Us" />
                </ListItem>
              </NavLink> */}
            </>
          )}
          {accessiblePages('Model').length > 0 && (
            <Accordion
              square
              expanded={expanded === 'Model'}
              onChange={handleExpand('Model')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <ListItem button>
                  <ListItemIcon>
                    <Model />
                  </ListItemIcon>
                  <ListItemText primary="Model" />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                {accessiblePages('Model').map((page, index) => {
                  return (
                    <NavLink
                      className="grd-cell"
                      key={page.label + '_' + index}
                      to={page.link}
                    >
                      <ListItem button>
                        <ListItemText primary={page.label} />
                      </ListItem>
                    </NavLink>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          )}
          {accessiblePages('Trade').length > 0 && (
            <Accordion
              square
              expanded={expanded === 'Trade'}
              onChange={handleExpand('Trade')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <ListItem button>
                  <ListItemIcon>
                    <Trade />
                  </ListItemIcon>
                  <ListItemText primary="Trade" />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                {accessiblePages('Trade').map((page, index) => {
                  return (
                    <NavLink
                      className="grd-cell"
                      key={page.label + '_' + index}
                      to={page.link}
                    >
                      <ListItem button>
                        <ListItemText primary={page.label} />
                      </ListItem>
                    </NavLink>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          )}
          {accessiblePages('Contact Us').length > 0 && (
            <Accordion
              square
              expanded={expanded === 'Contact Us'}
              onChange={handleExpand('Contact Us')}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <ListItem button>
                  <ListItemIcon>
                    <ContactUs />
                  </ListItemIcon>
                  <ListItemText primary="Contact Us" />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails>
                {accessiblePages('Contact Us').map((page, index) => {
                  return (
                    <NavLink
                      className="grd-cell"
                      key={page.label + '_' + index}
                      to={page.link}
                    >
                      <ListItem button>
                        <ListItemText primary={page.label} />
                      </ListItem>
                    </NavLink>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          )}
        </List>
      </Drawer>
      {open.disclosureModal && (
        <DisclosureModal open={open.disclosureModal} close={closeSideBar} />
      )}
      {open.contactUsModal && (
        <ContactUsModal open={open.contactUsModal} close={closeSideBar} />
      )}
    </div>
  );
}
