/**
 * @fileoverview gRPC-Web generated client stub for tradepb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_type_date_pb = require('../../google/type/date_pb.js')
const proto = {};
proto.tradepb = require('./orderentry_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.tradepb.OrderEntryServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.tradepb.OrderEntryServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tradepb.GetOrderEntryRequest,
 *   !proto.tradepb.OrderEntry>}
 */
const methodDescriptor_OrderEntryService_GetOrderEntry = new grpc.web.MethodDescriptor(
  '/tradepb.OrderEntryService/GetOrderEntry',
  grpc.web.MethodType.UNARY,
  proto.tradepb.GetOrderEntryRequest,
  proto.tradepb.OrderEntry,
  /**
   * @param {!proto.tradepb.GetOrderEntryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.OrderEntry.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.tradepb.GetOrderEntryRequest,
 *   !proto.tradepb.OrderEntry>}
 */
const methodInfo_OrderEntryService_GetOrderEntry = new grpc.web.AbstractClientBase.MethodInfo(
  proto.tradepb.OrderEntry,
  /**
   * @param {!proto.tradepb.GetOrderEntryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.OrderEntry.deserializeBinary
);


/**
 * @param {!proto.tradepb.GetOrderEntryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.tradepb.OrderEntry)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tradepb.OrderEntry>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tradepb.OrderEntryServiceClient.prototype.getOrderEntry =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tradepb.OrderEntryService/GetOrderEntry',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_GetOrderEntry,
      callback);
};


/**
 * @param {!proto.tradepb.GetOrderEntryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tradepb.OrderEntry>}
 *     Promise that resolves to the response
 */
proto.tradepb.OrderEntryServicePromiseClient.prototype.getOrderEntry =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tradepb.OrderEntryService/GetOrderEntry',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_GetOrderEntry);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tradepb.ListOrderEntryRequest,
 *   !proto.tradepb.ListOrderEntryResponse>}
 */
const methodDescriptor_OrderEntryService_ListOrderEntry = new grpc.web.MethodDescriptor(
  '/tradepb.OrderEntryService/ListOrderEntry',
  grpc.web.MethodType.UNARY,
  proto.tradepb.ListOrderEntryRequest,
  proto.tradepb.ListOrderEntryResponse,
  /**
   * @param {!proto.tradepb.ListOrderEntryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.ListOrderEntryResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.tradepb.ListOrderEntryRequest,
 *   !proto.tradepb.ListOrderEntryResponse>}
 */
const methodInfo_OrderEntryService_ListOrderEntry = new grpc.web.AbstractClientBase.MethodInfo(
  proto.tradepb.ListOrderEntryResponse,
  /**
   * @param {!proto.tradepb.ListOrderEntryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.ListOrderEntryResponse.deserializeBinary
);


/**
 * @param {!proto.tradepb.ListOrderEntryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.tradepb.ListOrderEntryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tradepb.ListOrderEntryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tradepb.OrderEntryServiceClient.prototype.listOrderEntry =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tradepb.OrderEntryService/ListOrderEntry',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_ListOrderEntry,
      callback);
};


/**
 * @param {!proto.tradepb.ListOrderEntryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tradepb.ListOrderEntryResponse>}
 *     Promise that resolves to the response
 */
proto.tradepb.OrderEntryServicePromiseClient.prototype.listOrderEntry =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tradepb.OrderEntryService/ListOrderEntry',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_ListOrderEntry);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tradepb.Empty,
 *   !proto.tradepb.ListPendingOrderEntryResponse>}
 */
const methodDescriptor_OrderEntryService_ListPendingOrderEntry = new grpc.web.MethodDescriptor(
  '/tradepb.OrderEntryService/ListPendingOrderEntry',
  grpc.web.MethodType.UNARY,
  proto.tradepb.Empty,
  proto.tradepb.ListPendingOrderEntryResponse,
  /**
   * @param {!proto.tradepb.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.ListPendingOrderEntryResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.tradepb.Empty,
 *   !proto.tradepb.ListPendingOrderEntryResponse>}
 */
const methodInfo_OrderEntryService_ListPendingOrderEntry = new grpc.web.AbstractClientBase.MethodInfo(
  proto.tradepb.ListPendingOrderEntryResponse,
  /**
   * @param {!proto.tradepb.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.ListPendingOrderEntryResponse.deserializeBinary
);


/**
 * @param {!proto.tradepb.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.tradepb.ListPendingOrderEntryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tradepb.ListPendingOrderEntryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tradepb.OrderEntryServiceClient.prototype.listPendingOrderEntry =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tradepb.OrderEntryService/ListPendingOrderEntry',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_ListPendingOrderEntry,
      callback);
};


/**
 * @param {!proto.tradepb.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tradepb.ListPendingOrderEntryResponse>}
 *     Promise that resolves to the response
 */
proto.tradepb.OrderEntryServicePromiseClient.prototype.listPendingOrderEntry =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tradepb.OrderEntryService/ListPendingOrderEntry',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_ListPendingOrderEntry);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tradepb.OrderEntry,
 *   !proto.tradepb.OrderEntry>}
 */
const methodDescriptor_OrderEntryService_CreateOrderEntry = new grpc.web.MethodDescriptor(
  '/tradepb.OrderEntryService/CreateOrderEntry',
  grpc.web.MethodType.UNARY,
  proto.tradepb.OrderEntry,
  proto.tradepb.OrderEntry,
  /**
   * @param {!proto.tradepb.OrderEntry} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.OrderEntry.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.tradepb.OrderEntry,
 *   !proto.tradepb.OrderEntry>}
 */
const methodInfo_OrderEntryService_CreateOrderEntry = new grpc.web.AbstractClientBase.MethodInfo(
  proto.tradepb.OrderEntry,
  /**
   * @param {!proto.tradepb.OrderEntry} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.OrderEntry.deserializeBinary
);


/**
 * @param {!proto.tradepb.OrderEntry} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.tradepb.OrderEntry)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tradepb.OrderEntry>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tradepb.OrderEntryServiceClient.prototype.createOrderEntry =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tradepb.OrderEntryService/CreateOrderEntry',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_CreateOrderEntry,
      callback);
};


/**
 * @param {!proto.tradepb.OrderEntry} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tradepb.OrderEntry>}
 *     Promise that resolves to the response
 */
proto.tradepb.OrderEntryServicePromiseClient.prototype.createOrderEntry =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tradepb.OrderEntryService/CreateOrderEntry',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_CreateOrderEntry);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tradepb.OrderEntry,
 *   !proto.tradepb.OrderEntry>}
 */
const methodDescriptor_OrderEntryService_UpdateOrderEntry = new grpc.web.MethodDescriptor(
  '/tradepb.OrderEntryService/UpdateOrderEntry',
  grpc.web.MethodType.UNARY,
  proto.tradepb.OrderEntry,
  proto.tradepb.OrderEntry,
  /**
   * @param {!proto.tradepb.OrderEntry} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.OrderEntry.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.tradepb.OrderEntry,
 *   !proto.tradepb.OrderEntry>}
 */
const methodInfo_OrderEntryService_UpdateOrderEntry = new grpc.web.AbstractClientBase.MethodInfo(
  proto.tradepb.OrderEntry,
  /**
   * @param {!proto.tradepb.OrderEntry} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.OrderEntry.deserializeBinary
);


/**
 * @param {!proto.tradepb.OrderEntry} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.tradepb.OrderEntry)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tradepb.OrderEntry>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tradepb.OrderEntryServiceClient.prototype.updateOrderEntry =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tradepb.OrderEntryService/UpdateOrderEntry',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_UpdateOrderEntry,
      callback);
};


/**
 * @param {!proto.tradepb.OrderEntry} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tradepb.OrderEntry>}
 *     Promise that resolves to the response
 */
proto.tradepb.OrderEntryServicePromiseClient.prototype.updateOrderEntry =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tradepb.OrderEntryService/UpdateOrderEntry',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_UpdateOrderEntry);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tradepb.OrderEntry,
 *   !proto.tradepb.Empty>}
 */
const methodDescriptor_OrderEntryService_DeleteOrderEntry = new grpc.web.MethodDescriptor(
  '/tradepb.OrderEntryService/DeleteOrderEntry',
  grpc.web.MethodType.UNARY,
  proto.tradepb.OrderEntry,
  proto.tradepb.Empty,
  /**
   * @param {!proto.tradepb.OrderEntry} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.tradepb.OrderEntry,
 *   !proto.tradepb.Empty>}
 */
const methodInfo_OrderEntryService_DeleteOrderEntry = new grpc.web.AbstractClientBase.MethodInfo(
  proto.tradepb.Empty,
  /**
   * @param {!proto.tradepb.OrderEntry} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.Empty.deserializeBinary
);


/**
 * @param {!proto.tradepb.OrderEntry} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.tradepb.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tradepb.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tradepb.OrderEntryServiceClient.prototype.deleteOrderEntry =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tradepb.OrderEntryService/DeleteOrderEntry',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_DeleteOrderEntry,
      callback);
};


/**
 * @param {!proto.tradepb.OrderEntry} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tradepb.Empty>}
 *     Promise that resolves to the response
 */
proto.tradepb.OrderEntryServicePromiseClient.prototype.deleteOrderEntry =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tradepb.OrderEntryService/DeleteOrderEntry',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_DeleteOrderEntry);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tradepb.MovePendingRequest,
 *   !proto.tradepb.Empty>}
 */
const methodDescriptor_OrderEntryService_MoveToPending = new grpc.web.MethodDescriptor(
  '/tradepb.OrderEntryService/MoveToPending',
  grpc.web.MethodType.UNARY,
  proto.tradepb.MovePendingRequest,
  proto.tradepb.Empty,
  /**
   * @param {!proto.tradepb.MovePendingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.tradepb.MovePendingRequest,
 *   !proto.tradepb.Empty>}
 */
const methodInfo_OrderEntryService_MoveToPending = new grpc.web.AbstractClientBase.MethodInfo(
  proto.tradepb.Empty,
  /**
   * @param {!proto.tradepb.MovePendingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.Empty.deserializeBinary
);


/**
 * @param {!proto.tradepb.MovePendingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.tradepb.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tradepb.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tradepb.OrderEntryServiceClient.prototype.moveToPending =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tradepb.OrderEntryService/MoveToPending',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_MoveToPending,
      callback);
};


/**
 * @param {!proto.tradepb.MovePendingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tradepb.Empty>}
 *     Promise that resolves to the response
 */
proto.tradepb.OrderEntryServicePromiseClient.prototype.moveToPending =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tradepb.OrderEntryService/MoveToPending',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_MoveToPending);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tradepb.MoveTradesRequest,
 *   !proto.tradepb.Empty>}
 */
const methodDescriptor_OrderEntryService_MoveToTrades = new grpc.web.MethodDescriptor(
  '/tradepb.OrderEntryService/MoveToTrades',
  grpc.web.MethodType.UNARY,
  proto.tradepb.MoveTradesRequest,
  proto.tradepb.Empty,
  /**
   * @param {!proto.tradepb.MoveTradesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.tradepb.MoveTradesRequest,
 *   !proto.tradepb.Empty>}
 */
const methodInfo_OrderEntryService_MoveToTrades = new grpc.web.AbstractClientBase.MethodInfo(
  proto.tradepb.Empty,
  /**
   * @param {!proto.tradepb.MoveTradesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.Empty.deserializeBinary
);


/**
 * @param {!proto.tradepb.MoveTradesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.tradepb.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tradepb.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tradepb.OrderEntryServiceClient.prototype.moveToTrades =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tradepb.OrderEntryService/MoveToTrades',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_MoveToTrades,
      callback);
};


/**
 * @param {!proto.tradepb.MoveTradesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tradepb.Empty>}
 *     Promise that resolves to the response
 */
proto.tradepb.OrderEntryServicePromiseClient.prototype.moveToTrades =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tradepb.OrderEntryService/MoveToTrades',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_MoveToTrades);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tradepb.GetAccountDetailsRequest,
 *   !proto.tradepb.AccountDetails>}
 */
const methodDescriptor_OrderEntryService_GetAccountDetails = new grpc.web.MethodDescriptor(
  '/tradepb.OrderEntryService/GetAccountDetails',
  grpc.web.MethodType.UNARY,
  proto.tradepb.GetAccountDetailsRequest,
  proto.tradepb.AccountDetails,
  /**
   * @param {!proto.tradepb.GetAccountDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.AccountDetails.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.tradepb.GetAccountDetailsRequest,
 *   !proto.tradepb.AccountDetails>}
 */
const methodInfo_OrderEntryService_GetAccountDetails = new grpc.web.AbstractClientBase.MethodInfo(
  proto.tradepb.AccountDetails,
  /**
   * @param {!proto.tradepb.GetAccountDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.AccountDetails.deserializeBinary
);


/**
 * @param {!proto.tradepb.GetAccountDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.tradepb.AccountDetails)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tradepb.AccountDetails>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tradepb.OrderEntryServiceClient.prototype.getAccountDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tradepb.OrderEntryService/GetAccountDetails',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_GetAccountDetails,
      callback);
};


/**
 * @param {!proto.tradepb.GetAccountDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tradepb.AccountDetails>}
 *     Promise that resolves to the response
 */
proto.tradepb.OrderEntryServicePromiseClient.prototype.getAccountDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tradepb.OrderEntryService/GetAccountDetails',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_GetAccountDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tradepb.GetOptionDetailsRequest,
 *   !proto.tradepb.OptionDetails>}
 */
const methodDescriptor_OrderEntryService_GetOptionDetails = new grpc.web.MethodDescriptor(
  '/tradepb.OrderEntryService/GetOptionDetails',
  grpc.web.MethodType.UNARY,
  proto.tradepb.GetOptionDetailsRequest,
  proto.tradepb.OptionDetails,
  /**
   * @param {!proto.tradepb.GetOptionDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.OptionDetails.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.tradepb.GetOptionDetailsRequest,
 *   !proto.tradepb.OptionDetails>}
 */
const methodInfo_OrderEntryService_GetOptionDetails = new grpc.web.AbstractClientBase.MethodInfo(
  proto.tradepb.OptionDetails,
  /**
   * @param {!proto.tradepb.GetOptionDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.OptionDetails.deserializeBinary
);


/**
 * @param {!proto.tradepb.GetOptionDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.tradepb.OptionDetails)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tradepb.OptionDetails>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tradepb.OrderEntryServiceClient.prototype.getOptionDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tradepb.OrderEntryService/GetOptionDetails',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_GetOptionDetails,
      callback);
};


/**
 * @param {!proto.tradepb.GetOptionDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tradepb.OptionDetails>}
 *     Promise that resolves to the response
 */
proto.tradepb.OrderEntryServicePromiseClient.prototype.getOptionDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tradepb.OrderEntryService/GetOptionDetails',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_GetOptionDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tradepb.GetSymbolDetailsRequest,
 *   !proto.tradepb.SymbolDetails>}
 */
const methodDescriptor_OrderEntryService_GetSymbolDetails = new grpc.web.MethodDescriptor(
  '/tradepb.OrderEntryService/GetSymbolDetails',
  grpc.web.MethodType.UNARY,
  proto.tradepb.GetSymbolDetailsRequest,
  proto.tradepb.SymbolDetails,
  /**
   * @param {!proto.tradepb.GetSymbolDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.SymbolDetails.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.tradepb.GetSymbolDetailsRequest,
 *   !proto.tradepb.SymbolDetails>}
 */
const methodInfo_OrderEntryService_GetSymbolDetails = new grpc.web.AbstractClientBase.MethodInfo(
  proto.tradepb.SymbolDetails,
  /**
   * @param {!proto.tradepb.GetSymbolDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.SymbolDetails.deserializeBinary
);


/**
 * @param {!proto.tradepb.GetSymbolDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.tradepb.SymbolDetails)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tradepb.SymbolDetails>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tradepb.OrderEntryServiceClient.prototype.getSymbolDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tradepb.OrderEntryService/GetSymbolDetails',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_GetSymbolDetails,
      callback);
};


/**
 * @param {!proto.tradepb.GetSymbolDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tradepb.SymbolDetails>}
 *     Promise that resolves to the response
 */
proto.tradepb.OrderEntryServicePromiseClient.prototype.getSymbolDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tradepb.OrderEntryService/GetSymbolDetails',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_GetSymbolDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tradepb.Empty,
 *   !proto.tradepb.LastTrades>}
 */
const methodDescriptor_OrderEntryService_GetLastTrades = new grpc.web.MethodDescriptor(
  '/tradepb.OrderEntryService/GetLastTrades',
  grpc.web.MethodType.UNARY,
  proto.tradepb.Empty,
  proto.tradepb.LastTrades,
  /**
   * @param {!proto.tradepb.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.LastTrades.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.tradepb.Empty,
 *   !proto.tradepb.LastTrades>}
 */
const methodInfo_OrderEntryService_GetLastTrades = new grpc.web.AbstractClientBase.MethodInfo(
  proto.tradepb.LastTrades,
  /**
   * @param {!proto.tradepb.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.LastTrades.deserializeBinary
);


/**
 * @param {!proto.tradepb.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.tradepb.LastTrades)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tradepb.LastTrades>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tradepb.OrderEntryServiceClient.prototype.getLastTrades =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tradepb.OrderEntryService/GetLastTrades',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_GetLastTrades,
      callback);
};


/**
 * @param {!proto.tradepb.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tradepb.LastTrades>}
 *     Promise that resolves to the response
 */
proto.tradepb.OrderEntryServicePromiseClient.prototype.getLastTrades =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tradepb.OrderEntryService/GetLastTrades',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_GetLastTrades);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tradepb.Empty,
 *   !proto.tradepb.Totals>}
 */
const methodDescriptor_OrderEntryService_GetTotals = new grpc.web.MethodDescriptor(
  '/tradepb.OrderEntryService/GetTotals',
  grpc.web.MethodType.UNARY,
  proto.tradepb.Empty,
  proto.tradepb.Totals,
  /**
   * @param {!proto.tradepb.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.Totals.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.tradepb.Empty,
 *   !proto.tradepb.Totals>}
 */
const methodInfo_OrderEntryService_GetTotals = new grpc.web.AbstractClientBase.MethodInfo(
  proto.tradepb.Totals,
  /**
   * @param {!proto.tradepb.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.Totals.deserializeBinary
);


/**
 * @param {!proto.tradepb.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.tradepb.Totals)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tradepb.Totals>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tradepb.OrderEntryServiceClient.prototype.getTotals =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tradepb.OrderEntryService/GetTotals',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_GetTotals,
      callback);
};


/**
 * @param {!proto.tradepb.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tradepb.Totals>}
 *     Promise that resolves to the response
 */
proto.tradepb.OrderEntryServicePromiseClient.prototype.getTotals =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tradepb.OrderEntryService/GetTotals',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_GetTotals);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tradepb.ClientInputRequest,
 *   !proto.tradepb.Empty>}
 */
const methodDescriptor_OrderEntryService_SetClientInput = new grpc.web.MethodDescriptor(
  '/tradepb.OrderEntryService/SetClientInput',
  grpc.web.MethodType.UNARY,
  proto.tradepb.ClientInputRequest,
  proto.tradepb.Empty,
  /**
   * @param {!proto.tradepb.ClientInputRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.tradepb.ClientInputRequest,
 *   !proto.tradepb.Empty>}
 */
const methodInfo_OrderEntryService_SetClientInput = new grpc.web.AbstractClientBase.MethodInfo(
  proto.tradepb.Empty,
  /**
   * @param {!proto.tradepb.ClientInputRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.Empty.deserializeBinary
);


/**
 * @param {!proto.tradepb.ClientInputRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.tradepb.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tradepb.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tradepb.OrderEntryServiceClient.prototype.setClientInput =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tradepb.OrderEntryService/SetClientInput',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_SetClientInput,
      callback);
};


/**
 * @param {!proto.tradepb.ClientInputRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tradepb.Empty>}
 *     Promise that resolves to the response
 */
proto.tradepb.OrderEntryServicePromiseClient.prototype.setClientInput =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tradepb.OrderEntryService/SetClientInput',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_SetClientInput);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tradepb.GetPositionDetailsRequest,
 *   !proto.tradepb.PositionDetails>}
 */
const methodDescriptor_OrderEntryService_GetPositionDetails = new grpc.web.MethodDescriptor(
  '/tradepb.OrderEntryService/GetPositionDetails',
  grpc.web.MethodType.UNARY,
  proto.tradepb.GetPositionDetailsRequest,
  proto.tradepb.PositionDetails,
  /**
   * @param {!proto.tradepb.GetPositionDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.PositionDetails.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.tradepb.GetPositionDetailsRequest,
 *   !proto.tradepb.PositionDetails>}
 */
const methodInfo_OrderEntryService_GetPositionDetails = new grpc.web.AbstractClientBase.MethodInfo(
  proto.tradepb.PositionDetails,
  /**
   * @param {!proto.tradepb.GetPositionDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.PositionDetails.deserializeBinary
);


/**
 * @param {!proto.tradepb.GetPositionDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.tradepb.PositionDetails)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tradepb.PositionDetails>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tradepb.OrderEntryServiceClient.prototype.getPositionDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tradepb.OrderEntryService/GetPositionDetails',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_GetPositionDetails,
      callback);
};


/**
 * @param {!proto.tradepb.GetPositionDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tradepb.PositionDetails>}
 *     Promise that resolves to the response
 */
proto.tradepb.OrderEntryServicePromiseClient.prototype.getPositionDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tradepb.OrderEntryService/GetPositionDetails',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_GetPositionDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tradepb.GetIceSymbolRequest,
 *   !proto.tradepb.GetIceSymbolResponse>}
 */
const methodDescriptor_OrderEntryService_GetIceSymbol = new grpc.web.MethodDescriptor(
  '/tradepb.OrderEntryService/GetIceSymbol',
  grpc.web.MethodType.UNARY,
  proto.tradepb.GetIceSymbolRequest,
  proto.tradepb.GetIceSymbolResponse,
  /**
   * @param {!proto.tradepb.GetIceSymbolRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.GetIceSymbolResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.tradepb.GetIceSymbolRequest,
 *   !proto.tradepb.GetIceSymbolResponse>}
 */
const methodInfo_OrderEntryService_GetIceSymbol = new grpc.web.AbstractClientBase.MethodInfo(
  proto.tradepb.GetIceSymbolResponse,
  /**
   * @param {!proto.tradepb.GetIceSymbolRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.GetIceSymbolResponse.deserializeBinary
);


/**
 * @param {!proto.tradepb.GetIceSymbolRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.tradepb.GetIceSymbolResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tradepb.GetIceSymbolResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tradepb.OrderEntryServiceClient.prototype.getIceSymbol =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tradepb.OrderEntryService/GetIceSymbol',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_GetIceSymbol,
      callback);
};


/**
 * @param {!proto.tradepb.GetIceSymbolRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tradepb.GetIceSymbolResponse>}
 *     Promise that resolves to the response
 */
proto.tradepb.OrderEntryServicePromiseClient.prototype.getIceSymbol =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tradepb.OrderEntryService/GetIceSymbol',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_GetIceSymbol);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tradepb.GetPositionsRequest,
 *   !proto.tradepb.GetPositionsResponse>}
 */
const methodDescriptor_OrderEntryService_GetPositions = new grpc.web.MethodDescriptor(
  '/tradepb.OrderEntryService/GetPositions',
  grpc.web.MethodType.UNARY,
  proto.tradepb.GetPositionsRequest,
  proto.tradepb.GetPositionsResponse,
  /**
   * @param {!proto.tradepb.GetPositionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.GetPositionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.tradepb.GetPositionsRequest,
 *   !proto.tradepb.GetPositionsResponse>}
 */
const methodInfo_OrderEntryService_GetPositions = new grpc.web.AbstractClientBase.MethodInfo(
  proto.tradepb.GetPositionsResponse,
  /**
   * @param {!proto.tradepb.GetPositionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.GetPositionsResponse.deserializeBinary
);


/**
 * @param {!proto.tradepb.GetPositionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.tradepb.GetPositionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tradepb.GetPositionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tradepb.OrderEntryServiceClient.prototype.getPositions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tradepb.OrderEntryService/GetPositions',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_GetPositions,
      callback);
};


/**
 * @param {!proto.tradepb.GetPositionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tradepb.GetPositionsResponse>}
 *     Promise that resolves to the response
 */
proto.tradepb.OrderEntryServicePromiseClient.prototype.getPositions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tradepb.OrderEntryService/GetPositions',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_GetPositions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tradepb.ListAmountBreakdownRequest,
 *   !proto.tradepb.ListAmountBreakdownResponse>}
 */
const methodDescriptor_OrderEntryService_ListAmountBreakdown = new grpc.web.MethodDescriptor(
  '/tradepb.OrderEntryService/ListAmountBreakdown',
  grpc.web.MethodType.UNARY,
  proto.tradepb.ListAmountBreakdownRequest,
  proto.tradepb.ListAmountBreakdownResponse,
  /**
   * @param {!proto.tradepb.ListAmountBreakdownRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.ListAmountBreakdownResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.tradepb.ListAmountBreakdownRequest,
 *   !proto.tradepb.ListAmountBreakdownResponse>}
 */
const methodInfo_OrderEntryService_ListAmountBreakdown = new grpc.web.AbstractClientBase.MethodInfo(
  proto.tradepb.ListAmountBreakdownResponse,
  /**
   * @param {!proto.tradepb.ListAmountBreakdownRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.ListAmountBreakdownResponse.deserializeBinary
);


/**
 * @param {!proto.tradepb.ListAmountBreakdownRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.tradepb.ListAmountBreakdownResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tradepb.ListAmountBreakdownResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tradepb.OrderEntryServiceClient.prototype.listAmountBreakdown =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tradepb.OrderEntryService/ListAmountBreakdown',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_ListAmountBreakdown,
      callback);
};


/**
 * @param {!proto.tradepb.ListAmountBreakdownRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tradepb.ListAmountBreakdownResponse>}
 *     Promise that resolves to the response
 */
proto.tradepb.OrderEntryServicePromiseClient.prototype.listAmountBreakdown =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tradepb.OrderEntryService/ListAmountBreakdown',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_ListAmountBreakdown);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tradepb.DuplicateTradeRequest,
 *   !proto.tradepb.Empty>}
 */
const methodDescriptor_OrderEntryService_DuplicateTrade = new grpc.web.MethodDescriptor(
  '/tradepb.OrderEntryService/DuplicateTrade',
  grpc.web.MethodType.UNARY,
  proto.tradepb.DuplicateTradeRequest,
  proto.tradepb.Empty,
  /**
   * @param {!proto.tradepb.DuplicateTradeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.tradepb.DuplicateTradeRequest,
 *   !proto.tradepb.Empty>}
 */
const methodInfo_OrderEntryService_DuplicateTrade = new grpc.web.AbstractClientBase.MethodInfo(
  proto.tradepb.Empty,
  /**
   * @param {!proto.tradepb.DuplicateTradeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.Empty.deserializeBinary
);


/**
 * @param {!proto.tradepb.DuplicateTradeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.tradepb.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tradepb.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tradepb.OrderEntryServiceClient.prototype.duplicateTrade =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tradepb.OrderEntryService/DuplicateTrade',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_DuplicateTrade,
      callback);
};


/**
 * @param {!proto.tradepb.DuplicateTradeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tradepb.Empty>}
 *     Promise that resolves to the response
 */
proto.tradepb.OrderEntryServicePromiseClient.prototype.duplicateTrade =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tradepb.OrderEntryService/DuplicateTrade',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_DuplicateTrade);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tradepb.GetTradingPrivilegeRequest,
 *   !proto.tradepb.TradingPrivilege>}
 */
const methodDescriptor_OrderEntryService_GetTradingPrivilege = new grpc.web.MethodDescriptor(
  '/tradepb.OrderEntryService/GetTradingPrivilege',
  grpc.web.MethodType.UNARY,
  proto.tradepb.GetTradingPrivilegeRequest,
  proto.tradepb.TradingPrivilege,
  /**
   * @param {!proto.tradepb.GetTradingPrivilegeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.TradingPrivilege.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.tradepb.GetTradingPrivilegeRequest,
 *   !proto.tradepb.TradingPrivilege>}
 */
const methodInfo_OrderEntryService_GetTradingPrivilege = new grpc.web.AbstractClientBase.MethodInfo(
  proto.tradepb.TradingPrivilege,
  /**
   * @param {!proto.tradepb.GetTradingPrivilegeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.TradingPrivilege.deserializeBinary
);


/**
 * @param {!proto.tradepb.GetTradingPrivilegeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.tradepb.TradingPrivilege)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tradepb.TradingPrivilege>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tradepb.OrderEntryServiceClient.prototype.getTradingPrivilege =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tradepb.OrderEntryService/GetTradingPrivilege',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_GetTradingPrivilege,
      callback);
};


/**
 * @param {!proto.tradepb.GetTradingPrivilegeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tradepb.TradingPrivilege>}
 *     Promise that resolves to the response
 */
proto.tradepb.OrderEntryServicePromiseClient.prototype.getTradingPrivilege =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tradepb.OrderEntryService/GetTradingPrivilege',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_GetTradingPrivilege);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tradepb.ListOrdersByIDRequest,
 *   !proto.tradepb.ListOrderEntryResponse>}
 */
const methodDescriptor_OrderEntryService_GetListOrdersByIDRequest = new grpc.web.MethodDescriptor(
  '/tradepb.OrderEntryService/GetListOrdersByIDRequest',
  grpc.web.MethodType.UNARY,
  proto.tradepb.ListOrdersByIDRequest,
  proto.tradepb.ListOrderEntryResponse,
  /**
   * @param {!proto.tradepb.ListOrdersByIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.ListOrderEntryResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.tradepb.ListOrdersByIDRequest,
 *   !proto.tradepb.ListOrderEntryResponse>}
 */
const methodInfo_OrderEntryService_GetListOrdersByIDRequest = new grpc.web.AbstractClientBase.MethodInfo(
  proto.tradepb.ListOrderEntryResponse,
  /**
   * @param {!proto.tradepb.ListOrdersByIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.ListOrderEntryResponse.deserializeBinary
);


/**
 * @param {!proto.tradepb.ListOrdersByIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.tradepb.ListOrderEntryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tradepb.ListOrderEntryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tradepb.OrderEntryServiceClient.prototype.getListOrdersByIDRequest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tradepb.OrderEntryService/GetListOrdersByIDRequest',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_GetListOrdersByIDRequest,
      callback);
};


/**
 * @param {!proto.tradepb.ListOrdersByIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tradepb.ListOrderEntryResponse>}
 *     Promise that resolves to the response
 */
proto.tradepb.OrderEntryServicePromiseClient.prototype.getListOrdersByIDRequest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tradepb.OrderEntryService/GetListOrdersByIDRequest',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_GetListOrdersByIDRequest);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.tradepb.ListPendingBatchOrdersRequest,
 *   !proto.tradepb.ListOrderEntryResponse>}
 */
const methodDescriptor_OrderEntryService_ListPendingBatchOrders = new grpc.web.MethodDescriptor(
  '/tradepb.OrderEntryService/ListPendingBatchOrders',
  grpc.web.MethodType.UNARY,
  proto.tradepb.ListPendingBatchOrdersRequest,
  proto.tradepb.ListOrderEntryResponse,
  /**
   * @param {!proto.tradepb.ListPendingBatchOrdersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.ListOrderEntryResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.tradepb.ListPendingBatchOrdersRequest,
 *   !proto.tradepb.ListOrderEntryResponse>}
 */
const methodInfo_OrderEntryService_ListPendingBatchOrders = new grpc.web.AbstractClientBase.MethodInfo(
  proto.tradepb.ListOrderEntryResponse,
  /**
   * @param {!proto.tradepb.ListPendingBatchOrdersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.tradepb.ListOrderEntryResponse.deserializeBinary
);


/**
 * @param {!proto.tradepb.ListPendingBatchOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.tradepb.ListOrderEntryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.tradepb.ListOrderEntryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.tradepb.OrderEntryServiceClient.prototype.listPendingBatchOrders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/tradepb.OrderEntryService/ListPendingBatchOrders',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_ListPendingBatchOrders,
      callback);
};


/**
 * @param {!proto.tradepb.ListPendingBatchOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.tradepb.ListOrderEntryResponse>}
 *     Promise that resolves to the response
 */
proto.tradepb.OrderEntryServicePromiseClient.prototype.listPendingBatchOrders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/tradepb.OrderEntryService/ListPendingBatchOrders',
      request,
      metadata || {},
      methodDescriptor_OrderEntryService_ListPendingBatchOrders);
};


module.exports = proto.tradepb;

