import React, { useEffect } from 'react';
// import OwnerModal from './OwnerModal';
import { CircularProgress, Box, IconButton } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import { notifySuccess, notifyError } from '../../../../components/Messages/Notification';
import { Visibility as ViewIcon } from '@material-ui/icons';
import Button from '../../../../components/Buttons/Button';
import ClientGrid from '../../../../components/ClientGrid/ClientGrid';
import { createNote, listNote } from '../../../../services/AccountNoteService';
import NoteModal from './NoteModal';
import { pbDateTime, pbDateTimeSorter } from '../../../../components/ClientGrid/CellRenderer';

export default function NoteTable({accountid: accountId, linkid,}) {
  // const classes = useStyles();
  const [rowData, setRowData] = React.useState({});
  const [validation, setValidation] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState({
    save: false,
  });
  const columns = [
    {
      id: 'subject',
      field: 'subject',
      label: 'Subject',
      visible: true,
    },
    {
      id: 'note',
      field: 'note',
      label: 'Note',
      width: '500px',
      visible: true,
    },
    {
      id: 'createdBy',
      field: 'createdBy',
      label: 'Created By',
      visible: true,
    },
    {
      id: 'createdAt',
      field: 'createdAt',
      label: 'Created At',
      visible: true,
      cellRenderer: pbDateTime,
      sort: pbDateTimeSorter,
    },
  ];

  const handleOpen = (data) => {
    if (!data) {
      //ADD default values
      data = {
        noteType: '',
        subject: '',
        note: '',
      };
    }

    setRowData(data);
    setOpen(true);
  };

  const handleClose = async (data, isEdit, note) => {
    if (!data) {
      setValidation({});
      setOpen(false);
      return;
    }
    data.note = note.currentTarget?.value
    data.noteType = 'call_logs'
    data.accountId = accountId
    data.linkId = linkid
    try {
      let valid = true;

      setValidation({
        noteType: !data.noteType,
        subject: !data.subject,
        note: !data.note,
      });

      if (!data.noteType) {
        notifyError('Note Type is required.');
        valid = false;
      }
      if (!data.subject) {
        notifyError('Subject is required.');
        valid = false;
      }
      if (!data.note) {
        notifyError('Note is required.');
        valid = false;
      }

      if (!valid) {
        return;
      }

      setLoading({ ...loading, save: true });

      //ADD
      data.linkId = linkid;
      const { note } = await createNote(data);
      setRows([note, ...rows]);
      notifySuccess('New note has been added.');

      setValidation({});
      setOpen(false);
    } catch (error) {
      setLoading({ ...loading, save: false });
      notifyError(error.message);
      console.error(error);
    }

    setLoading({ ...loading, save: false });
  };

  const getCsvData = async () => {
    // const data = await readOwner(searchData)
    // return data.ticketsList
  };

  async function getNotes() {
    try {
      const data = await listNote(accountId, {
        linkId: linkid,
        noteType: 'call_logs',
      });
      setRows(data.notesList);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
      getNotes();
  }, []);

  return (
    <>
      <Box component="search-c">
        <div className="grd-row nm">
          <div className="grd-cell-none">
            <Button
              type="plus"
              label="Add New Note"
              onClick={() => {
                handleOpen();
              }}
            />
          </div>
        </div>
      </Box>
      <div className="mt-20">
        <ClientGrid
          title="Notes"
          rowIdField="notesId"
          columns={columns}
          rows={rows}
          getCsvData={getCsvData}
          isLoading={loading}
          setRows={setRows}
        />
      </div>
      {open && (
        <NoteModal
          onClose={handleClose}
          open={open}
          value={rowData}
          validation={validation}
          loading={loading.save}
        ></NoteModal>
      )}
    </>
  );
}
