import React from 'react';
import {
  formatPbDate,
  formatPbDateTime,
  formatCurrency,
  formatPercentage,
  formatQty,
} from '../../lib/fmt';
import {
  dateProtoObjectToString,
  protoDatTimeObjectToDate,
} from '../../services/ConvertService';

import { CheckCircle as CheckCircleIcon, Cancel as CrossIcon } from '@material-ui/icons';
import color from '../../assets/css/colors';

const currency = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
  const v = parseFloat(value);

  return (
    <div
      style={{
        padding: '10px 20px',
        textAlign: 'right',
        width: '100%',
        height: '100%',
        fontWeight: 600,
        // backgroundColor:
        //   v === 0
        //     ? color.info.opacity
        //     : v > 0
        //     ? color.success.opacity
        //     : color.error.opacity,
        color: v === 0 ? color.info.main : v > 0 ? color.success.main : color.error.main,
      }}
    >
      {formatCurrency(value)}
    </div>
  );
};

const qty = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
  const v = parseInt(value);

  return (
    <div
      style={{
        padding: '10px 20px',
        textAlign: 'right',
        width: '100%',
        height: '100%',
        fontWeight: 600,
        // backgroundColor:
        //   v === 0
        //     ? color.info.opacity
        //     : v > 0
        //     ? color.success.opacity
        //     : color.error.opacity,
        color: v === 0 ? color.info.main : v > 0 ? color.success.main : color.error.main,
      }}
    >
      {formatQty(value)}
    </div>
  );
};

const number = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
  return (
    <div
      style={{
        padding: '10px 20px',
        textAlign: 'right',
        width: '100%',
        height: '100%',
        fontWeight: 600,
      }}
    >
      {value}
    </div>
  );
};

const percentage = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
  const v = parseFloat(value);

  return (
    <div
      style={{
        padding: '10px 20px',
        textAlign: 'right',
        width: '100%',
        height: '100%',
        fontWeight: 600,
        // backgroundColor:
        //   v === parseFloat(0)
        //     ? color.info.opacity
        //     : v > parseFloat(0)
        //     ? color.success.opacity
        //     : color.error.opacity,
        color:
          v === parseFloat(0)
            ? color.info.main
            : v > parseFloat(0)
            ? color.success.main
            : color.error.main,
      }}
    >
      {formatPercentage(value)}
    </div>
  );
};

const boolean = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      {data[column.field] === true && <CheckCircleIcon />}
      {data[column.field] != true && <CrossIcon />}
    </div>
  );
};

const pbDate = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
  return <div style={{ padding: '0px 20px' }}>{formatPbDate(data[column.field])}</div>;
};

const pbDateTime = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
  return (
    <div style={{ padding: '0px 20px' }}>{formatPbDateTime(data[column.field])}</div>
  );
};

const htmlString = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
  return (
    <div
      style={{ padding: '0px 20px' }}
      dangerouslySetInnerHTML={{ __html: data[column.field] }}
    ></div>
  );
};

const missing = ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
  return (
    <div
      style={{
        padding: '10px 20px',
        textAlign: 'right',
        width: '100%',
        height: '100%',
        fontWeight: 600,
        textAlign: 'right',
      }}
    >
      {value}
    </div>
  );
};

const dateTimeString = ({
  tableManager,
  value,
  field,
  data,
  column,
  colIndex,
  rowIndex,
}, format) => {
  const year = value.substring(0, 4);
  const month = value.substring(4, 6);
  const day = value.substring(6, 8);

  let formatString = year + format + month + format + day;
  
  return (
    <div
      style={{
        padding: '10px 20px',
        textAlign: 'right',
        width: '100%',
        height: '100%',
        fontWeight: 600,
        textAlign: 'right',
      }}
    >
      {formatString}
    </div>
  );
};
const pbDateSorter = ({ a, b, isAscending }) => {
  let first = dateProtoObjectToString(a),
    second = dateProtoObjectToString(b);
  let aa = first.split('-').reverse().join(),
    bb = second.split('-').reverse().join();
  return aa < bb ? (isAscending ? -1 : 1) : aa > bb ? (isAscending ? 1 : -1) : 0;
};

const pbDateTimeSorter = ({ a, b, isAscending }) => {
  let first = protoDatTimeObjectToDate(a),
    second = protoDatTimeObjectToDate(b);
  return first < second
    ? isAscending
      ? -1
      : 1
    : first > second
    ? isAscending
      ? 1
      : -1
    : 0;
};

export {
  currency,
  qty,
  number,
  percentage,
  boolean,
  pbDate,
  missing,
  dateTimeString,
  pbDateSorter,
  pbDateTime,
  htmlString,
  pbDateTimeSorter,
};
