import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(() => ({
  textField: {
    '& input': {
      color: '#222222 !important',
    },
  },
}));

export default function AutoCompleteFilter(props) {
  const classes = useStyles();
  //const options = props.options.map((v) => v?.toString());

  const [value, setValue] = React.useState(props.value ? props.value : '');
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    if (props.value === '' || props.value === undefined) {
      setPropsValue('');
      setInputValue('');
      setValue('');
    }
    // eslint-disable-next-line
  }, [props.value]);

  const setPropsValue = (value) => {
    props.onChange({
      currentTarget: {
        name: props.name,
        value: value,
      },
    });
  };

  return (
    <div>
      <Autocomplete
        freeSolo={props.freeSolo}
        value={value}
        onChange={(_, value) => {
          setValue(value);
          setPropsValue(value ? value : '');
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue.toUpperCase());
        }}
        getOptionLabel={(option) => (option === undefined ? '' : option)}
        getOptionSelected={(option, value) => {
          return option === value;
        }}
        onOpen={(e) => {
          setOptions(props.options.map((v) => v?.toString()));
        }}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.textField}
            label={props.label}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </div>
  );
}
