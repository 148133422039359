import React from 'react';
import { Typography } from '@material-ui/core';
import color from '../../assets/css/colors';

const Text = (props) => {
    return (
        <Typography
            style={{
                marginTop: props.mt ? props.mt : 0, 
                marginBottom: props.mb ? props.mb : 0,
                color: props.error ? color.error.main : props.color? props.color:color.default.main,
                marginLeft: props.ml ? props.ml : 0, 
                marginRight: props.mr ? props.mr : 0, 
                display: props.display ? props.display : 'block',
                fontStyle: props.fontStyle ? props.fontStyle : 'regular',
                align: props.align ? props.align : 'left',
            }}
            className={props.className}
            variant={props.variant}
            align={props.align}
            paragraph={props.paragraph}
        >
            {props.label} {props.required ? (<span style={{color: color.error.main}}>*</span>) : ''}
        </Typography>
    );
}
export default Text;