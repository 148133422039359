import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import color from '../../assets/css/colors'
import fontFamily from '../../assets/css/fonts'
import { Modal, Backdrop, Fade , Tooltip, IconButton, Checkbox} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check';
import ClientGrid from '../ClientGrid/ClientGrid'
import Button from '../Buttons/Button'
import CloseButton from '../Buttons/CloseButton'
import AutoCompleteAccountNo from '../AutoComplete/AutoCompleteAccountNo'
import AutoCompleteCorrespondent from '../AutoComplete/AutoCompleteCorrespondent'
import SelectSystemCode from '../Dropdown/SelectSystemCode'
import Text from '../Typography/Text'
import TextField from '../Textfields/TextField'
// import DigitalCashRequestModal from './Components/DigitalCashRequestModal'
import WalletAddressBookModal from '../Modals/WalletAddressBookModal'
import {
  notifyInfo,
  notifyError,
} from '../Messages/Notification'
import { walletAddressBookColumns } from '../../lib/data/columns'
import {
  listWalletAddressBook,
} from '../../services/WalletAddressBookService'
import QueryParam from '../../services/QueryParamService'

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
}))

export default function WalletAddressBookTable({
  isOpen,
  onClose,
  value
}) {
  const classes = useStyles()
  const [rows, setRows] = React.useState([])
  const [selectedRow, setSelectedRow] = React.useState({})
  const [open, setOpen] = React.useState({
    add: false,
    summary: false,
  })

  const [loading, setLoading] = React.useState({
    search: false,
    save: false,
    set: false,
  })

  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      nickName: '',
      correspondent: value.correspondent || '',
      accountNo:  value.accountNo || '',
      status: 'Active',
    }),
  )

  const GridButtons = ({
    tableManager,
    value,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return (
      <div>
        <Tooltip title="Select" arrow>
          <div>
            <IconButton
              aria-label="select wallet address book"
              onClick={() => {
                onClose(data);
              }}
            >
              <CheckIcon />
            </IconButton>
          </div>
        </Tooltip>
      </div>
    )
  }

  const columns = [
    {
      id: '',
      label: '',
      width: '40px',
      cellRenderer: GridButtons,
    },
    ...walletAddressBookColumns,
  ]

  const handleSearchDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target

    setSearchData({ ...searchData, [input.name]: input.value })
  }

  const handleSearch = async () => {
    try {
      setLoading({ ...loading, search: true })

      QueryParam.set(searchData)

      const data = await listWalletAddressBook(searchData)

      setRows(data.walletAddressBooksList)

      notifyInfo(
        data.walletAddressBooksList.length > 0
          ? data.walletAddressBooksList.length + ' search result(s)'
          : 'No records found.',
      )
    } catch (error) {
      notifyError(error.message)
      console.log(error)
      return
    } finally {
      setLoading({ ...loading, search: false })
    }
  };

  const getCsvData = async () => {
    const data = await listWalletAddressBook(searchData)

    return data.walletAddressBooksList
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => onClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div style={{ width: 800 }}>
          <div className={classes.modalHeader}>
            <div>
              <Text
                mt={4}
                variant="h2"
                label="Select Wallet Address Book"
              />
            </div>
            <div className={classes.closeButton}>
              <CloseButton onClick={() => onClose()} />
            </div>
          </div>
          <div className={classes.modalBody}>
            <div className="grd-row nm">
              <div className="grd-cell-none">
                <Button
                  disabled={loading.search}
                  loading={loading.search}
                  type="search"
                  label={loading.search ? 'Searching...' : 'Search'}
                  onClick={handleSearch}
                />
              </div>
            </div>
            <div className="grd-row">
              <div className="grd-cell">
                <TextField
                  name="nickName"
                  label="Nickname"
                  placeholder="Nickname"
                  type="text"
                  value={searchData.nickName}
                  onChange={handleSearchDataChange}
                />
              </div>
              <div className="grd-cell">
                <AutoCompleteCorrespondent
                  isAccessibleOnly={true}
                  name="correspondent"
                  value={searchData.correspondent}
                  onChange={handleSearchDataChange}
                />
              </div>
              <div className="grd-cell">
                <AutoCompleteAccountNo
                  isAccessibleOnly={true}
                  name="accountNo"
                  value={searchData.accountNo}
                  correspondent={searchData.correspondent}
                  onChange={handleSearchDataChange}
                />
              </div>
            </div>
            <div className="mt-20 grd-row">
              <ClientGrid
                title="Wallet Address Book"
                rowIdField="walletAddressBookId"
                columns={columns}
                rows={rows}
                getCsvData={getCsvData}
                isLoading={loading.search}
                setRows={setRows}
              />
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}
