import {
  ActivityServiceClient,
  ListActivityRequest,
} from "../proto/reportpb/activity_grpc_web_pb";
import { stringToProtoDate } from "./ConvertService";
import authSvc from './AuthService';
import { auth } from "../lib/auth/Auth";
import { Pagination } from '../proto/utilspb/pagination_pb';

const service = new ActivityServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });

const userId = authSvc.getCurrentUser()?.UserId;

export async function listActivity(param, paging) {
  return new Promise((resolve, reject) => {
    let req = new ListActivityRequest();
    req.setFromDate(stringToProtoDate(param.fromDate));
    req.setToDate(stringToProtoDate(param.toDate));
    req.setBranch(param.branch);
    req.setAccountNo(param.accountNo);
    req.setMasterAccountNo(param.masterAccountNo);
    req.setRep(param.rep);
    // req.setAccountName(param.accountName);
    req.setSymbol(param.symbol);
    req.setStatus(param.status);
    req.setEntryType(param.entryType);
    req.setSide(param.side);
    // req.setUsrId(userId);
    // req.setCompress(param.compress);
    req.setCorrespondent(param.correspondent);
    req.setDateType(param.dateType);

    if (paging) {
      let pg = new Pagination();
      pg.setPageSize(paging.pageSize);
      pg.setPageNo(paging.pageNo);
      pg.setSortName(paging.sortName);
      pg.setSortDirection(paging.sortDirection);
      pg.setFilterNamesList(paging.filterNames);
      pg.setFilterValuesList(paging.filterValues);
      req.setPagination(pg);
    }
    
    service.listActivity(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
