import { dateFormat } from "../format/format";
import {  notifyError } from "../../components/Messages/Notification"

const validateEmail = (email) => {
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9-]*$/i.test(email)) {
        return true
    }
    return false;
};

const validatePassword = (password) => {
    const regex = {
      hasNumber: /\d/,
      hasWhiteSpace: /\s/,
      hasSpecialChars: /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/,
      hasLower: /[a-z]/,
      hasUpper: /[A-Z]/,
    };

    const valid = {
      err: false,
      msg: '',
    };

    if (!password) {
      return {
        err: true,
        msg: 'Required field.',
      };
    }
    if (!regex.hasLower.test(password)) {
      return {
        err: true,
        msg: 'At least one lowercase letter [a-z].',
      };
    }
    if (!regex.hasUpper.test(password)) {
      return {
        err: true,
        msg: 'At least one uppercase letter [A-Z].',
      };
    }
    if (!regex.hasSpecialChars.test(password)) {
      return {
        err: true,
        msg: 'Allowed special characters: !@#$%-_.',
      };
    }
    if (!regex.hasNumber.test(password)) {
      return {
        err: true,
        msg: 'At least one number.',
      };
    }
    if (password.length < 8) {
      return {
        err: true,
        msg: 'Minimum of 8 characters.',
      };
    }
    if (regex.hasWhiteSpace.test(password)) {
      return {
        err: true,
        msg: 'Cannot contain white space.',
      };
    }

    return valid;
};

const validateDateOfBirth = (date) => {
    let splitedDate = date.split("-");
    let dateOfBirth = new Date(splitedDate[1] + "/" + splitedDate[2] + "/" + splitedDate[0]);
    let currentDate = new Date();
   
    if (currentDate.getFullYear() - dateOfBirth.getFullYear() < 18) {
        return false;
    }

    if (currentDate.getFullYear() - dateOfBirth.getFullYear() === 18) {
        if (currentDate.getMonth() < dateOfBirth.getMonth()) {
            return false;
        }
        if (currentDate.getMonth() === dateOfBirth.getMonth()) {
            if (currentDate.getDate() < dateOfBirth.getDate()) {
                return false;
            }
        }
    }

    return true;
}

const removeDuplicate = (array, key) => {
    return [...new Map(array.map(item => [item[key], item])).values()]
}

const validateExpirationDate = (expirationdate) => {
  const cDate = new Date();
  const eDate = new Date(expirationdate);

  if (!expirationdate) {
    return {
      err: true,
      msg: 'Required'
    }
  }

  if (dateFormat(cDate) === dateFormat(eDate)){
    return {
      err: true,
      msg: 'Same day is not allowed.'
    }
  }

  if (eDate.getTime() < cDate.getTime()) {
    return {
      err: true,
      msg: 'Expired ID is not allowed.'
    }
  }

  return {
    err: false,
    msg: ''
  }
}

const validateMobileNumber = (mobileNo) => {

  const splittedMobileNo = mobileNo.split(' ');
  const mobileOrigin = splittedMobileNo[0];

  if (!mobileNo) {
    return {
      err: true,
      msg: 'Required'
    }
  }

  //US
  if (mobileOrigin === '+1' && mobileNo.length < 17) {
    return {
      err: true,
      msg: 'Invalid phone number'
    }
  }

  //PH
  if (mobileOrigin === '+63' && mobileNo.length < 15) {
    return {
      err: true,
      msg: 'Invalid phone number'
    }
  }

  return {
    err: false,
    msg: ''
  }
}

const validateSSNNo = (ssnNo) => {
  if (!ssnNo) {
    return {
      err: true,
      msg: 'Required',
      toolTip: ''
    }
  }

  let ssnNoWithoutHyphen = ssnNo.replaceAll("-", "").replaceAll("_","");
	let ssnNoWithoutHyphenMessage = 'SSN should have 9 digits.'
  if(ssnNoWithoutHyphen.length != 9){
    return {
      err: true,
      msg: ssnNoWithoutHyphenMessage
    }
  }

  const sssNoFirstThree = ssnNo.substring(0,3);
	const sssNoFirstThreeMessage = 'The first part should have 3 digits and should not be 000, 666, or between 900 and 999.'
  const ssnNoInt = parseInt(sssNoFirstThree)
  if((["000", "666", "900", "999"].includes(sssNoFirstThree)) || (ssnNoInt > 900 && ssnNoInt < 999)){
    return {
      err: true,
      msg: "Invalid Format",
      toolTip: sssNoFirstThreeMessage
    }
  }

  return {
    err: false,
    msg: '',
    toolTip: '',
  }
}

const validateDuplicateName = (coName1, coName2) => {
  const ownerName1 = coName1.toLowerCase().replaceAll(' ', '')
  const ownerName2 = coName2.toLowerCase().replaceAll(' ', '')
  if (ownerName1 === ownerName2) {
    notifyError('Names of owner cannot be duplicated')
    return {
      err: true,
      msg: 'Names of owner cannot be duplicated'
    }
  }
  return {
    err: false,
    msg: ''
  }
}

const validateOwnerPercentage = (ownerPercentage) => {
  if(ownerPercentage > 100){
    notifyError('Total owner percentage cannot exceed 100%')
    return {
      err: true,
      msg: 'Total owner percentage cannot exceed 100%'
    }
  }
  return {
    err: false,
    msg: ''
  }
}

export { 
  validateEmail, 
  validatePassword, 
  validateDateOfBirth, 
  removeDuplicate, 
  validateExpirationDate, 
  validateMobileNumber,
  validateSSNNo,
  validateDuplicateName,
  validateOwnerPercentage,
};