import React from 'react';
import SocialMediaButton from '../../components/Buttons/SocialMediaButton';

const SocialMediaButtons = (props) => {
    
    return (
        <div className="social-links">
            <SocialMediaButton
                transition={props.transition}
                tooltipPlacement={props.tooltipPlacement}
                tooltip="Facebook"
                type="facebook"
                url="facebook.com"
                delay={400}
            />
            <SocialMediaButton
                transition={props.transition}
                tooltipPlacement={props.tooltipPlacement}
                tooltip="Twitter"
                type="twitter"
                url="twitter.com"
                delay={300}
            />
            <SocialMediaButton
                transition={props.transition}
                tooltipPlacement={props.tooltipPlacement}
                tooltip="LinkedIn"
                type="linkedin"
                url="linkedin.com"
                delay={200}
            />
            <SocialMediaButton
                transition={props.transition}
                tooltipPlacement={props.tooltipPlacement}
                tooltip="Email"
                type="email"
                url="gmail.com"
                delay={100}
            />
            <SocialMediaButton
                transition={props.transition}
                tooltipPlacement={props.tooltipPlacement}
                tooltip="Website"
                type="website"
                url="clientwebsite.com"
                delay={0}
            />
        </div>
    );
}
export default SocialMediaButtons;