import moment from 'moment'

const dateFormat = (date) => {
    return moment(new Date(date)).format('MM/DD/YYYY');
}

const dateFieldFormat = (date) => {
    return moment(new Date(date)).format('YYYY-MM-DD');
}

export { dateFormat, dateFieldFormat }