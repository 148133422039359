import React, { useState, useEffect } from 'react'
import lodash from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Modal, Backdrop, Fade, LinearProgress } from '@material-ui/core'
import { CSVLink } from 'react-csv'
import Text from '../Typography/Text'
import CloseButton from '../Buttons/CloseButton'
import fontFamily from '../../assets/css/fonts'
import color from '../../assets/css/colors'
import { formatPbDate, formatPbDateTime } from '../../lib/fmt'

const formatHeader = (data) => {
  if (!data.length) return []

  return Object.keys(data[0]).map((key) => lodash.startCase(key))
}

const formatData = (data) => {
  if (!data.length) return []

  const keys = Object.keys(data[0])
  const formatted = data.map((d) => {
    return keys.map((k) => {
      const cellValue = d[k]

      if (!cellValue) return ''

      // date object
      if (cellValue.year) {
        return formatPbDate(cellValue)
      }

      // datetime object
      if (cellValue.seconds) {
        return formatPbDateTime(cellValue)
      }

      return cellValue
    })
  })

  return formatted
}

export default function CsvDownload({
  onClose: handleClose,
  open: isOpen,
  title,
  filename,
  getData,
}) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState([])

  useEffect(() => {
    ;(async () => {
      try {
        const data = await getData()

        setLoading(false)
        setData(formatData(data))
        setHeaders(formatHeader(data))
      } catch (error) {
        setLoading(false)
      }
    })()
    // eslint-disable-next-line
  }, [])

  const useStyles = makeStyles(() => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: 550,
      width: '100%',
      margin: '0 auto',
      float: 'clear',
      fontFamily: fontFamily.label,
      fontSize: 14,
      color: color.default.main,
    },
    modalHeader: {
      backgroundColor: 'white',
      padding: 25,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      display: 'flex',
      '& div:nth-child(1)': {
        flex: 1,
      },
      '& div:nth-child(2)': {
        flex: 'none',
        '& .MuiIconButton-root': {
          borderRadius: 5,
          padding: 10,
          '&:hover svg path': {
            fill: color.primary.main,
          },
        },
        '& svg:last-child': {
          width: 15,
          height: 15,
        },
      },
    },
    modalBody: {
      backgroundColor: 'white',
      padding: '0px 25px 25px 25px',
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      '& p': {
        fontSize: 16,
        lineHeight: '24px',
      },
      '& .MuiLinearProgress-root': {
        marginTop: 5,
      },
      '& .MuiLinearProgress-colorPrimary': {
        backgroundColor: color.primary.light,
      },
      '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: color.primary.main,
      },
    },
    closeButton: {
      '& button': {
        marginTop: '-5px !important',
      },
    },
    downloadButton: {
      marginTop: 20,
      padding: 13,
      background: data.length > 0 ? color.primary.main : '#dddddd',
      color: data.length > 0 ? '#ffffff' : '#909090',
      cursor: data.length > 0 ? 'pointer' : 'not-allowed',
      pointerEvents: data.length > 0 ? 'auto' : 'none',
      display: 'block',
      fontWeight: 700,
      borderRadius: 4,
      textAlign: 'center',
      textDecoration: 'none',
      '&:hover': {
        background: data.length > 0 ? color.primary.light : '#dddddd',
      },
      '&:active': {
        background: data.length > 0 ? color.primary.light : '#dddddd',
      },
    },
  }))

  const classes = useStyles()

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={() => {
        handleClose()
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 100 }}
    >
      <Fade in={isOpen}>
        <div style={{ width: 350 }}>
          <div className={classes.modalHeader}>
            <div>
              <Text mt={4} variant="h2" label="Download" />
            </div>
            <div className={classes.closeButton}>
              <CloseButton
                onClick={() => {
                  handleClose()
                }}
              />
            </div>
          </div>
          <div className={classes.modalBody}>
            {loading ? (
              <div>
                Getting {title} data...
                <LinearProgress />
              </div>
            ) : (
              <div>
                <div style={{ lineHeight: '22px' }}>
                  ({data.length}) data found in {title} grid.
                </div>
                <CSVLink
                  id="csvDL"
                  className={classes.downloadButton}
                  data={data}
                  filename={filename}
                  target="_blank"
                  headers={headers}
                >
                  Download Data
                </CSVLink>
              </div>
            )}
          </div>
        </div>
      </Fade>
    </Modal>
  )
}
