import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List as MUIList, ListItem as MUIListItem, ListItemIcon, ListItemText, Collapse, makeStyles } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';

const itemStyles = (theme) => ({
    root: {

    }
});

class APWListItem extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { id, children, classes, button, ...otherProps } = this.props;

        return (
            <MUIListItem button={button} dense className={classes.root} {...otherProps}>
                {children}
            </MUIListItem>
        );
    }
}

APWListItem.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    button: PropTypes.bool
};

APWListItem.defaultProps = {
    button: false
};

/************/

const styles = (theme) => ({
    root: {

    }
});

class APWList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { id,children, classes, ...otherProps } = this.props;

        return (
            <MUIList id={id} dense disablePadding className={classes.root}  {...otherProps}>
                {React.Children.map(children, (child, index) => (
                    React.cloneElement(child, { 
                        key: index, 
                        id: `${id}_item${index}`,
                    })
                ))}
            </MUIList>
        );
    }
}

APWList.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

APWList.defaultProps = {};
APWList.Item = withStyles(itemStyles, { withTheme: true, name: 'APWListItem' })(APWListItem);

export default withStyles(styles, { withTheme: true, name: 'APWList' })(APWList);
