import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    textFieldWrapper: {
        display: 'flex',
        position: 'relative',
        '& .MuiInputBase-root': {
            backgroundColor: 'unset',
            '& .Mui-focused': {
                borderColor: '#E5E5E8',
            }
        }
    }
}));

const Textfield = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.textFieldWrapper}>
            <TextField
                style={{marginTop: props.mt ? 20 : 0, marginBottom: props.mb ? props.mb : 0}}
                fullWidth={true}
                autoFocus={props.autoFocus}
                error={props.error}
                required={props.required}
                disabled={props.disabled}
                id={props.id}
                name={props.name}
                label={props.label}
                placeholder={props.placeholder}
                multiline={props.multiline}
                rows={props.rows}
                value={props.value || ''}
                onChange={props.onChange}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                InputLabelProps={{ 
                    shrink: true, 
                    style: { 
                        color: "#4A5560",
                        fontWeight: '400',
                        position: 'absolute',
                        top: '1em',
                        zIndex: '1',
                        left: '1em',
                        padding: '0 5px',
                        lineHeight: 'unset !important',
                        backgroundColor: '#EBEBEB',
                    },
                }}
                onInput={props.onInput}
                inputProps={{ 
                    readOnly: props.readOnly ? true : false,
                    maxLength: props.max,
                    max: props.inputProps? props.inputProps.max : null,
                    min: props.inputProps? props.inputProps.min : null,
                    type: props.type,
                    autoComplete: 'off',
                    style: {
                        color: "#4A5560",
                        borderColor: '#E5E5E8',
                        fontWeight: '400',
                    }
                }}
            />
        </div>
    );
}
export default Textfield;