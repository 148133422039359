import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ReactComponent as Success} from '../../assets/icons/check-circle.svg';
import {ReactComponent as Info} from '../../assets/icons/info.svg';
import {ReactComponent as Warning} from '../../assets/icons/warning.svg';
import {ReactComponent as Error} from '../../assets/icons/error.svg';

function Notification() {
    return (
        <ToastContainer
            position="top-center"
            autoClose={5000}
            limit={0}
            newestOnTop={false}
            rtl={false}
            hideProgressBar
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    )
}

function notifySuccess(msg) {
    const options = {
        icon: <Success/>
    };

    return toast.success(msg, options);
}

function notifyInfo(msg) {
    const options = {
        icon: <Info/>
    };

    return toast.info(msg, options);
}

function notifyWarning(msg) {
    const options = {
        icon: <Warning/>
    };

    return toast.warn(msg, options);
}

function notifyError(msg) {
    const options = {
        icon: <Error/>
    };

    return toast.error(msg, options);
}

export { notifySuccess, notifyInfo, notifyWarning, notifyError, Notification }