import PropTypes from 'prop-types';
import { Box as MUIBox } from '@material-ui/core';

function APWTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`apw-tabpanel-${index}`}
            aria-labelledby={`apw-tab-${index}`}
            {...other}
        >
            {value === index && (
                <MUIBox p={1}>
                    {children}
                </MUIBox>
            )}
        </div>
    );
}

APWTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default APWTabPanel