import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Slide, IconButton, Tooltip } from '@material-ui/core';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';

const useStyles = makeStyles(() => ({
  searchField: {
    margin: '0px 10px',
    width: 400,
  },
  buttonLabel: {
    fontSize: 15,
    fontWeight: 600,
    marginLeft: 5,
  }
}));

export default function Search(props) {
  const classes = useStyles();

  const { searchText, setSearchText } = props.searchApi;

  const [checked, setChecked] = React.useState(false);

  const handleExpand = () => {
    setChecked((prev) => !prev);
  };

  return (
    <>
      <Tooltip arrow title="Search">
        <IconButton disableRipple onClick={handleExpand}>
          <SearchRoundedIcon />
        </IconButton>
      </Tooltip>
      <Slide direction="up" in={checked} mountOnEnter unmountOnExit>
        <TextField
          className={classes.searchField}
          name="my-search"
          placeholder="Search here"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
      </Slide>
    </>
  );
}
