import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import { Tooltip } from '@material-ui/core';
import color from '../../assets/css/colors';
import fontFamily from '../../assets/css/fonts';
import {ReactComponent as Info} from '../../assets/icons/info.svg';
import Text from '../Typography/Text';

const useStyles = makeStyles(() => ({
    maskedFieldWrapper: {
        position: 'relative',
    },
    inputFieldWrapper: {
        color: color.default.main,
        background: color.light.opacity,
        border: '1px solid ' + color.gray.opacity,
        borderRadius: 7,
        fontSize: 14,
        fontFamily: fontFamily.label,
        fontWeight: 600,
        lineHeight: '1.1876em',
        padding: '14px 15px 15px 14px',
        marginTop: 28,
        width: '100%',
        boxSizing: 'border-box',
        '&:focus': {
            border: '1px solid ' + color.primary.light + ' !important',
            outline: 'unset',
        },
        '&:focus + h6': {
            color: color.primary.main,
        },
        '&::placeholder': {
            color: '#b3b4bd',
        },
    },
    numberLabel: {
        fontWeight: 600,
        color: color.default.main,
        lineHeight: '17px',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        width: '100%',
        marginBottom: 10,
        '& h6': {
            fontWeight: 600,
            color: color.default.main,
            lineHeight: '17px',
        },
        '& .grd-cell-none > h6': {
            fontSize: 13,
            fontStyle: 'italic',
            lineHeight: '19px',
        }
    },
    tooltipWrapper: {
        position: 'absolute',
        top: 31,
        right: 1,
        padding: '9px 10px',
        backgroundColor: color.light.opacity,
        borderRadius: 5,
        '& svg': {
            width: 21,
            height: 21,
            cursor: 'help',
            '& path': {
                fill: color.primary.main,
            }
        },
    }
}));

const HtmlTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: color.light.main,
      maxWidth: 620,
      border: '1px solid ' + color.light.light,
      borderRadius: 10,
    },
}))(Tooltip);

const MaskedField = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.maskedFieldWrapper} style={{ marginTop: props.mt ? 20 : 0 }}>
            <NumberFormat
                className={classes.inputFieldWrapper}
                placeholder={props.placeholder}
                value={props.value}
                onValueChange={(v) => props.onChange({ currentTarget: { name: props.name, value: v.value, formattedValue: v.formattedValue }})}
                displayType="input"
                type="text"
                format={props.format}
                mask="_"
                disabled={props.disabled}
            />
            <div className={classes.numberLabel}>
                <div className="grd-cell">
                    <Text
                        required={props.required}
                        error={props.error}
                        variant="subtitle2"
                        label={props.label}
                    />
                </div>
                {props.error && (
                    <div className="grd-cell-none">
                        <Text
                            error={props.error}
                            variant="subtitle1"
                            label={props.msg}
                        />
                    </div>
                )}
            </div>
            {(props.tooltip!== "" && (props.tooltip || props.htmlTooltip)) && (
                <div className={classes.tooltipWrapper} style={{marginTop: props.mt ? 20 : 0}}>
                    {props.tooltip ? (
                        <Tooltip 
                            arrow
                            placement="top"
                            title={props.tooltip}
                        >
                            <Info />
                        </Tooltip>
                    ) : (
                        <HtmlTooltip 
                            placement="right"
                            title={<React.Fragment>{props.htmlTooltip}</React.Fragment>}
                        >
                            <Info />
                        </HtmlTooltip> 
                    )}
                </div>
            )}
        </div>
    );
}
export default MaskedField;