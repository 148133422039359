import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Box,
  Modal,
  Backdrop,
  Fade,
  CircularProgress,
} from '@material-ui/core';
import { CSVLink } from 'react-csv';
import { notifyError } from '../Messages/Notification';
import useStyles from '../../styles';

export default function CsvDownload({
  onClose: handleClose,
  open: isOpen,
  filename,
  getData,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const data = await getData();

        for (let i = 0; i < data.length; i++) {
          let batchNo = data[i].batchNo === '<nil>' ? '' : data[i].batchNo;

          if (data[i].batchNo !== undefined) {
            data[i].batchNo = batchNo;
          }
        }

        const dataHeader = [];

        for (let k in data[0]) {
          if (k !== 'transactionFeesList') {
            const properCase = k?.replace(
              /^[a-z]|[A-Z]/g,
              (c, i) => (i ? ' ' : '') + c.toUpperCase()
            );

            dataHeader.push({ label: properCase, key: k });
          }
        }

        setHeaders(dataHeader);
        setData(data);
        setLoading(false);
      } catch (error) {
        notifyError(error.message);
        setLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalBackdrop}
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 100 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalLg} style={{ width: 500 }}>
          <Typography
            id="transition-modal-title"
            variant="h4"
            className={classes.textBold}
            gutterBottom
          >
            CSV Download
          </Typography>
          <Box mt={2}>
            {loading ? (
              <div>
                <CircularProgress color="inherit" size={20} /> Getting data...
              </div>
            ) : (
              <div>
                <div>
                  {data.length > 0
                    ? '(' + data.length + ')' + ' Data found.'
                    : 'No data found.'}
                </div>
                {data.length > 0 && (
                  <CSVLink
                    id="csvDL"
                    data={data}
                    headers={headers}
                    filename={filename}
                    target="_blank"
                  >
                    Download {filename}
                  </CSVLink>
                )}
              </div>
            )}

            <div className={classes.modalFooter}>
              <div className={classes.grdCellNone}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
