import {
  Address,
  AddressServiceClient,
  DeleteAddressRequest,
  BatchCreateAddressRequest,
} from '../proto/accountpb/address_grpc_web_pb';
import { auth } from '../lib/auth/Auth';

const service = new AddressServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });

export async function createAddress(param) {
  return new Promise((resolve, reject) => {
    const req = paramToAddress(param);
    service.createAddress(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateAddress(param) {
  return new Promise((resolve, reject) => {
    const req = paramToAddress(param);

    service.updateAddress(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function deleteAddress(id) {
  return new Promise((resolve, reject) => {
    const req = new DeleteAddressRequest();
    req.setAccountId(id);

    service.deleteAddress(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

async function executeBatchCreateAddress(req) {
  return new Promise((resolve, reject) => {
    service.batchCreateAddress(req, {}, (error, response) => {
      if (error) {
        console.log(error);
        reject(error);
      } else {
        resolve(response.toObject());
        return response.toObject();
      }
    });
  });
}

export async function batchCreateAddress(param) {
  let listReq = requestAddressParameter(param);
  let batchReq = new BatchCreateAddressRequest();
  batchReq.setAddressList(listReq);
  try {
    const res = await executeBatchCreateAddress(batchReq);
    return res.addressResponsesList;
  } catch (err) {
    console.log(err);
    return err;
  }
}

const requestAddressParameter = (list) => {
  let listReq = [];

  list.forEach(async (row) => {
    let req = paramToAddress(row);
    req.setAddressType(row.addressType);
    req.setAccountId(row.accountId);
    req.setAccountNo(row.accountNo);
    req.setCorrespondent(row.correspondent);
    req.setAddressId(row.addressId);
    req.setStatus(row.status);
    req.setAddress1(row.address1);
    req.setAddress2(row.address2);
    req.setAddress3(row.address3);
    req.setCity(row.city);
    req.setState(row.state);
    req.setZip(row.zipCode);
    req.setCountry(row.country);
    req.setCreatedBy(row.createdBy);

    listReq.push(req);
  });

  return listReq;
};

const paramToAddress = (param) => {
  let req = new Address();
  req.setAddressId(param.addressId);
  req.setAddressType(param.addressType);
  req.setOwnerId(param.ownerId);
  req.setAccountId(param.accountId);
  req.setAccountNo(param.accountNo);
  req.setCorrespondent(param.correspondent);
  req.setAddressId(param.addressId);
  req.setStatus(param.status);
  req.setAddress1(param.address1);
  req.setAddress2(param.address2);
  req.setAddress3(param.address3);
  req.setCity(param.city);
  req.setState(param.state);
  req.setZip(param.zipCode);
  req.setCountry(param.country);
  req.setCreatedBy(param.createdBy);
  req.setImgProofOfAddress(param.imgProofOfAddress);

  return req;
};

export async function listAddress(param) {
  return new Promise((resolve, reject) => {
    const req = new Address();
    req.setAccountId(param.accountId);
    req.setOwnerId(param.ownerId);

    service.listAddress(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
