import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import APWList from '../../List/APWList';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Divider from '@mui/material/Divider';
import Link from '@material-ui/core/Link';
import APWTabs from '../../Tabs/APWTabs';

const styles = (theme) => ({

});


class NotificationMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
    };
    this.tabsRef = React.createRef();
  }


  setValue = (index) => {
    this.setState((prevState) => ({
      ...prevState,
      value: index,
    }));
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  // Lifecycle method: componentDidMount
  componentDidMount() {
    console.log('Component did mount');
  }

  // Lifecycle method: componentDidUpdate
  componentDidUpdate(prevProps, prevState) {
    console.log('Component did update');

    if (!prevProps.show && this.state.value != 0) {
      this.setValue(0)
    }


  }

  // Lifecycle method: componentWillUnmount
  componentWillUnmount() {
    console.log('Component will unmount');
  }

  render() {
    const { classes, show } = this.props;
    const { value } = this.state;

    return (
      <React.Fragment>
        <APWTabs
          id="menu-notif-tab"
          value={this.state.value}
          onChange={this.handleChange}
        >
          <APWTabs.Tab label="Alerts(3)" index={0} />
          <APWTabs.Tab label="Messages(3)" index={1} />
          <APWTabs.Tab label="Announcements" index={2} />
        </APWTabs>
        <APWTabs.TabPanel value={this.state.value} index={0}>
        <APWList id="notification-alerts-tab">
            <APWList.Item>
              <ListItemIcon>
                <FiberManualRecordIcon edge="end" color='secondary' fontSize='small' />
              </ListItemIcon>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography variant="body2" color="textSecondary" component="span">
                      2023-11-12
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      Insufficient Funds
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Typography variant="caption" color="textSecondary" component="span">
                      Insufficient funds for periodic distribution - 12133r245
                    </Typography>
                  </React.Fragment>
                }
              />
            </APWList.Item>
            <Divider variant="fullWidth" />
            <APWList.Item>
              <ListItemIcon>
                <FiberManualRecordIcon edge="end" color='secondary' fontSize='small' />
              </ListItemIcon>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography variant="body2" color="textSecondary" component="span">
                      2023-11-12
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      Insufficient Funds
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Typography variant="caption" color="textSecondary" component="span">
                      Insufficient funds for periodic distribution - 12133r245
                    </Typography>
                  </React.Fragment>
                }
              />
            </APWList.Item>
            <Divider variant="fullWidth" />
            <APWList.Item>
              <ListItemIcon>
                <FiberManualRecordIcon edge="end" color='secondary' fontSize='small' />
              </ListItemIcon>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography variant="body2" color="textSecondary" component="span">
                      2023-11-12
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      Insufficient Funds
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Typography variant="caption" color="textSecondary" component="span">
                      Insufficient funds for periodic distribution - 12133r245
                    </Typography>
                  </React.Fragment>
                }
              />
            </APWList.Item>
            <Divider variant="fullWidth" />
          </APWList>
          <Box mt={2} display="flex"
          justifyContent="center">
          <Link href={null}> {"View All Alerts"}</Link>
        </Box>
        </APWTabs.TabPanel>
        <APWTabs.TabPanel value={this.state.value} index={1}>
        <APWList id="notification-alerts-tab">
            <APWList.Item>
              <ListItemIcon>
                <FiberManualRecordIcon edge="end" color='secondary' fontSize='small' />
              </ListItemIcon>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography variant="body2" color="textSecondary" component="span">
                      2023-11-12
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      Insufficient Funds
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Typography variant="caption" color="textSecondary" component="span">
                      Insufficient funds for periodic distribution - 12133r245
                    </Typography>
                  </React.Fragment>
                }
              />
            </APWList.Item>
            <Divider variant="fullWidth" />
            <APWList.Item>
              <ListItemIcon>
                <FiberManualRecordIcon edge="end" color='secondary' fontSize='small' />
              </ListItemIcon>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography variant="body2" color="textSecondary" component="span">
                      2023-11-12
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      Insufficient Funds
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Typography variant="caption" color="textSecondary" component="span">
                      Insufficient funds for periodic distribution - 12133r245
                    </Typography>
                  </React.Fragment>
                }
              />
            </APWList.Item>
            <Divider variant="fullWidth" />
            <APWList.Item>
              <ListItemIcon>
                <FiberManualRecordIcon edge="end" color='secondary' fontSize='small' />
              </ListItemIcon>
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography variant="body2" color="textSecondary" component="span">
                      2023-11-12
                    </Typography>
                    <Typography variant="body2" color="textPrimary">
                      Insufficient Funds
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Typography variant="caption" color="textSecondary" component="span">
                      Insufficient funds for periodic distribution - 12133r245
                    </Typography>
                  </React.Fragment>
                }
              />
            </APWList.Item>
            <Divider variant="fullWidth" />
          </APWList>
          <Box mt={2} display="flex"
          justifyContent="center">
          <Link href={null}> {"View All Messages"}</Link>
        </Box>
        </APWTabs.TabPanel>
        <APWTabs.TabPanel value={this.state.value} index={2}>Announcements</APWTabs.TabPanel>
      </React.Fragment>
    );
  }
}

NotificationMenu.propTypes = {
  show: PropTypes.bool,
};

NotificationMenu.defaultProps = {
  show: false,
};

export default withStyles(styles)(NotificationMenu);
