import React, { useState } from 'react';
// import OwnerModal from './OwnerModal';
import { CircularProgress, Box, IconButton } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import { notifySuccess, notifyError } from '../../../components/Messages/Notification';
import {
  GetAppRounded as DownloadIcon,
  Delete as DeleteIcon,
  Create as EditIcon,
} from '@material-ui/icons';
import Button from '../../../components/Buttons/Button';
import ClientGrid from '../../../components/ClientGrid/ClientGrid';
import QueryParam from '../../../services/QueryParamService';
import {
  pbDateTime,
  pbDateTimeSorter,
} from '../../../components/ClientGrid/CellRenderer';
import {
  attachFiles,
  ListFiles,
  deleteFile,
  downloadFile,
  updateFile,
} from '../../../services/CommonService';
import FileAttachments from '../../../components/Misc/FileAttachments';

import { formatPbDate } from '../../../lib/fmt';

export default function AttachmentTable({ accountId }) {
  const linkType = 'ClientAccount';
  const confirm = useConfirm();
  const [rows, setRows] = useState([]);
  const [rowsSelected, setRowsSelected] = React.useState([]);
  const [attachments, setAttachments] = React.useState([]);
  const [loading, setLoading] = React.useState({
    download: false,
    import: false,
  });
  const [rowData, setRowData] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      tag: '',
    })
  );
  const [currentSelectedRowsIndex, setCurrentSelectedRowsIndex] = React.useState([]);

  const handleOpen = async (data) => {
    setRowData(data);
    setOpen(true);
  };

  const GridButtons = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    return (
      <div>
        <div>
          <IconButton
            aria-label="download"
            disabled={loading.download}
            onClick={() => handleDownload(data.id, rowIndex)}
          >
            {loading.download && currentSelectedRowsIndex.includes(data) ? (
              <CircularProgress style={{ color: '#1e7dff', height: 20, width: 20 }} />
            ) : (
              <DownloadIcon style={{ marginLeft: 15, height: 20, width: 20 }} />
            )}
          </IconButton>

          {/* <IconButton aria-label="edit" onClick={() => handleOpen(rows[data])}>
            <EditIcon style={{ height: 20, width: 20 }} />
          </IconButton> */}
        </div>
      </div>
    );
  };

  const columns = [
    // {
    //   id: 'checkbox',
    //   pinned: true,
    //   width: '54px',
    //   visible: true,
    // },
    {
      id: '',
      field: '',
      width: '60px',
      cellRenderer: GridButtons,
    },
    {
      id: 'fileName',
      field: 'fileName',
      label: 'File Name',
      width: '400px',
      visible: true,
    },
    {
      id: 'tag',
      field: 'tag',
      label: 'Tag',
      visible: true,
    },
    {
      id: 'uploadedBy',
      field: 'uploadedBy',
      label: 'Uploaded By',
      visible: true,
    },
    {
      id: 'uploadedAt',
      field: 'uploadedAt',
      label: 'Uploaded At',
      cellRenderer: pbDateTime,
      sort: pbDateTimeSorter,
      visible: true,
    },
  ];

  const handleSearch = async (noNotif) => {
    try {
      const { attachedFilesList } = await ListFiles({linkId : accountId, linkType});
      setRows(attachedFilesList);
    } catch (error) {
      notifyError(error.message);
    }
  };

  const handleAttachFile = async (e) => {
    const newRows = await attachFiles(accountId, linkType, e.target.files);
    setRows([...newRows, ...rows]);
    notifySuccess('File has been uploaded');
  };

  React.useEffect(() => {
    if (accountId) {
      handleSearch();
    }
  }, [accountId]);

  const handleDownload = async (id, index) => {
    setCurrentSelectedRowsIndex(index);
    setLoading({ ...loading, download: true });
    try {
      const fileName = await downloadFile(id);
      notifySuccess(fileName);
    } catch (error) {
      notifyError(error.message);
      setCurrentSelectedRowsIndex([]);
      setLoading({ ...loading, download: false });
    }
    setRowsSelected([]);
    setCurrentSelectedRowsIndex([]);
    setLoading({ ...loading, download: false });
  };

  const handleClose = async (data, isEdit) => {
    if (!data) {
      setOpen(false);
      return;
    }
    let valid = true;
    if (!data.tag) {
      notifyError('Tag is Required');
      valid = false;
    }
    if (!valid) {
      return;
    }
    try {
      if (isEdit) {
        const resp = await updateFile(data);
        const rowsCopy = [...rows];
        const index = rows.indexOf(rowData);
        rowsCopy[index].tag = resp.tag;
        setRows(rowsCopy);
        notifySuccess(' Attachment has been updated.');
      }
      setOpen(false);
    } catch (error) {
      console.error(error);
      notifyError(error.message);
    }
  };
  const handleDelete = (rowsToDelete) => {
    let messageKey = '';
    if (rowsToDelete.length === 1) {
      messageKey = rows[rowsToDelete[0].data].fileName;
    } else {
      messageKey = rowsToDelete.length + ' items';
    }

    let idsToDelete = [];
    confirm({
      description:
        "You are about to delete '" + messageKey + "'. Please confirm your action.",
      confirmationText: 'Yes, delete',
    }).then(async () => {
      const rowsCopy = [...rows];
      try {
        for (const r of rowsToDelete) {
          const index = r.data;
          await deleteFile(rows[index].id);
          idsToDelete.push(rows[index].id);
        }
      } catch (error) {
        console.error(error);
        notifyError(error.message);
      } finally {
        const filtered = rowsCopy.filter((r) => !idsToDelete.includes(r.id));
        setRows(filtered);
        notifySuccess(messageKey + ' has been deleted');
      }
    });
  };

  const getCsvData = async () => {
    // const data = await readOwner(searchData)
    // return data.ticketsList
  };

  return (
    <div className="page-c" style={{ marginTop: -50 }}>
      <Box component="search-c">
        <div className="grd-row nm">
          <div className="grd-cell-none">
            <FileAttachments
              title="Please attach your files here"
              attachments={attachments}
              onChange={handleAttachFile}
              setAttachments={setAttachments}
            />
            {/* <Button
              type="attachment"
              label="Attach File"
              onClick={() => {
                handleOpen();
              }}
            /> */}
          </div>
        </div>
      </Box>
      <div className="mt-20">
        <ClientGrid
          title="Attachments"
          rowIdField="attachmentsId"
          columns={columns}
          rows={rows}
          getCsvData={getCsvData}
          isLoading={loading}
          setRows={setRows}
        />
      </div>
      {/* {open && (
        <OwnerModal
          onClose={handleClose}
          open={open}
          value={rowData}
          importMode={isImport}
          editMode={editMode}
          validation={validation}
          loading={loading.save}
        ></OwnerModal>
      )}{' '} */}
    </div>
  );
}
