import React from 'react';
import OrderTable from './Components/OrderTable';
import PendingTable from './Components/PendingTable';
import QueryParam from '../../../services/QueryParamService';

export default function OrderEntry() {
  const [isViewPending, setIsViewPending] = React.useState(
    QueryParam.getUrlParameter('isViewPending') === 'true'
  );

  return (
    <div className="page-c">
      {!isViewPending ? (
        <OrderTable setIsViewPending={setIsViewPending}></OrderTable>
      ) : (
        <PendingTable setIsViewPending={setIsViewPending}></PendingTable>
      )}
    </div>
  );
}
