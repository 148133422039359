import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Popover,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import fontFamily from '../../assets/css/fonts';
import color from '../../assets/css/colors';

const useStyles = makeStyles(() => ({
  MuiPopover: {
    height: 400,
    fontFamily: fontFamily.label,
  },
  filterContainer: {
    width: 440,
    padding: 25,
    '& > div:first-child': {
      display: 'flex',
      marginBottom: 20,
      '& > div:first-child': {
        flex: 1,
      },
      '& > div:last-child': {
        flex: 'none',
      },
    },
    '& > div span': {
      marginRight: 20,
      fontWeight: 700,
    },
  },
  filterDropdownsContainer: {
    '& > .MuiFormControl-root': {
      width: 210,
      marginBottom: 20,
      marginRight: 20,
    },
    '& > .MuiFormControl-root:nth-of-type(2n)': {
      marginRight: 0,
    },
    '& > .MuiFormControl-root:last-child, .MuiFormControl-root:nth-last-child(2)': {
      marginBottom: 0,
    },
  },
  resetButton: {
    padding: '8px 20px',
    fontSize: 14,
    fontWeight: 600,
    fontFamily: fontFamily.buttons,
    color: color.primary.main,
    backgroundColor: 'rgba(0,0,0,0.0) !important',
    border: '1px solid ' + color.primary.main,
    borderRadius: 5,
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:hover': {
      color: color.primary.light,
      border: '1px solid ' + color.primary.light,
    }
  }
}));

export default function Filter(props) {
  const classes = useStyles();

  const { columns } = props.columnsApi;
  const { rows } = props.rowsApi;

  const [filterValue, setFilterValue] = React.useState({});
  const [rowsToFilter, setRowsToFilter] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const popup = {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  };

  const filterFieldObj = React.useCallback(() => {
    const obj = {
      ...columns
        .filter((col) => col.field !== undefined)
        .map((col) => {
          return col.field;
        })
        .reduce((json, value, key) => {
          json[value] = '';
          return json;
        }, {}),
    };

    return obj;
  }, [columns])

  React.useEffect(() => { 
    setFilterValue(filterFieldObj());
  }, [filterFieldObj]);

  const filterFields = () => {
    let filterFields = [];

    const fieldsRaw = columns
      .filter((col) => col.field !== undefined)
      .map((col) => {
        return col;
      });

    function removeDuplicates(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item[key]])).values()];
    }

    function mapOptionsPerFilterField(col) {
      const cleanupOption = removeDuplicates(options, col.field);

      filterFields.push({
        field: col.field,
        label: col.label,
        options: cleanupOption,
      });
    }

    fieldsRaw.forEach(mapOptionsPerFilterField);

    const sortedFilterFields = filterFields.sort(function(a, b) {
      if (a.label || b.label) {
        let valueA = a.label.toUpperCase();
        let valueB = b.label.toUpperCase();
        
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      }

      return '';
    });

    return sortedFilterFields;
  };

  const filteredRows = (input) => {
    const filterRows = rowsToFilter.filter(
      (row) => row[input.name] === input.value
    );

    return filterRows;
  };

  const handlePopup = (event) => {
    if (options.length === 0) {
      setOptions(...options, rows);
    }
    if (rowsToFilter.length === 0) {
      setRowsToFilter(...rowsToFilter, rows);
    }

    setAnchorEl(event.target);
  };

  const handlePopout = () => {
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    const input = e.target;

    setFilterValue({
      ...filterValue,
      [input.name]: input.value,
    });

    if (input.value !== '') {
      props.setRows(filteredRows(input));
    } else {
      props.setRows(rowsToFilter);
    }
  };

  const handleReset = () => {
    setFilterValue(filterFieldObj());

    props.setRows(rowsToFilter);
  };

  return (
    <>
      <Tooltip arrow title="Filter">
        <IconButton onClick={handlePopup}>
          <FilterListRoundedIcon />
        </IconButton>
      </Tooltip>
      <Popover
        className={classes.MuiPopover}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopout}
        anchorOrigin={popup.anchorOrigin}
        transformOrigin={popup.transformOrigin}
      >
        <div className={classes.filterContainer}>
          <div>
            <div>
              <span>Filter:</span>
            </div>
            <div>
              <button className={classes.resetButton} onClick={handleReset}>Reset</button>
            </div>
          </div>
          <div className={classes.filterDropdownsContainer}>
            {filterFields().map((column, idx) => (
              <FormControl className={classes.formControl} key={idx + '_' + column.field}>
                <InputLabel shrink>{column.label}</InputLabel>
                <Select
                  fullWidth
                  displayEmpty
                  name={column.field}
                  value={filterValue[column.field]}
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {column.options
                    ?.filter((item) => item !== '')
                    .map((item, idx) => (
                      <MenuItem key={idx + '_' + item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            ))}
          </div>
        </div>
      </Popover>
    </>
  );
}
