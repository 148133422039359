import React from 'react';
import { IconButton, Tooltip, Slide } from '@material-ui/core';
import {ReactComponent as Facebook} from '../../assets/icons/facebook.svg';
import {ReactComponent as Twitter} from '../../assets/icons/twitter.svg';
import {ReactComponent as LinkedIn} from '../../assets/icons/linkedin.svg';
import {ReactComponent as Email} from '../../assets/icons/email.svg';
import {ReactComponent as Website} from '../../assets/icons/world-wide-web.svg';

const SocialButton = (props) => {
    const SocialIcon = (icon) => {
        if (icon.type === 'facebook') {
            return <Facebook/>
        } else if (icon.type === 'twitter') {
            return <Twitter/>
        } else if (icon.type === 'linkedin') {
            return <LinkedIn/>
        } else if (icon.type === 'email') {
            return <Email/>
        } else {
            return <Website/>
        }
    }

    const handleNavigateLink = () => {
        window.open('https://www.' + props.url, '_blank')
    }

    if (props.transition === false) {
        
    }
    setTimeout(function(){ 
        
     }, 600);

    return (
        <>
            {props.transition !== undefined ? (
                <Slide 
                    direction="left" 
                    in={props.transition}
                    style={{ transitionDelay: props.delay }}
                    mountOnEnter 
                    unmountOnExit
                >
                    <Tooltip 
                        arrow
                        placement={props.tooltipPlacement ? props.tooltipPlacement : 'down'}
                        title={props.tooltip}>
                            <div>
                                <IconButton onClick={handleNavigateLink}>
                                    <SocialIcon type={props.type} />
                                </IconButton>
                            </div>
                    </Tooltip>
                </Slide>
            ) : (
                <Tooltip 
                    arrow
                    title={props.tooltip}>
                        <div>
                            <IconButton onClick={handleNavigateLink}>
                                <SocialIcon type={props.type} />
                            </IconButton>
                        </div>
                </Tooltip>
            )}
        </>
    );
}
export default SocialButton;