/**
 * @fileoverview gRPC-Web generated client stub for accountpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

var proto_accountpb_owner_pb = require('../../proto/accountpb/owner_pb.js')

var proto_accountpb_address_pb = require('../../proto/accountpb/address_pb.js')

var proto_accountpb_contactinfo_pb = require('../../proto/accountpb/contactinfo_pb.js')

var proto_accountpb_identification_pb = require('../../proto/accountpb/identification_pb.js')

var proto_accountpb_note_pb = require('../../proto/accountpb/note_pb.js')

var proto_accountpb_subaccount_pb = require('../../proto/accountpb/subaccount_pb.js')

var proto_utilspb_pagination_pb = require('../../proto/utilspb/pagination_pb.js')

const proto = {};
proto.accountpb = require('./client_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.accountpb.ClientServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.accountpb.ClientServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.Client,
 *   !proto.accountpb.CreateClientResponse>}
 */
const methodDescriptor_ClientService_CreateClient = new grpc.web.MethodDescriptor(
  '/accountpb.ClientService/CreateClient',
  grpc.web.MethodType.UNARY,
  proto.accountpb.Client,
  proto.accountpb.CreateClientResponse,
  /**
   * @param {!proto.accountpb.Client} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.CreateClientResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.accountpb.Client,
 *   !proto.accountpb.CreateClientResponse>}
 */
const methodInfo_ClientService_CreateClient = new grpc.web.AbstractClientBase.MethodInfo(
  proto.accountpb.CreateClientResponse,
  /**
   * @param {!proto.accountpb.Client} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.CreateClientResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.Client} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.accountpb.CreateClientResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.CreateClientResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.ClientServiceClient.prototype.createClient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.ClientService/CreateClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_CreateClient,
      callback);
};


/**
 * @param {!proto.accountpb.Client} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.CreateClientResponse>}
 *     A native promise that resolves to the response
 */
proto.accountpb.ClientServicePromiseClient.prototype.createClient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.ClientService/CreateClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_CreateClient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.ReadClientRequest,
 *   !proto.accountpb.ReadClientResponse>}
 */
const methodDescriptor_ClientService_ReadClient = new grpc.web.MethodDescriptor(
  '/accountpb.ClientService/ReadClient',
  grpc.web.MethodType.UNARY,
  proto.accountpb.ReadClientRequest,
  proto.accountpb.ReadClientResponse,
  /**
   * @param {!proto.accountpb.ReadClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.ReadClientResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.accountpb.ReadClientRequest,
 *   !proto.accountpb.ReadClientResponse>}
 */
const methodInfo_ClientService_ReadClient = new grpc.web.AbstractClientBase.MethodInfo(
  proto.accountpb.ReadClientResponse,
  /**
   * @param {!proto.accountpb.ReadClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.ReadClientResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.ReadClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.accountpb.ReadClientResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.ReadClientResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.ClientServiceClient.prototype.readClient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.ClientService/ReadClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_ReadClient,
      callback);
};


/**
 * @param {!proto.accountpb.ReadClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.ReadClientResponse>}
 *     A native promise that resolves to the response
 */
proto.accountpb.ClientServicePromiseClient.prototype.readClient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.ClientService/ReadClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_ReadClient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.ReadClientByUserRequest,
 *   !proto.accountpb.ReadClientResponse>}
 */
const methodDescriptor_ClientService_GetAccessibleClient = new grpc.web.MethodDescriptor(
  '/accountpb.ClientService/GetAccessibleClient',
  grpc.web.MethodType.UNARY,
  proto.accountpb.ReadClientByUserRequest,
  proto.accountpb.ReadClientResponse,
  /**
   * @param {!proto.accountpb.ReadClientByUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.ReadClientResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.accountpb.ReadClientByUserRequest,
 *   !proto.accountpb.ReadClientResponse>}
 */
const methodInfo_ClientService_GetAccessibleClient = new grpc.web.AbstractClientBase.MethodInfo(
  proto.accountpb.ReadClientResponse,
  /**
   * @param {!proto.accountpb.ReadClientByUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.ReadClientResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.ReadClientByUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.accountpb.ReadClientResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.ReadClientResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.ClientServiceClient.prototype.getAccessibleClient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.ClientService/GetAccessibleClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_GetAccessibleClient,
      callback);
};


/**
 * @param {!proto.accountpb.ReadClientByUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.ReadClientResponse>}
 *     A native promise that resolves to the response
 */
proto.accountpb.ClientServicePromiseClient.prototype.getAccessibleClient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.ClientService/GetAccessibleClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_GetAccessibleClient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.Client,
 *   !proto.accountpb.UpdateClientResponse>}
 */
const methodDescriptor_ClientService_UpdateClient = new grpc.web.MethodDescriptor(
  '/accountpb.ClientService/UpdateClient',
  grpc.web.MethodType.UNARY,
  proto.accountpb.Client,
  proto.accountpb.UpdateClientResponse,
  /**
   * @param {!proto.accountpb.Client} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.UpdateClientResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.accountpb.Client,
 *   !proto.accountpb.UpdateClientResponse>}
 */
const methodInfo_ClientService_UpdateClient = new grpc.web.AbstractClientBase.MethodInfo(
  proto.accountpb.UpdateClientResponse,
  /**
   * @param {!proto.accountpb.Client} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.UpdateClientResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.Client} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.accountpb.UpdateClientResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.UpdateClientResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.ClientServiceClient.prototype.updateClient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.ClientService/UpdateClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_UpdateClient,
      callback);
};


/**
 * @param {!proto.accountpb.Client} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.UpdateClientResponse>}
 *     A native promise that resolves to the response
 */
proto.accountpb.ClientServicePromiseClient.prototype.updateClient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.ClientService/UpdateClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_UpdateClient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.ListClientRequest,
 *   !proto.accountpb.ListClientResponse>}
 */
const methodDescriptor_ClientService_ListClient = new grpc.web.MethodDescriptor(
  '/accountpb.ClientService/ListClient',
  grpc.web.MethodType.UNARY,
  proto.accountpb.ListClientRequest,
  proto.accountpb.ListClientResponse,
  /**
   * @param {!proto.accountpb.ListClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.ListClientResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.accountpb.ListClientRequest,
 *   !proto.accountpb.ListClientResponse>}
 */
const methodInfo_ClientService_ListClient = new grpc.web.AbstractClientBase.MethodInfo(
  proto.accountpb.ListClientResponse,
  /**
   * @param {!proto.accountpb.ListClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.ListClientResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.ListClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.accountpb.ListClientResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.ListClientResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.ClientServiceClient.prototype.listClient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.ClientService/ListClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_ListClient,
      callback);
};


/**
 * @param {!proto.accountpb.ListClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.ListClientResponse>}
 *     A native promise that resolves to the response
 */
proto.accountpb.ClientServicePromiseClient.prototype.listClient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.ClientService/ListClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_ListClient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.ListClientAuditRequest,
 *   !proto.accountpb.ListClientAuditResponse>}
 */
const methodDescriptor_ClientService_ListClientAudit = new grpc.web.MethodDescriptor(
  '/accountpb.ClientService/ListClientAudit',
  grpc.web.MethodType.UNARY,
  proto.accountpb.ListClientAuditRequest,
  proto.accountpb.ListClientAuditResponse,
  /**
   * @param {!proto.accountpb.ListClientAuditRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.ListClientAuditResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.accountpb.ListClientAuditRequest,
 *   !proto.accountpb.ListClientAuditResponse>}
 */
const methodInfo_ClientService_ListClientAudit = new grpc.web.AbstractClientBase.MethodInfo(
  proto.accountpb.ListClientAuditResponse,
  /**
   * @param {!proto.accountpb.ListClientAuditRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.ListClientAuditResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.ListClientAuditRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.accountpb.ListClientAuditResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.ListClientAuditResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.ClientServiceClient.prototype.listClientAudit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.ClientService/ListClientAudit',
      request,
      metadata || {},
      methodDescriptor_ClientService_ListClientAudit,
      callback);
};


/**
 * @param {!proto.accountpb.ListClientAuditRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.ListClientAuditResponse>}
 *     A native promise that resolves to the response
 */
proto.accountpb.ClientServicePromiseClient.prototype.listClientAudit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.ClientService/ListClientAudit',
      request,
      metadata || {},
      methodDescriptor_ClientService_ListClientAudit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.GetAccountDetailsRequest,
 *   !proto.accountpb.GetAccountDetailsResponse>}
 */
const methodDescriptor_ClientService_GetAccountDetails = new grpc.web.MethodDescriptor(
  '/accountpb.ClientService/GetAccountDetails',
  grpc.web.MethodType.UNARY,
  proto.accountpb.GetAccountDetailsRequest,
  proto.accountpb.GetAccountDetailsResponse,
  /**
   * @param {!proto.accountpb.GetAccountDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.GetAccountDetailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.accountpb.GetAccountDetailsRequest,
 *   !proto.accountpb.GetAccountDetailsResponse>}
 */
const methodInfo_ClientService_GetAccountDetails = new grpc.web.AbstractClientBase.MethodInfo(
  proto.accountpb.GetAccountDetailsResponse,
  /**
   * @param {!proto.accountpb.GetAccountDetailsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.GetAccountDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.GetAccountDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.accountpb.GetAccountDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.GetAccountDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.ClientServiceClient.prototype.getAccountDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.ClientService/GetAccountDetails',
      request,
      metadata || {},
      methodDescriptor_ClientService_GetAccountDetails,
      callback);
};


/**
 * @param {!proto.accountpb.GetAccountDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.GetAccountDetailsResponse>}
 *     A native promise that resolves to the response
 */
proto.accountpb.ClientServicePromiseClient.prototype.getAccountDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.ClientService/GetAccountDetails',
      request,
      metadata || {},
      methodDescriptor_ClientService_GetAccountDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.GetAccountIdRequest,
 *   !proto.accountpb.GetAccountIdResponse>}
 */
const methodDescriptor_ClientService_GetAccountID = new grpc.web.MethodDescriptor(
  '/accountpb.ClientService/GetAccountID',
  grpc.web.MethodType.UNARY,
  proto.accountpb.GetAccountIdRequest,
  proto.accountpb.GetAccountIdResponse,
  /**
   * @param {!proto.accountpb.GetAccountIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.GetAccountIdResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.accountpb.GetAccountIdRequest,
 *   !proto.accountpb.GetAccountIdResponse>}
 */
const methodInfo_ClientService_GetAccountID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.accountpb.GetAccountIdResponse,
  /**
   * @param {!proto.accountpb.GetAccountIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.GetAccountIdResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.GetAccountIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.accountpb.GetAccountIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.GetAccountIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.ClientServiceClient.prototype.getAccountID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.ClientService/GetAccountID',
      request,
      metadata || {},
      methodDescriptor_ClientService_GetAccountID,
      callback);
};


/**
 * @param {!proto.accountpb.GetAccountIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.GetAccountIdResponse>}
 *     A native promise that resolves to the response
 */
proto.accountpb.ClientServicePromiseClient.prototype.getAccountID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.ClientService/GetAccountID',
      request,
      metadata || {},
      methodDescriptor_ClientService_GetAccountID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.GetNewClientIdRequest,
 *   !proto.accountpb.GetNewClientIdResponse>}
 */
const methodDescriptor_ClientService_GetNewClientId = new grpc.web.MethodDescriptor(
  '/accountpb.ClientService/GetNewClientId',
  grpc.web.MethodType.UNARY,
  proto.accountpb.GetNewClientIdRequest,
  proto.accountpb.GetNewClientIdResponse,
  /**
   * @param {!proto.accountpb.GetNewClientIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.GetNewClientIdResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.accountpb.GetNewClientIdRequest,
 *   !proto.accountpb.GetNewClientIdResponse>}
 */
const methodInfo_ClientService_GetNewClientId = new grpc.web.AbstractClientBase.MethodInfo(
  proto.accountpb.GetNewClientIdResponse,
  /**
   * @param {!proto.accountpb.GetNewClientIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.GetNewClientIdResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.GetNewClientIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.accountpb.GetNewClientIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.GetNewClientIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.ClientServiceClient.prototype.getNewClientId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.ClientService/GetNewClientId',
      request,
      metadata || {},
      methodDescriptor_ClientService_GetNewClientId,
      callback);
};


/**
 * @param {!proto.accountpb.GetNewClientIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.GetNewClientIdResponse>}
 *     A native promise that resolves to the response
 */
proto.accountpb.ClientServicePromiseClient.prototype.getNewClientId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.ClientService/GetNewClientId',
      request,
      metadata || {},
      methodDescriptor_ClientService_GetNewClientId);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.ValidateAccountRequest,
 *   !proto.accountpb.EmptyResponse>}
 */
const methodDescriptor_ClientService_ValidateAccount = new grpc.web.MethodDescriptor(
  '/accountpb.ClientService/ValidateAccount',
  grpc.web.MethodType.UNARY,
  proto.accountpb.ValidateAccountRequest,
  proto.accountpb.EmptyResponse,
  /**
   * @param {!proto.accountpb.ValidateAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.EmptyResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.accountpb.ValidateAccountRequest,
 *   !proto.accountpb.EmptyResponse>}
 */
const methodInfo_ClientService_ValidateAccount = new grpc.web.AbstractClientBase.MethodInfo(
  proto.accountpb.EmptyResponse,
  /**
   * @param {!proto.accountpb.ValidateAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.EmptyResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.ValidateAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.accountpb.EmptyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.EmptyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.ClientServiceClient.prototype.validateAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.ClientService/ValidateAccount',
      request,
      metadata || {},
      methodDescriptor_ClientService_ValidateAccount,
      callback);
};


/**
 * @param {!proto.accountpb.ValidateAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.EmptyResponse>}
 *     A native promise that resolves to the response
 */
proto.accountpb.ClientServicePromiseClient.prototype.validateAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.ClientService/ValidateAccount',
      request,
      metadata || {},
      methodDescriptor_ClientService_ValidateAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.EmptyRequest,
 *   !proto.accountpb.ListSelectionItemResponse>}
 */
const methodDescriptor_ClientService_ListAccountType = new grpc.web.MethodDescriptor(
  '/accountpb.ClientService/ListAccountType',
  grpc.web.MethodType.UNARY,
  proto.accountpb.EmptyRequest,
  proto.accountpb.ListSelectionItemResponse,
  /**
   * @param {!proto.accountpb.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.ListSelectionItemResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.accountpb.EmptyRequest,
 *   !proto.accountpb.ListSelectionItemResponse>}
 */
const methodInfo_ClientService_ListAccountType = new grpc.web.AbstractClientBase.MethodInfo(
  proto.accountpb.ListSelectionItemResponse,
  /**
   * @param {!proto.accountpb.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.ListSelectionItemResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.accountpb.ListSelectionItemResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.ListSelectionItemResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.ClientServiceClient.prototype.listAccountType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.ClientService/ListAccountType',
      request,
      metadata || {},
      methodDescriptor_ClientService_ListAccountType,
      callback);
};


/**
 * @param {!proto.accountpb.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.ListSelectionItemResponse>}
 *     A native promise that resolves to the response
 */
proto.accountpb.ClientServicePromiseClient.prototype.listAccountType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.ClientService/ListAccountType',
      request,
      metadata || {},
      methodDescriptor_ClientService_ListAccountType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.ListSubAccountTypeItemRequest,
 *   !proto.accountpb.ListSelectionItemResponse>}
 */
const methodDescriptor_ClientService_ListSubAccountType = new grpc.web.MethodDescriptor(
  '/accountpb.ClientService/ListSubAccountType',
  grpc.web.MethodType.UNARY,
  proto.accountpb.ListSubAccountTypeItemRequest,
  proto.accountpb.ListSelectionItemResponse,
  /**
   * @param {!proto.accountpb.ListSubAccountTypeItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.ListSelectionItemResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.accountpb.ListSubAccountTypeItemRequest,
 *   !proto.accountpb.ListSelectionItemResponse>}
 */
const methodInfo_ClientService_ListSubAccountType = new grpc.web.AbstractClientBase.MethodInfo(
  proto.accountpb.ListSelectionItemResponse,
  /**
   * @param {!proto.accountpb.ListSubAccountTypeItemRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.ListSelectionItemResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.ListSubAccountTypeItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.accountpb.ListSelectionItemResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.ListSelectionItemResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.ClientServiceClient.prototype.listSubAccountType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.ClientService/ListSubAccountType',
      request,
      metadata || {},
      methodDescriptor_ClientService_ListSubAccountType,
      callback);
};


/**
 * @param {!proto.accountpb.ListSubAccountTypeItemRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.ListSelectionItemResponse>}
 *     A native promise that resolves to the response
 */
proto.accountpb.ClientServicePromiseClient.prototype.listSubAccountType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.ClientService/ListSubAccountType',
      request,
      metadata || {},
      methodDescriptor_ClientService_ListSubAccountType);
};


module.exports = proto.accountpb;

