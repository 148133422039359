import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import {ReactComponent as Close} from '../../assets/icons/close.svg';

const CloseButton = (props) => {
    return (
        <>
            {props.noTooltip ? (
                <IconButton
                    aria-label="back" 
                    style={{marginTop: -7}}
                    onClick={props.onClick}
                >
                    <Close />
                </IconButton>
            ) : (
                <Tooltip
                    arrow
                    title="Close">
                        <div>
                            <IconButton
                                aria-label="back" 
                                style={{marginTop: -7}}
                                onClick={props.onClick}
                            >
                                <Close />
                            </IconButton>
                        </div>
                </Tooltip>
            )}
        </>
    );
}
export default CloseButton;