import React from 'react';
import { Button as MUIButton, CircularProgress } from '@material-ui/core';
import {ReactComponent as Compose} from '../../assets/icons/pen.svg';
import {ReactComponent as Paperclip} from '../../assets/icons/paperclip.svg';
import {ReactComponent as Camera} from '../../assets/icons/camera.svg';
import {ReactComponent as Search} from '../../assets/icons/search.svg';
import {ReactComponent as Plus} from '../../assets/icons/plus.svg';
import { ReactComponent as Reports } from '../../assets/icons/reports.svg';
import {
  Loop
} from '@material-ui/icons';

const Button = (props) => {
    const StartIcon = (button) => {
        if (button.type === 'message') {
            return <Compose/>
        } else if (button.type === 'search') {
            return (props.loading ? <CircularProgress color="inherit" size={20} thickness={2}/> : <Search/>);
        } else if (button.type === 'add') {
            return <Camera/>
        } else if (button.type === 'edit') {
            return <Compose/>
        } else if (button.type === 'attachment') {
            return <Paperclip/>
        } else if (button.type === 'photo') {
            return <Camera/>
        } else if (button.type === 'recurrence') {
            return <Loop/>
        } else if (button.type === 'plus') {
            return <Plus/>
        } else if (button.type === 'generate') {
            return <Reports/>
        } else {
            return <CircularProgress color="inherit" size={20} thickness={2}/>
        }
    } 

    return (
        <MUIButton
            id={props.id}
            variant="contained"
            color="primary"
            fullWidth={props.fullWidth === undefined || props.fullWidth === true}
            disabled={props.disabled}
            component={props.component}
            name={props.name}
            onClick={props.onClick}
            startIcon={props.loading || props.type ? <StartIcon type={props.type} /> : null || ''}
        >
            {props.label}
        </MUIButton>
    );
}
export default Button;