import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import color from '../../../../assets/css/colors';
import fontFamily from '../../../../assets/css/fonts';
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from '../../../../components/Messages/Notification';
import { GetAppRounded as DownloadIcon, Delete as DeleteIcon } from '@material-ui/icons';
import {
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  Tooltip,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import NoteTable from './NoteTable';
import { useConfirm } from 'material-ui-confirm';
import {
  attachFiles,
  ListFiles,
  deleteFile,
  downloadFile,
} from '../../../../services/CommonService';
import SelectSystemCode from '../../../../components/Dropdown/SelectSystemCode';
import AutoCompleteCorrespondent from '../../../../components/AutoComplete/AutoCompleteCorrespondent';
import AutoCompleteMasterAccountNo from '../../../../components/AutoComplete/AutoCompleteMasterAccountNo';
import Button from '../../../../components/Buttons/Button';
import { callNotice } from '../../../../services/CallLogService';
import { formatDollar } from '../../../../lib/fmt';
import { listNote } from '../../../../services/AccountNoteService';
import {
  pbDateTime,
  pbDateTimeSorter,
} from '../../../../components/ClientGrid/CellRenderer';
import ClientGrid from '../../../../components/ClientGrid/ClientGrid';
import Text from '../../../../components/Typography/Text';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  fadeModalLg: {
    maxWidth: 900,
    maxHeight: '90vh',
    width: '100%',
    padding: '25px 30px 30px 30px',
    margin: '0px auto',
    backgroundColor: '#ffffff',
    border: 'none',
    borderRadius: 5,
    boxShadow: '0px 0px 10px #333333',
    boxSizing: 'border-box',
    outline: 'none',
    overflowY: 'auto',
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
}));

export default function CallLogModal({
  onClose: handleClose,
  open: isOpen,
  value,
  loading: saving,
}) {
  const confirm = useConfirm();
  const classes = useStyles();
  const [modalData, setModalData] = useState({});
  const [noteList, setNoteList] = React.useState([]);
  const [mounted, setMounted] = React.useState(false);
  const [loading, setLoading] = React.useState({
    send: false,
  });
  const [, setIsEdit] = React.useState(false);
  const linkType = 'CallLog';
  const [rows, setRows] = useState([]);
  const [rowsSelected, setRowsSelected] = React.useState([]);

  const [currentSelectedRowsIndex, setCurrentSelectedRowsIndex] = React.useState([]);

  useEffect(() => {
    if (isOpen && !mounted) {
      setModalData({ ...value });
      if (value.requirementLogId !== 0) {
        setIsEdit(true);
      }
      getNotes();
      getFileAttachements();
    }
    return () => {
      setMounted(true);
    };
  }, [isOpen, value, modalData, mounted]);

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setModalData({
      ...modalData,
      [input.name]: input.value,
    });
  };

  const handleCallNotice = async () => {
    let idsToTransfer = [];

    idsToTransfer.push(modalData.requirementLogId);

    try {
      setLoading({ ...loading, send: true });
      await callNotice(idsToTransfer);
      setLoading({ ...loading, send: false });
      notifyInfo('Successfully sent a notice.');
    } catch (error) {
      setLoading({ ...loading, send: false });
      notifyError(error.message);
    }
  };

  const handleAttachFile = async (e) => {
    const newRows = await attachFiles(
      modalData.requirementLogId,
      linkType,
      e.target.files
    );
    setRows([...newRows, ...rows]);
    notifySuccess('File has been uploaded');
  };

  const options = {
    rowsSelected: rowsSelected,
    selectableRows: 'multiple',
    onRowsDelete: (selectedItems) => {
      handleDelete(selectedItems.data);
      return false;
    },
    customToolbarSelect: (selectedRows, a, b) => {
      return (
        <div className="grd-row" style={{ margin: 0, padding: 0, marginRight: 20 }}>
          <div className="grd-cell">
            <Tooltip title="Delete" arrow>
              <div>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    handleDelete(selectedRows.data);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </Tooltip>
          </div>
          <div align={'left'} className="grd-cell">
            <Tooltip title="Download" arrow>
              <div>
                <IconButton
                  aria-label="download"
                  onClick={() =>
                    handleDownload(
                      selectedRows.data.map((d) => rows[d.dataIndex].id),
                      selectedRows.data.map((d) => d.dataIndex)
                    )
                  }
                >
                  <DownloadIcon />
                </IconButton>
              </div>
            </Tooltip>
          </div>
        </div>
      );
    },
  };

  const GridButtons = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    return (
      <div align={'left'} className="grd-row" style={{ margin: 0, padding: 0 }}>
        <div align={'left'} className="grd-cell">
          <IconButton
            aria-label="download"
            disabled={loading.download}
            onClick={() => handleDownload([data.id], rowIndex)}
          >
            {loading.download && currentSelectedRowsIndex.includes(rowIndex) ? (
              <CircularProgress style={{ color: '#1e7dff', height: 20, width: 20 }} />
            ) : (
              <DownloadIcon style={{ height: 24, width: 24 }} />
            )}
          </IconButton>
        </div>
      </div>
    );
  };

  const columns = [
    {
      id: '',
      field: '',
      width: '60px',
      cellRenderer: GridButtons,
    },
    {
      id: 'fileName',
      field: 'fileName',
      label: 'File Name',
    },
    {
      id: 'uploadedBy',
      field: 'uploadedBy',
      label: 'Uploaded By',
    },
    {
      id: 'uploadedAt',
      field: 'uploadedAt',
      label: 'Uploaded At',
      cellRenderer: pbDateTime,
      sort: pbDateTimeSorter,
      visible: false,
    },
  ];

  const handleDelete = (rowsToDelete) => {
    let messageKey = '';
    if (rowsToDelete.length === 1) {
      messageKey = rows[rowsToDelete[0].dataIndex].fileName;
    } else {
      messageKey = rowsToDelete.length + ' items';
    }

    let idsToDelete = [];
    confirm({
      description:
        "You are about to delete '" + messageKey + "'. Please confirm your action.",
      confirmationText: 'Yes, delete',
    }).then(async () => {
      const rowsCopy = [...rows];
      try {
        for (const r of rowsToDelete) {
          const index = r.dataIndex;
          await deleteFile(rows[index].id);
          idsToDelete.push(rows[index].id);
        }
      } catch (error) {
        console.error(error);
        notifyError(error.message);
      } finally {
        const filtered = rowsCopy.filter((r) => !idsToDelete.includes(r.id));
        setRows(filtered);
        notifySuccess(messageKey + ' has been deleted');
      }
    });
  };

  const handleDownload = async (ids, index) => {
    setCurrentSelectedRowsIndex(index);
    setLoading({ download: true });
    for (let id of ids) {
      try {
        const fileName = await downloadFile(id);
        notifySuccess(fileName);
      } catch (error) {
        setCurrentSelectedRowsIndex([]);
        notifyError(error.message);
        setLoading({ download: false });
      }
    }
    setRowsSelected([]);
    setCurrentSelectedRowsIndex([]);
    setLoading({ download: false });
  };

  async function getNotes() {
    try {
      const data = await listNote(modalData.accountId, {
        linkId: modalData.requirementLogId,
        noteType: 'call_logs',
      });
      setNoteList(data.notesList);
    } catch (error) {
      console.error(error);
    }
  }

  async function getFileAttachements() {
    const data = await ListFiles({
      linkId: modalData.requirementLogId,
      linkType: linkType,
    });
    setRows(data.attachedFilesList);
  }

  const getCsvData = async () => {
    return rows;
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalLg}>
          <div className="grd-row">
            <div className="grd-cell">
              <Text id="transition-modal-title" variant="h2" label={'Edit Call Log'} />
            </div>
            <div className="grd-cell"></div>
            <div className="grd-cell"></div>
            <div className="grd-cell" style={{ marginRight: 10 }}>
              <Button
                loading={loading.send}
                disabled={modalData.notify || loading.send}
                color="secondary"
                label={loading.send ? 'Sending...' : 'Send Call Notice'}
                onClick={() => {
                  handleCallNotice();
                }}
              />
            </div>
          </div>
          <Box mt={5}>
            <form noValidate autoComplete="off">
              <div className="grd-row">
                <div className="grd-cell" style={{ marginRight: 30 }}>
                  <AutoCompleteCorrespondent
                    freeSolo={true}
                    disabled={true}
                    name="correspondent"
                    label="Correspondent"
                    value={modalData.correspondent}
                    onChange={handleChange}
                  ></AutoCompleteCorrespondent>
                </div>
                <div className="grd-cell" style={{ marginRight: 30 }}>
                  <AutoCompleteMasterAccountNo
                    freeSolo={true}
                    disabled={true}
                    name="masterAccountNo"
                    label="Master Account No"
                    value={modalData.masterAccountNo}
                    correspondent={modalData.correspondent}
                    isAccessibleOnly={true}
                  ></AutoCompleteMasterAccountNo>
                </div>
              </div>
              <div className="grd-row">
                <div className="grd-cell" style={{ marginRight: 30 }}>
                  <SelectSystemCode
                    disabled={true}
                    name="callType"
                    label="Call Type"
                    type="Call Type"
                    value={modalData.callType}
                  ></SelectSystemCode>
                </div>
                <div className="grd-cell" style={{ marginRight: 30 }}>
                  <TextField
                    disabled={true}
                    fullWidth
                    name="callAmt"
                    label="Call Amount"
                    value={formatDollar(modalData.callAmt) || 0}
                    inputProps={{ maxLength: 100 }}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </div>
              <div className="grd-row">
                <div className="grd-cell" style={{ marginRight: 30 }}>
                  <SelectSystemCode
                    required={true}
                    name="callStatus"
                    label="Call Status"
                    type="Status"
                    subType="Call Status"
                    value={modalData.callStatus}
                    onChange={handleChange}
                  ></SelectSystemCode>
                </div>
                <div className="grd-cell" style={{ marginRight: 30 }}>
                  <SelectSystemCode
                    required={true}
                    name="callStatusReason"
                    label="Call Status Reason"
                    type="Status"
                    subType="Call Status Reason"
                    value={modalData.callStatusReason}
                    onChange={handleChange}
                  ></SelectSystemCode>
                </div>
              </div>
              <div className="mt-20">
                <NoteTable
                  accountid={modalData.accountId}
                  linkid={modalData.requirementLogId}
                  setNoteList={setNoteList}
                />
              </div>
              <div className="grd-row">
                <div className={classes.actionContainer}>
                  <div className="grd-cell-none" style={{ marginRight: 10 }}>
                    <input
                      onChange={handleAttachFile}
                      accept="*/*"
                      className={classes.input}
                      style={{ display: 'none' }}
                      id="raised-button-file"
                      multiple
                      type="file"
                    />
                    <label htmlFor="raised-button-file">
                      <Button
                        color="secondary"
                        type="import"
                        component="span"
                        label="Attach File"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="mt-20">
                <ClientGrid
                  title={'Attachments'}
                  rows={rows}
                  setRows={setRows}
                  columns={columns}
                  getCsvData={getCsvData}
                />
              </div>
              <div className={classes.modalFooter}>
                <div className="grd-cell-none" style={{ marginRight: 10, marginTop: 20 }}>
                  <Button
                    disabled={saving}
                    color="primary"
                    label="Close"
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </div>
                <div className="grd-cell-none">
                  <Button
                    disabled={saving}
                    loading={saving}
                    color="secondary"
                    label={saving ? 'Saving...' : 'Save'}
                    onClick={() => {
                      handleClose(modalData);
                    }}
                  />
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
