import React from 'react';
import Button from '../../../components/Buttons/Button';
import FormHeader from './FormHeader';
import TextField from '../../../components/Textfields/TextField';
import Text from '../../../components/Typography/Text';
import { ReactComponent as SignatureGraphic } from '../../../assets/graphics/sign-graphic.svg';
import ESignatureModal from '../../../components/Modals/ESignatureModal';
import { notifyError } from '../../../components/Messages/Notification';
import PDFApplicationForm from '../../../components/Misc/PDFApplicationForm';

export default function FormSubmit({
  data,
  setData,
  accountType,
  onBack,
  isLoading,
  onSubmit,
  onSaveAndExit,
  onboardingAttachment,
  setOnboardingAttachment,
  pdfData,
}) {
  const [open, setOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [document, setDocument] = React.useState(null);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleDocumentChange = (document) => {
    setDocument(document);
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(false);
      await onSubmit();
      setIsSubmitting(true);
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <div className="register-b">
      <div className="register-b-ls">
        <FormHeader
          header="Submit Your Application"
          subHeader="You have completed all the necessary steps"
        />
        <div className="ls-b">
          <form className="signature-form-w" autoComplete="off">
            {data.primaryApplicant ? (
              <div className="grd-row">
                <div className="grd-cell">
                  <TextField
                    disabled
                    mt={20}
                    max={50}
                    name="primaryApplicantSignature"
                    label="Primary Applicant E-Signature"
                    placeholder="Primary Applicant Signature"
                    type="text"
                    value={data.primaryApplicant}
                  />
                </div>
                {data.coApplicant && accountType === 'J' ? (
                  <div className="grd-cell">
                    <TextField
                      disabled
                      mt={20}
                      max={50}
                      name="coApplicantSignature"
                      label="Co-Applicant E-Signature"
                      placeholder="Co-Applicant Signature"
                      type="text"
                      value={data.coApplicant}
                    />
                  </div>
                ) : null}
              </div>
            ) : (
              <SignatureGraphic
                style={{
                  maxWidth: 270,
                  maxHeight: 270,
                  margin: '0 auto',
                  display: 'block',
                }}
              />
            )}
            <Button
              mt={true}
              disabled={isLoading}
              label="Sign E-Signature"
              onClick={handleOpenModal}
            />
            <Text
              mt={30}
              variant="body1"
              label="I have read, understood and accepted the terms and conditions provided during
                                the account opening process; I will comply with all terms of the Client Agreement
                                (including the pre-dispute arbitration clauses at the end of the Agreement) and all
                                other agreements and disclosures provided to me above; and I understand and agree
                                that my electronic signature is equivalent to my handwritten signature."
            />
            <ESignatureModal
              modalTitle="E-Signature"
              content={
                <div>
                  <TextField
                    required={true}
                    mt={20}
                    max={50}
                    name="primaryApplicantSignature"
                    label="Primary applicant signature here."
                    placeholder="Primary Applicant Signature"
                    type="text"
                    value={data.primaryApplicant}
                    onChange={(e) => {
                      const input = e.currentTarget.name ? e.currentTarget : e.target;
                      setData({
                        ...data,
                        primaryApplicant: input.value,
                      });
                    }}
                  />
                  {accountType === 'J' && (
                    <TextField
                      required={true}
                      mt={20}
                      max={50}
                      name="coApplicantSignature"
                      label="Co-applicant signature here."
                      placeholder="Co-Applicant Signature"
                      type="text"
                      value={data.coApplicant}
                      onChange={(e) => {
                        const input = e.currentTarget.name ? e.currentTarget : e.target;
                        setData({
                          ...data,
                          coApplicant: input.value,
                        });
                      }}
                    />
                  )}
                </div>
              }
              open={open}
              onClose={handleCloseModal}
            />
          </form>
          <div className="stepper-buttons">
            <div className="grd-cell-none">
              <Button disabled={isLoading} label="Back" onClick={onBack} />
            </div>
            <div className="grd-cell">
              <Button
                loading={isLoading || isSubmitting}
                disabled={
                  isSubmitting ||
                  isLoading ||
                  (accountType !== 'J' && !data.primaryApplicant) ||
                  (accountType === 'J' && !data.primaryApplicant && !data.coApplicant)
                }
                label={isSubmitting ? 'Submitting...' : 'I Agree'}
                onClick={handleSubmit}
              />
            </div>
            <div className="grd-cell-none">
              <Button
                loading={isLoading || isSubmitting}
                disabled={isLoading || isSubmitting}
                label={isLoading ? 'Saving...' : 'Save and Exit'}
                onClick={onSaveAndExit}
              />
            </div>
          </div>
          {isSubmitting && (
            <div className="grd-row">
              <div>
                <Text
                  label="Please don't hit back button or refresh, this may take up to 60 seconds."
                  error={true}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="register-b-rs">
        <div className="graphics-w">
          <PDFApplicationForm
            data={pdfData}
            handleDocumentChange={handleDocumentChange}
            document={document}
            onboardingAttachment={onboardingAttachment}
            setAttachments={setOnboardingAttachment}
          />
        </div>
      </div>
    </div>
  );
}
