/*eslint eqeqeq: "off"*/
import {
  Form1042DivnraServiceClient,
  Form1042DivnraRequest,
  Form1042DivnraProcessRequest,
  DownLoadForm1042DivnraRequest,
} from '../proto/taxfilingpb/form1042_divnra_grpc_web_pb';
import {
  notifyError,
  notifySuccess,
} from '../components/Messages/Notification';
import { auth } from '../lib/auth/Auth';
import download from './DownloadService';
import { stringToProtoDate } from './ConvertService';

const service = new Form1042DivnraServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

const requestForm1042DivnraParameter = (param) => {
  let req = new Form1042DivnraRequest();
  req.setYear(param.year);
  req.setCorrespondent(param.correspondent);
  req.setOffice(param.branch);
  req.setAccountNo(param.accountNo);
  return req;
};

export async function listForm1042Divnra(param) {
  return new Promise((resolve, reject) => {
    const req = requestForm1042DivnraParameter(param);

    service.listForm1042Divnra(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listForm1042DivnraDetails(param) {
  return new Promise((resolve, reject) => {
    const req = new Form1042DivnraRequest();
    req.setYear(param.year);
    req.setCorrespondent(param.correspondent);
    req.setOffice(param.office);
    req.setAccountNo(param.accountNo);

    service.listForm1042DivnraDetails(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function form1042DivnraProcess(param) {
  return new Promise((resolve, reject) => {
    let req = new Form1042DivnraProcessRequest();
    req.setFromDate(stringToProtoDate(param.fromDate));
    req.setToDate(stringToProtoDate(param.toDate));
    service.form1042DivnraProcess(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function downloadDivnra(param, filetype, batchNo, downloadType) {
  return new Promise((resolve, reject) => {
    let request = new DownLoadForm1042DivnraRequest();
    request.setFileType(filetype);
    request.setBatchNo(batchNo);
    request.setDownloadType(downloadType);
    let req = new Form1042DivnraRequest();
    req.setYear(param.year);
    req.setCorrespondent(param.correspondent);
    req.setOffice(param.office);
    req.setAccountNo(param.accountNo);
    // req.setUsrId(param.usrId);
    request.setForm1042DivnraRequest(req);
    service.downloadDivnra(request, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        if (response) {
          download(response);
          resolve(response);
          if (response?.array[2]) {
            notifySuccess('Download ' + response.array[2] + ' completed');
          } else {
            notifySuccess('Download completed');
          }
        }
      }
    });
  });
}

export async function generateDivnra(param, filetype, batchNo, downloadType) {
  return new Promise((resolve, reject) => {
    let request = new DownLoadForm1042DivnraRequest();
    request.setFileType(filetype);
    request.setBatchNo(batchNo);
    request.setDownloadType(downloadType);
    let req = new Form1042DivnraRequest();
    req.setYear(param.year);
    req.setCorrespondent(param.correspondent);
    req.setOffice(param.office);
    req.setAccountNo(param.accountNo);
    req.setUsrId(param.usrId);
    request.setForm1042DivnraRequest(req);

    service.generateDivnra(request, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response);
      }
    });
  });
}
