import {
  Registration,
  RegistrationServiceClient,
  VerifyRegistrationRequest,
} from '../proto/usrpb/registration_grpc_web_pb';
import { SetTokens } from './AuthService';

const serviceNoAuth = new RegistrationServiceClient(window.env.GRPC_ENDPOINT);

const requestParameter = (param) => {
  let req = new Registration();
  req.setVerificationKey(param.verificationKey);
  req.setFirstName(param.firstName);
  req.setMiddleName(param.middleName);
  req.setLastName(param.lastName);
  req.setSuffix(param.suffix);
  req.setEmail(param.email);
  req.setPassword(param.password);
  req.setSecurityWord(param.securityWord);
  req.setSecurityWordHint(param.securityWordHint);
  req.setPromoCode(param.promoCode);
  req.setPhoneNumber(param.phoneNumber);

  return req;
};

export async function createRegistration(param) {
  return new Promise((resolve, reject) => {
    const req = requestParameter(param);

    serviceNoAuth.createRegistration(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function readRegistration(param) {
  return new Promise((resolve, reject) => {
    let req = new Registration();
    req.setVerificationKey(param.verificationKey);

    serviceNoAuth.readRegistration(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function verifyRegistration() {
  return new Promise((resolve, reject) => {
    let req = new VerifyRegistrationRequest();
    req.setVerificationKey(window.location.href.split('?')[1]);
    req.setUrl(window.location.origin);
    req.setClientId(window.env.GRPC_CLIENT_ID);

    serviceNoAuth.verifyRegistration(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        const { accessToken, refreshToken, userId, accountId, correspondent, accountNo } = response.toObject();
        SetTokens(accessToken, refreshToken, []);

        resolve({
          userId,
          accountId,
          correspondent,
          accountNo,
        });
      }
    });
  });
}
