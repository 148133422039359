import React, { useEffect, useState } from 'react';
import QueryParam from '../../services/QueryParamService'

export default function Impersonate() {
    const [data, setData] = React.useState(
        QueryParam.get({
          usr: '',
          token:''
        })
      );
  
    useEffect(() => {
        localStorage.clear();
        if (data.itoken !="" ) {
            localStorage.setItem('i_token', data.token);
            localStorage.setItem('i_user', data.usr);
        
            window.location='/loginuser';
        }
        
    }, []);
  
   return(<></>)
  
   
  }
  