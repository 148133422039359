import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment, IconButton, Tooltip } from '@material-ui/core';
import color from '../../assets/css/colors'
import {ReactComponent as Info} from '../../assets/icons/info.svg';
import {ReactComponent as Message} from '../../assets/icons/message.svg';

const useStyles = makeStyles(() => ({
    textFieldWrapper: {
        display: 'flex',
        position: 'relative',
    },
    tooltipWrapper: {
        position: 'absolute',
        top: 31,
        right: 1,
        padding: '9px 10px',
        backgroundColor: color.light.opacity,
        borderRadius: 5,
        '& svg': {
            width: 21,
            height: 21,
            cursor: 'help',
            '& path': {
                fill: color.primary.main,
            }
        },
    }
}));

const HtmlTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: color.light.main,
      maxWidth: 620,
      border: '1px solid ' + color.light.light,
      borderRadius: 10,
    },
}))(Tooltip);

const ChatField = (props) => {
    const classes = useStyles();

    const endIcon = () => {
        return (
            <InputAdornment position="end">
                <IconButton
                    onClick={() => console.log('sent')}
                >
                    <Message />
                </IconButton>
            </InputAdornment>
        );
    }

    return (
        <div className={classes.textFieldWrapper}>
            <TextField
                style={{marginTop: props.mt ? 20 : 0, marginBottom: props.mb ? props.mb : 0}}
                fullWidth={true}
                autoFocus={props.autoFocus}
                autoComplete="off"
                error={props.error}
                required={props.required}
                disabled={props.disabled}
                id={props.id}
                name={props.name}
                label={props.label}
                placeholder={props.placeholder}
                helperText={props.error ? props.msg : ''}
                type={"text"}
                value={props.value || ''}
                onChange={props.onChange}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    endAdornment: endIcon(),
                }}
                inputProps={{ 
                    readOnly: props.readOnly ? true : false,
                    maxLength: props.max,
                    autoComplete: 'off',
                }}
            />
            {(props.tooltip || props.htmlTooltip) && (
                <div className={classes.tooltipWrapper} style={{marginTop: props.mt ? 20 : 0}}>
                    {props.tooltip ? (
                        <Tooltip 
                            arrow
                            placement="top"
                            title={props.tooltip}
                        >
                            <Info />
                        </Tooltip>
                    ) : (
                        <HtmlTooltip 
                            placement="right"
                            title={<React.Fragment>{props.htmlTooltip}</React.Fragment>}
                        >
                            <Info />
                        </HtmlTooltip> 
                    )}
                </div>
            )}
        </div>
    );
}
export default ChatField;