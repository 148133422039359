import React from 'react';
import { Radio } from '@material-ui/core';
import PropTypes from 'prop-types';

const APWRadio = (props) => {
    
    const {...otherProps} = props;

    return (
        <Radio {...otherProps} color="primary" size="small" />
    );
}

APWRadio.propTypes = {};

APWRadio.defaultProps = Object.freeze({
    // Add other default props if needed
});



export default APWRadio;