import React from 'react';
import OwnerModal from '../../../components/Modals/OwnerModal';
import { CircularProgress, Box, IconButton } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import { notifySuccess, notifyError } from '../../../components/Messages/Notification';
import {
  Create as EditIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
} from '@material-ui/icons';
import Button from '../../../components/Buttons/Button';
import ClientGrid from '../../../components/ClientGrid/ClientGrid';
import {
  updateOwner,
  deleteOwnerId,
  readOwner,
} from '../../../services/AccountOwnerService';
import {
  pbDate,
  pbDateTime,
  currency,
  qty,
  number,
  htmlString,
  pbDateSorter,
  pbDateTimeSorter,
  boolean,
  percentage,
  missing,
  dateTimeString,
} from '../../../components/ClientGrid/CellRenderer';

export default function OwnerTable({
  accountId,
  list: rows,
  set: setRows,
  importMode: isImport,
}) {
  // const classes = useStyles();
  const confirm = useConfirm();
  const [rowData, setRowData] = React.useState({});
  const [validation, setValidation] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [loading, setLoading] = React.useState({
    save: false,
    delete: false,
  });
  const [currentSelectedRowsIndex] = React.useState([]);
  const options = {
    isRowSelectable: (dataIndex) => isDeleteEnabled(dataIndex),
    onRowsDelete: (selectedItems) => {
      handleDelete(selectedItems.data);
      return false;
    },
  };

  const GridButtons = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    return (
      <div>
        <div className="grd-cell">
          {/* <IconButton
            aria-label="delete"
            disabled={data.status === 'Inactive' || loading.delete}
            onClick={() => {
              handleDelete(data, rowIndex-1);
            }}
          >
            <DeleteIcon style={{ color: '#1e7dff', height: 20, width: 20 }} />
          </IconButton> */}
          <IconButton
            aria-label="edit"
            disabled={loading.delete}
            onClick={() => handleOpen(data)}
          >
            <EditIcon style={{ color: '#1e7dff', height: 20, width: 20 }} />
          </IconButton>
        </div>
      </div>
    );
  };

  const columns = [
    // {
    //   id: 'checkbox',
    //   pinned: true,
    //   width: '54px',
    //   visible: true,
    // },
    {
      id: '',
      field: '',
      width: '35px',
      cellRenderer: GridButtons,
    },
    {
      id: 'ownerType',
      field: 'ownerType',
      label: 'Owner Type',
      visible: true,
    },
    {
      id: 'firstName',
      field: 'firstName',
      label: 'First Name',
      visible: true,
    },
    {
      id: 'middleName',
      field: 'middleName',
      label: 'Middle Name',
      visible: true,
    },
    {
      id: 'lastName',
      field: 'lastName',
      label: 'Last Name',
      visible: true,
    },
    {
      id: 'country',
      field: 'country',
      label: 'Country',
      visible: true,
    },
    {
      id: 'citizenship',
      field: 'citizenship',
      label: 'Citizenship',
      visible: true,
    },
    {
      id: 'dateOfBirth',
      field: 'dateOfBirth',
      label: 'Date Of Birth',
      visible: true,
      cellRenderer: pbDate,
      sort: pbDateSorter,
    },
    {
      id: 'employmentStatus',
      field: 'employmentStatus',
      label: 'Employment Status',
      visible: true,
    },
    {
      id: 'employmentName',
      field: 'employmentName',
      label: 'Employment Name',
      visible: true,
    },
    {
      id: 'employmentOccupation',
      field: 'employmentOccupation',
      label: 'Employment Occupation',
      visible: true,
    },
    {
      id: 'ownership',
      field: 'ownership',
      label: 'Ownership',
      visible: true,
      options: {
        display: false,
        customBodyRenderLite: (dataIndex) => {
          return rows[dataIndex].ownership ? <CheckCircleIcon /> : null;
        },
      },
    },
    {
      id: 'stockOwnership',
      field: 'stockOwnership',
      label: 'Stock Symbol',
      visible: true,
    },
    {
      id: 'brokerMember',
      field: 'brokerMember',
      label: 'Broker Member',
      visible: true,
      options: {
        display: false,
        customBodyRenderLite: (dataIndex) => {
          return rows[dataIndex].brokerMember ? <CheckCircleIcon /> : null;
        },
      },
    },
    {
      id: 'brokerName',
      field: 'brokerName',
      label: 'Broker Name',
      visible: true,
    },
    {
      id: 'affiliatePerson',
      field: 'affiliatePerson',
      label: 'Affiliated Person',
      visible: true,
    },
    {
      id: 'affiliatePersonPosition',
      field: 'affiliatePersonPosition',
      label: 'Affiliated Person Position',
      visible: true,
    },
    {
      id: 'status',
      field: 'status',
      label: 'Status',
      visible: true,
    },
    {
      id: 'ofacStatus',
      field: 'ofacStatus',
      label: 'OFAC Status',
      visible: true,
    },
    {
      id: 'ofacDate',
      field: 'ofacDate',
      label: 'OFAC Date',
      cellRenderer: pbDate,
      sort: pbDateSorter,
      visible: true,
    },
    {
      id: 'kycStatus',
      field: 'kycStatus',
      label: 'KYC Status',
      visible: true,
    },
    {
      id: 'kycDate',
      field: 'kycDate',
      label: 'KYC Date',
      visible: true,
      cellRenderer: pbDate,
      sort: pbDateSorter,
    },
    {
      id: 'officer',
      field: 'officer',
      label: 'Officer',
      visible: true,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return rows[dataIndex].officer ? <CheckCircleIcon /> : null;
        },
      },
    },
    {
      id: 'owner',
      field: 'owner',
      label: 'Owner',
      visible: true,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return rows[dataIndex].owner ? <CheckCircleIcon /> : null;
        },
      },
    },
    {
      id: 'ownershipPercent',
      field: 'ownershipPercent',
      label: 'Ownership Percent',
      visible: true,
    },
    {
      id: 'relationship',
      field: 'relationship',
      label: 'Relationship',
      visible: true,
    },
    {
      id: 'maritalStatus',
      field: 'maritalStatus',
      label: 'Marital Status',
      visible: true,
    },
    {
      id: 'natureOfBusiness',
      field: 'natureOfBusiness',
      label: 'Nature Of Business',
      visible: true,
    },
    {
      id: 'yearsEmployed',
      field: 'yearsEmployed',
      label: 'Years Employed',
      visible: true,
    },
  ];

  const isDeleteEnabled = (dataIndex) => {
    return rows[dataIndex]?.status !== 'Inactive';
  };

  const handleDelete = (data, index) => {
    confirm({
      description:
        "You are about to delete '" + data.firstName + "'. Please confirm your action.",
      confirmationText: 'Yes, disable',
    }).then(async () => {
      const rowsCopy = [...rows];
      try {
        //setCurrentSelectedRowsIndex(index);
        setLoading({ ...loading, delete: true });

        await deleteOwnerId(data.ownerId).then((res) => {
          rowsCopy[index] = res.owner;
        });
      } catch (error) {
        notifyError(error.message);
      } finally {
        //setCurrentSelectedRowsIndex([]);
        setLoading({ ...loading, delete: false });
        setRows(rowsCopy);
        notifySuccess(data.firstName + ' Owner has been disabled');
      }
    });
  };

  const handleOpen = (data) => {
    if (!data) {
      // ADD default values
      data = {
        ownerId: 0,
        accountId: accountId,
        ownerType: 'Beneficiary',
        firstName: '',
        middleName: '',
        lastName: '',
        citizenship: 'US',
        dateOfBirth: '',
        status: 'Active',
        employmentStatus: '',
        employmentOccupation: '',
        employmentName: '',
        ownership: false,
        stockOwnership: '',
        brokerMember: false,
        brokerName: '',
        affiliatePerson: '',
        affiliatePersonPosition: '',
        ofacStatus: 'Pending',
        ofacDate: '',
        kycStatus: 'Pending',
        kycDate: '',
        imgSignature: '',
        officer: false,
        owner: false,
        ownershipPercent: '',
        investmentObjectiveCode: '',
        investmentObjectiveDate: '',
        relationship: '',
        maritalStatus: '',
        natureOfBusiness: '',
        yearsEmployed: '',
      };

      setEditMode(false);
    } else {
      setEditMode(true);
    }
    setRowData(data);
    setOpen(true);
  };

  const validateDateOfBirth = (date) => {
    let splitedDate = date.split('-');
    let dateOfBirth = new Date(
      splitedDate[1] + '/' + splitedDate[2] + '/' + splitedDate[0]
    );
    let currentDate = new Date();

    if (currentDate.getFullYear() - dateOfBirth.getFullYear() < 18) {
      return false;
    }

    if (currentDate.getFullYear() - dateOfBirth.getFullYear() === 18) {
      if (currentDate.getMonth() < dateOfBirth.getMonth()) {
        return false;
      }
      if (currentDate.getMonth() === dateOfBirth.getMonth()) {
        if (currentDate.getDate() < dateOfBirth.getDate()) {
          return false;
        }
      }
    }

    return true;
  };

  const handleClose = async (data, isEdit, linkedOwnerId, piiAccess) => {
    if (linkedOwnerId && !isEdit) {
      try {
        const { owner } = await readOwner(linkedOwnerId, accountId);
        setRows([owner, ...rows]);
        setOpen(false);
      } catch (error) {
        console.error(error);
      }
      return;
    }

    if (!data) {
      setOpen(false);
      setValidation({});
      return;
    }

    let valid = true;

    setValidation({
      ownerType: !data.ownerType,
      firstName: !data.firstName,
      lastName: !data.lastName,
      citizenship: !data.citizenship && data.ownerType !== 'Trusted Contact',
      status: !data.status,
      dateOfBirth:
        (!data.dateOfBirth &&
          data.ownerType !== 'Trusted Contact' &&
          data.ownerType !== 'Beneficiary') ||
        (data.ownerType === 'Primary' &&
          data.dateOfBirth &&
          validateDateOfBirth(data.dateOfBirth) === false),
      employmentStatus: !data.employmentStatus && data.ownerType !== 'Trusted Contact',
      stockOwnership: data.ownership === true ? !data.stockOwnership : false,
      stockCompany: data.ownership === true ? !data.stockCompany : false,
      stockPosition: data.ownership === true ? !data.stockPosition : false,
      stockPerson: data.ownership === true ? !data.stockPerson : false,
      brokerName: data.brokerMember === true ? !data.brokerName : false,
      affiliatePerson: data.brokerMember === true ? !data.affiliatePerson : false,
      affiliatePersonPosition:
        data.brokerMember === true ? !data.affiliatePersonPosition : false,
      ofacDate:
        data.ownerType !== 'Contingent Beneficiary' &&
        data.ownerType !== 'Trusted Contact' &&
        data.ownerType !== 'Beneficiary'
          ? !data.ofacDate
          : false,
      kycDate:
        data.ownerType !== 'Contingent Beneficiary' &&
        data.ownerType !== 'Trusted Contact' &&
        data.ownerType !== 'Beneficiary'
          ? !data.kycDate
          : false,
    });

    if (!data.ownerType) {
      notifyError('Owner Type is required.');
      valid = false;
    }
    if (!data.firstName) {
      notifyError('First Name is required.');
      valid = false;
    }
    if (!data.lastName) {
      notifyError('Last Name is required.');
      valid = false;
    }
    if (!data.citizenship) {
      notifyError('Citizenship is required.');
      valid = false;
    }
    if (!data.status) {
      notifyError('Status is required.');
      valid = false;
    }
    if (data.owner === true) {
      if (!data.ownershipPercent) {
        notifyError('Ownership percent is required.');
        valid = false;
      }
    }

    if (data.brokerMember === true) {
      if (!data.brokerName) {
        notifyError('Company Name is required.');
        valid = false;
      }

      if (!data.affiliatePerson) {
        notifyError('Person Affiliated is required.');
        valid = false;
      }

      if (!data.affiliatePersonPosition) {
        notifyError('Position of Affiliated Person is required.');
        valid = false;
      }
    }

    if (!valid) {
      return;
    }

    setLoading({ ...loading, save: true });
    if (data.status !== 'Inactive') {
      data.inactiveStatusReason = '';
    }
    if (data.employmentStatus !== 'Employed') {
      data.employmentOccupation = '';
      data.employmentName = '';
      data.natureOfBusiness = '';
      data.yearsEmployed = 0;
    }
    try {
      await updateOwner(accountId, data).then((res) => {
        if (linkedOwnerId || !isEdit) {
          // ADD or Linked
          res.owner.piiAccess = piiAccess;
          setRows([res.owner, ...rows]);
          notifySuccess('New Owner has been added.');
        } else {
          // EDIT
          let index = rows.indexOf(rowData);
          let rowsCopy = [...rows];
          rowsCopy[index] = res.owner;
          rowsCopy[index].piiAccess = piiAccess;
          setRows(rowsCopy);
          notifySuccess('Owner has been updated.');
        }

        setValidation({});
        setOpen(false);
      });
    } catch (error) {
      setLoading({ ...loading, save: false });
      notifyError(error.message);
      console.error(error);
    }

    setLoading({ ...loading, save: false });
  };

  const getCsvData = async () => {
    // const data = await readOwner(searchData)
    // return data.ticketsList
  };

  return (
    <div className="page-c" style={{ marginTop: -50 }}>
      <Box component="search-c">
        <div className="grd-row nm">
          <div className="grd-cell-none">
            <Button
              type="plus"
              label="Add New Owner"
              onClick={() => {
                handleOpen();
              }}
            />
          </div>
        </div>
      </Box>
      <div className="mt-20">
        <ClientGrid
          title="Owners"
          rowIdField="ownerId"
          columns={columns}
          rows={rows}
          getCsvData={getCsvData}
          isLoading={loading}
          setRows={setRows}
        />
      </div>
      {open && (
        <OwnerModal
          onClose={handleClose}
          open={open}
          value={rowData}
          importMode={isImport}
          editMode={editMode}
          validation={validation}
          loading={loading.save}
        ></OwnerModal>
      )}{' '}
    </div>
  );
}
