import React, { useState, useEffect } from 'react';

import {
  Modal,
  Backdrop,
  Fade,
  makeStyles,
} from '@material-ui/core';
import Text from '../Typography/Text'
import TextField from '../Textfields/TextField'
import Button from '../Buttons/Button'
import fontFamily from '../../assets/css/fonts'
import color from '../../assets/css/colors'
import CloseButton from '../Buttons/CloseButton'
import { generateKey, generateTotpUri } from 'authenticator';
import QRCode from 'qrcode.react';
import { readAdministrator } from '../../services/AdministratorService';
import { notifyError } from '../Messages/Notification';

const useStyles = makeStyles(() => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: 800,
      width: '100%',
      margin: '0 auto',
      float: 'clear',
    },
    modalHeader: {
      backgroundColor: 'white',
      padding: 25,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      display: 'flex',
      '& div:nth-child(1)': {
        flex: 1,
      },
      '& div:nth-child(2)': {
        flex: 'none',
        '& .MuiIconButton-root': {
          borderRadius: 5,
          padding: 10,
          '&:hover svg path': {
            fill: color.primary.main,
          },
        },
        '& svg:last-child': {
          width: 15,
          height: 15,
        },
      },
    },
    modalBody: {
      backgroundColor: 'white',
      padding: '0px 25px 25px 25px',
      fontFamily: fontFamily.headers,
      '& p': {
        fontSize: 16,
        lineHeight: '24px',
      },
    },
    modalFooter: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      backgroundColor: 'white',
      padding: '0px 25px 25px 25px',
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      position: 'relative',
      '& button': {
        width: 'auto',
      },
    },
    closeButton: {
      '& button': {
        marginTop: '-4px !important',
      },
    },
  }))
  

export default function MultiFactorAuthenticationModal({
  onClose,
  open: isOpen,
  id,
  loading
}) {
  const classes = useStyles();
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    if (isOpen && id) {
        const init = async () => {
            try{
                const { administrator } = await readAdministrator(id);
                let value = administrator;
                value.correspondent = value.correspondent.trim();
                value.otPath = '';
                value.code = '';
    
                if (value.secretKey == '') {
                    value.secretKey = generateKey();
                    value.new = true;
                } else {
                    value.new = false;
                }
                const ot = generateTotpUri(
                    value.secretKey,
                    value.email.toLowerCase(),
                    'SAS',
                    'SHA1',
                    6,
                    30
                );
    
                value.otPath = ot;
                setModalData({ ...value });
            } catch (error){
                notifyError(error);
                console.error(error);
            }
        }

        init();
    }
  }, [isOpen, id]);

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;
    setModalData({
      ...modalData,
      [input.name]: input.value,
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={() => onClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div style={{ width: 800 }}>
          <div className={classes.modalHeader}>
            <div>
              <Text
                mt={4}
                variant="h2"
                label={(modalData.new ? 'Setup new ' : 'Existing ') + '2-Factor Authentication'} 
              />
            </div>
            <div className={classes.closeButton}>
              <CloseButton onClick={() => onClose()} />
            </div>
          </div>
          <div className={classes.modalBody}>
            <div className="grd-row nm">
              <div className="grd-cell"
                  style={{ textAlign: 'center' }}>
                <h5> Scan with your authenticator app </h5>
                  {modalData.otPath != '' && (
                    <QRCode value={modalData.otPath} />
                  )}
              </div>
            </div>
            <div className="grd-row nm">
              <div className="grd-cell" 
                  style={{ textAlign: 'center' }}>
                    <TextField
                        fullWidth
                        required
                        name="code"
                        label="Enter Validation Code"
                        value={modalData.code}
                        onChange={handleChange}
                        inputProps={{ maxLength: 100 }}
                        InputLabelProps={{ shrink: true }}
                    />
              </div>
            </div>
          </div>
          <div className={classes.modalFooter}>
              <div
                className={classes.grdCellNone}
                style={{ marginRight: 10 }}
              >
                <Button
                    disabled={loading}
                    loading={loading}
                    color="secondary"
                    label={loading ? 'Validating...' : 'Validate'}
                    onClick={() => {
                    onClose(modalData, 'verify');
                    }}
                />
              </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
