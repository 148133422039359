// source: proto/tradepb/orderentry.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_type_date_pb = require('../../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.tradepb.AccountDetails', null, global);
goog.exportSymbol('proto.tradepb.AmountBreakdown', null, global);
goog.exportSymbol('proto.tradepb.ClientInputRequest', null, global);
goog.exportSymbol('proto.tradepb.DuplicateTradeRequest', null, global);
goog.exportSymbol('proto.tradepb.Empty', null, global);
goog.exportSymbol('proto.tradepb.GetAccountDetailsRequest', null, global);
goog.exportSymbol('proto.tradepb.GetIceSymbolRequest', null, global);
goog.exportSymbol('proto.tradepb.GetIceSymbolResponse', null, global);
goog.exportSymbol('proto.tradepb.GetOptionDetailsRequest', null, global);
goog.exportSymbol('proto.tradepb.GetOrderEntryRequest', null, global);
goog.exportSymbol('proto.tradepb.GetPositionDetailsRequest', null, global);
goog.exportSymbol('proto.tradepb.GetPositionsRequest', null, global);
goog.exportSymbol('proto.tradepb.GetPositionsResponse', null, global);
goog.exportSymbol('proto.tradepb.GetSymbolDetailsRequest', null, global);
goog.exportSymbol('proto.tradepb.GetTradingPrivilegeRequest', null, global);
goog.exportSymbol('proto.tradepb.LastTrades', null, global);
goog.exportSymbol('proto.tradepb.ListAmountBreakdownRequest', null, global);
goog.exportSymbol('proto.tradepb.ListAmountBreakdownResponse', null, global);
goog.exportSymbol('proto.tradepb.ListOrderEntryRequest', null, global);
goog.exportSymbol('proto.tradepb.ListOrderEntryResponse', null, global);
goog.exportSymbol('proto.tradepb.ListOrdersByIDRequest', null, global);
goog.exportSymbol('proto.tradepb.ListPendingBatchOrdersRequest', null, global);
goog.exportSymbol('proto.tradepb.ListPendingOrderEntryResponse', null, global);
goog.exportSymbol('proto.tradepb.MovePendingRequest', null, global);
goog.exportSymbol('proto.tradepb.MoveTradesRequest', null, global);
goog.exportSymbol('proto.tradepb.OptionDetails', null, global);
goog.exportSymbol('proto.tradepb.OrderEntry', null, global);
goog.exportSymbol('proto.tradepb.PendingOrderEntry', null, global);
goog.exportSymbol('proto.tradepb.Position', null, global);
goog.exportSymbol('proto.tradepb.PositionDetails', null, global);
goog.exportSymbol('proto.tradepb.SymbolDetails', null, global);
goog.exportSymbol('proto.tradepb.Totals', null, global);
goog.exportSymbol('proto.tradepb.TradeRequest', null, global);
goog.exportSymbol('proto.tradepb.TradingPrivilege', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.OrderEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.OrderEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.OrderEntry.displayName = 'proto.tradepb.OrderEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.PendingOrderEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.PendingOrderEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.PendingOrderEntry.displayName = 'proto.tradepb.PendingOrderEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.AccountDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.AccountDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.AccountDetails.displayName = 'proto.tradepb.AccountDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.AmountBreakdown = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.AmountBreakdown, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.AmountBreakdown.displayName = 'proto.tradepb.AmountBreakdown';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.TradingPrivilege = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.TradingPrivilege, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.TradingPrivilege.displayName = 'proto.tradepb.TradingPrivilege';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.GetTradingPrivilegeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.GetTradingPrivilegeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.GetTradingPrivilegeRequest.displayName = 'proto.tradepb.GetTradingPrivilegeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.ListAmountBreakdownRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tradepb.ListAmountBreakdownRequest.repeatedFields_, null);
};
goog.inherits(proto.tradepb.ListAmountBreakdownRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.ListAmountBreakdownRequest.displayName = 'proto.tradepb.ListAmountBreakdownRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.ListAmountBreakdownResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tradepb.ListAmountBreakdownResponse.repeatedFields_, null);
};
goog.inherits(proto.tradepb.ListAmountBreakdownResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.ListAmountBreakdownResponse.displayName = 'proto.tradepb.ListAmountBreakdownResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.GetAccountDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.GetAccountDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.GetAccountDetailsRequest.displayName = 'proto.tradepb.GetAccountDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.GetOrderEntryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.GetOrderEntryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.GetOrderEntryRequest.displayName = 'proto.tradepb.GetOrderEntryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.ListOrderEntryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.ListOrderEntryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.ListOrderEntryRequest.displayName = 'proto.tradepb.ListOrderEntryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.ListOrderEntryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tradepb.ListOrderEntryResponse.repeatedFields_, null);
};
goog.inherits(proto.tradepb.ListOrderEntryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.ListOrderEntryResponse.displayName = 'proto.tradepb.ListOrderEntryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.ListPendingOrderEntryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tradepb.ListPendingOrderEntryResponse.repeatedFields_, null);
};
goog.inherits(proto.tradepb.ListPendingOrderEntryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.ListPendingOrderEntryResponse.displayName = 'proto.tradepb.ListPendingOrderEntryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.Empty.displayName = 'proto.tradepb.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.MovePendingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tradepb.MovePendingRequest.repeatedFields_, null);
};
goog.inherits(proto.tradepb.MovePendingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.MovePendingRequest.displayName = 'proto.tradepb.MovePendingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.MoveTradesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tradepb.MoveTradesRequest.repeatedFields_, null);
};
goog.inherits(proto.tradepb.MoveTradesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.MoveTradesRequest.displayName = 'proto.tradepb.MoveTradesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.TradeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.TradeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.TradeRequest.displayName = 'proto.tradepb.TradeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.ListPendingBatchOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tradepb.ListPendingBatchOrdersRequest.repeatedFields_, null);
};
goog.inherits(proto.tradepb.ListPendingBatchOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.ListPendingBatchOrdersRequest.displayName = 'proto.tradepb.ListPendingBatchOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.ListOrdersByIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tradepb.ListOrdersByIDRequest.repeatedFields_, null);
};
goog.inherits(proto.tradepb.ListOrdersByIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.ListOrdersByIDRequest.displayName = 'proto.tradepb.ListOrdersByIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.OptionDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.OptionDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.OptionDetails.displayName = 'proto.tradepb.OptionDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.GetOptionDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.GetOptionDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.GetOptionDetailsRequest.displayName = 'proto.tradepb.GetOptionDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.SymbolDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.SymbolDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.SymbolDetails.displayName = 'proto.tradepb.SymbolDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.GetSymbolDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.GetSymbolDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.GetSymbolDetailsRequest.displayName = 'proto.tradepb.GetSymbolDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.LastTrades = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tradepb.LastTrades.repeatedFields_, null);
};
goog.inherits(proto.tradepb.LastTrades, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.LastTrades.displayName = 'proto.tradepb.LastTrades';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.Totals = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.Totals, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.Totals.displayName = 'proto.tradepb.Totals';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.ClientInputRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.ClientInputRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.ClientInputRequest.displayName = 'proto.tradepb.ClientInputRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.PositionDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.PositionDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.PositionDetails.displayName = 'proto.tradepb.PositionDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.GetPositionDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.GetPositionDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.GetPositionDetailsRequest.displayName = 'proto.tradepb.GetPositionDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.GetIceSymbolRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.GetIceSymbolRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.GetIceSymbolRequest.displayName = 'proto.tradepb.GetIceSymbolRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.GetIceSymbolResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.GetIceSymbolResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.GetIceSymbolResponse.displayName = 'proto.tradepb.GetIceSymbolResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.Position = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.Position, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.Position.displayName = 'proto.tradepb.Position';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.GetPositionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.GetPositionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.GetPositionsRequest.displayName = 'proto.tradepb.GetPositionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.GetPositionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tradepb.GetPositionsResponse.repeatedFields_, null);
};
goog.inherits(proto.tradepb.GetPositionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.GetPositionsResponse.displayName = 'proto.tradepb.GetPositionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.DuplicateTradeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tradepb.DuplicateTradeRequest.repeatedFields_, null);
};
goog.inherits(proto.tradepb.DuplicateTradeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.DuplicateTradeRequest.displayName = 'proto.tradepb.DuplicateTradeRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.OrderEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.OrderEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.OrderEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.OrderEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    row: jspb.Message.getFieldWithDefault(msg, 1, 0),
    orderEntryId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    accountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    action: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dollar: jspb.Message.getFieldWithDefault(msg, 6, ""),
    share: jspb.Message.getFieldWithDefault(msg, 7, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 8, ""),
    symbolDescription: jspb.Message.getFieldWithDefault(msg, 9, ""),
    assetType: jspb.Message.getFieldWithDefault(msg, 10, ""),
    divRei: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    plusCommission: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    orderType: jspb.Message.getFieldWithDefault(msg, 13, ""),
    stopPrice: jspb.Message.getFieldWithDefault(msg, 14, ""),
    limitPrice: jspb.Message.getFieldWithDefault(msg, 15, ""),
    tif: jspb.Message.getFieldWithDefault(msg, 16, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 17, ""),
    rep: jspb.Message.getFieldWithDefault(msg, 18, ""),
    createdDate: jspb.Message.getFieldWithDefault(msg, 19, ""),
    createdTime: jspb.Message.getFieldWithDefault(msg, 20, ""),
    price: jspb.Message.getFieldWithDefault(msg, 21, ""),
    positionQty: jspb.Message.getFieldWithDefault(msg, 22, ""),
    amt: jspb.Message.getFieldWithDefault(msg, 23, ""),
    cashBalance: jspb.Message.getFieldWithDefault(msg, 24, ""),
    roundDown: jspb.Message.getFieldWithDefault(msg, 25, 0),
    note: jspb.Message.getFieldWithDefault(msg, 26, ""),
    clientInput: jspb.Message.getFieldWithDefault(msg, 27, ""),
    qty: jspb.Message.getFieldWithDefault(msg, 28, ""),
    underlyingPosition: jspb.Message.getFieldWithDefault(msg, 29, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 30, ""),
    batchNo: jspb.Message.getFieldWithDefault(msg, 31, ""),
    positionValue: jspb.Message.getFieldWithDefault(msg, 32, ""),
    userNote: jspb.Message.getFieldWithDefault(msg, 33, ""),
    settlementType: jspb.Message.getFieldWithDefault(msg, 34, ""),
    linkedTradeId: jspb.Message.getFieldWithDefault(msg, 35, 0),
    solicited: jspb.Message.getBooleanFieldWithDefault(msg, 36, false),
    systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    modifiedDate: (f = msg.getModifiedDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    symbol2: jspb.Message.getFieldWithDefault(msg, 39, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 40, 0),
    batchNo2: jspb.Message.getFieldWithDefault(msg, 41, ""),
    trailingStop: jspb.Message.getFieldWithDefault(msg, 42, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 43, ""),
    nextDayExecution: jspb.Message.getBooleanFieldWithDefault(msg, 44, false),
    systemTime: (f = msg.getSystemTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    option: jspb.Message.getFieldWithDefault(msg, 46, ""),
    primeBroker: jspb.Message.getFieldWithDefault(msg, 47, ""),
    marginAgreement: jspb.Message.getFieldWithDefault(msg, 48, ""),
    stage: jspb.Message.getFieldWithDefault(msg, 49, ""),
    filePathPdf: jspb.Message.getFieldWithDefault(msg, 50, ""),
    costPerShare: jspb.Message.getFieldWithDefault(msg, 51, ""),
    securityType: jspb.Message.getFieldWithDefault(msg, 52, ""),
    accountType: jspb.Message.getFieldWithDefault(msg, 53, ""),
    modifiedTime: (f = msg.getModifiedTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    estRealizedPl: jspb.Message.getFieldWithDefault(msg, 55, ""),
    priceSrc: jspb.Message.getFieldWithDefault(msg, 56, ""),
    credit: jspb.Message.getFieldWithDefault(msg, 57, ""),
    maturity: jspb.Message.getFieldWithDefault(msg, 58, ""),
    type: jspb.Message.getFieldWithDefault(msg, 59, ""),
    state: jspb.Message.getFieldWithDefault(msg, 60, ""),
    ytc: jspb.Message.getFieldWithDefault(msg, 61, ""),
    ytw: jspb.Message.getFieldWithDefault(msg, 62, ""),
    maturityPeriod: jspb.Message.getFieldWithDefault(msg, 63, ""),
    coupon: jspb.Message.getFieldWithDefault(msg, 64, ""),
    earlySettlementClientApproved: jspb.Message.getBooleanFieldWithDefault(msg, 65, false),
    restricted: jspb.Message.getBooleanFieldWithDefault(msg, 66, false),
    symbolConverted: jspb.Message.getBooleanFieldWithDefault(msg, 67, false),
    tradeConfirm: jspb.Message.getFieldWithDefault(msg, 68, ""),
    lastPrice: jspb.Message.getFieldWithDefault(msg, 80, ""),
    expirationDate: (f = msg.getExpirationDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    putCall: jspb.Message.getFieldWithDefault(msg, 82, ""),
    strikePrice: jspb.Message.getFieldWithDefault(msg, 83, ""),
    signIndicator: jspb.Message.getFieldWithDefault(msg, 84, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.OrderEntry}
 */
proto.tradepb.OrderEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.OrderEntry;
  return proto.tradepb.OrderEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.OrderEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.OrderEntry}
 */
proto.tradepb.OrderEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRow(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrderEntryId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAction(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDollar(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setShare(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolDescription(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetType(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDivRei(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPlusCommission(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderType(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setStopPrice(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setLimitPrice(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setTif(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setRep(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedDate(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedTime(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setPositionQty(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmt(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setCashBalance(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRoundDown(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientInput(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnderlyingPosition(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchNo(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setPositionValue(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserNote(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlementType(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLinkedTradeId(value);
      break;
    case 36:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSolicited(value);
      break;
    case 37:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 38:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setModifiedDate(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol2(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchNo2(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailingStop(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 44:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNextDayExecution(value);
      break;
    case 45:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSystemTime(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.setOption(value);
      break;
    case 47:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimeBroker(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarginAgreement(value);
      break;
    case 49:
      var value = /** @type {string} */ (reader.readString());
      msg.setStage(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilePathPdf(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.setCostPerShare(value);
      break;
    case 52:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecurityType(value);
      break;
    case 53:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountType(value);
      break;
    case 54:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModifiedTime(value);
      break;
    case 55:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstRealizedPl(value);
      break;
    case 56:
      var value = /** @type {string} */ (reader.readString());
      msg.setPriceSrc(value);
      break;
    case 57:
      var value = /** @type {string} */ (reader.readString());
      msg.setCredit(value);
      break;
    case 58:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaturity(value);
      break;
    case 59:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 60:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 61:
      var value = /** @type {string} */ (reader.readString());
      msg.setYtc(value);
      break;
    case 62:
      var value = /** @type {string} */ (reader.readString());
      msg.setYtw(value);
      break;
    case 63:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaturityPeriod(value);
      break;
    case 64:
      var value = /** @type {string} */ (reader.readString());
      msg.setCoupon(value);
      break;
    case 65:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEarlySettlementClientApproved(value);
      break;
    case 66:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRestricted(value);
      break;
    case 67:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSymbolConverted(value);
      break;
    case 68:
      var value = /** @type {string} */ (reader.readString());
      msg.setTradeConfirm(value);
      break;
    case 80:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastPrice(value);
      break;
    case 81:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setExpirationDate(value);
      break;
    case 82:
      var value = /** @type {string} */ (reader.readString());
      msg.setPutCall(value);
      break;
    case 83:
      var value = /** @type {string} */ (reader.readString());
      msg.setStrikePrice(value);
      break;
    case 84:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignIndicator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.OrderEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.OrderEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.OrderEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.OrderEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRow();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getOrderEntryId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAction();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDollar();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getShare();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSymbolDescription();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAssetType();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDivRei();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getPlusCommission();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getOrderType();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getStopPrice();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getLimitPrice();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getTif();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getRep();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getCreatedDate();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getCreatedTime();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getPositionQty();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getAmt();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getCashBalance();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getRoundDown();
  if (f !== 0) {
    writer.writeUint32(
      25,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getClientInput();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getUnderlyingPosition();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getBatchNo();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getPositionValue();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getUserNote();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getSettlementType();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getLinkedTradeId();
  if (f !== 0) {
    writer.writeUint32(
      35,
      f
    );
  }
  f = message.getSolicited();
  if (f) {
    writer.writeBool(
      36,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      37,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getModifiedDate();
  if (f != null) {
    writer.writeMessage(
      38,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getSymbol2();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      40,
      f
    );
  }
  f = message.getBatchNo2();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getTrailingStop();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getNextDayExecution();
  if (f) {
    writer.writeBool(
      44,
      f
    );
  }
  f = message.getSystemTime();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOption();
  if (f.length > 0) {
    writer.writeString(
      46,
      f
    );
  }
  f = message.getPrimeBroker();
  if (f.length > 0) {
    writer.writeString(
      47,
      f
    );
  }
  f = message.getMarginAgreement();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getStage();
  if (f.length > 0) {
    writer.writeString(
      49,
      f
    );
  }
  f = message.getFilePathPdf();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getCostPerShare();
  if (f.length > 0) {
    writer.writeString(
      51,
      f
    );
  }
  f = message.getSecurityType();
  if (f.length > 0) {
    writer.writeString(
      52,
      f
    );
  }
  f = message.getAccountType();
  if (f.length > 0) {
    writer.writeString(
      53,
      f
    );
  }
  f = message.getModifiedTime();
  if (f != null) {
    writer.writeMessage(
      54,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEstRealizedPl();
  if (f.length > 0) {
    writer.writeString(
      55,
      f
    );
  }
  f = message.getPriceSrc();
  if (f.length > 0) {
    writer.writeString(
      56,
      f
    );
  }
  f = message.getCredit();
  if (f.length > 0) {
    writer.writeString(
      57,
      f
    );
  }
  f = message.getMaturity();
  if (f.length > 0) {
    writer.writeString(
      58,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      59,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      60,
      f
    );
  }
  f = message.getYtc();
  if (f.length > 0) {
    writer.writeString(
      61,
      f
    );
  }
  f = message.getYtw();
  if (f.length > 0) {
    writer.writeString(
      62,
      f
    );
  }
  f = message.getMaturityPeriod();
  if (f.length > 0) {
    writer.writeString(
      63,
      f
    );
  }
  f = message.getCoupon();
  if (f.length > 0) {
    writer.writeString(
      64,
      f
    );
  }
  f = message.getEarlySettlementClientApproved();
  if (f) {
    writer.writeBool(
      65,
      f
    );
  }
  f = message.getRestricted();
  if (f) {
    writer.writeBool(
      66,
      f
    );
  }
  f = message.getSymbolConverted();
  if (f) {
    writer.writeBool(
      67,
      f
    );
  }
  f = message.getTradeConfirm();
  if (f.length > 0) {
    writer.writeString(
      68,
      f
    );
  }
  f = message.getLastPrice();
  if (f.length > 0) {
    writer.writeString(
      80,
      f
    );
  }
  f = message.getExpirationDate();
  if (f != null) {
    writer.writeMessage(
      81,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getPutCall();
  if (f.length > 0) {
    writer.writeString(
      82,
      f
    );
  }
  f = message.getStrikePrice();
  if (f.length > 0) {
    writer.writeString(
      83,
      f
    );
  }
  f = message.getSignIndicator();
  if (f.length > 0) {
    writer.writeString(
      84,
      f
    );
  }
};


/**
 * optional uint64 row = 1;
 * @return {number}
 */
proto.tradepb.OrderEntry.prototype.getRow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setRow = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 order_entry_id = 2;
 * @return {number}
 */
proto.tradepb.OrderEntry.prototype.getOrderEntryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setOrderEntryId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string action = 5;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setAction = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string dollar = 6;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getDollar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setDollar = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string share = 7;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getShare = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setShare = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string symbol = 8;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string symbol_description = 9;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getSymbolDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setSymbolDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string asset_type = 10;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getAssetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setAssetType = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool div_rei = 11;
 * @return {boolean}
 */
proto.tradepb.OrderEntry.prototype.getDivRei = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setDivRei = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool plus_commission = 12;
 * @return {boolean}
 */
proto.tradepb.OrderEntry.prototype.getPlusCommission = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setPlusCommission = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional string order_type = 13;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getOrderType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setOrderType = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string stop_price = 14;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getStopPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setStopPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string limit_price = 15;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getLimitPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setLimitPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string tif = 16;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getTif = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setTif = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string account_name = 17;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string rep = 18;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getRep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setRep = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string created_date = 19;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getCreatedDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setCreatedDate = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string created_time = 20;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getCreatedTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setCreatedTime = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string price = 21;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string position_qty = 22;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getPositionQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setPositionQty = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string amt = 23;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string cash_balance = 24;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional uint32 round_down = 25;
 * @return {number}
 */
proto.tradepb.OrderEntry.prototype.getRoundDown = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setRoundDown = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional string note = 26;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string client_input = 27;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getClientInput = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setClientInput = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string qty = 28;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string underlying_position = 29;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getUnderlyingPosition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setUnderlyingPosition = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string created_by = 30;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string batch_no = 31;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getBatchNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setBatchNo = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string position_value = 32;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getPositionValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setPositionValue = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string user_note = 33;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getUserNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setUserNote = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string settlement_type = 34;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getSettlementType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setSettlementType = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional uint32 linked_trade_id = 35;
 * @return {number}
 */
proto.tradepb.OrderEntry.prototype.getLinkedTradeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {number} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setLinkedTradeId = function(value) {
  return jspb.Message.setProto3IntField(this, 35, value);
};


/**
 * optional bool solicited = 36;
 * @return {boolean}
 */
proto.tradepb.OrderEntry.prototype.getSolicited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 36, false));
};


/**
 * @param {boolean} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setSolicited = function(value) {
  return jspb.Message.setProto3BooleanField(this, 36, value);
};


/**
 * optional google.type.Date system_date = 37;
 * @return {?proto.google.type.Date}
 */
proto.tradepb.OrderEntry.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 37));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.tradepb.OrderEntry} returns this
*/
proto.tradepb.OrderEntry.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 37, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tradepb.OrderEntry.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 37) != null;
};


/**
 * optional google.type.Date modified_date = 38;
 * @return {?proto.google.type.Date}
 */
proto.tradepb.OrderEntry.prototype.getModifiedDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 38));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.tradepb.OrderEntry} returns this
*/
proto.tradepb.OrderEntry.prototype.setModifiedDate = function(value) {
  return jspb.Message.setWrapperField(this, 38, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.clearModifiedDate = function() {
  return this.setModifiedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tradepb.OrderEntry.prototype.hasModifiedDate = function() {
  return jspb.Message.getField(this, 38) != null;
};


/**
 * optional string symbol2 = 39;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getSymbol2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setSymbol2 = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional uint32 account_id = 40;
 * @return {number}
 */
proto.tradepb.OrderEntry.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {number} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 40, value);
};


/**
 * optional string batch_no2 = 41;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getBatchNo2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setBatchNo2 = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional string trailing_stop = 42;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getTrailingStop = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setTrailingStop = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional string master_account_no = 43;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * optional bool next_day_execution = 44;
 * @return {boolean}
 */
proto.tradepb.OrderEntry.prototype.getNextDayExecution = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 44, false));
};


/**
 * @param {boolean} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setNextDayExecution = function(value) {
  return jspb.Message.setProto3BooleanField(this, 44, value);
};


/**
 * optional google.protobuf.Timestamp system_time = 45;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.tradepb.OrderEntry.prototype.getSystemTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 45));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.tradepb.OrderEntry} returns this
*/
proto.tradepb.OrderEntry.prototype.setSystemTime = function(value) {
  return jspb.Message.setWrapperField(this, 45, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.clearSystemTime = function() {
  return this.setSystemTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tradepb.OrderEntry.prototype.hasSystemTime = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional string option = 46;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getOption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 46, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setOption = function(value) {
  return jspb.Message.setProto3StringField(this, 46, value);
};


/**
 * optional string prime_broker = 47;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getPrimeBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 47, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setPrimeBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 47, value);
};


/**
 * optional string margin_agreement = 48;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getMarginAgreement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setMarginAgreement = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional string stage = 49;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getStage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 49, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setStage = function(value) {
  return jspb.Message.setProto3StringField(this, 49, value);
};


/**
 * optional string file_path_pdf = 50;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getFilePathPdf = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setFilePathPdf = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional string cost_per_share = 51;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getCostPerShare = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setCostPerShare = function(value) {
  return jspb.Message.setProto3StringField(this, 51, value);
};


/**
 * optional string security_type = 52;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getSecurityType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 52, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setSecurityType = function(value) {
  return jspb.Message.setProto3StringField(this, 52, value);
};


/**
 * optional string account_type = 53;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getAccountType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 53, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setAccountType = function(value) {
  return jspb.Message.setProto3StringField(this, 53, value);
};


/**
 * optional google.protobuf.Timestamp modified_time = 54;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.tradepb.OrderEntry.prototype.getModifiedTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 54));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.tradepb.OrderEntry} returns this
*/
proto.tradepb.OrderEntry.prototype.setModifiedTime = function(value) {
  return jspb.Message.setWrapperField(this, 54, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.clearModifiedTime = function() {
  return this.setModifiedTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tradepb.OrderEntry.prototype.hasModifiedTime = function() {
  return jspb.Message.getField(this, 54) != null;
};


/**
 * optional string est_realized_pl = 55;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getEstRealizedPl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 55, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setEstRealizedPl = function(value) {
  return jspb.Message.setProto3StringField(this, 55, value);
};


/**
 * optional string price_src = 56;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getPriceSrc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 56, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setPriceSrc = function(value) {
  return jspb.Message.setProto3StringField(this, 56, value);
};


/**
 * optional string credit = 57;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getCredit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 57, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setCredit = function(value) {
  return jspb.Message.setProto3StringField(this, 57, value);
};


/**
 * optional string maturity = 58;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getMaturity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 58, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setMaturity = function(value) {
  return jspb.Message.setProto3StringField(this, 58, value);
};


/**
 * optional string type = 59;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 59, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 59, value);
};


/**
 * optional string state = 60;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 60, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 60, value);
};


/**
 * optional string ytc = 61;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getYtc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 61, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setYtc = function(value) {
  return jspb.Message.setProto3StringField(this, 61, value);
};


/**
 * optional string ytw = 62;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getYtw = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 62, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setYtw = function(value) {
  return jspb.Message.setProto3StringField(this, 62, value);
};


/**
 * optional string maturity_period = 63;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getMaturityPeriod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 63, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setMaturityPeriod = function(value) {
  return jspb.Message.setProto3StringField(this, 63, value);
};


/**
 * optional string coupon = 64;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getCoupon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 64, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setCoupon = function(value) {
  return jspb.Message.setProto3StringField(this, 64, value);
};


/**
 * optional bool early_settlement_client_approved = 65;
 * @return {boolean}
 */
proto.tradepb.OrderEntry.prototype.getEarlySettlementClientApproved = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 65, false));
};


/**
 * @param {boolean} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setEarlySettlementClientApproved = function(value) {
  return jspb.Message.setProto3BooleanField(this, 65, value);
};


/**
 * optional bool restricted = 66;
 * @return {boolean}
 */
proto.tradepb.OrderEntry.prototype.getRestricted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 66, false));
};


/**
 * @param {boolean} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setRestricted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 66, value);
};


/**
 * optional bool symbol_converted = 67;
 * @return {boolean}
 */
proto.tradepb.OrderEntry.prototype.getSymbolConverted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 67, false));
};


/**
 * @param {boolean} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setSymbolConverted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 67, value);
};


/**
 * optional string trade_confirm = 68;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getTradeConfirm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 68, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setTradeConfirm = function(value) {
  return jspb.Message.setProto3StringField(this, 68, value);
};


/**
 * optional string last_price = 80;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getLastPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 80, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setLastPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 80, value);
};


/**
 * optional google.type.Date expiration_date = 81;
 * @return {?proto.google.type.Date}
 */
proto.tradepb.OrderEntry.prototype.getExpirationDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 81));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.tradepb.OrderEntry} returns this
*/
proto.tradepb.OrderEntry.prototype.setExpirationDate = function(value) {
  return jspb.Message.setWrapperField(this, 81, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.clearExpirationDate = function() {
  return this.setExpirationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tradepb.OrderEntry.prototype.hasExpirationDate = function() {
  return jspb.Message.getField(this, 81) != null;
};


/**
 * optional string put_call = 82;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getPutCall = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 82, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setPutCall = function(value) {
  return jspb.Message.setProto3StringField(this, 82, value);
};


/**
 * optional string strike_price = 83;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getStrikePrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 83, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setStrikePrice = function(value) {
  return jspb.Message.setProto3StringField(this, 83, value);
};


/**
 * optional string sign_indicator = 84;
 * @return {string}
 */
proto.tradepb.OrderEntry.prototype.getSignIndicator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 84, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OrderEntry} returns this
 */
proto.tradepb.OrderEntry.prototype.setSignIndicator = function(value) {
  return jspb.Message.setProto3StringField(this, 84, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.PendingOrderEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.PendingOrderEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.PendingOrderEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.PendingOrderEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderEntryId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    trnsCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    amt: jspb.Message.getFieldWithDefault(msg, 5, ""),
    sell: jspb.Message.getFieldWithDefault(msg, 6, ""),
    buy: jspb.Message.getFieldWithDefault(msg, 7, ""),
    note: jspb.Message.getFieldWithDefault(msg, 8, ""),
    modifiedDate: jspb.Message.getFieldWithDefault(msg, 9, ""),
    batchNo: jspb.Message.getFieldWithDefault(msg, 10, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 11, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.PendingOrderEntry}
 */
proto.tradepb.PendingOrderEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.PendingOrderEntry;
  return proto.tradepb.PendingOrderEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.PendingOrderEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.PendingOrderEntry}
 */
proto.tradepb.PendingOrderEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrderEntryId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTrnsCount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSell(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuy(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifiedDate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchNo(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.PendingOrderEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.PendingOrderEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.PendingOrderEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.PendingOrderEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderEntryId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTrnsCount();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getAmt();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSell();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBuy();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getModifiedDate();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBatchNo();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional uint32 order_entry_id = 1;
 * @return {number}
 */
proto.tradepb.PendingOrderEntry.prototype.getOrderEntryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.tradepb.PendingOrderEntry} returns this
 */
proto.tradepb.PendingOrderEntry.prototype.setOrderEntryId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.tradepb.PendingOrderEntry.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.PendingOrderEntry} returns this
 */
proto.tradepb.PendingOrderEntry.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_name = 3;
 * @return {string}
 */
proto.tradepb.PendingOrderEntry.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.PendingOrderEntry} returns this
 */
proto.tradepb.PendingOrderEntry.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 trns_count = 4;
 * @return {number}
 */
proto.tradepb.PendingOrderEntry.prototype.getTrnsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.tradepb.PendingOrderEntry} returns this
 */
proto.tradepb.PendingOrderEntry.prototype.setTrnsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string amt = 5;
 * @return {string}
 */
proto.tradepb.PendingOrderEntry.prototype.getAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.PendingOrderEntry} returns this
 */
proto.tradepb.PendingOrderEntry.prototype.setAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string sell = 6;
 * @return {string}
 */
proto.tradepb.PendingOrderEntry.prototype.getSell = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.PendingOrderEntry} returns this
 */
proto.tradepb.PendingOrderEntry.prototype.setSell = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string buy = 7;
 * @return {string}
 */
proto.tradepb.PendingOrderEntry.prototype.getBuy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.PendingOrderEntry} returns this
 */
proto.tradepb.PendingOrderEntry.prototype.setBuy = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string note = 8;
 * @return {string}
 */
proto.tradepb.PendingOrderEntry.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.PendingOrderEntry} returns this
 */
proto.tradepb.PendingOrderEntry.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string modified_date = 9;
 * @return {string}
 */
proto.tradepb.PendingOrderEntry.prototype.getModifiedDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.PendingOrderEntry} returns this
 */
proto.tradepb.PendingOrderEntry.prototype.setModifiedDate = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string batch_no = 10;
 * @return {string}
 */
proto.tradepb.PendingOrderEntry.prototype.getBatchNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.PendingOrderEntry} returns this
 */
proto.tradepb.PendingOrderEntry.prototype.setBatchNo = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string created_by = 11;
 * @return {string}
 */
proto.tradepb.PendingOrderEntry.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.PendingOrderEntry} returns this
 */
proto.tradepb.PendingOrderEntry.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string master_account_no = 12;
 * @return {string}
 */
proto.tradepb.PendingOrderEntry.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.PendingOrderEntry} returns this
 */
proto.tradepb.PendingOrderEntry.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.AccountDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.AccountDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.AccountDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.AccountDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountNo: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rep: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cashBalance: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountValue: jspb.Message.getFieldWithDefault(msg, 5, ""),
    additionalCash: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.AccountDetails}
 */
proto.tradepb.AccountDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.AccountDetails;
  return proto.tradepb.AccountDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.AccountDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.AccountDetails}
 */
proto.tradepb.AccountDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRep(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCashBalance(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountValue(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdditionalCash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.AccountDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.AccountDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.AccountDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.AccountDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRep();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCashBalance();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAdditionalCash();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string account_no = 1;
 * @return {string}
 */
proto.tradepb.AccountDetails.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.AccountDetails} returns this
 */
proto.tradepb.AccountDetails.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_name = 2;
 * @return {string}
 */
proto.tradepb.AccountDetails.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.AccountDetails} returns this
 */
proto.tradepb.AccountDetails.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string rep = 3;
 * @return {string}
 */
proto.tradepb.AccountDetails.prototype.getRep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.AccountDetails} returns this
 */
proto.tradepb.AccountDetails.prototype.setRep = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string cash_balance = 4;
 * @return {string}
 */
proto.tradepb.AccountDetails.prototype.getCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.AccountDetails} returns this
 */
proto.tradepb.AccountDetails.prototype.setCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_value = 5;
 * @return {string}
 */
proto.tradepb.AccountDetails.prototype.getAccountValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.AccountDetails} returns this
 */
proto.tradepb.AccountDetails.prototype.setAccountValue = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string additional_cash = 6;
 * @return {string}
 */
proto.tradepb.AccountDetails.prototype.getAdditionalCash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.AccountDetails} returns this
 */
proto.tradepb.AccountDetails.prototype.setAdditionalCash = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.AmountBreakdown.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.AmountBreakdown.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.AmountBreakdown} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.AmountBreakdown.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountNo: jspb.Message.getFieldWithDefault(msg, 1, ""),
    trnsCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    accountValue: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bodBalance: jspb.Message.getFieldWithDefault(msg, 4, ""),
    runningBalance: jspb.Message.getFieldWithDefault(msg, 5, ""),
    buy: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sell: jspb.Message.getFieldWithDefault(msg, 7, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 8, ""),
    endRunningBalance: jspb.Message.getFieldWithDefault(msg, 9, ""),
    estRealizedPl: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.AmountBreakdown}
 */
proto.tradepb.AmountBreakdown.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.AmountBreakdown;
  return proto.tradepb.AmountBreakdown.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.AmountBreakdown} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.AmountBreakdown}
 */
proto.tradepb.AmountBreakdown.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTrnsCount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountValue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBodBalance(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRunningBalance(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuy(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSell(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndRunningBalance(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstRealizedPl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.AmountBreakdown.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.AmountBreakdown.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.AmountBreakdown} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.AmountBreakdown.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTrnsCount();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getAccountValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBodBalance();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRunningBalance();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBuy();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSell();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getEndRunningBalance();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getEstRealizedPl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string account_no = 1;
 * @return {string}
 */
proto.tradepb.AmountBreakdown.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.AmountBreakdown} returns this
 */
proto.tradepb.AmountBreakdown.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 trns_count = 2;
 * @return {number}
 */
proto.tradepb.AmountBreakdown.prototype.getTrnsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.tradepb.AmountBreakdown} returns this
 */
proto.tradepb.AmountBreakdown.prototype.setTrnsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string account_value = 3;
 * @return {string}
 */
proto.tradepb.AmountBreakdown.prototype.getAccountValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.AmountBreakdown} returns this
 */
proto.tradepb.AmountBreakdown.prototype.setAccountValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string bod_balance = 4;
 * @return {string}
 */
proto.tradepb.AmountBreakdown.prototype.getBodBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.AmountBreakdown} returns this
 */
proto.tradepb.AmountBreakdown.prototype.setBodBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string running_balance = 5;
 * @return {string}
 */
proto.tradepb.AmountBreakdown.prototype.getRunningBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.AmountBreakdown} returns this
 */
proto.tradepb.AmountBreakdown.prototype.setRunningBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string buy = 6;
 * @return {string}
 */
proto.tradepb.AmountBreakdown.prototype.getBuy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.AmountBreakdown} returns this
 */
proto.tradepb.AmountBreakdown.prototype.setBuy = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string sell = 7;
 * @return {string}
 */
proto.tradepb.AmountBreakdown.prototype.getSell = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.AmountBreakdown} returns this
 */
proto.tradepb.AmountBreakdown.prototype.setSell = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string amount = 8;
 * @return {string}
 */
proto.tradepb.AmountBreakdown.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.AmountBreakdown} returns this
 */
proto.tradepb.AmountBreakdown.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string end_running_balance = 9;
 * @return {string}
 */
proto.tradepb.AmountBreakdown.prototype.getEndRunningBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.AmountBreakdown} returns this
 */
proto.tradepb.AmountBreakdown.prototype.setEndRunningBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string est_realized_pl = 10;
 * @return {string}
 */
proto.tradepb.AmountBreakdown.prototype.getEstRealizedPl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.AmountBreakdown} returns this
 */
proto.tradepb.AmountBreakdown.prototype.setEstRealizedPl = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.TradingPrivilege.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.TradingPrivilege.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.TradingPrivilege} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.TradingPrivilege.toObject = function(includeInstance, msg) {
  var f, obj = {
    stocks: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    option: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    mutualFunds: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    fixedIncome: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.TradingPrivilege}
 */
proto.tradepb.TradingPrivilege.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.TradingPrivilege;
  return proto.tradepb.TradingPrivilege.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.TradingPrivilege} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.TradingPrivilege}
 */
proto.tradepb.TradingPrivilege.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStocks(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOption(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMutualFunds(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFixedIncome(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.TradingPrivilege.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.TradingPrivilege.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.TradingPrivilege} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.TradingPrivilege.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStocks();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getOption();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getMutualFunds();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getFixedIncome();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional bool stocks = 1;
 * @return {boolean}
 */
proto.tradepb.TradingPrivilege.prototype.getStocks = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.tradepb.TradingPrivilege} returns this
 */
proto.tradepb.TradingPrivilege.prototype.setStocks = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool option = 2;
 * @return {boolean}
 */
proto.tradepb.TradingPrivilege.prototype.getOption = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.tradepb.TradingPrivilege} returns this
 */
proto.tradepb.TradingPrivilege.prototype.setOption = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool mutual_funds = 3;
 * @return {boolean}
 */
proto.tradepb.TradingPrivilege.prototype.getMutualFunds = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.tradepb.TradingPrivilege} returns this
 */
proto.tradepb.TradingPrivilege.prototype.setMutualFunds = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool fixed_income = 4;
 * @return {boolean}
 */
proto.tradepb.TradingPrivilege.prototype.getFixedIncome = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.tradepb.TradingPrivilege} returns this
 */
proto.tradepb.TradingPrivilege.prototype.setFixedIncome = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.GetTradingPrivilegeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.GetTradingPrivilegeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.GetTradingPrivilegeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.GetTradingPrivilegeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountNo: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.GetTradingPrivilegeRequest}
 */
proto.tradepb.GetTradingPrivilegeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.GetTradingPrivilegeRequest;
  return proto.tradepb.GetTradingPrivilegeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.GetTradingPrivilegeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.GetTradingPrivilegeRequest}
 */
proto.tradepb.GetTradingPrivilegeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.GetTradingPrivilegeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.GetTradingPrivilegeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.GetTradingPrivilegeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.GetTradingPrivilegeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string account_no = 1;
 * @return {string}
 */
proto.tradepb.GetTradingPrivilegeRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.GetTradingPrivilegeRequest} returns this
 */
proto.tradepb.GetTradingPrivilegeRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tradepb.ListAmountBreakdownRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.ListAmountBreakdownRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.ListAmountBreakdownRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.ListAmountBreakdownRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.ListAmountBreakdownRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderEntriesList: jspb.Message.toObjectList(msg.getOrderEntriesList(),
    proto.tradepb.OrderEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.ListAmountBreakdownRequest}
 */
proto.tradepb.ListAmountBreakdownRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.ListAmountBreakdownRequest;
  return proto.tradepb.ListAmountBreakdownRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.ListAmountBreakdownRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.ListAmountBreakdownRequest}
 */
proto.tradepb.ListAmountBreakdownRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.tradepb.OrderEntry;
      reader.readMessage(value,proto.tradepb.OrderEntry.deserializeBinaryFromReader);
      msg.addOrderEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.ListAmountBreakdownRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.ListAmountBreakdownRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.ListAmountBreakdownRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.ListAmountBreakdownRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.tradepb.OrderEntry.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OrderEntry order_entries = 1;
 * @return {!Array<!proto.tradepb.OrderEntry>}
 */
proto.tradepb.ListAmountBreakdownRequest.prototype.getOrderEntriesList = function() {
  return /** @type{!Array<!proto.tradepb.OrderEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tradepb.OrderEntry, 1));
};


/**
 * @param {!Array<!proto.tradepb.OrderEntry>} value
 * @return {!proto.tradepb.ListAmountBreakdownRequest} returns this
*/
proto.tradepb.ListAmountBreakdownRequest.prototype.setOrderEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.tradepb.OrderEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tradepb.OrderEntry}
 */
proto.tradepb.ListAmountBreakdownRequest.prototype.addOrderEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.tradepb.OrderEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tradepb.ListAmountBreakdownRequest} returns this
 */
proto.tradepb.ListAmountBreakdownRequest.prototype.clearOrderEntriesList = function() {
  return this.setOrderEntriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tradepb.ListAmountBreakdownResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.ListAmountBreakdownResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.ListAmountBreakdownResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.ListAmountBreakdownResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.ListAmountBreakdownResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    amountBreakdownsList: jspb.Message.toObjectList(msg.getAmountBreakdownsList(),
    proto.tradepb.AmountBreakdown.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.ListAmountBreakdownResponse}
 */
proto.tradepb.ListAmountBreakdownResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.ListAmountBreakdownResponse;
  return proto.tradepb.ListAmountBreakdownResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.ListAmountBreakdownResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.ListAmountBreakdownResponse}
 */
proto.tradepb.ListAmountBreakdownResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.tradepb.AmountBreakdown;
      reader.readMessage(value,proto.tradepb.AmountBreakdown.deserializeBinaryFromReader);
      msg.addAmountBreakdowns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.ListAmountBreakdownResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.ListAmountBreakdownResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.ListAmountBreakdownResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.ListAmountBreakdownResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmountBreakdownsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.tradepb.AmountBreakdown.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AmountBreakdown amount_breakdowns = 1;
 * @return {!Array<!proto.tradepb.AmountBreakdown>}
 */
proto.tradepb.ListAmountBreakdownResponse.prototype.getAmountBreakdownsList = function() {
  return /** @type{!Array<!proto.tradepb.AmountBreakdown>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tradepb.AmountBreakdown, 1));
};


/**
 * @param {!Array<!proto.tradepb.AmountBreakdown>} value
 * @return {!proto.tradepb.ListAmountBreakdownResponse} returns this
*/
proto.tradepb.ListAmountBreakdownResponse.prototype.setAmountBreakdownsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.tradepb.AmountBreakdown=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tradepb.AmountBreakdown}
 */
proto.tradepb.ListAmountBreakdownResponse.prototype.addAmountBreakdowns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.tradepb.AmountBreakdown, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tradepb.ListAmountBreakdownResponse} returns this
 */
proto.tradepb.ListAmountBreakdownResponse.prototype.clearAmountBreakdownsList = function() {
  return this.setAmountBreakdownsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.GetAccountDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.GetAccountDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.GetAccountDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.GetAccountDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountNo: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.GetAccountDetailsRequest}
 */
proto.tradepb.GetAccountDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.GetAccountDetailsRequest;
  return proto.tradepb.GetAccountDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.GetAccountDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.GetAccountDetailsRequest}
 */
proto.tradepb.GetAccountDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.GetAccountDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.GetAccountDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.GetAccountDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.GetAccountDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string account_no = 1;
 * @return {string}
 */
proto.tradepb.GetAccountDetailsRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.GetAccountDetailsRequest} returns this
 */
proto.tradepb.GetAccountDetailsRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.GetOrderEntryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.GetOrderEntryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.GetOrderEntryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.GetOrderEntryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderEntryId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.GetOrderEntryRequest}
 */
proto.tradepb.GetOrderEntryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.GetOrderEntryRequest;
  return proto.tradepb.GetOrderEntryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.GetOrderEntryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.GetOrderEntryRequest}
 */
proto.tradepb.GetOrderEntryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrderEntryId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.GetOrderEntryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.GetOrderEntryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.GetOrderEntryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.GetOrderEntryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderEntryId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 order_entry_id = 1;
 * @return {number}
 */
proto.tradepb.GetOrderEntryRequest.prototype.getOrderEntryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.tradepb.GetOrderEntryRequest} returns this
 */
proto.tradepb.GetOrderEntryRequest.prototype.setOrderEntryId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.ListOrderEntryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.ListOrderEntryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.ListOrderEntryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.ListOrderEntryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountNo: jspb.Message.getFieldWithDefault(msg, 1, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rep: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    assetType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 6, ""),
    side: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.ListOrderEntryRequest}
 */
proto.tradepb.ListOrderEntryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.ListOrderEntryRequest;
  return proto.tradepb.ListOrderEntryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.ListOrderEntryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.ListOrderEntryRequest}
 */
proto.tradepb.ListOrderEntryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRep(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSide(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.ListOrderEntryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.ListOrderEntryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.ListOrderEntryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.ListOrderEntryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRep();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAssetType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSide();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string account_no = 1;
 * @return {string}
 */
proto.tradepb.ListOrderEntryRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.ListOrderEntryRequest} returns this
 */
proto.tradepb.ListOrderEntryRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string master_account_no = 2;
 * @return {string}
 */
proto.tradepb.ListOrderEntryRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.ListOrderEntryRequest} returns this
 */
proto.tradepb.ListOrderEntryRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string rep = 3;
 * @return {string}
 */
proto.tradepb.ListOrderEntryRequest.prototype.getRep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.ListOrderEntryRequest} returns this
 */
proto.tradepb.ListOrderEntryRequest.prototype.setRep = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_name = 4;
 * @return {string}
 */
proto.tradepb.ListOrderEntryRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.ListOrderEntryRequest} returns this
 */
proto.tradepb.ListOrderEntryRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string asset_type = 5;
 * @return {string}
 */
proto.tradepb.ListOrderEntryRequest.prototype.getAssetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.ListOrderEntryRequest} returns this
 */
proto.tradepb.ListOrderEntryRequest.prototype.setAssetType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string symbol = 6;
 * @return {string}
 */
proto.tradepb.ListOrderEntryRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.ListOrderEntryRequest} returns this
 */
proto.tradepb.ListOrderEntryRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string side = 7;
 * @return {string}
 */
proto.tradepb.ListOrderEntryRequest.prototype.getSide = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.ListOrderEntryRequest} returns this
 */
proto.tradepb.ListOrderEntryRequest.prototype.setSide = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tradepb.ListOrderEntryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.ListOrderEntryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.ListOrderEntryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.ListOrderEntryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.ListOrderEntryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderEntriesList: jspb.Message.toObjectList(msg.getOrderEntriesList(),
    proto.tradepb.OrderEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.ListOrderEntryResponse}
 */
proto.tradepb.ListOrderEntryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.ListOrderEntryResponse;
  return proto.tradepb.ListOrderEntryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.ListOrderEntryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.ListOrderEntryResponse}
 */
proto.tradepb.ListOrderEntryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.tradepb.OrderEntry;
      reader.readMessage(value,proto.tradepb.OrderEntry.deserializeBinaryFromReader);
      msg.addOrderEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.ListOrderEntryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.ListOrderEntryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.ListOrderEntryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.ListOrderEntryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.tradepb.OrderEntry.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OrderEntry order_entries = 1;
 * @return {!Array<!proto.tradepb.OrderEntry>}
 */
proto.tradepb.ListOrderEntryResponse.prototype.getOrderEntriesList = function() {
  return /** @type{!Array<!proto.tradepb.OrderEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tradepb.OrderEntry, 1));
};


/**
 * @param {!Array<!proto.tradepb.OrderEntry>} value
 * @return {!proto.tradepb.ListOrderEntryResponse} returns this
*/
proto.tradepb.ListOrderEntryResponse.prototype.setOrderEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.tradepb.OrderEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tradepb.OrderEntry}
 */
proto.tradepb.ListOrderEntryResponse.prototype.addOrderEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.tradepb.OrderEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tradepb.ListOrderEntryResponse} returns this
 */
proto.tradepb.ListOrderEntryResponse.prototype.clearOrderEntriesList = function() {
  return this.setOrderEntriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tradepb.ListPendingOrderEntryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.ListPendingOrderEntryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.ListPendingOrderEntryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.ListPendingOrderEntryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.ListPendingOrderEntryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderEntriesList: jspb.Message.toObjectList(msg.getOrderEntriesList(),
    proto.tradepb.PendingOrderEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.ListPendingOrderEntryResponse}
 */
proto.tradepb.ListPendingOrderEntryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.ListPendingOrderEntryResponse;
  return proto.tradepb.ListPendingOrderEntryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.ListPendingOrderEntryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.ListPendingOrderEntryResponse}
 */
proto.tradepb.ListPendingOrderEntryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.tradepb.PendingOrderEntry;
      reader.readMessage(value,proto.tradepb.PendingOrderEntry.deserializeBinaryFromReader);
      msg.addOrderEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.ListPendingOrderEntryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.ListPendingOrderEntryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.ListPendingOrderEntryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.ListPendingOrderEntryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.tradepb.PendingOrderEntry.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PendingOrderEntry order_entries = 1;
 * @return {!Array<!proto.tradepb.PendingOrderEntry>}
 */
proto.tradepb.ListPendingOrderEntryResponse.prototype.getOrderEntriesList = function() {
  return /** @type{!Array<!proto.tradepb.PendingOrderEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tradepb.PendingOrderEntry, 1));
};


/**
 * @param {!Array<!proto.tradepb.PendingOrderEntry>} value
 * @return {!proto.tradepb.ListPendingOrderEntryResponse} returns this
*/
proto.tradepb.ListPendingOrderEntryResponse.prototype.setOrderEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.tradepb.PendingOrderEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tradepb.PendingOrderEntry}
 */
proto.tradepb.ListPendingOrderEntryResponse.prototype.addOrderEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.tradepb.PendingOrderEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tradepb.ListPendingOrderEntryResponse} returns this
 */
proto.tradepb.ListPendingOrderEntryResponse.prototype.clearOrderEntriesList = function() {
  return this.setOrderEntriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.Empty}
 */
proto.tradepb.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.Empty;
  return proto.tradepb.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.Empty}
 */
proto.tradepb.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tradepb.MovePendingRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.MovePendingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.MovePendingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.MovePendingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.MovePendingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderEntryIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.MovePendingRequest}
 */
proto.tradepb.MovePendingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.MovePendingRequest;
  return proto.tradepb.MovePendingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.MovePendingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.MovePendingRequest}
 */
proto.tradepb.MovePendingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrderEntryIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.MovePendingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.MovePendingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.MovePendingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.MovePendingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderEntryIdsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      1,
      f
    );
  }
};


/**
 * repeated uint32 order_entry_ids = 1;
 * @return {!Array<number>}
 */
proto.tradepb.MovePendingRequest.prototype.getOrderEntryIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.tradepb.MovePendingRequest} returns this
 */
proto.tradepb.MovePendingRequest.prototype.setOrderEntryIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.tradepb.MovePendingRequest} returns this
 */
proto.tradepb.MovePendingRequest.prototype.addOrderEntryIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tradepb.MovePendingRequest} returns this
 */
proto.tradepb.MovePendingRequest.prototype.clearOrderEntryIdsList = function() {
  return this.setOrderEntryIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tradepb.MoveTradesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.MoveTradesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.MoveTradesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.MoveTradesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.MoveTradesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradeRequestList: jspb.Message.toObjectList(msg.getTradeRequestList(),
    proto.tradepb.TradeRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.MoveTradesRequest}
 */
proto.tradepb.MoveTradesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.MoveTradesRequest;
  return proto.tradepb.MoveTradesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.MoveTradesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.MoveTradesRequest}
 */
proto.tradepb.MoveTradesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.tradepb.TradeRequest;
      reader.readMessage(value,proto.tradepb.TradeRequest.deserializeBinaryFromReader);
      msg.addTradeRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.MoveTradesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.MoveTradesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.MoveTradesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.MoveTradesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradeRequestList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.tradepb.TradeRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TradeRequest trade_request = 1;
 * @return {!Array<!proto.tradepb.TradeRequest>}
 */
proto.tradepb.MoveTradesRequest.prototype.getTradeRequestList = function() {
  return /** @type{!Array<!proto.tradepb.TradeRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tradepb.TradeRequest, 1));
};


/**
 * @param {!Array<!proto.tradepb.TradeRequest>} value
 * @return {!proto.tradepb.MoveTradesRequest} returns this
*/
proto.tradepb.MoveTradesRequest.prototype.setTradeRequestList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.tradepb.TradeRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tradepb.TradeRequest}
 */
proto.tradepb.MoveTradesRequest.prototype.addTradeRequest = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.tradepb.TradeRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tradepb.MoveTradesRequest} returns this
 */
proto.tradepb.MoveTradesRequest.prototype.clearTradeRequestList = function() {
  return this.setTradeRequestList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.TradeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.TradeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.TradeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.TradeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountNo: jspb.Message.getFieldWithDefault(msg, 1, ""),
    batchNo: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.TradeRequest}
 */
proto.tradepb.TradeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.TradeRequest;
  return proto.tradepb.TradeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.TradeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.TradeRequest}
 */
proto.tradepb.TradeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.TradeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.TradeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.TradeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.TradeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBatchNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string account_no = 1;
 * @return {string}
 */
proto.tradepb.TradeRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.TradeRequest} returns this
 */
proto.tradepb.TradeRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string batch_no = 2;
 * @return {string}
 */
proto.tradepb.TradeRequest.prototype.getBatchNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.TradeRequest} returns this
 */
proto.tradepb.TradeRequest.prototype.setBatchNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tradepb.ListPendingBatchOrdersRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.ListPendingBatchOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.ListPendingBatchOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.ListPendingBatchOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.ListPendingBatchOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountNoList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    batchNoList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.ListPendingBatchOrdersRequest}
 */
proto.tradepb.ListPendingBatchOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.ListPendingBatchOrdersRequest;
  return proto.tradepb.ListPendingBatchOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.ListPendingBatchOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.ListPendingBatchOrdersRequest}
 */
proto.tradepb.ListPendingBatchOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addAccountNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addBatchNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.ListPendingBatchOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.ListPendingBatchOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.ListPendingBatchOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.ListPendingBatchOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountNoList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getBatchNoList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string account_no = 1;
 * @return {!Array<string>}
 */
proto.tradepb.ListPendingBatchOrdersRequest.prototype.getAccountNoList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.tradepb.ListPendingBatchOrdersRequest} returns this
 */
proto.tradepb.ListPendingBatchOrdersRequest.prototype.setAccountNoList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.tradepb.ListPendingBatchOrdersRequest} returns this
 */
proto.tradepb.ListPendingBatchOrdersRequest.prototype.addAccountNo = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tradepb.ListPendingBatchOrdersRequest} returns this
 */
proto.tradepb.ListPendingBatchOrdersRequest.prototype.clearAccountNoList = function() {
  return this.setAccountNoList([]);
};


/**
 * repeated string batch_no = 2;
 * @return {!Array<string>}
 */
proto.tradepb.ListPendingBatchOrdersRequest.prototype.getBatchNoList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.tradepb.ListPendingBatchOrdersRequest} returns this
 */
proto.tradepb.ListPendingBatchOrdersRequest.prototype.setBatchNoList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.tradepb.ListPendingBatchOrdersRequest} returns this
 */
proto.tradepb.ListPendingBatchOrdersRequest.prototype.addBatchNo = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tradepb.ListPendingBatchOrdersRequest} returns this
 */
proto.tradepb.ListPendingBatchOrdersRequest.prototype.clearBatchNoList = function() {
  return this.setBatchNoList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tradepb.ListOrdersByIDRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.ListOrdersByIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.ListOrdersByIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.ListOrdersByIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.ListOrdersByIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderEntryIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.ListOrdersByIDRequest}
 */
proto.tradepb.ListOrdersByIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.ListOrdersByIDRequest;
  return proto.tradepb.ListOrdersByIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.ListOrdersByIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.ListOrdersByIDRequest}
 */
proto.tradepb.ListOrdersByIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrderEntryIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.ListOrdersByIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.ListOrdersByIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.ListOrdersByIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.ListOrdersByIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderEntryIdsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      1,
      f
    );
  }
};


/**
 * repeated uint32 order_entry_ids = 1;
 * @return {!Array<number>}
 */
proto.tradepb.ListOrdersByIDRequest.prototype.getOrderEntryIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.tradepb.ListOrdersByIDRequest} returns this
 */
proto.tradepb.ListOrdersByIDRequest.prototype.setOrderEntryIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.tradepb.ListOrdersByIDRequest} returns this
 */
proto.tradepb.ListOrdersByIDRequest.prototype.addOrderEntryIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tradepb.ListOrdersByIDRequest} returns this
 */
proto.tradepb.ListOrdersByIDRequest.prototype.clearOrderEntryIdsList = function() {
  return this.setOrderEntryIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.OptionDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.OptionDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.OptionDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.OptionDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    expirationDate: (f = msg.getExpirationDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    putCall: jspb.Message.getFieldWithDefault(msg, 2, ""),
    strikePrice: jspb.Message.getFieldWithDefault(msg, 3, ""),
    symbolDescription: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.OptionDetails}
 */
proto.tradepb.OptionDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.OptionDetails;
  return proto.tradepb.OptionDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.OptionDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.OptionDetails}
 */
proto.tradepb.OptionDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setExpirationDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPutCall(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStrikePrice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.OptionDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.OptionDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.OptionDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.OptionDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExpirationDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getPutCall();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStrikePrice();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSymbolDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional google.type.Date expiration_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.tradepb.OptionDetails.prototype.getExpirationDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.tradepb.OptionDetails} returns this
*/
proto.tradepb.OptionDetails.prototype.setExpirationDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.tradepb.OptionDetails} returns this
 */
proto.tradepb.OptionDetails.prototype.clearExpirationDate = function() {
  return this.setExpirationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.tradepb.OptionDetails.prototype.hasExpirationDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string put_call = 2;
 * @return {string}
 */
proto.tradepb.OptionDetails.prototype.getPutCall = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OptionDetails} returns this
 */
proto.tradepb.OptionDetails.prototype.setPutCall = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string strike_price = 3;
 * @return {string}
 */
proto.tradepb.OptionDetails.prototype.getStrikePrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OptionDetails} returns this
 */
proto.tradepb.OptionDetails.prototype.setStrikePrice = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string symbol_description = 4;
 * @return {string}
 */
proto.tradepb.OptionDetails.prototype.getSymbolDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.OptionDetails} returns this
 */
proto.tradepb.OptionDetails.prototype.setSymbolDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.GetOptionDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.GetOptionDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.GetOptionDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.GetOptionDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.GetOptionDetailsRequest}
 */
proto.tradepb.GetOptionDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.GetOptionDetailsRequest;
  return proto.tradepb.GetOptionDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.GetOptionDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.GetOptionDetailsRequest}
 */
proto.tradepb.GetOptionDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.GetOptionDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.GetOptionDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.GetOptionDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.GetOptionDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.tradepb.GetOptionDetailsRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.GetOptionDetailsRequest} returns this
 */
proto.tradepb.GetOptionDetailsRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.SymbolDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.SymbolDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.SymbolDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.SymbolDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    symbolDescription: jspb.Message.getFieldWithDefault(msg, 2, ""),
    assetType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastPrice: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bidPrice: jspb.Message.getFieldWithDefault(msg, 5, ""),
    askPrice: jspb.Message.getFieldWithDefault(msg, 6, ""),
    closingPrice: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.SymbolDetails}
 */
proto.tradepb.SymbolDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.SymbolDetails;
  return proto.tradepb.SymbolDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.SymbolDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.SymbolDetails}
 */
proto.tradepb.SymbolDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastPrice(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBidPrice(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAskPrice(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setClosingPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.SymbolDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.SymbolDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.SymbolDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.SymbolDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSymbolDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssetType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastPrice();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBidPrice();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAskPrice();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getClosingPrice();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.tradepb.SymbolDetails.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.SymbolDetails} returns this
 */
proto.tradepb.SymbolDetails.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string symbol_description = 2;
 * @return {string}
 */
proto.tradepb.SymbolDetails.prototype.getSymbolDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.SymbolDetails} returns this
 */
proto.tradepb.SymbolDetails.prototype.setSymbolDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string asset_type = 3;
 * @return {string}
 */
proto.tradepb.SymbolDetails.prototype.getAssetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.SymbolDetails} returns this
 */
proto.tradepb.SymbolDetails.prototype.setAssetType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string last_price = 4;
 * @return {string}
 */
proto.tradepb.SymbolDetails.prototype.getLastPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.SymbolDetails} returns this
 */
proto.tradepb.SymbolDetails.prototype.setLastPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string bid_price = 5;
 * @return {string}
 */
proto.tradepb.SymbolDetails.prototype.getBidPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.SymbolDetails} returns this
 */
proto.tradepb.SymbolDetails.prototype.setBidPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ask_price = 6;
 * @return {string}
 */
proto.tradepb.SymbolDetails.prototype.getAskPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.SymbolDetails} returns this
 */
proto.tradepb.SymbolDetails.prototype.setAskPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string closing_price = 7;
 * @return {string}
 */
proto.tradepb.SymbolDetails.prototype.getClosingPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.SymbolDetails} returns this
 */
proto.tradepb.SymbolDetails.prototype.setClosingPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.GetSymbolDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.GetSymbolDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.GetSymbolDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.GetSymbolDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.GetSymbolDetailsRequest}
 */
proto.tradepb.GetSymbolDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.GetSymbolDetailsRequest;
  return proto.tradepb.GetSymbolDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.GetSymbolDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.GetSymbolDetailsRequest}
 */
proto.tradepb.GetSymbolDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.GetSymbolDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.GetSymbolDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.GetSymbolDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.GetSymbolDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.tradepb.GetSymbolDetailsRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.GetSymbolDetailsRequest} returns this
 */
proto.tradepb.GetSymbolDetailsRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tradepb.LastTrades.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.LastTrades.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.LastTrades.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.LastTrades} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.LastTrades.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastTradesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.LastTrades}
 */
proto.tradepb.LastTrades.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.LastTrades;
  return proto.tradepb.LastTrades.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.LastTrades} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.LastTrades}
 */
proto.tradepb.LastTrades.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addLastTrades(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.LastTrades.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.LastTrades.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.LastTrades} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.LastTrades.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastTradesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string last_trades = 1;
 * @return {!Array<string>}
 */
proto.tradepb.LastTrades.prototype.getLastTradesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.tradepb.LastTrades} returns this
 */
proto.tradepb.LastTrades.prototype.setLastTradesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.tradepb.LastTrades} returns this
 */
proto.tradepb.LastTrades.prototype.addLastTrades = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tradepb.LastTrades} returns this
 */
proto.tradepb.LastTrades.prototype.clearLastTradesList = function() {
  return this.setLastTradesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.Totals.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.Totals.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.Totals} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.Totals.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalSell: jspb.Message.getFieldWithDefault(msg, 1, ""),
    totalBuy: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalAmount: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amountLabel: jspb.Message.getFieldWithDefault(msg, 4, ""),
    estRealizedPl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    plLabel: jspb.Message.getFieldWithDefault(msg, 6, ""),
    totalCount: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.Totals}
 */
proto.tradepb.Totals.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.Totals;
  return proto.tradepb.Totals.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.Totals} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.Totals}
 */
proto.tradepb.Totals.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTotalSell(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTotalBuy(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTotalAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmountLabel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEstRealizedPl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlLabel(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.Totals.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.Totals.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.Totals} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.Totals.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalSell();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotalBuy();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalAmount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmountLabel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEstRealizedPl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPlLabel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
};


/**
 * optional string total_sell = 1;
 * @return {string}
 */
proto.tradepb.Totals.prototype.getTotalSell = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.Totals} returns this
 */
proto.tradepb.Totals.prototype.setTotalSell = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string total_buy = 2;
 * @return {string}
 */
proto.tradepb.Totals.prototype.getTotalBuy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.Totals} returns this
 */
proto.tradepb.Totals.prototype.setTotalBuy = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string total_amount = 3;
 * @return {string}
 */
proto.tradepb.Totals.prototype.getTotalAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.Totals} returns this
 */
proto.tradepb.Totals.prototype.setTotalAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string amount_label = 4;
 * @return {string}
 */
proto.tradepb.Totals.prototype.getAmountLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.Totals} returns this
 */
proto.tradepb.Totals.prototype.setAmountLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string est_realized_pl = 5;
 * @return {string}
 */
proto.tradepb.Totals.prototype.getEstRealizedPl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.Totals} returns this
 */
proto.tradepb.Totals.prototype.setEstRealizedPl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string pl_label = 6;
 * @return {string}
 */
proto.tradepb.Totals.prototype.getPlLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.Totals} returns this
 */
proto.tradepb.Totals.prototype.setPlLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint32 total_count = 7;
 * @return {number}
 */
proto.tradepb.Totals.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.tradepb.Totals} returns this
 */
proto.tradepb.Totals.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.ClientInputRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.ClientInputRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.ClientInputRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.ClientInputRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientInput: jspb.Message.getFieldWithDefault(msg, 1, ""),
    orderEntryId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.ClientInputRequest}
 */
proto.tradepb.ClientInputRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.ClientInputRequest;
  return proto.tradepb.ClientInputRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.ClientInputRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.ClientInputRequest}
 */
proto.tradepb.ClientInputRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientInput(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrderEntryId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.ClientInputRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.ClientInputRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.ClientInputRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.ClientInputRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientInput();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrderEntryId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string client_input = 1;
 * @return {string}
 */
proto.tradepb.ClientInputRequest.prototype.getClientInput = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.ClientInputRequest} returns this
 */
proto.tradepb.ClientInputRequest.prototype.setClientInput = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 order_entry_id = 2;
 * @return {number}
 */
proto.tradepb.ClientInputRequest.prototype.getOrderEntryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.tradepb.ClientInputRequest} returns this
 */
proto.tradepb.ClientInputRequest.prototype.setOrderEntryId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.PositionDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.PositionDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.PositionDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.PositionDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    positionQty: jspb.Message.getFieldWithDefault(msg, 1, ""),
    positionValue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    underlyingPosition: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.PositionDetails}
 */
proto.tradepb.PositionDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.PositionDetails;
  return proto.tradepb.PositionDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.PositionDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.PositionDetails}
 */
proto.tradepb.PositionDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPositionQty(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPositionValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnderlyingPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.PositionDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.PositionDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.PositionDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.PositionDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositionQty();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPositionValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUnderlyingPosition();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string position_qty = 1;
 * @return {string}
 */
proto.tradepb.PositionDetails.prototype.getPositionQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.PositionDetails} returns this
 */
proto.tradepb.PositionDetails.prototype.setPositionQty = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string position_value = 2;
 * @return {string}
 */
proto.tradepb.PositionDetails.prototype.getPositionValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.PositionDetails} returns this
 */
proto.tradepb.PositionDetails.prototype.setPositionValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string underlying_position = 3;
 * @return {string}
 */
proto.tradepb.PositionDetails.prototype.getUnderlyingPosition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.PositionDetails} returns this
 */
proto.tradepb.PositionDetails.prototype.setUnderlyingPosition = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.GetPositionDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.GetPositionDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.GetPositionDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.GetPositionDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assetType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.GetPositionDetailsRequest}
 */
proto.tradepb.GetPositionDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.GetPositionDetailsRequest;
  return proto.tradepb.GetPositionDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.GetPositionDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.GetPositionDetailsRequest}
 */
proto.tradepb.GetPositionDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.GetPositionDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.GetPositionDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.GetPositionDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.GetPositionDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.tradepb.GetPositionDetailsRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.GetPositionDetailsRequest} returns this
 */
proto.tradepb.GetPositionDetailsRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string asset_type = 2;
 * @return {string}
 */
proto.tradepb.GetPositionDetailsRequest.prototype.getAssetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.GetPositionDetailsRequest} returns this
 */
proto.tradepb.GetPositionDetailsRequest.prototype.setAssetType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.tradepb.GetPositionDetailsRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.GetPositionDetailsRequest} returns this
 */
proto.tradepb.GetPositionDetailsRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.GetIceSymbolRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.GetIceSymbolRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.GetIceSymbolRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.GetIceSymbolRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.GetIceSymbolRequest}
 */
proto.tradepb.GetIceSymbolRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.GetIceSymbolRequest;
  return proto.tradepb.GetIceSymbolRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.GetIceSymbolRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.GetIceSymbolRequest}
 */
proto.tradepb.GetIceSymbolRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.GetIceSymbolRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.GetIceSymbolRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.GetIceSymbolRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.GetIceSymbolRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.tradepb.GetIceSymbolRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.GetIceSymbolRequest} returns this
 */
proto.tradepb.GetIceSymbolRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.GetIceSymbolResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.GetIceSymbolResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.GetIceSymbolResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.GetIceSymbolResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol2: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.GetIceSymbolResponse}
 */
proto.tradepb.GetIceSymbolResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.GetIceSymbolResponse;
  return proto.tradepb.GetIceSymbolResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.GetIceSymbolResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.GetIceSymbolResponse}
 */
proto.tradepb.GetIceSymbolResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.GetIceSymbolResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.GetIceSymbolResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.GetIceSymbolResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.GetIceSymbolResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol2();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string symbol2 = 1;
 * @return {string}
 */
proto.tradepb.GetIceSymbolResponse.prototype.getSymbol2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.GetIceSymbolResponse} returns this
 */
proto.tradepb.GetIceSymbolResponse.prototype.setSymbol2 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.Position.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.Position.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.Position} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.Position.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    symbolDescription: jspb.Message.getFieldWithDefault(msg, 2, ""),
    qty: jspb.Message.getFieldWithDefault(msg, 3, ""),
    positionValue: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.Position}
 */
proto.tradepb.Position.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.Position;
  return proto.tradepb.Position.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.Position} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.Position}
 */
proto.tradepb.Position.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPositionValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.Position.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.Position.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.Position} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.Position.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSymbolDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPositionValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.tradepb.Position.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.Position} returns this
 */
proto.tradepb.Position.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string symbol_description = 2;
 * @return {string}
 */
proto.tradepb.Position.prototype.getSymbolDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.Position} returns this
 */
proto.tradepb.Position.prototype.setSymbolDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string qty = 3;
 * @return {string}
 */
proto.tradepb.Position.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.Position} returns this
 */
proto.tradepb.Position.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string position_value = 4;
 * @return {string}
 */
proto.tradepb.Position.prototype.getPositionValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.Position} returns this
 */
proto.tradepb.Position.prototype.setPositionValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.GetPositionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.GetPositionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.GetPositionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.GetPositionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountNo: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assetType: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.GetPositionsRequest}
 */
proto.tradepb.GetPositionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.GetPositionsRequest;
  return proto.tradepb.GetPositionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.GetPositionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.GetPositionsRequest}
 */
proto.tradepb.GetPositionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.GetPositionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.GetPositionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.GetPositionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.GetPositionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string account_no = 1;
 * @return {string}
 */
proto.tradepb.GetPositionsRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.GetPositionsRequest} returns this
 */
proto.tradepb.GetPositionsRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string asset_type = 2;
 * @return {string}
 */
proto.tradepb.GetPositionsRequest.prototype.getAssetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.GetPositionsRequest} returns this
 */
proto.tradepb.GetPositionsRequest.prototype.setAssetType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tradepb.GetPositionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.GetPositionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.GetPositionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.GetPositionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.GetPositionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    positionsList: jspb.Message.toObjectList(msg.getPositionsList(),
    proto.tradepb.Position.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.GetPositionsResponse}
 */
proto.tradepb.GetPositionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.GetPositionsResponse;
  return proto.tradepb.GetPositionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.GetPositionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.GetPositionsResponse}
 */
proto.tradepb.GetPositionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.tradepb.Position;
      reader.readMessage(value,proto.tradepb.Position.deserializeBinaryFromReader);
      msg.addPositions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.GetPositionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.GetPositionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.GetPositionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.GetPositionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.tradepb.Position.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Position positions = 1;
 * @return {!Array<!proto.tradepb.Position>}
 */
proto.tradepb.GetPositionsResponse.prototype.getPositionsList = function() {
  return /** @type{!Array<!proto.tradepb.Position>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tradepb.Position, 1));
};


/**
 * @param {!Array<!proto.tradepb.Position>} value
 * @return {!proto.tradepb.GetPositionsResponse} returns this
*/
proto.tradepb.GetPositionsResponse.prototype.setPositionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.tradepb.Position=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tradepb.Position}
 */
proto.tradepb.GetPositionsResponse.prototype.addPositions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.tradepb.Position, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tradepb.GetPositionsResponse} returns this
 */
proto.tradepb.GetPositionsResponse.prototype.clearPositionsList = function() {
  return this.setPositionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tradepb.DuplicateTradeRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.DuplicateTradeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.DuplicateTradeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.DuplicateTradeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.DuplicateTradeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderEntryIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    accountNo: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.DuplicateTradeRequest}
 */
proto.tradepb.DuplicateTradeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.DuplicateTradeRequest;
  return proto.tradepb.DuplicateTradeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.DuplicateTradeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.DuplicateTradeRequest}
 */
proto.tradepb.DuplicateTradeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addOrderEntryIds(values[i]);
      }
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.DuplicateTradeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.DuplicateTradeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.DuplicateTradeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.DuplicateTradeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderEntryIdsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated uint32 order_entry_ids = 1;
 * @return {!Array<number>}
 */
proto.tradepb.DuplicateTradeRequest.prototype.getOrderEntryIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.tradepb.DuplicateTradeRequest} returns this
 */
proto.tradepb.DuplicateTradeRequest.prototype.setOrderEntryIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.tradepb.DuplicateTradeRequest} returns this
 */
proto.tradepb.DuplicateTradeRequest.prototype.addOrderEntryIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tradepb.DuplicateTradeRequest} returns this
 */
proto.tradepb.DuplicateTradeRequest.prototype.clearOrderEntryIdsList = function() {
  return this.setOrderEntryIdsList([]);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.tradepb.DuplicateTradeRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.DuplicateTradeRequest} returns this
 */
proto.tradepb.DuplicateTradeRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.tradepb);
