/*eslint eqeqeq: "off"*/
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { green } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import { SystemUpdateAltOutlined, GetAppRounded } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { useConfirm } from 'material-ui-confirm';

const useStyles = makeStyles((theme) => ({
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },

  CheckIcon: {
    backgroundColor: '#00ce7d',
    padding: '6px',
    width: 25,
    height: 25,
  },
}));

export default function DownloadProgress(props) {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0.0);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const confirm = useConfirm();
  const handleButtonClick = async () => {
    try {
      if (props.downloadType == 'multiple') {
        confirm({
          description:
            'Are you sure you want to download ' +
            props.selected.length +
            ' record(s)?',
          confirmationText: 'Yes, Confirm',
        })
          .then(async () => {
            setProgress(0);
            setLoading(true);
            await downloadBatchAsycn();
          })
          .catch((error) => {
            setSuccess(false);
            setLoading(false);
          });
      } else {
        setProgress(0);
        setLoading(true);
        await props.download(
          props.selected,
          props.fileType,
          '',
          props.downloadType
        );
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      setSuccess(false);
      setLoading(false);
    }
  };

  const downloadBatchAsycn = async () => {
    let today = new Date();
    let batchNo =
      today.getFullYear() +
      '' +
      ('0' + (today.getMonth() + 1)).slice(-2) +
      '' +
      ('0' + today.getDate()).slice(-2) +
      '-' +
      ('0' + today.getHours()).slice(-2) +
      '' +
      ('0' + today.getMinutes()).slice(-2) +
      '' +
      ('0' + today.getSeconds()).slice(-2);
    let counter = 0;
    for (let item of props.selected) {
      const row = props.rows[item.index];
      try {
        await props.generate(row, props.fileType, batchNo, props.downloadType);
      } catch (err) {
        console.log(err);
      }
      counter++;
      setProgress((counter / props.selected.length) * 100.0);
      if (counter == props.selected.length) {
        setTimeout(() => {
          setSuccess(true);
          setLoading(false);
        }, 1300);

        try {
          await props.download(
            row,
            props.fileType,
            batchNo,
            props.downloadType
          );
        } catch (err) {
          console.log(err);
        }
        setTimeout(() => {
          setSuccess(false);
          setLoading(false);
        }, 2900);
      }
    }
  };

  return (
    <>
      {success ? (
        <IconButton aria-label="download" className={classes.CheckIcon}>
          <CheckIcon style={{ height: 25, width: 25 }} />
        </IconButton>
      ) : (
        <IconButton aria-label="download" onClick={handleButtonClick}>
          {props.useSystemUpdateIcon ? (
            <SystemUpdateAltOutlined style={{ height: 25, width: 25 }} />
          ) : (
            <GetAppRounded style={{ height: 25, width: 25 }} />
          )}
        </IconButton>
      )}
    </>
  );
}
