import React, { useState, useEffect } from 'react';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import Text from '../Typography/Text';
import fontFamily from '../../assets/css/fonts';
import color from '../../assets/css/colors';
import Button from '../Buttons/Button';
import authSvc from '../../services/AuthService';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 550,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
}));

function IdleTimerContainer() {
  const classes = useStyles();

  // Set timeout values
  const timeout = 1000 * 60 * 55;
  const promptTimeout = 1000 * 60 * 5;
  const crossTab = false;

  // Modal open state
  const [open, setOpen] = useState(false);

  // Time before idle
  const [remaining, setRemaining] = useState(0);

  const logout = () => {
    authSvc.logout();
    sessionStorage.clear();

    sessionStorage.setItem('inactive', true);

    if (window.env.ACTIVATE_SINGLE_SIGNIN) {
      window.location.replace(window.env.COMMON_LOGIN_URL + '/logout');
    } else {
      window.location = '/login';
    }
  };

  const onPrompt = () => {
    console.log('user is idle, warning');
    setOpen(true);
    setRemaining(promptTimeout);
  };

  const onIdle = () => {
    console.log('user is idle, logout');
    setOpen(false);
    setRemaining(0);
    logout();
  };

  const onActive = () => {
    setOpen(false);
    setRemaining(0);
  };

  const { getRemainingTime, isPrompted, activate } = useIdleTimer({
    timeout: timeout,
    crossTab: crossTab,
    promptTimeout: promptTimeout,
    onPrompt,
    onIdle,
    onActive,
    timers: workerTimers,
  });

  const handleStillHere = () => {
    setOpen(false);
    activate();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(() => {
          const minutes = Math.floor(Math.ceil(getRemainingTime() / 1000) / 60);

          // 👇️ get remainder of seconds
          const seconds = Math.ceil(getRemainingTime() / 1000) % 60;

          function padTo2Digits(num) {
            return num.toString().padStart(2, '0');
          }

          const result = `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;

          //Will execute once the computer turned on after sleep.
          if (result.toString() === '00:00') {
            setOpen(false);
            setRemaining(0);
            logout();
          }

          return result;
        });
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  return (
    <>
      {open && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <Fade in={open}>
            <div style={{ width: 500 }}>
              <div className={classes.modalHeader}>
                <div>
                  <Text
                    mt={4}
                    variant="h5"
                    color="primary"
                    label="Idle Warning"
                  />
                </div>
              </div>
              <div className={classes.modalBody}>
                You will be automatically logged out due to inactivity in{' '}
                <span id="timeLeft" className={classes.textBold}>
                  {remaining}
                </span>{' '}
                sec(s)
              </div>
              <div className={classes.modalFooter}>
                <Button
                  label={'Keep me logged in'}
                  onClick={() => {
                    handleStillHere();
                  }}
                />
              </div>
            </div>
          </Fade>
        </Modal>
      )}
    </>
  );
}

export default IdleTimerContainer;
