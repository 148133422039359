import React from 'react';
import Text from '../../components/Typography/Text';
import {ReactComponent as EmailVerification} from '../../assets/graphics/email-verification-graphic.svg';

export default function Verification() {

  return (
    <div style={{
      width: '100%', 
      height: '100vh', 
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'center', 
      alignItems: 'center'}}
    >
        <EmailVerification style={{width: 350, height: 320, marginTop: -60}}/>
        <Text
            variant="h2"
            label="Email Verification Sent!"
        />
        <Text
            mt={10}
            variant="subtitle1"
            label="Please check your email. We sent you a verification link to proceed with your registration!"
        />
    </div>
  );
}