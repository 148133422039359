import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIcon from '@material-ui/icons/MoreVert';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import NotificationMenu from './NotificationMenu';
import APWPopMenu from '../../PopMenu/APWPopMenu';
import authServices from '../../../services/AuthService';
import { withRouter } from 'react-router-dom';
import { UserIcon } from '../../Icons/Mono/Communication';
import { BellIcon } from '../../Icons/Mono/Utility';
import { QuestionCircleIcon } from '../../Icons/Mono/Alert';

const NOTIFICATION_MENU = 'notification_menu';
const PROFILE_MENU = 'profile_menu';
const MOBILE_MENU = 'mobile_menu';
const IDENTIFIER = '_trigger_btn'
const NAME_MOUSEOVER = 'mouse-over-popover'

function MenuPanel(props) {
  const { children, id, open, anchorEl, handleMenuClose } = props;

  return (
    <APWPopMenu
      anchorEl={anchorEl}
      id={id}

      open={open}
      onClose={handleMenuClose}
    >
      {children}
    </APWPopMenu>
  );
}

MenuPanel.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.element]),
  handleMenuClose: PropTypes.func.isRequired,
  open: PropTypes.bool
};

MenuPanel.defaultProps = {
  open: false,
  anchorEl: null,
}

const styles = (theme) => ({
  desktopView: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  mobileView: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  }
});

class TopBarMenu extends Component {


  constructor(props) {
    super(props);
    this.state = {
      idOfMenuToOpen: '',
      mobileElementToAnchor: null,
      desktopElementToAnchor: null,
      popElementToAnchor: null,
    };
  }

  /*State Setter*/

  updateIdOfMenuToOpen = (id) => {
    this.setState((prevState) => ({
      ...prevState,
      idOfMenuToOpen: id,
    }));
  };

  updateDesktopElementToAnchor = (element) => {
    this.setState((prevState) => ({
      ...prevState,
      desktopElementToAnchor: element,
    }));
  };

  updateMobileElementToAnchor = (element) => {
    this.setState((prevState) => ({
      ...prevState,
      mobileElementToAnchor: element,
    }));
  };

  handleMenuClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      desktopElementToAnchor: null,
      idOfMenuToOpen: '',
    }));
  };

  handleMobileMenuClose = () => {
    this.setState((prevState) => ({
      ...prevState,
      mobileElementToAnchor: null,
    }));
  };

  updatePopElementToAnchor = (element) => {
    this.setState((prevState) => ({
      ...prevState,
      popElementToAnchor: element,
    }));
  };

  handleLougout = () => {
    authServices.logout().then((response) => {
      console.log(response)
      this.props.history.push('/login', {showAlert: true});
    })
  }

  /*Desktop View Function*/

  handlePopoverOpen = (event) => {
    this.updatePopElementToAnchor(event.currentTarget);
  };

  handleOpenDesktopMenu = (event) => {
    this.updateIdOfMenuToOpen(event.currentTarget.id)
    this.updateDesktopElementToAnchor(event.currentTarget);
  };

  /*Mobile View Function*/

  isMobileMenuOpen = () => Boolean(this.state.mobileElementToAnchor)

  isPopOver = () => Boolean(this.state.popElementToAnchor);

  handleOpenMobiletopMenu = (event) => {
    this.updateMobileElementToAnchor(event.currentTarget);
  };

  render() {
    const { id, classes, history, ...otherProps } = this.props;

    const helpIconElements = (
      <IconButton edge="start" color="inherit">
        <QuestionCircleIcon />
      </IconButton>
    )

    const notificationIconElements = (
      <IconButton
        edge="start"
        aria-label="show 17 new notifications"
        aria-controls={NOTIFICATION_MENU}
        aria-haspopup="true"
        onClick={this.handleOpenDesktopMenu}
        color="inherit"
        id={`${NOTIFICATION_MENU}${IDENTIFIER}`}
      >
        <Badge badgeContent={17} color="secondary" overlap="rectangular">
          <BellIcon />
        </Badge>
      </IconButton>
    )

    const profileIconElements = (
      <IconButton
        edge="start"
        aria-label="account of current user"
        aria-controls={PROFILE_MENU}
        aria-haspopup="true"
        onClick={this.handleOpenDesktopMenu}
        color="inherit"
        id={`${PROFILE_MENU}${IDENTIFIER}`}
      >
        <UserIcon
          color='primary'
          aria-owns={this.isPopOver() ? NAME_MOUSEOVER : undefined}
          aria-haspopup="true"
          onMouseEnter={this.handlePopoverOpen}
          onMouseLeave={() => { this.updatePopElementToAnchor(null) }}
        />
      </IconButton>
    )

    return (
      <React.Fragment>
        <div className={classes.desktopView}>
          {helpIconElements}
          {notificationIconElements}
          {profileIconElements}
        </div>

        <div className={classes.mobileView}>
          <IconButton
            aria-label="show more"
            aria-controls={MOBILE_MENU}
            aria-haspopup="true"
            onClick={this.handleOpenMobiletopMenu}
            color="inherit"
            id={`${MOBILE_MENU}${IDENTIFIER}`}
          >
            <MoreIcon />
          </IconButton>
        </div>

        {/*Menu of More Icon in mobile view*/}
        <MenuPanel
          id={MOBILE_MENU}
          open={this.isMobileMenuOpen()}
          anchorEl={this.state.mobileElementToAnchor}
          handleMenuClose={this.handleMobileMenuClose}
        >
          <MenuItem>
            {helpIconElements}
            Help Center
          </MenuItem>
          <MenuItem onClick={this.handleOpenDesktopMenu} id={`${NOTIFICATION_MENU}${IDENTIFIER}`}>
            {notificationIconElements}
            Notifications
          </MenuItem>
          <MenuItem onClick={this.handleOpenDesktopMenu} id={`${PROFILE_MENU}${IDENTIFIER}`}>
            {profileIconElements}
            Profile
          </MenuItem>

        </MenuPanel>

        {/*Menu of Notificaton in desktop view*/}
        <MenuPanel
          id={NOTIFICATION_MENU}
          anchorEl={this.state.desktopElementToAnchor}
          handleMenuClose={this.handleMenuClose}
          open={this.state.idOfMenuToOpen === `${NOTIFICATION_MENU}${IDENTIFIER}`}
        >
          <NotificationMenu show={this.state.idOfMenuToOpen === `${NOTIFICATION_MENU}${IDENTIFIER}`} />
        </MenuPanel>

        {/*Menu of Profile in desktop view*/}
        <MenuPanel
          id={PROFILE_MENU}
          open={this.state.idOfMenuToOpen === `${PROFILE_MENU}${IDENTIFIER}`}
          anchorEl={this.state.desktopElementToAnchor}
          handleMenuClose={this.handleMenuClose}
        >
          <MenuItem>Settings</MenuItem>
          <MenuItem>Theme Change</MenuItem>
          <MenuItem onClick={
            () => {
              this.handleLougout();
            }
          }>Log Out</MenuItem>
        </MenuPanel>

        <Popover
          id={NAME_MOUSEOVER}
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={this.isPopOver()}
          anchorEl={this.state.popElementToAnchor}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => { this.updatePopElementToAnchor(null) }}
          disableRestoreFocus
        >
          <Typography>Ryan Fields</Typography>
        </Popover>
      </React.Fragment>
    );
  }
}

TopBarMenu.propTypes = {

};

TopBarMenu.defaultProps = {

};

export default withStyles(styles, { withTheme: true, name: 'TopBarMenu' })(withRouter(TopBarMenu));
