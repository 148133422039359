/*ReactJS*/
import React, { useState, useEffect } from 'react';
import { Typography, Modal, Backdrop, Fade, Box, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AutoCompleteCorrespondent from '../AutoComplete/AutoCompleteCorrespondent';
import SelectSystemCode from '../Dropdown/SelectSystemCode';
import TextEditor from '../TextEditor/TextEditor';
import Button from '../Buttons/Button';
import Text from '../Typography/Text';

import fontFamily from '../../assets/css/fonts';
import color from '../../assets/css/colors';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 550,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  fadeModalFull: {
    minWidth: 900,
    minHeight: 700,
    height: 500,
    width: '100%',
    margin: '0px auto',
    padding: '20px 20px 20px 15px',
    backgroundColor: '#ffffff',
    border: 'none',
    borderRadius: 5,
    boxShadow: '0px 0px 10px #333333',
    boxSizing: 'border-box',
    outline: 'none',
    overflowY: 'auto',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
  accordionDetails: {
    display: 'block !important',
    padding: '0px 20px 25px 20px !important',
  },
  accordion: {
    borderRadius: '5px !important',
    boxShadow: '0px 1px 5px -2px #cdcfdd !important',
    border: '1px solid #efeff0 !important',
  },
  tabBody: {
    minWidth: '100%',
    padding: '0px 6px',
  },
}));

export default function NoteModal({
  onClose: handleClose,
  open: isOpen,
  importMode: isImport,
  value,
  validation,
  loading,
}) {
  const classes = useStyles();
  const [modalData, setModalData] = useState({});
  const [isEdit, setIsEdit] = React.useState(false);
  const [note, setNote] = useState('');

  useEffect(() => {
    if (isOpen) {
      setModalData({ ...value });
      setNote(value.note);
      if (isImport || value.noteId) {
        setIsEdit(true);
      }
    }
  }, [isOpen, value, isImport]);

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setModalData({
      ...modalData,
      [input.name]: input.value,
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={() => {handleClose()}}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalFull}>
          <Text id="transition-modal-title" variant="h2" label={'Edit Note'} />
          <Box mt={5}>
            <form className={classes.modalForm} noValidate autoComplete="off">
              <div className={classes.modalBody}>
                {isImport && (
                  <div className="grd-row">
                    <div className="grd-cell" style={{ marginRight: 20 }}>
                      <AutoCompleteCorrespondent
                        fullWidth
                        required={!isEdit}
                        disabled={isEdit}
                        freeSolo={true}
                        name="correspondent"
                        value={modalData.correspondent}
                        onChange={handleChange}
                      ></AutoCompleteCorrespondent>
                    </div>
                    <div className="grd-cell" style={{ marginRight: 20 }}>
                      <TextField
                        fullWidth
                        required={!isEdit}
                        disabled={isEdit}
                        name="accountNo"
                        label="Account No"
                        value={modalData.accountNo}
                        onChange={handleChange}
                        inputProps={{ maxLength: 50 }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </div>
                )}
                <div className="grd-row">
                  <div className="grd-cell" style={{ marginRight: 30 }}>
                    <SelectSystemCode
                      error={validation.noteType}
                      required={!isEdit}
                      disabled={isEdit}
                      name="noteType"
                      label="Note Type"
                      type="Note Type"
                      subType="Client"
                      value={modalData.noteType}
                      onChange={handleChange}
                    ></SelectSystemCode>
                  </div>
                  <div className="grd-cell">
                    <TextField
                      error={validation.subject}
                      fullWidth
                      required={!isEdit}
                      disabled={isEdit}
                      name="subject"
                      label="Subject"
                      value={modalData.subject}
                      onChange={handleChange}
                      inputProps={{ maxLength: 100 }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>
                <div className="grd-row">
                  <div className="grd-cell">
                    <TextEditor
                      required={!isEdit}
                      disable={isEdit}
                      error={validation.note}
                      label="Note"
                      name="note"
                      value={value.note}
                      setContents={note}
                      onChange={setNote}
                    ></TextEditor>
                  </div>
                </div>
              </div>

              <div className={classes.modalFooter}>
                <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
                  <Button
                    disabled={loading}
                    color="primary"
                    label="Close"
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </div>
                <div className='grd-cell-none'>
                  <Button
                    disabled={loading}
                    loading={loading}
                    color="secondary"
                    label={loading ? 'Saving...' : 'Save'}
                    onClick={() => {
                      handleClose(modalData, isEdit, note);
                    }}
                  />
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
