import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Paper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { lazyLoadSecurity } from '../../services/CommonService';
import color from '../../assets/css/colors';
import fontFamily from '../../assets/css/fonts';

const useStyles = makeStyles(() => ({
  autocompleteWrapper: {
    // width: 170,
    '& .MuiAutocomplete-inputRoot': {
      padding: '7px 15px 8px 15px !important',
      height: 48,
    },
    '& .MuiAutocomplete-option': {
      backgroundColor: 'red',
    },
    '& .MuiAutocomplete-endAdornment': {
      top: 'calc(50% - 15px)',
      '& svg': {
        width: '22px !important',
        height: '22px !important',
      },
    },
  },
  paper: {
    fontFamily: fontFamily.label,
    fontSize: 15,
    fontWeight: 600,
    backgroundColor: color.light.main,
  },
}));

export default function AutoCompleteSymbol(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState(props.value);

  const setPropsValue = (value, cusip) => {
    props.onChange({
      currentTarget: {
        name: props.name,
        value: value,
        cusip: cusip,
      },
      target: {
        name: props.name,
        value: value,
        cusip: cusip,
      },
    });
  };

  const handleOnBlur = async (value) => {
    if (props.freeSolo) {
      setPropsValue(value, '');
      return;
    }

    const v = options.find((o) => o.symbol === value);
    if (v) {
      setPropsValue(v.symbol, v.cusip);
      return;
    }

    // if selected value does not exist in options and is not a free solo
    setInputValue('');
    setPropsValue('', '');
  };

  //set local input value and get options
  const handleInputChange = async (event, value) => {
    if (!event) return;
    if (event.type === 'blur') return;
    if (event.type === 'click' && value) return;

    setInputValue(value.toUpperCase());
    getOptions(value);
  };

  const getOptions = async (value) => {
    try {
      const { securitiesList } = await lazyLoadSecurity(value, props.assetType);
      setOptions(securitiesList.filter((a) => a.symbol));
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(
    () => {
      if (open) {
        getOptions(props.value);
      }
    },
    // eslint-disable-next-line
    [open]
  );

  return (
    <Autocomplete
      className={classes.autocompleteWrapper}
      disabled={props.disabled}
      freeSolo={props.freeSolo}
      id={props.name}
      onInputChange={handleInputChange}
      onBlur={() => {
        handleOnBlur(inputValue);

        if (props.onBlur) {
          props.onBlur();
        }
      }}
      blurOnSelect={false}
      onChange={(_, v) => {
        const value = v ? v.symbol : '';
        const cusip = v ? v.cusip : '';
        setPropsValue(value, cusip);
        setInputValue(value);
      }}
      getOptionSelected={(option, value) => option.symbol === value.symbol}
      getOptionLabel={(option) => option.symbol}
      inputValue={inputValue ? inputValue : props.value}
      options={options}
      autoHighlight={true}
      clearOnEscape={true}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      PaperComponent={({ children }) => (
        <Paper className={classes.paper}>{children}</Paper>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          error={props.error}
          required={props.required}
          disabled={props.disabled}
          label={props.label ? props.label : 'Symbol'}
          placeholder={
            props.placeholder ? props.placeholder : props.label ? props.label : 'Symbol'
          }
          InputLabelProps={{ shrink: true }}
          inputProps={{ ...params.inputProps, maxLength: props.maxLength || 10 }}
        />
      )}
    />
  );
}
