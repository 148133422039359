function formatPbDate(d) {
  if (!d) {
    return '';
  }
  if (!(d.hasOwnProperty('month')) || !(d.hasOwnProperty('day')) || !(d.hasOwnProperty('year'))){
    return '';
  }
  return (
    `${d.month.toString().padStart(2, '0')}/` +
    `${d.day.toString().padStart(2, '0')}/` +
    `${d.year.toString().padStart(2, '0')}`
  );
}

function formatPbDateDash(d) {
  if (!d) {
    return '';
  }
  if (!(d.hasOwnProperty('month')) || !(d.hasOwnProperty('day')) || !(d.hasOwnProperty('year'))){
    return '';
  }
  return (
    `${d.year.toString().padStart(2, '0')}-` +
    `${d.month.toString().padStart(2, '0')}-` +
    `${d.day.toString().padStart(2, '0')}`
  );
}

export function dateProtoObjectToString(o) {
  if (!o) return "";

  const { year, month, day } = o;
  const m = padString(month, '0', 2);
  const d = padString(day, '0', 2);

  return `${year}-${m}-${d}`;
}


// d is assumed a decimal string
function formatDollar(d) {
  if (!d) {
    return '';
  }
  if (parseFloat(d) === 0) {
    return '$0.00';
  }
  const sign = parseFloat(d) < 0 ? '-$' : '$';
  let [digit, decimal] = d.replace(/^-/, '').split('.');
  const minDecimal = parseFloat(digit) < 1 ? 4 : 2;
  if (!decimal) {
    decimal = '';
  }
  if (decimal.length < minDecimal) {
    decimal += '0'.repeat(minDecimal - decimal.length);
  }
  return `${sign}${formatQty(digit)}.${decimal}`;
}

function formatCurrency(
  amount,
  decimalCount = 2,
  decimal = '.',
  thousands = ','
) {
  
  decimalCount = Math.abs(decimalCount);
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

  const negativeSign = amount < 0 ? '-$' : '$';

  let i = parseInt(
    (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
  ).toString();

  let j = i.length > 3 ? i.length % 3 : 0;

  const finalAmount =
    negativeSign +
    (j ? i.substr(0, j) + thousands : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
    (decimalCount
      ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
      : '');

  return `${finalAmount}`;
}

function formatCurrencySymbol(
  amount,
  currency = 'USD',
  decimalCount = 2,
  decimal = '.',
  thousands = ','
) {
  try {
    return new Intl.NumberFormat('en-EN', {
      style: 'currency',
      currency: currency.split(' ')[0],
      minimumFractionDigits: 2,
      maximumFractionDigits: 4,
    }).format(amount);
  } catch (error) {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-$' : '$';

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();

    let j = i.length > 3 ? i.length % 3 : 0;

    const finalAmount =
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '');

    return `${finalAmount}`;
  }
}


function formatPercentage(value) {
  if (value === '') {
    return '';
  }

  return value + '%';
}

function formatQty(x) {
  if (x % 1 != 0) {
    return x;
  } else {
    return x?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ','); //old - /\B(?=(\d{3})+(?!\d))/g, ',': this cause 6 decimal place format as thousand
  }
}

function formatStatus(value) {
  return value.slice(0,1).toUpperCase() + value.slice(1, value.length)
}

function formatNote(value) {
  if (value.length < 30)
    return value
  else 
    return value.substring(0, 30) + " ..."
}

function padString(value, padchar, charCount) {
  const pad = padchar.repeat(charCount);
  const formatted = (pad + value).slice(-charCount);

  return formatted;
}

function twelveHourTimeFormat(t) {
  if (!t) {
    return '--:--:--';
  }

  if (new Date(1000 * t.seconds).toISOString().substr(11, 8) === '00:00:00') {
    return '--:--:--';
  }

  function getTime() {
    const convertSecToDate = (secs) => {
      let t = new Date(1970, 0, 1);
      t.setSeconds(secs);

      return t;
    };

    let date = new Date(convertSecToDate(t.seconds));
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    
    // Check whether AM or PM
    let newformat = hours >= 12 ? 'PM' : 'AM'; 
    
    // Find current hour in AM-PM Format
    hours = hours % 12; 
    
    // To display "0" as "12"
    hours = hours ? hours : 12; 
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds ? seconds < 10 ? ('0' + seconds) : seconds : '00';
    
    return hours + ':' + minutes + ':' + seconds + ' ' + newformat;
  }
    
  return getTime();
}

function formatDuration(t) {
  if (!t) {
    return '--:--:--'
  }

  function getTime() {
    const convertSecToDate = (secs) => {
      let t = new Date(1970, 0, 1);
      t.setSeconds(secs);

      return t;
    }

    let date = new Date(convertSecToDate(t.seconds));
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    
    // Find current hour in AM-PM Format
    hours = hours % 12; 
    
    // To display "0" as "12"
    hours = hours ? hours : '00'; 
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds ? seconds < 10 ? ('0' + seconds) : seconds : '00';
    
    return hours + ':' + minutes + ':' + seconds;
  }
    
  return getTime();
}

export {
  formatPbDate,
  formatPbDateDash,
  formatDollar,
  formatCurrency,
  formatCurrencySymbol,
  formatPercentage,
  formatQty,
  formatStatus,
  formatNote,
  twelveHourTimeFormat,
  formatDuration,
  
};
