import React from 'react'
import { IconButton } from '@material-ui/core'
import { 
    Create as EditIcon,
    Visibility as ViewIcon,
} from '@material-ui/icons'
import ClientGrid from '../../components/ClientGrid/ClientGrid'
import Button from '../../components/Buttons/Button'
import SelectSystemCode from '../../components/Dropdown/SelectSystemCode'
import Text from '../../components/Typography/Text'
import TextField from '../../components/Textfields/TextField'
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from '../../components/Messages/Notification'
import { ticketColumns } from '../../lib/data/columns'
import {
  createTicket,
  listTicket,
  updateEntitlement,
  updateTicket,
} from '../../services/TicketService';
import QueryParam from '../../services/QueryParamService'
import { getCurrentUser } from '../../services/AuthService'
import TicketModal from './Components/TicketModal'

export default function Ticket() {
  const [rows, setRows] = React.useState([])
  const [rowData, setRowData] = React.useState({})
  const [open, setOpen] = React.useState({
    add: false,
    edit: false,
  })
  const [loading, setLoading] = React.useState({
    search: false,
    save: false,
  })
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
        topic: '',
        title: '',
        status: '',
    }),
  )
  const [user, setUser] = React.useState(getCurrentUser())

  const GridButtons = ({
    tableManager,
    value,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return (
      <div className='grd-row'>
        <div className='grd-cell nm'>
          <IconButton aria-label="edit" onClick={() => handleOpen(data)}>
            <EditIcon style={{ height: 24, width: 24 }} />
          </IconButton>
        </div>
      </div>
    )
  }

  const columns = [
    {
      id: '',
      label: '',
      width: '60px',
      cellRenderer: GridButtons,
    },
    ...ticketColumns,
  ]

  const handleSearchDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target

    setSearchData({ ...searchData, [input.name]: input.value })
  }

  const handleSearch = async (notify) => {
    try {
      setLoading({ ...loading, search: true })

      const newSearchData = {
          ...searchData,
          userId: user.UserId
      }
      QueryParam.set(newSearchData)

      const data = await listTicket(newSearchData)
      setRows(data.ticketsList)

      if (notify){
        notifyInfo(
          data.ticketsList.length > 0
            ? data.ticketsList.length + ' search result(s)'
            : 'No records found.',
        )
      }
    } catch (error) {
      notifyError(error.message)
      console.log(error)
      return
    } finally {
      setLoading({ ...loading, search: false })
    }
  }

  const handleOpen = async (data) => {
    if(data){
        setRowData(data)
        setOpen({
            ...open,
            edit: true,
            add: false
        })
    } else {
        setRowData({})
        setOpen({
            ...open,
            add: true,
            edit: false,
        })
    }
  };
  
  const validateInput  = (data) =>{
    let isValid = true;
    if(!data.topic){
      notifyError('Topic is required')
      isValid = false;
    }

    if(!data.title){
      notifyError('Title is required')
      isValid = false;
    }

    if(!data.description){
      notifyError('Description is required')
      isValid = false;
    }

    return isValid;
  }

  const handleClose = async (data) => {
    if (!data || (user.UserType === "User" && open.edit)) {
      setOpen({
          ...open,
          add: false,
          edit: false,
      });
      return;
    }

    const isValid = validateInput(data)

    if(!isValid){
      return;
    }
    try {
      if (open.edit){
        const resp = await updateTicket(data);
        notifySuccess('Ticket has been updated.');
      } else {
        data.userId = user.UserId
        const resp = await createTicket(data);
        notifySuccess('Ticket has been created.');
      }

      handleSearch(false);
    } catch (error) {
      console.error(error);
      notifyError(error.message);
    }

    setOpen({
      ...open,
      add: false,
      edit: false,
    });
  };

  const getCsvData = async () => {
    const data = await listTicket(searchData)

    return data.ticketsList
  }

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Ticket" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading.search}
              loading={loading.search}
              type="search"
              label={loading.search ? 'Searching...' : 'Search'}
              onClick={() => {handleSearch(true)}}
            />
          </div>
          {user.UserType === "User" && (
          <div className="grd-cell-none">
            <Button
              type="plus"
              label="Add Ticket"
              onClick={() => handleOpen()}
            />
          </div>
          )}
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <SelectSystemCode
              name="status"
              label="Status"
              placeholder="Blank"
              type="Status"
              subType="AI"
              value={searchData.status}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <SelectSystemCode
              name="topic"
              label="Topic"
              placeholder="Blank"
              type="Ticket"
              subType="Topic"
              value={searchData.topic}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <TextField
              name="title"
              label="Title"
              value={searchData.title}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell"> </div>
        </div>
      </div>
      <div className="mt-20">
        <ClientGrid
          title="Tickets"
          rowIdField="ticketId"
          columns={columns}
          rows={rows}
          getCsvData={getCsvData}
          isLoading={loading.search}
          setRows={setRows}
        />
      </div>
      {(open.edit || open.add) && <TicketModal
        data={rowData}
        open={open.edit || open.add}
        isEdit={open.edit}
        user={user}
        close={handleClose}
        loading={loading.save}>
      </TicketModal>}
    </div>
  )
}
