import {
  CallLogServiceClient,
  ListCallLogRequest,
  CallLog,
  CallNoticeRequest,
} from '../proto/marginpb/calllog_grpc_web_pb';
import { stringToProtoDate, objectToProtoDate } from "./ConvertService";
import { notifyError } from '../components/Messages/Notification';
import { auth } from "../lib/auth/Auth";

const service = new CallLogServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

export async function listCallLog(param) {
  return new Promise((resolve, reject) => {
    const marginTypes = [];

    if (param.marginType?.length > 0) {
      for (let i = 0; i < param.marginType?.length; i++) {
        marginTypes.push(param.marginType[i].code);
      }
    }

    const marginType = "'" + marginTypes.join("','") + "'";

    let req = new ListCallLogRequest();
    req.setToDate(stringToProtoDate(param.toDate));
    req.setFromDate(stringToProtoDate(param.fromDate));
    req.setCorrespondent(param.correspondent);
    req.setMasterAccountNo(param.masterAccountNo);
    req.setAccountName(param.accountName);
    req.setCallType(param.callType);
    req.setCallStatus(param.callStatus);
    req.setCallStatusReason(param.callStatusReason);
    req.setMarginType(marginType);
    req.setIsOpen(param.isOpen);

    service.listCallLog(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateCallLog(param) {
  return new Promise((resolve, reject) => {
    const req = new CallLog();
    req.setRequirementLogId(param.requirementLogId);
    req.setCallStatus(param.callStatus);
    req.setCallStatusReason(param.callStatusReason);
    req.setTradeDate(objectToProtoDate(param.tradeDate));

    service.updateCallLog(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function callNotice(param) {
  return new Promise((resolve, reject) => {
    let req = new CallNoticeRequest();
    req.setAccountIdsList(param);

    service.callNotice(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
