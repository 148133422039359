import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import Text from '../Typography/Text';
import PasswordField from '../Textfields/PasswordField';
import Button from '../Buttons/Button';
import CloseButton from '../Buttons/CloseButton';
import FormMessage from "../Messages/FormMessage";
import fontFamily from '../../assets/css/fonts';
import color from '../../assets/css/colors';
import { validatePassword } from '../../lib/validate/validate';
import { changePassword } from '../../services/AdministratorService';
import authSvc from '../../services/AuthService';

const useStyles = makeStyles(() => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: 550,
        width: '100%',
        margin: '0 auto',
        float: 'clear',
    },
    modalHeader: {
        backgroundColor: 'white',
        padding: 25,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        display: 'flex',
        '& div:nth-child(1)': {
        flex: 1,
        },
        '& div:nth-child(2)': {
            flex: 'none',
            '& .MuiIconButton-root': {
            borderRadius: 5,
            padding: 10,
            '&:hover svg path': {
                fill: color.primary.main,
            },
            },
            '& svg:last-child': {
            width: 15,
            height: 15,
        }
        }
    },
    modalBody: {
            backgroundColor: 'white',
            padding: '0px 25px 25px 25px',
            fontFamily: fontFamily.headers,
            '& p': {
            fontSize: 16,
            lineHeight: '24px',
        }
    },
    modalFooter: {
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            backgroundColor: 'white',
            padding: '0px 25px 25px 25px',
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            position: 'relative',
            '& button': {
            width: 'auto',
        }
    },
    closeButton: {
        '& button': {
            marginTop: '-4px !important',
        } 
    },
}));

export default function ChangePasswordModal(props) {
    const classes = useStyles();

    const { open, close } = props;

    const [loading, setLoading] = React.useState(false);

    const [data, setData] = React.useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        usrId: authSvc.getCurrentUser()?.UserId,
    });

    const [message, setMessage] = React.useState({
        type: '',
        value: '',
    });

    const handleChange = (e) => {
        const input = e.currentTarget.name ? e.currentTarget : e.target;

        setData({ ...data, [input.name]: input.value });
    }

    const handleSubmit = async () => {
        if (!data.oldPassword) {
            setMessage({ type: 'error', value: 'Old Password is required.' });
            return;
        }
        if (!data.newPassword) {
            setMessage({ type: 'error', value: 'New Password is required.' });
            return;
        }
        if (!data.confirmPassword) {
            setMessage({ type: 'error', value: 'Confirm Password is required.' });
            return;
        }
        if (validatePassword(data.newPassword).err) {
            setMessage({ type: 'error', value: 'New Password does not meet the requirement.' });
            return;
        }
        if (validatePassword(data.confirmPassword).err) {
            setMessage({ type: 'error', value: 'Confirm New Password does not meet the requirement.' });
            return;
        }
        if (data.newPassword !== data.confirmPassword) {
            setMessage({ type: 'error', value: 'Password does not match.' });
            return;
        }

        try {
            setLoading(true);
    
            const res = await changePassword(data);

            if (res.status !== 'Success') {
                setMessage({ type: "error", value: res.msg });
                setLoading(false);
                return;
            } 
            else {
                setData({
                    ...data,
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: '',
                });
        
                setMessage({ type: 'success', value: res.msg });
                setLoading(false);
            }
        }
        catch (error) {
            setMessage({ type: 'error', value: error.message });
            setLoading(false);
            return;
        }
    }

    const handleClose = () => {
        setData({
            ...data,
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        });

        setMessage({ 
            type: '', 
            value: '' 
        });

        close();
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={close}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{timeout: 500,}}
        >
            <Fade in={open}>
                <div style={{width: 485}}>
                    <div className={classes.modalHeader}>
                        <div>
                            <Text
                                mt={4}
                                variant="h2"
                                label="Change Password"
                            />
                        </div>
                        <div className={classes.closeButton}>
                            <CloseButton onClick={handleClose} />
                        </div>
                    </div>
                    <div className={classes.modalBody}>
                        <form autoComplete="off">
                                {message.value !== '' 
                                ? (<FormMessage type={message.type} message={message.value} />) 
                                : null}
                                <PasswordField
                                    max={50}
                                    showIcon={false}
                                    name="oldPassword"
                                    label="Old Password"
                                    placeholder="Old Password"
                                    type="password"
                                    value={data.oldPassword}
                                    onChange={handleChange}
                                />
                                <PasswordField
                                    mt={true}
                                    max={50}
                                    showIcon={false}
                                    showPasswordRequirement={true}
                                    name="newPassword"
                                    label="New Password"
                                    placeholder="New Password"
                                    type="password"
                                    value={data.newPassword}
                                    onChange={handleChange}
                                />
                                <PasswordField
                                    mt={true}
                                    max={50}
                                    showIcon={false}
                                    showPasswordRequirement={true}
                                    name="confirmPassword"
                                    label="Confirm New Password"
                                    placeholder="Confirm New Password"
                                    type="password"
                                    value={data.confirmPassword}
                                    onChange={handleChange}
                                />
                            </form>
                    </div>
                    <div className={classes.modalFooter}>
                        <Button
                            disabled={loading}
                            loading={loading}
                            label={loading ? "Submiting..." : "Submit"}
                            onClick={handleSubmit}
                        />
                    </div>
                </div>
            </Fade>
        </Modal>
  );
}