import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { createSelector } from 'reselect';

import {
  AuthServiceClient,
  LoginRequest,
  RefreshTokenRequest,
  ValidateCodeRequest,
  ChangePasswordRequest,
  RefreshAccessRequest,
  SessionRequest,
  GetKeyRequest,
  GetModeRequest,
  LoginUserRequest,
  ValidateAccountRequest,
  SendAuthicationCodeRequest,
  ValidateAuthicationCodeRequest,
  ValidateAuthenticationGACodeRequest,
  ChangePasswordPreLoginRequest,
  VerifySecurityAnswerRequest,
} from '../proto/authpb/auth_grpc_web_pb';

import { verifyToken } from 'authenticator';

import { processError } from './Helper';

import { auth } from '../lib/auth/Auth';



const tokenKey = 'id_token';
const refreshTokenKey = 'refresh_token';
const authMsgKey = 'auth_error';
const client = new AuthServiceClient(window.env.GRPC_ENDPOINT);
let userAccess = undefined;

export function getToken() {
  return localStorage.getItem(tokenKey);
}

export function removeToken() {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(refreshTokenKey);
  localStorage.removeItem('access');
  localStorage.removeItem('code_validity');
  localStorage.removeItem('account_id');
  localStorage.removeItem('pages');
  localStorage.removeItem('search_data');
  localStorage.removeItem('access_token');
  localStorage.removeItem('auth_error');
  // localStorage.clear();
}

export function getAuthErrorMessage() {
  return localStorage.getItem(authMsgKey);
}

export function setAuthErrorMessage(msg) {
  return localStorage.setItem(authMsgKey, msg);
}

export function removeAuthErrorMessage() {
  return localStorage.removeItem(authMsgKey);
}

export function getCurrentUser() {
  try {
    const jwt = getToken();
    const jwtDecodedData = jwtDecode(jwt);

    localStorage.setItem('account_id', jwtDecodedData.AccountId);
    //TODO REMEMBER: setItem to Local storage

    return jwtDecodedData;
  } catch (error) {
    return null;
  }
}

export function getUserAccess() {
  refreshAccessPromise();

  const access = localStorage.getItem('access');
  userAccess = JSON.parse(access);
  return userAccess;
}

export const refreshAccessPromise = createSelector(
  () =>
    (async () => {
      const userAccessesList = await refreshAccess();
      return userAccessesList;
    })(),
  (userAccessesList) => userAccessesList
);

export function refreshToken(auth) {
  //if user not logged in then return
  const user = getCurrentUser();
  if (!user) return;

  //if token already expired then return
  const unixNow = new Date().getTime() / 1000;
  if (unixNow > user.exp) return;

  //if token will not expire in 1hr then return
  const refreshTime = moment(new Date()).add(60, 'm').toDate();
  const refreshUnix = refreshTime.getTime() / 1000;
  if (refreshUnix < user.exp) return;

  //if token will about to expire then request a new one
  let req = new RefreshTokenRequest();
  req.setRefreshToken(localStorage.getItem(refreshTokenKey));
  req.setClientId(window.env.GRPC_CLIENT_ID);

  const service = new AuthServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });

  service.refreshToken(req, {}, (error, response) => {
    if (error) {
      console.error('refreshToken', error);
    } else {
      const { accessToken, refreshToken, userAccessesList } = response.toObject();
      SetTokens(accessToken, refreshToken, userAccessesList);
    }
  });
}

export async function refreshAccess() {
  console.log('refresh access attempt - updated ui');

  //if user not logged in then return
  const user = getCurrentUser();
  if (!user) return;
  //if token already expired then return
  const unixNow = new Date().getTime() / 1000;
  if (unixNow > user.exp) return;
  //do not request if expiration is more than a minute
  //if (user.exp - unixNow > 360) return;

  const service = new AuthServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });

  //if token will about to expire then request a new one
  return new Promise((resolve, reject) => {
    let req = new RefreshAccessRequest();
    req.setEmail(user.Username);

    service.refreshAccess(req, {}, (error, response) => {
      if (error) {
        console.error('refreshToken', error);
      } else {
        const { userAccessesList } = response.toObject();
        console.log('access refreshed');
        setAccess(userAccessesList);
        resolve(userAccessesList);
      }
    });
  });
}

function setAccess(userAccessesList) {
  let access = {};
  for (let i = 0; i < userAccessesList.length; i++) {
    const a = userAccessesList[i];
    if (a.access) {
      const key = (a.menu + a.subMenu + a.pageName).replace(/ /g, '');
      access[key] = a.access;
    }
  }

  localStorage.setItem('pages', JSON.stringify(userAccessesList));
  localStorage.setItem('access', JSON.stringify(access));
}

async function loginPromise(data) {
  return new Promise((resolve, reject) => {
    let req = new LoginRequest();
    // req.setEmail(data.email);
    req.setUsername(data.username);
    req.setPassword(data.password);
    req.setMode(data.mode);
    req.setClientId(window.env.GRPC_CLIENT_ID);
    req.setAuthenticationMode(data.authenticationMode);
    req.setSiteUrl('https://' + window.location.hostname);
    req.setDeviceId(data.deviceID);

    if (data.mode === 'Log In Via Token') {
      req.setAccessToken(data.access_token);
      req.setUsrId(data.usr_id);
    }

    client.login(req, {}, (error, response) => {
      if (error) {
        reject(processError(error));
      } else {
        if (data.mode === 'Log In Via Token') {
          localStorage.setItem('code_validity', true);
        }

        resolve(response.toObject());
      }
    });
  });
}

async function loginUserPromise(data) {
  return new Promise((resolve, reject) => {
    let req = new LoginUserRequest();
    req.setIUser(data.i_user);
    req.setIToken(data.i_token);

    req.setClientId(window.env.GRPC_CLIENT_ID);

    client.loginuser(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        localStorage.setItem('code_validity', true);

        resolve(response.toObject());
      }
    });
  });
}

export async function login(data) {
  const { accessToken, refreshToken, userAccessesList } = await loginPromise(data);

  SetTokens(accessToken, refreshToken, userAccessesList);

  const { UserId } = getCurrentUser();

  return UserId;
}

export async function loginAPW(data) {
  const { accessToken, refreshToken, userAccessesList } = await loginPromise(data);

  SetTokens(accessToken, refreshToken, userAccessesList);

  return getCurrentUser();
}

export async function loginuser(data) {
  const { accessToken, refreshToken, userAccessesList } = await loginUserPromise(data);

  SetTokens(accessToken, refreshToken, userAccessesList);

  const { UserId } = getCurrentUser();

  return UserId;
}

export function SetTokens(accessToken, refreshToken, userAccessesList) {
  localStorage.setItem(tokenKey, accessToken);
  localStorage.setItem(refreshTokenKey, refreshToken);

  let access = {};

  for (let i = 0; i < userAccessesList?.length; i++) {
    const a = userAccessesList[i];
    if (a.access) {
      const key = (a.menu + a.subMenu + a.pageName).replace(/ /g, '');
      access[key] = a.access;
    }
  }

  localStorage.setItem('pages', JSON.stringify(userAccessesList));
  localStorage.setItem('access', JSON.stringify(access));
}

export function logout() {
  return new Promise((resolve, reject) => {

    try {
      removeToken();
      resolve("success");
    } catch (error) {
      reject(error);
    }

  });
}

export function getPages() {
  const lsp = localStorage.getItem('pages');
  const pages = JSON.parse(lsp);
  return pages;
}

export function validateAuthCode(param) {
  const service = new AuthServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });

  return new Promise((resolve, reject) => {
    let req = new ValidateCodeRequest();
    req.setUsrId(param.usrId);
    req.setCode(param.code);

    service.validateCode(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        const res = response.toObject();

        if (res.status === 'Success') {
          localStorage.setItem('code_validity', true);
        }

        resolve(res);
      }
    });
  });
}

export function changePassword(param) {
  const service = new AuthServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });

  return new Promise((resolve, reject) => {
    let req = new ChangePasswordRequest();
    req.setUsrId(param.usrId);
    req.setLastPassword(param.lastPassword);
    req.setNewPassword(param.newPassword);
    req.setSecurityWordHint(param.securityWordHint);
    req.setSecurityWord(param.securityWord);

    service.changePassword(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export function getValidCodeResponse() {
  const valid = localStorage.getItem('code_validity');
  return valid;
}

export async function validateSession(params) {
  const service = new AuthServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });

  return new Promise((resolve, reject) => {
    const req = new SessionRequest();
    req.setUsrId(params.usrId);
    req.setEmail(params.email);
    req.setAccessToken(params.accessToken);

    service.validateSession(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

async function getAuthMode(data) {
  return new Promise((resolve, reject) => {
    let req = new GetModeRequest();
    req.setEmail(data.email);

    client.getAuthMode(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

async function getKey(data) {
  const service = new AuthServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });

  return new Promise((resolve, reject) => {
    let req = new GetKeyRequest();
    req.setEmail(data.email);
    req.setUsrId(data.usrId);

    service.getKey(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export function validateAuthenticatorCode(param) {
  if (verifyToken(param.key, param.code) === null) {
    return 'Invalid verification code.';
  } else {
    localStorage.setItem('code_validity', true);
    return 'Success';
  }
}

export function getAccess(component, userType) {
  if (component === 'master')
    return !(
      userType === 'Master Account' ||
      userType === 'User' ||
      userType === 'Counterparty'
    );
  if (component === 'correspondent')
    return !(userType === 'Counterparty' || userType === 'User');
  if (component === 'account_no') return !(userType === 'User');
  return true;
}

function validateAccount(data) {
  return new Promise((resolve, reject) => {
    let req = new ValidateAccountRequest();
    req.setUsername(data.username);

    client.validateAccount(req, {}, (error, response) => {
      if (error) {
        console.log(error)
        reject(processError(error));
      } else {
        resolve(response.toObject());
      }
    });
  });
}

function sendAuthenticationCode(data) {
  return new Promise((resolve, reject) => {
    let req = new SendAuthicationCodeRequest();
    req.setUsername(data.username);
    req.setDeliveryMethod(data.deliveryMethod)

    client.sendAuthenticationCode(req, {}, (error, response) => {
      if (error) {
        reject(processError(error));
      } else {
        resolve(response.toObject());
      }
    });
  });
}

  function validateGoogleAuthenticationCode(data) {
    return new Promise((resolve, reject) => {
      let req = new ValidateAuthenticationGACodeRequest();
      req.setUsername(data.username);
      req.setAuthenticationCode(data.authenticationCode);
      req.setRememberMe(data.rememberMe);
      req.setDeviceId(data.deviceID);

      client.validateGAAuthenticationCode(req, {}, (error, response) => {
        if (error) {
          reject(processError(error));
        } else {
          localStorage.setItem('code_validity', true);
          resolve(response.toObject());
        }
      });
    });
  }

 function validateAuthenticationCode(data) {
  return new Promise((resolve, reject) => {

    let req = new ValidateAuthicationCodeRequest();
    req.setUsername(data.username);
    req.setAuthenticationCode(data.authenticationCode);
    req.setDeliveryMethod(data.deliveryMethod)
    req.setRememberMe(data.rememberMe);
    req.setDeviceId(data.deviceID)

    client.validateAuthenticationCode(req, {}, (error, response) => {
      if (error) {
        reject(processError(error));
      } else {
        localStorage.setItem('code_validity', true);
        resolve(response.toObject());
      }
    });
  });
}

function changePasswordPreLogin(data) {
  return new Promise((resolve, reject) => {
    let req = new ChangePasswordPreLoginRequest();
    req.setUsername(data.username);
    req.setPassword(data.password);

    client.changePasswordPreLogin(req, {}, (error, response) => {
      if (error) {
        console.log(error)
        reject(processError(error));
      } else {
        resolve(response.toObject());
      }
    });
  });
}

function verifySecurityAnswer(data) {
  const service = new AuthServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });

  return new Promise((resolve, reject) => {
    let req = new VerifySecurityAnswerRequest();
    req.setQuestionId(data.questionID);
    req.setQuestionText(data.questionText);
    req.setAnswer(data.answer);

    service.verifySecurityAnswer(req, {}, (error, response) => {
      if (error) {
        reject(processError(error));
      } else {
        resolve(response.toObject());
      }
    });
  });
}

const authServices = {
  removeToken,
  getToken,
  getAuthErrorMessage,
  setAuthErrorMessage,
  removeAuthErrorMessage,
  logout,
  login,
  getCurrentUser,
  refreshToken,
  refreshAccess,
  getUserAccess,
  getPages,
  validateAuthCode,
  getValidCodeResponse,
  changePassword,
  validateSession,
  getKey,
  getAuthMode,
  validateAuthenticatorCode,
  getAccess,
  loginuser,
  refreshAccessPromise,
  validateAccount,
  sendAuthenticationCode,
  validateAuthenticationCode,
  validateGoogleAuthenticationCode,
  changePasswordPreLogin,
  verifySecurityAnswer,
  loginAPW
};

export default authServices;
