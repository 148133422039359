import {
  TileBigIcon,
  CogIcon,
  BellIcon
} from '../Icons/Mono/Utility';
import {
  UsersIcon
} from '../Icons/Mono/Communication';
import {
  TradingIcon
} from '../Icons/Mono/Invest';
import {
  ArrowsHorizontalIcon,
  DoubleChevronRightIcon
} from '../Icons/Mono/Navigational';
import {
  ClipboardCheckIcon,
  FileIcon
} from '../Icons/Mono/Files';

const AppDashboardPath = '/app/dashboard'

const AppAccountsAllAccountsPath = '/app/accounts/all-accounts'
const AppAccountsAllPositionsPath = '/app/accounts/all-positions'
const AppAccountsAllActivityPath = '/app/accounts/all-activity'

const AppTradingSingleOrderPath = '/app/trading/single-order'
const AppTradingSubmittedTradesPath = '/app/trading/submitted-trades'
const AppTradingSavedOrdersPath = '/app/trading/saved-orders'
const AppTradingBasketTradesPath = '/app/trading/basket-trades'

const AppMoveMoneyPath = '/app/move-money'

const AppReportingPath = '/app/reporting'

const AppCommunicationPath = '/app/communication'

const AppFirmSettingsPath = '/app/firm-settings'

const AppDocumentsPath = '/app/documents'

const menuItems = [
  {
    label: 'Dashboard',
    icon: <TileBigIcon />,
    href: AppDashboardPath
  },
  {
    label: 'Accounts',
    icon: <UsersIcon />,
    submenu: [
      {
        label: 'All Accounts',
        href: AppAccountsAllAccountsPath
      },
      {
        label: 'All Positions',
        href: AppAccountsAllPositionsPath
      },
      {
        label: 'All Activity',
        href: AppAccountsAllActivityPath
      },
    ],
  },
  {
    label: 'Trading',
    icon: <TradingIcon />,
    submenu: [
      {
        label: 'Single Order',
        href: AppTradingSingleOrderPath
      },
      {
        label: 'Submitted Trades',
        href: AppTradingSubmittedTradesPath
      },
      {
        label: 'Saved Orders',
        href: AppTradingSavedOrdersPath
      },
      {
        label: 'Basket Trades',
        href: AppTradingBasketTradesPath
      },
    ],
  },
  {
    label: 'Move Money',
    icon: <ArrowsHorizontalIcon />,
    href: AppMoveMoneyPath
  },
  {
    label: 'Reporting',
    icon: <ClipboardCheckIcon />,
    href: AppReportingPath
  },
  {
    label: 'Documents',
    icon: <FileIcon />,
    href: AppDocumentsPath
  },
  {
    label: 'Communication',
    icon: <BellIcon />,
    href: AppCommunicationPath
  },
  {
    label: 'Firm Settings',
    icon: <CogIcon />,
    href: AppFirmSettingsPath
  },
  // Add more items as needed
];

export {
  menuItems,
  AppDashboardPath,
  AppAccountsAllAccountsPath,
  AppAccountsAllPositionsPath,
  AppAccountsAllActivityPath,
  AppTradingSingleOrderPath,
  AppTradingSubmittedTradesPath,
  AppTradingSavedOrdersPath,
  AppTradingBasketTradesPath,
  AppMoveMoneyPath,
  AppReportingPath,
  AppCommunicationPath,
  AppFirmSettingsPath,
  AppDocumentsPath
};