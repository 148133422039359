import React from 'react'
import ClientGrid from '../../../components/ClientGrid/ClientGrid'
import Button from '../../../components/Buttons/Button'
import DateTimePicker from '../../../components/Textfields/DateTimePicker'
import SelectSystemCode from '../../../components/Dropdown/SelectSystemCode'
import AutoCompleteAccountNo from '../../../components/AutoComplete/AutoCompleteAccountNo'
import AutoCompleteAccountName from '../../../components/AutoComplete/AutoCompleteAccountName'
import AutoCompleteMasterAccountNo from '../../../components/AutoComplete/AutoCompleteMasterAccountNo'
import AutoCompleteBranch from '../../../components/AutoComplete/AutoCompleteBranch'
import AutoCompleteRep from '../../../components/AutoComplete/AutoCompleteRep'
import AutoCompleteSymbol from '../../../components/AutoComplete/AutoCompleteSymbol'
import AutoCompleteCorrespondent from '../../../components/AutoComplete/AutoCompleteCorrespondent'
import Text from '../../../components/Typography/Text'
import {
  notifyInfo,
  notifyError,
} from '../../../components/Messages/Notification'
import { execiseDeclarationColumns } from '../../../lib/data/columns'
import { listExerciseDeclaration } from '../../../services/ExerciseDeclarationService'
import { getSystemDate } from '../../../services/ProfileService'
import QueryParam from '../../../services/QueryParamService'

export default function ExerciseDeclaration() {
  const [rows, setRows] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      dateType: '',
      fromDate: '',
      toDate: '',
      accountNo: '',
      correspondent: '',
      symbol: '',
      type: '',
      accountName: '',
      masterAccountNo: '',
      branch: '',
      rep: '',
      type: '',
      status: '',
    }),
  )

  const init = async () => {
    const searchParameters = localStorage.getItem('search_data')

    const {
      dateType,
      fromDate,
      toDate,
      accountNo,
      correspondent,
      symbol,
      type,
      accountName,
      masterAccountNo,
      branch,
      rep,
      broker,
      status,
    } = searchParameters ? JSON.parse(searchParameters) : searchData

    const systemDate = await getSystemDate()

    setSearchData({
      ...searchData,
      dateType: dateType ? dateType : 'System Date',
      fromDate: fromDate ? fromDate : systemDate,
      toDate: toDate ? toDate : systemDate,
      branch: branch ? branch : '',
      accountNo: accountNo ? accountNo : '',
      correspondent: correspondent ? correspondent : '',
      symbol: symbol ? symbol : '',
      type: type ? type : '',
      accountName: accountName ? accountName : '',
      masterAccountNo: masterAccountNo ? masterAccountNo : '',
      branch: branch ? branch : '',
      rep: rep ? rep : '',
      broker: broker ? broker : '',
      status: status ? status : '',
    })
  }

  React.useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  const handleSearchDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target

    setSearchData({ ...searchData, [input.name]: input.value })
  }

  const handleSearch = async () => {
    try {
      setLoading(true)

      QueryParam.set(searchData)

      localStorage.setItem('search_data', JSON.stringify(searchData))

      const data = await listExerciseDeclaration(searchData)

      setRows(data.exerciseDeclarationsList)

      notifyInfo(
        data.exerciseDeclarationsList.length > 0
          ? data.exerciseDeclarationsList.length + ' search result(s)'
          : 'No records found.',
      )
    } catch (error) {
      setLoading(false)
      notifyError(error.message)
      console.log(error)
      return
    } finally {
      setLoading(false)
    }
  }

  const getCsvData = async () => {
    const data = await listExerciseDeclaration(searchData)

    return data.exerciseDeclarationsList
  }

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Exercise Declaration" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading}
              loading={loading}
              type="search"
              label={loading ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <SelectSystemCode
              name="dateType"
              label="Date Type"
              placeholder="Blank"
              type="Date Type Announcement"
              value={searchData.dateType}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <DateTimePicker
              type="date"
              name="fromDate"
              label="From Date"
              value={searchData.fromDate}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <DateTimePicker
              type="date"
              name="toDate"
              label="To Date"
              value={searchData.toDate}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteCorrespondent
              isAccessibleOnly={true}
              name="correspondent"
              value={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteAccountNo
              isAccessibleOnly={true}
              name="accountNo"
              value={searchData.accountNo}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <AutoCompleteAccountName
              isAccessibleOnly={true}
              name="accountName"
              value={searchData.accountName}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteMasterAccountNo
              isAccessibleOnly={true}
              name="masterAccountNo"
              value={searchData.masterAccountNo}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteBranch
              isAccessibleOnly={true}
              name="branch"
              value={searchData.branch}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteRep
              isAccessibleOnly={true}
              name="rep"
              value={searchData.rep}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <SelectSystemCode
              name="broker"
              label="Broker"
              placeholder="Broker"
              type="Broker"
              value={searchData.broker}
              onChange={handleSearchDataChange}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <SelectSystemCode
              name="type"
              label="Type"
              placeholder="Type"
              type="Sub Account Type"
              value={searchData.type}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteSymbol
              name="symbol"
              value={searchData.symbol}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell"></div>
          <div className="grd-cell"></div>
          <div className="grd-cell"></div>
        </div>
      </div>
      <div className="mt-20">
        <ClientGrid
          title="Exercise Declaration"
          rowIdField="accountId"
          columns={execiseDeclarationColumns}
          rows={rows}
          getCsvData={getCsvData}
          isLoading={loading}
          setRows={setRows}
        />
      </div>
    </div>
  )
}
