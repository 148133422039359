import {
  pbDate,
  pbDateTime,
  currency,
  qty,
  number,
  htmlString,
  pbDateSorter,
  pbDateTimeSorter,
  boolean,
  percentage,
  missing,
  dateTimeString,
} from '../../components/ClientGrid/CellRenderer';
import { dateProtoObjectToString } from '../../services/ConvertService';


const positionColumns = [
  {
    id: 'date',
    field: 'date',
    label: 'Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'correspondent',
    field: 'correspondent',
    label: 'Correspondent',
  },
  {
    id: 'branch',
    field: 'branch',
    label: 'Branch',
  },
  {
    id: 'accountNo',
    field: 'accountNo',
    label: 'Account No',
  },
  {
    id: 'masterAccountNo',
    field: 'masterAccountNo',
    label: 'Master Account No',
  },
  {
    id: 'rep',
    field: 'rep',
    label: 'Rep',
  },
  {
    id: 'accountName',
    field: 'accountName',
    label: 'Account Name',
  },
  {
    id: 'symbol',
    field: 'symbol',
    label: 'Symbol',
  },
  {
    id: 'symbolDescription',
    field: 'symbolDescription',
    label: 'Symbol Description',
  },
  {
    id: 'qty',
    field: 'qty',
    label: 'Qty',
    cellRenderer: qty,
  },
  {
    id: 'closingPrice',
    field: 'closingPrice',
    label: 'Closing Price',
    cellRenderer: currency,
  },
  {
    id: 'marketValue',
    field: 'marketValue',
    label: 'Market Value',
    cellRenderer: currency,
  },
  {
    id: 'costBasis',
    field: 'costBasis',
    label: 'Cost Basis',
    cellRenderer: currency,
  },
];

const tradeConfirmColumns = [
  {
    id: 'tradeDate',
    field: 'tradeDate',
    label: 'Trade Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'correspondent',
    field: 'correspondent',
    label: 'Correspondent',
  },
  {
    id: 'branch',
    field: 'branch',
    label: 'Branch',
  },
  {
    id: 'accountNo',
    field: 'accountNo',
    label: 'Account No',
  },
  {
    id: 'masterAccountNo',
    field: 'masterAccountNo',
    label: 'Master Account No',
  },
  {
    id: 'rep',
    field: 'rep',
    label: 'Rep/Advisor',
  },
  {
    id: 'trnsCount',
    field: 'trnsCount',
    label: 'TRNS Count',
  },
];

const requirementColumns = [
  {
    id: 'tradeDate',
    field: 'tradeDate',
    label: 'Trade Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'correspondent',
    field: 'correspondent',
    label: 'Correspondent',
  },
  {
    id: 'masterAccountNo',
    field: 'masterAccountNo',
    label: 'Master Account No',
  },
  {
    id: 'accountName',
    field: 'accountName',
    label: 'Account Name',
  },
  {
    id: 'equity',
    field: 'equity',
    label: 'Equity',
    cellRenderer: currency,
  },
  {
    id: 'exchangeReq',
    field: 'exchangeReq',
    label: 'Exchange Req',
    cellRenderer: currency,
  },
  {
    id: 'exchangeCall',
    field: 'exchangeCall',
    label: 'Exchange Call',
    cellRenderer: currency,
  },
  {
    id: 'fedReq',
    field: 'fedReq',
    label: 'Fed Req',
    cellRenderer: currency,
  },
  {
    id: 'fedCall',
    field: 'fedCall',
    label: 'Fed Call',
    cellRenderer: currency,
  },
  {
    id: 'houseReq',
    field: 'houseReq',
    label: 'House Req',
    cellRenderer: currency,
  },
  {
    id: 'houseCall',
    field: 'houseCall',
    label: 'House Call',
    cellRenderer: currency,
  },
  {
    id: 'buyingPowerCall',
    field: 'buyingPowerCall',
    label: 'Buying Power Call',
  },
  {
    id: 'minBalanceCall',
    field: 'minBalanceCall',
    label: 'Min Balance Call',
    cellRenderer: currency,
  },
];

const statementColumns = [
  {
    id: 'correspondent',
    field: 'correspondent',
    label: 'Correspondent',
  },
  {
    id: 'branch',
    field: 'branch',
    label: 'Branch',
  },
  {
    id: 'accountNo',
    field: 'accountNo',
    label: 'Account No',
  },
  {
    id: 'accountName',
    field: 'accountName',
    label: 'Account Name',
  },
  {
    id: 'masterAccountNo',
    field: 'masterAccountNo',
    label: 'Master Account No',
  },
  {
    id: 'rep',
    field: 'rep',
    label: 'Rep/Advisor',
  },
];

const balanceColumns = [
  {
    id: 'date',
    field: 'date',
    label: 'Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'correspondent',
    field: 'correspondent',
    label: 'Correspondent',
  },
  {
    id: 'branch',
    field: 'branch',
    label: 'Branch',
  },
  {
    id: 'accountNo',
    field: 'accountNo',
    label: 'Account No',
  },
  {
    id: 'accountName',
    field: 'accountName',
    label: 'Account Name',
  },
  {
    id: 'cashBalance',
    field: 'cashBalance',
    label: 'Cash Balance',
    cellRenderer: currency,
  },
  {
    id: 'longMarketValue',
    field: 'longMarketValue',
    label: 'Long Market Value',
    cellRenderer: currency,
  },
  {
    id: 'shortMarketValue',
    field: 'shortMarketValue',
    label: 'Short Market Value',
    cellRenderer: currency,
  },
  {
    id: 'equity',
    field: 'equity',
    label: 'Equity',
    cellRenderer: currency,
  },
  {
    id: 'adjustedBalance',
    field: 'adjustedBalance',
    label: 'Adjusted Balance',
    cellRenderer: currency,
  },
];

const activityColumns = [
  {
    id: 'correspondent',
    field: 'correspondent',
    label: 'Correspondent',
  },
  {
    id: 'branch',
    field: 'branch',
    label: 'Branch',
  },
  {
    id: 'accountNo',
    field: 'accountNo',
    label: 'Account No',
  },
  {
    id: 'masterAccountNo',
    field: 'masterAccountNo',
    label: 'Master Account No',
  },
  {
    id: 'rep',
    field: 'rep',
    label: 'Rep',
  },
  {
    id: 'accountName',
    field: 'accountName',
    label: 'Account Name',
  },
  {
    id: 'tradeDate',
    field: 'tradeDate',
    label: 'Trade Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'settleDate',
    field: 'settleDate',
    label: 'Settle Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'entryType',
    field: 'entryType',
    label: 'Entry Type',
  },
  {
    id: 'symbol',
    field: 'symbol',
    label: 'Symbol',
  },
  {
    id: 'side',
    field: 'side',
    label: 'Side',
  },
  {
    id: 'qty',
    field: 'qty',
    label: 'Qty',
    cellRenderer: qty,
  },
  {
    id: 'price',
    field: 'price',
    label: 'Price',
    cellRenderer: currency,
  },
  {
    id: 'grossAmt',
    field: 'grossAmt',
    label: 'Gross Amount',
    cellRenderer: currency,
  },
  {
    id: 'regFee',
    field: 'regFee',
    label: 'Reg Fee',
    cellRenderer: currency,
  },
  {
    id: 'commission',
    field: 'commission',
    label: 'Commission',
    cellRenderer: currency,
  },
  {
    id: 'tafFee',
    field: 'tafFee',
    label: 'Taf Fee',
    cellRenderer: currency,
  },
  {
    id: 'netAmt',
    field: 'netAmt',
    label: 'Net Amount',
    cellRenderer: currency,
  },
  {
    id: 'status',
    field: 'status',
    label: 'Status',
  },
  {
    id: 'otherFees',
    field: 'otherFees',
    label: 'Other Fees',
  },
  {
    id: 'description',
    field: 'description',
    label: 'Description',
    cellRenderer: htmlString,
    visible: false,
  },
];

const bankAccountColumns = [
  {
    id: 'bankName',
    field: 'bankName',
    label: 'Bank Name',
  },
  {
    id: 'bankAccountNo',
    field: 'bankAccountNo',
    label: 'Account Number',
  },
  {
    id: 'bankAccountType',
    field: 'bankAccountType',
    label: 'Account Type',
  },
  {
    id: 'achRoutingNo',
    field: 'achRoutingNo',
    label: 'ACH Routing No',
  },
  {
    id: 'wireRoutingNo',
    field: 'wireRoutingNo',
    label: 'Wire Routing No',
  },
  {
    id: 'status',
    field: 'status',
    label: 'Status',
  },
];

const walletColumns = [
  {
    id: 'symbol',
    field: 'symbol',
    label: 'Symbol',
  },
  {
    id: 'symbolDescription',
    field: 'symbolDescription',
    label: 'Symbol Description',
  },
  {
    id: 'wallet',
    field: 'wallet',
    label: 'Wallet',
  },
  {
    id: 'accountNo',
    field: 'accountNo',
    label: 'Account No',
  },
  {
    id: 'status',
    field: 'status',
    label: 'Status',
  },
  {
    id: 'note',
    field: 'note',
    label: 'Note',
    cellRenderer: htmlString,
  },
  {
    id: 'originalCusip',
    field: 'originalCusip',
    label: 'Original Cusip',
  },
  {
    id: 'systemDate',
    field: 'systemDate',
    label: 'System Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'createdBy',
    field: 'createdBy',
    label: 'Created By',
  },
];

const walletSummaryColumns = [
  {
    id: 'masterACcountNo',
    field: 'masterAccountNo',
    label: 'Master Account No',
  },
  {
    id: 'correspondent',
    field: 'correspondent',
    label: 'Counterparty',
  },
  {
    id: 'accountNo',
    field: 'accountNo',
    label: 'Account No',
  },
  {
    id: 'qty',
    field: 'qty',
    label: 'Qty',
    cellRenderer: qty,
    addFooter: true,
  },
];

const digitalCashRequestColumns = [
  {
    id: 'correspondent',
    field: 'correspondent',
    label: 'Correspondent',
  },
  {
    id: 'accountNo',
    field: 'accountNo',
    label: 'Account No',
  },
  {
    id: 'accountName',
    field: 'accountName',
    label: 'Account Name',
  },
  {
    id: 'masterAccountNo',
    field: 'masterAccountNo',
    label: 'Master Account No',
  },
  {
    id: 'broker',
    field: 'broker',
    label: 'Broker',
  },
  {
    id: 'type',
    field: 'type',
    label: 'Type',
  },
  {
    id: 'symbol',
    field: 'symbol',
    label: 'Symbol',
  },
  {
    id: 'amt',
    field: 'amt',
    label: 'Amount',
    cellRenderer: currency,
  },
  {
    id: 'transferType',
    field: 'transferType',
    label: 'Transfer Type',
  },
  {
    id: 'referenceId',
    field: 'referenceId',
    label: 'Reference ID',
    visible: false,
  },
  {
    id: 'confirmationId',
    field: 'confirmationId',
    label: 'Confirmation ID',
    visible: false,
  },
  {
    id: 'status',
    field: 'status',
    label: 'Status',
  },
  {
    id: 'publicDescription',
    field: 'publicDescription',
    label: 'Public Description',
  },
  {
    id: 'privateDescription',
    field: 'privateDescription',
    label: 'Private Description',
  },
  {
    id: 'internalId',
    field: 'internalId',
    label: 'Internal ID',
    visible: false,
  },
  {
    id: 'externalId',
    field: 'externalId',
    label: 'External ID',
    visible: false,
  },
  {
    id: 'bankId',
    field: 'bankId',
    label: 'DDA Bank',
  },
  {
    id: 'requestType',
    field: 'requestType',
    label: 'DDA Request Type',
  },

  {
    id: 'createdBy',
    field: 'createdBy',
    label: 'Created By',
  },
  {
    id: 'systemDate',
    field: 'systemDate',
    label: 'System Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
];

const digitalCashRequestColumnsAxs = [
  {
    id: 'systemDate',
    field: 'systemDate',
    label: 'System Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'masterAccountNo',
    field: 'masterAccountNo',
    label: 'Master Account No',
  },
  {
    id: 'correspondent',
    field: 'correspondent',
    label: 'Counterparty',
  },
  {
    id: 'accountNo',
    field: 'accountNo',
    label: 'Account No',
  },
  {
    id: 'accountName',
    field: 'accountName',
    label: 'Account Name',
  },
  {
    id: 'broker',
    field: 'broker',
    label: 'Broker',
  },
  {
    id: 'type',
    field: 'type',
    label: 'Type',
  },
  {
    id: 'symbol',
    field: 'symbol',
    label: 'Symbol',
  },
  {
    id: 'amt',
    field: 'amt',
    label: 'Amount',
    cellRenderer: currency,
  },
  {
    id: 'transferType',
    field: 'transferType',
    label: 'Transfer Type',
  },
  {
    id: 'referenceId',
    field: 'referenceId',
    label: 'Reference ID',
    visible: false,
  },
  {
    id: 'confirmationId',
    field: 'confirmationId',
    label: 'Confirmation ID',
    visible: false,
  },
  {
    id: 'status',
    field: 'status',
    label: 'Status',
  },
  {
    id: 'publicDescription',
    field: 'publicDescription',
    label: 'Public Description',
    visible: false,
  },
  {
    id: 'privateDescription',
    field: 'privateDescription',
    label: 'Private Description',
    visible: false,
  },
  {
    id: 'internalId',
    field: 'internalId',
    label: 'Internal ID',
    visible: false,
  },
  {
    id: 'externalId',
    field: 'externalId',
    label: 'External ID',
    visible: false,
  },
  {
    id: 'bankId',
    field: 'bankId',
    label: 'Bank',
    visible: false,
  },
  {
    id: 'requestType',
    field: 'requestType',
    label: 'Request Type',
  },

  {
    id: 'createdBy',
    field: 'createdBy',
    label: 'Created By',
    visible: false,
  },
];
const digitalCashRequestSummaryColumns = [
  {
    id: 'fromDate',
    field: 'fromDate',
    label: 'From Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'toDate',
    field: 'toDate',
    label: 'To Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'correspondent',
    field: 'correspondent',
    label: 'Correspondent',
  },
  {
    id: 'accountNo',
    field: 'accountNo',
    label: 'Account No',
  },
  {
    id: 'accountName',
    field: 'accountName',
    label: 'Account Name',
  },
  {
    id: 'broker',
    field: 'broker',
    label: 'Broker',
  },
  {
    id: 'type',
    field: 'type',
    label: 'Type',
  },
  {
    id: 'transferType',
    field: 'transferType',
    label: 'Transfer Type',
  },
  {
    id: 'status',
    field: 'status',
    label: 'Status',
  },
  {
    id: 'symbol',
    field: 'symbol',
    label: 'Symbol',
  },
  {
    id: 'amt',
    field: 'amt',
    label: 'Amount',
    cellRenderer: currency,
  },
  {
    id: 'recurrence',
    field: 'recurrence',
    label: 'Recurrence',
    cellRenderer: number,
  },
];

const fileColumns = [
  {
    id: 'checkbox',
    pinned: true,
    width: '54px',
    visible: true,
  },
  {
    id: 'fileName',
    field: 'fileName',
    label: 'File Name',
  },
  {
    id: 'createdDate',
    field: 'createdDate',
    label: 'Created Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'filePath',
    field: 'filePath',
    label: 'File Path',
    width: '600px',
  },
];

const fileManagerColumns = [
  {
    id: 'fileName',
    field: 'fileName',
    label: 'File Name',
    width: '60%',
  },
  {
    id: 'dateModified',
    field: 'dateModified',
    label: 'Date Modified',
    cellRenderer: pbDate,
    sort: pbDateSorter,
    width: '20%',
  },
  {
    id: 'fileType',
    field: 'fileType',
    label: 'File Type',
    width: '20%',
  },
];

const clientAccountColumns = [
  {
    id: 'correspondent',
    field: 'correspondent',
    label: 'Correspondent',
  },
  {
    id: 'branch',
    field: 'branch',
    label: 'Branch',
  },
  {
    id: 'onboardingPercent',
    field: 'onboardingPercent',
    label: 'Onboarding Percent',
  },
  {
    id: 'accountNo',
    field: 'accountNo',
    label: 'Account No',
  },
  {
    id: 'masterAccountNo',
    field: 'masterAccountNo',
    label: 'Master Account No',
  },
  {
    id: 'rep',
    field: 'rep',
    label: 'Rep',
  },
  {
    id: 'accountName',
    field: 'accountName',
    label: 'Account Name',
  },
  {
    id: 'broker',
    field: 'broker',
    label: 'Broker',
  },
  {
    id: 'privacyConsent',
    field: 'privacyConsent',
    label: 'Privacy Consent',
  },
  {
    id: 'taxCountry',
    field: 'taxCountry',
    label: 'Tax Country',
  },
  {
    id: 'accountType',
    field: 'accountType',
    label: 'Account Type',
  },
  {
    id: 'marginType',
    field: 'marginType',
    label: 'Margin Type',
  },
  {
    id: 'status',
    field: 'status',
    label: 'Status',
  },
];

const walletAddressBookColumns = [
  {
    id: 'accountId',
    field: 'accountId',
    label: 'Account Id',
    visible: false,
  },
  {
    id: 'correspondent',
    field: 'correspondent',
    label: 'Correspondent',
  },
  {
    id: 'accountNo',
    field: 'accountNo',
    label: 'Account No',
  },
  {
    id: 'walletId',
    field: 'walletId',
    label: 'Wallet Id',
    visible: false,
  },
  {
    id: 'contraWalletId',
    field: 'contraWalletId',
    label: 'Contra Wallet Id',
    visible: false,
  },
  {
    id: 'nickName',
    field: 'nickName',
    label: 'Nickname',
  },
  {
    id: 'recurrenceAmount',
    field: 'recurrenceAmount',
    label: 'Recurrence Amount',
    cellRenderer: currency,
  },
  {
    id: 'status',
    field: 'status',
    label: 'Status',
  },
  {
    id: 'recurring',
    field: 'recurring',
    label: 'Recurrence',
  },
];

const entitlementColumns = [
  {
    id: 'entitlementId',
    field: 'entitlementId',
    label: 'Entitlement ID',
    visible: false,
  },
  {
    id: 'processDate',
    field: 'processDate',
    label: 'Process Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'payableDate',
    field: 'payableDate',
    label: 'Payable Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'declarationDate',
    field: 'declarationDate',
    label: 'Declaration Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'recordDate',
    field: 'recordDate',
    label: 'Record Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'expirationDate',
    field: 'expirationDate',
    label: 'Expiration Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'positionDate',
    field: 'positionDate',
    label: 'Position Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },

  {
    id: 'systemDate',
    field: 'systemDate',
    label: 'System Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },

  {
    id: 'textNumber',
    field: 'textNumber',
    label: 'Text Number',
  },
  {
    id: 'symbol',
    field: 'symbol',
    label: 'Symbol',
  },
  {
    id: 'entryType',
    field: 'entryType',
    label: 'Entry Type',
  },
  {
    id: 'accountNo',
    field: 'accountNo',
    label: 'Account No',
  },
  {
    id: 'accountName',
    field: 'accountName',
    label: 'Account Name',
    visible: false,
  },
  {
    id: 'correspondent',
    field: 'correspondent',
    label: 'Correspondent',
  },
  {
    id: 'originalCusip',
    field: 'originalCusip',
    label: 'Original Cusip',
    visible: false,
  },
  {
    id: 'cusip',
    field: 'cusip',
    label: 'Cusip',
    visible: false,
  },
  {
    id: 'type',
    field: 'type',
    label: 'Type',
  },
  {
    id: 'qtyRate',
    field: 'qtyRate',
    label: 'Qty Rate',
    cellRenderer: qty,
    visible: false,
  },
  {
    id: 'cashRate',
    field: 'cashRate',
    label: 'Cash Rate',
    cellRenderer: qty,
    visible: false,
  },
  {
    id: 'dtccCashRate',
    field: 'dtccCashRate',
    label: 'DTCC Cash Rate',
    cellRenderer: qty,
    visible: false,
  },
  {
    id: 'cash',
    field: 'cash',
    label: 'Cash',
    cellRenderer: currency,
    addFooter: true,
  },
  {
    id: 'qty',
    field: 'qty',
    label: 'Qty',
    cellRenderer: qty,
    addFooter: true,
  },
  {
    id: 'fractionalCash',
    field: 'fractionalCash',
    label: 'Fractional Cash',
    cellRenderer: currency,
    visible: false,
  },
  {
    id: 'fractionalQty',
    field: 'fractionalQty',
    label: 'Fractional Qty',
    cellRenderer: qty,
    visible: false,
  },
  {
    id: 'taxRate',
    field: 'taxRate',
    label: 'Tax Rate',
    cellRenderer: qty,
    visible: false,
  },
  {
    id: 'taxCountry',
    field: 'taxCountry',
    label: 'Tax Country',
    visible: false,
  },
  {
    id: 'noCil',
    field: 'noCil',
    label: 'No CIL',
    visible: false,
    // options: {
    //   customBodyRenderLite: (dataIndex) => {
    //     const f = rows[dataIndex].noCil;
    //     return (
    //       <div align="center">{f === true ? <CheckCircleIcon /> : null}</div>
    //     );
    //   },
    // },
  },
  {
    id: 'description',
    field: 'description',
    label: 'Description',
    cellRenderer: htmlString,
    visible: false,
  },
  {
    id: 'voluntary',
    field: 'voluntary',
    label: 'Voluntary',
  },
  {
    id: 'voluntaryAction',
    field: 'voluntaryAction',
    label: 'Voluntary Action',
  },
  {
    id: 'status',
    field: 'status',
    label: 'Status',
  },
  {
    id: 'detail',
    field: 'detail',
    label: 'Detail',
    cellRenderer: htmlString,
    visible: false,
  },
  {
    id: 'executionId',
    field: 'executionId',
    label: 'Execution ID',
    visible: false,
  },
];

const divindendColumns = [
  {
    id: 'cashRate',
    field: 'cashRate',
    label: 'Cash Rate',
  },
  {
    id: 'declarationDate',
    field: 'declarationDate',
    label: 'Declaration Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'expirationDate',
    field: 'expirationDate',
    label: 'Expiration Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'originalCusip',
    field: 'originalCusip',
    label: 'Original Cusip',
    visible: false,
  },
  {
    id: 'payableDate',
    field: 'payableDate',
    label: 'Payable Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'positionDate',
    field: 'positionDate',
    label: 'Position Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'processDate',
    field: 'processDate',
    label: 'Process Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'qtyRate',
    field: 'qtyRate',
    label: 'Qty Rate',
  },
  {
    id: 'recordDate',
    field: 'recordDate',
    label: 'Record Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'symbol',
    field: 'symbol',
    label: 'Symbol',
  },
  {
    id: 'systemDate',
    field: 'systemDate',
    label: 'System Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'textNumber',
    field: 'textNumber',
    label: 'Text Number',
  },
  {
    id: 'type',
    field: 'type',
    label: 'Type',
  },
  {
    id: 'detail',
    field: 'detail',
    label: 'Detail',
    cellRenderer: htmlString,
    visible: false,
    visible: false,
  },
  {
    id: 'status',
    field: 'status',
    label: 'Status',
  },
];

const mergerColumns = [
  {
    id: 'textNumber',
    field: 'textNumber',
    label: 'Text Number',
  },
  {
    id: 'systemDate',
    field: 'systemDate',
    label: 'System Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'effectiveDate',
    field: 'effectiveDate',
    label: 'Effective Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'targetSymbol',
    field: 'targetSymbol',
    label: 'Target Symbol',
  },
  {
    id: 'targetOriginalCusip',
    field: 'targetOriginalCusip',
    label: 'Target Original Cusip',
  },
  {
    id: 'initiatingSymbol',
    field: 'initiatingSymbol',
    label: 'Initiating Symbol',
  },
  {
    id: 'initiatingOriginalCusip',
    field: 'initiatingOriginalCusip',
    label: 'Initiating Original Cusip',
  },
  {
    id: 'cash',
    field: 'cash',
    label: 'Cash',
    cellRenderer: currency,
  },
  {
    id: 'newRate',
    field: 'newRate',
    label: 'New Qty',
    cellRenderer: qty,
  },
  {
    id: 'oldRate',
    field: 'oldRate',
    label: 'Old Qty',
    cellRenderer: qty,
  },
  {
    id: 'processDate',
    field: 'processDate',
    label: 'Process Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'recordDate',
    field: 'recordDate',
    label: 'Record Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'positionDate',
    field: 'positionDate',
    label: 'Position Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'detail',
    field: 'detail',
    label: 'Detail',
    cellRenderer: htmlString,
    visible: false,
  },
  {
    id: 'status',
    field: 'status',
    label: 'Status',
  },
];

const spinOffColumns = [
  {
    id: 'textNumber',
    field: 'textNumber',
    label: 'Text Number',
  },
  {
    id: 'systemDate',
    field: 'systemDate',
    label: 'System Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'expirationDate',
    field: 'expirationDate',
    label: 'Expiration Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'targetSymbol',
    field: 'targetSymbol',
    label: 'Target Symbol',
  },
  {
    id: 'targetOriginalCusip',
    field: 'targetOriginalCusip',
    label: 'Target Original Cusip',
  },
  {
    id: 'initiatingSymbol',
    field: 'initiatingSymbol',
    label: 'Initiating Symbol',
  },
  {
    id: 'initiatingOriginalCusip',
    field: 'initiatingOriginalCusip',
    label: 'Initiating Original Cusip',
  },
  {
    id: 'cash',
    field: 'cash',
    label: 'Cash',
    cellRenderer: currency,
  },
  {
    id: 'newRate',
    field: 'newRate',
    label: 'New Qty',
    cellRenderer: qty,
  },
  {
    id: 'oldRate',
    field: 'oldRate',
    label: 'Old Qty',
    cellRenderer: qty,
  },
  {
    id: 'positionDate',
    field: 'positionDate',
    label: 'Position Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
    visible: false,
  },
  {
    id: 'processDate',
    field: 'processDate',
    label: 'Process Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
    visible: false,
  },
  {
    id: 'detail',
    field: 'detail',
    label: 'Detail',
    cellRenderer: htmlString,
  },
  {
    id: 'status',
    field: 'status',
    label: 'Status',
  },
];

const splitColumns = [
  {
    id: 'textNumber',
    field: 'textNumber',
    label: 'Text Number',
  },
  {
    id: 'systemDate',
    field: 'systemDate',
    label: 'System Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'expirationDate',
    field: 'expirationDate',
    label: 'Expiration Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
  {
    id: 'targetSymbol',
    field: 'targetSymbol',
    label: 'Target Symbol',
  },
  {
    id: 'targetOriginalCusip',
    field: 'targetOriginalCusip',
    label: 'Target Original Cusip',
  },
  {
    id: 'initiatingSymbol',
    field: 'initiatingSymbol',
    label: 'Initiating Symbol',
  },
  {
    id: 'initiatingOriginalCusip',
    field: 'initiatingOriginalCusip',
    label: 'Initiating Original Cusip',
  },
  {
    id: 'cash',
    field: 'cash',
    label: 'Cash',
    cellRenderer: currency,
  },
  {
    id: 'newRate',
    field: 'newRate',
    label: 'New Qty',
    cellRenderer: qty,
  },
  {
    id: 'oldRate',
    field: 'oldRate',
    label: 'Old Qty',
    cellRenderer: qty,
  },
  {
    id: 'splitType',
    field: 'splitType',
    label: 'Split Type',
    visible: false,
  },
  {
    id: 'recordDate',
    field: 'recordDate',
    label: 'Record Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
    visible: false,
  },
  {
    id: 'positionDate',
    field: 'positionDate',
    label: 'Position Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
    visible: false,
  },
  {
    id: 'processDate',
    field: 'processDate',
    label: 'Process Date',
    cellRenderer: pbDate,
    sort: pbDateSorter,
    visible: false,
  },
  {
    id: 'detail',
    field: 'detail',
    label: 'Detail',
    cellRenderer: htmlString,
    visible: false,
  },
  {
    id: 'status',
    field: 'status',
    label: 'Status',
  },
];

const execiseDeclarationColumns = [
  {
    id: 'accountId',
    field: 'accountId',
    label: 'Account Id',
    visible: false,
  },
  {
    id: 'accountNo',
    field: 'accountNo',
    label: 'Account No',
  },
  {
    id: 'branch',
    field: 'branch',
    label: 'Branch',
  },
  {
    id: 'correspondent',
    field: 'correspondent',
    label: 'Correspondent',
  },
  {
    id: 'marginType',
    field: 'marginType',
    label: 'Margin Type',
  },
  {
    id: 'marketValue',
    field: 'marketValue',
    label: 'Market Value',
    cellRenderer: currency,
  },
  {
    id: 'originalCusip',
    field: 'originalCusip',
    label: 'Original Cusip',
  },
  {
    id: 'qty',
    field: 'qty',
    label: 'Qty',
    cellRenderer: qty,
  },
  {
    id: 'status',
    field: 'status',
    label: 'Status',
  },
  {
    id: 'symbol',
    field: 'symbol',
    label: 'Symbol',
  },
  {
    id: 'symbolDescription',
    field: 'symbolDescription',
    label: 'Symbol Description',
  },
];

const ticketColumns = [
  {
    id: 'status',
    field: 'status',
    label: 'Status',
  },
  {
    id: 'topic',
    field: 'topic',
    label: 'Topic',
  },
  {
    id: 'title',
    field: 'title',
    label: 'Title',
  },
  {
    id: 'createdAt',
    field: 'createdAt',
    label: 'Created At',
    cellRenderer: pbDateTime,
    sort: pbDateTimeSorter,
  },
  {
    id: 'modifiedAt',
    field: 'modifiedAt',
    label: 'Modified At',
    cellRenderer: pbDateTime,
    sort: pbDateTimeSorter,
  },
];

const noteColumns = [
  {
    id: 'note',
    field: 'note',
    label: 'Note',
    cellRenderer: htmlString,
  },
  {
    id: 'createdBy',
    field: 'createdBy',
    label: 'Created By',
  },
  {
    id: 'createdAt',
    field: 'createdAt',
    label: 'Created At',
    cellRenderer: pbDateTime,
    sort: pbDateTimeSorter,
  },
];

const attachmentColumns = [
  {
    id: 'fileName',
    field: 'fileName',
    label: 'File Name',
  },
  {
    id: 'uploadedBy',
    field: 'uploadedBy',
    label: 'Uploaded By',
  },
  {
    id: 'uploadedAt',
    field: 'uploadedAt',
    label: 'Uploaded At',
    cellRenderer: pbDate,
    sort: pbDateSorter,
  },
];

const modelingColumns = [
  {
    id: 'allocationModelName',
    field: 'allocationModelName',
    label: 'Allocation Model',
  },
  {
    id: 'symbol',
    field: 'symbol',
    label: 'Symbol',
  },
  {
    id: 'symbolDescription',
    field: 'symbolDescription',
    label: 'Symbol Description',
  },
  {
    id: 'allocatedPercent',
    field: 'allocatedPercent',
    label: 'Allocated Percentage',
    cellRenderer: percentage,
    addFooter: true,
  },
  {
    id: 'allocatedValue',
    field: 'allocatedValue',
    label: 'Allocated Value',
    cellRenderer: currency,
    addFooter: true,
  },
];

const orderEntryColumns = [
  {
    id: 'orderEntryId',
    field: 'orderEntryId',
    label: 'ID',
    visible: false,
  },
  {
    id: 'row',
    field: 'row',
    label: 'Row',
    width: '100px',
  },
  {
    id: 'accountNo',
    field: 'accountNo',
    label: 'Account No',
  },
  {
    id: 'accountName',
    field: 'accountName',
    label: 'Account Name',
  },
  {
    id: 'action',
    field: 'action',
    label: 'Action',
  },
  {
    id: 'share',
    field: 'share',
    label: 'Qty',
    cellRenderer: ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
      if (!data.signIndicator)
        return qty({ tableManager, value, field, data, column, colIndex, rowIndex });
      return currency({ tableManager, value, field, data, column, colIndex, rowIndex });
    },
  },
  {
    id: 'symbol',
    field: 'symbol',
    label: 'Symbol',
  },
  {
    id: 'symbolDescription',
    field: 'symbolDescription',
    label: 'Symbol Description',
  },
  {
    id: 'orderType',
    field: 'orderType',
    label: 'Order Type',
  },
  {
    id: 'assetType',
    field: 'assetType',
    label: 'Asset Type',
  },
  {
    id: 'tif',
    field: 'tif',
    label: 'Time Limit',
  },
  {
    id: 'divRei',
    field: 'divRei',
    label: 'DIV Rei',
    cellRenderer: boolean,
  },
  {
    id: 'solicited',
    field: 'solicited',
    label: 'Solicited',
    cellRenderer: boolean,
  },
  {
    id: 'price',
    field: 'price',
    label: 'Price',
    cellRenderer: currency,
    visible: false,
  },
  {
    id: 'stopPrice',
    field: 'stopPrice',
    label: 'Stop Price',
    cellRenderer: currency,
  },
  {
    id: 'limitPrice',
    field: 'limitPrice',
    label: 'LimitPrice',
    cellRenderer: currency,
  },
  {
    id: 'amount',
    field: 'amount',
    label: 'Amount',
    cellRenderer: currency,
  },
  {
    id: 'positionValue',
    field: 'positionValue',
    label: 'Position Value',
    cellRenderer: currency,
    visible: false,
  },
  {
    id: 'cashBalance',
    field: 'cashBalance',
    label: 'Cash Balance',
    cellRenderer: currency,
    visible: false,
  },
  {
    id: 'lastPrice',
    field: 'lastPrice',
    label: 'Last Price',
    cellRenderer: currency,
  },
  {
    id: 'trailingStop',
    field: 'trailingStop',
    label: 'Trailing Stop',
    cellRenderer: ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
      if (data.orderType === 'TS$') {
        return currency({ tableManager, value, field, data, column, colIndex, rowIndex });
      }
      return percentage({ tableManager, value, field, data, column, colIndex, rowIndex });
    },
  },
  {
    id: 'positionQty',
    field: 'positionQty',
    label: 'Position Qty',
    visible: false,
  },
  {
    id: 'plusCommission',
    field: 'plusCommission',
    label: 'Plus Commission',
    visible: false,
  },
  {
    id: 'note',
    field: 'note',
    label: 'Note',
    visible: false,
  },
  {
    id: 'rounding',
    field: 'rounding',
    label: 'Rounding',
  },
  {
    id: 'rep',
    field: 'rep',
    label: 'Rep',
  },
  {
    id: 'option',
    field: 'option',
    label: 'Option',
    visible: false,
  },
  {
    id: 'primeBroker',
    field: 'primeBroker',
    label: 'Prime Broker',
    visible: false,
  },
  {
    id: 'marginAgreement',
    field: 'marginAgreement',
    label: 'Margin Agreement',
    visible: false,
  },
  {
    id: 'costPerShare',
    field: 'costPerShare',
    label: 'Cost Per Share',
    cellRenderer: ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
      if (value === 'Missing' || value === '') {
        return missing({ tableManager, value, field, data, column, colIndex, rowIndex });
      }
      return currency({ tableManager, value, field, data, column, colIndex, rowIndex });
    },
  },
  {
    id: 'estRealizedPL',
    field: 'estRealizedPL',
    label: 'Est Realized PL',
    cellRenderer: ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
      if (value === 'Missing' || value === '') {
        return missing({ tableManager, value, field, data, column, colIndex, rowIndex });
      }
      return currency({ tableManager, value, field, data, column, colIndex, rowIndex });
    },
  },
  {
    id: 'createdDate',
    field: 'createdDate',
    label: 'Created Date',
    cellRenderer: ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
      return dateTimeString({ tableManager, value, field, data, column, colIndex, rowIndex }, '/');
    },
    visible: false,
  },
  {
    id: 'createdTime',
    field: 'createdTime',
    label: 'Created Time',
    visible: false,
    cellRenderer: pbDateTime,
    sort: pbDateTimeSorter,
  },
];

const pendingOrderEntryColumns = [
  {
    id: 'orderEntryId',
    field: 'orderEntryId',
    label: 'Row',
    visible: false,
  },
  {
    id: 'accountNo',
    field: 'accountNo',
    label: 'Account No',
  },
  {
    id: 'accountName',
    field: 'accountName',
    label: 'Account Name',
    visible: false,
  },
  {
    id: 'trnsCount',
    field: 'trnsCount',
    label: 'TRNS Count',
    cellRenderer: qty,
  },
  {
    id: 'amt',
    field: 'amt',
    label: 'Amt',
    cellRenderer: currency,
  },
  {
    id: 'sell',
    field: 'sell',
    label: 'Sell',
    cellRenderer: currency,
  },
  {
    id: 'buy',
    field: 'buy',
    label: 'Buy',
    cellRenderer: currency,
  },
  {
    id: 'note',
    field: 'note',
    label: 'Note',
  },
  {
    id: 'batchNo',
    field: 'batchNo',
    label: 'Batch No',
    visible: false,
  },
  {
    id: 'masterAccountNo',
    field: 'masterAccountNo',
    label: 'Master Account No',
    visible: false,
  },
  {
    id: 'modifiedDate',
    field: 'modifiedDate',
    label: 'Modified Date',
    cellRenderer: ({ tableManager, value, field, data, column, colIndex, rowIndex }) => {
      return dateTimeString({ tableManager, value, field, data, column, colIndex, rowIndex }, '/');
    },
    sort: pbDateTimeSorter,
  },
  {
    id: 'createdBy',
    field: 'createdBy',
    label: 'Created By',
  },
];

const amountBreakdownOrderEntryColumns = [
  {
    id: 'accountNo',
    field: 'accountNo',
    label: 'Account No',
  },
  {
    id: 'trnsCount',
    field: 'trnsCount',
    label: 'TRNS Count',
  },
  {
    id: 'accountValue',
    field: 'accountValue',
    label: 'Account Value',
    cellRenderer: currency,
  },
  {
    id: 'bodBalance',
    field: 'bodBalance',
    label: 'BOD Balance',
    cellRenderer: currency,
  },
  {
    id: 'runningBalance',
    field: 'runningBalance',
    label: 'Running Balance',
    cellRenderer: currency,
  },
  {
    id: 'buy',
    field: 'buy',
    label: 'Buy',
    cellRenderer: currency,
  },
  {
    id: 'sell',
    field: 'sell',
    label: 'Sell',
    cellRenderer: currency,
  },
  {
    id: 'amount',
    field: 'amount',
    label: 'Amount',
    cellRenderer: currency,
  },
  {
    id: 'endRunningBalance',
    field: 'endRunningBalance',
    label: 'End Running Balance',
    cellRenderer: currency,
  },
  {
    id: 'estRealizedPl',
    field: 'estRealizedPl',
    label: 'Est Realized PL'
  },
];

const contactUsColumns = [
  {
    id: 'subject',
    field: 'subject',
    label: 'Subject',
  },
  {
    id: 'message',
    field: 'message',
    label: 'Message',
  },
  {
    id: 'status',
    field: 'status',
    label: 'Status',
  },
  {
    id: 'pageName',
    field: 'pageName',
    label: 'Page Name',
  },
  {
    id: 'name',
    field: 'name',
    label: 'User',
  },
];

export {
  positionColumns,
  tradeConfirmColumns,
  requirementColumns,
  statementColumns,
  balanceColumns,
  activityColumns,
  bankAccountColumns,
  walletColumns,
  digitalCashRequestColumns,
  digitalCashRequestSummaryColumns,
  fileColumns,
  clientAccountColumns,
  walletAddressBookColumns,
  entitlementColumns,
  divindendColumns,
  mergerColumns,
  spinOffColumns,
  splitColumns,
  execiseDeclarationColumns,
  walletSummaryColumns,
  digitalCashRequestColumnsAxs,
  ticketColumns,
  noteColumns,
  attachmentColumns,
  modelingColumns,
  pendingOrderEntryColumns,
  orderEntryColumns,
  amountBreakdownOrderEntryColumns,
  fileManagerColumns,
  contactUsColumns,
};
