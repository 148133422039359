import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button as MUIButton } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    button: {
        '& .MuiButton-label': {
            textTransform: 'capitalize',
            fontWeight: 400,
        },
        '&.MuiButton-contained': {
            backgroundImage: 'unset',
            backgroundColor: '#8C690E',
            padding: '12px 96px',
            border: 'unset',
        },
        '&.Mui-disabled': {
            backgroundColor: '#F9F9F9 !important',
            color: '#B8BABE',
        },
      },
}));

const Button = (props) => {
    const classes = useStyles();
    return (
        <MUIButton
            className={classes.button}
            fullWidth={true}
            disabled={props.disabled}
            component={props.component}
            name={props.name}
            onClick={props.onClick}
        >
            {props.label}
        </MUIButton>
    );
}
export default Button;