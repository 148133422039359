import {
  Administrator,
  AdministratorServiceClient,
  ChangePasswordRequest,
  ConfirmationEmailRequest,
  CheckEmailRequest,
  ReadAdministratorRequest,
  ListUserAccountAccessRequest,
  SetKeyRequest,
  AcceptClientNoticeRequest,
  HasSecurityWordRequest,
  DeleteAdministratorRequest,
  ListAdministratorRequest,
  CreateCorrespondentAccessRequest,
  ListCorrespondentAccessRequest,
  Access,
  DeleteAccessRequest,
  EmptyRequest,
  CreateClientOnboardingRequest,
  HasSecurityQuestionsRequest,
  SecurityQuestionRequest,
  BrandingURLRequest,
} from '../proto/usrpb/administrator_grpc_web_pb';
import { auth } from '../lib/auth/Auth';
import authSvc from './AuthService';
import { notifyError } from '../components/Messages/Notification';
import { stringToProtoDate, stringToProtoTimeSpan } from './ConvertService';

import { Registration } from '../proto/usrpb/registration_grpc_web_pb';
import { GenerateAccountNoRequest } from '../proto/accountpb/generateaccountno_grpc_web_pb';
import { Client } from '../proto/accountpb/client_grpc_web_pb';
import { Owner } from '../proto/accountpb/owner_grpc_web_pb';
import { Identification } from '../proto/accountpb/identification_grpc_web_pb';
import { ContactInfo } from '../proto/accountpb/contactinfo_grpc_web_pb';
import { Address } from '../proto/accountpb/address_grpc_web_pb';
import { AccountAccess } from '../proto/clientpb/accountaccess_grpc_web_pb';
import { Pagination } from '../proto/utilspb/pagination_pb';
import { processError } from './Helper';

import {
  //ADMINISTRATOR
  dataToClientAdministrator,

  //CLIENT
  accountDataToAccountClient,

  //OWNER
  dataToOwner,
  coApplicantToOwner,
  coOfficerToOwner,
  trustedContactToOwner,

  //ADDRESS
  accountToAddress,
  accountCoApplicantToAddress,
  accountPrimaryOfficerToAddress,
  accountCoOfficerToAddress,
  accountToTrustedContactAddress,

  //CONTACT INFO
  accessToContactInfo,
  accountToContactInfoPhoneNumber,
  accountCoApplicantToContactInfoEmail,
  accountCoApplicantToContactInfoPhoneNumber,
  accountPrimaryOfficerToContactInfoPhoneNumber,
  accountCoOfficerToContactInfo,
  accountCoOfficerToContactInfoEmail,
  accountToTrustedContactInfoPhoneNumber,
  accountToTrustedContactInfoEmail,

  //IDENTIFICATION
  ToidentificationTax,
  Toidentification,
  ToidentificationSSN,
  accountCoOfficerToidentification,
  accountCoOfficerToidentificationSSN,
  trustedContactIdentification,
} from '../pages/Register/registerDataMapping';

const service = new AdministratorServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });
const serviceNoAuth = new AdministratorServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

const userId = authSvc.getCurrentUser()?.UserId;
const roleId = authSvc.getCurrentUser()?.RoleId;
const userName = authSvc.getCurrentUser()?.Username;

const requestParameter = (param) => {
  let req = new Administrator();
  req.setUsrId(param.usrId);
  req.setEmail(param.email);
  req.setName(param.name.replace('  ', ' '));
  req.setPassword(param.password);
  req.setDefaultAccess(param.defaultAccess);
  req.setMobileNo(param.mobileNo);
  req.setUserType(param.userType);
  req.setStatus(param.status);
  req.setAdmin(param.admin);
  req.setAuthenticationMode(param.authenticationMode);
  req.setAccountId(param.accountId);
  req.setRoleId(param.roleId);
  req.setCorrespondent(param.correspondent);

  return req;
};

export async function createAdministrator(param) {
  return new Promise((resolve, reject) => {
    const req = requestParameter(param);

    serviceNoAuth.createAdministrator(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateAdministrator(param) {
  return new Promise((resolve, reject) => {
    const req = requestParameter(param);

    service.updateAdministrator(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function readAdministrator(userId) {
  return new Promise((resolve, reject) => {
    let req = new ReadAdministratorRequest();
    req.setUsrId(userId);

    service.readAdministrator(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function changePassword(param) {
  return new Promise((resolve, reject) => {
    const req = new ChangePasswordRequest();
    req.setUsrId(param.usrId);
    req.setOldPassword(param.oldPassword);
    req.setNewPassword(param.newPassword);

    service.changePassword(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function getBrandingLogoURL() {
  return new Promise((resolve, reject) => {
    const req = new BrandingURLRequest();
    req.setUsername(userName);

    service.getBrandingURL(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function sendConfirmationEmail(param) {
  return new Promise((resolve, reject) => {
    const req = new ConfirmationEmailRequest();
    req.setEmail(param.email);
    req.setUrl(param.url);

    serviceNoAuth.sendConfirmationEmail(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function checkEmailIfExists(param) {
  return new Promise((resolve, reject) => {
    const req = new CheckEmailRequest();
    req.setEmail(param.email);

    serviceNoAuth.checkEmailIfExists(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listUserAccountAccess(userId) {
  return new Promise((resolve, reject) => {
    const req = new ListUserAccountAccessRequest();
    req.setUserId(userId);

    serviceNoAuth.listUserAccountAccess(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function setKey(param) {
  return new Promise((resolve, reject) => {
    const req = new SetKeyRequest();
    req.setUsrId(param.usrId);
    req.setEmail(param.email);
    req.setSecretKey(param.secretKey);
    service.setKey(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function acceptClientNotice() {
  return new Promise((resolve, reject) => {
    let req = new AcceptClientNoticeRequest();
    req.setUsrId(userId);

    service.acceptClientNotice(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function hasSecurityWord(id) {
  return new Promise((resolve, reject) => {
    const req = new HasSecurityWordRequest();
    req.setUsrId(id);

    serviceNoAuth.hasSecurityWord(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export function hasSecurityQuestions(id) {
  return new Promise((resolve, reject) => {
    const req = new HasSecurityQuestionsRequest();
    req.setUsrId(id);

    serviceNoAuth.hasSecurityQuestions(req, {}, (error, response) => {
      if (error) {
        reject(processError(error));
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export function getSecurityQuestion(username) {
  return new Promise((resolve, reject) => {
    const req = new SecurityQuestionRequest();
    req.setUsername(username)

    serviceNoAuth.getSecurityQuestion(req, {}, (error, response) => {
      if (error) {
        reject(processError(error));
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function deleteAdministrator(id) {
  return new Promise((resolve, reject) => {
    const req = new DeleteAdministratorRequest();
    req.setUserId(id);
    service.deleteAdministrator(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listAdministrator(param, paging) {
  return new Promise((resolve, reject) => {
    const req = new ListAdministratorRequest();
    req.setEmail(param.email);
    req.setName(param.name);
    req.setUserType(param.userType);
    req.setStatus(param.status);
    req.setRoleId(param.roleId);
    req.setUserRoleId(roleId);

    if (paging) {
      let pg = new Pagination();
      pg.setPageNo(paging.pageNo);
      pg.setPageSize(paging.rowsPerPage);
      pg.setSortName(paging.sortName);
      pg.setSortDirection(paging.sortDirection);
      pg.setFilterNamesList(paging.filterNames);
      pg.setFilterValuesList(paging.filterValues);
      req.setPagination(pg);
    }

    service.listAdministrator(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

const accessParameter = (param) => {
  let req = new Access();
  req.setUsrId(param.usrId);
  req.setRoleId(param.roleId);
  req.setAccessId(param.accessId);
  req.setAccess(param.access);
  req.setSubAccess(param.subAccess);

  return req;
};

export async function createAccess(param) {
  return new Promise((resolve, reject) => {
    const req = accessParameter(param);
    service.createAccess(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function deleteAccess(param) {
  return new Promise((resolve, reject) => {
    const req = new DeleteAccessRequest();
    req.setAccessId(param);
    service.deleteAccess(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function createCorrespondentAccess(param) {
  return new Promise((resolve, reject) => {
    const req = new CreateCorrespondentAccessRequest();
    req.setUsrId(param.usrId);
    req.setCorrespondent(param.correspondent);
    service.createCorrespondentAccess(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listAccess(param) {
  return new Promise((resolve, reject) => {
    const req = accessParameter(param);
    service.listAccess(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listCorrespondentAccess(usrId) {
  return new Promise((resolve, reject) => {
    const req = new ListCorrespondentAccessRequest();
    req.setUsrId(usrId);
    service.listCorrespondentAccess(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateAccess(param) {
  return new Promise((resolve, reject) => {
    const req = accessParameter(param);
    service.updateAccess(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listDefaultAccessOption() {
  return new Promise((resolve, reject) => {
    service.listDefaultAccessOption(new EmptyRequest(), {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

// ONBOARDING =================================================

const reqRegistration = (param) => {
  console.log(param)
  let req = new Registration();

  req.setFirstName(param.firstName);
  req.setLastName(param.lastName);
  req.setEmail(param.email);
  req.setVerificationKey(param.verificationKey);

  return req;
};

const reqGenerateAccountNo = (param) => {
  let req = new GenerateAccountNoRequest();
  const lastNameEnding = param.lastName.split(' ')[param.lastName.split(' ').length - 1];
  req.setFirstName(param.firstName);
  req.setLastName(lastNameEnding);
  req.setPhoneNumber(param.phoneNumber);

  return req;
};

const reqAdministrator = (param) => {
  let req = new Administrator();
  req.setUsrId(param.usrId);
  req.setEmail(param.email);
  req.setName(param.name.replace('  ', ' '));
  req.setPassword(param.password);
  req.setDefaultAccess(param.defaultAccess);
  req.setMobileNo(param.mobileNo);
  req.setUserType(param.userType);
  req.setStatus(param.status);
  req.setAdmin(param.admin);
  req.setAuthenticationMode(param.authenticationMode);
  req.setAccountId(param.accountId);

  if (param.admin !== undefined && param.admin) {
    req.setCorrespondent(param.correspondent);
  } else {
    req.setCorrespondent(null);
  }

  return req;
};

const reqIdentification = (param) => {
  const req = new Identification();
  req.setAccountId(param.accountId);
  req.setOwnerId(param.ownerId);
  req.setType(param.type);
  req.setId(param.id);
  req.setCountryOfIssuance(param.countryOfIssuance);
  req.setIssueDate(stringToProtoDate(param.issueDate));
  req.setExpirationDate(stringToProtoDate(param.expirationDate));
  req.setImgGovernmentId(param.imgGovernmentId);
  req.setStatus(param.status);
  req.setState(param.state);
  req.setSsnState(param.ssnState);
  req.setSsnCountryOfIssuance(param.ssnCountryOfIssuance);

  return req;
};

const reqClient = (param) => {
  let req = new Client();
  req.setAccountId(param.accountId);
  req.setTin(param.tin);
  req.setCorrespondent(param.correspondent);
  req.setAccountName(param.accountName);
  req.setAccountNo(param.accountNo);
  req.setMasterAccountNo(param.masterAccountNo);
  req.setStatus(param.status);
  req.setBroker(param.broker);
  req.setPrivacyConsent(param.privacyContent);
  req.setDayTrader(param.dayTrader);
  req.setMultiplier(param.multiplier);
  req.setLegalEntity(param.legalEntity);
  req.setInvestmentObjectiveCode(param.investmentObjectiveCode);
  req.setTaxCountry(param.taxCountry);
  req.setTefra(param.tefra);
  req.setW8w9(param.w8w9);
  req.setInvestmentObjectiveDate(stringToProtoDate(param.investmentObjectiveDate));
  req.setW8w9ReceivedDate(stringToProtoDate(param.w8w9ReceivedDate));
  req.setActivatedAt(stringToProtoTimeSpan(param.activatedAt));
  req.setDisabledAt(stringToProtoTimeSpan(param.disabledAt));
  req.setAccreditedInvestor(param.accreditedInvestor);
  req.setFdidFlag(param.fdidFlag);
  req.setFdid(param.fdid);
  req.setFdidEndReason(param.fdidEndReason);
  req.setBranch(param.branch);
  req.setRep(param.rep);
  req.setAccountType(param.accountType);
  req.setMarginType(param.marginType);
  req.setLargeTraderId(param.largeTraderId);
  req.setFpslParticipant(param.fpslParticipant);
  req.setCommonId(param.commonId);
  req.setStatusReason(param.statusReason);
  req.setSubType(param.subType);
  req.setAllowLiquidInvestment(param.allowLiquidInvestment);
  req.setWebTermConditions(stringToProtoTimeSpan(param.webTermConditions));
  req.setDisclosureStatement(stringToProtoTimeSpan(param.disclosureStatement));
  req.setFeeSchedule(stringToProtoTimeSpan(param.feeSchedule));
  req.setBeneficiary(param.beneficiary);
  req.setPrimeBroker(param.primeBroker);
  req.setParticipantNo(param.participantNo);
  req.setExecutingBroker(param.executingBroker);
  req.setInstitutionNo(param.institutionNo);
  req.setAgentNo(param.agentNo);
  req.setAgentAccountNo(param.agentAccountNo);
  req.setTraderId(param.traderId);
  req.setMarginDisclosure(param.marginDisclosure);
  req.setMarginAgreement(param.marginAgreement);
  req.setStocks(param.stocks);
  req.setEtf(param.eft);
  req.setOption(param.option);
  req.setAnnualIncome(param.annualIncome);
  req.setLiquidNetWorth(param.liquidNetWorth);
  req.setSourceOfFunds(param.sourceOfFunds);
  req.setSigned(param.signed);
  req.setImgSignature(param.imgSignature);
  req.setKycStatus(param.kycStatus);
  req.setPromoCode(param.promoCode);
  req.setSecurityWordHint(param.securityWordHint);
  req.setSecurityWord(param.securityWord);
  req.setTaxBracket(param.taxBracket);
  req.setRiskTolerance(param.riskTolerance);
  req.setCustomerAccountAgreement(param.customerAccountAgreement);
  req.setOnboardingPercent(param.onboardingPercent?.toString());
  return req;
};

const reqOwner = (param) => {
  const req = new Owner();
  req.setAccountId(param.accountId);
  req.setOwnerId(param.ownerId);
  req.setOwnerType(param.ownerType);
  req.setFirstName(param.firstName);
  req.setMiddleName(param.middleName);
  req.setLastName(param.lastName);
  req.setSuffix(param.suffix);
  req.setStatus(param.status);

  req.setDateOfBirth(stringToProtoDate(param.dateOfBirth));
  req.setCountry(param.country);
  req.setEmploymentStatus(param.employmentStatus);
  req.setEmploymentOccupation(param.employmentOccupation);
  req.setEmploymentName(param.employmentName);
  req.setPreviousEmploymentName(param.previousEmploymentName);

  req.setOwnership(param.ownership);
  req.setStockOwnership(param.stockOwnerShip);
  req.setBrokerMember(param.brokerMember);
  req.setBrokerName(param.brokerName);
  req.setAffiliatePerson(param.affiliatePerson);
  req.setAffiliatePersonPosition(param.affiliatePersonPosition);

  req.setOfacStatus(param.ofacStatus);
  req.setOfacDate(param.ofacDate);
  req.setKycStatus(param.kycStatus);
  req.setKycDate(param.kycDate);

  req.setAccountNo(param.accountNo);
  req.setCorrespondent(param.correspondent);
  req.setCitizenship(param.citizenship);

  req.setOfficer(param.officer);
  req.setOwner(param.owner);
  req.setOwnershipPercent('' + param.ownershipPercent + '');

  req.setMaritalStatus(param.maritalStatus);
  req.setNatureOfBusiness(param.natureOfBusiness);
  req.setYearsEmployed(param.yearsEmployed);
  req.setRelationship(param.relationship);

  return req;
};

const reqAccountAccess = (param) => {
  const req = new AccountAccess();
  req.setUsrId(param.usrId);
  req.setAccountId(param.accountId);

  return req;
};

const reqContactInfo = (param) => {
  const req = new ContactInfo();
  req.setOwnerId(param.ownerId);
  req.setAccountId(param.accountId);
  req.setContactInfoType(param.contactInfoType);
  req.setContactInfo(param.contactInfo);
  req.setStatus(param.status);

  return req;
};

const reqAddress = (param) => {
  let req = new Address();

  req.setAddressId(param.addressId);
  req.setAddressType(param.addressType);
  req.setOwnerId(param.ownerId);
  req.setAccountId(param.accountId);
  req.setAccountNo(param.accountNo);
  req.setCorrespondent(param.correspondent);
  req.setAddressId(param.addressId);
  req.setStatus(param.status);
  req.setAddress1(param.address1);
  req.setAddress2(param.address2);
  req.setAddress3(param.address3);
  req.setCity(param.city);
  req.setState(param.state);
  req.setZip(param.zipCode);
  req.setCountry(param.country);
  req.setCreatedBy(param.createdBy);
  req.setImgProofOfAddress(param.imgProofOfAddress);

  return req;
};

export async function createClientOnboarding(
  accessData,
  accountData,
  accountCoOfficerData,
  identificationData,
  employmentData,
  associationData,
  attachments,
  marginAgreementAttachment,
  onboardingtAttachment,
  customerAccountAgreementAttachment,
  tradingData,
  investmentObjectiveData,
  eSignatureData,
  coIdentificationData,
  marginAgreementData,
  incomplete,
  accountId,
  trustedContactData,
  customerAccountAgreementData,
  progress
) {
  return new Promise((resolve, reject) => {
    const req = new CreateClientOnboardingRequest();

    if(window.location.href.includes('verified')){
      //VALIDATE REGISTRATION
      const verificationKey = window.location.href.split('verified?')[1];
      const reqClientRegistration = reqRegistration({
        ...accessData,
        verificationKey: verificationKey,
      });
  
      req.setRegistration(reqClientRegistration);
    }

    const { accountType } = accountData;

    //GENERATE ACCOUNT NO==============================
    const reqClientGenerateAccountNo = reqGenerateAccountNo({
      firstName: accessData.firstName.trim(),
      lastName: accessData.lastName.trim(),
      phoneNumber: accountData.phoneNumber.trim(),
    });

    req.setGenerateAccountNo(reqClientGenerateAccountNo);

    //ADMINISTRATOR====================================
    const administrator = dataToClientAdministrator(
      accessData,
      accountData,
      incomplete,
      accountId
    );

    const reqClientAdministrator = reqAdministrator(administrator);

    req.setAdministrator(reqClientAdministrator);

    const reqClientAccountAccess = reqAccountAccess({
      // usrId: res.administrator.usrId,
      accountId: accountId,
    });

    req.setAccountAccess(reqClientAccountAccess);

    //CLIENT===========================================
    const clientData = accountDataToAccountClient(
      accountId,
      accessData,
      // account,
      accountData,
      identificationData,
      investmentObjectiveData,
      tradingData,
      eSignatureData,
      marginAgreementData,
      customerAccountAgreementData,
      progress
    );

    const reqClientData = reqClient(clientData);

    req.setClient(reqClientData);

    //OWNER============================================
    const ownerData = dataToOwner(
      accountId,
      accessData,
      // account,
      accountData,
      identificationData,
      employmentData,
      associationData,
      investmentObjectiveData
    );

    const reqClientOwner = reqOwner(ownerData);

    req.setOwner(reqClientOwner);

    // const ownerId = owner.owner.ownerId;

    //IDENFICATION=====================================
    const clientIdentification = Toidentification(
      accountId,
      // ownerId,
      // account,
      accountData,
      identificationData
    );

    const reqClientIdentification = reqIdentification(clientIdentification);

    req.setIdentification(reqClientIdentification);

    const ssnIdentification = ToidentificationSSN(
      accountId,
      // ownerId,
      // account,
      accountData,
      identificationData
    );

    const reqSsnIdentification = reqIdentification(ssnIdentification);

    req.setIdentificationSsn(reqSsnIdentification);

    if (accountType === 'C') {
      const taxIdentification = ToidentificationTax(
        accountId,
        // ownerId,
        // account,
        accountData,
        identificationData
      );

      const reqTaxIdentification = reqIdentification(taxIdentification);

      req.setIdentificationTax(reqTaxIdentification);
    }

    //CONTACT INFO======================================
    const accessEmail = accessToContactInfo(
      accountId,
      // ownerId,
      // account,
      accessData
    );

    const reqContactInfoEmail = reqContactInfo(accessEmail);

    req.setContactInfoEmail(reqContactInfoEmail);

    const accountPhoneNumber = accountToContactInfoPhoneNumber(
      accountId,
      // ownerId,
      // account,
      accountData
    );

    const reqContactInfoPhoneNumber = reqContactInfo(accountPhoneNumber);

    req.setContactInfoPhone(reqContactInfoPhoneNumber);

    //ADDRESS===========================================
    const accountAddress = accountToAddress(
      accountId,
      // ownerId,
      // account,
      accountData
    );

    const reqClientAddress = reqAddress(accountAddress);

    req.setAddress(reqClientAddress);

    //JOINT=============================================
    if (accountType === 'J') {
      const coOwnerData = coApplicantToOwner(accountId, accountData);

      const reqCoOwnerData = reqOwner(coOwnerData);

      req.setCoOwner(reqCoOwnerData);

      // const coOwnerId = coOwner.owner.ownerId;

      //CONTACT INFO=================================
      const accountCoApplicantEmail = accountCoApplicantToContactInfoEmail(
        accountId,
        // coOwnerId,
        accountData
      );

      const reqAccountCoApplicantEmail = reqContactInfo(accountCoApplicantEmail);

      req.setCoContactInfoEmail(reqAccountCoApplicantEmail);

      const accountCoApplicantPhoneNumber = accountCoApplicantToContactInfoPhoneNumber(
        accountId,
        // coOwnerId,
        accountData
      );

      const reqAccountCoApplicantPhoneNumber = reqContactInfo(
        accountCoApplicantPhoneNumber
      );

      req.setCoContactInfoPhone(reqAccountCoApplicantPhoneNumber);

      //ADDRESS=======================================
      const coApplicantAddress = accountCoApplicantToAddress(
        accountId,
        // coOwnerId,
        accountData
      );

      const reqCoApplicantAddress = reqAddress(coApplicantAddress);

      req.setCoAddress(reqCoApplicantAddress);
    }

    //CORPORATION=========================================
    if (accountType === 'C') {
      const primaryOfficerPhoneNumber = accountPrimaryOfficerToContactInfoPhoneNumber(
        accountId,
        // ownerId,
        // account,
        accountData
      );

      const reqPrimaryOfficerPhoneNumber = reqContactInfo(primaryOfficerPhoneNumber);

      req.setPoContactInfoPhone1(reqPrimaryOfficerPhoneNumber);

      const primaryOfficerAddress = accountPrimaryOfficerToAddress(
        accountId,
        // ownerId,
        // account,
        accountData
      );

      const reqPrimaryOfficerAddress = reqAddress(primaryOfficerAddress);

      req.setPoAddress(reqPrimaryOfficerAddress);

      let reqPoOwner = [];
      let reqPoIdentification = [];
      let reqPoIdentificationSSN = [];
      let reqPoPhoneNumber2 = [];
      let reqPoEmail = [];
      let reqPoAddress2 = [];

      for (let i = 0; i < accountCoOfficerData.length; i++) {
        //OWNER=========================================
        const coOfficerOwner = coOfficerToOwner(
          accountId,
          // account,
          accountCoOfficerData[i],
          coIdentificationData[i]
        );

        reqPoOwner.push(reqOwner(coOfficerOwner));

        // const coOfficerOwnerId = coOfficerOwnerRes.owner.ownerId;

        if (accountCoOfficerData[i].coOwnershipPercentage >= 25) {
          const coOfficerIdentification = accountCoOfficerToidentification(
            accountId,
            // coOfficerOwnerId,
            accountCoOfficerData[i],
            coIdentificationData[i]
          );

          reqPoIdentification.push(reqIdentification(coOfficerIdentification));

          const coOfficerIdentificationSSN = accountCoOfficerToidentificationSSN(
            accountId,
            // coOfficerOwnerId,
            accountCoOfficerData[i],
            coIdentificationData[i]
          );

          reqPoIdentificationSSN.push(reqIdentification(coOfficerIdentificationSSN));
        }

        //CONTACTINFO====================================
        const coOfficerPhoneNumber = accountCoOfficerToContactInfo(
          accountId,
          // coOfficerOwnerId,
          accountCoOfficerData[i]
        );

        reqPoPhoneNumber2.push(reqContactInfo(coOfficerPhoneNumber));

        const coOfficerEmail = accountCoOfficerToContactInfoEmail(
          accountId,
          // coOfficerOwnerId,
          accountCoOfficerData[i]
        );

        reqPoEmail.push(reqContactInfo(coOfficerEmail));

        //ADDRESS========================================
        const coOfficerAddress = accountCoOfficerToAddress(
          accountId,
          // coOfficerOwnerId,
          accountCoOfficerData[i]
        );

        reqPoAddress2.push(reqAddress(coOfficerAddress));
      }

      req.setPoOwnerList(reqPoOwner);
      req.setPoIdentificationList(reqPoIdentification);
      req.setPoIdentificationSsnList(reqPoIdentificationSSN);
      req.setPoContactInfoPhone2List(reqPoPhoneNumber2);
      req.setPoContactInfoEmailList(reqPoEmail);
      req.setPoAddress2List(reqPoAddress2);
    }

    //TRUSTED CONTACT OWNER============================================
    if (trustedContactData.firstName && trustedContactData.lastName) {
      const trustedContactOwnerData = trustedContactToOwner(
        accountId,
        accessData,
        accountData,
        identificationData,
        employmentData,
        associationData,
        investmentObjectiveData,
        trustedContactData
      );

      // const trustedContact = await createOwner(trustedContactOwnerData);
      const updatedTrustContactOwnerData = {
        ...trustedContactOwnerData,
        // ownerId: trustedContact.owner.ownerId,
      };

      const reqUpdatedTrustedContact = reqOwner(updatedTrustContactOwnerData);
      req.setTcOwner(reqUpdatedTrustedContact);

      // const trustedContactOwnerId = trustedContact.owner.ownerId;

      //TRUSTED CONTACT OWNER PHONE============================================
      const trustedContactPhoneNumber = accountToTrustedContactInfoPhoneNumber(
        accountId,
        // trustedContactOwnerId,
        accountData,
        trustedContactData
      );

      const reqTrustedContactPhoneNumber = reqContactInfo(trustedContactPhoneNumber);
      req.setTcContactInfoPhone(reqTrustedContactPhoneNumber);

      //TRUSTED CONTACT OWNER Email============================================
      const trustedContactEmail = accountToTrustedContactInfoEmail(
        accountId,
        // trustedContactOwnerId,
        accountData,
        trustedContactData
      );

      const reqTrustedContactEmail = reqContactInfo(trustedContactEmail);
      req.setTcContactInfoEmail(reqTrustedContactEmail);

      //TRUSTED CONTACT OWNER ID============================================
      const trustedContactId = trustedContactIdentification(
        accountId,
        // trustedContactOwnerId,
        accountData,
        trustedContactData
      );

      const reqTrustedContactId = reqIdentification(trustedContactId);
      req.setTcIdentification(reqTrustedContactId);

      //TRUSTED CONTACT OWNER ADDRESS============================================
      const trustedContactAddress = accountToTrustedContactAddress(
        accountId,
        // trustedContactOwnerId,
        accountData,
        trustedContactData
      );

      const reqTrustedContactAddress = reqAddress(trustedContactAddress);
      req.setTcAddress(reqTrustedContactAddress);
    }

    // not object
    req.setAccountId(accountId);
    req.setAccountType(accountType);

    serviceNoAuth.createClientOnboarding(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
