/**
 * @fileoverview gRPC-Web generated client stub for accountpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.accountpb = require('./correspondent_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.accountpb.CorrespondentServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.accountpb.CorrespondentServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.ReadCorrespondentRequest,
 *   !proto.accountpb.ReadCorrespondentResponse>}
 */
const methodDescriptor_CorrespondentService_ReadCorrespondent = new grpc.web.MethodDescriptor(
  '/accountpb.CorrespondentService/ReadCorrespondent',
  grpc.web.MethodType.UNARY,
  proto.accountpb.ReadCorrespondentRequest,
  proto.accountpb.ReadCorrespondentResponse,
  /**
   * @param {!proto.accountpb.ReadCorrespondentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.ReadCorrespondentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.accountpb.ReadCorrespondentRequest,
 *   !proto.accountpb.ReadCorrespondentResponse>}
 */
const methodInfo_CorrespondentService_ReadCorrespondent = new grpc.web.AbstractClientBase.MethodInfo(
  proto.accountpb.ReadCorrespondentResponse,
  /**
   * @param {!proto.accountpb.ReadCorrespondentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.ReadCorrespondentResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.ReadCorrespondentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.accountpb.ReadCorrespondentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.ReadCorrespondentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.CorrespondentServiceClient.prototype.readCorrespondent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.CorrespondentService/ReadCorrespondent',
      request,
      metadata || {},
      methodDescriptor_CorrespondentService_ReadCorrespondent,
      callback);
};


/**
 * @param {!proto.accountpb.ReadCorrespondentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.ReadCorrespondentResponse>}
 *     Promise that resolves to the response
 */
proto.accountpb.CorrespondentServicePromiseClient.prototype.readCorrespondent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.CorrespondentService/ReadCorrespondent',
      request,
      metadata || {},
      methodDescriptor_CorrespondentService_ReadCorrespondent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.Correspondent,
 *   !proto.accountpb.ReadCorrespondentResponse>}
 */
const methodDescriptor_CorrespondentService_GetLogo = new grpc.web.MethodDescriptor(
  '/accountpb.CorrespondentService/GetLogo',
  grpc.web.MethodType.UNARY,
  proto.accountpb.Correspondent,
  proto.accountpb.ReadCorrespondentResponse,
  /**
   * @param {!proto.accountpb.Correspondent} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.ReadCorrespondentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.accountpb.Correspondent,
 *   !proto.accountpb.ReadCorrespondentResponse>}
 */
const methodInfo_CorrespondentService_GetLogo = new grpc.web.AbstractClientBase.MethodInfo(
  proto.accountpb.ReadCorrespondentResponse,
  /**
   * @param {!proto.accountpb.Correspondent} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.ReadCorrespondentResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.Correspondent} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.accountpb.ReadCorrespondentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.ReadCorrespondentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.CorrespondentServiceClient.prototype.getLogo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.CorrespondentService/GetLogo',
      request,
      metadata || {},
      methodDescriptor_CorrespondentService_GetLogo,
      callback);
};


/**
 * @param {!proto.accountpb.Correspondent} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.ReadCorrespondentResponse>}
 *     Promise that resolves to the response
 */
proto.accountpb.CorrespondentServicePromiseClient.prototype.getLogo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.CorrespondentService/GetLogo',
      request,
      metadata || {},
      methodDescriptor_CorrespondentService_GetLogo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.Correspondent,
 *   !proto.accountpb.ReadCorrespondentResponse>}
 */
const methodDescriptor_CorrespondentService_GetCorrespondentByUrl = new grpc.web.MethodDescriptor(
  '/accountpb.CorrespondentService/GetCorrespondentByUrl',
  grpc.web.MethodType.UNARY,
  proto.accountpb.Correspondent,
  proto.accountpb.ReadCorrespondentResponse,
  /**
   * @param {!proto.accountpb.Correspondent} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.ReadCorrespondentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.accountpb.Correspondent,
 *   !proto.accountpb.ReadCorrespondentResponse>}
 */
const methodInfo_CorrespondentService_GetCorrespondentByUrl = new grpc.web.AbstractClientBase.MethodInfo(
  proto.accountpb.ReadCorrespondentResponse,
  /**
   * @param {!proto.accountpb.Correspondent} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.ReadCorrespondentResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.Correspondent} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.accountpb.ReadCorrespondentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.ReadCorrespondentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.CorrespondentServiceClient.prototype.getCorrespondentByUrl =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.CorrespondentService/GetCorrespondentByUrl',
      request,
      metadata || {},
      methodDescriptor_CorrespondentService_GetCorrespondentByUrl,
      callback);
};


/**
 * @param {!proto.accountpb.Correspondent} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.ReadCorrespondentResponse>}
 *     Promise that resolves to the response
 */
proto.accountpb.CorrespondentServicePromiseClient.prototype.getCorrespondentByUrl =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.CorrespondentService/GetCorrespondentByUrl',
      request,
      metadata || {},
      methodDescriptor_CorrespondentService_GetCorrespondentByUrl);
};


module.exports = proto.accountpb;

