import { PositionServiceClient, ListPositionRequest } from '../proto/reportpb/position_grpc_web_pb';
import { stringToProtoDate } from './ConvertService';
import { auth } from '../lib/auth/Auth';
import { Pagination } from '../proto/utilspb/pagination_pb';

const service = new PositionServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });

export async function generateRandomData(param, paging) {
  const startDate = new Date('2023-01-01');
  const endDate = new Date(startDate);
  endDate.setMonth(endDate.getMonth() + 3);

  const data = [];

  const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  while (startDate < endDate) {
    const dateString = startDate.toISOString().split('T')[0];
    const randomValue = getRandomInt(10, 10000);
    const randomValue2 = getRandomInt(10, 10000);

    data.push(
      {
        "dateType": "Settle Date",
        "date": {
          "year": 0,
          "month": 0,
          "day": 0
        },
        "accountId": 1,
        "correspondent": "SASS",
        "branch": "INDIVIDUAL",
        "accountNo": "0001234501",
        "subAccountNo": "0001234501",
        "masterAccountNo": "SAS101004",
        "rep": "JB",
        "broker": "Broker Dealer",
        "marginType": "Cash",
        "type": "Client",
        "accountName": "SAS Account 1",
        "originalCusip": "136836060",
        "symbol": "AMZN",
        "cusip": "136836060",
        "symbolDescription": "AMZN",
        "assetType": "Equity",
        "closingPrice": randomValue2.toString(),
        "qty": randomValue.toString(),
        "marketValue": randomValue.toString(),
        "costBasis": "0",
        "sdQty": randomValue.toString(),
        "sdMarketValue": randomValue.toString(),
        "sdCostBasis": "0",
        "sdLongQty": "100",
        "sdShortQty": "0",
        "sdShortMarketValue": "0",
        "sdLongMarketValue": "100",
        "side": "Long",
        "dtccNo": "0",
        "location": "Fed Withholding",
        "segQty": "0",
        "accountStatus": "Active",
        "participantNo": "1143",
        "tdQty": randomValue.toString(),
        "tdMarketValue": randomValue.toString(),
        "tdCostBasis": "100",
        "tdLongQty": "100",
        "tdShortQty": "0",
        "tdShortMarketValue": "0",
        "tdLongMarketValue": "100",
        "accountCurrency": "USD",
        "currency": "USD",
        "currencyAmount": "100",
        "currencyRate": "100",
        "currencyPrice": "100",
        "lock": true,
        "lockReason": "This is a lock reason.",
        "unsettledQty": "100",
        "unsettledValue": "100",
        "contraBroker": "SASS",
        "ytc": "100",
        "ytm": "100",
        "expirationDate": {
          "year": 0,
          "month": 0,
          "day": 0
        }
      }
    );

    startDate.setDate(startDate.getDate() + 1);
  }

  return new Promise((resolve, reject) => {
    // Your asynchronous logic goes here
    // For example, you might have an asynchronous operation like fetching data

    // Simulating an asynchronous operation with setTimeout
    setTimeout(() => {
      // Assuming the data is successfully generated
      const dataRes = {
        positions: data,
        summary: {
          "totalRows": 0,
          "qty": "99",
          "marketValue": "99.99",
          "costBasis": "99.99",
          "sdQty": "100",
          "sdMarketValue": "99.99",
          "sdCostBasis": "99.99",
          "sdLongQty": "45",
          "sdShortQty": "67",
          "sdShortMarketValue": "99.99",
          "sdLongMarketValue": "99.99",
          "tdQty": "234",
          "tdMarketValue": "99.99",
          "tdCostBasis": "99.99",
          "tdLongQty": "2345",
          "tdShortQty": "45",
          "tdShortMarketValue": "99.99",
          "tdLongMarketValue": "99.99",
          "segQty": "2345",
          "ytc": "12",
          "ytm": "4"
        },
      };

      // Resolve the Promise with the generated data
      resolve(dataRes);

      // If there's an error, you can reject the Promise
      // reject(new Error('Failed to generate data'));
    }, 1000); // Simulating a delay of 1000 milliseconds (1 second)
  });
};


export async function generateDataFromStorage(key = 'generatedData') {
  // Check if data is already present in local storage
  const storedData = localStorage.getItem(key);

  if (storedData) {
    // If data is present, parse and return it
    return new Promise((resolve) => {
      setTimeout(() => {
        const parsedData = JSON.parse(storedData);
        console.log(parsedData, " parsedData ")
        resolve(parsedData);
      }, 1000);
    });
  }

  // If data is not present, generate new data
  const startDate = new Date('2023-01-01');
  const endDate = new Date('2023-01-06');

  const data = [];

  const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  while (startDate < endDate) {
    const dateString = startDate.toISOString().split('T')[0];
    const randomValue = getRandomInt(10, 10000);
    const randomValue2 = getRandomInt(10, 10000);

    data.push(
      {
        "dateType": "Settle Date",
        "date": {
          "year": 0,
          "month": 0,
          "day": 0
        },
        "accountId": 1,
        "correspondent": "SASS",
        "branch": "INDIVIDUAL",
        "accountNo": "0001234501",
        "subAccountNo": "0001234501",
        "masterAccountNo": "SAS101004",
        "rep": "JB",
        "broker": "Broker Dealer",
        "marginType": "Cash",
        "type": "Client",
        "accountName": "SAS Account 1",
        "originalCusip": "136836060",
        "symbol": "AMZN",
        "cusip": "136836060",
        "symbolDescription": "AMZN",
        "assetType": "Equity",
        "closingPrice": randomValue2.toString(),
        "qty": randomValue.toString(),
        "marketValue": randomValue.toString(),
        "costBasis": "0",
        "sdQty": randomValue.toString(),
        "sdMarketValue": randomValue.toString(),
        "sdCostBasis": "0",
        "sdLongQty": "100",
        "sdShortQty": "0",
        "sdShortMarketValue": "0",
        "sdLongMarketValue": "100",
        "side": "Long",
        "dtccNo": "0",
        "location": "Fed Withholding",
        "segQty": "0",
        "accountStatus": "Active",
        "participantNo": "1143",
        "tdQty": randomValue.toString(),
        "tdMarketValue": randomValue.toString(),
        "tdCostBasis": "100",
        "tdLongQty": "100",
        "tdShortQty": "0",
        "tdShortMarketValue": "0",
        "tdLongMarketValue": "100",
        "accountCurrency": "USD",
        "currency": "USD",
        "currencyAmount": "100",
        "currencyRate": "100",
        "currencyPrice": "100",
        "lock": true,
        "lockReason": "This is a lock reason.",
        "unsettledQty": "100",
        "unsettledValue": "100",
        "contraBroker": "SASS",
        "ytc": "100",
        "ytm": "100",
        "expirationDate": {
          "year": 0,
          "month": 0,
          "day": 0
        }
      }
    );

    startDate.setDate(startDate.getDate() + 1);
  }

  const generatedData = {
    positions: data,
    summary: {
      "totalRows": 0,
      "qty": "99",
      "marketValue": "99.99",
      "costBasis": "99.99",
      "sdQty": "100",
      "sdMarketValue": "99.99",
      "sdCostBasis": "99.99",
      "sdLongQty": "45",
      "sdShortQty": "67",
      "sdShortMarketValue": "99.99",
      "sdLongMarketValue": "99.99",
      "tdQty": "234",
      "tdMarketValue": "99.99",
      "tdCostBasis": "99.99",
      "tdLongQty": "2345",
      "tdShortQty": "45",
      "tdShortMarketValue": "99.99",
      "tdLongMarketValue": "99.99",
      "segQty": "2345",
      "ytc": "12",
      "ytm": "4"
    },
  };

  // Save the generated data in local storage
  localStorage.setItem(key, JSON.stringify(generatedData));

  // Return the generated data
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(generatedData);
    }, 1000);
  });
};


export async function listPosition(param, paging) {
  return new Promise((resolve, reject) => {
    let req = new ListPositionRequest();
    req.setFromDate(stringToProtoDate(param.fromDate));
    req.setToDate(stringToProtoDate(param.toDate));
    req.setCorrespondent(param.correspondent);
    req.setBranch(param.branch);
    req.setAccountNo(param.accountNo);
    req.setMasterAccountNo(param.masterAccountNo);
    req.setRep(param.rep);
    req.setAccountName(param.accountName);
    req.setSymbol(param.symbol);

    if (paging) {
      let pg = new Pagination();
      pg.setPageNo(paging.pageNo);
      pg.setPageSize(paging.rowsPerPage);
      pg.setSortName(paging.sortName);
      pg.setSortDirection(paging.sortDirection);
      pg.setFilterNamesList(paging.filterNames);
      pg.setFilterValuesList(paging.filterValues);
      req.setPagination(pg);
    }

    service.listPosition(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
