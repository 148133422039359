import React from 'react';
import { Stepper, Step, StepButton } from '@material-ui/core';
import CircularPercentProgress from '../../../components/Progress/CircularPercentProgress';
import defaultLogo from '../../../assets/images/sas-logo.png';
import { getLogo } from '../../../services/TemplateService';

export default function Header({
  activeForm,
  forms,
  isCompleted,
  progress,
  accountData,
  onNavigate,
}) {
  const [clientLogo, setClientLogo] = React.useState(null);

  React.useEffect(() => {
    fetchClientLogo();
  });

  const fetchClientLogo = async () => {
    try {
      const serverLogo = await getLogo();

      if (serverLogo.correspondent.photo !== '') {
        setClientLogo(serverLogo.correspondent.photo);
      } else {
        setClientLogo(defaultLogo);
      }
    } catch (error) {
      console.error(error);
      setClientLogo(defaultLogo);
    }
  };

  return (
    <div className="register-h">
      <div className={activeForm <= 10 ? 'grd-cell-none' : 'd-none'}>
        {clientLogo && (
          <img
            src={clientLogo}
            alt="Client Logo"
            style={{ maxHeight: '100px', maxWidth: '350px' }}
          />
        )}
      </div>
      {activeForm <= 10 && (
        <div>
          <div className="stepper-w">
            <Stepper nonLinear activeStep={activeForm} alternativeLabel>
              {forms.map((form, index) => {
                const stepProps = {};

                return (
                  <Step key={index} {...stepProps}>
                    <StepButton
                      onClick={() => {
                        onNavigate(index);
                      }}
                      completed={isCompleted[form.name]}
                    >
                      {form.label}
                    </StepButton>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        </div>
      )}
      {activeForm <= 10 && (
        <div>
          <CircularPercentProgress
            value={progress}
            accountType={accountData.accountType}
          />
        </div>
      )}
    </div>
  );
}
