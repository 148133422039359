import React from 'react'
import { IconButton } from '@material-ui/core'
import {
  Create as EditIcon, 
  Visibility as ViewIcon,
  Delete as DeleteIcon 
} from '@material-ui/icons'
import ClientGrid from '../../../../components/ClientGrid/ClientGrid'
import ClientGrid2 from '../../../../components/ClientGrid/ClientGrid2'
import Table, { columnType } from '../../../../components/ClearingTable/Table';
import { 
  deleteSpinOff,
  listSpinOff,
  updateSpinOff
} from '../../../../services/ReorgAnnouncement';
import { notifyError, notifySuccess, notifyInfo } from '../../../../components/Messages/Notification';
import { spinOffColumns } from '../../../../lib/data/columns';
import SpinOffModal from './SpinOffModal';
import DetailModal from './DetailModal';
import {
  CustomBodyRenderCurrency,
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderDate,
  CustomBodyRenderDateTime,
  CustomBodyRenderBoolean,
  CustomBodyRenderAlign,
  CustomBodyRenderHtmlString,
  CustomBodyRenderCenter,
  CustomBodyRenderQty,
} from '../../../../components/Table/CustomBodyRender';
import { formatPbDate, formatPbDateTime } from '../../../../lib/fmt';

export default function SpinOffTable({
  newRows,
  searchData,
  announcementType,
  loading
}) {
const [rows, setRows] = React.useState([])
const [rowData, setRowData] = React.useState([])
const [open, setOpen] = React.useState({
  edit: false,
  view: false
})
const [saving, setSaving] = React.useState(false);

React.useEffect(() => {
  if (announcementType == 'spin_off'){
    setRows([])
  }
}, [announcementType])

React.useEffect(() => {
  if (announcementType == 'spin_off'){
    getRows()
  }
}, [newRows])

  const GridButtons = ({
    tableManager,
    value,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return (
      <div className='grd-row'>
        <div className='grd-cell nm'>
          <IconButton aria-label="view" onClick={() => handleView(data)}>
            <ViewIcon style={{ height: 24, width: 24 }} />
          </IconButton>
        </div>
      </div>
    )
  }

  const columns2 = [
    {
      id: '',
      label: '',
      width: '100px',
      cellRenderer: GridButtons,
    },
    ...spinOffColumns
  ]

  const columns = [
    {
      name: 'actions',
      label: 'Actions',
      sort: false,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return(
            <div className='grd-row'>
              <div className='grd-cell nm'>
                <IconButton aria-label="view" onClick={() => handleView(rows[dataIndex])}>
                  <ViewIcon style={{ height: 24, width: 24 }} />
                </IconButton>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'textNumber',
      label: 'Text Number',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      // Already converted to string in handlesearch
      name: 'systemDate',
      label: 'System Date',
    },
    {
      // Already converted to string in handlesearch
      name: 'expirationDate',
      label: 'Expiration Date',
    },
    {
      name: 'targetSymbol',
      label: 'Target Symbol',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'targetOriginalCusip',
      label: 'Target Original Cusip',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'initiatingSymbol',
      label: 'Initiating Symbol',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'initiatingOriginalCusip',
      label: 'Initiating Original Cusip',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'cash',
      label: 'Cash',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'newRate',
      label: 'New Qty',
      options: {
        customBodyRender: CustomBodyRenderQty,
      },
      addFooter: true,
      type: 'qty',
    },
    {
      name: 'oldRate',
      label: 'Old Qty',
      options: {
        customBodyRender: CustomBodyRenderQty,
      },
      addFooter: true,
      type: 'qty',
    },
    {
      // Already converted to string in handlesearch
      name: 'positionDate',
      label: 'Position Date',
      options: {
        display: false,
      }
    },
    {
      // Already converted to string in handlesearch
      name: 'processDate',
      label: 'Process Date',
      options: {
        dsiplay: false,
      }
    },
    {
      name: 'detail',
      label: 'Detail',
      options: {
        customBodyRender: CustomBodyRenderHtmlString,
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
  ];

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
  };

  const handleOpen = (data) => {
    setRowData(data);
    setOpen({
      ...open,
      edit: true
    })
  }

  const handleView = (data) => {
    setRowData(data);
    setOpen({
      ...open,
      view: true
    })
  }

  const handleClose = async (data) => {
    if (!data) {
      setOpen({
        view: false,
        edit: false
      })
      return;
    }

    setSaving(true)
    try{
      const {spinOff} = await updateSpinOff(data);

      notifySuccess("SpinOff has been updated");
    } catch(error){
      console.error(error)
      notifyError(error.message)
    }
    
    getRows()
    setSaving(false)
    setOpen({
      view: false,
      edit: false
    })
  }

  const isDeleteEnabled = (data) => {
    if( data.status == 'Active'){
      return true
    }
    return false
  }

  const handleDelete = async (data) => {
    try {
      await deleteSpinOff(data.spinOffId);

      notifySuccess("SpinOff has been deactivated");
      getRows(false);
    } catch(error){
      console.error(error);
      notifyError(error.message);
    }
  }

  const getRows = async (notify) => {
    const data = await listSpinOff(searchData);

    let rowsCopy = data.spinOffsList;

    const rowsCopyOutput = rowsCopy.map((data) => {
      let tempDate1 = formatPbDate(data.expirationDate);
      data.expirationDate = tempDate1

      let tempDate2 = formatPbDate(data.positionDate);
      data.positionDate = tempDate2

      let tempDate3 = formatPbDate(data.processDate);
      data.processDate = tempDate3

      let tempDate4 = formatPbDate(data.systemDate);
      data.systemDate = tempDate4

      return data;
    })

    if (notify){
      notifyInfo(data.spinOffsList.length + ' search results.');
    }
    setRows(rowsCopyOutput);
  }

  const getCsvData = async () => {
    const data = await listSpinOff(searchData)

    return data.spinOffsList
  }

  return (
    <div className="mt-20">
      {/* <ClientGrid
        title='SpinOff'
        rowIdField='spinOffId'
        columns={columns2}
        rows={rows}
        getCsvData={getCsvData}
        isLoading={loading.search}
        setRows={setRows}
      /> */}
      {/* <ClientGrid2
        title="SpinOff"
        data={rows}
        columns={columns}
        options={options}
      /> */}
      <Table
        title={'SpinOff'}
        data={rows}
        columns={columns}
        options={options}
      />
      {open.edit && 
        <SpinOffModal
          data = {rowData}
          open = {open.edit}
          close = {handleClose}
          isEdit = {true}
          loading = {saving}
        />
      }
      
      {open.view && 
        <DetailModal
          data = {rowData}
          open = {open.view}
          close = {handleClose}
          title = {'Spin-Off'}
        />
      }
    </div>
  )
}
