import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import { AddCircle as AddCircleIcon } from '@material-ui/icons'
import { listContraWallet, createWallet } from '../../services/WalletService'
import { notifySuccess, notifyError } from '../Messages/Notification'
import color from '../../assets/css/colors'
import {
  createWalletAddressBook,
  updateWalletAddressBook,
} from '../../services/WalletAddressBookService'
import {
  createRecurrence,
  updateRecurrence,
  deleteRecurrence,
} from '../../services/RecurrenceService';
import WalletAddressBookModal from '../Modals/WalletAddressBookModal'

const useStyles = makeStyles(() => ({
  dropdownWrapper: {
    display: 'flex',
    position: 'relative',
  },
  actionWrapper: {
    position: 'absolute',
    top: 29,
    right: 20,
    padding: 8,
    backgroundColor: 'unset',
    borderRadius: 5,
    '& svg': {
      width: 24,
      height: 24,
      cursor: 'pointer',
      '& path': {
        fill: color.primary.main,
      },
    },
  },
}))

export default function SelectContraWallet(props) {
  const classes = useStyles()

  const [options, setOptions] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [rowData, setRowData] = React.useState({
    walletId: props.walletId || '',
    accountId: '',
    symbol: '',
    symbol: '',
    accountNo: props.accountNo || '',
    correspondent: props.correspondent || '',
    wallet: '',
    status: 'Active',
    note: '',
  })

  const getOptions = async (props) => {
    const { walletsList } = await listContraWallet(props)

    setOptions(walletsList)
  }

  const handleOpen = async ()  => {
    let dataCopy = {...rowData}
    try {
    const reservedData = await createWalletAddressBook(dataCopy);
    dataCopy.walletAddressBookId = reservedData.walletAddressBook.walletAddressBookId;
    console.log(dataCopy)
  } catch (error) {
    console.error(error);
    notifyError(error.message);
  }
    setRowData(dataCopy);
    setOpen(true)
  }

  const validate = async (data, recData) => {
    let valid = true;

    if (
      (data.recurrenceAmount &&
      data.recurrenceAmount !== '' &&
      data.recurrenceAmount != 0) &&
      (recData.recurring == 'None' || recData.recurring == '')
    ) {
      notifyError('Recurring Pattern is required to enter recurrence amount');
      valid = false;
    }

    if (((!data.recurrenceAmount) || data.recurrenceAmount === '' || data.recurrenceAmount == 0) &&
      (recData.recurring !== 'None' && recData.recurring !== '')
    ) {
      notifyError('Recurrence Amount is required for recurrence');
      valid = false;
    }

    if (data.correspondent == '' || !data.correspondent) {
      notifyError('Correspondent is required');
      valid = false;
    }
    if (data.accountNo == '' || !data.accountNo) {
      notifyError('Account No is required');
      valid = false;
    }
    if ( data.walletId == '' || !data.walletId) {
      notifyError('Wallet Id is required');
      valid = false;
    }
    if (data.contraWalletId == '' || !data.contraWalletId) {
      notifyError('Contra wallet Id is required');
      valid = false;
    }
    if (data.status == '' || !data.status) {
      notifyError('Status is required');
      valid = false;
    }

    return valid;
  };

  const handleClose = async (data, recData, isEdit) => {
    if (!data) {
      setOpen(false);
      return;
    }
    console.log(data);
    const valid = await validate(data, recData);
    if (!valid) {
      return;
    }

    try {
      const resp = await updateWalletAddressBook(data);
      if (recData) {
        recData.linkId = data.walletAddressBookId;
        recData.functionName = 'Bank';
        recData.report = 'Wallet Address Book';
        if (recData.recurrenceId != 0) {
          if (recData.recurring == 'None') {
            await deleteRecurrence(recData);
          } else {
            await updateRecurrence(recData);
          }
        } else {
          await createRecurrence(recData);
        }
      }

      if (isEdit) {
        notifySuccess(' Wallet Address Book has been updated.');
      } else {
        notifySuccess(' Wallet Address Book has been added.');
      }
      let param = {
        walletId: props.walletId,
        status: 'Active',
      }
  
      getOptions(param)
      setOpen(false);
    } catch (error) {
      console.error(error);
      notifyError(error.message);
    }
  };


  React.useEffect(() => {
    let param = {
      walletId: props.walletId,
      status: 'Active',
    }

    getOptions(param)
  }, [props.walletId])

  return (
    <div className={classes.dropdownWrapper}>
      <FormControl
        fullWidth
        error={props.error}
        style={{
          marginTop: props.mt ? props.mt : 0,
          marginBottom: props.mb ? props.mb : 0,
        }}
        disabled={props.disabled}
      >
        <InputLabel
          className={props.disabled ? classes.disabled : ''}
          shrink
          required={props.required}
        >
          {props.label}
        </InputLabel>
        <Select
          displayEmpty
          fullWidth
          disabled={props.disabled}
          name={props.name}
          value={props.value ? props.value : ''}
          onChange={props.onChange}
        >
          <MenuItem key="blank" value="">
            <span style={{ color: '#bbbdc5' }}>{props.placeholder}</span>
          </MenuItem>
          {options.length ? (
            options.map((i) => (
              <MenuItem key={i.walletId} value={i.walletId}>
                {i.nickName} ({i.symbol})
              </MenuItem>
            ))
          ) : (
            <MenuItem key="" value=""></MenuItem>
          )}
        </Select>
      </FormControl>
      {(props.allowAddWallet && !props.disabled) && (
        <div className={classes.actionWrapper}>
          <Tooltip arrow placement="top" title="Add Address Book">
            <IconButton aria-label="add" onClick={() => handleOpen()}>
              <AddCircleIcon color="primary" />
            </IconButton>
          </Tooltip>
        </div>
      )}
      <WalletAddressBookModal
        open={open}
        close={handleClose}
        data={rowData}
        loading={loading}
        profileName={props.profileName}
      />
    </div>
  )
}
