/* eslint-disable no-use-before-define */
import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { lazyLoadSystemCode } from '../../services/CommonService';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
    '& .MuiChip-label': {
      paddingLeft: 9,
      paddingRight: 9,
    },
    '& .MuiChip-deleteIcon': {
      width: '18px !important',
      height: '21px !important',
      margin: '0 4px 0 -6px !important',
    },
    '& .MuiChip-root': {
      height: '25px !important',
    },
  },
}));

function SelectAutoCompleteSystemCode(props) {
  const classes = useStyles();

  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    if (options.length === 0) {
      getOptions(props);
    }
    // eslint-disable-next-line
  }, [props]);

  const getOptions = async (data) => {
    const { systemCodeList } = await lazyLoadSystemCode({
      type: props.type,
      subType: props.subType,
      code: data.value,
      note: props.note,
      limit: props.limit,
      multiple: true,
    });

    const list = systemCodeList.filter((s) => s.code);
    const nonTrade = { code: 'NON TRD', description: 'None Trade Entry' };

    if (props.type === 'Entry Type') {
      list.push(nonTrade);
    }

    setOptions(list);
  };

  const setPropsValue = (value) => {
    props.onChange({
      currentTarget: {
        name: props.name,
        value: value,
      },
      target: {
        name: props.name,
        value: value,
      },
    });
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        freeSolo={true}
        disabled={props.disabled}
        options={options}
        getOptionLabel={(option) => {
          return ['Status', 'Side', 'System Process'].includes(props.type)
            ? option?.code
            : ['Sub Account No'].includes(props.type)
            ? option?.description
            : option?.code + ' - ' + option?.description;
        }}
        getOptionSelected={(option, value) => option.code === value.code}
        onChange={(e, value) => {
          setPropsValue(value);
        }}
        onKeyDown={props.onKeyDown}
        value={props.value}
        renderInput={(params) => (
          <TextField
            {...params}
            name={props.name}
            required={props.required}
            disabled={props.disabled}
            label={props.label}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </div>
  );
}

export const SelectMarginType = (props) => {
  return <SelectAutoCompleteSystemCode {...props} type={'Margin Type'} />;
};
