// source: proto/marginpb/calllog.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_type_date_pb = require('../../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.marginpb.CallLog', null, global);
goog.exportSymbol('proto.marginpb.CallNoticeRequest', null, global);
goog.exportSymbol('proto.marginpb.EmptyResponse', null, global);
goog.exportSymbol('proto.marginpb.ListCallLogRequest', null, global);
goog.exportSymbol('proto.marginpb.ListCallLogResponse', null, global);
goog.exportSymbol('proto.marginpb.UpdateCallLogResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marginpb.CallLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marginpb.CallLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marginpb.CallLog.displayName = 'proto.marginpb.CallLog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marginpb.ListCallLogRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marginpb.ListCallLogRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marginpb.ListCallLogRequest.displayName = 'proto.marginpb.ListCallLogRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marginpb.ListCallLogResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marginpb.ListCallLogResponse.repeatedFields_, null);
};
goog.inherits(proto.marginpb.ListCallLogResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marginpb.ListCallLogResponse.displayName = 'proto.marginpb.ListCallLogResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marginpb.UpdateCallLogResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marginpb.UpdateCallLogResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marginpb.UpdateCallLogResponse.displayName = 'proto.marginpb.UpdateCallLogResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marginpb.CallNoticeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marginpb.CallNoticeRequest.repeatedFields_, null);
};
goog.inherits(proto.marginpb.CallNoticeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marginpb.CallNoticeRequest.displayName = 'proto.marginpb.CallNoticeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marginpb.EmptyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marginpb.EmptyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marginpb.EmptyResponse.displayName = 'proto.marginpb.EmptyResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marginpb.CallLog.prototype.toObject = function(opt_includeInstance) {
  return proto.marginpb.CallLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marginpb.CallLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.CallLog.toObject = function(includeInstance, msg) {
  var f, obj = {
    requirementLogId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    requirementId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    tradeDate: (f = msg.getTradeDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    fromDate: (f = msg.getFromDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    toDate: (f = msg.getToDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    accountId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    correspondent: jspb.Message.getFieldWithDefault(msg, 7, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 8, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    callType: jspb.Message.getFieldWithDefault(msg, 10, ""),
    callReq: jspb.Message.getFieldWithDefault(msg, 11, ""),
    callAmt: jspb.Message.getFieldWithDefault(msg, 12, ""),
    callStatus: jspb.Message.getFieldWithDefault(msg, 13, ""),
    callStatusReason: jspb.Message.getFieldWithDefault(msg, 14, ""),
    modifiedBy: jspb.Message.getFieldWithDefault(msg, 15, ""),
    modifiedDate: (f = msg.getModifiedDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    dueDate: (f = msg.getDueDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    callStatusReasonCount: jspb.Message.getFieldWithDefault(msg, 18, 0),
    notify: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    notifyDate: (f = msg.getNotifyDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    marginType: jspb.Message.getFieldWithDefault(msg, 21, ""),
    age: jspb.Message.getFieldWithDefault(msg, 22, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marginpb.CallLog}
 */
proto.marginpb.CallLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marginpb.CallLog;
  return proto.marginpb.CallLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marginpb.CallLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marginpb.CallLog}
 */
proto.marginpb.CallLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRequirementLogId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRequirementId(value);
      break;
    case 3:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setTradeDate(value);
      break;
    case 4:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromDate(value);
      break;
    case 5:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToDate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallReq(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallAmt(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallStatus(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallStatusReason(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifiedBy(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModifiedDate(value);
      break;
    case 17:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setDueDate(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCallStatusReasonCount(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotify(value);
      break;
    case 20:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setNotifyDate(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarginType(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marginpb.CallLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marginpb.CallLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marginpb.CallLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.CallLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequirementLogId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getRequirementId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTradeDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getFromDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCallType();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCallReq();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCallAmt();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCallStatus();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCallStatusReason();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getModifiedBy();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getModifiedDate();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDueDate();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getCallStatusReasonCount();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getNotify();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getNotifyDate();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMarginType();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getAge();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
};


/**
 * optional uint32 requirement_log_id = 1;
 * @return {number}
 */
proto.marginpb.CallLog.prototype.getRequirementLogId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setRequirementLogId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 requirement_id = 2;
 * @return {number}
 */
proto.marginpb.CallLog.prototype.getRequirementId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setRequirementId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.type.Date trade_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.marginpb.CallLog.prototype.getTradeDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.marginpb.CallLog} returns this
*/
proto.marginpb.CallLog.prototype.setTradeDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.clearTradeDate = function() {
  return this.setTradeDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marginpb.CallLog.prototype.hasTradeDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.type.Date from_date = 4;
 * @return {?proto.google.type.Date}
 */
proto.marginpb.CallLog.prototype.getFromDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 4));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.marginpb.CallLog} returns this
*/
proto.marginpb.CallLog.prototype.setFromDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.clearFromDate = function() {
  return this.setFromDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marginpb.CallLog.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.type.Date to_date = 5;
 * @return {?proto.google.type.Date}
 */
proto.marginpb.CallLog.prototype.getToDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 5));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.marginpb.CallLog} returns this
*/
proto.marginpb.CallLog.prototype.setToDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.clearToDate = function() {
  return this.setToDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marginpb.CallLog.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint32 account_id = 6;
 * @return {number}
 */
proto.marginpb.CallLog.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string correspondent = 7;
 * @return {string}
 */
proto.marginpb.CallLog.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string master_account_no = 8;
 * @return {string}
 */
proto.marginpb.CallLog.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string account_name = 9;
 * @return {string}
 */
proto.marginpb.CallLog.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string call_type = 10;
 * @return {string}
 */
proto.marginpb.CallLog.prototype.getCallType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setCallType = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string call_req = 11;
 * @return {string}
 */
proto.marginpb.CallLog.prototype.getCallReq = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setCallReq = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string call_amt = 12;
 * @return {string}
 */
proto.marginpb.CallLog.prototype.getCallAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setCallAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string call_status = 13;
 * @return {string}
 */
proto.marginpb.CallLog.prototype.getCallStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setCallStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string call_status_reason = 14;
 * @return {string}
 */
proto.marginpb.CallLog.prototype.getCallStatusReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setCallStatusReason = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string modified_by = 15;
 * @return {string}
 */
proto.marginpb.CallLog.prototype.getModifiedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setModifiedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional google.protobuf.Timestamp modified_date = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.marginpb.CallLog.prototype.getModifiedDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.marginpb.CallLog} returns this
*/
proto.marginpb.CallLog.prototype.setModifiedDate = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.clearModifiedDate = function() {
  return this.setModifiedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marginpb.CallLog.prototype.hasModifiedDate = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.type.Date due_date = 17;
 * @return {?proto.google.type.Date}
 */
proto.marginpb.CallLog.prototype.getDueDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 17));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.marginpb.CallLog} returns this
*/
proto.marginpb.CallLog.prototype.setDueDate = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.clearDueDate = function() {
  return this.setDueDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marginpb.CallLog.prototype.hasDueDate = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional int32 call_status_reason_count = 18;
 * @return {number}
 */
proto.marginpb.CallLog.prototype.getCallStatusReasonCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setCallStatusReasonCount = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional bool notify = 19;
 * @return {boolean}
 */
proto.marginpb.CallLog.prototype.getNotify = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setNotify = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional google.protobuf.Timestamp notify_date = 20;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.marginpb.CallLog.prototype.getNotifyDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 20));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.marginpb.CallLog} returns this
*/
proto.marginpb.CallLog.prototype.setNotifyDate = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.clearNotifyDate = function() {
  return this.setNotifyDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marginpb.CallLog.prototype.hasNotifyDate = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional string margin_type = 21;
 * @return {string}
 */
proto.marginpb.CallLog.prototype.getMarginType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setMarginType = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional int32 age = 22;
 * @return {number}
 */
proto.marginpb.CallLog.prototype.getAge = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.marginpb.CallLog} returns this
 */
proto.marginpb.CallLog.prototype.setAge = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marginpb.ListCallLogRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marginpb.ListCallLogRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marginpb.ListCallLogRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.ListCallLogRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromDate: (f = msg.getFromDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    toDate: (f = msg.getToDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    correspondent: jspb.Message.getFieldWithDefault(msg, 3, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    callType: jspb.Message.getFieldWithDefault(msg, 6, ""),
    callStatus: jspb.Message.getFieldWithDefault(msg, 7, ""),
    callStatusReason: jspb.Message.getFieldWithDefault(msg, 8, ""),
    isOpen: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    marginType: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marginpb.ListCallLogRequest}
 */
proto.marginpb.ListCallLogRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marginpb.ListCallLogRequest;
  return proto.marginpb.ListCallLogRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marginpb.ListCallLogRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marginpb.ListCallLogRequest}
 */
proto.marginpb.ListCallLogRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromDate(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallStatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallStatusReason(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOpen(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarginType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marginpb.ListCallLogRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marginpb.ListCallLogRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marginpb.ListCallLogRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.ListCallLogRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCallType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCallStatus();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCallStatusReason();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIsOpen();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getMarginType();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional google.type.Date from_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.marginpb.ListCallLogRequest.prototype.getFromDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.marginpb.ListCallLogRequest} returns this
*/
proto.marginpb.ListCallLogRequest.prototype.setFromDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marginpb.ListCallLogRequest} returns this
 */
proto.marginpb.ListCallLogRequest.prototype.clearFromDate = function() {
  return this.setFromDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marginpb.ListCallLogRequest.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.type.Date to_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.marginpb.ListCallLogRequest.prototype.getToDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.marginpb.ListCallLogRequest} returns this
*/
proto.marginpb.ListCallLogRequest.prototype.setToDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marginpb.ListCallLogRequest} returns this
 */
proto.marginpb.ListCallLogRequest.prototype.clearToDate = function() {
  return this.setToDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marginpb.ListCallLogRequest.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string correspondent = 3;
 * @return {string}
 */
proto.marginpb.ListCallLogRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.ListCallLogRequest} returns this
 */
proto.marginpb.ListCallLogRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.marginpb.ListCallLogRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.ListCallLogRequest} returns this
 */
proto.marginpb.ListCallLogRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_name = 5;
 * @return {string}
 */
proto.marginpb.ListCallLogRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.ListCallLogRequest} returns this
 */
proto.marginpb.ListCallLogRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string call_type = 6;
 * @return {string}
 */
proto.marginpb.ListCallLogRequest.prototype.getCallType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.ListCallLogRequest} returns this
 */
proto.marginpb.ListCallLogRequest.prototype.setCallType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string call_status = 7;
 * @return {string}
 */
proto.marginpb.ListCallLogRequest.prototype.getCallStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.ListCallLogRequest} returns this
 */
proto.marginpb.ListCallLogRequest.prototype.setCallStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string call_status_reason = 8;
 * @return {string}
 */
proto.marginpb.ListCallLogRequest.prototype.getCallStatusReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.ListCallLogRequest} returns this
 */
proto.marginpb.ListCallLogRequest.prototype.setCallStatusReason = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool is_open = 9;
 * @return {boolean}
 */
proto.marginpb.ListCallLogRequest.prototype.getIsOpen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.marginpb.ListCallLogRequest} returns this
 */
proto.marginpb.ListCallLogRequest.prototype.setIsOpen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string margin_type = 10;
 * @return {string}
 */
proto.marginpb.ListCallLogRequest.prototype.getMarginType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.ListCallLogRequest} returns this
 */
proto.marginpb.ListCallLogRequest.prototype.setMarginType = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marginpb.ListCallLogResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marginpb.ListCallLogResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marginpb.ListCallLogResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marginpb.ListCallLogResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.ListCallLogResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    callLogsList: jspb.Message.toObjectList(msg.getCallLogsList(),
    proto.marginpb.CallLog.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marginpb.ListCallLogResponse}
 */
proto.marginpb.ListCallLogResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marginpb.ListCallLogResponse;
  return proto.marginpb.ListCallLogResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marginpb.ListCallLogResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marginpb.ListCallLogResponse}
 */
proto.marginpb.ListCallLogResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.marginpb.CallLog;
      reader.readMessage(value,proto.marginpb.CallLog.deserializeBinaryFromReader);
      msg.addCallLogs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marginpb.ListCallLogResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marginpb.ListCallLogResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marginpb.ListCallLogResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.ListCallLogResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCallLogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.marginpb.CallLog.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CallLog call_logs = 1;
 * @return {!Array<!proto.marginpb.CallLog>}
 */
proto.marginpb.ListCallLogResponse.prototype.getCallLogsList = function() {
  return /** @type{!Array<!proto.marginpb.CallLog>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.marginpb.CallLog, 1));
};


/**
 * @param {!Array<!proto.marginpb.CallLog>} value
 * @return {!proto.marginpb.ListCallLogResponse} returns this
*/
proto.marginpb.ListCallLogResponse.prototype.setCallLogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.marginpb.CallLog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.marginpb.CallLog}
 */
proto.marginpb.ListCallLogResponse.prototype.addCallLogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.marginpb.CallLog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marginpb.ListCallLogResponse} returns this
 */
proto.marginpb.ListCallLogResponse.prototype.clearCallLogsList = function() {
  return this.setCallLogsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marginpb.UpdateCallLogResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marginpb.UpdateCallLogResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marginpb.UpdateCallLogResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.UpdateCallLogResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    callLog: (f = msg.getCallLog()) && proto.marginpb.CallLog.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marginpb.UpdateCallLogResponse}
 */
proto.marginpb.UpdateCallLogResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marginpb.UpdateCallLogResponse;
  return proto.marginpb.UpdateCallLogResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marginpb.UpdateCallLogResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marginpb.UpdateCallLogResponse}
 */
proto.marginpb.UpdateCallLogResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.marginpb.CallLog;
      reader.readMessage(value,proto.marginpb.CallLog.deserializeBinaryFromReader);
      msg.setCallLog(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marginpb.UpdateCallLogResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marginpb.UpdateCallLogResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marginpb.UpdateCallLogResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.UpdateCallLogResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCallLog();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.marginpb.CallLog.serializeBinaryToWriter
    );
  }
};


/**
 * optional CallLog call_log = 1;
 * @return {?proto.marginpb.CallLog}
 */
proto.marginpb.UpdateCallLogResponse.prototype.getCallLog = function() {
  return /** @type{?proto.marginpb.CallLog} */ (
    jspb.Message.getWrapperField(this, proto.marginpb.CallLog, 1));
};


/**
 * @param {?proto.marginpb.CallLog|undefined} value
 * @return {!proto.marginpb.UpdateCallLogResponse} returns this
*/
proto.marginpb.UpdateCallLogResponse.prototype.setCallLog = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marginpb.UpdateCallLogResponse} returns this
 */
proto.marginpb.UpdateCallLogResponse.prototype.clearCallLog = function() {
  return this.setCallLog(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marginpb.UpdateCallLogResponse.prototype.hasCallLog = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marginpb.CallNoticeRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marginpb.CallNoticeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marginpb.CallNoticeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marginpb.CallNoticeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.CallNoticeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marginpb.CallNoticeRequest}
 */
proto.marginpb.CallNoticeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marginpb.CallNoticeRequest;
  return proto.marginpb.CallNoticeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marginpb.CallNoticeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marginpb.CallNoticeRequest}
 */
proto.marginpb.CallNoticeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAccountIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marginpb.CallNoticeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marginpb.CallNoticeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marginpb.CallNoticeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.CallNoticeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountIdsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      1,
      f
    );
  }
};


/**
 * repeated uint32 account_ids = 1;
 * @return {!Array<number>}
 */
proto.marginpb.CallNoticeRequest.prototype.getAccountIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.marginpb.CallNoticeRequest} returns this
 */
proto.marginpb.CallNoticeRequest.prototype.setAccountIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.marginpb.CallNoticeRequest} returns this
 */
proto.marginpb.CallNoticeRequest.prototype.addAccountIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marginpb.CallNoticeRequest} returns this
 */
proto.marginpb.CallNoticeRequest.prototype.clearAccountIdsList = function() {
  return this.setAccountIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marginpb.EmptyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marginpb.EmptyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marginpb.EmptyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.EmptyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marginpb.EmptyResponse}
 */
proto.marginpb.EmptyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marginpb.EmptyResponse;
  return proto.marginpb.EmptyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marginpb.EmptyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marginpb.EmptyResponse}
 */
proto.marginpb.EmptyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marginpb.EmptyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marginpb.EmptyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marginpb.EmptyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.EmptyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.marginpb);
