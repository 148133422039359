import {

    GetUrlRequest,
    GetStatusRequest,
    KycServiceClient
   } from '../proto/kycpb/verify_grpc_web_pb';
   
   
   const service = new KycServiceClient(
     window.env.GRPC_ENDPOINT
   );
   
   export async function getUrl(param) {
     return new Promise((resolve, reject) => {
       let req = new GetUrlRequest();
       req.setUsrId(param.id);
       
   
       service.getVerifyUrl(req, {}, (error, response) => {
         if (error) {
           reject(error);
         } else {
           resolve(response.toObject());
         }
       });
     });
   }
   

   export async function getJumioStatus(param) {
    return new Promise((resolve, reject) => {
    
      let req = new GetStatusRequest();
      req.setTransactionReference(param.transactionReference);
      
  
      service.getStatus(req, {}, (error, response) => {
        if (error) {
          reject(error);
        } else {
          resolve(response.toObject());
        }
      });
    });
  }
 