// source: proto/usrpb/administrator.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var proto_accountpb_generateaccountno_pb = require('../../proto/accountpb/generateaccountno_pb.js');
goog.object.extend(proto, proto_accountpb_generateaccountno_pb);
var proto_accountpb_client_pb = require('../../proto/accountpb/client_pb.js');
goog.object.extend(proto, proto_accountpb_client_pb);
var proto_accountpb_owner_pb = require('../../proto/accountpb/owner_pb.js');
goog.object.extend(proto, proto_accountpb_owner_pb);
var proto_accountpb_identification_pb = require('../../proto/accountpb/identification_pb.js');
goog.object.extend(proto, proto_accountpb_identification_pb);
var proto_accountpb_contactinfo_pb = require('../../proto/accountpb/contactinfo_pb.js');
goog.object.extend(proto, proto_accountpb_contactinfo_pb);
var proto_accountpb_address_pb = require('../../proto/accountpb/address_pb.js');
goog.object.extend(proto, proto_accountpb_address_pb);
var proto_clientpb_accountaccess_pb = require('../../proto/clientpb/accountaccess_pb.js');
goog.object.extend(proto, proto_clientpb_accountaccess_pb);
var proto_usrpb_registration_pb = require('../../proto/usrpb/registration_pb.js');
goog.object.extend(proto, proto_usrpb_registration_pb);
var proto_utilspb_pagination_pb = require('../../proto/utilspb/pagination_pb.js');
goog.object.extend(proto, proto_utilspb_pagination_pb);
goog.exportSymbol('proto.usrpb.AcceptClientNoticeRequest', null, global);
goog.exportSymbol('proto.usrpb.Access', null, global);
goog.exportSymbol('proto.usrpb.Administrator', null, global);
goog.exportSymbol('proto.usrpb.BrandingURLRequest', null, global);
goog.exportSymbol('proto.usrpb.BrandingURLResponse', null, global);
goog.exportSymbol('proto.usrpb.ChangePasswordRequest', null, global);
goog.exportSymbol('proto.usrpb.ChangePasswordResponse', null, global);
goog.exportSymbol('proto.usrpb.CheckEmailRequest', null, global);
goog.exportSymbol('proto.usrpb.CheckEmailResponse', null, global);
goog.exportSymbol('proto.usrpb.ConfirmationEmailRequest', null, global);
goog.exportSymbol('proto.usrpb.ConfirmationEmailResponse', null, global);
goog.exportSymbol('proto.usrpb.CreateAccessResponse', null, global);
goog.exportSymbol('proto.usrpb.CreateAdministratorResponse', null, global);
goog.exportSymbol('proto.usrpb.CreateClientOnboardingRequest', null, global);
goog.exportSymbol('proto.usrpb.DeleteAccessRequest', null, global);
goog.exportSymbol('proto.usrpb.DeleteAccessResponse', null, global);
goog.exportSymbol('proto.usrpb.DeleteAdministratorRequest', null, global);
goog.exportSymbol('proto.usrpb.DeleteAdministratorResponse', null, global);
goog.exportSymbol('proto.usrpb.EmptyRequest', null, global);
goog.exportSymbol('proto.usrpb.EmptyResponse', null, global);
goog.exportSymbol('proto.usrpb.HasSecurityQuestionsRequest', null, global);
goog.exportSymbol('proto.usrpb.HasSecurityQuestionsResponse', null, global);
goog.exportSymbol('proto.usrpb.HasSecurityWordRequest', null, global);
goog.exportSymbol('proto.usrpb.HasSecurityWordResponse', null, global);
goog.exportSymbol('proto.usrpb.ListAdministratorRequest', null, global);
goog.exportSymbol('proto.usrpb.ListAdministratorResponse', null, global);
goog.exportSymbol('proto.usrpb.ListAdministratorSummary', null, global);
goog.exportSymbol('proto.usrpb.ListDefaultAccessOptionResponse', null, global);
goog.exportSymbol('proto.usrpb.ListUserAccountAccessRequest', null, global);
goog.exportSymbol('proto.usrpb.ListUserAccountAccessResponse', null, global);
goog.exportSymbol('proto.usrpb.ReadAdministratorRequest', null, global);
goog.exportSymbol('proto.usrpb.ReadAdministratorResponse', null, global);
goog.exportSymbol('proto.usrpb.SecurityQuestionRequest', null, global);
goog.exportSymbol('proto.usrpb.SecurityQuestionResponse', null, global);
goog.exportSymbol('proto.usrpb.SetKeyRequest', null, global);
goog.exportSymbol('proto.usrpb.UpdateAccessResponse', null, global);
goog.exportSymbol('proto.usrpb.UpdateAdministratorResponse', null, global);
goog.exportSymbol('proto.usrpb.UserAccountAccess', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.Administrator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.Administrator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.Administrator.displayName = 'proto.usrpb.Administrator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.ListAdministratorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.ListAdministratorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.ListAdministratorRequest.displayName = 'proto.usrpb.ListAdministratorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.ListAdministratorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.usrpb.ListAdministratorResponse.repeatedFields_, null);
};
goog.inherits(proto.usrpb.ListAdministratorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.ListAdministratorResponse.displayName = 'proto.usrpb.ListAdministratorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.ListAdministratorSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.ListAdministratorSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.ListAdministratorSummary.displayName = 'proto.usrpb.ListAdministratorSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.CreateAdministratorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.CreateAdministratorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.CreateAdministratorResponse.displayName = 'proto.usrpb.CreateAdministratorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.UpdateAdministratorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.UpdateAdministratorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.UpdateAdministratorResponse.displayName = 'proto.usrpb.UpdateAdministratorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.ReadAdministratorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.ReadAdministratorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.ReadAdministratorRequest.displayName = 'proto.usrpb.ReadAdministratorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.ReadAdministratorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.ReadAdministratorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.ReadAdministratorResponse.displayName = 'proto.usrpb.ReadAdministratorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.ChangePasswordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.ChangePasswordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.ChangePasswordRequest.displayName = 'proto.usrpb.ChangePasswordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.ChangePasswordResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.ChangePasswordResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.ChangePasswordResponse.displayName = 'proto.usrpb.ChangePasswordResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.Access = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.Access, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.Access.displayName = 'proto.usrpb.Access';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.ConfirmationEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.ConfirmationEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.ConfirmationEmailRequest.displayName = 'proto.usrpb.ConfirmationEmailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.ConfirmationEmailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.ConfirmationEmailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.ConfirmationEmailResponse.displayName = 'proto.usrpb.ConfirmationEmailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.CheckEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.CheckEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.CheckEmailRequest.displayName = 'proto.usrpb.CheckEmailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.CheckEmailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.CheckEmailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.CheckEmailResponse.displayName = 'proto.usrpb.CheckEmailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.UserAccountAccess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.UserAccountAccess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.UserAccountAccess.displayName = 'proto.usrpb.UserAccountAccess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.ListUserAccountAccessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.usrpb.ListUserAccountAccessResponse.repeatedFields_, null);
};
goog.inherits(proto.usrpb.ListUserAccountAccessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.ListUserAccountAccessResponse.displayName = 'proto.usrpb.ListUserAccountAccessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.ListUserAccountAccessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.ListUserAccountAccessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.ListUserAccountAccessRequest.displayName = 'proto.usrpb.ListUserAccountAccessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.SetKeyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.SetKeyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.SetKeyRequest.displayName = 'proto.usrpb.SetKeyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.AcceptClientNoticeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.AcceptClientNoticeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.AcceptClientNoticeRequest.displayName = 'proto.usrpb.AcceptClientNoticeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.EmptyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.EmptyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.EmptyResponse.displayName = 'proto.usrpb.EmptyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.HasSecurityWordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.HasSecurityWordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.HasSecurityWordRequest.displayName = 'proto.usrpb.HasSecurityWordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.HasSecurityWordResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.HasSecurityWordResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.HasSecurityWordResponse.displayName = 'proto.usrpb.HasSecurityWordResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.DeleteAdministratorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.DeleteAdministratorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.DeleteAdministratorRequest.displayName = 'proto.usrpb.DeleteAdministratorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.DeleteAdministratorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.DeleteAdministratorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.DeleteAdministratorResponse.displayName = 'proto.usrpb.DeleteAdministratorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.EmptyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.EmptyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.EmptyRequest.displayName = 'proto.usrpb.EmptyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.ListDefaultAccessOptionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.usrpb.ListDefaultAccessOptionResponse.repeatedFields_, null);
};
goog.inherits(proto.usrpb.ListDefaultAccessOptionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.ListDefaultAccessOptionResponse.displayName = 'proto.usrpb.ListDefaultAccessOptionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.CreateAccessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.CreateAccessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.CreateAccessResponse.displayName = 'proto.usrpb.CreateAccessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.UpdateAccessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.UpdateAccessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.UpdateAccessResponse.displayName = 'proto.usrpb.UpdateAccessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.DeleteAccessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.DeleteAccessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.DeleteAccessRequest.displayName = 'proto.usrpb.DeleteAccessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.DeleteAccessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.DeleteAccessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.DeleteAccessResponse.displayName = 'proto.usrpb.DeleteAccessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.HasSecurityQuestionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.HasSecurityQuestionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.HasSecurityQuestionsRequest.displayName = 'proto.usrpb.HasSecurityQuestionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.HasSecurityQuestionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.HasSecurityQuestionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.HasSecurityQuestionsResponse.displayName = 'proto.usrpb.HasSecurityQuestionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.SecurityQuestionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.SecurityQuestionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.SecurityQuestionRequest.displayName = 'proto.usrpb.SecurityQuestionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.SecurityQuestionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.SecurityQuestionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.SecurityQuestionResponse.displayName = 'proto.usrpb.SecurityQuestionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.BrandingURLRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.BrandingURLRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.BrandingURLRequest.displayName = 'proto.usrpb.BrandingURLRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.BrandingURLResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.BrandingURLResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.BrandingURLResponse.displayName = 'proto.usrpb.BrandingURLResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.CreateClientOnboardingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.usrpb.CreateClientOnboardingRequest.repeatedFields_, null);
};
goog.inherits(proto.usrpb.CreateClientOnboardingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.CreateClientOnboardingRequest.displayName = 'proto.usrpb.CreateClientOnboardingRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.Administrator.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.Administrator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.Administrator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.Administrator.toObject = function(includeInstance, msg) {
  var f, obj = {
    usrId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    password: jspb.Message.getFieldWithDefault(msg, 5, ""),
    defaultAccess: jspb.Message.getFieldWithDefault(msg, 6, ""),
    mobileNo: jspb.Message.getFieldWithDefault(msg, 7, ""),
    modifiedBy: jspb.Message.getFieldWithDefault(msg, 8, ""),
    userType: jspb.Message.getFieldWithDefault(msg, 9, ""),
    status: jspb.Message.getFieldWithDefault(msg, 10, ""),
    admin: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    correspondent: jspb.Message.getFieldWithDefault(msg, 12, ""),
    authenticationMode: jspb.Message.getFieldWithDefault(msg, 13, ""),
    secretKey: jspb.Message.getFieldWithDefault(msg, 14, ""),
    clientNotice: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    accountId: jspb.Message.getFieldWithDefault(msg, 16, 0),
    roleId: jspb.Message.getFieldWithDefault(msg, 17, 0),
    roleName: jspb.Message.getFieldWithDefault(msg, 18, ""),
    clientLink: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.Administrator}
 */
proto.usrpb.Administrator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.Administrator;
  return proto.usrpb.Administrator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.Administrator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.Administrator}
 */
proto.usrpb.Administrator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUsrId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultAccess(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobileNo(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifiedBy(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAdmin(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthenticationMode(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecretKey(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setClientNotice(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRoleId(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoleName(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientLink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.Administrator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.Administrator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.Administrator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.Administrator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsrId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDefaultAccess();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMobileNo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getModifiedBy();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUserType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAdmin();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAuthenticationMode();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSecretKey();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getClientNotice();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getRoleId();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
  f = message.getRoleName();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getClientLink();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional uint32 usr_id = 1;
 * @return {number}
 */
proto.usrpb.Administrator.prototype.getUsrId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.Administrator} returns this
 */
proto.usrpb.Administrator.prototype.setUsrId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.usrpb.Administrator.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.usrpb.Administrator} returns this
*/
proto.usrpb.Administrator.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.Administrator} returns this
 */
proto.usrpb.Administrator.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.Administrator.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.usrpb.Administrator.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.Administrator} returns this
 */
proto.usrpb.Administrator.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.usrpb.Administrator.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.Administrator} returns this
 */
proto.usrpb.Administrator.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string password = 5;
 * @return {string}
 */
proto.usrpb.Administrator.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.Administrator} returns this
 */
proto.usrpb.Administrator.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string default_access = 6;
 * @return {string}
 */
proto.usrpb.Administrator.prototype.getDefaultAccess = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.Administrator} returns this
 */
proto.usrpb.Administrator.prototype.setDefaultAccess = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string mobile_no = 7;
 * @return {string}
 */
proto.usrpb.Administrator.prototype.getMobileNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.Administrator} returns this
 */
proto.usrpb.Administrator.prototype.setMobileNo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string modified_by = 8;
 * @return {string}
 */
proto.usrpb.Administrator.prototype.getModifiedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.Administrator} returns this
 */
proto.usrpb.Administrator.prototype.setModifiedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string user_type = 9;
 * @return {string}
 */
proto.usrpb.Administrator.prototype.getUserType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.Administrator} returns this
 */
proto.usrpb.Administrator.prototype.setUserType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string status = 10;
 * @return {string}
 */
proto.usrpb.Administrator.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.Administrator} returns this
 */
proto.usrpb.Administrator.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool admin = 11;
 * @return {boolean}
 */
proto.usrpb.Administrator.prototype.getAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.usrpb.Administrator} returns this
 */
proto.usrpb.Administrator.prototype.setAdmin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string correspondent = 12;
 * @return {string}
 */
proto.usrpb.Administrator.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.Administrator} returns this
 */
proto.usrpb.Administrator.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string authentication_mode = 13;
 * @return {string}
 */
proto.usrpb.Administrator.prototype.getAuthenticationMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.Administrator} returns this
 */
proto.usrpb.Administrator.prototype.setAuthenticationMode = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string secret_key = 14;
 * @return {string}
 */
proto.usrpb.Administrator.prototype.getSecretKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.Administrator} returns this
 */
proto.usrpb.Administrator.prototype.setSecretKey = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool client_notice = 15;
 * @return {boolean}
 */
proto.usrpb.Administrator.prototype.getClientNotice = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.usrpb.Administrator} returns this
 */
proto.usrpb.Administrator.prototype.setClientNotice = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional uint32 account_id = 16;
 * @return {number}
 */
proto.usrpb.Administrator.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.Administrator} returns this
 */
proto.usrpb.Administrator.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional uint32 role_id = 17;
 * @return {number}
 */
proto.usrpb.Administrator.prototype.getRoleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.Administrator} returns this
 */
proto.usrpb.Administrator.prototype.setRoleId = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string role_name = 18;
 * @return {string}
 */
proto.usrpb.Administrator.prototype.getRoleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.Administrator} returns this
 */
proto.usrpb.Administrator.prototype.setRoleName = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string client_link = 19;
 * @return {string}
 */
proto.usrpb.Administrator.prototype.getClientLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.Administrator} returns this
 */
proto.usrpb.Administrator.prototype.setClientLink = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.ListAdministratorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.ListAdministratorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.ListAdministratorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ListAdministratorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, ""),
    roleId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    userRoleId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    pagination: (f = msg.getPagination()) && proto_utilspb_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.ListAdministratorRequest}
 */
proto.usrpb.ListAdministratorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.ListAdministratorRequest;
  return proto.usrpb.ListAdministratorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.ListAdministratorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.ListAdministratorRequest}
 */
proto.usrpb.ListAdministratorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRoleId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserRoleId(value);
      break;
    case 7:
      var value = new proto_utilspb_pagination_pb.Pagination;
      reader.readMessage(value,proto_utilspb_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.ListAdministratorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.ListAdministratorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.ListAdministratorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ListAdministratorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRoleId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getUserRoleId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_utilspb_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.usrpb.ListAdministratorRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.ListAdministratorRequest} returns this
 */
proto.usrpb.ListAdministratorRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.usrpb.ListAdministratorRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.ListAdministratorRequest} returns this
 */
proto.usrpb.ListAdministratorRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_type = 3;
 * @return {string}
 */
proto.usrpb.ListAdministratorRequest.prototype.getUserType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.ListAdministratorRequest} returns this
 */
proto.usrpb.ListAdministratorRequest.prototype.setUserType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.usrpb.ListAdministratorRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.ListAdministratorRequest} returns this
 */
proto.usrpb.ListAdministratorRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 role_id = 5;
 * @return {number}
 */
proto.usrpb.ListAdministratorRequest.prototype.getRoleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.ListAdministratorRequest} returns this
 */
proto.usrpb.ListAdministratorRequest.prototype.setRoleId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 user_role_id = 6;
 * @return {number}
 */
proto.usrpb.ListAdministratorRequest.prototype.getUserRoleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.ListAdministratorRequest} returns this
 */
proto.usrpb.ListAdministratorRequest.prototype.setUserRoleId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional utilspb.Pagination pagination = 7;
 * @return {?proto.utilspb.Pagination}
 */
proto.usrpb.ListAdministratorRequest.prototype.getPagination = function() {
  return /** @type{?proto.utilspb.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_utilspb_pagination_pb.Pagination, 7));
};


/**
 * @param {?proto.utilspb.Pagination|undefined} value
 * @return {!proto.usrpb.ListAdministratorRequest} returns this
*/
proto.usrpb.ListAdministratorRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.ListAdministratorRequest} returns this
 */
proto.usrpb.ListAdministratorRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.ListAdministratorRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.usrpb.ListAdministratorResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.ListAdministratorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.ListAdministratorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.ListAdministratorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ListAdministratorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    administratorsList: jspb.Message.toObjectList(msg.getAdministratorsList(),
    proto.usrpb.Administrator.toObject, includeInstance),
    summary: (f = msg.getSummary()) && proto.usrpb.ListAdministratorSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.ListAdministratorResponse}
 */
proto.usrpb.ListAdministratorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.ListAdministratorResponse;
  return proto.usrpb.ListAdministratorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.ListAdministratorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.ListAdministratorResponse}
 */
proto.usrpb.ListAdministratorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.usrpb.Administrator;
      reader.readMessage(value,proto.usrpb.Administrator.deserializeBinaryFromReader);
      msg.addAdministrators(value);
      break;
    case 2:
      var value = new proto.usrpb.ListAdministratorSummary;
      reader.readMessage(value,proto.usrpb.ListAdministratorSummary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.ListAdministratorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.ListAdministratorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.ListAdministratorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ListAdministratorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdministratorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.usrpb.Administrator.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.usrpb.ListAdministratorSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Administrator administrators = 1;
 * @return {!Array<!proto.usrpb.Administrator>}
 */
proto.usrpb.ListAdministratorResponse.prototype.getAdministratorsList = function() {
  return /** @type{!Array<!proto.usrpb.Administrator>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.usrpb.Administrator, 1));
};


/**
 * @param {!Array<!proto.usrpb.Administrator>} value
 * @return {!proto.usrpb.ListAdministratorResponse} returns this
*/
proto.usrpb.ListAdministratorResponse.prototype.setAdministratorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.usrpb.Administrator=} opt_value
 * @param {number=} opt_index
 * @return {!proto.usrpb.Administrator}
 */
proto.usrpb.ListAdministratorResponse.prototype.addAdministrators = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.usrpb.Administrator, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.usrpb.ListAdministratorResponse} returns this
 */
proto.usrpb.ListAdministratorResponse.prototype.clearAdministratorsList = function() {
  return this.setAdministratorsList([]);
};


/**
 * optional ListAdministratorSummary summary = 2;
 * @return {?proto.usrpb.ListAdministratorSummary}
 */
proto.usrpb.ListAdministratorResponse.prototype.getSummary = function() {
  return /** @type{?proto.usrpb.ListAdministratorSummary} */ (
    jspb.Message.getWrapperField(this, proto.usrpb.ListAdministratorSummary, 2));
};


/**
 * @param {?proto.usrpb.ListAdministratorSummary|undefined} value
 * @return {!proto.usrpb.ListAdministratorResponse} returns this
*/
proto.usrpb.ListAdministratorResponse.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.ListAdministratorResponse} returns this
 */
proto.usrpb.ListAdministratorResponse.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.ListAdministratorResponse.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.ListAdministratorSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.ListAdministratorSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.ListAdministratorSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ListAdministratorSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalRows: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.ListAdministratorSummary}
 */
proto.usrpb.ListAdministratorSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.ListAdministratorSummary;
  return proto.usrpb.ListAdministratorSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.ListAdministratorSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.ListAdministratorSummary}
 */
proto.usrpb.ListAdministratorSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.ListAdministratorSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.ListAdministratorSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.ListAdministratorSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ListAdministratorSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalRows();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 total_rows = 1;
 * @return {number}
 */
proto.usrpb.ListAdministratorSummary.prototype.getTotalRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.ListAdministratorSummary} returns this
 */
proto.usrpb.ListAdministratorSummary.prototype.setTotalRows = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.CreateAdministratorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.CreateAdministratorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.CreateAdministratorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.CreateAdministratorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    administrator: (f = msg.getAdministrator()) && proto.usrpb.Administrator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.CreateAdministratorResponse}
 */
proto.usrpb.CreateAdministratorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.CreateAdministratorResponse;
  return proto.usrpb.CreateAdministratorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.CreateAdministratorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.CreateAdministratorResponse}
 */
proto.usrpb.CreateAdministratorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.usrpb.Administrator;
      reader.readMessage(value,proto.usrpb.Administrator.deserializeBinaryFromReader);
      msg.setAdministrator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.CreateAdministratorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.CreateAdministratorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.CreateAdministratorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.CreateAdministratorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdministrator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.usrpb.Administrator.serializeBinaryToWriter
    );
  }
};


/**
 * optional Administrator administrator = 1;
 * @return {?proto.usrpb.Administrator}
 */
proto.usrpb.CreateAdministratorResponse.prototype.getAdministrator = function() {
  return /** @type{?proto.usrpb.Administrator} */ (
    jspb.Message.getWrapperField(this, proto.usrpb.Administrator, 1));
};


/**
 * @param {?proto.usrpb.Administrator|undefined} value
 * @return {!proto.usrpb.CreateAdministratorResponse} returns this
*/
proto.usrpb.CreateAdministratorResponse.prototype.setAdministrator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateAdministratorResponse} returns this
 */
proto.usrpb.CreateAdministratorResponse.prototype.clearAdministrator = function() {
  return this.setAdministrator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateAdministratorResponse.prototype.hasAdministrator = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.UpdateAdministratorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.UpdateAdministratorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.UpdateAdministratorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.UpdateAdministratorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    administrator: (f = msg.getAdministrator()) && proto.usrpb.Administrator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.UpdateAdministratorResponse}
 */
proto.usrpb.UpdateAdministratorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.UpdateAdministratorResponse;
  return proto.usrpb.UpdateAdministratorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.UpdateAdministratorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.UpdateAdministratorResponse}
 */
proto.usrpb.UpdateAdministratorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.usrpb.Administrator;
      reader.readMessage(value,proto.usrpb.Administrator.deserializeBinaryFromReader);
      msg.setAdministrator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.UpdateAdministratorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.UpdateAdministratorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.UpdateAdministratorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.UpdateAdministratorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdministrator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.usrpb.Administrator.serializeBinaryToWriter
    );
  }
};


/**
 * optional Administrator administrator = 1;
 * @return {?proto.usrpb.Administrator}
 */
proto.usrpb.UpdateAdministratorResponse.prototype.getAdministrator = function() {
  return /** @type{?proto.usrpb.Administrator} */ (
    jspb.Message.getWrapperField(this, proto.usrpb.Administrator, 1));
};


/**
 * @param {?proto.usrpb.Administrator|undefined} value
 * @return {!proto.usrpb.UpdateAdministratorResponse} returns this
*/
proto.usrpb.UpdateAdministratorResponse.prototype.setAdministrator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.UpdateAdministratorResponse} returns this
 */
proto.usrpb.UpdateAdministratorResponse.prototype.clearAdministrator = function() {
  return this.setAdministrator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.UpdateAdministratorResponse.prototype.hasAdministrator = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.ReadAdministratorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.ReadAdministratorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.ReadAdministratorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ReadAdministratorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    usrId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.ReadAdministratorRequest}
 */
proto.usrpb.ReadAdministratorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.ReadAdministratorRequest;
  return proto.usrpb.ReadAdministratorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.ReadAdministratorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.ReadAdministratorRequest}
 */
proto.usrpb.ReadAdministratorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUsrId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.ReadAdministratorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.ReadAdministratorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.ReadAdministratorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ReadAdministratorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsrId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 usr_id = 1;
 * @return {number}
 */
proto.usrpb.ReadAdministratorRequest.prototype.getUsrId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.ReadAdministratorRequest} returns this
 */
proto.usrpb.ReadAdministratorRequest.prototype.setUsrId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.ReadAdministratorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.ReadAdministratorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.ReadAdministratorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ReadAdministratorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    administrator: (f = msg.getAdministrator()) && proto.usrpb.Administrator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.ReadAdministratorResponse}
 */
proto.usrpb.ReadAdministratorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.ReadAdministratorResponse;
  return proto.usrpb.ReadAdministratorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.ReadAdministratorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.ReadAdministratorResponse}
 */
proto.usrpb.ReadAdministratorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.usrpb.Administrator;
      reader.readMessage(value,proto.usrpb.Administrator.deserializeBinaryFromReader);
      msg.setAdministrator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.ReadAdministratorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.ReadAdministratorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.ReadAdministratorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ReadAdministratorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdministrator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.usrpb.Administrator.serializeBinaryToWriter
    );
  }
};


/**
 * optional Administrator administrator = 1;
 * @return {?proto.usrpb.Administrator}
 */
proto.usrpb.ReadAdministratorResponse.prototype.getAdministrator = function() {
  return /** @type{?proto.usrpb.Administrator} */ (
    jspb.Message.getWrapperField(this, proto.usrpb.Administrator, 1));
};


/**
 * @param {?proto.usrpb.Administrator|undefined} value
 * @return {!proto.usrpb.ReadAdministratorResponse} returns this
*/
proto.usrpb.ReadAdministratorResponse.prototype.setAdministrator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.ReadAdministratorResponse} returns this
 */
proto.usrpb.ReadAdministratorResponse.prototype.clearAdministrator = function() {
  return this.setAdministrator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.ReadAdministratorResponse.prototype.hasAdministrator = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.ChangePasswordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.ChangePasswordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.ChangePasswordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ChangePasswordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    usrId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    oldPassword: jspb.Message.getFieldWithDefault(msg, 2, ""),
    newPassword: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.ChangePasswordRequest}
 */
proto.usrpb.ChangePasswordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.ChangePasswordRequest;
  return proto.usrpb.ChangePasswordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.ChangePasswordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.ChangePasswordRequest}
 */
proto.usrpb.ChangePasswordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUsrId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.ChangePasswordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.ChangePasswordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.ChangePasswordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ChangePasswordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsrId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getOldPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNewPassword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 usr_id = 1;
 * @return {number}
 */
proto.usrpb.ChangePasswordRequest.prototype.getUsrId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.ChangePasswordRequest} returns this
 */
proto.usrpb.ChangePasswordRequest.prototype.setUsrId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string old_password = 2;
 * @return {string}
 */
proto.usrpb.ChangePasswordRequest.prototype.getOldPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.ChangePasswordRequest} returns this
 */
proto.usrpb.ChangePasswordRequest.prototype.setOldPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string new_password = 3;
 * @return {string}
 */
proto.usrpb.ChangePasswordRequest.prototype.getNewPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.ChangePasswordRequest} returns this
 */
proto.usrpb.ChangePasswordRequest.prototype.setNewPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.ChangePasswordResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.ChangePasswordResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.ChangePasswordResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ChangePasswordResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    msg: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.ChangePasswordResponse}
 */
proto.usrpb.ChangePasswordResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.ChangePasswordResponse;
  return proto.usrpb.ChangePasswordResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.ChangePasswordResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.ChangePasswordResponse}
 */
proto.usrpb.ChangePasswordResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMsg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.ChangePasswordResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.ChangePasswordResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.ChangePasswordResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ChangePasswordResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMsg();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.usrpb.ChangePasswordResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.ChangePasswordResponse} returns this
 */
proto.usrpb.ChangePasswordResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string msg = 2;
 * @return {string}
 */
proto.usrpb.ChangePasswordResponse.prototype.getMsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.ChangePasswordResponse} returns this
 */
proto.usrpb.ChangePasswordResponse.prototype.setMsg = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.Access.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.Access.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.Access} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.Access.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    usrId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    access: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subAccess: jspb.Message.getFieldWithDefault(msg, 4, ""),
    roleId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.Access}
 */
proto.usrpb.Access.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.Access;
  return proto.usrpb.Access.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.Access} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.Access}
 */
proto.usrpb.Access.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccessId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUsrId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccess(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubAccess(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRoleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.Access.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.Access.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.Access} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.Access.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUsrId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getAccess();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubAccess();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRoleId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional uint32 access_id = 1;
 * @return {number}
 */
proto.usrpb.Access.prototype.getAccessId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.Access} returns this
 */
proto.usrpb.Access.prototype.setAccessId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 usr_id = 2;
 * @return {number}
 */
proto.usrpb.Access.prototype.getUsrId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.Access} returns this
 */
proto.usrpb.Access.prototype.setUsrId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string access = 3;
 * @return {string}
 */
proto.usrpb.Access.prototype.getAccess = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.Access} returns this
 */
proto.usrpb.Access.prototype.setAccess = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sub_access = 4;
 * @return {string}
 */
proto.usrpb.Access.prototype.getSubAccess = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.Access} returns this
 */
proto.usrpb.Access.prototype.setSubAccess = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 role_id = 5;
 * @return {number}
 */
proto.usrpb.Access.prototype.getRoleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.Access} returns this
 */
proto.usrpb.Access.prototype.setRoleId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.ConfirmationEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.ConfirmationEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.ConfirmationEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ConfirmationEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    url: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.ConfirmationEmailRequest}
 */
proto.usrpb.ConfirmationEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.ConfirmationEmailRequest;
  return proto.usrpb.ConfirmationEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.ConfirmationEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.ConfirmationEmailRequest}
 */
proto.usrpb.ConfirmationEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.ConfirmationEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.ConfirmationEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.ConfirmationEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ConfirmationEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.usrpb.ConfirmationEmailRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.ConfirmationEmailRequest} returns this
 */
proto.usrpb.ConfirmationEmailRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.usrpb.ConfirmationEmailRequest.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.ConfirmationEmailRequest} returns this
 */
proto.usrpb.ConfirmationEmailRequest.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.ConfirmationEmailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.ConfirmationEmailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.ConfirmationEmailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ConfirmationEmailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.ConfirmationEmailResponse}
 */
proto.usrpb.ConfirmationEmailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.ConfirmationEmailResponse;
  return proto.usrpb.ConfirmationEmailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.ConfirmationEmailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.ConfirmationEmailResponse}
 */
proto.usrpb.ConfirmationEmailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.ConfirmationEmailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.ConfirmationEmailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.ConfirmationEmailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ConfirmationEmailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.usrpb.ConfirmationEmailResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.usrpb.ConfirmationEmailResponse} returns this
 */
proto.usrpb.ConfirmationEmailResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.CheckEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.CheckEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.CheckEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.CheckEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    url: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.CheckEmailRequest}
 */
proto.usrpb.CheckEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.CheckEmailRequest;
  return proto.usrpb.CheckEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.CheckEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.CheckEmailRequest}
 */
proto.usrpb.CheckEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.CheckEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.CheckEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.CheckEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.CheckEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.usrpb.CheckEmailRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.CheckEmailRequest} returns this
 */
proto.usrpb.CheckEmailRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.usrpb.CheckEmailRequest.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.CheckEmailRequest} returns this
 */
proto.usrpb.CheckEmailRequest.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.CheckEmailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.CheckEmailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.CheckEmailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.CheckEmailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isExists: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.CheckEmailResponse}
 */
proto.usrpb.CheckEmailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.CheckEmailResponse;
  return proto.usrpb.CheckEmailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.CheckEmailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.CheckEmailResponse}
 */
proto.usrpb.CheckEmailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExists(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.CheckEmailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.CheckEmailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.CheckEmailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.CheckEmailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsExists();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_exists = 1;
 * @return {boolean}
 */
proto.usrpb.CheckEmailResponse.prototype.getIsExists = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.usrpb.CheckEmailResponse} returns this
 */
proto.usrpb.CheckEmailResponse.prototype.setIsExists = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.UserAccountAccess.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.UserAccountAccess.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.UserAccountAccess} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.UserAccountAccess.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.UserAccountAccess}
 */
proto.usrpb.UserAccountAccess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.UserAccountAccess;
  return proto.usrpb.UserAccountAccess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.UserAccountAccess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.UserAccountAccess}
 */
proto.usrpb.UserAccountAccess.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.UserAccountAccess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.UserAccountAccess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.UserAccountAccess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.UserAccountAccess.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.usrpb.UserAccountAccess.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.UserAccountAccess} returns this
 */
proto.usrpb.UserAccountAccess.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.usrpb.UserAccountAccess.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.UserAccountAccess} returns this
 */
proto.usrpb.UserAccountAccess.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.usrpb.ListUserAccountAccessResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.ListUserAccountAccessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.ListUserAccountAccessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.ListUserAccountAccessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ListUserAccountAccessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountsList: jspb.Message.toObjectList(msg.getAccountsList(),
    proto.usrpb.UserAccountAccess.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.ListUserAccountAccessResponse}
 */
proto.usrpb.ListUserAccountAccessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.ListUserAccountAccessResponse;
  return proto.usrpb.ListUserAccountAccessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.ListUserAccountAccessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.ListUserAccountAccessResponse}
 */
proto.usrpb.ListUserAccountAccessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.usrpb.UserAccountAccess;
      reader.readMessage(value,proto.usrpb.UserAccountAccess.deserializeBinaryFromReader);
      msg.addAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.ListUserAccountAccessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.ListUserAccountAccessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.ListUserAccountAccessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ListUserAccountAccessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.usrpb.UserAccountAccess.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserAccountAccess accounts = 1;
 * @return {!Array<!proto.usrpb.UserAccountAccess>}
 */
proto.usrpb.ListUserAccountAccessResponse.prototype.getAccountsList = function() {
  return /** @type{!Array<!proto.usrpb.UserAccountAccess>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.usrpb.UserAccountAccess, 1));
};


/**
 * @param {!Array<!proto.usrpb.UserAccountAccess>} value
 * @return {!proto.usrpb.ListUserAccountAccessResponse} returns this
*/
proto.usrpb.ListUserAccountAccessResponse.prototype.setAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.usrpb.UserAccountAccess=} opt_value
 * @param {number=} opt_index
 * @return {!proto.usrpb.UserAccountAccess}
 */
proto.usrpb.ListUserAccountAccessResponse.prototype.addAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.usrpb.UserAccountAccess, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.usrpb.ListUserAccountAccessResponse} returns this
 */
proto.usrpb.ListUserAccountAccessResponse.prototype.clearAccountsList = function() {
  return this.setAccountsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.ListUserAccountAccessRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.ListUserAccountAccessRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.ListUserAccountAccessRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ListUserAccountAccessRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.ListUserAccountAccessRequest}
 */
proto.usrpb.ListUserAccountAccessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.ListUserAccountAccessRequest;
  return proto.usrpb.ListUserAccountAccessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.ListUserAccountAccessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.ListUserAccountAccessRequest}
 */
proto.usrpb.ListUserAccountAccessRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.ListUserAccountAccessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.ListUserAccountAccessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.ListUserAccountAccessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ListUserAccountAccessRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 user_id = 1;
 * @return {number}
 */
proto.usrpb.ListUserAccountAccessRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.ListUserAccountAccessRequest} returns this
 */
proto.usrpb.ListUserAccountAccessRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.SetKeyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.SetKeyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.SetKeyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.SetKeyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    usrId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    secretKey: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.SetKeyRequest}
 */
proto.usrpb.SetKeyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.SetKeyRequest;
  return proto.usrpb.SetKeyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.SetKeyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.SetKeyRequest}
 */
proto.usrpb.SetKeyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUsrId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecretKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.SetKeyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.SetKeyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.SetKeyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.SetKeyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsrId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSecretKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 usr_id = 1;
 * @return {number}
 */
proto.usrpb.SetKeyRequest.prototype.getUsrId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.SetKeyRequest} returns this
 */
proto.usrpb.SetKeyRequest.prototype.setUsrId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.usrpb.SetKeyRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.SetKeyRequest} returns this
 */
proto.usrpb.SetKeyRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string secret_key = 3;
 * @return {string}
 */
proto.usrpb.SetKeyRequest.prototype.getSecretKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.SetKeyRequest} returns this
 */
proto.usrpb.SetKeyRequest.prototype.setSecretKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.AcceptClientNoticeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.AcceptClientNoticeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.AcceptClientNoticeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.AcceptClientNoticeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    usrId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.AcceptClientNoticeRequest}
 */
proto.usrpb.AcceptClientNoticeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.AcceptClientNoticeRequest;
  return proto.usrpb.AcceptClientNoticeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.AcceptClientNoticeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.AcceptClientNoticeRequest}
 */
proto.usrpb.AcceptClientNoticeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUsrId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.AcceptClientNoticeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.AcceptClientNoticeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.AcceptClientNoticeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.AcceptClientNoticeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsrId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 usr_id = 1;
 * @return {number}
 */
proto.usrpb.AcceptClientNoticeRequest.prototype.getUsrId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.AcceptClientNoticeRequest} returns this
 */
proto.usrpb.AcceptClientNoticeRequest.prototype.setUsrId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.EmptyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.EmptyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.EmptyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.EmptyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.EmptyResponse}
 */
proto.usrpb.EmptyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.EmptyResponse;
  return proto.usrpb.EmptyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.EmptyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.EmptyResponse}
 */
proto.usrpb.EmptyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.EmptyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.EmptyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.EmptyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.EmptyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.HasSecurityWordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.HasSecurityWordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.HasSecurityWordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.HasSecurityWordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    usrId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.HasSecurityWordRequest}
 */
proto.usrpb.HasSecurityWordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.HasSecurityWordRequest;
  return proto.usrpb.HasSecurityWordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.HasSecurityWordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.HasSecurityWordRequest}
 */
proto.usrpb.HasSecurityWordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUsrId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.HasSecurityWordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.HasSecurityWordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.HasSecurityWordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.HasSecurityWordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsrId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 usr_id = 1;
 * @return {number}
 */
proto.usrpb.HasSecurityWordRequest.prototype.getUsrId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.HasSecurityWordRequest} returns this
 */
proto.usrpb.HasSecurityWordRequest.prototype.setUsrId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.HasSecurityWordResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.HasSecurityWordResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.HasSecurityWordResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.HasSecurityWordResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hasSecurityWord: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.HasSecurityWordResponse}
 */
proto.usrpb.HasSecurityWordResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.HasSecurityWordResponse;
  return proto.usrpb.HasSecurityWordResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.HasSecurityWordResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.HasSecurityWordResponse}
 */
proto.usrpb.HasSecurityWordResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasSecurityWord(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.HasSecurityWordResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.HasSecurityWordResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.HasSecurityWordResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.HasSecurityWordResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasSecurityWord();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool has_security_word = 1;
 * @return {boolean}
 */
proto.usrpb.HasSecurityWordResponse.prototype.getHasSecurityWord = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.usrpb.HasSecurityWordResponse} returns this
 */
proto.usrpb.HasSecurityWordResponse.prototype.setHasSecurityWord = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.DeleteAdministratorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.DeleteAdministratorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.DeleteAdministratorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.DeleteAdministratorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.DeleteAdministratorRequest}
 */
proto.usrpb.DeleteAdministratorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.DeleteAdministratorRequest;
  return proto.usrpb.DeleteAdministratorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.DeleteAdministratorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.DeleteAdministratorRequest}
 */
proto.usrpb.DeleteAdministratorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.DeleteAdministratorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.DeleteAdministratorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.DeleteAdministratorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.DeleteAdministratorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 user_id = 1;
 * @return {number}
 */
proto.usrpb.DeleteAdministratorRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.DeleteAdministratorRequest} returns this
 */
proto.usrpb.DeleteAdministratorRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.DeleteAdministratorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.DeleteAdministratorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.DeleteAdministratorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.DeleteAdministratorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    administrator: (f = msg.getAdministrator()) && proto.usrpb.Administrator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.DeleteAdministratorResponse}
 */
proto.usrpb.DeleteAdministratorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.DeleteAdministratorResponse;
  return proto.usrpb.DeleteAdministratorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.DeleteAdministratorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.DeleteAdministratorResponse}
 */
proto.usrpb.DeleteAdministratorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.usrpb.Administrator;
      reader.readMessage(value,proto.usrpb.Administrator.deserializeBinaryFromReader);
      msg.setAdministrator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.DeleteAdministratorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.DeleteAdministratorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.DeleteAdministratorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.DeleteAdministratorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdministrator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.usrpb.Administrator.serializeBinaryToWriter
    );
  }
};


/**
 * optional Administrator administrator = 1;
 * @return {?proto.usrpb.Administrator}
 */
proto.usrpb.DeleteAdministratorResponse.prototype.getAdministrator = function() {
  return /** @type{?proto.usrpb.Administrator} */ (
    jspb.Message.getWrapperField(this, proto.usrpb.Administrator, 1));
};


/**
 * @param {?proto.usrpb.Administrator|undefined} value
 * @return {!proto.usrpb.DeleteAdministratorResponse} returns this
*/
proto.usrpb.DeleteAdministratorResponse.prototype.setAdministrator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.DeleteAdministratorResponse} returns this
 */
proto.usrpb.DeleteAdministratorResponse.prototype.clearAdministrator = function() {
  return this.setAdministrator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.DeleteAdministratorResponse.prototype.hasAdministrator = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.EmptyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.EmptyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.EmptyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.EmptyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.EmptyRequest}
 */
proto.usrpb.EmptyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.EmptyRequest;
  return proto.usrpb.EmptyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.EmptyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.EmptyRequest}
 */
proto.usrpb.EmptyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.EmptyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.EmptyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.EmptyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.EmptyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.usrpb.ListDefaultAccessOptionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.ListDefaultAccessOptionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.ListDefaultAccessOptionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.ListDefaultAccessOptionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ListDefaultAccessOptionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    defaultAccessOptionsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.ListDefaultAccessOptionResponse}
 */
proto.usrpb.ListDefaultAccessOptionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.ListDefaultAccessOptionResponse;
  return proto.usrpb.ListDefaultAccessOptionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.ListDefaultAccessOptionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.ListDefaultAccessOptionResponse}
 */
proto.usrpb.ListDefaultAccessOptionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addDefaultAccessOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.ListDefaultAccessOptionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.ListDefaultAccessOptionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.ListDefaultAccessOptionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ListDefaultAccessOptionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDefaultAccessOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string default_access_options = 1;
 * @return {!Array<string>}
 */
proto.usrpb.ListDefaultAccessOptionResponse.prototype.getDefaultAccessOptionsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.usrpb.ListDefaultAccessOptionResponse} returns this
 */
proto.usrpb.ListDefaultAccessOptionResponse.prototype.setDefaultAccessOptionsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.usrpb.ListDefaultAccessOptionResponse} returns this
 */
proto.usrpb.ListDefaultAccessOptionResponse.prototype.addDefaultAccessOptions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.usrpb.ListDefaultAccessOptionResponse} returns this
 */
proto.usrpb.ListDefaultAccessOptionResponse.prototype.clearDefaultAccessOptionsList = function() {
  return this.setDefaultAccessOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.CreateAccessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.CreateAccessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.CreateAccessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.CreateAccessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    access: (f = msg.getAccess()) && proto.usrpb.Access.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.CreateAccessResponse}
 */
proto.usrpb.CreateAccessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.CreateAccessResponse;
  return proto.usrpb.CreateAccessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.CreateAccessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.CreateAccessResponse}
 */
proto.usrpb.CreateAccessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.usrpb.Access;
      reader.readMessage(value,proto.usrpb.Access.deserializeBinaryFromReader);
      msg.setAccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.CreateAccessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.CreateAccessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.CreateAccessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.CreateAccessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.usrpb.Access.serializeBinaryToWriter
    );
  }
};


/**
 * optional Access access = 1;
 * @return {?proto.usrpb.Access}
 */
proto.usrpb.CreateAccessResponse.prototype.getAccess = function() {
  return /** @type{?proto.usrpb.Access} */ (
    jspb.Message.getWrapperField(this, proto.usrpb.Access, 1));
};


/**
 * @param {?proto.usrpb.Access|undefined} value
 * @return {!proto.usrpb.CreateAccessResponse} returns this
*/
proto.usrpb.CreateAccessResponse.prototype.setAccess = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateAccessResponse} returns this
 */
proto.usrpb.CreateAccessResponse.prototype.clearAccess = function() {
  return this.setAccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateAccessResponse.prototype.hasAccess = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.UpdateAccessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.UpdateAccessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.UpdateAccessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.UpdateAccessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    access: (f = msg.getAccess()) && proto.usrpb.Access.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.UpdateAccessResponse}
 */
proto.usrpb.UpdateAccessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.UpdateAccessResponse;
  return proto.usrpb.UpdateAccessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.UpdateAccessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.UpdateAccessResponse}
 */
proto.usrpb.UpdateAccessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.usrpb.Access;
      reader.readMessage(value,proto.usrpb.Access.deserializeBinaryFromReader);
      msg.setAccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.UpdateAccessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.UpdateAccessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.UpdateAccessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.UpdateAccessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.usrpb.Access.serializeBinaryToWriter
    );
  }
};


/**
 * optional Access access = 1;
 * @return {?proto.usrpb.Access}
 */
proto.usrpb.UpdateAccessResponse.prototype.getAccess = function() {
  return /** @type{?proto.usrpb.Access} */ (
    jspb.Message.getWrapperField(this, proto.usrpb.Access, 1));
};


/**
 * @param {?proto.usrpb.Access|undefined} value
 * @return {!proto.usrpb.UpdateAccessResponse} returns this
*/
proto.usrpb.UpdateAccessResponse.prototype.setAccess = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.UpdateAccessResponse} returns this
 */
proto.usrpb.UpdateAccessResponse.prototype.clearAccess = function() {
  return this.setAccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.UpdateAccessResponse.prototype.hasAccess = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.DeleteAccessRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.DeleteAccessRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.DeleteAccessRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.DeleteAccessRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.DeleteAccessRequest}
 */
proto.usrpb.DeleteAccessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.DeleteAccessRequest;
  return proto.usrpb.DeleteAccessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.DeleteAccessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.DeleteAccessRequest}
 */
proto.usrpb.DeleteAccessRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccessId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.DeleteAccessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.DeleteAccessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.DeleteAccessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.DeleteAccessRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 access_id = 1;
 * @return {number}
 */
proto.usrpb.DeleteAccessRequest.prototype.getAccessId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.DeleteAccessRequest} returns this
 */
proto.usrpb.DeleteAccessRequest.prototype.setAccessId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.DeleteAccessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.DeleteAccessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.DeleteAccessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.DeleteAccessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.DeleteAccessResponse}
 */
proto.usrpb.DeleteAccessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.DeleteAccessResponse;
  return proto.usrpb.DeleteAccessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.DeleteAccessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.DeleteAccessResponse}
 */
proto.usrpb.DeleteAccessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccessId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.DeleteAccessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.DeleteAccessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.DeleteAccessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.DeleteAccessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 access_id = 1;
 * @return {number}
 */
proto.usrpb.DeleteAccessResponse.prototype.getAccessId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.DeleteAccessResponse} returns this
 */
proto.usrpb.DeleteAccessResponse.prototype.setAccessId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.HasSecurityQuestionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.HasSecurityQuestionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.HasSecurityQuestionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.HasSecurityQuestionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    usrId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.HasSecurityQuestionsRequest}
 */
proto.usrpb.HasSecurityQuestionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.HasSecurityQuestionsRequest;
  return proto.usrpb.HasSecurityQuestionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.HasSecurityQuestionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.HasSecurityQuestionsRequest}
 */
proto.usrpb.HasSecurityQuestionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUsrId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.HasSecurityQuestionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.HasSecurityQuestionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.HasSecurityQuestionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.HasSecurityQuestionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsrId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 usr_id = 1;
 * @return {number}
 */
proto.usrpb.HasSecurityQuestionsRequest.prototype.getUsrId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.HasSecurityQuestionsRequest} returns this
 */
proto.usrpb.HasSecurityQuestionsRequest.prototype.setUsrId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.HasSecurityQuestionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.HasSecurityQuestionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.HasSecurityQuestionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.HasSecurityQuestionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hasSecurityQuestions: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.HasSecurityQuestionsResponse}
 */
proto.usrpb.HasSecurityQuestionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.HasSecurityQuestionsResponse;
  return proto.usrpb.HasSecurityQuestionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.HasSecurityQuestionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.HasSecurityQuestionsResponse}
 */
proto.usrpb.HasSecurityQuestionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasSecurityQuestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.HasSecurityQuestionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.HasSecurityQuestionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.HasSecurityQuestionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.HasSecurityQuestionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHasSecurityQuestions();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool has_security_questions = 1;
 * @return {boolean}
 */
proto.usrpb.HasSecurityQuestionsResponse.prototype.getHasSecurityQuestions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.usrpb.HasSecurityQuestionsResponse} returns this
 */
proto.usrpb.HasSecurityQuestionsResponse.prototype.setHasSecurityQuestions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.SecurityQuestionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.SecurityQuestionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.SecurityQuestionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.SecurityQuestionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.SecurityQuestionRequest}
 */
proto.usrpb.SecurityQuestionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.SecurityQuestionRequest;
  return proto.usrpb.SecurityQuestionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.SecurityQuestionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.SecurityQuestionRequest}
 */
proto.usrpb.SecurityQuestionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.SecurityQuestionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.SecurityQuestionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.SecurityQuestionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.SecurityQuestionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.usrpb.SecurityQuestionRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.SecurityQuestionRequest} returns this
 */
proto.usrpb.SecurityQuestionRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.SecurityQuestionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.SecurityQuestionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.SecurityQuestionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.SecurityQuestionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    questionText: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.SecurityQuestionResponse}
 */
proto.usrpb.SecurityQuestionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.SecurityQuestionResponse;
  return proto.usrpb.SecurityQuestionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.SecurityQuestionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.SecurityQuestionResponse}
 */
proto.usrpb.SecurityQuestionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.SecurityQuestionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.SecurityQuestionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.SecurityQuestionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.SecurityQuestionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuestionText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string question_id = 1;
 * @return {string}
 */
proto.usrpb.SecurityQuestionResponse.prototype.getQuestionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.SecurityQuestionResponse} returns this
 */
proto.usrpb.SecurityQuestionResponse.prototype.setQuestionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string question_text = 2;
 * @return {string}
 */
proto.usrpb.SecurityQuestionResponse.prototype.getQuestionText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.SecurityQuestionResponse} returns this
 */
proto.usrpb.SecurityQuestionResponse.prototype.setQuestionText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.BrandingURLRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.BrandingURLRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.BrandingURLRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.BrandingURLRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.BrandingURLRequest}
 */
proto.usrpb.BrandingURLRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.BrandingURLRequest;
  return proto.usrpb.BrandingURLRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.BrandingURLRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.BrandingURLRequest}
 */
proto.usrpb.BrandingURLRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.BrandingURLRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.BrandingURLRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.BrandingURLRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.BrandingURLRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.usrpb.BrandingURLRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.BrandingURLRequest} returns this
 */
proto.usrpb.BrandingURLRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.BrandingURLResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.BrandingURLResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.BrandingURLResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.BrandingURLResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.BrandingURLResponse}
 */
proto.usrpb.BrandingURLResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.BrandingURLResponse;
  return proto.usrpb.BrandingURLResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.BrandingURLResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.BrandingURLResponse}
 */
proto.usrpb.BrandingURLResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.BrandingURLResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.BrandingURLResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.BrandingURLResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.BrandingURLResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.usrpb.BrandingURLResponse.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.BrandingURLResponse} returns this
 */
proto.usrpb.BrandingURLResponse.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.usrpb.CreateClientOnboardingRequest.repeatedFields_ = [17,18,19,20,21,22];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.CreateClientOnboardingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.CreateClientOnboardingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.CreateClientOnboardingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    generateAccountNo: (f = msg.getGenerateAccountNo()) && proto_accountpb_generateaccountno_pb.GenerateAccountNoRequest.toObject(includeInstance, f),
    administrator: (f = msg.getAdministrator()) && proto.usrpb.Administrator.toObject(includeInstance, f),
    client: (f = msg.getClient()) && proto_accountpb_client_pb.Client.toObject(includeInstance, f),
    owner: (f = msg.getOwner()) && proto_accountpb_owner_pb.Owner.toObject(includeInstance, f),
    identification: (f = msg.getIdentification()) && proto_accountpb_identification_pb.Identification.toObject(includeInstance, f),
    identificationSsn: (f = msg.getIdentificationSsn()) && proto_accountpb_identification_pb.Identification.toObject(includeInstance, f),
    identificationTax: (f = msg.getIdentificationTax()) && proto_accountpb_identification_pb.Identification.toObject(includeInstance, f),
    contactInfoEmail: (f = msg.getContactInfoEmail()) && proto_accountpb_contactinfo_pb.ContactInfo.toObject(includeInstance, f),
    contactInfoPhone: (f = msg.getContactInfoPhone()) && proto_accountpb_contactinfo_pb.ContactInfo.toObject(includeInstance, f),
    address: (f = msg.getAddress()) && proto_accountpb_address_pb.Address.toObject(includeInstance, f),
    coOwner: (f = msg.getCoOwner()) && proto_accountpb_owner_pb.Owner.toObject(includeInstance, f),
    coContactInfoEmail: (f = msg.getCoContactInfoEmail()) && proto_accountpb_contactinfo_pb.ContactInfo.toObject(includeInstance, f),
    coContactInfoPhone: (f = msg.getCoContactInfoPhone()) && proto_accountpb_contactinfo_pb.ContactInfo.toObject(includeInstance, f),
    coAddress: (f = msg.getCoAddress()) && proto_accountpb_address_pb.Address.toObject(includeInstance, f),
    poContactInfoPhone1: (f = msg.getPoContactInfoPhone1()) && proto_accountpb_contactinfo_pb.ContactInfo.toObject(includeInstance, f),
    poAddress: (f = msg.getPoAddress()) && proto_accountpb_address_pb.Address.toObject(includeInstance, f),
    poOwnerList: jspb.Message.toObjectList(msg.getPoOwnerList(),
    proto_accountpb_owner_pb.Owner.toObject, includeInstance),
    poIdentificationList: jspb.Message.toObjectList(msg.getPoIdentificationList(),
    proto_accountpb_identification_pb.Identification.toObject, includeInstance),
    poIdentificationSsnList: jspb.Message.toObjectList(msg.getPoIdentificationSsnList(),
    proto_accountpb_identification_pb.Identification.toObject, includeInstance),
    poContactInfoPhone2List: jspb.Message.toObjectList(msg.getPoContactInfoPhone2List(),
    proto_accountpb_contactinfo_pb.ContactInfo.toObject, includeInstance),
    poContactInfoEmailList: jspb.Message.toObjectList(msg.getPoContactInfoEmailList(),
    proto_accountpb_contactinfo_pb.ContactInfo.toObject, includeInstance),
    poAddress2List: jspb.Message.toObjectList(msg.getPoAddress2List(),
    proto_accountpb_address_pb.Address.toObject, includeInstance),
    tcOwner: (f = msg.getTcOwner()) && proto_accountpb_owner_pb.Owner.toObject(includeInstance, f),
    tcIdentification: (f = msg.getTcIdentification()) && proto_accountpb_identification_pb.Identification.toObject(includeInstance, f),
    tcContactInfoPhone: (f = msg.getTcContactInfoPhone()) && proto_accountpb_contactinfo_pb.ContactInfo.toObject(includeInstance, f),
    tcContactInfoEmail: (f = msg.getTcContactInfoEmail()) && proto_accountpb_contactinfo_pb.ContactInfo.toObject(includeInstance, f),
    tcAddress: (f = msg.getTcAddress()) && proto_accountpb_address_pb.Address.toObject(includeInstance, f),
    accountAccess: (f = msg.getAccountAccess()) && proto_clientpb_accountaccess_pb.AccountAccess.toObject(includeInstance, f),
    accountId: jspb.Message.getFieldWithDefault(msg, 29, 0),
    accountType: jspb.Message.getFieldWithDefault(msg, 30, ""),
    registration: (f = msg.getRegistration()) && proto_usrpb_registration_pb.Registration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.CreateClientOnboardingRequest}
 */
proto.usrpb.CreateClientOnboardingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.CreateClientOnboardingRequest;
  return proto.usrpb.CreateClientOnboardingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.CreateClientOnboardingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.CreateClientOnboardingRequest}
 */
proto.usrpb.CreateClientOnboardingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_accountpb_generateaccountno_pb.GenerateAccountNoRequest;
      reader.readMessage(value,proto_accountpb_generateaccountno_pb.GenerateAccountNoRequest.deserializeBinaryFromReader);
      msg.setGenerateAccountNo(value);
      break;
    case 2:
      var value = new proto.usrpb.Administrator;
      reader.readMessage(value,proto.usrpb.Administrator.deserializeBinaryFromReader);
      msg.setAdministrator(value);
      break;
    case 3:
      var value = new proto_accountpb_client_pb.Client;
      reader.readMessage(value,proto_accountpb_client_pb.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 4:
      var value = new proto_accountpb_owner_pb.Owner;
      reader.readMessage(value,proto_accountpb_owner_pb.Owner.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 5:
      var value = new proto_accountpb_identification_pb.Identification;
      reader.readMessage(value,proto_accountpb_identification_pb.Identification.deserializeBinaryFromReader);
      msg.setIdentification(value);
      break;
    case 6:
      var value = new proto_accountpb_identification_pb.Identification;
      reader.readMessage(value,proto_accountpb_identification_pb.Identification.deserializeBinaryFromReader);
      msg.setIdentificationSsn(value);
      break;
    case 7:
      var value = new proto_accountpb_identification_pb.Identification;
      reader.readMessage(value,proto_accountpb_identification_pb.Identification.deserializeBinaryFromReader);
      msg.setIdentificationTax(value);
      break;
    case 8:
      var value = new proto_accountpb_contactinfo_pb.ContactInfo;
      reader.readMessage(value,proto_accountpb_contactinfo_pb.ContactInfo.deserializeBinaryFromReader);
      msg.setContactInfoEmail(value);
      break;
    case 9:
      var value = new proto_accountpb_contactinfo_pb.ContactInfo;
      reader.readMessage(value,proto_accountpb_contactinfo_pb.ContactInfo.deserializeBinaryFromReader);
      msg.setContactInfoPhone(value);
      break;
    case 10:
      var value = new proto_accountpb_address_pb.Address;
      reader.readMessage(value,proto_accountpb_address_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 11:
      var value = new proto_accountpb_owner_pb.Owner;
      reader.readMessage(value,proto_accountpb_owner_pb.Owner.deserializeBinaryFromReader);
      msg.setCoOwner(value);
      break;
    case 12:
      var value = new proto_accountpb_contactinfo_pb.ContactInfo;
      reader.readMessage(value,proto_accountpb_contactinfo_pb.ContactInfo.deserializeBinaryFromReader);
      msg.setCoContactInfoEmail(value);
      break;
    case 13:
      var value = new proto_accountpb_contactinfo_pb.ContactInfo;
      reader.readMessage(value,proto_accountpb_contactinfo_pb.ContactInfo.deserializeBinaryFromReader);
      msg.setCoContactInfoPhone(value);
      break;
    case 14:
      var value = new proto_accountpb_address_pb.Address;
      reader.readMessage(value,proto_accountpb_address_pb.Address.deserializeBinaryFromReader);
      msg.setCoAddress(value);
      break;
    case 15:
      var value = new proto_accountpb_contactinfo_pb.ContactInfo;
      reader.readMessage(value,proto_accountpb_contactinfo_pb.ContactInfo.deserializeBinaryFromReader);
      msg.setPoContactInfoPhone1(value);
      break;
    case 16:
      var value = new proto_accountpb_address_pb.Address;
      reader.readMessage(value,proto_accountpb_address_pb.Address.deserializeBinaryFromReader);
      msg.setPoAddress(value);
      break;
    case 17:
      var value = new proto_accountpb_owner_pb.Owner;
      reader.readMessage(value,proto_accountpb_owner_pb.Owner.deserializeBinaryFromReader);
      msg.addPoOwner(value);
      break;
    case 18:
      var value = new proto_accountpb_identification_pb.Identification;
      reader.readMessage(value,proto_accountpb_identification_pb.Identification.deserializeBinaryFromReader);
      msg.addPoIdentification(value);
      break;
    case 19:
      var value = new proto_accountpb_identification_pb.Identification;
      reader.readMessage(value,proto_accountpb_identification_pb.Identification.deserializeBinaryFromReader);
      msg.addPoIdentificationSsn(value);
      break;
    case 20:
      var value = new proto_accountpb_contactinfo_pb.ContactInfo;
      reader.readMessage(value,proto_accountpb_contactinfo_pb.ContactInfo.deserializeBinaryFromReader);
      msg.addPoContactInfoPhone2(value);
      break;
    case 21:
      var value = new proto_accountpb_contactinfo_pb.ContactInfo;
      reader.readMessage(value,proto_accountpb_contactinfo_pb.ContactInfo.deserializeBinaryFromReader);
      msg.addPoContactInfoEmail(value);
      break;
    case 22:
      var value = new proto_accountpb_address_pb.Address;
      reader.readMessage(value,proto_accountpb_address_pb.Address.deserializeBinaryFromReader);
      msg.addPoAddress2(value);
      break;
    case 23:
      var value = new proto_accountpb_owner_pb.Owner;
      reader.readMessage(value,proto_accountpb_owner_pb.Owner.deserializeBinaryFromReader);
      msg.setTcOwner(value);
      break;
    case 24:
      var value = new proto_accountpb_identification_pb.Identification;
      reader.readMessage(value,proto_accountpb_identification_pb.Identification.deserializeBinaryFromReader);
      msg.setTcIdentification(value);
      break;
    case 25:
      var value = new proto_accountpb_contactinfo_pb.ContactInfo;
      reader.readMessage(value,proto_accountpb_contactinfo_pb.ContactInfo.deserializeBinaryFromReader);
      msg.setTcContactInfoPhone(value);
      break;
    case 26:
      var value = new proto_accountpb_contactinfo_pb.ContactInfo;
      reader.readMessage(value,proto_accountpb_contactinfo_pb.ContactInfo.deserializeBinaryFromReader);
      msg.setTcContactInfoEmail(value);
      break;
    case 27:
      var value = new proto_accountpb_address_pb.Address;
      reader.readMessage(value,proto_accountpb_address_pb.Address.deserializeBinaryFromReader);
      msg.setTcAddress(value);
      break;
    case 28:
      var value = new proto_clientpb_accountaccess_pb.AccountAccess;
      reader.readMessage(value,proto_clientpb_accountaccess_pb.AccountAccess.deserializeBinaryFromReader);
      msg.setAccountAccess(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountType(value);
      break;
    case 31:
      var value = new proto_usrpb_registration_pb.Registration;
      reader.readMessage(value,proto_usrpb_registration_pb.Registration.deserializeBinaryFromReader);
      msg.setRegistration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.CreateClientOnboardingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.CreateClientOnboardingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.CreateClientOnboardingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGenerateAccountNo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_accountpb_generateaccountno_pb.GenerateAccountNoRequest.serializeBinaryToWriter
    );
  }
  f = message.getAdministrator();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.usrpb.Administrator.serializeBinaryToWriter
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_accountpb_client_pb.Client.serializeBinaryToWriter
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto_accountpb_owner_pb.Owner.serializeBinaryToWriter
    );
  }
  f = message.getIdentification();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_accountpb_identification_pb.Identification.serializeBinaryToWriter
    );
  }
  f = message.getIdentificationSsn();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto_accountpb_identification_pb.Identification.serializeBinaryToWriter
    );
  }
  f = message.getIdentificationTax();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto_accountpb_identification_pb.Identification.serializeBinaryToWriter
    );
  }
  f = message.getContactInfoEmail();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto_accountpb_contactinfo_pb.ContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getContactInfoPhone();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto_accountpb_contactinfo_pb.ContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto_accountpb_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getCoOwner();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto_accountpb_owner_pb.Owner.serializeBinaryToWriter
    );
  }
  f = message.getCoContactInfoEmail();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_accountpb_contactinfo_pb.ContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getCoContactInfoPhone();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto_accountpb_contactinfo_pb.ContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getCoAddress();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto_accountpb_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getPoContactInfoPhone1();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto_accountpb_contactinfo_pb.ContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getPoAddress();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto_accountpb_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getPoOwnerList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto_accountpb_owner_pb.Owner.serializeBinaryToWriter
    );
  }
  f = message.getPoIdentificationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto_accountpb_identification_pb.Identification.serializeBinaryToWriter
    );
  }
  f = message.getPoIdentificationSsnList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto_accountpb_identification_pb.Identification.serializeBinaryToWriter
    );
  }
  f = message.getPoContactInfoPhone2List();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto_accountpb_contactinfo_pb.ContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getPoContactInfoEmailList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto_accountpb_contactinfo_pb.ContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getPoAddress2List();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      22,
      f,
      proto_accountpb_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getTcOwner();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto_accountpb_owner_pb.Owner.serializeBinaryToWriter
    );
  }
  f = message.getTcIdentification();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto_accountpb_identification_pb.Identification.serializeBinaryToWriter
    );
  }
  f = message.getTcContactInfoPhone();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto_accountpb_contactinfo_pb.ContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getTcContactInfoEmail();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto_accountpb_contactinfo_pb.ContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getTcAddress();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto_accountpb_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getAccountAccess();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto_clientpb_accountaccess_pb.AccountAccess.serializeBinaryToWriter
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      29,
      f
    );
  }
  f = message.getAccountType();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getRegistration();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto_usrpb_registration_pb.Registration.serializeBinaryToWriter
    );
  }
};


/**
 * optional accountpb.GenerateAccountNoRequest generate_account_no = 1;
 * @return {?proto.accountpb.GenerateAccountNoRequest}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getGenerateAccountNo = function() {
  return /** @type{?proto.accountpb.GenerateAccountNoRequest} */ (
    jspb.Message.getWrapperField(this, proto_accountpb_generateaccountno_pb.GenerateAccountNoRequest, 1));
};


/**
 * @param {?proto.accountpb.GenerateAccountNoRequest|undefined} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setGenerateAccountNo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearGenerateAccountNo = function() {
  return this.setGenerateAccountNo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.hasGenerateAccountNo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Administrator administrator = 2;
 * @return {?proto.usrpb.Administrator}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getAdministrator = function() {
  return /** @type{?proto.usrpb.Administrator} */ (
    jspb.Message.getWrapperField(this, proto.usrpb.Administrator, 2));
};


/**
 * @param {?proto.usrpb.Administrator|undefined} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setAdministrator = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearAdministrator = function() {
  return this.setAdministrator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.hasAdministrator = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional accountpb.Client client = 3;
 * @return {?proto.accountpb.Client}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getClient = function() {
  return /** @type{?proto.accountpb.Client} */ (
    jspb.Message.getWrapperField(this, proto_accountpb_client_pb.Client, 3));
};


/**
 * @param {?proto.accountpb.Client|undefined} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.hasClient = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional accountpb.Owner owner = 4;
 * @return {?proto.accountpb.Owner}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getOwner = function() {
  return /** @type{?proto.accountpb.Owner} */ (
    jspb.Message.getWrapperField(this, proto_accountpb_owner_pb.Owner, 4));
};


/**
 * @param {?proto.accountpb.Owner|undefined} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional accountpb.Identification identification = 5;
 * @return {?proto.accountpb.Identification}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getIdentification = function() {
  return /** @type{?proto.accountpb.Identification} */ (
    jspb.Message.getWrapperField(this, proto_accountpb_identification_pb.Identification, 5));
};


/**
 * @param {?proto.accountpb.Identification|undefined} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setIdentification = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearIdentification = function() {
  return this.setIdentification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.hasIdentification = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional accountpb.Identification identification_ssn = 6;
 * @return {?proto.accountpb.Identification}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getIdentificationSsn = function() {
  return /** @type{?proto.accountpb.Identification} */ (
    jspb.Message.getWrapperField(this, proto_accountpb_identification_pb.Identification, 6));
};


/**
 * @param {?proto.accountpb.Identification|undefined} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setIdentificationSsn = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearIdentificationSsn = function() {
  return this.setIdentificationSsn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.hasIdentificationSsn = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional accountpb.Identification identification_tax = 7;
 * @return {?proto.accountpb.Identification}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getIdentificationTax = function() {
  return /** @type{?proto.accountpb.Identification} */ (
    jspb.Message.getWrapperField(this, proto_accountpb_identification_pb.Identification, 7));
};


/**
 * @param {?proto.accountpb.Identification|undefined} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setIdentificationTax = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearIdentificationTax = function() {
  return this.setIdentificationTax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.hasIdentificationTax = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional accountpb.ContactInfo contact_info_email = 8;
 * @return {?proto.accountpb.ContactInfo}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getContactInfoEmail = function() {
  return /** @type{?proto.accountpb.ContactInfo} */ (
    jspb.Message.getWrapperField(this, proto_accountpb_contactinfo_pb.ContactInfo, 8));
};


/**
 * @param {?proto.accountpb.ContactInfo|undefined} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setContactInfoEmail = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearContactInfoEmail = function() {
  return this.setContactInfoEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.hasContactInfoEmail = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional accountpb.ContactInfo contact_info_phone = 9;
 * @return {?proto.accountpb.ContactInfo}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getContactInfoPhone = function() {
  return /** @type{?proto.accountpb.ContactInfo} */ (
    jspb.Message.getWrapperField(this, proto_accountpb_contactinfo_pb.ContactInfo, 9));
};


/**
 * @param {?proto.accountpb.ContactInfo|undefined} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setContactInfoPhone = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearContactInfoPhone = function() {
  return this.setContactInfoPhone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.hasContactInfoPhone = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional accountpb.Address address = 10;
 * @return {?proto.accountpb.Address}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getAddress = function() {
  return /** @type{?proto.accountpb.Address} */ (
    jspb.Message.getWrapperField(this, proto_accountpb_address_pb.Address, 10));
};


/**
 * @param {?proto.accountpb.Address|undefined} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional accountpb.Owner co_owner = 11;
 * @return {?proto.accountpb.Owner}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getCoOwner = function() {
  return /** @type{?proto.accountpb.Owner} */ (
    jspb.Message.getWrapperField(this, proto_accountpb_owner_pb.Owner, 11));
};


/**
 * @param {?proto.accountpb.Owner|undefined} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setCoOwner = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearCoOwner = function() {
  return this.setCoOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.hasCoOwner = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional accountpb.ContactInfo co_contact_info_email = 12;
 * @return {?proto.accountpb.ContactInfo}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getCoContactInfoEmail = function() {
  return /** @type{?proto.accountpb.ContactInfo} */ (
    jspb.Message.getWrapperField(this, proto_accountpb_contactinfo_pb.ContactInfo, 12));
};


/**
 * @param {?proto.accountpb.ContactInfo|undefined} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setCoContactInfoEmail = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearCoContactInfoEmail = function() {
  return this.setCoContactInfoEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.hasCoContactInfoEmail = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional accountpb.ContactInfo co_contact_info_phone = 13;
 * @return {?proto.accountpb.ContactInfo}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getCoContactInfoPhone = function() {
  return /** @type{?proto.accountpb.ContactInfo} */ (
    jspb.Message.getWrapperField(this, proto_accountpb_contactinfo_pb.ContactInfo, 13));
};


/**
 * @param {?proto.accountpb.ContactInfo|undefined} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setCoContactInfoPhone = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearCoContactInfoPhone = function() {
  return this.setCoContactInfoPhone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.hasCoContactInfoPhone = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional accountpb.Address co_address = 14;
 * @return {?proto.accountpb.Address}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getCoAddress = function() {
  return /** @type{?proto.accountpb.Address} */ (
    jspb.Message.getWrapperField(this, proto_accountpb_address_pb.Address, 14));
};


/**
 * @param {?proto.accountpb.Address|undefined} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setCoAddress = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearCoAddress = function() {
  return this.setCoAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.hasCoAddress = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional accountpb.ContactInfo po_contact_info_phone1 = 15;
 * @return {?proto.accountpb.ContactInfo}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getPoContactInfoPhone1 = function() {
  return /** @type{?proto.accountpb.ContactInfo} */ (
    jspb.Message.getWrapperField(this, proto_accountpb_contactinfo_pb.ContactInfo, 15));
};


/**
 * @param {?proto.accountpb.ContactInfo|undefined} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setPoContactInfoPhone1 = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearPoContactInfoPhone1 = function() {
  return this.setPoContactInfoPhone1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.hasPoContactInfoPhone1 = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional accountpb.Address po_address = 16;
 * @return {?proto.accountpb.Address}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getPoAddress = function() {
  return /** @type{?proto.accountpb.Address} */ (
    jspb.Message.getWrapperField(this, proto_accountpb_address_pb.Address, 16));
};


/**
 * @param {?proto.accountpb.Address|undefined} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setPoAddress = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearPoAddress = function() {
  return this.setPoAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.hasPoAddress = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * repeated accountpb.Owner po_owner = 17;
 * @return {!Array<!proto.accountpb.Owner>}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getPoOwnerList = function() {
  return /** @type{!Array<!proto.accountpb.Owner>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_accountpb_owner_pb.Owner, 17));
};


/**
 * @param {!Array<!proto.accountpb.Owner>} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setPoOwnerList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.accountpb.Owner=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.Owner}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.addPoOwner = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.accountpb.Owner, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearPoOwnerList = function() {
  return this.setPoOwnerList([]);
};


/**
 * repeated accountpb.Identification po_identification = 18;
 * @return {!Array<!proto.accountpb.Identification>}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getPoIdentificationList = function() {
  return /** @type{!Array<!proto.accountpb.Identification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_accountpb_identification_pb.Identification, 18));
};


/**
 * @param {!Array<!proto.accountpb.Identification>} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setPoIdentificationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.accountpb.Identification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.Identification}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.addPoIdentification = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.accountpb.Identification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearPoIdentificationList = function() {
  return this.setPoIdentificationList([]);
};


/**
 * repeated accountpb.Identification po_identification_ssn = 19;
 * @return {!Array<!proto.accountpb.Identification>}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getPoIdentificationSsnList = function() {
  return /** @type{!Array<!proto.accountpb.Identification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_accountpb_identification_pb.Identification, 19));
};


/**
 * @param {!Array<!proto.accountpb.Identification>} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setPoIdentificationSsnList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.accountpb.Identification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.Identification}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.addPoIdentificationSsn = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.accountpb.Identification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearPoIdentificationSsnList = function() {
  return this.setPoIdentificationSsnList([]);
};


/**
 * repeated accountpb.ContactInfo po_contact_info_phone2 = 20;
 * @return {!Array<!proto.accountpb.ContactInfo>}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getPoContactInfoPhone2List = function() {
  return /** @type{!Array<!proto.accountpb.ContactInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_accountpb_contactinfo_pb.ContactInfo, 20));
};


/**
 * @param {!Array<!proto.accountpb.ContactInfo>} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setPoContactInfoPhone2List = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.accountpb.ContactInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.ContactInfo}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.addPoContactInfoPhone2 = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.accountpb.ContactInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearPoContactInfoPhone2List = function() {
  return this.setPoContactInfoPhone2List([]);
};


/**
 * repeated accountpb.ContactInfo po_contact_info_email = 21;
 * @return {!Array<!proto.accountpb.ContactInfo>}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getPoContactInfoEmailList = function() {
  return /** @type{!Array<!proto.accountpb.ContactInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_accountpb_contactinfo_pb.ContactInfo, 21));
};


/**
 * @param {!Array<!proto.accountpb.ContactInfo>} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setPoContactInfoEmailList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.accountpb.ContactInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.ContactInfo}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.addPoContactInfoEmail = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.accountpb.ContactInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearPoContactInfoEmailList = function() {
  return this.setPoContactInfoEmailList([]);
};


/**
 * repeated accountpb.Address po_address2 = 22;
 * @return {!Array<!proto.accountpb.Address>}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getPoAddress2List = function() {
  return /** @type{!Array<!proto.accountpb.Address>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_accountpb_address_pb.Address, 22));
};


/**
 * @param {!Array<!proto.accountpb.Address>} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setPoAddress2List = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 22, value);
};


/**
 * @param {!proto.accountpb.Address=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.Address}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.addPoAddress2 = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 22, opt_value, proto.accountpb.Address, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearPoAddress2List = function() {
  return this.setPoAddress2List([]);
};


/**
 * optional accountpb.Owner tc_owner = 23;
 * @return {?proto.accountpb.Owner}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getTcOwner = function() {
  return /** @type{?proto.accountpb.Owner} */ (
    jspb.Message.getWrapperField(this, proto_accountpb_owner_pb.Owner, 23));
};


/**
 * @param {?proto.accountpb.Owner|undefined} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setTcOwner = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearTcOwner = function() {
  return this.setTcOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.hasTcOwner = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional accountpb.Identification tc_identification = 24;
 * @return {?proto.accountpb.Identification}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getTcIdentification = function() {
  return /** @type{?proto.accountpb.Identification} */ (
    jspb.Message.getWrapperField(this, proto_accountpb_identification_pb.Identification, 24));
};


/**
 * @param {?proto.accountpb.Identification|undefined} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setTcIdentification = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearTcIdentification = function() {
  return this.setTcIdentification(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.hasTcIdentification = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional accountpb.ContactInfo tc_contact_info_phone = 25;
 * @return {?proto.accountpb.ContactInfo}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getTcContactInfoPhone = function() {
  return /** @type{?proto.accountpb.ContactInfo} */ (
    jspb.Message.getWrapperField(this, proto_accountpb_contactinfo_pb.ContactInfo, 25));
};


/**
 * @param {?proto.accountpb.ContactInfo|undefined} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setTcContactInfoPhone = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearTcContactInfoPhone = function() {
  return this.setTcContactInfoPhone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.hasTcContactInfoPhone = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional accountpb.ContactInfo tc_contact_info_email = 26;
 * @return {?proto.accountpb.ContactInfo}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getTcContactInfoEmail = function() {
  return /** @type{?proto.accountpb.ContactInfo} */ (
    jspb.Message.getWrapperField(this, proto_accountpb_contactinfo_pb.ContactInfo, 26));
};


/**
 * @param {?proto.accountpb.ContactInfo|undefined} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setTcContactInfoEmail = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearTcContactInfoEmail = function() {
  return this.setTcContactInfoEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.hasTcContactInfoEmail = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional accountpb.Address tc_address = 27;
 * @return {?proto.accountpb.Address}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getTcAddress = function() {
  return /** @type{?proto.accountpb.Address} */ (
    jspb.Message.getWrapperField(this, proto_accountpb_address_pb.Address, 27));
};


/**
 * @param {?proto.accountpb.Address|undefined} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setTcAddress = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearTcAddress = function() {
  return this.setTcAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.hasTcAddress = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional clientpb.AccountAccess account_access = 28;
 * @return {?proto.clientpb.AccountAccess}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getAccountAccess = function() {
  return /** @type{?proto.clientpb.AccountAccess} */ (
    jspb.Message.getWrapperField(this, proto_clientpb_accountaccess_pb.AccountAccess, 28));
};


/**
 * @param {?proto.clientpb.AccountAccess|undefined} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setAccountAccess = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearAccountAccess = function() {
  return this.setAccountAccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.hasAccountAccess = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional uint32 account_id = 29;
 * @return {number}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 29, value);
};


/**
 * optional string account_type = 30;
 * @return {string}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getAccountType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.setAccountType = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional Registration registration = 31;
 * @return {?proto.usrpb.Registration}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.getRegistration = function() {
  return /** @type{?proto.usrpb.Registration} */ (
    jspb.Message.getWrapperField(this, proto_usrpb_registration_pb.Registration, 31));
};


/**
 * @param {?proto.usrpb.Registration|undefined} value
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
*/
proto.usrpb.CreateClientOnboardingRequest.prototype.setRegistration = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateClientOnboardingRequest} returns this
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.clearRegistration = function() {
  return this.setRegistration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateClientOnboardingRequest.prototype.hasRegistration = function() {
  return jspb.Message.getField(this, 31) != null;
};


goog.object.extend(exports, proto.usrpb);
