import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment, Tooltip } from '@material-ui/core';
import color from '../../assets/css/colors'
import {ReactComponent as Envelope} from '../../assets/icons/envelope.svg';
import {ReactComponent as Info} from '../../assets/icons/info.svg';

const useStyles = makeStyles(() => ({
    textFieldWrapper: {
        display: 'flex',
        position: 'relative',
    },
    tooltipWrapper: {
        position: 'absolute',
        top: 32,
        right: 1,
        padding: '9px 10px',
        backgroundColor: 'rgba(0,0,0,0)',
        borderRadius: 5,
        '& svg': {
            width: 21,
            height: 21,
            cursor: 'help',
            '& path': {
                fill: color.primary.main,
            }
        },
    }
}));

const HtmlTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: color.light.main,
      maxWidth: 620,
      border: '1px solid ' + color.light.light,
      borderRadius: 10,
    },
}))(Tooltip);

const Textfield = (props) => {
    const classes = useStyles();
    
    const startIcon = (name) => {
        if (name === 'email') {
            return <InputAdornment position="start"><Envelope/></InputAdornment> 
        } else {
            return null
        }
    }

    return (
        <div className={classes.textFieldWrapper}>
            <TextField
                style={{marginTop: props.mt ? 20 : 0, marginBottom: props.mb ? props.mb : 0}}
                fullWidth={true}
                autoFocus={props.autoFocus}
                error={props.error}
                required={props.required}
                disabled={props.disabled}
                id={props.id}
                name={props.name}
                label={props.label}
                placeholder={props.placeholder}
                multiline={props.multiline}
                rows={props.rows}
                value={props.value || ''}
                onChange={props.onChange}
                onKeyUp={props.onKeyUp}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                InputLabelProps={{ shrink: true }}
                onInput={props.onInput}
                helperText={props.error ? props.msg : ''}
                InputProps={props.InputProps || {
                    startAdornment: props.showIcon ? startIcon(props.name) : null,
                }}
                inputProps={{ 
                    readOnly: props.readOnly ? true : false,
                    maxLength: props.max,
                    max: props.inputProps? props.inputProps.max : null,
                    min: props.inputProps? props.inputProps.min : null,
                    type: props.type,
                    autoComplete: 'no',
                }}
            />
            {(props.tooltip || props.htmlTooltip) && (
                <div className={classes.tooltipWrapper} style={{marginTop: props.mt ? 20 : 0}}>
                    {props.tooltip ? (
                        <Tooltip 
                            arrow
                            placement="top"
                            title={props.tooltip}
                        >
                            <Info />
                        </Tooltip>
                    ) : (
                        <HtmlTooltip 
                            placement="right"
                            title={<React.Fragment>{props.htmlTooltip}</React.Fragment>}
                        >
                            <Info />
                        </HtmlTooltip> 
                    )}
                </div>
            )}
        </div>
    );
}
export default Textfield;