import {React, lazy, Suspense } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Root from './Root'
import Header from './Header'
import Nav from './Nav'
import Content from './Content'
import Footer from './Footer'
import {
  Switch,
  Route,
  withRouter,
  Redirect,
} from 'react-router-dom';
import APWNavigation from '../Navigation/APWNavigation';
import {
  menuItems,
  AppDashboardPath,
  AppAccountsAllAccountsPath,
  AppAccountsAllPositionsPath,
  AppAccountsAllActivityPath,
  AppTradingSingleOrderPath,
  AppTradingSubmittedTradesPath,
  AppTradingSavedOrdersPath,
  AppTradingBasketTradesPath,
  AppMoveMoneyPath,
  AppReportingPath,
  AppCommunicationPath,
  AppFirmSettingsPath,
  AppDocumentsPath
} from './menu';
import { DoubleChevronLeftIcon, DoubleChevronRightIcon } from '../Icons/Mono/Navigational';

const DashboardPage = lazy(() => import('../../pages/Dashboard/DashboardPage'));

//Accounts
const Positions = lazy(() => import('../../pages/Accounts/Positions'));
const Accounts = lazy(() => import('../../pages/Accounts/AllAccounts/Accounts'));
const Activity = lazy(() => import('../../pages/Accounts/Activity'));

//Trading
const SingleOrder = lazy(() => import('../../pages/Trading/SingleOrder'));
const SubmittedTrades = lazy(() => import('../../pages/Trading/SubmittedTrades'));
const SavedOrders = lazy(() => import('../../pages/Trading/SavedOrders'));
const BasketTrades = lazy(() => import('../../pages/Trading/BasketTrades'));

//Move Money
const MoveMoney = lazy(() => import('../../pages/MoveMoney/MoveMoney'));

//Reporting
const Reporting = lazy(() => import('../../pages/Reporting/Reporting'));

//Communication
const Communication = lazy(() => import('../../pages/Communication/Communication'));

//Firm Settings
const FirmSettings = lazy(() => import('../../pages/FirmSettings/FirmSettings'));

//Documents
const Documents = lazy(() => import('../../pages/Documents/Documents'));



const LoadingFallback = () => <div>Loading...</div>;

const config = {
  "navAnchor": "left",
  "navVariant": {
    "xs": "permanent",
    "sm": "permanent",
    "md": "permanent"
  },
  "navWidth": {
    "xs": 240,
    "sm": 256,
    "md": 256
  },
  "collapsible": {
    "xs": true,
    "sm": true,
    "md": true
  },
  "collapsedWidth": {
    "xs": 64,
    "sm": 64,
    "md": 64
  },
  "clipped": {
    "xs": true,
    "sm": true,
    "md": true
  },
  "headerPosition": {
    "xs": "sticky",
    "sm": "sticky",
    "md": "sticky"
  },
  "squeezed": {
    "xs": true,
    "sm": true,
    "md": true
  },
  "footerShrink": {
    "xs": true,
    "sm": true,
    "md": true
  }
};

const Layout = () => (
    <Root config={config} style={{ minHeight: "100vh" }}>
      <CssBaseline />
      <Header
        menuIcon={{
          inactive: <MenuIcon />,
          active: <ChevronLeftIcon />
        }}
      />
      <Nav
        collapsedIcon={{
          inactive: <DoubleChevronLeftIcon fontSize="small" />,
          active: <DoubleChevronRightIcon fontSize="small"/>
        }}
        header={
          // you can provide fixed header inside nav
          // change null to some react element
          ctx => null
        }
      >
        {/* nav goes here */}
        <APWNavigation menuItems={menuItems} />
      </Nav>
      <Content>
        {/* content goes here */}
        <Suspense fallback={<LoadingFallback />}>
        <Switch>
          <Route path={AppDashboardPath} component={DashboardPage} />
          <Route path={AppAccountsAllAccountsPath} render={(props) =>(<Accounts {...props} />)} />
          <Route path={AppAccountsAllPositionsPath} render={(props) =>(<Positions {...props} />)} />
          <Route path={AppAccountsAllActivityPath} render={(props) =>(<Activity {...props} />)} />
          <Route path={AppTradingSingleOrderPath} render={(props) =>(<SingleOrder {...props} />)} />
          <Route path={AppTradingSubmittedTradesPath} render={(props) =>(<SubmittedTrades {...props} />)} />
          <Route path={AppTradingSavedOrdersPath} render={(props) =>(<SavedOrders {...props} />)} />
          <Route path={AppTradingBasketTradesPath} render={(props) =>(<BasketTrades {...props} />)} />
          <Route path={AppMoveMoneyPath} render={(props) =>(<MoveMoney {...props} />)} />
          <Route path={AppReportingPath} render={(props) =>(<Reporting {...props} />)} />
          <Route path={AppCommunicationPath} render={(props) =>(<Communication {...props} />)} />
          <Route path={AppFirmSettingsPath} render={(props) =>(<FirmSettings {...props} />)} />
          <Route path={AppDocumentsPath} render={(props) =>(<Documents {...props} />)} />
          <Route path="/app/*" render={() => <Redirect to="/" />} />
        </Switch>
        </Suspense>
      </Content>
      {/* 
      <Footer>
        <span>footer</span>
      </Footer>
        */}

    </Root>
)

export default withRouter(Layout);