import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import APWButton from '../../components/Buttons/APWButton';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

const APWAlertDialog = ({
  classes,
  theme,
  maxWidth,
  fullWidth,
  open,
  toggleDialog,
  title,
  content,
  actionButtons,
}) => {
  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick') {
            toggleDialog(false)
          }
        }}
        aria-labelledby="max-width-dialog-title"
        disableEscapeKeyDown={true}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <Divider variant="middle" />
        {content === undefined ? (
          <DialogContent sx={classes.center}>
            <DialogContentText>
              Body
            </DialogContentText>
          </DialogContent>
        ) : content}
        <DialogActions sx={classes.center} pt={0}>
          {actionButtons ? actionButtons:(<APWButton label="Close" fullWidth={fullWidth} onClick={() => { toggleDialog(false) }} />)}
        </DialogActions>

      </Dialog>
    </React.Fragment>
  );
}


APWAlertDialog.propTypes = {
  classes: PropTypes.object.isRequired, // PropTypes for styles
  theme: PropTypes.object.isRequired,   // PropTypes for theme
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
};

APWAlertDialog.defaultProps = {
  title: 'Alert',
  open: false,
  toggleDialog: () => { },
  fullWidth: true,
  maxWidth: 'xs',
};

APWAlertDialog.DialogContent = DialogContent
APWAlertDialog.DialogContentText = DialogContentText

export default withStyles(styles, { withTheme: true, name: "APWAlertDialog" })(APWAlertDialog);