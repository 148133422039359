import {
  Wallet,
  ListWalletRequest,
  WalletServiceClient,
  UpdateWalletRequest,
  ListContraWalletRequest,
  ListWalletSummaryRequest
} from '../proto/bankpb/wallet_grpc_web_pb'
import { auth } from '../lib/auth/Auth'
import authSvc from './AuthService';

const service = new WalletServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth },
)

const userId = authSvc.getCurrentUser()?.UserId;

export async function listWallet(param) {
  return new Promise((resolve, reject) => {
    const req = new ListWalletRequest()
    req.setStatus(param.status)
    req.setSymbol(param.symbol)
    req.setAccountNo(param.accountNo)

    service.listWallet(req, {}, (error, response) => {
      if (error) {
        reject(error)
      } else {
        resolve(response.toObject())
      }
    })
  })
}

export async function listWalletSummary(param) {
  return new Promise((resolve, reject) => {
    const req = new ListWalletSummaryRequest()
    req.setSymbol(param.symbol)
    req.setAccountNo(param.accountNo)
    req.setCorrespondent(param.correspondent)
    req.setMasterAccountNo(param.masterAccountNo)
    req.setUsrId(userId);

    service.listWalletSummary(req, {}, (error, response) => {
      if (error) {
        reject(error)
      } else {
        resolve(response.toObject())
      }
    })
  })
}
export async function listContraWallet(param) {
  return new Promise((resolve, reject) => {
    const req = new ListContraWalletRequest()
    req.setStatus(param.status)
    req.setWalletId(param.walletId)

    service.listWalletAddressBookContraWallet(req, {}, (error, response) => {
      if (error) {
        reject(error)
      } else {
        resolve(response.toObject())
      }
    })
  })
}

export async function createWallet(param) {
  return new Promise((resolve, reject) => {
    let req = new Wallet()
    req.setNote(param.note)
    req.setSymbol(param.symbol)
    req.setStatus(param.status)
    req.setWallet(param.wallet)
    req.setAccountNo(param.accountNo)
    req.setAccountId(param.accountId)

    service.createWallet(req, {}, (error, response) => {
      if (error) {
        reject(error)
      } else {
        resolve(response.toObject())
      }
    })
  })
}

export async function updateWallet(param) {
  return new Promise((resolve, reject) => {
    const req = new UpdateWalletRequest()
    req.setWalletId(param.walletId)
    req.setStatus(param.status)
    req.setNote(param.note)

    service.updateWallet(req, {}, (error, response) => {
      if (error) {
        reject(error)
      } else {
        resolve(response.toObject())
      }
    })
  })
}
