import React, { useState, useEffect } from 'react';
import { TextField, IconButton, FormControlLabel, Checkbox } from '@material-ui/core';
import { Create as EditIcon, Visibility as ViewIcon } from '@material-ui/icons';
import moment from 'moment-timezone';

import { formatPbDate } from '../../../lib/fmt';
import { callNotice } from '../../../lib/access/access';

import { listCallLog, updateCallLog } from '../../../services/CallLogService';
import { getPreviousDate } from '../../../services/ProfileService';

import AutoCompleteMasterAccountNo from '../../../components/AutoComplete/AutoCompleteMasterAccountNo';
import AutoCompleteCorrespondent from '../../../components/AutoComplete/AutoCompleteCorrespondent';
import AutoCompleteAccountName from '../../../components/AutoComplete/AutoCompleteAccountName';
import { SelectMarginType } from '../../../components/MultipleSelect/SelectSystemCode';
import SelectSystemCode from '../../../components/Dropdown/SelectSystemCode';
import ClientGrid from '../../../components/ClientGrid/ClientGrid';
import Button from '../../../components/Buttons/Button';
import Text from '../../../components/Typography/Text';
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from '../../../components/Messages/Notification';
import {
  pbDate,
  pbDateSorter,
  pbDateTime,
  pbDateTimeSorter,
  boolean,
  currency,
} from '../../../components/ClientGrid/CellRenderer';

import CallLogModal from './Components/CallLogModal';
import CallLogDetailsModal from './Components/CallLogDetailsModal';

export default function CallLog() {
  const [selectedRowsIds, setSelectedRowsIds] = React.useState([]);
  const [openDetails, setOpenDetails] = React.useState(false);
  const [rowData, setRowData] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [callNoticeAccess, setCallNoticeAccess] = React.useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = React.useState({
    search: false,
    save: false,
    send: false,
  });
  const [searchData, setSearchData] = React.useState({
    fromDate: '',
    toDate: '',
    callType: '',
    correspondent: '',
    masterAccountNo: '',
    accountId: '',
    field: '',
    callStatus: 'Pending',
    callStatusReason: '',
    marginType: [
      { code: 'House', description: 'House' },
      { code: 'Margin', description: 'Margin' },
    ],
    isOpen: false,
  });

  const isSendNoticeEnabled = (data) => {
    return data?.notify !== true && callNoticeAccess;
  };

  const GridButtons = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    return (
      <div align={'left'} className="grd-row" style={{ margin: 0, padding: 0 }}>
        <div align={'left'} className="grd-cell">
          <IconButton
            aria-label="details"
            onClick={() => {
              handleOpen(data);
            }}
          >
            <EditIcon style={{ height: 20, width: 20 }} />
          </IconButton>
          <IconButton
            aria-label="view"
            onClick={() => {
              const tradeDate = moment(new Date(formatPbDate(data.tradeDate))).format(
                'yyyy-MM-DD'
              );

              handleOpenDetails(tradeDate, data.masterAccountNo);
            }}
          >
            <ViewIcon style={{ height: 20, width: 20 }} />
          </IconButton>
        </div>
      </div>
    );
  };

  const columns = [
    {
      id: 'checkbox',
      width: '54px',
      visible: true,
    },
    {
      id: '',
      field: '',
      width: '60px',
      cellRenderer: GridButtons,
    },
    {
      id: 'tradeDate',
      field: 'tradeDate',
      label: 'Trade Date',
      cellRenderer: pbDate,
      sort: pbDateSorter,
      visible: false,
    },
    {
      id: 'correspondent',
      field: 'correspondent',
      label: 'Correspondent',
    },
    {
      id: 'masterAccountNo',
      field: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      id: 'accountName',
      field: 'accountName',
      label: 'Account Name',
    },
    {
      id: 'marginType',
      field: 'marginType',
      label: 'Margin Type',
    },
    {
      id: 'age',
      field: 'age',
      label: 'Age',
    },
    {
      id: 'callType',
      field: 'callType',
      label: 'Call Type',
    },
    {
      id: 'callReq',
      field: 'callReq',
      label: 'Call Requirement',
      cellRenderer: currency,
    },
    {
      id: 'callAmt',
      field: 'callAmt',
      label: 'Call Amt',
      cellRenderer: currency,
    },
    {
      id: 'callStatus',
      field: 'callStatus',
      label: 'Call Status',
    },
    {
      id: 'callStatusReason',
      field: 'callStatusReason',
      label: 'Call Status Reason',
    },
    {
      id: 'modifiedBy',
      field: 'modifiedBy',
      label: 'Modified By',
    },
    {
      id: 'modifiedDate',
      field: 'modifiedDate',
      label: 'Modified Date',
      cellRenderer: pbDateTime,
      sort: pbDateTimeSorter,
      visible: false,
    },
    {
      id: 'dueDate',
      field: 'dueDate',
      label: 'Due Date',
      cellRenderer: pbDate,
      sort: pbDateSorter,
      visible: false,
    },
    {
      id: 'callStatusReasonCount',
      field: 'callStatusReasonCount',
      label: 'Call Status Reason Count',
    },
    {
      id: 'notify',
      field: 'notify',
      label: 'Notify',
      cellRenderer: boolean,
      visible: false,
    },
    {
      id: 'notifyDate',
      field: 'notifyDate',
      label: 'Notify Date',
      cellRenderer: pbDateTime,
      sort: pbDateTimeSorter,
      visible: false,
    },
  ];

  const handleChange = (e, checkboxValue) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setSearchData({
      ...searchData,
      [input.name]:
        checkboxValue === true || checkboxValue === false ? checkboxValue : input.value,
    });
  };

  const handleSearch = async () => {
    try {
      setLoading({ ...loading, search: true });
      const data = await listCallLog(searchData);
      setRows(data.callLogsList);
      notifyInfo(data.callLogsList.length + ' search results.');
    } catch (error) {
      console.error(error);
      setLoading({ ...loading, search: false });
      notifyError(error.message);
    } finally {
      setLoading({ ...loading, search: false });
    }
  };

  const handleCallNotice = async (selected, index) => {
    let idsToTransfer = [];

    for (const s of selected) {
      idsToTransfer.push(rows[s - 1]?.requirementLogId);
    }

    try {
      setSelectedRowsIds(index);
      setLoading({ ...loading, send: true });
      await callNotice(idsToTransfer);
      setSelectedRowsIds([]);
      setLoading({ ...loading, send: false });
      notifyInfo('Successfully sent a notice.');
      handleSearch();
    } catch (error) {
      setSelectedRowsIds([]);
      setLoading({ ...loading, send: false });
      notifyError(error.message);
    }
  };

  const handleOpen = (data) => {
    setRowData(data);
    setOpen(true);
  };

  const handleOpenDetails = (tradeDate, masterAccountNo) => {
    setRowData({ tradeDate: tradeDate, masterAccountNo: masterAccountNo });
    setOpenDetails(true);
  };

  const handleClose = async (data, closeOnly) => {
    if (closeOnly) {
      setOpen(false);
      return;
    }

    if (!data) {
      setOpen(false);
      return;
    }

    try {
      if (!data.callStatus) {
        notifyError('Call status is required.');
        return;
      }

      if (!data.callStatusReason) {
        notifyError('Call status reason is required.');
        return;
      }

      setLoading({ ...loading, save: true });

      const resp = await updateCallLog(data);
      const rowsCopy = [...rows];
      const index = rows.indexOf(rowData);
      rowsCopy[index] = resp.callLog;
      setRows(rowsCopy);
      setLoading({ ...loading, send: false });
      notifySuccess('Call log has been updated.');
      setOpen(false);
    } catch (error) {
      console.error(error);
      setLoading({ ...loading, send: false });
      notifyError(error.message);
    }
  };

  useEffect(
    async () => {
      setCallNoticeAccess(await callNotice());
      async function init() {
        if (searchData.toDate || searchData.fromDate) return;

        const systemDate = await getPreviousDate();
        setSearchData({
          ...searchData,
          toDate: systemDate,
          fromDate: systemDate,
        });
      }

      init();
    },
    // eslint-disable-next-line
    []
  );

  const getCsvData = async () => {
    return rows;
  };

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Call Log" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading.search}
              loading={loading.search}
              type="search"
              label={loading.search ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
          {callNoticeAccess && (
            <div className="grd-cell-none">
              <Button
                disabled={selectedRowsIds.length ? loading.save : true}
                label="SEND CALL NOTICE"
                onClick={() => {
                  handleCallNotice(selectedRowsIds, selectedRowsIds);
                }}
              />
            </div>
          )}
        </div>
        <div className="grd-row">
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <TextField
              disabled={searchData.isOpen}
              name="fromDate"
              fullWidth
              label="From Date"
              type="date"
              value={searchData.fromDate}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <TextField
              disabled={searchData.isOpen}
              name="toDate"
              fullWidth
              label="To Date"
              type="date"
              value={searchData.toDate}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <SelectSystemCode
              name="callType"
              label="Call Type"
              type="Call Type"
              value={searchData.callType}
              onChange={handleChange}
            ></SelectSystemCode>
          </div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <SelectSystemCode
              name="callStatus"
              label="Call Status"
              type="Status"
              subType="Call Status"
              value={searchData.callStatus}
              onChange={handleChange}
            ></SelectSystemCode>
          </div>
          <div className="grd-cell">
            <SelectSystemCode
              name="callStatusReason"
              label="Call Status Reason"
              type="Status"
              subType="Call Status Reason"
              value={searchData.callStatusReason}
              onChange={handleChange}
            ></SelectSystemCode>
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <AutoCompleteCorrespondent
              name="correspondent"
              freeSolo={true}
              value={searchData.correspondent}
              isAccessibleOnly={true}
              onChange={handleChange}
            ></AutoCompleteCorrespondent>
          </div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <AutoCompleteAccountName
              label="Account Name"
              name="accountName"
              value={searchData.accountName}
              correspondent={searchData.correspondent}
              isAccessibleOnly={true}
              freeSolo={true}
              onChange={handleChange}
            ></AutoCompleteAccountName>
          </div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <AutoCompleteMasterAccountNo
              name="masterAccountNo"
              label="Master Account No"
              freeSolo={true}
              value={searchData.masterAccountNo}
              correspondent={searchData.correspondent}
              isAccessibleOnly={true}
              onChange={handleChange}
            ></AutoCompleteMasterAccountNo>
          </div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <SelectMarginType
              name="marginType"
              label="Margin Type"
              value={searchData.marginType}
              onChange={handleChange}
              onKeyDown={(e) => (e.keyCode === 13 ? handleSearch() : null)}
            ></SelectMarginType>
          </div>
          <div className="grd-cell">
            <FormControlLabel
              control={
                <Checkbox
                  name="isOpen"
                  checked={searchData.isOpen}
                  onChange={handleChange}
                />
              }
              label="Open"
            />
          </div>
        </div>
      </div>
      <div className="mt-20">
        <ClientGrid
          title="Call Log"
          rowIdField="callLogId"
          columns={columns}
          rows={rows}
          setRows={setRows}
          isLoading={loading.search}
          getCsvData={getCsvData}
          onSelectedRowsChange={(selectedRowsIds) => {
            setSelectedRowsIds(selectedRowsIds);
          }}
          selectedRowsIds={selectedRowsIds}
          getIsRowSelectable={isSendNoticeEnabled}
        />
      </div>
      {open && (
        <CallLogModal
          onClose={handleClose}
          open={open}
          value={rowData}
          loading={loading.save}
        ></CallLogModal>
      )}
      {openDetails && (
        <CallLogDetailsModal
          onClose={() => setOpenDetails(false)}
          open={openDetails}
          value={rowData}
        ></CallLogDetailsModal>
      )}
    </div>
  );
}
