// source: proto/usrpb/access.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.usrpb.ListUsrAccessRequest', null, global);
goog.exportSymbol('proto.usrpb.ListUsrAccessResponse', null, global);
goog.exportSymbol('proto.usrpb.UpdateAmountLimitRequest', null, global);
goog.exportSymbol('proto.usrpb.UsrAccess', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.UsrAccess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.UsrAccess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.UsrAccess.displayName = 'proto.usrpb.UsrAccess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.ListUsrAccessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.ListUsrAccessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.ListUsrAccessRequest.displayName = 'proto.usrpb.ListUsrAccessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.ListUsrAccessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.usrpb.ListUsrAccessResponse.repeatedFields_, null);
};
goog.inherits(proto.usrpb.ListUsrAccessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.ListUsrAccessResponse.displayName = 'proto.usrpb.ListUsrAccessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.UpdateAmountLimitRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.UpdateAmountLimitRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.UpdateAmountLimitRequest.displayName = 'proto.usrpb.UpdateAmountLimitRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.UsrAccess.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.UsrAccess.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.UsrAccess} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.UsrAccess.toObject = function(includeInstance, msg) {
  var f, obj = {
    usrId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accessId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    access: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subAccess: jspb.Message.getFieldWithDefault(msg, 4, ""),
    roleId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    amountLimit: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.UsrAccess}
 */
proto.usrpb.UsrAccess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.UsrAccess;
  return proto.usrpb.UsrAccess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.UsrAccess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.UsrAccess}
 */
proto.usrpb.UsrAccess.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUsrId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccessId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccess(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubAccess(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRoleId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmountLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.UsrAccess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.UsrAccess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.UsrAccess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.UsrAccess.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsrId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAccessId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getAccess();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubAccess();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRoleId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getAmountLimit();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional uint32 usr_id = 1;
 * @return {number}
 */
proto.usrpb.UsrAccess.prototype.getUsrId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.UsrAccess} returns this
 */
proto.usrpb.UsrAccess.prototype.setUsrId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 access_id = 2;
 * @return {number}
 */
proto.usrpb.UsrAccess.prototype.getAccessId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.UsrAccess} returns this
 */
proto.usrpb.UsrAccess.prototype.setAccessId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string access = 3;
 * @return {string}
 */
proto.usrpb.UsrAccess.prototype.getAccess = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.UsrAccess} returns this
 */
proto.usrpb.UsrAccess.prototype.setAccess = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sub_access = 4;
 * @return {string}
 */
proto.usrpb.UsrAccess.prototype.getSubAccess = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.UsrAccess} returns this
 */
proto.usrpb.UsrAccess.prototype.setSubAccess = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 role_id = 5;
 * @return {number}
 */
proto.usrpb.UsrAccess.prototype.getRoleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.UsrAccess} returns this
 */
proto.usrpb.UsrAccess.prototype.setRoleId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string amount_limit = 6;
 * @return {string}
 */
proto.usrpb.UsrAccess.prototype.getAmountLimit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.UsrAccess} returns this
 */
proto.usrpb.UsrAccess.prototype.setAmountLimit = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.ListUsrAccessRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.ListUsrAccessRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.ListUsrAccessRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ListUsrAccessRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    usrId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    requestType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    roleId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.ListUsrAccessRequest}
 */
proto.usrpb.ListUsrAccessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.ListUsrAccessRequest;
  return proto.usrpb.ListUsrAccessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.ListUsrAccessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.ListUsrAccessRequest}
 */
proto.usrpb.ListUsrAccessRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUsrId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRoleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.ListUsrAccessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.ListUsrAccessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.ListUsrAccessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ListUsrAccessRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsrId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getRequestType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRoleId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 usr_id = 1;
 * @return {number}
 */
proto.usrpb.ListUsrAccessRequest.prototype.getUsrId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.ListUsrAccessRequest} returns this
 */
proto.usrpb.ListUsrAccessRequest.prototype.setUsrId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string request_type = 2;
 * @return {string}
 */
proto.usrpb.ListUsrAccessRequest.prototype.getRequestType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.ListUsrAccessRequest} returns this
 */
proto.usrpb.ListUsrAccessRequest.prototype.setRequestType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 role_id = 3;
 * @return {number}
 */
proto.usrpb.ListUsrAccessRequest.prototype.getRoleId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.ListUsrAccessRequest} returns this
 */
proto.usrpb.ListUsrAccessRequest.prototype.setRoleId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.usrpb.ListUsrAccessResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.ListUsrAccessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.ListUsrAccessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.ListUsrAccessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ListUsrAccessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usrAccessesList: jspb.Message.toObjectList(msg.getUsrAccessesList(),
    proto.usrpb.UsrAccess.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.ListUsrAccessResponse}
 */
proto.usrpb.ListUsrAccessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.ListUsrAccessResponse;
  return proto.usrpb.ListUsrAccessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.ListUsrAccessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.ListUsrAccessResponse}
 */
proto.usrpb.ListUsrAccessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.usrpb.UsrAccess;
      reader.readMessage(value,proto.usrpb.UsrAccess.deserializeBinaryFromReader);
      msg.addUsrAccesses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.ListUsrAccessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.ListUsrAccessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.ListUsrAccessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ListUsrAccessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsrAccessesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.usrpb.UsrAccess.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UsrAccess usr_accesses = 1;
 * @return {!Array<!proto.usrpb.UsrAccess>}
 */
proto.usrpb.ListUsrAccessResponse.prototype.getUsrAccessesList = function() {
  return /** @type{!Array<!proto.usrpb.UsrAccess>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.usrpb.UsrAccess, 1));
};


/**
 * @param {!Array<!proto.usrpb.UsrAccess>} value
 * @return {!proto.usrpb.ListUsrAccessResponse} returns this
*/
proto.usrpb.ListUsrAccessResponse.prototype.setUsrAccessesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.usrpb.UsrAccess=} opt_value
 * @param {number=} opt_index
 * @return {!proto.usrpb.UsrAccess}
 */
proto.usrpb.ListUsrAccessResponse.prototype.addUsrAccesses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.usrpb.UsrAccess, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.usrpb.ListUsrAccessResponse} returns this
 */
proto.usrpb.ListUsrAccessResponse.prototype.clearUsrAccessesList = function() {
  return this.setUsrAccessesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.UpdateAmountLimitRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.UpdateAmountLimitRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.UpdateAmountLimitRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.UpdateAmountLimitRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    amountLimit: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accessId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.UpdateAmountLimitRequest}
 */
proto.usrpb.UpdateAmountLimitRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.UpdateAmountLimitRequest;
  return proto.usrpb.UpdateAmountLimitRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.UpdateAmountLimitRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.UpdateAmountLimitRequest}
 */
proto.usrpb.UpdateAmountLimitRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmountLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccessId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.UpdateAmountLimitRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.UpdateAmountLimitRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.UpdateAmountLimitRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.UpdateAmountLimitRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmountLimit();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccessId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string amount_limit = 1;
 * @return {string}
 */
proto.usrpb.UpdateAmountLimitRequest.prototype.getAmountLimit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.UpdateAmountLimitRequest} returns this
 */
proto.usrpb.UpdateAmountLimitRequest.prototype.setAmountLimit = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 access_id = 2;
 * @return {number}
 */
proto.usrpb.UpdateAmountLimitRequest.prototype.getAccessId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.usrpb.UpdateAmountLimitRequest} returns this
 */
proto.usrpb.UpdateAmountLimitRequest.prototype.setAccessId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto.usrpb);
