/*eslint eqeqeq: "off"*/
import {
    Form1099DivServiceClient,
    Form1099DivRequest,
    DownLoadDivRequest,
  } from '../proto/taxfilingpb/form1099_div_grpc_web_pb';
  import {
    notifyError,
    notifySuccess,
  } from '../components/Messages/Notification';
  import { auth } from '../lib/auth/Auth';
  import download from './DownloadService';
  
  const service = new Form1099DivServiceClient(
    window.env.GRPC_ENDPOINT,
    {},
    { ...auth }
  );
  
  export async function listForm1099Div(param) {
    return new Promise((resolve, reject) => {
      const req = new Form1099DivRequest();
      req.setYear(param.year);
      req.setCorrespondent(param.correspondent);
      req.setOffice(param.office);
      req.setAccountNo(param.accountNo);
  
      service.listForm1099Div(req, {}, (error, response) => {
        if (error) {
          notifyError(error.message);
          reject(error);
        } else {
          resolve(response.toObject());
        }
      });
    });
  }
  
  export async function listForm1099DivDetails(param) {
    return new Promise((resolve, reject) => {
      const req = new Form1099DivRequest();
      req.setYear(param.year);
      req.setCorrespondent(param.correspondent);
      req.setOffice(param.office);
      req.setAccountNo(param.accountNo);
  
      service.listForm1099DivDetails(req, {}, (error, response) => {
        if (error) {
          notifyError(error.message);
          reject(error);
        } else {
          resolve(response.toObject());
        }
      });
    });
  }
  
  export async function downloadDiv(param, filetype, batchNo, downloadType) {
    return new Promise((resolve, reject) => {
      let request = new DownLoadDivRequest();
      request.setFileType(filetype);
      request.setBatchNo(batchNo);
      request.setDownloadType(downloadType);
  
      let req = new Form1099DivRequest();
      req.setYear(param.year);
      req.setCorrespondent(param.correspondent);
      req.setOffice(param.office);
      req.setAccountNo(param.accountNo);
      request.setForm1099DivRequest(req);
  
      service.download(request, {}, (error, response) => {
        if (error) {
          notifyError(error.message);
          reject(error);
        } else {
          download(response);
          resolve(response);
          if (response.array[2]) {
            notifySuccess('Download ' + response.array[2] + ' completed');
          } else {
            notifySuccess('Download completed');
          }
        }
      });
    });
  }
  
  export async function generateDiv(param, filetype, batchNo, downloadType) {
    return new Promise((resolve, reject) => {
      let request = new DownLoadDivRequest();
      request.setFileType(filetype);
      request.setBatchNo(batchNo);
      request.setDownloadType(downloadType);
  
      let req = new Form1099DivRequest();
      req.setYear(param.year);
      req.setCorrespondent(param.correspondent);
      req.setOffice(param.office);
      req.setAccountNo(param.accountNo);
      request.setForm1099DivRequest(req);
  
      service.generate(request, {}, (error, response) => {
        if (error) {
          notifyError(error.message);
          reject(error);
          return;
        } else {
          resolve(response);
        }
      });
    });
  }
  