import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    Typography,
    Box,
    Link,
    FormControlLabel,
} from '@material-ui/core'
import APWAlertDialog from '../../components/Modals/APWAlertDialog';
import APWGrid from '../../components/Grid/APWGrid';
import APWForm from '../../components/Forms/APWForm';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import APWCodeField from '../../components/Forms/APWCodeField';
import APWButton from '../../components/Buttons/APWButton';
import authSvc from '../../services/AuthService';
import APWTextField from '../../components/Forms/APWTextField';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import axosDigital from '../../assets/images/axos-digital.png';
import { withRouter } from 'react-router-dom';
import { validateEmail } from '../../lib/validate/validate';
import { forgotUsername } from '../../services/ForgotCredentialService';

const styles = (theme) => ({

    backButtonWrapper: {
        marginBottom: "12px",
        display: "flex",
        alignItems: "center",
        '& .backIcon': {
            width: '12px',
        }
    },

    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },

    firmLogo: {
        marginLeft: theme.spacing(5),
        width: '9%', // Set the default width
        height: 'auto',
        // Media query for screens smaller than 600px (adjust the breakpoint as needed)
        [theme.breakpoints.down('lg')]: {
            width: '13%', // Adjust the width for small screens
        },
        [theme.breakpoints.down('xs')]: {
            width: '20%', // Adjust the width for small screens
        },
    },
});

class ForgotUsernamePreLogin extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            dialog: {
                open: false,
                title: "",
                content: null,
                actions: null,
            },
            error: {
                show: false,
                errorMsg: ''
            },
            loading: false,
        };
    }

    componentDidUpdate() {
        //console.log("sms did update: ", this.state)
    }

    componentDidMount() {
        // console.log("sms did mount: ", this.state.account)
    }

    setDialog = (dlgObj) => {
        this.setState(prevState => ({
            ...prevState,
            dialog: dlgObj,
        }));
    }

    internalError = () => {
        const dlg = {
            title: "Internal Server Error",
            content: (
                <APWAlertDialog.DialogContent>
                    <APWAlertDialog.DialogContentText>
                        {`Please try again`}
                    </APWAlertDialog.DialogContentText>
                </APWAlertDialog.DialogContent>
            ),
        }

        this.setState(prevState => ({
            ...prevState,
            dialog: { ...prevState.dialog, open: true, title: dlg.title, content: dlg.content, actions: dlg.actions },
            email: "",
            loading: false,
            error: {
                show: false,
                errorMsg: ''
            }
        }));
    }

    showError = (code) => {
        this.internalError()
        return;
      }

    alertSuccess = () => {
        const dlg = {
            title: "Thank you!",
            content: (
                <APWAlertDialog.DialogContent>
                    <APWAlertDialog.DialogContentText>
                        {`
                    If the information entered matches our records, a message with your username will be sent to the email you provided
                    `}
                    </APWAlertDialog.DialogContentText>
                </APWAlertDialog.DialogContent>
            ),
            actions: (
                <APWButton
                    mt={true}
                    label="Sign In"
                    onClick={() => {
                        this.backToSignin()
                    }}
                />
            ),
        }

        this.setState(prevState => ({
            ...prevState,
            dialog: { ...prevState.dialog, open: true, title: dlg.title, content: dlg.content, actions: dlg.actions },
            email: "",
            loading: false,
            error: {
                show: false,
                errorMsg: ''
            }
        }));
    }

    setError = (show, msg) => {
        this.setState(prevState => ({
            ...prevState,
            error: {
                show,
                errorMsg: msg
            },
        }));
    }

    hideErrorDialog = (state) => {
        this.setDialog({ open: state, title: "", content: null, actions: null })
    }

    backToSignin = () => {
        this.props.history.push('/login');
    }

    handleRequest = async () => {
        
        if (this.state.email === "") {
            return;
        }

        if (!this.state.email) {
            this.setError(true, 'Email address is required.')
            return;
        }

        if (validateEmail(this.state.email)) {
            this.setError(true, 'Invalid email address.')
            return;
        }

        try {
            this.setState(prevState => ({
                ...prevState,
                loading: true,
            }));
            await forgotUsername({ email: this.state.email });
        } catch (error) {
            this.showError()
            this.setState(prevState => ({
                ...prevState,
                loading: false,
            }));
            return;
        }

        this.alertSuccess()
    }

    handleChange = (name, value) => {
        this.setState(prevState => ({
            ...prevState,
            email: value,
            error: {
                show: false,
                errorMsg: ''
            }
        }));

    }

    render() {
        const { classes, theme, ...otherProps } = this.props;

        return (
            <React.Fragment>
                <AppBar
                    position="static"
                    color="default"
                    elevation={0}
                    style={{
                        borderTop: `5px solid ${theme.palette.primary.main}`,
                    }}
                >
                    <Toolbar sx={{ flexWrap: 'wrap' }}>
                        <img id="logo" src={axosDigital} alt="Logo" className={classes.firmLogo} />
                    </Toolbar>
                </AppBar>
                <APWAlertDialog
                    open={this.state.dialog.open}
                    title={this.state.dialog.title}
                    toggleDialog={(state) => {
                        this.hideErrorDialog(state)
                    }}
                    content={this.state.dialog.content}
                    actionButtons={this.state.dialog.actions}
                />
                <APWGrid id="forgot_username_container" justifyContent="flex-end">
                    <APWGrid.Item xs={11} sm={11} md={11} lg={11} xl={11}>
                        <Link
                            component="button"
                            variant="body2"
                            onClick={() => {
                                this.backToSignin();
                            }}
                        >
                            <Box className={classes.backButtonWrapper}>
                                <ArrowBackIosRoundedIcon className="backIcon" fontSize="small" /> Back to Signin
                            </Box>

                        </Link>
                        <Typography component="div" style={{ marginBottom: theme.spacing(2) }}>
                            <Box
                                textAlign="left"
                                fontSize="h4.fontSize"
                                fontWeight="fontWeightBold"
                            >
                                Forgot your username?
                            </Box>
                            <Box
                                textAlign="left"
                                fontSize="body2.fontSize"

                            >
                                Enter the email associated with your account and we'll send you an email with your username
                            </Box>
                        </Typography>
                        <APWForm>
                            <APWForm.Item xs={12} sm={12} md={6} lg={4} xl={4}>
                                <APWTextField
                                    error={this.state.error.show}
                                    helperText={this.state.error.errorMsg}
                                    id="txt_email"
                                    name="email"
                                    label="Email"
                                    value={this.state.email}
                                    placeholder='Email'
                                    onChange={this.handleChange}
                                    onKeyUp={(e) => ((e.keyCode === 13 || e.key === 'Enter' || e.key === 'NumpadEnter') ? this.handleRequest() : null)}
                                />
                            </APWForm.Item>
                            <APWForm.Item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <APWButton
                                    fullWidth={false}
                                    id="btn_change_password"
                                    disabled={this.state.loading || this.state.email === ""}
                                    loading={this.state.loading}
                                    label={this.state.loading ? 'Requesting...' : 'Submit'}
                                    onClick={this.handleRequest}
                                />
                            </APWForm.Item>
                        </APWForm>


                    </APWGrid.Item>
                </APWGrid>
            </React.Fragment>
        );
    }
}

ForgotUsernamePreLogin.propTypes = {
    classes: PropTypes.object.isRequired, // PropTypes for styles
    theme: PropTypes.object.isRequired,   // PropTypes for theme
};

export default withStyles(styles, { withTheme: true, name: 'ForgotUsernamePreLogin' })(withRouter(ForgotUsernamePreLogin));
