import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { listSystemCode } from '../../services/CommonService';
import { makeStyles } from '@material-ui/core/styles';
import color from '../../assets/css/colors';
import fontFamily from '../../assets/css/fonts';

const useStyles = makeStyles(() => ({
  autocompleteWrapper: {
      '& .MuiAutocomplete-inputRoot': {
          padding: '7px 15px 8px 15px !important',
          height: 48,
      },
      '& .MuiAutocomplete-option': {
          backgroundColor: 'red',
      },
      '& .MuiAutocomplete-endAdornment': {
          top: 'calc(50% - 15px)',
          '& svg': {
              width: '22px !important',
              height: '22px !important',
          }
      }
  },
  paper: {
      fontFamily: fontFamily.label,
      fontSize: 15,
      fontWeight: 600,
      backgroundColor: color.light.main,
  }
}));

function SelectAutoCompleteSystemCode(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState(props.value);

  const setPropsValue = (value) => {
    props.onChange({
      currentTarget: {
        name: props.name,
        value: value,
      },
      target: {
        name: props.name,
        value: value,
      },
    });
  };

  const handleOnBlur = async (value) => {
    if (props.freeSolo) {
      setPropsValue(value);
      return;
    }

    if (options.find((o) => o.code === value)) {
      setPropsValue(value);
      return;
    }

    // if selected value does not exist in options and is not a free solo
    setInputValue('');
    setPropsValue('');
  };

  //set local input value and get options
  const handleInputChange = async (event, value) => {
    if (!event) return;
    if (event.type === 'blur') return;
    if (event.type === 'click' && value) return;
    setInputValue(value);
    setPropsValue(value);
    getOptions(value);
  };

  //get list from backend
  const getOptions = async (data) => {
    const { systemCodeList } = await listSystemCode({
      type: props.type,
      subType: props.subType,
      code: props.type === 'Side' ? data : data.value,
      note: props.note,
      limit: props.limit,
    });

    let list = systemCodeList.filter((s) => s.code);
    const nonTrade = { code: 'NON TRD', description: 'None Trade Entry' };

    if (props.type === 'Entry Type') {
      list.push(nonTrade);
    }

    setOptions(list);
  };

  React.useEffect(() => {
    if (!options.length && !props.value) {
      //removed this from if condition [open]
      getOptions(props);
    }
    if (props.value !== inputValue) {
      setInputValue(props.value);
      setPropsValue(props.value);
    }
    // eslint-disable-next-line
  }, [props]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (e.target.value.length === 0 || options.find((o) => o.code === e.target.value)) {
        return;
      }
      if (!options.find((o) => o.code.indexOf(e.target.value) === -1)) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  };

  return (
    <Autocomplete
      className={classes.autocompleteWrapper}
      disabled={props.disabled}
      freeSolo={props.freeSolo}
      id={props.name}
      onInputChange={handleInputChange}
      onBlur={() => {
        if (props.autoSelect) {
          return;
        }
        handleOnBlur(inputValue);
      }}
      blurOnSelect={false}
      onChange={(_, v) => {
        const value = v ? v.code : '';
        setPropsValue(value);
        setInputValue(value);
      }}
      getOptionSelected={(option, value) =>
        option.code === value.code || options.find((o) => o.code !== value)
      }
      getOptionLabel={(option) => {
        return props.type === 'Side'
          ? `${option?.code} ${
              option?.description && !props.forceRemoveDescription
                ? '- ' + option?.description
                : ''
            }`
          : props.itemLabel === 'description'
          ? option?.description
          : option?.code + ' - ' + option?.description;
      }}
      inputValue={inputValue ? inputValue : props.value ? props.value : ''}
      options={options}
      name={props.name}
      autoHighlight={true}
      clearOnEscape={true}
      autoSelect={props.autoSelect}
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      renderInput={(params) => {
        params.inputProps.onKeyDown = handleKeyDown;
        return (
          <TextField
            {...params}
            error={props.error}
            name={props.name}
            required={props.required}
            disabled={props.disabled}
            label={props.label}
            InputLabelProps={{ shrink: true }}
          />
        );
      }}
    />
  );
}

export const AutoCompleteSettleType = (props) => {
  return <SelectAutoCompleteSystemCode {...props} type={'Settle Type'} />;
};

export const AutoCompleteExecBroker = (props) => {
  return <SelectAutoCompleteSystemCode {...props} type={'Exec Broker'} />;
};

export const AutoCompleteExecBrokerNo = (props) => {
  return <SelectAutoCompleteSystemCode {...props} type={'Exec Broker No'} />;
};

export const AutoCompleteContraExecBroker = (props) => {
  return <SelectAutoCompleteSystemCode {...props} type={'Contra Exec Broker'} />;
};

export const AutoCompleteContraExecBrokerNo = (props) => {
  return <SelectAutoCompleteSystemCode {...props} type={'Contra Exec Broker No'} />;
};

export const AutoCompleteCusip = (props) => {
  return <SelectAutoCompleteSystemCode {...props} type={'Cusip'} />;
};

export const AutoCompleteCurrency = (props) => {
  return <SelectAutoCompleteSystemCode {...props} type={'Currency'} />;
};

export const AutoCompleteSubAccountNo = (props) => {
  return <SelectAutoCompleteSystemCode {...props} type={'Sub Account No'} />;
};

export const SelectEntryType = (props) => {
  return <SelectAutoCompleteSystemCode {...props} limit={60} type={'Entry Type'} />;
};

export const SelectSicCode = (props) => {
  return <SelectAutoCompleteSystemCode {...props} type={'SIC Code'} />;
};

export const SelectReserveCalcCode = (props) => {
  return <SelectAutoCompleteSystemCode {...props} type={'15c3'} />;
};

export const SelectSide = (props) => {
  return (
    <SelectAutoCompleteSystemCode
      {...props}
      type={'Side'}
      subType={'Equity, Option'}
      autoSelect={true}
    />
  );
};

export const SelectSideCash = (props) => {
  return (
    <SelectAutoCompleteSystemCode
      {...props}
      type={'Side'}
      subType={'Cash'}
      autoSelect={true}
      forceRemoveDescription={true}
    />
  );
};

export const SelectCategory = (props) => {
  return (
    <SelectAutoCompleteSystemCode
      {...props}
      type={'Security Profile'}
      subType={'Category'}
    />
  );
};

export const SelectSecurityType = (props) => {
  return <SelectAutoCompleteSystemCode {...props} type={'Security Type'} />;
};

export const SelectSecuritySubType = (props) => {
  return <SelectAutoCompleteSystemCode {...props} type={'Security Sub Type'} />;
};

export const SelectExchangeCode = (props) => {
  return <SelectAutoCompleteSystemCode {...props} type={'Exchange Code'} />;
};

export const SelectBankSource = (props) => {
  return <SelectAutoCompleteSystemCode {...props} type={'Bank Source'} />;
};
export const SelectLegalEntity = (props) => {
  return (
    <SelectAutoCompleteSystemCode
      {...props}
      type="Legal Entity"
      itemLabel="description"
    />
  );
};

export const SelectAccountType = (props) => {
  return <SelectAutoCompleteSystemCode {...props} type="Client Account Type" />;
};

export const SelectInvestmentObject = (props) => {
  return (
    <SelectAutoCompleteSystemCode
      {...props}
      type="Investment Objective"
      itemLabel="description"
    />
  );
};

export const SelectFundSource = (props) => {
  return (
    <SelectAutoCompleteSystemCode {...props} type="Fund Source" itemLabel="description" />
  );
};

export const SelectTaxIdType = (props) => {
  return (
    <SelectAutoCompleteSystemCode
      {...props}
      type="Identification"
      itemLabel="description"
    />
  );
};

export const SelectState = (props) => {
  return (
    <SelectAutoCompleteSystemCode {...props} freeSolo={props.freeSolo} type={'State'} />
  );
};

export const SelectStateIRA = (props) => {
  return (
    <SelectAutoCompleteSystemCode
      {...props}
      freeSolo={props.freeSolo}
      type={'Tax Treaty'}
      subType={'State'}
    />
  );
};

export const SelectStateCA = (props) => {
  return (
    <SelectAutoCompleteSystemCode
      {...props}
      freeSolo={props.freeSolo}
      type={'Canada'}
      itemLabel="description"
    />
  );
};

export const SelectMonth = (props) => {
  return (
    <SelectAutoCompleteSystemCode
      {...props}
      freeSolo={props.freeSolo}
      type={'Month'}
      itemLabel="description"
    />
  );
};

export const SelectFeeType = (props) => {
  return (
    <SelectAutoCompleteSystemCode
      {...props}
      freeSolo={props.freeSolo}
      type={'Fee Type'}
      itemLabel="description"
    />
  );
};

export const SelectSplitType = (props) => {
  return (
    <SelectAutoCompleteSystemCode
      {...props}
      freeSolo={props.freeSolo}
      type={'Reorg Code'}
      note={'Split'}
    />
  );
};

export const SelectEntitlementEntryType = (props) => {
  return (
    <SelectAutoCompleteSystemCode
      {...props}
      limit={60}
      type={'Entry Type'}
      subType={'ReOrg'}
    />
  );
};

export const SelectReportTypeDisclosure = (props) => {
  return (
    <SelectAutoCompleteSystemCode
      {...props}
      limit={60}
      type={'Report Type'}
      subType={'Disclosure'}
      itemLabel="description"
    />
  );
};

export const SelectTypeDisclosure = (props) => {
  return (
    <SelectAutoCompleteSystemCode
      {...props}
      limit={60}
      type={'Type'}
      subType={'Disclosure'}
      itemLabel="description"
    />
  );
};
