import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox as MUICheckbox, FormControlLabel } from '@material-ui/core';
import color from '../../assets/css/colors';

const Checkbox = (props) => {
    const useStyles = makeStyles(() => ({
        checkbox: {
            marginLeft: 0,
            marginRight: 0,
            '&.MuiFormControlLabel-root': {
                alignItems: 'flex-start',
            },
            '& .MuiIconButton-root': {
                padding: 0,
                marginRight: props.mr ? props.mr : 5,
                marginLeft: props.ml ? props.ml : 5,
            },
            '& .MuiIconButton-label svg': {
                width: 25,
                height: 25,
            },
            '& .MuiTypography-body1': {
                fontSize: 14,
                fontWeight: props.labelLink ? 500 : 600,
                marginTop: 2,
            },
            '& .MuiIconButton-label svg path': {
                fill: 'unset',
            },
            '& .MuiIconButton-label:hover svg path': {
                fill: color.primary.main,
            },
            '& .Mui-checked': {
                color: color.primary.main
            },
        },
    }));
    
    const classes = useStyles();

    return (
        <FormControlLabel
            style={{marginTop: props.mt ? props.mt : 0, marginBottom: props.mb ? props.mb : 0}}
            className={classes.checkbox}
            labelPlacement={props.labelPlacement || 'end'}
            control={
                <MUICheckbox
                    disabled={props.disabled}
                    checked={props.checked} 
                    onChange={props.onChange} 
                    name={props.name} 
                />
            }
            label={props.label}
        />
    );
}
export default Checkbox;

