import React from 'react';
import TextField from '@material-ui/core/TextField';

const DateTimePicker = (props) => {
  return (
    <TextField
      style={{ marginTop: props.mt ? 20 : 0 }}
      fullWidth={true}
      autoFocus={props.autoFocus}
      error={props.error}
      required={props.required}
      disabled={props.disabled}
      name={props.name}
      label={props.label}
      helperText={props.error ? props.msg : ''}
      type={props.type}
      value={props.value}
      onChange={props.onChange}
      InputLabelProps={{ shrink: true }}
      InputProps={props.inputProps}
    />
  );
};
export default DateTimePicker;
