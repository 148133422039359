import React from 'react';
import { InputLabel, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import color from '../../assets/css/colors'
import { FormControl } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  dropdownWrapper: {
    display: 'flex',
    position: 'relative',
  },
  actionWrapper: {
    position: 'absolute',
    top: 29,
    right: 20,
    padding: 8,
    backgroundColor: 'unset',
    borderRadius: 5,
    '& svg': {
      width: 24,
      height: 24,
      cursor: 'pointer',
      '& path': {
        fill: color.primary.main,
      },
    },
  },
}))

export default function SelectAnnouncementType(props) {
  const classes = useStyles()

  return (
    <div className={classes.dropdownWrapper}>
      <FormControl
        fullWidth
        error={props.error}
        style={{
          marginTop: props.mt ? props.mt : 0,
          marginBottom: props.mb ? props.mb : 0,
        }}
        disabled={props.disabled}
      >
        <InputLabel
          className={props.disabled ? classes.disabled : ''}
          shrink
          required={props.required}
        >
          {props.label}
        </InputLabel>
        <Select
          displayEmpty
          fullWidth
          disabled={props.disabled}
          name={props.name}
          value={props.value ? props.value : ''}
          onChange={props.onChange}
        >
            <MenuItem value="dividend">Dividend</MenuItem>
            <MenuItem value="merger">Merger</MenuItem>
            <MenuItem value="spin_off">Spin-Off</MenuItem>
            <MenuItem value="split">Split</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
