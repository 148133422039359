import React from 'react';
import { useConfirm } from 'material-ui-confirm';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { Create as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import Text from '../../../../components/Typography/Text';
import Button from '../../../../components/Buttons/Button';
import { orderEntryColumns } from '../../../../lib/data/columns';
import Table, { columnType } from '../../../../components/ClearingTable/Table';
import OrderEntryModal from './OrderEntryModal';
import OrderEntryDuplicateModal from './OrderEntryDuplicateModal';
import OrderEntryAmountBreakdownModal from './OrderEntryAmountBreakdownModal';
import TotalSummaryCard from '../../../../components/Cards/TotalSummaryCard';
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from '../../../../components/Messages/Notification';
import QueryParam from '../../../../services/QueryParamService';
import {
  listOrderEntry,
  getListOrdersByID,
  createOrderEntry,
  updateOrderEntry,
  deleteOrderEntry,
  moveToPending,
  getOrderEntry,
  getTotals,
  duplicateTrade,
} from '../../../../services/OrderEntryService';
import SelectSystemCode from '../../../../components/Dropdown/SelectSystemCode';
import Textfield from '../../../../components/Textfields/TextField';
import AutoCompleteAccountNo from '../../../../components/AutoComplete/AutoCompleteAccountNo';
import AutoCompleteMasterAccountNo from '../../../../components/AutoComplete/AutoCompleteMasterAccountNo';
import AutoCompleteAccountName from '../../../../components/AutoComplete/AutoCompleteAccountName';
import OrderEntryMovePreviewModal from './OrderEntryMovePreviewModal';
import {
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderCurrencyLeftLeft,
  CustomBodyRenderDate,
  CustomBodyRenderDateTime,
  CustomBodyRenderBoolean,
  CustomBodyRenderAlign,
  CustomBodyRenderHtmlString,
  CustomBodyRenderCenter,
  CustomBodyRenderQty,
  CustomBodyRenderPercentage,
  CustomBodyRenderMissing,
} from '../../../../components/Table/CustomBodyRender';
import { formatPbDate, formatPbDateTime } from '../../../../lib/fmt';

const useStyles = makeStyles(() => ({
  badge: {
    marginLeft: 8,
    marginRight: 20,
  },
}));

export default function OrderTable(props) {
  const classes = useStyles();
  const confirm = useConfirm();
  const [loading, setLoading] = React.useState(false);
  const [selectedRowsIds, setSelectedRowsIds] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [rowData, setRowData] = React.useState({});
  const [openModal, setOpenModal] = React.useState(false);
  const [openDuplicate, setOpenDuplicate] = React.useState(false);
  const [openAmountBreakdown, setOpenAmountBreakdown] = React.useState(false);
  const [openPreview, setOpenPreview] = React.useState(false);
  const [openMovePreview, setOpenMovePreview] = React.useState(false);
  const [selectedOrderEntriesData, setSelectedOrderEntriesData] = React.useState([]);
  const [totals, setTotals] = React.useState({});
  const { setIsViewPending } = props;
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      accountNo: '',
      masterAccountNo: '',
      rep: '',
      accountName: '',
      assetType: '',
      symbol: '',
      side: '',
    })
  );

  const init = async () => {
    const searchParameters = localStorage.getItem('search_data');

    const { accountNo, masterAccountNo, rep, accountName, assetType, symbol, side } =
      searchParameters ? JSON.parse(searchParameters) : searchData;

    setSearchData({
      ...searchData,
      accountNo: accountNo ? accountNo : '',
      masterAccountNo: masterAccountNo ? masterAccountNo : '',
      rep: rep ? rep : '',
      accountName: accountName ? accountName : '',
      assetType: assetType ? assetType : '',
      symbol: symbol ? symbol : '',
      side: side ? side : '',
    });
  };

  const columns2 = [
    {
      id: 'checkbox',
      visible: true,
      pinned: true,
      width: '54px',
      sortable: false,
    },
    {
      id: '',
      label: '',
      width: '80px',
      cellRenderer: ({ tableManager, value, data, column, colIndex, rowIndex }) => {
        return (
          <div>
            <div style={{ padding: 5 }}>
              <IconButton aria-label="edit" onClick={() => handleOpen(data)}>
                <EditIcon style={{ height: 24, width: 24 }} />
              </IconButton>
              <IconButton aria-label="delete" onClick={() => handleDelete(data)}>
                <DeleteIcon style={{ height: 24, width: 24 }} />
              </IconButton>
            </div>
          </div>
        );
      },
      sortable: false,
    },
    ...orderEntryColumns,
  ];

  const columns = [
    {
      name: 'actions',
      label: 'Actions',
      sort: false,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
              <div style={{ padding: 5 }}>
                <IconButton aria-label="edit" onClick={() => handleOpen(rows[dataIndex])}>
                  <EditIcon style={{ height: 24, width: 24 }} />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDelete(rows[dataIndex])}
                >
                  <DeleteIcon style={{ height: 24, width: 24 }} />
                </IconButton>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'orderEntryId',
      label: 'ID',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      },
    },
    {
      name: 'row',
      label: 'Row',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      },
    },
    {
      name: 'accountNo',
      label: 'Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      },
    },
    {
      name: 'accountName',
      label: 'Account Name',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      },
    },
    {
      name: 'action',
      label: 'Action',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      },
    },
    {
      name: 'share',
      label: 'Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (!rows[dataIndex].signIndicator) {
            return CustomBodyRenderQty;
          } else {
            return CustomBodyRenderCurrencyLeft;
          }
        },
      },
    },
    {
      name: 'symbol',
      label: 'Symbol',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      },
    },
    {
      name: 'symbolDescription',
      label: 'Symbol Description',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      },
    },
    {
      name: 'orderType',
      label: 'Order Type',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      },
    },
    {
      name: 'assetType',
      label: 'Asset Type',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      },
    },
    {
      name: 'tif',
      label: 'Time Limit',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      },
    },
    {
      name: 'divRei',
      label: 'DIV Rei',
      options: {
        customBodyRender: CustomBodyRenderBoolean,
      },
    },
    {
      name: 'solicited',
      label: 'Solicited',
      options: {
        customBodyRender: CustomBodyRenderBoolean,
      },
    },
    {
      name: 'price',
      label: 'Price',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
        display: false,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'stopPrice',
      label: 'Stop Price',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'limitPrice',
      label: 'LimitPrice',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'amount',
      label: 'Amount',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'positionValue',
      label: 'Position Value',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'cashBalance',
      label: 'Cash Balance',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
        display: false,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'lastPrice',
      label: 'Last Price',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'trailingStop',
      label: 'Trailing Stop',
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (rows[dataIndex].orderType === 'TS$') {
            return CustomBodyRenderCurrencyLeft;
          } else {
            return CustomBodyRenderPercentage;
          }
        },
      },
    },
    {
      name: 'positionQty',
      label: 'Position Qty',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      },
    },
    {
      name: 'plusCommission',
      label: 'Plus Commission',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      },
    },
    {
      name: 'note',
      label: 'Note',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      },
    },
    {
      name: 'rounding',
      label: 'Rounding',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      },
    },
    {
      name: 'rep',
      label: 'Rep',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      },
    },
    {
      name: 'option',
      label: 'Option',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      },
    },
    {
      name: 'primeBroker',
      label: 'Prime Broker',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      },
    },
    {
      name: 'marginAgreement',
      label: 'Margin Agreement',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      },
    },
    {
      name: 'costPerShare',
      label: 'Cost Per Share',
      options: {
        customBodyRender: (value) => {
          if (value === 'Missing' || value === '') {
            return CustomBodyRenderMissing;
          } else {
            return CustomBodyRenderCurrencyLeft;
          }
        },
      },
    },
    {
      name: 'estRealizedPL',
      label: 'Est Realized PL',
      options: {
        customBodyRender: (value) => {
          if (value === 'Missing' || value === '') {
            return CustomBodyRenderMissing;
          } else {
            return CustomBodyRenderCurrencyLeft;
          }
        },
      },
    },
    {
      // Already converted to string in handlesearch
      name: 'createdDate',
      label: 'Created Date',
      options: {
        display: false,
      },
    },
    {
      name: 'createdTime',
      label: 'Created Time',
      options: {
        customBodyRender: CustomBodyRenderDateTime,
        display: false,
      },
    },
  ];

  const options = {
    responsive: 'standard',
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      setSelectedRowsIds(rowsSelected);
    },
    rowsSelected: selectedRowsIds,
  };

  React.useEffect(() => {
    init();
    handleRefresh(true);
  }, []);

  const handleSearchDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setSearchData({ ...searchData, [input.name]: input.value });
  };

  const getCsvData = async () => {
    const data = await listOrderEntry(searchData);

    return data.orderEntriesList;
  };

  const handleViewPending = async (hideAlert) => {
    QueryParam.set({ isViewPending: true });
    setIsViewPending(true);
  };

  const handleDelete = async (data) => {
    let count = selectedRowsIds.length || 1;
    confirm({
      title: 'Delete',
      description: `Are you sure you want to delete ${count} items(s)?`,
      confirmationText: 'Yes, Delete',
      cancellationText: 'No, Cancel',
    })
      .then(async () => {
        try {
          setLoading(true);
          if (data) {
            await deleteOrderEntry(data.orderEntryId);
            notifySuccess(' Order entry has been deleted.');
          } else {
            for (let index = 0; index < selectedRowsIds.length; index++) {
              const orderEntryId = selectedRowsIds[index];
              await deleteOrderEntry(orderEntryId);
            }
            setSelectedRowsIds([]);
            notifySuccess(`${selectedRowsIds.length} order entry item(s) was deleted.`);
          }
          handleRefresh(true);
        } catch (err) {
          notifyError(err.message);
        } finally {
          setLoading(false);
        }
      })
      .catch(() => {
        console.error('error');
      });
  };

  const handleRefresh = async (hideAlert) => {
    try {
      setSelectedRowsIds([]);
      setLoading(true);
      const data = await listOrderEntry(searchData);
      let rowsCopy = data.orderEntriesList;

      const rowsCopyOutput = rowsCopy.map((data) => {
        let tempDate = formatPbDateTime(data.createdDate);
        data.createdDate = tempDate;

        return data;
      });

      setRows(rowsCopyOutput);
      if (!hideAlert) {
        notifyInfo(
          data.orderEntriesList.length > 0
            ? data.orderEntriesList.length + ' search result(s)'
            : 'No records found.'
        );
      }

      const totalValues = await getTotals();
      setTotals(totalValues);
    } catch (error) {
      setLoading(false);
      notifyError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = async (data) => {
    let details = {};

    // get all data need for the form
    if (!data) {
      // add
      details = await createOrderEntry();
    } else {
      // edit
      details = await getOrderEntry(data.orderEntryId);
    }

    setRowData(details);
    setOpenModal(true);
  };

  const handleClose = async (data) => {
    if (data) {
      try {
        const resp = await updateOrderEntry(data);
        // reload by search for now
        // to do: append resp in rows
        handleRefresh(true);
        notifySuccess(' Order entry has been saved.');
      } catch (error) {
        console.error(error);
        notifyError(error.message);
      }
    }

    if (!data) setOpenModal(false);
  };

  const handleCloseDuplicate = async (data) => {
    if (!data) {
      setOpenDuplicate(false);
      return;
    }
    let valid = true;

    if (!data.accountNo) {
      notifyError('Account No is required.');
      valid = false;
    }

    if (!valid) {
      return;
    }

    data.selectedRowsIds = selectedRowsIds;

    try {
      setLoading(true);
      await duplicateTrade(data);
      notifySuccess('Duplicate trade successfully!');
      handleRefresh();
      setSelectedRowsIds([]);
    } catch (error) {
      setLoading(false);
      notifyError(error.message);
    } finally {
      setOpenDuplicate(false);
      setLoading(false);
    }
  };

  const handleCloseAmountBreakdown = async (data) => {
    setOpenAmountBreakdown(false);
  };

  const handleMoveToPending = async () => {
    let orderEntryIds = [];

    for (let index = 0; index < selectedRowsIds.length; index++) {
      orderEntryIds.push(rows[selectedRowsIds[index]].orderEntryId);
    }

    const data = await getListOrdersByID(orderEntryIds);
    setSelectedOrderEntriesData(data.orderEntriesList);
    setOpenMovePreview(true);
  };

  const handleMoveToPendingClose = async () => {
    try {
      setLoading(true);
      let orderEntryIds = [];

      for (let index = 0; index < selectedRowsIds.length; index++) {
        orderEntryIds.push(rows[selectedRowsIds[index]].orderEntryId);
      }

      await moveToPending(orderEntryIds);
      setSelectedRowsIds([]);
      notifySuccess(
        `${selectedRowsIds.length} order entry item(s) was moved to pending.`
      );
      handleRefresh(true);
      setOpenMovePreview(false);
    } catch (err) {
      notifyError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Order Entry" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading}
              loading={loading}
              label={loading ? 'Searching...' : 'Search'}
              onClick={() => handleRefresh(false)}
            />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading.save}
              label="Amount Breakdown"
              onClick={() => setOpenAmountBreakdown(true)}
            />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading.save}
              label="View Pending"
              onClick={() => handleViewPending()}
            />
          </div>
          <div className="grd-cell-none">
            <Button disabled={loading.save} label="Order" onClick={() => handleOpen()} />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={selectedRowsIds.length ? loading.save : true}
              label="Duplicate"
              onClick={() => setOpenDuplicate(true)}
            />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={selectedRowsIds.length ? loading.save : true}
              label="Delete"
              onClick={() => handleDelete()}
            />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={selectedRowsIds.length ? loading.save : true}
              label="Move to Pending"
              onClick={() => handleMoveToPending()}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <AutoCompleteAccountNo
              isAccessibleOnly={true}
              name="accountNo"
              value={searchData.accountNo}
              onChange={handleSearchDataChange}
              freeSolo
            />
          </div>
          {/* <div className="grd-cell">
              <AutoCompleteMasterAccountNo
                name="masterAccountNo"
                label="Master Account No"
                placeholder="Master Account No"
                value={searchData.masterAccountNo}
                onChange={handleSearchDataChange}
              />
            </div> */}
          <div className="grd-cell">
            <Textfield
              name="rep"
              label="Rep"
              placeholder="Rep"
              value={searchData.rep}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteAccountName
              isAccessibleOnly={true}
              name="accountName"
              value={searchData.accountName}
              onChange={handleSearchDataChange}
              freeSolo
            />
          </div>
          <div className="grd-cell">
            <Textfield
              name="assetType"
              label="Asset Type"
              placeholder="Asset Type"
              value={searchData.assetType}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <Textfield
              name="symbol"
              label="Symbol"
              placeholder="Symbol"
              value={searchData.symbol}
              onChange={handleSearchDataChange}
            />
          </div>
          {/* <div className="grd-cell">
              <Textfield
                name="side"
                label="Side"
                placeholder="Side"
                value={searchData.side}
                onChange={handleSearchDataChange}
              />
            </div> */}
        </div>
        <div className="mt-20">
          <div>
            <div className="grd-row nm">
              <div className="grd-cell">
                <TotalSummaryCard label="Buy" total={totals.totalBuy} />
              </div>
              <div className="grd-cell">
                <TotalSummaryCard label="Sell" total={totals.totalSell} />
              </div>
              <div className="grd-cell">
                <TotalSummaryCard label={totals.amountLabel} total={totals.totalAmount} />
              </div>
              <div className="grd-cell">
                <TotalSummaryCard label={totals.plLabel} total={totals.estRealizedPl} />
              </div>
              <div className="grd-cell">
                <TotalSummaryCard label="Count" type="qty" total={totals.totalCount} />
              </div>
            </div>
          </div>
          <div className="mt-20">
            {/* <ClientGrid
              title="Order Entry"
              rowIdField="orderEntryId"
              columns={columns2}
              rows={rows}
              getCsvData={getCsvData}
              isLoading={loading}
              onSelectedRowsChange={(selectedRowsIds) => {
                setSelectedRowsIds(selectedRowsIds);
                console.log(selectedRowsIds);
              }}
              selectedRowsIds={selectedRowsIds}
            /> */}
            {/* <ClientGrid2
              title="Order Entry"
              data={rows}
              columns={columns}
              options={options}
            /> */}
            <Table
              title={'Order Entry'}
              data={rows}
              columns={columns}
              options={options}
            />
          </div>

          {openModal && (
            <OrderEntryModal
              data={rowData}
              open={openModal}
              openPreview={openPreview}
              close={handleClose}
              setOpenPreview={setOpenPreview}
              loading={loading.save}
            ></OrderEntryModal>
          )}

          {openDuplicate && (
            <OrderEntryDuplicateModal
              data={rowData}
              selectedRowsIds={selectedRowsIds}
              open={openDuplicate}
              close={handleCloseDuplicate}
              loading={loading.save}
            ></OrderEntryDuplicateModal>
          )}

          {openAmountBreakdown && (
            <OrderEntryAmountBreakdownModal
              selectedRows={rows}
              open={openAmountBreakdown}
              close={handleCloseAmountBreakdown}
              loading={loading.save}
            ></OrderEntryAmountBreakdownModal>
          )}

          {openMovePreview && (
            <OrderEntryMovePreviewModal
              orderEntries={selectedOrderEntriesData}
              count={selectedRowsIds.length}
              open={openMovePreview}
              close={() => {
                setOpenMovePreview(false);
              }}
              loading={loading}
              handleClose={handleMoveToPendingClose}
            ></OrderEntryMovePreviewModal>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
