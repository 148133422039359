import React from 'react';
import { Link as MUILink, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import color from "../../assets/css/colors";
import fontFamily from '../../assets/css/fonts';

const useStyles = makeStyles(() => ({
    buttonLink: {
        backgroundImage: 'linear-gradient(30deg, ' + color.secondary.main + ', ' + color.primary.main + ')',
        borderImageSource: 'linear-gradient(30deg, ' + color.secondary.main + ', ' + color.primary.main + ')',
        color: color.light.main,
        display: 'block',
        padding: '14px 20px',
        borderRadius: '7px',
        borderImageSlice: 1,
        borderWidth: 1,
        textAlign: 'center',
        fontFamily: fontFamily.label,
        fontSize: '14px',
        '&:hover': {
            opacity: .7,
            boxShadow: 'none',
            textDecoration: 'none',
        },
    },
}));

const Link = (props) => {
    const classes = useStyles();

    return (
        <Tooltip arrow title={props.tooltipTitle ? props.tooltipTitle : ''}>
            <MUILink
                className={props.className ? classes.buttonLink : ''}
                style={{
                    marginTop: props.mt ? 20 : 0, 
                    marginLeft: props.ml ? props.ml : 0, 
                    marginRight: props.mr ? props.mr : 0}}
                component={'a'}
                href={props.type ? '' : props.href}
                target={props.target}
                name={props.name}
                onClick={
                    props.type 
                    ? props.onClick 
                    : props.name === 'readMarginDisclosureStatement' 
                    ? props.onClick 
                    : props.preventDefault
                }
                download={props.download}
            >
                {props.label}
            </MUILink>
        </Tooltip>
    );
}
export default Link;