import React from "react";
import TextField from "../Textfields/TextField";
import PhoneNumber from "../Textfields/PhoneNumber";
import LinkButton from "../Buttons/LinkButton";
import Text from "../Typography/Text";
import AutoCompletePlace from "../AutoComplete/AutoCompletePlace";
import AutoCompleteCountry from "../AutoComplete//AutoCompleteCountry";
import AutoCompleteState from "../AutoComplete//AutoCompleteState";
import Checkbox from "./Checkbox";
import RangeSlider from "./RangeSlider";

const CreateCoOfficer = (props) => {
  const {
    accountCoOfficerData,
    validateAccountCoOfficerData,
    handleAccountCoOfficerDataChange,
    handleRemoveCoOfficer,
    setAddressData,
  } = props;

  return (
    <div>
      {accountCoOfficerData.map((x, i) => {
        return (
          <div className="co-officer-w" key={i}>
            <div className="mt-50">
              <Text
                required={true}
                error={validateAccountCoOfficerData[i]?.coPrimaryType.err}
                msg={validateAccountCoOfficerData[i]?.coPrimaryType.msg}
                mt={20}
                variant="subtitle1"
                label="Primary Type"
              />
              <div className="grd-row">
                <div className="grd-cell-none">
                  <Checkbox
                    ml={-3}
                    name="coPrimaryOfficer"
                    label="Officer"
                    checked={x.coPrimaryOfficer}
                    onChange={(e) => handleAccountCoOfficerDataChange(e, i)}
                  />
                </div>
                <div className="grd-cell-none">
                  <Checkbox
                    ml={20}
                    name="coPrimaryOwner"
                    label="Owner"
                    checked={x.coPrimaryOwner}
                    onChange={(e) => handleAccountCoOfficerDataChange(e, i)}
                  />
                </div>
              </div>
              {x.coPrimaryOwner && (
                <div className="grd-row">
                  <div className="grd-cell">
                    <RangeSlider
                      required={true}
                      error={
                        validateAccountCoOfficerData[i]?.coOwnershipPercentage
                          .err
                      }
                      msg={
                        validateAccountCoOfficerData[i]?.coOwnershipPercentage
                          .msg
                      }
                      name="coOwnershipPercentage"
                      label="Percentage of Ownership"
                      step={0.5}
                      value={x.coOwnershipPercentage}
                      onChange={(e) => handleAccountCoOfficerDataChange(e, i)}
                      totalValue={props.totalPercentageOfOwnership}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="grd-row mt-30">
              <div className="grd-cell">
                <Text
                  mt={2}
                  variant="h3"
                  label={
                    "Co-" +
                    (x.coPrimaryOwner && x.coPrimaryOfficer
                      ? "Officer/Owner " + (i === 0 ? "" : i + 1)
                      : x.coPrimaryOwner
                      ? "Owner " + (i === 0 ? "" : i + 1)
                      : x.coPrimaryOfficer
                      ? "Officer " + (i === 0 ? "" : i + 1)
                      : "Officer/Owner " + (i === 0 ? "" : i + 1))
                  }
                />
              </div>
              <div className="grd-cell-none">
                {accountCoOfficerData.length !== 0 && (
                  <LinkButton
                    type="remove"
                    label="Remove Officer"
                    onClick={() => handleRemoveCoOfficer(i)}
                  />
                )}
              </div>
            </div>
            <div className="grd-row">
              <div className="grd-cell">
                <TextField
                  required={true}
                  error={validateAccountCoOfficerData[i]?.coOfficerFirstName.err}
                  msg={validateAccountCoOfficerData[i]?.coOfficerFirstName.msg}
                  max={50}
                  name="coOfficerFirstName"
                  label="First Name"
                  placeholder="First Name"
                  type="text"
                  value={x.coOfficerFirstName}
                  onChange={(e) => handleAccountCoOfficerDataChange(e, i)}
                />
              </div>
              <div className="grd-cell">
                <TextField
                  max={50}
                  name="coOfficerMiddleName"
                  label="Middle Name"
                  placeholder="Middle Name (Optional)"
                  type="text"
                  value={x.coOfficerMiddleName}
                  onChange={(e) => handleAccountCoOfficerDataChange(e, i)}
                />
              </div>
              <div className="grd-cell">
                <TextField
                  required={true}
                  error={validateAccountCoOfficerData[i]?.coOfficerLastName.err}
                  msg={validateAccountCoOfficerData[i]?.coOfficerLastName.msg}
                  max={50}
                  name="coOfficerLastName"
                  label="Last Name"
                  placeholder="Last Name"
                  type="text"
                  value={x.coOfficerLastName}
                  onChange={(e) => handleAccountCoOfficerDataChange(e, i)}
                />
              </div>
            </div>
            <div className="grd-row">
              <div className="grd-cell">
                <AutoCompletePlace
                  required={true}
                  error={validateAccountCoOfficerData[i]?.coOfficerAddress1.err}
                  msg={validateAccountCoOfficerData[i]?.coOfficerAddress1.msg}
                  max={100}
                  name="coOfficerAddress1"
                  label="Address 1"
                  placeholder="Address 1"
                  value={x.coOfficerAddress1}
                  onChange={(e) => handleAccountCoOfficerDataChange(e, i)}
                  index={i}
                  setAddressData={setAddressData}
                />
              </div>
              <div className="grd-cell">
                <TextField
                  max={100}
                  name="coOfficerAddress2"
                  label="Address 2"
                  placeholder="Address 2"
                  type="text"
                  value={x.coOfficerAddress2}
                  onChange={(e) => handleAccountCoOfficerDataChange(e, i)}
                />
              </div>
            </div>
            <div className="grd-row">
              <div className="grd-cell">
                <TextField
                  required={true}
                  error={validateAccountCoOfficerData[i]?.coOfficerCity.err}
                  msg={validateAccountCoOfficerData[i]?.coOfficerCity.msg}
                  max={80}
                  name="coOfficerCity"
                  label="City"
                  placeholder="City"
                  type="text"
                  value={x.coOfficerCity}
                  onChange={(e) => handleAccountCoOfficerDataChange(e, i)}
                />
              </div>
              <div className="grd-cell">
                {x.coOfficerCountry !== 'US' ? (
                  <TextField
                    required={true}
                    error={validateAccountCoOfficerData[i]?.coOfficerState.err}
                    msg={validateAccountCoOfficerData[i]?.coOfficerState.msg}
                    max={50}
                    name="coOfficerState"
                    label="State"
                    placeholder="State"
                    type="text"
                    value={x.coOfficerState}
                    onChange={(e) => handleAccountCoOfficerDataChange(e, i)}
                  />
                ) : (
                  <AutoCompleteState
                    required={true}
                    error={validateAccountCoOfficerData[i]?.coOfficerState.err}
                    msg={validateAccountCoOfficerData[i]?.coOfficerState.msg}
                    name="coOfficerState"
                    label="State"
                    placeholder="State"
                    type="text"
                    value={x.coOfficerState}
                    onChange={(e) => handleAccountCoOfficerDataChange(e, i)}
                  />
                )}
              </div>
            </div>
            <div className="grd-row">
              <div className="grd-cell">
                <AutoCompleteCountry
                  required={true}
                  error={validateAccountCoOfficerData[i]?.coOfficerCountry.err}
                  msg={validateAccountCoOfficerData[i]?.coOfficerCountry.msg}
                  name="coOfficerCountry"
                  label="Country"
                  placeholder="Country"
                  type="text"
                  value={x.coOfficerCountry}
                  onChange={(e) => handleAccountCoOfficerDataChange(e, i)}
                />
              </div>
              <div className="grd-cell">
                <TextField
                  required={true}
                  error={validateAccountCoOfficerData[i]?.coOfficerZipCode.err}
                  msg={validateAccountCoOfficerData[i]?.coOfficerZipCode.msg}
                  max={10}
                  name="coOfficerZipCode"
                  label="Zip Code"
                  placeholder="Zip Code"
                  type="text"
                  value={x.coOfficerZipCode}
                  onChange={(e) => handleAccountCoOfficerDataChange(e, i)}
                />
              </div>
            </div>
            <div className="grd-row">
              <div className="grd-cell">
                <PhoneNumber
                  required={true}
                  error={
                    validateAccountCoOfficerData[i]?.coOfficerPhoneNumber.err
                  }
                  msg={validateAccountCoOfficerData[i]?.coOfficerPhoneNumber.msg}
                  name="coOfficerPhoneNumber"
                  label="Phone Number"
                  value={x.coOfficerPhoneNumber}
                  onChange={(e) => handleAccountCoOfficerDataChange(e, i)}
                />
              </div>
              <div className="grd-cell">
                <TextField
                  required={true}
                  error={validateAccountCoOfficerData[i]?.coOfficerEmail.err}
                  msg={validateAccountCoOfficerData[i]?.coOfficerEmail.msg}
                  max={50}
                  name="coOfficerEmail"
                  label="Email Address"
                  placeholder="Email Address"
                  type="text"
                  value={x.coOfficerEmail}
                  onChange={(e) => handleAccountCoOfficerDataChange(e, i)}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default CreateCoOfficer;
