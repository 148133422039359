import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import Text from '../Typography/Text';
import Button from '../Buttons/Button';
import CloseButton from '../Buttons/CloseButton';
import fontFamily from '../../assets/css/fonts';
import color from '../../assets/css/colors';
import ClientGrid2 from '../../components/ClientGrid/ClientGrid2'
import Table, { columnType } from '../../components/ClearingTable/Table';
import { listForm1099DivDetails } from '../../services/Form1099DIVService';
import {
  CustomBodyRenderCurrency,
  CustomBodyRenderDate,
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderBoolean,
} from '../../components/Table/CustomBodyRender';


const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 1500,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 30px',
    maxHeight: 600,
    overflowY: 'auto',
    fontFamily: fontFamily.headers,
    color: color.default.main,
    '& p': {
      fontSize: 16,
      fontFamily: fontFamily.headers,
      fontWeight: 400,
      lineHeight: '26px',
      color: color.default.main,
      marginTop: 0,
      textAlign: 'center',
    },
    '& hr': {
      margin: '30px 0px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: 25,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
  paper: {
    position: 'absolute',
  }
}));

// Table Settings  
const form1099DIVColumns = [
    {
      name: 'trNo',
      label: 'TR No',
    },
    {
      name: 'settleDate',
      label: 'Settle Date',
      options: {
        customBodyRender: CustomBodyRenderDate,
      },
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'cusip',
      label: 'Cusip',
    },
    {
      name: 'symbolDescription',
      label: 'Symbol Description',
    },
    {
      name: 'symbolCountry',
      label: 'Symbol Country',
    },
    {
      name: 'div',
      label: 'Dividend',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft
      }
    },
    {
      name: 'taxRate',
      label: 'Tax Rate',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft
      }
    },
    {
      name: 'taxAmount',
      label: 'Tax Amount',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft
      }
    },
  ];

  const options = {
    responsive: 'standard',
    selectableRows: 'none',
  };
  // End Table Settings

  const testRows = [
  ];

export default function Form1099INTModal(props) {
  const classes = useStyles();
  const [disclosure, setDisclosure] = React.useState('');
  const [rows, setRows] = React.useState([]);

  const { open, close } = props;
  useEffect(() => {
    async function init() {
      let url = window.location.origin;
      let value = props.data;
      
      const data = await listForm1099DivDetails(value);
      setRows(data.form1099DivDetailsList)
      console.log(data.form1099DivDetailsList)
    }

    init();
  }, []);

  const handleClose = () => {
    close();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div style={{ width: 1500 }}>
          <div className={classes.modalHeader}>
            <div>
              <Text mt={3} variant="h2" label="Form 1099 DIV Details" />
            </div>
            <div className={classes.closeButton}>
              <CloseButton onClick={handleClose} />
            </div>
          </div>
          <div className={classes.modalBody}>
            <div>
                <Table
                  title={'Form 1099 DIV Details'}
                  data={rows}
                  columns={form1099DIVColumns}
                  options={options}
                />
            </div>
          </div>
          <div className={classes.modalFooter}>
            <Button label="Close" onClick={handleClose} />
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
