import {
  DividendServiceClient,
  ListDividendRequest,
  Dividend,
} from '../proto/reorgpb/dividend_grpc_web_pb';
import { stringToProtoDate } from './ConvertService';
import {
  MergerServiceClient,
  ListMergerRequest,
  Merger,
} from '../proto/reorgpb/merger_grpc_web_pb';

import {
  SpinOffServiceClient,
  ListSpinOffRequest,
  SpinOff,
} from '../proto/reorgpb/spinoff_grpc_web_pb';

import {
  SplitServiceClient,
  ListSplitRequest,
  Split,
} from '../proto/reorgpb/split_grpc_web_pb';
import { Pagination } from '../proto/utilspb/pagination_pb';

import { notifyError } from '../components/Messages/Notification';
import { auth } from '../lib/auth/Auth';

const service = new DividendServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);
const merger = new MergerServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);
const spinoff = new SpinOffServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);
const split = new SplitServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });

export async function listDividend(param, paging) {
  return new Promise((resolve, reject) => {
    const req = new ListDividendRequest();
    req.setFromSystemDate(stringToProtoDate(param.fromSystemDate));
    req.setToSystemDate(stringToProtoDate(param.toSystemDate));
    req.setSymbol(param.symbol);
    req.setStatus(param.status);
    req.setDateType(param.dateType);
    req.setCusip(param.cusip);

    if (paging) {
      let pg = new Pagination();
      pg.setPageNo(paging.pageNo);
      pg.setPageSize(paging.rowsPerPage);
      pg.setSortName(paging.sortName);
      pg.setSortDirection(paging.sortDirection);
      pg.setFilterNamesList(paging.filterNames);
      pg.setFilterValuesList(paging.filterValues);
      req.setPagination(pg);
    }

    service.listDividend(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function movePageDividend(param, paging) {
  return new Promise((resolve, reject) => {
    const req = new ListDividendRequest();
    req.setFromSystemDate(stringToProtoDate(param.fromSystemDate));
    req.setToSystemDate(stringToProtoDate(param.toSystemDate));
    req.setSymbol(param.symbol);
    req.setStatus(param.status);
    req.setDateType(param.dateType);
    req.setCusip(param.cusip);

    if (paging) {
      let pg = new Pagination();
      pg.setPageNo(paging.pageNo);
      pg.setPageSize(paging.rowsPerPage);
      pg.setSortName(paging.sortName);
      pg.setSortDirection(paging.sortDirection);
      pg.setFilterNamesList(paging.filterNames);
      pg.setFilterValuesList(paging.filterValues);
      req.setPagination(pg);
    }

    service.movePageDividend(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listMerger(param, paging) {
  return new Promise((resolve, reject) => {
    const req = new ListMergerRequest();
    req.setFromSystemDate(stringToProtoDate(param.fromSystemDate));
    req.setToSystemDate(stringToProtoDate(param.toSystemDate));
    req.setTargetSymbol(param.symbol);
    req.setStatus(param.status);
    req.setDateType(param.dateType);
    req.setCusip(param.cusip);

    if (paging) {
      let pg = new Pagination();
      pg.setPageNo(paging.pageNo);
      pg.setPageSize(paging.rowsPerPage);
      pg.setSortName(paging.sortName);
      pg.setSortDirection(paging.sortDirection);
      pg.setFilterNamesList(paging.filterNames);
      pg.setFilterValuesList(paging.filterValues);
      req.setPagination(pg);
    }

    merger.listMerger(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function movePageMerger(param, paging) {
  return new Promise((resolve, reject) => {
    const req = new ListMergerRequest();
    req.setFromSystemDate(stringToProtoDate(param.fromSystemDate));
    req.setToSystemDate(stringToProtoDate(param.toSystemDate));
    req.setTargetSymbol(param.symbol);
    req.setStatus(param.status);
    req.setDateType(param.dateType);
    req.setCusip(param.cusip);

    if (paging) {
      let pg = new Pagination();
      pg.setPageNo(paging.pageNo);
      pg.setPageSize(paging.rowsPerPage);
      pg.setSortName(paging.sortName);
      pg.setSortDirection(paging.sortDirection);
      pg.setFilterNamesList(paging.filterNames);
      pg.setFilterValuesList(paging.filterValues);
      req.setPagination(pg);
    }

    merger.movePageMerger(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listSpinOff(param, paging) {
  return new Promise((resolve, reject) => {
    const req = new ListSpinOffRequest();
    req.setFromSystemDate(stringToProtoDate(param.fromSystemDate));
    req.setToSystemDate(stringToProtoDate(param.toSystemDate));
    req.setTargetSymbol(param.symbol);
    req.setStatus(param.status);
    req.setDateType(param.dateType);
    req.setCusip(param.cusip);

    if (paging) {
      let pg = new Pagination();
      pg.setPageNo(paging.pageNo);
      pg.setPageSize(paging.rowsPerPage);
      pg.setSortName(paging.sortName);
      pg.setSortDirection(paging.sortDirection);
      pg.setFilterNamesList(paging.filterNames);
      pg.setFilterValuesList(paging.filterValues);
      req.setPagination(pg);
    }

    spinoff.listSpinOff(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function movePageSpinOff(param, paging) {
  return new Promise((resolve, reject) => {
    const req = new ListSpinOffRequest();
    req.setFromSystemDate(stringToProtoDate(param.fromSystemDate));
    req.setToSystemDate(stringToProtoDate(param.toSystemDate));
    req.setTargetSymbol(param.symbol);
    req.setStatus(param.status);
    req.setDateType(param.dateType);
    req.setCusip(param.cusip);

    if (paging) {
      let pg = new Pagination();
      pg.setPageNo(paging.pageNo);
      pg.setPageSize(paging.rowsPerPage);
      pg.setSortName(paging.sortName);
      pg.setSortDirection(paging.sortDirection);
      pg.setFilterNamesList(paging.filterNames);
      pg.setFilterValuesList(paging.filterValues);
      req.setPagination(pg);
    }

    spinoff.movePageSpinOff(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listSplit(param, paging) {
  return new Promise((resolve, reject) => {
    const req = new ListSplitRequest();
    req.setFromSystemDate(stringToProtoDate(param.fromSystemDate));
    req.setToSystemDate(stringToProtoDate(param.toSystemDate));
    req.setTargetSymbol(param.symbol);
    req.setStatus(param.status);
    req.setDateType(param.dateType);
    req.setCusip(param.cusip);

    if (paging) {
      let pg = new Pagination();
      pg.setPageNo(paging.pageNo);
      pg.setPageSize(paging.rowsPerPage);
      pg.setSortName(paging.sortName);
      pg.setSortDirection(paging.sortDirection);
      pg.setFilterNamesList(paging.filterNames);
      pg.setFilterValuesList(paging.filterValues);
      req.setPagination(pg);
    }

    split.listSplit(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function movePageSplit(param, paging) {
  return new Promise((resolve, reject) => {
    const req = new ListSplitRequest();
    req.setFromSystemDate(stringToProtoDate(param.fromSystemDate));
    req.setToSystemDate(stringToProtoDate(param.toSystemDate));
    req.setTargetSymbol(param.symbol);
    req.setStatus(param.status);
    req.setDateType(param.dateType);
    req.setCusip(param.cusip);

    if (paging) {
      let pg = new Pagination();
      pg.setPageNo(paging.pageNo);
      pg.setPageSize(paging.rowsPerPage);
      pg.setSortName(paging.sortName);
      pg.setSortDirection(paging.sortDirection);
      pg.setFilterNamesList(paging.filterNames);
      pg.setFilterValuesList(paging.filterValues);
      req.setPagination(pg);
    }

    split.movePageSplit(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function createDividend(param) {
  return new Promise((resolve, reject) => {
    let req = new Dividend();

    req.setTextNumber(param.textNumber);
    req.setSystemDate(stringToProtoDate(param.systemDate));
    req.setSymbol(param.symbol);
    req.setType(param.type);
    req.setOriginalCusip(param.originalCusip);
    req.setCashRate(param.cashRate);
    req.setQtyRate(param.qtyRate);
    req.setRecordDate(stringToProtoDate(param.recordDate));
    req.setPayableDate(stringToProtoDate(param.payableDate));
    req.setExpirationDate(stringToProtoDate(param.expirationDate));
    req.setDeclarationDate(stringToProtoDate(param.declarationDate));
    req.setPositionDate(stringToProtoDate(param.positionDate));
    req.setProcessDate(stringToProtoDate(param.processDate));
    req.setDetail(param.detail);
    req.setStatus(param.status);

    service.createDividend(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateDividend(param) {
  return new Promise((resolve, reject) => {
    let req = new Dividend();

    req.setTextNumber(param.textNumber);
    req.setSystemDate(stringToProtoDate(param.systemDate));
    req.setSymbol(param.symbol);
    req.setType(param.type);
    req.setOriginalCusip(param.originalCusip);
    req.setCashRate(param.cashRate);
    req.setQtyRate(param.qtyRate);
    req.setRecordDate(stringToProtoDate(param.recordDate));
    req.setPayableDate(stringToProtoDate(param.payableDate));
    req.setExpirationDate(stringToProtoDate(param.expirationDate));
    req.setDeclarationDate(stringToProtoDate(param.declarationDate));
    req.setPositionDate(stringToProtoDate(param.positionDate));
    req.setProcessDate(stringToProtoDate(param.processDate));
    req.setDetail(param.detail);
    req.setStatus(param.status);
    req.setDividendId(param.dividendId);

    service.updateDividend(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function createMerger(param) {
  return new Promise((resolve, reject) => {
    let req = new Merger();

    req.setTextNumber(param.textNumber);
    req.setSystemDate(stringToProtoDate(param.systemDate));
    req.setTargetSymbol(param.targetSymbol);
    req.setTargetOriginalCusip(param.targetOriginalCusip);
    req.setEffectiveDate(stringToProtoDate(param.effectiveDate));
    req.setInitiatingSymbol(param.initiatingSymbol);
    req.setInitiatingOriginalCusip(param.initiatingOriginalCusip);
    req.setCash(param.cash);
    req.setNewRate(param.newRate);
    req.setOldRate(param.oldRate);
    req.setRecordDate(stringToProtoDate(param.recordDate));
    req.setPositionDate(stringToProtoDate(param.positionDate));
    req.setProcessDate(stringToProtoDate(param.processDate));
    req.setDetail(param.detail);
    req.setStatus(param.status);

    merger.createMerger(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateMerger(param) {
  return new Promise((resolve, reject) => {
    let req = new Merger();

    req.setTextNumber(param.textNumber);
    req.setSystemDate(stringToProtoDate(param.systemDate));
    req.setTargetSymbol(param.targetSymbol);
    req.setTargetOriginalCusip(param.targetOriginalCusip);
    req.setEffectiveDate(stringToProtoDate(param.effectiveDate));
    req.setInitiatingSymbol(param.initiatingSymbol);
    req.setInitiatingOriginalCusip(param.initiatingOriginalCusip);
    req.setCash(param.cash);
    req.setNewRate(param.newRate);
    req.setOldRate(param.oldRate);
    req.setPositionDate(stringToProtoDate(param.positionDate));
    req.setRecordDate(stringToProtoDate(param.recordDate));
    req.setProcessDate(stringToProtoDate(param.processDate));
    req.setDetail(param.detail);
    req.setStatus(param.status);
    req.setMergerId(param.mergerId);

    merger.updateMerger(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function createSpinOff(param) {
  return new Promise((resolve, reject) => {
    let req = new SpinOff();

    req.setTextNumber(param.textNumber);
    req.setSystemDate(stringToProtoDate(param.systemDate));
    req.setTargetSymbol(param.targetSymbol);
    req.setTargetOriginalCusip(param.targetOriginalCusip);
    req.setExpirationDate(stringToProtoDate(param.expirationDate));
    req.setInitiatingSymbol(param.initiatingSymbol);
    req.setInitiatingOriginalCusip(param.initiatingOriginalCusip);
    req.setCash(param.cash);
    req.setNewRate(param.newRate);
    req.setOldRate(param.oldRate);
    req.setPositionDate(stringToProtoDate(param.positionDate));
    req.setProcessDate(stringToProtoDate(param.processDate));
    req.setDetail(param.detail);
    req.setStatus(param.status);

    spinoff.createSpinOff(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateSpinOff(param) {
  return new Promise((resolve, reject) => {
    let req = new SpinOff();
    console.log(param);
    req.setTextNumber(param.textNumber);
    req.setSystemDate(stringToProtoDate(param.systemDate));
    req.setTargetSymbol(param.targetSymbol);
    req.setTargetOriginalCusip(param.targetOriginalCusip);
    req.setExpirationDate(stringToProtoDate(param.expirationDate));
    req.setInitiatingSymbol(param.initiatingSymbol);
    req.setInitiatingOriginalCusip(param.initiatingOriginalCusip);
    req.setCash(param.cash);
    req.setNewRate(param.newRate);
    req.setOldRate(param.oldRate);
    req.setPositionDate(stringToProtoDate(param.positionDate));
    req.setProcessDate(stringToProtoDate(param.processDate));
    req.setDetail(param.detail);
    req.setStatus(param.status);
    req.setSpinOffId(param.spinOffId);

    spinoff.updateSpinOff(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function createSplit(param) {
  return new Promise((resolve, reject) => {
    let req = new Split();

    req.setTextNumber(param.textNumber);
    req.setSystemDate(stringToProtoDate(param.systemDate));
    req.setTargetSymbol(param.targetSymbol);
    req.setTargetOriginalCusip(param.targetOriginalCusip);
    req.setExpirationDate(stringToProtoDate(param.expirationDate));
    req.setInitiatingSymbol(param.initiatingSymbol);
    req.setInitiatingOriginalCusip(param.initiatingOriginalCusip);
    req.setCash(param.cash);
    req.setNewRate(param.newRate);
    req.setOldRate(param.oldRate);
    req.setSplitType(param.splitType);
    req.setRecordDate(stringToProtoDate(param.recordDate));
    req.setPositionDate(stringToProtoDate(param.positionDate));
    req.setProcessDate(stringToProtoDate(param.processDate));
    req.setDetail(param.detail);
    req.setStatus(param.status);

    split.createSplit(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateSplit(param) {
  return new Promise((resolve, reject) => {
    let req = new Split();

    req.setTextNumber(param.textNumber);
    req.setSystemDate(stringToProtoDate(param.systemDate));
    req.setTargetSymbol(param.targetSymbol);
    req.setTargetOriginalCusip(param.targetOriginalCusip);
    req.setExpirationDate(stringToProtoDate(param.expirationDate));
    req.setInitiatingSymbol(param.initiatingSymbol);
    req.setInitiatingOriginalCusip(param.initiatingOriginalCusip);
    req.setCash(param.cash);
    req.setNewRate(param.newRate);
    req.setOldRate(param.oldRate);
    req.setSplitType(param.splitType);
    req.setRecordDate(stringToProtoDate(param.recordDate));
    req.setPositionDate(stringToProtoDate(param.positionDate));
    req.setProcessDate(stringToProtoDate(param.processDate));
    req.setDetail(param.detail);
    req.setStatus(param.status);
    req.setSplitId(param.splitId);

    split.updateSplit(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function deleteDividend(param) {
  return new Promise((resolve, reject) => {
    const req = new Dividend();
    req.setDividendId(param);
    service.deleteDividend(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function deleteMerger(param) {
  return new Promise((resolve, reject) => {
    const req = new Merger();
    req.setMergerId(param);
    merger.deleteMerger(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function deleteSpinOff(param) {
  return new Promise((resolve, reject) => {
    const req = new SpinOff();
    req.setSpinOffId(param);
    spinoff.deleteSpinOff(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function deleteSplit(param) {
  return new Promise((resolve, reject) => {
    const req = new Split();
    req.setSplitId(param);
    split.deleteSplit(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
