import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import {
    formatPbDate,
    formatPbDateTime,
    formatCurrency,
    formatPercentage,
    formatQty,
} from '../../lib/fmt';
import { padding } from "@mui/system";


const useStyles = makeStyles(() => ({
    stickyFooterCell: {
        position: 'sticky',
        bottom: 0,
        zIndex: 100,
        background: 'white',
    },
}));

export default function ClientGrid2(props) {
    const classes = useStyles();

    let columnSettings = props.columns;

    const getColor = (value) => {
        return value < 0 ? '#f44336' : '#4caf50'
    }

    const options = {
        ...props.options,
        tableBodyHeight: '500px',
        customTableBodyFooterRender: (opts) => {
            return (
                <TableFooter>
                    <TableRow>
                        {opts.selectableRows !== 'none' ? <TableCell/> : null}
                        {opts.columns.map((col, index) => {
                            if(col.display === 'true') {
                                if(columnSettings[index].addFooter === true) {
                                    switch (columnSettings[index].type) {
                                        case 'amount':
                                            let totalAmt = props.data.reduce((accu, data) => {
                                                return accu + parseFloat(data[col.name]);
                                            }, 0);

                                            return (
                                                <TableCell className={classes.stickyFooterCell} key={index} style={{ paddingLeft: 33, fontWeight: 900, fontSize: 14, color: 'white'}}>
                                                    <div style={{ backgroundColor: getColor(totalAmt), paddingLeft: 10, paddingRight:10, borderRadius: 5, textAlign: 'center', maxWidth: 140}}>
                                                        {formatCurrency(totalAmt)}
                                                    </div>
                                                </TableCell>
                                            )
                                            break;
                                        case 'qty':
                                            let totalQty = props.data.reduce((accu, data) => {
                                                return accu + parseInt(data[col.name]);
                                            }, 0);

                                            return (
                                                <TableCell className={classes.stickyFooterCell} key={index} style={{ paddingLeft: 33, fontWeight: 900, fontSize: 14, color: 'white'}}>
                                                    <div style={{ backgroundColor: getColor(totalQty), paddingLeft: 10, paddingRight:10, borderRadius: 5, textAlign: 'center', maxWidth: 140}}>
                                                        {formatQty(totalQty)}
                                                    </div>
                                                </TableCell>
                                            )
                                            break;
                                        case 'percentage':
                                            let totalPercent= props.data.reduce((accu, data) => {
                                                return accu + parseFloat(data[col.name]);
                                            }, 0);

                                            return (
                                                <TableCell className={classes.stickyFooterCell} key={index} style={{ paddingLeft: 33, fontWeight: 900, fontSize: 14, color: 'white'}}>
                                                    <div style={{ backgroundColor: getColor(totalPercent), paddingLeft: 10, paddingRight:10, borderRadius: 5, textAlign: 'center', maxWidth: 140}}>
                                                        {formatPercentage(totalPercent)}
                                                    </div>
                                                </TableCell>
                                            )
                                            break;
                                        default:
                                            return (
                                                <TableCell className={classes.stickyFooterCell} key={index}></TableCell>
                                            )
                                    }
                                } else {
                                    return (
                                        <TableCell className={classes.stickyFooterCell} key={index}></TableCell>
                                    )
                                }
                            }
                            return null;
                        })}
                    </TableRow>
                </TableFooter>
            )
        }
    }
    
    return(
        <MUIDataTable
        title={props.title}
        data={props.data}
        columns={props.columns}
        onRowSelectionChange={props.onRowSelectionChange}
        rowsSelected={props.rowsSelected}
        options={options}
        />
    );
}