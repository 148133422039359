const rootStyles = (theme) => ({
    tabSelected: {
        backgroundColor: theme.palette.primary.opacity,
    },
    tabsRoot: {
        minHeight: 0,
    },
    tabRoot: {
        minHeight: 0,
        padding: 0,
        textTransform: 'none',
        borderRadius: 4,
        '&:hover': {
            opacity: 1,
            backgroundColor: theme.palette.default.opacity,
        },
        '&:focus': {
            backgroundColor: theme.palette.primary.opacity,
        },
    },
    labelWrapper: {

    }
});

export default {
    rootStyles
};

