/**
 * @fileoverview gRPC-Web generated client stub for usrpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck

const grpc = {};
grpc.web = require('grpc-web');

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
const proto = {};
proto.usrpb = require('./pages_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.usrpb.PageServiceClient = function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;
};

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.usrpb.PageServicePromiseClient = function(
  hostname,
  credentials,
  options
) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.Page,
 *   !proto.usrpb.ListPageResponse>}
 */
const methodDescriptor_PageService_ListPage = new grpc.web.MethodDescriptor(
  '/usrpb.PageService/ListPage',
  grpc.web.MethodType.UNARY,
  proto.usrpb.Page,
  proto.usrpb.ListPageResponse,
  /**
   * @param {!proto.usrpb.Page} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.ListPageResponse.deserializeBinary
);

/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.usrpb.Page,
 *   !proto.usrpb.ListPageResponse>}
 */
const methodInfo_PageService_ListPage = new grpc.web.AbstractClientBase.MethodInfo(
  proto.usrpb.ListPageResponse,
  /**
   * @param {!proto.usrpb.Page} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.ListPageResponse.deserializeBinary
);

/**
 * @param {!proto.usrpb.Page} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.usrpb.ListPageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.ListPageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.PageServiceClient.prototype.listPage = function(
  request,
  metadata,
  callback
) {
  return this.client_.rpcCall(
    this.hostname_ + '/usrpb.PageService/ListPage',
    request,
    metadata || {},
    methodDescriptor_PageService_ListPage,
    callback
  );
};

/**
 * @param {!proto.usrpb.Page} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.ListPageResponse>}
 *     A native promise that resolves to the response
 */
proto.usrpb.PageServicePromiseClient.prototype.listPage = function(
  request,
  metadata
) {
  return this.client_.unaryCall(
    this.hostname_ + '/usrpb.PageService/ListPage',
    request,
    metadata || {},
    methodDescriptor_PageService_ListPage
  );
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.Page,
 *   !proto.usrpb.Page>}
 */
const methodDescriptor_PageService_CreatePage = new grpc.web.MethodDescriptor(
  '/usrpb.PageService/CreatePage',
  grpc.web.MethodType.UNARY,
  proto.usrpb.Page,
  proto.usrpb.Page,
  /**
   * @param {!proto.usrpb.Page} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.Page.deserializeBinary
);

/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.usrpb.Page,
 *   !proto.usrpb.Page>}
 */
const methodInfo_PageService_CreatePage = new grpc.web.AbstractClientBase.MethodInfo(
  proto.usrpb.Page,
  /**
   * @param {!proto.usrpb.Page} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.Page.deserializeBinary
);

/**
 * @param {!proto.usrpb.Page} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.usrpb.Page)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.Page>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.PageServiceClient.prototype.createPage = function(
  request,
  metadata,
  callback
) {
  return this.client_.rpcCall(
    this.hostname_ + '/usrpb.PageService/CreatePage',
    request,
    metadata || {},
    methodDescriptor_PageService_CreatePage,
    callback
  );
};

/**
 * @param {!proto.usrpb.Page} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.Page>}
 *     A native promise that resolves to the response
 */
proto.usrpb.PageServicePromiseClient.prototype.createPage = function(
  request,
  metadata
) {
  return this.client_.unaryCall(
    this.hostname_ + '/usrpb.PageService/CreatePage',
    request,
    metadata || {},
    methodDescriptor_PageService_CreatePage
  );
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.Page,
 *   !proto.usrpb.Page>}
 */
const methodDescriptor_PageService_UpdatePage = new grpc.web.MethodDescriptor(
  '/usrpb.PageService/UpdatePage',
  grpc.web.MethodType.UNARY,
  proto.usrpb.Page,
  proto.usrpb.Page,
  /**
   * @param {!proto.usrpb.Page} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.Page.deserializeBinary
);

/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.usrpb.Page,
 *   !proto.usrpb.Page>}
 */
const methodInfo_PageService_UpdatePage = new grpc.web.AbstractClientBase.MethodInfo(
  proto.usrpb.Page,
  /**
   * @param {!proto.usrpb.Page} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.Page.deserializeBinary
);

/**
 * @param {!proto.usrpb.Page} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.usrpb.Page)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.Page>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.PageServiceClient.prototype.updatePage = function(
  request,
  metadata,
  callback
) {
  return this.client_.rpcCall(
    this.hostname_ + '/usrpb.PageService/UpdatePage',
    request,
    metadata || {},
    methodDescriptor_PageService_UpdatePage,
    callback
  );
};

/**
 * @param {!proto.usrpb.Page} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.Page>}
 *     A native promise that resolves to the response
 */
proto.usrpb.PageServicePromiseClient.prototype.updatePage = function(
  request,
  metadata
) {
  return this.client_.unaryCall(
    this.hostname_ + '/usrpb.PageService/UpdatePage',
    request,
    metadata || {},
    methodDescriptor_PageService_UpdatePage
  );
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.Page,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_PageService_DeletePage = new grpc.web.MethodDescriptor(
  '/usrpb.PageService/DeletePage',
  grpc.web.MethodType.UNARY,
  proto.usrpb.Page,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.usrpb.Page} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);

/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.usrpb.Page,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_PageService_DeletePage = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.usrpb.Page} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);

/**
 * @param {!proto.usrpb.Page} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.PageServiceClient.prototype.deletePage = function(
  request,
  metadata,
  callback
) {
  return this.client_.rpcCall(
    this.hostname_ + '/usrpb.PageService/DeletePage',
    request,
    metadata || {},
    methodDescriptor_PageService_DeletePage,
    callback
  );
};

/**
 * @param {!proto.usrpb.Page} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     A native promise that resolves to the response
 */
proto.usrpb.PageServicePromiseClient.prototype.deletePage = function(
  request,
  metadata
) {
  return this.client_.unaryCall(
    this.hostname_ + '/usrpb.PageService/DeletePage',
    request,
    metadata || {},
    methodDescriptor_PageService_DeletePage
  );
};

module.exports = proto.usrpb;
