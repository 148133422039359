import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme  } from '@material-ui/core/styles';
import color from "../../assets/css/colors";

const Timer = (props) => {
    const [minutes, setMinutes] = useState(props.minutes);
    const [seconds, setSeconds] =  useState(props.seconds);
    
    function resetTimer() {
        setMinutes(props.minutes);
        setSeconds(props.seconds);
    }
    
    useEffect(()=>{
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval);
                    props.setLoading({ timer: false });
                } else {
                    const minutesTimer = minutes - 1
                    setMinutes(minutesTimer);
                    setSeconds(59);
                    props.countdownListener(minutesTimer)
                }
            } 
        }, 1000)

        return ()=> {
            clearInterval(myInterval);
        };
    });

    const theme = useTheme();

    const useStyles = makeStyles(() => ({
        timer: {
            backgroundColor: seconds <= 10 && minutes === 0 
                ? seconds === 0 && minutes === 0 
                ? theme.palette.error.opacity 
                : theme.palette.warning.opacity 
                : theme.palette.default.opacity,
            color: seconds <= 10 && minutes === 0 
                ? seconds === 0 && minutes === 0 
                ? theme.palette.error.main 
                : theme.palette.warning.main 
                : theme.palette.default.main,
            border: seconds <= 10 && minutes === 0 
                ? seconds === 0 && minutes === 0 
                ? '1px solid ' + theme.palette.error.light 
                : '1px solid ' + theme.palette.warning.light 
                : color.gray.opacity,
            display: 'inline-block',
            padding: '6px 10px 7px 10px',
            marginRight: '-5px',
            fontSize: '14px',
            fontWeight: 'bold',
            lineHeight: '14px',
            whiteSpace: 'nowrap',
        },
    }));
    
    const classes = useStyles();

    return (
        <div id="timerReset" className="pointer-none" onClick={resetTimer}>
        { minutes === 0 && seconds === 0
            ? <span className={classes.timer}>Code Expired</span>
            : <span className={classes.timer}>{minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</span>
        }
        </div>
    )
}

export default Timer