import React from 'react';
import Files from '../../components/Pages/Files';

export default function Invoices() {
  return (
    <Files
      title="Invoices"
      type="Invoice"
    />
  );
}