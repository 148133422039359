import React, { useState, useEffect } from 'react';
import authSvc from '../../../services/AuthService';
import { useConfirm } from 'material-ui-confirm';

import SelectDefaultPageAccess from '../../../components/Dropdown/SelectDefaultPageAccess';
import SelectSubMenu from '../../../components/AutoComplete/SelectSubMenu';
import SelectMenu from '../../../components/AutoComplete/SelectMenu';
import SelectPage from '../../../components/AutoComplete/SelectPage';
import ClientGrid from '../../../components/ClientGrid/ClientGrid';
import Button from '../../../components/Buttons/Button';
import {
  notifySuccess,
  notifyError,
  notifyInfo,
} from '../../../components/Messages/Notification';

import { listUserAccess, saveUserAccess } from '../../../services/UserAccessService';

export default function AccessTable({ id, params }) {
  const confirm = useConfirm();

  const [loading, setLoading] = React.useState({ search: false, grid: false });
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = React.useState(false);

  const [cache, setCache] = React.useState({});
  const [searchData, setSearchData] = React.useState({
    userId: '',
    roleId: id,
    roleName: '',
    menu: '',
    subMenu: '',
    pageName: '',
    access: '',
  });

  const GridButtons = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    const rows = tableManager.rowsApi.rows;
    return (
      <div
        align={'left'}
        className="grd-row"
        style={{
          margin: 0,
          padding: 0,
          width: 200,
          textAlign: 'center',
          backgroundColor: 'whitesmoke',
        }}
      >
        <SelectDefaultPageAccess
          name="access"
          label=""
          value={data.access}
          cache={cache}
          setCache={handleCache}
          setLoaded={handleLoaded}
          loaded={loaded}
          onChange={(e) => {
            handleSave(rowIndex - 1, e.target.value, rows);
          }}
        ></SelectDefaultPageAccess>
      </div>
    );
  };

  const columns = [
    {
      id: 'menu',
      field: 'menu',
      label: 'Menu',
    },
    {
      id: 'subMenu',
      field: 'subMenu',
      label: 'Sub Menu',
    },
    {
      id: 'pageName',
      field: 'pageName',
      label: 'Page Name',
    },
    {
      id: 'access',
      field: 'access',
      label: 'Access',
      cellRenderer: GridButtons,
    },
  ];

  const handleSave = async (dataIndex, newAccessType, rows) => {
    try {
      const currentUser = authSvc.getCurrentUser();
      const data = rows[dataIndex];
      if (data.pageName === 'Access' && data.roleId === currentUser.RoleId) {
        confirm({
          description:
            'You are about to change your access rights on access page. Please confirm your action.',
          confirmationText: 'Yes, Update',
        }).then(async () => {
          try {
            await saveUserAccess(data, newAccessType);
            const copy = [...rows];
            copy[dataIndex].access = newAccessType;
            copy[dataIndex].roleId = id;
            setRows(copy);
            notifySuccess('User access was updated.');
            authSvc.refreshAccess();
          } catch (error) {
            notifyError(error.message);
          }
        });
      } else {
        try {
          await saveUserAccess(data, newAccessType);
          const copy = [...rows];
          copy[dataIndex].access = newAccessType;
          copy[dataIndex].roleId = id;
          setRows(copy);
          notifySuccess('User access was updated.');
          authSvc.refreshAccess();
        } catch (error) {
          notifyError(error.message);
        }
      }
    } catch (error) {
      notifyError(error.message);
    }
  };

  const handleCache = (data) => {
    setCache(data);
  };

  const handleLoaded = (data) => {
    setLoaded(data);
  };

  const handleMultipleEdit = async (data, newAccessType) => {
    const copy = [...rows];
    try {
      const currentUser = authSvc.getCurrentUser();

      setLoading({ ...loading, grid: true });

      for (let i = 0; i < data.length; i++) {
        const dataIndex = data[i].dataIndex;
        if (
          rows[dataIndex].pageName === 'Access' &&
          rows[dataIndex].roleId === currentUser.RoleId
        ) {
          await confirm({
            description:
              'You are about to change your access rights on access page. Please confirm your action.',
            confirmationText: 'Yes, Update',
          }).then(async () => {
            try {
              await saveUserAccess(rows[dataIndex], newAccessType);
              copy[dataIndex].access = newAccessType;
              copy[dataIndex].roleId = id;
              setRows(copy);
              authSvc.refreshAccess();
            } catch (error) {
              notifyError(error.message);
            }
          });
        } else {
          await saveUserAccess(rows[dataIndex], newAccessType);
          copy[dataIndex].access = newAccessType;
          copy[dataIndex].roleId = id;
          setRows(copy);
          authSvc.refreshAccess();
        }
      }
    } catch (error) {
      notifyError(error.message);
    } finally {
      handleSearch();
      notifySuccess(
        'User access for ' +
          data.length +
          ' pages was successfully updated to ' +
          newAccessType
      );
      setLoading({ ...loading, grid: false });
    }
  };

  useEffect(() => {
    handleSearch();
  }, []);

  const handleChange = (e, x) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    if (['roleName'].includes(input.name)) {
      searchData.roleId = input.roleId;
      searchData.roleName = input.value;
    }

    setSearchData({
      ...searchData,
      [input.name]: input.value,
    });
  };

  const handleSearch = async () => {
    setLoading({ ...loading, search: true });
    try {
      const userAccessesList = await listUserAccess(searchData);

      const clientUserAccessesList = userAccessesList.filter(page => page.menu === 'Client Site')
      notifyInfo(clientUserAccessesList.length + ' pages found');
      setRows(clientUserAccessesList);
    } catch (error) {
      notifyError(error.message);
    }

    setLoading({ ...loading, search: false });
  };

  const getCsvData = async () => {
    return rows;
  };

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell"></div>
          <div className="grd-cell-none">
            <Button
              disabled={loading.search}
              loading={loading.search}
              type="search"
              label={loading.search ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <SelectMenu
              disabled={searchData.roleId === '' || searchData.roleId === null}
              name="menu"
              label="Menu"
              value={searchData.menu}
              onChange={handleChange}
            ></SelectMenu>
          </div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <SelectSubMenu
              disabled={searchData.roleId === '' || searchData.roleId === null}
              name="subMenu"
              label="Sub Menu"
              value={searchData.subMenu}
              onChange={handleChange}
            ></SelectSubMenu>
          </div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <SelectPage
              disabled={searchData.roleId === '' || searchData.roleId === null}
              name="pageName"
              label="Page Name"
              value={searchData.pageName || ''}
              onChange={handleChange}
            ></SelectPage>
          </div>
          <div className="grd-cell">
            <SelectDefaultPageAccess
              disabled={searchData.roleId === '' || searchData.userId === null}
              name="access"
              label="Access"
              value={searchData.access}
              setCache={handleCache}
              setLoaded={handleLoaded}
              loaded={loaded}
              cache={cache}
              onChange={handleChange}
            ></SelectDefaultPageAccess>
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell" style={{ marginRight: 30 }}></div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <div
              className="grd-cell"
              style={{
                textAlign: 'right',
                marginTop: 30,
                color: '#1e7dff',
              }}
            >
              <small>Changes will be saved automatically.</small>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-20">
        <ClientGrid
          title="Page Access"
          rowIdField="id"
          columns={columns}
          rows={rows}
          setRows={setRows}
          getCsvData={getCsvData}
        />
      </div>
    </div>
  );
}
