import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import Text from '../../../../components/Typography/Text';
import Button from '../../../../components/Buttons/Button';
import CloseButton from '../../../../components/Buttons/CloseButton';
import fontFamily from '../../../../assets/css/fonts';
import color from '../../../../assets/css/colors';
import AutoCompleteAccountNo from '../../../../components/AutoComplete/AutoCompleteAccountNo';
import SelectAssetType from '../../../../components/Dropdown/SelectAssetType';
import SelectAction from '../../../../components/Dropdown/SelectAction';
import TextField from '../../../../components/Textfields/TextField';
import SelectTiming from '../../../../components/Dropdown/SelectTiming';
import { notifyWarning } from '../../../../components/Messages/Notification';
import lodash from 'lodash';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
}));

export default function ModelingModal(props) {
  const classes = useStyles();
  const { data, open, close, loading } = props;
  const [modalData, setModalData] = React.useState(data);

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;
    setModalData({ ...modalData, [input.name]: input.value });
  };

  const handleSubmit = () => {
    const valid = checkRequiredFields([
      'accountNo'
    ]);
    if (
      (modalData.share === '0' || modalData.share === '') &&
      (modalData.quantity === '0' || modalData.quantity === '')
    ) {
      notifyWarning('Share/Quantity is required.');
    } else if (valid) {
    console.log(modalData);
      close(modalData);
    }
  };

  const checkRequiredFields = (fieldNames) => {
    let valid = true;
    for (let index = 0; index < fieldNames.length; index++) {
      const field = fieldNames[index];
      if (!modalData[field]) {
        notifyWarning(`${lodash.startCase(field)} is required.`);
        valid = false;
      }
    }

    return valid;
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => close()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div style={{ width: 800 }}>
        <div className={classes.modalHeader}>
            <div>
              <Text
                mt={4}
                variant="h2"
                label={"Create Trade Sheet"}
              />
            </div>
            <div className={classes.closeButton}>
              <CloseButton onClick={() => close()} />
            </div>
          </div>
          <div className={classes.modalBody}>
            <div className="grd-row nm">
              <div className="grd-cell">
                <AutoCompleteAccountNo
                  isAccessibleOnly={true}
                  name="accountNo"
                  value={modalData.accountNo}
                  correspondent={modalData.correspondent}
                  onChange={handleChange}
                  required
                  freeSolo
                />
              </div>
            </div>
          </div>
          <div className={classes.modalFooter}>
            <Button
              disabled={loading}
              loading={loading}
              label={loading ? 'Saving...' : 'Save'}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
