// source: proto/feepb/feemanagerreport.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_type_date_pb = require('../../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.feepb.FeeManagerReport', null, global);
goog.exportSymbol('proto.feepb.FeeManagerReportDetails', null, global);
goog.exportSymbol('proto.feepb.ListFeeManagerReportDetailsRequest', null, global);
goog.exportSymbol('proto.feepb.ListFeeManagerReportDetailsResponse', null, global);
goog.exportSymbol('proto.feepb.ListFeeManagerReportRequest', null, global);
goog.exportSymbol('proto.feepb.ListFeeManagerReportResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.feepb.FeeManagerReport = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.feepb.FeeManagerReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.feepb.FeeManagerReport.displayName = 'proto.feepb.FeeManagerReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.feepb.FeeManagerReportDetails = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.feepb.FeeManagerReportDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.feepb.FeeManagerReportDetails.displayName = 'proto.feepb.FeeManagerReportDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.feepb.ListFeeManagerReportRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.feepb.ListFeeManagerReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.feepb.ListFeeManagerReportRequest.displayName =
    'proto.feepb.ListFeeManagerReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.feepb.ListFeeManagerReportResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.feepb.ListFeeManagerReportResponse.repeatedFields_,
    null
  );
};
goog.inherits(proto.feepb.ListFeeManagerReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.feepb.ListFeeManagerReportResponse.displayName =
    'proto.feepb.ListFeeManagerReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.feepb.ListFeeManagerReportDetailsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.feepb.ListFeeManagerReportDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.feepb.ListFeeManagerReportDetailsRequest.displayName =
    'proto.feepb.ListFeeManagerReportDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.feepb.ListFeeManagerReportDetailsResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.feepb.ListFeeManagerReportDetailsResponse.repeatedFields_,
    null
  );
};
goog.inherits(proto.feepb.ListFeeManagerReportDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.feepb.ListFeeManagerReportDetailsResponse.displayName =
    'proto.feepb.ListFeeManagerReportDetailsResponse';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.feepb.FeeManagerReport.prototype.toObject = function (opt_includeInstance) {
    return proto.feepb.FeeManagerReport.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.feepb.FeeManagerReport} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.feepb.FeeManagerReport.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        fromDate:
          (f = msg.getFromDate()) &&
          google_type_date_pb.Date.toObject(includeInstance, f),
        toDate:
          (f = msg.getToDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
        accountId: jspb.Message.getFieldWithDefault(msg, 3, 0),
        correspondent: jspb.Message.getFieldWithDefault(msg, 4, ''),
        branch: jspb.Message.getFieldWithDefault(msg, 5, ''),
        accountNo: jspb.Message.getFieldWithDefault(msg, 6, ''),
        masterAccountNo: jspb.Message.getFieldWithDefault(msg, 7, ''),
        rep: jspb.Message.getFieldWithDefault(msg, 8, ''),
        broker: jspb.Message.getFieldWithDefault(msg, 9, ''),
        accountName: jspb.Message.getFieldWithDefault(msg, 10, ''),
        fee: jspb.Message.getFieldWithDefault(msg, 11, ''),
        feeType: jspb.Message.getFieldWithDefault(msg, 12, ''),
        status: jspb.Message.getFieldWithDefault(msg, 13, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.feepb.FeeManagerReport}
 */
proto.feepb.FeeManagerReport.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.feepb.FeeManagerReport();
  return proto.feepb.FeeManagerReport.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.feepb.FeeManagerReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.feepb.FeeManagerReport}
 */
proto.feepb.FeeManagerReport.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new google_type_date_pb.Date();
        reader.readMessage(value, google_type_date_pb.Date.deserializeBinaryFromReader);
        msg.setFromDate(value);
        break;
      case 2:
        var value = new google_type_date_pb.Date();
        reader.readMessage(value, google_type_date_pb.Date.deserializeBinaryFromReader);
        msg.setToDate(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setAccountId(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setCorrespondent(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setBranch(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setAccountNo(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setMasterAccountNo(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setRep(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setBroker(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setAccountName(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setFee(value);
        break;
      case 12:
        var value = /** @type {string} */ (reader.readString());
        msg.setFeeType(value);
        break;
      case 13:
        var value = /** @type {string} */ (reader.readString());
        msg.setStatus(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.feepb.FeeManagerReport.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.feepb.FeeManagerReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.feepb.FeeManagerReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.FeeManagerReport.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getFromDate();
  if (f != null) {
    writer.writeMessage(1, f, google_type_date_pb.Date.serializeBinaryToWriter);
  }
  f = message.getToDate();
  if (f != null) {
    writer.writeMessage(2, f, google_type_date_pb.Date.serializeBinaryToWriter);
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(3, f);
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(7, f);
  }
  f = message.getRep();
  if (f.length > 0) {
    writer.writeString(8, f);
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(9, f);
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(10, f);
  }
  f = message.getFee();
  if (f.length > 0) {
    writer.writeString(11, f);
  }
  f = message.getFeeType();
  if (f.length > 0) {
    writer.writeString(12, f);
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(13, f);
  }
};

/**
 * optional google.type.Date from_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.feepb.FeeManagerReport.prototype.getFromDate = function () {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1)
  );
};

/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.feepb.FeeManagerReport} returns this
 */
proto.feepb.FeeManagerReport.prototype.setFromDate = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.feepb.FeeManagerReport} returns this
 */
proto.feepb.FeeManagerReport.prototype.clearFromDate = function () {
  return this.setFromDate(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.feepb.FeeManagerReport.prototype.hasFromDate = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional google.type.Date to_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.feepb.FeeManagerReport.prototype.getToDate = function () {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2)
  );
};

/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.feepb.FeeManagerReport} returns this
 */
proto.feepb.FeeManagerReport.prototype.setToDate = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.feepb.FeeManagerReport} returns this
 */
proto.feepb.FeeManagerReport.prototype.clearToDate = function () {
  return this.setToDate(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.feepb.FeeManagerReport.prototype.hasToDate = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional uint32 account_id = 3;
 * @return {number}
 */
proto.feepb.FeeManagerReport.prototype.getAccountId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.feepb.FeeManagerReport} returns this
 */
proto.feepb.FeeManagerReport.prototype.setAccountId = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional string correspondent = 4;
 * @return {string}
 */
proto.feepb.FeeManagerReport.prototype.getCorrespondent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.FeeManagerReport} returns this
 */
proto.feepb.FeeManagerReport.prototype.setCorrespondent = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string branch = 5;
 * @return {string}
 */
proto.feepb.FeeManagerReport.prototype.getBranch = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.FeeManagerReport} returns this
 */
proto.feepb.FeeManagerReport.prototype.setBranch = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional string account_no = 6;
 * @return {string}
 */
proto.feepb.FeeManagerReport.prototype.getAccountNo = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.FeeManagerReport} returns this
 */
proto.feepb.FeeManagerReport.prototype.setAccountNo = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional string master_account_no = 7;
 * @return {string}
 */
proto.feepb.FeeManagerReport.prototype.getMasterAccountNo = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.FeeManagerReport} returns this
 */
proto.feepb.FeeManagerReport.prototype.setMasterAccountNo = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};

/**
 * optional string rep = 8;
 * @return {string}
 */
proto.feepb.FeeManagerReport.prototype.getRep = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.FeeManagerReport} returns this
 */
proto.feepb.FeeManagerReport.prototype.setRep = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};

/**
 * optional string broker = 9;
 * @return {string}
 */
proto.feepb.FeeManagerReport.prototype.getBroker = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.FeeManagerReport} returns this
 */
proto.feepb.FeeManagerReport.prototype.setBroker = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};

/**
 * optional string account_name = 10;
 * @return {string}
 */
proto.feepb.FeeManagerReport.prototype.getAccountName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.FeeManagerReport} returns this
 */
proto.feepb.FeeManagerReport.prototype.setAccountName = function (value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};

/**
 * optional string fee = 11;
 * @return {string}
 */
proto.feepb.FeeManagerReport.prototype.getFee = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.FeeManagerReport} returns this
 */
proto.feepb.FeeManagerReport.prototype.setFee = function (value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};

/**
 * optional string fee_type = 12;
 * @return {string}
 */
proto.feepb.FeeManagerReport.prototype.getFeeType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.FeeManagerReport} returns this
 */
proto.feepb.FeeManagerReport.prototype.setFeeType = function (value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};

/**
 * optional string status = 13;
 * @return {string}
 */
proto.feepb.FeeManagerReport.prototype.getStatus = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.FeeManagerReport} returns this
 */
proto.feepb.FeeManagerReport.prototype.setStatus = function (value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.feepb.FeeManagerReportDetails.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.feepb.FeeManagerReportDetails.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.feepb.FeeManagerReportDetails} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.feepb.FeeManagerReportDetails.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        tradeDate:
          (f = msg.getTradeDate()) &&
          google_type_date_pb.Date.toObject(includeInstance, f),
        settleDate:
          (f = msg.getSettleDate()) &&
          google_type_date_pb.Date.toObject(includeInstance, f),
        systemDate:
          (f = msg.getSystemDate()) &&
          google_type_date_pb.Date.toObject(includeInstance, f),
        trnsId: jspb.Message.getFieldWithDefault(msg, 4, 0),
        accountId: jspb.Message.getFieldWithDefault(msg, 5, 0),
        correspondent: jspb.Message.getFieldWithDefault(msg, 6, ''),
        branch: jspb.Message.getFieldWithDefault(msg, 7, ''),
        accountNo: jspb.Message.getFieldWithDefault(msg, 8, ''),
        masterAccountNo: jspb.Message.getFieldWithDefault(msg, 9, ''),
        rep: jspb.Message.getFieldWithDefault(msg, 10, ''),
        broker: jspb.Message.getFieldWithDefault(msg, 11, ''),
        accountName: jspb.Message.getFieldWithDefault(msg, 12, ''),
        feeType: jspb.Message.getFieldWithDefault(msg, 13, ''),
        fee: jspb.Message.getFieldWithDefault(msg, 14, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.feepb.FeeManagerReportDetails}
 */
proto.feepb.FeeManagerReportDetails.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.feepb.FeeManagerReportDetails();
  return proto.feepb.FeeManagerReportDetails.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.feepb.FeeManagerReportDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.feepb.FeeManagerReportDetails}
 */
proto.feepb.FeeManagerReportDetails.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new google_type_date_pb.Date();
        reader.readMessage(value, google_type_date_pb.Date.deserializeBinaryFromReader);
        msg.setTradeDate(value);
        break;
      case 2:
        var value = new google_type_date_pb.Date();
        reader.readMessage(value, google_type_date_pb.Date.deserializeBinaryFromReader);
        msg.setSettleDate(value);
        break;
      case 3:
        var value = new google_type_date_pb.Date();
        reader.readMessage(value, google_type_date_pb.Date.deserializeBinaryFromReader);
        msg.setSystemDate(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setTrnsId(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setAccountId(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setCorrespondent(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setBranch(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setAccountNo(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setMasterAccountNo(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setRep(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setBroker(value);
        break;
      case 12:
        var value = /** @type {string} */ (reader.readString());
        msg.setAccountName(value);
        break;
      case 13:
        var value = /** @type {string} */ (reader.readString());
        msg.setFeeType(value);
        break;
      case 14:
        var value = /** @type {string} */ (reader.readString());
        msg.setFee(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.feepb.FeeManagerReportDetails.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.feepb.FeeManagerReportDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.feepb.FeeManagerReportDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.FeeManagerReportDetails.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTradeDate();
  if (f != null) {
    writer.writeMessage(1, f, google_type_date_pb.Date.serializeBinaryToWriter);
  }
  f = message.getSettleDate();
  if (f != null) {
    writer.writeMessage(2, f, google_type_date_pb.Date.serializeBinaryToWriter);
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(3, f, google_type_date_pb.Date.serializeBinaryToWriter);
  }
  f = message.getTrnsId();
  if (f !== 0) {
    writer.writeUint32(4, f);
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(5, f);
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(7, f);
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(8, f);
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(9, f);
  }
  f = message.getRep();
  if (f.length > 0) {
    writer.writeString(10, f);
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(11, f);
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(12, f);
  }
  f = message.getFeeType();
  if (f.length > 0) {
    writer.writeString(13, f);
  }
  f = message.getFee();
  if (f.length > 0) {
    writer.writeString(14, f);
  }
};

/**
 * optional google.type.Date trade_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.feepb.FeeManagerReportDetails.prototype.getTradeDate = function () {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1)
  );
};

/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.feepb.FeeManagerReportDetails} returns this
 */
proto.feepb.FeeManagerReportDetails.prototype.setTradeDate = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.feepb.FeeManagerReportDetails} returns this
 */
proto.feepb.FeeManagerReportDetails.prototype.clearTradeDate = function () {
  return this.setTradeDate(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.feepb.FeeManagerReportDetails.prototype.hasTradeDate = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional google.type.Date settle_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.feepb.FeeManagerReportDetails.prototype.getSettleDate = function () {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2)
  );
};

/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.feepb.FeeManagerReportDetails} returns this
 */
proto.feepb.FeeManagerReportDetails.prototype.setSettleDate = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.feepb.FeeManagerReportDetails} returns this
 */
proto.feepb.FeeManagerReportDetails.prototype.clearSettleDate = function () {
  return this.setSettleDate(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.feepb.FeeManagerReportDetails.prototype.hasSettleDate = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional google.type.Date system_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.feepb.FeeManagerReportDetails.prototype.getSystemDate = function () {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3)
  );
};

/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.feepb.FeeManagerReportDetails} returns this
 */
proto.feepb.FeeManagerReportDetails.prototype.setSystemDate = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.feepb.FeeManagerReportDetails} returns this
 */
proto.feepb.FeeManagerReportDetails.prototype.clearSystemDate = function () {
  return this.setSystemDate(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.feepb.FeeManagerReportDetails.prototype.hasSystemDate = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional uint32 trns_id = 4;
 * @return {number}
 */
proto.feepb.FeeManagerReportDetails.prototype.getTrnsId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.feepb.FeeManagerReportDetails} returns this
 */
proto.feepb.FeeManagerReportDetails.prototype.setTrnsId = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional uint32 account_id = 5;
 * @return {number}
 */
proto.feepb.FeeManagerReportDetails.prototype.getAccountId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/**
 * @param {number} value
 * @return {!proto.feepb.FeeManagerReportDetails} returns this
 */
proto.feepb.FeeManagerReportDetails.prototype.setAccountId = function (value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * optional string correspondent = 6;
 * @return {string}
 */
proto.feepb.FeeManagerReportDetails.prototype.getCorrespondent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.FeeManagerReportDetails} returns this
 */
proto.feepb.FeeManagerReportDetails.prototype.setCorrespondent = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional string branch = 7;
 * @return {string}
 */
proto.feepb.FeeManagerReportDetails.prototype.getBranch = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.FeeManagerReportDetails} returns this
 */
proto.feepb.FeeManagerReportDetails.prototype.setBranch = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};

/**
 * optional string account_no = 8;
 * @return {string}
 */
proto.feepb.FeeManagerReportDetails.prototype.getAccountNo = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.FeeManagerReportDetails} returns this
 */
proto.feepb.FeeManagerReportDetails.prototype.setAccountNo = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};

/**
 * optional string master_account_no = 9;
 * @return {string}
 */
proto.feepb.FeeManagerReportDetails.prototype.getMasterAccountNo = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.FeeManagerReportDetails} returns this
 */
proto.feepb.FeeManagerReportDetails.prototype.setMasterAccountNo = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};

/**
 * optional string rep = 10;
 * @return {string}
 */
proto.feepb.FeeManagerReportDetails.prototype.getRep = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.FeeManagerReportDetails} returns this
 */
proto.feepb.FeeManagerReportDetails.prototype.setRep = function (value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};

/**
 * optional string broker = 11;
 * @return {string}
 */
proto.feepb.FeeManagerReportDetails.prototype.getBroker = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.FeeManagerReportDetails} returns this
 */
proto.feepb.FeeManagerReportDetails.prototype.setBroker = function (value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};

/**
 * optional string account_name = 12;
 * @return {string}
 */
proto.feepb.FeeManagerReportDetails.prototype.getAccountName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.FeeManagerReportDetails} returns this
 */
proto.feepb.FeeManagerReportDetails.prototype.setAccountName = function (value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};

/**
 * optional string fee_type = 13;
 * @return {string}
 */
proto.feepb.FeeManagerReportDetails.prototype.getFeeType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.FeeManagerReportDetails} returns this
 */
proto.feepb.FeeManagerReportDetails.prototype.setFeeType = function (value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};

/**
 * optional string fee = 14;
 * @return {string}
 */
proto.feepb.FeeManagerReportDetails.prototype.getFee = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.FeeManagerReportDetails} returns this
 */
proto.feepb.FeeManagerReportDetails.prototype.setFee = function (value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.feepb.ListFeeManagerReportRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.feepb.ListFeeManagerReportRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.feepb.ListFeeManagerReportRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.feepb.ListFeeManagerReportRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        dateType: jspb.Message.getFieldWithDefault(msg, 1, ''),
        fromDate:
          (f = msg.getFromDate()) &&
          google_type_date_pb.Date.toObject(includeInstance, f),
        toDate:
          (f = msg.getToDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
        feeType: jspb.Message.getFieldWithDefault(msg, 4, ''),
        branch: jspb.Message.getFieldWithDefault(msg, 5, ''),
        accountNo: jspb.Message.getFieldWithDefault(msg, 6, ''),
        masterAccountNo: jspb.Message.getFieldWithDefault(msg, 7, ''),
        rep: jspb.Message.getFieldWithDefault(msg, 8, ''),
        broker: jspb.Message.getFieldWithDefault(msg, 9, ''),
        correspondent: jspb.Message.getFieldWithDefault(msg, 10, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.feepb.ListFeeManagerReportRequest}
 */
proto.feepb.ListFeeManagerReportRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.feepb.ListFeeManagerReportRequest();
  return proto.feepb.ListFeeManagerReportRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.feepb.ListFeeManagerReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.feepb.ListFeeManagerReportRequest}
 */
proto.feepb.ListFeeManagerReportRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setDateType(value);
        break;
      case 2:
        var value = new google_type_date_pb.Date();
        reader.readMessage(value, google_type_date_pb.Date.deserializeBinaryFromReader);
        msg.setFromDate(value);
        break;
      case 3:
        var value = new google_type_date_pb.Date();
        reader.readMessage(value, google_type_date_pb.Date.deserializeBinaryFromReader);
        msg.setToDate(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setFeeType(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setBranch(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setAccountNo(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setMasterAccountNo(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setRep(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setBroker(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setCorrespondent(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.feepb.ListFeeManagerReportRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.feepb.ListFeeManagerReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.feepb.ListFeeManagerReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.ListFeeManagerReportRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getDateType();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getFromDate();
  if (f != null) {
    writer.writeMessage(2, f, google_type_date_pb.Date.serializeBinaryToWriter);
  }
  f = message.getToDate();
  if (f != null) {
    writer.writeMessage(3, f, google_type_date_pb.Date.serializeBinaryToWriter);
  }
  f = message.getFeeType();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(7, f);
  }
  f = message.getRep();
  if (f.length > 0) {
    writer.writeString(8, f);
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(9, f);
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(10, f);
  }
};

/**
 * optional string date_type = 1;
 * @return {string}
 */
proto.feepb.ListFeeManagerReportRequest.prototype.getDateType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.ListFeeManagerReportRequest} returns this
 */
proto.feepb.ListFeeManagerReportRequest.prototype.setDateType = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional google.type.Date from_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.feepb.ListFeeManagerReportRequest.prototype.getFromDate = function () {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2)
  );
};

/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.feepb.ListFeeManagerReportRequest} returns this
 */
proto.feepb.ListFeeManagerReportRequest.prototype.setFromDate = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.feepb.ListFeeManagerReportRequest} returns this
 */
proto.feepb.ListFeeManagerReportRequest.prototype.clearFromDate = function () {
  return this.setFromDate(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.feepb.ListFeeManagerReportRequest.prototype.hasFromDate = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional google.type.Date to_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.feepb.ListFeeManagerReportRequest.prototype.getToDate = function () {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3)
  );
};

/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.feepb.ListFeeManagerReportRequest} returns this
 */
proto.feepb.ListFeeManagerReportRequest.prototype.setToDate = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.feepb.ListFeeManagerReportRequest} returns this
 */
proto.feepb.ListFeeManagerReportRequest.prototype.clearToDate = function () {
  return this.setToDate(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.feepb.ListFeeManagerReportRequest.prototype.hasToDate = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional string fee_type = 4;
 * @return {string}
 */
proto.feepb.ListFeeManagerReportRequest.prototype.getFeeType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.ListFeeManagerReportRequest} returns this
 */
proto.feepb.ListFeeManagerReportRequest.prototype.setFeeType = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string branch = 5;
 * @return {string}
 */
proto.feepb.ListFeeManagerReportRequest.prototype.getBranch = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.ListFeeManagerReportRequest} returns this
 */
proto.feepb.ListFeeManagerReportRequest.prototype.setBranch = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional string account_no = 6;
 * @return {string}
 */
proto.feepb.ListFeeManagerReportRequest.prototype.getAccountNo = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.ListFeeManagerReportRequest} returns this
 */
proto.feepb.ListFeeManagerReportRequest.prototype.setAccountNo = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional string master_account_no = 7;
 * @return {string}
 */
proto.feepb.ListFeeManagerReportRequest.prototype.getMasterAccountNo = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.ListFeeManagerReportRequest} returns this
 */
proto.feepb.ListFeeManagerReportRequest.prototype.setMasterAccountNo = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};

/**
 * optional string rep = 8;
 * @return {string}
 */
proto.feepb.ListFeeManagerReportRequest.prototype.getRep = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.ListFeeManagerReportRequest} returns this
 */
proto.feepb.ListFeeManagerReportRequest.prototype.setRep = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};

/**
 * optional string broker = 9;
 * @return {string}
 */
proto.feepb.ListFeeManagerReportRequest.prototype.getBroker = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.ListFeeManagerReportRequest} returns this
 */
proto.feepb.ListFeeManagerReportRequest.prototype.setBroker = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};

/**
 * optional string correspondent = 10;
 * @return {string}
 */
proto.feepb.ListFeeManagerReportRequest.prototype.getCorrespondent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.ListFeeManagerReportRequest} returns this
 */
proto.feepb.ListFeeManagerReportRequest.prototype.setCorrespondent = function (value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.feepb.ListFeeManagerReportResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.feepb.ListFeeManagerReportResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.feepb.ListFeeManagerReportResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.feepb.ListFeeManagerReportResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.feepb.ListFeeManagerReportResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        feeManagerReportsList: jspb.Message.toObjectList(
          msg.getFeeManagerReportsList(),
          proto.feepb.FeeManagerReport.toObject,
          includeInstance
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.feepb.ListFeeManagerReportResponse}
 */
proto.feepb.ListFeeManagerReportResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.feepb.ListFeeManagerReportResponse();
  return proto.feepb.ListFeeManagerReportResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.feepb.ListFeeManagerReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.feepb.ListFeeManagerReportResponse}
 */
proto.feepb.ListFeeManagerReportResponse.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.feepb.FeeManagerReport();
        reader.readMessage(
          value,
          proto.feepb.FeeManagerReport.deserializeBinaryFromReader
        );
        msg.addFeeManagerReports(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.feepb.ListFeeManagerReportResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.feepb.ListFeeManagerReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.feepb.ListFeeManagerReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.ListFeeManagerReportResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getFeeManagerReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.feepb.FeeManagerReport.serializeBinaryToWriter
    );
  }
};

/**
 * repeated FeeManagerReport fee_manager_reports = 1;
 * @return {!Array<!proto.feepb.FeeManagerReport>}
 */
proto.feepb.ListFeeManagerReportResponse.prototype.getFeeManagerReportsList =
  function () {
    return /** @type{!Array<!proto.feepb.FeeManagerReport>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.feepb.FeeManagerReport, 1)
    );
  };

/**
 * @param {!Array<!proto.feepb.FeeManagerReport>} value
 * @return {!proto.feepb.ListFeeManagerReportResponse} returns this
 */
proto.feepb.ListFeeManagerReportResponse.prototype.setFeeManagerReportsList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.feepb.FeeManagerReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.feepb.FeeManagerReport}
 */
proto.feepb.ListFeeManagerReportResponse.prototype.addFeeManagerReports = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.feepb.FeeManagerReport,
    opt_index
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.feepb.ListFeeManagerReportResponse} returns this
 */
proto.feepb.ListFeeManagerReportResponse.prototype.clearFeeManagerReportsList =
  function () {
    return this.setFeeManagerReportsList([]);
  };

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.feepb.ListFeeManagerReportDetailsRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.feepb.ListFeeManagerReportDetailsRequest.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.feepb.ListFeeManagerReportDetailsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.feepb.ListFeeManagerReportDetailsRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        dateType: jspb.Message.getFieldWithDefault(msg, 1, ''),
        feeType: jspb.Message.getFieldWithDefault(msg, 2, ''),
        fromDate:
          (f = msg.getFromDate()) &&
          google_type_date_pb.Date.toObject(includeInstance, f),
        toDate:
          (f = msg.getToDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
        accountId: jspb.Message.getFieldWithDefault(msg, 5, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.feepb.ListFeeManagerReportDetailsRequest}
 */
proto.feepb.ListFeeManagerReportDetailsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.feepb.ListFeeManagerReportDetailsRequest();
  return proto.feepb.ListFeeManagerReportDetailsRequest.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.feepb.ListFeeManagerReportDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.feepb.ListFeeManagerReportDetailsRequest}
 */
proto.feepb.ListFeeManagerReportDetailsRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setDateType(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setFeeType(value);
        break;
      case 3:
        var value = new google_type_date_pb.Date();
        reader.readMessage(value, google_type_date_pb.Date.deserializeBinaryFromReader);
        msg.setFromDate(value);
        break;
      case 4:
        var value = new google_type_date_pb.Date();
        reader.readMessage(value, google_type_date_pb.Date.deserializeBinaryFromReader);
        msg.setToDate(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setAccountId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.feepb.ListFeeManagerReportDetailsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.feepb.ListFeeManagerReportDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.feepb.ListFeeManagerReportDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.ListFeeManagerReportDetailsRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getDateType();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getFeeType();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getFromDate();
  if (f != null) {
    writer.writeMessage(3, f, google_type_date_pb.Date.serializeBinaryToWriter);
  }
  f = message.getToDate();
  if (f != null) {
    writer.writeMessage(4, f, google_type_date_pb.Date.serializeBinaryToWriter);
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(5, f);
  }
};

/**
 * optional string date_type = 1;
 * @return {string}
 */
proto.feepb.ListFeeManagerReportDetailsRequest.prototype.getDateType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.ListFeeManagerReportDetailsRequest} returns this
 */
proto.feepb.ListFeeManagerReportDetailsRequest.prototype.setDateType = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string fee_type = 2;
 * @return {string}
 */
proto.feepb.ListFeeManagerReportDetailsRequest.prototype.getFeeType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.feepb.ListFeeManagerReportDetailsRequest} returns this
 */
proto.feepb.ListFeeManagerReportDetailsRequest.prototype.setFeeType = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional google.type.Date from_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.feepb.ListFeeManagerReportDetailsRequest.prototype.getFromDate = function () {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3)
  );
};

/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.feepb.ListFeeManagerReportDetailsRequest} returns this
 */
proto.feepb.ListFeeManagerReportDetailsRequest.prototype.setFromDate = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.feepb.ListFeeManagerReportDetailsRequest} returns this
 */
proto.feepb.ListFeeManagerReportDetailsRequest.prototype.clearFromDate = function () {
  return this.setFromDate(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.feepb.ListFeeManagerReportDetailsRequest.prototype.hasFromDate = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional google.type.Date to_date = 4;
 * @return {?proto.google.type.Date}
 */
proto.feepb.ListFeeManagerReportDetailsRequest.prototype.getToDate = function () {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 4)
  );
};

/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.feepb.ListFeeManagerReportDetailsRequest} returns this
 */
proto.feepb.ListFeeManagerReportDetailsRequest.prototype.setToDate = function (value) {
  return jspb.Message.setWrapperField(this, 4, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.feepb.ListFeeManagerReportDetailsRequest} returns this
 */
proto.feepb.ListFeeManagerReportDetailsRequest.prototype.clearToDate = function () {
  return this.setToDate(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.feepb.ListFeeManagerReportDetailsRequest.prototype.hasToDate = function () {
  return jspb.Message.getField(this, 4) != null;
};

/**
 * optional uint32 account_id = 5;
 * @return {number}
 */
proto.feepb.ListFeeManagerReportDetailsRequest.prototype.getAccountId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/**
 * @param {number} value
 * @return {!proto.feepb.ListFeeManagerReportDetailsRequest} returns this
 */
proto.feepb.ListFeeManagerReportDetailsRequest.prototype.setAccountId = function (value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.feepb.ListFeeManagerReportDetailsResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.feepb.ListFeeManagerReportDetailsResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.feepb.ListFeeManagerReportDetailsResponse.toObject(
      opt_includeInstance,
      this
    );
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.feepb.ListFeeManagerReportDetailsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.feepb.ListFeeManagerReportDetailsResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        feeManagerReportDetailsList: jspb.Message.toObjectList(
          msg.getFeeManagerReportDetailsList(),
          proto.feepb.FeeManagerReportDetails.toObject,
          includeInstance
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.feepb.ListFeeManagerReportDetailsResponse}
 */
proto.feepb.ListFeeManagerReportDetailsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.feepb.ListFeeManagerReportDetailsResponse();
  return proto.feepb.ListFeeManagerReportDetailsResponse.deserializeBinaryFromReader(
    msg,
    reader
  );
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.feepb.ListFeeManagerReportDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.feepb.ListFeeManagerReportDetailsResponse}
 */
proto.feepb.ListFeeManagerReportDetailsResponse.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.feepb.FeeManagerReportDetails();
        reader.readMessage(
          value,
          proto.feepb.FeeManagerReportDetails.deserializeBinaryFromReader
        );
        msg.addFeeManagerReportDetails(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.feepb.ListFeeManagerReportDetailsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.feepb.ListFeeManagerReportDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.feepb.ListFeeManagerReportDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.feepb.ListFeeManagerReportDetailsResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined;
  f = message.getFeeManagerReportDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.feepb.FeeManagerReportDetails.serializeBinaryToWriter
    );
  }
};

/**
 * repeated FeeManagerReportDetails fee_manager_report_details = 1;
 * @return {!Array<!proto.feepb.FeeManagerReportDetails>}
 */
proto.feepb.ListFeeManagerReportDetailsResponse.prototype.getFeeManagerReportDetailsList =
  function () {
    return /** @type{!Array<!proto.feepb.FeeManagerReportDetails>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.feepb.FeeManagerReportDetails, 1)
    );
  };

/**
 * @param {!Array<!proto.feepb.FeeManagerReportDetails>} value
 * @return {!proto.feepb.ListFeeManagerReportDetailsResponse} returns this
 */
proto.feepb.ListFeeManagerReportDetailsResponse.prototype.setFeeManagerReportDetailsList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value);
  };

/**
 * @param {!proto.feepb.FeeManagerReportDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.feepb.FeeManagerReportDetails}
 */
proto.feepb.ListFeeManagerReportDetailsResponse.prototype.addFeeManagerReportDetails =
  function (opt_value, opt_index) {
    return jspb.Message.addToRepeatedWrapperField(
      this,
      1,
      opt_value,
      proto.feepb.FeeManagerReportDetails,
      opt_index
    );
  };

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.feepb.ListFeeManagerReportDetailsResponse} returns this
 */
proto.feepb.ListFeeManagerReportDetailsResponse.prototype.clearFeeManagerReportDetailsList =
  function () {
    return this.setFeeManagerReportDetailsList([]);
  };

goog.object.extend(exports, proto.feepb);
