import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Search from './Search';
import Download from './Download';
import ToggleColumns from './ToggleColumns';
import SaveGrid from './SaveGrid';
import Filter from './Filter';
import { readTableSettings } from '../../services/TableSettingsService';
import authSvc from '../../services/AuthService';
import FileDownload from './FileDownload';

const useStyles = makeStyles(() => ({
  toolbarContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 15px',
    width: '100%',
    height: 70,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderTop: '1px solid #ccc6ff',
    borderLeft: '1px solid #ccc6ff',
    borderRight: '1px solid #ccc6ff',
  },
  tableTitle: {
    flex: 1,
    padding: '12px 0px',
    '& .MuiTypography-h4': {
      fontWeight: 700,
    },
  },
  tableActions: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    '& div:first-child': {
      flex: 1,
    },
    '& > div': {
      flex: 'none',
      padding: '2px 0px',
      overflow: 'hidden',
    },
    '& .MuiIconButton-root': {
      padding: 10,
      borderRadius: 5,
      position: 'relative',
      zIndex: 3,
      '& svg': {
        width: 22,
        height: 22,
      },
    },
  },
  search: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function GridHeader({ tableManager }) {
  const classes = useStyles();
  const user = authSvc.getCurrentUser();

  const {
    config,
    searchApi,
    columnsVisibilityApi,
    columnsApi,
    rowsApi,
    rowSelectionApi,
  } = tableManager;

  const { columns } = columnsApi;

  const tableName = config.additionalProps.header.props.title;
  const setRows = config.additionalProps.header.props.setRows;
  const showDownloadBatchAction =
    config.additionalProps.header.props.showDownloadBatchAction;
  const batchDownloadParams = config.additionalProps.header.props.batchDownloadParams;
  const nosearch = config.additionalProps.header.props.nosearch;

  const [hasTableSettings, setHasTableSettings] = React.useState(false);
  const [isTableSettingsChecked, setIsTableSettingsChecked] = React.useState(false);
  const [columnsState, setColumnsState] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      handleCheckTableSettings();
    })();
  }, []);

  const handleUpdateColumnsState = (res) => {
    let columnSettings = {
      field: res.visibleColumns.split(','),
      order: res.columnOrder.split(','),
    };

    let newColumnsState = [];

    columns.map((col) => {
      for (let i = 0; i < columns.length; i++) {
        if (col.field === columnSettings.field[i]) {
          if (columnSettings.field[i] !== undefined) {
            return newColumnsState.push({
              ...col,
              index: parseInt(columnSettings.order[i]),
              visible: true,
            });
          }
        }
        if (col.field === undefined || col.field === '') {
          return newColumnsState.push(col);
        }
      }
      return newColumnsState.push({ ...col, visible: false });
    });

    newColumnsState.sort((a, b) => {
      return a.index - b.index;
    });

    setColumnsState(newColumnsState);
  };

  const handleCheckTableSettings = async () => {
    try {
      const params = {
        title: tableName,
        userId: user.UserId,
      };

      const res = await readTableSettings(params);

      handleUpdateColumnsState(res);
      setHasTableSettings(true);
      setIsTableSettingsChecked(true);
    } catch (error) {
      setHasTableSettings(false);
      setIsTableSettingsChecked(true);
    }
  };

  return (
    <div className={classes.toolbarContainer}>
      <div className={classes.tableActions}>
        <div className={classes.search}>
          {!nosearch && <Search searchApi={searchApi} />}
        </div>
        <div>
          {showDownloadBatchAction ? (
            <FileDownload
              title={tableName}
              parameters={batchDownloadParams}
              rowsApi={rowsApi.rows}
              rowSelectionApi={rowSelectionApi}
            />
          ) : null}
        </div>
        <div>
          <Download
            title={tableName}
            data={rowsApi.rows}
            getCsvData={config.additionalProps.header.props.getCsvData}
          />
        </div>
        <div>
          <SaveGrid
            title={tableName}
            userId={user.UserId}
            columnsApi={columnsApi}
            hasTableSettings={hasTableSettings}
            handleCheckTableSettings={handleCheckTableSettings}
          />
        </div>
        <div>
          {isTableSettingsChecked ? (
            <ToggleColumns
              columnsApi={columnsApi}
              columnsVisibilityApi={columnsVisibilityApi}
              hasTableSettings={hasTableSettings}
              columnsState={columnsState}
            />
          ) : null}
        </div>
        <div>
          <Filter columnsApi={columnsApi} rowsApi={rowsApi} setRows={setRows} />
        </div>
      </div>
    </div>
  );
}
