import { createTheme } from '@material-ui/core/styles';
import { getPalette } from './palette';
import fontFamily from './fonts';

export const theme = (paletteName) => {
  const palette = getPalette(paletteName);

  return createTheme({
    palette: palette,
    overrides: {
      MuiAvatar: {},
      MuiBadge: {},
      MuiBreadcrumbs: {},
      MuiButton: {},
      MuiButtonGroup: {},
      MuiCard: {},
      MuiCardActions: {},
      MuiCardContent: {},
      MuiCardMedia: {},
      MuiCardHeader: {},
      MuiCheckbox: {},
      MuiChip: {},
      MuiDialog: {},
      MuiDialogActions: {},
      MuiDialogContent: {},
      MuiDialogContentText: {},
      MuiDialogTitle: {},
      MuiLinearProgress: {},
      MuiIconButton: {},
      MuiModal: {},
      MuiRadio: {},
      MuiRadioGroup: {},
      MuiSelect: {},
      MuiSnackbar: {},
      MuiTypography: {},
      MuiTextField: {},
      MuiFormControl: {},
      MuiPopover: {},
      MuiInputBase: {},
      MuiInputLabel: {},
      MuiInput: {},
      MuiFormLabel: {},
      MuiFormHelperText: {},
      MuiInputAdornment: {},
      MuiLink: {},
      MuiCircularProgress: {},
      MuiPaper: {},
      MuiMenuItem: { },
      MuiStepper: {},
      MuiStep: {},
      MuiStepLabel: {},
      MuiStepButton: {},
      MuiStepIcon: {},
      MuiStepConnector: {},
      MuiSlider: {},
      MuiDrawer: {},
      MuiAutocomplete: {},
      MuiTableRow: {
        root: {
          "&$selected": {
            "&$selected": {
              "backgroundColor": palette.primary.opacity,
              "&:hover": {
                "backgroundColor": palette.primary.opacity
              }
            }
          }
        }
      },
      MUIDataTableHeadRow: {
        root: {
          backgroundColor: palette.default.light + ' !important',
        }
      },
      MUIDataTableHeadCell: {
        root: {
          backgroundColor: palette.default.light + ' !important',
        },
        sortAction: {
          alignItems: 'center'
        }
      },
      MUIDataTableSelectCell: {
        headerCell: {
          backgroundColor: palette.default.light + ' !important',
        }
      },
      MUIDataTableToolbarSelect: {
        root: {
          boxShadow: 'none',
        }
      },
      MUIDataTableBodyRow: {
        '&.Mui-selected': {
          backgroundColor: palette.primary.opacity, // Color for the selected row
            '&:hover': {
              backgroundColor: palette.primary.opacity, // Same color as selected when hovered
          },
        },
      },
    },
    props: {
      MuiAvatar: {},
      MuiBadge: {},
      MuiBreadcrumbs: {},
      MuiButton: {},
      MuiButtonGroup: {},
      MuiCard: {},
      MuiCardActions: {},
      MuiCardContent: {},
      MuiCardMedia: {},
      MuiCardHeader: {},
      MuiCheckbox: {},
      MuiChip: {},
      MuiDialog: {},
      MuiDialogActions: {},
      MuiDialogContent: {},
      MuiDialogContentText: {},
      MuiDialogTitle: {},
      MuiIconButton: {},
      MuiModal: {},
      MuiRadioGroup: {},
      MuiSelect: {},
      MuiSnackbar: {},
      MuiTooltip: {},
      MuiTypography: {},
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      h1: {
        
      },
      h2: {
        
      },
      h3: {
       
      },
      h4: {
        
      },
      h5: {
        
      },
      h6: {
        
      },
      subtitle1: {
        
      },
      subtitle2: {
       
      },
      body1: {
        
      },
      body2: {
       
      },
      button: {},
      caption: {},
    },
  });
};
