import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormHelperText, InputLabel, Select, MenuItem } from '@material-ui/core';
import color from '../../assets/css/colors';

const useStyles = makeStyles(() => ({
  dropdownWrapper: {
    display: 'flex',
    position: 'relative',
  },
  tooltipWrapper: {
    position: 'absolute',
    top: 29,
    right: 16,
    padding: '10px 10px 7px 10px',
    backgroundColor: color.light.opacity,
    borderRadius: 5,
    '& svg': {
      width: 24,
      height: 24,
      cursor: 'help',
      '& path': {
        fill: color.primary.main,
      }
    },
  }
}));

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function SelectMonth(props) {
  const classes = useStyles();

  return (
    <div className={classes.dropdownWrapper}>
      <FormControl
        fullWidth
        error={props.error}
        style={{ marginTop: props.mt ? props.mt : 0, marginBottom: props.mb ? props.mb : 0 }}
      >
        <InputLabel
          shrink
          required={props.required}
        >
          Month
        </InputLabel>
        <Select
          displayEmpty
          fullWidth
          defaultValue=""
          disabled={props.disabled}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
        >
          <MenuItem key="" value=""><span style={{color: '#bbbdc5'}}>None</span></MenuItem>
          {monthNames.map((month, index) => (
            <MenuItem key={index} value={month}>{month}</MenuItem>
          ))
          }
        </Select>
        {props.error && (<FormHelperText>{props.error ? props.msg : ''}</FormHelperText>)}
      </FormControl>
    </div>
  );
}