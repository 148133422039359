import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridTable from '@nadavshaar/react-grid-table';
import GridHeader from './GridHeader';
import GridBody from './GridBody';
import fontFamily from '../../assets/css/fonts';

const useStyles = makeStyles(() => ({
  grid: {
    color: '#40397e',
    fontFamily: fontFamily.label,
    fontSize: 14,
    border: 'none',
    borderRadius: 8,
    boxShadow: '0px 0px 15px #d1cbff',
    '& .rgt-container': {
      maxHeight: 505,
      minHeight: 505,
      border: '1px solid #ccc6ff',
      '& .rgt-cell-header': {
        fontWeight: 600,
      },
    },
    '& .rgt-cell': {
      minHeight: 40,
      borderRight: '1px solid #ccc6ff',
    },
    '& .rgt-footer': {
      borderTop: 'none',
      borderLeft: '1px solid #ccc6ff',
      borderRight: '1px solid #ccc6ff',
      borderBottom: '1px solid #ccc6ff',
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
    '& .rgt-container-overlay': {
      fontSize: 18,
      fontWeight: 600,
      top: 120,
    },
  },
}));

export default function ClientGrid(props) {
  const classes = useStyles();

  return (
    <div className="custom-grid-style">
      <GridTable
        className={classes.grid}
        columns={props.columns}
        rows={props.rows}
        rowIdField={props.rowIdField}
        isLoading={props.loading}
        pageSizes={[50, 500, 1000]}
        components={{
          Header: GridHeader,
          Cell: GridBody,
        }}
        additionalProps={{
          header: { props },
        }}
        onSelectedRowsChange={props.onSelectedRowsChange}
        selectedRowsIds={props.selectedRowsIds}
      />
    </div>
  );
}
