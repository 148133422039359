import { listUsrAccess } from '../../services/UsrAccessService';
import authSvc from '../../services/AuthService';

const activeUser = authSvc.getCurrentUser();

const getAccess = async (access, subAccess) => {
  const { usrAccessesList } = await listUsrAccess({
    roleId: activeUser.RoleId,
    requestType: 'Show Selected',
  });

  const findAccess = usrAccessesList.find(
    (usrAccess) => usrAccess.access === access && usrAccess.subAccess === subAccess
  );
  return findAccess;
};

const pii = async () => {
  return (await getAccess('PII', 'View and Edit')) ? true : false;
};

const callNotice = async () => {
  return (await getAccess('Notification', 'Margin Call')) ? true : false;
};

const impersonate = async () => {
  return (await getAccess('Admin', 'Impersonate')) ? true : false;
};

export { pii, callNotice, impersonate };
