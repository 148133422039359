import { formatCurrency, formatQty } from '../../lib/fmt';
import Text from '../Typography/Text';

const TotalSummaryCard = (props) => {
  const { label = '--', total, type } = props;

  return (
    <div className="summary-card">
      <div className="mb-10">
        <Text variant="body1" label={label} />
      </div>
      <Text
        variant="h3"
        label={type === 'qty' ? formatQty(total) : formatCurrency(total)}
      />
    </div>
  );
};

export default TotalSummaryCard;
