import React, { useState, useEffect } from 'react';
import { CircularProgress, IconButton } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import { verifyToken } from 'authenticator';
import moment from 'moment-timezone';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  VpnKey as LockIcon,
  Person as PersonIcon,
} from '@material-ui/icons';

import { validatePassword } from '../../lib/validate/validate';
import { impersonate } from '../../lib/access/access';

import {
  createAdministrator,
  listAdministrator,
  deleteAdministrator,
  updateAdministrator,
  setKey,
} from '../../services/AdministratorService';

import SelectAdministratorEmail from '../../components/AutoComplete/AutoCompleteAdministratorEmail';
import MultiFactorAuthenticationModal from '../../components/Modals/MultiFactorAuthenticationModal';
import SelectAdministratorName from '../../components/AutoComplete/AutoCompleteAdministratorName';
import SelectSystemCode from '../../components/Dropdown/SelectSystemCode';
import ClientGrid from '../../components/ClientGrid/ClientGrid';
import ClientGrid2 from '../../components/ClientGrid/ClientGrid2'
import Table, { columnType } from '../../components/ClearingTable/Table';
import SelectRole from '../../components/Dropdown/SelectRole';
import QueryParam from '../../services/QueryParamService';
import Button from '../../components/Buttons/Button';
import Text from '../../components/Typography/Text';
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from '../../components/Messages/Notification';
import { pbDateTime, pbDateTimeSorter } from '../../components/ClientGrid/CellRenderer';
import { formatPbDate, formatPbDateTime } from '../../lib/fmt';

import AdministratorModal from './Components/AdministratorModal';

import {
  CustomBodyRenderCurrency,
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderDate,
  CustomBodyRenderDateTime,
  CustomBodyRenderBoolean,
  CustomBodyRenderAlign,
  CustomBodyRenderCenter,
  CustomBodyRenderQty,
  CustomBodyRenderHtmlString
} from '../../components/Table/CustomBodyRender';

export default function Administrator(params) {
  const confirm = useConfirm();
  const [selectedRowsIds, setSelectedRowsIds] = React.useState([]);

  const isDeleteEnabled = (data) => {
    return data?.status !== 'Inactive';
  };
  const [lockTable, setLockTable] = useState(true);
  const [searchDataCsv, setSearchDataCsv] = React.useState([]);
  const [pagination, setPagination] = useState({});
  const [totalRows, setTotalRows] = useState(0);
  const [previousRows, setPreviousRows] = useState(0);
  const [rowData, setRowData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [open2fa, set2faOpen] = React.useState(false);
  const [impersonateAccess, setImpersonateAccess] = React.useState(false);
  const [loading, setLoading] = React.useState({
    search: false,
    save: false,
    delete: false,
  });
  const [rows, setRows] = useState([]);
  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      name: '',
      email: '',
      userType: '',
      roleId: 0,
      roleName: '',
      status: 'Active',
    })
  );


  useEffect(
    async () => {
      setImpersonateAccess(await impersonate());
    },
    // eslint-disable-next-line
    []
  );

  const GridButtons = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    const impersonateAccess = tableManager.config.additionalProps.header.props.impersonateAccess
    return (
      <div align={'left'} className="grd-row" style={impersonateAccess ? { margin: 0, padding: 0 } : { margin: 0, paddingLeft: 15 }}>
        <div className="grd-cell-none">
          <IconButton
            aria-label="delete"
            disabled={!isDeleteEnabled(data) || loading.delete}
            onClick={() => {
              handleDelete([rowIndex], [rowIndex], tableManager.rowsApi.rows);
            }}
          >
            {loading.delete && selectedRowsIds.includes(rowIndex) ? (
              <CircularProgress style={{ color: '#1e7dff', height: 20, width: 20 }} />
            ) : (
              <DeleteIcon style={{ height: 20, width: 20 }} />
            )}
          </IconButton>
          <IconButton
            aria-label="edit"
            disabled={loading.delete}
            onClick={() => handleOpen(data)}
          >
            <EditIcon style={{ height: 20, width: 20 }} />
          </IconButton>
          <IconButton
            aria-label="2fa"
            disabled={loading.delete}
            onClick={() => handle2faOpen(data)}
          >
            <LockIcon style={{ height: 20, width: 20 }} />
          </IconButton>
          {impersonateAccess ? (
            <IconButton aria-label="Impersonate" onClick={() => handleClientOpen(data)}>
              <PersonIcon style={{ height: 20, width: 20 }} />
            </IconButton>
          ) : null}
        </div>
      </div>
    );
  };

  // const columns2 = [
  //   {
  //     id: 'checkbox',
  //     width: '54px',
  //     visible: true,
  //   },
  //   {
  //     id: '',
  //     field: '',
  //     width: '110px',
  //     cellRenderer: GridButtons,
  //   },
  //   {
  //     id: 'name',
  //     field: 'name',
  //     label: 'User',
  //   },
  //   {
  //     id: 'roleName',
  //     field: 'roleName',
  //     label: 'Role Name',
  //   },
  //   {
  //     id: 'email',
  //     field: 'email',
  //     label: 'Email',
  //     width: '300px',
  //   },
  //   {
  //     id: 'mobileNo',
  //     field: 'mobileNo',
  //     label: 'Mobile No',
  //   },
  //   {
  //     id: 'userType',
  //     field: 'userType',
  //     label: 'User Type',
  //   },
  //   {
  //     id: 'status',
  //     field: 'status',
  //     label: 'Status',
  //   },
  //   {
  //     id: 'authenticationMode',
  //     field: 'authenticationMode',
  //     label: 'Authentication Mode',
  //   },
  //   {
  //     id: 'createdAt',
  //     field: 'createdAt',
  //     label: 'Created At',
  //     cellRenderer: pbDateTime,
  //     sort: pbDateTimeSorter,
  //     visible: false,
  //   },
  //   {
  //     id: 'modifiedBy',
  //     field: 'modifiedBy',
  //     label: 'Modified By',
  //   },
  //   {
  //     id: 'lastLogin',
  //     field: 'lastLogin',
  //     label: 'Last Login',
  //     cellRenderer: pbDateTime,
  //     sort: pbDateTimeSorter,
  //     visible: false,
  //   },
  // ];

  const columns = [
    {
      name: 'actions',
      label: 'Actions',
      sort: false,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return(
            <div align={'left'} className="grd-row" style={impersonateAccess ? { margin: 0, padding: 0 } : { margin: 0, paddingLeft: 15 }}>
              <div className="grd-cell-none">
                <IconButton
                  aria-label="delete"
                  disabled={!isDeleteEnabled(rows[dataIndex]) || loading.delete}
                  onClick={() => {
                    handleDelete([dataIndex + 1], [dataIndex + 1], rows);
                  }}
                >
                  {loading.delete && selectedRowsIds.includes(dataIndex) ? (
                    <CircularProgress style={{ color: '#1e7dff', height: 20, width: 20 }} />
                  ) : (
                    <DeleteIcon style={{ height: 20, width: 20 }} />
                  )}
                </IconButton>
                <IconButton
                  aria-label="edit"
                  disabled={loading.delete}
                  onClick={() => handleOpen(rows[dataIndex])}
                >
                  <EditIcon style={{ height: 20, width: 20 }} />
                </IconButton>
                <IconButton
                  aria-label="2fa"
                  disabled={loading.delete}
                  onClick={() => handle2faOpen(rows[dataIndex])}
                >
                  <LockIcon style={{ height: 20, width: 20 }} />
                </IconButton>
                {impersonateAccess ? (
                  <IconButton aria-label="Impersonate" onClick={() => handleClientOpen(rows[dataIndex])}>
                    <PersonIcon style={{ height: 20, width: 20 }} />
                  </IconButton>
                ) : null}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'name',
      label: 'User',
    },
    {
      name: 'roleName',
      label: 'Role Name',
    },
    {
      name: 'email',
      label: 'Email',
      width: '300px',
    },
    {
      name: 'mobileNo',
      label: 'Mobile No',
    },
    {
      name: 'userType',
      label: 'User Type',
    },
    {
      name: 'status',
      label: 'Status',
    },
    {
      name: 'authenticationMode',
      label: 'Authentication Mode',
    },
    {
      name: 'createdAt',
      label: 'Created At',
      options: {
        display: false,
      }
    },
    {
      name: 'modifiedBy',
      label: 'Modified By',
    },
    {
      name: 'lastLogin',
      label: 'Last Login',
      options: {
        display: false,
      }
    },
    {
      name: 'a-selectAll',
      label: 'Select All',
      options: {
        display: true,
        setCellHeaderProps: () => ({
          style: {
            width: '0px',
            display: 'table-cell',
            padding: 0,
            pointerEvents: 'none',
            fontSize: 0,
          },
        }),
      },
    },
  ];

  const options = {
    serverSide: true,
    responsive: 'standard',
    onRowSelectionChange: (allRowsSelected, currentRowsSelected) => {
      let tempIds = [];

      currentRowsSelected.map((row) => {
        tempIds.push(row.index);
      })

      setSelectedRowsIds(tempIds);
    },
    rowsSelected: selectedRowsIds,
    isRowSelectable: isDeleteEnabled,
  };

  const handleChange = (e, x) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    if (['roleName'].includes(input.name)) {
      searchData.roleId = input.roleId;
      searchData.roleName = input.value;
    }

    setSearchData({
      ...searchData,
      [input.name]: input.value,
    });
  };

  const [customData, setCustomData] = useState([]);
  const getCsvData = async () => {
    if (lockTable === true) {
      return [];
    }
    let paginationCopy = {
      ...pagination,
      reload: false,
      pageNo: 0,
      rowsPerPage: totalRows,
    };

    const data = await listAdministrator(searchDataCsv, paginationCopy);
    const rows = data.administratorsList.map((data) => ({
      userId: data.usrId,
      user: data.name,
      roleName: data.roleName,      
      email: data.email,
      mobileNo: data.mobileNo,
      userType: data.userType,
      status: data.status,
      authenticationMode: data.authenticationMode,
      createdAt: data.createdAt
        ? moment(new Date(data.createdAt.seconds * 1000)).format(
            'MM/DD/YYYY hh:mm'
          )
        : '--',
      modifiedBy: data.modifiedBy,
      lastLogin: data.lastLogin
        ? moment(new Date(data.lastLogin.seconds * 1000)).format(
            'MM/DD/YYYY hh:mm'
          )
        : '--',      
    }));
    return rows;
  };

  const handleSearch = async (paginationChange) => {
    try {
      if (paginationChange !== true) {
        setLoading({ ...loading, search: true });
        setLockTable(false);
      }
      if (paginationChange === true) {
        if (lockTable === true) {
          return;
        }
      }      
      QueryParam.set(searchData);
      let paginationCopy = {
        ...pagination,
        reload: false,
        pageNo: paginationChange === true ? pagination.pageNo : 0,
        rowsPerPage: pagination.rowsPerPage || 100,
      };
      if(previousRows != 0){
        if (previousRows < pagination.rowsPerPage * pagination.pageNo) {
          paginationCopy.pageNo = 0;
        }
      } 
      if(paginationChange !== true){
            paginationCopy.filterNames = [];
            paginationCopy.filterValues = [];
      }
      const data = await listAdministrator(searchData, paginationCopy);
      const rows = data.administratorsList.map((data) => ({
        usrId: data.usrId,
        roleName: data.roleName,
        roleId: data.roleId,
        name: data.name,
        email: data.email,
        createdAt: data.createdAt
          ? moment(new Date(data.createdAt.seconds * 1000)).format(
              'MM/DD/YYYY hh:mm'
            )
          : '--',
        lastLogin: data.lastLogin
          ? moment(new Date(data.lastLogin.seconds * 1000)).format(
              'MM/DD/YYYY hh:mm'
            )
          : '--',
        // password: data.password,
        // passwordConfirm: data.password,
        defaultAccess: data.defaultAccess,
        mobileNo: data.mobileNo,
        userType: data.userType,
        status: data.status,
        admin: data.admin,
        correspondent: data.correspondent,
        accountNo: data.accountNo,
        authenticationMode: data.authenticationMode,
        secretKey: data.secretKey,
        authenticationText: data.authenticationMode.includes('Text'),
        authenticationEmail: data.authenticationMode.includes('Email'),
        authenticationAuthenticator: data.authenticationMode.includes(
          'Authenticator'
        ),
        modifiedBy: data.modifiedBy,
        clientLink: data.clientLink,
      }));
      setSearchDataCsv(searchData);
      setTotalRows(data.summary.totalRows);
      setPreviousRows(totalRows);
      paginationCopy.count = data.summary.totalRows;
      setPagination(paginationCopy);
      setRows(rows);
      if (paginationChange !== true) {
        notifyInfo(data.summary.totalRows + ' search results.');
      }
    } catch (error) {
      console.error(error);
      notifyError(error.message);
    } finally {
      setLoading({ ...loading, search: false });
    }
  };

  const handleOpen = (data, isAdd) => {
    if (isAdd) {
      data = {
        usrId: 0,
        name: '',
        email: '',
        password: '',
        passwordConfirm: '',
        defaultAccess: '',
        userType: 'Employee',
        status: 'Active',
        admin: false,
        correspondent: '',
        authenticationMode: 'Email',
        authenticationText: false,
        authenticationEmail: false,
        authenticationAuthenticator: false,
      };
      setModalAddAction(true);
    } else {
      data.correspondent = data.correspondent.trim();
      setModalAddAction(false);
    }
    setRowData(data);
    setOpen(true);
  };

  const handle2faOpen = (data) => {
    data.correspondent = data.correspondent.trim();
    data.otPath = '';
    data.code = '';
    setRowData(data);
    set2faOpen(true);
  };

  const handleClientOpen = (data) => {
    window.open(data.clientLink, '_blank', 'noopener,noreferrer');
  };

  const handle2faClose = async (data, action) => {
    var valid = true;
    if (action === 'verify') {
      if (data.code === '') {
        notifyError('Please input verification code.');
        valid = false;
      }
      if (verifyToken(data.secretKey, data.code) == null) {
        notifyError('Invalid verification code.');
        valid = false;
      } else {
        try {
          const resp = await setKey(data);
          var response = resp;
          response.createdAt = response.createdAt
            ? moment(new Date(response.createdAt.seconds * 1000)).format(
                'MM/DD/YYYY hh:mm'
              )
            : '--';
          response.lastLogin = response.lastLogin
            ? moment(new Date(response.lastLogin.seconds * 1000)).format(
                'MM/DD/YYYY hh:mm'
              )
            : '--';
          response.password = '';
          notifySuccess('Setup account success.');

          const rowsCopy = [...rows];
          const index = rows.indexOf(rowData);
          rowsCopy[index] = response;
          setRows(rowsCopy);
          set2faOpen(false);
        } catch (error) {
          notifyError(error.message);
        }
      }
    } else {
      set2faOpen(false);
    }
  };

  const handleClose = async (data, isAdd) => {
    if (!data) {
      setOpen(false);
      return;
    }
    let valid = true;
    try {
      if (!data.name) {
        notifyError('Name is required.');
        valid = false;
      }

      if (!data.email) {
        notifyError('Email is required.');
        valid = false;
      }

      if (!data.roleId) {
        notifyError('Role Name is required.');
        valid = false;
      }

      if (!data.mobileNo) {
        notifyError('Mobile No is required.');
        valid = false;
      }

      if (!data.userType) {
        notifyError('User type is required.');
        valid = false;
      }

      if (!data.status) {
        notifyError('Status is required.');
        valid = false;
      }

      if (isAdd) {
        if (!data.password) {
          notifyError('Password is required.');
          valid = false;
        }

        if (data.password !== data.passwordConfirm) {
          notifyError('Password does not match.');
          valid = false;
        }

        if (validatePassword(data.password).err) {
          notifyError('Password does not meet the requirement.');
          valid = false;
        }

        if (validatePassword(data.passwordConfirm).err) {
          notifyError(' does not meet the requirement.');
          valid = false;
        }
      } else {
        if (data.password) {
          if (data.password !== data.passwordConfirm) {
            notifyError('Password does not match.');
            valid = false;
          }

          if (validatePassword(data.password).err) {
            notifyError('Password does not meet the requirement.');
            valid = false;
          }

          if (validatePassword(data.passwordConfirm).err) {
            notifyError('Confirm Password does not meet the requirement.');
            valid = false;
          }
        }
      }

      if (
        !data.authenticationEmail &&
        !data.authenticationText &&
        !data.authenticationAuthenticator
      ) {
        notifyError('At least 1 authentication mode is required.');
        valid = false;
      } else {
        var mode = '';
        mode = data.authenticationEmail ? 'Email,' : '';
        mode = mode + (data.authenticationText ? 'Text,' : '');
        mode = mode + (data.authenticationAuthenticator ? 'Authenticator,' : '');
        data.authenticationMode = mode;
      }

      if (data.userType !== 'Client') {
        data.admin = '';
      }

      if (data.userType !== 'Client') {
        data.correspondent = '';
      }

      if (!valid) {
        return;
      }

      setLoading({ ...loading, save: true });

      if (isAdd) {
        //ADD
        const resp = await createAdministrator(data);
        const response = resp.administrator;
        response.createdAt = response.createdAt
          ? moment(new Date(response.createdAt.seconds * 1000)).format('MM/DD/YYYY hh:mm')
          : '--';
        response.lastLogin = response.lastLogin
          ? moment(new Date(response.lastLogin.seconds * 1000)).format('MM/DD/YYYY hh:mm')
          : '--';
        response.password = '';
        setRows([response, ...rows]);
        notifySuccess('New Administrator has been added.');
      } else {
        const resp = await updateAdministrator(data);
        const response = resp.administrator;

        response.createdAt = response.createdAt
          ? moment(new Date(response.createdAt.seconds * 1000)).format('MM/DD/YYYY hh:mm')
          : '--';
        response.lastLogin = response.lastLogin
          ? moment(new Date(response.lastLogin.seconds * 1000)).format('MM/DD/YYYY hh:mm')
          : '--';
        response.password = '';
        response.authenticationText = response.authenticationMode.includes('Text');
        response.authenticationEmail = response.authenticationMode.includes('Email');
        response.authenticationAuthenticator =
          response.authenticationMode.includes('Authenticator');
        const rowsCopy = [...rows];
        const index = rows.indexOf(rowData);
        rowsCopy[index] = response;
        setRows(rowsCopy);

        notifySuccess('An Administrator has been updated.');
      }
      setOpen(false);
    } catch (error) {
      console.error(error);
      setLoading({ ...loading, save: false });
      notifyError(error.message);
    }

    setLoading({ ...loading, save: false });
  };

  const handleDelete = (selectedRowsIds, index, rows, isFromButton) => {
    let messageKey = '';
    if (selectedRowsIds?.length === 1) {
      messageKey = rows[selectedRowsIds[0] - 1]?.name;
    } else {
      messageKey = selectedRowsIds?.length + ' items';
    }
    let idsToDelete = [];
    confirm({
      description:
        "You are about to delete '" + messageKey + "'. Please confirm your action.",
      confirmationText: 'Yes, delete',
    }).then(async () => {
      const rowsCopy = [...rows];
      try {
        setSelectedRowsIds(index);
        setLoading({ ...loading, delete: true });

        for (const r of selectedRowsIds) {
          const index = r;
          await deleteAdministrator(rowsCopy[index].usrId);
          idsToDelete.push(rowsCopy[index].usrId);
        }
      } catch (error) {
        console.error(error);
        notifyError(error.message);
      } finally {
        for (let i = 0; i < rowsCopy.length; i++) {
          if (idsToDelete.includes(rowsCopy[i].usrId)) {
            rowsCopy[i].status = 'Inactive';
          }
        }
        setRows(rowsCopy);
        setLoading({ ...loading, delete: false });
        setSelectedRowsIds([]);
        notifySuccess(messageKey + ' has been deleted');
      }
    });
  };

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="User" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading.search}
              loading={loading.search}
              type="search"
              label={loading.search ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading.add}
              loading={loading.add}
              type="plus"
              label={'Add New'}
              onClick={() => {
                handleOpen('', true);
              }}
            />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={selectedRowsIds.length ? loading.delete : true}
              loading={loading.delete}
              label={'Delete'}
              onClick={() => {
                handleDelete(selectedRowsIds, selectedRowsIds, rows, true);
              }}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <SelectAdministratorName
              name="name"
              label="User"
              freeSolo={true}
              value={searchData.name}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => (e.keyCode === 13 ? handleSearch() : null)}
            ></SelectAdministratorName>
          </div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <SelectAdministratorEmail
              name="email"
              label="Email"
              freeSolo={true}
              value={searchData.email}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => (e.keyCode === 13 ? handleSearch() : null)}
            ></SelectAdministratorEmail>
          </div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <SelectSystemCode
              name="userType"
              label="User Type"
              type="User Type"
              value={searchData.userType}
              onChange={handleChange}
            ></SelectSystemCode>
          </div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <SelectRole
              name="roleName"
              label="Role Name"
              value={searchData.roleName}
              onChange={handleChange}
            ></SelectRole>
          </div>
          <div className="grd-cell">
            <SelectSystemCode
              name="status"
              label="Status"
              type="Status"
              subType="AI"
              value={searchData.status}
              onChange={handleChange}
            ></SelectSystemCode>
          </div>
        </div>
      </div>
      <div className="mt-20">
        {/* <ClientGrid
          title="User"
          rowIdField="id"
          columns={columns2}
          rows={rows}
          setRows={setRows}
          isLoading={loading}
          getCsvData={getCsvData}
          onSelectedRowsChange={(selectedRowsIds) => {
            setSelectedRowsIds(selectedRowsIds);
          }}
          selectedRowsIds={selectedRowsIds}
          getIsRowSelectable={isDeleteEnabled}
          impersonateAccess={impersonateAccess}
        /> */}
        {/* <ClientGrid2
          title="User"
          data={rows}
          columns={columns}
          options={options}
          impersonateAccess={impersonateAccess}
        /> */}
        <Table
          title={'User'}
          data={rows}
          columns={columns}
          options={options}
          pagination={pagination}
          setPagination={setPagination}
          onSearch={handleSearch}
          getCsvData={getCsvData}
          onRowSelectionChange = {(rowsSelected) => {
            console.log(rowsSelected)
            setSelectedRowsIds(rowsSelected)
          }}
          selectedRowsIds={selectedRowsIds}
          impersonateAccess={impersonateAccess}
        />
      </div>
      {open && (
        <AdministratorModal
          onClose={handleClose}
          open={open}
          add={modalAddAction}
          value={rowData}
          loading={loading.save}
        ></AdministratorModal>
      )}
      {open2fa && (
        <MultiFactorAuthenticationModal
          onClose={handle2faClose}
          open={open2fa}
          add={modalAddAction}
          id={rowData.usrId}
          loading={loading.save}
        ></MultiFactorAuthenticationModal>
      )}
    </div>
  );
}
