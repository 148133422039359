import React from 'react';
import { ErrorOutline as ErrorIcon } from '@material-ui/icons';
import Text from '../../../components/Typography/Text';

export default function BrowserCompatibilityNotification() {
  return !!window.chrome ? (
    <React.Fragment />
  ) : (
    <div className="register-b" style={{ display: 'flex', marginBottom: '10px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '4px',
          backgroundColor: '#ff425f',
          padding: '10px',
        }}
      >
        <>
          <ErrorIcon style={{ fontSize: '20px', color: '#ffecef', fontWeight: 'bold' }} />
          <div style={{ textAlign: 'center', paddingLeft: '5px', width: 'fit-content' }}>
            <Text
              variant={'subtitle1'}
              label="This browser is currently not compatible. Please use Google Chrome."
              display={'inline-block'}
              color={'#ffecef'}
            />
          </div>
        </>
      </div>
    </div>
  );
}
