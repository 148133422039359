import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import {
  updateTableSettings,
  createTableSettings,
} from '../../services/TableSettingsService';
import { notifySuccess } from '../Messages/Notification';

export default function SaveGrid(props) {
  const { columns } = props.columnsApi;

  const getVisibleColumnsWithOrder = () => {
    let filteredColumns = {
      visible: [],
      order: [],
    };

    columns.filter(function(col) {
      return col.visible === true &&
        col.field !== '' &&
        col.field !== undefined
        ? col
        : null;
    }).map(function(col) {
      filteredColumns.visible.push(col.field);
      filteredColumns.order.push(col.index);
      return true;
    });

    return filteredColumns;
  };

  const handleSave = async () => {
    const columns = await getVisibleColumnsWithOrder();

    const params = {
      title: props.title,
      userId: props.userId,
      visibleColumns: columns.visible,
      columnOrder: columns.order,
    };

    if (props.hasTableSettings) {
      try {
        await updateTableSettings(params);
        props.handleCheckTableSettings();
        //notifySuccess('Table has been saved.');
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await createTableSettings(params);
        props.handleCheckTableSettings();
        notifySuccess('Table has been saved.');
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Tooltip arrow title="Save Table">
        <IconButton onClick={handleSave}>
          <SaveRoundedIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}
