// source: proto/tradepb/modeling.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.tradepb.CreateTradeSheetRequest', null, global);
goog.exportSymbol('proto.tradepb.EmptyResponse', null, global);
goog.exportSymbol('proto.tradepb.ListModelingRequest', null, global);
goog.exportSymbol('proto.tradepb.ListModelingResponse', null, global);
goog.exportSymbol('proto.tradepb.Modeling', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.Modeling = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.Modeling, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.Modeling.displayName = 'proto.tradepb.Modeling';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.ListModelingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.ListModelingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.ListModelingRequest.displayName = 'proto.tradepb.ListModelingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.CreateTradeSheetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.CreateTradeSheetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.CreateTradeSheetRequest.displayName = 'proto.tradepb.CreateTradeSheetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.EmptyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tradepb.EmptyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.EmptyResponse.displayName = 'proto.tradepb.EmptyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tradepb.ListModelingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tradepb.ListModelingResponse.repeatedFields_, null);
};
goog.inherits(proto.tradepb.ListModelingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.tradepb.ListModelingResponse.displayName = 'proto.tradepb.ListModelingResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.Modeling.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.Modeling.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.Modeling} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.Modeling.toObject = function(includeInstance, msg) {
  var f, obj = {
    allocationModelName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 2, ""),
    symbolDescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    allocatedPercent: jspb.Message.getFieldWithDefault(msg, 4, ""),
    allocatedValue: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.Modeling}
 */
proto.tradepb.Modeling.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.Modeling;
  return proto.tradepb.Modeling.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.Modeling} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.Modeling}
 */
proto.tradepb.Modeling.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAllocationModelName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAllocatedPercent(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAllocatedValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.Modeling.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.Modeling.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.Modeling} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.Modeling.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllocationModelName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSymbolDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAllocatedPercent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAllocatedValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string allocation_model_name = 1;
 * @return {string}
 */
proto.tradepb.Modeling.prototype.getAllocationModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.Modeling} returns this
 */
proto.tradepb.Modeling.prototype.setAllocationModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string symbol = 2;
 * @return {string}
 */
proto.tradepb.Modeling.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.Modeling} returns this
 */
proto.tradepb.Modeling.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string symbol_description = 3;
 * @return {string}
 */
proto.tradepb.Modeling.prototype.getSymbolDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.Modeling} returns this
 */
proto.tradepb.Modeling.prototype.setSymbolDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string allocated_percent = 4;
 * @return {string}
 */
proto.tradepb.Modeling.prototype.getAllocatedPercent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.Modeling} returns this
 */
proto.tradepb.Modeling.prototype.setAllocatedPercent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string allocated_value = 5;
 * @return {string}
 */
proto.tradepb.Modeling.prototype.getAllocatedValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.Modeling} returns this
 */
proto.tradepb.Modeling.prototype.setAllocatedValue = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.ListModelingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.ListModelingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.ListModelingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.ListModelingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountValue: jspb.Message.getFieldWithDefault(msg, 1, ""),
    model: jspb.Message.getFieldWithDefault(msg, 2, ""),
    platform: jspb.Message.getFieldWithDefault(msg, 3, ""),
    equity: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fixedIncomeI: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.ListModelingRequest}
 */
proto.tradepb.ListModelingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.ListModelingRequest;
  return proto.tradepb.ListModelingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.ListModelingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.ListModelingRequest}
 */
proto.tradepb.ListModelingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlatform(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFixedIncomeI(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.ListModelingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.ListModelingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.ListModelingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.ListModelingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlatform();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEquity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFixedIncomeI();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string account_value = 1;
 * @return {string}
 */
proto.tradepb.ListModelingRequest.prototype.getAccountValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.ListModelingRequest} returns this
 */
proto.tradepb.ListModelingRequest.prototype.setAccountValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model = 2;
 * @return {string}
 */
proto.tradepb.ListModelingRequest.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.ListModelingRequest} returns this
 */
proto.tradepb.ListModelingRequest.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string platform = 3;
 * @return {string}
 */
proto.tradepb.ListModelingRequest.prototype.getPlatform = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.ListModelingRequest} returns this
 */
proto.tradepb.ListModelingRequest.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string equity = 4;
 * @return {string}
 */
proto.tradepb.ListModelingRequest.prototype.getEquity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.ListModelingRequest} returns this
 */
proto.tradepb.ListModelingRequest.prototype.setEquity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string fixed_income_i = 5;
 * @return {string}
 */
proto.tradepb.ListModelingRequest.prototype.getFixedIncomeI = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.ListModelingRequest} returns this
 */
proto.tradepb.ListModelingRequest.prototype.setFixedIncomeI = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.CreateTradeSheetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.CreateTradeSheetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.CreateTradeSheetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.CreateTradeSheetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountNo: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountValue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    model: jspb.Message.getFieldWithDefault(msg, 3, ""),
    platform: jspb.Message.getFieldWithDefault(msg, 4, ""),
    equity: jspb.Message.getFieldWithDefault(msg, 5, ""),
    fixedIncomeI: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.CreateTradeSheetRequest}
 */
proto.tradepb.CreateTradeSheetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.CreateTradeSheetRequest;
  return proto.tradepb.CreateTradeSheetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.CreateTradeSheetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.CreateTradeSheetRequest}
 */
proto.tradepb.CreateTradeSheetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlatform(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFixedIncomeI(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.CreateTradeSheetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.CreateTradeSheetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.CreateTradeSheetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.CreateTradeSheetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPlatform();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEquity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFixedIncomeI();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string account_no = 1;
 * @return {string}
 */
proto.tradepb.CreateTradeSheetRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.CreateTradeSheetRequest} returns this
 */
proto.tradepb.CreateTradeSheetRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_value = 2;
 * @return {string}
 */
proto.tradepb.CreateTradeSheetRequest.prototype.getAccountValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.CreateTradeSheetRequest} returns this
 */
proto.tradepb.CreateTradeSheetRequest.prototype.setAccountValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string model = 3;
 * @return {string}
 */
proto.tradepb.CreateTradeSheetRequest.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.CreateTradeSheetRequest} returns this
 */
proto.tradepb.CreateTradeSheetRequest.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string platform = 4;
 * @return {string}
 */
proto.tradepb.CreateTradeSheetRequest.prototype.getPlatform = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.CreateTradeSheetRequest} returns this
 */
proto.tradepb.CreateTradeSheetRequest.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string equity = 5;
 * @return {string}
 */
proto.tradepb.CreateTradeSheetRequest.prototype.getEquity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.CreateTradeSheetRequest} returns this
 */
proto.tradepb.CreateTradeSheetRequest.prototype.setEquity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string fixed_income_i = 6;
 * @return {string}
 */
proto.tradepb.CreateTradeSheetRequest.prototype.getFixedIncomeI = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.tradepb.CreateTradeSheetRequest} returns this
 */
proto.tradepb.CreateTradeSheetRequest.prototype.setFixedIncomeI = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.EmptyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.EmptyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.EmptyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.EmptyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.EmptyResponse}
 */
proto.tradepb.EmptyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.EmptyResponse;
  return proto.tradepb.EmptyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.EmptyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.EmptyResponse}
 */
proto.tradepb.EmptyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.EmptyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.EmptyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.EmptyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.EmptyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tradepb.ListModelingResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tradepb.ListModelingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.tradepb.ListModelingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tradepb.ListModelingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.ListModelingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelingsList: jspb.Message.toObjectList(msg.getModelingsList(),
    proto.tradepb.Modeling.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tradepb.ListModelingResponse}
 */
proto.tradepb.ListModelingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tradepb.ListModelingResponse;
  return proto.tradepb.ListModelingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tradepb.ListModelingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tradepb.ListModelingResponse}
 */
proto.tradepb.ListModelingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.tradepb.Modeling;
      reader.readMessage(value,proto.tradepb.Modeling.deserializeBinaryFromReader);
      msg.addModelings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tradepb.ListModelingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tradepb.ListModelingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tradepb.ListModelingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tradepb.ListModelingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.tradepb.Modeling.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Modeling modelings = 1;
 * @return {!Array<!proto.tradepb.Modeling>}
 */
proto.tradepb.ListModelingResponse.prototype.getModelingsList = function() {
  return /** @type{!Array<!proto.tradepb.Modeling>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tradepb.Modeling, 1));
};


/**
 * @param {!Array<!proto.tradepb.Modeling>} value
 * @return {!proto.tradepb.ListModelingResponse} returns this
*/
proto.tradepb.ListModelingResponse.prototype.setModelingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.tradepb.Modeling=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tradepb.Modeling}
 */
proto.tradepb.ListModelingResponse.prototype.addModelings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.tradepb.Modeling, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.tradepb.ListModelingResponse} returns this
 */
proto.tradepb.ListModelingResponse.prototype.clearModelingsList = function() {
  return this.setModelingsList([]);
};


goog.object.extend(exports, proto.tradepb);
