import React, { useEffect } from 'react';
import ClientGrid2 from '../../../components/ClientGrid/ClientGrid2';
import Table, { columnType } from '../../../components/ClearingTable/Table';
import Button from '../../../components/Buttons/Button';
import AutoCompleteCorrespondent from '../../../components/AutoComplete/AutoCompleteCorrespondent';
import AutoCompleteAccountNo from '../../../components/AutoComplete/AutoCompleteAccountNo';
import AutoCompleteBranch from '../../../components/AutoComplete/AutoCompleteBranch';
import Text from '../../../components/Typography/Text';
import { notifyInfo, notifyError } from '../../../components/Messages/Notification';
import { getSystemDate } from '../../../services/ProfileService';
import QueryParam from '../../../services/QueryParamService';
import { IconButton, Tooltip } from '@material-ui/core';
import {
  Visibility as ViewIcon,
  GetAppRounded as DownloadIcon,
  SystemUpdateAlt as DownloadCSVIcon,
} from '@material-ui/icons';
import {
  listFeeManagerReport,
  listFeeManagerReportDetails,
} from '../../../services/FeeManagerReportService';
import FeeManagerReportModal from '../../../components/Modals/FeeManagerModal';
import {
  CustomBodyRenderCurrency,
  CustomBodyRenderDate,
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderBoolean,
} from '../../../components/Table/CustomBodyRender';
import SelectSystemCode from '../../../components/Dropdown/SelectSystemCode';
import DateTimePicker from '../../../components/Textfields/DateTimePicker';
import AutoCompleteMasterAccountNo from '../../../components/AutoComplete/AutoCompleteMasterAccountNo';
import AutoCompleteRep from '../../../components/AutoComplete/AutoCompleteRep';
import { formatPbDate, formatPbDateTime } from '../../../lib/fmt';

export default function Form1099B() {
  const [rows, setRows] = React.useState([]);
  const [rowData, setRowData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      dateType: '',
      fromDate: '',
      toDate: '',
      feeType: '',
      branch: '',
      accountNo: '',
      masterAccountNo: '',
      rep: '',
      broker: '',
      correspondent: '',
    })
  );
  const [openModal, setOpenModal] = React.useState(false);

  useEffect(() => {
    async function init() {
      const systemDate = await getSystemDate();
      const searchDataCopy = { ...searchData, fromDate: systemDate, toDate: systemDate };

      setSearchData(searchDataCopy);
    }

    init();
  }, []);

  // Table Settings
  const form1099BColumns = [
    {
      name: 'actions',
      label: 'Actions',
      sort: false,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div align={'left'} className="grd-row" style={{ margin: 0, padding: 0 }}>
              <div align={'left'} className="grd-cell" style={{ display: 'flex' }}>
                <Tooltip title="View" arrow>
                  <IconButton
                    aria-label="view"
                    onClick={() => {
                      handleOpenModal(dataIndex);
                    }}
                  >
                    <ViewIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'accountId',
      label: 'Account ID',
      options: {
        display: false,
      },
    },
    {
      name: 'accountName',
      label: 'Account Name',
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'office',
      label: 'Branch',
    },
    {
      name: 'broker',
      label: 'Broker',
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'fee',
      label: 'Fee',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'feeType',
      label: 'Fee Type',
    },
    {
      // Already converted to string in handlesearch
      name: 'fromDate',
      label: 'From Date',
      type: columnType.date,
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      name: 'rep',
      label: 'Rep',
    },
    {
      name: 'status',
      label: 'Status',
    },
    {
      // Already converted to string in handlesearch
      name: 'toDate',
      label: 'To Date',
      type: columnType.date,
    },
  ];

  const options = {
    responsive: 'standard',
  };
  // End Table Settings

  const init = async () => {
    const searchParameters = localStorage.getItem('search_data');

    const { year, correspondent, branch, accountNo } = searchParameters
      ? JSON.parse(searchParameters)
      : searchData;

    const systemDate = await getSystemDate();

    setSearchData({
      ...searchData,
      year: year ? year : '',
      correspondent: correspondent ? correspondent : '',
      branch: branch ? branch : '',
      accountNo: accountNo ? accountNo : '',
    });
  };

  React.useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  const handleSearchDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setSearchData({ ...searchData, [input.name]: input.value });
  };

  const handleSearch = async () => {
    try {
      setLoading(true);

      QueryParam.set(searchData);
      const { feeManagerReportsList } = await listFeeManagerReport(searchData);

      let rowsCopy = feeManagerReportsList;

      // const rowsCopyOutput = rowsCopy.map((data) => {
      //   let tempDate = formatPbDate(data.fromDate);
      //   data.fromDate = tempDate

      //   let tempDate2 = formatPbDate(data.toDate);
      //   data.toDate = tempDate2

      //   return data;
      // })

      setRows(rowsCopy);

      notifyInfo(feeManagerReportsList.length + ' search results.');
    } catch (error) {
      notifyError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModal = (selectedRow) => {
    setRowData(rows[selectedRow]);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Fee Manager" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading}
              loading={loading}
              type="search"
              label={loading ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <SelectSystemCode
              name="dateType"
              label="Date Type"
              placeholder="Blank"
              type="Date Type Announcement"
              value={searchData.dateType}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <DateTimePicker
              type="date"
              name="fromDate"
              label="From Date"
              value={searchData.fromDate}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <DateTimePicker
              type="date"
              name="toDate"
              label="To Date"
              value={searchData.toDate}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <SelectSystemCode
              name="feeType"
              label="Fee Type"
              placeholder="Blank"
              type="Fee Type"
              value={searchData.feeType}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteCorrespondent
              isAccessibleOnly={true}
              name="correspondent"
              value={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <AutoCompleteBranch
              isAccessibleOnly={true}
              name="branch"
              value={searchData.branch}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteAccountNo
              isAccessibleOnly={true}
              name="accountNo"
              value={searchData.accountNo}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteMasterAccountNo
              name="masterAccountNo"
              label="Master Account No"
              freeSolo={true}
              value={searchData.masterAccountNo}
              correspondent={searchData.correspondent}
              isAccessibleOnly={true}
              onChange={handleSearchDataChange}
            ></AutoCompleteMasterAccountNo>
          </div>
          <div className="grd-cell">
            <AutoCompleteRep
              isAccessibleOnly={true}
              name="rep"
              value={searchData.rep}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <SelectSystemCode
              disabled={false}
              name="broker"
              label="Broker"
              type="Broker"
              value={searchData.broker}
              onChange={handleSearchDataChange}
            ></SelectSystemCode>
          </div>
        </div>
      </div>
      <div className="mt-20">
        {/* <ClientGrid2
            title="Fee Manager Report"
            data={rows}
            columns={form1099BColumns}
            options={options}
        /> */}
        <Table
          title={'Fee Manager Report'}
          data={rows}
          columns={form1099BColumns}
          options={options}
        />
        {openModal && (
          <FeeManagerReportModal open={openModal} close={closeModal} data={rowData} />
        )}
      </div>
    </div>
  );
}
