import React from 'react';
import { TableFooter, TableRow, TableCell } from '@material-ui/core';
import { formatCurrency, formatPercentage, formatQty } from '../../lib/fmt2';
import useStyles from '../../styles';

const quantity = 'quantity';
const amount = 'amount';
const percentage = 'percentage';

export default function ServerSideTableBodyFooter({
  columnOrder,
  columns,
  columnsWithAmt,
  columnsWithQty,
  columnsWithPercentage,
  rows,
  data,
  isFilterActive,
  additionalCell,
}) {
  const classes = useStyles();

  const getType = (colName) => {
    if (columnsWithAmt && columnsWithAmt.includes(colName)) return amount;
    else if (columnsWithQty && columnsWithQty.includes(colName))
      return quantity;
    else if (columnsWithPercentage && columnsWithPercentage.includes(colName))
      return percentage;
  };

  const getFooterCell = (col, index) => {
    if (!col) return null;
    if (col.display !== 'true') return null;

    // Checked if add footer is enabled
    if (col.addFooter === false) return <TableCell key={index} />;

    if (data[col.name] === undefined) return <TableCell key={index} />;

    const bgColor = {
      background: data[col.name].includes('-') ? '#f44336' : '#4caf50',
    };

    return (
      <TableCell align="right" key={index}>
        <b style={bgColor} className={classes.totalBadge}>
          {data[col.name] || 0}
        </b>
      </TableCell>
    );
  };

  const getFilteredFooterCell = (col, type, index) => {
    const total = rows.reduce((runningTotal, row) => {
      return runningTotal + parseFloat(row[col.name] || 0);
    }, 0);

    const bgColor = { background: total < 0 ? '#f44336' : '#4caf50' };

    let displayValue = total;

    switch (type) {
      case amount:
        displayValue = formatCurrency(total);
        break;
      case quantity:
        displayValue = formatQty(total);
        break;
      case percentage:
        displayValue = formatPercentage(total);
        break;
      default:
        break;
    }

    return (
      <TableCell align="right" key={index}>
        <b style={bgColor} className={classes.totalBadge}>
          {displayValue}
        </b>
      </TableCell>
    );
  };

  return (
    <TableFooter>
      <TableRow>
        {additionalCell ? <TableCell key="action" /> : null}
        {columnOrder.map((order, index) => {
          const col = columns[order];
          const type = getType(col.name);

          if (isFilterActive) {
            if (!col.name) return <TableCell key={order} />;

            if (col.display !== 'true') return null;

            if (!type) return <TableCell key={order} />;
          }

          return isFilterActive
            ? getFilteredFooterCell(col, type, order)
            : getFooterCell(col, order);
        })}
      </TableRow>
    </TableFooter>
  );
}
