import {
  ReportBalanceServiceClient,
  ListReportBalanceRequest,
} from '../proto/reportpb/reportbalance_grpc_web_pb';
import { stringToProtoDate } from './ConvertService';
import { auth } from '../lib/auth/Auth';

const service = new ReportBalanceServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });

export async function listBalanceSummaryReport(param) {
  return new Promise((resolve, reject) => {
    let req = new ListReportBalanceRequest();
    req.setDate(stringToProtoDate(param.date));
    req.setCorrespondent(param.correspondent);
    req.setAccountNo(param.accountNo);
    req.setAccountName(param.accountName);
    req.setMasterAccountNo(param.masterAccountNo);
    req.setBranch(param.branch);
    req.setRep(param.rep);
    req.setBroker(param.broker);
    req.setType(param.type);
    req.setDateType(param.dateType);
    req.setSubAccountNo(param.subAccountNo);
    req.setHideZero(param.hideZero);

    service.listBalanceSummaryReport(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
