import React, { useState } from 'react';
import ClientGrid from '../../../components/ClientGrid/ClientGrid'
import ClientGrid2 from '../../../components/ClientGrid/ClientGrid2'
import Table, { columnType } from '../../../components/ClearingTable/Table';
import Button from '../../../components/Buttons/Button'
import DateTimePicker from '../../../components/Textfields/DateTimePicker'
import AutoCompleteCorrespondent from '../../../components/AutoComplete/AutoCompleteCorrespondent'
import AutoCompleteAccountNo from '../../../components/AutoComplete/AutoCompleteAccountNo'
import AutoCompleteAccountName from '../../../components/AutoComplete/AutoCompleteAccountName'
import AutoCompleteMasterAccountNo from '../../../components/AutoComplete/AutoCompleteMasterAccountNo'
import AutoCompleteBranch from '../../../components/AutoComplete/AutoCompleteBranch'
import AutoCompleteRep from '../../../components/AutoComplete/AutoCompleteRep'
import AutoCompleteSymbol from '../../../components/AutoComplete/AutoCompleteSymbol'
import Text from '../../../components/Typography/Text'
import {
  notifyInfo,
  notifyError,
} from '../../../components/Messages/Notification'
import { positionColumns } from '../../../lib/data/columns'
import { listPosition } from '../../../services/PositionService'
import { getSystemDate } from '../../../services/ProfileService'
import QueryParam from '../../../services/QueryParamService'
import {
  CustomBodyRenderCurrency,
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderDate,
  CustomBodyRenderDateTime,
  CustomBodyRenderBoolean,
  CustomBodyRenderAlign,
  CustomBodyRenderQty,
} from '../../../components/Table/CustomBodyRender';
import { formatPbDate } from '../../../lib/fmt';

export default function Position() {
  const [rows, setRows] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [searchDataCsv, setSearchDataCsv] = React.useState([]);
  const [pagination, setPagination] = useState({});
  const [totalRows, setTotalRows] = useState(0);
  const [previousRows, setPreviousRows] = useState(0);
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      fromDate: '',
      toDate: '',
      correspondent: '',
      branch: '',
      accountNo: '',
      masterAccountNo: '',
      rep: '',
      accountName: '',
      symbol: '',
    }),
  )

  const columns = [
    {
      // Already converted to string in handlesearch
      name: 'date',
      label: 'Date',
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'branch',
      label: 'Branch',
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      name: 'rep',
      label: 'Rep',
    },
    {
      name: 'accountName',
      label: 'Account Name',
    },
    {
      name: 'accountStatus',
      label: 'Account Status',
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'symbolDescription',
      label: 'Symbol Description',
    },
    {
      name: 'qty',
      label: 'Qty',
      options: {
        customBodyRender: CustomBodyRenderQty,
      },
      addFooter: true,
      type: 'qty',
    },
    {
      name: 'closingPrice',
      label: 'Closing Price',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'marketValue',
      label: 'Market Value',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'costBasis',
      label: 'Cost Basis',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
  ];

  const options = {
    serverSide: true,
    selectableRows: 'none',
    responsive: 'standard',
  };

  const init = async () => {
    const searchParameters = localStorage.getItem('search_data')

    const {
      fromDate,
      toDate,
      correspondent,
      branch,
      accountNo,
      masterAccountNo,
      rep,
      accountName,
      symbol,
    } = searchParameters ? JSON.parse(searchParameters) : searchData

    const systemDate = await getSystemDate()

    setSearchData({
      ...searchData,
      fromDate: fromDate ? fromDate : systemDate,
      toDate: toDate ? toDate : systemDate,
      correspondent: correspondent ? correspondent : '',
      branch: branch ? branch : '',
      accountNo: accountNo ? accountNo : '',
      masterAccountNo: masterAccountNo ? masterAccountNo : '',
      rep: rep ? rep : '',
      accountName: accountName ? accountName : '',
      symbol: symbol ? symbol : '',
    })
  }

  React.useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  const handleSearchDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target

    setSearchData({ ...searchData, [input.name]: input.value })
  }

  const handleSearch = async (paginationChange) => {
    try {
      setLoading(true)
      QueryParam.set(searchData)
      let paginationCopy = {
        ...pagination,
        reload: false,
        pageNo: paginationChange === true ? pagination.pageNo : 0,
        rowsPerPage: pagination.rowsPerPage || 100,
      };
      if(previousRows != 0){
        if (previousRows < pagination.rowsPerPage * pagination.pageNo) {
          paginationCopy.pageNo = 0;
        }
      } 
	    if(paginationChange !== true){
        paginationCopy.filterNames = [];
        paginationCopy.filterValues = [];
      }
      localStorage.setItem('search_data', JSON.stringify(searchData))
      const data = await listPosition(searchData, paginationCopy)
      console.log(data.summary);
      let rowsCopy = data.positionsList;
      const rowsCopyOutput = rowsCopy.map((data) => {
        let tempDate = formatPbDate(data.date);
        data.date = tempDate

        return data;
      })
      setSearchDataCsv(searchData);
      setTotalRows(data.summary.totalRows);
      setPreviousRows(totalRows);
      paginationCopy.count = data.summary.totalRows;
      setPagination(paginationCopy);
      setRows(rowsCopyOutput)
      if (paginationChange !== true) {
        notifyInfo(data.summary.totalRows + ' search results.');
      }
    } catch (error) {
      setLoading(false)
      notifyError(error.message)
      console.log(error)
      return
    } finally {
      setLoading(false)
    }
  }

  const getCsvData = async () => {
    let paginationCopy = {
      ...pagination,
      reload: false,
      pageNo: 0,
      rowsPerPage: totalRows,
    };
    const data = await listPosition(searchDataCsv, paginationCopy)

    const csvData = data.positionsList.map((data) => {
      let tempDate = formatPbDate(data.date);
      data.date = tempDate

      return data;
    })

    console.log("CSV: " + JSON.stringify(csvData))

    return csvData
  }

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Position" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading}
              loading={loading}
              type="search"
              label={loading ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell-none">
            <DateTimePicker
              type="date"
              name="fromDate"
              label="From Date"
              value={searchData.fromDate}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell-none">
            <DateTimePicker
              type="date"
              name="toDate"
              label="To Date"
              value={searchData.toDate}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteCorrespondent
              isAccessibleOnly={true}
              name="correspondent"
              value={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteBranch
              isAccessibleOnly={true}
              name="branch"
              value={searchData.branch}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteAccountNo
              isAccessibleOnly={true}
              name="accountNo"
              value={searchData.accountNo}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteMasterAccountNo
              isAccessibleOnly={true}
              name="masterAccountNo"
              value={searchData.masterAccountNo}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteRep
              isAccessibleOnly={true}
              name="rep"
              value={searchData.rep}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteAccountName
              isAccessibleOnly={true}
              name="accountName"
              value={searchData.accountName}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteSymbol
              name="symbol"
              value={searchData.symbol}
              onChange={handleSearchDataChange}
            />
          </div>
        </div>
      </div>
      <div className="mt-20">
        {/* <ClientGrid
          title="Position"
          rowIdField="trnsId"
          columns={positionColumns}
          rows={rows}
          getCsvData={getCsvData}
          isLoading={loading}
          setRows={setRows}
        /> */}
        {/* <ClientGrid2
            title="Position"
            data={rows}
            columns={columns}
            options={options}
            style={{ width:100 }}
        /> */}
        <Table
          title={'Position'}
          data={rows}
          columns={columns}
          options={options}
          getCsvData={getCsvData}
          pagination={pagination}
          setPagination={setPagination}
          onSearch={handleSearch}
        />
      </div>
    </div>
  )
}
