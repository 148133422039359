import React, { useEffect, useState } from 'react';
import Button from '../../../components/Buttons/Button';
import DateTimePicker from '../../../components/Textfields/DateTimePicker';
import AutoCompleteCorrespondent from '../../../components/AutoComplete/AutoCompleteCorrespondent';
import AutoCompleteAccountNo from '../../../components/AutoComplete/AutoCompleteAccountNo';
import AutoCompleteAccountName from '../../../components/AutoComplete/AutoCompleteAccountName';
import AutoCompleteMasterAccountNo from '../../../components/AutoComplete/AutoCompleteMasterAccountNo';
import AutoCompleteBranch from '../../../components/AutoComplete/AutoCompleteBranch';
import AutoCompleteRep from '../../../components/AutoComplete/AutoCompleteRep';
import SelectSystemCode from '../../../components/Dropdown/SelectSystemCode';
import Text from '../../../components/Typography/Text';
import Checkbox from '../../../components/Misc/Checkbox';
import { notifyInfo, notifyError } from '../../../components/Messages/Notification';
import { getSystemDate } from '../../../services/ProfileService';
import { listBalanceSummaryReport } from '../../../services/ReportBalanceService';
import QueryParam from '../../../services/QueryParamService';
import { BalanceSummaryTable } from './BalanceSummaryTable';
import { formatPbDate, formatPbDateTime } from '../../../lib/fmt';

export default function ReportBalanceSummary() {
  const setSearchDataSystemDate = async () => {
    const systemDate = await getSystemDate();
    setSearchData({ ...searchData, date: systemDate });
  };

  useEffect(() => {
    setSearchDataSystemDate();
    // eslint-disable-next-line
  }, []);

  const [isLoading, setIsLoading] = React.useState(false);
  const [rows, setRows] = useState([]);
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      correspondent: '',
      accountName: '',
      accountNo: '',
      broker: '',
      masterAccountNo: '',
      type: '',
      date: '',
      dateType: 'System Date',
      subAccountNo: '',
      hideZero: true,
    })
  );

  const handleChange = (e, checkboxValue) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    // setSearchData({
    //   ...searchData,
    //   [input.name]: checkboxValue !== undefined ? checkboxValue : input.value,
    // });

    if(input.name === "hideZero") {
      setSearchData({
        ...searchData,
        [input.name]: checkboxValue !== undefined ? checkboxValue : input.value,
      });
    } else {
      console.log("test")
      setSearchData({
        ...searchData,
        [input.name]: input.value,
      });
    }
  };

  const handleSearch = async () => {
    try {
      QueryParam.set(searchData);
      setIsLoading(true);
      const data = await listBalanceSummaryReport(searchData);

      let rowsCopy = data.balanceSummaryReportsList;

      const rowsCopyOutput = rowsCopy.map((data) => {
        let tempDate = formatPbDate(data.date);
        data.date = tempDate

        return data;
      })

      setRows(rowsCopyOutput);

      notifyInfo(
        data.balanceSummaryReportsList.length > 0
          ? data.balanceSummaryReportsList.length + ' search result(s)'
          : 'No records found.'
      );
    } catch (error) {
      notifyError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Balance Summary" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={isLoading}
              loading={isLoading}
              type="search"
              label={isLoading ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell-2">
            <SelectSystemCode
              name="dateType"
              label="Date Type"
              type="Date Type Balance Summary"
              value={searchData.dateType}
              onChange={handleChange}
              disableBlank
            ></SelectSystemCode>
          </div>
          <div className="grd-cell-2">
            <DateTimePicker
              type="date"
              name="date"
              label="Date"
              value={searchData.date}
              onChange={handleChange}
            />
          </div>
          <div className="grd-cell-2">
            <AutoCompleteCorrespondent
              isAccessibleOnly={true}
              name="correspondent"
              value={searchData.correspondent}
              onChange={handleChange}
            />
          </div>
          <div className="grd-cell-2">
            <AutoCompleteAccountNo
              isAccessibleOnly={true}
              name="accountNo"
              value={searchData.accountNo}
              correspondent={searchData.correspondent}
              onChange={handleChange}
            />
          </div>
          <div className="grd-cell-2">
            <AutoCompleteAccountName
              isAccessibleOnly={true}
              name="accountName"
              value={searchData.accountName}
              correspondent={searchData.correspondent}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <AutoCompleteMasterAccountNo
              isAccessibleOnly={true}
              name="masterAccountNo"
              value={searchData.masterAccountNo}
              correspondent={searchData.correspondent}
              onChange={handleChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteBranch
              isAccessibleOnly={true}
              name="branch"
              value={searchData.branch}
              correspondent={searchData.correspondent}
              onChange={handleChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteRep
              isAccessibleOnly={true}
              name="rep"
              value={searchData.rep}
              correspondent={searchData.correspondent}
              onChange={handleChange}
            />
          </div>
          <div className="grd-cell">
            <SelectSystemCode
              name="broker"
              label="Broker"
              type="Broker"
              value={searchData.broker}
              onChange={handleChange}
            ></SelectSystemCode>
          </div>
          <div className="grd-cell">
            <SelectSystemCode
              name="type"
              label="Type"
              type="Sub Account Type"
              itemLabel="code"
              value={searchData.type}
              onChange={handleChange}
            ></SelectSystemCode>
          </div>
          <div className="grd-cell">
            <Checkbox
              ml={-3}
              name="hideZero"
              label="Hide Zero Values"
              labelPlacement="top"
              checked={searchData.hideZero}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="mt-20">
        <BalanceSummaryTable data={rows} />
      </div>
    </div>
  );
}
