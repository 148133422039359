/* eslint-disable no-inner-declarations */
import React, { useState, useEffect } from 'react';
import { FormControlLabel, RadioGroup, Radio, IconButton } from '@material-ui/core';
import { Create as EditIcon } from '@material-ui/icons';

import AutoCompleteMasterAccountNo from '../../../components/AutoComplete/AutoCompleteMasterAccountNo';
import AutoCompleteCorrespondent from '../../../components/AutoComplete/AutoCompleteCorrespondent';
import AutoCompleteAccountNo from '../../../components/AutoComplete/AutoCompleteAccountNo';
import AutoCompleteBranch from '../../../components/AutoComplete/AutoCompleteBranch';
import AutoCompleteRep from '../../../components/AutoComplete/AutoCompleteRep';
import ClientAccountAccessAddModal from './ClientAccountAccessAddModal';
import ClientAccountAccessorModal from './ClientAccountAccessorModal';
import ClientGrid from '../../../components/ClientGrid/ClientGrid';
import Button from '../../../components/Buttons/Button';
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from '../../../components/Messages/Notification';

import {
  listAccountAccess,
  checkAccountAccess,
  uncheckAccountAccess,
} from '../../../services/ClientAccountAccessService';

export default function ClientAccountAccessTable({ roleId, tableLvl }) {
  const [loading, setLoading] = useState({ search: false, addremove: false });
  const [rows, setRows] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [idLists, setIdLists] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [openAccessor, setOpenAccessor] = useState(false);
  const [accountId, setAccountId] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedListStatus, setSelectedListStatus] = useState('Show Selected');
  const [searchData, setSearchData] = useState({
    usrId: 0,
    roleId: roleId,
    correspondent: '',
    branch: '',
    accountNo: '',
    accountName: '',
    masterAccountNo: '',
    rep: '',
    broker: '',
    status: 'Active',
  });

  const [pagination, setPagination] = useState({
    pageNo: 0,
    rowsPerPage: 100,
  });

  async function fetchData(selectedStatus) {
    const params = {
      ...searchData,
      status: '',
      roleId: roleId,
      requestType: selectedStatus,
    };
    let paginationCopy = {
      ...pagination,
      reload: false,
      pageNo: pagination.pageNo,
      rowsPerPage: pagination.rowsPerPage || 100,
    };
    const data = await listAccountAccess(params, paginationCopy);
    setIdLists(
      data.accountAccessesList
        .filter((access) => access.accountAccessSetupId !== 0)
        .map((access) => access.accountAccessId)
    );
    setRows(data.accountAccessesList);
    let selected = [];
    data.accountAccessesList.filter(function (v, index) {
      if (v.accountAccessSetupId !== 0) {
        selected.push(index);
      }
    });

    if (selectedStatus === 'Show All') {
      data.accountAccessesList.filter(function (v, index) {
        if (v.accountAccessSetupId !== 0) {
          selected.push(index);
        }
      });

      setRows(data.accountAccessesList);
    } else if (selectedStatus === 'Show Selected') {
      let showSelected = [];

      data.accountAccessesList.filter(function (v, index) {
        if (v.accountAccessSetupId !== 0) {
          showSelected.push(v);
        }
      });

      showSelected.filter(function (v, index) {
        selected.push(index);
      });

      setRows(showSelected);
    } else {
      let showUnselected = [];

      if (params.status !== '') {
        data.accountAccessesList.filter(function (v, index) {
          if (v.accountAccessSetupId === 0 && v.status === params.status) {
            showUnselected.push(v);
          }
        });
      } else {
        data.accountAccessesList.filter(function (v, index) {
          if (v.accountAccessSetupId === 0) {
            showUnselected.push(v);
          }
        });
      }
      setRows(showUnselected);
    }
  }

  useEffect(() => {
    handleSearch();
  }, []);

  const GridButtons = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    return (
      <div align={'left'} className="grd-row" style={{ margin: 0, paddingLeft: 20 }}>
        <div align={'left'} className="grd-cell-none">
          <IconButton
            aria-label="edit"
            disabled={loading.search}
            onClick={(e) => {
              e.preventDefault();
              handleOpen(data, true);
            }}
          >
            <EditIcon style={{ height: 20, width: 20 }} />
          </IconButton>
        </div>
      </div>
    );
  };

  const GridCheckbox = ({
    tableManager,
    value,
    data,
    column,
    colIndex,
    rowIndex,
    onChange,
    disabled,
  }) => {
    const ids = tableManager.config.additionalProps.header.props.idLists;
    const checkboxValue = ids.includes(data.accountAccessId);
    const selectedStatus =
      tableManager.config.additionalProps.header.props.selectedListStatus;
    return (
      <div style={{ paddingLeft: 20 }}>
        <input
          key={rowIndex}
          type="checkbox"
          checked={checkboxValue}
          disabled={disabled}
          column={column}
          onChange={(event) => {
            const checked = event.target.checked;
            if (checked) {
              const param = {
                accountAccessId: data.accountAccessId,
                usrId: data.usrId,
                roleId: roleId,
                accountId: data.accountId,
                accountName: data.accountName,
                accountNo: data.accountNo,
                correspondent: data.correspondent,
                masterAccountNo: data.masterAccountNo,
                branch: data.branch,
                rep: data.rep,
                status: data.status,
              };

              data.accountAccessSetupId = 1;
              handleAdd(param, selectedStatus);
            } else {
              const param = {
                accountAccessId: data.accountAccessId,
                usrId: data.usrId,
                roleId: roleId,
                accountId: data.accountId,
                accountName: data.accountName,
                accountNo: data.accountNo,
                correspondent: data.correspondent,
                masterAccountNo: data.masterAccountNo,
                branch: data.branch,
                rep: data.rep,
                status: data.status,
              };

              data.accountAccessSetupId = 0;
              handleRemove(param, selectedStatus);
            }
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      id: 'accountAccess',
      width: '60px',
      visible: true,
      cellRenderer: GridCheckbox,
    },
    {
      id: '',
      field: '',
      width: '60px',
      cellRenderer: GridButtons,
    },
    {
      id: 'correspondent',
      field: 'correspondent',
      label: 'Correspondent',
    },
    {
      id: 'branch',
      field: 'branch',
      label: 'Branch',
    },
    {
      id: 'accountNo',
      field: 'accountNo',
      label: 'Account No',
    },
    {
      id: 'masterAccountNo',
      field: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      id: 'rep',
      field: 'rep',
      label: 'Rep',
    },
    {
      id: 'status',
      field: 'status',
      label: 'Status',
    },
  ];

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;
    setSearchData({
      ...searchData,
      [input.name]: input.value,
    });
  };

  const handleListStatusChange = async (e) => {
    let paginationCopy = {
      ...pagination,
      reload: false,
      pageNo: e === true ? pagination.pageNo : 0,
      rowsPerPage: pagination.rowsPerPage || 100,
    };

    const selectedOption = e.target.value;
    const params = {
      ...searchData,
      requestType: selectedOption,
      roleId: roleId,
    };
    const data = await listAccountAccess(params, paginationCopy);
    let selected = [];

    if (selectedOption === 'Show All') {
      data.accountAccessesList.filter(function (v, index) {
        if (params.status !== '') {
          if (v.accountAccessSetupId !== 0 && v.status === params.status) {
            selected.push(index);
          }
        } else {
          if (v.accountAccessSetupId !== 0) {
            selected.push(index);
          }
        }
      });

      setRows(data.accountAccessesList);
    } else if (selectedOption === 'Show Selected') {
      let showSelected = [];
      if (params.status !== '') {
        data.accountAccessesList.filter(function (v, index) {
          if (v.accountAccessSetupId !== 0 && v.status === params.status) {
            showSelected.push(v);
          }
        });
      } else {
        data.accountAccessesList.filter(function (v, index) {
          if (v.accountAccessSetupId !== 0) {
            showSelected.push(v);
          }
        });
      }

      showSelected.filter(function (v, index) {
        selected.push(index);
      });

      setRows(showSelected);
    } else {
      let showUnselected = [];

      if (params.status !== '') {
        data.accountAccessesList.filter(function (v, index) {
          if (v.accountAccessSetupId === 0 && v.status === params.status) {
            showUnselected.push(v);
          }
        });
      } else {
        data.accountAccessesList.filter(function (v, index) {
          if (v.accountAccessSetupId === 0) {
            showUnselected.push(v);
          }
        });
      }
      setRows(showUnselected);
    }
    setSelectedListStatus(selectedOption);
  };

  const handleSearch = async (notify) => {
    let paginationCopy = {
      ...pagination,
      reload: false,
      pageNo: notify === true ? pagination.pageNo : 0,
      rowsPerPage: pagination.rowsPerPage || 100,
    };

    try {
      setLoading({ ...loading, search: true });
      var data;
      // Empty broker and status filters for add/remove access modal
      if (tableLvl !== 1) {
        //modal
        const searchDataCopy = {
          ...searchData,
          requestType: selectedListStatus,
          roleId: roleId,
        };
        data = await listAccountAccess(searchDataCopy, paginationCopy);
        setIdLists(
          data.accountAccessesList
            .filter((access) => access.accountAccessSetupId !== 0)
            .map((access) => access.accountAccessId)
        );
        paginationCopy.count = data.totalRows;
        setPagination(paginationCopy);
      }

      setRows(data.accountAccessesList);

      let selected = [];

      if (selectedListStatus === 'Show All') {
        data.accountAccessesList.filter(function (v, index) {
          if (v.accountAccessSetupId !== 0) {
            selected.push(index);
          }
        });

        setRows(data.accountAccessesList);
        if (notify !== false) {
          if (tableLvl === 2) {
            notifyInfo(data.accountAccessesList.length + ' search results.');
          }
        }
      } else if (selectedListStatus === 'Show Selected') {
        let showSelected = [];

        data.accountAccessesList.filter(function (v, index) {
          if (v.accountAccessSetupId !== 0) {
            showSelected.push(v);
          }
        });

        showSelected.filter(function (v, index) {
          selected.push(index);
        });

        setRows(showSelected);
        if (notify !== false) {
          if (tableLvl === 2) {
            notifyInfo(showSelected.length + ' search results.');
          }
        }
      } else {
        let showUnselected = [];

        data.accountAccessesList.filter(function (v, index) {
          if (v.accountAccessSetupId === 0) {
            showUnselected.push(v);
          }
        });

        setRows(showUnselected);

        if (notify !== false) {
          if (tableLvl === 2) {
            notifyInfo(showUnselected.length + ' search results.');
          }
        }
      }
    } catch (error) {
      notifyError(error.message);
    } finally {
      setLoading({ ...loading, search: false });
    }
  };

  const handleOpen = (data, isEdit) => {
    if (isEdit) {
      setRowData(data);
    } else {
      setRowData({
        usrId: 0,
        roleId: roleId,
        correspondent: '',
        accounAccessId: '',
        branch: '',
        accountNo: '',
        accountName: '',
        masterAccountNo: '',
        rep: '',
        broker: '',
        status: 'Active',
      });
    }
    setIsEdit(isEdit);
    setOpen(true);
  };

  const handleClose = async () => {
    handleSearch();
    setOpen(false);
  };

  const handleCloseAccessor = async () => {
    setOpenAccessor(false);
  };

  const handleAdd = async (param, selectedStatus) => {
    try {
      setLoading({ ...loading, addremove: true });
      await checkAccountAccess(param);
      notifySuccess(param.accountNo + ' has been added.');
    } catch (error) {
      notifyError(error.message);
    } finally {
      setLoading({ ...loading, addremove: false });
    }
    fetchData(selectedStatus);
  };

  const handleRemove = async (param, selectedStatus) => {
    try {
      setLoading({ ...loading, addremove: true });

      await uncheckAccountAccess(param);
      notifySuccess(param.accountNo + ' has been removed.');
    } catch (error) {
      notifyError(error.message);
    } finally {
      setLoading({ ...loading, addremove: false });
      fetchData(selectedStatus);
    }
  };

  const getCsvData = async () => {
    let paginationCopy = {
      ...pagination,
      reload: false,
      pageNo: 0,
      rowsPerPage: pagination.rowsPerPage || 100,
    };

    try {
      var data;
      // Empty broker and status filters for add/remove access modal
      if (tableLvl !== 1) {
        //modal
        const searchDataCopy = {
          ...searchData,
          requestType: selectedListStatus,
          roleId: roleId,
        };
        data = await listAccountAccess(searchDataCopy, paginationCopy);
      } else {
        const searchDataCopy = {
          ...searchData,
          requestType: selectedListStatus,
        };
        data = await listAccountAccess(searchDataCopy, paginationCopy);
      }

      if (selectedListStatus === 'Show All') {
        return data.accountAccessesList;
      } else if (selectedListStatus === 'Show Selected') {
        let showSelected = [];

        data.accountAccessesList.filter(function (v, index) {
          if (v.accountAccessSetupId !== 0) {
            showSelected.push(v);
          }
        });
        return showSelected;
      } else {
        let showUnselected = [];

        data.accountAccessesList.filter(function (v, index) {
          if (v.accountAccessSetupId === 0) {
            showUnselected.push(v);
          }
        });
        return showUnselected;
      }
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell"></div>
          <div className="grd-cell-none">
            <Button
              disabled={loading.search}
              loading={loading.search}
              type="search"
              label={loading.search ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
          <div className="grd-cell-none">
            {tableLvl === 2 && (
              <div className="grd-cell-none" style={{ marginRight: 10 }}>
                <Button
                  disabled={
                    searchData.roleId === '' ||
                    searchData.roleId === null ||
                    searchData.roleId === 0
                  }
                  type="plus"
                  label="Add New"
                  onClick={() => {
                    handleOpen();
                  }}
                >
                  Add Account Access
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <AutoCompleteCorrespondent
              name="correspondent"
              isAccessibleOnly={true}
              freeSolo={true}
              value={searchData.correspondent}
              onChange={handleChange}
            ></AutoCompleteCorrespondent>
          </div>
          {tableLvl === 2 && (
            <div className="grd-cell" style={{ marginRight: 30 }}>
              <AutoCompleteAccountNo
                isAccessibleOnly={true}
                freeSolo={true}
                name="accountNo"
                label="Account No"
                value={searchData.accountNo}
                correspondent={searchData.correspondent}
                onChange={handleChange}
                onKeyDown={(e) => (e.keyCode === 13 ? handleSearch() : null)}
              ></AutoCompleteAccountNo>
            </div>
          )}
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <AutoCompleteMasterAccountNo
              freeSolo={true}
              name="masterAccountNo"
              label="Master Account No"
              value={searchData.masterAccountNo}
              onChange={handleChange}
              type="client"
              isAccessibleOnly={true}
            ></AutoCompleteMasterAccountNo>
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <AutoCompleteBranch
              freeSolo={true}
              isAccessibleOnly={true}
              name="branch"
              label="Branch"
              value={searchData.branch}
              onChange={handleChange}
            ></AutoCompleteBranch>
          </div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <AutoCompleteRep
              freeSolo={true}
              isAccessibleOnly={true}
              name="rep"
              label="Rep"
              value={searchData.rep}
              onChange={handleChange}
            ></AutoCompleteRep>
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell" style={{ marginRight: 30 }}>
            {tableLvl === 2 ? (
              <RadioGroup
                style={{ flexDirection: 'row' }}
                name="listStatus"
                value={selectedListStatus}
                onChange={handleListStatusChange}
              >
                <FormControlLabel
                  name="showAll"
                  label="Show All"
                  value="Show All"
                  control={<Radio />}
                />
                <FormControlLabel
                  name="showSelected"
                  label="Show Selected"
                  value="Show Selected"
                  control={<Radio />}
                />
                <FormControlLabel
                  name="showUnselected"
                  label="Show Unselected"
                  value="Show Unselected"
                  control={<Radio />}
                />
              </RadioGroup>
            ) : null}
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell" style={{ marginRight: 30 }}>
            {tableLvl === 2 && (
              <div
                className="grd-cell"
                style={{
                  textAlign: 'right',
                  color: '#1e7dff',
                }}
              >
                <small>Changes will be saved automatically.</small>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-20">
        <ClientGrid
          title="Client Account Access"
          rowIdField="id"
          columns={columns}
          rows={rows}
          setRows={setRows}
          isLoading={loading.search}
          getCsvData={getCsvData}
          idLists={idLists}
          selectedListStatus={selectedListStatus}
        />
      </div>
      {open && (
        <ClientAccountAccessAddModal
          onClose={handleClose}
          open={open}
          id={rowData?.usrId}
          loading={loading.search}
          modalDataInit={rowData}
          isEdit={isEdit}
        ></ClientAccountAccessAddModal>
      )}
      {openAccessor && (
        <ClientAccountAccessorModal
          onClose={handleCloseAccessor}
          open={openAccessor}
          id={accountId}
          tableLvl={1}
        ></ClientAccountAccessorModal>
      )}
    </div>
  );
}
