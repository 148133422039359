import React, { useState, useEffect } from 'react';

import { IconButton } from '@material-ui/core';
import { Create as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';

import SelectAdministratorName from '../../components/AutoComplete/AutoCompleteAdministratorName';
import AutoCompleteAccountNo from '../../components/AutoComplete/AutoCompleteAccountNo';
import SelectPage from '../../components/AutoComplete/SelectPage';
import ClientGrid from '../../components/ClientGrid/ClientGrid';
import ClientGrid2 from '../../components/ClientGrid/ClientGrid2'
import Table, { columnType } from '../../components/ClearingTable/Table';
import SelectRole from '../../components/Dropdown/SelectRole';
import Button from '../../components/Buttons/Button';
import Text from '../../components/Typography/Text';
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from '../../components/Messages/Notification';

import { accessibleAccountNo } from '../../services/CommonService';
import { listAdministrator } from '../../services/AdministratorService';
import { listPage } from '../../services/CommonService';
import { listRole, createRole, updateRole, deleteRole } from '../../services/RoleService';

import RoleModal from './Components/RoleModal';

import {
  CustomBodyRenderCurrency,
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderDate,
  CustomBodyRenderDateTime,
  CustomBodyRenderBoolean,
  CustomBodyRenderAlign,
  CustomBodyRenderCenter,
  CustomBodyRenderQty,
  CustomBodyRenderHtmlString
} from '../../components/Table/CustomBodyRender';

import { useConfirm } from 'material-ui-confirm';

export default function RoleManager() {
  const [rowData, setRowData] = useState({});
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [searchData, setSearchData] = useState({
    roleId: 0,
    roleName: '',
    usrId: '',
    accountNo: '',
    accountId: 0,
    pageId: '',
    pageName: '',
    name: '',
  });
  const confirm = useConfirm();
  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setLoading(true);

    try {
      let { rolesList } = await listRole(searchData);

      setRows(rolesList);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const GridButtons = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    return (
      <div align={'left'} className="grd-row" style={{ margin: 0, paddingLeft: 10 }}>
        <IconButton
          aria-label="edit"
          disabled={loading}
          onClick={() => {
            handleOpen(data);
          }}
        >
          <EditIcon style={{ height: 20, width: 20 }} />
        </IconButton>
        {data?.userCount === '' && (
          <IconButton
            aria-label="delete"
            disabled={loading}
            onClick={() => {
              handleDelete(data);
            }}
          >
            <DeleteIcon style={{ height: 20, width: 20 }} />
          </IconButton>
        )}
      </div>
    );
  };

  // const columns2 = [
  //   {
  //     id: '',
  //     field: '',
  //     width: '60px',
  //     cellRenderer: GridButtons,
  //   },
  //   {
  //     id: 'roleName',
  //     field: 'roleName',
  //     label: 'Role Name',
  //   },
  //   {
  //     id: 'userCount',
  //     field: 'userCount',
  //     label: 'User',
  //   },
  //   {
  //     id: 'accountCount',
  //     field: 'accountCount',
  //     label: 'Account',
  //   },
  //   {
  //     id: 'pageCount',
  //     field: 'pageCount',
  //     label: 'Access',
  //   },
  // ];

  const columns = [
    {
      name: 'actions',
      label: 'Actions',
      sort: false,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div align={'left'} className="grd-row" style={{ margin: 0, paddingLeft: 10 }}>
              <IconButton
                aria-label="edit"
                disabled={loading}
                onClick={() => {
                  handleOpen(rows[dataIndex]);
                }}
              >
                <EditIcon style={{ height: 20, width: 20 }} />
              </IconButton>
              {rows[dataIndex]?.userCount === '' && (
                <IconButton
                  aria-label="delete"
                  disabled={loading}
                  onClick={() => {
                    handleDelete(rows[dataIndex]);
                  }}
                >
                  <DeleteIcon style={{ height: 20, width: 20 }} />
                </IconButton>
              )}
            </div>
          );
        },
      },
    },
    {
      name: 'accountCount',
      label: 'Account',
    },
    {
      name: 'pageCount',
      label: 'Access',
    },
    {
      name: 'roleName',
      label: 'Role Name',
    },
    {
      name: 'userCount',
      label: 'User',
    },
  ];

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
  };

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;
    console.log(input);
    if (['roleName'].includes(input.name)) {
      searchData.roleId = input.roleId;
      searchData.roleName = input.value;
    }
    if (['pageId'].includes(input.name)) {
      searchData.pageId = input.pageId;
      searchData.pageName = input.name;
    }

    setSearchData({
      ...searchData,
      [input.name]: input.value,
    });
  };

  const handleSearch = async (fromCall) => {
    try {
      setLoading(true);
      let searchAccountId = searchData.accountId;
      let searchUserId = searchData.usrId;
      let searchPageId = searchData.pageId;
      if (searchData.accountNo !== '') {
        const { accountNosList } = await accessibleAccountNo('', '', 'client', '', '');

        const accountNo = accountNosList?.find(
          (account) => account.accountNo === searchData.accountNo
        );
        searchAccountId = accountNo?.accountId;
      }
      if (searchData.name !== '') {
        const { administratorsList } = await listAdministrator({ name: searchData.name });
        searchUserId = administratorsList[0].usrId;
      }
      console.log(searchData.pageName)
      console.log(searchPageId)
      if (searchData.pageName !== '') {
        const { pagesList } = await listPage({
          key: searchData.pageName,
          limit: 50,
        });
        searchPageId = pagesList[0]?.pageId;
      }
      const { rolesList } = await listRole({
        ...searchData,
        accountId: searchAccountId,
        usrId: searchUserId,
        pageId: searchPageId,
      });
      if (!fromCall) notifyInfo(rolesList.length + ' search results.');
      setRows(rolesList);
    } catch (error) {
      notifyError(error.message);
    } finally {
      setLoading(false);
      searchData.accountId = 0;
    }
  };

  const handleOpen = (data) => {
    if (!data) {
      //ADD default values
      data = {
        roleName: '',
      };
    }

    setRowData(data);
    setOpen(true);
  };

  const handleClose = async (data, isEdit) => {
    if (!data) {
      setOpen(false);
      handleSearch(true);
      return;
    }

    let valid = true;

    if (!data.roleName) {
      notifyError('Role name is required.');
      valid = false;
    }

    if (!valid) {
      return;
    }
    data.defaultAccess = 'None';
    setLoading(true);
    let resp;
    try {
      if (isEdit) {
        //EDIT
        resp = await updateRole(data);
        const rowsCopy = [...rows];
        const index = rows.indexOf(rowData);
        rowsCopy[index] = resp.roleList;
        setRows(rowsCopy);
        notifySuccess('Role has been updated.');
      } else {
        //ADD
        resp = await createRole(data);
        setRows([resp.roleList, ...rows]);
        notifySuccess('New Role has been added.');
      }

      setOpen(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      notifyError(error.message);
    } finally {
      if (!isEdit) {
        handleOpen(resp);
      }
    }
    handleSearch(true);
    setLoading(false);
  };

  const handleDelete = async (data) => {
    try {
      confirm({
        description:
          "You are about to delete '" + data.roleName + "'. Please confirm your action.",
        confirmationText: 'Yes, delete',
      }).then(async () => {
        setLoading(true);
        await deleteRole(data);
        notifySuccess('Role has been successfully deleted');
        handleSearch(true);
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getCsvData = async () => {
    return rows;
  };

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Role Manager" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading.search}
              loading={loading.search}
              type="search"
              label={loading.search ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
          <div className="grd-cell-none">
            <Button
              type="plus"
              label="Add New"
              onClick={() => {
                handleOpen();
              }}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <SelectRole
              name="roleName"
              label="Role Name"
              value={searchData.roleName}
              onChange={handleChange}
            ></SelectRole>
          </div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <SelectAdministratorName
              name="name"
              label="User"
              value={searchData.name}
              onChange={handleChange}
            ></SelectAdministratorName>
          </div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <AutoCompleteAccountNo
              freeSolo={true}
              name="accountNo"
              label="Account No"
              value={searchData.accountNo}
              correspondent={searchData.correspondent}
              onChange={handleChange}
              type="client"
              isAccessibleOnly={true}
            ></AutoCompleteAccountNo>
          </div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <SelectPage
              name="pageName"
              label="Page Name"
              value={searchData.pageName || ''}
              onChange={handleChange}
            ></SelectPage>
          </div>
        </div>
      </div>
      <div className="mt-20">
        {/* <ClientGrid
          title="Role Manager"
          rowIdField="id"
          rows={rows}
          columns={columns2}
          getCsvData={getCsvData}
        /> */}
        {/* <ClientGrid2
          title="Role Manager"
          data={rows}
          columns={columns}
          options={options}
        /> */}
        <Table
          title={'Role Manager'}
          data={rows}
          columns={columns}
          options={options}
          getCsvData={getCsvData}
        />
      </div>
      {open && <RoleModal onClose={handleClose} open={open} value={rowData}></RoleModal>}
    </div>
  );
}
