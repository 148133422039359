import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Tooltip } from '@material-ui/core';
import {ReactComponent as Add} from '../../assets/icons/plus.svg';
import {ReactComponent as Remove} from '../../assets/icons/minus.svg';
import color from '../../assets/css/colors';

const useStyles = makeStyles(() => ({
    linkButton: {
        backgroundColor: 'unset',
        backgroundImage: 'unset',
        color: color.primary.main,
        padding: 2,
        border: 'unset',
        fontSize: 14,
        fontWeight: 700,
        '& .MuiButton-label': {
            textTransform: 'capitalize',
            fontSize: 14,
            fontWeight: 600,
        },
        '& span > svg': {
            width: 22,
            height: 22,
            position: 'relative',
            top: 1,
            marginRight: 5,
            '& path': {
                fill: color.primary.main,
            },
        },
        '&:hover': {
            backgroundColor: 'unset',
            color: color.primary.light,
            padding: 2,
            border: 'unset',
        },
        '&:hover svg > path': {
            fill: color.primary.light,
        }
    },
}));

const LinkButton = (props) => {
    const classes = useStyles();

    const startIcon = (type) => {
        if (type === 'add') {
            return <Add />;
        } else if (type === 'remove') {
            return <Remove />;
        } else {
            return null
        }
    }

    return (
        <Tooltip arrow title={props.tooltipTitle ? props.tooltipTitle : ''}>
            <Button
                id={props.id}
                style={{marginTop: props.mt ? props.mt : 0, marginBottom: props.mb ? props.mb : 0}}
                className={classes.linkButton}
                onClick={props.onClick}
            >
                {startIcon(props.type)} {props.label}
            </Button>
        </Tooltip>
    );
}
export default LinkButton;