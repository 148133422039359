import React, { useState, useEffect, useContext } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  makeStyles,
} from '@material-ui/core';
import { LayoutContext } from '../Layout/Root';
import { ChevronUpIcon, ChevronDownIcon } from '../Icons/Mono/Navigational';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(2), // Adjust the left margin for indentation
  },
  selected: {
    backgroundColor: theme.palette.primary.opacity + ' !important',
    background: `linear-gradient(to right, ${theme.palette.primary.main} 7px, transparent 7px) !important`,
  },
}));

function MenuItem({ item, selectedIndex, handleListItemClick, hasChildren, className, isParent }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const ctx = useContext(LayoutContext);

  useEffect(() => {
    // This code will run once when the component mounts
    const extractedHrefs = [];

    if (item.submenu) {
      item.submenu.forEach((item) => {
        extractedHrefs.push(item.href);
      });
    }

    const filteredHrefs = extractedHrefs.filter((href) => location.pathname.includes(href));

    if (filteredHrefs && filteredHrefs.length !== 0) {
      // This will open the parent if url is access directly and will open the parent menu
      setOpen(true);
    }

    // If you return a cleanup function, it will be called when the component unmounts
    return () => {
      // Cleanup code
    };
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };

  const properties = () => {
    return hasChildren ? childrenProperties() : parentProperties()
  }

  const parentProperties = () => {

    return {
      component: Link,
      to: item.href,
      className: className,
      button: true,
      dense: true,
      selected: location.pathname === item.href,
      onClick: (event) => handleListItemClick(event, item.index),
      classes: {
        selected: classes.selected,
      },
    }
  }

  const childrenProperties = () => {
    return {
      button: true,
      dense: true,
      onClick: () => handleClick(),
    }
  }

  return (
    <>
      <ListItem
        {...properties()}
      >
        {isParent ? (
          <ListItemIcon>
            {item.icon}
          </ListItemIcon>
        ) : (
          <ListItemIcon>
            {ctx.collapsed ? item.icon : null}
          </ListItemIcon>
        )}<ListItemText
          primary={item.label}
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        />
        {item.submenu && (open ? <ChevronUpIcon fontSize="small" /> : <ChevronDownIcon fontSize="small" />)}
      </ListItem>
      {item.submenu && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding dense>
            {item.submenu.map((subitem, index) => (
              <MenuItem
                key={`${item.index}.${index}`}
                item={{ ...subitem, index: `${item.index}.${index}` }}
                selectedIndex={selectedIndex}
                handleListItemClick={handleListItemClick}
                hasChildren={subitem.submenu ? true : false}
                className={classes.nested}
                isParent={false}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

function APWNavigation({ menuItems }) {

  const [selectedIndex, setSelectedIndex] = useState(`0`);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <React.Fragment>
      <List component="nav" dense disablePadding>
        {menuItems.map((item, index) => (
          <MenuItem
            key={`${index}`}
            item={{ ...item, index: `${index}` }}
            selectedIndex={selectedIndex}
            handleListItemClick={handleListItemClick}
            hasChildren={item.submenu ? true : false}
            isParent={true}
          />
        ))}
      </List>
    </React.Fragment>

  );
}

APWNavigation.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    href: PropTypes.string,
    submenu: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
      href: PropTypes.string.isRequired,
    }))
  })).isRequired,
}

APWNavigation.defaultProps = {
};

export default APWNavigation;
