import React from 'react';
import { GetAppRounded as DownloadIcon } from '@material-ui/icons';
import { IconButton, makeStyles } from '@material-ui/core';
import ClientGrid from '../../../components/ClientGrid/ClientGrid';
import ClientGrid2 from '../../../components/ClientGrid/ClientGrid2'
import Button from '../../../components/Buttons/Button';
import AutoCompleteCorrespondent from '../../../components/AutoComplete/AutoCompleteCorrespondent';
import AutoCompleteAccountNo from '../../../components/AutoComplete/AutoCompleteAccountNo';
import AutoCompleteAccountName from '../../../components/AutoComplete/AutoCompleteAccountName';
import AutoCompleteMasterAccountNo from '../../../components/AutoComplete/AutoCompleteMasterAccountNo';
import AutoCompleteBranch from '../../../components/AutoComplete/AutoCompleteBranch';
import AutoCompleteRep from '../../../components/AutoComplete/AutoCompleteRep';
import SelectMonth from '../../../components/Dropdown/SelectMonth';
import Text from '../../../components/Typography/Text';
import TextField from '../../../components/Textfields/TextField';
import ReportDownloadModal from '../../../components/Modals/ReportDownloadModal';
import { notifyInfo, notifyError, notifySuccess} from '../../../components/Messages/Notification';
import { statementColumns } from '../../../lib/data/columns';
import { monthNames } from '../../../lib/data/common';
import { listStatement } from '../../../services/StatementService';
import QueryParam from '../../../services/QueryParamService';
import { GridTitleStatement } from '../../../components/contants/GridTitle';
import {
  CustomBodyRenderCurrency,
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderDate,
  CustomBodyRenderDateTime,
  CustomBodyRenderBoolean,
  CustomBodyRenderAlign,
  CustomBodyRenderHtmlString,
  CustomBodyRenderCenter,
} from '../../../components/Table/CustomBodyRender';
import Table, { columnType } from '../../../components/ClearingTable/Table';
import color from '../../../assets/css/colors'
import fontFamily from '../../../assets/css/fonts'
import { 
  downloadMonthlyStatement, 
  batchDownloadMonthlyStatement 
} from "../../../services/StatementService";

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottom:'1px solid #ccc',
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
    
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
}))

export default function Statement() {
  const classes = useStyles();

  var currentDate = new Date();
  var previousMonth = currentDate.getMonth();

  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const [loading, setLoading] = React.useState({
    search: false,
    download: false,
  });

  const [downloadData, setDownloadData] = React.useState({
    accountId: '',
    tradeDate: '',
  });

  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      month: monthNames[previousMonth],
      year: currentDate.getFullYear(),
      correspondent: '',
      branch: '',
      accountNo: '',
      masterAccountNo: '',
      rep: '',
      accountName: '',
    })
  );

  const init = () => {
    const searchParameters = localStorage.getItem('search_data');

    const {
      correspondent,
      branch,
      accountNo,
      masterAccountNo,
      rep,
      accountName,
      month,
      year,
    } = searchParameters ? JSON.parse(searchParameters) : searchData;

    setSearchData({
      ...searchData,
      month: month ? month : monthNames[previousMonth],
      year: year ? year : currentDate.getFullYear(),
      correspondent: correspondent ? correspondent : '',
      branch: branch ? branch : '',
      accountNo: accountNo ? accountNo : '',
      masterAccountNo: masterAccountNo ? masterAccountNo : '',
      rep: rep ? rep : '',
      accountName: accountName ? accountName : '',
    });
  };

  React.useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  const GridButtons = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    const { month, year } =
      tableManager.config.additionalProps.header.props.batchDownloadParams;

    return (
      <div>
        <div style={{ padding: 5 }}>
          <IconButton
            aria-label="download"
            onClick={() =>
              handleDownloadStatementReport(data.accountId, month, year, rowIndex)
            }
          >
            <DownloadIcon style={{ height: 24, width: 24 }} />
          </IconButton>
        </div>
      </div>
    );
  };

  const columns2 = [
    {
      id: 'checkbox',
      pinned: true,
      width: '54px',
      visible: true,
    },
    {
      id: 'action',
      field: 'action',
      label: '',
      width: '40px',
      cellRenderer: GridButtons,
    },
    ...statementColumns,
  ];

  const columns = [
    {
      name: 'actions',
      label: 'Actions',
      sort: false,
      options: {
        customBodyRenderLite: (dataIndex, rowIndex, tableManager) => {
          const month = searchData.month;
          const year = searchData.year;
          
          return(
            <div>
              <div style={{ padding: 5 }}>
                <IconButton
                  aria-label="download"
                  onClick={() =>
                    handleDownloadStatementReport(rows[dataIndex].accountId, month, year, rowIndex)
                  }
                >
                  <DownloadIcon style={{ height: 24, width: 24 }} />
                </IconButton>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'branch',
      label: 'Branch',
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'accountName',
      label: 'Account Name',
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      name: 'rep',
      label: 'Rep/Advisor',
    },
  ];

  const options = {
    customToolbarSelect: (selectedRows) => {
      return (
        <div className={classes.grdRow} style={{ marginRight: 30, padding: 0 }}>
          <div className={classes.grdCellNone}>
            <Button
              id="batch"
              disabled={loading.search}
              loading={loading.search}
              type="search"
              label={'BATCH DOWNLOAD'}
              onClick={() => {
                handleBatchDownloadStatementReport(
                  selectedRows.data,
                  selectedRows.data.map((d) => d.dataIndex)
                );
              }}
            />
          </div>
        </div>
      );
    },
  };

  const handleSearchDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setSearchData({ ...searchData, [input.name]: input.value });
  };

  const handleSearch = async () => {
    try {
      setLoading({ ...loading, search: true });

      QueryParam.set(searchData);

      localStorage.setItem('search_data', JSON.stringify(searchData));

      const data = await listStatement(searchData);

      setRows(data.statementsList);

      notifyInfo(
        data.statementsList.length > 0
          ? data.statementsList.length + ' search result(s)'
          : 'No records found.'
      );
    } catch (error) {
      setLoading({ ...loading, search: false });
      notifyError(error.message);
      console.log(error);
      return;
    } finally {
      setLoading({ ...loading, search: false });
    }
  };

  const handleDownloadStatementReport = async (accountId, month, year, dataIndex) => {
    setOpen(true);

    setDownloadData({
      accountId: accountId,
      month: month,
      year: year,
      dataIndex: dataIndex,
    });
  };

  const handleBatchDownloadStatementReport = async (selected, index) => {
    const param = {
      month: searchData.month,
      year: searchData.year,
    };
    const selectedIds = [];

    selected.forEach((data) => {
      selectedIds.push(rows[data.dataIndex].accountId,)
    });

    try {
      for(let i = 0; i < selectedIds.length; i++) {
        await downloadMonthlyStatement(selectedIds[i], param);
      }
      notifySuccess('Statement Download is Completed');
    } catch (error) {
      console.error(error);
      notifyError(error.message);
    }
  };

  const getCsvData = async () => {
    const data = await listStatement(searchData);

    return data.statementsList;
  };

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Monthly Statement" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading.search}
              loading={loading.search}
              type="search"
              label={loading.search ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <SelectMonth
              name="month"
              label="Month"
              placeholder="Month"
              type="Month"
              value={searchData.month}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell-none">
            <TextField
              max={4}
              name="year"
              label="Year"
              placeholder="Year"
              type="text"
              value={searchData.year}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteCorrespondent
              isAccessibleOnly={true}
              name="correspondent"
              value={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteBranch
              isAccessibleOnly={true}
              name="branch"
              value={searchData.branch}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteAccountNo
              isAccessibleOnly={true}
              name="accountNo"
              value={searchData.accountNo}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteMasterAccountNo
              isAccessibleOnly={true}
              name="masterAccountNo"
              value={searchData.masterAccountNo}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteRep
              isAccessibleOnly={true}
              name="rep"
              value={searchData.rep}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteAccountName
              isAccessibleOnly={true}
              name="accountName"
              value={searchData.accountName}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
        </div>
      </div>
      <div className="mt-20">
        {/* <ClientGrid
          title={GridTitleStatement}
          rowIdField="accountId"
          columns={columns2}
          rows={rows}
          getCsvData={getCsvData}
          isLoading={loading}
          setRows={setRows}
          showDownloadBatchAction={true}
          batchDownloadParams={{
            month: searchData.month,
            year: searchData.year,
          }}
        /> */}
        {/* <ClientGrid2
          title="Statement"
          data={rows}
          columns={columns}
          options={options}
          batchDownloadParams={{
            month: searchData.month,
            year: searchData.year,
          }}
        /> */}
        <Table
          title={'Statement'}
          data={rows}
          columns={columns}
          options={options}
        />
      </div>
      <ReportDownloadModal
        title={GridTitleStatement}
        selectedIds={[downloadData.accountId]}
        rows={rows}
        parameters={downloadData}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </div>
  );
}
