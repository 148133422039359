
import {
  AccountAccess,
  AccountAccessServiceClient,
  ListAccountAccessRequest,
  ListAccountAccessorRequest,
  DeleteAccountAccessRequest,
} from '../proto/clientpb/accountaccess_grpc_web_pb';

import { notifyError } from '../components/Messages/Notification';

import { auth } from "../lib/auth/Auth";
import authSvc from './AuthService';

const roleId = authSvc.getCurrentUser()?.RoleId;

const service = new AccountAccessServiceClient(window.env.GRPC_ENDPOINT, {}, {...auth});

export async function createAccountAccess(param) {
  return new Promise((resolve, reject) => {
    const req = new AccountAccess();
    req.setUsrId(param.usrId);
    req.setAccountId(param.accountId);

    service.createAccountAccess(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function createAccountAccessByAccount(param) {
  return new Promise((resolve, reject) => {
    const req = new AccountAccess();
    req.setUsrId(param.usrId);
    req.setRoleId(param.roleId);
    req.setAccountId(param.accountId);
    req.setCorrespondent(param.correspondent);
    req.setMasterAccountNo(param.masterAccountNo);
    req.setBranch(param.branch);
    req.setRep(param.rep);
    req.setAccountNo(param.accountNo);
    req.setStatus(param.status);
    service.createAccountAccessByAccount(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listAccountAccess(param) {
  return new Promise((resolve, reject) => {
    const req = new ListAccountAccessRequest();
    req.setUsrId(param.usrId);
    req.setCorrespondent(param.correspondent);
    req.setBranch(param.branch);
    req.setAccountNo(param.accountNo);
    req.setAccountName(param.accountName);
    req.setMasterAccountNo(param.masterAccountNo);
    req.setRep(param.rep);
    req.setBroker(param.broker);
    req.setStatus(param.status);
    req.setRoleId(param?.roleId);
    req.setUserRoleId(roleId);

    service.listAccountAccess(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listAccessorsOnCreate(id, name, email) {
  return new Promise((resolve, reject) => {
    let req = new ListAccountAccessorRequest();
    req.setAccountId(id);
    req.setName(name);
    req.setEmail(email);

    service.listAccountAccessorOnCreate(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function deleteAccountAccess(param) {
  return new Promise((resolve, reject) => {
    let req = new DeleteAccountAccessRequest();
    req.setAccountAccessId(param.accountAccessId);

    service.deleteAccountAccess(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function checkAccountAccess(param) {
  return new Promise((resolve, reject) => {
    let req = new AccountAccess();
    req.setAccountAccessId(param.accountAccessId);
    req.setRoleId(param.roleId);

    service.checkAccountAccess(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listAccessors(id) {
  return new Promise((resolve, reject) => {
    let req = new ListAccountAccessorRequest();
    req.setAccountId(id);

    service.listAccountAccessor(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function uncheckAccountAccess(param) {
  return new Promise((resolve, reject) => {
    let req = new AccountAccess();
    req.setAccountAccessId(param.accountAccessId);
    req.setRoleId(param.roleId);

    service.uncheckAccountAccess(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateAccountAccess(param) {
  return new Promise((resolve, reject) => {
    const req = new AccountAccess();
    req.setUsrId(param.usrId);
    req.setCorrespondent(param.correspondent);
    req.setMasterAccountNo(param.masterAccountNo);
    req.setBranch(param.branch);
    req.setRep(param.rep);
    req.setAccountNo(param.accountNo);
    req.setStatus(param.status);
    req.setAccountAccessId(param.accountAccessId);
    service.updateAccountAccess(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}