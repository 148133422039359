/**
 * @fileoverview gRPC-Web generated client stub for reportpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_type_date_pb = require('../../google/type/date_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.reportpb = require('./disclosure_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.reportpb.DisclosureServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.reportpb.DisclosureServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.ReadDisclosureRequest,
 *   !proto.reportpb.ReadDisclosureResponse>}
 */
const methodDescriptor_DisclosureService_ReadDisclosure = new grpc.web.MethodDescriptor(
  '/reportpb.DisclosureService/ReadDisclosure',
  grpc.web.MethodType.UNARY,
  proto.reportpb.ReadDisclosureRequest,
  proto.reportpb.ReadDisclosureResponse,
  /**
   * @param {!proto.reportpb.ReadDisclosureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.ReadDisclosureResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.reportpb.ReadDisclosureRequest,
 *   !proto.reportpb.ReadDisclosureResponse>}
 */
const methodInfo_DisclosureService_ReadDisclosure = new grpc.web.AbstractClientBase.MethodInfo(
  proto.reportpb.ReadDisclosureResponse,
  /**
   * @param {!proto.reportpb.ReadDisclosureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.ReadDisclosureResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.ReadDisclosureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.reportpb.ReadDisclosureResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.ReadDisclosureResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.DisclosureServiceClient.prototype.readDisclosure =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.DisclosureService/ReadDisclosure',
      request,
      metadata || {},
      methodDescriptor_DisclosureService_ReadDisclosure,
      callback);
};


/**
 * @param {!proto.reportpb.ReadDisclosureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.ReadDisclosureResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.DisclosureServicePromiseClient.prototype.readDisclosure =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.DisclosureService/ReadDisclosure',
      request,
      metadata || {},
      methodDescriptor_DisclosureService_ReadDisclosure);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.FetchDisclosureRequest,
 *   !proto.reportpb.FetchDisclosureResponse>}
 */
const methodDescriptor_DisclosureService_FetchDisclosures = new grpc.web.MethodDescriptor(
  '/reportpb.DisclosureService/FetchDisclosures',
  grpc.web.MethodType.UNARY,
  proto.reportpb.FetchDisclosureRequest,
  proto.reportpb.FetchDisclosureResponse,
  /**
   * @param {!proto.reportpb.FetchDisclosureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.FetchDisclosureResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.reportpb.FetchDisclosureRequest,
 *   !proto.reportpb.FetchDisclosureResponse>}
 */
const methodInfo_DisclosureService_FetchDisclosures = new grpc.web.AbstractClientBase.MethodInfo(
  proto.reportpb.FetchDisclosureResponse,
  /**
   * @param {!proto.reportpb.FetchDisclosureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.FetchDisclosureResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.FetchDisclosureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.reportpb.FetchDisclosureResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.FetchDisclosureResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.DisclosureServiceClient.prototype.fetchDisclosures =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.DisclosureService/FetchDisclosures',
      request,
      metadata || {},
      methodDescriptor_DisclosureService_FetchDisclosures,
      callback);
};


/**
 * @param {!proto.reportpb.FetchDisclosureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.FetchDisclosureResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.DisclosureServicePromiseClient.prototype.fetchDisclosures =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.DisclosureService/FetchDisclosures',
      request,
      metadata || {},
      methodDescriptor_DisclosureService_FetchDisclosures);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.ReadDisclosureByIdRequest,
 *   !proto.reportpb.ReadDisclosureResponse>}
 */
const methodDescriptor_DisclosureService_ReadDisclosureById = new grpc.web.MethodDescriptor(
  '/reportpb.DisclosureService/ReadDisclosureById',
  grpc.web.MethodType.UNARY,
  proto.reportpb.ReadDisclosureByIdRequest,
  proto.reportpb.ReadDisclosureResponse,
  /**
   * @param {!proto.reportpb.ReadDisclosureByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.ReadDisclosureResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.reportpb.ReadDisclosureByIdRequest,
 *   !proto.reportpb.ReadDisclosureResponse>}
 */
const methodInfo_DisclosureService_ReadDisclosureById = new grpc.web.AbstractClientBase.MethodInfo(
  proto.reportpb.ReadDisclosureResponse,
  /**
   * @param {!proto.reportpb.ReadDisclosureByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.ReadDisclosureResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.ReadDisclosureByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.reportpb.ReadDisclosureResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.ReadDisclosureResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.DisclosureServiceClient.prototype.readDisclosureById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.DisclosureService/ReadDisclosureById',
      request,
      metadata || {},
      methodDescriptor_DisclosureService_ReadDisclosureById,
      callback);
};


/**
 * @param {!proto.reportpb.ReadDisclosureByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.ReadDisclosureResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.DisclosureServicePromiseClient.prototype.readDisclosureById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.DisclosureService/ReadDisclosureById',
      request,
      metadata || {},
      methodDescriptor_DisclosureService_ReadDisclosureById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.SaveClientDisclosureRequest,
 *   !proto.reportpb.Empty>}
 */
const methodDescriptor_DisclosureService_SaveClientDisclosure = new grpc.web.MethodDescriptor(
  '/reportpb.DisclosureService/SaveClientDisclosure',
  grpc.web.MethodType.UNARY,
  proto.reportpb.SaveClientDisclosureRequest,
  proto.reportpb.Empty,
  /**
   * @param {!proto.reportpb.SaveClientDisclosureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.reportpb.SaveClientDisclosureRequest,
 *   !proto.reportpb.Empty>}
 */
const methodInfo_DisclosureService_SaveClientDisclosure = new grpc.web.AbstractClientBase.MethodInfo(
  proto.reportpb.Empty,
  /**
   * @param {!proto.reportpb.SaveClientDisclosureRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.Empty.deserializeBinary
);


/**
 * @param {!proto.reportpb.SaveClientDisclosureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.reportpb.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.DisclosureServiceClient.prototype.saveClientDisclosure =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.DisclosureService/SaveClientDisclosure',
      request,
      metadata || {},
      methodDescriptor_DisclosureService_SaveClientDisclosure,
      callback);
};


/**
 * @param {!proto.reportpb.SaveClientDisclosureRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.Empty>}
 *     Promise that resolves to the response
 */
proto.reportpb.DisclosureServicePromiseClient.prototype.saveClientDisclosure =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.DisclosureService/SaveClientDisclosure',
      request,
      metadata || {},
      methodDescriptor_DisclosureService_SaveClientDisclosure);
};


module.exports = proto.reportpb;

