import React from 'react';
import { Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';

const APWCheckbox = (props) => {
    
    const {...otherProps} = props;

    return (
        <Checkbox {...otherProps} color="primary" size="small" />
    );
}

APWCheckbox.propTypes = {};

APWCheckbox.defaultProps = Object.freeze({
    // Add other default props if needed
});



export default APWCheckbox;