import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import color from '../../../assets/css/colors';
import fontFamily from '../../../assets/css/fonts';

import {
  TextField,
  Modal,
  Backdrop,
  Fade,
  Box,
  AppBar,
  Tab,
  Tabs,
} from '@material-ui/core';

import PropTypes from 'prop-types';

import Button from '../../../components/Buttons/Button';

import ClientAccountAccessTable from './ClientAccountAccessTable';
import AdministratorTable from './AdministratorTable';
import Text from '../../../components/Typography/Text';
import RoleFunctionTable from './RoleFunctionTable';
import AccessTable from './AccessTable';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  fadeModalLg: {
    minWidth: 1400,
    maxHeight: '90vh',
    width: '100%',
    padding: '25px 30px 30px 30px',
    margin: '0px auto',
    backgroundColor: '#ffffff',
    border: 'none',
    borderRadius: 5,
    boxShadow: '0px 0px 10px #333333',
    boxSizing: 'border-box',
    outline: 'none',
    overflowY: 'auto',
  },
  fadeModalSmall: {
    minWidth: 500,
    maxWidth: 500,
    maxHeight: '90vh',
    width: '100%',
    padding: '25px 30px 30px 30px',
    margin: '0px auto',
    backgroundColor: '#ffffff',
    border: 'none',
    borderRadius: 5,
    boxShadow: '0px 0px 10px #333333',
    boxSizing: 'border-box',
    outline: 'none',
    overflowY: 'auto',
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
}));

function TabPanel({ children, value: tabValue, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={tabValue !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {tabValue === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function RoleModal({
  onClose: handleClose,
  open: isOpen,
  value,
  loading,
}) {
  const classes = useStyles();
  const [modalData, setModalData] = useState({
    defaultAccess: 'None',
  });
  const [isEdit, setIsEdit] = useState(false);

  const [tabIndexValue, setTabIndexValue] = React.useState(0);
  const handleChangeTab = (_, newValue) => {
    setTabIndexValue(newValue);
  };

  useEffect(() => {
    if (isOpen) {
      let modalValue = { ...value };
      if (modalValue.roleId) {
        setIsEdit(true);
      }
      setModalData(modalValue);
    }
  }, [isOpen, value]);

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;
    setModalData({
      ...modalData,
      [input.name]: input.value,
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={isEdit ? classes.fadeModalLg : classes.fadeModalSmall }>
          <div className="grd-row">
            <div className="grd-cell">
              <Text
                id="transition-modal-title"
                variant="h2"
                label={isEdit ? 'Edit Role' : 'Add New Role'}
              />
            </div>
          </div>
          <Box mt={5}>
            <form noValidate autoComplete="off">
              <div className="grd-row">
                <div className="grd-cell" style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    required
                    name="roleName"
                    label="Role Name"
                    value={modalData.roleName}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </div>

              {isEdit && (
                <>
                  <AppBar
                    position="static"
                    className={classes.tabHeader}
                    style={{
                      backgroundColor: '#ffffff',
                      boxShadow: 'none',
                      borderBottom: '1px solid #e8e8e8',
                    }}
                  >
                    <Tabs
                      value={tabIndexValue}
                      onChange={handleChangeTab}
                      indicatorColor="primary"
                      textColor="primary"
                      scrollButtons="auto"
                      variant="fullWidth"
                      centered
                    >
                      <Tab label="Accounts Access" {...a11yProps(0)} />
                      <Tab label="Pages Access" {...a11yProps(1)} />
                      <Tab label="Users" {...a11yProps(2)} />
                      <Tab label="Function" {...a11yProps(3)} />
                    </Tabs>
                  </AppBar>
                  <TabPanel className={classes.tabBody} value={tabIndexValue} index={0}>
                    <ClientAccountAccessTable roleId={modalData.roleId} tableLvl={2} />
                  </TabPanel>
                  <TabPanel className={classes.tabBody} value={tabIndexValue} index={1}>
                    <AccessTable id={modalData.roleId} />
                  </TabPanel>
                  <TabPanel className={classes.tabBody} value={tabIndexValue} index={2}>
                    <AdministratorTable fromRole={true} id={modalData.roleId} />
                  </TabPanel>
                  <TabPanel className={classes.tabBody} value={tabIndexValue} index={3}>
                    <RoleFunctionTable fromRole={true} id={modalData.roleId} />
                  </TabPanel>
                </>
              )}
              <div className={classes.modalFooter}>
                <div className="grd-cell-none" style={{ marginRight: 10, marginTop: 20 }}>
                  <Button
                    color="primary"
                    label="Close"
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </div>
                <div className="grd-cell-none">
                  <Button
                    color="secondary"
                    label={loading ? 'Saving...' : 'Save'}
                    onClick={() => {
                      handleClose(modalData, isEdit);
                    }}
                  />
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
