import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import FileDownloadModal from "../Modals/FileDownloadModal";
import ReportDownloadModal from "../Modals/ReportDownloadModal";
import { GridTitleFile, GridTitleStatement, GridTitleTradeConfirm } from "../contants/GridTitle";

export default function Download(props) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      {open && props.title === GridTitleFile && (
        <FileDownloadModal
          selectedIds={props.rowSelectionApi.selectedRowsIds}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
      {open && [GridTitleStatement, GridTitleTradeConfirm].includes(props.title) && (
        <ReportDownloadModal
          title={props.title}
          selectedIds={props.rowSelectionApi.selectedRowsIds}
          setSelectedIds={props.rowSelectionApi.setSelectedRowsIds}
          rows={props.rowsApi}
          parameters={props.parameters}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
      <Tooltip
        style={{ display: props.rowSelectionApi.selectedRowsIds.length > 0 ? "block" : "none" }}
        arrow
        title="Download Selected Files"
      >
        <IconButton onClick={handleOpen}>
          <SaveAltIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}
