import React from 'react'
import Table, { columnType } from '../../../components/ClearingTable/Table';
import Button from '../../../components/Buttons/Button'
import DateTimePicker from '../../../components/Textfields/DateTimePicker'
import SelectSystemCode from '../../../components/Dropdown/SelectSystemCode'
import AutoCompleteAccountNo from '../../../components/AutoComplete/AutoCompleteAccountNo'
import AutoCompleteAccountName from '../../../components/AutoComplete/AutoCompleteAccountName'
import AutoCompleteMasterAccountNo from '../../../components/AutoComplete/AutoCompleteMasterAccountNo'
import AutoCompleteBranch from '../../../components/AutoComplete/AutoCompleteBranch'
import AutoCompleteRep from '../../../components/AutoComplete/AutoCompleteRep'
import AutoCompleteSymbol from '../../../components/AutoComplete/AutoCompleteSymbol'
import AutoCompleteCorrespondent from '../../../components/AutoComplete/AutoCompleteCorrespondent'
import Text from '../../../components/Typography/Text'
import {
  notifyInfo,
  notifyError,
} from '../../../components/Messages/Notification'
import { listActivity } from '../../../services/ActivityService'
import { getSystemDate } from '../../../services/ProfileService'
import QueryParam from '../../../services/QueryParamService'
import {
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderAlign,
  CustomBodyRenderQty,
  CustomBodyRenderHtmlString
} from '../../../components/Table/CustomBodyRender';
import { formatPbDate } from '../../../lib/fmt';
import Checkbox from '../../../components/Misc/Checkbox';
import SelectEntryType from '../../../components/AutoComplete/SelectEntryType'

export default function Activity() {
  const [rows, setRows] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      fromDate: '',
      toDate: '',
      branch: '',
      accountNo: '',
      masterAccountNo: '',
      rep: '',
      accountName: '',
      entryType: '',
      symbol: '',
      side: '',
      status: '',
      compress: false,
      correspondent: '',
      dateType: 'System Date',
    }),
  )
  const [totalQty, setTotalQty] = React.useState(0)
  const [totalGrossAmt, setTotalGrossAmt] = React.useState(0)
  const [totalRegFee, setTotalRegFee] = React.useState(0)
  const [totalTafFee, setTotalTafFee] = React.useState(0)
  const [totalCommission, setTotalCommission] = React.useState(0)
  const [totalFees, setTotalFees] = React.useState(0)
  const [totalNetAmt, setTotalNetAmt] = React.useState(0)

  const init = async () => {
    const searchParameters = localStorage.getItem('search_data')

    const {
      fromDate,
      toDate,
      branch,
      accountNo,
      masterAccountNo,
      rep,
      accountName,
      symbol,
      status,
    } = searchParameters ? JSON.parse(searchParameters) : searchData

    const systemDate = await getSystemDate()

    setSearchData({
      ...searchData,
      fromDate: fromDate ? fromDate : systemDate,
      toDate: toDate ? toDate : systemDate,
      branch: branch ? branch : '',
      accountNo: accountNo ? accountNo : '',
      masterAccountNo: masterAccountNo ? masterAccountNo : '',
      rep: rep ? rep : '',
      accountName: accountName ? accountName : '',
      symbol: symbol ? symbol : '',
      status: status ? status : '',
    })
  }

  React.useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  const columns = [
    {
      name: 'trnsId',
      label: 'TRNS ID',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      }
    },
    {
      name: 'systemDate',
      label: 'System Date',
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'branch',
      label: 'Branch',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'accountNo',
      label: 'Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'rep',
      label: 'Rep',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'accountName',
      label: 'Account Name',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      // Already converted to string in handlesearch
      name: 'tradeDate',
      label: 'Trade Date',
    },
    {
      // Already converted to string in handlesearch
      name: 'settleDate',
      label: 'Settle Date',
    },
    {
      name: 'entryType',
      label: 'Entry Type',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'symbol',
      label: 'Symbol',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'side',
      label: 'Side',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'qty',
      label: 'Qty',
      options: {
        customBodyRender: CustomBodyRenderQty,
      },
      addFooter: true,
      type: 'qty',
    },
    {
      name: 'price',
      label: 'Price',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'grossAmt',
      label: 'Gross Amount',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'regFee',
      label: 'Reg Fee',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'commission',
      label: 'Commission',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'tafFee',
      label: 'Taf Fee',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'netAmt',
      label: 'Net Amount',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'otherFees',
      label: 'Other Fees',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        display: false,
        customBodyRender: CustomBodyRenderHtmlString,
      }
    },
  ];

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
  };

  const handleSearchDataChange = (e, checkboxValue) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target

    setSearchData({ ...searchData, [input.name]: checkboxValue === true || checkboxValue === false ? checkboxValue : input.value, });
  }

  const handleSearch = async () => {
    try {
      setLoading(true)

      QueryParam.set(searchData)

      localStorage.setItem('search_data', JSON.stringify(searchData))

      const data = await listActivity(searchData)

      let qty = 0;
      let grossAmt = 0;
      let regFee = 0;
      let tafFee = 0;
      let commission = 0;
      let fees = 0;
      let netAmt = 0;

      data.activitiesList.forEach((activity) => {
        qty += parseInt(activity.qty);
        grossAmt += parseInt(activity.grossAmt);
        regFee += parseInt(activity.regFee);
        tafFee += parseInt(activity.tafFee);
        commission += parseInt(activity.commission);
        fees += parseInt(activity.fees);
        netAmt += parseInt(activity.netAmt);
      });

      setTotalQty(qty);
      setTotalGrossAmt(grossAmt);
      setTotalRegFee(regFee);
      setTotalTafFee(tafFee);
      setTotalCommission(commission);
      setTotalFees(fees);
      setTotalNetAmt(netAmt);

      let rowsCopy = data.activitiesList;

      const rowsCopyOutput = rowsCopy.map((data) => {
        let tempDate = formatPbDate(data.settleDate);
        data.settleDate = tempDate

        let tempDate2 = formatPbDate(data.tradeDate);
        data.tradeDate = tempDate2

        let tempDate3 = formatPbDate(data.systemDate);
        data.systemDate = tempDate3

        return data;
      })

      setRows(rowsCopyOutput)

      notifyInfo(
        data.activitiesList.length > 0
          ? data.activitiesList.length + ' search result(s)'
          : 'No records found.',
      )
    } catch (error) {
      setLoading(false)
      notifyError(error.message)
      console.log(error)
      return
    } finally {
      setLoading(false)
    }
  }

  const getCsvData = async () => {
    const data = await listActivity(searchData)

    return data.activitiesList
  }

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Activity" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading}
              loading={loading}
              type="search"
              label={loading ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <SelectSystemCode
              name="dateType"
              label="Date Type"
              type="Date Type"
              subType="Activity Report"
              value={searchData.dateType}
              onChange={handleSearchDataChange}
              disableBlank
            ></SelectSystemCode>
          </div>
          <div className="grd-cell">
            <DateTimePicker
              type="date"
              name="fromDate"
              label="From Date"
              value={searchData.fromDate}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <DateTimePicker
              type="date"
              name="toDate"
              label="To Date"
              value={searchData.toDate}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteCorrespondent
              isAccessibleOnly={true}
              name="correspondent"
              value={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteBranch
              isAccessibleOnly={true}
              name="branch"
              value={searchData.branch}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteAccountNo
              isAccessibleOnly={true}
              name="accountNo"
              value={searchData.accountNo}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteMasterAccountNo
              isAccessibleOnly={true}
              name="masterAccountNo"
              value={searchData.masterAccountNo}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <AutoCompleteRep
              isAccessibleOnly={true}
              name="rep"
              value={searchData.rep}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteAccountName
              isAccessibleOnly={true}
              name="accountName"
              value={searchData.accountName}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <SelectEntryType
              name="entryType"
              label="Entry Type"
              value={searchData.entryType}
              onChange={handleSearchDataChange}
              placeholder="Entry Type"
            ></SelectEntryType>
          </div>
          <div className="grd-cell">
            <AutoCompleteSymbol
              name="symbol"
              value={searchData.symbol}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <SelectSystemCode
              name="side"
              label="Side"
              placeholder="Side"
              type="Side"
              subType="Equity"
              value={searchData.side}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <SelectSystemCode
              name="status"
              label="Status"
              placeholder="Status"
              type="Status"
              subType="Transaction"
              value={searchData.status}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <Checkbox
              mt={40}
              name="compress"
              label="Compress"
              checked={searchData.compress}
              onChange={handleSearchDataChange}
            />
          </div>
        </div>
        <div className="mt-20">
          <Table
            title={'Activity'}
            data={rows}
            columns={columns}
            options={options}
          />
        </div>
      </div>
    </div>
  )
}
