import React from 'react';
import SelectSystemCode from './SelectSystemCode';

export default function SelectAction(props) {
  const { assetType } = props;

  // Default to equity
  let type = 'Action Equity';
  let subType = '';

  switch (assetType) {
    case 'MF': //Mutual Fund
      type = 'Action Mutual Funds';
      break;
    case 'O': // Option
      type = 'Action Option';
      break;
    case 'F': // Fixed Income
      type = 'Action';
      subType = 'Side';
      break;
  }

  return (
    <SelectSystemCode type={type} subType={subType} valueType="description" {...props} />
  );
}
