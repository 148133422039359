import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import Text from '../Typography/Text';
import Button from '../Buttons/Button';
import CloseButton from '../Buttons/CloseButton';
import fontFamily from '../../assets/css/fonts';
import color from '../../assets/css/colors';
import { acceptClientNotice } from '../../services/AdministratorService';
import { notifyError, notifySuccess } from '../Messages/Notification';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 30px',
    maxHeight: 600,
    overflowY: 'auto',
    fontFamily: fontFamily.headers,
    color: color.default.main,
    wordBreak: 'break-word',
    '& p': {
      fontSize: 16,
      fontFamily: fontFamily.headers,
      fontWeight: 400,
      lineHeight: '26px',
      color: color.default.main,
      marginTop: 0,
    },
    '& hr': {
      margin: '30px 0px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '50px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
}));

export default function ClientNoticeModal({ open, close, clientNotice }) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);

  const handleAgree = async () => {
    try {
      setLoading(true);
      await acceptClientNotice();

      notifySuccess('You have accepted the Client Notice');
      close();
    } catch (error) {
      notifyError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    close();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div style={{ width: 600 }}>
          <div className={classes.modalHeader}>
            <div>
              <Text mt={3} variant="h2" label="Client Notice" />
            </div>
            <div className={classes.closeButton}>
              <CloseButton onClick={handleClose} />
            </div>
          </div>
          <div className={classes.modalBody}>
            <div dangerouslySetInnerHTML={{ __html: clientNotice }}></div>
          </div>
          <div className={classes.modalFooter}>
            <div style={{ marginRight: 10 }}>
              <Button label="Decline" disabled={loading} onClick={handleClose} />
            </div>
            <div>
              <Button
                loading={loading}
                disabled={loading}
                label={loading ? 'Accepting...' : 'Agree'}
                onClick={handleAgree}
              />
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
