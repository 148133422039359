/**
 * @fileoverview gRPC-Web generated client stub for accountpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.accountpb = require('./address_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.accountpb.AddressServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.accountpb.AddressServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.Address,
 *   !proto.accountpb.CreateAddressResponse>}
 */
const methodDescriptor_AddressService_CreateAddress = new grpc.web.MethodDescriptor(
  '/accountpb.AddressService/CreateAddress',
  grpc.web.MethodType.UNARY,
  proto.accountpb.Address,
  proto.accountpb.CreateAddressResponse,
  /**
   * @param {!proto.accountpb.Address} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.CreateAddressResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.accountpb.Address,
 *   !proto.accountpb.CreateAddressResponse>}
 */
const methodInfo_AddressService_CreateAddress = new grpc.web.AbstractClientBase.MethodInfo(
  proto.accountpb.CreateAddressResponse,
  /**
   * @param {!proto.accountpb.Address} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.CreateAddressResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.Address} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.accountpb.CreateAddressResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.CreateAddressResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.AddressServiceClient.prototype.createAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.AddressService/CreateAddress',
      request,
      metadata || {},
      methodDescriptor_AddressService_CreateAddress,
      callback);
};


/**
 * @param {!proto.accountpb.Address} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.CreateAddressResponse>}
 *     Promise that resolves to the response
 */
proto.accountpb.AddressServicePromiseClient.prototype.createAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.AddressService/CreateAddress',
      request,
      metadata || {},
      methodDescriptor_AddressService_CreateAddress);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.Address,
 *   !proto.accountpb.UpdateAddressResponse>}
 */
const methodDescriptor_AddressService_UpdateAddress = new grpc.web.MethodDescriptor(
  '/accountpb.AddressService/UpdateAddress',
  grpc.web.MethodType.UNARY,
  proto.accountpb.Address,
  proto.accountpb.UpdateAddressResponse,
  /**
   * @param {!proto.accountpb.Address} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.UpdateAddressResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.accountpb.Address,
 *   !proto.accountpb.UpdateAddressResponse>}
 */
const methodInfo_AddressService_UpdateAddress = new grpc.web.AbstractClientBase.MethodInfo(
  proto.accountpb.UpdateAddressResponse,
  /**
   * @param {!proto.accountpb.Address} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.UpdateAddressResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.Address} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.accountpb.UpdateAddressResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.UpdateAddressResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.AddressServiceClient.prototype.updateAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.AddressService/UpdateAddress',
      request,
      metadata || {},
      methodDescriptor_AddressService_UpdateAddress,
      callback);
};


/**
 * @param {!proto.accountpb.Address} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.UpdateAddressResponse>}
 *     Promise that resolves to the response
 */
proto.accountpb.AddressServicePromiseClient.prototype.updateAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.AddressService/UpdateAddress',
      request,
      metadata || {},
      methodDescriptor_AddressService_UpdateAddress);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.DeleteAddressRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_AddressService_DeleteAddress = new grpc.web.MethodDescriptor(
  '/accountpb.AddressService/DeleteAddress',
  grpc.web.MethodType.UNARY,
  proto.accountpb.DeleteAddressRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.accountpb.DeleteAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.accountpb.DeleteAddressRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_AddressService_DeleteAddress = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.accountpb.DeleteAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.accountpb.DeleteAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.AddressServiceClient.prototype.deleteAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.AddressService/DeleteAddress',
      request,
      metadata || {},
      methodDescriptor_AddressService_DeleteAddress,
      callback);
};


/**
 * @param {!proto.accountpb.DeleteAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.accountpb.AddressServicePromiseClient.prototype.deleteAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.AddressService/DeleteAddress',
      request,
      metadata || {},
      methodDescriptor_AddressService_DeleteAddress);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.DeleteOwnerAddressRequest,
 *   !proto.accountpb.DeleteOwnerAddressResponse>}
 */
const methodDescriptor_AddressService_DeleteOwnerAddress = new grpc.web.MethodDescriptor(
  '/accountpb.AddressService/DeleteOwnerAddress',
  grpc.web.MethodType.UNARY,
  proto.accountpb.DeleteOwnerAddressRequest,
  proto.accountpb.DeleteOwnerAddressResponse,
  /**
   * @param {!proto.accountpb.DeleteOwnerAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.DeleteOwnerAddressResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.accountpb.DeleteOwnerAddressRequest,
 *   !proto.accountpb.DeleteOwnerAddressResponse>}
 */
const methodInfo_AddressService_DeleteOwnerAddress = new grpc.web.AbstractClientBase.MethodInfo(
  proto.accountpb.DeleteOwnerAddressResponse,
  /**
   * @param {!proto.accountpb.DeleteOwnerAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.DeleteOwnerAddressResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.DeleteOwnerAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.accountpb.DeleteOwnerAddressResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.DeleteOwnerAddressResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.AddressServiceClient.prototype.deleteOwnerAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.AddressService/DeleteOwnerAddress',
      request,
      metadata || {},
      methodDescriptor_AddressService_DeleteOwnerAddress,
      callback);
};


/**
 * @param {!proto.accountpb.DeleteOwnerAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.DeleteOwnerAddressResponse>}
 *     Promise that resolves to the response
 */
proto.accountpb.AddressServicePromiseClient.prototype.deleteOwnerAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.AddressService/DeleteOwnerAddress',
      request,
      metadata || {},
      methodDescriptor_AddressService_DeleteOwnerAddress);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.BatchCreateAddressRequest,
 *   !proto.accountpb.BatchCreateAddressResponse>}
 */
const methodDescriptor_AddressService_BatchCreateAddress = new grpc.web.MethodDescriptor(
  '/accountpb.AddressService/BatchCreateAddress',
  grpc.web.MethodType.UNARY,
  proto.accountpb.BatchCreateAddressRequest,
  proto.accountpb.BatchCreateAddressResponse,
  /**
   * @param {!proto.accountpb.BatchCreateAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.BatchCreateAddressResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.accountpb.BatchCreateAddressRequest,
 *   !proto.accountpb.BatchCreateAddressResponse>}
 */
const methodInfo_AddressService_BatchCreateAddress = new grpc.web.AbstractClientBase.MethodInfo(
  proto.accountpb.BatchCreateAddressResponse,
  /**
   * @param {!proto.accountpb.BatchCreateAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.BatchCreateAddressResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.BatchCreateAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.accountpb.BatchCreateAddressResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.BatchCreateAddressResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.AddressServiceClient.prototype.batchCreateAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.AddressService/BatchCreateAddress',
      request,
      metadata || {},
      methodDescriptor_AddressService_BatchCreateAddress,
      callback);
};


/**
 * @param {!proto.accountpb.BatchCreateAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.BatchCreateAddressResponse>}
 *     Promise that resolves to the response
 */
proto.accountpb.AddressServicePromiseClient.prototype.batchCreateAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.AddressService/BatchCreateAddress',
      request,
      metadata || {},
      methodDescriptor_AddressService_BatchCreateAddress);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.Address,
 *   !proto.accountpb.ListAddressResponse>}
 */
const methodDescriptor_AddressService_ListAddress = new grpc.web.MethodDescriptor(
  '/accountpb.AddressService/ListAddress',
  grpc.web.MethodType.UNARY,
  proto.accountpb.Address,
  proto.accountpb.ListAddressResponse,
  /**
   * @param {!proto.accountpb.Address} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.ListAddressResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.accountpb.Address,
 *   !proto.accountpb.ListAddressResponse>}
 */
const methodInfo_AddressService_ListAddress = new grpc.web.AbstractClientBase.MethodInfo(
  proto.accountpb.ListAddressResponse,
  /**
   * @param {!proto.accountpb.Address} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.ListAddressResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.Address} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.accountpb.ListAddressResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.ListAddressResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.AddressServiceClient.prototype.listAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.AddressService/ListAddress',
      request,
      metadata || {},
      methodDescriptor_AddressService_ListAddress,
      callback);
};


/**
 * @param {!proto.accountpb.Address} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.ListAddressResponse>}
 *     Promise that resolves to the response
 */
proto.accountpb.AddressServicePromiseClient.prototype.listAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.AddressService/ListAddress',
      request,
      metadata || {},
      methodDescriptor_AddressService_ListAddress);
};


module.exports = proto.accountpb;

