/**
 * @fileoverview gRPC-Web generated client stub for taxfilingpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_type_date_pb = require('../../google/type/date_pb.js')

var proto_commonpb_file_pb = require('../../proto/commonpb/file_pb.js')
const proto = {};
proto.taxfilingpb = require('./form1099_div_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.taxfilingpb.Form1099DivServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.taxfilingpb.Form1099DivServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Form1099DivRequest,
 *   !proto.taxfilingpb.ListForm1099DivResponse>}
 */
const methodDescriptor_Form1099DivService_ListForm1099Div = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099DivService/ListForm1099Div',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Form1099DivRequest,
  proto.taxfilingpb.ListForm1099DivResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099DivRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ListForm1099DivResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Form1099DivRequest,
 *   !proto.taxfilingpb.ListForm1099DivResponse>}
 */
const methodInfo_Form1099DivService_ListForm1099Div = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.ListForm1099DivResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099DivRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ListForm1099DivResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Form1099DivRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.ListForm1099DivResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.ListForm1099DivResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099DivServiceClient.prototype.listForm1099Div =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099DivService/ListForm1099Div',
      request,
      metadata || {},
      methodDescriptor_Form1099DivService_ListForm1099Div,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Form1099DivRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.ListForm1099DivResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099DivServicePromiseClient.prototype.listForm1099Div =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099DivService/ListForm1099Div',
      request,
      metadata || {},
      methodDescriptor_Form1099DivService_ListForm1099Div);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Form1099DivRequest,
 *   !proto.taxfilingpb.ListForm1099DivDetailsResponse>}
 */
const methodDescriptor_Form1099DivService_ListForm1099DivDetails = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099DivService/ListForm1099DivDetails',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Form1099DivRequest,
  proto.taxfilingpb.ListForm1099DivDetailsResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099DivRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ListForm1099DivDetailsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Form1099DivRequest,
 *   !proto.taxfilingpb.ListForm1099DivDetailsResponse>}
 */
const methodInfo_Form1099DivService_ListForm1099DivDetails = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.ListForm1099DivDetailsResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099DivRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.ListForm1099DivDetailsResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Form1099DivRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.ListForm1099DivDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.ListForm1099DivDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099DivServiceClient.prototype.listForm1099DivDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099DivService/ListForm1099DivDetails',
      request,
      metadata || {},
      methodDescriptor_Form1099DivService_ListForm1099DivDetails,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Form1099DivRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.ListForm1099DivDetailsResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099DivServicePromiseClient.prototype.listForm1099DivDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099DivService/ListForm1099DivDetails',
      request,
      metadata || {},
      methodDescriptor_Form1099DivService_ListForm1099DivDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.Form1099DivProcessRequest,
 *   !proto.taxfilingpb.Form1099DivProcessResponse>}
 */
const methodDescriptor_Form1099DivService_Form1099DivProcess = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099DivService/Form1099DivProcess',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.Form1099DivProcessRequest,
  proto.taxfilingpb.Form1099DivProcessResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099DivProcessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.Form1099DivProcessResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.Form1099DivProcessRequest,
 *   !proto.taxfilingpb.Form1099DivProcessResponse>}
 */
const methodInfo_Form1099DivService_Form1099DivProcess = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.Form1099DivProcessResponse,
  /**
   * @param {!proto.taxfilingpb.Form1099DivProcessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.Form1099DivProcessResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.Form1099DivProcessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.Form1099DivProcessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.Form1099DivProcessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099DivServiceClient.prototype.form1099DivProcess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099DivService/Form1099DivProcess',
      request,
      metadata || {},
      methodDescriptor_Form1099DivService_Form1099DivProcess,
      callback);
};


/**
 * @param {!proto.taxfilingpb.Form1099DivProcessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.Form1099DivProcessResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099DivServicePromiseClient.prototype.form1099DivProcess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099DivService/Form1099DivProcess',
      request,
      metadata || {},
      methodDescriptor_Form1099DivService_Form1099DivProcess);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.DownLoadDivRequest,
 *   !proto.commonpb.File>}
 */
const methodDescriptor_Form1099DivService_Download = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099DivService/Download',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.DownLoadDivRequest,
  proto_commonpb_file_pb.File,
  /**
   * @param {!proto.taxfilingpb.DownLoadDivRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_commonpb_file_pb.File.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.DownLoadDivRequest,
 *   !proto.commonpb.File>}
 */
const methodInfo_Form1099DivService_Download = new grpc.web.AbstractClientBase.MethodInfo(
  proto_commonpb_file_pb.File,
  /**
   * @param {!proto.taxfilingpb.DownLoadDivRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto_commonpb_file_pb.File.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.DownLoadDivRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.File)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.File>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099DivServiceClient.prototype.download =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099DivService/Download',
      request,
      metadata || {},
      methodDescriptor_Form1099DivService_Download,
      callback);
};


/**
 * @param {!proto.taxfilingpb.DownLoadDivRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.File>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099DivServicePromiseClient.prototype.download =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099DivService/Download',
      request,
      metadata || {},
      methodDescriptor_Form1099DivService_Download);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.taxfilingpb.DownLoadDivRequest,
 *   !proto.taxfilingpb.GenerateDivResponse>}
 */
const methodDescriptor_Form1099DivService_Generate = new grpc.web.MethodDescriptor(
  '/taxfilingpb.Form1099DivService/Generate',
  grpc.web.MethodType.UNARY,
  proto.taxfilingpb.DownLoadDivRequest,
  proto.taxfilingpb.GenerateDivResponse,
  /**
   * @param {!proto.taxfilingpb.DownLoadDivRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.GenerateDivResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.taxfilingpb.DownLoadDivRequest,
 *   !proto.taxfilingpb.GenerateDivResponse>}
 */
const methodInfo_Form1099DivService_Generate = new grpc.web.AbstractClientBase.MethodInfo(
  proto.taxfilingpb.GenerateDivResponse,
  /**
   * @param {!proto.taxfilingpb.DownLoadDivRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.taxfilingpb.GenerateDivResponse.deserializeBinary
);


/**
 * @param {!proto.taxfilingpb.DownLoadDivRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.taxfilingpb.GenerateDivResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.taxfilingpb.GenerateDivResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.taxfilingpb.Form1099DivServiceClient.prototype.generate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/taxfilingpb.Form1099DivService/Generate',
      request,
      metadata || {},
      methodDescriptor_Form1099DivService_Generate,
      callback);
};


/**
 * @param {!proto.taxfilingpb.DownLoadDivRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.taxfilingpb.GenerateDivResponse>}
 *     Promise that resolves to the response
 */
proto.taxfilingpb.Form1099DivServicePromiseClient.prototype.generate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/taxfilingpb.Form1099DivService/Generate',
      request,
      metadata || {},
      methodDescriptor_Form1099DivService_Generate);
};


module.exports = proto.taxfilingpb;

