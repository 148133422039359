import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, Divider } from '@material-ui/core';
import Text from '../Typography/Text';
import Button from '../Buttons/Button';
import CloseButton from '../Buttons/CloseButton';
import Checkbox from '../Misc/Checkbox';
//import { notifyError } from '../Messages/Notification';
import color from '../../assets/css/colors';
import fontFamily from '../../assets/css/fonts';
import { readDisclosure } from '../../services/DisclosureService';
import { getCorrespondentByUrl } from '../../services/TemplateService';

const useStyles = makeStyles(() => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	modalHeader: {
		backgroundColor: 'white',
		padding: 30,
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
		display: 'flex',
		'& div:first-child': {
			flex: 1,
		},
		'& div:last-child': {
			flex: 'none',
			'& .MuiIconButton-root': {
				borderRadius: 5,
				padding: 10,
				'&:hover svg path': {
					fill: color.primary.main,
				},
			},
			'& svg:last-child': {
				width: 15,
				height: 15,
			},
		},
	},
	modalBody: {
		backgroundColor: 'white',
		padding: '0px 30px',
		maxHeight: 600,
		overflowY: 'auto',
		fontFamily: fontFamily.headers,
		color: color.default.main,
		wordBreak: 'break-word',
		'& p': {
			fontSize: 16,
			fontFamily: fontFamily.headers,
			fontWeight: 400,
			lineHeight: '26px',
			color: color.default.main,
			marginTop: 0,
		},
		'& hr': {
			margin: '30px 0px',
		},
		'& > button': {
			float: 'right',
			width: 120,
			marginTop: '30px !important',
		},
	},
	modalFooter: {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'flex-end',
		backgroundColor: 'white',
		padding: '0px 30px 30px 30px',
		borderBottomLeftRadius: 10,
		borderBottomRightRadius: 10,
		position: 'relative',
	},
	closeButton: {
		'& button': {
			marginTop: '-5px !important',
		},
	},
}));

export default function ESignatureModal(props) {
	const classes = useStyles();

	const [disclosure, setDisclosure] = React.useState('');
	const [haveReadDisclosure, setHaveReadDisclosure] = React.useState(false);

	const init = async () => {
		try {          
			
			let url = window.location.origin;
			const serverCorrespondent = await getCorrespondentByUrl(url);
			let correspondent = serverCorrespondent.correspondent.correspondent;
			const res = await readDisclosure('Client Site', correspondent);

			setDisclosure(res.disclosure.disclosure);
		} catch (error) {}
	};

	React.useEffect(() => {
		init();
		// eslint-disable-next-line
	}, []);

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={props.open}
			onClose={props.onClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={props.open}>
				<div style={{ width: 800 }}>
					<div className={classes.modalHeader}>
						<div>
							<Text mt={3} variant="h2" label={props.modalTitle} />
						</div>
						<div className={classes.closeButton}>
							<CloseButton onClick={props.onClose} />
						</div>
					</div>
					<div className={classes.modalBody}>
						{disclosure !== '' ? (
							<div dangerouslySetInnerHTML={{ __html: disclosure }}></div>
						) : (
							<div
								style={{
									width: '100%',
									textAlign: 'center',
									padding: '30px 0px',
								}}
							>
								No Disclosure Found.
							</div>
						)}
						<Checkbox
							mt={20}
							ml={-3}
							name="haveReadDisclosure"
							label=" I have read the disclosure and understand the possible risks."
							checked={haveReadDisclosure}
							onChange={(e) => setHaveReadDisclosure(e.currentTarget.checked)}
							disabled={props.disabled}
						/>
						<Divider />
						<React.Fragment>{props.content}</React.Fragment>
						<Button
							mt={true}
							disabled={!haveReadDisclosure || props.disabled}
							label="Done"
							onClick={props.onClose}
						/>
					</div>
					<div className={classes.modalFooter}></div>
				</div>
			</Fade>
		</Modal>
	);
}
