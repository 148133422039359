// source: proto/admpb/entrytype.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_utilspb_pagination_pb = require('../../proto/utilspb/pagination_pb.js');
goog.object.extend(proto, proto_utilspb_pagination_pb);
goog.exportSymbol('proto.admpb.EntryType', null, global);
goog.exportSymbol('proto.admpb.ListEntryTypeRequest', null, global);
goog.exportSymbol('proto.admpb.ListEntryTypeResponse', null, global);
goog.exportSymbol('proto.admpb.ListEntryTypeSummary', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.EntryType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.EntryType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.EntryType.displayName = 'proto.admpb.EntryType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.ListEntryTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.ListEntryTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.ListEntryTypeRequest.displayName = 'proto.admpb.ListEntryTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.ListEntryTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admpb.ListEntryTypeResponse.repeatedFields_, null);
};
goog.inherits(proto.admpb.ListEntryTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.ListEntryTypeResponse.displayName = 'proto.admpb.ListEntryTypeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.ListEntryTypeSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.ListEntryTypeSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.ListEntryTypeSummary.displayName = 'proto.admpb.ListEntryTypeSummary';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.EntryType.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.EntryType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.EntryType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.EntryType.toObject = function(includeInstance, msg) {
  var f, obj = {
    entryTypeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    entryType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entryTypeDescription: jspb.Message.getFieldWithDefault(msg, 3, ""),
    screenType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 5, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    subAccountNo: jspb.Message.getFieldWithDefault(msg, 7, ""),
    note: jspb.Message.getFieldWithDefault(msg, 8, ""),
    status: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.EntryType}
 */
proto.admpb.EntryType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.EntryType;
  return proto.admpb.EntryType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.EntryType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.EntryType}
 */
proto.admpb.EntryType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntryTypeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryTypeDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setScreenType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubAccountNo(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.EntryType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.EntryType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.EntryType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.EntryType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntryTypeId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEntryType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntryTypeDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getScreenType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSubAccountNo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional uint32 entry_type_id = 1;
 * @return {number}
 */
proto.admpb.EntryType.prototype.getEntryTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.EntryType} returns this
 */
proto.admpb.EntryType.prototype.setEntryTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string entry_type = 2;
 * @return {string}
 */
proto.admpb.EntryType.prototype.getEntryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.EntryType} returns this
 */
proto.admpb.EntryType.prototype.setEntryType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string entry_type_description = 3;
 * @return {string}
 */
proto.admpb.EntryType.prototype.getEntryTypeDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.EntryType} returns this
 */
proto.admpb.EntryType.prototype.setEntryTypeDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string screen_type = 4;
 * @return {string}
 */
proto.admpb.EntryType.prototype.getScreenType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.EntryType} returns this
 */
proto.admpb.EntryType.prototype.setScreenType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string correspondent = 5;
 * @return {string}
 */
proto.admpb.EntryType.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.EntryType} returns this
 */
proto.admpb.EntryType.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string account_no = 6;
 * @return {string}
 */
proto.admpb.EntryType.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.EntryType} returns this
 */
proto.admpb.EntryType.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string sub_account_no = 7;
 * @return {string}
 */
proto.admpb.EntryType.prototype.getSubAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.EntryType} returns this
 */
proto.admpb.EntryType.prototype.setSubAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string note = 8;
 * @return {string}
 */
proto.admpb.EntryType.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.EntryType} returns this
 */
proto.admpb.EntryType.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string status = 9;
 * @return {string}
 */
proto.admpb.EntryType.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.EntryType} returns this
 */
proto.admpb.EntryType.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.ListEntryTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.ListEntryTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.ListEntryTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ListEntryTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entryType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    screenType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pagination: (f = msg.getPagination()) && proto_utilspb_pagination_pb.Pagination.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.ListEntryTypeRequest}
 */
proto.admpb.ListEntryTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.ListEntryTypeRequest;
  return proto.admpb.ListEntryTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.ListEntryTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.ListEntryTypeRequest}
 */
proto.admpb.ListEntryTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScreenType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 5:
      var value = new proto_utilspb_pagination_pb.Pagination;
      reader.readMessage(value,proto_utilspb_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.ListEntryTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.ListEntryTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.ListEntryTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ListEntryTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntryType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getScreenType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto_utilspb_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string entry_type = 1;
 * @return {string}
 */
proto.admpb.ListEntryTypeRequest.prototype.getEntryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.ListEntryTypeRequest} returns this
 */
proto.admpb.ListEntryTypeRequest.prototype.setEntryType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string screen_type = 2;
 * @return {string}
 */
proto.admpb.ListEntryTypeRequest.prototype.getScreenType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.ListEntryTypeRequest} returns this
 */
proto.admpb.ListEntryTypeRequest.prototype.setScreenType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string correspondent = 3;
 * @return {string}
 */
proto.admpb.ListEntryTypeRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.ListEntryTypeRequest} returns this
 */
proto.admpb.ListEntryTypeRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_no = 4;
 * @return {string}
 */
proto.admpb.ListEntryTypeRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.ListEntryTypeRequest} returns this
 */
proto.admpb.ListEntryTypeRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional utilspb.Pagination pagination = 5;
 * @return {?proto.utilspb.Pagination}
 */
proto.admpb.ListEntryTypeRequest.prototype.getPagination = function() {
  return /** @type{?proto.utilspb.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_utilspb_pagination_pb.Pagination, 5));
};


/**
 * @param {?proto.utilspb.Pagination|undefined} value
 * @return {!proto.admpb.ListEntryTypeRequest} returns this
*/
proto.admpb.ListEntryTypeRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admpb.ListEntryTypeRequest} returns this
 */
proto.admpb.ListEntryTypeRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admpb.ListEntryTypeRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string status = 6;
 * @return {string}
 */
proto.admpb.ListEntryTypeRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.ListEntryTypeRequest} returns this
 */
proto.admpb.ListEntryTypeRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admpb.ListEntryTypeResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.ListEntryTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.ListEntryTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.ListEntryTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ListEntryTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    entryTypesList: jspb.Message.toObjectList(msg.getEntryTypesList(),
    proto.admpb.EntryType.toObject, includeInstance),
    summary: (f = msg.getSummary()) && proto.admpb.ListEntryTypeSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.ListEntryTypeResponse}
 */
proto.admpb.ListEntryTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.ListEntryTypeResponse;
  return proto.admpb.ListEntryTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.ListEntryTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.ListEntryTypeResponse}
 */
proto.admpb.ListEntryTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admpb.EntryType;
      reader.readMessage(value,proto.admpb.EntryType.deserializeBinaryFromReader);
      msg.addEntryTypes(value);
      break;
    case 2:
      var value = new proto.admpb.ListEntryTypeSummary;
      reader.readMessage(value,proto.admpb.ListEntryTypeSummary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.ListEntryTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.ListEntryTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.ListEntryTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ListEntryTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntryTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.admpb.EntryType.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.admpb.ListEntryTypeSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EntryType entry_types = 1;
 * @return {!Array<!proto.admpb.EntryType>}
 */
proto.admpb.ListEntryTypeResponse.prototype.getEntryTypesList = function() {
  return /** @type{!Array<!proto.admpb.EntryType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.admpb.EntryType, 1));
};


/**
 * @param {!Array<!proto.admpb.EntryType>} value
 * @return {!proto.admpb.ListEntryTypeResponse} returns this
*/
proto.admpb.ListEntryTypeResponse.prototype.setEntryTypesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.admpb.EntryType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.admpb.EntryType}
 */
proto.admpb.ListEntryTypeResponse.prototype.addEntryTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.admpb.EntryType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admpb.ListEntryTypeResponse} returns this
 */
proto.admpb.ListEntryTypeResponse.prototype.clearEntryTypesList = function() {
  return this.setEntryTypesList([]);
};


/**
 * optional ListEntryTypeSummary summary = 2;
 * @return {?proto.admpb.ListEntryTypeSummary}
 */
proto.admpb.ListEntryTypeResponse.prototype.getSummary = function() {
  return /** @type{?proto.admpb.ListEntryTypeSummary} */ (
    jspb.Message.getWrapperField(this, proto.admpb.ListEntryTypeSummary, 2));
};


/**
 * @param {?proto.admpb.ListEntryTypeSummary|undefined} value
 * @return {!proto.admpb.ListEntryTypeResponse} returns this
*/
proto.admpb.ListEntryTypeResponse.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admpb.ListEntryTypeResponse} returns this
 */
proto.admpb.ListEntryTypeResponse.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admpb.ListEntryTypeResponse.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.ListEntryTypeSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.ListEntryTypeSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.ListEntryTypeSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ListEntryTypeSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalRows: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.ListEntryTypeSummary}
 */
proto.admpb.ListEntryTypeSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.ListEntryTypeSummary;
  return proto.admpb.ListEntryTypeSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.ListEntryTypeSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.ListEntryTypeSummary}
 */
proto.admpb.ListEntryTypeSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.ListEntryTypeSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.ListEntryTypeSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.ListEntryTypeSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ListEntryTypeSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalRows();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 total_rows = 2;
 * @return {number}
 */
proto.admpb.ListEntryTypeSummary.prototype.getTotalRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.ListEntryTypeSummary} returns this
 */
proto.admpb.ListEntryTypeSummary.prototype.setTotalRows = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto.admpb);
