import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  MenuItem,
  Select,
  InputLabel,
  makeStyles,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { Visibility as ViewIcon, AddCircle as AddCircleIcon } from '@material-ui/icons';
import fontFamily from '../../../../assets/css/fonts';
import color from '../../../../assets/css/colors';
import TextField from '../../../../components/Textfields/TextField';
import CloseButton from '../../../../components/Buttons/CloseButton';
import { listBankAccount } from '../../../../services/CommonService';
import SelectSystemCode from '../../../../components/Dropdown/SelectSystemCode';
import AutoCompleteAccountNo from '../../../../components/AutoComplete/AutoCompleteAccountNo';
import AutoCompleteCorrespondent from '../../../../components/AutoComplete/AutoCompleteCorrespondent';
import {
  readMaximumWithdrawable,
  update,
  create,
  getNewRequestId,
} from '../../../../services/AchWireRequestService';
import { readAccount } from '../../../../services/BankAccountService';
import { notifySuccess, notifyError } from '../../../../components/Messages/Notification';
import { formatCurrencyInput } from '../../../../lib/fmt/input';
import { formatCurrency } from '../../../../lib/fmt/index';
import ACHWireBankDetailsModal from './ACHWireBankDetailsModal';
import MaximumWithdrawableModal from './MaximumWithdrawableModal';
import SelectStatus from '../../../../components/Dropdown/SelectStatus'

function SelectBankAccount({
  correspondent,
  accountNo,
  triggerValue,
  label,
  required,
  ...rest
}) {
  const [options, setOptions] = React.useState([]);
  const getBankAccounts = async () => {
    if (!correspondent || !accountNo) return [];

    const { bankAccountsList } = await listBankAccount(correspondent, accountNo);
    setOptions(bankAccountsList);
  };

  React.useEffect(
    () => {
      getBankAccounts();
    },
    // eslint-disable-next-line
    [correspondent, accountNo, triggerValue]
  );

  return (
    <>
      <InputLabel required={required} shrink>
        {label}
      </InputLabel>
      <Select {...rest} required={required} displayEmpty fullWidth>
        {options.length ? (
          options.map((item) => (
            <MenuItem key={item.bankId} value={item.bankId}>
              {item.bankName + ': Bank Account # ' + item.bankAccountNo}
            </MenuItem>
          ))
        ) : (
          <MenuItem key="invalid" value="">
            No Bank Available
          </MenuItem>
        )}
      </Select>
    </>
  );
}

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: '30px 0px 0px 30px',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& > div:nth-child(1)': {
      flex: 'none',
      marginRight: 7,
      '& > svg': {
        width: 28,
        height: 28,
        '& path': {},
      },
    },
    '& div:nth-child(2)': {
      flex: 1,
    },
    '& div:nth-child(3)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {},
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 30px',
    maxHeight: 600,
    overflowY: 'auto',
    fontFamily: fontFamily.headers,
    color: color.default.main,
    '& p': {
      fontSize: 16,
      fontFamily: fontFamily.headers,
      fontWeight: 400,
      lineHeight: '26px',
      color: color.default.main,
      marginTop: 0,
    },
    '& hr': {
      margin: '30px 0px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 120,
    },
  },
  incompleteSteps: {
    fontSize: 16,
    lineHeight: '24px',
    marginTop: 20,
    '& > label': {
      fontWeight: 600,
      marginBottom: 10,
      display: 'block',
    },
    '& ul': {
      listStyle: 'none',
      margin: '0px 0px 0px -40px',
      lineHeight: '30px',
      '& > li > span': {
        position: 'relative',
        top: 4,
        '& > svg': {
          width: 20,
          height: 20,
        },
      },
    },
  },
  success: {
    '& > svg > path': {},
  },
  error: {
    '& > svg > path': {},
  },
  closeButton: {
    '& button': {
      marginTop: '-10px !important',
      marginRight: '5px !important',
    },
  },
}));

const initialMaximumWithdrawable = {
  totalAmount: 0,
  withdrawableAmount: 0,
  charges: 0,
  pendingCallLog: 0,
};

export default function AchWireRequestModal({
  onClose: handleClose,
  open: isOpen,
  value,
}) {

  async function getNewID() {
    let modalValue = { ...value };
    const { requestId } = await getNewRequestId();
    modalValue.requestId = requestId;
    setModalData(modalValue);
  }

  const classes = useStyles();
  const [isEdit, setIsEdit] = React.useState(false);
  const [rowData, setRowData] = React.useState({});
  const [openBalanceDetails, setOpenBalanceDetails] = React.useState(false);
  const [openBankDetails, setOpenBankDetails] = React.useState(false);
  const [btnText, setBtnText] = React.useState("Save")
  const [maximumWithdrawable, setMaximumWithdrawable] = React.useState(
    initialMaximumWithdrawable
  );
  const [modalData, setModalData] = useState(
    value || {
      correspondent: '',
      accountNo: '',
      bankId: 0,
      externalId: '',
      amt: 0,
      transferType: '',
      requestType: '',
      status: 'Pending',
      fee: 0,
      fedNo: '',
      bankNote: '',
      bank: '',
      internalNote: '',
    }
  );

  React.useEffect(() => {
    if (isOpen) {
      if (value.requestId) {
        setIsEdit(true);
      }

      if (!value.requestId) {
        getNewID();
      }

      // delay to allow it to be rendered
      setTimeout(function () {
        const maxWithdrawHelper = document.getElementsByClassName(
          'MuiFormHelperText-root'
        )[0];

        if (maxWithdrawHelper === undefined) {
          return;
        }
        // set cursor to pointer
        maxWithdrawHelper.style.cursor = 'pointer';
        // make it clickable
        maxWithdrawHelper.addEventListener('click', function () {
          setOpenBalanceDetails(true);
        });
        toggleMaxWithdrawHelperVisibility(value.transferType);
      }, 100);
    }
  }, [isOpen, value]);

  useEffect(() => {
    const copy = { ...modalData };
    if (!modalData.bankId) {
      copy.isInternational = false;
      copy.bank = '';
      setModalData(copy);
      setRowData({});
    } else {
      // setIsEdit(true);
      getBankAccount(modalData.bankId).then((result) => {
        copy.isInternational = result.isInternational;
        copy.bank = result.bankName;
        setModalData(copy);
        setRowData(result);
      });
    }
    // eslint-disable-next-line
  }, [modalData.bankId]);

  React.useEffect(() => {
    getMaximumWithdrawable(
      modalData.correspondent,
      modalData.accountNo,
      modalData.transferType
    );
  }, [modalData.correspondent, modalData.accountNo, modalData.transferType]);

  const toggleMaxWithdrawHelperVisibility = (transferType) => {
    const maxWithdrawHelper = document.getElementsByClassName(
      'MuiFormHelperText-root'
    )[0];

    if (maxWithdrawHelper === undefined) {
      return;
    }
    if (transferType === 'Withdrawal') {
      maxWithdrawHelper.style.display = 'block';
    } else {
      maxWithdrawHelper.style.display = 'none';
    }
  };

  const getBankAccount = async (bankId) => {
    const { bankAccount } = await readAccount(bankId);
    if (bankAccount.bankIdentifierCode !== '') {
      bankAccount.isInternational = true;
      return bankAccount;
    } else {
      bankAccount.isInternational = false;
      return bankAccount;
    }
  };

  const getMaximumWithdrawable = async (correspondent, accountNo, transferType) => {
    if (transferType !== 'Withdrawal' || !correspondent || !accountNo) {
      return setMaximumWithdrawable(initialMaximumWithdrawable);
    }

    const resp = await readMaximumWithdrawable(correspondent, accountNo);

    setMaximumWithdrawable({
      totalAmount: resp.totalAmount,
      withdrawableAmount: resp.withdrawableAmt,
      charges: resp.charges,
      pendingCallLog: resp.pendingCallLog,
    });
  };

  const handleOpenAddBank = () => {
    const copyModalData = {
      ...modalData,
      isAddBank: true,
    };

    handleClose(copyModalData, isEdit);
  };

  const handleCloseModal = async () => {
    setOpenBankDetails(false);
    setOpenBalanceDetails(false);
  };

  const handleChange = (e, checkboxValue) => {
    const input = e.target?.name ? e.target : e.currentTarget;
    const copyModalData = {
      ...modalData,
      [input.name]:
        checkboxValue === true || checkboxValue === false ? checkboxValue : input.value,
    };

    // Remove Bank
    if (['correspondent', 'accountNo'].includes(input.name)) {
      copyModalData.bankId = '';
    }

    if (['transferType'].includes(input.name)) {
      toggleMaxWithdrawHelperVisibility(copyModalData.transferType);
    }

    if (input.value === 'Wire') {
      copyModalData.transferType = 'Withdrawal';
    }

    setModalData(copyModalData);
  };

  const handleSave = async () => {
    if (!modalData.correspondent) {
      return notifyError('Correspondent is required.');
    }
    if (!modalData.accountNo) {
      return notifyError('Account No is required.');
    }
    // if (!modalData.bank) {
    //   return notifyError('Bank is required.');
    // }

    // if (!modalData.bankId) {
    //   return notifyError('Bank ID is required.');
    // }

    if (!modalData.amt) {
      return notifyError('Amount is required.');
    } else if (Number(modalData.amt) === 0) {
      return notifyError('Amount is invalid.');
    }

    if (!modalData.transferType) {
      return notifyError('Transfer Type Account is required.');
    } else {
      if (modalData.transferType === 'Withdrawal') {
        if (maximumWithdrawable.pendingCallLog > 0) {
          return notifyError('Cannot withdraw with pending calls.');
        } else if (Number(modalData.amt) > maximumWithdrawable.withdrawableAmount) {
          return notifyError('Amount is greater than Maximum Withdrawable.');
        }
      }
    }
    if (!modalData.requestType) {
      return notifyError('Request Type Account is required.');
    }

    try {
      if (isEdit) {
        //EDIT
        setBtnText("Saving...");
        const resp = await update(modalData);
        setBtnText("Save");
        notifySuccess('ACHWire has been updated.');
        handleClose(resp.request, true);
      } else {
        //ADD
        setBtnText("Saving...");
        const resp = await create(modalData);
        setBtnText("Save");
        notifySuccess('New ACHWire has been added.');
        handleClose(resp.request, false);
      }
    } catch (error) {
      notifyError(error.message);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div style={{ width: 2000 }}>
          <Box mt={5}></Box>
          <form noValidate autoComplete="off">
            <div className={classes.modalHeader}>
              <Typography
                id="transition-modal-title"
                className={classes.textBold}
                style={{ fontWeight: 'bold', fontSize: '30px' }}
                gutterBottom
              >
                {isEdit ? 'Edit' : 'Add New'} ACH\Wire
              </Typography>

              <div
                className="grd-cell"
                style={{
                  float: 'right',
                  marginRight: '20px',
                  textAlign: 'right',
                  fontWeight: 'Bold',
                }}
              ></div>
              <div className={classes.closeButton}>
                <CloseButton onClick={() => handleClose()} />
              </div>
            </div>

            <div className={classes.modalBody}>
              <div className="grd-row">
                <div className="grd-cell">
                  <AutoCompleteCorrespondent
                    required={true}
                    disabled={isEdit}
                    isAccessibleOnly={true}
                    name="correspondent"
                    value={modalData.correspondent || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className="grd-cell">
                  <AutoCompleteAccountNo
                    required={true}
                    isAccessibleOnly={true}
                    name="accountNo"
                    value={modalData.accountNo}
                    correspondent={modalData.correspondent}
                    onChange={handleChange}
                    disabled={isEdit}
                  />
                </div>
              </div>
              <div className="grd-row">
                <div className="grd-cell">
                  <SelectBankAccount
                    required={true}
                    disabled={isEdit}
                    name="bankId"
                    label="Bank"
                    correspondent={modalData.correspondent}
                    accountNo={modalData.accountNo}
                    triggerValue={modalData.triggerValue}
                    value={modalData.bankId || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className="grd-cell-none">
                  {modalData.bankId > 0 && (
                    <IconButton
                      aria-label="view"
                      onClick={() => {
                        setOpenBankDetails(true);
                      }}
                    >
                      <ViewIcon color="primary" />
                    </IconButton>
                  )}
                  {!isEdit && (
                    <IconButton
                      aria-label="add"
                      onClick={() => {
                        handleOpenAddBank();
                      }}
                    >
                      <AddCircleIcon color="primary" />
                    </IconButton>
                  )}
                </div>
                <div className="grd-cell">
                  <TextField
                    fullWidth
                    required={true}
                    disabled={isEdit}
                    name="amt"
                    label="Amount"
                    value={modalData.amt}
                    onChange={handleChange}
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 25);
                    }}
                    InputProps={{
                      inputProps: {
                        min: 1,
                        max: maximumWithdrawable.withdrawableAmount,
                      },
                      inputComponent: formatCurrencyInput,
                      startAdornment: (
                        <InputAdornment position="start">{'$'}</InputAdornment>
                      ),
                    }}
                    InputLabelProps={{ shrink: true }}
                    helperText={
                      'Maximum Withdrawable: ' +
                      formatCurrency(maximumWithdrawable.withdrawableAmount)
                    }
                  />
                </div>
              </div>
              <div className="grd-row">
                <div className="grd-cell">
                  <SelectSystemCode
                    required={true}
                    disabled={isEdit}
                    name="requestType"
                    label="Request Type"
                    placeholder="Request Type"
                    type="Type"
                    subType="Request Type"
                    value={modalData.requestType || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className="grd-cell">
                  <SelectSystemCode
                    required={true}
                    disabled={isEdit || modalData.requestType === 'Wire'}
                    name="transferType"
                    label="Transfer Type"
                    placeholder="Transfer Type"
                    type="Type"
                    subType="Transfer Type"
                    value={modalData.transferType}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="grd-row">
                <div className="grd-cell">
                  <SelectStatus
                    value={modalData.status}
                    onChange={handleChange}
                    disabled={
                      !isEdit
                    }
                  ></SelectStatus>
                </div>
                <div className="grd-cell">
                  {isEdit && (
                    <InputLabel shrink disabled
                      style={{ marginTop: '30px' }}>
                      Fee: {formatCurrency(modalData.fee)}
                    </InputLabel>
                  )}
                </div>
              </div>
            </div>

            <div className={classes.modalFooter}>
              <div className="grd-row">
                <div className="grd-cell" style={{ marginRight: 10 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Close
                  </Button>
                </div>
                <div className="grd-cell">
                  <Button
                    disabled={btnText !== 'Save'}
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleSave}
                  >
                    {btnText}
                  </Button>
                </div>
                <div className="grd-cell"></div>
              </div>
            </div>
          </form>

          {openBankDetails && (
            <ACHWireBankDetailsModal
              onClose={handleCloseModal}
              open={openBankDetails}
              value={rowData}
            ></ACHWireBankDetailsModal>
          )}
          {openBalanceDetails && (
            <MaximumWithdrawableModal
              onClose={handleCloseModal}
              open={openBalanceDetails}
              value={maximumWithdrawable}
            ></MaximumWithdrawableModal>
          )}
        </div>
      </Fade>
    </Modal>
  );
}
