import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// import useStyles from '../../../styles';
// import IdentificationModal from './IdentificationModal';
// import CustomerTypeModal from './CustomerTypeModal';
// import LinkOwnerModal from './LinkOwnerModal';
import AddressModal from './AddressModal';
import ContactInfoModal from './ContactInfoModal';
// import ClientAuditModal from './ClientAuditModal';
import { useConfirm } from 'material-ui-confirm';
import {
  listAddress,
  updateAddress,
  createAddress,
  deleteOwnerAddress,
} from '../../services/OwnerAddressService';
import {
  listContactInfo,
  updateContactInfo,
  createContactInfo,
  deleteOwnerContactInfo,
} from '../../services/OwnerContactInfoService';
import {
  listIdentification,
  //   updateIdentification,
  //   createIdentification,
  //   deleteIdentification,
} from '../../services/IdentificationService';
import {
  listCustomerType,
  //   createCustomerType,
  //   deleteCustomerType,
} from '../../services/CustomerTypeService';
import { createOwner } from '../../services/AccountOwnerService';
import { notifySuccess, notifyError } from '../../components/Messages/Notification';
import Text from '../Typography/Text';
import { Create as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import {
  Typography,
  FormControl,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  AppBar,
  Tabs,
  Tab,
  IconButton,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import SelectTaxCountry from '../../components/Dropdown/SelectTaxCountry';
import SelectSystemCode from '../../components/Dropdown/SelectSystemCode';
import SelectOwner from '../../components/Dropdown/SelectOwner';
import {
  dateProtoObjectToString,
  protoTimeSpanObjectToString,
} from '../../services/ConvertService';
import { getSystemDate } from '../../services/ProfileService';
// import Table, { columnType } from 'components/Table/Table';
import ClientGrid from '../../components/ClientGrid/ClientGrid';
import Button from '../../components/Buttons/Button';
import { SelectInvestmentObject } from '../../components/AutoComplete/SelectSystemCode';

import fontFamily from '../../assets/css/fonts';
import color from '../../assets/css/colors';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 550,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  fadeModalFull: {
    maxWidth: 1400,
    minWidth: 1400,
    maxHeight: '90vh',
    width: '100%',
    padding: '25px 30px 30px 30px',
    margin: '0px auto',
    backgroundColor: '#ffffff',
    border: 'none',
    borderRadius: 5,
    boxSizing: 'border-box',
    outline: 'none',
    overflowY: 'auto',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
  accordionDetails: {
    display: 'block !important',
    padding: '0px 20px 25px 20px !important',
  },
  accordion: {
    borderRadius: '5px !important',
    boxShadow: '0px 1px 5px -2px #cdcfdd !important',
    border: '1px solid #efeff0 !important',
  },
  tabBody: {
    minWidth: '100%',
    padding: '0px 6px',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function OwnerModal({
  onClose: handleClose,
  open: isOpen,
  importMode: isImport,
  editMode: editMode,
  value,
  validation,
  loading: saving,
}) {
  const classes = useStyles();
  const confirm = useConfirm();
  const [modalData, setModalData] = useState({
    ownerId: 0,
    accountId: value.accountId,
    ownerType: 'Beneficiary',
    firstName: '',
    middleName: '',
    lastName: '',
    citizenship: 'US',
    dateOfBirth: '',
    status: 'Active',
    employmentStatus: '',
    employmentOccupation: '',
    employmentName: '',
    ownership: false,
    stockOwnership: '',
    brokerMember: false,
    brokerName: '',
    affiliatePerson: '',
    affiliatePersonPosition: '',
    ofacStatus: 'Pending',
    ofacDate: '',
    kycStatus: 'Pending',
    kycDate: '',
    officer: false,
    owner: false,
    ownershipPercent: 0,
    investmentObjectiveCode: '',
    investmentObjectiveDate: '',
    traderId: '',
    inactiveStatusReason: '',
  });
  const [isEdit, setIsEdit] = React.useState(false);
  const [rowData, setRowData] = React.useState({});
  const [identificationRows, setIdentificationRows] = React.useState([]);
  const [customerTypeRows, setCustomerTypeRows] = React.useState([]);
  const [addressRows, setAddressRows] = React.useState([]);
  const [addressValidation, setAddressValidation] = React.useState({});
  const [contactInfoRows, setContactInfoRows] = React.useState([]);
  const [contactInfoValidation, setContactInfoValidation] = React.useState({});
  const [openIdentification, setOpenIdentification] = React.useState(false);
  const [openCustomerType, setOpenCustomerType] = React.useState(false);
  const [openAddress, setOpenAddress] = React.useState(false);
  const [openContactInfo, setOpenContactInfo] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [openAudit, setOpenAudit] = React.useState(false);
  const [linkSelectedOwner, setLinkSelectedOwner] = React.useState('');
  const [linkedOwnerId, setlLnkedOwnerId] = React.useState(0);
  const [systemDateValue, setSystemDateValue] = React.useState('');
  const [isAccessible, setIsAccessible] = React.useState(false);
  const [loading, setLoading] = React.useState({
    save: false,
    delete: false,
  });
  const [currentSelectedRowsIndex, setCurrentSelectedRowsIndex] = React.useState([]);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const loadValues = async (owner) => {
    let ownerCopy = { ...owner };
    ownerCopy.dateOfBirth = dateProtoObjectToString(ownerCopy.dateOfBirth);
    ownerCopy.ofacDate = dateProtoObjectToString(ownerCopy.ofacDate);
    ownerCopy.kycDate = dateProtoObjectToString(ownerCopy.kycDate);
    ownerCopy.investmentObjectiveDate = dateProtoObjectToString(
      ownerCopy.investmentObjectiveDate
    );
    ownerCopy.webTermConditions = protoTimeSpanObjectToString(
      ownerCopy.webTermConditions
    );
    ownerCopy.disclosureStatement = protoTimeSpanObjectToString(
      ownerCopy.disclosureStatement
    );
    ownerCopy.feeSchedule = protoTimeSpanObjectToString(ownerCopy.feeSchedule);

    setModalData({ ...ownerCopy });

    if (editMode) {
      setIsAccessible(owner.piiAccess);
    } else {
      setIsAccessible(true);
    }

    let param = {
      accountId: ownerCopy.accountId,
      ownerId: ownerCopy.ownerId,
    };

    try {
      const resIdentification = await listIdentification(param);
      setIdentificationRows(resIdentification.identificationsList);
    } catch (error) {
      console.error(error);
    }

    try {
      const resCustomerType = await listCustomerType(param.accountId);
      setCustomerTypeRows(resCustomerType.customerTypesList);
    } catch (error) {
      console.error(error);
    }

    try {
      const resAddress = await listAddress(param);
      setAddressRows(resAddress.addressList);
    } catch (error) {
      console.error(error);
    }

    try {
      const resContactInfo = await listContactInfo(param);
      setContactInfoRows(resContactInfo.contactInfoList);
    } catch (error) {
      console.error(error);
    }
  };

  async function init() {
    if (isImport || value.ownerId) {
      setIsEdit(true);
    }
    if (!value.ownerId) {
      try {
        const { owner } = await createOwner(value.accountId, {
          ...value,
          ownerType: value.ownerType,
        });
        value.ownerId = owner.ownerId;
      } catch (error) {
        console.error(error);
      }
    }
    try {
      await loadValues(value);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (isOpen) {
      init();
    }
  }, [isOpen]);

  useEffect(() => {
    (async () => {
      const systemDate = await getSystemDate();
      setSystemDateValue(systemDate);
    })();
  }, []);

  useEffect(() => {
    if (modalData.ownerType === 'Beneficiary') {
      modalData.owner = true;
    }
  }, [modalData]);

  const isDeleteEnabled = (dataIndex) => {
    return identificationRows[dataIndex].status !== 'Inactive';
  };

  const options = {
    tableBodyHeight: '160px',
    isRowSelectable: (dataIndex) => isDeleteEnabled(dataIndex),
    onRowsDelete: (selectedItems) => {
      handleDelete(
        selectedItems.data,
        selectedItems.data.map((d) => d.dataIndex)
      );
      return false;
    },
  };

  const ColumnsButtons = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    return (
      <div>
        <IconButton
          aria-label="delete"
          disabled={!isDeleteEnabled(rowIndex - 1) || loading.delete}
          onClick={() => {
            handleDelete([{ data: data }], [data]);
          }}
        >
          {loading.delete && currentSelectedRowsIndex.includes(data) ? (
            <CircularProgress style={{ color: '#1e7dff', height: 20, width: 20 }} />
          ) : (
            <DeleteIcon style={{ height: 20, width: 20 }} />
          )}
        </IconButton>
        <IconButton
          aria-label="edit"
          disabled={loading.delete}
          onClick={() => handleOpenIdentification(identificationRows[data])}
        >
          <EditIcon style={{ height: 20, width: 20 }} />
        </IconButton>
      </div>
    );
  };

  const columns = [
    // {
    //   id: '',
    //   field: '',
    //   width: '60px',
    //   cellRenderer: ColumnsButtons,
    // },
    {
      id: 'type',
      field: 'type',
      label: 'Type',
    },
    {
      id: 'id',
      field: 'id',
      label: 'ID',
    },
    {
      id: 'countryOfIssuance',
      field: 'countryOfIssuance',
      label: 'Country Of Issuance',
    },
    {
      id: 'issueDate',
      field: 'issueDate',
      label: 'Issue Date',
    },
    {
      id: 'expirationDate',
      field: 'expirationDate',
      label: 'Expiration Date',
    },
    {
      id: 'status',
      field: 'status',
      label: 'Status',
    },
    {
      id: 'state',
      field: 'state',
      label: 'State',
    },
  ];

  const CustomerTypeColumnsButtons = ({
    tableManager,
    value,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return (
      <div>
        <div className="grd-cell-none">
          <IconButton
            aria-label="delete"
            //disabled={!isDeleteEnabled(dataIndex)} #JS: if delete function relies on status value, show this else remove
            onClick={() => {
              handleDeleteCustomerType([
                {
                  data: data,
                },
              ]);
            }}
          >
            <DeleteIcon
              style={{ color: '#1e7dff', height: 20, width: 20, marginLeft: 20 }}
            />
          </IconButton>
        </div>
      </div>
    );
  };

  const customerTypeColumns = [
    // {
    //   id: '',
    //   field: '',
    //   width: '60px',
    //   cellRenderer: CustomerTypeColumnsButtons,
    // },
    {
      id: 'customerType',
      field: 'customerType',
      label: 'Customer Type',
    },
    {
      id: 'description',
      field: 'description',
      label: 'Description',
    },
  ];

  const handleChange = (e, checkboxValue) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    if (
      ['brokerName', 'affiliatePerson', 'affiliatePersonPosition'].includes(input.name)
    ) {
      modalData.brokerMember = true;
    }
    if (
      ['stockOwnership', 'stockCompany', 'stockPosition', 'stockPerson'].includes(
        input.name
      )
    ) {
      modalData.ownership = true;
    }
    if (modalData.ownerType === 'Beneficiary') {
      modalData.owner = true;
    }

    setModalData({
      ...modalData,
      [input.name]:
        checkboxValue === true || checkboxValue === false ? checkboxValue : input.value,
    });
  };

  const handleCloseLinkOwnerModal = async (ownerType, selected) => {
    if (!ownerType) {
      return setLinkSelectedOwner('');
    }
    try {
      //   const { owner } = await createOwner(value.accountId, {
      //     ...selected,
      //     ownerType: ownerType,
      //   });

      //   await loadValues(owner);
      //   setlLnkedOwnerId(owner.ownerId);

      //   notifySuccess(
      //     owner.firstName + ' ' + owner.lastName + ' was linked to this account.'
      //   );
      setLinkSelectedOwner('');
    } catch (error) {
      notifyError(error.message);
    }
  };

  const handleAutoFill = (selected) => {
    setLinkSelectedOwner(selected);
  };

  const handleOpenIdentification = (data) => {
    if (!data) {
      // ADD default values
      data = {
        ownerId: modalData.ownerId,
        type: '',
        id: '',
        countryOfIssuance: 'US',
        imgGovernmentId: '',
        status: 'Active',
      };
    }

    setRowData(data);
    setOpenIdentification(true);
  };

  const handleCloseIdentification = async (data, isEdit) => {
    if (!data) {
      setOpenIdentification(false);
      return;
    }

    let valid = true;

    if (!data.type) {
      notifyError('Type is required.');
      valid = false;
    }
    if (!data.id || data.id === '___-__-____') {
      notifyError('ID is required.');
      valid = false;
    }
    if (!data.countryOfIssuance) {
      notifyError('Country Of Issuance is required.');
      valid = false;
    }
    if (
      ((data.countryOfIssuance === 'US' && data.type !== 'SSN') ||
        (data.countryOfIssuance === 'CA' && data.type !== 'SSN')) &&
      (data.state === '' || !data.state)
    ) {
      notifyError('State  is required.');
      valid = false;
    }

    if (data.type === 'Driver License' || data.type === 'Passport') {
      if (!data.issueDate) {
        notifyError('Issue Date is required.');
        valid = false;
      }

      if (!data.expirationDate) {
        notifyError('ExpirationDate is required.');
        valid = false;
      }

      if (data.issueDate > data.expirationDate) {
        notifyError('Issue Date should not be greater than Expiration Date.');
        valid = false;
      }

      if (data.expirationDate <= systemDateValue) {
        notifyError('Expiration Date should be greater than today.');
        valid = false;
      }
    } else {
      //clean up

      data.expirationDate = undefined;
      data.issueDate = undefined;
    }

    if (!valid) {
      return;
    }

    setLoading({ ...loading, save: true });

    try {
      if (isEdit) {
        //EDIT
        // await updateIdentification(value.accountId, data, value.ownerId).then(
        //   (res) => {
        //     const index = identificationRows.indexOf(rowData);
        //     const rowsCopy = [...identificationRows];
        //     rowsCopy[index] = res.identification;
        //     setIdentificationRows(rowsCopy);
        //     notifySuccess('Identification has been updated.');
        //   }
        // );
      } else {
        //ADD
        // await createIdentification(value.accountId, data, value.ownerId).then(
        //   (res) => {
        //     setIdentificationRows([res.identification, ...identificationRows]);
        //     notifySuccess('New identification has been added.');
        //   }
        // );
      }

      setOpenIdentification(false);
    } catch (error) {
      console.error(error);
      setLoading({ ...loading, save: false });
      notifyError(error.message);
    }

    setLoading({ ...loading, save: false });
  };

  const handleDelete = (rowsToDelete, index) => {
    let messageKey = '';
    if (rowsToDelete.length === 1) {
      messageKey = identificationRows[rowsToDelete[0].dataIndex].type;
    } else {
      messageKey = rowsToDelete.length + ' items';
    }

    confirm({
      description:
        "You are about to disable '" + messageKey + "'. Please confirm your action.",
      confirmationText: 'Yes, disable',
    }).then(async () => {
      const rowsCopy = [...identificationRows];
      try {
        setCurrentSelectedRowsIndex(index);
        setLoading({ ...loading, delete: true });

        for (const r of rowsToDelete) {
          const index = r.dataIndex;
          //   const { identification } = await deleteIdentification(
          //     identificationRows[index].identificationId,
          //     modalData.ownerId
          //   );
          //   rowsCopy[index] = identification;
        }
      } catch (error) {
        notifyError(error.message);
      } finally {
        setCurrentSelectedRowsIndex([]);
        setLoading({ ...loading, delete: false });
        setIdentificationRows(rowsCopy);
        notifySuccess(messageKey + ' Identification has been disabled');
      }
    });
  };

  const isAddressDeleteEnabled = (dataIndex) => {
    return addressRows[dataIndex].status !== 'Inactive';
  };

  const customerTypeOptions = {
    selectableRows: 'multiple',
    //isRowSelectable: (dataIndex) => isDeleteEnabled(dataIndex), #JS: if delete function relies on status value, show this else remove
    onRowsDelete: (selectedItems) => {
      handleDeleteCustomerType(selectedItems.data);
      return false;
    },
  };

  const addressOptions = {
    isRowSelectable: (dataIndex) => isAddressDeleteEnabled(dataIndex),
    onRowsDelete: (selectedItems) => {
      handleDeleteAddress(
        selectedItems.data,
        selectedItems.data.map((d) => d.dataIndex)
      );
      return false;
    },
  };

  const AddressColumnsButtons = ({
    tableManager,
    value,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return (
      <div
        align={'left'}
        className="grd-row"
        style={{
          margin: 0,
          padding: 0,
        }}
      >
        {/* <IconButton
          aria-label="delete"
          disabled={data.status === 'Inactive'}
          onClick={() => {
            handleDeleteAddress(data, rowIndex - 1);
          }}
        >
          <DeleteIcon style={{ height: 20, width: 20 }} />
        </IconButton> */}
        <IconButton
          aria-label="edit"
          disabled={loading.delete}
          onClick={() => handleOpenAddress(data)}
        >
          <EditIcon style={{ height: 20, width: 20 }} />
        </IconButton>
      </div>
    );
  };

  const addressColumn = [
    {
      id: '',
      field: '',
      width: '30px',
      cellRenderer: AddressColumnsButtons,
    },
    {
      id: 'addressType',
      field: 'addressType',
      label: 'Address Type',
      order: 1,
    },
    {
      id: 'address1',
      field: 'address1',
      label: 'Address 1',
      order: 2,
    },
    {
      id: 'address2',
      field: 'address2',
      label: 'Address 2',
      order: 3,
    },
    {
      id: 'address3',
      field: 'address3',
      label: 'Address 3 ',
      order: 4,
    },
    {
      id: 'address4',
      field: 'address4',
      label: 'Address 4 ',
      order: 5,
    },
    {
      id: 'city',
      field: 'city',
      label: 'City',
      order: 6,
    },
    {
      id: 'state',
      field: 'state',
      label: 'State',
      order: 7,
    },
    {
      id: 'zip',
      field: 'zip',
      label: 'Zip',
      order: 8,
    },
    {
      id: 'country',
      field: 'country',
      label: 'Country',
      order: 9,
    },
    {
      id: 'status',
      field: 'status',
      label: 'Status',
      order: 10,
    },
  ];

  const handleDeleteAddress = (data, index) => {
    confirm({
      description:
        "You are about to delete '" + data.addressType + "'. Please confirm your action.",
      confirmationText: 'Yes, delete',
    }).then(async () => {
      const rowsCopy = [...addressRows];
      try {
        setCurrentSelectedRowsIndex(index);
        setLoading({ ...loading, delete: true });

        await deleteOwnerAddress(data.addressId, modalData.ownerId).then((res) => {
          rowsCopy[index] = res.address;
        });
      } catch (error) {
        notifyError(error.message);
      } finally {
        setCurrentSelectedRowsIndex([]);
        setLoading({ ...loading, delete: false });
        setAddressRows(rowsCopy);
        notifySuccess(data.addressType + ' Address has been deleted');
      }
    });
  };

  const handleDeleteCustomerType = (rowsToDelete) => {
    let messageKey = '';
    if (rowsToDelete.length === 1) {
      messageKey = 'record ';
    } else {
      messageKey = rowsToDelete.length + ' items';
    }
    confirm({
      description:
        "You are about to delete '" + messageKey + "'. Please confirm your action.",
      confirmationText: 'Yes, delete',
    }).then(async () => {
      const rowsCopy = [...customerTypeRows];
      try {
        for (const r of rowsToDelete) {
          const index = r.dataIndex;
          //   await deleteCustomerType(rowsCopy[index].customerTypeId);
          //   const resp = await listCustomerType(value.accountId);
          //   console.log(resp);
          //   setCustomerTypeRows(resp.customerTypesList);
        }
      } catch (error) {
        console.error(error);
        notifyError(error.message);
      } finally {
        notifySuccess(messageKey + ' has been deleted');
      }
    });
  };

  const handleOpenAddress = (data) => {
    if (!data) {
      // ADD default values
      data = {
        ownerId: modalData.ownerId,
        addressType: 'Primary',
        address1: '',
        address2: '',
        address3: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        status: 'Active',
        imgProofOfAddress: '',
      };
    }

    setRowData(data);
    setOpenAddress(true);
  };

  const handleOpenCustomerType = (data) => {
    if (!data) {
      data = {
        accountId: '',
        customerType: '',
      };
    }

    setRowData(data);
    setOpenCustomerType(true);
  };

  const handleCloseCustomerType = async (data, isEdit) => {
    if (!data) {
      setOpenCustomerType(false);
      return;
    }

    try {
      //   await createCustomerType(data);
      //   const resp = await listCustomerType(value.accountId);
      //   console.log(resp);
      //   setCustomerTypeRows(resp.customerTypesList);
      //   notifySuccess('New  CustomerType has been added.');
      //   setOpenCustomerType(false);
    } catch (error) {
      console.error(error);
      notifyError(error.message);
    }
  };

  const handleCloseAddress = async (data, isEdit) => {
    if (!data) {
      setAddressValidation({});
      setOpenAddress(false);
      return;
    }

    let valid = true;

    setAddressValidation({
      addressType: !data.addressType,
      address1: !data.address1,
      city: !data.city,
      country: !data.country,
      state: data.country === 'US' && (data.state === '' || !data.state),
      zip: !data.zip,
      status: !data.status,
    });

    if (!data.addressType) {
      notifyError('Address Type is required.');
      valid = false;
    }
    if (!data.address1) {
      notifyError('Address 1 is required.');
      valid = false;
    }
    if (!data.city) {
      notifyError('City is required.');
      valid = false;
    }
    if (!data.country) {
      notifyError('Country is required.');
      valid = false;
    }

    if (
      (data.country === 'US' || data.country === 'CA') &&
      (data.state === '' || !data.state)
    ) {
      notifyError('State is required.');
      valid = false;
    }

    if (!data.zip) {
      notifyError('Zip is required.');
      valid = false;
    }
    if (!data.status) {
      notifyError('Status is required.');
      valid = false;
    }

    if (!valid) {
      return;
    }

    setLoading({ ...loading, save: true });

    try {
      if (isEdit) {
        // EDIT
        await updateAddress(value.accountId, data, value.ownerId).then((res) => {
          const index = addressRows.indexOf(rowData);
          const rowsCopy = [...addressRows];
          rowsCopy[index] = res.address;
          setAddressRows(rowsCopy);
          notifySuccess('Address has been updated.');
        });
      } else {
        // ADD
        await createAddress(value.accountId, data, value.ownerId).then((res) => {
          setAddressRows([res.address, ...addressRows]);
          notifySuccess('New Address has been added.');
        });
      }
    } catch (error) {
      setLoading({ ...loading, save: false });
      notifyError(error.message);

      return;
    }

    setLoading({ ...loading, save: false });
    setAddressValidation({});
    setOpenAddress(false);
  };

  const isContactInfoDeleteEnabled = (dataIndex) => {
    return contactInfoRows[dataIndex]?.status !== 'Inactive';
  };

  const ContactInfoColumnsButtons = ({
    tableManager,
    value,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return (
      <div>
        {/* <IconButton
          aria-label="delete"
          disabled={data.status === 'Inactive'}
          onClick={() => {
            handleDeleteContactInfo(data, rowIndex - 1);
          }}
        >
          <DeleteIcon style={{ height: 20, width: 20 }} />
        </IconButton> */}
        <IconButton
          aria-label="edit"
          disabled={loading.delete}
          onClick={() => handleOpenContactInfo(data)}
        >
          <EditIcon style={{ height: 20, width: 20 }} />
        </IconButton>
      </div>
    );
  };

  const contactInfoColumns = [
    {
      id: '',
      field: '',
      width: '30px',
      cellRenderer: ContactInfoColumnsButtons,
    },
    {
      id: 'contactInfoType',
      field: 'contactInfoType',
      label: 'Contact Info Type',
    },
    {
      id: 'contactInfo',
      field: 'contactInfo',
      label: 'Contact Info',
    },
    {
      id: 'status',
      field: 'status',
      label: 'Status',
    },
  ];

  const handleDeleteContactInfo = (data, index) => {
    confirm({
      description:
        'You are about to disable ' + data.contactInfo + '. Please confirm your action.',
      confirmationText: 'Yes, disable',
    }).then(async () => {
      const rowsCopy = [...contactInfoRows];
      try {
        setLoading({ ...loading, delete: true });
        await deleteOwnerContactInfo(data.contactInfoId, data.ownerId).then((res) => {
          rowsCopy[index] = res.contactInfo;
        });
      } catch (error) {
        notifyError(error.message);
      } finally {
        setLoading({ ...loading, delete: false });
        setContactInfoRows(rowsCopy);
        notifySuccess(data.contactInfo + ' Contact Info has been disabled');
      }
    });
  };

  const handleOpenContactInfo = (data) => {
    if (!data) {
      //ADD default values
      data = {
        ownerId: modalData.ownerId,
        contactInfoType: '',
        contactInfo: '',
      };
    }

    setRowData(data);
    setOpenContactInfo(true);
  };

  const handleCloseContactInfo = async (data, isEdit) => {
    if (!data) {
      setContactInfoValidation({});
      setOpenContactInfo(false);
      return;
    }

    let valid = true;

    setContactInfoValidation({
      contactInfoType: !data.contactInfoType,
      contactInfo:
        !data.contactInfo ||
        data.contactInfoType === 'Primary Email' ||
        (data.contactInfoType === 'Email' &&
          !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9-]*$/i.test(data.contactInfo)),
      status: !data.status,
    });

    if (!data.contactInfoType) {
      notifyError('Contact Info Type is required.');
      valid = false;
    }

    if (!data.contactInfo) {
      notifyError('Contact Info is required.');
      valid = false;
    }

    if (!data.status) {
      notifyError('Status is required.');
      valid = false;
    }

    if (data.contactInfoType === 'Primary Email' || data.contactInfoType === 'Email') {
      if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9-]*$/i.test(data.contactInfo)) {
        notifyError('Invalid email address.');
        valid = false;
      }
    }

    if (!valid) {
      return;
    }

    setLoading({ ...loading, save: true });

    try {
      if (isEdit) {
        //EDIT
        const { contactInfo } = await updateContactInfo(
          value.accountId,
          data,
          value.ownerId
        );
        const index = contactInfoRows.indexOf(rowData);
        const rowsCopy = [...contactInfoRows];
        rowsCopy[index] = contactInfo;
        setContactInfoRows(rowsCopy);
        notifySuccess('Contact info has been updated.');
      } else {
        // ADD
        await createContactInfo(value.accountId, data, value.ownerId).then((res) => {
          setContactInfoRows([res.contactInfo, ...contactInfoRows]);
          notifySuccess('New contact info has been added.');
        });
      }

      setContactInfoValidation({});
      setOpenContactInfo(false);
    } catch (error) {
      console.error(error);
      setLoading({ ...loading, save: false });
      notifyError(error.message);
    }

    setLoading({ ...loading, save: false });
  };

  const handleCloseAuditModal = async () => {
    setOpenAudit(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={() => handleClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalFull}>
          <Text
            id="transition-modal-title"
            variant="h2"
            label={isEdit ? 'Edit Owner' : 'Add New Owner'}
          />
          <Box mt={3}>
            <AppBar
              position="static"
              className={classes.tabHeader}
              style={{
                backgroundColor: '#ffffff',
                boxShadow: 'none',
                borderBottom: '1px solid #e8e8e8',
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="auto"
                variant="fullWidth"
                centered
              >
                <Tab label="Owner" {...a11yProps(0)} />
                <Tab label="Customer Type" disabled={!isAccessible} {...a11yProps(1)} />
                <Tab label="Identification" disabled={!isAccessible} {...a11yProps(2)} />
                <Tab label="Address" disabled={!isAccessible} {...a11yProps(3)} />
                <Tab
                  label="Contact Information"
                  disabled={!isAccessible}
                  {...a11yProps(4)}
                />
              </Tabs>
            </AppBar>
            <TabPanel value={tabValue} index={0}>
              <form>
                <div className="grd-row">
                  <div className="grd-cell" style={{ marginRight: 30 }}>
                    <SelectSystemCode
                      error={validation.ownerType}
                      required={true && !isEdit}
                      action={isEdit}
                      name="ownerType"
                      label="Owner Type"
                      type="Owner Type"
                      value={modalData.ownerType}
                      onChange={handleChange}
                      disabled={isEdit}
                    ></SelectSystemCode>
                  </div>
                  <div className="grd-cell" style={{ marginRight: 30 }}>
                    <TextField
                      error={validation.firstName}
                      required={!isEdit}
                      fullWidth={true}
                      name="firstName"
                      label="First Name"
                      value={modalData.firstName}
                      onChange={handleChange}
                      inputProps={{ maxLength: 300 }}
                      InputLabelProps={{ shrink: true }}
                      disabled={isEdit}
                    />
                  </div>
                  <div className="grd-cell" style={{ marginRight: 30 }}>
                    <TextField
                      fullWidth={true}
                      name="middleName"
                      label="Middle Name"
                      value={modalData.middleName}
                      onChange={handleChange}
                      inputProps={{ maxLength: 300 }}
                      InputLabelProps={{ shrink: true }}
                      disabled={isEdit}
                    />
                  </div>
                  <div className="grd-cell">
                    <TextField
                      error={validation.lastName}
                      required
                      fullWidth={true}
                      name="lastName"
                      label="Last Name"
                      value={modalData.lastName}
                      onChange={handleChange}
                      inputProps={{ maxLength: 300 }}
                      InputLabelProps={{ shrink: true }}
                      disabled={false}
                    />
                  </div>
                </div>
                <div className="grd-row">
                  <div className="grd-cell" style={{ marginRight: 30 }}>
                    <SelectTaxCountry
                      error={validation.citizenship}
                      required={false}
                      name="citizenship"
                      label="Citizenship"
                      value={modalData.citizenship}
                      onChange={(selected) => {
                        setModalData({
                          ...modalData,
                          citizenship: selected?.code,
                        });
                      }}
                      disabled={isEdit}
                    ></SelectTaxCountry>
                  </div>
                  <div className="grd-cell" style={{ marginRight: 30 }}>
                    {!isAccessible ? (
                      <TextField
                        fullWidth
                        disabled={true}
                        name="dateOfBirthPlaceholder"
                        label="Date Of Birth"
                        type="date"
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                      />
                    ) : (
                      <TextField
                        fullWidth
                        required={false}
                        name="dateOfBirth"
                        label="Date Of Birth"
                        type="date"
                        value={modalData.dateOfBirth}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        disabled={isEdit}
                      />
                    )}
                  </div>
                  {modalData.ownerType.includes('Beneficiary') && (
                    <div className="grd-cell" style={{ marginRight: 30 }}>
                      <TextField
                        fullWidth
                        required={!isEdit}
                        name="ownershipPercent"
                        label="Ownership Percent"
                        value={modalData.ownershipPercent}
                        type="number"
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">%</InputAdornment>
                          ),
                        }}
                        disabled={isEdit}
                      />
                    </div>
                  )}

                  {modalData.ownerType.includes('Beneficiary') && (
                    <div className="grd-cell">
                      <SelectSystemCode
                        name="relationship"
                        label="Relationship"
                        type="Relationship"
                        subType="Client Site"
                        value={modalData.relationship}
                        onChange={handleChange}
                        disabled={isEdit}
                      />
                    </div>
                  )}

                  {modalData.ownerType !== 'Beneficiary' &&
                    modalData.ownerType !== 'Contingent Beneficiary' && (
                      <div className="grd-cell" style={{ marginRight: 30 }}>
                        <SelectSystemCode
                          name="maritalStatus"
                          label="Marital Status"
                          type="Marital Status"
                          value={modalData.maritalStatus}
                          onChange={handleChange}
                          disabled={false}
                        ></SelectSystemCode>
                      </div>
                    )}
                  {modalData.ownerType !== 'Beneficiary' &&
                    modalData.ownerType !== 'Contingent Beneficiary' && (
                      <div className="grd-cell">
                        <SelectSystemCode
                          error={validation.employmentStatus}
                          name="employmentStatus"
                          label="Employment Status"
                          type="Employment Status"
                          subType="Client Site"
                          value={modalData.employmentStatus}
                          onChange={handleChange}
                          disabled={false}
                        ></SelectSystemCode>
                      </div>
                    )}
                </div>

                {modalData.employmentStatus === 'Employed' && (
                  <div className="grd-row">
                    <div className="grd-cell" style={{ marginRight: 30 }}>
                      <TextField
                        fullWidth
                        required={true && !isEdit}
                        name="employmentOccupation"
                        label="Employment Occupation"
                        value={modalData.employmentOccupation}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        disabled={isEdit}
                      />
                    </div>
                    <div className="grd-cell" style={{ marginRight: 30 }}>
                      <TextField
                        fullWidth
                        required={true && !isEdit}
                        name="employmentName"
                        label="Employment Name"
                        value={modalData.employmentName}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        disabled={isEdit}
                      />
                    </div>
                    <div className="grd-cell" style={{ marginRight: 30 }}>
                      <TextField
                        fullWidth
                        name="natureOfBusiness"
                        label="Nature of Business"
                        value={modalData.natureOfBusiness}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        disabled={isEdit}
                      />
                    </div>
                    <div className="grd-cell">
                      <TextField
                        fullWidth
                        name="yearsEmployed"
                        label="Years Employed"
                        value={modalData.yearsEmployed}
                        type="number"
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                        disabled={isEdit}
                      />
                    </div>
                  </div>
                )}
                {modalData.ownerType === 'Trusted Contact' && (
                  <div className="grd-row">
                    <div className="grd-cell">
                      <SelectSystemCode
                        name="relationship"
                        label="Relationship"
                        type="Relationship"
                        subType="Client Site"
                        value={modalData.relationship}
                        onChange={handleChange}
                        disabled={isEdit}
                      />
                    </div>
                    <div className="grd-cell" style={{ marginRight: 30 }}></div>
                    <div className="grd-cell" style={{ marginRight: 30 }}></div>
                    <div className="grd-cell" style={{ marginRight: 30 }}></div>
                  </div>
                )}

                {modalData.ownerType !== 'Beneficiary' &&
                  modalData.ownerType !== 'Contingent Beneficiary' && (
                    <div>
                      <div className="grd-row">
                        <div
                          className="grd-cell"
                          style={{ marginRight: 30, marginTop: 10 }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="officer"
                                checked={modalData.officer}
                                onChange={handleChange}
                                disabled={isEdit}
                              />
                            }
                            label="Officer"
                          />
                        </div>
                        <div
                          className="grd-cell"
                          style={{ marginRight: 10, marginTop: 10 }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="owner"
                                checked={modalData.owner}
                                onChange={handleChange}
                                disabled={isEdit}
                              />
                            }
                            label="Owner"
                          />
                        </div>
                        <div className="grd-cell">
                          {modalData.owner === true && (
                            <TextField
                              fullWidth
                              name="ownershipPercent"
                              label="Ownership Percent"
                              required={!isEdit}
                              value={modalData.ownershipPercent}
                              type="number"
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">%</InputAdornment>
                                ),
                              }}
                              disabled={isEdit}
                            />
                          )}
                        </div>
                        <div className="grd-cell"></div>
                      </div>
                      <div className="grd-row">
                        <div className="grd-cell" style={{ marginRight: 30 }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="ownership"
                                checked={modalData.ownership}
                                disabled={isEdit}
                                onChange={handleChange}
                              />
                            }
                            label="Ownership"
                          />
                        </div>
                      </div>

                      {modalData.ownership && (
                        <div className="grd-row">
                          <div className="grd-cell" style={{ marginRight: 30 }}>
                            <TextField
                              fullWidth
                              error={validation.stockOwnership}
                              required={modalData.ownership && !isEdit}
                              name="stockOwnership"
                              label="Stock Symbol"
                              value={modalData.stockOwnership}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              disabled={isEdit}
                            />
                          </div>
                          <div className="grd-cell" style={{ marginRight: 30 }}>
                            <TextField
                              fullWidth
                              error={validation.stockCompany}
                              required={modalData.ownership && !isEdit}
                              name="stockCompany"
                              label="Stock Company"
                              value={modalData.stockCompany}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              disabled={isEdit}
                            />
                          </div>
                          <div className="grd-cell" style={{ marginRight: 30 }}>
                            <TextField
                              fullWidth
                              error={validation.stockPosition}
                              required={modalData.ownership && !isEdit}
                              name="stockPosition"
                              label="Stock Position"
                              value={modalData.stockPosition}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              disabled={isEdit}
                            />
                          </div>
                          <div className="grd-cell" style={{ marginRight: 30 }}>
                            <TextField
                              fullWidth
                              error={validation.stockPerson}
                              required={modalData.ownership && !isEdit}
                              name="stockPerson"
                              label="Stock Person"
                              value={modalData.stockPerson}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              disabled={isEdit}
                            />
                          </div>
                        </div>
                      )}
                      <div className="grd-row">
                        <div className="grd-cell" style={{ marginRight: 30 }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="brokerMember"
                                checked={modalData.brokerMember}
                                onChange={handleChange}
                                disabled={isEdit}
                              />
                            }
                            label="Broker Member"
                          />
                        </div>
                      </div>
                      {modalData.brokerMember && (
                        <div className="grd-row">
                          <div className="grd-cell" style={{ marginRight: 30 }}>
                            <TextField
                              fullWidth
                              error={validation.brokerName}
                              required={modalData.brokerMember && !isEdit}
                              name="brokerName"
                              label="Name of the Company"
                              value={modalData.brokerName}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              disabled={isEdit}
                            />
                          </div>
                          <div className="grd-cell" style={{ marginRight: 30 }}>
                            <TextField
                              fullWidth
                              error={validation.affiliatePerson}
                              required={modalData.brokerMember && !isEdit}
                              name="affiliatePerson"
                              label="Name of the Affiliated Person"
                              value={modalData.affiliatePerson}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              disabled={isEdit}
                            />
                          </div>
                          <div className="grd-cell">
                            <TextField
                              fullWidth
                              error={validation.affiliatePersonPosition}
                              required={modalData.brokerMember && !isEdit}
                              name="affiliatePersonPosition"
                              label="Position of the Affiliated Person"
                              value={modalData.affiliatePersonPosition}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              disabled={isEdit}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                {modalData.ownerType !== 'Beneficiary' &&
                  modalData.ownerType !== 'Contingent Beneficiary' && (
                    <div>
                      <div className="grd-row">
                        <div className="grd-cell" style={{ marginRight: 30 }}>
                          <FormControl fullWidth disabled={isEdit}>
                            <InputLabel shrink id="ofacStatus">
                              OFAC Status
                            </InputLabel>
                            <Select
                              name="ofacStatus"
                              fullWidth
                              value={modalData.ofacStatus}
                              onChange={handleChange}
                            >
                              <MenuItem value="Pending">Pending</MenuItem>
                              <MenuItem value="Approved">Approved</MenuItem>
                              <MenuItem value="Denied">Denied</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="grd-cell" style={{ marginRight: 30 }}>
                          <TextField
                            fullWidth
                            error={validation.ofacDate}
                            required={
                              modalData.ownerType !== 'Contingent Beneficiary' &&
                              modalData.ownerType !== 'Trusted Contact' &&
                              modalData.ownerType !== 'Beneficiary' &&
                              !isEdit
                            }
                            name="ofacDate"
                            label="OFAC Date"
                            type="date"
                            value={modalData.ofacDate}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            disabled={isEdit}
                          />
                        </div>
                        <div className="grd-cell" style={{ marginRight: 30 }}>
                          <FormControl fullWidth disabled={isEdit}>
                            <InputLabel shrink id="kycStatus">
                              KYC Status
                            </InputLabel>
                            <Select
                              name="kycStatus"
                              fullWidth
                              value={modalData.kycStatus}
                              onChange={handleChange}
                            >
                              <MenuItem value="Pending">Pending</MenuItem>
                              <MenuItem value="Approved">Approved</MenuItem>
                              <MenuItem value="Denied">Denied</MenuItem>
                              <MenuItem value="Denied">Failed</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="grd-cell">
                          <TextField
                            fullWidth
                            error={validation.kycDate}
                            required={
                              modalData.ownerType !== 'Contingent Beneficiary' &&
                              modalData.ownerType !== 'Trusted Contact' &&
                              modalData.ownerType !== 'Beneficiary' &&
                              !isEdit
                            }
                            name="kycDate"
                            label="KYC Date"
                            type="date"
                            value={modalData.kycDate}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            disabled={isEdit}
                          />
                        </div>
                      </div>
                      <div className="grd-row">
                        <div className="grd-cell" style={{ marginRight: 30 }}>
                          <SelectInvestmentObject
                            type="Side"
                            name="investmentObjectiveCode"
                            label="Investment Objective Code"
                            value={modalData.investmentObjectiveCode}
                            onChange={handleChange}
                            disabled={isEdit}
                          ></SelectInvestmentObject>
                        </div>
                        <div className="grd-cell" style={{ marginRight: 30 }}>
                          <TextField
                            fullWidth
                            error={validation.ofacDate}
                            // required={modalData.ofacStatus !== 'Pending'}
                            name="investmentObjectiveDate"
                            label="Investment Objective Date"
                            type="date"
                            value={modalData.investmentObjectiveDate}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            disabled={isEdit}
                          />
                        </div>
                        <div className="grd-cell" style={{ marginRight: 30 }}>
                          <TextField
                            fullWidth
                            name="webTermConditions"
                            label="Web Term Conditions"
                            type="datetime-local"
                            value={modalData.webTermConditions}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            disabled={isEdit}
                          />
                        </div>
                        <div className="grd-cell">
                          <TextField
                            fullWidth
                            name="disclosureStatement"
                            label="Disclosure Statement"
                            type="datetime-local"
                            value={modalData.disclosureStatement}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            disabled={isEdit}
                          />
                        </div>
                      </div>
                      <div className="grd-row">
                        <div className="grd-cell" style={{ marginRight: 30 }}>
                          <TextField
                            fullWidth
                            name="feeSchedule"
                            label="Fee Schedule"
                            type="datetime-local"
                            value={modalData.feeSchedule}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            disabled={isEdit}
                          />
                        </div>
                        <div className="grd-cell" style={{ marginRight: 30 }}>
                          <TextField
                            fullWidth
                            name="traderId"
                            label="Trader ID"
                            value={modalData.traderId}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            disabled={isEdit}
                          />
                        </div>
                        <div className="grd-cell" style={{ marginRight: 30 }}>
                          {isEdit ? (
                            <TextField
                              fullWidth
                              name="status"
                              label="Status"
                              value={modalData.status}
                              onChange={handleChange}
                              disabled={isEdit}
                            />
                          ) : (
                            <SelectSystemCode
                              error={validation.status}
                              required={true && !isEdit}
                              name="status"
                              label="Status"
                              type="Status"
                              subType="Client Setup"
                              value={modalData.status}
                              onChange={handleChange}
                              disabled={isEdit}
                            ></SelectSystemCode>
                          )}
                        </div>
                        <div className="grd-cell">
                          {modalData.status === 'Inactive' && (
                            <TextField
                              fullWidth
                              name="inactiveStatusReason"
                              label="Inactive Status Reason"
                              value={modalData.inactiveStatusReason}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                              disabled={isEdit}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                {(modalData.ownerType === 'Beneficiary' ||
                  modalData.ownerType === 'Contingent Beneficiary') && (
                  <div className="grd-row">
                    <div className="grd-cell" style={{ marginRight: 30 }}>
                      {isEdit ? (
                        <TextField
                          fullWidth
                          name="status"
                          label="Status"
                          value={modalData.status}
                          onChange={handleChange}
                          disabled={isEdit}
                        />
                      ) : (
                        <SelectSystemCode
                          error={validation.status}
                          required={true && !isEdit}
                          name="status"
                          label="Status"
                          type="Status"
                          subType="Client Setup"
                          value={modalData.status}
                          onChange={handleChange}
                          disabled={isEdit}
                        ></SelectSystemCode>
                      )}
                    </div>
                    <div className="grd-cell" style={{ marginRight: 30 }}>
                      {modalData.status === 'Inactive' ? (
                        <TextField
                          fullWidth
                          name="inactiveStatusReason"
                          label="Inactive Status Reason"
                          value={modalData.inactiveStatusReason}
                          onChange={handleChange}
                          InputLabelProps={{ shrink: true }}
                          disabled={isEdit}
                        />
                      ) : (
                        <div className="grd-cell"></div>
                      )}
                    </div>
                    <div className="grd-cell"></div>
                    <div className="grd-cell"></div>
                  </div>
                )}
              </form>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <div className="grd-row">
                <div className="grd-cell-none">
                </div>
              </div>
              <Box mt={3}>
                <ClientGrid
                  title={'Customer Type'}
                  id={'customerTypeId'}
                  rows={customerTypeRows}
                  setRows={setCustomerTypeRows}
                  columns={customerTypeColumns}
                  // options={customerTypeOptions}
                />
              </Box>
              {
                openCustomerType && null
                //   <CustomerTypeModal
                //     onClose={handleCloseCustomerType}
                //     open={openCustomerType}
                //     value={value}
                //     // importMode={isImport}
                //     // validation={addressValidation}
                //     loading={loading.save}
                //   ></CustomerTypeModal>
              }{' '}
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <div className="grd-row">
                <div className="grd-cell-none">
                  {/* <Button
                    color="secondary"
                    type="plus"
                    label="Add New"
                    onClick={() => {
                      handleOpenIdentification();
                    }}
                  /> */}
                </div>
              </div>
              <Box mt={3}>
                <ClientGrid
                  title={'Identification'}
                  id={'identificationId'}
                  rows={identificationRows}
                  setRows={setIdentificationRows}
                  columns={columns}
                  // options={options}
                />
              </Box>
              {
                openIdentification && null
                //   <IdentificationModal
                //     onClose={handleCloseIdentification}
                //     open={openIdentification}
                //     value={rowData}
                //     importMode={isImport}
                //     loading={loading.save}
                //   ></IdentificationModal>
              }{' '}
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <div className="grd-row">
                <div className="grd-cell-none">
                  {/* <Button
                    color="secondary"
                    type="plus"
                    label="Add New"
                    onClick={() => {
                      handleOpenAddress();
                    }}
                  /> */}
                </div>
              </div>
              <Box mt={3}>
                <ClientGrid
                  title={'Address'}
                  id={'addressId'}
                  rows={addressRows}
                  setRows={setAddressRows}
                  columns={addressColumn}
                  options={addressOptions}
                />
              </Box>
              {openAddress && (
                <AddressModal
                  onClose={handleCloseAddress}
                  open={openAddress}
                  value={rowData}
                  importMode={isImport}
                  validation={addressValidation}
                  loading={loading.save}
                ></AddressModal>
              )}{' '}
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
              <div className="grd-row">
                <div className="grd-cell-none">
                  {/* <Button
                    color="secondary"
                    type="plus"
                    label="Add New"
                    onClick={() => {
                      handleOpenContactInfo();
                    }}
                  /> */}
                </div>
              </div>
              <Box mt={3}>
                <ClientGrid
                  title={'Contact Informations'}
                  id={'contactInformationsId'}
                  rows={contactInfoRows}
                  setRows={setContactInfoRows}
                  columns={contactInfoColumns}
                />
              </Box>
              {openContactInfo && (
                <ContactInfoModal
                  onClose={handleCloseContactInfo}
                  open={openContactInfo}
                  value={rowData}
                  validation={contactInfoValidation}
                  loading={loading.save}
                ></ContactInfoModal>
              )}
            </TabPanel>
            <div
              className={classes.modalFooter}
              style={{ margin: 0, padding: '10px 30px 30px 25px' }}
            >
              {[0, 1, 2, 3].includes(tabValue) && (
                <div className="grd-cell-none" style={{ marginRight: 10 }}>
                  {/* <Button
                    disabled={!isEdit || saving}
                    color="primary"
                    label="View Audit Logs"
                    onClick={() => {
                      setOpenAudit(true);
                    }}
                  /> */}
                </div>
              )}
              <div className="grd-cell-none" style={{ marginRight: 10 }}>
                <Button
                  disabled={saving}
                  color="primary"
                  label="Close"
                  onClick={() => {
                    handleClose(null, false, linkedOwnerId);
                  }}
                />
              </div>
              <div className="grd-cell-none">
                <Button
                  disabled={saving}
                  loading={saving}
                  color="secondary"
                  label={saving ? 'Saving...' : 'Save'}
                  onClick={() => {
                    handleClose(
                      modalData,
                      isEdit || linkedOwnerId,
                      linkedOwnerId,
                      isAccessible
                    );
                  }}
                />
              </div>
            </div>
          </Box>
          {
            openAudit && null
            //   <ClientAuditModal
            //     onClose={handleCloseAuditModal}
            //     open={openAudit}
            //     value={value.ownerId}
            //     tabIndex={tabValue}
            //     tabType={'Owner'}
            //   ></ClientAuditModal>
          }
        </div>
      </Fade>
    </Modal>
  );
}
