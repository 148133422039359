/**
 * @fileoverview gRPC-Web generated client stub for commonpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_type_date_pb = require('../../google/type/date_pb.js')
const proto = {};
proto.commonpb = require('./list_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.commonpb.ListServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.commonpb.ListServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.ReadPrimaryOwnerRequest,
 *   !proto.commonpb.ReadPrimaryOwnerResponse>}
 */
const methodDescriptor_ListService_ReadPrimaryOwner = new grpc.web.MethodDescriptor(
  '/commonpb.ListService/ReadPrimaryOwner',
  grpc.web.MethodType.UNARY,
  proto.commonpb.ReadPrimaryOwnerRequest,
  proto.commonpb.ReadPrimaryOwnerResponse,
  /**
   * @param {!proto.commonpb.ReadPrimaryOwnerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.ReadPrimaryOwnerResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.commonpb.ReadPrimaryOwnerRequest,
 *   !proto.commonpb.ReadPrimaryOwnerResponse>}
 */
const methodInfo_ListService_ReadPrimaryOwner = new grpc.web.AbstractClientBase.MethodInfo(
  proto.commonpb.ReadPrimaryOwnerResponse,
  /**
   * @param {!proto.commonpb.ReadPrimaryOwnerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.ReadPrimaryOwnerResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.ReadPrimaryOwnerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.ReadPrimaryOwnerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.ReadPrimaryOwnerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.ListServiceClient.prototype.readPrimaryOwner =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.ListService/ReadPrimaryOwner',
      request,
      metadata || {},
      methodDescriptor_ListService_ReadPrimaryOwner,
      callback);
};


/**
 * @param {!proto.commonpb.ReadPrimaryOwnerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.ReadPrimaryOwnerResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.ListServicePromiseClient.prototype.readPrimaryOwner =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.ListService/ReadPrimaryOwner',
      request,
      metadata || {},
      methodDescriptor_ListService_ReadPrimaryOwner);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.ListBankAccountRequest,
 *   !proto.commonpb.ListBankAccountResponse>}
 */
const methodDescriptor_ListService_ListBankAccount = new grpc.web.MethodDescriptor(
  '/commonpb.ListService/ListBankAccount',
  grpc.web.MethodType.UNARY,
  proto.commonpb.ListBankAccountRequest,
  proto.commonpb.ListBankAccountResponse,
  /**
   * @param {!proto.commonpb.ListBankAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.ListBankAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.commonpb.ListBankAccountRequest,
 *   !proto.commonpb.ListBankAccountResponse>}
 */
const methodInfo_ListService_ListBankAccount = new grpc.web.AbstractClientBase.MethodInfo(
  proto.commonpb.ListBankAccountResponse,
  /**
   * @param {!proto.commonpb.ListBankAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.ListBankAccountResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.ListBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.ListBankAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.ListBankAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.ListServiceClient.prototype.listBankAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.ListService/ListBankAccount',
      request,
      metadata || {},
      methodDescriptor_ListService_ListBankAccount,
      callback);
};


/**
 * @param {!proto.commonpb.ListBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.ListBankAccountResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.ListServicePromiseClient.prototype.listBankAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.ListService/ListBankAccount',
      request,
      metadata || {},
      methodDescriptor_ListService_ListBankAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.ListOriginalCusipRequest,
 *   !proto.commonpb.ListOriginalCusipResponse>}
 */
const methodDescriptor_ListService_ListOriginalCusip = new grpc.web.MethodDescriptor(
  '/commonpb.ListService/ListOriginalCusip',
  grpc.web.MethodType.UNARY,
  proto.commonpb.ListOriginalCusipRequest,
  proto.commonpb.ListOriginalCusipResponse,
  /**
   * @param {!proto.commonpb.ListOriginalCusipRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.ListOriginalCusipResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.commonpb.ListOriginalCusipRequest,
 *   !proto.commonpb.ListOriginalCusipResponse>}
 */
const methodInfo_ListService_ListOriginalCusip = new grpc.web.AbstractClientBase.MethodInfo(
  proto.commonpb.ListOriginalCusipResponse,
  /**
   * @param {!proto.commonpb.ListOriginalCusipRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.ListOriginalCusipResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.ListOriginalCusipRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.ListOriginalCusipResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.ListOriginalCusipResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.ListServiceClient.prototype.listOriginalCusip =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.ListService/ListOriginalCusip',
      request,
      metadata || {},
      methodDescriptor_ListService_ListOriginalCusip,
      callback);
};


/**
 * @param {!proto.commonpb.ListOriginalCusipRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.ListOriginalCusipResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.ListServicePromiseClient.prototype.listOriginalCusip =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.ListService/ListOriginalCusip',
      request,
      metadata || {},
      methodDescriptor_ListService_ListOriginalCusip);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.ListBankNameRequest,
 *   !proto.commonpb.ListBankNameResponse>}
 */
const methodDescriptor_ListService_ListBankName = new grpc.web.MethodDescriptor(
  '/commonpb.ListService/ListBankName',
  grpc.web.MethodType.UNARY,
  proto.commonpb.ListBankNameRequest,
  proto.commonpb.ListBankNameResponse,
  /**
   * @param {!proto.commonpb.ListBankNameRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.ListBankNameResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.commonpb.ListBankNameRequest,
 *   !proto.commonpb.ListBankNameResponse>}
 */
const methodInfo_ListService_ListBankName = new grpc.web.AbstractClientBase.MethodInfo(
  proto.commonpb.ListBankNameResponse,
  /**
   * @param {!proto.commonpb.ListBankNameRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.ListBankNameResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.ListBankNameRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.ListBankNameResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.ListBankNameResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.ListServiceClient.prototype.listBankName =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.ListService/ListBankName',
      request,
      metadata || {},
      methodDescriptor_ListService_ListBankName,
      callback);
};


/**
 * @param {!proto.commonpb.ListBankNameRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.ListBankNameResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.ListServicePromiseClient.prototype.listBankName =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.ListService/ListBankName',
      request,
      metadata || {},
      methodDescriptor_ListService_ListBankName);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.ListPageRequest,
 *   !proto.commonpb.ListPageResponse>}
 */
const methodDescriptor_ListService_ListPage = new grpc.web.MethodDescriptor(
  '/commonpb.ListService/ListPage',
  grpc.web.MethodType.UNARY,
  proto.commonpb.ListPageRequest,
  proto.commonpb.ListPageResponse,
  /**
   * @param {!proto.commonpb.ListPageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.ListPageResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.commonpb.ListPageRequest,
 *   !proto.commonpb.ListPageResponse>}
 */
const methodInfo_ListService_ListPage = new grpc.web.AbstractClientBase.MethodInfo(
  proto.commonpb.ListPageResponse,
  /**
   * @param {!proto.commonpb.ListPageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.ListPageResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.ListPageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.ListPageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.ListPageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.ListServiceClient.prototype.listPage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.ListService/ListPage',
      request,
      metadata || {},
      methodDescriptor_ListService_ListPage,
      callback);
};


/**
 * @param {!proto.commonpb.ListPageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.ListPageResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.ListServicePromiseClient.prototype.listPage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.ListService/ListPage',
      request,
      metadata || {},
      methodDescriptor_ListService_ListPage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.ListPageRequest,
 *   !proto.commonpb.ListPageResponse>}
 */
const methodDescriptor_ListService_ListMenu = new grpc.web.MethodDescriptor(
  '/commonpb.ListService/ListMenu',
  grpc.web.MethodType.UNARY,
  proto.commonpb.ListPageRequest,
  proto.commonpb.ListPageResponse,
  /**
   * @param {!proto.commonpb.ListPageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.ListPageResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.commonpb.ListPageRequest,
 *   !proto.commonpb.ListPageResponse>}
 */
const methodInfo_ListService_ListMenu = new grpc.web.AbstractClientBase.MethodInfo(
  proto.commonpb.ListPageResponse,
  /**
   * @param {!proto.commonpb.ListPageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.ListPageResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.ListPageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.ListPageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.ListPageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.ListServiceClient.prototype.listMenu =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.ListService/ListMenu',
      request,
      metadata || {},
      methodDescriptor_ListService_ListMenu,
      callback);
};


/**
 * @param {!proto.commonpb.ListPageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.ListPageResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.ListServicePromiseClient.prototype.listMenu =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.ListService/ListMenu',
      request,
      metadata || {},
      methodDescriptor_ListService_ListMenu);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.ListPageRequest,
 *   !proto.commonpb.ListPageResponse>}
 */
const methodDescriptor_ListService_ListSubMenu = new grpc.web.MethodDescriptor(
  '/commonpb.ListService/ListSubMenu',
  grpc.web.MethodType.UNARY,
  proto.commonpb.ListPageRequest,
  proto.commonpb.ListPageResponse,
  /**
   * @param {!proto.commonpb.ListPageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.ListPageResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.commonpb.ListPageRequest,
 *   !proto.commonpb.ListPageResponse>}
 */
const methodInfo_ListService_ListSubMenu = new grpc.web.AbstractClientBase.MethodInfo(
  proto.commonpb.ListPageResponse,
  /**
   * @param {!proto.commonpb.ListPageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.ListPageResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.ListPageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.ListPageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.ListPageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.ListServiceClient.prototype.listSubMenu =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.ListService/ListSubMenu',
      request,
      metadata || {},
      methodDescriptor_ListService_ListSubMenu,
      callback);
};


/**
 * @param {!proto.commonpb.ListPageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.ListPageResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.ListServicePromiseClient.prototype.listSubMenu =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.ListService/ListSubMenu',
      request,
      metadata || {},
      methodDescriptor_ListService_ListSubMenu);
};


module.exports = proto.commonpb;

