/**
 * @fileoverview gRPC-Web generated client stub for usrpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.usrpb = require('./role_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.usrpb.RoleServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.usrpb.RoleServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.Role,
 *   !proto.usrpb.Role>}
 */
const methodDescriptor_RoleService_CreateRole = new grpc.web.MethodDescriptor(
  '/usrpb.RoleService/CreateRole',
  grpc.web.MethodType.UNARY,
  proto.usrpb.Role,
  proto.usrpb.Role,
  /**
   * @param {!proto.usrpb.Role} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.Role.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.usrpb.Role,
 *   !proto.usrpb.Role>}
 */
const methodInfo_RoleService_CreateRole = new grpc.web.AbstractClientBase.MethodInfo(
  proto.usrpb.Role,
  /**
   * @param {!proto.usrpb.Role} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.Role.deserializeBinary
);


/**
 * @param {!proto.usrpb.Role} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.usrpb.Role)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.Role>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.RoleServiceClient.prototype.createRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.RoleService/CreateRole',
      request,
      metadata || {},
      methodDescriptor_RoleService_CreateRole,
      callback);
};


/**
 * @param {!proto.usrpb.Role} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.Role>}
 *     Promise that resolves to the response
 */
proto.usrpb.RoleServicePromiseClient.prototype.createRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.RoleService/CreateRole',
      request,
      metadata || {},
      methodDescriptor_RoleService_CreateRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.Role,
 *   !proto.usrpb.Role>}
 */
const methodDescriptor_RoleService_UpdateRole = new grpc.web.MethodDescriptor(
  '/usrpb.RoleService/UpdateRole',
  grpc.web.MethodType.UNARY,
  proto.usrpb.Role,
  proto.usrpb.Role,
  /**
   * @param {!proto.usrpb.Role} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.Role.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.usrpb.Role,
 *   !proto.usrpb.Role>}
 */
const methodInfo_RoleService_UpdateRole = new grpc.web.AbstractClientBase.MethodInfo(
  proto.usrpb.Role,
  /**
   * @param {!proto.usrpb.Role} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.Role.deserializeBinary
);


/**
 * @param {!proto.usrpb.Role} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.usrpb.Role)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.Role>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.RoleServiceClient.prototype.updateRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.RoleService/UpdateRole',
      request,
      metadata || {},
      methodDescriptor_RoleService_UpdateRole,
      callback);
};


/**
 * @param {!proto.usrpb.Role} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.Role>}
 *     Promise that resolves to the response
 */
proto.usrpb.RoleServicePromiseClient.prototype.updateRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.RoleService/UpdateRole',
      request,
      metadata || {},
      methodDescriptor_RoleService_UpdateRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.ListRoleRequest,
 *   !proto.usrpb.ListRoleResponse>}
 */
const methodDescriptor_RoleService_ListRole = new grpc.web.MethodDescriptor(
  '/usrpb.RoleService/ListRole',
  grpc.web.MethodType.UNARY,
  proto.usrpb.ListRoleRequest,
  proto.usrpb.ListRoleResponse,
  /**
   * @param {!proto.usrpb.ListRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.ListRoleResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.usrpb.ListRoleRequest,
 *   !proto.usrpb.ListRoleResponse>}
 */
const methodInfo_RoleService_ListRole = new grpc.web.AbstractClientBase.MethodInfo(
  proto.usrpb.ListRoleResponse,
  /**
   * @param {!proto.usrpb.ListRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.ListRoleResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.ListRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.usrpb.ListRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.ListRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.RoleServiceClient.prototype.listRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.RoleService/ListRole',
      request,
      metadata || {},
      methodDescriptor_RoleService_ListRole,
      callback);
};


/**
 * @param {!proto.usrpb.ListRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.ListRoleResponse>}
 *     Promise that resolves to the response
 */
proto.usrpb.RoleServicePromiseClient.prototype.listRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.RoleService/ListRole',
      request,
      metadata || {},
      methodDescriptor_RoleService_ListRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.ListRoleRequest,
 *   !proto.usrpb.ListRoleResponse>}
 */
const methodDescriptor_RoleService_LazyListRole = new grpc.web.MethodDescriptor(
  '/usrpb.RoleService/LazyListRole',
  grpc.web.MethodType.UNARY,
  proto.usrpb.ListRoleRequest,
  proto.usrpb.ListRoleResponse,
  /**
   * @param {!proto.usrpb.ListRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.ListRoleResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.usrpb.ListRoleRequest,
 *   !proto.usrpb.ListRoleResponse>}
 */
const methodInfo_RoleService_LazyListRole = new grpc.web.AbstractClientBase.MethodInfo(
  proto.usrpb.ListRoleResponse,
  /**
   * @param {!proto.usrpb.ListRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.ListRoleResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.ListRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.usrpb.ListRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.ListRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.RoleServiceClient.prototype.lazyListRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.RoleService/LazyListRole',
      request,
      metadata || {},
      methodDescriptor_RoleService_LazyListRole,
      callback);
};


/**
 * @param {!proto.usrpb.ListRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.ListRoleResponse>}
 *     Promise that resolves to the response
 */
proto.usrpb.RoleServicePromiseClient.prototype.lazyListRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.RoleService/LazyListRole',
      request,
      metadata || {},
      methodDescriptor_RoleService_LazyListRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.DeleteRoleRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_RoleService_DeleteRole = new grpc.web.MethodDescriptor(
  '/usrpb.RoleService/DeleteRole',
  grpc.web.MethodType.UNARY,
  proto.usrpb.DeleteRoleRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.usrpb.DeleteRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.usrpb.DeleteRoleRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodInfo_RoleService_DeleteRole = new grpc.web.AbstractClientBase.MethodInfo(
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.usrpb.DeleteRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.usrpb.DeleteRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.RoleServiceClient.prototype.deleteRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.RoleService/DeleteRole',
      request,
      metadata || {},
      methodDescriptor_RoleService_DeleteRole,
      callback);
};


/**
 * @param {!proto.usrpb.DeleteRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.usrpb.RoleServicePromiseClient.prototype.deleteRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.RoleService/DeleteRole',
      request,
      metadata || {},
      methodDescriptor_RoleService_DeleteRole);
};


module.exports = proto.usrpb;

