import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  Link,
  FormControlLabel,
} from '@material-ui/core'
import APWAlertDialog from '../../components/Modals/APWAlertDialog';
import APWGrid from '../../components/Grid/APWGrid';
import APWForm from '../../components/Forms/APWForm';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import APWCodeField from '../../components/Forms/APWCodeField';
import APWButton from '../../components/Buttons/APWButton';
import authSvc from '../../services/AuthService';
import APWCheckbox from '../../components/Forms/APWCheckbox';
import APWSnackbar from '../../components/Snackbar/APWSnackbar';

const styles = (theme) => ({

  backButtonWrapper: {
    marginBottom: "12px",
    display: "flex",
    alignItems: "center",
    '& .backIcon': {
      width: '12px',
    }
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

const SMS_DELIVERY_METHOD = "1"
const EMAIL_DELIVERY_METHOD = "2"
const GA_DELIVERY_METHOD = "3"

const VERIFY_CODE = "verifyAuthCode"

const LOADING_OBJ = {
  verifyAuthCode: false,
  resendAuthCode: false,
}

const NUMBER_OF_ATTEMPTS = 5

class SMSAndEmailAuthentication extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      startTimer: true,
      authCode: '',
      timerLoading: {
        timer: true
      },
      account: props.account,
      loading: LOADING_OBJ,
      dialog: {
        open: false,
        title: "",
        content: null,
        actions: null,
      },
      invalidOTPAttempts: NUMBER_OF_ATTEMPTS,
      errorMessage: {
        id: '',
        errorMsg: '',
      },
      rememberMe: false,
      canResendNewCode: false,
    };
  }

  snackBarRef = React.createRef();

  // Reusable function to update a specific property in state
  setTimerLoading = (timer) => {
    this.setState(prevState => ({
      ...prevState,
      timerLoading: timer,
    }));
  };


  /*Verify OTP Functionalities*/

  componentDidUpdate() {
    //console.log("sms did update: ", this.state)
  }

  componentDidMount() {
   // console.log("sms did mount: ", this.state.account)
  }

  setDialog = (dlgObj) => {
    this.setState(prevState => ({
      ...prevState,
      dialog: dlgObj,
    }));
  }

  setLoading = (varName, val) => {
    this.setState(prevState => ({
      ...prevState,
      loading: { ...prevState.loading, [varName]: val },
    }));
  }

  handleVerifyOTP = () => {
    if (this.props.account.deliveryMethod === GA_DELIVERY_METHOD) {
      this.verifyGAOTP()
    } else {
      this.verifySMSEmailOTP()
    }
  }

  verifySMSEmailOTP = () => {
    if (!this.state.authCode) {
      setMessage({ type: 'error', value: 'Authentication Code is required.' });
      return;
    }

    this.setLoading(VERIFY_CODE, true)
    authSvc.validateAuthenticationCode({
      username: this.props.account.username,
      authenticationCode: this.state.authCode,
      deliveryMethod: this.props.account.deliveryMethod,
      deviceID: this.props.account.deviceID,
      rememberMe: this.state.rememberMe
    }).then((response) => {
      if (response.success) {
        if (this.state.rememberMe) {
          localStorage.setItem('device_id', this.props.account.deviceID);
        }
        this.setLoading(VERIFY_CODE, false)
        window.location = '/';
      }
    }).catch((err) => {
      //console.log(err, " validateAuthenticationCode err")
      this.showError(err.errorCode)
      return;
    });
  }

  verifyGAOTP = () => {
    if (!this.state.authCode) {
      setMessage({ type: 'error', value: 'Authentication Code is required.' });
      return;
    }

    this.setLoading(VERIFY_CODE, true)
    authSvc.validateGoogleAuthenticationCode({
      username: this.props.account.username,
      authenticationCode: this.state.authCode,
      deviceID: this.props.account.deviceID,
      rememberMe: this.state.rememberMe
    }).then((response) => {
      if (response.success) {
        if (this.state.rememberMe) {
          localStorage.setItem('device_id', this.props.account.deviceID);
        }
        this.setLoading(VERIFY_CODE, false)
        window.location = '/';
      }
    }).catch((err) => {
      //console.log(err, " validateGoogleAuthenticationCode err")
      this.showError(err.errorCode)
      return;
    });
  }

  internalError = () => {
    const dlg = {
      title: "Internal Server Error",
      content: (
        <APWAlertDialog.DialogContent>
          <APWAlertDialog.DialogContentText>
            {`Please try again`}
          </APWAlertDialog.DialogContentText>
        </APWAlertDialog.DialogContent>
      ),
    }

    this.setState(prevState => ({
      ...prevState,
      dialog: { ...prevState.dialog, open: true, title: dlg.title, content: dlg.content, actions: dlg.actions },
      loading: LOADING_OBJ,
    }));
  }

  alertAccountLockedMultipleOTP = () => {
    const dlg = {
      title: "Account Locked",
      content: (
        <APWAlertDialog.DialogContent>
          <APWAlertDialog.DialogContentText>
            {`
                    You’ve exceeded the allowed number of attempts.
                    Your account is locked. Please try again in 1 hour or
                    contact customer support at [# ### ### ####].
                    `}
          </APWAlertDialog.DialogContentText>
        </APWAlertDialog.DialogContent>
      ),
      actions: (
        <APWButton
          mt={true}
          label="Back to Sign"
          onClick={() => {
            this.props.backToSignin()
          }}
        />
      ),
    }

    this.setState(prevState => ({
      ...prevState,
      dialog: { ...prevState.dialog, open: true, title: dlg.title, content: dlg.content, actions: dlg.actions },
      loading: LOADING_OBJ,
    }));
  }

  alertIncorrectOTP = () => {
    const attempts = this.state.invalidOTPAttempts
    const deliveryMethod = this.props.account.deliveryMethod
    const dlg = {
      title: "Let's try that again",
      content: (
        <APWAlertDialog.DialogContent>
          <APWAlertDialog.DialogContentText>
            {`The verification code you entered is incorrect. Please verify
                        your code and try again.`}
          </APWAlertDialog.DialogContentText>
           {(attempts !== 5 && this.props.account.deliveryMethod !== GA_DELIVERY_METHOD) && (
            <React.Fragment>
              {`You have ${attempts} more attempts`}
            </React.Fragment>
          )}
        </APWAlertDialog.DialogContent>
      ),
      actions: (
        <React.Fragment>
          {this.props.account.deliveryMethod !== GA_DELIVERY_METHOD && (
            <APWButton
              disabled={!this.state.canResendNewCode}
              mt={true}
              label="Send me a new code"
              variant="outlined"
              onClick={(state) => {
                this.handleResendNewCode()
                this.hideErrorDialog()
              }}
            />
          )}
          <APWButton
            mt={true}
            label="Try Again"
            onClick={() => {
              this.hideErrorDialog()
            }}
          />
        </React.Fragment>
      ),
    }

    this.setState(prevState => ({
      ...prevState,
      dialog: { ...prevState.dialog, open: true, title: dlg.title, content: dlg.content, actions: dlg.actions },
      loading: LOADING_OBJ,
      invalidOTPAttempts: prevState.invalidOTPAttempts - 1,
    }));
  }


  showError = (code) => {
    if (code === "identity_invalid_otp_error") {
      this.alertIncorrectOTP()
      return;
    } else if (code === "identity_invalid_otp_exceeded_error") {
      this.alertAccountLockedMultipleOTP();
      return;
    } else {
      this.internalError()
      return;
    }

  }

  hideErrorDialog = (state) => {
    this.setDialog({ open: state, title: "", content: null, actions: null })
  }

  handleResendNewCode = () => {
    this.setState(prevState => ({
      ...prevState,
      initialAttempts: NUMBER_OF_ATTEMPTS,
      authCode: "",
      loading: { ...prevState.loading, resendAuthCode: true },
      startTimer: false,
    }));

    authSvc.sendAuthenticationCode(this.state.account).then((response) => {
      if (response.success) {
        this.setState(prevState => ({
          ...prevState,
          startTimer: true,
          timerLoading: { ...prevState.timerLoading, timer: true },
          loading: { ...prevState.loading, resendAuthCode: false },
          canResendNewCode: false,
        }));
        this.snackBarRef.current.openSnackbar(this.getSnackbarLabel(this.state.account.deliveryMethod))
      }
    }).catch((err) => {
      console.log("errr ", err)
      this.showError(err.errorCode)
      return;
    });
  }

  getHeaderLabel = (deliveryMethod) => {
    const label = {
      '1': 'We just sent you a text',
      '2': 'We just sent you an Email',
      '3': 'We just sent you a verification code',
      // Add more entries as needed
    };

    return label[deliveryMethod]
  }

  getHeaderSubLabel = (deliveryMethod) => {
    const subLabel = {
      '1': `Enter the verification code sent to (XXX) XXX-${this.state.account.deliveryAddress}`,
      '2': `Enter the verification code sent to ${this.state.account.deliveryAddress}`,
      '3': 'Enter the 6-digit verification code generated by your authentication app',
      // Add more entries as needed
    };
    return subLabel[deliveryMethod]
  }

  getSnackbarLabel = (deliveryMethod) => {
    const subLabel = {
      '1': `Verification code successfully resent to (XXX) XXX-${this.state.account.deliveryAddress}`,
      '2': `Verification code successfully resent to ${this.state.account.deliveryAddress}`,
      // Add more entries as needed
    };
    return subLabel[deliveryMethod]
  }

  handleRememberMe = (event) => {
    this.setState(prevState => ({
      ...prevState,
      rememberMe: event.target.checked,
    }));
  }

  render() {
    const { classes, theme, backToSignin, ...otherProps } = this.props;

    return (
      <React.Fragment>
        <APWAlertDialog
          open={this.state.dialog.open}
          title={this.state.dialog.title}
          toggleDialog={(state) => {
            this.hideErrorDialog(state)
          }}
          content={this.state.dialog.content}
          actionButtons={this.state.dialog.actions}
        />
        <APWGrid id="otp_sms_container" justifyContent="flex-end">
          <APWGrid.Item xs={11} sm={11} md={11} lg={11} xl={11}>
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                this.props.backToSignin();
              }}
            >
              <Box className={classes.backButtonWrapper}>
                <ArrowBackIosRoundedIcon className="backIcon" fontSize="small" /> Back to Signin
              </Box>

            </Link>
            <Typography component="div" style={{ marginBottom: theme.spacing(2) }}>
              <Box
                textAlign="left"
                fontSize="h4.fontSize"
                fontWeight="fontWeightBold"
              >
                {this.getHeaderLabel(this.state.account.deliveryMethod)}
              </Box>
              <Box
                textAlign="left"
                fontSize="body2.fontSize"

              >
                {this.getHeaderSubLabel(this.state.account.deliveryMethod)}
              </Box>
            </Typography>


            <APWForm>
              <APWForm.Item xs={12} sm={12} md={6} lg={4} xl={4}>

                {this.props.account.deliveryMethod === GA_DELIVERY_METHOD ? (
                  <APWCodeField
                    id="ga_auth_code"
                    mt={true}
                    max={6}
                    showIcon={false}
                    name="otp"
                    label="Verification Code"
                    placeholder={`6 Alphanumeric Code`}
                    type="text"
                    value={this.state.authCode}
                    onChange={(name, value) => {
                      this.setState(prevState => ({
                        ...prevState,
                        authCode: value,
                      }));
                    }}
                    onKeyUp={(e) => ((e.keyCode === 13 || e.key === 'Enter' || e.key === 'NumpadEnter') ? this.handleVerifyOTP() : null)}
                  />
                ) : (
                  <APWCodeField
                    timerInMinutes={15}
                    countdownListener={(minutes)=>{
                      if (minutes === 9) {
                        this.setState(prevState => ({
                          ...prevState,
                          canResendNewCode: true,
                        }));
                      }
                      console.log("remaining ", minutes)
                    }}
                    id="txt_auth_code"
                    mt={true}
                    max={6}
                    showIcon={false}
                    startTimer={this.state.startTimer}
                    setLoading={this.setTimerLoading}
                    name="otp"
                    label="Verification Code"
                    placeholder={`${(this.props.account.deliveryMethod === SMS_DELIVERY_METHOD || this.props.account.deliveryMethod === EMAIL_DELIVERY_METHOD) ? "6 Alphanumeric Code" : "6 Alphanumeric Code"}`}
                    type="text"
                    value={this.state.authCode}
                    onChange={(name, value) => {
                      this.setState(prevState => ({
                        ...prevState,
                        authCode: value,
                      }));
                    }}
                    onKeyUp={(e) => ((e.keyCode === 13 || e.key === 'Enter' || e.key === 'NumpadEnter') ? this.handleVerifyOTP() : null)}
                  />
                )}
              </APWForm.Item>
              {this.props.account.deliveryMethod !== GA_DELIVERY_METHOD && (
              <APWForm.Item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Link
                  disabled={this.state.loading.verifyAuthCode || !this.state.canResendNewCode}
                  component="button"
                  variant="body2"
                  underline="always"
                  onClick={this.state.loading.resendAuthCode ? null : this.handleResendNewCode}
                >
                  {this.state.loading.resendAuthCode ? 'Sending new code...' : 'Send me a new code'}
                </Link>
              </APWForm.Item>)}
              <APWForm.Item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormControlLabel
                  control={<APWCheckbox checked={this.state.rememberMe}
                  onChange={this.handleRememberMe} />}
                  label="Remember this device"
                  disabled={this.state.loading.verifyAuthCode}
                />
              </APWForm.Item>
              <APWForm.Item xs={12} sm={12} md={4} lg={2} xl={2}>
                <APWButton
                  fullWidth={true}
                  id="btn_verify_auth_Code"
                  disabled={this.state.loading.verifyAuthCode || !this.state.timerLoading.timer || this.state.authCode === ""}
                  loading={this.state.loading.verifyAuthCode}
                  label={this.state.loading.verifyAuthCode ? 'Verifying OTP...' : 'Submit'}
                  onClick={this.handleVerifyOTP}
                />
              </APWForm.Item>
            </APWForm>
          </APWGrid.Item>
        </APWGrid>
        <APWSnackbar ref={this.snackBarRef} id="alert_sms" />
      </React.Fragment>
    );
  }
}

SMSAndEmailAuthentication.propTypes = {
  classes: PropTypes.object.isRequired, // PropTypes for styles
  theme: PropTypes.object.isRequired,   // PropTypes for theme
  backToSignin: PropTypes.func.isRequired,
  authType: PropTypes.string.isRequired,
  account: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true, name: 'SMSAndEmailAuthentication' })(SMSAndEmailAuthentication);
