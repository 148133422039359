const pages = [
  {
    menu: 'Client Site',
    subMenu: 'Position',
    page: 'Position',
    label: 'Position',
    groupLabel: 'Reports',
    link: '/app/position',
  },
  {
    menu: 'Client Site',
    subMenu: 'Cash',
    page: 'Balance',
    label: 'Balance',
    groupLabel: 'Reports',
    link: '/app/balance',
  },
  {
    menu: 'Client Site',
    subMenu: 'Reports',
    page: 'Balance Summary',
    label: 'Balance Summary',
    groupLabel: 'Reports',
    link: '/app/balance-summary',
  },
  {
    menu: 'Client Site',
    subMenu: 'TRNS',
    page: 'Activity',
    label: 'Activity',
    groupLabel: 'Reports',
    link: '/app/activity',
  },
  {
    menu: 'Client Site',
    subMenu: 'Reports',
    page: 'Margin Requirement',
    label: 'Margin Requirement',
    groupLabel: 'Reports',
    link: '/app/margin-requirement',
  },
  {
    menu: 'Client Site',
    subMenu: 'Reports',
    page: 'Call Log',
    label: 'Call Log',
    groupLabel: 'Reports',
    link: '/app/call-log',
  },
  {
    menu: 'Client Site',
    subMenu: 'Reports',
    page: 'Margin Interest Calc',
    label: 'Margin Interest Calc',
    groupLabel: 'Reports',
    link: '/app/margin-interest-calc',
  },
  {
    menu: 'Client Site',
    subMenu: 'Reports',
    page: 'Daily Trade Confirm',
    label: 'Daily Trade Confirm',
    groupLabel: 'Reports',
    link: '/app/daily-trade-confirm',
  },
  {
    menu: 'Client Site',
    subMenu: 'Reports',
    page: 'Monthly Statement',
    label: 'Monthly Statement',
    groupLabel: 'Reports',
    link: '/app/monthly-statement',
  },
  {
    menu: 'Client Site',
    subMenu: 'Reports',
    page: 'Entitlement',
    label: 'Entitlement',
    groupLabel: 'Reports',
    link: '/app/entitlement',
  },
  {
    menu: 'Client Site',
    subMenu: 'Reports',
    page: 'Announcement',
    label: 'Announcement',
    groupLabel: 'Reports',
    link: '/app/announcement',
  },
  {
    menu: 'Client Site',
    subMenu: 'Reports',
    page: 'Fee Manager',
    label: 'Fee Manager',
    groupLabel: 'Reports',
    link: '/app/fee-manager',
  },
  {
    menu: 'Client Site',
    subMenu: 'Bank',
    page: 'Bank Summary',
    label: 'Bank Summary',
    groupLabel: 'Banking',
    link: '/app/bank-summary',
  },
  {
    menu: 'Client Site',
    subMenu: 'Bank',
    page: 'Account',
    label: 'Bank Account',
    groupLabel: 'Banking',
    link: '/app/bank-account',
  },
  {
    menu: 'Client Site',
    subMenu: 'Bank',
    page: 'ACH/Wire',
    label: 'ACH/Wire Request',
    groupLabel: 'Banking',
    link: '/app/ach-wire-request',
  },
  {
    menu: 'Client Site',
    subMenu: 'Bank',
    page: 'Wallet',
    label: 'Wallet',
    groupLabel: 'Banking',
    link: '/app/wallet',
  },
  {
    menu: 'Client Site',
    subMenu: 'Bank',
    page: 'Digital Cash Request',
    label: 'Digital Cash Request',
    groupLabel: 'Banking',
    link: '/app/digital-cash-request',
  },
  {
    menu: 'Client Site',
    subMenu: 'Bank',
    page: 'Wallet Address Book',
    label: 'Wallet Address Book',
    groupLabel: 'Banking',
    link: '/app/wallet-address-book',
  },
  {
    menu: 'Client Site',
    subMenu: 'TRNS',
    page: 'Bank Transaction',
    label: 'Transaction',
    groupLabel: 'Banking',
    link: '/app/transaction',
  },
  {
    menu: 'Client Site',
    subMenu: 'Account Setup',
    page: 'Client Account',
    label: 'Account Setup',
    groupLabel: 'Account Setup',
    link: '/app/account-setup',
  },
  {
    menu: 'Client Site',
    subMenu: 'Files',
    page: 'Tax Files',
    label: 'Tax Files',
    groupLabel: 'NA',
    link: '/app/tax-files',
  },
  {
    menu: 'Client Site',
    subMenu: 'Files',
    page: 'Invoices',
    label: 'Invoices',
    groupLabel: 'NA',
    link: '/app/invoices',
  },
  {
    menu: 'Client Site',
    subMenu: 'Position',
    page: 'Stock Borrow',
    label: 'Stock Borrow',
    groupLabel: 'NA',
    link: '/app/stock-borrow',
  },
  {
    menu: 'Client Site',
    subMenu: 'File',
    page: 'File Manager',
    label: 'File Manager',
    groupLabel: 'File',
    link: '/app/file-manager',
  },
  {
    menu: 'Client Site',
    subMenu: 'Option',
    page: 'Exercise Declaration',
    label: 'Exercise Declaration',
    groupLabel: 'Option',
    link: '/app/exercise-declaration',
  },
  {
    menu: 'Client Site',
    subMenu: 'Options',
    page: 'Exercise Declaration',
    label: 'Exercise Declaration',
    groupLabel: 'Options',
    link: '/app/exercise-declaration',
  },
  {
    menu: 'Client Site',
    subMenu: 'Model',
    page: 'Modeling',
    label: 'Modeling',
    groupLabel: 'Model',
    link: '/app/modeling',
  },
  {
    menu: 'Client Site',
    subMenu: 'Account Setup',
    page: 'User',
    label: 'User',
    groupLabel: 'Account Setup',
    link: '/app/user',
  },
  {
    menu: 'Client Site',
    subMenu: 'Account Setup',
    page: 'Role Manager',
    label: 'Role Manager',
    groupLabel: 'Account Setup',
    link: '/app/role-manager',
  },
  {
    menu: 'Client Site',
    subMenu: 'Trade',
    page: 'Order Entry',
    label: 'Order Entry',
    groupLabel: 'Trade',
    link: '/app/order-entry',
  },
  {
    menu: 'Client Site',
    subMenu: 'Tax Filing',
    page: 'Form 1042 DIVNRA',
    label: 'Form 1042 DIVNRA',
    groupLabel: 'Tax Filing',
    link: '/app/form-1042-divnra',
  },
  {
    menu: 'Client Site',
    subMenu: 'Tax Filing',
    page: 'Form 1099 B',
    label: 'Form 1099 B',
    groupLabel: 'Tax Filing',
    link: '/app/form-1099-b',
  },
  {
    menu: 'Client Site',
    subMenu: 'Tax Filing',
    page: 'Form 1099 INT',
    label: 'Form 1099 INT',
    groupLabel: 'Tax Filing',
    link: '/app/form-1099-int',
  },
  {
    menu: 'Client Site',
    subMenu: 'Tax Filing',
    page: 'Form 1099 DIV',
    label: 'Form 1099 DIV',
    groupLabel: 'Tax Filing',
    link: '/app/form-1099-div',
  },
  {
    menu: 'Client Site',
    subMenu: 'Contact Us',
    page: 'Contact Us',
    label: 'Contact Us',
    groupLabel: 'Contact Us',
    link: '/app/contact-us',
  },
];

export { pages };
