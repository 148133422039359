import {
  Identification,
  DeleteIdentificationRequest,
  IdentificationServiceClient,
} from '../proto/accountpb/identification_grpc_web_pb';
import { stringToProtoDate } from './ConvertService';

import { auth } from '../lib/auth/Auth';

const service = new IdentificationServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

export async function createIdentification(param) {
  return new Promise((resolve, reject) => {
    const req = new Identification();
    req.setAccountId(param.accountId);
    req.setOwnerId(param.ownerId);
    req.setType(param.type);
    req.setId(param.id);
    req.setCountryOfIssuance(param.ssnCountryOfIssuance);
    req.setIssueDate(stringToProtoDate(param.issueDate));
    req.setExpirationDate(stringToProtoDate(param.expirationDate));
    req.setImgGovernmentId(param.imgGovernmentId);
    req.setStatus(param.status);
    req.setState(param.state);
    req.setSsnState(param.ssnState);
    req.setSsnCountryOfIssuance(param.ssnCountryOfIssuance);

    service.createIdentification(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        let res = response.toObject();

        if (res.identification.msg) {
        } else {
          resolve(response.toObject());
        }
      }
    });
  });
}

export async function updateIdentification(param) {
  return new Promise((resolve, reject) => {
    const req = new Identification();
    req.setIdentificationId(param.identificationId);
    req.setAccountId(param.accountId);
    req.setOwnerId(param.ownerId);
    req.setType(param.type);
    req.setId(param.id);
    req.setCountryOfIssuance(param.countryOfIssuance);
    req.setIssueDate(stringToProtoDate(param.issueDate));
    req.setExpirationDate(stringToProtoDate(param.expirationDate));
    req.setImgGovernmentId(param.imgGovernmentId);
    req.setStatus(param.status);
    req.setSsnState(param.ssnState);
    req.setSsnCountryOfIssuance(param.ssnCountryOfIssuance);

    service.updateIdentification(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        let res = response.toObject();

        if (res.identification.msg) {
        } else {
          resolve(response.toObject());
        }
      }
    });
  });
}

export async function deleteIdentification(id) {
  return new Promise((resolve, reject) => {
    const req = new DeleteIdentificationRequest();
    req.setAccountId(id);

    service.deleteIdentification(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listIdentification(param) {
  return new Promise((resolve, reject) => {
    const req = new Identification();
    req.setAccountId(param.accountId);
    req.setOwnerId(param.ownerId);

    service.listIdentification(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
