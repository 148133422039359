import React, { useEffect } from 'react';
import { Create as EditIcon } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import Text from '../../../components/Typography/Text';
import ClientGrid from '../../../components/ClientGrid/ClientGrid';
import ClientGrid2 from '../../../components/ClientGrid/ClientGrid2'
import Table, { columnType } from '../../../components/ClearingTable/Table';
import { list } from '../../../services/AchWireRequestService';
import Button from '../../../components/Buttons/Button';
import AutoCompleteAccountNo from '../../../components/AutoComplete/AutoCompleteAccountNo';
import AutoCompleteCorrespondent from '../../../components/AutoComplete/AutoCompleteCorrespondent';
import AutoCompleteMasterAccountNo from '../../../components/AutoComplete/AutoCompleteMasterAccountNo';
import ManualInputModal from '../BankAccount/Component/ManualInputModal';
import AchWireRequestModal from './Component/AchWireRequestModal';
import SelectSystemCode from '../../../components/Dropdown/SelectSystemCode';
import TextField from '../../../components/Textfields/TextField';
import DateTimePicker from '../../../components/Textfields/DateTimePicker';
import MoneyField from '../../../components/Textfields/MoneyField';
import { getSystemDate } from '../../../services/ProfileService';
import { notifyError, notifyInfo } from '../../../components/Messages/Notification';
import QueryParam from '../../../services/QueryParamService';
import { getCurrentUser, getAccess } from '../../../services/AuthService';
import { readClient, getAccessibleClient } from '../../../services/ClientAccountService';
import { CreateLinkToken } from '../../../services/PlaidService';
import {
  pbDate,
  currency,
  boolean,
  pbDateTime,
  pbDateSorter,
} from '../../../components/ClientGrid/CellRenderer';
import {
  CustomBodyRenderCurrency,
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderDate,
  CustomBodyRenderDateTime,
  CustomBodyRenderBoolean,
  CustomBodyRenderAlign,
  CustomBodyRenderHtmlString,
  CustomBodyRenderCenter,
  CustomBodyRenderMissing,
} from '../../../components/Table/CustomBodyRender';
import { formatPbDate, formatPbDateTime } from '../../../lib/fmt';

export default function AchWireRequest() {
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState({
    search: false,
    save: false,
  });

  const [defaultAccount, setDefaultAccount] = React.useState([]);

  const [openAddBank, setOpenAddBank] = React.useState(false);
  const [isOpen, setOpen] = React.useState(false);
  const [rowData, setRowData] = React.useState({});
  const [modalData, setModalData] = React.useState({});

  const [addBankData] = React.useState({
    correspondent: '',
    accountNo: '',
    bankOwnerName: '',
    bankName: '',
    achRoutingNo: '',
    wireRoutingNo: '',
    bankAccountNo: '',
    bankAddress: '',
    bankAccountType: '',
    approvedMethod: '',
    plaidAccessToken: '',
    status: 'Pending Approval',
    bankIdentifierCode: '',
    isInternational: false,

  });
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      fromDate: '',
      toDate: '',
      correspondent: '',
      accountNo: '',
      externalId: '',
      masterAccountNo: '',
      transferType: '',
      requestType: '',
      status: '',
      sign: '',
      amount: '',
    })
  );

  useEffect(() => {
    init();

    // eslint-disable-next-line
  }, []);

  const init = async () => {
    let account_no = '';
    let correspondent = '';
    try {
      const systemDate = await getSystemDate();

      const loggeduser = getCurrentUser();

      if (loggeduser.AccountId !== 0) {
        const account = await readClient(loggeduser.AccountId);
        setDefaultAccount(account);
        account_no = account.client.accountNo;
        correspondent = account.client.correspondent;
      } else {
        const account = await getAccessibleClient(loggeduser.UserId);
        setDefaultAccount(account);
        account_no = account.client.accountNo;
        correspondent = account.client.correspondent;
      }
      if (!getAccess('account_no', loggeduser.UserType)) {
        setSearchData({
          ...searchData,
          fromDate: systemDate,
          toDate: systemDate,
          accountNo: account_no,
          correspondent: correspondent,
        });
      } else {
        setSearchData({
          ...searchData,
          fromDate: systemDate,
          toDate: systemDate,
        });
      }
    } catch (error) {
      notifyError(error.message);
    }
  };

  const getCsvData = () => {
    return rows;
  };

  const handleOpen = (data) => {
    if (!data) {
      //ADD default values
      const correspondent = defaultAccount?.client?.correspondent
        ? defaultAccount.client.correspondent
        : '';
      const account_no = defaultAccount?.client?.accountNo
        ? defaultAccount.client.accountNo
        : '';
      data = {
        correspondent: correspondent,
        accountNo: account_no,
        bankId: '',
        amt: '',
        transferType: '',
        requestType: '',
        status: 'Pending',
        fee: '0',
        bankNote: '',
        bank: '',
        // sendTo: 'BMO',
      };
    }

    setRowData(data);
    setOpen(true);
  };

  const handleClose = async (data, isEdit) => {
    // if close is clicked, close the modal
    if (!data) {
      setOpen(false);
      return;
    }

    // If add bank is clicked
    if (data.isAddBank) {
      // re-initialize isAddBank
      data.isAddBank = false;

      const loggeduser = getCurrentUser()
      var linkToken


      if (loggeduser === null) return
      try {
        const lt = await CreateLinkToken({
          name: 'SAS',
          country: 'US',
          id: loggeduser.UserId,
          type:'Micro Deposit'
        })
        if (lt.errorCode !== '') {
          notifyError(lt.errorCode + ' ' + lt.errorMessage)
          console.error(lt)
          return
        }
        linkToken = lt
        // console.log(linkToken);
      } catch (e) {
        notifyError(e)
        console.error(e)
        return
      }
      addBankData.linktoken = linkToken

      //backup the modal data
      setModalData(data);

      //close modal
      setOpen(false);

      //set initial value of correspondent and account no
      addBankData.correspondent = data.correspondent;
      addBankData.accountNo = data.accountNo;

      // open the add bank modal
      setOpenAddBank(true);
      // If save is clicked, save the ACH/Wire entry
    } else {
      try {
        if (isEdit) {
          //EDIT
          const rowsCopy = [...rows];
          const index = rows.indexOf(rowData);
          rowsCopy[index] = data;
          // setRows(rowsCopy);
          fetchData();
        } else {
          //ADD
          // setRows([data, ...rows]);
          fetchData();
        }
        setOpen(false);
      } catch (error) {
        notifyError(error.message);
      }
    }
  };

  const handleSearchDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    if (input.name === 'sign' && input.value === '') {
      searchData.amount = '';
    }

    setSearchData({ ...searchData, [input.name]: input.value });
  };

const fetchData = async () =>{
  try {

    QueryParam.set(searchData);
    const { requestsList } = await list(searchData);

    let rowsCopy = requestsList;

    const rowsCopyOutput = rowsCopy.map((data) => {
      let tempDate = formatPbDateTime(data.createdAt);
      data.createdAt = tempDate

      let tempDate2 = formatPbDate(data.systemDate);
      data.systemDate = tempDate2

      return data;
    })

    setRows(rowsCopyOutput);

  } catch (error) {
    notifyError(error.message);
    return;
  }
};

  const handleSearch = async () => {
    try {
      setLoading({ ...loading, search: true });

      QueryParam.set(searchData);
      const { requestsList } = await list(searchData);

      let rowsCopy = requestsList;

      const rowsCopyOutput = rowsCopy.map((data) => {
        let tempDate = formatPbDateTime(data.createdAt);
        data.createdAt = tempDate

        let tempDate2 = formatPbDate(data.systemDate);
        data.systemDate = tempDate2

        return data;
      })

      setRows(rowsCopyOutput);

      notifyInfo(
        requestsList.length > 0
          ? requestsList.length + ' search result(s)'
          : 'No records found.'
      );
    } catch (error) {
      notifyError(error.message);
      return;
    } finally {
      setLoading({ ...loading, search: false });
    }
  };

  const handleCloseAddBank = async (data) => {
    console.log("Data: " + data)
    // if close, is clicked
    if (!data) {
      setOpenAddBank(false);
      setRowData(modalData);
      setOpen(true);
      return;
    }

    try {
      setOpenAddBank(false);
      const copyModalData = {
        ...modalData,
        accountNo: data.status === 'Active' ? data.accountNo : modalData.accountNo,
        bankId: data.status === 'Active' ? data.bankId : modalData.bankId,
        correspondent:
          data.status === 'Active' ? data.correspondent : modalData.correspondent,
        bank: data.status === 'Active' ? data.bankName : modalData.bank,
        isInternational:
          data.status === 'Active' ? data.isInternational : modalData.isInternational,
      };
      setRowData(copyModalData);
      setOpen(true);
    } catch (error) {
      notifyError(error.message);
    }
  };

  const GridButtons = ({ tableManager, value, field, data }) => {
    return (
      <div>
        {data.bankAddressId !== 0 && (
          <IconButton onClick={() => handleOpen(data)}>
            <EditIcon style={{ height: 25, width: 25 }}/>
          </IconButton>
        )}
      </div>
    );
  };

  // const columns2 = [
  //   {
  //     id: '',
  //     label: '',
  //     width: '40px',
  //     cellRenderer: GridButtons,
  //   },
  //   {
  //     id: 'requestId',
  //     field: 'requestId',
  //     label: 'Request ID',
  //     visible: false,
  //   },
  //   {
  //     id: 'correspondent',
  //     field: 'correspondent',
  //     label: 'Correspondent',
  //   },
  //   {
  //     id: 'systemDate',
  //     field: 'systemDate',
  //     label: 'System Date',
  //     cellRenderer: pbDate,
  //     sort: pbDateSorter,
  //   },
  //   {
  //     id: 'accountId',
  //     field: 'accountId',
  //     label: 'Account ID',
  //     visible: false,
  //   },
  //   {
  //     id: 'branch',
  //     field: 'branch',
  //     label: 'Branch',
  //   },
  //   {
  //     id: 'accountNo',
  //     field: 'accountNo',
  //     label: 'Account No',
  //   },
  //   {
  //     id: 'masterAccountNo',
  //     field: 'masterAccountNo',
  //     label: 'Master Account No',
  //   },
  //   {
  //     id: 'rep',
  //     field: 'rep',
  //     label: 'Rep',
  //   },
  //   {
  //     id: 'bankId',
  //     field: 'bankId',
  //     label: 'Bank ID',
  //     visible: false,
  //   },
  //   {
  //     id: 'walletId',
  //     field: 'walletId',
  //     label: 'Wallet ID',
  //     visible: false,
  //   },
  //   {
  //     id: 'bankAccountNo',
  //     field: 'bankAccountNo',
  //     label: 'Bank Account No',
  //   },
  //   {
  //     id: 'bankRoutingNo',
  //     field: 'bankRoutingNo',
  //     label: 'Bank Routing No',
  //   },
  //   {
  //     id: 'bankName',
  //     field: 'bankName',
  //     label: 'Bank Name',
  //   },
  //   {
  //     id: 'requestType',
  //     field: 'requestType',
  //     label: 'Request Type',
  //   },
  //   {
  //     id: 'transferType',
  //     field: 'transferType',
  //     label: 'Transfer Type',
  //   },
  //   {
  //     id: 'amt',
  //     field: 'amt',
  //     label: 'Amount',
  //     cellRenderer: currency,
  //   },
  //   {
  //     id: 'fee',
  //     field: 'fee',
  //     label: 'Fee',
  //     cellRenderer: currency,
  //   },
  //   {
  //     id: 'fedNo',
  //     field: 'fedNo',
  //     label: 'Fed No',
  //   },
  //   {
  //     id: 'externalId',
  //     field: 'externalId',
  //     label: 'External ID',
  //     visible: false,
  //   },
  //   {
  //     id: 'bank',
  //     field: 'bank',
  //     label: 'Bank',
  //   },
  //   {
  //     id: 'bankNote',
  //     field: 'bankNote',
  //     label: 'Bank Note',
  //   },
  //   {
  //     id: 'internalNote',
  //     field: 'internalNote',
  //     label: 'Internal Note',
  //   },
  //   {
  //     id: 'status',
  //     field: 'status',
  //     label: 'Status',
  //   },
  //   {
  //     id: 'createdBy',
  //     field: 'createdBy',
  //     label: 'Created By',
  //   },
  //   {
  //     id: 'createdAt',
  //     field: 'createdAt',
  //     label: 'Created At',
  //     cellRenderer: pbDateTime,
  //     sort: pbDateSorter,
  //   },
  //   {
  //     id: 'isInternational',
  //     field: 'isInternational',
  //     label: 'Is International',
  //     cellRenderer: boolean,
  //   },
  // ];

  const columns = [
    {
      name: 'actions',
      label: 'Actions',
      sort: false,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return(
            <div>
              {rows[dataIndex].bankAddressId !== 0 && (
                <IconButton onClick={() => handleOpen(rows[dataIndex])}>
                  <EditIcon style={{ height: 25, width: 25 }}/>
                </IconButton>
              )}
            </div>
          );
        },
      },
    },
    {
      name: 'requestId',
      label: 'Request ID',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      }
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      // Already converted to string in handlesearch
      name: 'systemDate',
      label: 'System Date',
    },
    {
      name: 'accountId',
      label: 'Account ID',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false
      }
    },
    {
      name: 'branch',
      label: 'Branch',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'accountNo',
      label: 'Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'rep',
      label: 'Rep',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'bankId',
      label: 'Bank ID',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      }
    },
    {
      name: 'walletId',
      label: 'Wallet ID',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      }
    },
    {
      name: 'bankAccountNo',
      label: 'Bank Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'bankRoutingNo',
      label: 'Bank Routing No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'bankName',
      label: 'Bank Name',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'requestType',
      label: 'Request Type',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'transferType',
      label: 'Transfer Type',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'amt',
      label: 'Amount',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'fee',
      label: 'Fee',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'fedNo',
      label: 'Fed No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'externalId',
      label: 'External ID',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        visible: false,
      }
    },
    {
      name: 'bank',
      label: 'Bank',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'bankNote',
      label: 'Bank Note',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    // {
    //   name: 'internalNote',
    //   label: 'Internal Note',
    //   options: {
    //     customBodyRender: CustomBodyRenderAlign,
    //   }
    // },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'createdBy',
      label: 'Created By',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      // Already converted to string in handlesearch
      name: 'createdAt',
      label: 'Created At',
    },
    {
      name: 'isInternational',
      label: 'Is International',
      options: {
        customBodyRender: CustomBodyRenderBoolean,
      }
    },
  ];

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
  };

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="ACH\Wire Request" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading.search}
              loading={loading.search}
              type="search"
              label={loading.search ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading.save}
              loading={loading.save}
              type="plus"
              label="Add ACH\Wire Request"
              onClick={() => handleOpen()}
            />
          </div>
        </div>

        <div>
          <div className="grd-row">
            <div className="grd-cell">
              <DateTimePicker
                type="date"
                name="fromDate"
                label="From Date"
                value={searchData.fromDate}
                onChange={handleSearchDataChange}
              />
            </div>
            <div className="grd-cell">
              <DateTimePicker
                type="date"
                name="toDate"
                label="To Date"
                value={searchData.toDate}
                onChange={handleSearchDataChange}
              />
            </div>
            <div className="grd-cell">
              <AutoCompleteCorrespondent
                isAccessibleOnly={true}
                name="correspondent"
                value={searchData.correspondent}
                onChange={handleSearchDataChange}
              />
            </div>
            <div className="grd-cell">
              <AutoCompleteAccountNo
                isAccessibleOnly={true}
                name="accountNo"
                value={searchData.accountNo}
                correspondent={searchData.correspondent}
                onChange={handleSearchDataChange}
              />
            </div>
            <div className="grd-cell">
              <TextField
                name="externalId"
                label="External ID"
                placeholder="External ID"
                type="text"
                value={searchData.externalId}
                onChange={handleSearchDataChange}
              />
            </div>
          </div>
          <div className="grd-row">
            <div className="grd-cell">
              <AutoCompleteMasterAccountNo
                isAccessibleOnly={true}
                name="masterAccountNo"
                value={searchData.masterAccountNo}
                correspondent={searchData.correspondent}
                onChange={handleSearchDataChange}
              />
            </div>
            <div className="grd-cell">
              <SelectSystemCode
                name="transferType"
                label="Transfer Type"
                placeholder="Transfer Type"
                type="Type"
                subType="Transfer Type"
                value={searchData.transferType}
                onChange={handleSearchDataChange}
              />
            </div>
            <div className="grd-cell">
              <SelectSystemCode
                name="status"
                label="Status"
                placeholder="Status"
                type="Status"
                subType="Bank Request"
                value={searchData.status}
                onChange={handleSearchDataChange}
              />
            </div>
            <div className="grd-cell">
              <div className="grd-row nm">
                <div className="grd-cell">
                  <SelectSystemCode
                    name="sign"
                    label="Sign"
                    placeholder="Sign"
                    type="Sign"
                    subType="Bank Request"
                    value={searchData.sign}
                    onChange={handleSearchDataChange}
                  />
                </div>
                <div className="grd-cell">
                  <MoneyField
                    disabled={searchData.sign ? false : true}
                    name="amount"
                    label="Amount"
                    placeholder="$"
                    value={searchData.amount}
                    onChange={handleSearchDataChange}
                  />
                </div>
              </div>
            </div>
            <div className="grd-cell"></div>
          </div>
        </div>
      </div>

      <div className="mt-20">
        {/* <ClientGrid
          title="ACH\Wire Request"
          rowIdField="requestId"
          columns={columns2}
          rows={rows}
          getCsvData={getCsvData}
          isLoading={loading.search}
          setRows={setRows}
        /> */}
        {/* <ClientGrid2
          title="ACH\Wire Request"
          data={rows}
          columns={columns}
          options={options}
        /> */}
        <Table
          title={'ACH\Wire Request'}
          data={rows}
          columns={columns}
          options={options}
        />
      </div>

      {isOpen && (
        <AchWireRequestModal
          onClose={handleClose}
          open={isOpen}
          value={rowData}
        ></AchWireRequestModal>
      )}
      {openAddBank && (
        <ManualInputModal
          onClose={handleCloseAddBank}
          iopen={openAddBank}
          value={addBankData}
        ></ManualInputModal>
      )}
    </div>
  );
}
