/**
 * @fileoverview gRPC-Web generated client stub for commonpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.commonpb = require('./lazylist_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.commonpb.LazyListServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.commonpb.LazyListServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.LazyAccountRequest,
 *   !proto.commonpb.LazyAccountResponse>}
 */
const methodDescriptor_LazyListService_LazyAccount = new grpc.web.MethodDescriptor(
  '/commonpb.LazyListService/LazyAccount',
  grpc.web.MethodType.UNARY,
  proto.commonpb.LazyAccountRequest,
  proto.commonpb.LazyAccountResponse,
  /**
   * @param {!proto.commonpb.LazyAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.LazyAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.commonpb.LazyAccountRequest,
 *   !proto.commonpb.LazyAccountResponse>}
 */
const methodInfo_LazyListService_LazyAccount = new grpc.web.AbstractClientBase.MethodInfo(
  proto.commonpb.LazyAccountResponse,
  /**
   * @param {!proto.commonpb.LazyAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.LazyAccountResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.LazyAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.LazyAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.LazyAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.LazyListServiceClient.prototype.lazyAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.LazyListService/LazyAccount',
      request,
      metadata || {},
      methodDescriptor_LazyListService_LazyAccount,
      callback);
};


/**
 * @param {!proto.commonpb.LazyAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.LazyAccountResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.LazyListServicePromiseClient.prototype.lazyAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.LazyListService/LazyAccount',
      request,
      metadata || {},
      methodDescriptor_LazyListService_LazyAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.LazyLoadSecurityRequest,
 *   !proto.commonpb.LazyLoadSecurityResponse>}
 */
const methodDescriptor_LazyListService_LazySecurity = new grpc.web.MethodDescriptor(
  '/commonpb.LazyListService/LazySecurity',
  grpc.web.MethodType.UNARY,
  proto.commonpb.LazyLoadSecurityRequest,
  proto.commonpb.LazyLoadSecurityResponse,
  /**
   * @param {!proto.commonpb.LazyLoadSecurityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.LazyLoadSecurityResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.commonpb.LazyLoadSecurityRequest,
 *   !proto.commonpb.LazyLoadSecurityResponse>}
 */
const methodInfo_LazyListService_LazySecurity = new grpc.web.AbstractClientBase.MethodInfo(
  proto.commonpb.LazyLoadSecurityResponse,
  /**
   * @param {!proto.commonpb.LazyLoadSecurityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.LazyLoadSecurityResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.LazyLoadSecurityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.LazyLoadSecurityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.LazyLoadSecurityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.LazyListServiceClient.prototype.lazySecurity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.LazyListService/LazySecurity',
      request,
      metadata || {},
      methodDescriptor_LazyListService_LazySecurity,
      callback);
};


/**
 * @param {!proto.commonpb.LazyLoadSecurityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.LazyLoadSecurityResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.LazyListServicePromiseClient.prototype.lazySecurity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.LazyListService/LazySecurity',
      request,
      metadata || {},
      methodDescriptor_LazyListService_LazySecurity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.LazyLoadRequest,
 *   !proto.commonpb.LazyCusipResponse>}
 */
const methodDescriptor_LazyListService_LazyLoadCusip = new grpc.web.MethodDescriptor(
  '/commonpb.LazyListService/LazyLoadCusip',
  grpc.web.MethodType.UNARY,
  proto.commonpb.LazyLoadRequest,
  proto.commonpb.LazyCusipResponse,
  /**
   * @param {!proto.commonpb.LazyLoadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.LazyCusipResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.commonpb.LazyLoadRequest,
 *   !proto.commonpb.LazyCusipResponse>}
 */
const methodInfo_LazyListService_LazyLoadCusip = new grpc.web.AbstractClientBase.MethodInfo(
  proto.commonpb.LazyCusipResponse,
  /**
   * @param {!proto.commonpb.LazyLoadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.LazyCusipResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.LazyLoadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.LazyCusipResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.LazyCusipResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.LazyListServiceClient.prototype.lazyLoadCusip =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.LazyListService/LazyLoadCusip',
      request,
      metadata || {},
      methodDescriptor_LazyListService_LazyLoadCusip,
      callback);
};


/**
 * @param {!proto.commonpb.LazyLoadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.LazyCusipResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.LazyListServicePromiseClient.prototype.lazyLoadCusip =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.LazyListService/LazyLoadCusip',
      request,
      metadata || {},
      methodDescriptor_LazyListService_LazyLoadCusip);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.LazyAccountRequest,
 *   !proto.commonpb.LazyAdministratorNameResponse>}
 */
const methodDescriptor_LazyListService_LazyAdministratorName = new grpc.web.MethodDescriptor(
  '/commonpb.LazyListService/LazyAdministratorName',
  grpc.web.MethodType.UNARY,
  proto.commonpb.LazyAccountRequest,
  proto.commonpb.LazyAdministratorNameResponse,
  /**
   * @param {!proto.commonpb.LazyAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.LazyAdministratorNameResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.commonpb.LazyAccountRequest,
 *   !proto.commonpb.LazyAdministratorNameResponse>}
 */
const methodInfo_LazyListService_LazyAdministratorName = new grpc.web.AbstractClientBase.MethodInfo(
  proto.commonpb.LazyAdministratorNameResponse,
  /**
   * @param {!proto.commonpb.LazyAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.LazyAdministratorNameResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.LazyAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.LazyAdministratorNameResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.LazyAdministratorNameResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.LazyListServiceClient.prototype.lazyAdministratorName =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.LazyListService/LazyAdministratorName',
      request,
      metadata || {},
      methodDescriptor_LazyListService_LazyAdministratorName,
      callback);
};


/**
 * @param {!proto.commonpb.LazyAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.LazyAdministratorNameResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.LazyListServicePromiseClient.prototype.lazyAdministratorName =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.LazyListService/LazyAdministratorName',
      request,
      metadata || {},
      methodDescriptor_LazyListService_LazyAdministratorName);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.LazyAccountRequest,
 *   !proto.commonpb.LazyAdministratorEmailResponse>}
 */
const methodDescriptor_LazyListService_LazyAdministratorEmail = new grpc.web.MethodDescriptor(
  '/commonpb.LazyListService/LazyAdministratorEmail',
  grpc.web.MethodType.UNARY,
  proto.commonpb.LazyAccountRequest,
  proto.commonpb.LazyAdministratorEmailResponse,
  /**
   * @param {!proto.commonpb.LazyAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.LazyAdministratorEmailResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.commonpb.LazyAccountRequest,
 *   !proto.commonpb.LazyAdministratorEmailResponse>}
 */
const methodInfo_LazyListService_LazyAdministratorEmail = new grpc.web.AbstractClientBase.MethodInfo(
  proto.commonpb.LazyAdministratorEmailResponse,
  /**
   * @param {!proto.commonpb.LazyAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.LazyAdministratorEmailResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.LazyAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.LazyAdministratorEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.LazyAdministratorEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.LazyListServiceClient.prototype.lazyAdministratorEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.LazyListService/LazyAdministratorEmail',
      request,
      metadata || {},
      methodDescriptor_LazyListService_LazyAdministratorEmail,
      callback);
};


/**
 * @param {!proto.commonpb.LazyAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.LazyAdministratorEmailResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.LazyListServicePromiseClient.prototype.lazyAdministratorEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.LazyListService/LazyAdministratorEmail',
      request,
      metadata || {},
      methodDescriptor_LazyListService_LazyAdministratorEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.LazyAccountNoRequest,
 *   !proto.commonpb.LazyAccountResponse>}
 */
const methodDescriptor_LazyListService_LazyAccountNo = new grpc.web.MethodDescriptor(
  '/commonpb.LazyListService/LazyAccountNo',
  grpc.web.MethodType.UNARY,
  proto.commonpb.LazyAccountNoRequest,
  proto.commonpb.LazyAccountResponse,
  /**
   * @param {!proto.commonpb.LazyAccountNoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.LazyAccountResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.commonpb.LazyAccountNoRequest,
 *   !proto.commonpb.LazyAccountResponse>}
 */
const methodInfo_LazyListService_LazyAccountNo = new grpc.web.AbstractClientBase.MethodInfo(
  proto.commonpb.LazyAccountResponse,
  /**
   * @param {!proto.commonpb.LazyAccountNoRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.LazyAccountResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.LazyAccountNoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.LazyAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.LazyAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.LazyListServiceClient.prototype.lazyAccountNo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.LazyListService/LazyAccountNo',
      request,
      metadata || {},
      methodDescriptor_LazyListService_LazyAccountNo,
      callback);
};


/**
 * @param {!proto.commonpb.LazyAccountNoRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.LazyAccountResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.LazyListServicePromiseClient.prototype.lazyAccountNo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.LazyListService/LazyAccountNo',
      request,
      metadata || {},
      methodDescriptor_LazyListService_LazyAccountNo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.AccessibleRequest,
 *   !proto.commonpb.AccessibleCorrespondentResponse>}
 */
const methodDescriptor_LazyListService_AccessibleCorrespondent = new grpc.web.MethodDescriptor(
  '/commonpb.LazyListService/AccessibleCorrespondent',
  grpc.web.MethodType.UNARY,
  proto.commonpb.AccessibleRequest,
  proto.commonpb.AccessibleCorrespondentResponse,
  /**
   * @param {!proto.commonpb.AccessibleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.AccessibleCorrespondentResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.commonpb.AccessibleRequest,
 *   !proto.commonpb.AccessibleCorrespondentResponse>}
 */
const methodInfo_LazyListService_AccessibleCorrespondent = new grpc.web.AbstractClientBase.MethodInfo(
  proto.commonpb.AccessibleCorrespondentResponse,
  /**
   * @param {!proto.commonpb.AccessibleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.AccessibleCorrespondentResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.AccessibleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.AccessibleCorrespondentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.AccessibleCorrespondentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.LazyListServiceClient.prototype.accessibleCorrespondent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.LazyListService/AccessibleCorrespondent',
      request,
      metadata || {},
      methodDescriptor_LazyListService_AccessibleCorrespondent,
      callback);
};


/**
 * @param {!proto.commonpb.AccessibleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.AccessibleCorrespondentResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.LazyListServicePromiseClient.prototype.accessibleCorrespondent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.LazyListService/AccessibleCorrespondent',
      request,
      metadata || {},
      methodDescriptor_LazyListService_AccessibleCorrespondent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.AccessibleRequest,
 *   !proto.commonpb.AccessibleAccountNoResponse>}
 */
const methodDescriptor_LazyListService_AccessibleAccountNo = new grpc.web.MethodDescriptor(
  '/commonpb.LazyListService/AccessibleAccountNo',
  grpc.web.MethodType.UNARY,
  proto.commonpb.AccessibleRequest,
  proto.commonpb.AccessibleAccountNoResponse,
  /**
   * @param {!proto.commonpb.AccessibleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.AccessibleAccountNoResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.commonpb.AccessibleRequest,
 *   !proto.commonpb.AccessibleAccountNoResponse>}
 */
const methodInfo_LazyListService_AccessibleAccountNo = new grpc.web.AbstractClientBase.MethodInfo(
  proto.commonpb.AccessibleAccountNoResponse,
  /**
   * @param {!proto.commonpb.AccessibleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.AccessibleAccountNoResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.AccessibleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.AccessibleAccountNoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.AccessibleAccountNoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.LazyListServiceClient.prototype.accessibleAccountNo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.LazyListService/AccessibleAccountNo',
      request,
      metadata || {},
      methodDescriptor_LazyListService_AccessibleAccountNo,
      callback);
};


/**
 * @param {!proto.commonpb.AccessibleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.AccessibleAccountNoResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.LazyListServicePromiseClient.prototype.accessibleAccountNo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.LazyListService/AccessibleAccountNo',
      request,
      metadata || {},
      methodDescriptor_LazyListService_AccessibleAccountNo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.AccessibleRequest,
 *   !proto.commonpb.AccessibleMasterAccountNoResponse>}
 */
const methodDescriptor_LazyListService_AccessibleMasterAccountNo = new grpc.web.MethodDescriptor(
  '/commonpb.LazyListService/AccessibleMasterAccountNo',
  grpc.web.MethodType.UNARY,
  proto.commonpb.AccessibleRequest,
  proto.commonpb.AccessibleMasterAccountNoResponse,
  /**
   * @param {!proto.commonpb.AccessibleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.AccessibleMasterAccountNoResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.commonpb.AccessibleRequest,
 *   !proto.commonpb.AccessibleMasterAccountNoResponse>}
 */
const methodInfo_LazyListService_AccessibleMasterAccountNo = new grpc.web.AbstractClientBase.MethodInfo(
  proto.commonpb.AccessibleMasterAccountNoResponse,
  /**
   * @param {!proto.commonpb.AccessibleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.AccessibleMasterAccountNoResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.AccessibleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.AccessibleMasterAccountNoResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.AccessibleMasterAccountNoResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.LazyListServiceClient.prototype.accessibleMasterAccountNo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.LazyListService/AccessibleMasterAccountNo',
      request,
      metadata || {},
      methodDescriptor_LazyListService_AccessibleMasterAccountNo,
      callback);
};


/**
 * @param {!proto.commonpb.AccessibleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.AccessibleMasterAccountNoResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.LazyListServicePromiseClient.prototype.accessibleMasterAccountNo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.LazyListService/AccessibleMasterAccountNo',
      request,
      metadata || {},
      methodDescriptor_LazyListService_AccessibleMasterAccountNo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.AccessibleRequest,
 *   !proto.commonpb.AccessibleAccountNameResponse>}
 */
const methodDescriptor_LazyListService_AccessibleAccountName = new grpc.web.MethodDescriptor(
  '/commonpb.LazyListService/AccessibleAccountName',
  grpc.web.MethodType.UNARY,
  proto.commonpb.AccessibleRequest,
  proto.commonpb.AccessibleAccountNameResponse,
  /**
   * @param {!proto.commonpb.AccessibleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.AccessibleAccountNameResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.commonpb.AccessibleRequest,
 *   !proto.commonpb.AccessibleAccountNameResponse>}
 */
const methodInfo_LazyListService_AccessibleAccountName = new grpc.web.AbstractClientBase.MethodInfo(
  proto.commonpb.AccessibleAccountNameResponse,
  /**
   * @param {!proto.commonpb.AccessibleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.AccessibleAccountNameResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.AccessibleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.AccessibleAccountNameResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.AccessibleAccountNameResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.LazyListServiceClient.prototype.accessibleAccountName =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.LazyListService/AccessibleAccountName',
      request,
      metadata || {},
      methodDescriptor_LazyListService_AccessibleAccountName,
      callback);
};


/**
 * @param {!proto.commonpb.AccessibleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.AccessibleAccountNameResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.LazyListServicePromiseClient.prototype.accessibleAccountName =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.LazyListService/AccessibleAccountName',
      request,
      metadata || {},
      methodDescriptor_LazyListService_AccessibleAccountName);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.AccessibleRequest,
 *   !proto.commonpb.AccessibleRepResponse>}
 */
const methodDescriptor_LazyListService_AccessibleRep = new grpc.web.MethodDescriptor(
  '/commonpb.LazyListService/AccessibleRep',
  grpc.web.MethodType.UNARY,
  proto.commonpb.AccessibleRequest,
  proto.commonpb.AccessibleRepResponse,
  /**
   * @param {!proto.commonpb.AccessibleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.AccessibleRepResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.commonpb.AccessibleRequest,
 *   !proto.commonpb.AccessibleRepResponse>}
 */
const methodInfo_LazyListService_AccessibleRep = new grpc.web.AbstractClientBase.MethodInfo(
  proto.commonpb.AccessibleRepResponse,
  /**
   * @param {!proto.commonpb.AccessibleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.AccessibleRepResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.AccessibleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.AccessibleRepResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.AccessibleRepResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.LazyListServiceClient.prototype.accessibleRep =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.LazyListService/AccessibleRep',
      request,
      metadata || {},
      methodDescriptor_LazyListService_AccessibleRep,
      callback);
};


/**
 * @param {!proto.commonpb.AccessibleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.AccessibleRepResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.LazyListServicePromiseClient.prototype.accessibleRep =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.LazyListService/AccessibleRep',
      request,
      metadata || {},
      methodDescriptor_LazyListService_AccessibleRep);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.AccessibleRequest,
 *   !proto.commonpb.AccessibleBranchResponse>}
 */
const methodDescriptor_LazyListService_AccessibleBranch = new grpc.web.MethodDescriptor(
  '/commonpb.LazyListService/AccessibleBranch',
  grpc.web.MethodType.UNARY,
  proto.commonpb.AccessibleRequest,
  proto.commonpb.AccessibleBranchResponse,
  /**
   * @param {!proto.commonpb.AccessibleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.AccessibleBranchResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.commonpb.AccessibleRequest,
 *   !proto.commonpb.AccessibleBranchResponse>}
 */
const methodInfo_LazyListService_AccessibleBranch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.commonpb.AccessibleBranchResponse,
  /**
   * @param {!proto.commonpb.AccessibleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.AccessibleBranchResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.AccessibleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.AccessibleBranchResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.AccessibleBranchResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.LazyListServiceClient.prototype.accessibleBranch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.LazyListService/AccessibleBranch',
      request,
      metadata || {},
      methodDescriptor_LazyListService_AccessibleBranch,
      callback);
};


/**
 * @param {!proto.commonpb.AccessibleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.AccessibleBranchResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.LazyListServicePromiseClient.prototype.accessibleBranch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.LazyListService/AccessibleBranch',
      request,
      metadata || {},
      methodDescriptor_LazyListService_AccessibleBranch);
};


module.exports = proto.commonpb;

