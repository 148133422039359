import {
  ExerciseDeclarationServiceClient,
  ExerciseDeclaration,
  ListExerciseDeclarationRequest,
} from '../proto/reportpb/exercisedeclaration_grpc_web_pb';
import {notifyError,} from '../components/Messages/Notification'
import { auth } from '../lib/auth/Auth';
import { stringToProtoDate } from './ConvertService';

const service = new ExerciseDeclarationServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

const requestParameter = (param) => {
  let req = new ExerciseDeclaration();
  req.setStatus(param.status);
  req.setQty(param.qty);
  req.setSystemDate(stringToProtoDate(param.systemDate));
  req.setAccountId(param.accountId);
  req.setSymbol(param.symbol);
  req.setCorrespondent(param.correspondent);
  req.setOriginalCusuip(param.originalCusip);
  return req;
};

export async function updateExerciseDeclaration(param) {
  return new Promise((resolve, reject) => {
    const req = requestParameter(param);
    service.updateExerciseDeclaration(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listExerciseDeclaration(param) {
  return new Promise((resolve, reject) => {
    const req = new ListExerciseDeclarationRequest();
    req.setDateType(param.dateType);
    req.setFromDate(stringToProtoDate(param.fromDate));
    req.setToDate(stringToProtoDate(param.toDate));
    req.setAccountNo(param.accountNo);
    req.setCorrespondent(param.correspondent);
    req.setSymbol(param.symbol);
    req.setType(param.type);
    req.setAccountName(param.accountName);
    req.setMasterAccountNo(param.masterAccountNo);
    req.setBranch(param.branch);
    req.setRep(param.rep);
    req.setBroker(param.broker);
    req.setStatus(param.status);
    service.listExerciseDeclaration(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}