/*ReactJS*/
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Typography, Modal, Backdrop, Fade, Box, TextField } from '@material-ui/core';
import SelectTaxCountry from '../Dropdown/SelectTaxCountry';
import SelectSystemCode from '../Dropdown/SelectSystemCode';
import { SelectState, SelectStateCA } from '../AutoComplete/SelectSystemCode';
import ImageDropZone from '../Misc/ImageDropZone';
import AutoCompletePlace from '../AutoComplete/AutoCompletePlace';
import Button from '../Buttons/Button';

import Text from '../Typography/Text';

import fontFamily from '../../assets/css/fonts';
import color from '../../assets/css/colors';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 550,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  fadeModalFull: {
    maxWidth: 1400,
    minWidth: 900,
    maxHeight: '90vh',
    width: '100%',
    padding: '25px 30px 30px 30px',
    margin: '0px auto',
    backgroundColor: '#ffffff',
    border: 'none',
    borderRadius: 5,
    boxSizing: 'border-box',
    outline: 'none',
    overflowY: 'auto',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
  accordionDetails: {
    display: 'block !important',
    padding: '0px 20px 25px 20px !important',
  },
  accordion: {
    borderRadius: '5px !important',
    boxShadow: '0px 1px 5px -2px #cdcfdd !important',
    border: '1px solid #efeff0 !important',
  },
  tabBody: {
    minWidth: '100%',
    padding: '0px 6px',
  },
}));

export default function AddressModal({
  onClose: handleClose,
  open: isOpen,
  value,
  validation,
  importMode: isImport,
  loading,
}) {
  const classes = useStyles();
  const [modalData, setModalData] = useState({});
  const [isEdit, setIsEdit] = React.useState(false);
  const [initialFile, setInitialFile] = useState({});

  const setupData = async (value) => {
    if (value.imgProofOfAddress || value.imgProofOfAddress !== '') {
      await fetch(value.imgProofOfAddress)
        .then(function (res) {
          return res.arrayBuffer();
        })
        .then(function (buf) {
          const file = new File([buf], 'image_data.jpg', {
            type: 'image/*',
          });
          setInitialFile(file);
        });
    } else {
      setInitialFile({});
    }

    setModalData({ ...value });
  };

  useEffect(() => {
    if (isOpen) {
      setupData(value);
      if (value.addressId) {
        setIsEdit(true);
      }
    }
  }, [isOpen, value]);

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    if (input.name === 'state') {
      modalData.country = [''].includes(input.value) ? '' : 'US';
    }

    setModalData({
      ...modalData,
      [input.name]: input.value,
    });
  };

  const setAddressData = (name, address) => {
    const { address1, address2, state, city, zipCode, country } = address;

    setModalData({
      ...modalData,
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      zip: zipCode,
      country: country,
    });
  };

  const handleUploadedPhotoStatus = ({ meta, file }, status) => {
    if (status === 'done') {
      const reader = new FileReader();

      reader.addEventListener(
        'load',
        function () {
          setModalData({ ...modalData, imgProofOfAddress: reader.result });
        },
        false
      );

      reader.readAsDataURL(file);
    }
  };
  const handleUploadPhoto = (files) => {
    console.log(files.map((f) => f.meta));
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={() => handleClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalFull} style={{ overflow: 'unset' }}>
          <Text id="transition-modal-title" variant="h2" label={'Add New Address'} />
          <Box mt={5}>
            <form className={classes.modalForm} noValidate autoComplete="off">
              <div className="grd-row">
                {!isImport ? (
                  <div className="grd-cell-none" style={{ marginRight: 20 }}>
                    <ImageDropZone
                      error={validation.imgProofOfAddress}
                      label="Proof of Address"
                      onChangeStatus={handleUploadedPhotoStatus}
                      onSubmit={handleUploadPhoto}
                      initialFiles={[initialFile]}
                    />
                  </div>
                ) : null}
                <div className="grd-cell">
                  <div className="grd-row">
                    <div className="grd-cell" style={{ marginRight: 30 }}>
                      <SelectSystemCode
                        error={validation.addressType}
                        required={true}
                        name="addressType"
                        label="Address Type"
                        type="Address Type"
                        value={modalData.addressType}
                        onChange={handleChange}
                      ></SelectSystemCode>
                    </div>
                    <div className="grd-cell">
                      <AutoCompletePlace
                        required={true}
                        error={validation.address1}
                        max={100}
                        name="address1"
                        label="Address 1"
                        value={modalData.address1 || ''}
                        onChange={handleChange}
                        setAddressData={setAddressData}
                      />
                    </div>
                  </div>
                  <div className="grd-row">
                    <div className="grd-cell" style={{ marginRight: 30 }}>
                      <TextField
                        fullWidth
                        name="address2"
                        label="Address 2"
                        value={modalData.address2 || ''}
                        onChange={handleChange}
                        inputProps={{ maxLength: 300 }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                    <div className="grd-cell">
                      <TextField
                        fullWidth
                        name="address3"
                        label="Address 3"
                        value={modalData.address3 || ''}
                        onChange={handleChange}
                        inputProps={{ maxLength: 300 }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </div>
                  <div className="grd-row">
                    <div className="grd-cell" style={{ marginRight: 30 }}>
                      <TextField
                        fullWidth
                        name="address4"
                        label="Address 4"
                        value={modalData.address4 || ''}
                        onChange={handleChange}
                        inputProps={{ maxLength: 300 }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                    <div className="grd-cell">
                      <TextField
                        error={validation.city}
                        fullWidth
                        required={true}
                        name="city"
                        label="City"
                        value={modalData.city || ''}
                        onChange={handleChange}
                        inputProps={{ maxLength: 50 }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </div>
                  <div className="grd-row">
                    <div className="grd-cell" style={{ marginRight: 30 }}>
                      {modalData.country === 'CA' && (
                        <SelectStateCA
                          error={validation.state}
                          freeSolo={true}
                          required={
                            modalData.country === 'US' || modalData.country === 'CA'
                          }
                          name="state"
                          label="State"
                          value={modalData.state}
                          onChange={handleChange}
                        />
                      )}
                      {modalData.country !== 'CA' && (
                        <SelectState
                          error={validation.state}
                          freeSolo={true}
                          required={
                            modalData.country === 'US' || modalData.country === 'CA'
                          }
                          name="state"
                          label="State"
                          value={modalData.state}
                          onChange={handleChange}
                        />
                      )}
                    </div>
                    <div className="grd-cell">
                      <TextField
                        fullWidth
                        error={validation.zip}
                        name="zip"
                        label="Zip"
                        required={true}
                        value={modalData.zip || ''}
                        onChange={handleChange}
                        inputProps={{ maxLength: 10 }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </div>
                  <div className="grd-row">
                    <div className="grd-cell" style={{ marginRight: 30 }}>
                      <SelectTaxCountry
                        error={validation.country}
                        required={true}
                        name="country"
                        label="Country"
                        value={modalData.country || ''}
                        key={modalData.country}
                        onChange={(selected) => {
                          setModalData({
                            ...modalData,
                            country: selected?.code,
                          });
                        }}
                      ></SelectTaxCountry>
                    </div>
                    <div className="grd-cell">
                      <SelectSystemCode
                        error={validation.status}
                        required={true}
                        name="status"
                        label="Status"
                        type="Status"
                        subType="Client Setup"
                        value={modalData.status || ''}
                        onChange={handleChange}
                      ></SelectSystemCode>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.modalFooter}>
                <div className="grd-row">
                  <div className="grd-cell-none" style={{ marginRight: 10 }}>
                    <Button
                      disabled={loading}
                      color="primary"
                      label="Close"
                      onClick={() => {
                        handleClose();
                      }}
                    />
                  </div>
                  <div className="grd-cell-none">
                    <Button
                      disabled={loading}
                      loading={loading}
                      color="secondary"
                      label={loading ? 'Saving...' : 'Save'}
                      onClick={() => {
                        handleClose(modalData, isEdit);
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
