import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { LayoutContext } from './Root';
import { alpha, makeStyles } from '@material-ui/core/styles';
import axosDigital from '../../assets/images/axos-digital.png';
import SearchIcon from '@material-ui/icons/Search';
import APWTextField from '../Forms/APWTextField';
import TopBarMenu from '../Misc/TopBar/TopBarMenu';
import { getBrandingLogoURL } from "../../services/AdministratorService";

const styles = (theme) => ({
  root: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginLeft: -8,
    marginRight: 8,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    width: '30%',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
        paddingLeft: `calc(1em + ${theme.spacing(8)}px)`, // Adjust the padding for focus
      },
    },
  },


  firmLogo: {
    marginLeft: theme.spacing(2),
    width: '150px',
    height: '50px',
    objectFit: 'cover',
  },

  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '69px',
  },
});

const getBrandLogo = async () => {
  try {
      const res = await getBrandingLogoURL();
      if (res.url === '') {
          return axosDigital;
      } 
      else {
          return res.url;
      }
  }
  catch (error) {
    console.log(error);
    return axosDigital;
  }
};

const createGet = (
  { clipped, navVariant, collapsible, collapsed, open, squeezed, navAnchor },
  normal,
  shrink,
  pushed,
  unsqueeze,
) => () => {
  if (clipped || navAnchor !== 'left') return normal;
  if (navVariant === 'persistent' && open) {
    // open is effect only when
    // navVariant === 'persistent' ||
    // navVariant === 'temporary'
    if (squeezed) {
      return pushed;
    }
    return unsqueeze;
  }
  if (navVariant === 'permanent') {
    if (collapsible) {
      if (collapsed) return shrink;
      return pushed;
    }
    return pushed;
  }
  return normal;
};

const Header = ({
  className,
  component: Component,
  classes,
  menuIcon,
  style,
  theme,
  children,
  toolbarProps,
  ...props
}) => {
  const ctx = useContext(LayoutContext);
  const {
    clipped,
    collapsedWidth,
    navWidth,
    navVariant,
    headerPosition,
    open,
    setOpen,
  } = ctx;
  const getWidth = createGet(
    ctx,
    '100%',
    `calc(100% - ${collapsedWidth}px)`,
    `calc(100% - ${navWidth}px)`,
    '100%',
  );
  const getMargin = createGet(ctx, 0, collapsedWidth, navWidth, navWidth);
  const shouldRenderMenu = navVariant !== 'permanent' && !!menuIcon;
  const [search, setSearch] = React.useState("");
  const [logo, setLogo] = React.useState(""); 
  React.useEffect(() => {
    getBrandLogo()
    .then((data) => setLogo(data))
  }, []);
  return (
    <Fragment>
      <AppBar
        color={'inherit'}
        elevation={0}
        {...props}
        className={`${className} ${classes.root}`}
        position={headerPosition}
        style={{
          ...style,
          zIndex: clipped ? theme.zIndex.drawer + 1 : theme.zIndex.appBar,
          width: getWidth(),
          marginLeft: getMargin(),
        }}
      >
        <Toolbar {...toolbarProps}>
          {shouldRenderMenu && (
            <IconButton onClick={setOpen} className={classes.menuButton}>
              {open ? menuIcon.active : menuIcon.inactive || menuIcon.active}
            </IconButton>
          )}
 
          <div className={classes.logoContainer}>
          <img id="logo" src={logo} alt="Logo" className={classes.firmLogo}/>
          </div>

          <div className={classes.search}>
            <APWTextField
            fullWidth
             id="top-bar-search" 
             label="Search"
             name="search"
             startIcon={<SearchIcon fontSize="small"/>}
             value={search}
             placeholder='Search Account Name or Account Number'
             onChange={(name, value) => {
              setSearch(value);
          }}
             />
          </div>
          <div className={classes.grow} />
          <TopBarMenu />
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({}).isRequired,
  component: PropTypes.elementType,
  style: PropTypes.shape({}),
  position: PropTypes.string,
  theme: PropTypes.shape({}).isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  toolbarProps: PropTypes.shape({}),
  menuIcon: PropTypes.shape({
    inactive: PropTypes.node.isRequired,
    active: PropTypes.node,
  }),
};
Header.defaultProps = {
  className: '',
  component: 'div',
  style: {},
  position: 'relative',
  toolbarProps: {},
  menuIcon: null,
};

export default withTheme(withStyles(styles, { name: 'MuiHeader' })(Header));
