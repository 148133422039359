import React from 'react'
import Button from '../../../components/Buttons/Button'
import SelectSystemCode from '../../../components/Dropdown/SelectSystemCode'
import Text from '../../../components/Typography/Text'
import TextField from '../../../components/Textfields/TextField'
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from '../../../components/Messages/Notification'
import QueryParam from '../../../services/QueryParamService'
import SelectAnnouncementType from '../../../components/Dropdown/SelectAnnouncementType'
import AutoCompleteSymbol from '../../../components/AutoComplete/AutoCompleteSymbol'
import SelectCusip from '../../../components/AutoComplete/SelectCusip'
import DividendTable from './Components/DividendTable'
import { createDividend, listDividend, listMerger, listSpinOff, listSplit } from '../../../services/ReorgAnnouncement'
import DividendModal from './Components/DividendModal'
import MergerModal from './Components/MergerModal'
import MergerTable from './Components/MergerTable'
import { getSystemDate } from '../../../services/ProfileService'
import SpinOffTable from './Components/SpinOffTable'
import SpinOffModal from './Components/SpinOffModal'
import SplitModal from './Components/SplitModal'
import SplitTable from './Components/SplitTable'
import { formatPbDate, formatPbDateTime } from '../../../lib/fmt';

export default function ReorgAnnouncement() {
  const [rows, setRows] = React.useState([])
  const [announcementType, setAnnouncementType] = React.useState('dividend')
  const [open, setOpen] = React.useState({
    add: false
  })
  const [loading, setLoading] = React.useState({
    search: false,
    save: false,
  })
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
        announcementType: 'dividend',
        dateType: 'Process Date',
        fromSystemDate: '',
        toSystemDate: '',
        symbol: '',
        cusip: '',
        status: 'Active',
    }),
  )

  React.useEffect(() => {
    searchReset('dividend')
  }, [])

  const searchReset = async (type) => {
      QueryParam.set({
          announcementType: type,
          dateType: 'Process Date',
          fromSystemDate: await getSystemDate(),
          toSystemDate: await getSystemDate(),
          symbol: '',
          cusip: '',
          status: 'Active',
      })

      setRows([])
      setAnnouncementType(type)
  }

  const handleSearchDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target

    if (input.name == 'announcementType' && input.value != announcementType){
      searchReset(input.value);
    }

    setSearchData({ ...searchData, [input.name]: input.value })
  }

  const handleOpen = () => {
    setOpen({
        add: true
    })
  }

  const handleClose = async (data) => {
    if (!data) {
        setOpen({
            add: false
        })
        return;
    }

    setLoading({
        ...loading,
        save: true
    })
    try {
        const {dividend} = await createDividend(data)

        notifySuccess("New Dividend has been created");
    } catch (error){
        console.error(error);
        notifyError(error.message)
    }
      
    setOpen({
        add: false
    })  
    setLoading({
        ...loading,
        save: false
    })
  }

  const getTable = () => {
    if (announcementType == 'dividend'){
      return (
        <DividendTable
          newRows={rows}
          searchData={searchData}
          announcementType={announcementType}
          loading={loading.search}
        />
      )
    } else if (announcementType == 'merger'){
      return (
        <MergerTable
          newRows={rows}
          searchData={searchData}
          announcementType={announcementType}
          loading={loading.search}
        />
      )
    } else if (announcementType == 'spin_off'){
      return (
        <SpinOffTable
          newRows={rows}  
          searchData={searchData}
          announcementType={announcementType}
          loading={loading.search}
        />
      )
    } else if (announcementType == 'split'){
      return (
        <SplitTable
          newRows={rows}
          searchData={searchData}
          announcementType={announcementType}
          loading={loading.search}
        />
      )
    } 
  }

  const getModal = () => {
    if (!open.add){
      return
    }

    if (announcementType == 'dividend'){
      return (
        <DividendModal
            open={open.add && announcementType == 'dividend'}
            close={handleClose}
            isEdit={false}
            loading={loading.save}
        />
      )
    } else if (announcementType == 'merger'){
      return (
        <MergerModal
            open={open.add && announcementType == 'merger'}
            close={handleClose}
            isEdit={false}
            loading={loading.save}
        />
      )
    } else if (announcementType == 'spin_off'){
      return (
        <SpinOffModal
            open={open.add && announcementType == 'spin_off'}
            close={handleClose}
            isEdit={false}
            loading={loading.save}
        />
      )
    } else if (announcementType == 'split'){
      return (
        <SplitModal
            open={open.add && announcementType == 'split'}
            close={handleClose}
            isEdit={false}
            loading={loading.save}
        />
      )
    } 
  }

  const handleSearch = async () => {
    try {
      setLoading({ ...loading, search: true })

      QueryParam.set(searchData)

      let data
      let length
      if (announcementType == 'dividend'){
        data = await listDividend(searchData)
        console.log(JSON.stringify(data.dividendsList))

        let rowsCopy = data.dividendsList;

        const rowsCopyOutput = rowsCopy.map((data) => {
          let tempDate = formatPbDate(data.expirationDate);
          data.expirationDate = tempDate

          let tempDate2 = formatPbDate(data.payableDate);
          data.payableDate = tempDate2

          let tempDate3 = formatPbDate(data.positionDate);
          data.positionDate = tempDate3

          let tempDate4 = formatPbDate(data.processDate);
          data.processDate = tempDate4

          let tempDate5 = formatPbDate(data.recordDate);
          data.recordDate = tempDate5

          let tempDate6 = formatPbDate(data.systemDate);
          data.systemDate = tempDate6

          let tempDate7 = formatPbDate(data.declarationDate);
          data.declarationDate = tempDate7

          return data;
        })

        console.log(rowsCopyOutput)

        setRows(rowsCopyOutput)
        length = data.dividendsList.length
      } else if (announcementType == 'merger'){
        data = await listMerger(searchData)

        let rowsCopy = data.mergersList;

        const rowsCopyOutput = rowsCopy.map((data) => {
          let tempDate1 = formatPbDate(data.effectiveDate);
          data.effectiveDate = tempDate1

          let tempDate2 = formatPbDate(data.positionDate);
          data.positionDate = tempDate2

          let tempDate3 = formatPbDate(data.processDate);
          data.processDate = tempDate3

          let tempDate4 = formatPbDate(data.recordDate);
          data.recordDate = tempDate4

          let tempDate5 = formatPbDate(data.systemDate);
          data.systemDate = tempDate5

          return data;
        })

        setRows(rowsCopyOutput)
        length = data.mergersList.length
      } else if (announcementType == 'spin_off'){
        data = await listSpinOff(searchData)

        let rowsCopy = data.spinOffsList;

        const rowsCopyOutput = rowsCopy.map((data) => {
          let tempDate1 = formatPbDate(data.expirationDate);
          data.expirationDate = tempDate1

          let tempDate2 = formatPbDate(data.positionDate);
          data.positionDate = tempDate2

          let tempDate3 = formatPbDate(data.processDate);
          data.processDate = tempDate3

          let tempDate4 = formatPbDate(data.systemDate);
          data.systemDate = tempDate4

          return data;
        })
        setRows(rowsCopyOutput)
        length = data.spinOffsList.length
      } else if (announcementType == 'split'){
        data = await listSplit(searchData)

        let rowsCopy = data.splitsList;

        const rowsCopyOutput = rowsCopy.map((data) => {
          let tempDate1 = formatPbDate(data.expirationDate);
          data.expirationDate = tempDate1

          let tempDate2 = formatPbDate(data.positionDate);
          data.positionDate = tempDate2

          let tempDate3 = formatPbDate(data.processDate);
          data.processDate = tempDate3

          let tempDate4 = formatPbDate(data.recordDate);
          data.recordDate = tempDate4

          let tempDate5 = formatPbDate(data.systemDate);
          data.systemDate = tempDate5

          return data;
        })
        setRows(rowsCopyOutput)
        length = data.splitsList.length
      } 


      notifyInfo(
        length > 0
          ? length + ' search result(s)'
          : 'No records found.',
      )
    } catch (error) {
      notifyError(error.message)
      console.error(error)
      return
    } finally {
      setLoading({ ...loading, search: false })
    }
  }

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Announcement" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading.search}
              loading={loading.search}
              type="search"
              label={loading.search ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
          {/* <div className="grd-cell-none">
            <Button
              type="plus"
              label="Add New"
              onClick={() => handleOpen()}
            />
          </div> */}
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <SelectAnnouncementType
              name="announcementType"
              label="Announcement Type"
              value={searchData.announcementType}
              onChange={handleSearchDataChange}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <SelectSystemCode
                name="dateType"
                label="Date Type"
                type="Date Type Announcement"
                value={searchData.dateType}
                onChange={handleSearchDataChange}
            ></SelectSystemCode>
          </div>
          <div className="grd-cell">
            <TextField
                fullWidth
                name="fromSystemDate"
                label="From System Date"
                type="date"
                value={searchData.fromSystemDate}
                onChange={handleSearchDataChange}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                    max: searchData.toSystemDate,
                }}
            />
          </div>
          <div className="grd-cell">
            <TextField
                fullWidth
                name="toSystemDate"
                label="To System Date"
                type="date"
                value={searchData.toSystemDate}
                onChange={handleSearchDataChange}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                min: searchData.fromSystemDate,
                }}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteSymbol
              name="symbol"
              label="Symbol"
              value={searchData.symbol}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <SelectCusip
              name="cusip"
              label="Cusip"
              value={searchData.cusip}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <SelectSystemCode
              name="status"
              label="Status"
              placeholder="Status"
              type="Status"
              subType="AI"
              value={searchData.status}
              onChange={handleSearchDataChange}
            />
          </div>
        </div>
      </div>
      {getTable()}
      {getModal()}
    </div>
  )
}
