import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import color from '../../../../assets/css/colors';
import fontFamily from '../../../../assets/css/fonts';
import {
  Modal,
  Backdrop,
  Fade,
  Box,
} from '@material-ui/core';
import Button from '../../../../components/Buttons/Button';
import {
  pbDate,
  pbDateSorter,
  boolean,
  qty,
  currency,
  percentage,
} from '../../../../components/ClientGrid/CellRenderer';
import ClientGrid from '../../../../components/ClientGrid/ClientGrid';
import Text from '../../../../components/Typography/Text';
import { listRequirementDetail } from '../../../../services/MarginRequirementService'

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  fadeModalFull: {
    maxWidth: '100%',
    minWidth: 1400,
    maxHeight: '90vh',
    width: '100%',
    padding: '25px 30px 30px 30px',
    margin: '0px auto',
    backgroundColor: '#ffffff',
    border: 'none',
    borderRadius: 5,
    boxShadow: '0px 0px 10px #333333',
    boxSizing: 'border-box',
    outline: 'none',
    overflowY: 'auto',
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
}));

export default function CallLogDetailsModal({
  onClose: handleClose,
  open: isOpen,
  value,
}) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const getRequirementDetails = async () => {
      try {
        setLoading(true)
        const data = await listRequirementDetail(value);
        setRows(data.requirementDetailsList);
      } catch (error) {
        console.error(error);
      }
      setLoading(false)
    }
      getRequirementDetails()
  }, [isOpen]);

  const columns = [
    {
      id: 'symbol',
      field: 'symbol',
      label: 'Symbol',
    },
    {
      id: 'cusip',
      field: 'cusip',
      label: 'Cusip',
    },
    {
      id: 'assetType',
      field: 'assetType',
      label: 'Asset Type',
    },
    {
      id: 'tradeDate',
      field: 'tradeDate',
      label: 'Trade Date',
      cellRenderer: pbDate,
      sort: pbDateSorter,
      visible: false,
    },
    {
      id: 'masterAccountNo',
      field: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      id: 'accountName',
      field: 'accountName',
      label: 'Account Name',
    },
    {
      id: 'originalCusip',
      field: 'originalCusip',
      label: 'Original Cusip',
      visible: false,
    },
    {
      id: 'isMarginable',
      field: 'isMarginable',
      label: 'Marginable',
      visible: false,
      cellRenderer: boolean,
    },
    {
      id: 'leveragedFactor',
      field: 'leveragedFactor',
      label: 'Leveraged Factor',
      cellRenderer: qty,
    },
    {
      id: 'closingPrice',
      field: 'closingPrice',
      label: 'Closing Price',
      cellRenderer: currency,
    },
    {
      id: 'longQty',
      field: 'longQty',
      label: 'Long Qty',
      cellRenderer: qty,
    },
    {
      id: 'shortQty',
      field: 'shortQty',
      label: 'Short Qty',
      cellRenderer: qty,
    },
    {
      id: 'previousQty',
      field: 'previousQty',
      label: 'Previous Qty',
      cellRenderer: qty,
    },
    {
      id: 'longMarketValue',
      field: 'longMarketValue',
      label: 'Long Market Value',
      cellRenderer: currency,
    },
    {
      id: 'shortMarketValue',
      field: 'shortMarketValue',
      label: 'Short Market Value',
      cellRenderer: currency,
    },
    {
      id: 'marketValue',
      field: 'marketValue',
      label: 'Market Value',
      cellRenderer: currency,
    },
    {
      id: 'previousMarketValue',
      field: 'previousMarketValue',
      label: 'Previous Market Value',
      cellRenderer: currency,
    },
    {
      id: 'exchangeReq',
      field: 'exchangeReq',
      label: 'Exchange Req',
      cellRenderer: currency,
    },
    {
      id: 'exchangeRate',
      field: 'exchangeRate',
      label: 'Exchange Rate',
      cellRenderer: percentage,
    },
    {
      id: 'houseReq',
      field: 'houseReq',
      label: 'House Req',
      cellRenderer: currency,
    },
    {
      id: 'houseRate',
      field: 'houseRate',
      label: 'House Rate',
      cellRenderer: percentage,
    },
    {
      id: 'fedReq',
      field: 'fedReq',
      label: 'Fed Req',
      cellRenderer: currency,
    },
    {
      id: 'fedRate',
      field: 'fedRate',
      label: 'Fed Rate',
      cellRenderer: percentage,
    },
  ];

  const getCsvData = async () => {
    return rows;
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalFull}>
          <div className={classes.modalHeader}>
            <div>
              <Text variant="h2" label="Margin Requirement Details" />
            </div>
          </div>
          <Box>
            <form noValidate autoComplete="off">
              <div className="mt-20">
                <ClientGrid
                  title={'Margin Requirement Details'}
                  rows={rows}
                  setRows={setRows}
                  columns={columns}
                  getCsvData={getCsvData}
                  loading={loading}
                  setLoading={setLoading}
                />
              </div>
              <div className={classes.modalFooter}>
                <div className="grd-cell-none" style={{ marginRight: 10, marginTop: 20 }}>
                  <Button
                    color="primary"
                    label="Close"
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
