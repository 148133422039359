import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import Text from '../Typography/Text';
import TextField from '../Textfields/TextField';
import Button from '../Buttons/Button';
import CloseButton from '../Buttons/CloseButton';
import fontFamily from '../../assets/css/fonts';
import color from '../../assets/css/colors';
import { notifySuccess, notifyError } from '../Messages/Notification';

import FileAttachments from '../Misc/FileAttachments';

import authSvc from '../../services/AuthService';
import { attachFiles, listPage } from '../../services/CommonService';
import { createContactUs } from '../../services/ContactUsService';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 550,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
}));

export default function ContactAddUs(props) {
  const classes = useStyles();
  const UsrID = authSvc.getCurrentUser()?.UserId;
  const { open, close } = props;

  const [loading, setLoading] = React.useState(false);
  const [attachments, setAttachments] = React.useState([]);

  const [data, setData] = React.useState({
    subject: '',
    message: '',
    usrId: UsrID,
    pageId: 0,
  });

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;
    setData({ ...data, [input.name]: input.value });
  };

  const handleSubmit = async () => {
    if (data.message === '') {
      notifyError('Message is required.');
      return;
    }

    try {
      setLoading(true);
      setLoading(false);
      const createContactUsRes = await createContactUs(data);
      if (attachments.length > 0) {
        await attachFiles(createContactUsRes.contactUsId, 'ContactUs', attachments);
      }
      notifySuccess('Your feedback/report has been sent successfully!');
      handleClose();
    } catch (error) {
      notifyError(error);
      setLoading(false);
      return;
    }
  };

  const handleClose = () => {
    setData({
      ...data,
      subject: '',
      message: '',
    });

    close();
  };

  const handleAttachFile = async (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    setAttachments(e.target.files);
  };

  React.useEffect(() => {
    if (open) {
      const getPageId = async () => {
        var { pagesList } = await listPage({
          key: '',
          limit: 300,
        });

        const pageName = window.location.pathname.split('/')[2].replaceAll('-', ' ');
        const params = pagesList
          .filter(function (v) {
            return v.menu === 'Client Site' &&
              v.pageName.toUpperCase() === pageName.toUpperCase()
              ? v.pageId
              : 0;
          })
          .map((v) => ({
            pageId: v.pageId,
          }));

        const pageId = params[0]?.pageId ? params[0]?.pageId : 0;

        setData({ ...data, pageId: pageId });
      };

      getPageId();
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div style={{ width: 500 }}>
          <div className={classes.modalHeader}>
            <div>
              <Text mt={4} variant="h2" label="Contact Us" />
            </div>
            <div className={classes.closeButton}>
              <CloseButton onClick={handleClose} />
            </div>
          </div>
          <div className={classes.modalBody}>
            <form autoComplete="off">
              <TextField
                mt={true}
                max={50}
                name="subject"
                label="Subject"
                placeholder="Subject"
                type="text"
                value={data.subject}
                onChange={handleChange}
              />
              <TextField
                mt={true}
                max={300}
                showIcon={false}
                name="message"
                label="Message"
                placeholder="Message..."
                type="text"
                multiline={true}
                required={true}
                rows={5}
                value={data.message}
                onChange={handleChange}
              />
              <FileAttachments
                title=""
                attachments={attachments}
                onChange={handleAttachFile}
                setAttachments={setAttachments}
                allowVideo={true}
              />
            </form>
          </div>
          <div className={classes.modalFooter}>
            <Button
              disabled={loading}
              loading={loading}
              label={loading ? 'Submiting...' : 'Submit'}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
