import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import color from '../../assets/css/colors';
import fontFamily from '../../assets/css/fonts';
import Text from '../Typography/Text';

export default function CircularPercentProgress(props) {
  const useStyles = makeStyles(() => ({
    circularProgressWrapper: {
      display: 'inline-flex',
      position: 'relative',
      '& div:nth-child(1)': {
        color: props.value === 101 ? color.success.main : color.primary.main,
        position: 'absolute',
        zIndex: 2,
      },
      '& div:nth-child(2)': {
        color: color.gray.opacity,
      }
    },
    circularProgress: {
      width: '50px !important',
      height: '50px !important',
    },
    progressLabel: {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& h6': {
        fontSize: 12,
        fontFamily: fontFamily.headers,
      },
      '& svg': {
        width: 22,
        height: 22,
        '& path': {
          fill: color.success.main,
        }
      }
    }
  }));

  const classes = useStyles();

  return (
    <div className={classes.circularProgressWrapper}>
      <CircularProgress 
        className={classes.circularProgress} 
        variant="determinate" 
        thickness={4.5}
        value={props.accountType === 'C' ? props.value >= 85 ? 100 : props.value : props.value}
      />
      <CircularProgress 
        className={classes.circularProgress} 
        variant="determinate" 
        thickness={4.5}
        value={100}
      />
      <div className={classes.progressLabel}><Text variant="subtitle1" label={`${Math.round(props.accountType === 'C' ? props.value >= 85 ? 100 : props.value : props.value)}%`} /></div>
    </div>
  );
}