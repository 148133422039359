// source: proto/bankpb/walletaddressbook.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.bankpb.CreateWalletAddressBookResponse', null, global);
goog.exportSymbol('proto.bankpb.DeleteWalletAddressBookRequest', null, global);
goog.exportSymbol('proto.bankpb.DeleteWalletAddressBookResponse', null, global);
goog.exportSymbol('proto.bankpb.ListWalletAddressBookRequest', null, global);
goog.exportSymbol('proto.bankpb.ListWalletAddressBookResponse', null, global);
goog.exportSymbol('proto.bankpb.UpdateWalletAddressBookResponse', null, global);
goog.exportSymbol('proto.bankpb.WalletAddressBook', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.WalletAddressBook = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bankpb.WalletAddressBook, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.WalletAddressBook.displayName = 'proto.bankpb.WalletAddressBook';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.CreateWalletAddressBookResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bankpb.CreateWalletAddressBookResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.CreateWalletAddressBookResponse.displayName = 'proto.bankpb.CreateWalletAddressBookResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.UpdateWalletAddressBookResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bankpb.UpdateWalletAddressBookResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.UpdateWalletAddressBookResponse.displayName = 'proto.bankpb.UpdateWalletAddressBookResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.ListWalletAddressBookRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bankpb.ListWalletAddressBookRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.ListWalletAddressBookRequest.displayName = 'proto.bankpb.ListWalletAddressBookRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.ListWalletAddressBookResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.bankpb.ListWalletAddressBookResponse.repeatedFields_, null);
};
goog.inherits(proto.bankpb.ListWalletAddressBookResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.ListWalletAddressBookResponse.displayName = 'proto.bankpb.ListWalletAddressBookResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.DeleteWalletAddressBookRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bankpb.DeleteWalletAddressBookRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.DeleteWalletAddressBookRequest.displayName = 'proto.bankpb.DeleteWalletAddressBookRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.DeleteWalletAddressBookResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bankpb.DeleteWalletAddressBookResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.DeleteWalletAddressBookResponse.displayName = 'proto.bankpb.DeleteWalletAddressBookResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.WalletAddressBook.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.WalletAddressBook.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.WalletAddressBook} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.WalletAddressBook.toObject = function(includeInstance, msg) {
  var f, obj = {
    walletAddressBookId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    nickName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    accountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 5, ""),
    walletId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    contraWalletId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    status: jspb.Message.getFieldWithDefault(msg, 8, ""),
    recurrenceAmount: jspb.Message.getFieldWithDefault(msg, 9, ""),
    recurring: jspb.Message.getFieldWithDefault(msg, 10, ""),
    verified: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.WalletAddressBook}
 */
proto.bankpb.WalletAddressBook.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.WalletAddressBook;
  return proto.bankpb.WalletAddressBook.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.WalletAddressBook} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.WalletAddressBook}
 */
proto.bankpb.WalletAddressBook.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWalletAddressBookId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWalletId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setContraWalletId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecurrenceAmount(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecurring(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVerified(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.WalletAddressBook.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.WalletAddressBook.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.WalletAddressBook} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.WalletAddressBook.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWalletAddressBookId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getNickName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getWalletId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getContraWalletId();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRecurrenceAmount();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRecurring();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getVerified();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional uint32 wallet_address_book_id = 1;
 * @return {number}
 */
proto.bankpb.WalletAddressBook.prototype.getWalletAddressBookId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bankpb.WalletAddressBook} returns this
 */
proto.bankpb.WalletAddressBook.prototype.setWalletAddressBookId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string nick_name = 2;
 * @return {string}
 */
proto.bankpb.WalletAddressBook.prototype.getNickName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.WalletAddressBook} returns this
 */
proto.bankpb.WalletAddressBook.prototype.setNickName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 account_id = 3;
 * @return {number}
 */
proto.bankpb.WalletAddressBook.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.bankpb.WalletAddressBook} returns this
 */
proto.bankpb.WalletAddressBook.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string account_no = 4;
 * @return {string}
 */
proto.bankpb.WalletAddressBook.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.WalletAddressBook} returns this
 */
proto.bankpb.WalletAddressBook.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string correspondent = 5;
 * @return {string}
 */
proto.bankpb.WalletAddressBook.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.WalletAddressBook} returns this
 */
proto.bankpb.WalletAddressBook.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 wallet_id = 6;
 * @return {number}
 */
proto.bankpb.WalletAddressBook.prototype.getWalletId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.bankpb.WalletAddressBook} returns this
 */
proto.bankpb.WalletAddressBook.prototype.setWalletId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 contra_wallet_id = 7;
 * @return {number}
 */
proto.bankpb.WalletAddressBook.prototype.getContraWalletId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.bankpb.WalletAddressBook} returns this
 */
proto.bankpb.WalletAddressBook.prototype.setContraWalletId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string status = 8;
 * @return {string}
 */
proto.bankpb.WalletAddressBook.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.WalletAddressBook} returns this
 */
proto.bankpb.WalletAddressBook.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string recurrence_amount = 9;
 * @return {string}
 */
proto.bankpb.WalletAddressBook.prototype.getRecurrenceAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.WalletAddressBook} returns this
 */
proto.bankpb.WalletAddressBook.prototype.setRecurrenceAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string recurring = 10;
 * @return {string}
 */
proto.bankpb.WalletAddressBook.prototype.getRecurring = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.WalletAddressBook} returns this
 */
proto.bankpb.WalletAddressBook.prototype.setRecurring = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool verified = 11;
 * @return {boolean}
 */
proto.bankpb.WalletAddressBook.prototype.getVerified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.bankpb.WalletAddressBook} returns this
 */
proto.bankpb.WalletAddressBook.prototype.setVerified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.CreateWalletAddressBookResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.CreateWalletAddressBookResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.CreateWalletAddressBookResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.CreateWalletAddressBookResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    walletAddressBook: (f = msg.getWalletAddressBook()) && proto.bankpb.WalletAddressBook.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.CreateWalletAddressBookResponse}
 */
proto.bankpb.CreateWalletAddressBookResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.CreateWalletAddressBookResponse;
  return proto.bankpb.CreateWalletAddressBookResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.CreateWalletAddressBookResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.CreateWalletAddressBookResponse}
 */
proto.bankpb.CreateWalletAddressBookResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.bankpb.WalletAddressBook;
      reader.readMessage(value,proto.bankpb.WalletAddressBook.deserializeBinaryFromReader);
      msg.setWalletAddressBook(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.CreateWalletAddressBookResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.CreateWalletAddressBookResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.CreateWalletAddressBookResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.CreateWalletAddressBookResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWalletAddressBook();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.bankpb.WalletAddressBook.serializeBinaryToWriter
    );
  }
};


/**
 * optional WalletAddressBook wallet_address_book = 1;
 * @return {?proto.bankpb.WalletAddressBook}
 */
proto.bankpb.CreateWalletAddressBookResponse.prototype.getWalletAddressBook = function() {
  return /** @type{?proto.bankpb.WalletAddressBook} */ (
    jspb.Message.getWrapperField(this, proto.bankpb.WalletAddressBook, 1));
};


/**
 * @param {?proto.bankpb.WalletAddressBook|undefined} value
 * @return {!proto.bankpb.CreateWalletAddressBookResponse} returns this
*/
proto.bankpb.CreateWalletAddressBookResponse.prototype.setWalletAddressBook = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bankpb.CreateWalletAddressBookResponse} returns this
 */
proto.bankpb.CreateWalletAddressBookResponse.prototype.clearWalletAddressBook = function() {
  return this.setWalletAddressBook(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bankpb.CreateWalletAddressBookResponse.prototype.hasWalletAddressBook = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.UpdateWalletAddressBookResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.UpdateWalletAddressBookResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.UpdateWalletAddressBookResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.UpdateWalletAddressBookResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    walletAddressBook: (f = msg.getWalletAddressBook()) && proto.bankpb.WalletAddressBook.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.UpdateWalletAddressBookResponse}
 */
proto.bankpb.UpdateWalletAddressBookResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.UpdateWalletAddressBookResponse;
  return proto.bankpb.UpdateWalletAddressBookResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.UpdateWalletAddressBookResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.UpdateWalletAddressBookResponse}
 */
proto.bankpb.UpdateWalletAddressBookResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.bankpb.WalletAddressBook;
      reader.readMessage(value,proto.bankpb.WalletAddressBook.deserializeBinaryFromReader);
      msg.setWalletAddressBook(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.UpdateWalletAddressBookResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.UpdateWalletAddressBookResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.UpdateWalletAddressBookResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.UpdateWalletAddressBookResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWalletAddressBook();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.bankpb.WalletAddressBook.serializeBinaryToWriter
    );
  }
};


/**
 * optional WalletAddressBook wallet_address_book = 1;
 * @return {?proto.bankpb.WalletAddressBook}
 */
proto.bankpb.UpdateWalletAddressBookResponse.prototype.getWalletAddressBook = function() {
  return /** @type{?proto.bankpb.WalletAddressBook} */ (
    jspb.Message.getWrapperField(this, proto.bankpb.WalletAddressBook, 1));
};


/**
 * @param {?proto.bankpb.WalletAddressBook|undefined} value
 * @return {!proto.bankpb.UpdateWalletAddressBookResponse} returns this
*/
proto.bankpb.UpdateWalletAddressBookResponse.prototype.setWalletAddressBook = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bankpb.UpdateWalletAddressBookResponse} returns this
 */
proto.bankpb.UpdateWalletAddressBookResponse.prototype.clearWalletAddressBook = function() {
  return this.setWalletAddressBook(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bankpb.UpdateWalletAddressBookResponse.prototype.hasWalletAddressBook = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.ListWalletAddressBookRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.ListWalletAddressBookRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.ListWalletAddressBookRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.ListWalletAddressBookRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    nickName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 3, ""),
    walletId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    contraWalletId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    status: jspb.Message.getFieldWithDefault(msg, 6, ""),
    usrId: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.ListWalletAddressBookRequest}
 */
proto.bankpb.ListWalletAddressBookRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.ListWalletAddressBookRequest;
  return proto.bankpb.ListWalletAddressBookRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.ListWalletAddressBookRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.ListWalletAddressBookRequest}
 */
proto.bankpb.ListWalletAddressBookRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWalletId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setContraWalletId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUsrId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.ListWalletAddressBookRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.ListWalletAddressBookRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.ListWalletAddressBookRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.ListWalletAddressBookRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNickName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWalletId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getContraWalletId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUsrId();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
};


/**
 * optional string nick_name = 1;
 * @return {string}
 */
proto.bankpb.ListWalletAddressBookRequest.prototype.getNickName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.ListWalletAddressBookRequest} returns this
 */
proto.bankpb.ListWalletAddressBookRequest.prototype.setNickName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.bankpb.ListWalletAddressBookRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.ListWalletAddressBookRequest} returns this
 */
proto.bankpb.ListWalletAddressBookRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string correspondent = 3;
 * @return {string}
 */
proto.bankpb.ListWalletAddressBookRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.ListWalletAddressBookRequest} returns this
 */
proto.bankpb.ListWalletAddressBookRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 wallet_id = 4;
 * @return {number}
 */
proto.bankpb.ListWalletAddressBookRequest.prototype.getWalletId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.bankpb.ListWalletAddressBookRequest} returns this
 */
proto.bankpb.ListWalletAddressBookRequest.prototype.setWalletId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 contra_wallet_id = 5;
 * @return {number}
 */
proto.bankpb.ListWalletAddressBookRequest.prototype.getContraWalletId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.bankpb.ListWalletAddressBookRequest} returns this
 */
proto.bankpb.ListWalletAddressBookRequest.prototype.setContraWalletId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string status = 6;
 * @return {string}
 */
proto.bankpb.ListWalletAddressBookRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.ListWalletAddressBookRequest} returns this
 */
proto.bankpb.ListWalletAddressBookRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint32 usr_id = 7;
 * @return {number}
 */
proto.bankpb.ListWalletAddressBookRequest.prototype.getUsrId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.bankpb.ListWalletAddressBookRequest} returns this
 */
proto.bankpb.ListWalletAddressBookRequest.prototype.setUsrId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.bankpb.ListWalletAddressBookResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.ListWalletAddressBookResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.ListWalletAddressBookResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.ListWalletAddressBookResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.ListWalletAddressBookResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    walletAddressBooksList: jspb.Message.toObjectList(msg.getWalletAddressBooksList(),
    proto.bankpb.WalletAddressBook.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.ListWalletAddressBookResponse}
 */
proto.bankpb.ListWalletAddressBookResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.ListWalletAddressBookResponse;
  return proto.bankpb.ListWalletAddressBookResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.ListWalletAddressBookResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.ListWalletAddressBookResponse}
 */
proto.bankpb.ListWalletAddressBookResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.bankpb.WalletAddressBook;
      reader.readMessage(value,proto.bankpb.WalletAddressBook.deserializeBinaryFromReader);
      msg.addWalletAddressBooks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.ListWalletAddressBookResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.ListWalletAddressBookResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.ListWalletAddressBookResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.ListWalletAddressBookResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWalletAddressBooksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.bankpb.WalletAddressBook.serializeBinaryToWriter
    );
  }
};


/**
 * repeated WalletAddressBook wallet_address_books = 1;
 * @return {!Array<!proto.bankpb.WalletAddressBook>}
 */
proto.bankpb.ListWalletAddressBookResponse.prototype.getWalletAddressBooksList = function() {
  return /** @type{!Array<!proto.bankpb.WalletAddressBook>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.bankpb.WalletAddressBook, 1));
};


/**
 * @param {!Array<!proto.bankpb.WalletAddressBook>} value
 * @return {!proto.bankpb.ListWalletAddressBookResponse} returns this
*/
proto.bankpb.ListWalletAddressBookResponse.prototype.setWalletAddressBooksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.bankpb.WalletAddressBook=} opt_value
 * @param {number=} opt_index
 * @return {!proto.bankpb.WalletAddressBook}
 */
proto.bankpb.ListWalletAddressBookResponse.prototype.addWalletAddressBooks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.bankpb.WalletAddressBook, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.bankpb.ListWalletAddressBookResponse} returns this
 */
proto.bankpb.ListWalletAddressBookResponse.prototype.clearWalletAddressBooksList = function() {
  return this.setWalletAddressBooksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.DeleteWalletAddressBookRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.DeleteWalletAddressBookRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.DeleteWalletAddressBookRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.DeleteWalletAddressBookRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    walletAddressBookId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.DeleteWalletAddressBookRequest}
 */
proto.bankpb.DeleteWalletAddressBookRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.DeleteWalletAddressBookRequest;
  return proto.bankpb.DeleteWalletAddressBookRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.DeleteWalletAddressBookRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.DeleteWalletAddressBookRequest}
 */
proto.bankpb.DeleteWalletAddressBookRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWalletAddressBookId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.DeleteWalletAddressBookRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.DeleteWalletAddressBookRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.DeleteWalletAddressBookRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.DeleteWalletAddressBookRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWalletAddressBookId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 wallet_address_book_id = 1;
 * @return {number}
 */
proto.bankpb.DeleteWalletAddressBookRequest.prototype.getWalletAddressBookId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bankpb.DeleteWalletAddressBookRequest} returns this
 */
proto.bankpb.DeleteWalletAddressBookRequest.prototype.setWalletAddressBookId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.DeleteWalletAddressBookResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.DeleteWalletAddressBookResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.DeleteWalletAddressBookResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.DeleteWalletAddressBookResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    walletAddressBook: (f = msg.getWalletAddressBook()) && proto.bankpb.WalletAddressBook.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.DeleteWalletAddressBookResponse}
 */
proto.bankpb.DeleteWalletAddressBookResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.DeleteWalletAddressBookResponse;
  return proto.bankpb.DeleteWalletAddressBookResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.DeleteWalletAddressBookResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.DeleteWalletAddressBookResponse}
 */
proto.bankpb.DeleteWalletAddressBookResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.bankpb.WalletAddressBook;
      reader.readMessage(value,proto.bankpb.WalletAddressBook.deserializeBinaryFromReader);
      msg.setWalletAddressBook(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.DeleteWalletAddressBookResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.DeleteWalletAddressBookResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.DeleteWalletAddressBookResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.DeleteWalletAddressBookResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWalletAddressBook();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.bankpb.WalletAddressBook.serializeBinaryToWriter
    );
  }
};


/**
 * optional WalletAddressBook wallet_address_book = 1;
 * @return {?proto.bankpb.WalletAddressBook}
 */
proto.bankpb.DeleteWalletAddressBookResponse.prototype.getWalletAddressBook = function() {
  return /** @type{?proto.bankpb.WalletAddressBook} */ (
    jspb.Message.getWrapperField(this, proto.bankpb.WalletAddressBook, 1));
};


/**
 * @param {?proto.bankpb.WalletAddressBook|undefined} value
 * @return {!proto.bankpb.DeleteWalletAddressBookResponse} returns this
*/
proto.bankpb.DeleteWalletAddressBookResponse.prototype.setWalletAddressBook = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bankpb.DeleteWalletAddressBookResponse} returns this
 */
proto.bankpb.DeleteWalletAddressBookResponse.prototype.clearWalletAddressBook = function() {
  return this.setWalletAddressBook(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bankpb.DeleteWalletAddressBookResponse.prototype.hasWalletAddressBook = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.bankpb);
