import React, { useState, useEffect } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Box,
  makeStyles,
} from '@material-ui/core';
import color from "../../assets/css/colors"
import CloseButton from "../Buttons/CloseButton"

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 800,
    width: "100%",
    margin: "0 auto",
    float: "clear",
  },
  modalBox: {
    height: '100%',
    overflow: 'hidden',
  },
	modalHeader: {
		backgroundColor: "white",
		padding: 25,
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
		display: "flex",
		"& div:nth-child(1)": {
			flex: 1,
		},
		"& div:nth-child(2)": {
			flex: "none",
			"& .MuiIconButton-root": {
				borderRadius: 5,
				padding: 10,
				"&:hover svg path": {
					fill: color.primary.main,
				},
			},
			"& svg:last-child": {
				width: 15,
				height: 15,
			},
		},
	},
  fadeModalFull: {
    maxWidth: 1400,
    maxHeight: '90vh',
    height: '90vh',
    width: '100%',
    margin: '0px auto',
    padding: '20px 20px 20px 15px',
    backgroundColor: '#ffffff',
    border: 'none',
    borderRadius: 5,
    boxShadow: '0px 0px 10px #333333',
    boxSizing: 'border-box',
    outline: 'none',
    overflowY: 'auto',
  },
  jumioFrame: {
    height: '100%',
    width: '100%',
    border: '0px !important',
  },
	closeButton: {
		"& button": {
			marginTop: "-4px !important",
		},
	},
}));

export default function VerificationModal({
  onClose: handleClose,
  open: isOpen,
  value,
}) {
  const classes = useStyles();
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    if (isOpen) {
      let modalValue = { ...value };
      setModalData(modalValue);
    }
  }, [isOpen, value]);

  

  function receiveMessage(event) {
    var data = window.JSON.parse(event.data);
    // console.log('Netverify Web was loaded in an iframe.');
    // console.log('data', data);
    // console.log('auth token:', data.authorizationToken);
    // console.log('transaction reference:', data.transactionReference);
    // console.log('customer internal reference:', data.customerInternalReference);
    // console.log('event type:', data.eventType);
    // console.log('date-time:', data.dateTime);
    // console.log('event value:', data.payload.value);
    // console.log('event metainfo:', data.payload.metainfo);
    if(data.payload.value === "success"  || data.payload.value === "error") {
      let modalValue = { ...modalData };
      modalValue.status = data.payload.value;
      window.removeEventListener("message", receiveMessage, false);
        handleClose(modalValue);
    }
  }
  window.addEventListener("message", receiveMessage, false);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalFull}>
          <Box className={classes.modalBox}>
              <div className={classes.modalHeader}>
                <div>
                </div>
                <div className={classes.closeButton}>
                  <CloseButton onClick={handleClose} />
                </div>
              </div>
              { modalData.redirectUrl && (<iframe src={modalData.redirectUrl} className={classes.jumioFrame} allow="camera;fullscreen;accelerometer;gyroscope;magnetometer" allowFullScreen title="JumioValidationApi"></iframe>)}
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
