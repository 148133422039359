// source: proto/taxfilingpb/form1099b.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_type_date_pb = require('../../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
var proto_commonpb_file_pb = require('../../proto/commonpb/file_pb.js');
goog.object.extend(proto, proto_commonpb_file_pb);
goog.exportSymbol('proto.taxfilingpb.DownLoadBRequest', null, global);
goog.exportSymbol('proto.taxfilingpb.Form1099b', null, global);
goog.exportSymbol('proto.taxfilingpb.Form1099bDetails', null, global);
goog.exportSymbol('proto.taxfilingpb.Form1099bDetailsResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.Form1099bProcessRequest', null, global);
goog.exportSymbol('proto.taxfilingpb.Form1099bProcessResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.Form1099bRequest', null, global);
goog.exportSymbol('proto.taxfilingpb.GenerateBResponse', null, global);
goog.exportSymbol('proto.taxfilingpb.ListForm1099bResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.Form1099bRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.Form1099bRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.Form1099bRequest.displayName = 'proto.taxfilingpb.Form1099bRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.Form1099b = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.Form1099b, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.Form1099b.displayName = 'proto.taxfilingpb.Form1099b';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.Form1099bDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.Form1099bDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.Form1099bDetails.displayName = 'proto.taxfilingpb.Form1099bDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.ListForm1099bResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.taxfilingpb.ListForm1099bResponse.repeatedFields_, null);
};
goog.inherits(proto.taxfilingpb.ListForm1099bResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.ListForm1099bResponse.displayName = 'proto.taxfilingpb.ListForm1099bResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.Form1099bDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.taxfilingpb.Form1099bDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.taxfilingpb.Form1099bDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.Form1099bDetailsResponse.displayName = 'proto.taxfilingpb.Form1099bDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.Form1099bProcessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.Form1099bProcessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.Form1099bProcessRequest.displayName = 'proto.taxfilingpb.Form1099bProcessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.Form1099bProcessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.Form1099bProcessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.Form1099bProcessResponse.displayName = 'proto.taxfilingpb.Form1099bProcessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.DownLoadBRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.taxfilingpb.DownLoadBRequest.repeatedFields_, null);
};
goog.inherits(proto.taxfilingpb.DownLoadBRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.DownLoadBRequest.displayName = 'proto.taxfilingpb.DownLoadBRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.taxfilingpb.GenerateBResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.taxfilingpb.GenerateBResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.taxfilingpb.GenerateBResponse.displayName = 'proto.taxfilingpb.GenerateBResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.Form1099bRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.Form1099bRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.Form1099bRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099bRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    office: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    usrId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.Form1099bRequest}
 */
proto.taxfilingpb.Form1099bRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.Form1099bRequest;
  return proto.taxfilingpb.Form1099bRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.Form1099bRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.Form1099bRequest}
 */
proto.taxfilingpb.Form1099bRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOffice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsrId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.Form1099bRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.Form1099bRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.Form1099bRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099bRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOffice();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUsrId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string year = 1;
 * @return {string}
 */
proto.taxfilingpb.Form1099bRequest.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099bRequest} returns this
 */
proto.taxfilingpb.Form1099bRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.taxfilingpb.Form1099bRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099bRequest} returns this
 */
proto.taxfilingpb.Form1099bRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string office = 3;
 * @return {string}
 */
proto.taxfilingpb.Form1099bRequest.prototype.getOffice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099bRequest} returns this
 */
proto.taxfilingpb.Form1099bRequest.prototype.setOffice = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_no = 4;
 * @return {string}
 */
proto.taxfilingpb.Form1099bRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099bRequest} returns this
 */
proto.taxfilingpb.Form1099bRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 usr_id = 5;
 * @return {number}
 */
proto.taxfilingpb.Form1099bRequest.prototype.getUsrId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.Form1099bRequest} returns this
 */
proto.taxfilingpb.Form1099bRequest.prototype.setUsrId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 account_id = 6;
 * @return {number}
 */
proto.taxfilingpb.Form1099bRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.Form1099bRequest} returns this
 */
proto.taxfilingpb.Form1099bRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.Form1099b.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.Form1099b.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.Form1099b} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099b.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, ""),
    account: jspb.Message.getFieldWithDefault(msg, 2, ""),
    companyName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    address: jspb.Message.getFieldWithDefault(msg, 4, ""),
    city: jspb.Message.getFieldWithDefault(msg, 5, ""),
    state: jspb.Message.getFieldWithDefault(msg, 6, ""),
    zip: jspb.Message.getFieldWithDefault(msg, 7, ""),
    country: jspb.Message.getFieldWithDefault(msg, 8, ""),
    cost: jspb.Message.getFieldWithDefault(msg, 9, ""),
    proceed: jspb.Message.getFieldWithDefault(msg, 10, ""),
    netAmount: jspb.Message.getFieldWithDefault(msg, 11, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 12, ""),
    office: jspb.Message.getFieldWithDefault(msg, 13, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 14, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 16, 0),
    accountName: jspb.Message.getFieldWithDefault(msg, 17, ""),
    taxCountry: jspb.Message.getFieldWithDefault(msg, 18, ""),
    legalEntity: jspb.Message.getFieldWithDefault(msg, 19, ""),
    governmentId: jspb.Message.getFieldWithDefault(msg, 20, ""),
    washSaleLossDisallowed: jspb.Message.getFieldWithDefault(msg, 21, ""),
    accruedMarketDiscount: jspb.Message.getFieldWithDefault(msg, 22, ""),
    currentYearRealized: jspb.Message.getFieldWithDefault(msg, 23, ""),
    priorYearRealized: jspb.Message.getFieldWithDefault(msg, 24, ""),
    currentYearUnrealized: jspb.Message.getFieldWithDefault(msg, 25, ""),
    aggregateProfitLoss: jspb.Message.getFieldWithDefault(msg, 26, ""),
    stateName: jspb.Message.getFieldWithDefault(msg, 27, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.Form1099b}
 */
proto.taxfilingpb.Form1099b.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.Form1099b;
  return proto.taxfilingpb.Form1099b.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.Form1099b} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.Form1099b}
 */
proto.taxfilingpb.Form1099b.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setZip(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCost(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setProceed(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetAmount(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setOffice(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAccountId(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxCountry(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalEntity(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setGovernmentId(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setWashSaleLossDisallowed(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccruedMarketDiscount(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentYearRealized(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setPriorYearRealized(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentYearUnrealized(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setAggregateProfitLoss(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.Form1099b.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.Form1099b.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.Form1099b} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099b.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getZip();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCost();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getProceed();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getNetAmount();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getOffice();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTaxCountry();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getLegalEntity();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getGovernmentId();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getWashSaleLossDisallowed();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getAccruedMarketDiscount();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getCurrentYearRealized();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getPriorYearRealized();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getCurrentYearUnrealized();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getAggregateProfitLoss();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getStateName();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
};


/**
 * optional string year = 1;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account = 2;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string company_name = 3;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setCompanyName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string address = 4;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city = 5;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string state = 6;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string zip = 7;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getZip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setZip = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string country = 8;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string cost = 9;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getCost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setCost = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string proceed = 10;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getProceed = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setProceed = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string net_amount = 11;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getNetAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setNetAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string correspondent = 12;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string office = 13;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getOffice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setOffice = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string account_no = 14;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string external_id = 15;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int32 account_id = 16;
 * @return {number}
 */
proto.taxfilingpb.Form1099b.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string account_name = 17;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string tax_country = 18;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getTaxCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setTaxCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string legal_entity = 19;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getLegalEntity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setLegalEntity = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string government_id = 20;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getGovernmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setGovernmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string wash_sale_loss_disallowed = 21;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getWashSaleLossDisallowed = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setWashSaleLossDisallowed = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string accrued_market_discount = 22;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getAccruedMarketDiscount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setAccruedMarketDiscount = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string current_year_realized = 23;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getCurrentYearRealized = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setCurrentYearRealized = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string prior_year_realized = 24;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getPriorYearRealized = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setPriorYearRealized = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string current_year_unrealized = 25;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getCurrentYearUnrealized = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setCurrentYearUnrealized = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string aggregate_profit_loss = 26;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getAggregateProfitLoss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setAggregateProfitLoss = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string state_name = 27;
 * @return {string}
 */
proto.taxfilingpb.Form1099b.prototype.getStateName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099b} returns this
 */
proto.taxfilingpb.Form1099b.prototype.setStateName = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.Form1099bDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.Form1099bDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.Form1099bDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099bDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    dateAcq: (f = msg.getDateAcq()) && google_type_date_pb.Date.toObject(includeInstance, f),
    dateSale: (f = msg.getDateSale()) && google_type_date_pb.Date.toObject(includeInstance, f),
    symbol: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cusip: jspb.Message.getFieldWithDefault(msg, 4, ""),
    symbolDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    cost: jspb.Message.getFieldWithDefault(msg, 6, ""),
    netAmount: jspb.Message.getFieldWithDefault(msg, 7, ""),
    proceed: jspb.Message.getFieldWithDefault(msg, 8, ""),
    shortTerm: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    longTerm: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    washSaleLossDisallowed: jspb.Message.getFieldWithDefault(msg, 21, ""),
    accruedMarketDiscount: jspb.Message.getFieldWithDefault(msg, 22, ""),
    currentYearRealized: jspb.Message.getFieldWithDefault(msg, 23, ""),
    priorYearRealized: jspb.Message.getFieldWithDefault(msg, 24, ""),
    currentYearUnrealized: jspb.Message.getFieldWithDefault(msg, 25, ""),
    aggregateProfitLoss: jspb.Message.getFieldWithDefault(msg, 26, ""),
    stateName: jspb.Message.getFieldWithDefault(msg, 27, ""),
    entryType: jspb.Message.getFieldWithDefault(msg, 28, ""),
    sellTrnsId: jspb.Message.getFieldWithDefault(msg, 29, 0),
    buyTrnsId: jspb.Message.getFieldWithDefault(msg, 30, 0),
    qty: jspb.Message.getFieldWithDefault(msg, 31, ""),
    sellPrice: jspb.Message.getFieldWithDefault(msg, 32, ""),
    buyPrice: jspb.Message.getFieldWithDefault(msg, 33, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.Form1099bDetails}
 */
proto.taxfilingpb.Form1099bDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.Form1099bDetails;
  return proto.taxfilingpb.Form1099bDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.Form1099bDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.Form1099bDetails}
 */
proto.taxfilingpb.Form1099bDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setDateAcq(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setDateSale(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCost(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetAmount(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setProceed(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShortTerm(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLongTerm(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setWashSaleLossDisallowed(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccruedMarketDiscount(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentYearRealized(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setPriorYearRealized(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentYearUnrealized(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setAggregateProfitLoss(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setStateName(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryType(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSellTrnsId(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBuyTrnsId(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setSellPrice(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuyPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.Form1099bDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.Form1099bDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.Form1099bDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099bDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDateAcq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getDateSale();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSymbolDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCost();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNetAmount();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getProceed();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getShortTerm();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getLongTerm();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getWashSaleLossDisallowed();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getAccruedMarketDiscount();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getCurrentYearRealized();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getPriorYearRealized();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getCurrentYearUnrealized();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getAggregateProfitLoss();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getStateName();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getEntryType();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getSellTrnsId();
  if (f !== 0) {
    writer.writeUint32(
      29,
      f
    );
  }
  f = message.getBuyTrnsId();
  if (f !== 0) {
    writer.writeUint32(
      30,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getSellPrice();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getBuyPrice();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
};


/**
 * optional google.type.Date date_acq = 1;
 * @return {?proto.google.type.Date}
 */
proto.taxfilingpb.Form1099bDetails.prototype.getDateAcq = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
*/
proto.taxfilingpb.Form1099bDetails.prototype.setDateAcq = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
 */
proto.taxfilingpb.Form1099bDetails.prototype.clearDateAcq = function() {
  return this.setDateAcq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.Form1099bDetails.prototype.hasDateAcq = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.type.Date date_sale = 2;
 * @return {?proto.google.type.Date}
 */
proto.taxfilingpb.Form1099bDetails.prototype.getDateSale = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
*/
proto.taxfilingpb.Form1099bDetails.prototype.setDateSale = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
 */
proto.taxfilingpb.Form1099bDetails.prototype.clearDateSale = function() {
  return this.setDateSale(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.Form1099bDetails.prototype.hasDateSale = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string symbol = 3;
 * @return {string}
 */
proto.taxfilingpb.Form1099bDetails.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
 */
proto.taxfilingpb.Form1099bDetails.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string cusip = 4;
 * @return {string}
 */
proto.taxfilingpb.Form1099bDetails.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
 */
proto.taxfilingpb.Form1099bDetails.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string symbol_description = 5;
 * @return {string}
 */
proto.taxfilingpb.Form1099bDetails.prototype.getSymbolDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
 */
proto.taxfilingpb.Form1099bDetails.prototype.setSymbolDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string cost = 6;
 * @return {string}
 */
proto.taxfilingpb.Form1099bDetails.prototype.getCost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
 */
proto.taxfilingpb.Form1099bDetails.prototype.setCost = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string net_amount = 7;
 * @return {string}
 */
proto.taxfilingpb.Form1099bDetails.prototype.getNetAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
 */
proto.taxfilingpb.Form1099bDetails.prototype.setNetAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string proceed = 8;
 * @return {string}
 */
proto.taxfilingpb.Form1099bDetails.prototype.getProceed = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
 */
proto.taxfilingpb.Form1099bDetails.prototype.setProceed = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool short_term = 9;
 * @return {boolean}
 */
proto.taxfilingpb.Form1099bDetails.prototype.getShortTerm = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
 */
proto.taxfilingpb.Form1099bDetails.prototype.setShortTerm = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool long_term = 10;
 * @return {boolean}
 */
proto.taxfilingpb.Form1099bDetails.prototype.getLongTerm = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
 */
proto.taxfilingpb.Form1099bDetails.prototype.setLongTerm = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string wash_sale_loss_disallowed = 21;
 * @return {string}
 */
proto.taxfilingpb.Form1099bDetails.prototype.getWashSaleLossDisallowed = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
 */
proto.taxfilingpb.Form1099bDetails.prototype.setWashSaleLossDisallowed = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string accrued_market_discount = 22;
 * @return {string}
 */
proto.taxfilingpb.Form1099bDetails.prototype.getAccruedMarketDiscount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
 */
proto.taxfilingpb.Form1099bDetails.prototype.setAccruedMarketDiscount = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string current_year_realized = 23;
 * @return {string}
 */
proto.taxfilingpb.Form1099bDetails.prototype.getCurrentYearRealized = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
 */
proto.taxfilingpb.Form1099bDetails.prototype.setCurrentYearRealized = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string prior_year_realized = 24;
 * @return {string}
 */
proto.taxfilingpb.Form1099bDetails.prototype.getPriorYearRealized = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
 */
proto.taxfilingpb.Form1099bDetails.prototype.setPriorYearRealized = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string current_year_unrealized = 25;
 * @return {string}
 */
proto.taxfilingpb.Form1099bDetails.prototype.getCurrentYearUnrealized = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
 */
proto.taxfilingpb.Form1099bDetails.prototype.setCurrentYearUnrealized = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string aggregate_profit_loss = 26;
 * @return {string}
 */
proto.taxfilingpb.Form1099bDetails.prototype.getAggregateProfitLoss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
 */
proto.taxfilingpb.Form1099bDetails.prototype.setAggregateProfitLoss = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string state_name = 27;
 * @return {string}
 */
proto.taxfilingpb.Form1099bDetails.prototype.getStateName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
 */
proto.taxfilingpb.Form1099bDetails.prototype.setStateName = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string entry_type = 28;
 * @return {string}
 */
proto.taxfilingpb.Form1099bDetails.prototype.getEntryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
 */
proto.taxfilingpb.Form1099bDetails.prototype.setEntryType = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional uint32 sell_trns_id = 29;
 * @return {number}
 */
proto.taxfilingpb.Form1099bDetails.prototype.getSellTrnsId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
 */
proto.taxfilingpb.Form1099bDetails.prototype.setSellTrnsId = function(value) {
  return jspb.Message.setProto3IntField(this, 29, value);
};


/**
 * optional uint32 buy_trns_id = 30;
 * @return {number}
 */
proto.taxfilingpb.Form1099bDetails.prototype.getBuyTrnsId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
 */
proto.taxfilingpb.Form1099bDetails.prototype.setBuyTrnsId = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional string qty = 31;
 * @return {string}
 */
proto.taxfilingpb.Form1099bDetails.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
 */
proto.taxfilingpb.Form1099bDetails.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string sell_price = 32;
 * @return {string}
 */
proto.taxfilingpb.Form1099bDetails.prototype.getSellPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
 */
proto.taxfilingpb.Form1099bDetails.prototype.setSellPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string buy_price = 33;
 * @return {string}
 */
proto.taxfilingpb.Form1099bDetails.prototype.getBuyPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099bDetails} returns this
 */
proto.taxfilingpb.Form1099bDetails.prototype.setBuyPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.taxfilingpb.ListForm1099bResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.ListForm1099bResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.ListForm1099bResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.ListForm1099bResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1099bResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    form1099BList: jspb.Message.toObjectList(msg.getForm1099BList(),
    proto.taxfilingpb.Form1099b.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.ListForm1099bResponse}
 */
proto.taxfilingpb.ListForm1099bResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.ListForm1099bResponse;
  return proto.taxfilingpb.ListForm1099bResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.ListForm1099bResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.ListForm1099bResponse}
 */
proto.taxfilingpb.ListForm1099bResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.Form1099b;
      reader.readMessage(value,proto.taxfilingpb.Form1099b.deserializeBinaryFromReader);
      msg.addForm1099B(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.ListForm1099bResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.ListForm1099bResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.ListForm1099bResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.ListForm1099bResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForm1099BList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.taxfilingpb.Form1099b.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Form1099b form1099_b = 1;
 * @return {!Array<!proto.taxfilingpb.Form1099b>}
 */
proto.taxfilingpb.ListForm1099bResponse.prototype.getForm1099BList = function() {
  return /** @type{!Array<!proto.taxfilingpb.Form1099b>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.taxfilingpb.Form1099b, 1));
};


/**
 * @param {!Array<!proto.taxfilingpb.Form1099b>} value
 * @return {!proto.taxfilingpb.ListForm1099bResponse} returns this
*/
proto.taxfilingpb.ListForm1099bResponse.prototype.setForm1099BList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.taxfilingpb.Form1099b=} opt_value
 * @param {number=} opt_index
 * @return {!proto.taxfilingpb.Form1099b}
 */
proto.taxfilingpb.ListForm1099bResponse.prototype.addForm1099B = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.taxfilingpb.Form1099b, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.taxfilingpb.ListForm1099bResponse} returns this
 */
proto.taxfilingpb.ListForm1099bResponse.prototype.clearForm1099BList = function() {
  return this.setForm1099BList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.taxfilingpb.Form1099bDetailsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.Form1099bDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.Form1099bDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.Form1099bDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099bDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    form1099BDetailsList: jspb.Message.toObjectList(msg.getForm1099BDetailsList(),
    proto.taxfilingpb.Form1099bDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.Form1099bDetailsResponse}
 */
proto.taxfilingpb.Form1099bDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.Form1099bDetailsResponse;
  return proto.taxfilingpb.Form1099bDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.Form1099bDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.Form1099bDetailsResponse}
 */
proto.taxfilingpb.Form1099bDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.Form1099bDetails;
      reader.readMessage(value,proto.taxfilingpb.Form1099bDetails.deserializeBinaryFromReader);
      msg.addForm1099BDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.Form1099bDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.Form1099bDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.Form1099bDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099bDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForm1099BDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.taxfilingpb.Form1099bDetails.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Form1099bDetails form1099_b_details = 1;
 * @return {!Array<!proto.taxfilingpb.Form1099bDetails>}
 */
proto.taxfilingpb.Form1099bDetailsResponse.prototype.getForm1099BDetailsList = function() {
  return /** @type{!Array<!proto.taxfilingpb.Form1099bDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.taxfilingpb.Form1099bDetails, 1));
};


/**
 * @param {!Array<!proto.taxfilingpb.Form1099bDetails>} value
 * @return {!proto.taxfilingpb.Form1099bDetailsResponse} returns this
*/
proto.taxfilingpb.Form1099bDetailsResponse.prototype.setForm1099BDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.taxfilingpb.Form1099bDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.taxfilingpb.Form1099bDetails}
 */
proto.taxfilingpb.Form1099bDetailsResponse.prototype.addForm1099BDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.taxfilingpb.Form1099bDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.taxfilingpb.Form1099bDetailsResponse} returns this
 */
proto.taxfilingpb.Form1099bDetailsResponse.prototype.clearForm1099BDetailsList = function() {
  return this.setForm1099BDetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.Form1099bProcessRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.Form1099bProcessRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.Form1099bProcessRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099bProcessRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.Form1099bProcessRequest}
 */
proto.taxfilingpb.Form1099bProcessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.Form1099bProcessRequest;
  return proto.taxfilingpb.Form1099bProcessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.Form1099bProcessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.Form1099bProcessRequest}
 */
proto.taxfilingpb.Form1099bProcessRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.Form1099bProcessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.Form1099bProcessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.Form1099bProcessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099bProcessRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string year = 1;
 * @return {string}
 */
proto.taxfilingpb.Form1099bProcessRequest.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.Form1099bProcessRequest} returns this
 */
proto.taxfilingpb.Form1099bProcessRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.Form1099bProcessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.Form1099bProcessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.Form1099bProcessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099bProcessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.Form1099bProcessResponse}
 */
proto.taxfilingpb.Form1099bProcessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.Form1099bProcessResponse;
  return proto.taxfilingpb.Form1099bProcessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.Form1099bProcessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.Form1099bProcessResponse}
 */
proto.taxfilingpb.Form1099bProcessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.Form1099bProcessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.Form1099bProcessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.Form1099bProcessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.Form1099bProcessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.taxfilingpb.Form1099bProcessResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.taxfilingpb.Form1099bProcessResponse} returns this
 */
proto.taxfilingpb.Form1099bProcessResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.taxfilingpb.DownLoadBRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.DownLoadBRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.DownLoadBRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.DownLoadBRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.DownLoadBRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    form1099BRequest: (f = msg.getForm1099BRequest()) && proto.taxfilingpb.Form1099bRequest.toObject(includeInstance, f),
    fileType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    downloadType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    batchNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    saveToExternal: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    accountIdList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.DownLoadBRequest}
 */
proto.taxfilingpb.DownLoadBRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.DownLoadBRequest;
  return proto.taxfilingpb.DownLoadBRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.DownLoadBRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.DownLoadBRequest}
 */
proto.taxfilingpb.DownLoadBRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.taxfilingpb.Form1099bRequest;
      reader.readMessage(value,proto.taxfilingpb.Form1099bRequest.deserializeBinaryFromReader);
      msg.setForm1099BRequest(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDownloadType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchNo(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSaveToExternal(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAccountId(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.DownLoadBRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.DownLoadBRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.DownLoadBRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.DownLoadBRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getForm1099BRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.taxfilingpb.Form1099bRequest.serializeBinaryToWriter
    );
  }
  f = message.getFileType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDownloadType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBatchNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSaveToExternal();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getAccountIdList();
  if (f.length > 0) {
    writer.writePackedInt32(
      6,
      f
    );
  }
};


/**
 * optional Form1099bRequest form1099_b_request = 1;
 * @return {?proto.taxfilingpb.Form1099bRequest}
 */
proto.taxfilingpb.DownLoadBRequest.prototype.getForm1099BRequest = function() {
  return /** @type{?proto.taxfilingpb.Form1099bRequest} */ (
    jspb.Message.getWrapperField(this, proto.taxfilingpb.Form1099bRequest, 1));
};


/**
 * @param {?proto.taxfilingpb.Form1099bRequest|undefined} value
 * @return {!proto.taxfilingpb.DownLoadBRequest} returns this
*/
proto.taxfilingpb.DownLoadBRequest.prototype.setForm1099BRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.taxfilingpb.DownLoadBRequest} returns this
 */
proto.taxfilingpb.DownLoadBRequest.prototype.clearForm1099BRequest = function() {
  return this.setForm1099BRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.taxfilingpb.DownLoadBRequest.prototype.hasForm1099BRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string file_type = 2;
 * @return {string}
 */
proto.taxfilingpb.DownLoadBRequest.prototype.getFileType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.DownLoadBRequest} returns this
 */
proto.taxfilingpb.DownLoadBRequest.prototype.setFileType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string download_type = 3;
 * @return {string}
 */
proto.taxfilingpb.DownLoadBRequest.prototype.getDownloadType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.DownLoadBRequest} returns this
 */
proto.taxfilingpb.DownLoadBRequest.prototype.setDownloadType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string batch_no = 4;
 * @return {string}
 */
proto.taxfilingpb.DownLoadBRequest.prototype.getBatchNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.taxfilingpb.DownLoadBRequest} returns this
 */
proto.taxfilingpb.DownLoadBRequest.prototype.setBatchNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool save_to_external = 5;
 * @return {boolean}
 */
proto.taxfilingpb.DownLoadBRequest.prototype.getSaveToExternal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.taxfilingpb.DownLoadBRequest} returns this
 */
proto.taxfilingpb.DownLoadBRequest.prototype.setSaveToExternal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated int32 account_id = 6;
 * @return {!Array<number>}
 */
proto.taxfilingpb.DownLoadBRequest.prototype.getAccountIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.taxfilingpb.DownLoadBRequest} returns this
 */
proto.taxfilingpb.DownLoadBRequest.prototype.setAccountIdList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.taxfilingpb.DownLoadBRequest} returns this
 */
proto.taxfilingpb.DownLoadBRequest.prototype.addAccountId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.taxfilingpb.DownLoadBRequest} returns this
 */
proto.taxfilingpb.DownLoadBRequest.prototype.clearAccountIdList = function() {
  return this.setAccountIdList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.taxfilingpb.GenerateBResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.taxfilingpb.GenerateBResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.taxfilingpb.GenerateBResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.GenerateBResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.taxfilingpb.GenerateBResponse}
 */
proto.taxfilingpb.GenerateBResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.taxfilingpb.GenerateBResponse;
  return proto.taxfilingpb.GenerateBResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.taxfilingpb.GenerateBResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.taxfilingpb.GenerateBResponse}
 */
proto.taxfilingpb.GenerateBResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.taxfilingpb.GenerateBResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.taxfilingpb.GenerateBResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.taxfilingpb.GenerateBResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.taxfilingpb.GenerateBResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.taxfilingpb.GenerateBResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.taxfilingpb.GenerateBResponse} returns this
 */
proto.taxfilingpb.GenerateBResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.taxfilingpb);
