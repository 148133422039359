import {
  DcRequest,
  ListDcRequest,
  DcRequestServiceClient,
  EmptyDCRequest
} from '../proto/bankpb/dcrequest_grpc_web_pb'
import { auth } from '../lib/auth/Auth'
import { stringToProtoDate } from './ConvertService'
import authSvc from './AuthService';

const service = new DcRequestServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth },
)

const userId = authSvc.getCurrentUser()?.UserId;

export async function createDigitalCashRequest(param) {
  return new Promise((resolve, reject) => {
    let req = new DcRequest()
    req.setCorrespondent(param.correspondent)
    req.setAccountNo(param.accountNo)
    req.setTransferType(param.transferType)
    req.setWalletId(param.walletId)
    req.setContraWalletId(param.contraWalletId)
    req.setAmt(param.amt)
    req.setStatus(param.status)
    req.setConfirmationId(param.confirmationId)
    req.setReferenceId(param.referenceId)
    req.setInternalId(param.internalId)
    req.setExternalId(param.externalId)
    req.setPrivateDescription(param.privateDescription)
    req.setPublicDescription(param.publicDescription)
    req.setBankId(param.bankId)
    req.setRequestType(param.requestType)

    service.createDc(req, {}, (error, response) => {
      if (error) {
        reject(error)
      } else {
        resolve(response.toObject())
      }
    })
  })
}

export async function updateDigitalCashRequest(param) {
  return new Promise((resolve, reject) => {
    let req = new DcRequest()
    req.setDcRequestId(param.dcRequestId)
    req.setStatus(param.status)
    req.setInternalId(param.internalId)
    req.setExternalId(param.externalId)
    req.setPrivateDescription(param.privateDescription)

    service.updateDc(req, {}, (error, response) => {
      if (error) {
        reject(error)
      } else {
        resolve(response.toObject())
      }
    })
  })
}

export async function listDigitalCashRequest(param) {
  return new Promise((resolve, reject) => {
    const req = new ListDcRequest()
    req.setFromDate(stringToProtoDate(param.fromDate))
    req.setToDate(stringToProtoDate(param.toDate))
    req.setCorrespondent(param.correspondent)
    req.setAccountNo(param.accountNo)
    req.setMasterAccountNo(param.masterAccountNo)
    req.setTransferType(param.transferType)
    req.setStatus(param.status)
    req.setExternalId(param.externalId)
    req.setSymbol(param.symbol)
    req.setSign(param.sign)
    req.setAmount(param.amount)
    req.setUsrId(userId);
    req.setRequestType(param.requestType)
    
    service.listDc(req, {}, (error, response) => {
      if (error) {
        reject(error)
      } else {
        resolve(response.toObject())
      }
    })
  })
}

export async function listDigitalCashRequestSummary(param) {
  return new Promise((resolve, reject) => {
    const req = new ListDcRequest()
    req.setFromDate(stringToProtoDate(param.fromDate))
    req.setToDate(stringToProtoDate(param.toDate))
    req.setSign(param.sign)
    req.setAmount(param.amount)

    service.listDcSummary(req, {}, (error, response) => {
      if (error) {
        reject(error)
      } else {
        resolve(response.toObject())
      }
    })
  })
}

export async function readPositionQty() {
  return new Promise((resolve, reject) => {
    const req = new EmptyDCRequest();

    service.readPositionQty(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}