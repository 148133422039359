import { ReadDisclosureRequest, DisclosureServiceClient } from "../proto/reportpb/disclosure_grpc_web_pb"
import { auth } from "../lib/auth/Auth"
import { DisclosureServiceClient as MarginDisclosureServiceClient, DownloadBlankReportRequest } from "../proto/admpb/disclosure_grpc_web_pb"
import download from "./DownloadService"

const service = new DisclosureServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth })
const marginDisclosureService = new MarginDisclosureServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth })

export async function readDisclosure(reportType, correspondent) {
	return new Promise((resolve, reject) => {
		let req = new ReadDisclosureRequest()
    req.setReportType(reportType);
    req.setCorrespondent(correspondent);

		service.readDisclosure(req, {}, (error, response) => {
			if (error) {
				reject(error)
			} else {
				resolve(response.toObject())
			}
		})
	})
}

export async function downloadBlankReport(param, disclosure) {
	return new Promise((resolve, reject) => {
		const req = new DownloadBlankReportRequest()
		console.log(param, disclosure)
		req.setCorrespondent(param.correspondent)
		req.setReportType(param.reportType)
		req.setDisclosure(disclosure)

		marginDisclosureService.downloadBlankReport(req, {}, (error, response) => {
			if (error) {
				reject(error)
			} else {
				download(response)
				resolve(response.toObject())
			}
		})
	})
}
