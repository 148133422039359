/*ReactJS*/
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Typography, Modal, Backdrop, Fade, Box, TextField } from '@material-ui/core';
import AutoCompleteCorrespondent from '../AutoComplete/AutoCompleteCorrespondent';
import TextFieldMobileNoAreaCode from '../Textfields/TextFieldMobileNoAreaCode.js';
import SelectSystemCode from '../Dropdown/SelectSystemCode';
import Button from '../Buttons/Button';
import Text from '../Typography/Text';

import fontFamily from '../../assets/css/fonts';
import color from '../../assets/css/colors';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 550,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  fadeModalMd: {
    maxWidth: 800,
    width: '100%',
    padding: '25px 30px 30px 30px',
    margin: '0px auto',
    backgroundColor: '#ffffff',
    border: 'none',
    borderRadius: 5,
    boxShadow: '0px 0px 10px #333333',
    boxSizing: 'border-box',
    outline: 'none',
    overflowY: 'auto',
    maxHeight: '90vh',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
  accordionDetails: {
    display: 'block !important',
    padding: '0px 20px 25px 20px !important',
  },
  accordion: {
    borderRadius: '5px !important',
    boxShadow: '0px 1px 5px -2px #cdcfdd !important',
    border: '1px solid #efeff0 !important',
  },
  tabBody: {
    minWidth: '100%',
    padding: '0px 6px',
  },
}));

export default function ContactInfoModal({
  onClose: handleClose,
  open: isOpen,
  importMode: isImport,
  value,
  validation,
  loading,
}) {
  const classes = useStyles();
  const [modalData, setModalData] = useState({});
  const [isEditContactinfo, setisEditContactinfo] = React.useState(false);

  useEffect(() => {
    if (isOpen) {
      console.log(value)
      value.status = !value.status ? 'Active' : value.status;

      setModalData({ ...value });
      if (isImport || value.contactInfoId) {
        setisEditContactinfo(true);
      }
    }
  }, [isOpen, value, isImport]);

  const handleContactInfoChange = (value, data, event, formattedValue) => {
    setModalData({
      ...modalData,
      contactInfo: formattedValue,
    });
  };

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;
    setModalData({
      ...modalData,
      [input.name]: input.value,
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={() => handleClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalMd}>
          <Text
            id="transition-modal-title"
            variant="h2"
            label={
              isEditContactinfo
                ? 'Edit Contact Information'
                : 'Add New Contact Information'
            }
          />
          <Box mt={5}>
            <form className={classes.modalForm} noValidate autoComplete="off">
              {isImport && (
                <div className="grd-row">
                  <div className="grd-cell">
                    <AutoCompleteCorrespondent
                      fullWidth
                      required={true}
                      freeSolo={true}
                      name="correspondent"
                      value={modalData.correspondent}
                      onChange={handleChange}
                    ></AutoCompleteCorrespondent>
                  </div>
                  <div className="grd-cell">
                    <TextField
                      fullWidth
                      required={true}
                      name="accountNo"
                      label="Account No"
                      value={modalData.accountNo}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>
              )}
              <div>
                <div className="grd-row">
                  <div className="grd-cell">
                    <SelectSystemCode
                      error={validation.contactInfoType}
                      required={true}
                      name="contactInfoType"
                      label="Contact Info Type"
                      type="Contact Info Type"
                      value={modalData.contactInfoType}
                      onChange={handleChange}
                    ></SelectSystemCode>
                  </div>
                </div>
                <div className="grd-row">
                  <div className="grd-cell">
                    {modalData.contactInfoType === 'Primary' ||
                    modalData.contactInfoType === 'Mobile' ||
                    modalData.contactInfoType === 'Business' ||
                    modalData.contactInfoType === 'Personal' ||
                    modalData.contactInfoType === 'Home' ||
                    modalData.contactInfoType === 'Office' ? (
                      <TextFieldMobileNoAreaCode
                        required={true}
                        name="contactInfo"
                        label="Contact Info"
                        value={modalData.contactInfo}
                        onChange={handleContactInfoChange}
                      ></TextFieldMobileNoAreaCode>
                    ) : (
                      <TextField
                        error={validation.contactInfo}
                        fullWidth
                        required={true}
                        name="contactInfo"
                        label="Contact Info"
                        type="email"
                        value={modalData.contactInfo}
                        onChange={handleChange}
                        inputProps={{ maxLength: 100 }}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </div>
                </div>
                <div className="grd-row">
                  <div className="grd-cell">
                    <SelectSystemCode
                      error={validation.status}
                      required={true}
                      name="status"
                      label="Status"
                      type="Status"
                      subType="Client Setup"
                      value={modalData.status}
                      onChange={handleChange}
                    ></SelectSystemCode>
                  </div>
                </div>
              </div>
              <div className={classes.modalFooter}>
                <div className="grd-row">
                  <div className="grd-cell-none" style={{ marginRight: 10 }}>
                    <Button
                      disabled={loading}
                      color="primary"
                      label="Close"
                      onClick={() => {
                        handleClose();
                      }}
                    />
                  </div>
                  <div className="grd-cell-none">
                    <Button
                      disabled={loading}
                      loading={loading}
                      color="secondary"
                      label={loading ? 'Saving...' : 'Save'}
                      onClick={() => {
                        handleClose(modalData, isEditContactinfo);
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
