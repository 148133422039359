import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip, Popover, ClickAwayListener } from '@material-ui/core';
import color from "../../assets/css/colors";
import {ReactComponent as Chat} from '../../assets/icons/comment-lines.svg';
import {ReactComponent as Share} from '../../assets/icons/share-alt.svg';
import John from '../../assets/images/stock-photo-male.png';
import SocialMediaButtons from '../Buttons/SocialMediaButtons';
import CloseButton from '../Buttons/CloseButton';
import Text from '../Typography/Text';
import ChatField from '../Textfields/ChatField';

const useStyles = makeStyles(() => ({
    floatingButtonWrapper: {
        position: 'fixed',
        right: 20,
        bottom: 40,
        '& .social-links': {
            textAlign: 'center',
            '& div': {
                marginBottom: 10,
            },
            '& div:last-child': {
                marginBottom: 20,
            },
            '& button': {
                padding: 10,
                backgroundColor: color.secondary.main,
                '& svg': {
                    width: 18,
                    height: 18,
                    '& path': {
                        fill: color.light.light + ' !important',
                    }
                }
            },
            '& button:hover': {
                backgroundColor: color.secondary.light,
                '& svg > path': {
                    fill: color.light.light,
                }
            },
        },
    },
    mainButtonContainer: {
        '& div:last-child': {
            marginTop: 20,
        },
        '& button': {
            padding: 15,
            backgroundColor: color.primary.main,
            '& svg': {
                width: 22,
                height: 22,
                '& path': {
                    fill: color.light.light,
                }
            }
        },
        '& button:hover': {
            backgroundColor: color.primary.light,
            '& svg > path': {
                fill: color.light.light,
            }
        },
    },
    chatBoxWrapper: {
        '& .MuiPopover-paper': {
            backgroundColor: 'unset',
        },
        '& .MuiPaper-elevation8': {
            boxShadow: 'unset',
            overflow: 'inherit'
        },
        '& svg': {
            width: 15,
            height: 15,
        },
    },
    chatBoxContainer: {
        marginRight: 10,
        border: '1px solid ' + color.light.dark,
        borderRadius: 5,
        boxShadow: '1px 0px 8px ' + color.gray.opacity
    },
    chatBoxHeader: {
        padding: '15px 15px 14px 20px',
        backgroundColor: color.light.main,
        borderBottom: '1px solid ' + color.light.dark,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        display: 'flex',
        '& div:first-child': {
            flex: 1,
            display: 'flex',
            '& div:first-child': {
                flex: 'none',
                marginRight: 15,
                '& img': {
                    width: 39,
                    height: 39,
                    borderRadius: 40,
                },
            },
            '& span > p': {
                fontSize: 14,
                lineHeight: '20px',
            },
            '& span > h6': {
                fontSize: 12,
                lineHeight: '20px',
            },
        },
        '& div:last-child': {
            flex: 'none',
            marginTop: 11,
            '& .MuiIconButton-root': {
                borderRadius: 5,
                padding: 10,
                '&:hover svg path': {
                  fill: color.primary.main,
                },
            },
        }
    },
    chatBoxContent: {
        padding: 20,
        height: 190,
        maxHeight: 190,
        overflowY: 'auto',
        overflowX: 'hidden',
        backgroundColor: color.light.main,
    },
    chatBoxFooter: {
        backgroundColor: color.light.main,
        borderTop: '1px solid ' + color.light.dark,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        '& .MuiInputBase-root': {
            border: 'unset',
            borderRadius: 'unset',
            backgroundColor: 'unset',
            fontWeight: 600,
        }
    }
}));

const FloatingButton = (props) => {
    const classes = useStyles();

    const [showSocialMedia, setShowSocialMedia] = React.useState(false);
    const [chatboxValue, setChatboxValue] = React.useState('');
    const [showChatBox, setShowChatBox] = React.useState(null);
    const openChatBox = Boolean(showChatBox);

    const handleOpenChatBox = (event) => {
        setShowChatBox(event.currentTarget);
    };

    const handleCloseChatBox = () => {
        setChatboxValue('');
        setShowChatBox(null);
    };

    const handleClickConnect = () => {
        setShowSocialMedia((prev) => !prev)
    }

    return (
        <ClickAwayListener onClickAway={() => setShowSocialMedia(false)}>
            <div className={classes.floatingButtonWrapper}>
                <SocialMediaButtons transition={showSocialMedia} tooltipPlacement="left" />
                <div className={classes.mainButtonContainer}>
                    <Tooltip 
                        arrow
                        placement="left"
                        title="Connect With Us">
                            <div>
                                <IconButton
                                    aria-label="connect" 
                                    style={{marginTop: -7}}
                                    onClick={handleClickConnect}
                                >
                                    <Share />
                                </IconButton>
                            </div>
                    </Tooltip>
                    <Popover
                        className={classes.chatBoxWrapper}
                        open={openChatBox}
                        anchorEl={showChatBox}
                        marginThreshold={30}
                        onClose={handleCloseChatBox}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                        }}
                    >
                        <div className={classes.chatBoxContainer}>
                            <div className={classes.chatBoxHeader}>
                                <div>
                                    <div>
                                        <img src={John} alt="tech-support" />
                                    </div>
                                    <span>
                                        <Text
                                            variant="body2"
                                            label="John Doe"
                                        />
                                        <Text
                                            variant="subtitle2"
                                            label="Tech Support"
                                        />
                                    </span>
                                </div>
                                <div>
                                    <CloseButton noTooltip onClick={handleCloseChatBox}/>
                                </div>
                            </div>
                            <div className={classes.chatBoxContent}>
                                <Text
                                    mb={10}
                                    variant="body1"
                                    label="Hi there!"
                                />
                                <Text
                                    variant="body1"
                                    label="Can I answer any question you might have?"
                                />
                            </div>
                            <div className={classes.chatBoxFooter}>
                                <ChatField
                                    autoFocus={true}
                                    name="chatbox"
                                    value={chatboxValue}
                                    onChange={(e) => setChatboxValue(e.currentTarget.value)}
                                    placeholder="Ask here..."
                                />
                            </div>
                        </div>
                    </Popover>
                    <Tooltip 
                        arrow
                        placement="left"
                        title="Chat Support">
                            <div>
                                <IconButton
                                    aria-label="chat" 
                                    style={{marginTop: -7}}
                                    onClick={handleOpenChatBox}
                                >
                                    <Chat />
                                </IconButton>
                            </div>
                    </Tooltip>
                </div>
            </div>
        </ClickAwayListener>
    );
}
export default FloatingButton;