import {
  UserAccessServiceClient,
  ListUserAccessRequest,
  ListClientAccessRequest,
  UserAccess,
} from '../proto/usrpb/useraccess_grpc_web_pb';
import { auth } from '../lib/auth/Auth';

const service = new UserAccessServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

export async function listUserAccess(param) {
  return new Promise((resolve, reject) => {
    let req = new ListUserAccessRequest();
    req.setUserId(param.userId);
    req.setRoleId(param.roleId);
    req.setMenu(param.menu);
    req.setSubMenu(param.subMenu);
    req.setPageName(param.pageName);
    req.setAccess(param.access);

    service.listUserAccess(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject().userAccessesList);
      }
    });
  });
}

export async function listClientAccess(param) {
  return new Promise((resolve, reject) => {
    let req = new ListClientAccessRequest();
    req.setUserId(param.userId);
    req.setRoleId(param.roleId);
    req.setMenu(param.menu);
    req.setSubMenu(param.subMenu);
    req.setPageName(param.pageName);
    req.setAccess(param.access);
    service.listClientAccess(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject().clientAccessesList);
      }
    });
  });
}

export async function saveUserAccess(param, newAccessType) {
  return new Promise((resolve, reject) => {
    let req = new UserAccess();
    req.setUserId(param.userId);
    req.setRoleId(param.roleId);
    req.setAccess(newAccessType);
    req.setPageId(param.pageId);
    req.setMenu(param.menu);
    req.setSubMenu(param.subMenu);
    req.setPageName(param.pageName);

    service.saveUserAccess(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
