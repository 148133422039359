// src/components/FinancialIcons.js
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ArrowsHorizontalIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path d="M8.02849 11.4688L4.80882 8.24908H20.9982V6.74908H4.80882L8.02849 3.52941L6.96783 2.46875L1.9375 7.49908L6.96783 12.5294L8.02849 11.4688Z" fill="#4A5560" />
        <path d="M22.0588 16.4991L17.0285 11.4688L15.9678 12.5294L19.1875 15.7491H2.99814V17.2491H19.1875L15.9678 20.4688L17.0285 21.5294L22.0588 16.4991Z" fill="#4A5560" />
    </SvgIcon>
);

const ChevronUpIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path d="M21.5294 16.2354L11.9991 6.70508L2.46875 16.2354L3.52941 17.2961L11.9991 8.82624L20.4688 17.2961L21.5294 16.2354Z" fill="#4A5560" />
    </SvgIcon>
);

const ChevronDownIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path d="M2.46875 7.76574L11.9991 17.2961L21.5294 7.76574L20.4687 6.70508L11.9991 15.1749L3.52941 6.70508L2.46875 7.76574Z" fill="#4A5560" />
    </SvgIcon>
);

const DoubleChevronRightIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path d="M14.2121 11.9991L4.68175 2.46875L3.62109 3.52941L12.0909 11.9991L3.62109 20.4687L4.68175 21.5294L14.2121 11.9991Z" fill="#4A5560" />
        <path d="M20.9167 11.9991L11.3863 2.46875L10.3257 3.52941L18.7955 11.9991L10.3257 20.4687L11.3863 21.5294L20.9167 11.9991Z" fill="#4A5560" />
    </SvgIcon>
);


const DoubleChevronLeftIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path d="M10.3257 11.9991L19.856 2.46875L20.9167 3.52941L12.4468 11.9991L20.9167 20.4687L19.856 21.5294L10.3257 11.9991Z" fill="#4A5560" />
        <path d="M3.62109 11.9991L13.1514 2.46875L14.2121 3.52941L5.74225 11.9991L14.2121 20.4687L13.1514 21.5294L3.62109 11.9991Z" fill="#4A5560" />
    </SvgIcon>
);

const EllipsesVerticalIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path d="M12 17.25C13.2426 17.25 14.25 18.2574 14.25 19.5C14.25 20.7426 13.2426 21.75 12 21.75C10.7574 21.75 9.75 20.7426 9.75 19.5C9.75 18.2574 10.7574 17.25 12 17.25ZM12 9.75C13.2426 9.75 14.25 10.7574 14.25 12C14.25 13.2426 13.2426 14.25 12 14.25C10.7574 14.25 9.75 13.2426 9.75 12C9.75 10.7574 10.7574 9.75 12 9.75ZM12 2.25C13.2426 2.25 14.25 3.25736 14.25 4.5C14.25 5.74264 13.2426 6.75 12 6.75C10.7574 6.75 9.75 5.74264 9.75 4.5C9.75 3.25736 10.7574 2.25 12 2.25Z" fill="#4A5560" />
    </SvgIcon>
);

const ChevronRightIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path d="M7.76378 2.46875L17.2941 11.9991L7.76378 21.5294L6.70312 20.4687L15.173 11.9991L6.70312 3.52941L7.76378 2.46875Z" fill="#4A5560" />
    </SvgIcon>
);


export {
    ArrowsHorizontalIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    DoubleChevronRightIcon,
    DoubleChevronLeftIcon,
    EllipsesVerticalIcon,
    ChevronRightIcon
};
