import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBody: {
      width: '100%',
      padding: 20,
  }
}));

export default function AccountSetupModal(props) {
  const classes = useStyles();

  return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.open}
            onClose={props.onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Fade in={props.open}>
                <div className={classes.modalBody}>
                    <React.Fragment>
                        {props.content}
                    </React.Fragment>
                </div>
            </Fade>
        </Modal>
  );
}