import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, LinearProgress } from '@material-ui/core';
import Text from '../Typography/Text';
import CloseButton from '../Buttons/CloseButton';
import fontFamily from '../../assets/css/fonts';
import color from '../../assets/css/colors';
import { downloadFile } from '../../services/FileService';
import Button from '../Buttons/Button';
import { notifyError } from '../Messages/Notification';

export default function FileDownloadModal2({
  selectedIds,
  onClose: handleClose,
  open: isOpen,
}) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setData(selectedIds);
        console.log(data)
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        setLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, []);

  const useStyles = makeStyles(() => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: 550,
      width: '100%',
      margin: '0 auto',
      float: 'clear',
      fontFamily: fontFamily.label,
      fontSize: 14,
      color: color.default.main,
    },
    modalHeader: {
      backgroundColor: 'white',
      padding: 25,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      display: 'flex',
      '& div:nth-child(1)': {
        flex: 1,
      },
      '& div:nth-child(2)': {
        flex: 'none',
        '& .MuiIconButton-root': {
          borderRadius: 5,
          padding: 10,
          '&:hover svg path': {
            fill: color.primary.main,
          },
        },
        '& svg:last-child': {
          width: 15,
          height: 15,
        },
      },
    },
    modalBody: {
      backgroundColor: 'white',
      padding: '0px 25px 25px 25px',
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      '& p': {
        fontSize: 16,
        lineHeight: '24px',
      },
      '& .MuiLinearProgress-root': {
        marginTop: 5,
      },
      '& .MuiLinearProgress-colorPrimary': {
        backgroundColor: color.primary.light,
      },
      '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: color.primary.main,
      },
    },
    closeButton: {
      '& button': {
        marginTop: '-5px !important',
      },
    },
    downloadButton: {
      marginTop: 20,
      padding: 13,
      background: data.length > 0 ? color.primary.main : '#dddddd',
      color: data.length > 0 ? '#ffffff' : '#909090',
      cursor: data.length > 0 ? 'pointer' : 'not-allowed',
      pointerEvents: data.length > 0 ? 'auto' : 'none',
      display: 'block',
      fontWeight: 700,
      borderRadius: 4,
      textAlign: 'center',
      textDecoration: 'none',
      '&:hover': {
        background: data.length > 0 ? color.primary.light : '#dddddd',
      },
      '&:active': {
        background: data.length > 0 ? color.primary.light : '#dddddd',
      },
    },
  }));

  const classes = useStyles();

  const handleDownload = async () => {
    setLoading(true);
    try {
      await downloadFile(data);
    } catch (error) {
      notifyError(error.message);
      console.error(error);
    }
    setLoading(false);
    handleClose();
  };

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 100 }}
    >
      <Fade in={isOpen}>
        <div style={{ width: 350 }}>
          <div className={classes.modalHeader}>
            <div>
              <Text mt={4} variant="h5" style={{ fontWeight: 700 }} label={'Download' + (loading ? 'ing' : '')} />
            </div>
            <div className={classes.closeButton}>
              <CloseButton
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
          </div>
          <div className={classes.modalBody}>
            {loading ? (
              <div>
                Please wait...
                <LinearProgress />
              </div>
            ) : (
              <div>
                <div>Selected file(s) ready to download.</div>
                <Button
                  disabled={loading}
                  loading={loading}
                  label="Download"
                  onClick={handleDownload}
                  mt={20}
                />
              </div>
            )}
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
