import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core'
import { listContraWallet } from '../../services/WalletService'
import color from '../../assets/css/colors'
import { lazyLoadSystemCode } from '../../services/CommonService'

const useStyles = makeStyles(() => ({
  dropdownWrapper: {
    display: 'flex',
    position: 'relative',
  },
  actionWrapper: {
    position: 'absolute',
    top: 29,
    right: 20,
    padding: 8,
    backgroundColor: 'unset',
    borderRadius: 5,
    '& svg': {
      width: 24,
      height: 24,
      cursor: 'pointer',
      '& path': {
        fill: color.primary.main,
      },
    },
  },
}))

export default function SelectSplitType(props) {
  const classes = useStyles()

  const [options, setOptions] = React.useState([])

  const getOptions = async () => {
    const data = await lazyLoadSystemCode("Reorg Code", "", "", "Split")

    setOptions(data.systemCodeList)
  }

  React.useEffect(() => {
    getOptions();
  }, []);

  return (
    <div className={classes.dropdownWrapper}>
      <FormControl
        fullWidth
        error={props.error}
        style={{
          marginTop: props.mt ? props.mt : 0,
          marginBottom: props.mb ? props.mb : 0,
        }}
        disabled={props.disabled}
      >
        <InputLabel
          className={props.disabled ? classes.disabled : ''}
          shrink
          required={props.required}
        >
          {props.label}
        </InputLabel>
        <Select
          displayEmpty
          fullWidth
          disabled={props.disabled}
          name={props.name}
          value={props.value ? props.value : ''}
          onChange={props.onChange}
        >
          <MenuItem key="blank" value="">
            <span style={{ color: '#bbbdc5' }}>{props.placeholder}</span>
          </MenuItem>
          {options.length ? (
            options.map((i) => (
              <MenuItem key={i.code} value={i.code}>
                {i.code} - {i.description}
              </MenuItem>
            ))
          ) : (
            <MenuItem key="" value=""></MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  )
}
