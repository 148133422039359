import {
  BalanceServiceClient,
  ListBalanceRequest,
} from "../proto/reportpb/balance_grpc_web_pb";
import { stringToProtoDate } from "./ConvertService";
import { auth } from "../lib/auth/Auth";
import { Pagination } from '../proto/utilspb/pagination_pb';

const service = new BalanceServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });

export async function listBalance(param, paging) {
  return new Promise((resolve, reject) => {
    let req = new ListBalanceRequest();
    req.setFromDate(stringToProtoDate(param.fromDate));
    req.setToDate(stringToProtoDate(param.toDate));
    req.setCorrespondent(param.correspondent);
    req.setBranch(param.branch);
    req.setAccountNo(param.accountNo);
    req.setMasterAccountNo(param.masterAccountNo);
    req.setAccountName(param.accountName);
    req.setRep(param.rep);
    req.setRange(param.range)
    req.setRollUp(param.rollUp)

    if (paging) {
      let pg = new Pagination();
      pg.setPageNo(paging.pageNo);
      pg.setPageSize(paging.rowsPerPage);
      pg.setSortName(paging.sortName);
      pg.setSortDirection(paging.sortDirection);
      pg.setFilterNamesList(paging.filterNames);
      pg.setFilterValuesList(paging.filterValues);
      req.setPagination(pg);
    }

    service.listBalance(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export function listBalanceHistory(param, paging) {
  return new Promise((resolve, reject) => {
    let req = new ListBalanceRequest();
    req.setFromDate(stringToProtoDate(param.fromDate));
    req.setToDate(stringToProtoDate(param.toDate));
    req.setCorrespondent(param.correspondent);
    req.setBranch(param.branch);
    req.setAccountNo(param.accountNo);
    req.setMasterAccountNo(param.masterAccountNo);
    req.setAccountName(param.accountName);
    req.setRep(param.rep);
    req.setUsrId(userId);

    if (paging) {
      let pg = new Pagination();
      pg.setPageNo(paging.pageNo);
      pg.setPageSize(paging.rowsPerPage);
      pg.setSortName(paging.sortName);
      pg.setSortDirection(paging.sortDirection);
      pg.setFilterNamesList(paging.filterNames);
      pg.setFilterValuesList(paging.filterValues);
      req.setPagination(pg);
    }

   service.listBalanceHistory(req, {}, (error, response) => {
     if (error) {
       reject(error);
     } else {
       resolve(response.toObject());
     }
   });
 });
}
