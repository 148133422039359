import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Popover, IconButton, Tooltip } from '@material-ui/core';
import ViewColumnRoundedIcon from '@material-ui/icons/ViewColumnRounded';
import fontFamily from '../../assets/css/fonts';

const useStyles = makeStyles(() => ({
  MuiPopover: {
    height: 400,
  },
  toggleColumnContainer: {
    minWidth: 190,
    padding: 20,
    fontFamily: fontFamily.label,
    fontSize: 14,
    '& > div:first-child': {
      display: 'flex',
      marginBottom: 20,
      '& > div:first-child': {
        flex: 1,
      },
      '& > div:last-child': {
        flex: 'none',
      },
    },
    '& > div': {
      marginBottom: 4,
    },
    '& > div span': {
      marginRight: 20,
      fontSize: 15,
      fontWeight: 700,
    },
    '& > div input': {
      marginRight: 10,
      cursor: 'pointer',
    },
    '& > div label': {
      cursor: 'pointer',
    },
    '& > div label, > div span': {
      position: 'relative',
      top: -1,
    },
  },
}));

export default function ToggleColumns(props) {
  const classes = useStyles();

  const { toggleColumnVisibility } = props.columnsVisibilityApi;
  const { columns, setColumns } = props.columnsApi;

  const [toggleColumns, setToggleColumns] = React.useState(
    props.columnsState.length > 0 ? props.columnsState : columns
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const popup = {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  };

  const areSomeTogglesNotToggled = toggleColumns.some(
    (col) => col.visible === false
  );

  React.useEffect(() => {
    (async () => {
      setColumns(props.columnsState.length > 0 ? props.columnsState : columns);
    })();
  }, []);

  const handleSelectAll = (e) => {
    const columnVisibility = (col) => {
      if (areSomeTogglesNotToggled === false) {
        return (col.visible = false);
      }
      return (col.visible = true);
    };

    columns.forEach(columnVisibility);
    toggleColumns.forEach(columnVisibility);

    setColumns(columns);
    setToggleColumns(toggleColumns);
  };

  const handleColumnsToggleState = (id) => {
    const columnVisibility = (col) => {
      if (col.id === id) {
        if (col.visible === true) {
          return (col.visible = false);
        }
        return (col.visible = true);
      }
    };

    toggleColumns.forEach(columnVisibility);

    setToggleColumns(toggleColumns);
  };

  const handlePopup = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopout = () => {
    setColumns(columns);
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip arrow title="Toggle Columns">
        <IconButton onClick={handlePopup}>
          <ViewColumnRoundedIcon />
        </IconButton>
      </Tooltip>
      <Popover
        className={classes.MuiPopover}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopout}
        anchorOrigin={popup.anchorOrigin}
        transformOrigin={popup.transformOrigin}
      >
        <div className={classes.toggleColumnContainer}>
          <div>
            <div>
              <span>Columns:</span>
            </div>
            <div>
              <input
                id="selectAll"
                type="checkbox"
                onChange={(e) => {
                  handleSelectAll(e);
                }}
                checked={areSomeTogglesNotToggled ? false : true}
              />
              <label htmlFor="selectAll">
                {areSomeTogglesNotToggled ? 'Select All' : 'Unselect All'}
              </label>
            </div>
          </div>
          {toggleColumns
            .filter((col) => col.field !== undefined)
            .sort(function(a, b) {
              if (a.label || b.label) {
                let valueA = a.label.toUpperCase();
                let valueB = b.label.toUpperCase();
                return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
              }
              return '';
            })
            .map((column, idx) => (
              <div key={idx}>
                <input
                  id={`checkbox-${idx}`}
                  type="checkbox"
                  onChange={(e) => {
                    toggleColumnVisibility(column.id);
                    handleColumnsToggleState(column.id);
                  }}
                  checked={column.visible !== false}
                />
                <label htmlFor={`checkbox-${idx}`}>{column.label}</label>
              </div>
            ))}
        </div>
      </Popover>
    </>
  );
}
