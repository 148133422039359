// source: proto/bankpb/dcrequest.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_type_date_pb = require('../../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.bankpb.CreateDcResponse', null, global);
goog.exportSymbol('proto.bankpb.DcRequest', null, global);
goog.exportSymbol('proto.bankpb.DcRequestSummary', null, global);
goog.exportSymbol('proto.bankpb.EmptyDCRequest', null, global);
goog.exportSymbol('proto.bankpb.ListDcRequest', null, global);
goog.exportSymbol('proto.bankpb.ListDcResponse', null, global);
goog.exportSymbol('proto.bankpb.ListDcSummaryResponse', null, global);
goog.exportSymbol('proto.bankpb.ReadDcRequest', null, global);
goog.exportSymbol('proto.bankpb.ReadDcResponse', null, global);
goog.exportSymbol('proto.bankpb.ReadPositionQtyResponse', null, global);
goog.exportSymbol('proto.bankpb.UpdateDcResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.DcRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bankpb.DcRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.DcRequest.displayName = 'proto.bankpb.DcRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.CreateDcResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bankpb.CreateDcResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.CreateDcResponse.displayName = 'proto.bankpb.CreateDcResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.UpdateDcResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bankpb.UpdateDcResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.UpdateDcResponse.displayName = 'proto.bankpb.UpdateDcResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.ReadDcRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bankpb.ReadDcRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.ReadDcRequest.displayName = 'proto.bankpb.ReadDcRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.ReadDcResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bankpb.ReadDcResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.ReadDcResponse.displayName = 'proto.bankpb.ReadDcResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.ListDcRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bankpb.ListDcRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.ListDcRequest.displayName = 'proto.bankpb.ListDcRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.ListDcResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.bankpb.ListDcResponse.repeatedFields_, null);
};
goog.inherits(proto.bankpb.ListDcResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.ListDcResponse.displayName = 'proto.bankpb.ListDcResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.DcRequestSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bankpb.DcRequestSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.DcRequestSummary.displayName = 'proto.bankpb.DcRequestSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.ListDcSummaryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.bankpb.ListDcSummaryResponse.repeatedFields_, null);
};
goog.inherits(proto.bankpb.ListDcSummaryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.ListDcSummaryResponse.displayName = 'proto.bankpb.ListDcSummaryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.ReadPositionQtyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bankpb.ReadPositionQtyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.ReadPositionQtyResponse.displayName = 'proto.bankpb.ReadPositionQtyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.bankpb.EmptyDCRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.bankpb.EmptyDCRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.bankpb.EmptyDCRequest.displayName = 'proto.bankpb.EmptyDCRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.DcRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.DcRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.DcRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.DcRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dcRequestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    walletId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    accountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    amt: jspb.Message.getFieldWithDefault(msg, 4, ""),
    transferType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    status: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 7, ""),
    broker: jspb.Message.getFieldWithDefault(msg, 8, ""),
    systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    confirmationId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    referenceId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 12, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 13, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    type: jspb.Message.getFieldWithDefault(msg, 15, ""),
    contraWalletId: jspb.Message.getFieldWithDefault(msg, 16, 0),
    modifiedAt: (f = msg.getModifiedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    modifiedBy: jspb.Message.getFieldWithDefault(msg, 18, ""),
    internalId: jspb.Message.getFieldWithDefault(msg, 19, ""),
    publicDescription: jspb.Message.getFieldWithDefault(msg, 20, ""),
    privateDescription: jspb.Message.getFieldWithDefault(msg, 21, ""),
    costBasis: jspb.Message.getFieldWithDefault(msg, 22, ""),
    fifoCostBasis: jspb.Message.getFieldWithDefault(msg, 23, ""),
    avgCostBasis: jspb.Message.getFieldWithDefault(msg, 24, ""),
    qty: jspb.Message.getFieldWithDefault(msg, 25, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 26, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 27, ""),
    bankId: jspb.Message.getFieldWithDefault(msg, 28, 0),
    requestType: jspb.Message.getFieldWithDefault(msg, 29, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.DcRequest}
 */
proto.bankpb.DcRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.DcRequest;
  return proto.bankpb.DcRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.DcRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.DcRequest}
 */
proto.bankpb.DcRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDcRequestId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWalletId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransferType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 9:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfirmationId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setContraWalletId(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModifiedAt(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifiedBy(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalId(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicDescription(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivateDescription(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setCostBasis(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setFifoCostBasis(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvgCostBasis(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBankId(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.DcRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.DcRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.DcRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.DcRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDcRequestId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getWalletId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAmt();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTransferType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getConfirmationId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getReferenceId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getContraWalletId();
  if (f !== 0) {
    writer.writeUint32(
      16,
      f
    );
  }
  f = message.getModifiedAt();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModifiedBy();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getInternalId();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getPublicDescription();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getPrivateDescription();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getCostBasis();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getFifoCostBasis();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getAvgCostBasis();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getBankId();
  if (f !== 0) {
    writer.writeUint32(
      28,
      f
    );
  }
  f = message.getRequestType();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
};


/**
 * optional uint32 dc_request_id = 1;
 * @return {number}
 */
proto.bankpb.DcRequest.prototype.getDcRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setDcRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 wallet_id = 2;
 * @return {number}
 */
proto.bankpb.DcRequest.prototype.getWalletId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setWalletId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.bankpb.DcRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string amt = 4;
 * @return {string}
 */
proto.bankpb.DcRequest.prototype.getAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string transfer_type = 5;
 * @return {string}
 */
proto.bankpb.DcRequest.prototype.getTransferType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setTransferType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string status = 6;
 * @return {string}
 */
proto.bankpb.DcRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string created_by = 7;
 * @return {string}
 */
proto.bankpb.DcRequest.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string broker = 8;
 * @return {string}
 */
proto.bankpb.DcRequest.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.type.Date system_date = 9;
 * @return {?proto.google.type.Date}
 */
proto.bankpb.DcRequest.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 9));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.bankpb.DcRequest} returns this
*/
proto.bankpb.DcRequest.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bankpb.DcRequest.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string confirmation_id = 10;
 * @return {string}
 */
proto.bankpb.DcRequest.prototype.getConfirmationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setConfirmationId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string reference_id = 11;
 * @return {string}
 */
proto.bankpb.DcRequest.prototype.getReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string correspondent = 12;
 * @return {string}
 */
proto.bankpb.DcRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string master_account_no = 13;
 * @return {string}
 */
proto.bankpb.DcRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string account_name = 14;
 * @return {string}
 */
proto.bankpb.DcRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string type = 15;
 * @return {string}
 */
proto.bankpb.DcRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional uint32 contra_wallet_id = 16;
 * @return {number}
 */
proto.bankpb.DcRequest.prototype.getContraWalletId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setContraWalletId = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional google.protobuf.Timestamp modified_at = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.bankpb.DcRequest.prototype.getModifiedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.bankpb.DcRequest} returns this
*/
proto.bankpb.DcRequest.prototype.setModifiedAt = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.clearModifiedAt = function() {
  return this.setModifiedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bankpb.DcRequest.prototype.hasModifiedAt = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string modified_by = 18;
 * @return {string}
 */
proto.bankpb.DcRequest.prototype.getModifiedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setModifiedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string internal_id = 19;
 * @return {string}
 */
proto.bankpb.DcRequest.prototype.getInternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setInternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string public_description = 20;
 * @return {string}
 */
proto.bankpb.DcRequest.prototype.getPublicDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setPublicDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string private_description = 21;
 * @return {string}
 */
proto.bankpb.DcRequest.prototype.getPrivateDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setPrivateDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string cost_basis = 22;
 * @return {string}
 */
proto.bankpb.DcRequest.prototype.getCostBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setCostBasis = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string fifo_cost_basis = 23;
 * @return {string}
 */
proto.bankpb.DcRequest.prototype.getFifoCostBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setFifoCostBasis = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string avg_cost_basis = 24;
 * @return {string}
 */
proto.bankpb.DcRequest.prototype.getAvgCostBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setAvgCostBasis = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string qty = 25;
 * @return {string}
 */
proto.bankpb.DcRequest.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string external_id = 26;
 * @return {string}
 */
proto.bankpb.DcRequest.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string symbol = 27;
 * @return {string}
 */
proto.bankpb.DcRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional uint32 bank_id = 28;
 * @return {number}
 */
proto.bankpb.DcRequest.prototype.getBankId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setBankId = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional string request_type = 29;
 * @return {string}
 */
proto.bankpb.DcRequest.prototype.getRequestType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequest} returns this
 */
proto.bankpb.DcRequest.prototype.setRequestType = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.CreateDcResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.CreateDcResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.CreateDcResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.CreateDcResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dcRequest: (f = msg.getDcRequest()) && proto.bankpb.DcRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.CreateDcResponse}
 */
proto.bankpb.CreateDcResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.CreateDcResponse;
  return proto.bankpb.CreateDcResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.CreateDcResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.CreateDcResponse}
 */
proto.bankpb.CreateDcResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.bankpb.DcRequest;
      reader.readMessage(value,proto.bankpb.DcRequest.deserializeBinaryFromReader);
      msg.setDcRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.CreateDcResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.CreateDcResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.CreateDcResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.CreateDcResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDcRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.bankpb.DcRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional DcRequest dc_request = 1;
 * @return {?proto.bankpb.DcRequest}
 */
proto.bankpb.CreateDcResponse.prototype.getDcRequest = function() {
  return /** @type{?proto.bankpb.DcRequest} */ (
    jspb.Message.getWrapperField(this, proto.bankpb.DcRequest, 1));
};


/**
 * @param {?proto.bankpb.DcRequest|undefined} value
 * @return {!proto.bankpb.CreateDcResponse} returns this
*/
proto.bankpb.CreateDcResponse.prototype.setDcRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bankpb.CreateDcResponse} returns this
 */
proto.bankpb.CreateDcResponse.prototype.clearDcRequest = function() {
  return this.setDcRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bankpb.CreateDcResponse.prototype.hasDcRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.UpdateDcResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.UpdateDcResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.UpdateDcResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.UpdateDcResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dcRequest: (f = msg.getDcRequest()) && proto.bankpb.DcRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.UpdateDcResponse}
 */
proto.bankpb.UpdateDcResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.UpdateDcResponse;
  return proto.bankpb.UpdateDcResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.UpdateDcResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.UpdateDcResponse}
 */
proto.bankpb.UpdateDcResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.bankpb.DcRequest;
      reader.readMessage(value,proto.bankpb.DcRequest.deserializeBinaryFromReader);
      msg.setDcRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.UpdateDcResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.UpdateDcResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.UpdateDcResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.UpdateDcResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDcRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.bankpb.DcRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional DcRequest dc_request = 1;
 * @return {?proto.bankpb.DcRequest}
 */
proto.bankpb.UpdateDcResponse.prototype.getDcRequest = function() {
  return /** @type{?proto.bankpb.DcRequest} */ (
    jspb.Message.getWrapperField(this, proto.bankpb.DcRequest, 1));
};


/**
 * @param {?proto.bankpb.DcRequest|undefined} value
 * @return {!proto.bankpb.UpdateDcResponse} returns this
*/
proto.bankpb.UpdateDcResponse.prototype.setDcRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bankpb.UpdateDcResponse} returns this
 */
proto.bankpb.UpdateDcResponse.prototype.clearDcRequest = function() {
  return this.setDcRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bankpb.UpdateDcResponse.prototype.hasDcRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.ReadDcRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.ReadDcRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.ReadDcRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.ReadDcRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    dcRequestId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.ReadDcRequest}
 */
proto.bankpb.ReadDcRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.ReadDcRequest;
  return proto.bankpb.ReadDcRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.ReadDcRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.ReadDcRequest}
 */
proto.bankpb.ReadDcRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDcRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.ReadDcRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.ReadDcRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.ReadDcRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.ReadDcRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDcRequestId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 dc_request_id = 1;
 * @return {number}
 */
proto.bankpb.ReadDcRequest.prototype.getDcRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.bankpb.ReadDcRequest} returns this
 */
proto.bankpb.ReadDcRequest.prototype.setDcRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.ReadDcResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.ReadDcResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.ReadDcResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.ReadDcResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dcRequest: (f = msg.getDcRequest()) && proto.bankpb.DcRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.ReadDcResponse}
 */
proto.bankpb.ReadDcResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.ReadDcResponse;
  return proto.bankpb.ReadDcResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.ReadDcResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.ReadDcResponse}
 */
proto.bankpb.ReadDcResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.bankpb.DcRequest;
      reader.readMessage(value,proto.bankpb.DcRequest.deserializeBinaryFromReader);
      msg.setDcRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.ReadDcResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.ReadDcResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.ReadDcResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.ReadDcResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDcRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.bankpb.DcRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional DcRequest dc_request = 1;
 * @return {?proto.bankpb.DcRequest}
 */
proto.bankpb.ReadDcResponse.prototype.getDcRequest = function() {
  return /** @type{?proto.bankpb.DcRequest} */ (
    jspb.Message.getWrapperField(this, proto.bankpb.DcRequest, 1));
};


/**
 * @param {?proto.bankpb.DcRequest|undefined} value
 * @return {!proto.bankpb.ReadDcResponse} returns this
*/
proto.bankpb.ReadDcResponse.prototype.setDcRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bankpb.ReadDcResponse} returns this
 */
proto.bankpb.ReadDcResponse.prototype.clearDcRequest = function() {
  return this.setDcRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bankpb.ReadDcResponse.prototype.hasDcRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.ListDcRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.ListDcRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.ListDcRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.ListDcRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromDate: (f = msg.getFromDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    toDate: (f = msg.getToDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    correspondent: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    transferType: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 9, ""),
    sign: jspb.Message.getFieldWithDefault(msg, 10, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 11, ""),
    usrId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    requestType: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.ListDcRequest}
 */
proto.bankpb.ListDcRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.ListDcRequest;
  return proto.bankpb.ListDcRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.ListDcRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.ListDcRequest}
 */
proto.bankpb.ListDcRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromDate(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransferType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSign(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUsrId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.ListDcRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.ListDcRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.ListDcRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.ListDcRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTransferType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSign();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUsrId();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getRequestType();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional google.type.Date from_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.bankpb.ListDcRequest.prototype.getFromDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.bankpb.ListDcRequest} returns this
*/
proto.bankpb.ListDcRequest.prototype.setFromDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bankpb.ListDcRequest} returns this
 */
proto.bankpb.ListDcRequest.prototype.clearFromDate = function() {
  return this.setFromDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bankpb.ListDcRequest.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.type.Date to_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.bankpb.ListDcRequest.prototype.getToDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.bankpb.ListDcRequest} returns this
*/
proto.bankpb.ListDcRequest.prototype.setToDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bankpb.ListDcRequest} returns this
 */
proto.bankpb.ListDcRequest.prototype.clearToDate = function() {
  return this.setToDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bankpb.ListDcRequest.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string correspondent = 3;
 * @return {string}
 */
proto.bankpb.ListDcRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.ListDcRequest} returns this
 */
proto.bankpb.ListDcRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_no = 4;
 * @return {string}
 */
proto.bankpb.ListDcRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.ListDcRequest} returns this
 */
proto.bankpb.ListDcRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string master_account_no = 5;
 * @return {string}
 */
proto.bankpb.ListDcRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.ListDcRequest} returns this
 */
proto.bankpb.ListDcRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string transfer_type = 6;
 * @return {string}
 */
proto.bankpb.ListDcRequest.prototype.getTransferType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.ListDcRequest} returns this
 */
proto.bankpb.ListDcRequest.prototype.setTransferType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string status = 7;
 * @return {string}
 */
proto.bankpb.ListDcRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.ListDcRequest} returns this
 */
proto.bankpb.ListDcRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string external_id = 8;
 * @return {string}
 */
proto.bankpb.ListDcRequest.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.ListDcRequest} returns this
 */
proto.bankpb.ListDcRequest.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string symbol = 9;
 * @return {string}
 */
proto.bankpb.ListDcRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.ListDcRequest} returns this
 */
proto.bankpb.ListDcRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string sign = 10;
 * @return {string}
 */
proto.bankpb.ListDcRequest.prototype.getSign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.ListDcRequest} returns this
 */
proto.bankpb.ListDcRequest.prototype.setSign = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string amount = 11;
 * @return {string}
 */
proto.bankpb.ListDcRequest.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.ListDcRequest} returns this
 */
proto.bankpb.ListDcRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional uint32 usr_id = 12;
 * @return {number}
 */
proto.bankpb.ListDcRequest.prototype.getUsrId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.bankpb.ListDcRequest} returns this
 */
proto.bankpb.ListDcRequest.prototype.setUsrId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string request_type = 13;
 * @return {string}
 */
proto.bankpb.ListDcRequest.prototype.getRequestType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.ListDcRequest} returns this
 */
proto.bankpb.ListDcRequest.prototype.setRequestType = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.bankpb.ListDcResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.ListDcResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.ListDcResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.ListDcResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.ListDcResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dcRequestsList: jspb.Message.toObjectList(msg.getDcRequestsList(),
    proto.bankpb.DcRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.ListDcResponse}
 */
proto.bankpb.ListDcResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.ListDcResponse;
  return proto.bankpb.ListDcResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.ListDcResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.ListDcResponse}
 */
proto.bankpb.ListDcResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.bankpb.DcRequest;
      reader.readMessage(value,proto.bankpb.DcRequest.deserializeBinaryFromReader);
      msg.addDcRequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.ListDcResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.ListDcResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.ListDcResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.ListDcResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDcRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.bankpb.DcRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DcRequest dc_requests = 1;
 * @return {!Array<!proto.bankpb.DcRequest>}
 */
proto.bankpb.ListDcResponse.prototype.getDcRequestsList = function() {
  return /** @type{!Array<!proto.bankpb.DcRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.bankpb.DcRequest, 1));
};


/**
 * @param {!Array<!proto.bankpb.DcRequest>} value
 * @return {!proto.bankpb.ListDcResponse} returns this
*/
proto.bankpb.ListDcResponse.prototype.setDcRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.bankpb.DcRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.bankpb.DcRequest}
 */
proto.bankpb.ListDcResponse.prototype.addDcRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.bankpb.DcRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.bankpb.ListDcResponse} returns this
 */
proto.bankpb.ListDcResponse.prototype.clearDcRequestsList = function() {
  return this.setDcRequestsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.DcRequestSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.DcRequestSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.DcRequestSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.DcRequestSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromDate: (f = msg.getFromDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    toDate: (f = msg.getToDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    correspondent: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    broker: jspb.Message.getFieldWithDefault(msg, 7, ""),
    type: jspb.Message.getFieldWithDefault(msg, 8, ""),
    status: jspb.Message.getFieldWithDefault(msg, 9, ""),
    transferType: jspb.Message.getFieldWithDefault(msg, 10, ""),
    amt: jspb.Message.getFieldWithDefault(msg, 11, ""),
    recurrence: jspb.Message.getFieldWithDefault(msg, 12, 0),
    symbol: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.DcRequestSummary}
 */
proto.bankpb.DcRequestSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.DcRequestSummary;
  return proto.bankpb.DcRequestSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.DcRequestSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.DcRequestSummary}
 */
proto.bankpb.DcRequestSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromDate(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransferType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmt(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecurrence(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.DcRequestSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.DcRequestSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.DcRequestSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.DcRequestSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTransferType();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAmt();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getRecurrence();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional google.type.Date from_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.bankpb.DcRequestSummary.prototype.getFromDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.bankpb.DcRequestSummary} returns this
*/
proto.bankpb.DcRequestSummary.prototype.setFromDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bankpb.DcRequestSummary} returns this
 */
proto.bankpb.DcRequestSummary.prototype.clearFromDate = function() {
  return this.setFromDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bankpb.DcRequestSummary.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.type.Date to_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.bankpb.DcRequestSummary.prototype.getToDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.bankpb.DcRequestSummary} returns this
*/
proto.bankpb.DcRequestSummary.prototype.setToDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.bankpb.DcRequestSummary} returns this
 */
proto.bankpb.DcRequestSummary.prototype.clearToDate = function() {
  return this.setToDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.bankpb.DcRequestSummary.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string correspondent = 3;
 * @return {string}
 */
proto.bankpb.DcRequestSummary.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequestSummary} returns this
 */
proto.bankpb.DcRequestSummary.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_name = 4;
 * @return {string}
 */
proto.bankpb.DcRequestSummary.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequestSummary} returns this
 */
proto.bankpb.DcRequestSummary.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_no = 5;
 * @return {string}
 */
proto.bankpb.DcRequestSummary.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequestSummary} returns this
 */
proto.bankpb.DcRequestSummary.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string master_account_no = 6;
 * @return {string}
 */
proto.bankpb.DcRequestSummary.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequestSummary} returns this
 */
proto.bankpb.DcRequestSummary.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string broker = 7;
 * @return {string}
 */
proto.bankpb.DcRequestSummary.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequestSummary} returns this
 */
proto.bankpb.DcRequestSummary.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string type = 8;
 * @return {string}
 */
proto.bankpb.DcRequestSummary.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequestSummary} returns this
 */
proto.bankpb.DcRequestSummary.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string status = 9;
 * @return {string}
 */
proto.bankpb.DcRequestSummary.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequestSummary} returns this
 */
proto.bankpb.DcRequestSummary.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string transfer_type = 10;
 * @return {string}
 */
proto.bankpb.DcRequestSummary.prototype.getTransferType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequestSummary} returns this
 */
proto.bankpb.DcRequestSummary.prototype.setTransferType = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string amt = 11;
 * @return {string}
 */
proto.bankpb.DcRequestSummary.prototype.getAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequestSummary} returns this
 */
proto.bankpb.DcRequestSummary.prototype.setAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional uint32 recurrence = 12;
 * @return {number}
 */
proto.bankpb.DcRequestSummary.prototype.getRecurrence = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.bankpb.DcRequestSummary} returns this
 */
proto.bankpb.DcRequestSummary.prototype.setRecurrence = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string symbol = 13;
 * @return {string}
 */
proto.bankpb.DcRequestSummary.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.DcRequestSummary} returns this
 */
proto.bankpb.DcRequestSummary.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.bankpb.ListDcSummaryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.ListDcSummaryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.ListDcSummaryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.ListDcSummaryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.ListDcSummaryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dcRequestsSummaryList: jspb.Message.toObjectList(msg.getDcRequestsSummaryList(),
    proto.bankpb.DcRequestSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.ListDcSummaryResponse}
 */
proto.bankpb.ListDcSummaryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.ListDcSummaryResponse;
  return proto.bankpb.ListDcSummaryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.ListDcSummaryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.ListDcSummaryResponse}
 */
proto.bankpb.ListDcSummaryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.bankpb.DcRequestSummary;
      reader.readMessage(value,proto.bankpb.DcRequestSummary.deserializeBinaryFromReader);
      msg.addDcRequestsSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.ListDcSummaryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.ListDcSummaryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.ListDcSummaryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.ListDcSummaryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDcRequestsSummaryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.bankpb.DcRequestSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DcRequestSummary dc_requests_summary = 1;
 * @return {!Array<!proto.bankpb.DcRequestSummary>}
 */
proto.bankpb.ListDcSummaryResponse.prototype.getDcRequestsSummaryList = function() {
  return /** @type{!Array<!proto.bankpb.DcRequestSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.bankpb.DcRequestSummary, 1));
};


/**
 * @param {!Array<!proto.bankpb.DcRequestSummary>} value
 * @return {!proto.bankpb.ListDcSummaryResponse} returns this
*/
proto.bankpb.ListDcSummaryResponse.prototype.setDcRequestsSummaryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.bankpb.DcRequestSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.bankpb.DcRequestSummary}
 */
proto.bankpb.ListDcSummaryResponse.prototype.addDcRequestsSummary = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.bankpb.DcRequestSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.bankpb.ListDcSummaryResponse} returns this
 */
proto.bankpb.ListDcSummaryResponse.prototype.clearDcRequestsSummaryList = function() {
  return this.setDcRequestsSummaryList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.ReadPositionQtyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.ReadPositionQtyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.ReadPositionQtyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.ReadPositionQtyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    positionQty: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.ReadPositionQtyResponse}
 */
proto.bankpb.ReadPositionQtyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.ReadPositionQtyResponse;
  return proto.bankpb.ReadPositionQtyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.ReadPositionQtyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.ReadPositionQtyResponse}
 */
proto.bankpb.ReadPositionQtyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPositionQty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.ReadPositionQtyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.ReadPositionQtyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.ReadPositionQtyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.ReadPositionQtyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositionQty();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string position_qty = 1;
 * @return {string}
 */
proto.bankpb.ReadPositionQtyResponse.prototype.getPositionQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.bankpb.ReadPositionQtyResponse} returns this
 */
proto.bankpb.ReadPositionQtyResponse.prototype.setPositionQty = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.bankpb.EmptyDCRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.bankpb.EmptyDCRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.bankpb.EmptyDCRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.EmptyDCRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.bankpb.EmptyDCRequest}
 */
proto.bankpb.EmptyDCRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.bankpb.EmptyDCRequest;
  return proto.bankpb.EmptyDCRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.bankpb.EmptyDCRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.bankpb.EmptyDCRequest}
 */
proto.bankpb.EmptyDCRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.bankpb.EmptyDCRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.bankpb.EmptyDCRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.bankpb.EmptyDCRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.bankpb.EmptyDCRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.bankpb);
