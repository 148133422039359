import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SignaturePad from 'react-signature-canvas';
import color from '../../assets/css/colors';
import Text from '../Typography/Text';
import Button from '../Buttons/Button';

const useStyles = makeStyles(() => ({
	signatureCanvasWrapper: {
		marginBottom: 25,
		'&:last-child': {
			marginBottom: 0,
		},
		'& .grd-row > .grd-cell-none': {
			marginLeft: 10,
		},
	},
	signatureLabel: {
		fontWeight: 600,
		color: color.gray.dark,
	},
	canvasWrapper: {
		'& canvas': {
			borderRadius: 5,
		},
	},
	clearButton: {
		'& button': {
			width: 740,
			marginTop: '-15px !important',
			padding: 7,
			borderRadius: 0,
			borderBottomRightRadius: 5,
			borderBottomLeftRadius: 5,
			border: 0,
			backgroundColor: color.gray.opacity,
			backgroundImage: 'unset',
			color: color.default.light,
			'&:hover': {
				backgroundColor: color.gray.opacity,
				color: color.primary.main,
				border: 0,
				opacity: 1,
			},
		},
	},
}));

const Signature = (props) => {
	const classes = useStyles();
	const sigCanvas = React.useRef({});

	const handleClearSignature = () => sigCanvas.current.clear();

	const handleSaveSignature = (name) => {
		if (name === 'primaryApplicantSignature') {
			props.setESignatureData({
				...props.eSignatureData,
				primaryApplicant: sigCanvas.current
					.getTrimmedCanvas()
					.toDataURL('image/png'),
			});
		} else {
			props.setESignatureData({
				...props.eSignatureData,
				coApplicant: sigCanvas.current
					.getTrimmedCanvas()
					.toDataURL('image/png'),
			});
		}
	};

	return (
		<div className={classes.signatureCanvasWrapper}>
			<Text
				className={classes.signatureLabel}
				required={props.required}
				mb={10}
				variant="subtitle2"
				label={props.label}
			/>
			<div className={classes.canvasWrapper}>
				<SignaturePad
					style={{ borderRadius: 5 }}
					ref={sigCanvas}
					penColor={color.default.main}
					canvasProps={{ width: 738, height: 250, className: 'sigCanvas' }}
					onEnd={() => handleSaveSignature(props.name)}
				/>
				<div className={classes.clearButton}>
					<Button label="Clear" onClick={handleClearSignature} />
				</div>
			</div>
		</div>
	);
};
export default Signature;
