import { createClientOnboarding } from '../../services/AdministratorService';
import { validateAccount } from '../../services/ClientAccountService';
import { attachFiles } from '../../services/CommonService';

export const SaveClient = async (
  accessData,
  accountData,
  accountCoOfficerData,
  identificationData,
  employmentData,
  associationData,
  attachments,
  marginAgreementAttachment,
  onboardingtAttachment,
  customerAccountAgreementAttachment,
  tradingData,
  investmentObjectiveData,
  eSignatureData,
  coIdentificationData,
  marginAgreementData,
  incomplete,
  accountId,
  trustedContactData,
  customerAccountAgreementData,
  progress
) => {
  await createClientOnboarding(
    accessData,
    accountData,
    accountCoOfficerData,
    identificationData,
    employmentData,
    associationData,
    attachments,
    marginAgreementAttachment,
    onboardingtAttachment,
    customerAccountAgreementAttachment,
    tradingData,
    investmentObjectiveData,
    eSignatureData,
    coIdentificationData,
    marginAgreementData,
    incomplete,
    accountId,
    trustedContactData,
    customerAccountAgreementData,
    progress
  );

  //ATTACHMENTS======================================
  await attachFiles(accountId, 'ClientAccount', attachments);
  await attachFiles(accountId, 'ClientAccount', marginAgreementAttachment);
  await attachFiles(accountId, 'ClientAccount', onboardingtAttachment);
  await attachFiles(accountId, 'ClientAccount', customerAccountAgreementAttachment);

  //VALIDATE ACCOUNT=================================
  await validateAccount(accountId);
};
