import React from 'react';
import FileBrowser from '../../components/Pages/FileBrowser';

export default function FileManager() {
  return (
    <FileBrowser
      title="File Manager"
      type="File"
    />
  );
}