import React from 'react'
import Select from 'react-select'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import color from '../../assets/css/colors'
import { listSystemCode } from '../../services/CommonService';

const useStyles = makeStyles(() => ({
    textFieldWrapper: {
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
    },
    textLabel: {
        fontWeight: 640,
        color: color.default.main,
        marginBottom: 6,
    },
    selectField: {
        '& .select__control': {
            minHeight: '48px !important',
        },
        '& .css-1jqq78o-placeholder': {
            fontWeight: 'bold',
            opacity: 0.4,
        },
        '& .css-1nmdiq5-menu': {
            fontWeight: 640,
            color: color.default.main,
        }
    }
}));

export const SelectSourceOfFunds = (props) => {
    const classes = useStyles();

    const [options, setOptions] = React.useState([]);

    React.useEffect(() => {
    if (options.length === 0) {
        getOptions();
    }
    // eslint-disable-next-line
    }, [props]);

    const getOptions = async () => {
        const { systemCodeList } = await listSystemCode({
            type: 'Fund Source',
            subType: '',
            orderBy: '',
        });

        let tempOptions = [];

        systemCodeList.forEach(element => {
            tempOptions.push({
                value: element.description,
                label: element.description,
                name: props.name,
            })
        });

        setOptions(tempOptions);
    };

    let label = !props.required ? props.label : props.label + ' *';

    return(
        <div className={classes.textFieldWrapper}>
            <label className={classes.textLabel}>{ label }</label>
            <Select
                className={classes.selectField}
                defaultValue={[]}
                isMulti
                options={options}
                classNamePrefix="select"
                id={props.id}
                name={props.name}
                disabled={props.disabled}
                required={props.required}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                InputLabelProps={{ shrink: true }}
            />
        </div>
    );
}