import React from 'react';
import Recaptcha from 'react-recaptcha';
import { ReactComponent as RegisterGraphic } from '../../../assets/graphics/register-graphic.svg';
import Text from '../../../components/Typography/Text';
import TextField from '../../../components/Textfields/TextField';
import SelectSystemCode from '../../../components/Dropdown/SelectSystemCode';
import PhoneNumber from '../../../components/Textfields/PhoneNumber';
import PasswordField from '../../../components/Textfields/PasswordField';
import { validateEmail, validateMobileNumber } from '../../../lib/validate/validate';
import { notifyError } from '../../../components/Messages/Notification';
import Button from '../../../components/Buttons/Button';
import { generateKey } from '../../../lib/generator/generator';

import {
  sendConfirmationEmail,
  checkEmailIfExists,
} from '../../../services/AdministratorService';
import { createRegistration } from '../../../services/RegistrationService';
import { clearLocalStorageVerification } from '../utils/utils';
import FormHeader from './FormHeader';

export default function FormAccess({ subHeader }) {
  const CryptoJS = require('crypto-js');

  const [isLoading, setIsLoading] = React.useState(false);
  const [showRecaptcha, setShowRecaptcha] = React.useState(false);
  const [isValidatedByRecaptcha, setIsValidatedByRecaptcha] = React.useState(false);
  const [accessData, setAccessData] = React.useState({
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    email: '',
    password: '',
    confirmPassword: '',
    securityWordHint: '',
    securityWord: '',
    promoCode: '',
    phoneNumber: '',
  });

  const [validateAccessData, setValidateAccessData] = React.useState({
    firstName: {
      err: false,
      msg: '',
    },
    lastName: {
      err: false,
      msg: '',
    },
    suffix: {
      err: false,
      msg: '',
    },
    email: {
      err: false,
      msg: '',
    },
    password: {
      err: false,
      msg: '',
    },
    confirmPassword: {
      err: false,
      msg: '',
    },
    securityWordHint: {
      err: false,
      msg: '',
    },
    securityWord: {
      err: false,
      msg: '',
    },
    phoneNumber: {
      err: false,
      msg: '',
    },
  });

  const handleAccessDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setAccessData({ ...accessData, [input.name]: input.value });

    if (accessData.firstName) {
      setShowRecaptcha(true);
    }
  };

  const validateCreateAccessSubmission = () => {
    const required = 'Required';

    const validateEmailAddress = () => {
      const valid = {
        err: false,
        msg: '',
      };

      if (!accessData.email) {
        return {
          err: true,
          msg: required,
        };
      }
      if (accessData.email) {
        if (validateEmail(accessData.email)) {
          return {
            err: true,
            msg: 'Invalid email address.',
          };
        }
      }

      return valid;
    };

    const validatePassword = (password, compare) => {
      const regex = {
        hasNumber: /\d/,
        hasWhiteSpace: /\s/,
        hasSpecialChars: /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/,
        hasLower: /[a-z]/,
        hasUpper: /[A-Z]/,
      };

      const valid = {
        err: false,
        msg: '',
      };

      if (!password) {
        return {
          err: true,
          msg: required,
        };
      }
      if (!regex.hasLower.test(password)) {
        return {
          err: true,
          msg: 'At least one lowercase letter [a-z].',
        };
      }
      if (!regex.hasUpper.test(password)) {
        return {
          err: true,
          msg: 'At least one uppercase letter [A-Z].',
        };
      }
      if (!regex.hasSpecialChars.test(password)) {
        return {
          err: true,
          msg: 'Allowed special characters: !@#$%-_.',
        };
      }
      if (!regex.hasNumber.test(password)) {
        return {
          err: true,
          msg: 'At least one number.',
        };
      }
      if (password.length < 8) {
        return {
          err: true,
          msg: 'Minimum of 8 characters.',
        };
      }
      if (regex.hasWhiteSpace.test(password)) {
        return {
          err: true,
          msg: 'Cannot contain white space.',
        };
      }
      if (compare.password || compare.confirmPassword) {
        if (compare.password !== compare.confirmPassword) {
          return {
            err: true,
            msg: 'Your password does not match.',
          };
        }
      }

      return valid;
    };

    setValidateAccessData({
      firstName: {
        err: !accessData.firstName,
        msg: required,
      },
      lastName: {
        err: !accessData.lastName,
        msg: required,
      },
      email: {
        err: validateEmailAddress().err,
        msg: validateEmailAddress().msg,
      },
      password: {
        err: validatePassword(accessData.password, { ...accessData }).err,
        msg: validatePassword(accessData.password, { ...accessData }).msg,
      },
      confirmPassword: {
        err: validatePassword(accessData.confirmPassword, {
          ...accessData,
        }).err,
        msg: validatePassword(accessData.confirmPassword, {
          ...accessData,
        }).msg,
      },
      securityWordHint: {
        err: !accessData.securityWordHint,
        msg: required,
      },
      securityWord: {
        err: !accessData.securityWord,
        msg: required,
      },
      phoneNumber: {
        err: validateMobileNumber(accessData.phoneNumber).err,
        msg: validateMobileNumber(accessData.phoneNumber).msg,
      },
    });

    const isValid = () => {
      if (
        !accessData.firstName ||
        !accessData.lastName ||
        !accessData.securityWordHint ||
        !accessData.securityWord ||
        validateEmailAddress().err ||
        validatePassword(accessData.password, { ...accessData }).err ||
        validateMobileNumber(accessData.phoneNumber).err
      ) {
        return false;
      }
      return true;
    };

    setShowRecaptcha(true);

    if (!isValid()) {
      return false;
    }

    if (!isValidatedByRecaptcha) {
      notifyError('Please verify that you are not a robot.');
      return false;
    }

    return true;
  };

  const handleRecaptchaVerifyCallback = (response) => {
    if (response) {
      setIsValidatedByRecaptcha(true);
    }
  };

  const handleSave = async () => {
    if (!validateCreateAccessSubmission()) {
      return;
    }

    setIsLoading(true);

    try {
      const { isExists } = await checkEmailIfExists({
        email: accessData.email,
      });

      if (isExists) {
        setValidateAccessData({
          ...validateAccessData,
          email: {
            err: true,
            msg: 'Email address already exist',
          },
        });

        return;
      }

      let data = {
        firstName: accessData.firstName.trim(),
        middleName: accessData.middleName.trim(),
        lastName: accessData.lastName.trim(),
        suffix: accessData.suffix.trim(),
        email: accessData.email,
        password: accessData.password,
        securityWordHint: accessData.securityWordHint,
        securityWord: accessData.securityWord,
        promoCode: accessData.promoCode,
        phoneNumber: accessData.phoneNumber,
      };

      // TODO: need to move this in BE
      let encrypted = CryptoJS.AES.encrypt(generateKey(15), 'verification-key');
      let key = encrypted.toString().replace(/\//g, 'aessl');

      await createRegistration({ verificationKey: key, ...data });

      let verificationUrl =
        'https://' + window.location.hostname + '/register/:verified?' + key;
      let param = {
        email: accessData.email,
        url: verificationUrl,
      };

      await sendConfirmationEmail(param);
      clearLocalStorageVerification();

      return (window.location.href = '/verification');
    } catch (error) {
      notifyError(error);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="register-b">
      <div className="register-b-ls">
        <FormHeader header="Create Your Access" subHeader={subHeader} />
        <div className="ls-b">
          <form className="access-form-w" autoComplete="off">
            <input type="hidden" autoComplete="off" />
            <div className="grd-row">
              <div className="grd-cell">
                <TextField
                  required={true}
                  error={validateAccessData.firstName.err}
                  msg={validateAccessData.firstName.msg}
                  autoFocus={true}
                  max={50}
                  tooltip="The information for last name and first name must matched the entries in your identification."
                  name="firstName"
                  label="First Name"
                  placeholder="First Name"
                  type="text"
                  value={accessData.firstName}
                  onChange={handleAccessDataChange}
                />
              </div>
              <div className="grd-cell">
                <TextField
                  max={20}
                  name="middleName"
                  label="Middle Name"
                  placeholder="Middle Name (Optional)"
                  type="text"
                  value={accessData.middleName}
                  onChange={handleAccessDataChange}
                />
              </div>
              <div className="grd-cell">
                <TextField
                  required={true}
                  error={validateAccessData.lastName.err}
                  msg={validateAccessData.lastName.msg}
                  max={20}
                  name="lastName"
                  label="Last Name"
                  placeholder="Last Name"
                  type="text"
                  value={accessData.lastName}
                  onChange={handleAccessDataChange}
                />
              </div>
              <div className="grd-cell">
                <SelectSystemCode
                  error={validateAccessData.suffix?.err}
                  msg={validateAccessData.suffix?.msg}
                  name="suffix"
                  label="Suffix"
                  placeholder="Suffix (Optional)"
                  type="Suffix"
                  subType="Client Site"
                  value={accessData.suffix}
                  onChange={handleAccessDataChange}
                />
              </div>
            </div>
            <div className="grd-row">
              <div className="grd-cell">
                <TextField
                  required={true}
                  error={validateAccessData.email.err}
                  msg={validateAccessData.email.msg}
                  max={50}
                  name="email"
                  label="Email Address"
                  placeholder="youremail@website.com"
                  type="text"
                  value={accessData.email}
                  onChange={handleAccessDataChange}
                />
              </div>
              <div className="grd-cell">
                <PhoneNumber
                  required={true}
                  error={validateAccessData.phoneNumber.err}
                  msg={validateAccessData.phoneNumber.msg}
                  name="phoneNumber"
                  label="Phone Number"
                  value={accessData.phoneNumber}
                  onChange={handleAccessDataChange}
                />
              </div>
            </div>
            <div className="grd-row">
              <div className="grd-cell">
                <PasswordField
                  required={true}
                  error={validateAccessData.password.err}
                  msg={validateAccessData.password.msg}
                  max={50}
                  showPasswordRequirement={true}
                  id="password"
                  name="password"
                  label="Password"
                  placeholder="Password"
                  type="password"
                  value={accessData.password}
                  onChange={handleAccessDataChange}
                />
              </div>
              <div className="grd-cell">
                <PasswordField
                  required={true}
                  error={validateAccessData.confirmPassword.err}
                  msg={validateAccessData.confirmPassword.msg}
                  max={50}
                  showPasswordRequirement={true}
                  name="confirmPassword"
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  type="password"
                  value={accessData.confirmPassword}
                  onChange={handleAccessDataChange}
                />
              </div>
            </div>
            <div className="grd-row">
              <div className="grd-cell">
                <SelectSystemCode
                  required={true}
                  error={validateAccessData.securityWordHint.err}
                  msg={validateAccessData.securityWordHint.msg}
                  name="securityWordHint"
                  label="Security Word Hint"
                  placeholder="Security Word Hint"
                  type="Security Question"
                  subType="Client Site"
                  value={accessData.securityWordHint}
                  onChange={handleAccessDataChange}
                />
              </div>
              <div className="grd-cell">
                <TextField
                  required={true}
                  error={validateAccessData.securityWord.err}
                  msg={validateAccessData.securityWord.msg}
                  max={50}
                  name="securityWord"
                  label="Security Word"
                  placeholder="Security Word"
                  type="text"
                  value={accessData.securityWord}
                  onChange={handleAccessDataChange}
                />
              </div>
            </div>
            {!!showRecaptcha && (
              <div className="recaptcha-c">
                <Text variant="subtitle1" label="Verify that you're not a robot" />
                <Recaptcha
                  sitekey="6LcJbFkeAAAAAMbMBV0ucyuDuVJKRc9wFb7-PFCW"
                  render="explicit"
                  onloadCallback={() => {}}
                  verifyCallback={handleRecaptchaVerifyCallback}
                />
              </div>
            )}
          </form>
          <div className="stepper-buttons">
            <div className="grd-cell">
              <Button
                loading={isLoading}
                disabled={isLoading}
                label={isLoading ? 'Submitting...' : 'Create and Proceed'}
                onClick={handleSave}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="register-b-rs">
        <div className="graphics-w">
          <RegisterGraphic />
        </div>
      </div>
    </div>
  );
}
