import{
	TicketServiceClient,
	Ticket,
	ListTicketRequest,
	ReadTicketRequest,
	DeleteTicketRequest,
} from '../proto/commonpb/ticket_grpc_web_pb';
import { auth } from '../lib/auth/Auth';
import { stringToProtoTimeSpan } from './ConvertService';


const service = new TicketServiceClient( 
	window.env.GRPC_ENDPOINT,
	{},
	{ ...auth }
);


export async function createTicket(param) {
	 return new Promise((resolve, reject) => {
		const req = new Ticket();
		req.setDescription(param.description);
		req.setStatus(param.status);
		req.setUsrId(param.userId);
		req.setTopic(param.topic);
		req.setTitle(param.title);

		service.createTicket(req, {}, (error, response) => {
			if (error) {
				reject(error);
			} else {
				resolve(response.toObject());
			}
			});
		});
	}

export async function updateTicket(param) {
	 return new Promise((resolve, reject) => {
		const req = new Ticket();
		req.setStatus(param.status);
		req.setTicketId(param.ticketId);

		service.updateTicket(req, {}, (error, response) => {
			if (error) {
				reject(error);
			} else {
				resolve(response.toObject());
			}
			});
		});
	}

export async function listTicket(param) {
	 return new Promise((resolve, reject) => {
		const req = new ListTicketRequest();
		req.setUsrId(param.userId);
		req.setTopic(param.topic);
		req.setTitle(param.title);
		req.setStatus(param.status);
		service.listTicket(req, {}, (error, response) => {
			if (error) {
				reject(error);
			} else {
				resolve(response.toObject());
			}
			});
		});
	}