import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Text from '../Typography/Text';
import color from '../../assets/css/colors';

const useStyles = makeStyles(() => ({
  radioGroup: {
      marginLeft: 0,
      marginRight: 0,
      '& .MuiRadio-root ': {
          padding: 6,
          marginRight: 5,
      },
      '& .MuiIconButton-label svg': {
          width: 25,
          height: 25,
      },
      '& .MuiTypography-body1': {
          fontSize: 14,
          fontWeight: 600,
      },
      '& .MuiIconButton-label svg path': {
          fill: 'unset',
      },
      '& .MuiIconButton-label:hover svg path': {
          fill: color.primary.main,
      },
      '& .Mui-checked': {
          color: color.primary.main
      },
  },
  radioGroupLabel: {
    color: color.default.main,
  }
}));

export default function RadioButtonGroup(props) {
  const classes = useStyles();

  return (
    <FormControl
      style={{marginTop: props.mt ? props.mt : 0, marginBottom: props.mb ? props.mb : 0}}
      component="fieldset"
    >
      <Text
          className={classes.radioGroupLabel}
          required={props.required}
          mb={10}
          variant="subtitle1"
          label={props.label}
      />
      <RadioGroup className={classes.radioGroup} name={props.name} value={props.value} onChange={props.onChange} style={props.style}>
        {props.options.map((value, index) => {
            return (
                <FormControlLabel key={index} value={value} control={<Radio />} label={value} disabled={props.disabled}/>
            )
        })}
      </RadioGroup>
    </FormControl>
  );
}