import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Backdrop, Fade, LinearProgress } from "@material-ui/core";
import Text from "../Typography/Text";
import Button from "../Buttons/Button";
import CloseButton from "../Buttons/CloseButton";
import { notifySuccess, notifyError } from "../Messages/Notification";
import { 
  downloadMonthlyStatement,
  batchDownloadMonthlyStatement 
} from "../../services/StatementService";
import { 
  downloadDailyTradeConfirmation, 
  batchDownloadDailyTradeConfirmation 
} from "../../services/DailyTradeConfirmService";
import fontFamily from "../../assets/css/fonts";
import color from "../../assets/css/colors";
import { formatPbDate } from '../../lib/fmt';

import { GridTitleStatement, GridTitleTradeConfirm } from "../contants/GridTitle";

export default function ReportDownloadModal({
  title,
  selectedIds,
  setSelectedIds,
  rows,
  parameters,
  onClose: handleClose,
  open: isOpen,
}) {
  const useStyles = makeStyles(() => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      maxWidth: 550,
      width: "100%",
      margin: "0 auto",
      float: "clear",
      fontFamily: fontFamily.label,
      fontSize: 14,
      color: color.default.main,
    },
    modalHeader: {
      backgroundColor: "white",
      padding: '20px 25px 25px 25px',
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      display: "flex",
      "& div:nth-child(1)": {
        flex: 1,
      },
      "& div:nth-child(2)": {
        flex: "none",
        "& .MuiIconButton-root": {
          borderRadius: 5,
          padding: 10,
          "&:hover svg path": {
            fill: color.primary.main,
          },
        },
        "& svg:last-child": {
          width: 15,
          height: 15,
        },
      },
    },
    modalBody: {
      backgroundColor: "white",
      padding: "0px 25px 25px 25px",
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      "& p": {
        fontSize: 16,
        lineHeight: "24px",
      },
      "& .MuiLinearProgress-root": {
        marginTop: 5,
      },
      "& .MuiLinearProgress-colorPrimary": {
        backgroundColor: color.primary.light,
      },
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: color.primary.main,
      },
    },
    closeButton: {
      "& button": {
        marginTop: "-5px !important",
      },
    },
    downloadButton: {
      marginTop: 20,
      padding: 13,
      background: selectedIds.length > 0 ? color.primary.main : "#dddddd",
      color: selectedIds.length > 0 ? "#ffffff" : "#909090",
      cursor: selectedIds.length > 0 ? "pointer" : "not-allowed",
      pointerEvents: selectedIds.length > 0 ? "auto" : "none",
      display: "block",
      fontWeight: 700,
      borderRadius: 4,
      textAlign: "center",
      textDecoration: "none",
      "&:hover": {
        background: selectedIds.length > 0 ? color.primary.light : "#dddddd",
      },
      "&:active": {
        background: selectedIds.length > 0 ? color.primary.light : "#dddddd",
      },
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    if (title === GridTitleTradeConfirm) {
      let params = [];

      for (let id of selectedIds) {
        const row = rows
          .filter((row) => row.accountId === id && row.tradeDate === parameters.tradeDate)
          .map((row) => {
            return {
              accountId: row.accountId,
              tradeDate: row.tradeDate,
            };
          });

        params.push(row[0]);
      }

      setTradeConfirmParams(params);
    }
    // eslint-disable-next-line
  }, [selectedIds]);

  const [loading, setLoading] = useState(false);
  const [tradeConfirmParams, setTradeConfirmParams] = useState([]);

  const handleDownload = async () => {
    try {
      setLoading(true);

      if (title === GridTitleStatement) {
        if (selectedIds.length > 1) {
          await batchDownloadMonthlyStatement(selectedIds, parameters);
        } else {
          await downloadMonthlyStatement(selectedIds[0], parameters);
        }
      } else {
        if (tradeConfirmParams.length > 1) {
          await batchDownloadDailyTradeConfirmation(tradeConfirmParams);
        } else {
          await downloadDailyTradeConfirmation(tradeConfirmParams[0]);
        }
      }

      notifySuccess(title + " Download Completed");
    } catch (error) {
      notifyError(error.message);
    } finally {
      if (selectedIds.length > 1) {
        setSelectedIds([]);
      }
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 100 }}
    >
      <Fade in={isOpen}>
        <div style={{ width: 350 }}>
          <div className={classes.modalHeader}>
            <div>
              <Text
                mt={4}
                variant="h2"
                label={"Download" + (loading ? "ing" : "")}
              />
            </div>
            <div className={classes.closeButton}>
              <CloseButton
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
          </div>
          <div className={classes.modalBody}>
            {loading ? (
              <div>
                Please wait...
                <LinearProgress />
              </div>
            ) : (
              <div>
                <div>
                  {
                    selectedIds.length > 1 
                    ? 'Selected (' + selectedIds.length + ') report(s) ready to download.'
                    : rows[parameters.dataIndex]?.accountNo + ' report is ready to download'
                  }
                </div>
                <Button
                  disabled={loading}
                  loading={loading}
                  label="Download"
                  onClick={handleDownload}
                  mt={20}
                />
              </div>
            )}
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
