export function processError(error) {
    const errorParts = error.message.split("[:]");
    if (errorParts.length === 2) {
      const [errorCode, message] = errorParts;
      return {
        code: error.code,
        errorCode,
        message
      };
    } else {
      // Handle cases where the error format is unexpected.
      return error
    }
  }