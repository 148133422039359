import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import NumberFormat from 'react-number-format'
import { Tooltip } from '@material-ui/core'
import color from '../../assets/css/colors'
import fontFamily from '../../assets/css/fonts'
import { ReactComponent as Info } from '../../assets/icons/info.svg'
import Text from '../Typography/Text'

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: color.light.main,
    maxWidth: 620,
    border: '1px solid ' + color.light.light,
    borderRadius: 10,
  },
}))(Tooltip)

const MoneyField = (props) => {
  const useStyles = makeStyles(() => ({
    moneyFieldWrapper: {
      position: 'relative',
    },
    inputFieldWrapper: {
      color: color.default.main,
      background: color.light.opacity,
      border: '1px solid ' + color.gray.opacity,
      borderRadius: 7,
      fontSize: 14,
      fontFamily: fontFamily.label,
      fontWeight: 600,
      lineHeight: '1.1876em',
      padding: '14px 15px 15px 14px',
      marginTop: 28,
      width: '100%',
      boxSizing: 'border-box',
      '&:focus': {
        border: '1px solid ' + color.primary.light + ' !important',
        outline: 'unset',
      },
      '&:focus + h6': {
        color: color.primary.main,
      },
      '&::placeholder': {
        color: '#b3b4bd',
      },
      '&:disabled': {
        border: '1px solid ' + color.gray.opacity + ' !important',
        color: '#b5b5b6',
        fontWeight: 'normal',
      },
    },
    numberLabel: {
      fontWeight: 600,
      color: props.disabled ? '#b9b9b9 !important' : color.default.main,
      lineHeight: '17px',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    tooltipWrapper: {
      position: 'absolute',
      top: 31,
      right: 1,
      padding: '9px 10px',
      backgroundColor: color.light.opacity,
      borderRadius: 5,
      '& svg': {
        width: 21,
        height: 21,
        cursor: 'help',
        '& path': {
          fill: color.primary.main,
        },
      },
    },
  }))

  const classes = useStyles()

  return (
    <div
      className={classes.moneyFieldWrapper}
      style={{ marginTop: props.mt ? 20 : 0 }}
    >
      <NumberFormat
        className={classes.inputFieldWrapper}
        disabled={props.disabled}
        placeholder={props.placeholder}
        value={props.value}
        onValueChange={(v) =>
          props.onChange({
            currentTarget: { name: props.name, value: v.value },
          })
        }
        thousandsGroupStyle="thousand"
        suffix="%"
        decimalSeparator="."
        displayType="input"
        type="text"
        thousandSeparator={true}
        allowNegative={true}
        isNumericString
      />
      <Text
        className={classes.numberLabel}
        required={props.required}
        variant="subtitle2"
        label={props.label}
      />
      {(props.tooltip || props.htmlTooltip) && (
        <div
          className={classes.tooltipWrapper}
          style={{ marginTop: props.mt ? 20 : 0 }}
        >
          {props.tooltip ? (
            <Tooltip arrow placement="top" title={props.tooltip}>
              <Info />
            </Tooltip>
          ) : (
            <HtmlTooltip
              placement="right"
              title={<React.Fragment>{props.htmlTooltip}</React.Fragment>}
            >
              <Info />
            </HtmlTooltip>
          )}
        </div>
      )}
    </div>
  )
}
export default MoneyField
