import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import color from '../../../assets/css/colors';
import fontFamily from '../../../assets/css/fonts';
import {
  notifyError,
  notifySuccess,
  notifyWarning,
} from '../../../components/Messages/Notification';
import { Modal, Backdrop, Fade, Box } from '@material-ui/core';

import AutoCompleteMasterAccountNo from '../../../components/AutoComplete/AutoCompleteMasterAccountNo';
import AutoCompleteCorrespondent from '../../../components/AutoComplete/AutoCompleteCorrespondent';
import AutoCompleteAccountNo from '../../../components/AutoComplete/AutoCompleteAccountNo';
import AutoCompleteBranch from '../../../components/AutoComplete/AutoCompleteBranch';
import AutoCompleteRep from '../../../components/AutoComplete/AutoCompleteRep';
import SelectSystemCode from '../../../components/Dropdown/SelectSystemCode';
import Button from '../../../components/Buttons/Button';
import Text from '../../../components/Typography/Text';

import { lazyAccountNo } from '../../../services/CommonService';
import {
  createAccountAccessByAccount,
  updateAccountAccess,
} from '../../../services/ClientAccountAccessService';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  fadeModalLg: {
    maxWidth: 900,
    maxHeight: '90vh',
    width: '100%',
    padding: '25px 30px 30px 30px',
    margin: '0px auto',
    backgroundColor: '#ffffff',
    border: 'none',
    borderRadius: 5,
    boxShadow: '0px 0px 10px #333333',
    boxSizing: 'border-box',
    outline: 'none',
    overflowY: 'auto',
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
}));

export default function ClientAccountAccessAddModal({
  onClose: handleClose,
  open: isOpen,
  id,
  loading,
  modalDataInit,
  isEdit,
}) {
  const classes = useStyles();
  const [modalData, setModalData] = React.useState(modalDataInit);

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;
    setModalData({ ...modalData, [input.name]: input.value });
  };

  const handleSave = async (data) => {
    if (
      (!data.correspondent || data.correspondent.trim().trim() === '') &&
      (!data.masterAccountNo || data.masterAccountNo.trim() === '') &&
      (!data.branch || data.branch.trim() === '') &&
      (!data.rep || !data.rep.trim() === '') &&
      (!data.accountNo || !data.accountNo.trim() === '')
    ) {
      notifyWarning('Please fill atleast one(1) more field');
      return;
    }

    if (!data.status) {
      notifyWarning('Status is required');
      return;
    }

    try {
      if (isEdit) {
        await updateAccountAccess(data);
      } else {
        const { accountsList } = await lazyAccountNo('', '', 'client', '', '');
        const accountNo = accountsList.find(
          (account) => account.accountNo === data.accountNo
        );

        data.accountId = accountNo?.accountId;

        if (!data.accountId) {
          notifyError('This is not a valid account no');
          return
        } else {
          await createAccountAccessByAccount(data);
        }
      }

      notifySuccess(
        (isEdit ? '' : 'New ') +
          'Account access has been ' +
          (isEdit ? 'updated' : 'added')
      );
    } catch (error) {
      console.error(error);
      notifyError(error.message);
    }

    handleClose();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalLg}>
          <div className="grd-row">
            <div className="grd-cell">
              <Text
                id="transition-modal-title"
                variant="h2"
                label={isEdit ? 'Edit Account Access' : 'Add New Account Access'}
              />
            </div>
          </div>
          <Box mt={5}>
            <form noValidate autoComplete="off">
              <div className="grd-row">
                <div className="grd-cell" style={{ marginRight: 30 }}>
                  <AutoCompleteCorrespondent
                    freeSolo={true}
                    name="correspondent"
                    label="Correspondent"
                    value={modalData.correspondent}
                    onChange={handleChange}
                    isAccessibleOnly={true}
                  ></AutoCompleteCorrespondent>
                </div>
                <div className="grd-cell" style={{ marginRight: 30 }}>
                  <AutoCompleteMasterAccountNo
                    freeSolo={true}
                    name="masterAccountNo"
                    label="Master Account No"
                    value={modalData.masterAccountNo}
                    correspondent={modalData.correspondent}
                    onChange={handleChange}
                    isAccessibleOnly={true}
                  ></AutoCompleteMasterAccountNo>
                </div>
              </div>
              <div className="grd-row">
                <div className="grd-cell" style={{ marginRight: 30 }}>
                  <AutoCompleteBranch
                    freeSolo={true}
                    name="branch"
                    label="Branch"
                    value={modalData.branch}
                    onChange={handleChange}
                    isAccessibleOnly={true}
                  ></AutoCompleteBranch>
                </div>
                <div className="grd-cell" style={{ marginRight: 30 }}>
                  <AutoCompleteRep
                    freeSolo={true}
                    name="rep"
                    label="Rep"
                    value={modalData.rep}
                    onChange={handleChange}
                    isAccessibleOnly={true}
                  ></AutoCompleteRep>
                </div>
              </div>
              <div className="grd-row">
                <div className="grd-cell" style={{ marginRight: 30 }}>
                  <AutoCompleteAccountNo
                    freeSolo={true}
                    name="accountNo"
                    label="Account No"
                    value={modalData.accountNo}
                    correspondent={modalData?.correspondent}
                    onChange={handleChange}
                    type="client"
                    isAccessibleOnly={true}
                  ></AutoCompleteAccountNo>
                </div>
                <div className="grd-cell" style={{ marginRight: 30 }}>
                  <SelectSystemCode
                    required
                    name="status"
                    label="Status"
                    type="Status"
                    subType="AI"
                    value={modalData.status}
                    onChange={handleChange}
                  ></SelectSystemCode>
                </div>
              </div>
              <div className={classes.modalFooter}>
                <div className="grd-cell-none" style={{ marginRight: 10, marginTop: 20 }}>
                  <Button
                    label="Close"
                    type="plus"
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </div>
                <div className="grd-cell-none">
                  <Button
                    type="search"
                    label={loading ? 'Saving...' : 'Save'}
                    onClick={() => {
                      handleSave(modalData);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
