import React, { useEffect } from 'react'
import { IconButton } from '@material-ui/core'
import { Create as EditIcon } from '@material-ui/icons'
import ClientGrid from '../../../components/ClientGrid/ClientGrid'
import ClientGrid2 from '../../../components/ClientGrid/ClientGrid2'
import Table, { columnType } from '../../../components/ClearingTable/Table';
import Button from '../../../components/Buttons/Button'
import AutoCompleteAccountNo from '../../../components/AutoComplete/AutoCompleteAccountNo'
import AutoCompleteSymbol from '../../../components/AutoComplete/AutoCompleteSymbol'
import SelectSystemCode from '../../../components/Dropdown/SelectSystemCode'
import Text from '../../../components/Typography/Text'
import WalletModal from '../../../components/Modals/WalletModal'
import { getProfileName } from '../../../services/ProfileService'
import { getCurrentUser,getAccess } from '../../../services/AuthService'
import {readClient, getAccessibleClient} from '../../../services/ClientAccountService'
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from '../../../components/Messages/Notification'
import { walletColumns, walletSummaryColumns } from '../../../lib/data/columns'
import {
  listWallet,
  createWallet,
  updateWallet,
  listWalletSummary,
} from '../../../services/WalletService'
import QueryParam from '../../../services/QueryParamService'
import AutoCompleteCorrespondent from '../../../components/AutoComplete/AutoCompleteCorrespondent'
import {
  CustomBodyRenderCurrency,
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderDate,
  CustomBodyRenderDateTime,
  CustomBodyRenderBoolean,
  CustomBodyRenderAlign,
  CustomBodyRenderHtmlString,
  CustomBodyRenderCenter,
  CustomBodyRenderMissing,
} from '../../../components/Table/CustomBodyRender';
import { formatPbDate, formatPbDateTime } from '../../../lib/fmt';

export default function Wallet() {
  const [rows, setRows] = React.useState([])
  const [rowData, setRowData] = React.useState({})
  const [open, setOpen] = React.useState(false)

  let title = '';
  const [loading, setLoading] = React.useState({
    search: false,
    save: false,
  })

  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      accountNo: '',
      masterAccountNo: '',
      correspondent: '',
      symbol: '',
      status: 'Active',
    }),
  )

  const [profileName, setProfileName] = React.useState('')
  const [user, setUser] = React.useState([])
  const [defaultAccount, setDefaultAccount] = React.useState([])


  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    let account_no = ''
    let correspondent = ''
    let masterAccountNo = ''
    let symbol = 'AXX'
    try {
      
      const serverProfile = await getProfileName()
      const loggeduser = getCurrentUser()
      setProfileName(serverProfile.toLowerCase())
      setUser(loggeduser)
      if(loggeduser.AccountId != 0) {
        const account = await readClient(loggeduser.AccountId)
        setDefaultAccount(account)
        account_no = account.client.accountNo
        correspondent = account.client.correspondent
        masterAccountNo = account.client.masterAccountNo
      }else {
        const account = await getAccessibleClient(loggeduser.UserId);
        setDefaultAccount(account);
        account_no = account.client.accountNo;
        correspondent = account.client.correspondent;
      }
      
      if(!getAccess("account_no", loggeduser.UserType)){
        setSearchData({ 
          ...searchData,
          accountNo: account_no,
          correspondent: correspondent,
          masterAccountNo: masterAccountNo,
          symbol: symbol,
        })
      }
    } catch (error) {
      notifyError(error.message)
    }

  }
  const GridButtons = ({
    tableManager,
    value,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return (
      <div>
        <div style={{ padding: 5 }}>
          {data.walletId !== 0 &&
            ['Pending Approval', 'Active'].includes(data.status) && (
              <IconButton aria-label="edit" onClick={() => handleOpen(data)}>
                <EditIcon style={{ height: 24, width: 24 }} />
              </IconButton>
            )}
        </div>
      </div>
    )
  }


  const columns2 = [
    {
      id: '',
      label: '',
      width: '40px',
      cellRenderer: GridButtons,
    },
    ...walletColumns,
  ]

  const columns = [
    {
      name: 'actions',
      label: 'Actions',
      sort: false,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return(
            <div>
              <div style={{ padding: 5 }}>
                {rows[dataIndex].walletId !== 0 &&
                  ['Pending Approval', 'Active'].includes(rows[dataIndex].status) && (
                    <IconButton aria-label="edit" onClick={() => handleOpen(rows[dataIndex])}>
                      <EditIcon style={{ height: 24, width: 24 }} />
                    </IconButton>
                  )}
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'symbol',
      label: 'Symbol',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'symbolDescription',
      label: 'Symbol Description',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'wallet',
      label: 'Wallet',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'accountNo',
      label: 'Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'note',
      label: 'Note',
      options: {
        customBodyRender: CustomBodyRenderHtmlString,
      }
    },
    {
      name: 'originalCusip',
      label: 'Original Cusip',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      // Already converted to string in handlesearch
      name: 'systemDate',
      label: 'System Date',
    },
    {
      name: 'createdBy',
      label: 'Created By',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
  ];

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
  };
  
  const handleSearchDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target

    setSearchData({ ...searchData, [input.name]: input.value })
  }

  const handleSearch = async () => {
    try {
      setLoading({ ...loading, search: true })

      QueryParam.set(searchData)
      let data;

        data = await listWallet(searchData)

        let rowsCopy = data.walletsList;

        const rowsCopyOutput = rowsCopy.map((data) => {
          let tempDate = formatPbDate(data.systemDate);
          data.systemDate = tempDate

          return data;
        })

        setRows(rowsCopyOutput)
        notifyInfo(
          data.walletsList.length > 0
            ? data.walletsList.length + ' search result(s)'
            : 'No records found.',
        )

    } catch (error) {
      notifyError(error.message)
      console.log(error)
      return
    } finally {
      setLoading({ ...loading, search: false })
    }
  }

  const handleOpen = (data) => {
    if (!data) {
      data = {
        walletId: '',
        accountId: '',
        symbol: '',
        accountNo: '',
        wallet: '',
        status: 'Pending Approval',
        note: '',
      }
    }

    setRowData(data)
    setOpen(true)
  }

  const handleClose = async (data, isEdit) => {
    if (!data) {
      setOpen(false)
      return
    }

    let valid = true

    if (!data.status) {
      notifyError('Status is required.')
      valid = false
    }

    if (!data.wallet) {
      notifyError('Wallet is required.')
      valid = false
    }

    if (!valid) {
      return
    }

    try {
      setLoading({ ...loading, save: true })

      if (isEdit) {
        const resp = await updateWallet(data)
        const rowsCopy = [...rows]
        const index = rows.indexOf(rowData)

        rowsCopy[index] = resp.wallet

        setRows(rowsCopy)
        notifySuccess('Wallet has been updated.')
      } else {
        const resp = await createWallet(data)

        setRows([resp.wallet, ...rows])
        notifySuccess('New Wallet has been added.')
      }
    } catch (error) {
      setLoading({ ...loading, save: false })
      notifyError(error.message)
    } finally {
      setOpen(false)
      setLoading({ ...loading, save: false })
    }
  }

  const getCsvData = async () => {
    const data = await listWallet(searchData)

    return data.walletsList
  }


  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Wallet" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading.search}
              loading={loading.search}
              type="search"
              label={loading.search ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
          <div className="grd-cell-none">
            <Button
              type="plus"
              label="Add Wallet"
              onClick={() => handleOpen()}
            />
          </div>
        </div>
        <div className="grd-row">
            <div className="grd-cell">
              <SelectSystemCode
                name="status"
                label="Status"
                placeholder="Status"
                type="Status"
                subType="Bank Account"
                value={searchData.status}
                onChange={handleSearchDataChange}
              />
            </div>
          <div className="grd-cell">
            <AutoCompleteCorrespondent
              isAccessibleOnly={true}
              name="correspondent"
              label={"Correspondent"}
              value={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteAccountNo
              isAccessibleOnly={true}
              name="accountNo"
              value={searchData.accountNo}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteSymbol
              name="symbol"
              value={searchData.symbol}
              onChange={handleSearchDataChange}
              assetType="DA"
            />
          </div>
        </div>
      </div>

      <div className="mt-20">
        {/* <ClientGrid
          title={'wallet'}
          rowIdField="walletId"
          columns={columns2}
          rows={rows}
          getCsvData={getCsvData}
          isLoading={loading.search}
          setRows={setRows}
        /> */}
        {/* <ClientGrid2
          title="Wallet"
          data={rows}
          columns={columns}
          options={options}
        /> */}
        <Table
          title={'Wallet'}
          data={rows}
          columns={columns}
          options={options}
        />
      </div>

      <WalletModal
        open={open}
        close={handleClose}
        data={rowData}
        loading={loading.save}
      />
    </div>
  )
}
