import React from 'react';
import { LinearProgress } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import TextField from '../../components/Textfields/TextField';
import PhoneNumber from '../../components/Textfields/PhoneNumber';
import DateTimePicker from '../../components/Textfields/DateTimePicker';
import MaskedField from '../../components/Textfields/MaskedField';
import AutoCompletePlace from '../../components/AutoComplete/AutoCompletePlace';
import AutoCompleteCountry from '../../components/AutoComplete/AutoCompleteCountry';
import AutoCompleteState from '../../components/AutoComplete/AutoCompleteState';
import Text from '../../components/Typography/Text';
import Link from '../../components/Typography/Link';
import Button from '../../components/Buttons/Button';
import FloatingButton from '../../components/Buttons/FloatingButton';
import RadioButtonGroup from '../../components/Buttons/RadioButtonGroup';
import MarginTypeRadioGroup from '../../components/Buttons/MarginTypeRadioGroup';
import LinkButton from '../../components/Buttons/LinkButton';
import SelectSystemCode from '../../components/Dropdown/SelectSystemCode';
import CreateCoOfficer from '../../components/Misc/CreateCoOfficer';
import Checkbox from '../../components/Misc/Checkbox';
import FAQ from '../../components/Misc/FAQ';
import RangeSlider from '../../components/Misc/RangeSlider';
import FileAttachments from '../../components/Misc/FileAttachments';
import ImageAttachment from '../../components/Misc/ImageAttachment';
import MessageModal from '../../components/Modals/MessageModal';
import MarginAgreementModal from '../../components/Modals/MarginAgreementModal';
import MarginDisclosureModal from '../../components/Modals/MarginDisclosureModal';
import OnboardingDisclosureModal from '../../components/Modals/OnBoardingDisclosureModal';
import CustomerAccountAgreementModal from '../../components/Modals/CustomerAccountAgreementModal';
import VerificationModal from '../../components/Modals/VerificationModal';
import { formatPbDate } from '../../lib/fmt';
import { dateFieldFormat } from '../../lib/format/format';
import {
  Notification,
  notifyError,
  notifyWarning,
} from '../../components/Messages/Notification';
import { ReactComponent as VerifyIdGraphic } from '../../assets/graphics/verify-id.svg';
import {
  validateEmail,
  validateDuplicateName,
  validateOwnerPercentage,
  validateDateOfBirth,
  validateExpirationDate,
  validateMobileNumber,
  validateSSNNo,
} from '../../lib/validate/validate';
import {
  associationMessage,
  validationMessage,
  submittionWarningMessage,
  accountTypeCorporateInformMessage,
} from '../../lib/data/message';
import { SaveClient } from './createClient';
import { SaveClient as SaveClient2 } from '../AccountSetup/SaveAccount';
import { getJumioStatus, getUrl } from '../../services/KycService';
import { readRegistration, verifyRegistration } from '../../services/RegistrationService';
import {
  SelectAccountType,
  SelectSubAccountType,
} from '../../components/Dropdown/SelectAccountType';
import { SelectSourceOfFunds } from '../../components/MultipleSelect/SelectSourceOfFunds';
import { getCorrespondentByUrl } from '../../services/TemplateService';
import { readClient } from '../../services/ClientAccountService';
import { listSystemCode } from '../../services/CommonService';
import { fetchDisclosures, saveClientDisclosure } from '../../services/DisclosureService';
import DisclosureByIdModal from '../../components/Modals/DisclosureByIdModal';

import FormAccess from './Components/FormAccess';
import BrowserCompatibilityNotification from './Components/BrowserCompatibilityNotification';
import Header from './Components/Header';
import FormSubmit from './Components/FormSubmit';

var CryptoJS = require('crypto-js');

export default function Register({ params }) {
  const confirm = useConfirm();

  const [accountId, setAccountId] = React.useState(0);
  const [userId, setUserId] = React.useState(0);
  const [investmentObjectives, setInvestmentObjectives] = React.useState([]);
  const [mounted, setMounted] = React.useState(false);
  const [activeFAQ, setActiveFAQ] = React.useState('');
  const [progress, setProgress] = React.useState(0);
  const [sameAsPrimaryAddress, setSameAsPrimaryAddress] = React.useState(false);
  const [isAttentionMessageShown, setIsAttentionMessageShown] = React.useState(false);
  const [attachments, setAttachments] = React.useState([]);
  const [marginAgreementAttachment, setMarginAgreementAttachment] = React.useState([]);
  const [onboardingAttachment, setOnboardingAttachment] = React.useState([]);
  const [customerAccountAgreementAttachment, setCustomerAccountAgreementAttachment] =
    React.useState([]);
  const [activeForm, setActiveForm] = React.useState(0);
  const [partialStatus, setPartialStatus] = React.useState(false);
  const [activeFormHeader, setActiveFormHeader] = React.useState('Create Your Access');
  const [correspondentData, setCorrespondentData] = React.useState({});
  const [selectedMarginTypes, setSelectedMarginTypes] = React.useState([]);
  const [isCustomerChkDisabled, setIsCustomerChkDisabled] = React.useState(true);
  const [verificationData, setVerificationData] = React.useState({
    transactionReference: '',
    redirectUrl: '',
    submittedVerification: false,
    status: '',
    result: false,
    id: 0,
  });

  const [dynamicDisclosures, setDynamicDisclosures] = React.useState([]);
  const [disclosusreId, setDisclosureId] = React.useState([]);
  const [disclosureTitle, setDisclosureTitle] = React.useState([]);
  const [disclosureIndex, setDisclosureIndex] = React.useState(null);

  const forms = [
    {
      name: 'access',
      label: 'Access',
    },
    {
      name: 'account',
      label: 'Account',
    },
    {
      name: 'identification',
      label: 'Identification',
    },
    {
      name: 'employment',
      label: 'Employment',
    },
    {
      name: 'trustedContact',
      label: 'Trusted Contact',
    },
    {
      name: 'association',
      label: 'Association',
    },
    {
      name: 'trading',
      label: 'Trading',
    },
    {
      name: 'objective',
      label: 'Objective',
    },
    {
      name: 'customerAccountAgreement',
      label: 'Customer Account Agreement',
    },
    {
      name: 'disclosures',
      label: 'Disclosures',
    },
    {
      name: 'submit',
      label: 'Submit',
    },
  ];

  const [loading, setLoading] = React.useState({
    submit: false,
    saveAndExit: false,
    verifyIdentity: false,
  });

  const [isCompleted, setIsCompleted] = React.useState({
    access: false,
    account: false,
    identification: false,
    employment: false,
    trustedContact: false,
    association: false,
    trading: false,
    objective: false,
    disclosures: false,
    customerAccountAgreement: false,
  });

  const [open, setOpen] = React.useState({
    attentionModal: false,
    validationModal: false,
    submittionModal: false,
    informModal: false,
    signatureModal: false,
    marginAgreementModal: false,
    marginDisclosureModal: false,
    verificationModal: false,
    onBoardingModal: false,
    customerAgreementModal: false,
    DisclosureByIdModal: false,
  });

  const [accessData, setAccessData] = React.useState({
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    email: '',
    password: '',
    confirmPassword: '',
    securityWordHint: '',
    securityWord: '',
    promoCode: '',
  });

  const [accountData, setAccountData] = React.useState({
    correspondent: '',
    accountNo: '',
    accountType: 'I',
    subAccountType: '',
    proofOfAddress: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    maritalStatus: '',
    phoneNumber: '',
    email: '',
    taxId: '',
    companyName: '',
    coFirstName: '',
    coMiddleName: '',
    coLastName: '',
    coSuffix: '',
    coAddress1: '',
    coAddress2: '',
    coCity: '',
    coState: '',
    coCountry: '',
    coZipCode: '',
    coPhoneNumber: '',
    coEmail: '',
    primaryOfficer: false,
    primaryOwner: false,
    ownershipPercentage: 0,
    primaryOfficerFirstName: '',
    primaryOfficerMiddleName: '',
    primaryOfficerLastName: '',
    primaryOfficerAddress1: '',
    primaryOfficerAddress2: '',
    primaryOfficerCity: '',
    primaryOfficerState: '',
    primaryOfficerCountry: '',
    primaryOfficerZipCode: '',
    primaryOfficerPhoneNumber: '',
    status: 'Pending',
  });

  const [validateAccountData, setValidateAccountData] = React.useState({
    accountType: {
      err: false,
      msg: '',
    },
    subAccountType: {
      err: false,
      msg: '',
    },
    address1: {
      err: false,
      msg: '',
    },
    city: {
      err: false,
      msg: '',
    },
    state: {
      err: false,
      msg: '',
    },
    country: {
      err: false,
      msg: '',
    },
    zipCode: {
      err: false,
      msg: '',
    },
    phoneNumber: {
      err: false,
      msg: '',
    },
    email: {
      err: false,
      msg: '',
    },
    taxId: {
      err: false,
      msg: '',
    },
    companyName: {
      err: false,
      msg: '',
    },
    coFirstName: {
      err: false,
      msg: '',
    },
    coLastName: {
      err: false,
      msg: '',
    },
    coAddress1: {
      err: false,
      msg: '',
    },
    coCity: {
      err: false,
      msg: '',
    },
    coState: {
      err: false,
      msg: '',
    },
    coCountry: {
      err: false,
      msg: '',
    },
    coZipCode: {
      err: false,
      msg: '',
    },
    coPhoneNumber: {
      err: false,
      msg: '',
    },
    coEmail: {
      err: false,
      msg: '',
    },
    primaryType: {
      err: false,
      msg: '',
    },
    ownershipPercentage: {
      err: false,
      msg: '',
    },
    primaryOfficerFirstName: {
      err: false,
      msg: '',
    },
    primaryOfficerLastName: {
      err: false,
      msg: '',
    },
    primaryOfficerAddress1: {
      err: false,
      msg: '',
    },
    primaryOfficerCity: {
      err: false,
      msg: '',
    },
    primaryOfficerState: {
      err: false,
      msg: '',
    },
    primaryOfficerCountry: {
      err: false,
      msg: '',
    },
    primaryOfficerZipCode: {
      err: false,
      msg: '',
    },
    primaryOfficerPhoneNumber: {
      err: false,
      msg: '',
    },
  });

  const [trustedContactData, setTrustedContactData] = React.useState({
    isTrustedContact: 'No',
    usrId: 0,
    ownerId: 0,
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    emailType: '',
    birthDate: '',
    type: '',
    id: '',
    countryOfIssuance: '',
    ssnCountryOfIssuance: '',
    ssnState: '',
    phone: '',
    phoneType: '',
    trustedContactAddress1: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    addressType: '',
    relationship: '',
    other: '',
  });

  const [validateTrustedContactData, setValidateTrustedContactData] = React.useState({
    firstName: {
      err: false,
      msg: '',
    },
    lastName: {
      err: false,
      msg: '',
    },
    emailType: {
      err: false,
      msg: '',
    },
    email: {
      err: false,
      msg: '',
    },
    birthDate: {
      err: false,
      msg: '',
    },
    type: {
      err: false,
      msg: '',
    },
    id: {
      err: false,
      msg: '',
    },
    ssnCountryOfIssuance: {
      err: false,
      msg: '',
    },
    ssnState: {
      err: false,
      msg: '',
    },
    phoneType: {
      err: false,
      msg: '',
    },
    phone: {
      err: false,
      msg: '',
    },
    addressType: {
      err: false,
      msg: '',
    },
    trustedContactAddress1: {
      err: false,
      msg: '',
    },
    city: {
      err: false,
      msg: '',
    },
    state: {
      err: false,
      msg: '',
    },
    country: {
      err: false,
      msg: '',
    },
    zipCode: {
      err: false,
      msg: '',
    },
    relationship: {
      err: false,
      msg: '',
    },
    other: {
      err: false,
      msg: '',
    },
  });

  const [accountCoOfficerData, setAccountCoOfficerData] = React.useState([]);

  const [validateAccountCoOfficerData, setValidateAccountCoOfficerData] = React.useState(
    []
  );

  const [identificationData, setIdentificationData] = React.useState({
    proofOfIdentity: '',
    ssnNo: '',
    birthDate: '',
    identificationType: '',
    identificationNo: '',
    issueDate: '',
    expirationDate: '',
    countryOfIssuance: '',
    taxCountry: '',
    citizenship: '',
    idAddress: true,
    taxAddress: true,
    citizenshipAddress: true,
    statusReason: '',
    kycStatus: '',
    state: '',
  });

  const [validateIdentificationData, setValidateIdentificationData] = React.useState({
    ssnNo: {
      err: false,
      msg: '',
      toolTip: '',
    },
    birthDate: {
      err: false,
      msg: '',
    },
    proofOfIdentity: {
      err: false,
      msg: '',
    },
    identificationType: {
      err: false,
      msg: '',
    },
    identificationNo: {
      err: false,
      msg: '',
    },
    issueDate: {
      err: false,
      msg: '',
    },
    expirationDate: {
      err: false,
      msg: '',
    },
    countryOfIssuance: {
      err: false,
      msg: '',
    },
    taxCountry: {
      err: false,
      msg: '',
    },
    citizenship: {
      err: false,
      msg: '',
    },
    idAddress: {
      err: false,
      msg: '',
    },
    taxAddress: {
      err: false,
      msg: '',
    },
    citizenshipAddress: {
      err: false,
      msg: '',
    },
  });

  const [coIdentificationData, setCoIdentificationData] = React.useState([]);

  const [validateCoIdentificationData, setValidateCoIdentificationData] = React.useState(
    []
  );

  const [employmentData, setEmploymentData] = React.useState({
    employmentStatus: '',
    employerName: '',
    previousEmployerName: '',
    occupation: '',
    doingBusinessAs: '',
    yearsEmployed: 0,
  });

  const [validateEmploymentData, setValidateEmploymentData] = React.useState({
    employmentStatus: {
      err: false,
      msg: '',
    },
    employerName: {
      err: false,
      msg: '',
    },
    previousEmployerName: {
      err: false,
      msg: '',
    },
    occupation: {
      err: false,
      msg: '',
    },
    doingBusinessAs: {
      err: false,
      msg: '',
    },
  });

  const [associationData, setAssociationData] = React.useState({
    isExecutive: 'No',
    stockSymbol: '',
    isRegisteredBroker: 'No',
    companyName: '',
    affiliatedPerson: '',
    affiliatedPersonPosition: '',
  });

  const [validateAssociationData, setValidateAssociationData] = React.useState({
    stockSymbol: {
      err: false,
      msg: '',
    },
    companyName: {
      err: false,
      msg: '',
    },
    affiliatedPerson: {
      err: false,
      msg: '',
    },
    affiliatedPersonPosition: {
      err: false,
      msg: '',
    },
    attachments: {
      err: false,
      msg: '',
    },
  });

  const [tradingData, setTradingData] = React.useState({
    marginType: '',
    readMarginDisclosureStatement: false,
    readMarginAgreement: false,
    stocks: true,
    options: false,
  });

  const [validateTradingData, setValidateTradingData] = React.useState({
    marginType: {
      err: false,
      msg: '',
    },
    readMarginDisclosureStatement: {
      err: false,
      msg: '',
    },
    readMarginAgreement: {
      err: false,
      msg: '',
    },
    tradingPrivileges: {
      err: false,
      msg: '',
    },
  });

  const [marginAgreementData, setMarginAgreementData] = React.useState({
    accountName: '',
    clientName: '',
    account: '',
    rep: '',
  });

  const [validateMarginAgreementData, setValidateMarginAgreementData] = React.useState({
    accountName: {
      err: false,
      msg: '',
    },
    clientName: {
      err: false,
      msg: '',
    },
  });

  const [investmentObjectiveData, setInvestmentObjectiveData] = React.useState({
    investmentObjective: '',
    annualIncome: '',
    liquidNetWorth: '',
    fund: '',
    multipleFunds: [],
    other: '',
    taxBracket: 0,
    riskTolerance: '',
    natureOfBusiness: '',
  });

  const [validateInvestmentObjectiveData, setValidateInvestmentObjectiveData] =
    React.useState({
      investmentObjective: {
        err: false,
        msg: '',
      },
      annualIncome: {
        err: false,
        msg: '',
      },
      liquidNetWorth: {
        err: false,
        msg: '',
      },
      fund: {
        err: false,
        msg: '',
      },
      riskTolerance: {
        err: false,
        msg: '',
      },
      other: {
        err: false,
        msg: '',
      },
    });

  const [customerAccountAgreementData, setCustomerAccountAgreementData] = React.useState({
    acceptCustomerAgreement: false,
  });

  const [validateCustomerAccountAgreementData, setValidateCustomerAccountAgreementData] =
    React.useState({
      acceptCustomerAgreement: {
        err: false,
        msg: '',
      },
    });

  const [eSignatureData, setESignatureData] = React.useState({
    primaryApplicant: '',
    coApplicant: '',
  });

  React.useEffect(() => {
    const readVerification = async () => {
      // Do not reset activeForm to 0 on refresh if email is already verified
      if (
        window.location.href
          .split(':')
        [window.location.href.split(':').length - 1].includes('verified')
      ) {
        setActiveForm(1);
        setActiveFAQ('Account');
        setActiveFormHeader('Account Information');
        setProgress(10);
        setIsCompleted({ ...isCompleted, access: true });
      }
      if (!mounted) {
        let redirectUrl = localStorage.getItem('redirectUrl');
        let generatedId = localStorage.getItem('user-id'); // this is account ID
        let res = null;
        if (
          window.location.href
            .split(':')
          [window.location.href.split(':').length - 1].includes('verified')
        ) {
          try {
            const param = {
              verificationKey: window.location.href.split('?')[1],
            };
            res = await readRegistration(param);
          } catch (error) {
            console.error(error);
          }
        }
        let encryptedKey = res?.verificationKey;
        if (encryptedKey) {
          let { firstName, middleName, lastName, suffix, email, promoCode, phoneNumber } =
            res;

          let pathName = params.location.search;
          let encryptedPathKey = pathName.split('?').pop();
          let cleanPathKey = encryptedPathKey.replace(/aessl/g, '/');
          let decryptedPathKey = CryptoJS.AES.decrypt(cleanPathKey, 'verification-key');
          let pathKey = decryptedPathKey.toString(CryptoJS.enc.Utf8);

          let cleanKey = encryptedKey.replace(/aessl/g, '/');
          let decryptedKey = CryptoJS.AES.decrypt(cleanKey, 'verification-key');
          let key = decryptedKey.toString(CryptoJS.enc.Utf8);

          if (pathKey === key) {
            const { userId, accountId, correspondent, accountNo } =
              await verifyRegistration();
            setUserId(userId);
            setAccountId(accountId);
            localStorage.setItem('user-id', accountId);
            if (generatedId) {
              setAccountId(generatedId);
            }

            setAccessData({
              ...accessData,
              firstName: firstName,
              middleName: middleName,
              lastName: lastName,
              suffix: suffix,
              email: email,
              promoCode: promoCode,
            });

            setAccountData({
              ...accountData,
              email: email,
              accountNo: accountNo,
              correspondent: correspondent,
              phoneNumber: phoneNumber,
              primaryOfficerFirstName: firstName,
              primaryOfficerMiddleName: middleName,
              primaryOfficerLastName: lastName,
              primaryOfficerSuffix: suffix,
            });

            setMarginAgreementData({
              ...marginAgreementData,
              accountName: firstName + ' ' + lastName + ' ' + suffix,
              clientName: firstName + ' ' + lastName + ' ' + suffix,
            });

            setActiveForm(1);
            setActiveFAQ('Account');
            setActiveFormHeader('Account Information');
            setProgress(10);
            setIsCompleted({ ...isCompleted, access: true });
          } else {
            return (window.location.href = '/');
          }

          if (redirectUrl) {
            let verifyData = { ...verificationData };

            verifyData.transactionReference =
              localStorage.getItem('transactionReference');
            verifyData.redirectUrl = localStorage.getItem('redirectUrl');
            verifyData.id = localStorage.getItem('user-id'); // this is account id
            verifyData.status = localStorage.getItem('verifyStatus');

            let rs = localStorage.getItem('verifyResult');

            if (rs) {
              if (rs === 'APPROVED_VERIFIED') {
                verifyData.result = true;
              }
            }
            setVerificationData(verifyData);
          }
        }
      }
    };
    handleGetCorrespondent();
    handlGetInvestmentObjectives();
    readVerification();
    return () => {
      setMounted(true);
    };
    // eslint-disable-next-line
  }, [mounted]);

  const handletrustedContactDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    if (input.name === 'isTrustedContact' && input.value === 'Yes') {
      if (isCompleted.trustedContact) {
        setIsCompleted({ ...isCompleted, trustedContact: false });
        setProgress((prevProgress) => prevProgress - 10);
      }
    }
    if (input.name === 'isTrustedContact' && input.value === 'No') {
      if (!isCompleted.trustedContact) {
        setIsCompleted({ ...isCompleted, trustedContact: true });
        setProgress((prevProgress) => prevProgress + 10);
      }
    }

    setTrustedContactData({
      ...trustedContactData,
      [input.name]: input.type === 'checkbox' ? input.checked : input.value,
    });
  };

  const handlGetInvestmentObjectives = async () => {
    const objectivesResp = await listSystemCode({
      type: 'Investment Objective',
      subType: '',
    });

    setInvestmentObjectives(objectivesResp.systemCodeList);
  };

  const handleGetCorrespondent = async () => {
    try {
      let url = window.location.origin;
      let tempSelectedMarginTypes = [];
      //get correspondent
      const serverCorrespondent = await getCorrespondentByUrl(url);
      let correspondentResp = serverCorrespondent.correspondent;
      setCorrespondentData(correspondentResp);

      // Set selected margin types
      if (correspondentResp.isMargin) {
        tempSelectedMarginTypes.push('Margin Account');
      }

      if (correspondentResp.isCash) {
        tempSelectedMarginTypes.push('Cash Account');
      }

      setSelectedMarginTypes(tempSelectedMarginTypes);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseVerification = async (data) => {
    if (data.transactionReference !== '') {
      localStorage.setItem('transactionReference', data.transactionReference);
      localStorage.setItem('redirectUrl', data.redirectUrl);
    }

    localStorage.setItem('verifyStatus', data.status);

    if (data.status === 'error' || data.status === '') {
      notifyError('Error in verification. Please try again');
      clearVerification();
      return;
    }

    let fetchCtr = 0;

    if (data.status === 'success') {
      setOpen({ ...open, verificationModal: false });
      setLoading({ ...loading, verifyIdentity: true });
      if (data.transactionReference === '') return;
      setTimeout(async function () {
        await fetchJumioData(fetchCtr, data);
      }, 50000);
    } else {
      setOpen({ ...open, verificationModal: false });
    }
  };

  const clearVerification = () => {
    localStorage.removeItem('transactionReference');
    localStorage.removeItem('redirectUrl');
    localStorage.removeItem('verifyStatus');
    localStorage.removeItem('verifyResult');

    let data = { ...verificationData };

    data.transactionReference = '';
    data.result = false;
    data.status = '';
    data.redirectUrl = '';

    setVerificationData(data);
  };

  async function fetchJumioData(ctr, data) {
    if (!data.transactionReference || data.transactionReference === '') {
      return;
    }
    if (ctr === 3) {
      notifyError('Unable to get result please try again ');
      setLoading({ ...loading, verifyIdentity: false });
      clearVerification();
      return;
    }

    ctr++;

    try {
      const dt = await getJumioStatus(data);
      let iData = { ...identificationData };
      let aData = { ...accountData };

      if (dt.result === 'APPROVED_VERIFIED') {
        data.result = true;

        localStorage.setItem('verifyResult', dt.result);

        iData.identificationNo = dt.identificationNo;

        if (dt.idType === 'DRIVING_LICENSE') {
          iData.idType = 'Driver License';
          iData.identificationType = 'Driver License';
        } else if (dt.idType === 'PASSPORT') {
          iData.idType = 'Passport';
          iData.identificationType = 'Passport';
        }

        iData.issueDate = dt?.issueDate
          ? dateFieldFormat(formatPbDate(dt?.issueDate))
          : '';
        iData.expirationDate = dt?.expiry
          ? dateFieldFormat(formatPbDate(dt?.expiry))
          : '';
        iData.birthDate = dt?.idDob ? dateFieldFormat(formatPbDate(dt?.idDob)) : '';
        iData.countryOfIssuance = dt.idCountry;
        iData.proofOfIdentity = dt.idImg;
        iData.state = dt.state;

        if (dt.screeningStatus !== 'DONE') {
          iData.kycStatus = 'Pending';
          iData.statusReason = 'Screening Not Done';
        } else {
          if (dt.screenResult > 0) {
            iData.kycStatus = 'Denied';
            iData.statusReason = 'Found ' + dt.screenResult + ' Match';
          } else {
            iData.kycStatus = 'Approved';
            aData.status = 'Active';
          }
        }

        setIdentificationData(iData);
        setAccountData(aData);
        setVerificationData(data);
        setLoading({ ...loading, verifyIdentity: false });
      } else if (dt.result === '') {
        notifyError('Unable to get result please try again ');
        setLoading({ ...loading, verifyIdentity: false });
        clearVerification();
        return;
      } else if (dt.result !== '') {
        let rejectReason = dt.rejectReason === '' ? dt.result : dt.rejectReason;

        notifyError('Invalid Verification: ' + rejectReason);
        setLoading({ ...loading, verifyIdentity: false });

        iData.kycStatus = 'Failed';
        if (dt.rejectReason !== '') {
          iData.statusReason = dt.rejectReason;
        } else {
          iData.statusReason = dt.result;
        }
        setIdentificationData(iData);
        clearVerification();
        return;
      }
    } catch (e) {
      console.error(e);
    }
  }

  const handleVerificationClick = async (e) => {
    let verifyData = { ...verificationData };

    if (verifyData.status === 'success') {
      handleCloseVerification(verifyData);
      return;
    }

    if (verifyData.redirectUrl === '') {
      verifyData.status = '';
      verifyData.id = accountId;

      try {
        const data = await getUrl(verifyData);

        verifyData.redirectUrl = data.redirectUrl;
        verifyData.transactionReference = data.transactionReference;
      } catch (e) {
        console.error(e);
        return;
      }
    }

    verifyData.id = accountId;

    setVerificationData(verifyData);
    setOpen({ ...open, verificationModal: true });
  };

  const handleAccountDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    if (input.name === 'accountType' && ['C', 'I'].includes(input.value)) {
      accountData.subAccountType = '';
      accountData.coFirstName = '';
      accountData.coMiddleName = '';
      accountData.coLastName = '';
      accountData.coPhoneNumber = '';
      accountData.coEmail = '';
      accountData.coAddress1 = '';
      accountData.coAddress2 = '';
      accountData.coCity = '';
      accountData.coState = '';
      accountData.coZipCode = '';
    }

    if (input.name === 'accountType' && ['J', 'I'].includes(input.value)) {
      accountData.companyName = '';
      accountData.taxId = '';
      accountData.primaryOfficer = false;
      accountData.primaryOwner = false;
      accountData.ownershipPercentage = 0;
      accountData.primaryOfficerAddress1 = '';
      accountData.primaryOfficerAddress2 = '';
      accountData.primaryOfficerCity = '';
      accountData.primaryOfficerState = '';
      accountData.primaryOfficerZipCode = '';
      accountData.primaryOfficerPhoneNumber = '';
      setAccountCoOfficerData([]);
      setValidateAccountCoOfficerData([]);
      setCoIdentificationData([]);
      setValidateCoIdentificationData([]);
    }

    if (input.name === 'accountType') {
      let progressValue = 0;
      let accountStatus = isCompleted.account;
      let identificationStatus = isCompleted.identification;
      let employmentStatus = isCompleted.employment;

      if (['I', 'J'].includes(input.value)) {
        if (isCompleted.account) {
          progressValue += 10;
          accountStatus = false;
        }
      }
      if (input.value === 'C') {
        if (isCompleted.account) {
          progressValue += 10;
          accountStatus = false;
        }
        if (isCompleted.identification) {
          progressValue += 10;
          identificationStatus = false;
        }
        if (isCompleted.employment) {
          progressValue += 10;
          employmentStatus = false;
        }

        employmentData.employmentStatus = '';
        employmentData.employerName = '';
        employmentData.doingBusinessAs = '';
        employmentData.occupation = '';
      }

      setIsCompleted({
        ...isCompleted,
        account: accountStatus,
        identification: identificationStatus,
        employment: employmentStatus,
      });
      setProgress((prevProgress) => prevProgress - progressValue);
    }

    if (input.name === 'primaryOwner' && input.checked === false) {
      accountData.ownershipPercentage = 0;
    }

    setAccountData({
      ...accountData,
      [input.name]: input.type === 'checkbox' ? input.checked : input.value,
    });
  };

  const handleAccountCoOfficerDataChange = (e, index) => {
    const { name, value, type, checked } = e.currentTarget;
    const coOfficers = [...accountCoOfficerData];

    if (name === 'coPrimaryOwner' && checked === false) {
      coOfficers[index].coOwnershipPercentage = 0;
    }

    coOfficers[index][name] = type === 'checkbox' ? checked : value;
    coOfficers[index].id = index;

    setAccountCoOfficerData(coOfficers);
  };

  const handleAddCoOfficer = () => {
    let progressValue = 0;
    let accountStatus = isCompleted.account;
    let identificationStatus = isCompleted.identification;

    if (isCompleted.account) {
      progressValue += 10;
      accountStatus = false;
    }

    if (isCompleted.identification) {
      progressValue += 10;
      identificationStatus = false;
    }

    setIsCompleted({
      ...isCompleted,
      account: accountStatus,
      identification: identificationStatus,
    });
    setProgress((prevProgress) => prevProgress - progressValue);

    setAccountCoOfficerData([
      ...accountCoOfficerData,
      {
        coPrimaryOfficer: false,
        coPrimaryOwner: false,
        coOwnershipPercentage: 0,
        coOfficerFirstName: '',
        coOfficerMiddleName: '',
        coOfficerLastName: '',
        coOfficerAddress1: '',
        coOfficerAddress2: '',
        coOfficerCity: '',
        coOfficerState: '',
        coOfficerZipCode: '',
        coOfficerCountry: '',
        coOfficerPhoneNumber: '',
        coOfficerEmail: '',
        id: '',
      },
    ]);

    setValidateAccountCoOfficerData([
      ...validateAccountCoOfficerData,
      {
        coPrimaryType: {
          err: false,
          msg: '',
        },
        coOwnershipPercentage: {
          err: false,
          msg: '',
        },
        coOfficerFirstName: {
          err: false,
          msg: '',
        },
        coOfficerLastName: {
          err: false,
          msg: '',
        },
        coOfficerAddress1: {
          err: false,
          msg: '',
        },
        coOfficerCity: {
          err: false,
          msg: '',
        },
        coOfficerState: {
          err: false,
          msg: '',
        },
        coOfficerZipCode: {
          err: false,
          msg: '',
        },
        coOfficerCountry: {
          err: false,
          msg: '',
        },
        coOfficerPhoneNumber: {
          err: false,
          msg: '',
        },
        coOfficerEmail: {
          err: false,
          msg: '',
        },
      },
    ]);

    setCoIdentificationData([
      ...coIdentificationData,
      {
        proofOfIdentity: '',
        ssnNo: '',
        birthDate: '',
        identificationType: '',
        identificationNo: '',
        issueDate: '',
        expirationDate: '',
        countryOfIssuance: '',
        taxCountry: '',
        citizenship: '',
        idAddress: true,
        taxAddress: true,
        citizenshipAddress: true,
        id: '',
      },
    ]);

    setValidateCoIdentificationData([
      ...validateCoIdentificationData,
      {
        proofOfIdentity: {
          err: false,
          msg: '',
        },
        ssnNo: {
          err: false,
          msg: '',
        },
        birthDate: {
          err: false,
          msg: '',
        },
        identificationType: {
          err: false,
          msg: '',
        },
        identificationNo: {
          err: false,
          msg: '',
        },
        issueDate: {
          err: false,
          msg: '',
        },
        expirationDate: {
          err: false,
          msg: '',
        },
        countryOfIssuance: {
          err: false,
          msg: '',
        },
        taxCountry: {
          err: false,
          msg: '',
        },
        citizenship: {
          err: false,
          msg: '',
        },
      },
    ]);
  };

  const handleRemoveCoOfficer = (index) => {
    const coOfficers = [...accountCoOfficerData];
    const validateCoOfficers = [...validateAccountCoOfficerData];
    const coIdentity = [...coIdentificationData];
    const validateCoIdentity = [...validateCoIdentificationData];

    coOfficers.splice(index, 1);
    validateCoOfficers.splice(index, 1);
    coIdentity.splice(index, 1);
    validateCoIdentity.splice(index, 1);

    setAccountCoOfficerData(coOfficers);
    setValidateAccountCoOfficerData(validateCoOfficers);
    setCoIdentificationData(coIdentity);
    setValidateCoIdentificationData(validateCoIdentity);
  };

  const handleIdentificationDataSSNNoChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;
    setIdentificationData({
      ...identificationData,
      [input.name]: input.type === 'checkbox' ? input.checked : input.formattedValue,
    });
  };

  const handleCoIdentificationSSNNoDataChange = (e, index) => {
    const { name, formattedValue } = e.currentTarget.name ? e.currentTarget : e.target;

    const coIdentity = [...coIdentificationData];

    coIdentity[index][name] = formattedValue;

    setCoIdentificationData(coIdentity);
  };

  const handleIdentificationDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    if (input.name === 'idAddress' && input.checked === true) {
      identificationData.countryOfIssuance = '';
    }
    if (input.name === 'taxAddress' && input.checked === true) {
      identificationData.taxCountry = '';
    }
    if (input.name === 'citizenshipAddress' && input.checked === true) {
      identificationData.citizenship = '';
    }

    setIdentificationData({
      ...identificationData,
      [input.name]:
        input.type === 'checkbox'
          ? input.checked
          : input.name !== 'identificationType' &&
            ['Passport', 'Driver License'].includes(identificationData.identificationType)
            ? input.value.toUpperCase()
            : input.value,
    });
  };

  const handleCoIdentificationDataChange = (e, index) => {
    const { name, value, type, checked } = e.currentTarget.name
      ? e.currentTarget
      : e.target;
    const coIdentity = [...coIdentificationData];

    coIdentity[index][name] =
      type === 'checkbox'
        ? checked
        : name !== 'identificationType' &&
          ['Passport', 'Driver License'].includes(
            coIdentificationData[index].identificationType
          )
          ? value.toUpperCase()
          : value;
    coIdentity[index].id = index;

    setCoIdentificationData(coIdentity);
  };

  const handleEmploymentDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    if (
      input.name === 'employmentStatus' &&
      ['Employed', 'Self-Employed', 'Not Employed', 'Student', 'Retired'].includes(
        input.value
      )
    ) {
      employmentData.employerName = '';
      employmentData.doingBusinessAs = '';
      employmentData.occupation = '';
    }

    setEmploymentData({ ...employmentData, [input.name]: input.value });
  };

  const handleAssociationDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    if (input.name === 'isExecutive' && input.value === 'Yes') {
      if (isCompleted.association) {
        setIsCompleted({ ...isCompleted, association: false });
        setProgress((prevProgress) => prevProgress - 10);
      }
    }

    if (input.name === 'isRegisteredBroker' && input.value === 'Yes') {
      setOpen({ ...open, attentionModal: true });

      if (isCompleted.association) {
        setIsCompleted({ ...isCompleted, association: false });
        setProgress((prevProgress) => prevProgress - 10);
      }
    }

    if (input.name === 'isExecutive' && input.value === 'No') {
      associationData.stockSymbol = '';
    }

    if (input.name === 'isRegisteredBroker' && input.value === 'No') {
      associationData.companyName = '';
      associationData.affiliatedPerson = '';
      associationData.affiliatedPersonPosition = '';
      setAttachments([]);
    }

    setAssociationData({ ...associationData, [input.name]: input.value });
  };

  const handleTradingDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    if (
      input.name === 'marginType' &&
      input.value === 'Margin Account' &&
      marginAgreementAttachment.length === 0
    ) {
      setOpen({ ...open, marginAgreementModal: true });
    }

    setTradingData({
      ...tradingData,
      [input.name]: input.type === 'checkbox' ? input.checked : input.value,
    });
  };

  const handleMarginAgreementDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setMarginAgreementData({
      ...marginAgreementData,
      [input.name]: input.type === 'checkbox' ? input.checked : input.value,
    });
  };

  const handleRiskObjectiveDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    // Check setup of investment objective vs risk tolerance
    if (
      input.name === 'riskTolerance' &&
      investmentObjectiveData.investmentObjective !== ''
    ) {
      let objective = investmentObjectives.find(
        (x) => x.code === investmentObjectiveData.investmentObjective
      );
      let validRiskTolerance = objective.note.split(',');
      validRiskTolerance = validRiskTolerance.map((x) => {
        return x.trim();
      });

      if (
        input.value !== '' &&
        validRiskTolerance.length > 0 &&
        validRiskTolerance[0] !== ''
      ) {
        if (!validRiskTolerance.includes(input.value)) {
          notifyWarning(
            'Invalid Setup! For the selected investment objective you can only choose (' +
            validRiskTolerance.join(', ') +
            ') risk tolerance.'
          );
          return;
        }
      }
    }

    if (input.name === 'fund' && input.value !== 'Others') {
      investmentObjectiveData.other = '';
    }

    setInvestmentObjectiveData({
      ...investmentObjectiveData,
      [input.name]: input.value,
    });
  };

  const handleMultipleFundsChange = (e) => {
    let tempFunds = [];

    e.forEach((element) => {
      tempFunds.push(element.value);
    });

    let fundsData = tempFunds.join(',');

    setInvestmentObjectiveData({
      ...investmentObjectiveData,
      multipleFunds: e,
      fund: fundsData,
    });
  };

  // const handleCustomerAgreementDataChange = (e) => {
  //   const input = e.currentTarget.name ? e.currentTarget : e.target;

  //   setCustomerAccountAgreementData({
  //     ...customerAccountAgreementData,
  //     [input.name]: input.type === 'checkbox' ? input.checked : input.value,
  //   });
  // };

  const validateCreateAccountSubmission = () => {
    const required = 'Required';

    const validateEmailAddress = (name) => {
      const valid = {
        err: false,
        msg: '',
      };

      if (name === 'email') {
        if (!accountData.email) {
          return {
            err: true,
            msg: required,
          };
        }

        if (accountData.email) {
          if (validateEmail(accountData.email)) {
            return {
              err: true,
              msg: 'Invalid email address.',
            };
          }
        }
      }

      if (name === 'coEmail') {
        if (!accountData.coEmail) {
          return {
            err: true,
            msg: required,
          };
        }

        if (accountData.coEmail) {
          if (validateEmail(accountData.coEmail)) {
            return {
              err: true,
              msg: 'Invalid email address.',
            };
          }
        }
      }

      return valid;
    };

    if (accountData.accountType === 'I') {
      setValidateAccountData({
        ...validateAccountData,
        accountType: {
          err: !accountData.accountType,
          msg: required,
        },
        address1: {
          err: !accountData.address1,
          msg: required,
        },
        city: {
          err: !accountData.city,
          msg: required,
        },
        state: {
          err: !accountData.state,
          msg: required,
        },
        country: {
          err: !accountData.country,
          msg: required,
        },
        zipCode: {
          err: !accountData.zipCode,
          msg: required,
        },
        phoneNumber: {
          err: validateMobileNumber(accountData.phoneNumber).err,
          msg: validateMobileNumber(accountData.phoneNumber).msg,
        },
      });
    }

    if (accountData.accountType === 'J') {
      setValidateAccountData({
        ...validateAccountData,
        accountType: {
          err: !accountData.accountType,
          msg: required,
        },
        subAccountType: {
          err: accountData.accountType === 'J' && !accountData.subAccountType,
          msg: required,
        },
        address1: {
          err: !accountData.address1,
          msg: required,
        },
        city: {
          err: !accountData.city,
          msg: required,
        },
        state: {
          err: !accountData.state,
          msg: required,
        },
        country: {
          err: !accountData.country,
          msg: required,
        },
        zipCode: {
          err: !accountData.zipCode,
          msg: required,
        },
        phoneNumber: {
          err: validateMobileNumber(accountData.phoneNumber).err,
          msg: validateMobileNumber(accountData.phoneNumber).msg,
        },
        email: {
          err: validateEmailAddress('email').err,
          msg: validateEmailAddress('email').msg,
        },
        coFirstName: {
          err: !accountData.coFirstName,
          msg: required,
        },
        coLastName: {
          err: !accountData.coLastName,
          msg: required,
        },
        coAddress1: {
          err: !accountData.coAddress1,
          msg: required,
        },
        coCity: {
          err: !accountData.coCity,
          msg: required,
        },
        coState: {
          err: !accountData.coState,
          msg: required,
        },
        coCountry: {
          err: !accountData.coCountry,
          msg: required,
        },
        coZipCode: {
          err: !accountData.coZipCode,
          msg: required,
        },
        coPhoneNumber: {
          err: validateMobileNumber(accountData.coPhoneNumber).err,
          msg: validateMobileNumber(accountData.coPhoneNumber).msg,
        },
        coEmail: {
          err: validateEmailAddress('coEmail').err,
          msg: validateEmailAddress('coEmail').msg,
        },
      });
    }

    if (accountData.accountType === 'C') {
      setValidateAccountData({
        ...validateAccountData,
        accountType: {
          err: !accountData.accountType,
          msg: required,
        },
        subAccountType: {
          err: accountData.accountType === 'C' && !accountData.subAccountType,
          msg: required,
        },
        address1: {
          err: !accountData.address1,
          msg: required,
        },
        city: {
          err: !accountData.city,
          msg: required,
        },
        state: {
          err: !accountData.state,
          msg: required,
        },
        country: {
          err: !accountData.country,
          msg: required,
        },
        zipCode: {
          err: !accountData.zipCode,
          msg: required,
        },
        phoneNumber: {
          err: validateMobileNumber(accountData.phoneNumber).err,
          msg: validateMobileNumber(accountData.phoneNumber).msg,
        },
        taxId: {
          err: !accountData.taxId,
          msg: required,
        },
        companyName: {
          err: !accountData.companyName,
          msg: required,
        },
        primaryType: {
          err: !accountData.primaryOfficer && !accountData.primaryOwner,
          msg: required,
        },
        ownershipPercentage: {
          err: accountData.primaryOwner && accountData.ownershipPercentage === 0,
          msg: required,
        },
        primaryOfficerFirstName: {
          err: !accountData.primaryOfficerFirstName,
          msg: required,
        },
        primaryOfficerLastName: {
          err: !accountData.primaryOfficerLastName,
          msg: required,
        },
        primaryOfficerAddress1: {
          err: !accountData.primaryOfficerAddress1,
          msg: required,
        },
        primaryOfficerCity: {
          err: !accountData.primaryOfficerCity,
          msg: required,
        },
        primaryOfficerState: {
          err: !accountData.primaryOfficerState,
          msg: required,
        },
        primaryOfficerCountry: {
          err: !accountData.primaryOfficerCountry,
          msg: required,
        },
        primaryOfficerZipCode: {
          err: !accountData.primaryOfficerZipCode,
          msg: required,
        },
        primaryOfficerPhoneNumber: {
          err: validateMobileNumber(accountData.primaryOfficerPhoneNumber).err,
          msg: validateMobileNumber(accountData.primaryOfficerPhoneNumber).msg,
        },
      });

      if (accountCoOfficerData.length > 0) {
        const validateCoOfficersData = (input, i) => {
          const coOfficer = [...accountCoOfficerData];
          const validateCoOfficer = [...validateAccountCoOfficerData];

          input.coPrimaryType.err =
            !coOfficer[i].coPrimaryOwner && !coOfficer[i].coPrimaryOfficer;
          input.coPrimaryType.msg = required;
          input.coOwnershipPercentage.err =
            coOfficer[i].coPrimaryOwner && !coOfficer[i].coOwnershipPercentage;
          input.coOwnershipPercentage.msg = required;
          input.coOfficerFirstName.err = !coOfficer[i].coOfficerFirstName;
          input.coOfficerFirstName.msg = required;
          input.coOfficerLastName.err = !coOfficer[i].coOfficerLastName;
          input.coOfficerLastName.msg = required;
          input.coOfficerAddress1.err = !coOfficer[i].coOfficerAddress1;
          input.coOfficerAddress1.msg = required;
          input.coOfficerCity.err = !coOfficer[i].coOfficerCity;
          input.coOfficerCity.msg = required;
          input.coOfficerState.err = !coOfficer[i].coOfficerState;
          input.coOfficerState.msg = required;
          input.coOfficerCountry.err = !coOfficer[i].coOfficerCountry;
          input.coOfficerCountry.msg = required;
          input.coOfficerZipCode.err = !coOfficer[i].coOfficerZipCode;
          input.coOfficerZipCode.msg = required;
          input.coOfficerPhoneNumber.err = validateMobileNumber(
            coOfficer[i].coOfficerPhoneNumber
          ).err;
          input.coOfficerPhoneNumber.msg = validateMobileNumber(
            coOfficer[i].coOfficerPhoneNumber
          ).msg;
          input.coOfficerEmail.err =
            !coOfficer[i].coOfficerEmail || validateEmail(coOfficer[i].coOfficerEmail);
          input.coOfficerEmail.msg = !coOfficer[i].coOfficerEmail
            ? required
            : validateEmail(coOfficer[i].coOfficerEmail)
              ? 'Invalid email address.'
              : '';

          setValidateAccountCoOfficerData(validateCoOfficer);
        };

        validateAccountCoOfficerData.forEach(validateCoOfficersData);
      }
    }

    const isValid = () => {
      if (accountData.accountType === 'I') {
        if (
          !accountData.address1 ||
          !accountData.city ||
          !accountData.state ||
          !accountData.country ||
          !accountData.zipCode ||
          validateMobileNumber(accountData.phoneNumber).err
        ) {
          return false;
        }
      }
      if (accountData.accountType === 'J') {
        if (
          !accountData.subAccountType ||
          !accountData.address1 ||
          !accountData.city ||
          !accountData.state ||
          !accountData.country ||
          !accountData.zipCode ||
          validateMobileNumber(accountData.phoneNumber).err ||
          !accountData.coFirstName ||
          !accountData.coLastName ||
          validateMobileNumber(accountData.coPhoneNumber).err ||
          validateEmailAddress('email').err ||
          validateEmailAddress('coEmail').err ||
          !accountData.coAddress1 ||
          !accountData.coCity ||
          !accountData.coState ||
          !accountData.coCountry ||
          !accountData.coZipCode
        ) {
          return false;
        }
      }
      if (accountData.accountType === 'C') {
        if (
          !accountData.subAccountType ||
          !accountData.taxId ||
          !accountData.companyName ||
          !accountData.address1 ||
          !accountData.city ||
          !accountData.state ||
          !accountData.country ||
          !accountData.zipCode ||
          validateMobileNumber(accountData.phoneNumber).err ||
          (!accountData.primaryOfficer && !accountData.primaryOwner) ||
          (accountData.primaryOwner && accountData.ownershipPercentage === 0) ||
          !accountData.primaryOfficerFirstName ||
          !accountData.primaryOfficerLastName ||
          !accountData.primaryOfficerAddress1 ||
          !accountData.primaryOfficerCity ||
          !accountData.primaryOfficerState ||
          !accountData.primaryOfficerCountry ||
          !accountData.primaryOfficerZipCode ||
          validateMobileNumber(accountData.primaryOfficerPhoneNumber).err
        ) {
          return false;
        }

        const arrayCount = accountCoOfficerData.length;

        let totalOwnershipPercentage = accountData.ownershipPercentage;
        for (let i = 0; i < arrayCount; i++) {
          if (accountCoOfficerData[i].coPrimaryOwner) {
            totalOwnershipPercentage =
              totalOwnershipPercentage + accountCoOfficerData[i].coOwnershipPercentage;
          }
          if (
            (!accountCoOfficerData[i].coPrimaryOfficer &&
              !accountCoOfficerData[i].coPrimaryOwner) ||
            (accountCoOfficerData[i].coPrimaryOwner &&
              accountCoOfficerData[i].coOwnershipPercentage === 0) ||
            !accountCoOfficerData[i].coOfficerFirstName ||
            !accountCoOfficerData[i].coOfficerLastName ||
            !accountCoOfficerData[i].coOfficerAddress1 ||
            !accountCoOfficerData[i].coOfficerCity ||
            !accountCoOfficerData[i].coOfficerState ||
            !accountCoOfficerData[i].coOfficerCountry ||
            !accountCoOfficerData[i].coOfficerZipCode ||
            validateMobileNumber(accountCoOfficerData[i].coOfficerPhoneNumber).err ||
            !accountCoOfficerData[i].coOfficerEmail ||
            validateEmail(accountCoOfficerData[i].coOfficerEmail) ||
            validateDuplicateName(
              accountCoOfficerData[i].coOfficerFirstName +
              accountCoOfficerData[i].coOfficerLastName,
              accountData.primaryOfficerFirstName + accountData.primaryOfficerLastName
            ).err ||
            validateOwnerPercentage(totalOwnershipPercentage).err
          ) {
            return false;
          }
          for (let j = i + 1; j < arrayCount; j++) {
            if (
              validateDuplicateName(
                accountCoOfficerData[i].coOfficerFirstName +
                accountCoOfficerData[i].coOfficerLastName,
                accountCoOfficerData[j].coOfficerFirstName +
                accountCoOfficerData[j].coOfficerLastName
              )
            ) {
              return false;
            }
          }
        }
      }

      return true;
    };

    if (!isValid()) {
      setProgress((prevProgress) =>
        !isCompleted.account ? prevProgress : prevProgress - 10
      );
      setIsCompleted({ ...isCompleted, account: false });
      setIsAttentionMessageShown(true);
      setOpen({
        ...open,
        validationModal: isAttentionMessageShown ? false : true,
      });
      return false;
    }

    setIsCompleted({ ...isCompleted, account: true });
    setProgress((prevProgress) =>
      isCompleted.account ? prevProgress : prevProgress + 10
    );

    return true;
  };

  const validateCreateIdentificationSubmission = () => {
    const required = 'Required';

    setValidateIdentificationData({
      proofOfIdentity: {
        err: !identificationData.proofOfIdentity,
        msg: required,
      },
      ssnNo:
        identificationData.taxCountry === 'US'
          ? {
            err: validateSSNNo(identificationData.ssnNo).err,
            msg: validateSSNNo(identificationData.ssnNo).msg,
            toolTip: validateSSNNo(identificationData.ssnNo).toolTip,
          }
          : {
            err: false,
            msg: '',
            toolTip: '',
          },

      birthDate: {
        err:
          !identificationData.birthDate ||
          !validateDateOfBirth(identificationData.birthDate),
        msg: !identificationData.birthDate
          ? required
          : 'Age should be atleast 18 yrs old.',
      },
      identificationType: {
        err: !identificationData.identificationType,
        msg: required,
      },
      identificationNo: {
        err:
          !identificationData.identificationNo ||
          (identificationData.identificationType === 'Driver License' &&
            identificationData.identificationNo.length <= 3),
        msg:
          identificationData.identificationType === 'Driver License' &&
            identificationData.identificationNo.length <= 3
            ? 'At least 4 characters'
            : required,
      },
      issueDate: {
        err:
          !identificationData.issueDate ||
          identificationData.issueDate > identificationData.expirationDate,
        msg:
          identificationData.issueDate > identificationData.expirationDate
            ? `Issue date can't be > exp date`
            : required,
      },
      expirationDate: {
        err: validateExpirationDate(identificationData.expirationDate).err,
        msg: validateExpirationDate(identificationData.expirationDate).msg,
      },
      countryOfIssuance: {
        err: !identificationData.idAddress && !identificationData.countryOfIssuance,
        msg: required,
      },
      taxCountry: {
        err: !identificationData.taxAddress && !identificationData.taxCountry,
        msg: required,
      },
      citizenship: {
        err: !identificationData.citizenshipAddress && !identificationData.citizenship,
        msg: required,
      },
    });

    if (coIdentificationData.length > 0) {
      const validateCoOfficersData = (input, i) => {
        if (accountCoOfficerData[i].coOwnershipPercentage >= 25) {
          const coIdenitity = [...coIdentificationData];
          const validateCoOfficer = [...validateCoIdentificationData];

          input.proofOfIdentity.err = !coIdenitity[i].proofOfIdentity;
          input.proofOfIdentity.msg = required;
          input.ssnNo.err = validateSSNNo(coIdenitity[i].ssnNo).err;
          input.ssnNo.msg = validateSSNNo(coIdenitity[i].ssnNo).msg;
          input.ssnNo.toolTip = validateSSNNo(identificationData.ssnNo).toolTip;
          input.birthDate.err =
            !coIdenitity[i].birthDate || !validateDateOfBirth(coIdenitity[i].birthDate);
          input.birthDate.msg = !coIdenitity[i].birthDate
            ? required
            : 'Age should be atleast 18 yrs old.';
          input.identificationType.err = !coIdenitity[i].identificationType;
          input.identificationType.msg = required;
          input.identificationNo.err =
            !coIdenitity[i].identificationNo ||
            (coIdenitity[i].identificationType === 'Driver License' &&
              coIdenitity[i].identificationNo.length <= 3);
          input.identificationNo.msg =
            coIdenitity[i].identificationType === 'Driver License' &&
              coIdenitity[i].identificationNo.length <= 3
              ? 'At least 4 characters'
              : required;
          input.issueDate.err =
            !coIdenitity[i].issueDate ||
            coIdenitity[i].issueDate > coIdenitity[i].expirationDate;
          input.issueDate.msg =
            coIdenitity[i].issueDate > coIdenitity[i].expirationDate
              ? `Issue date can't be > exp date`
              : required;
          input.expirationDate.err = validateExpirationDate(
            coIdenitity[i].expirationDate
          ).err;
          input.expirationDate.msg = validateExpirationDate(
            coIdenitity[i].expirationDate
          ).msg;
          input.countryOfIssuance.err =
            !coIdenitity[i].idAddress && !coIdenitity[i].countryOfIssuance;
          input.countryOfIssuance.msg = required;
          input.taxCountry.err = !coIdenitity[i].taxAddress && !coIdenitity[i].taxCountry;
          input.taxCountry.msg = required;
          input.citizenship.err =
            !coIdenitity[i].citizenshipAddress && !coIdenitity[i].citizenship;
          input.citizenship.msg = required;

          setValidateCoIdentificationData(validateCoOfficer);
        }
      };

      validateCoIdentificationData.forEach(validateCoOfficersData);
    }

    const isValid = () => {
      if (
        !identificationData.proofOfIdentity ||
        (identificationData.taxCountry === 'US' &&
          validateSSNNo(identificationData.ssnNo).err) ||
        !identificationData.birthDate ||
        !validateDateOfBirth(identificationData.birthDate) ||
        !identificationData.identificationType ||
        (identificationData.identificationType === 'Driver License' &&
          identificationData.identificationNo.length <= 3) ||
        !identificationData.identificationNo ||
        !identificationData.issueDate ||
        identificationData.issueDate > identificationData.expirationDate ||
        !identificationData.expirationDate ||
        validateExpirationDate(identificationData.expirationDate).err ||
        (!identificationData.idAddress && !identificationData.countryOfIssuance) ||
        (!identificationData.taxAddress && !identificationData.taxCountry) ||
        (!identificationData.citizenshipAddress && !identificationData.citizenship)
      ) {
        return false;
      }

      const arrayCount = coIdentificationData.length;

      for (let i = 0; i < arrayCount; i++) {
        if (accountCoOfficerData[i].coOwnershipPercentage >= 25) {
          if (
            !coIdentificationData[i].proofOfIdentity ||
            (coIdentificationData[i].taxCountry === 'US' &&
              validateSSNNo(coIdentificationData[i].ssnNo).err) ||
            !coIdentificationData[i].birthDate ||
            !validateDateOfBirth(coIdentificationData[i].birthDate) ||
            !coIdentificationData[i].identificationType ||
            (coIdentificationData[i].identificationType === 'Driver License' &&
              coIdentificationData[i].identificationNo.length <= 3) ||
            !coIdentificationData[i].identificationNo ||
            !coIdentificationData[i].issueDate ||
            coIdentificationData[i].issueDate > coIdentificationData[i].expirationDate ||
            validateExpirationDate(coIdentificationData[i].expirationDate).err ||
            (!coIdentificationData[i].idAddress &&
              !coIdentificationData[i].countryOfIssuance) ||
            (!coIdentificationData[i].taxAddress &&
              !coIdentificationData[i].taxCountry) ||
            (!coIdentificationData[i].citizenshipAddress &&
              !coIdentificationData[i].citizenship)
          ) {
            return false;
          }
        }
      }

      return true;
    };

    if (!isValid()) {
      setProgress((prevProgress) =>
        !isCompleted.identification ? prevProgress : prevProgress - 10
      );
      setIsCompleted({ ...isCompleted, identification: false });
      setIsAttentionMessageShown(true);
      setOpen({
        ...open,
        validationModal: isAttentionMessageShown ? false : true,
      });
      return false;
    }

    setIsCompleted({ ...isCompleted, identification: true });
    setProgress((prevProgress) =>
      isCompleted.identification ? prevProgress : prevProgress + 10
    );

    return true;
  };

  const validateCreateEmploymentSubmission = () => {
    const required = 'Required';

    setValidateEmploymentData({
      employmentStatus: {
        err: !employmentData.employmentStatus,
        msg: required,
      },
      employerName: {
        err:
          employmentData.employmentStatus === 'Employed' && !employmentData.employerName,
        msg: required,
      },
      occupation: {
        err:
          ['Employed', 'Self-Employed'].includes(employmentData.employmentStatus) &&
          !employmentData.occupation,
        msg: required,
      },
      doingBusinessAs: {
        err:
          employmentData.employmentStatus === 'Self-Employed' &&
          !employmentData.doingBusinessAs,
        msg: required,
      },
    });

    const isValid = () => {
      if (
        !employmentData.employmentStatus ||
        (employmentData.employmentStatus === 'Employed' &&
          !employmentData.employerName) ||
        (['Employed', 'Self-Employed'].includes(employmentData.employmentStatus) &&
          !employmentData.occupation) ||
        (employmentData.employmentStatus === 'Self-Employed' &&
          !employmentData.doingBusinessAs)
      ) {
        return false;
      }
      return true;
    };

    if (!isValid()) {
      setProgress((prevProgress) =>
        !isCompleted.employment ? prevProgress : prevProgress - 10
      );
      setIsCompleted({ ...isCompleted, employment: false });
      setOpen({
        ...open,
        validationModal: isAttentionMessageShown ? false : true,
      });
      return false;
    }

    setIsCompleted({ ...isCompleted, employment: true });
    setProgress((prevProgress) =>
      isCompleted.employment ? prevProgress : prevProgress + 10
    );

    return true;
  };

  const validateCreateTrustedContactSubmission = () => {
    const required = 'Required';

    const validateEmailAddress = (email) => {
      const valid = {
        err: false,
        msg: '',
      };

      if (!email) {
        return {
          err: true,
          msg: required,
        };
      }

      if (email) {
        if (validateEmail(email)) {
          return {
            err: true,
            msg: 'Invalid email address.',
          };
        }
      }

      return valid;
    };

    const validateId = (id) => {
      const valid = {
        err: false,
        msg: '',
      };

      if (!id) {
        return {
          err: true,
          msg: required,
        };
      }

      if (trustedContactData.type === 'SSN') {
        const ssnValidated = validateSSNNo(id);
        return {
          err: ssnValidated.err,
          msg: 'Invalid SSN Format',
        };
      }

      return valid;
    };

    if (trustedContactData.isTrustedContact === 'Yes') {
      setValidateTrustedContactData({
        firstName: {
          err: !trustedContactData.firstName,
          msg: required,
        },
        lastName: {
          err: !trustedContactData.lastName,
          msg: required,
        },
        emailType: {
          err: !trustedContactData.emailType,
          msg: required,
        },
        email: {
          err: validateEmailAddress(trustedContactData.email).err,
          msg: validateEmailAddress(trustedContactData.email).msg,
        },
        birthDate: {
          err:
            !trustedContactData.birthDate ||
            !validateDateOfBirth(trustedContactData.birthDate),
          msg: !trustedContactData.birthDate
            ? required
            : 'Age should be atleast 18 yrs old.',
        },
        relationship: {
          err: !trustedContactData.relationship,
          msg: required,
        },
        type: {
          err: !trustedContactData.type,
          msg: required,
        },
        id: {
          err: validateId(trustedContactData.id).err,
          msg: validateId(trustedContactData.id).msg,
        },
        ssnCountryOfIssuance: {
          err: !trustedContactData.ssnCountryOfIssuance,
          msg: required,
        },
        ssnState: {
          err: !trustedContactData.ssnState,
          msg: required,
        },
        phoneType: {
          err: !trustedContactData.phoneType,
          msg: required,
        },
        phone: {
          err: validateMobileNumber(trustedContactData.phone).err,
          msg: validateMobileNumber(trustedContactData.phone).msg,
        },
        addressType: {
          err: !trustedContactData.addressType,
          msg: required,
        },
        trustedContactAddress1: {
          err: !trustedContactData.trustedContactAddress1,
          msg: required,
        },
        city: {
          err: !trustedContactData.city,
          msg: required,
        },
        state: {
          err: !trustedContactData.state,
          msg: required,
        },
        zipCode: {
          err: !trustedContactData.zipCode,
          msg: required,
        },
        country: {
          err: !trustedContactData.country,
          msg: required,
        },
      });
    }

    let isValid = () => {
      if (
        trustedContactData.isTrustedContact === 'Yes' &&
        (!trustedContactData.firstName ||
          !trustedContactData.lastName ||
          !trustedContactData.emailType ||
          validateEmailAddress(trustedContactData.email).err ||
          !trustedContactData.birthDate ||
          !validateDateOfBirth(trustedContactData.birthDate) ||
          !trustedContactData.type ||
          validateId(trustedContactData.id).err ||
          !trustedContactData.phoneType ||
          validateMobileNumber(trustedContactData.phone).err ||
          !trustedContactData.addressType ||
          !trustedContactData.trustedContactAddress1 ||
          !trustedContactData.city ||
          !trustedContactData.state ||
          !trustedContactData.zipCode ||
          !trustedContactData.country)
      ) {
        return false;
      }
      return true;
    };

    if (
      trustedContactData.type === 'SSN' &&
      (trustedContactData.ssnCountryOfIssuance === 'US' ||
        trustedContactData.ssnCountryOfIssuance === 'CA')
    ) {
      isValid = () => {
        if (
          trustedContactData.isTrustedContact === 'Yes' &&
          (!trustedContactData.firstName ||
            !trustedContactData.lastName ||
            !trustedContactData.emailType ||
            validateEmailAddress(trustedContactData.email).err ||
            !trustedContactData.birthDate ||
            !validateDateOfBirth(trustedContactData.birthDate) ||
            !trustedContactData.type ||
            validateId(trustedContactData.id).err ||
            !trustedContactData.ssnCountryOfIssuance ||
            !trustedContactData.ssnState ||
            !trustedContactData.phoneType ||
            validateMobileNumber(trustedContactData.phone).err ||
            !trustedContactData.addressType ||
            !trustedContactData.trustedContactAddress1 ||
            !trustedContactData.city ||
            !trustedContactData.state ||
            !trustedContactData.zipCode ||
            !trustedContactData.country)
        ) {
          return false;
        }
        return true;
      };
    }

    if (
      trustedContactData.type === 'SSN' &&
      (trustedContactData.ssnCountryOfIssuance !== 'US' ||
        trustedContactData.ssnCountryOfIssuance !== 'CA')
    ) {
      isValid = () => {
        if (
          trustedContactData.isTrustedContact === 'Yes' &&
          (!trustedContactData.firstName ||
            !trustedContactData.lastName ||
            !trustedContactData.emailType ||
            validateEmailAddress(trustedContactData.email).err ||
            !trustedContactData.birthDate ||
            !validateDateOfBirth(trustedContactData.birthDate) ||
            !trustedContactData.type ||
            validateId(trustedContactData.id).err ||
            !trustedContactData.ssnCountryOfIssuance ||
            !trustedContactData.phoneType ||
            validateMobileNumber(trustedContactData.phone).err ||
            !trustedContactData.addressType ||
            !trustedContactData.trustedContactAddress1 ||
            !trustedContactData.city ||
            !trustedContactData.state ||
            !trustedContactData.zipCode ||
            !trustedContactData.country)
        ) {
          return false;
        }
        return true;
      };
    }

    if (!isValid()) {
      setProgress((prevProgress) =>
        !isCompleted.trustedContact ? prevProgress : prevProgress - 10
      );
      setIsCompleted({ ...isCompleted, trustedContact: false });
      setOpen({
        ...open,
        validationModal: isAttentionMessageShown ? false : true,
      });
      return false;
    }

    setIsCompleted({ ...isCompleted, trustedContact: true });
    setProgress((prevProgress) =>
      isCompleted.trustedContact ? prevProgress : prevProgress + 10
    );

    return true;
  };

  const validateCreateAssociationSubmission = () => {
    const required = 'Required';

    setValidateAssociationData({
      stockSymbol: {
        err: associationData.isExecutive === 'Yes' && !associationData.stockSymbol,
        msg: required,
      },
      companyName: {
        err: associationData.isRegisteredBroker === 'Yes' && !associationData.companyName,
        msg: required,
      },
      affiliatedPerson: {
        err:
          associationData.isRegisteredBroker === 'Yes' &&
          !associationData.affiliatedPerson,
        msg: required,
      },
      affiliatedPersonPosition: {
        err:
          associationData.isRegisteredBroker === 'Yes' &&
          !associationData.affiliatedPersonPosition,
        msg: required,
      },
      attachments: {
        err: associationData.isRegisteredBroker === 'Yes' && attachments.length === 0,
        msg: required,
      },
    });

    const isValid = () => {
      if (
        (associationData.isExecutive === 'Yes' && !associationData.stockSymbol) ||
        (associationData.isRegisteredBroker === 'Yes' && !associationData.companyName) ||
        (associationData.isRegisteredBroker === 'Yes' &&
          !associationData.affiliatedPerson) ||
        (associationData.isRegisteredBroker === 'Yes' &&
          !associationData.affiliatedPersonPosition) ||
        (associationData.isRegisteredBroker === 'Yes' && attachments.length === 0)
      ) {
        return false;
      }
      return true;
    };

    if (!isValid()) {
      setProgress((prevProgress) =>
        !isCompleted.association ? prevProgress : prevProgress - 10
      );
      setIsCompleted({ ...isCompleted, association: false });
      setIsAttentionMessageShown(true);
      setOpen({
        ...open,
        validationModal: isAttentionMessageShown ? false : true,
      });
      return false;
    }

    setIsCompleted({ ...isCompleted, association: true });
    setProgress((prevProgress) =>
      isCompleted.association ? prevProgress : prevProgress + 10
    );

    return true;
  };

  const validateCreateTradingSubmission = () => {
    const required = 'Required';

    setValidateTradingData({
      marginType: {
        err: !tradingData.marginType,
        msg: required,
      },
      readMarginDisclosureStatement: {
        err: !tradingData.readMarginDisclosureStatement,
        msg: required,
      },
      readMarginAgreement: {
        err: !tradingData.readMarginAgreement,
        msg: required,
      },
      tradingPrivileges: {
        err: !tradingData.stocks && !tradingData.options,
        msg: required,
      },
    });

    const isValid = () => {
      if (
        !tradingData.marginType ||
        (!tradingData.readMarginDisclosureStatement &&
          tradingData.marginType === 'Margin Account') ||
        (!tradingData.readMarginAgreement &&
          tradingData.marginType === 'Margin Account') ||
        (!tradingData.stocks && !tradingData.options)
      ) {
        return false;
      }
      return true;
    };

    if (!isValid()) {
      setProgress((prevProgress) =>
        !isCompleted.trading ? prevProgress : prevProgress - 10
      );
      setIsCompleted({ ...isCompleted, trading: false });
      setIsAttentionMessageShown(true);
      setOpen({
        ...open,
        validationModal: isAttentionMessageShown ? false : true,
      });
      return false;
    }

    setIsCompleted({ ...isCompleted, trading: true });
    setProgress((prevProgress) =>
      isCompleted.trading ? prevProgress : prevProgress + 10
    );

    return true;
  };

  const validateCreateInvestmentObjectiveSubmission = () => {
    const required = 'Required';

    setValidateInvestmentObjectiveData({
      investmentObjective: {
        err: !investmentObjectiveData.investmentObjective,
        msg: required,
      },
      annualIncome: {
        err: !investmentObjectiveData.annualIncome,
        msg: required,
      },
      liquidNetWorth: {
        err: !investmentObjectiveData.liquidNetWorth,
        msg: required,
      },
      fund: {
        err: !investmentObjectiveData.fund,
        msg: required,
      },
      riskTolerance: {
        err: !investmentObjectiveData.riskTolerance,
        msg: required,
      },
      other: {
        err: investmentObjectiveData.fund === 'Others' && !investmentObjectiveData.other,
        msg: required,
      },
    });

    const isValid = () => {
      if (
        !investmentObjectiveData.investmentObjective ||
        !investmentObjectiveData.annualIncome ||
        !investmentObjectiveData.liquidNetWorth ||
        !investmentObjectiveData.fund ||
        !investmentObjectiveData.riskTolerance ||
        (investmentObjectiveData.fund === 'Others' && !investmentObjectiveData.other)
      ) {
        return false;
      }
      return true;
    };

    if (!isValid()) {
      setProgress((prevProgress) =>
        !isCompleted.objective ? prevProgress : prevProgress - 10
      );
      setIsCompleted({ ...isCompleted, objective: false });
      setIsAttentionMessageShown(true);
      setOpen({
        ...open,
        validationModal: isAttentionMessageShown ? false : true,
      });
      return false;
    }

    setIsCompleted({ ...isCompleted, objective: true });
    setProgress((prevProgress) =>
      isCompleted.objective ? prevProgress : prevProgress + 10
    );

    return true;
  };

  const validateCustomerAccountAgreement = () => {
    setValidateCustomerAccountAgreementData({
      acceptCustomerAgreement: {
        err: !customerAccountAgreementData.acceptCustomerAgreement,
        msg: 'Required',
      },
    });

    const isValid = () => {
      if (!customerAccountAgreementData.acceptCustomerAgreement) {
        return false;
      }
      return true;
    };

    if (!isValid()) {
      setProgress((prevProgress) =>
        !isCompleted.customerAccountAgreement ? prevProgress : prevProgress - 10
      );
      setIsCompleted({ ...isCompleted, customerAccountAgreement: false });
      setIsAttentionMessageShown(true);
      setOpen({
        ...open,
        validationModal: isAttentionMessageShown ? false : true,
      });
      return false;
    }

    setIsCompleted({ ...isCompleted, customerAccountAgreement: true });
    setProgress((prevProgress) =>
      isCompleted.customerAccountAgreement ? prevProgress : prevProgress + 10
    );

    return true;
  };

  const validateDisclosures = () => {
    let notAcceptedMsg = '';

    for (let i = 0; i < dynamicDisclosures.length; i++) {
      const disclosure = dynamicDisclosures[i];
      if (disclosure.checked === false) {
        notAcceptedMsg += disclosure.title + ' is not yet accepted.';
        notifyError(notAcceptedMsg);
        return false;
      }
    }

    const isValid = () => {
      if (!customerAccountAgreementData.acceptCustomerAgreement) {
        return false;
      }
      if (
        !tradingData.marginType ||
        (!tradingData.readMarginDisclosureStatement &&
          tradingData.marginType === 'Margin Account') ||
        (!tradingData.readMarginAgreement &&
          tradingData.marginType === 'Margin Account') ||
        (!tradingData.stocks && !tradingData.options)
      ) {
        return false;
      }
      return true;
    };

    if (!isValid()) {
      setProgress((prevProgress) =>
        !isCompleted.customerAccountAgreement ? prevProgress : prevProgress - 10
      );
      setIsCompleted({ ...isCompleted, disclosures: false });
      setIsAttentionMessageShown(true);
      setOpen({
        ...open,
        validationModal: isAttentionMessageShown ? false : true,
      });
      return false;
    }

    setIsCompleted({ ...isCompleted, disclosures: true });
    setProgress((prevProgress) =>
      isCompleted.disclosures ? prevProgress : prevProgress + 10
    );

    return true;
  };

  const handleUploadedProofOfAddress = (e) => {
    let file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.addEventListener(
        'load',
        function () {
          setAccountData({
            ...accountData,
            proofOfAddress: reader.result,
          });
        },
        false
      );

      reader.readAsDataURL(file);
    }
  };

  const handleUploadedProofOfIdentity = (e, index) => {
    let file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.addEventListener(
        'load',
        function () {
          if (index !== undefined) {
            const coIdentity = [...coIdentificationData];

            coIdentity[index]['proofOfIdentity'] = reader.result;

            setCoIdentificationData(coIdentity);
          } else {
            setIdentificationData({
              ...identificationData,
              proofOfIdentity: reader.result,
            });
          }
        },
        false
      );

      reader.readAsDataURL(file);
    }
  };

  const handleCopyPrimaryAddress = (e) => {
    const checked = e.target.checked;
    const { address1, address2, city, state, country, zipCode } = accountData;

    setSameAsPrimaryAddress(checked);

    setAccountData({
      ...accountData,
      coAddress1: checked ? address1 : '',
      coAddress2: checked ? address2 : '',
      coCity: checked ? city : '',
      coState: checked ? state : '',
      coCountry: checked ? country : '',
      coZipCode: checked ? zipCode : '',
    });
  };

  const setAddressData = (name, address, index) => {
    const { address1, address2, state, city, zipCode, country } = address;

    if (name === 'trustedContactAddress1') {
      setTrustedContactData({
        ...trustedContactData,
        trustedContactAddress1: address1,
        address2: address2,
        city: city,
        state: state,
        country: country,
        zipCode: zipCode,
      });
    }

    if (name === 'address1') {
      setAccountData({
        ...accountData,
        address1: address1,
        address2: address2,
        city: city,
        state: state,
        country: country,
        zipCode: zipCode,
      });
    }
    if (name === 'coAddress1') {
      setAccountData({
        ...accountData,
        coAddress1: address1,
        coAddress2: address2,
        coCity: city,
        coState: state,
        coCountry: country,
        coZipCode: zipCode,
      });
    }
    if (name === 'primaryOfficerAddress1') {
      setAccountData({
        ...accountData,
        primaryOfficerAddress1: address1,
        primaryOfficerAddress2: address2,
        primaryOfficerCity: city,
        primaryOfficerState: state,
        primaryOfficerCountry: country,
        primaryOfficerZipCode: zipCode,
      });
    }
    if (name === 'coOfficerAddress1') {
      const coOfficers = [...accountCoOfficerData];

      coOfficers[index]['coOfficerAddress1'] = address1;
      coOfficers[index]['coOfficerAddress2'] = address2;
      coOfficers[index]['coOfficerCity'] = city;
      coOfficers[index]['coOfficerState'] = state;
      coOfficers[index]['coOfficerCountry'] = country;
      coOfficers[index]['coOfficerZipCode'] = zipCode;

      setAccountCoOfficerData(coOfficers);
    }
  };

  const handleAttachFile = async (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    setAttachments(e.target.files);
  };

  const getTotalPercentageOfOwnership = () => {
    const existinCoOwnershipPercentage = accountCoOfficerData.reduce(function (
      accumulator,
      currentValue
    ) {
      return accumulator + currentValue['coOwnershipPercentage'];
    },
      accountData.ownershipPercentage);

    return existinCoOwnershipPercentage;
  };

  const selectedForm = (form) => {
    switch (form) {
      case 1:
        return (
          <form className="account-form-w" autoComplete="off">
            <input type="hidden" autoComplete="off" />
            <div className="grd-row">
              <div className="grd-cell">
                <SelectAccountType
                  required={true}
                  name="accountType"
                  label="Account Type"
                  placeholder="Account Type"
                  value={accountData.accountType}
                  onChange={handleAccountDataChange}
                />
              </div>
              {(accountData.accountType === 'J' ||
                accountData.accountType === 'C' ||
                accountData.accountType === 'IRA') && (
                  <div className="grd-cell" style={{ marginRight: 20 }}>
                    <SelectSubAccountType
                      tooltip={
                        accountData.accountType === 'J' &&
                        (accountData.subAccountType === 'Joint with Rights of Survivorship'
                          ? 'Have an equal rights to the accounts asset and are afforded survivor rights'
                          : accountData.subAccountType === 'Joint Community Property'
                            ? 'Joint ownership assets between married couples. States inclusion: WA, ID, NV, CA, WZ, TX, and LA'
                            : accountData.subAccountType === 'Joint Tenants in Common'
                              ? 'Joint ownership of assets but no survivorship afforded.'
                              : 'Select a joint account type first to see info of that specific account')
                      }
                      required={true}
                      error={validateAccountData.subAccountType.err}
                      msg={validateAccountData.subAccountType.msg}
                      name="subAccountType"
                      value={accountData.subAccountType}
                      label="Sub Account Type"
                      placeholder="Sub Account Type"
                      onChange={handleAccountDataChange}
                      selectedAccountType={accountData.accountType}
                    ></SelectSubAccountType>
                  </div>
                )}
            </div>
            <Text
              mt={40}
              mb={25}
              variant="h3"
              label={handleChangeFormSubHeader(accountData.accountType)}
            />
            {accountData.accountType === 'C' && (
              <div className="grd-row">
                <div className="grd-cell">
                  <TextField
                    required={true}
                    error={validateAccountData.companyName.err}
                    msg={validateAccountData.companyName.msg}
                    max={100}
                    name="companyName"
                    label="Company Name"
                    placeholder="Company Name"
                    type="text"
                    value={accountData.companyName}
                    onChange={handleAccountDataChange}
                  />
                </div>
                <div className="grd-cell">
                  <MaskedField
                    required={true}
                    error={validateAccountData.taxId.err}
                    msg={validateAccountData.taxId.msg}
                    name="taxId"
                    label="EIN or Tax ID"
                    placeholder="EIN or Tax ID"
                    setAccountData
                    format="##-#######"
                    value={accountData.taxId}
                    onChange={handleAccountDataChange}
                  />
                </div>
              </div>
            )}
            <div className="grd-row">
              <div className="grd-cell-none mr">
                <ImageAttachment
                  id="proofOfAddress"
                  label="Proof of Address"
                  thumbnail={accountData.proofOfAddress}
                  handleAttach={handleUploadedProofOfAddress}
                  data={accountData}
                  setNewData={setAccountData}
                  form={1}
                />
              </div>
              <div className="grd-cell">
                <div className="grd-row nm">
                  <div className="grd-cell">
                    <AutoCompletePlace
                      required={true}
                      error={validateAccountData.address1.err}
                      msg={validateAccountData.address1.msg}
                      max={100}
                      name="address1"
                      label={
                        ['I', 'J'].includes(accountData.accountType)
                          ? 'Address 1'
                          : 'Business Address 1'
                      }
                      placeholder={
                        ['I', 'J'].includes(accountData.accountType)
                          ? 'Address 1'
                          : 'Business Address 1'
                      }
                      value={accountData.address1}
                      onChange={handleAccountDataChange}
                      setAddressData={setAddressData}
                    />
                  </div>
                  <div className="grd-cell">
                    <TextField
                      max={100}
                      name="address2"
                      label={
                        ['I', 'J'].includes(accountData.accountType)
                          ? 'Address 2'
                          : 'Business Address 2'
                      }
                      placeholder={
                        ['I', 'J'].includes(accountData.accountType)
                          ? 'Address 2'
                          : 'Business Address 2'
                      }
                      type="text"
                      value={accountData.address2}
                      onChange={handleAccountDataChange}
                    />
                  </div>
                </div>
                <div className="grd-row">
                  <div className="grd-cell">
                    <TextField
                      required={true}
                      error={validateAccountData.city.err}
                      msg={validateAccountData.city.msg}
                      max={80}
                      name="city"
                      label="City"
                      placeholder="City"
                      type="text"
                      value={accountData.city}
                      onChange={handleAccountDataChange}
                    />
                  </div>
                  <div className="grd-cell">
                    {accountData.country !== 'US' ? (
                      <TextField
                        required={true}
                        error={validateAccountData.state.err}
                        msg={validateAccountData.state.msg}
                        max={50}
                        name="state"
                        label="State"
                        placeholder="State"
                        value={accountData.state}
                        onChange={handleAccountDataChange}
                      />
                    ) : (
                      <AutoCompleteState
                        required={true}
                        error={validateAccountData.state.err}
                        msg={validateAccountData.state.msg}
                        name="state"
                        label="State"
                        placeholder="State"
                        value={accountData.state}
                        onChange={handleAccountDataChange}
                      />
                    )}
                  </div>
                </div>
                <div className="grd-row">
                  <div className="grd-cell">
                    <AutoCompleteCountry
                      required={true}
                      error={validateAccountData.country.err}
                      msg={validateAccountData.country.msg}
                      name="country"
                      label="Country"
                      placeholder="Country"
                      value={accountData.country}
                      onChange={handleAccountDataChange}
                    />
                  </div>
                  <div className="grd-cell">
                    <TextField
                      required={true}
                      error={validateAccountData.zipCode.err}
                      msg={validateAccountData.zipCode.msg}
                      max={10}
                      name="zipCode"
                      label="Zip Code"
                      placeholder="Zip Code"
                      type="text"
                      value={accountData.zipCode}
                      onChange={handleAccountDataChange}
                    />
                  </div>
                </div>
                {/* {accountData.accountType !== 'C' && (
                  <div className="grd-row">
                    <div className="grd-cell">
                      <SelectSystemCode
                        name="maritalStatus"
                        label="Marital Status"
                        placeholder="Marital Status"
                        type="Marital Status"
                        value={accountData.maritalStatus}
                        onChange={handleAccountDataChange}
                      />
                    </div>
                    <div className="grd-cell"></div>
                  </div>
                )} */}
              </div>
            </div>
            <div className="grd-row">
              <div className="grd-cell">
                <PhoneNumber
                  required={true}
                  // disabled={true}
                  error={validateAccountData.phoneNumber.err}
                  msg={validateAccountData.phoneNumber.msg}
                  name="phoneNumber"
                  label="Phone Number"
                  value={accountData.phoneNumber}
                  onChange={handleAccountDataChange}
                />
              </div>
              {accountData.accountType === 'J' && (
                <div className="grd-cell">
                  <TextField
                    required={true}
                    error={validateAccountData.email.err}
                    msg={validateAccountData.email.msg}
                    max={50}
                    name="email"
                    label="Email Address"
                    placeholder="Email Address"
                    type="text"
                    value={accountData.email}
                    onChange={handleAccountDataChange}
                  />
                </div>
              )}
            </div>
            {accountData.accountType === 'C' && (
              <div className="mt-50">
                <Text
                  required={true}
                  error={validateAccountData.primaryType.err}
                  msg={validateAccountData.primaryType.msg}
                  mt={20}
                  variant="subtitle1"
                  label="Primary Type"
                />
                <div className="grd-row">
                  <div className="grd-cell-none">
                    <Checkbox
                      ml={-3}
                      name="primaryOfficer"
                      label="Officer"
                      checked={accountData.primaryOfficer}
                      onChange={handleAccountDataChange}
                    />
                  </div>
                  <div className="grd-cell-none">
                    <Checkbox
                      ml={20}
                      name="primaryOwner"
                      label="Owner"
                      checked={accountData.primaryOwner}
                      onChange={handleAccountDataChange}
                    />
                  </div>
                </div>
                {accountData.primaryOwner && (
                  <div className="grd-row">
                    <div className="grd-cell">
                      <RangeSlider
                        required={true}
                        error={validateAccountData.ownershipPercentage.err}
                        msg={validateAccountData.ownershipPercentage.msg}
                        name="ownershipPercentage"
                        label="Percentage of Ownership"
                        step={0.5}
                        value={accountData.ownershipPercentage}
                        onChange={handleAccountDataChange}
                        totalValue={getTotalPercentageOfOwnership()}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            {accountData.accountType === 'J' && (
              <div>
                <Text mt={40} variant="h3" label="Co-Applicant" />
                <div className="grd-row">
                  <div className="grd-cell">
                    <TextField
                      required={true}
                      error={validateAccountData.coFirstName.err}
                      msg={validateAccountData.coFirstName.msg}
                      max={50}
                      name="coFirstName"
                      label="First Name"
                      placeholder="First Name"
                      type="text"
                      value={accountData.coFirstName}
                      onChange={handleAccountDataChange}
                    />
                  </div>
                  <div className="grd-cell">
                    <TextField
                      max={20}
                      name="coMiddleName"
                      label="Middle Name"
                      placeholder="Middle Name (Optional)"
                      type="text"
                      value={accountData.coMiddleName}
                      onChange={handleAccountDataChange}
                    />
                  </div>
                  <div className="grd-cell">
                    <TextField
                      required={true}
                      error={validateAccountData.coLastName.err}
                      msg={validateAccountData.coLastName.msg}
                      max={20}
                      name="coLastName"
                      label="Last Name"
                      placeholder="Last Name"
                      type="text"
                      value={accountData.coLastName}
                      onChange={handleAccountDataChange}
                    />
                  </div>
                </div>
                <div className="grd-row">
                  <div className="grd-cell">
                    <PhoneNumber
                      required={true}
                      error={validateAccountData.coPhoneNumber.err}
                      msg={validateAccountData.coPhoneNumber.msg}
                      name="coPhoneNumber"
                      label="Phone Number"
                      value={accountData.coPhoneNumber}
                      onChange={handleAccountDataChange}
                    />
                  </div>
                  <div className="grd-cell">
                    <TextField
                      required={true}
                      error={validateAccountData.coEmail.err}
                      msg={validateAccountData.coEmail.msg}
                      max={50}
                      name="coEmail"
                      label="Email Address"
                      placeholder="Email Address"
                      type="text"
                      value={accountData.coEmail}
                      onChange={handleAccountDataChange}
                    />
                  </div>
                </div>
                <Checkbox
                  mt={30}
                  ml={-3}
                  name="idAddress"
                  label="Same address of primary applicant"
                  checked={sameAsPrimaryAddress}
                  onChange={handleCopyPrimaryAddress}
                />
                <div className="grd-row">
                  <div className="grd-cell">
                    <AutoCompletePlace
                      required={true}
                      error={validateAccountData.coAddress1.err}
                      msg={validateAccountData.coAddress1.msg}
                      max={100}
                      name="coAddress1"
                      label="Address 1"
                      placeholder="Address 1"
                      value={accountData.coAddress1}
                      onChange={handleAccountDataChange}
                      setAddressData={setAddressData}
                    />
                  </div>
                  <div className="grd-cell">
                    <TextField
                      max={100}
                      name="coAddress2"
                      label="Address 2"
                      placeholder="Address 2"
                      type="text"
                      value={accountData.coAddress2}
                      onChange={handleAccountDataChange}
                    />
                  </div>
                </div>
                <div className="grd-row">
                  <div className="grd-cell">
                    <TextField
                      required={true}
                      error={validateAccountData.coCity.err}
                      msg={validateAccountData.coCity.msg}
                      max={80}
                      name="coCity"
                      label="City"
                      placeholder="City"
                      type="text"
                      value={accountData.coCity}
                      onChange={handleAccountDataChange}
                    />
                  </div>
                  <div className="grd-cell">
                    {accountData.coCountry !== 'US' ? (
                      <TextField
                        required={true}
                        error={validateAccountData.coState.err}
                        msg={validateAccountData.coState.msg}
                        max={50}
                        name="coState"
                        label="State"
                        placeholder="State"
                        type="text"
                        value={accountData.coState}
                        onChange={handleAccountDataChange}
                      />
                    ) : (
                      <AutoCompleteState
                        required={true}
                        error={validateAccountData.coState.err}
                        msg={validateAccountData.coState.msg}
                        name="coState"
                        label="State"
                        placeholder="State"
                        type="text"
                        value={accountData.coState}
                        onChange={handleAccountDataChange}
                      />
                    )}
                  </div>
                </div>
                <div className="grd-row">
                  <div className="grd-cell">
                    <AutoCompleteCountry
                      required={true}
                      error={validateAccountData.coCountry.err}
                      msg={validateAccountData.coCountry.msg}
                      name="coCountry"
                      label="Country"
                      placeholder="Country"
                      type="text"
                      value={accountData.coCountry}
                      onChange={handleAccountDataChange}
                    />
                  </div>
                  <div className="grd-cell">
                    <TextField
                      required={true}
                      error={validateAccountData.coZipCode.err}
                      msg={validateAccountData.coZipCode.msg}
                      max={10}
                      name="coZipCode"
                      label="Zip Code"
                      placeholder="Zip Code"
                      type="text"
                      value={accountData.coZipCode}
                      onChange={handleAccountDataChange}
                    />
                  </div>
                </div>
              </div>
            )}
            {accountData.accountType === 'C' && (
              <div>
                <div className="grd-row mt-30">
                  <div className="grd-cell">
                    <Text
                      variant="h3"
                      label={
                        'Primary ' +
                        (accountData.primaryOwner && accountData.primaryOfficer
                          ? 'Officer/Owner'
                          : accountData.primaryOwner
                            ? 'Owner'
                            : accountData.primaryOfficer
                              ? 'Officer'
                              : 'Officer/Owner')
                      }
                    />
                  </div>
                </div>
                <div className="grd-row">
                  <div className="grd-cell">
                    <TextField
                      required={true}
                      error={validateAccountData.primaryOfficerFirstName.err}
                      msg={validateAccountData.primaryOfficerFirstName.msg}
                      max={50}
                      name="primaryOfficerFirstName"
                      label="First Name"
                      placeholder="First Name"
                      type="text"
                      value={accountData.primaryOfficerFirstName}
                      onChange={handleAccountDataChange}
                    />
                  </div>
                  <div className="grd-cell">
                    <TextField
                      max={50}
                      name="primaryOfficerMiddleName"
                      label="Middle Name"
                      placeholder="Middle Name (Optional)"
                      type="text"
                      value={accountData.primaryOfficerMiddleName}
                      onChange={handleAccountDataChange}
                    />
                  </div>
                  <div className="grd-cell">
                    <TextField
                      required={true}
                      error={validateAccountData.primaryOfficerLastName.err}
                      msg={validateAccountData.primaryOfficerLastName.msg}
                      max={50}
                      name="primaryOfficerLastName"
                      label="Last Name"
                      placeholder="Last Name"
                      type="text"
                      value={accountData.primaryOfficerLastName}
                      onChange={handleAccountDataChange}
                    />
                  </div>
                </div>
                <div className="grd-row">
                  <div className="grd-cell">
                    <AutoCompletePlace
                      required={true}
                      error={validateAccountData.primaryOfficerAddress1.err}
                      msg={validateAccountData.primaryOfficerAddress1.msg}
                      max={100}
                      name="primaryOfficerAddress1"
                      label="Address 1"
                      placeholder="Address 1"
                      value={accountData.primaryOfficerAddress1}
                      onChange={handleAccountDataChange}
                      setAddressData={setAddressData}
                    />
                  </div>
                  <div className="grd-cell">
                    <TextField
                      max={100}
                      name="primaryOfficerAddress2"
                      label="Address 2"
                      placeholder="Address 2"
                      type="text"
                      value={accountData.primaryOfficerAddress2}
                      onChange={handleAccountDataChange}
                    />
                  </div>
                </div>
                <div className="grd-row">
                  <div className="grd-cell">
                    <TextField
                      required={true}
                      error={validateAccountData.primaryOfficerCity.err}
                      msg={validateAccountData.primaryOfficerCity.msg}
                      max={80}
                      name="primaryOfficerCity"
                      label="City"
                      placeholder="City"
                      type="text"
                      value={accountData.primaryOfficerCity}
                      onChange={handleAccountDataChange}
                    />
                  </div>
                  <div className="grd-cell">
                    {accountData.primaryOfficerCountry !== 'US' ? (
                      <TextField
                        required={true}
                        error={validateAccountData.primaryOfficerState.err}
                        msg={validateAccountData.primaryOfficerState.msg}
                        max={50}
                        name="primaryOfficerState"
                        label="State"
                        placeholder="State"
                        type="text"
                        value={accountData.primaryOfficerState}
                        onChange={handleAccountDataChange}
                      />
                    ) : (
                      <AutoCompleteState
                        required={true}
                        error={validateAccountData.primaryOfficerState.err}
                        msg={validateAccountData.primaryOfficerState.msg}
                        name="primaryOfficerState"
                        label="State"
                        placeholder="State"
                        value={accountData.primaryOfficerState}
                        onChange={handleAccountDataChange}
                      />
                    )}
                  </div>
                </div>
                <div className="grd-row">
                  <div className="grd-cell">
                    <AutoCompleteCountry
                      required={true}
                      error={validateAccountData.primaryOfficerCountry.err}
                      msg={validateAccountData.primaryOfficerCountry.msg}
                      name="primaryOfficerCountry"
                      label="Country"
                      placeholder="Country"
                      value={accountData.primaryOfficerCountry}
                      onChange={handleAccountDataChange}
                    />
                  </div>
                  <div className="grd-cell">
                    <TextField
                      required={true}
                      error={validateAccountData.primaryOfficerZipCode.err}
                      msg={validateAccountData.primaryOfficerZipCode.msg}
                      max={10}
                      name="primaryOfficerZipCode"
                      label="Zip Code"
                      placeholder="Zip Code"
                      type="text"
                      value={accountData.primaryOfficerZipCode}
                      onChange={handleAccountDataChange}
                    />
                  </div>
                </div>
                <PhoneNumber
                  required={true}
                  error={validateAccountData.primaryOfficerPhoneNumber.err}
                  msg={validateAccountData.primaryOfficerPhoneNumber.msg}
                  mt={true}
                  name="primaryOfficerPhoneNumber"
                  label="Phone Number"
                  value={accountData.primaryOfficerPhoneNumber}
                  onChange={handleAccountDataChange}
                />
                <CreateCoOfficer
                  accountCoOfficerData={accountCoOfficerData}
                  validateAccountCoOfficerData={validateAccountCoOfficerData}
                  handleAccountCoOfficerDataChange={handleAccountCoOfficerDataChange}
                  handleRemoveCoOfficer={handleRemoveCoOfficer}
                  setAddressData={setAddressData}
                  totalPercentageOfOwnership={getTotalPercentageOfOwnership()}
                />
                <span
                  style={{
                    float: 'right',
                    margin: '20px 0px',
                  }}
                >
                  <LinkButton
                    type="add"
                    label={'Add Officer/Owner'}
                    onClick={handleAddCoOfficer}
                  />
                </span>
              </div>
            )}
          </form>
        );
      case 2:
        return (
          <form className="identity-form-w" autoComplete="off">
            <input type="hidden" autoComplete="off" />
            {!verificationData.result ? (
              <div className="grd-row verify-c nm">
                <div className="grd-cell-none">
                  <VerifyIdGraphic />
                  <Text
                    mt={10}
                    mb={10}
                    variant="h2"
                    label={
                      loading.verifyIdentity ? 'Verifying...' : 'Identity Verification'
                    }
                  />
                  <Text
                    mb={25}
                    variant="body1"
                    label={
                      loading.verifyIdentity
                        ? 'Please wait while we verify your identity'
                        : 'We need to verify your information to process your application'
                    }
                  />
                  {loading.verifyIdentity ? (
                    <>
                      <Text variant={'string'} label="This may take up to 60 seconds." />
                      <div className="grd-row"></div>
                      <LinearProgress variant="indeterminate" color="secondary" />
                    </>
                  ) : (
                    <>
                      <Button
                        loading={loading.verifyIdentity}
                        disabled={loading.verifyIdentity || verificationData.result}
                        label={
                          loading.verifyIdentity ? 'Verifying...' : 'Verify Identity'
                        }
                        onClick={handleVerificationClick}
                      />
                    </>
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className="grd-row nm">
                  <div className="grd-cell-none mr">
                    <ImageAttachment
                      id="proofOfIdentity"
                      required={true}
                      error={validateIdentificationData.proofOfIdentity.err}
                      msg={validateIdentificationData.proofOfIdentity.msg}
                      label="Government ID"
                      thumbnail={identificationData.proofOfIdentity}
                      handleAttach={handleUploadedProofOfIdentity}
                      data={identificationData}
                      setNewData={setIdentificationData}
                      form={2}
                      hidButton={true}
                    />
                  </div>
                  <div className="grd-cell">
                    <div className="grd-row nm">
                      <div className="grd-cell">
                        <SelectSystemCode
                          required={true}
                          error={validateIdentificationData.identificationType.err}
                          msg={validateIdentificationData.identificationType.msg}
                          name="identificationType"
                          label="Identification Type"
                          placeholder="Identification Type"
                          type="Identification"
                          subType="Client"
                          value={identificationData.identificationType}
                          onChange={handleIdentificationDataChange}
                        />
                      </div>
                      <div className="grd-cell">
                        {['SSN', 'TIN'].includes(
                          identificationData.identificationType
                        ) ? (
                          <MaskedField
                            required={true}
                            error={validateIdentificationData.identificationNo.err}
                            msg={validateIdentificationData.identificationNo.msg}
                            name="identificationNo"
                            label="Identification No."
                            placeholder="Identification No."
                            format={
                              identificationData.identificationType === 'SSN'
                                ? '###-##-####'
                                : '##-#######'
                            }
                            value={identificationData.identificationNo}
                            onChange={handleIdentificationDataChange}
                          />
                        ) : (
                          <TextField
                            required={true}
                            error={validateIdentificationData.identificationNo.err}
                            msg={validateIdentificationData.identificationNo.msg}
                            max={
                              identificationData.identificationType === 'Passport'
                                ? 30
                                : 13
                            }
                            name="identificationNo"
                            label="Identification No."
                            placeholder="Identification No."
                            type="text"
                            value={identificationData.identificationNo}
                            onChange={handleIdentificationDataChange}
                          />
                        )}
                      </div>
                    </div>
                    <div className="grd-row">
                      <div className="grd-cell">
                        <DateTimePicker
                          required={true}
                          error={validateIdentificationData.issueDate.err}
                          msg={validateIdentificationData.issueDate.msg}
                          type="date"
                          name="issueDate"
                          label="Issue Date"
                          value={identificationData.issueDate}
                          onChange={handleIdentificationDataChange}
                        />
                      </div>
                      <div className="grd-cell">
                        <DateTimePicker
                          required={true}
                          error={validateIdentificationData.expirationDate.err}
                          msg={validateIdentificationData.expirationDate.msg}
                          type="date"
                          name="expirationDate"
                          label="Expiration Date"
                          value={identificationData.expirationDate}
                          onChange={handleIdentificationDataChange}
                        />
                      </div>
                    </div>
                    <div className="grd-row">
                      <div className="grd-cell">
                        <Checkbox
                          mt={15}
                          ml={-3}
                          name="idAddress"
                          label="My country of ID is the same as my physical address."
                          checked={identificationData.idAddress}
                          onChange={handleIdentificationDataChange}
                        />
                      </div>
                      {!identificationData.idAddress && (
                        <div className="grd-cell">
                          <AutoCompleteCountry
                            required={true}
                            error={validateIdentificationData.countryOfIssuance.err}
                            msg={validateIdentificationData.countryOfIssuance.msg}
                            name="countryOfIssuance"
                            label="Country of Issuance"
                            placeholder="Country of Issuance"
                            value={identificationData.countryOfIssuance}
                            onChange={handleIdentificationDataChange}
                          />
                        </div>
                      )}
                    </div>
                    <div className="grd-row">
                      <div className="grd-cell">
                        <AutoCompleteState
                          name="state"
                          label="State"
                          placeholder="State"
                          type="text"
                          value={identificationData.state}
                          onChange={handleIdentificationDataChange}
                        />
                      </div>
                      <div className="grd-cell"></div>
                    </div>
                  </div>
                </div>
                <Text
                  mt={10}
                  mb={35}
                  variant="body1"
                  label="min of 500kb and max of 3mb"
                />
                <div className="grd-row">
                  <div className="grd-cell">
                    <DateTimePicker
                      required={true}
                      error={validateIdentificationData.birthDate.err}
                      msg={validateIdentificationData.birthDate.msg}
                      type="date"
                      name="birthDate"
                      label="Birth Date"
                      value={identificationData.birthDate}
                      onChange={handleIdentificationDataChange}
                    />
                  </div>
                  <div className="grd-cell">
                    <MaskedField
                      required={identificationData.taxAddress === 'US'}
                      error={validateIdentificationData.ssnNo.err}
                      msg={validateIdentificationData.ssnNo.msg}
                      tooltip={validateIdentificationData.ssnNo.toolTip}
                      name="ssnNo"
                      label="SSN"
                      placeholder="SSN"
                      format="###-##-####"
                      value={identificationData.ssnNo}
                      onChange={handleIdentificationDataSSNNoChange}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="grd-col">
              <Checkbox
                ml={-3}
                name="taxAddress"
                label="My country of taxation is the same as my physical address."
                checked={identificationData.taxAddress}
                onChange={handleIdentificationDataChange}
              />
              {!identificationData.taxAddress && (
                <AutoCompleteCountry
                  required={true}
                  error={validateIdentificationData.taxCountry.err}
                  msg={validateIdentificationData.taxCountry.msg}
                  mt={20}
                  mb={10}
                  name="taxCountry"
                  label="Tax Country"
                  placeholder="Tax Country"
                  value={identificationData.taxCountry}
                  onChange={handleIdentificationDataChange}
                />
              )}
            </div>
            <div className="grd-col">
              <Checkbox
                ml={-3}
                name="citizenshipAddress"
                label="My country of citizenship is the same as my physical address."
                checked={identificationData.citizenshipAddress}
                onChange={handleIdentificationDataChange}
              />
              {!identificationData.citizenshipAddress && (
                <AutoCompleteCountry
                  required={true}
                  error={validateIdentificationData.citizenship.err}
                  msg={validateIdentificationData.citizenship.msg}
                  mt={20}
                  name="citizenship"
                  label="Citizenship"
                  placeholder="Citizenship"
                  value={identificationData.citizenship}
                  onChange={handleIdentificationDataChange}
                />
              )}
            </div>
            {coIdentificationData.map((x, index) => {
              const {
                coOfficerFirstName,
                coOfficerLastName,
                coPrimaryOfficer,
                coPrimaryOwner,
              } = accountCoOfficerData[index];
              const identityHolder =
                'Co-' +
                (coPrimaryOfficer && coPrimaryOwner
                  ? 'Officer/Owner: '
                  : coPrimaryOfficer
                    ? 'Officer: '
                    : coPrimaryOwner
                      ? 'Owner: '
                      : 'Officer/Owner: ') +
                coOfficerFirstName +
                ' ' +
                coOfficerLastName;

              return (
                <div className="mt-50" key={index}>
                  {accountCoOfficerData[index].coOwnershipPercentage >= 25 && (
                    <div>
                      <Text mb={25} variant="h3" label={identityHolder} />
                      <div className="grd-row nm">
                        <div className="grd-cell-none mr">
                          <ImageAttachment
                            id={'proofOfIdentity' + index.toString()}
                            required={true}
                            error={
                              validateCoIdentificationData[index].proofOfIdentity.err
                            }
                            msg={validateCoIdentificationData[index].proofOfIdentity.msg}
                            label="Government ID"
                            thumbnail={coIdentificationData[index].proofOfIdentity}
                            handleAttach={handleUploadedProofOfIdentity}
                            data={coIdentificationData}
                            setNewData={setCoIdentificationData}
                            form={2}
                            index={index}
                          />
                        </div>
                        <div className="grd-cell">
                          <div className="grd-row nm">
                            <div className="grd-cell">
                              <SelectSystemCode
                                required={true}
                                error={
                                  validateCoIdentificationData[index].identificationType
                                    .err
                                }
                                msg={
                                  validateCoIdentificationData[index].identificationType
                                    .msg
                                }
                                name="identificationType"
                                label="Identification Type"
                                placeholder="Identification Type"
                                type="Identification"
                                subType="Client"
                                value={x.identificationType}
                                onChange={(e) =>
                                  handleCoIdentificationDataChange(e, index)
                                }
                              />
                            </div>
                            <div className="grd-cell">
                              {['SSN', 'TIN'].includes(x.identificationType) ? (
                                <MaskedField
                                  required={true}
                                  error={
                                    validateCoIdentificationData[index].identificationNo
                                      .err
                                  }
                                  msg={
                                    validateCoIdentificationData[index].identificationNo
                                      .msg
                                  }
                                  name="identificationNo"
                                  label="Identification No."
                                  placeholder="Identification No."
                                  format={
                                    x.identificationType === 'SSN'
                                      ? '###-##-####'
                                      : '##-#######'
                                  }
                                  value={x.identificationNo}
                                  onChange={(e) =>
                                    handleCoIdentificationDataChange(e, index)
                                  }
                                />
                              ) : (
                                <TextField
                                  required={true}
                                  error={
                                    validateCoIdentificationData[index].identificationNo
                                      .err
                                  }
                                  msg={
                                    validateCoIdentificationData[index].identificationNo
                                      .msg
                                  }
                                  max={x.identificationType === 'Passport' ? 30 : 15}
                                  name="identificationNo"
                                  label="Identification No."
                                  placeholder="Identification No."
                                  type="text"
                                  value={x.identificationNo}
                                  onChange={(e) =>
                                    handleCoIdentificationDataChange(e, index)
                                  }
                                />
                              )}
                            </div>
                          </div>
                          <div className="grd-row">
                            <div className="grd-cell">
                              <DateTimePicker
                                required={true}
                                error={validateCoIdentificationData[index].issueDate.err}
                                msg={validateCoIdentificationData[index].issueDate.msg}
                                type="date"
                                name="issueDate"
                                label="Issue Date"
                                value={x.issueDate}
                                onChange={(e) =>
                                  handleCoIdentificationDataChange(e, index)
                                }
                              />
                            </div>
                            <div className="grd-cell">
                              <DateTimePicker
                                required={true}
                                error={
                                  validateCoIdentificationData[index].expirationDate.err
                                }
                                msg={
                                  validateCoIdentificationData[index].expirationDate.msg
                                }
                                type="date"
                                name="expirationDate"
                                label="Expiration Date"
                                value={x.expirationDate}
                                onChange={(e) =>
                                  handleCoIdentificationDataChange(e, index)
                                }
                              />
                            </div>
                          </div>
                          <div className="grd-row">
                            <div className="grd-cell">
                              <Checkbox
                                mt={15}
                                ml={-3}
                                name="idAddress"
                                label="My country of ID is the same as my physical address."
                                checked={x.idAddress}
                                onChange={(e) =>
                                  handleCoIdentificationDataChange(e, index)
                                }
                              />
                            </div>
                            {!x.idAddress && (
                              <div className="grd-cell">
                                <AutoCompleteCountry
                                  required={true}
                                  error={
                                    validateCoIdentificationData[index].countryOfIssuance
                                      .err
                                  }
                                  msg={
                                    validateCoIdentificationData[index].countryOfIssuance
                                      .msg
                                  }
                                  name="countryOfIssuance"
                                  label="Country of Issuance"
                                  placeholder="Country of Issuance"
                                  value={x.countryOfIssuance}
                                  onChange={(e) =>
                                    handleCoIdentificationDataChange(e, index)
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <Text
                        mt={10}
                        mb={35}
                        variant="body1"
                        label="min of 500kb and max of 3mb"
                      />
                      <div className="grd-row">
                        <div className="grd-cell">
                          <DateTimePicker
                            required={true}
                            error={validateCoIdentificationData[index].birthDate.err}
                            msg={validateCoIdentificationData[index].birthDate.msg}
                            type="date"
                            name="birthDate"
                            label="Birth Date"
                            value={x.birthDate}
                            onChange={(e) => handleCoIdentificationDataChange(e, index)}
                          />
                        </div>
                        <div className="grd-cell">
                          <MaskedField
                            required={x.taxAddress === 'US'}
                            error={validateCoIdentificationData[index].ssnNo.err}
                            msg={validateCoIdentificationData[index].ssnNo.msg}
                            tooltip={validateCoIdentificationData[index].ssnNo.toolTip}
                            name="ssnNo"
                            label="SSN"
                            placeholder="SSN"
                            format="###-##-####"
                            value={x.ssnNo}
                            onChange={(e) =>
                              handleCoIdentificationSSNNoDataChange(e, index)
                            }
                          />
                        </div>
                      </div>
                      <div className="grd-col">
                        <Checkbox
                          mt={20}
                          ml={-3}
                          name="taxAddress"
                          label="My country of taxation is the same as my physical address."
                          checked={x.taxAddress}
                          onChange={(e) => handleCoIdentificationDataChange(e, index)}
                        />
                        {!x.taxAddress && (
                          <AutoCompleteCountry
                            required={true}
                            error={validateCoIdentificationData[index].taxCountry.err}
                            msg={validateCoIdentificationData[index].taxCountry.msg}
                            mt={20}
                            mb={10}
                            name="taxCountry"
                            label="Tax Country"
                            placeholder="Tax Country"
                            value={x.taxCountry}
                            onChange={(e) => handleCoIdentificationDataChange(e, index)}
                          />
                        )}
                      </div>
                      <div className="grd-col">
                        <Checkbox
                          ml={-3}
                          name="citizenshipAddress"
                          label="My country of citizenship is the same as my physical address."
                          checked={x.citizenshipAddress}
                          onChange={(e) => handleCoIdentificationDataChange(e, index)}
                        />
                        {!x.citizenshipAddress && (
                          <AutoCompleteCountry
                            required={true}
                            error={validateCoIdentificationData[index].citizenship.err}
                            msg={validateCoIdentificationData[index].citizenship.msg}
                            mt={20}
                            name="citizenship"
                            label="Citizenship"
                            placeholder="Citizenship"
                            value={x.citizenship}
                            onChange={(e) => handleCoIdentificationDataChange(e, index)}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
            {open.verificationModal && (
              <VerificationModal
                onClose={handleCloseVerification}
                open={open.verificationModal}
                value={verificationData}
              ></VerificationModal>
            )}
          </form>
        );
      case 3:
        return (
          <form className="employment-form-w" autoComplete="off">
            <input type="hidden" autoComplete="off" />
            <SelectSystemCode
              required={true}
              error={validateEmploymentData.employmentStatus.err}
              msg={validateEmploymentData.employmentStatus.msg}
              name="employmentStatus"
              label="Employment Status"
              placeholder="Employment Status"
              type="Employment Status"
              subType="Client Site"
              value={employmentData.employmentStatus}
              onChange={handleEmploymentDataChange}
            />
            {['Employed', 'Self-Employed'].includes(employmentData.employmentStatus) && (
              <div>
                {employmentData.employmentStatus === 'Employed' && (
                  <TextField
                    required={true}
                    error={validateEmploymentData.employerName.err}
                    msg={validateEmploymentData.employerName.msg}
                    mt={20}
                    max={50}
                    name="employerName"
                    label="Employer Name"
                    placeholder="Employer Name"
                    type="text"
                    value={employmentData.employerName}
                    onChange={handleEmploymentDataChange}
                  />
                )}
                {employmentData.employmentStatus === 'Employed' && (
                  <TextField
                    mt={20}
                    name="yearsEmployed"
                    label="Years Employed"
                    placeholder="Years Employed"
                    type="number"
                    value={employmentData.yearsEmployed}
                    onChange={handleEmploymentDataChange}
                  />
                )}
                {employmentData.employmentStatus === 'Self-Employed' && (
                  <TextField
                    required={true}
                    error={validateEmploymentData.doingBusinessAs.err}
                    msg={validateEmploymentData.doingBusinessAs.msg}
                    mt={20}
                    max={50}
                    name="doingBusinessAs"
                    label="Doing Business As"
                    placeholder="Doing Business As"
                    type="text"
                    value={employmentData.doingBusinessAs}
                    onChange={handleEmploymentDataChange}
                  />
                )}
                <TextField
                  required={true}
                  error={validateEmploymentData.occupation.err}
                  msg={validateEmploymentData.occupation.msg}
                  mt={20}
                  max={50}
                  name="occupation"
                  label="Occupation"
                  placeholder="Occupation"
                  type="text"
                  value={employmentData.occupation}
                  onChange={handleEmploymentDataChange}
                />
              </div>
            )}
            {employmentData.employmentStatus === 'Unemployed' && (
              <div>
                <TextField
                  required={true}
                  error={validateEmploymentData.previousEmployerName?.err}
                  msg={validateEmploymentData.previousEmployerName?.msg}
                  mt={20}
                  max={50}
                  name="previousEmployerName"
                  label="Previous Employer Name"
                  placeholder="Previous Employer Name"
                  type="text"
                  value={employmentData.previousEmployerName}
                  onChange={handleEmploymentDataChange}
                />
                <TextField
                  mt={20}
                  name="yearsEmployed"
                  label="Years Employed"
                  placeholder="Years Employed"
                  type="number"
                  value={employmentData.yearsEmployed}
                  onChange={handleEmploymentDataChange}
                />
                <TextField
                  required={true}
                  error={validateEmploymentData.occupation.err}
                  msg={validateEmploymentData.occupation.msg}
                  mt={20}
                  max={50}
                  name="occupation"
                  label="Occupation"
                  placeholder="Occupation"
                  type="text"
                  value={employmentData.occupation}
                  onChange={handleEmploymentDataChange}
                />
              </div>
            )}
          </form>
        );
      case 4:
        return (
          <form className="trusted-contact-form-w" autoComplete="off">
            <input type="hidden" autoComplete="off" />
            <RadioButtonGroup
              name="isTrustedContact"
              label="Do you have a trusted contact??"
              value={trustedContactData.isTrustedContact}
              options={['Yes', 'No']}
              onChange={handletrustedContactDataChange}
            />
            {trustedContactData.isTrustedContact === 'Yes' && (
              <div>
                <div className="grd-row">
                  <div className="grd-cell">
                    <TextField
                      required={true}
                      error={validateTrustedContactData.firstName.err}
                      msg={validateTrustedContactData.firstName.msg}
                      max={50}
                      name="firstName"
                      label="First Name"
                      placeholder="First Name"
                      type="text"
                      value={trustedContactData.firstName}
                      onChange={handletrustedContactDataChange}
                    />
                  </div>
                  <div className="grd-cell">
                    <TextField
                      max={20}
                      name="middleName"
                      label="Middle Name"
                      placeholder="Middle Name (Optional)"
                      type="text"
                      value={trustedContactData.middleName}
                      onChange={handletrustedContactDataChange}
                    />
                  </div>
                  <div className="grd-cell">
                    <TextField
                      required={true}
                      error={validateTrustedContactData.lastName.err}
                      msg={validateTrustedContactData.lastName.msg}
                      max={20}
                      name="lastName"
                      label="Last Name"
                      placeholder="Last Name"
                      type="text"
                      value={trustedContactData.lastName}
                      onChange={handletrustedContactDataChange}
                    />
                  </div>
                </div>
                <div className="grd-row">
                  <div className="grd-cell">
                    <SelectSystemCode
                      required={true}
                      error={validateTrustedContactData.emailType.err}
                      msg={validateTrustedContactData.emailType.msg}
                      name="emailType"
                      label="Email Type"
                      placeholder="Email Type"
                      type="Contact Info Type"
                      subType="Email Type"
                      value={trustedContactData.emailType}
                      onChange={handletrustedContactDataChange}
                    />
                  </div>
                  <div className="grd-cell">
                    <TextField
                      required={true}
                      error={validateTrustedContactData.email.err}
                      msg={validateTrustedContactData.email.msg}
                      max={50}
                      name="email"
                      label="Email Address"
                      placeholder="Email Address"
                      type="text"
                      value={trustedContactData.email}
                      onChange={handletrustedContactDataChange}
                    />
                  </div>
                  <div className="grd-cell">
                    <DateTimePicker
                      required={true}
                      error={validateTrustedContactData.birthDate.err}
                      msg={validateTrustedContactData.birthDate.msg}
                      type="date"
                      name="birthDate"
                      label="Birth Date"
                      value={trustedContactData.birthDate}
                      onChange={handletrustedContactDataChange}
                    />
                  </div>
                </div>
                <div className="grd-row">
                  <div className="grd-cell">
                    <SelectSystemCode
                      required={true}
                      error={validateTrustedContactData.type.err}
                      msg={validateTrustedContactData.type.msg}
                      name="type"
                      label="Identification Type"
                      placeholder="Identification Type"
                      type="Identification"
                      subType="Client Site"
                      value={trustedContactData.type}
                      onChange={handletrustedContactDataChange}
                    />
                  </div>
                  <div className="grd-cell">
                    <MaskedField
                      required={true}
                      error={validateTrustedContactData.id.err}
                      msg={validateTrustedContactData.id.msg}
                      name="id"
                      label="ID"
                      placeholder="ID"
                      format={
                        trustedContactData.type === 'SSN' ? '###-##-####' : '##-#######'
                      }
                      value={trustedContactData.id}
                      onChange={handletrustedContactDataChange}
                    />
                  </div>
                  {trustedContactData.type === 'SSN' && (
                    <div className="grd-cell">
                      <AutoCompleteCountry
                        required={true}
                        error={validateTrustedContactData.ssnCountryOfIssuance.err}
                        msg={validateTrustedContactData.ssnCountryOfIssuance.msg}
                        name="ssnCountryOfIssuance"
                        label="Country of Issuance"
                        placeholder="Country"
                        value={trustedContactData.ssnCountryOfIssuance}
                        onChange={handletrustedContactDataChange}
                      />
                    </div>
                  )}
                  {/* ssnCountryOfIssuance is US */}
                  {trustedContactData.type === 'SSN' &&
                    trustedContactData.ssnCountryOfIssuance === 'US' && (
                      <div className="grd-cell">
                        <AutoCompleteState
                          required={true}
                          error={validateTrustedContactData.ssnState.err}
                          msg={validateTrustedContactData.ssnState.msg}
                          name="ssnState"
                          label="State"
                          placeholder="State"
                          value={trustedContactData.ssnState}
                          onChange={handletrustedContactDataChange}
                        />
                      </div>
                    )}
                  {/* ssnCountryOfIssuance is Canada */}
                  {trustedContactData.type === 'SSN' &&
                    trustedContactData.ssnCountryOfIssuance === 'CA' && (
                      <div className="grd-cell">
                        <SelectSystemCode
                          required={true}
                          error={validateTrustedContactData.ssnState.err}
                          msg={validateTrustedContactData.ssnState.msg}
                          name="ssnState"
                          label="State"
                          placeholder="State"
                          type="Canada"
                          subType="Provinces and Territories"
                          value={trustedContactData.ssnState}
                          onChange={handletrustedContactDataChange}
                        />
                      </div>
                    )}
                  {trustedContactData.type === 'SSN' &&
                    trustedContactData.ssnCountryOfIssuance !== 'US' &&
                    trustedContactData.ssnCountryOfIssuance !== 'CA' && (
                      <div className="grd-cell">
                        <TextField
                          required={false}
                          max={50}
                          name="ssnState"
                          label="State"
                          placeholder="State"
                          type="text"
                          value={trustedContactData.ssnState}
                          onChange={handletrustedContactDataChange}
                        />
                      </div>
                    )}
                  {trustedContactData.type !== 'SSN' && <div className="grd-cell"></div>}
                </div>
                <div className="grd-row">
                  <div className="grd-cell">
                    <SelectSystemCode
                      required={true}
                      error={validateTrustedContactData.relationship.err}
                      msg={validateTrustedContactData.relationship.msg}
                      name="relationship"
                      label="Relationship"
                      placeholder="Relationship"
                      type="Relationship"
                      subType="Client Site"
                      value={trustedContactData.relationship}
                      onChange={handletrustedContactDataChange}
                    />
                  </div>
                  {trustedContactData.relationship === 'Other' && (
                    <div className="grd-cell">
                      <TextField
                        required={trustedContactData.relationship === 'Other'}
                        error={validateTrustedContactData.other?.err}
                        msg={validateTrustedContactData.other?.msg}
                        max={20}
                        name="other"
                        label="Other"
                        placeholder="Please Specify"
                        type="text"
                        value={trustedContactData.other}
                        onChange={handletrustedContactDataChange}
                      />
                    </div>
                  )}
                  <div className="grd-cell">
                    <SelectSystemCode
                      required={true}
                      error={validateTrustedContactData.phoneType.err}
                      msg={validateTrustedContactData.phoneType.msg}
                      name="phoneType"
                      label="Phone Type"
                      placeholder="Phone Type"
                      type="Contact Info Type"
                      subType="Phone Type"
                      value={trustedContactData.phoneType}
                      onChange={handletrustedContactDataChange}
                    />
                  </div>
                  <div className="grd-cell">
                    <PhoneNumber
                      required={true}
                      error={validateTrustedContactData.phone.err}
                      msg={validateTrustedContactData.phone.msg}
                      name="phone"
                      label="Phone Number"
                      value={trustedContactData.phone}
                      onChange={handletrustedContactDataChange}
                    />
                  </div>
                </div>
                <div className="grd-row">
                  <div className="grd-cell">
                    <SelectSystemCode
                      required={true}
                      error={validateTrustedContactData.addressType.err}
                      msg={validateTrustedContactData.addressType.msg}
                      name="addressType"
                      label="Address Type"
                      placeholder="Address Type"
                      type="Address Type"
                      subType="Client Site"
                      value={trustedContactData.addressType}
                      onChange={handletrustedContactDataChange}
                    />
                  </div>
                  <div className="grd-cell">
                    <AutoCompletePlace
                      required={true}
                      error={validateTrustedContactData.trustedContactAddress1.err}
                      msg={validateTrustedContactData.trustedContactAddress1.msg}
                      max={100}
                      name="trustedContactAddress1"
                      label="Address 1"
                      placeholder="Address 1"
                      value={trustedContactData.trustedContactAddress1}
                      onChange={handletrustedContactDataChange}
                      setAddressData={setAddressData}
                    />
                  </div>
                  <div className="grd-cell">
                    <TextField
                      max={100}
                      name="address2"
                      label="Address 2"
                      placeholder="Address 2"
                      type="text"
                      value={trustedContactData.address2}
                      onChange={handletrustedContactDataChange}
                    />
                  </div>
                </div>
                <div className="grd-row">
                  <div className="grd-cell">
                    <TextField
                      required={true}
                      error={validateTrustedContactData.city.err}
                      msg={validateTrustedContactData.city.msg}
                      max={80}
                      name="city"
                      label="City"
                      placeholder="City"
                      type="text"
                      value={trustedContactData.city}
                      onChange={handletrustedContactDataChange}
                    />
                  </div>
                  <div className="grd-cell">
                    {accountData.country !== 'US' ? (
                      <TextField
                        required={true}
                        error={validateTrustedContactData.state.err}
                        msg={validateTrustedContactData.state.msg}
                        max={50}
                        name="state"
                        label="State"
                        placeholder="State"
                        type="text"
                        value={trustedContactData.state}
                        onChange={handletrustedContactDataChange}
                      />
                    ) : (
                      <AutoCompleteState
                        required={true}
                        error={validateTrustedContactData.state.err}
                        msg={validateTrustedContactData.state.msg}
                        name="state"
                        label="State"
                        placeholder="State"
                        value={trustedContactData.state}
                        onChange={handletrustedContactDataChange}
                      />
                    )}
                  </div>
                  <div className="grd-cell">
                    <AutoCompleteCountry
                      required={true}
                      error={validateTrustedContactData.country.err}
                      msg={validateTrustedContactData.country.msg}
                      name="country"
                      label="Country"
                      placeholder="Country"
                      value={trustedContactData.country}
                      onChange={handletrustedContactDataChange}
                    />
                  </div>
                </div>
                <div className="grd-row">
                  <div className="grd-cell">
                    <TextField
                      required={true}
                      error={validateTrustedContactData.zipCode.err}
                      msg={validateTrustedContactData.zipCode.msg}
                      max={10}
                      name="zipCode"
                      label="Zip Code"
                      placeholder="Zip Code"
                      type="text"
                      value={trustedContactData.zipCode}
                      onChange={handletrustedContactDataChange}
                    />
                  </div>
                  <div className="grd-cell"></div>
                  <div className="grd-cell"></div>
                </div>
              </div>
            )}
          </form>
        );
      case 5:
        return (
          <form className="association-form-w" autoComplete="off">
            <input type="hidden" autoComplete="off" />
            <RadioButtonGroup
              name="isExecutive"
              label="Are you or a family member a senior executive or 10% or greater
                                shareholder of a publicly traded company?"
              value={associationData.isExecutive}
              options={['Yes', 'No']}
              onChange={handleAssociationDataChange}
            />
            {associationData.isExecutive === 'Yes' && (
              <TextField
                required={true}
                error={validateAssociationData.stockSymbol.err}
                msg={validateAssociationData.stockSymbol.msg}
                mt={20}
                max={50}
                name="stockSymbol"
                label="Stock Symbol"
                placeholder="Stock Symbol"
                type="text"
                value={associationData.stockSymbol}
                onChange={handleAssociationDataChange}
              />
            )}
            <RadioButtonGroup
              mt={30}
              name="isRegisteredBroker"
              label="Are you or a family member employed with a member firm of a
                                stock exchange or Finra registered broker?"
              value={associationData.isRegisteredBroker}
              options={['Yes', 'No']}
              onChange={handleAssociationDataChange}
            />
            {associationData.isRegisteredBroker === 'Yes' && (
              <div>
                <TextField
                  required={true}
                  error={validateAssociationData.companyName.err}
                  msg={validateAssociationData.companyName.msg}
                  mt={20}
                  max={50}
                  name="companyName"
                  label="Name of the Company"
                  placeholder="Name of the Company"
                  type="text"
                  value={associationData.companyName}
                  onChange={handleAssociationDataChange}
                />
                <TextField
                  required={true}
                  error={validateAssociationData.affiliatedPerson.err}
                  msg={validateAssociationData.affiliatedPerson.msg}
                  mt={20}
                  max={50}
                  name="affiliatedPerson"
                  label="Name of the Affiliated Person"
                  placeholder="Name of the Affiliated Person"
                  type="text"
                  value={associationData.affiliatedPerson}
                  onChange={handleAssociationDataChange}
                />
                <TextField
                  required={true}
                  error={validateAssociationData.affiliatedPersonPosition.err}
                  msg={validateAssociationData.affiliatedPersonPosition.msg}
                  mt={20}
                  max={50}
                  name="affiliatedPersonPosition"
                  label="Postion of the Affiliated Person"
                  placeholder="Postion of the Affiliated Person"
                  type="text"
                  value={associationData.affiliatedPersonPosition}
                  onChange={handleAssociationDataChange}
                />
                <FileAttachments
                  required={true}
                  error={validateAssociationData.attachments.err}
                  msg={validateAssociationData.attachments.msg}
                  title="Please attach your account authorization from your employer"
                  attachments={attachments}
                  onChange={handleAttachFile}
                  setAttachments={setAttachments}
                />
              </div>
            )}
            <MessageModal
              modalTitle="Attention"
              content={associationMessage}
              open={open.attentionModal}
              onClose={handleCloseModal}
            />
          </form>
        );
      case 6:
        return (
          <form className="trading-form-w" autoComplete="off">
            <MarginTypeRadioGroup
              required={true}
              error={validateTradingData.marginType.err}
              msg={validateTradingData.marginType.msg}
              name="marginType"
              label="Please select an Account Type"
              value={tradingData.marginType}
              correspondent={correspondentData}
              options={selectedMarginTypes}
              onChange={handleTradingDataChange}
            />
            {tradingData.marginType === 'Margin Account' && (
              <div>
                {validateTradingData.readMarginAgreement.err && (
                  <div className="grd-row">
                    <Text
                      mt={10}
                      error={validateTradingData.readMarginAgreement.err}
                      label="Please check Margin Agreement"
                      variant="subtitle1"
                    />
                  </div>
                )}
                <Checkbox
                  mt={!validateTradingData.readMarginAgreement.err ? 40 : 0}
                  ml={-3}
                  required={true}
                  labelLink={true}
                  name="readMarginAgreement"
                  label={
                    <React.Fragment>
                      I have read and accepted the
                      <span
                        onClick={(e) => {
                          setOpen({ ...open, marginAgreementModal: true });
                          e.preventDefault();
                        }}
                      >
                        <Link ml={5} label="Margin Agreement" />.
                      </span>
                    </React.Fragment>
                  }
                  checked={tradingData.readMarginAgreement}
                  onChange={handleTradingDataChange}
                  disabled={true}
                />
                <div className="grd-row">
                  {validateTradingData.readMarginDisclosureStatement.err && (
                    <Text
                      mt={10}
                      error={validateTradingData.readMarginDisclosureStatement.err}
                      label="Please check Margin Disclosure Statement"
                      variant="subtitle1"
                    />
                  )}
                </div>
                <Checkbox
                  mt={!validateTradingData.readMarginAgreement.err ? 10 : 0}
                  ml={-3}
                  required={true}
                  labelLink={true}
                  name="readMarginDisclosureStatement"
                  label={
                    <React.Fragment>
                      I have read and accepted the
                      <span
                        onClick={(e) => {
                          setOpen({ ...open, marginDisclosureModal: true });
                          e.preventDefault();
                        }}
                      >
                        <Link ml={5} label="Margin Disclosure Statement" />.
                      </span>
                    </React.Fragment>
                  }
                  checked={tradingData.readMarginDisclosureStatement}
                  onChange={handleTradingDataChange}
                  disabled={true}
                />
              </div>
            )}
            <Text
              required={true}
              error={validateTradingData.tradingPrivileges.err}
              msg={validateTradingData.tradingPrivileges.msg}
              mt={40}
              variant="subtitle1"
              label="Trading Privileges"
            />
            <div className="grd-row">
              <div className="grd-cell-none mr">
                <Checkbox
                  ml={-3}
                  name="stocks"
                  label="Stocks"
                  checked={tradingData.stocks}
                  onChange={handleTradingDataChange}
                />
              </div>
              <div className="grd-cell-none mr">
                <Checkbox
                  name="options"
                  disabled={true}
                  label="Options"
                  checked={tradingData.options}
                  onChange={handleTradingDataChange}
                />
              </div>
            </div>
          </form>
        );
      case 7:
        return (
          <form className="risk-objective-form-w" autoComplete="off">
            <SelectSystemCode
              required={true}
              error={validateInvestmentObjectiveData.investmentObjective.err}
              msg={validateInvestmentObjectiveData.investmentObjective.msg}
              name="investmentObjective"
              label="Investment Objective"
              placeholder="Investment Objective"
              type="Investment Objective"
              subType=""
              value={investmentObjectiveData.investmentObjective}
              onChange={handleRiskObjectiveDataChange}
            />
            <div className="grd-row">
              <div className="grd-cell">
                {/*added this extra div because of an unexplainable bug in system code list for this dropdown */}
                <div>
                  <SelectSystemCode
                    required={true}
                    error={validateInvestmentObjectiveData.annualIncome.err}
                    msg={validateInvestmentObjectiveData.annualIncome.msg}
                    tooltip="Employment, alimony, social security, investment income, etc."
                    name="annualIncome"
                    label="Annual Income"
                    placeholder="Annual Income"
                    type="Investment Objectives"
                    subType="Annual Income"
                    orderBy="note"
                    value={investmentObjectiveData.annualIncome}
                    onChange={handleRiskObjectiveDataChange}
                  />
                </div>
              </div>
              <div className="grd-cell">
                <SelectSystemCode
                  required={true}
                  error={validateInvestmentObjectiveData.liquidNetWorth.err}
                  msg={validateInvestmentObjectiveData.liquidNetWorth.msg}
                  tooltip="Total amount of all assets that can be readily converted to cash."
                  name="liquidNetWorth"
                  label="Liquid Net Worth"
                  placeholder="Liquid Net Worth"
                  type="Investment Objectives"
                  subType="Liquid Net Worth"
                  orderBy="note"
                  value={investmentObjectiveData.liquidNetWorth}
                  onChange={handleRiskObjectiveDataChange}
                />
              </div>
            </div>
            <div className="grd-row">
              {/* <div className="grd-cell">
								<SelectSystemCode
									required={true}
									error={validateInvestmentObjectiveData.fund.err}
									msg={validateInvestmentObjectiveData.fund.msg}
									name="fund"
									label="What are you using to fund this account?"
									placeholder="Funds Source"
									type="Fund Source"
									value={investmentObjectiveData.fund}
									onChange={handleRiskObjectiveDataChange}
								/>
							</div> */}
              <div className="grd-cell">
                <SelectSourceOfFunds
                  required={true}
                  name="multipleFunds"
                  label="What are you using to fund this account?"
                  value={investmentObjectiveData.multipleFunds}
                  onChange={handleMultipleFundsChange}
                />
              </div>
              {investmentObjectiveData.fund.includes('Others') && (
                <div className="grd-cell">
                  <TextField
                    required={true}
                    error={validateInvestmentObjectiveData.other.err}
                    msg={validateInvestmentObjectiveData.other.msg}
                    max={50}
                    name="other"
                    label="Other"
                    placeholder="Other"
                    type="text"
                    value={investmentObjectiveData.other}
                    onChange={handleRiskObjectiveDataChange}
                  />
                </div>
              )}
            </div>
            <div className="grd-row">
              <div className="grd-cell">
                <SelectSystemCode
                  required={true}
                  error={validateInvestmentObjectiveData.riskTolerance.err}
                  msg={validateInvestmentObjectiveData.riskTolerance.msg}
                  name="riskTolerance"
                  label="Risk Tolerance"
                  placeholder="Risk Tolerance"
                  type="Risk Tolerance"
                  value={investmentObjectiveData.riskTolerance}
                  onChange={handleRiskObjectiveDataChange}
                />
              </div>
            </div>
          </form>
        );
      case 8:
        return (
          <div>
            <Checkbox
              mt={20}
              ml={-3}
              required={true}
              disabled={
                isCustomerChkDisabled ||
                customerAccountAgreementData.acceptCustomerAgreement
              }
              labelLink={true}
              name="acceptCustomerAgreement"
              label={
                <React.Fragment>
                  I have read and accepted the
                  <span
                    onClick={(e) => {
                      setOpen({
                        ...open,
                        customerAgreementModal: true,
                      });
                      e.preventDefault();
                    }}
                  >
                    <Link ml={5} label="Customer Account Agreement" />.
                  </span>
                </React.Fragment>
              }
              checked={customerAccountAgreementData.acceptCustomerAgreement}
            />
            {validateCustomerAccountAgreementData.acceptCustomerAgreement.err && (
              <Text
                error={validateCustomerAccountAgreementData.acceptCustomerAgreement.err}
                label="Please check Customer Account Agreement"
                variant="subtitle1"
              />
            )}
          </div>
        );
      case 9:
        return (
          // EDIT HERE FOR DISCLOSURE

          <div>
            <Text
              mt={20}
              mb={50}
              label={<b>Please review & acknowledge all disclosures.</b>}
            />
            {validateTradingData.readMarginAgreement.err && tradingData.marginType === 'Margin Account' && (
              <div className="grd-row">
                <Text
                  mt={10}
                  error={validateTradingData.readMarginAgreement.err}
                  label="Please check Margin Agreement"
                  variant="subtitle1"
                />
              </div>
            )}
            {tradingData.marginType === 'Margin Account' && (
              <div>
                <div className="grd-row">
                  <div className="col">
                    <Checkbox
                      mt={!validateTradingData.readMarginAgreement.err ? 11 : 0}
                      ml={0}
                      required={true}
                      labelLink={true}
                      name="readMarginAgreement"
                      label={
                        <React.Fragment>
                          I have read and accepted the
                          <span
                            onClick={(e) => {
                              setOpen({ ...open, marginAgreementModal: true });
                              e.preventDefault();
                            }}
                          >
                            <Link ml={5} label="Margin Agreement" />.
                          </span>
                        </React.Fragment>
                      }
                      checked={tradingData.readMarginAgreement}
                      onChange={handleTradingDataChange}
                      disabled={true}
                    />
                  </div>
                </div>
                {validateTradingData.readMarginDisclosureStatement.err && (
                  <div className="grd-row">
                    <Text
                      mt={10}
                      error={validateTradingData.readMarginDisclosureStatement.err}
                      label="Please check Margin Disclosure Statement"
                      variant="subtitle1"
                    />
                  </div>
                )}
                <div className="grd-row">
                  <div className="col">
                    <Checkbox
                      mt={!validateTradingData.readMarginAgreement.err ? 11 : 0}
                      ml={0}
                      required={true}
                      labelLink={true}
                      name="readMarginDisclosureStatement"
                      label={
                        <React.Fragment>
                          I have read and accepted the
                          <span
                            onClick={(e) => {
                              setOpen({ ...open, marginDisclosureModal: true });
                              e.preventDefault();
                            }}
                          >
                            <Link ml={5} label="Margin Disclosure Statement" />.
                          </span>
                        </React.Fragment>
                      }
                      checked={tradingData.readMarginDisclosureStatement}
                      onChange={handleTradingDataChange}
                      disabled={true}
                    />
                  </div>
                </div>
                {validateTradingData.readMarginDisclosureStatement.err && (
                  <div className="grd-row">
                    <Text
                      mt={10}
                      error={validateTradingData.readMarginDisclosureStatement.err}
                      label="Please check Margin Dsiclosure Statement"
                      variant="subtitle1"
                    />
                  </div>
                )}
              </div>
            )}
            <div className="grd-row">
              <div className="col">
                <Checkbox
                  mt={!validateTradingData.readMarginAgreement.err ? 11 : 0}
                  ml={0}
                  required={true}
                  disabled={
                    isCustomerChkDisabled ||
                    customerAccountAgreementData.acceptCustomerAgreement
                  }
                  labelLink={true}
                  name="acceptCustomerAgreement"
                  label={
                    <React.Fragment>
                      I have read and accepted the
                      <span
                        onClick={(e) => {
                          setOpen({
                            ...open,
                            customerAgreementModal: true,
                          });
                          e.preventDefault();
                        }}
                      >
                        <Link ml={5} label="Customer Account Agreement" />.
                      </span>
                    </React.Fragment>
                  }
                  checked={customerAccountAgreementData.acceptCustomerAgreement}
                />
              </div>
            </div>
            {dynamicDisclosures?.map((disclosure, index) => (
              <div key={index}>
                <div className="grd-row">
                  <div className="col">
                    <Checkbox
                      mt={11}
                      ml={0}
                      required={true}
                      labelLink={true}
                      name={'read ' + disclosure.title}
                      label={
                        <React.Fragment>
                          I have read and accepted the{' '}
                          <span
                            onClick={(e) => {
                              setOpen({ ...open, DisclosureByIdModal: true });
                              e.preventDefault();
                              setDisclosureId(disclosure.disclosureId);
                              setDisclosureTitle(disclosure.title);
                              setDisclosureIndex(index);
                            }}
                          >
                            <Link ml={5} label={disclosure.title} />.
                          </span>
                        </React.Fragment>
                      }
                      checked={disclosure.checked}
                      onChange={(e) => {
                        const copy = [...dynamicDisclosures];
                        copy[index].checked = e.currentTarget.checked;
                        setDynamicDisclosures(copy);
                        handleSaveDisclosure();
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}

            <Text
              mt={50}
              mb={12}
              label={
                <React.Fragment>
                  <b>Disclosures Agreement: </b>
                  <span>
                    By accepting this document, I acknowledge that I have read and
                    understood each disclosure's terms and conditions.
                  </span>
                </React.Fragment>
              }
            />
          </div>
        );
      default:
        return '';
    }
  };

  const handleChangeFormHeader = (position) => {
    if (position === 0) {
      setActiveFAQ('');
      setActiveFormHeader('Create Your Access');
    } else if (position === 1) {
      setActiveFAQ('Account');
      setActiveFormHeader('Account Information');
    } else if (position === 2) {
      setActiveFAQ('Identification');
      setActiveFormHeader('Proof Of Identity');
    } else if (position === 3) {
      setActiveFAQ('Employment');
      setActiveFormHeader('Employment');
    } else if (position === 4) {
      setActiveFAQ('Trusted Contact');
      setActiveFormHeader('Trusted Contact');
    } else if (position === 5) {
      setActiveFAQ('Association');
      setActiveFormHeader('Investor Association');
    } else if (position === 6) {
      setActiveFAQ('Trading');
      setActiveFormHeader('Trading');
    } else if (position === 7) {
      setActiveFAQ('Objective');
      setActiveFormHeader('Investment Objective');
    } else if (position === 8) {
      setActiveFAQ('Customer Account Agreement');
      setActiveFormHeader('Customer Account Agreement');
    } else if (position === 9) {
      setActiveFAQ('Disclosures');
      setActiveFormHeader('Disclosures');
    } else if (position === 10) {
      setActiveFAQ('');
      setActiveFormHeader('Submit Your Application');
    } else {
      setActiveFAQ('');
      setActiveFormHeader('Completed!');
    }
  };

  const handleChangeFormSubHeader = (accountType) => {
    if (accountType === 'Joint') {
      return 'Primary Applicant';
    } else if (accountType === 'Corporate') {
      return 'Company Information';
    } else {
      return 'Basic Information';
    }
  };

  const handleNavigateForm = async (selectedForm) => {
    const completed = Object.values(isCompleted).reduce((a, form) => a + form, 0);
    const accountType = accountData.accountType;

    if (activeForm === 0) {
      return;
    }

    // disclosures
    if (selectedForm === 9 || selectedForm === 10) {
      if (correspondentData.correspondent) {
        const { disclosuresList } = await fetchDisclosures(
          userId,
          correspondentData.correspondent
        );
        setDynamicDisclosures(disclosuresList);
      }
    }
    if (selectedForm === 10) {
      if (completed < (['I', 'J'].includes(accountType) ? 10 : 9)) {
        setOpen({ ...open, submittionModal: true });
        return;
      }
    }

    if (selectedForm === 3 && accountData.accountType === 'C') {
      setOpen({ ...open, informModal: true });
      return;
    }

    if (selectedForm === 8 && !customerAccountAgreementData.acceptCustomerAgreement) {
      setOpen({ ...open, customerAgreementModal: true });
    }

    setActiveForm(selectedForm === 0 || activeForm === 11 ? activeForm : selectedForm);
    handleChangeFormHeader(
      selectedForm === 0 || activeForm === 11 ? activeForm : selectedForm
    );
  };

  const handleNext = async () => {
    if (activeForm === 8) {
      if (correspondentData.correspondent) {
        const { disclosuresList } = await fetchDisclosures(
          userId,
          correspondentData.correspondent
        );
        setDynamicDisclosures(disclosuresList);
      }
    }

    if (activeForm === 1 && !validateCreateAccountSubmission()) {
      return;
    }

    // if (activeForm === 2 && !validateCreateIdentificationSubmission()) {
    //   return;
    // }

    if (activeForm === 3 && !validateCreateEmploymentSubmission()) {
      return;
    }

    if (activeForm === 4 && !validateCreateTrustedContactSubmission()) {
      return;
    }

    if (activeForm === 5 && !validateCreateAssociationSubmission()) {
      return;
    }

    if (activeForm === 6 && !validateCreateTradingSubmission()) {
      return;
    }

    if (activeForm === 7 && !validateCreateInvestmentObjectiveSubmission()) {
      return;
    }

    if (activeForm === 8 && !validateCustomerAccountAgreement()) {
      return;
    }

    if (activeForm === 9 && !validateDisclosures()) {
      return;
    }

    if (activeForm === 9) {
      const completed = Object.values(isCompleted).reduce((a, form) => a + form, 0);
      const accountType = accountData.accountType;

      if (
        !isCompleted.account ||
        !isCompleted.identification ||
        !isCompleted.association ||
        !isCompleted.trading ||
        !isCompleted.trustedContact ||
        !isCompleted.customerAccountAgreement ||
        (['I', 'J'].includes(accountType) ? !isCompleted.employment : null)
      ) {
        if (completed < (['I', 'J'].includes(accountType) ? 9 : 8)) {
          setOpen({ ...open, submittionModal: true });
          return;
        }
      }
    }

    setActiveForm((prevActiveStep) => {
      let newActiveForm = prevActiveStep + 1;

      if (prevActiveStep === 2 && accountData.accountType === 'C') {
        newActiveForm = prevActiveStep + 2;
      }

      if (newActiveForm === 8 && !customerAccountAgreementData.acceptCustomerAgreement) {
        setOpen({ ...open, customerAgreementModal: true });
      }

      handleChangeFormHeader(newActiveForm);

      return newActiveForm;
    });
  };

  const handleSaveClient = async () => {
    await SaveClient(
      accessData,
      accountData,
      accountCoOfficerData,
      identificationData,
      employmentData,
      associationData,
      attachments,
      marginAgreementAttachment,
      onboardingAttachment,
      customerAccountAgreementAttachment,
      tradingData,
      investmentObjectiveData,
      eSignatureData,
      coIdentificationData,
      marginAgreementData,
      false,
      accountId,
      trustedContactData,
      customerAccountAgreementData,
      progress >= 99.99 ? 100 : progress
    );

    localStorage.clear();
    setOpen({ ...open, onBoardingModal: true });
  };

  const handleBack = () => {
    setActiveForm((prevActiveStep) => {
      let newActiveForm = prevActiveStep - 1;

      if (prevActiveStep === 4 && accountData.accountType === 'C') {
        newActiveForm = prevActiveStep - 2;
      }

      handleChangeFormHeader(newActiveForm);

      return newActiveForm;
    });
  };

  const handleCloseModal = (acceptedValue) => {
    setOpen({
      attentionModal: false,
      validationModal: false,
      submittionModal: false,
      informModal: false,
      signatureModal: false,
      marginAgreementModal: false,
      onBoardingModal: false,
      customerAgreementModal: false,
    });
    if (acceptedValue === 'agreement accepted') {
      setIsCustomerChkDisabled(false);
    }
  };

  const handleCloseDynamicModal = (index) => {
    setOpen({
      attentionModal: false,
      validationModal: false,
      submittionModal: false,
      informModal: false,
      signatureModal: false,
      marginAgreementModal: false,
      onBoardingModal: false,
      customerAgreementModal: false,
    });

    const copy = [...dynamicDisclosures];
    copy[index].checked = true;
    setDynamicDisclosures(copy);
  };

  const handleSaveDisclosure = async () => {
    let disclosureArray = [];

    for (let i = 0; i < dynamicDisclosures.length; i++) {
      const disclosure = dynamicDisclosures[i];
      if (disclosure.checked) {
        disclosureArray.push(disclosure.disclosureId);
      }
    }

    if (disclosureArray.length > 0) {
      await saveClientDisclosure(userId, disclosureArray);
    }
  };

  const handleSaveAndExit = async () => {
    handleSaveDisclosure();

    if (accountData.phoneNumber === '') {
      notifyError('Please provide the phone number first.');
      return;
    }

    confirm({
      title: 'Save and Exit',
      description: 'Are you sure you want to continue your application later?',
      confirmationText: 'Yes, Save and Exit',
      cancellationText: 'No, Cancel',
    })
      .then(async () => {
        try {
          let isRegister = true;
          setLoading({ ...loading, saveAndExit: true });

          const {
            client,
            ownersList,
            addressesList,
            contactInfosList,
            identificationsList,
          } = await readClient(accountId);

          const { accountNo } = client;

          //owner
          const primaryApplicant = ownersList.filter((v) => v.ownerType === 'Primary')[0];
          const coApplicant = ownersList.filter((v) => v.ownerType === 'Secondary');

          //ids
          const coOwnerIds = [];
          const identificationIds = [];
          const addressIds = [];
          const contactInfoIds = [];

          if (coApplicant.length > 0) {
            const getOwnerId = (data) => {
              coOwnerIds.push(data.ownerId);
            };
            coApplicant.forEach(getOwnerId);
          }

          if (identificationsList.length > 0) {
            const getIdentificationId = (data) => {
              identificationIds.push(data.identificationId);
            };
            identificationsList.forEach(getIdentificationId);
          }

          if (addressesList.length > 0) {
            const getAddressId = (data) => {
              addressIds.push(data.addressId);
            };
            addressesList.forEach(getAddressId);
          }

          if (contactInfosList.length > 0) {
            const getContactInfoId = (data) => {
              contactInfoIds.push(data.contactInfoId);
            };
            contactInfosList.forEach(getContactInfoId);
          }

          const saveAccountData = {
            ...accountData,
            usrId: accountId,
            primaryOwnerId: primaryApplicant?.ownerId ? primaryApplicant.ownerId : 0,
            email: accountData.email,
            addressIds: addressIds,
            phoneNumber: accountData.phoneNumber,
            firstName: accountData.primaryOfficerFirstName,
            middleName: accountData.primaryOfficerMiddleName,
            lastName: accountData.primaryOfficerLastName,
            contactInfoIds: contactInfoIds,
            correspondent: correspondentData.correspondent,
            accountNo: accountNo,
          };

          await SaveClient2(
            saveAccountData,
            accountCoOfficerData,
            identificationData,
            employmentData,
            associationData,
            attachments,
            marginAgreementAttachment,
            customerAccountAgreementAttachment,
            onboardingAttachment,
            tradingData,
            investmentObjectiveData,
            eSignatureData,
            coIdentificationData,
            marginAgreementData,
            true,
            accountId,
            trustedContactData,
            customerAccountAgreementData,
            progress >= 99.99 ? 100 : progress,
            isRegister
          );

          localStorage.clear();
          setActiveFormHeader('Partially Completed');
          setActiveForm(10);
          setOpen({ ...open, onBoardingModal: true });
        } catch (err) {
          setLoading({ ...loading, saveAndExit: false });
          notifyError(err.message);
          console.error(err);
          return;
        } finally {
          setPartialStatus(true);
          setLoading({ ...loading, saveAndExit: false });
        }
      })
      .catch(() => {
        console.error('error');
      });
  };

  const renderFormSwitch = (activeForm) => {
    const subHeader = 'Step ' + (activeForm + 1) + ' of ' + forms.length;

    switch (activeForm) {
      case 0:
        return <FormAccess subHeader={subHeader} />;
      case 10:
        return (
          <FormSubmit
            data={eSignatureData}
            setData={setESignatureData}
            accountType={accountData.accountType}
            onBack={handleBack}
            isLoading={loading.saveAndExit}
            onSubmit={handleSaveClient}
            onSaveAndExit={handleSaveAndExit}
            setOnboardingAttachment={setOnboardingAttachment}
            onboardingAttachment={onboardingAttachment}
            pdfData={{
              accessData: accessData,
              accountData: accountData,
              accountCoOfficerData: accountCoOfficerData,
              identificationData: identificationData,
              coIdentificationData: coIdentificationData,
              employmentData: employmentData,
              associationData: associationData,
              tradingData: tradingData,
              investmentObjectiveData: investmentObjectiveData,
              eSignatureData,
              trustedContactData: trustedContactData,
              customerAccountAgreementData: customerAccountAgreementData,
              dynamicDisclosures: dynamicDisclosures,
            }}
          />
        );
      default:
        return (
          <div className="register-b">
            <div className="register-b-ls">
              <div className="ls-h">
                <Text mb={12} variant="h2" label={activeFormHeader} />
                <Text label={'Step ' + (activeForm + 1) + ' of ' + forms.length} />
              </div>
              <div className="ls-b">
                <React.Fragment>
                  {selectedForm(activeForm)}
                  {activeForm < 11 ? (
                    <div className="stepper-buttons">
                      <div className="grd-cell-none">
                        <Button
                          disabled={
                            activeForm === 1 || loading.submit || loading.saveAndExit
                          }
                          label="Back"
                          onClick={handleBack}
                        />
                      </div>
                      <div className="grd-cell">
                        <Button label="Next" onClick={handleNext} />
                      </div>
                      <div className="grd-cell-none">
                        <Button
                          loading={loading.saveAndExit}
                          disabled={
                            loading.saveAndExit ||
                            loading.submit ||
                            (activeForm === 9 &&
                              accountData.accountType !== 'J' &&
                              !!eSignatureData.primaryApplicant) ||
                            (activeForm === 9 &&
                              accountData.accountType === 'J' &&
                              !!eSignatureData.primaryApplicant &&
                              !!eSignatureData.coApplicant)
                          }
                          label={loading.saveAndExit ? 'Saving...' : 'Save and Exit'}
                          onClick={handleSaveAndExit}
                        />
                      </div>
                    </div>
                  ) : null}
                </React.Fragment>
              </div>
            </div>
            {activeForm <= 10 && (
              <div className="register-b-rs">
                <div className="faq-w">
                  {activeForm < 8 && <FAQ pageName={activeFAQ} />}
                </div>
              </div>
            )}
          </div>
        );
    }
  };

  return (
    <div className="register-w">
      <Header
        activeForm={activeForm}
        forms={forms}
        isCompleted={isCompleted}
        progress={progress}
        accountData={accountData}
        onNavigate={handleNavigateForm}
      />
      <BrowserCompatibilityNotification />
      {renderFormSwitch(activeForm)}
      {isAttentionMessageShown && (
        <MessageModal
          modalTitle="Attention"
          coverImage={true}
          content={validationMessage}
          open={open.validationModal}
          onClose={handleCloseModal}
        />
      )}
      <MessageModal
        modalTitle="Attention"
        content={accountTypeCorporateInformMessage}
        open={open.informModal}
        onClose={handleCloseModal}
      />
      <MessageModal
        modalTitle="Attention"
        content={submittionWarningMessage}
        completed={isCompleted}
        accountType={accountData.accountType}
        navigate={handleNavigateForm}
        open={open.submittionModal}
        onClose={handleCloseModal}
      />
      {open.marginAgreementModal && (
        <MarginAgreementModal
          modalTitle="Margin Agreement"
          data={marginAgreementData}
          validateData={validateMarginAgreementData}
          tradingData={tradingData}
          accountData={accountData}
          setValidateData={setValidateMarginAgreementData}
          setAttachments={setMarginAgreementAttachment}
          setTradingData={setTradingData}
          setAccountData={setAccountData}
          handleChange={handleMarginAgreementDataChange}
          applicant={{
            accountNo: accountData.accountNo,
            accountType: accountData.accountType,
            firstName: accessData.firstName,
            lastName: accessData.lastName,
            coFirstName: accountData.coFirstName,
            coLastName: accountData.coLastName,
            phoneNumber: accountData.phoneNumber,
          }}
          open={open.marginAgreementModal}
          onClose={handleCloseModal}
        />
      )}
      {open.marginDisclosureModal && (
        <MarginDisclosureModal
          open={open.marginDisclosureModal}
          close={handleCloseModal}
          tradingData={tradingData}
          setTradingData={setTradingData}
        />
      )}
      {open.customerAgreementModal && (
        <CustomerAccountAgreementModal
          modalTitle="Customer Account Agreement"
          customerAccountAgreementData={customerAccountAgreementData}
          setAttachments={setCustomerAccountAgreementAttachment}
          setCustomerAccountAgreementData={setCustomerAccountAgreementData}
          open={open.customerAgreementModal}
          onClose={handleCloseModal}
        />
      )}
      {open.onBoardingModal && (
        <OnboardingDisclosureModal
          open={open.onBoardingModal}
          partiallyCompleted={partialStatus}
        />
      )}
      {open.DisclosureByIdModal && (
        <DisclosureByIdModal
          open={open.DisclosureByIdModal}
          close={handleCloseModal}
          disclosureId={disclosusreId}
          disclosureTitle={disclosureTitle}
          accept={() => handleCloseDynamicModal(disclosureIndex)}
        />
      )}
      <FloatingButton />
      <Notification />
    </div>
  );
}
