import React from 'react';
import Text from '../../components/Typography/Text';
import {ReactComponent as NotFound404} from '../../assets/graphics/not-found-graphic.svg';

export default function Verification() {

  return (
    <div style={{
      width: '100%', 
      height: '100vh', 
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'center', 
      alignItems: 'center'}}
    >
        <NotFound404 style={{width: 350, height: 320, marginTop: -60}}/>
        <Text
            variant="h2"
            label="Page Not Found!"
        />
        <Text
            mt={10}
            variant="subtitle1"
            label="Sorry, we can't find what you're looking for."
        />
    </div>
  );
}