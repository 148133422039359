import {
  MarginIntServiceClient,
  ListMarginIntRequest,
} from '../proto/reportpb/marginint_grpc_web_pb';
import { auth } from '../lib/auth/Auth';

import { stringToProtoDate } from './ConvertService';

const service = new MarginIntServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

export async function listMarginInterestCalc(param) {
  return new Promise((resolve, reject) => {
    const req = new ListMarginIntRequest();
    req.setFromDate(stringToProtoDate(param.fromDate));
    req.setToDate(stringToProtoDate(param.toDate));
    req.setCorrespondent(param.correspondent);
    req.setAccountNo(param.accountNo);
    req.setAccountName(param.accountName);
    req.setMasterAccountNo(param.masterAccountNo);
    req.setRep(param.rep);
    req.setBranch(param.branch);
    req.setStatus(param.status);

    service.listMarginInt(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
