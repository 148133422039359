/**
 * @fileoverview gRPC-Web generated client stub for usrpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.usrpb = require('./forgotcredentials_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.usrpb.ForgotCredentialServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.usrpb.ForgotCredentialServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.UsernameRequest,
 *   !proto.usrpb.ConfirmationResponse>}
 */
const methodDescriptor_ForgotCredentialService_ForgotUsername = new grpc.web.MethodDescriptor(
  '/usrpb.ForgotCredentialService/ForgotUsername',
  grpc.web.MethodType.UNARY,
  proto.usrpb.UsernameRequest,
  proto.usrpb.ConfirmationResponse,
  /**
   * @param {!proto.usrpb.UsernameRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.ConfirmationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.usrpb.UsernameRequest,
 *   !proto.usrpb.ConfirmationResponse>}
 */
const methodInfo_ForgotCredentialService_ForgotUsername = new grpc.web.AbstractClientBase.MethodInfo(
  proto.usrpb.ConfirmationResponse,
  /**
   * @param {!proto.usrpb.UsernameRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.ConfirmationResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.UsernameRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.usrpb.ConfirmationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.ConfirmationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.ForgotCredentialServiceClient.prototype.forgotUsername =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.ForgotCredentialService/ForgotUsername',
      request,
      metadata || {},
      methodDescriptor_ForgotCredentialService_ForgotUsername,
      callback);
};


/**
 * @param {!proto.usrpb.UsernameRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.ConfirmationResponse>}
 *     A native promise that resolves to the response
 */
proto.usrpb.ForgotCredentialServicePromiseClient.prototype.forgotUsername =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.ForgotCredentialService/ForgotUsername',
      request,
      metadata || {},
      methodDescriptor_ForgotCredentialService_ForgotUsername);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.PasswordRequest,
 *   !proto.usrpb.ConfirmationResponse>}
 */
const methodDescriptor_ForgotCredentialService_ForgotPassword = new grpc.web.MethodDescriptor(
  '/usrpb.ForgotCredentialService/ForgotPassword',
  grpc.web.MethodType.UNARY,
  proto.usrpb.PasswordRequest,
  proto.usrpb.ConfirmationResponse,
  /**
   * @param {!proto.usrpb.PasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.ConfirmationResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.usrpb.PasswordRequest,
 *   !proto.usrpb.ConfirmationResponse>}
 */
const methodInfo_ForgotCredentialService_ForgotPassword = new grpc.web.AbstractClientBase.MethodInfo(
  proto.usrpb.ConfirmationResponse,
  /**
   * @param {!proto.usrpb.PasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.ConfirmationResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.PasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.usrpb.ConfirmationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.ConfirmationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.ForgotCredentialServiceClient.prototype.forgotPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.ForgotCredentialService/ForgotPassword',
      request,
      metadata || {},
      methodDescriptor_ForgotCredentialService_ForgotPassword,
      callback);
};


/**
 * @param {!proto.usrpb.PasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.ConfirmationResponse>}
 *     A native promise that resolves to the response
 */
proto.usrpb.ForgotCredentialServicePromiseClient.prototype.forgotPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.ForgotCredentialService/ForgotPassword',
      request,
      metadata || {},
      methodDescriptor_ForgotCredentialService_ForgotPassword);
};


module.exports = proto.usrpb;

