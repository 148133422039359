import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import { AddCircle as AddCircleIcon } from '@material-ui/icons'
import { listAccount, createAccount } from '../../services/BankAccountService'
import { notifySuccess, notifyError } from '../Messages/Notification'
import color from '../../assets/css/colors'

const useStyles = makeStyles(() => ({
  dropdownWrapper: {
    display: 'flex',
    position: 'relative',
  },
  actionWrapper: {
    position: 'absolute',
    top: 29,
    right: 20,
    padding: 8,
    backgroundColor: 'unset',
    borderRadius: 5,
    '& svg': {
      width: 24,
      height: 24,
      cursor: 'pointer',
      '& path': {
        fill: color.primary.main,
      },
    },
  },
}))

export default function SelectWallet(props) {
  const classes = useStyles()

  const [options, setOptions] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [open, setOpen] = React.useState(false)

  const data = {
    bankAccountType: '',
    bankAddressId: 0,
    status: '',
    bankId: 0,
    glAccountId: 0,
    bankOwnerName: '',
    bankName: '',
    bankAccountNo: '',
    correspondent: '',
    accountNo: '',
    achRoutingNo: '',
    wireRoutingNo: '',
    plaidAccessToken: '',
    isInternational: false,
    bankIdentifierCode: '',
    approvedMethod: '',
  }

  const getOptions = async (props) => {
    const { bankAccountsList } = await listAccount(props)

    setOptions(bankAccountsList)
  }

  React.useEffect(() => {
    let param = {
      correspondent: props.correspondent,
      accountNo: props.accountNo,
      status: 'Active',
    }

    getOptions(param)
  }, [props.correspondent, props.accountNo])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = async (data) => {
    if (!data) {
      setOpen(false)
      return
    }

    let valid = true;
    if (!data.accountNo) {
      notifyError('Account No is required.');
      valid = false;
    }
    if (!data.correspondent) {
      notifyError('Correspondent is required.');
      valid = false;
    }
    if (!data.bankOwnerName) {
      notifyError('Bank Owner Name is required.');
      valid = false;
    }
    if (!data.bankName) {
      notifyError('Bank Name is required.');
      valid = false;
    }
    if (!data.achRoutingNo && !data.wireRoutingNo) {
      notifyError('ACH or Wire Routing No is required.');
      valid = false;
    }
    if ((data.wireRoutingNo || data.achRoutingNo) && !data.bankAddressId) {
      notifyError('Bank Address is required.');
      valid = false;
    }
    if (!data.bankAccountNo) {
      notifyError('Bank Account is required.');
      valid = false;
    }
    if (data.bankAccountNo.length < 8) {
      notifyError('Bank Account No min of 8 characters');
      valid = false;
    }
    if (!data.bankAccountType) {
      notifyError('Bank Account Type is required.');
      valid = false;
    }
    if (!data.approvedMethod) {
      notifyError('Approved Method is required.');
      valid = false;
    }
    if (!data.plaidAccessToken && data.approvedMethod === 'Plaid') {
      notifyError('Plaid Access Token is required.');
      valid = false;
    }
    if (!data.status) {
      notifyError('Status is required.');
      valid = false;
    }

    if (data.isInternational === true && data.bankIdentifierCode === '') {
      notifyError('Bank Identifier Code (BIC) is required.');
      valid = false;
    } else if (data.isInternational === false) {
      data.bankIdentifierCode = '';
    }

    if (typeof data.bankAddressId === 'object') {
      data.bankAddressId = data.bankAddressId.bankAddressId;
    }

    if (!valid) {
      return;
    }

    try {
      setLoading(true)

      await createAccount(data)

      notifySuccess('New Bank Account has been added.')
    } catch (error) {
      setLoading(false)
      notifyError(error.message)
    } finally {
      setOpen(false)
      setLoading(false)
    }
  }

  return (
    <div className={classes.dropdownWrapper}>
      <FormControl
        fullWidth
        error={props.error}
        style={{
          marginTop: props.mt ? props.mt : 0,
          marginBottom: props.mb ? props.mb : 0,
        }}
        disabled={props.disabled}
      >
        <InputLabel
          className={props.disabled ? classes.disabled : ''}
          shrink
          required={props.required}
        >
          {props.label}
        </InputLabel>
        <Select
          displayEmpty
          fullWidth
          disabled={props.disabled}
          name={props.name}
          value={props.value ? props.value : ''}
          onChange={props.onChange}
        >
          <MenuItem key="blank" value="">
            <span style={{ color: '#bbbdc5' }}>{props.placeholder}</span>
          </MenuItem>
          {options.length ? (
            options.map((i) => (
              <MenuItem key={i.bankId} value={i.bankId}>
                {i.bankAccountNo} ({i.bankName})
              </MenuItem>
            ))
          ) : (
            <MenuItem key="" value=""></MenuItem>
          )}
        </Select>
      </FormControl>
      {/* {props.allowAddWallet && (
        <div className={classes.actionWrapper}>
          <Tooltip arrow placement="top" title="Add Bank Account">
            <IconButton aria-label="add" onClick={() => handleOpen()}>
              <AddCircleIcon color="primary" />
            </IconButton>
          </Tooltip>
        </div>
      )}
      <AddBankAccountModal
        open={open}
        onClose={handleClose}
      /> */}
    </div>
  )
}
