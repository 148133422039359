import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import Text from '../../../../components/Typography/Text'
import { Chart, ArcElement } from 'chart.js'
import { formatQty } from '../../../../lib/fmt'
import { Typography } from '@material-ui/core'
import {Link} from "react-router-dom";



export default function Panel(props) {
  

  const styles = {
    chartContainer: {
      width: '40%',
      height: '20%',
      // top: "40%",
      // left: "40%",
      margin: 5,
      // position: "absolute",
      // transform: "translate(-40%, -40%)"
    },
    table: {
      width: '100%',
      maxWidth: '100%',
      marginBottom: '1rem',
      backgroundColor: 'transparent',
      borderCollapse:'collapse'
    },
    tabletr: {
      //borderBottom: '1px solid #CCC',
      //margin: '0px 20px 20px 20px',
      //paddingBottom: '10px'
    },
    tabletd: {
      borderBottom: '1px solid #CCC',
      //margin: '0px 20px 20px 20px',
      paddingBottom: '10px',
      paddingTop: '10px',
      fontColor :'#000',
      fontSize:'1em'
    },
    card: {
      minWidth: 150,
      display: 'inline-block',
      borderRadius: 15,
      //margin: 20,
      background: '#FFF',
      fontSize: '2em',
      fontWeight: '600',
      padding:'20px 20px 0 20px',
      fontFamily:'Inter',
    },
    cardTitle:{
      fontWeight:'600',
      // fontSize:'30px'
      fontFamily:'Inter',
    },
    cardValue: {
      minWidth: 150,
      display: 'inline-block',
      borderRadius: 15,
      //margin: 20,
      background: '#FFF',
      fontSize: '2em',
      fontWeight: '600',
      padding:'20px 20px 0 40px',
      fontFamily:'Inter',
    },
    link:{
      textDecoration: 'none',
      fontSize:'16px',
      color:'#292F4D',
      fontFamily:'Inter',
    },
    stats: {
      display: 'block',
      borderBottom: '1px solid #CCC',
      //margin: '0px 20px 20px 20px',
      paddingBottom: '10px'
    },
    statsContainer: {
      display: 'block',
      marginTop:'10px'
    },
    relative: {
      position: 'relative',
    },
    countContainer: {
      minWidth: 100,
      background: '#d6dfeb',
      marginBottom: 10,
      padding: 15,
      borderRadius: 15,
    },countContainerSuccess: {
      minWidth: 100,
      background: '#5F2B5F',
      marginBottom: 10,
      padding: 15,
      borderRadius: 15,
      color:'#fff !important',
      fontSize: '14px',
      fontFamily: 'Inter',
    },
    countContainerDanger: {
      minWidth: 100,
      background: '#B9324C',
      marginBottom: 10,
      padding: 15,
      borderRadius: 15,
      color:'#fff !important',
      fontSize: '14px',
      fontFamily: 'Inter',
    },
    countContainerWarning: {
      minWidth: 100,
      background: '#28947C',
      marginBottom: 10,
      padding: 15,
      borderRadius: 15,
      color:'#fff !important',
      fontSize: '14px',
      fontFamily: 'Inter',
    },
  }

  const options = {
    legend: {
      display: false,
      position: 'right',
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  }

  const data = {
    maintainAspectRatio: false,
    responsive: false,
    labels: ['a', 'b', 'c', 'd'],
    datasets: props.datasets,
  }

  return (
    <div className="grd-cell" style={styles.card}>
      <Typography variant="subtitle2" style={styles.cardTitle}>
        {props.title}
      </Typography>
      <div className="grd-row">
        <div className="grd-cell-4" style={styles.cardValue}>
          {formatQty(props.pendingCount+props.doneCount+props.failedCount)}
        </div>
      </div>
      <div className="grd-row" style={styles.statsContainer}>
        <div className="grd-cell-6">
          <div className="grd-row ">
            <div className="grd-cell">
              <table style={styles.table} >
                <tr  style={styles.tabletr}>
                    <td style={styles.tabletd}>
                      <Link to={"/app/digital-cash-request?transferType="+props.transferType+"&status=Done&search=true"}
                          target={"_blank"}
                          style={styles.link}
                        >
                         Completed   
                        </Link>
                    </td>
                    <td style={styles.tabletd}><Link to={"/app/digital-cash-request?transferType="+props.transferType+"&status=Done&search=true"}
                          target={"_blank"}
                          style={styles.link}
                        >
                          {formatQty(props.doneCount)}
                        </Link></td>
                </tr>
                <tr style={styles.tabletr}>
                    <td style={styles.tabletd}><Link to={"/app/digital-cash-request?transferType="+props.transferType+"&status=AllPending&search=true"}
                          target={"_blank"}
                          style={styles.link}
                          >
                            Pending
                          </Link></td>
                    <td style={styles.tabletd}><Link to={"/app/digital-cash-request?transferType="+props.transferType+"&status=AllPending&search=true"}
                          target={"_blank"}
                          style={styles.link}
                          >
                              {formatQty(props.pendingCount)}
                          </Link></td>
                </tr>
                <tr style={styles.tabletr}>
                    <td style={styles.tabletd}>
                      <Link to={"/app/digital-cash-request?transferType="+props.transferType+"&status=Failed&search=true"}
                      target={"_blank"}
                      style={styles.link}
                      >
                          {"Failed"}
                          
                      </Link>
                    </td>
                    <td style={styles.tabletd}>
                      <Link to={"/app/digital-cash-request?transferType="+props.transferType+"&status=Failed&search=true"}
                      target={"_blank"}
                      style={styles.link}
                      >
                          {formatQty(props.failedCount)}
                      </Link>

                    </td>
                </tr>
              </table>
              

            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
