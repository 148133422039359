import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, CircularProgress } from '@material-ui/core';
import PlacesAutocomplete from "react-places-autocomplete";
import { GetAddress } from '../../services/LocationPlaceService';
import TextField from '../Textfields/TextField';
import color from "../../assets/css/colors";
import fontFamily from "../../assets/css/fonts";
import {ReactComponent as Pin} from '../../assets/icons/placeholder.svg';

const useStyles = makeStyles(() => ({
  placesFieldWrapper: {
    position: 'relative',
    '& .error-label': {
      fontFamily: fontFamily.label,
      fontSize: 13,
      fontWeight: 600,
      fontStyle: 'italic',
      color: color.error.main,
      position: 'absolute',
      right: 0,
    }
  },
  placesOptionsWrapper: {
    position: 'absolute', 
    top: 80, 
    left: 0, 
    zIndex: 2,
    border: '1px solid ' + color.gray.opacity,
    borderRadius: 5,
    '& div, label': {
      cursor: 'pointer !important',
    },
    '& div:first-child': {
      marginRight: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg path': {
        fill: color.error.main,
      },
    },
    '& div:last-child > label:last-child': {
      fontSize: 13,
      fontWeight: 500,
      color: color.gray.main,
    },
    '& li:hover': {
      backgroundColor: color.primary.main + ' !important',
    },
    '& li:hover label': {
      color: color.light.light + ' !important',
    },
  },
  loaderText: {
    fontFamily: fontFamily.label,
    fontSize: 15,
    fontWeight: 600,
    color: color.default.main,
    backgroundColor: color.light.main,
    borderRadius: 5,
    padding: '12px 20px',
    '& .MuiCircularProgress-root': {
      position: 'relative',
      top: 2,
    },
    '& span': {
      position: 'relative',
      top: -4,
      display: 'inline-block',
      margin: '0 0 0 10px',
    }
  }
}));

export default function PlaceAutoCompleteField(props) {
  const classes = useStyles();

  const [gmapsLoaded, setGmapsLoaded] = React.useState(false)
  const [mounted, setMounted] = React.useState(false);
  const [address, setAddress] = React.useState("");

  React.useEffect(() => {
        if (!mounted) {
          let googlePlaceScript = document.getElementById('google-place');

          if (!gmapsLoaded && !googlePlaceScript) {
              window.initMap = () => setGmapsLoaded(true);
              const gmapScriptEl = document.createElement(`script`);
              gmapScriptEl.id = 'google-place';
              gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCgzwhK3NSQKfbsHg_cFzexywNepnqZlkQ&libraries=places&callback=initMap`;
              document.querySelector(`body`).insertAdjacentElement(`beforeend`, gmapScriptEl);
          } else {
            setGmapsLoaded(true);
          }
  
          setTimeout(function(){ setMounted(true); }, 2000);
        }
        return () => {
            setMounted(true);
        };
        // eslint-disable-next-line
  }, [mounted]);

  const handleSelect = async value => {
    const address = await GetAddress(value);

    props.setAddressData(props.name, address, props?.index);
    setAddress(address.address1);
  };

  return (
    <div>
        {mounted && gmapsLoaded ? (
          <PlacesAutocomplete
            value={address ? address : props.value}
            onChange={address ? setAddress : (value) => { props.onChange({currentTarget: {name: props.name, value: value}})}}
            onSelect={handleSelect}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div className={classes.placesFieldWrapper}>
                {props.error && (
                  <span className="error-label">{props.msg}</span>
                )}
                <TextField 
                  {...getInputProps(
                    { 
                        error: props.error,
                        required: props.required,
                        max: props.max,  
                        name: props.name, 
                        label: props.label, 
                        placeholder: props.placeholder,
                    })}
                /> 
                <div className={classes.placesOptionsWrapper}>
                  {loading ? <div className={classes.loaderText}><CircularProgress/> <span>Loading...</span></div> : null}
                  {suggestions.map((suggestion, key) => {
                    const style = {
                      backgroundColor: suggestion.active ? color.primary.main : color.light.main,
                      color: suggestion.active ? color.light.light : color.default.main,
                      whiteSpace: 'pre',
                    };
                      
                    return (
                        <MenuItem 
                          {...getSuggestionItemProps(suggestion, { style })}
                          key={key}
                          value={suggestion.description}
                        >
                          <div className="grd-row nm">
                            <div className="grd-cell-none">
                                <Pin/>
                            </div>
                            <div className="grd-cell">
                                <label>{suggestion.formattedSuggestion.mainText}</label><br/>
                                <label>{suggestion.formattedSuggestion.secondaryText}</label>
                            </div>
                          </div>
                        </MenuItem>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        ) : (<span>Loading Component...</span>)}
    </div>
  );
}