import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ButtonGroup, Button, Grid } from '@material-ui/core';

import ClientGrid from '../../components/ClientGrid/ClientGrid';
import ClientGrid2 from '../../components/ClientGrid/ClientGrid2'
import Table, { columnType } from '../../components/ClearingTable/Table';
import Text from '../../components/Typography/Text';
import BalanceCard from '../../components/Cards/BalanceCard';
import AccountSetupModal from '../../components/Modals/AccountSetupModal';
import AccountSetup from '../AccountSetup/AccountSetup';
import { notifyInfo, notifyError } from '../../components/Messages/Notification';

import { balanceColumns } from '../../lib/data/columns';
import { formatCurrency } from '../../lib/fmt';

import { ReactComponent as AccountsSetup } from '../../assets/icons/account-setup.svg';

import { listBalance } from '../../services/BalanceService';
import { getSystemDate } from '../../services/ProfileService';
import { getCurrentUser } from '../../services/AuthService';
import { readClient } from '../../services/ClientAccountService';

import {
  CustomBodyRenderCurrency,
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderDate,
  CustomBodyRenderDateTime,
  CustomBodyRenderBoolean,
  CustomBodyRenderAlign,
  CustomBodyRenderCenter,
  CustomBodyRenderQty,
  CustomBodyRenderHtmlString
} from '../../components/Table/CustomBodyRender';
import { formatPbDate, formatPbDateTime } from '../../lib/fmt';

export default function Dashboard() {
  const { Status = 'Inactive', AccountId, UserType } = getCurrentUser();

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gridView, setGridView] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [totalBalance, setTotalBalance] = useState(0);
  const [clientStatus, setClientStatus] = useState(null);
  const [subLabel, setSubLabel] = useState('');
  let clientDataStatus = ''

  const columns = [
    {
      // Already converted to string in handlesearch
      name: 'date',
      label: 'Date',
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
      options: {
        customBodyRender: CustomBodyRenderAlign
      }
    },
    {
      name: 'branch',
      label: 'Branch',
      options: {
        customBodyRender: CustomBodyRenderAlign
      }
    },
    {
      name: 'accountNo',
      label: 'Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign
      }
    },
    {
      name: 'accountName',
      label: 'Account Name',
      options: {
        customBodyRender: CustomBodyRenderAlign
      }
    },
    {
      name: 'accountStatus',
      label: 'Account Status',
      options: {
        customBodyRender: CustomBodyRenderAlign
      }
    },
    {
      name: 'tdCashBalance',
      label: 'TD Cash Balance',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'tdLongMarketValue',
      label: 'TD Long Market Value',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'tdShortMarketValue',
      label: 'TD Short Market Value',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'tdEquity',
      label: 'TD Equity',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'tdAdjustedBalance',
      label: 'TD Adjusted Balance',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'sdCashBalance',
      label: 'SD Cash Balance',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'sdLongMarketValue',
      label: 'SD Long Market Value',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'sdShortMarketValue',
      label: 'SD Short Market Value',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'sdEquity',
      label: 'SD Equity',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'sdAdjustedBalance',
      label: 'SD Adjusted Balance',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'a-selectAll',
      label: 'Select All',
      options: {
        display: true,
        setCellHeaderProps: () => ({
          style: {
            width: '0px',
            display: 'table-cell',
            padding: 0,
            pointerEvents: 'none',
            fontSize: 0,
          },
        }),
      },
    },
  ];

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
  };

  const loadBalance = async () => {
    let totalBalance = 0;

    try {
      setLoading(true);

      const systemDate = await getSystemDate();

      const searchData = {
        fromDate: systemDate,
        toDate: systemDate,
      };

      const { balancesList } = await listBalance(searchData);

      totalBalance = balancesList.reduce((partialSum, a) => {
        return partialSum + parseFloat(a.tdCashBalance);
      }, 0);

      setTotalBalance(totalBalance || 0);

      let rowsCopy = balancesList;

      const rowsCopyOutput = rowsCopy.map((data) => {
        let tempDate = formatPbDate(data.date);
        data.date = tempDate

        return data;
      })
      
      setRows(rowsCopyOutput);

      notifyInfo(
        balancesList.length > 0
          ? balancesList.length + ' search result(s)'
          : 'No records found.'
      );
    } catch (error) {
      notifyError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getAccountClientStatus = async () => {
    try {
      const res = await readClient(AccountId);
      setClientStatus(res.client.status);
      clientDataStatus = res.client.status;
      if(Status === 'Active' && (clientDataStatus === 'Active' || UserType !== 'Client')) {
        loadBalance();
      }
      if(res.client.status === 'Pending'){
        setSubLabel("Your application has been completed. It is now in pending approval.")
      } else {
        setSubLabel("Please complete your application.")
      }
      return res.client.status;
    } catch (error) {
      console.error(error);
    }
  };
  
  useEffect(() => {
    getAccountClientStatus();
  }, []);

  const getCsvData = async () => {
    const systemDate = await getSystemDate();

    const searchData = {
      fromDate: systemDate,
      toDate: systemDate,
    };

    const data = await listBalance(searchData);

    return data.balancesList;
  };

  const handleToggleView = async (view) => {
    setGridView(view);
  };

if (clientStatus === null ){
  return <></>
}

  return (
    <div className="page-c">
      {Status === 'Active' && (clientStatus === 'Active' || UserType !== 'Client') ? (
        <>
          <div>
            <Text mt={8} variant="h1" label="Dashboard" />
            <Text mt={10} variant="subtitle2" label="Balance Summary Dashboard" />
          </div>
          {rows.length > 0 && (
            <div className="grd-row grd-align-end">
              <div className="grd-cell">
                <div className="total-balance-card">
                  <Text variant="subtitle2" label="Total Balance" />
                  <Text mt={8} variant="h1" label={formatCurrency(totalBalance)} />
                </div>
              </div>
              <div className="grd-cell-none">
                <ButtonGroup disableElevation color="primary">
                  <Button
                    variant={gridView ? 'contained' : 'outlined'}
                    onClick={() => handleToggleView(true)}
                  >
                    Grid
                  </Button>
                  <Button
                    variant={!gridView ? 'contained' : 'outlined'}
                    onClick={() => handleToggleView(false)}
                  >
                    Tile
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          )}
          <div className="mt-20">
            {rows.length > 0 && gridView ? (
              // <ClientGrid
              //   title="Balance Summary"
              //   rowIdField="accountId"
              //   columns={balanceColumns}
              //   rows={rows}
              //   getCsvData={getCsvData}
              //   isLoading={loading}
              //   setRows={setRows}
              // />
              // <ClientGrid2
              //   title="Balance Summary"
              //   data={rows}
              //   columns={columns}
              //   options={options}
              // />
              <Table
                title={'Balance Summary'}
                data={rows}
                columns={columns}
                options={options}
              />
            ) : rows.length > 0 && !gridView ? (
              <div className="card-wrapper">
                <Grid container spacing={3}>
                  {rows.map((balance, key) => {
                    return (
                      <Grid
                        item
                        sm={12}
                        md={6}
                        lg={6}
                        xl={4}
                        key={balance.accountName + '-' + key}
                      >
                        <BalanceCard data={balance} />
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            ) : (
              <div className="no-data-wrapper">
                <Text
                  variant="body1"
                  label={
                    !loading && rows.length === 0
                      ? 'Sorry, no data found'
                      : 'Fetching data, please wait...'
                  }
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="dashboard-c">
          <div className="dashboard-b">
            <div className="grd-row nm">
              <NavLink className={'grd-cell'} onClick={(e) => setOpen(true)} to="#">
                <AccountsSetup />
                <div className="mt-20">
                  <Text variant={'subtitle1'} label="Client Account" />
                </div>
              </NavLink>
            </div>
            <div className="grd-row mt-30">
              <div className="mt-30">
                <Text
                  variant={'h3'}
                  label={subLabel}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {open && (
        <AccountSetupModal
          content={<AccountSetup close={() => {
            getAccountClientStatus();
            setOpen(false);
          }} />}
          open={open}
          status={Status}
        />
      )}
    </div>
  );
}
