import React from 'react';
import { styled, List, ListItem, ListItemText, Divider, Paper } from '@material-ui/core';
import { formatQty, formatDollar } from '../../../../lib/fmt';
// import { makeStyles } from '@material-ui/core/styles';

export default function OrderEntryModalDetails(props) {
  const {
    positionDetails,
    assetType,
    lastTrades,
    accountDetails,
    symbolDescription,
    symbolDetails,
  } = props;

  const StyledList = styled(List)({
    '& .MuiListItem-gutters': {
      paddingLeft: 10,
      paddingRight: 10,
    },
    '& .MuiListItem-gutters *': {
      fontSize: 12,
    },
  });

  return (
    <Paper variant="outlined">
      <StyledList>
        <ListItem>
          <ListItemText primary="BOD Cash" />
          <div>{formatDollar(accountDetails.cashBalance)}</div>
        </ListItem>
        <ListItem>
          <ListItemText primary="Additional Cash" />
          <div>{formatDollar(accountDetails.additionalCash)}</div>
        </ListItem>
        <ListItem>
          <ListItemText primary="Account Value" />
          <div>{formatDollar(accountDetails.accountValue)}</div>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Position QTY" />
          <div>{formatQty(positionDetails.positionQty)}</div>
        </ListItem>
        <ListItem>
          <ListItemText primary="Position Value" />
          <div>{formatDollar(positionDetails.positionValue)}</div>
        </ListItem>
        {assetType === 'O' && (
          <ListItem>
            <ListItemText primary="Underlying Position QTY" />
            <div>{formatQty(positionDetails.underlyingPosition)}</div>
          </ListItem>
        )}
        {/* only used in share calculator */}
        {/*
        {assetType === 'MF' && (
          <React.Fragment>
            <ListItem>
              <ListItemText primary="Share" />
              <div>${data.share}</div>
            </ListItem>
            <ListItem>
              <ListItemText primary="Dollar" />
              <div>${data.dollar}</div>
            </ListItem>
          </React.Fragment>
        )}
        <ListItem>
          <ListItemText primary="Amount" />
          <div>${data.amt}</div>
        </ListItem>
        */}
        <Divider />
        <ListItem>
          <ListItemText primary="Symbol Description" secondary={symbolDescription} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Last Price" />
          <div>{formatDollar(symbolDetails.lastPrice)}</div>
        </ListItem>
        {assetType === 'O' && (
          <React.Fragment>
            <ListItem>
              <ListItemText primary="Bid Price" />
              <div>{formatDollar(symbolDetails.bidPrice)}</div>
            </ListItem>
            <ListItem>
              <ListItemText primary="Ask Price" />
              <div>{formatDollar(symbolDetails.askPrice)}</div>
            </ListItem>
          </React.Fragment>
        )}
        <Divider />
        <ListItem>
          <ListItemText
            primary="Last Trades"
            secondary={lastTrades?.map((trade) => (
              <React.Fragment key={trade}>
                <span>{trade}</span> <br />
              </React.Fragment>
            ))}
          />
        </ListItem>
      </StyledList>
    </Paper>
  );
}
