import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Main from './components/Main/Main';
import IdleTimerContainer from './components/Misc/IdleTimerContainer';
import Login from '../src/pages/Login/Login';
import Register from '../src/pages/Register/Register';
import PageNotFound from '../src/pages/PageNotFound/PageNotFound';
import Verification from '../src/pages/Verification/Verification';
import authSvc from './services/AuthService';
import Impersonate from '../src/pages/Impersonate/Impersonate';
import Loginuser from './pages/Loginuser/Loginuser';
import ForgotUsername from './pages/Forgot/ForgotUsernamePreLogin';
import ForgotPassword from './pages/Forgot/ForgotPassword';
import LoginIdentity from './pages/Login/LoginIdentity';
import MainLayout from './components/Layout/Layout';
import { theme } from './assets/css/theme'
import { ThemeProvider } from '@material-ui/core/styles';

function AppV2() {
  const [isCodeValidated, setIsCodeValidated] = React.useState(false);

  React.useEffect(() => {
    try {
      const isValid = authSvc.getValidCodeResponse();
      setIsCodeValidated(isValid);

    } catch (error) {
      console.error(error);
    }
  }, []);

  const isAuthenticated = () => {
   // console.log("user :", user)
   // console.log("isCodeValidated :", isCodeValidated)
   // console.log("isAuthenticated ", (user && user.Username && isCodeValidated))
    const user = authSvc.getCurrentUser()
    return user && user.Username && isCodeValidated;
  };

  const getlandingPage = () => {
    const u = authSvc.getCurrentUser();
    if (['Master Account', 'Counterparty', 'User'].includes(u?.UserType)) {
      return '/app/bank-summary'
    } else {
      return '/app/dashboard'
    }
  }
 
  const PrivateRoute = function ({ component, ...rest }) {
    return (
      <Route {...rest} render={(props) => {
        if (isAuthenticated()) {
          return React.createElement(component, {
            ...props,
            user: authSvc.getCurrentUser(),
            pages: authSvc.getPages(),
          });
        }

        if (props.location.search) {
          sessionStorage.setItem('search', props.location.search.substring(1, props.location.search.length));
        }

        return (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />);
      }}
      />
    );
  };

  const PublicRoute = function ({ component, ...rest }) {
    return (
      <Route {...rest} render={(props) => {
        if (!isAuthenticated()) {
          return React.createElement(component, props);
        }

        const searchQuery = sessionStorage.getItem('search');
        sessionStorage.removeItem('search');

        return (<Redirect to={{ pathname: props.location?.state?.from?.pathname || '/', search: searchQuery || '', }} />
        );
      }}
      />
    );
  };

  return (
    <React.Fragment>
      {isAuthenticated() && <IdleTimerContainer />}
      <Switch>
        <Route
          exact
          path='/'
          render={() =>
            <Redirect to={getlandingPage()} />
          }
        />
        <Route
          exact
          path='/app'
          render={() =>
            <Redirect to={getlandingPage()} />
          }
        />
        <PrivateRoute path="/app" component={MainLayout} />
        <PublicRoute path="/login" component={LoginIdentity} />
        <Route path="/register/:verfied?" render={(props) => (<Register params={{ ...props }} />)} />
        <Route path="/impersonate" render={(props) => (<Impersonate params={{ ...props }} />)} />
        <Route path="/loginuser" render={(props) => (<Loginuser params={{ ...props }} />)} />
        <Route path="/forgot-username" render={(props) => (<ForgotUsername params={{ ...props }} />
        )} />
        <PublicRoute exact path="/verification" component={Verification} />
        <Route path="/*" component={PageNotFound} />
        <Route component={Error} />
      </Switch>
    </React.Fragment>
  );
}

export default AppV2;