import React from 'react';
import ClientGrid from '../../../components/ClientGrid/ClientGrid';
import ClientGrid2 from '../../../components/ClientGrid/ClientGrid2'
import Table, { columnType } from '../../../components/ClearingTable/Table';
import Button from '../../../components/Buttons/Button';
import SelectSystemCode from '../../../components/Dropdown/SelectSystemCode';
import Text from '../../../components/Typography/Text';
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from '../../../components/Messages/Notification';
import { modelingColumns } from '../../../lib/data/columns';
import {
  listModeling,
  createTradeSheet,
} from '../../../services/ModelingService';
import QueryParam from '../../../services/QueryParamService';
import MoneyField from '../../../components/Textfields/MoneyField';
import ModelingModal from './Components/ModelingModal'
import {
  CustomBodyRenderCurrency,
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderDate,
  CustomBodyRenderDateTime,
  CustomBodyRenderBoolean,
  CustomBodyRenderAlign,
  CustomBodyRenderHtmlString,
  CustomBodyRenderCenter,
  CustomBodyRenderPercentage,
} from '../../../components/Table/CustomBodyRender';

export default function Modeling() {
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      accountValue: '',
      model: '',
      platform: '',
      equity: '',
      fixedIncomeI: '',
    })
  );
  const [createData, setCreateData] = React.useState({
    accountNo: '',
    accountValue: '',
    model: '',
    platform: '',
    equity: '',
    fixedIncomeI: '',
  });

  const [errorMessage, setErrorMessage] = React.useState(
    'Check required fields!'
  );
  const [isValid, setIsValid] = React.useState(false);
  const [showCreateTradeSheet, setShowCreateTradeSheet] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  const columns = [
    {
      name: 'allocationModelName',
      label: 'Allocation Model',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'symbol',
      label: 'Symbol',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'symbolDescription',
      label: 'Symbol Description',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'allocatedPercent',
      label: 'Allocated Percentage',
      options: {
        customBodyRender: CustomBodyRenderPercentage,
      }
    },
    {
      name: 'allocatedValue',
      label: 'Allocated Value',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
  ];

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
  };
  
  const init = async () => {
    const searchParameters = localStorage.getItem('search_data');

    const { accountValue, model, platform, equity, fixedIncomeI } =
      searchParameters ? JSON.parse(searchParameters) : searchData;

    setSearchData({
      ...searchData,
      accountValue: accountValue ? accountValue : 0,
      model: model ? model : '',
      platform: platform ? platform : '',
      equity: equity ? equity : '',
      fixedIncomeI: fixedIncomeI ? fixedIncomeI : '',
    });
  };

  React.useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  const handleSearchDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setSearchData({ ...searchData, [input.name]: input.value });
  };

  const handleClose = async (data) => {
    if (data) {
      try {
        // Create Trade Sheet
        const resp = await createTradeSheet(data);
        
        notifySuccess('Trade Sheet has been added.');
      } catch (error) {
        console.error(error);
        notifyError(error.message);
      }
    }
   
    setOpenModal(false);
  };

  const handleOpen = async (data) => {
    setCreateData({accountNo: '', ...searchData});
    setOpenModal(true)
  };

  const handleSearch = async () => {
    try {
      setShowCreateTradeSheet(false);
      setLoading(true);
      QueryParam.set(searchData);

      localStorage.setItem('search_data', JSON.stringify(searchData));

      const data = await listModeling(searchData);

      setRows(data.modelingsList);
      notifyInfo(
        data.modelingsList.length > 0
          ? // ? data.modelingsList.length + ' Modeling result(s)'
            ' Allocation list result(s)'
          : 'No records found.'
      );

      setShowCreateTradeSheet(true);
    } catch (error) {
      setLoading(false);
      notifyError(error.message);
      setShowCreateTradeSheet(false);
      console.log(error);
      return;
    } finally {
      setLoading(false);
    }
  };

  const handleValid = () => {
    setIsValid(false);
    if (!searchData.accountValue) {
      setErrorMessage('Account value is required');
      notifyError(errorMessage);
      setIsValid(false);
      setShowCreateTradeSheet(false);
      return;
    } else if (searchData.accountValue.toString() === '0') {
      setErrorMessage('Account value should not be zero');
      notifyError(errorMessage);
      setIsValid(false);
      setShowCreateTradeSheet(false);
      return;
    } else if (!searchData.model) {
      setErrorMessage('Model is required');
      notifyError(errorMessage);
      setIsValid(false);
      setShowCreateTradeSheet(false);
      return;
    } else if (!searchData.platform) {
      setErrorMessage('Platform is required');
      notifyError(errorMessage);
      setIsValid(false);
      setShowCreateTradeSheet(false);
      return;
    } else {
      handleSearch();
    }
  };

  const getCsvData = async () => {
    const data = await listModeling(searchData);

    return data.modelingsList;  
  };

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Modeling" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading}
              loading={loading}
              type="generate"
              label={loading ? 'Generating...' : 'Generate'}
              onClick={handleValid}
            />
          </div>
          {showCreateTradeSheet && (
            <div className="grd-cell-none">
              <Button
                disabled={loading}
                loading={loading}
                type="plus"
                label="Create Trade Sheet"
                onClick={handleOpen}
              />
            </div>
          )}
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <MoneyField
              required={true}
              name="accountValue"
              label="Account Value (in Dollars)"
              value={searchData.accountValue}
              onChange={handleSearchDataChange}
              type="number"
              placeholder="$"
            />
          </div>
          <div className="grd-cell">
            <SelectSystemCode
              required={true}
              name="model"
              label="Model"
              placeholder=""
              type="SAS Model"
              value={searchData.model}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <SelectSystemCode
              required={true}
              name="platform"
              label="Platform"
              placeholder=""
              type="SAS Platform"
              value={searchData.platform}
              onChange={handleSearchDataChange}
            />
          </div>
        </div>
      </div>
      <div className="mt-20">
        {/* <ClientGrid
          title="Modeling"
          rowIdField="trnsId"
          columns={modelingColumns}
          rows={rows}
          nosearch
          getCsvData={getCsvData}
          isLoading={loading}
          setRows={setRows}
        /> */}
        {/* <ClientGrid2
          title="Modeling"
          data={rows}
          columns={columns}
          options={options}
        /> */}
        <Table
          title={'Modeling'}
          data={rows}
          columns={columns}
          options={options}
        />
      </div>
      <div className="mt-20">
        {openModal && <ModelingModal
        data={createData}
        open={openModal}
        close={handleClose}
        loading={loading}>
      </ModelingModal>}
      </div>
    </div>
  );
}
