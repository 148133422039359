//ADMINISTRATOR
export const dataToClientAdministrator = (
	accessData,
	accountData,
	incomplete,
	accountId,
) => {
	const { usrId, firstName, middleName, lastName, email, password } =
		accessData;
	const { phoneNumber } = accountData;

	const administrator = {};

	administrator.usrId = usrId ? usrId : null;
	administrator.name =
		firstName + (middleName ? ' ' + middleName + ' ' : ' ') + lastName;
	administrator.email = email;
	administrator.password = password;
	administrator.defaultAccess = 'Full';
	administrator.userType = 'Client';
	administrator.status = 'Active';
	administrator.authenticationMode = 'Email';
	administrator.mobileNo = phoneNumber;
	administrator.accountId = accountId

	return administrator;
};

//ACCOUNT
export const accountDataToAccountClient = (
	accountId,
	accessData,
	accountData,
	identificationData,
	investmentObjectiveData,
	tradingData,
	eSignatureData,
	marginAgreementData,
	customerAccountAgreementData,
	progress,
	incomplete
) => {
	const {
		correspondent,
		accountNo,
		accountType,
		subAccountType,
		companyName,
		country,
		taxId,
	} = accountData;
	const {
		marginType,
		readMarginDisclosureStatement,
		readMarginAgreement,
		stocks,
		options,
	} = tradingData;
	const {
		investmentObjective,
		annualIncome,
		liquidNetWorth,
		fund,
		taxBracket,
		riskTolerance,
	} = investmentObjectiveData;
	const {
		firstName,
		middleName,
		lastName,
		securityWordHint,
		securityWord,
		promoCode,
	} = accessData;
	const { taxAddress, taxCountry, statusReason, kycStatus } =
		identificationData;
	const { rep } = marginAgreementData;
	const { primaryApplicant } = eSignatureData;
	const { acceptCustomerAgreement } = customerAccountAgreementData;

	const accountClient = {};

	accountClient.accountId = accountId;
	accountClient.accountType = accountType;
	accountClient.subType = (accountType === 'J' || accountType === 'C' || accountType === 'IRA') ? subAccountType : '';
	accountClient.marginType = marginType
		.replace(/\s/g, '')
		.replace('Account', '');
	accountClient.rep = rep;
	accountClient.marginAgreement = readMarginAgreement;
	accountClient.marginDisclosure = readMarginDisclosureStatement;
	accountClient.stocks = stocks;
	accountClient.option = options;
	accountClient.investmentObjectiveCode = investmentObjective;
	accountClient.annualIncome = annualIncome;
	accountClient.liquidNetWorth = liquidNetWorth;
	accountClient.sourceOfFunds = fund;
	accountClient.correspondent = correspondent;
	accountClient.accountNo = accountNo;
	accountClient.masterAccountNo = accountNo;
	accountClient.tin = accountType === 'C' ? taxId : '';
	accountClient.accountName =
		accountType !== 'C'
			? firstName + (middleName ? ' ' + middleName + ' ' : ' ') + lastName
			: companyName;
	accountClient.broker = ['I', 'J'].includes(accountType)
		? 'Non Broker Dealer'
		: 'Broker Dealer';
	accountClient.legalEntity = ['I', 'J'].includes(accountType)
		? 'Individual'
		: 'Corporation';
	accountClient.taxCountry = taxAddress ? country : taxCountry;
	accountClient.w8w9 =
		accountClient.taxCountry === 'US' || accountClient.taxCountry === 'USA'
			? 'W9'
			: 'W8';
	accountClient.tefra = 'Exempt';
	accountClient.status = progress !== 100 ? 'In Progress' : 'Pending';
	accountClient.imgSignature = primaryApplicant;
	accountClient.kycStatus = kycStatus;
	accountClient.statusReason = statusReason;
	accountClient.promoCode = promoCode;
	accountClient.securityWord = securityWord;
	accountClient.securityWordHint = securityWordHint;
	accountClient.taxBracket = taxBracket;
	accountClient.riskTolerance = riskTolerance;
	accountClient.customerAccountAgreement = acceptCustomerAgreement;
	accountClient.onboardingPercent = progress;

	return accountClient;
};

//OWNER
export const dataToOwner = (
	accountId,
	accessData,
	accountData,
	identificationData,
	employmentData,
	associationData,
	investmentObjectiveData
) => {
	const {
		correspondent,
		accountNo,
		accountType,
		country,
		primaryOfficerFirstName,
		primaryOfficerMiddleName,
		primaryOfficerLastName,
		primaryOfficer,
		primaryOwner,
		ownershipPercentage,
		maritalStatus,
	} = accountData;
	const {
		isExecutive,
		stockSymbol,
		isRegisteredBroker,
		companyName,
		affiliatedPerson,
		affiliatedPersonPosition,
	} = associationData;
	const { firstName, middleName, lastName } = accessData;
	const { birthDate, citizenshipAddress, citizenship, kycStatus } =
		identificationData;
	const { employerName, previousEmployerName, occupation, employmentStatus, yearsEmployed } =
		employmentData;
	const { natureOfBusiness } = investmentObjectiveData;

	const owner = {};

	owner.accountId = accountId;
	owner.correspondent = correspondent;
	owner.accountNo = accountNo;
	owner.firstName = ['I', 'J'].includes(accountType)
		? firstName
		: primaryOfficerFirstName || firstName;
	owner.middleName = ['I', 'J'].includes(accountType)
		? middleName
		: primaryOfficerMiddleName || middleName;
	owner.lastName = ['I', 'J'].includes(accountType)
		? lastName
		: primaryOfficerLastName || lastName;
	owner.dateOfBirth = birthDate;
	owner.citizenship = citizenshipAddress ? country : citizenship;
	owner.country = citizenshipAddress ? country : citizenship;
	owner.employmentStatus = accountType === 'C' ? 'Excepted' : employmentStatus;
	owner.employmentName = accountType === 'C' ? '' : employerName;
	owner.previousEmploymentName = accountType === 'C' ? '' : previousEmployerName;
	owner.employmentOccupation = accountType === 'C' ? '' : occupation;
	owner.ownership = isExecutive === 'Yes' ? true : false;
	owner.stockOwnerShip = stockSymbol;
	owner.brokerMember = isRegisteredBroker === 'Yes' ? true : false;
	owner.brokerName = companyName;
	owner.affiliatePerson = affiliatedPerson;
	owner.affiliatePersonPosition = affiliatedPersonPosition;
	owner.ownerType = 'Primary';
	owner.ownershipPercent = ownershipPercentage;
	owner.owner = primaryOwner;
	owner.officer = primaryOfficer;
	owner.kycStatus = kycStatus;
	owner.maritalStatus = maritalStatus;
	owner.natureOfBusiness = natureOfBusiness;
	owner.yearsEmployed = yearsEmployed;
	return owner;
};

export const coApplicantToOwner = (accountId, accountData) => {
	const { coFirstName, coMiddleName, coLastName, coCountry } = accountData;

	const owner = {};

	owner.accountId = accountId;
	owner.firstName = coFirstName;
	owner.middleName = coMiddleName;
	owner.lastName = coLastName;
	owner.country = coCountry;
	owner.ownerType = 'Secondary';
	owner.ownershipPercent = '';
	owner.owner = false;
	owner.officer = false;

	return owner;
};

export const coOfficerToOwner = (
	accountId,
	accountCoOfficerData,
	coIdentificationData
) => {
	const {
		correspondent,
		accountNo,
		coOfficerFirstName,
		coOfficerMiddleName,
		coOfficerLastName,
		coOwnershipPercentage,
		coPrimaryOwner,
		coPrimaryOfficer,
		coOfficerCountry,
	} = accountCoOfficerData;
	const {
		idAddress,
		countryOfIssuance,
		citizenship,
		citizenshipAddress,
		birthDate,
	} = coIdentificationData;

	const owner = {};

	owner.accountId = accountId;
	owner.correspondent = correspondent;
	owner.accountNo = accountNo;
	owner.firstName = coOfficerFirstName;
	owner.middleName = coOfficerMiddleName;
	owner.lastName = coOfficerLastName;
	owner.ownershipPercent = coOwnershipPercentage;
	owner.country =
		coOwnershipPercentage >= 25
			? idAddress
				? coOfficerCountry
				: countryOfIssuance
			: coOfficerCountry;
	owner.citizenship =
		coOwnershipPercentage >= 25
			? citizenshipAddress
				? coOfficerCountry
				: citizenship
			: coOfficerCountry;
	owner.dateOfBirth = birthDate;
	owner.owner = coPrimaryOwner;
	owner.officer = coPrimaryOfficer;
	owner.ownerType = 'Secondary';
	owner.employmentStatus = 'Excepted';

	return owner;
};

//ADDRESS
export const accountToAddress = (accountId, ownerId, accountData) => {
	const {
		correspondent,
		accountNo,
		address1,
		address2,
		city,
		state,
		zipCode,
		country,
		proofOfAddress,
	} = accountData;

	const address = {};

	address.accountId = accountId;
	address.ownerId = ownerId;
	address.correspondent = correspondent;
	address.accountNo = accountNo;
	address.address1 = address1;
	address.address2 = address2;
	address.city = city;
	address.state = state;
	address.zipCode = zipCode;
	address.country = country;
	address.addressType = 'Primary';
	address.status = 'Active';
	address.createdBy = 'Client';
	address.imgProofOfAddress = proofOfAddress;

	return address;
};

export const accountPrimaryOfficerToAddress = (
	accountId,
	ownerId,
	accountData
) => {
	const {
		correspondent,
		accountNo,
		primaryOfficerAddress1,
		primaryOfficerAddress2,
		primaryOfficerCity,
		primaryOfficerState,
		primaryOfficerZipCode,
		primaryOfficerCountry,
	} = accountData;

	const address = {};

	address.accountId = accountId;
	address.ownerId = ownerId;
	address.correspondent = correspondent;
	address.accountNo = accountNo;
	address.address1 = primaryOfficerAddress1;
	address.address2 = primaryOfficerAddress2;
	address.city = primaryOfficerCity;
	address.state = primaryOfficerState;
	address.zipCode = primaryOfficerZipCode;
	address.country = primaryOfficerCountry;
	address.addressType = 'Registered Agent Address';
	address.status = 'Active';
	address.createdBy = 'Client';
	address.imgProofOfAddress = '';

	return address;
};

export const accountCompanyToAddress = (accountData) => {
	const address = {};

	address.address1 = accountData.address1;
	address.address2 = accountData.address2;
	address.city = accountData.city;
	address.state = accountData.state;
	address.zipCode = accountData.zipCode;
	address.country = accountData.country;
	address.addressType = 'Physical Address';
	address.status = 'Active';
	address.createdBy = 'Client';
	address.imgProofOfAddress = accountData.proofOfAddress;

	return address;
};

export const accountCoApplicantToAddress = (
	accountId,
	ownerId,
	accountData
) => {
	const { coAddress1, coAddress2, coCity, coState, coCountry, coZipCode } =
		accountData;

	const address = {};

	address.accountId = accountId;
	address.ownerId = ownerId;
	address.address1 = coAddress1;
	address.address2 = coAddress2;
	address.city = coCity;
	address.state = coState;
	address.zipCode = coZipCode;
	address.country = coCountry;
	address.addressType = 'Registered Agent Address';
	address.status = 'Active';
	address.createdBy = 'Client';
	address.imgProofOfAddress = '';

	return address;
};

export const accountCoOfficerToAddress = (
	accountId,
	ownerId,
	accountCoOfficerData
) => {
	const {
		coOfficerAddress1,
		coOfficerAddress2,
		coOfficerCity,
		coOfficerState,
		coOfficerZipCode,
		coOfficerCountry,
	} = accountCoOfficerData;

	const address = {};

	address.accountId = accountId;
	address.ownerId = ownerId;
	address.address1 = coOfficerAddress1;
	address.address2 = coOfficerAddress2;
	address.city = coOfficerCity;
	address.state = coOfficerState;
	address.zipCode = coOfficerZipCode;
	address.country = coOfficerCountry;
	address.addressType = 'Registered Agent Address';
	address.status = 'Active';
	address.createdBy = 'Client';
	address.imgProofOfAddress = '';

	return address;
};

//CONTACT INFO
export const accessToContactInfo = (
	accountId,
	ownerId,
	accountData,
	accessData
) => {
	const { correspondent, accountNo } = accountData;

	const contactInfo = {};

	contactInfo.accountId = accountId;
	contactInfo.ownerId = ownerId;
	contactInfo.correspondent = correspondent;
	contactInfo.accountNo = accountNo;
	contactInfo.status = 'Active';
	contactInfo.contactInfoType = 'Primary Email';
	contactInfo.contactInfo = accessData.email;
	contactInfo.createdBy = 'Client';

	return contactInfo;
};

export const accountToContactInfoEmail = (accountId, ownerId, accountData) => {
	const contactInfo = {};

	contactInfo.accountId = accountId;
	contactInfo.ownerId = ownerId;
	contactInfo.status = 'Active';
	contactInfo.contactInfoType = 'Email';
	contactInfo.contactInfo = accountData.email;

	return contactInfo;
};

export const accountToContactInfoPhoneNumber = (
	accountId,
	ownerId,
	accountData
) => {
	const { correspondent, accountNo, phoneNumber } = accountData;

	const contactInfo = {};

	contactInfo.accountId = accountId;
	contactInfo.ownerId = ownerId;
	contactInfo.correspondent = correspondent;
	contactInfo.accountNo = accountNo;
	contactInfo.status = 'Active';
	contactInfo.contactInfoType = 'Primary';
	contactInfo.contactInfo = phoneNumber;

	return contactInfo;
};

export const accountPrimaryOfficerToContactInfoPhoneNumber = (
	accountId,
	ownerId,
	accountData
) => {
	const { correspondent, accountNo, primaryOfficerPhoneNumber } = accountData;

	const contactInfo = {};

	contactInfo.accountId = accountId;
	contactInfo.ownerId = ownerId;
	contactInfo.correspondent = correspondent;
	contactInfo.accountNo = accountNo;
	contactInfo.status = 'Active';
	contactInfo.contactInfoType = 'Secondary';
	contactInfo.contactInfo = primaryOfficerPhoneNumber;

	return contactInfo;
};

export const accountCoApplicantToContactInfoEmail = (
	accountId,
	ownerId,
	accountData
) => {
	const contactInfo = {};

	contactInfo.accountId = accountId;
	contactInfo.ownerId = ownerId;
	contactInfo.status = 'Active';
	contactInfo.contactInfoType = 'Email';
	contactInfo.contactInfo = accountData.coEmail;

	return contactInfo;
};

export const accountCoApplicantToContactInfoPhoneNumber = (
	accountId,
	ownerId,
	accountData
) => {
	const contactInfo = {};

	contactInfo.accountId = accountId;
	contactInfo.ownerId = ownerId;
	contactInfo.status = 'Active';
	contactInfo.contactInfoType = 'Mobile';
	contactInfo.contactInfo = accountData.coPhoneNumber;

	return contactInfo;
};

export const accountCompanyToContactInfo = (accountData) => {
	const contactInfo = {};

	contactInfo.status = 'Active';
	contactInfo.contactInfoType = 'Primary';
	contactInfo.contactInfo = accountData.phoneNumber;

	return contactInfo;
};

export const accountCoOfficerToContactInfo = (
	accountId,
	ownerId,
	coOfficerPhoneNumber
) => {
	const contactInfo = {};

	contactInfo.accountId = accountId;
	contactInfo.ownerId = ownerId;
	contactInfo.status = 'Active';
	contactInfo.contactInfoType = 'Primary';
	contactInfo.contactInfo = coOfficerPhoneNumber.coOfficerPhoneNumber;

	return contactInfo;
};

export const accountCoOfficerToContactInfoEmail = (
	accountId,
	ownerId,
	coOfficerPhoneNumber
) => {
	const contactInfo = {};

	contactInfo.accountId = accountId;
	contactInfo.ownerId = ownerId;
	contactInfo.status = 'Active';
	contactInfo.contactInfoType = 'Primary Email';
	contactInfo.contactInfo = coOfficerPhoneNumber.coOfficerEmail;

	return contactInfo;
};

export const accountCoOfficerToidentification = (
	accountId,
	ownerId,
	accountCoOfficerData,
	identificationData
) => {
	const { country } = accountCoOfficerData;
	const {
		identificationType,
		identificationNo,
		idAddress,
		countryOfIssuance,
		issueDate,
		expirationDate,
		proofOfIdentity,
	} = identificationData;

	const identification = {};

	identification.accountId = accountId;
	identification.ownerId = ownerId;
	identification.type = identificationType;
	identification.id = identificationNo;
	identification.countryOfIssuance =
		idAddress === false ? countryOfIssuance : country;
	identification.issueDate = issueDate;
	identification.expirationDate = expirationDate;
	identification.imgGovernmentId = proofOfIdentity;
	identification.status = 'Active';

	return identification;
};

export const accountCoOfficerToidentificationSSN = (
	accountId,
	ownerId,
	accountCoOfficerData,
	identificationData
) => {
	const { country } = accountCoOfficerData;
	const { ssnNo, citizenshipAddress, citizenship } = identificationData;

	const identification = {};

	identification.accountId = accountId;
	identification.ownerId = ownerId;
	identification.type = 'SSN';
	identification.id = ssnNo;
	identification.countryOfIssuance = citizenshipAddress ? country : citizenship;
	identification.imgGovernmentId = '';
	identification.status = 'Active';

	return identification;
};

//IDENTIFICATION
export const ToidentificationTax = (
	accountId,
	ownerId,
	accountData,
	identificationData
) => {
	const { correspondent, accountNo, country, taxId } = accountData;
	const { taxAddress, taxCountry, state } = identificationData;

	const identification = {};

	identification.accountId = accountId;
	identification.ownerId = ownerId;
	identification.correspondent = correspondent;
	identification.accountNo = accountNo;
	identification.type = 'TIN';
	identification.id = taxId;
	identification.countryOfIssuance = taxAddress ? country : taxCountry;
	identification.imgGovernmentId = '';
	identification.status = 'Active';
	identification.state = state;

	return identification;
};

export const Toidentification = (
	accountId,
	ownerId,
	accountData,
	identificationData
) => {
	const { correspondent, accountNo, country, accountType } = accountData;
	const {
		identificationType,
		identificationNo,
		idAddress,
		countryOfIssuance,
		issueDate,
		expirationDate,
		proofOfIdentity,
		state,
	} = identificationData;

	const identification = {};

	identification.accountId = accountId;
	identification.ownerId = ownerId;
	identification.correspondent = accountType === 'C' ? '' : correspondent;
	identification.accountNo = accountType === 'C' ? '' : accountNo;
	identification.type = identificationType;
	identification.id = identificationNo;
	identification.countryOfIssuance =
		idAddress === false ? countryOfIssuance : country;
	identification.issueDate = issueDate;
	identification.expirationDate = expirationDate;
	identification.imgGovernmentId = proofOfIdentity;
	identification.status = 'Active';
	identification.state = state;

	return identification;
};

export const ToidentificationSSN = (
	accountId,
	ownerId,
	accountData,
	identificationData
) => {
	const { correspondent, accountNo, country, accountType } = accountData;
	const { ssnNo, citizenshipAddress, citizenship, state } = identificationData;

	const identification = {};

	identification.accountId = accountId;
	identification.ownerId = ownerId;
	identification.correspondent = accountType === 'C' ? '' : correspondent;
	identification.accountNo = accountType === 'C' ? '' : accountNo;
	identification.type = 'SSN';
	identification.id = ssnNo;
	identification.countryOfIssuance = citizenshipAddress ? country : citizenship;
	identification.imgGovernmentId = '';
	identification.status = 'Active';
	identification.state = state;

	return identification;
};

//OWNER TRUSTECT CONTACT
export const trustedContactToOwner = (
	accountId,
	accessData,
	accountData,
	identificationData,
	employmentData,
	associationData,
	investmentObjectiveData,
	trustedContactData
) => {
	const {
		correspondent,
		accountNo,
		accountType,
		country,
		primaryOfficerFirstName,
		primaryOfficerMiddleName,
		primaryOfficerLastName,
		primaryOfficer,
		primaryOwner,
		ownershipPercentage,
		maritalStatus,
	} = accountData;
	const {
		isExecutive,
		stockSymbol,
		isRegisteredBroker,
		companyName,
		affiliatedPerson,
		affiliatedPersonPosition,
	} = associationData;
	const { firstName, middleName, lastName, birthDate, relationship, other } =
		trustedContactData;
	const { citizenshipAddress, citizenship, kycStatus } = identificationData;
	const { employerName, previousEmployerName, occupation, employmentStatus, yearsEmployed } =
		employmentData;
	const { natureOfBusiness } = investmentObjectiveData;

	const owner = {};

	owner.accountId = accountId;
	owner.ownerId = 0;
	owner.correspondent = correspondent;
	owner.accountNo = accountNo;
	owner.firstName = firstName;
	owner.middleName = middleName;
	owner.lastName = lastName;
	owner.dateOfBirth = birthDate;
	owner.citizenship = citizenshipAddress ? country : citizenship;
	owner.country = citizenshipAddress ? country : citizenship;
	owner.employmentStatus = accountType === 'C' ? 'Excepted' : employmentStatus;
	owner.employmentName = accountType === 'C' ? '' : employerName;
	owner.previousEmploymentName = accountType === 'C' ? '' : previousEmployerName;
	owner.employmentOccupation = accountType === 'C' ? '' : occupation;
	owner.ownership = isExecutive === 'Yes' ? true : false;
	owner.stockOwnerShip = stockSymbol;
	owner.brokerMember = isRegisteredBroker === 'Yes' ? true : false;
	owner.brokerName = companyName;
	owner.affiliatePerson = affiliatedPerson;
	owner.affiliatePersonPosition = affiliatedPersonPosition;
	owner.ownerType = 'Trusted Contact';
	owner.ownershipPercent = ownershipPercentage;
	owner.relationship = relationship;
	owner.other = other;
	owner.owner = primaryOwner;
	owner.officer = primaryOfficer;
	owner.kycStatus = kycStatus;
	owner.maritalStatus = maritalStatus;
	owner.natureOfBusiness = natureOfBusiness;
	owner.yearsEmployed = yearsEmployed;
	return owner;
};

export const accountToTrustedContactInfoPhoneNumber = (
	accountId,
	ownerId,
	accountData,
	trustedContactData
) => {
	const { correspondent, accountNo } = accountData;
	const { phoneType, phone } = trustedContactData;

	const contactInfo = {};

	contactInfo.accountId = accountId;
	contactInfo.ownerId = ownerId;
	contactInfo.correspondent = correspondent;
	contactInfo.accountNo = accountNo;
	contactInfo.status = 'Active';
	contactInfo.contactInfoType = phoneType;
	contactInfo.contactInfo = phone;

	return contactInfo;
};

export const accountToTrustedContactInfoEmail = (
	accountId,
	ownerId,
	accountData,
	trustedContactData
) => {
	const { correspondent, accountNo } = accountData;
	const { emailType, email } = trustedContactData;

	const contactInfo = {};

	contactInfo.accountId = accountId;
	contactInfo.ownerId = ownerId;
	contactInfo.correspondent = correspondent;
	contactInfo.accountNo = accountNo;
	contactInfo.status = 'Active';
	contactInfo.contactInfoType = emailType;
	contactInfo.contactInfo = email;

	return contactInfo;
};

export const trustedContactIdentification = (
	accountId,
	ownerId,
	accountData,
	trustedContactData
) => {
	const { correspondent, accountNo, country, accountType } = accountData;
	const { id, type, state, ssnCountryOfIssuance, countryOfIssuance } = trustedContactData;

	const identification = {};

	identification.accountId = accountId;
	identification.ownerId = ownerId;
	identification.correspondent = accountType === 'C' ? '' : correspondent;
	identification.accountNo = accountType === 'C' ? '' : accountNo;
	identification.type = type;
	identification.id = id;
	identification.imgGovernmentId = '';
	identification.status = 'Active';
	identification.state = state;
	identification.countryOfIssuance = countryOfIssuance;
	identification.ssnCountryOfIssuance = ssnCountryOfIssuance;

	return identification;
};

export const accountToTrustedContactAddress = (
	accountId,
	ownerId,
	accountData,
	trustedContactData
) => {
	const { correspondent, accountNo } = accountData;
	const {
		trustedContactAddress1,
		address2,
		city,
		state,
		zipCode,
		country,
		addressType,
	} = trustedContactData;

	const address = {};

	address.accountId = accountId;
	address.ownerId = ownerId;
	address.correspondent = correspondent;
	address.accountNo = accountNo;
	address.address1 = trustedContactAddress1;
	address.address2 = address2;
	address.city = city;
	address.state = state;
	address.zipCode = zipCode;
	address.country = country;
	address.addressType = addressType;
	address.status = 'Active';
	address.createdBy = 'Client';

	return address;
};
