import React from 'react';
import Zoom from '@material-ui/core/Zoom';
import { makeStyles } from '@material-ui/core/styles';
import color from "../../assets/css/colors";
import fontFamily from "../../assets/css/fonts";
import {ReactComponent as Success} from '../../assets/icons/success.svg';
import {ReactComponent as Info} from '../../assets/icons/info.svg';
import {ReactComponent as Warning} from '../../assets/icons/warning.svg';
import {ReactComponent as Error} from '../../assets/icons/error.svg';

const FormMessage = (props) => {
    const borderColor = () => {
        if (props.type === 'success') {
            return '1px solid ' + color.success.light
        } else if (props.type === 'info') {
            return '1px solid ' + color.info.light
        } else if (props.type === 'warning') {
            return '1px solid ' + color.warning.light
        } else {
            return '1px solid ' + color.error.light
        }
    }

    const backgroundColor = () => {
        if (props.type === 'success') {
            return color.success.opacity
        } else if (props.type === 'info') {
            return color.info.opacity
        } else if (props.type === 'warning') {
            return color.warning.opacity
        } else {
            return color.error.opacity
        }
    }

    const fillColor = () => {
        if (props.type === 'success') {
            return color.success.main
        } else if (props.type === 'info') {
            return color.info.main
        } else if (props.type === 'warning') {
            return color.warning.main
        } else {
            return color.error.main
        }
    }

    const SvgIcon = () => {
        if (props.type === 'success') {
            return <Success className={classes.msgSvg}/> 
        } else if (props.type === 'info') {
            return <Info className={classes.msgSvg}/> 
        } else if (props.type === 'warning') {
            return <Warning className={classes.msgSvg}/> 
        } else {
            return <Error className={classes.msgSvg}/> 
        }
    }

    const useStyles = makeStyles(() => ({
        msgContainer: {
            marginBottom: 20,
            padding: 10,
            border: borderColor,
            borderRadius: '5px',
            backgroundColor: backgroundColor,
            lineHeight: '18px',
            fontFamily: fontFamily.label,
            fontSize: '14px',
            fontWeight: '600',
            color: fillColor,
            fill: fillColor + ' !important',
        },
        msgSvg: {
            width: '18px',
            marginRight: '5px',
            float: 'left',
            '& path': {
                fill: fillColor,
            }
        },
    }));
    
    const classes = useStyles();

    return (
        <Zoom in={true}>
            <div className={classes.msgContainer}>
                <SvgIcon /> <span>{props.message}</span>
            </div>
        </Zoom>
    );
}
export default FormMessage;