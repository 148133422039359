import {
  EntitlementServiceClient,
  ListEntitlementRequest,
  UpdateEntitlementRequest,
} from '../proto/reorgpb/entitlement_grpc_web_pb';
import { stringToProtoDate } from './ConvertService';
import authSvc from './AuthService';
import { auth } from '../lib/auth/Auth';

const service = new EntitlementServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });

const userId = authSvc.getCurrentUser()?.UserId;

export async function listEntitlement(param) {
  return new Promise((resolve, reject) => {
    let req = new ListEntitlementRequest();
    req.setFromSystemDate(stringToProtoDate(param.fromSystemDate));
    req.setToSystemDate(stringToProtoDate(param.toSystemDate));
    req.setDateType(param.dateType);
    req.setEntryType(param.entryType);
    req.setAccountNo(param.accountNo);
    req.setAccountName(param.accountName);
    req.setSymbol(param.symbol);
    req.setTextNumber(param.textNumber);
    req.setStatus(param.status);
    req.setCusip(param.cusip);
    req.setType(param.type);
    req.setUsrId(userId);

    service.listEntitlement(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateEntitlement(param) {
  return new Promise((resolve, reject) => {
    let req = new UpdateEntitlementRequest();
    req.setEntitlementId(param.entitlementId);
    req.setVoluntaryAction(param.voluntaryAction);
    service.updateEntitlement(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
