import React from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  makeStyles,
  Box,
} from '@material-ui/core';
import Button from '../../../../src/components/Buttons/ButtonForForgot'
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 440,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: '#FFFFFF',
    padding: '24px 24px 16px 24px',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    display: 'flex',
  },
  modalBody: {
    backgroundColor: '#FFFFFF',
    maxHeight: 104,
    overflowY: 'auto',
  },
  modalFooter: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    padding: '24px 24px 16px 24px',
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    position: 'relative',
    maxHeight: 80,
  }
}));

export default function AcknowledgeModal({ onClose, iopen, paramData }) {
  const classes = useStyles()
  const [mOpen, setMOpen] = React.useState(iopen)

  const history = useHistory();
  const handleRequest = () => {
    history.push("/login")
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={mOpen}
      onClose={() => onClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={mOpen}>
        <div style={{ width: 2000 }}>
            <div className={classes.modalHeader}>
              <Box
                sx={
                  {
                    margin: '0 auto',
                    fontSize: '24px',
                  }
                }
              >
                Thank you!
              </Box>
            </div>
            <div className={classes.modalBody}>
              <Box
                sx={
                  {
                    margin: '0 auto',
                    fontSize: '16px',
                    padding: '12px 20px',
                    textAlign: 'center',
                  }
                }
              >
                If the information entered matches our records. a message with your {paramData} will be sent to the
                email you provided
              </Box>
            </div>
            <div className={classes.modalFooter}>
              <div className="acknowledge-button">
                <Button
                  label={'Sign In'}
                  onClick={handleRequest}
                />
              </div>
            </div>
        </div>
      </Fade>
    </Modal>
  );
}
