/**
 * @fileoverview gRPC-Web generated client stub for usrpb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.14.0
// source: proto/usrpb/administrator.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var proto_accountpb_generateaccountno_pb = require('../../proto/accountpb/generateaccountno_pb.js')

var proto_accountpb_client_pb = require('../../proto/accountpb/client_pb.js')

var proto_accountpb_owner_pb = require('../../proto/accountpb/owner_pb.js')

var proto_accountpb_identification_pb = require('../../proto/accountpb/identification_pb.js')

var proto_accountpb_contactinfo_pb = require('../../proto/accountpb/contactinfo_pb.js')

var proto_accountpb_address_pb = require('../../proto/accountpb/address_pb.js')

var proto_clientpb_accountaccess_pb = require('../../proto/clientpb/accountaccess_pb.js')

var proto_usrpb_registration_pb = require('../../proto/usrpb/registration_pb.js')

var proto_utilspb_pagination_pb = require('../../proto/utilspb/pagination_pb.js')

const proto = {};
proto.usrpb = require('./administrator_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.usrpb.AdministratorServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.usrpb.AdministratorServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.CreateClientOnboardingRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_AdministratorService_CreateClientOnboarding = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/CreateClientOnboarding',
  grpc.web.MethodType.UNARY,
  proto.usrpb.CreateClientOnboardingRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.usrpb.CreateClientOnboardingRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.usrpb.CreateClientOnboardingRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.createClientOnboarding =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/CreateClientOnboarding',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_CreateClientOnboarding,
      callback);
};


/**
 * @param {!proto.usrpb.CreateClientOnboardingRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.createClientOnboarding =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/CreateClientOnboarding',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_CreateClientOnboarding);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.Administrator,
 *   !proto.usrpb.CreateAdministratorResponse>}
 */
const methodDescriptor_AdministratorService_CreateAdministrator = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/CreateAdministrator',
  grpc.web.MethodType.UNARY,
  proto.usrpb.Administrator,
  proto.usrpb.CreateAdministratorResponse,
  /**
   * @param {!proto.usrpb.Administrator} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.CreateAdministratorResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.Administrator} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.usrpb.CreateAdministratorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.CreateAdministratorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.createAdministrator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/CreateAdministrator',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_CreateAdministrator,
      callback);
};


/**
 * @param {!proto.usrpb.Administrator} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.CreateAdministratorResponse>}
 *     Promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.createAdministrator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/CreateAdministrator',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_CreateAdministrator);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.Administrator,
 *   !proto.usrpb.UpdateAdministratorResponse>}
 */
const methodDescriptor_AdministratorService_UpdateAdministrator = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/UpdateAdministrator',
  grpc.web.MethodType.UNARY,
  proto.usrpb.Administrator,
  proto.usrpb.UpdateAdministratorResponse,
  /**
   * @param {!proto.usrpb.Administrator} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.UpdateAdministratorResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.Administrator} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.usrpb.UpdateAdministratorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.UpdateAdministratorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.updateAdministrator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/UpdateAdministrator',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_UpdateAdministrator,
      callback);
};


/**
 * @param {!proto.usrpb.Administrator} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.UpdateAdministratorResponse>}
 *     Promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.updateAdministrator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/UpdateAdministrator',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_UpdateAdministrator);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.ReadAdministratorRequest,
 *   !proto.usrpb.ReadAdministratorResponse>}
 */
const methodDescriptor_AdministratorService_ReadAdministrator = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/ReadAdministrator',
  grpc.web.MethodType.UNARY,
  proto.usrpb.ReadAdministratorRequest,
  proto.usrpb.ReadAdministratorResponse,
  /**
   * @param {!proto.usrpb.ReadAdministratorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.ReadAdministratorResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.ReadAdministratorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.usrpb.ReadAdministratorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.ReadAdministratorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.readAdministrator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/ReadAdministrator',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_ReadAdministrator,
      callback);
};


/**
 * @param {!proto.usrpb.ReadAdministratorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.ReadAdministratorResponse>}
 *     Promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.readAdministrator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/ReadAdministrator',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_ReadAdministrator);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.ChangePasswordRequest,
 *   !proto.usrpb.ChangePasswordResponse>}
 */
const methodDescriptor_AdministratorService_ChangePassword = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/ChangePassword',
  grpc.web.MethodType.UNARY,
  proto.usrpb.ChangePasswordRequest,
  proto.usrpb.ChangePasswordResponse,
  /**
   * @param {!proto.usrpb.ChangePasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.ChangePasswordResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.ChangePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.usrpb.ChangePasswordResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.ChangePasswordResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.changePassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_ChangePassword,
      callback);
};


/**
 * @param {!proto.usrpb.ChangePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.ChangePasswordResponse>}
 *     Promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.changePassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_ChangePassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.ConfirmationEmailRequest,
 *   !proto.usrpb.ConfirmationEmailResponse>}
 */
const methodDescriptor_AdministratorService_SendConfirmationEmail = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/SendConfirmationEmail',
  grpc.web.MethodType.UNARY,
  proto.usrpb.ConfirmationEmailRequest,
  proto.usrpb.ConfirmationEmailResponse,
  /**
   * @param {!proto.usrpb.ConfirmationEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.ConfirmationEmailResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.ConfirmationEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.usrpb.ConfirmationEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.ConfirmationEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.sendConfirmationEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/SendConfirmationEmail',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_SendConfirmationEmail,
      callback);
};


/**
 * @param {!proto.usrpb.ConfirmationEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.ConfirmationEmailResponse>}
 *     Promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.sendConfirmationEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/SendConfirmationEmail',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_SendConfirmationEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.CheckEmailRequest,
 *   !proto.usrpb.CheckEmailResponse>}
 */
const methodDescriptor_AdministratorService_CheckEmailIfExists = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/CheckEmailIfExists',
  grpc.web.MethodType.UNARY,
  proto.usrpb.CheckEmailRequest,
  proto.usrpb.CheckEmailResponse,
  /**
   * @param {!proto.usrpb.CheckEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.CheckEmailResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.CheckEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.usrpb.CheckEmailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.CheckEmailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.checkEmailIfExists =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/CheckEmailIfExists',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_CheckEmailIfExists,
      callback);
};


/**
 * @param {!proto.usrpb.CheckEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.CheckEmailResponse>}
 *     Promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.checkEmailIfExists =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/CheckEmailIfExists',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_CheckEmailIfExists);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.ListUserAccountAccessRequest,
 *   !proto.usrpb.ListUserAccountAccessResponse>}
 */
const methodDescriptor_AdministratorService_ListUserAccountAccess = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/ListUserAccountAccess',
  grpc.web.MethodType.UNARY,
  proto.usrpb.ListUserAccountAccessRequest,
  proto.usrpb.ListUserAccountAccessResponse,
  /**
   * @param {!proto.usrpb.ListUserAccountAccessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.ListUserAccountAccessResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.ListUserAccountAccessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.usrpb.ListUserAccountAccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.ListUserAccountAccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.listUserAccountAccess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/ListUserAccountAccess',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_ListUserAccountAccess,
      callback);
};


/**
 * @param {!proto.usrpb.ListUserAccountAccessRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.ListUserAccountAccessResponse>}
 *     Promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.listUserAccountAccess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/ListUserAccountAccess',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_ListUserAccountAccess);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.SetKeyRequest,
 *   !proto.usrpb.Administrator>}
 */
const methodDescriptor_AdministratorService_SetKey = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/SetKey',
  grpc.web.MethodType.UNARY,
  proto.usrpb.SetKeyRequest,
  proto.usrpb.Administrator,
  /**
   * @param {!proto.usrpb.SetKeyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.Administrator.deserializeBinary
);


/**
 * @param {!proto.usrpb.SetKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.usrpb.Administrator)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.Administrator>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.setKey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/SetKey',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_SetKey,
      callback);
};


/**
 * @param {!proto.usrpb.SetKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.Administrator>}
 *     Promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.setKey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/SetKey',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_SetKey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.AcceptClientNoticeRequest,
 *   !proto.usrpb.EmptyResponse>}
 */
const methodDescriptor_AdministratorService_AcceptClientNotice = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/AcceptClientNotice',
  grpc.web.MethodType.UNARY,
  proto.usrpb.AcceptClientNoticeRequest,
  proto.usrpb.EmptyResponse,
  /**
   * @param {!proto.usrpb.AcceptClientNoticeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.EmptyResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.AcceptClientNoticeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.usrpb.EmptyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.EmptyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.acceptClientNotice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/AcceptClientNotice',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_AcceptClientNotice,
      callback);
};


/**
 * @param {!proto.usrpb.AcceptClientNoticeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.EmptyResponse>}
 *     Promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.acceptClientNotice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/AcceptClientNotice',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_AcceptClientNotice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.HasSecurityWordRequest,
 *   !proto.usrpb.HasSecurityWordResponse>}
 */
const methodDescriptor_AdministratorService_HasSecurityWord = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/HasSecurityWord',
  grpc.web.MethodType.UNARY,
  proto.usrpb.HasSecurityWordRequest,
  proto.usrpb.HasSecurityWordResponse,
  /**
   * @param {!proto.usrpb.HasSecurityWordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.HasSecurityWordResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.HasSecurityWordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.usrpb.HasSecurityWordResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.HasSecurityWordResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.hasSecurityWord =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/HasSecurityWord',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_HasSecurityWord,
      callback);
};


/**
 * @param {!proto.usrpb.HasSecurityWordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.HasSecurityWordResponse>}
 *     Promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.hasSecurityWord =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/HasSecurityWord',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_HasSecurityWord);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.ListAdministratorRequest,
 *   !proto.usrpb.ListAdministratorResponse>}
 */
const methodDescriptor_AdministratorService_ListAdministrator = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/ListAdministrator',
  grpc.web.MethodType.UNARY,
  proto.usrpb.ListAdministratorRequest,
  proto.usrpb.ListAdministratorResponse,
  /**
   * @param {!proto.usrpb.ListAdministratorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.ListAdministratorResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.ListAdministratorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.usrpb.ListAdministratorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.ListAdministratorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.listAdministrator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/ListAdministrator',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_ListAdministrator,
      callback);
};


/**
 * @param {!proto.usrpb.ListAdministratorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.ListAdministratorResponse>}
 *     Promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.listAdministrator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/ListAdministrator',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_ListAdministrator);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.DeleteAdministratorRequest,
 *   !proto.usrpb.DeleteAdministratorResponse>}
 */
const methodDescriptor_AdministratorService_DeleteAdministrator = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/DeleteAdministrator',
  grpc.web.MethodType.UNARY,
  proto.usrpb.DeleteAdministratorRequest,
  proto.usrpb.DeleteAdministratorResponse,
  /**
   * @param {!proto.usrpb.DeleteAdministratorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.DeleteAdministratorResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.DeleteAdministratorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.usrpb.DeleteAdministratorResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.DeleteAdministratorResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.deleteAdministrator =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/DeleteAdministrator',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_DeleteAdministrator,
      callback);
};


/**
 * @param {!proto.usrpb.DeleteAdministratorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.DeleteAdministratorResponse>}
 *     Promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.deleteAdministrator =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/DeleteAdministrator',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_DeleteAdministrator);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.EmptyRequest,
 *   !proto.usrpb.ListDefaultAccessOptionResponse>}
 */
const methodDescriptor_AdministratorService_ListDefaultAccessOption = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/ListDefaultAccessOption',
  grpc.web.MethodType.UNARY,
  proto.usrpb.EmptyRequest,
  proto.usrpb.ListDefaultAccessOptionResponse,
  /**
   * @param {!proto.usrpb.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.ListDefaultAccessOptionResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.usrpb.ListDefaultAccessOptionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.ListDefaultAccessOptionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.listDefaultAccessOption =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/ListDefaultAccessOption',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_ListDefaultAccessOption,
      callback);
};


/**
 * @param {!proto.usrpb.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.ListDefaultAccessOptionResponse>}
 *     Promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.listDefaultAccessOption =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/ListDefaultAccessOption',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_ListDefaultAccessOption);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.Access,
 *   !proto.usrpb.CreateAccessResponse>}
 */
const methodDescriptor_AdministratorService_CreateAccess = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/CreateAccess',
  grpc.web.MethodType.UNARY,
  proto.usrpb.Access,
  proto.usrpb.CreateAccessResponse,
  /**
   * @param {!proto.usrpb.Access} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.CreateAccessResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.Access} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.usrpb.CreateAccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.CreateAccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.createAccess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/CreateAccess',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_CreateAccess,
      callback);
};


/**
 * @param {!proto.usrpb.Access} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.CreateAccessResponse>}
 *     Promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.createAccess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/CreateAccess',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_CreateAccess);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.Access,
 *   !proto.usrpb.UpdateAccessResponse>}
 */
const methodDescriptor_AdministratorService_UpdateAccess = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/UpdateAccess',
  grpc.web.MethodType.UNARY,
  proto.usrpb.Access,
  proto.usrpb.UpdateAccessResponse,
  /**
   * @param {!proto.usrpb.Access} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.UpdateAccessResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.Access} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.usrpb.UpdateAccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.UpdateAccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.updateAccess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/UpdateAccess',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_UpdateAccess,
      callback);
};


/**
 * @param {!proto.usrpb.Access} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.UpdateAccessResponse>}
 *     Promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.updateAccess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/UpdateAccess',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_UpdateAccess);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.DeleteAccessRequest,
 *   !proto.usrpb.EmptyRequest>}
 */
const methodDescriptor_AdministratorService_DeleteAccess = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/DeleteAccess',
  grpc.web.MethodType.UNARY,
  proto.usrpb.DeleteAccessRequest,
  proto.usrpb.EmptyRequest,
  /**
   * @param {!proto.usrpb.DeleteAccessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.EmptyRequest.deserializeBinary
);


/**
 * @param {!proto.usrpb.DeleteAccessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.usrpb.EmptyRequest)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.EmptyRequest>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.deleteAccess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/DeleteAccess',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_DeleteAccess,
      callback);
};


/**
 * @param {!proto.usrpb.DeleteAccessRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.EmptyRequest>}
 *     Promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.deleteAccess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/DeleteAccess',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_DeleteAccess);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.HasSecurityQuestionsRequest,
 *   !proto.usrpb.HasSecurityQuestionsResponse>}
 */
const methodDescriptor_AdministratorService_HasSecurityQuestions = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/HasSecurityQuestions',
  grpc.web.MethodType.UNARY,
  proto.usrpb.HasSecurityQuestionsRequest,
  proto.usrpb.HasSecurityQuestionsResponse,
  /**
   * @param {!proto.usrpb.HasSecurityQuestionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.HasSecurityQuestionsResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.HasSecurityQuestionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.usrpb.HasSecurityQuestionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.HasSecurityQuestionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.hasSecurityQuestions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/HasSecurityQuestions',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_HasSecurityQuestions,
      callback);
};


/**
 * @param {!proto.usrpb.HasSecurityQuestionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.HasSecurityQuestionsResponse>}
 *     Promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.hasSecurityQuestions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/HasSecurityQuestions',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_HasSecurityQuestions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.SecurityQuestionRequest,
 *   !proto.usrpb.SecurityQuestionResponse>}
 */
const methodDescriptor_AdministratorService_GetSecurityQuestion = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/GetSecurityQuestion',
  grpc.web.MethodType.UNARY,
  proto.usrpb.SecurityQuestionRequest,
  proto.usrpb.SecurityQuestionResponse,
  /**
   * @param {!proto.usrpb.SecurityQuestionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.SecurityQuestionResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.SecurityQuestionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.usrpb.SecurityQuestionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.SecurityQuestionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.getSecurityQuestion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/GetSecurityQuestion',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_GetSecurityQuestion,
      callback);
};


/**
 * @param {!proto.usrpb.SecurityQuestionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.SecurityQuestionResponse>}
 *     Promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.getSecurityQuestion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/GetSecurityQuestion',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_GetSecurityQuestion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.usrpb.BrandingURLRequest,
 *   !proto.usrpb.BrandingURLResponse>}
 */
const methodDescriptor_AdministratorService_GetBrandingURL = new grpc.web.MethodDescriptor(
  '/usrpb.AdministratorService/GetBrandingURL',
  grpc.web.MethodType.UNARY,
  proto.usrpb.BrandingURLRequest,
  proto.usrpb.BrandingURLResponse,
  /**
   * @param {!proto.usrpb.BrandingURLRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.usrpb.BrandingURLResponse.deserializeBinary
);


/**
 * @param {!proto.usrpb.BrandingURLRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.usrpb.BrandingURLResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.usrpb.BrandingURLResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.usrpb.AdministratorServiceClient.prototype.getBrandingURL =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/usrpb.AdministratorService/GetBrandingURL',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_GetBrandingURL,
      callback);
};


/**
 * @param {!proto.usrpb.BrandingURLRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.usrpb.BrandingURLResponse>}
 *     Promise that resolves to the response
 */
proto.usrpb.AdministratorServicePromiseClient.prototype.getBrandingURL =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/usrpb.AdministratorService/GetBrandingURL',
      request,
      metadata || {},
      methodDescriptor_AdministratorService_GetBrandingURL);
};


module.exports = proto.usrpb;

