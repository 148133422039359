import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Text from '../Typography/Text';
import color from '../../assets/css/colors';
import fontFamily from '../../assets/css/fonts';
import { listFAQs } from '../../services/CommonService';

const Accordion = withStyles({
  root: {
    border: 'unset',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'unset',
    borderBottom: '1px solid ' + color.gray.opacity,
    marginBottom: -1,
    minHeight: 47,
    padding: 0,
    '& p': {
        fontSize: 14,
    },
    '&$expanded': {
      minHeight: 50,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: 0,
    backgroundColor: color.light.main,
    '& p': {
      fontSize:14,
      fontFamily: fontFamily.label,
      margin: 0,
      padding: 20,
      lineHeight: '22px',
      maxHeight: 225,
      overflowY: 'auto',
      overflowX: 'hidden',
      border: '1px solid ' + color.gray.opacity,
    }
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles(() => ({
    accordionWrapper: {
        width: '100%',
        maxWidth: 650,
        // margin: '-16px 0 0 0',
    },
    faqHeader: {
      marginBottom: 20,
    }
}));

export default function FAQ (props) {
    const classes = useStyles();

    const { pageName } = props;

    const [expanded, setExpanded] = React.useState('');
    const [FAQs, setFAQs] = React.useState([]);

    const getFAQs = React.useCallback(async() => {
      let params = { pageName: pageName !== '' ? pageName : 'Not Applicable' }

      const { faqsList } = await listFAQs(params);

      setFAQs(faqsList);
    }, [pageName])
  
    React.useEffect(() => { getFAQs(); }, [getFAQs]);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div className={classes.accordionWrapper}>
            {FAQs.length > 0 && (
              <div className={classes.faqHeader}>
                <div>
                  <Text
                      variant="h2"
                      label="Frequently Asked Questions"
                  />
                </div>
              </div>
            )}
            {FAQs?.map((v, index) => {
                return (
                    <Accordion key={index} square expanded={expanded === v.faqId} onChange={handleChange(v.faqId)}>
                        <AccordionSummary>
                            <Text
                                variant="body2"
                                label={(index + 1) + '. ' + v.question}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <p dangerouslySetInnerHTML={{ __html: v.answer }}></p>
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </div>
    );
}