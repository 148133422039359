import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Modal,
  Backdrop,
  Fade,
  AppBar,
  Tab,
  Tabs,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import InputMask from 'react-input-mask';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import Text from '../Typography/Text';
import TextField from '../Textfields/TextField';
import Button from '../Buttons/Button';
import CloseButton from '../Buttons/CloseButton';
import FormMessage from '../Messages/FormMessage';
import { notifyError } from '../../components/Messages/Notification';

import fontFamily from '../../assets/css/fonts';
import color from '../../assets/css/colors';

import authSvc from '../../services/AuthService';
import { readProfilePromise } from '../../services/ProfileService';
import { listSystemNumber } from '../../services/SystemNumberService';
import { readClient } from '../../services/ClientAccountService';
import { generateAccountNo } from '../../services/CommonService';
import ImageDropZone from '../../components/Misc/ImageDropZone';
import {
  dateProtoObjectToString,
  protoTimeSpanObjectToString,
} from '../../services/ConvertService';

import AutoCompleteCorrespondent from '../AutoComplete/AutoCompleteCorrespondent';
import AutoCompleteBranch from '../AutoComplete/AutoCompleteBranch';
import AutoCompleteRep from '../AutoComplete/AutoCompleteRep';
import AutoCompleteState from '../AutoComplete/AutoCompleteState';
import SelectSystemCode from '../Dropdown/SelectSystemCode';
import SelectCommonId from '../Dropdown/SelectCommonId';
import SelectSystemCodeWithDescription, {
  SelectCurrency,
} from '../Dropdown/SelectSystemCodeWithDescription';

import OwnerTable from '../../pages/AccountSetup/Components/OwnerTable';
import SubAccountTable from '../../pages/AccountSetup/Components/SubAccountTable';
import AccessTable from '../../pages/AccountSetup/Components/AccessTable';
import NoteTable from '../../pages/AccountSetup/Components/NoteTable';
import AttachmentTable from '../../pages/AccountSetup/Components/AttachmentTable';

import { listUsrAccess } from '../../services/UsrAccessService';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 550,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  fadeModalFull: {
    maxWidth: 1400,
    minWidth: 1400,
    maxHeight: '90vh',
    width: '100%',
    padding: '25px 30px 30px 30px',
    margin: '0px auto',
    backgroundColor: '#ffffff',
    border: 'none',
    borderRadius: 5,
    boxSizing: 'border-box',
    outline: 'none',
    overflowY: 'auto',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
  accordionDetails: {
    display: 'block !important',
    padding: '0px 20px 25px 20px !important',
  },
  accordion: {
    borderRadius: '5px !important',
    boxShadow: '0px 1px 5px -2px #cdcfdd !important',
    border: '1px solid #efeff0 !important',
  },
  tabBody: {
    minWidth: '100%',
    padding: '0px 6px',
  },
}));

function TabPanel({ children, value: tabValue, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={tabValue !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {tabValue === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function ClientAccountModal({
  onClose: handleCloseModal,
  openModal: openModal,
  value,
  validation,
  importMode: isImport,
  loading,
}) {
  const [tabIndexValue, setTabIndexValue] = React.useState(0);
  const handleChangeTab = (_, newValue) => {
    setTabIndexValue(newValue);
  };
  const taxIdMaskRef = React.createRef();
  const classes = useStyles();
  const [modalData, setModalData] = useState({
    correspondent: value?.correspondent ? value?.correspondent : '' || '',
    accountNo: '',
    accountName: '',
    masterAccountNo: '',
    status: '',
    activatedAt: '',
    disabledAt: '',
    broker: '',
    privacyConsent: '1',
    dayTrader: false,
    multiplier: 1,
    legalEntity: '',
    accountType: '',
    marginType: '',
    branch: '',
    rep: '',
    beneficiary: '',
    currency: '',
    largeTraderId: '',
    investmentObjectiveCode: '',
    investmentObjectiveDate: '',
    taxCountry: 'US',
    tefra: 'Exempt',
    w8w9: 'W9',
    w8w9ReceivedDate: '',
    accreditedInvestor: false,
    fdidFlag: false,
    fdid: '',
    fdidEndReason: '',
    fpslParticipant: false,
    cashSweepParticipant: false,
    mutualFunds: false,
    fixedIncome: false,
    commonId: '',
    statusReason: '',
    subType: '',
    allowLiquidInvestment: false,
    primeBroker: '',
    participantNo: '',
    executingBroker: '',
    institutionNo: '',
    agentNo: '',
    agentAccountNo: '',
    traderId: '',
    marginDisclosure: false,
    marginAgreement: false,
    stocks: true,
    etf: false,
    option: false,
    annualIncome: '',
    liquidNetWorth: '',
    sourceOfFunds: '',
    signed: false,
    imgSignature: '',
    errorMessage: '',
    validationStatus: '',
    batchNo: '',
    tin: '',
    kycStatus: '',
    formationCountry: '',
    formationState: '',
    formationDate: '',
    serviceBureau: '',
    promoCode: '',
    securityWord: '',
    securityWordHint: '',
    taxBracket: '',
    riskProfile: '',
    plType: '',
    onboardingPercent: '',
  });

  const [isEdit, setIsEdit] = React.useState(false);
  const [isAccountSet, setIsAccountSet] = React.useState(false);
  const [subAccountList, setSubAccountList] = React.useState([]);
  const [ownerList, setOwnerList] = React.useState([]);
  const [noteList, setNoteList] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState('account');
  const [marginTypes, setMarginTypes] = useState({});
  const [initialFile, setInitialFile] = useState({});
  const [addressList, setAddressList] = React.useState([]);
  const [requiredField, setRequired] = React.useState({
    activatedAt: true,
  });

  const u = authSvc.getCurrentUser();
  console.log("User: ", u)

  const getDetails = async (accountId, batchNo) => {
    try {
      const data = await readClient(accountId);
      const profileData = await readProfilePromise();

      let modalValue = { ...data.client };

      modalValue.batchNo = batchNo;
      modalValue.currency = profileData.profile.baseCurrency;

      if (data.client.currency === '') {
        modalValue.currency = profileData.profile.baseCurrency;
      } else {
        modalValue.currency = data.client.currency;
      }

      modalValue.legalEntity = data.client.legalEntity;
      modalValue.investmentObjectiveCode = data.client.investmentObjectiveCode;

      modalValue.activatedAt = protoTimeSpanObjectToString(data.client.activatedAt);
      modalValue.disabledAt = protoTimeSpanObjectToString(data.client.disabledAt);
      modalValue.investmentObjectiveDate = dateProtoObjectToString(
        data.client.investmentObjectiveDate
      );
      modalValue.w8w9ReceivedDate = dateProtoObjectToString(data.client.w8w9ReceivedDate);
      modalValue.formationDate = dateProtoObjectToString(data.client.formationDate);
      modalValue.correspondent =
        modalValue.correspondent !== '    ' ? modalValue.correspondent : '';

      modalValue.participantNo =
        modalValue.participantNo !== '    ' ? modalValue.participantNo : '';

      if (modalValue.imgSignature || modalValue.imgSignature !== '') {
        await fetch(modalValue.imgSignature)
          .then(function (res) {
            return res.arrayBuffer();
          })
          .then(function (buf) {
            const file = new File([buf], 'image_data.jpg', {
              type: 'image/*',
            });
            setInitialFile(file);
          });
      } else {
        setInitialFile({});
      }
      updateRequired(modalValue.status);
      setModalData(modalValue);
      setSubAccountList(data.subAccountsList);
      setOwnerList(data.ownersList);
      setNoteList(data.notesList);
      setAddressList(data.addressesList);
    } catch (error) {
      notifyError(error.message);
    }
  };

  const handleUploadedPhotoStatus = ({ meta, file }, status) => {
    if (status === 'done') {
      const reader = new FileReader();

      reader.addEventListener(
        'load',
        function () {
          setModalData({ ...modalData, imgSignature: reader.result });
        },
        false
      );

      reader.readAsDataURL(file);
    }
  };
  const handleUploadPhoto = (files) => {
    console.log(files.map((f) => f.meta));
  };

  useEffect(() => {
    if (
      modalData.taxCountry !== 'US' &&
      modalData.taxCountry !== '' &&
      modalData.taxCountry !== undefined
    ) {
      setModalData({ ...modalData, w8w9: 'W8' });
    } else {
      setModalData({ ...modalData, w8w9: 'W9' });
    }
  }, [modalData.taxCountry]);

  useEffect(() => {
    if (modalData.marginType === 'Margin' && modalData.dayTrader) {
      setModalData({ ...modalData, multiplier: 4 });
    } else if (modalData.marginType === 'Margin' && !modalData.dayTrader) {
      setModalData({ ...modalData, multiplier: 2 });
    }
  }, [modalData.marginType, modalData.dayTrader]);

  useEffect(
    () => {
      async function init() {
        try {
          const param = {
            type: 'Multiplier',
          };

          const data = await listSystemNumber(param);
          setMarginTypes(data.systemNumbersList);

          // Get user access details
          const accessParams = {
            roleId: u.RoleId,
            requestType: 'Show Selected',
          };
      
          const { usrAccessesList } = await listUsrAccess(accessParams);

          console.log("List: ", usrAccessesList)

          const findAccess = usrAccessesList.some(
            (usrAccess) => usrAccess.access === "Account Set" && usrAccess.subAccess === "Admin"
          );

          setIsAccountSet(findAccess);

          console.log("account set: ", isAccountSet)
        } catch (error) {
          console.error(error);
          notifyError(error.message);
        }
      }
      if (openModal) {
        if (value?.action !== 'add') {
          setIsEdit(true);
        }
        console.log("ashgdhjashd")
        getDetails(value?.accountId, value?.batchNo);

        init();
      }
    },
    // eslint-disable-next-line
    [openModal]
  );

  useEffect(() => {
    const fetchAccountNo = async () => {
      const res = await generateAccountNo({
        branch: modalData.branch,
        correspondent: modalData.correspondent,
        masterAccountNo: modalData.masterAccountNo,
        rep: modalData.rep,
      });
      if (res) {
        setModalData((prevData) => ({
          ...prevData,
          accountNo: res.account.accountNo,
        }));
      }
    };
    if (
      modalData.correspondent !== '' ||
      modalData.rep !== '' ||
      modalData.branch !== '' ||
      modalData.masterAccountNo !== ''
    ) {
      if (!modalData.accountNo && !isEdit) {
        fetchAccountNo();
      }
    }
  }, [
    modalData.correspondent,
    modalData.rep,
    modalData.branch,
    modalData.masterAccountNo,
  ]);

  const handleChange = async (e, checkboxValue) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    let modalDataCopy = { ...modalData };
    if (input.name === 'marginType') {
      let multiplier = marginTypes.filter(
        (multiplier) => multiplier.subType === input.value
      );
      modalDataCopy.multiplier = multiplier[0]?.rate;
    }
    if (input.name === 'status') {
      updateRequired(input.value);
    }

    if (input.name === 'correspondent') {
      modalDataCopy.rep = '';
    }

    setModalData({
      ...modalDataCopy,
      [input.name]:
        checkboxValue === true || checkboxValue === false
          ? checkboxValue
          : [
              'branch',
              'accountNo',
              'masterAccountNo',
              'primeBroker',
              'executingBroker',
              'institutionNo',
              'agentNo',
              'agentAccountNo',
              'traderId',
            ].includes(input.name)
          ? input.value.toUpperCase()
          : input.value,
    });
  };

  function updateRequired(status) {
    let act = { requiredField };
    if (status === 'Active') {
      act.activatedAt = true;
    } else {
      act.activatedAt = false;
    }
    setRequired(act);
  }

  const handleExpandAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const isEntity = () => {
    return (
      ['Limited Liability Company', 'Partnership', 'Corporation', 'Trust'].includes(
        modalData.legalEntity
      ) || /Corporation/.test(modalData.legalEntity)
    );
  };

  document.onkeyup = function (e) {
    if (e.ctrlKey && e.keyCode === 13) {
      handleCloseModal(modalData, isEdit);
      return;
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModal}
      onClose={() => handleCloseModal()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={openModal}>
        <div className={classes.fadeModalFull}>
          <div className={classes.modalHeader}>
            <Text
              id="transition-modal-title"
              variant="h2"
              label={'Edit Client Account'}
            />
          </div>
          <div className={classes.modalBody}>
            <div className="grd-row">
              <AppBar
                position="static"
                className={classes.tabHeader}
                style={{
                  backgroundColor: '#ffffff',
                  boxShadow: 'none',
                  borderBottom: '1px solid #e8e8e8',
                }}
              >
                <Tabs
                  value={tabIndexValue}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  scrollButtons="auto"
                  variant="fullWidth"
                  centered
                >
                  <Tab label="General" {...a11yProps(0)} />
                  <Tab label="Owner" {...a11yProps(1)} />
                  <Tab label="Sub Account" {...a11yProps(2)} />
                  <Tab label="Note" {...a11yProps(3)} />
                  <Tab label="Attachment" {...a11yProps(4)} />
                </Tabs>
              </AppBar>
            </div>
            <div className="grd-row">
              <TabPanel className={classes.tabBody} value={tabIndexValue} index={0}>
                <Accordion
                  className={classes.accordion}
                  expanded={expanded === 'account'}
                  onChange={handleExpandAccordion('account')}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>Account</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <div className="grd-row">
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <AutoCompleteCorrespondent
                          // required={true}
                          //freeSolo={true}
                          disabled={isEdit}
                          name="correspondent"
                          value={modalData.correspondent}
                          onChange={handleChange}
                        ></AutoCompleteCorrespondent>
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <TextField
                          fullWidth
                          // required={true}
                          disabled={isEdit}
                          name="accountNo"
                          label="Account No"
                          value={modalData.accountNo}
                          onChange={handleChange}
                          inputProps={{ maxLength: 50 }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <TextField
                          fullWidth
                          disabled={isEdit && !isAccountSet}
                          // required={true}
                          label="Account Name"
                          name="accountName"
                          value={modalData.accountName}
                          onChange={handleChange}
                          inputProps={{ maxLength: 300 }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                      <div className="grd-cell">
                        <TextField
                          fullWidth
                          disabled={isEdit && !isAccountSet}
                          name="masterAccountNo"
                          label="Master Account No"
                          value={modalData.masterAccountNo}
                          onChange={handleChange}
                          inputProps={{ maxLength: 50 }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                    </div>
                    <div className="grd-row">
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <AutoCompleteBranch
                          fullWidth
                          disabled={isEdit && !isAccountSet}
                          name="branch"
                          label="Branch"
                          value={modalData.branch}
                          onChange={handleChange}
                          inputProps={{ maxLength: 50 }}
                          InputLabelProps={{ shrink: true }}
                        ></AutoCompleteBranch>
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <AutoCompleteRep
                          fullWidth
                          disabled={isEdit && !isAccountSet}
                          name="rep"
                          label="Rep/Advisor"
                          value={modalData.rep}
                          correspondent={modalData.correspondent}
                          onChange={handleChange}
                          inputProps={{ maxLength: 150 }}
                          InputLabelProps={{ shrink: true }}
                        ></AutoCompleteRep>
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <SelectSystemCode
                          // required={true}
                          disabled={isEdit && !isAccountSet}
                          name="broker"
                          label="Broker"
                          type="Broker"
                          value={modalData.broker}
                          onChange={handleChange}
                        ></SelectSystemCode>
                      </div>
                      <div className="grd-cell">
                        <SelectSystemCode
                          // required={true}
                          disabled={isEdit && !isAccountSet}
                          name="legalEntity"
                          label="Legal Entity"
                          placeholder="Legal Entity"
                          type="Legal Entity"
                          // subType="Client Site"
                          value={modalData.legalEntity}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="grd-row">
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <SelectSystemCode
                          // required={true}
                          disabled={isEdit && !isAccountSet}
                          name="privacyConsent"
                          label="Privacy Consent"
                          type="Privacy Consent"
                          value={modalData.privacyConsent}
                          onChange={handleChange}
                        ></SelectSystemCode>
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <SelectSystemCode
                          // required={true}
                          disabled={isEdit && !isAccountSet}
                          name="accountType"
                          label="Account Type"
                          placeholder="Account Type"
                          type="Client Account Type"
                          subType="Client Site"
                          value={modalData.accountType}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <SelectSystemCode
                          disabled={isEdit && !isAccountSet}
                          name="subType"
                          label="Sub Account Type"
                          type="Sub Account Type"
                          value={modalData.subType}
                          onChange={handleChange}
                          selectedAccountType={modalData.accountType}
                        />
                      </div>
                      <div className="grd-cell">
                        <SelectCommonId
                          disabled={isEdit && !isAccountSet}
                          freeSolo={true}
                          name="commonId"
                          fullWidth
                          label="Common ID"
                          value={modalData.commonId}
                          onChange={handleChange}
                          InputLabelProps={{ shrink: true }}
                          filter={modalData}
                        />
                      </div>
                    </div>
                    <div className="grd-row">
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <TextField
                          disabled={isEdit && !isAccountSet}
                          fullWidth
                          name="beneficiary"
                          label="Beneficiary"
                          value={modalData.beneficiary}
                          onChange={handleChange}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 300 }}
                        />
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <SelectSystemCodeWithDescription
                          disabled={isEdit && !isAccountSet}
                          name="currency"
                          label="Currency"
                          type="Currency"
                          value={modalData.currency}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}></div>
                      <div className="grd-cell"></div>
                    </div>
                    <div className="grd-row" style={{ margin: 0 }}>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <div style={{ display: isEntity() ? 'block' : 'none' }}>
                          <SelectSystemCode
                            // required={true}
                            disabled={isEdit && !isAccountSet}
                            type="Country"
                            name="formationCountry"
                            label="Formation Country"
                            value={modalData.formationCountry}
                            key={modalData.formationCountry}
                            onChange={(selected) => {
                              setModalData({
                                ...modalData,
                                formationCountry: selected?.code,
                              });
                            }}
                            style={{
                              display: isEntity() ? 'block' : 'none',
                            }}
                          />
                        </div>
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <div style={{ display: isEntity() ? 'block' : 'none' }}>
                          <AutoCompleteState
                            disabled={isEdit && !isAccountSet}
                            name="formationState"
                            label="State"
                            placeholder="State"
                            value={modalData.formationState}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div
                        className=""
                        style={{
                          display: isEntity() ? 'block' : 'none',
                        }}
                      >
                        <TextField
                          fullWidth
                          disabled={isEdit && !isAccountSet}
                          name="formationDate"
                          label="Formation Date"
                          type="date"
                          value={modalData.formationDate}
                          onChange={handleChange}
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className={classes.accordion}
                  expanded={expanded === 'taxInformation'}
                  onChange={handleExpandAccordion('taxInformation')}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>Tax Information</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <div className="grd-row">
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <SelectSystemCode
                          disabled={isEdit && !isAccountSet}
                          // required={true}
                          type="Country"
                          name="formationCountry"
                          label="Formation Country"
                          value={modalData.formationCountry}
                          key={modalData.formationCountry}
                          onChange={(selected) => {
                            setModalData({
                              ...modalData,
                              formationCountry: selected?.code,
                            });
                          }}
                          style={{
                            display: isEntity() ? 'block' : 'none',
                          }}
                        />
                      </div>
                      <div
                        className=""
                        style={{
                          marginRight: 30,
                          display: isEntity() ? 'block' : 'none',
                        }}
                      >
                        <InputMask
                          mask={'99-9999999'}
                          value={modalData.tin}
                          // required={isEntity()}
                          ref={taxIdMaskRef}
                          beforeMaskedValueChange={(newState, oldState) => {
                            if (newState.value !== oldState.value) {
                              handleChange({
                                currentTarget: {
                                  name: 'tin',
                                  value: newState.value,
                                },
                              });
                            }
                            return {
                              value: newState.value,
                              selection: newState.selection,
                            };
                          }}
                        >
                          {() => (
                            <TextField
                              // error={!modalData.id}
                              fullWidth={true}
                              // required={true}
                              id="tin"
                              name="tin"
                              label="EIN"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        </InputMask>
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <SelectSystemCode
                          disabled={isEdit && !isAccountSet}
                          // required={true}
                          name="tefra"
                          label="TEFRA"
                          type="TEFRA"
                          value={modalData.tefra}
                          onChange={handleChange}
                        ></SelectSystemCode>
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <SelectSystemCode
                          disabled={isEdit && !isAccountSet}
                          // required={true}
                          name="w8w9"
                          label="W8W9"
                          type="W8/W9"
                          value={modalData.w8w9}
                          onChange={handleChange}
                        ></SelectSystemCode>
                      </div>
                    </div>
                    <div className="grd-row">
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <TextField
                          disabled={isEdit && !isAccountSet}
                          fullWidth
                          name="w8w9ReceivedDate"
                          label="W8W9 Received Date"
                          type="date"
                          value={modalData.w8w9ReceivedDate}
                          onChange={handleChange}
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <TextField
                          fullWidth
                          disabled={isEdit && !isAccountSet}
                          name="taxBracket"
                          label="Tax Bracket"
                          type="number"
                          value={modalData.taxBracket}
                          onChange={handleChange}
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}></div>
                      <div
                        className=""
                        style={{
                          marginRight: 20,
                          display: isEntity() ? 'block' : 'none',
                        }}
                      ></div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className={classes.accordion}
                  expanded={expanded === 'trade'}
                  onChange={handleExpandAccordion('trade')}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>Trade</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <div className="grd-row">
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <SelectSystemCode
                          disabled={isEdit && !isAccountSet}
                          // required={true}
                          name="marginType"
                          label="Margin Type"
                          type="Margin Type"
                          value={modalData.marginType}
                          onChange={handleChange}
                        ></SelectSystemCode>
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <TextField
                          disabled={isEdit && !isAccountSet}
                          fullWidth
                          name="largeTraderId"
                          label="Large Trader ID"
                          value={modalData.largeTraderId}
                          onChange={handleChange}
                          inputProps={{ maxLength: 50 }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={isEdit && !isAccountSet}
                              name="dayTrader"
                              checked={modalData.dayTrader}
                              onChange={handleChange}
                            />
                          }
                          label="Day Trader"
                        />
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <TextField
                          disabled={isEdit && !isAccountSet}
                          fullWidth
                          name="multiplier"
                          label="Multiplier"
                          type="number"
                          value={modalData.multiplier}
                          onChange={handleChange}
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                    </div>
                    <div className="grd-row">
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={isEdit && !isAccountSet}
                              name="allowLiquidInvestment"
                              checked={modalData.allowLiquidInvestment}
                              onChange={handleChange}
                            />
                          }
                          label="Allow Liquidation"
                        />
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={isEdit && !isAccountSet}
                              name="accreditedInvestor"
                              checked={modalData.accreditedInvestor}
                              onChange={handleChange}
                            />
                          }
                          label="Accredited Investor"
                        />
                      </div>

                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={isEdit && !isAccountSet}
                              name="marginDisclosure"
                              checked={modalData.marginDisclosure}
                              onChange={handleChange}
                            />
                          }
                          label="Margin Disclosure"
                        />
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={isEdit && !isAccountSet}
                              name="marginAgreement"
                              checked={modalData.marginAgreement}
                              onChange={handleChange}
                            />
                          }
                          label="Margin Agreement"
                        />
                      </div>
                    </div>
                    <div className="grd-row">
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={isEdit && !isAccountSet}
                              name="fpslParticipant"
                              checked={modalData.fpslParticipant}
                              onChange={handleChange}
                            />
                          }
                          label="FPSL Participant"
                        />
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={isEdit && !isAccountSet}
                              name="fdidFlag"
                              checked={modalData.fdidFlag}
                              onChange={handleChange}
                            />
                          }
                          label="FDID Flag"
                        />
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <TextField
                          disabled={isEdit && !isAccountSet}
                          fullWidth
                          name="fdid"
                          label="FDID"
                          value={modalData.fdid}
                          onChange={handleChange}
                          inputProps={{ maxLength: 150 }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <TextField
                          disabled={isEdit && !isAccountSet}
                          fullWidth
                          name="fdidEndReason"
                          label="FDID End Reason"
                          value={modalData.fdidEndReason}
                          onChange={handleChange}
                          inputProps={{ maxLength: 150 }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                    </div>
                    <div className="grd-row">
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        Trading Priviledge
                      </div>
                    </div>
                    <div className="grd-row">
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={isEdit && !isAccountSet}
                              name="stocks"
                              checked={modalData.stocks}
                              onChange={handleChange}
                            />
                          }
                          label="Equity"
                        />
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={isEdit && !isAccountSet}
                              name="option"
                              checked={modalData.option}
                              onChange={handleChange}
                            />
                          }
                          label="Option"
                        />
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={isEdit && !isAccountSet}
                              name="mutualFunds"
                              checked={modalData.mutualFunds}
                              onChange={handleChange}
                            />
                          }
                          label="Mutual Funds"
                        />
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={isEdit && !isAccountSet}
                              name="fixedIncome"
                              checked={modalData.fixedIncome}
                              onChange={handleChange}
                            />
                          }
                          label="Fixed Income"
                        />
                      </div>
                    </div>
                    <div className="grd-row">
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <SelectSystemCode
                          disabled={isEdit && !isAccountSet}
                          // required={true}
                          type="Investment Objective"
                          name="investmentObjectiveCode"
                          label="Investment Objective Code"
                          value={modalData.investmentObjectiveCode}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <TextField
                          disabled={isEdit && !isAccountSet}
                          fullWidth
                          name="investmentObjectiveDate"
                          label="Investment Objective Date"
                          type="date"
                          value={modalData.investmentObjectiveDate}
                          onChange={handleChange}
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <SelectSystemCode
                          disabled={isEdit && !isAccountSet}
                          freeSolo={true}
                          name="annualIncome"
                          label="Annual Income"
                          type="Investment Objectives"
                          subType="Annual Income"
                          itemLabel="code"
                          value={modalData.annualIncome}
                          onChange={handleChange}
                        ></SelectSystemCode>
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <SelectSystemCode
                          disabled={isEdit && !isAccountSet}
                          freeSolo={true}
                          name="liquidNetWorth"
                          label="Liquid Net Worth"
                          type="Investment Objectives"
                          subType="Liquid Net Worth"
                          itemLabel="code"
                          value={modalData.liquidNetWorth}
                          onChange={handleChange}
                        ></SelectSystemCode>
                      </div>
                    </div>
                    <div className="grd-row">
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <SelectSystemCode
                          disabled={isEdit && !isAccountSet}
                          freeSolo={true}
                          name="sourceOfFunds"
                          label="Source of Funds"
                          type="Fund Source"
                          value={modalData.sourceOfFunds}
                          onChange={handleChange}
                        ></SelectSystemCode>
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <SelectSystemCode
                          disabled={isEdit && !isAccountSet}
                          name="serviceBureau"
                          label="Service Bureau"
                          type="Service Bureau"
                          value={modalData.serviceBureau}
                          onChange={handleChange}
                        ></SelectSystemCode>
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <SelectSystemCode
                          disabled={isEdit && !isAccountSet}
                          name="riskProfile"
                          label="Risk Profile"
                          type="Risk Tolerance"
                          value={modalData.riskProfile}
                          onChange={handleChange}
                        ></SelectSystemCode>
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled={isEdit && !isAccountSet}
                              name="cashSweepParticipant"
                              checked={modalData.cashSweepParticipant}
                              onChange={handleChange}
                            />
                          }
                          label="Cash Sweep Participant"
                        />
                      </div>
                    </div>
                    <div className="grd-row">
                      <div className="grd-cell" style={{ marginRight: 20 }}>
                        <SelectSystemCode
                          disabled={isEdit && !isAccountSet}
                          name="plType"
                          label="PL Type"
                          type="PL Type"
                          value={modalData.plType}
                          onChange={handleChange}
                        ></SelectSystemCode>
                      </div>
                      <div className="grd-cell" style={{ marginRight: 20 }}></div>
                      <div className="grd-cell" style={{ marginRight: 20 }}></div>
                      <div className="grd-cell" style={{ marginRight: 20 }}></div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className={classes.accordion}
                  expanded={expanded === 'broker'}
                  onChange={handleExpandAccordion('broker')}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>Broker</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <div className="grd-row">
                      <div className="grd-cell" style={{ marginRight: 30 }}>
                        <TextField
                          fullWidth
                          disabled={isEdit && !isAccountSet}
                          name="primeBroker"
                          label="Prime Broker"
                          value={modalData.primeBroker}
                          onChange={handleChange}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 300 }}
                        />
                      </div>
                      <div className="grd-cell" style={{ marginRight: 30 }}>
                        <TextField
                          fullWidth
                          disabled={isEdit && !isAccountSet}
                          name="participantNo"
                          label="Participant No"
                          value={modalData.participantNo}
                          onChange={handleChange}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 4 }}
                        />
                      </div>
                      <div className="grd-cell" style={{ marginRight: 30 }}>
                        <TextField
                          fullWidth
                          disabled={isEdit && !isAccountSet}
                          name="executingBroker"
                          label="Executing Broker"
                          value={modalData.executingBroker}
                          onChange={handleChange}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 10 }}
                        />
                      </div>
                      <div className="grd-cell" style={{ marginRight: 30 }}>
                        <TextField
                          fullWidth
                          disabled={isEdit && !isAccountSet}
                          name="institutionNo"
                          label="Institution No"
                          value={modalData.institutionNo}
                          onChange={handleChange}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 10 }}
                        />
                      </div>
                    </div>
                    <div className="grd-row">
                      <div className="grd-cell" style={{ marginRight: 30 }}>
                        <TextField
                          fullWidth
                          disabled={isEdit && !isAccountSet}
                          name="agentNo"
                          label="Agent No"
                          value={modalData.agentNo}
                          onChange={handleChange}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 10 }}
                        />
                      </div>
                      <div className="grd-cell" style={{ marginRight: 30 }}>
                        <TextField
                          fullWidth
                          disabled={isEdit && !isAccountSet}
                          name="agentAccountNo"
                          label="Agent Account No"
                          value={modalData.agentAccountNo}
                          onChange={handleChange}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 10 }}
                        />
                      </div>
                      <div className="grd-cell" style={{ marginRight: 30 }}>
                        <TextField
                          fullWidth
                          disabled={isEdit && !isAccountSet}
                          name="traderId"
                          label="Trader ID"
                          value={modalData.traderId}
                          onChange={handleChange}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 10 }}
                        />
                      </div>
                      <div className="grd-cell" style={{ marginRight: 30 }}></div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className={classes.accordion}
                  expanded={expanded === 'address'}
                  onChange={handleExpandAccordion('address')}
                  style={{
                    display: isEntity() ? 'block' : 'none',
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>Address</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    {/* <AddressTable
                      accountId={modalData.accountId}
                      list={addressList}
                      set={setAddressList}
                    /> */}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className={classes.accordion}
                  expanded={expanded === 'other'}
                  onChange={handleExpandAccordion('other')}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>Other</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <div className="grd-row">
                      {!isImport ? (
                        <div
                          className="grd-cell"
                          style={{ marginRight: 20, marginTop: 18 }}
                        >
                          <ImageDropZone
                            disabled={isEdit && !isAccountSet}
                            label="Signature"
                            onChangeStatus={handleUploadedPhotoStatus}
                            onSubmit={handleUploadPhoto}
                            initialFiles={[initialFile]}
                          />
                        </div>
                      ) : null}
                      <div className="grd-cell">
                        <div className="grd-row">
                          <div className="grd-cell" style={{ marginRight: 20 }}>
                            <TextField
                              disabled={isEdit && !isAccountSet}
                              fullWidth
                              // required={requiredField.activatedAt}
                              name="activatedAt"
                              label="Activated At"
                              type="datetime-local"
                              value={modalData.activatedAt}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                            />
                          </div>
                        </div>
                        <div className="grd-row">
                          <div className="grd-cell" style={{ marginRight: 20 }}>
                            <TextField
                              disabled={isEdit && !isAccountSet}
                              fullWidth
                              name="statusReason"
                              label="Status Reason"
                              value={modalData.statusReason}
                              onChange={handleChange}
                              inputProps={{ maxLength: 150 }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </div>
                        </div>
                        <div className="grd-row">
                          <div className="grd-cell" style={{ marginRight: 30 }}>
                            <FormControl
                              fullWidth
                              disabled={isEdit && !isAccountSet}
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              <InputLabel shrink id="kycStatus">
                                KYC Status
                              </InputLabel>
                              <Select
                                name="kycStatus"
                                fullWidth
                                value={modalData.kycStatus}
                                onChange={handleChange}
                              >
                                <MenuItem value="Pending">Pending</MenuItem>
                                <MenuItem value="Approved">Approved</MenuItem>
                                <MenuItem value="Denied">Denied</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <div className="grd-row">
                          <div className="grd-cell" style={{ marginRight: 30 }}>
                            <TextField
                              disabled={isEdit && !isAccountSet}
                              fullWidth
                              name="securityWordHint"
                              label="Security Word Hint"
                              value={modalData.securityWordHint}
                              onChange={handleChange}
                              inputProps={{ maxLength: 50 }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grd-cell">
                        <div className="grd-row">
                          <div className="grd-cell" style={{ marginRight: 20 }}>
                            <TextField
                              fullWidth
                              disabled={true}
                              name="disabledAt"
                              label="Disabled At"
                              type="datetime-local"
                              value={modalData.disabledAt}
                              onChange={handleChange}
                              InputLabelProps={{ shrink: true }}
                            />
                          </div>
                        </div>
                        <div className="grd-row">
                          <div className="grd-cell" style={{ marginRight: 20 }}>
                            {!isImport ? (
                              <div
                                className="grd-cell"
                                style={{ marginRight: 20, marginTop: 24 }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disabled={isEdit && !isAccountSet}
                                      name="signed"
                                      checked={modalData.signed}
                                      onChange={handleChange}
                                    />
                                  }
                                  label="Signed"
                                />
                              </div>
                            ) : (
                              <div className="" style={{ marginRight: 20 }}></div>
                            )}
                          </div>
                        </div>
                        <div className="grd-row">
                          <div className="grd-cell" style={{ marginRight: 20 }}>
                            <TextField
                              fullWidth
                              disabled={ isEdit && !isAccountSet }
                              name="promoCode"
                              label="Promo Code"
                              value={modalData.promoCode}
                              onChange={handleChange}
                              inputProps={{ maxLength: 50 }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </div>
                        </div>
                        <div className="grd-row">
                          <div className="grd-cell" style={{ marginRight: 20 }}>
                            <TextField
                              fullWidth
                              name="onboardingPercent"
                              label="Onboarding Percent"
                              value={modalData.onboardingPercent + '%'}
                              onChange={handleChange}
                              inputProps={{ maxLength: 150 }}
                              InputLabelProps={{ shrink: true }}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grd-cell">
                        <div className="grd-row">
                          <div className="grd-cell" style={{ marginRight: 20 }}>
                            <SelectSystemCode
                              disabled={isEdit && !isAccountSet}
                              // required={true}
                              name="status"
                              label="Status"
                              type="Status"
                              subType="Client Setup"
                              value={modalData.status}
                              onChange={handleChange}
                            ></SelectSystemCode>
                          </div>
                        </div>
                        <div className="grd-row">
                          <div className="grd-cell" style={{ marginRight: 20 }}>
                            <TextField
                              disabled={isEdit && !isAccountSet}
                              fullWidth
                              name="internalStatus"
                              label="Internal Status"
                              value={modalData.internalStatus}
                              onChange={handleChange}
                              inputProps={{ readOnly: true, maxLength: 150 }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </div>
                        </div>
                        <div className="grd-row">
                          <div className="grd-cell" style={{ marginRight: 20 }}>
                            <TextField
                              disabled={isEdit && !isAccountSet}
                              fullWidth
                              name="securityWord"
                              label="Security Word"
                              value={modalData.securityWord}
                              onChange={handleChange}
                              inputProps={{ maxLength: 50 }}
                              InputLabelProps={{ shrink: true }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </TabPanel>
              <TabPanel className={classes.tabBody} value={tabIndexValue} index={1}>
                <OwnerTable
                  accountId={modalData.accountId}
                  list={ownerList}
                  set={setOwnerList}
                  importMode={isImport}
                />
              </TabPanel>
              <TabPanel className={classes.tabBody} value={tabIndexValue} index={2}>
                <Box>
                  <SubAccountTable
                    accountId={modalData.accountId}
                    list={subAccountList}
                    set={setSubAccountList}
                  />
                </Box>
              </TabPanel>
              <TabPanel className={classes.tabBody} value={tabIndexValue} index={3}>
                <Box>
                  <NoteTable
                    accountId={modalData.accountId}
                    list={noteList}
                    set={setNoteList}
                    style={{ paddingBottom: 0 }}
                  />
                </Box>
              </TabPanel>
              <TabPanel className={classes.tabBody} value={tabIndexValue} index={4}>
                <Box>
                  <AttachmentTable
                    accountId={modalData.accountId}
                    style={{ paddingBottom: 0 }}
                  />
                </Box>
              </TabPanel>
            </div>
          </div>
          <div
            className={classes.modalFooter}
            style={{ margin: 0, padding: '10px 30px 30px 25px' }}
          >
            {[0].includes(tabIndexValue) && !isImport && (
              <div className="grd-cell-none" style={{ marginRight: 10 }}>
                {/* <Button
                  disabled={loading || !isEdit}
                  color="primary"
                  label="View Audit Logs"
                  onClick={() => {
                    setOpen(true);
                  }}
                /> */}
              </div>
            )}
            <div className="grd-cell-none" style={{ marginRight: 10 }}>
              <Button
                disabled={loading}
                color="primary"
                label="Close"
                onClick={() => {
                  handleCloseModal();
                }}
              />
            </div>
            <div className="grd-cell-none">
              <Button
                disabled={loading}
                loading={loading}
                color="secondary"
                label={loading ? 'Saving...' : 'Save'}
                onClick={() => {
                  handleCloseModal(modalData, isEdit);
                }}
              />
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
