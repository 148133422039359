/**
 * @fileoverview gRPC-Web generated client stub for feepb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!

/* eslint-disable */
// @ts-nocheck

const grpc = {};
grpc.web = require('grpc-web');

var google_type_date_pb = require('../../google/type/date_pb.js');
const proto = {};
proto.feepb = require('./feemanagerreport_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.feepb.FeeManagerReportServiceClient = function (hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;
};

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.feepb.FeeManagerReportServicePromiseClient = function (
  hostname,
  credentials,
  options
) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;
};

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.feepb.ListFeeManagerReportRequest,
 *   !proto.feepb.ListFeeManagerReportResponse>}
 */
const methodDescriptor_FeeManagerReportService_ListFeeManagerReport =
  new grpc.web.MethodDescriptor(
    '/feepb.FeeManagerReportService/ListFeeManagerReport',
    grpc.web.MethodType.UNARY,
    proto.feepb.ListFeeManagerReportRequest,
    proto.feepb.ListFeeManagerReportResponse,
    /**
     * @param {!proto.feepb.ListFeeManagerReportRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.feepb.ListFeeManagerReportResponse.deserializeBinary
  );

/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.feepb.ListFeeManagerReportRequest,
 *   !proto.feepb.ListFeeManagerReportResponse>}
 */
const methodInfo_FeeManagerReportService_ListFeeManagerReport =
  new grpc.web.AbstractClientBase.MethodInfo(
    proto.feepb.ListFeeManagerReportResponse,
    /**
     * @param {!proto.feepb.ListFeeManagerReportRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.feepb.ListFeeManagerReportResponse.deserializeBinary
  );

/**
 * @param {!proto.feepb.ListFeeManagerReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.feepb.ListFeeManagerReportResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.feepb.ListFeeManagerReportResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.feepb.FeeManagerReportServiceClient.prototype.listFeeManagerReport = function (
  request,
  metadata,
  callback
) {
  return this.client_.rpcCall(
    this.hostname_ + '/feepb.FeeManagerReportService/ListFeeManagerReport',
    request,
    metadata || {},
    methodDescriptor_FeeManagerReportService_ListFeeManagerReport,
    callback
  );
};

/**
 * @param {!proto.feepb.ListFeeManagerReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.feepb.ListFeeManagerReportResponse>}
 *     A native promise that resolves to the response
 */
proto.feepb.FeeManagerReportServicePromiseClient.prototype.listFeeManagerReport =
  function (request, metadata) {
    return this.client_.unaryCall(
      this.hostname_ + '/feepb.FeeManagerReportService/ListFeeManagerReport',
      request,
      metadata || {},
      methodDescriptor_FeeManagerReportService_ListFeeManagerReport
    );
  };

/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.feepb.ListFeeManagerReportDetailsRequest,
 *   !proto.feepb.ListFeeManagerReportDetailsResponse>}
 */
const methodDescriptor_FeeManagerReportService_ListFeeManagerReportDetails =
  new grpc.web.MethodDescriptor(
    '/feepb.FeeManagerReportService/ListFeeManagerReportDetails',
    grpc.web.MethodType.UNARY,
    proto.feepb.ListFeeManagerReportDetailsRequest,
    proto.feepb.ListFeeManagerReportDetailsResponse,
    /**
     * @param {!proto.feepb.ListFeeManagerReportDetailsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.feepb.ListFeeManagerReportDetailsResponse.deserializeBinary
  );

/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.feepb.ListFeeManagerReportDetailsRequest,
 *   !proto.feepb.ListFeeManagerReportDetailsResponse>}
 */
const methodInfo_FeeManagerReportService_ListFeeManagerReportDetails =
  new grpc.web.AbstractClientBase.MethodInfo(
    proto.feepb.ListFeeManagerReportDetailsResponse,
    /**
     * @param {!proto.feepb.ListFeeManagerReportDetailsRequest} request
     * @return {!Uint8Array}
     */
    function (request) {
      return request.serializeBinary();
    },
    proto.feepb.ListFeeManagerReportDetailsResponse.deserializeBinary
  );

/**
 * @param {!proto.feepb.ListFeeManagerReportDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.feepb.ListFeeManagerReportDetailsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.feepb.ListFeeManagerReportDetailsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.feepb.FeeManagerReportServiceClient.prototype.listFeeManagerReportDetails =
  function (request, metadata, callback) {
    return this.client_.rpcCall(
      this.hostname_ + '/feepb.FeeManagerReportService/ListFeeManagerReportDetails',
      request,
      metadata || {},
      methodDescriptor_FeeManagerReportService_ListFeeManagerReportDetails,
      callback
    );
  };

/**
 * @param {!proto.feepb.ListFeeManagerReportDetailsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.feepb.ListFeeManagerReportDetailsResponse>}
 *     A native promise that resolves to the response
 */
proto.feepb.FeeManagerReportServicePromiseClient.prototype.listFeeManagerReportDetails =
  function (request, metadata) {
    return this.client_.unaryCall(
      this.hostname_ + '/feepb.FeeManagerReportService/ListFeeManagerReportDetails',
      request,
      metadata || {},
      methodDescriptor_FeeManagerReportService_ListFeeManagerReportDetails
    );
  };

module.exports = proto.feepb;
