import { notifyError } from '../components/Messages/Notification';
import {
  UsrAccessServiceClient,
  ListUsrAccessRequest,
  UpdateAmountLimitRequest,
} from '../proto/usrpb/access_grpc_web_pb';
import { auth } from '../lib/auth/Auth';

const service = new UsrAccessServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

export async function listUsrAccess(param) {
  return new Promise((resolve, reject) => {
    const req = new ListUsrAccessRequest();
    req.setRoleId(param.roleId);
    req.setRequestType(param.requestType);
    service.listUsrAccess(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateAmountLimit(accessId, amountLimit) {
  return new Promise((resolve, reject) => {
    const req = new UpdateAmountLimitRequest();
    req.setAccessId(accessId);
    req.setAmountLimit(amountLimit);
    service.updateAmountLimit(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
