import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getCorrespondentByUrl, getLogo } from '../../services/TemplateService';
import logo from '../../assets/images/sas-logo.png';
import {
	PDFViewer,
	Page,
	Text,
	View,
	Document,
	Image,
	Svg,
	Path,
	Rect,
	StyleSheet,
	pdf,
	BlobProvider,
} from '@react-pdf/renderer';
import { removeDuplicate } from '../../lib/validate/validate';
import { dateFormat } from '../../lib/format/format';
import color from '../../assets/css/colors';
import { generateAccountNo } from '../../services/CommonService';

const styles = StyleSheet.create({
	wrapper: {
		fontSize: 10,
		color: '#1c1c1c',
		backgroundColor: color.light.main,
		padding: 30,
	},
	grdRow: {
		flexDirection: 'row',
		marginBottom: 10,
	},
	grdCell: {
		flexGrow: 1,
		display: 'block',
		wordBreak: 'break-word',
		padding: 5,
	},
	grdHeader: {
		fontFamily: 'Helvetica-Bold',
		fontSize: 10,
		color: color.light.main,
		textTransform: 'uppercase',
		backgroundColor: '#1c1c1c',
		padding: 7,
	},
	grdSubHeader: {
		fontFamily: 'Helvetica-Bold',
		fontSize: 10,
		color: color.light.main,
		textTransform: 'uppercase',
		backgroundColor: '#1c1c1c',
		padding: 4,
	},
	BT: {
		borderTop: '1px solid #1c1c1c',
		borderCollapse: 'collapse',
	},
	BB: {
		borderBottom: '1px solid #1c1c1c',
		borderCollapse: 'collapse',
	},
	BL: {
		borderLeft: '1px solid #1c1c1c',
		borderCollapse: 'collapse',
	},
	BR: {
		borderRight: '1px solid #1c1c1c',
		borderCollapse: 'collapse',
	},
	imageSignature: {
		width: 100,
		height: 'auto',
	},
	imageLogo: {
		width: 50,
		height: 'auto',
		marginBottom: 30,
	},
	imageThumbnail: {
		width: '100%',
		height: 250,
		objectFit: 'contain',
		padding: 20,
		border: '1px solid #ececec',
		backgroundColor: '#ececec',
		display: 'inline',
	},
	h1: {
		fontFamily: 'Helvetica-Bold',
		fontSize: 12,
		textTransform: 'capitalize',
		textAlign: 'left',
		margin: '40px 5px 20px 5px',
		padding: 4,
		borderTop: '1px solid #1c1c1c',
		borderBottom: '1px solid #1c1c1c',
	},
	label: {
		fontSize: 10,
		color: '#424242',
		marginBottom: 4,
	},
	text: {
		color: '#202020',
		textTransform: 'uppercase',
		fontFamily: 'Helvetica-Bold',
	},
	text2: {
		color: '#202020',
		fontFamily: 'Helvetica-Bold',
	},
	textSmall: {
		fontSize: 15,
		color: '#424242',
		marginBottom: 4,
	},
	centerVH: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	mR8: { marginRight: 8 },
	mR20: { marginRight: 20 },
	mT5: { marginTop: 5 },
	mT10: { marginTop: 10 },
	mT20: { marginTop: 20 },
	mB5: { marginBottom: 5 },
	mB10: { marginBottom: 10 },
	mB20: { marginBottom: 20 },
	mB30: { marginBottom: 30 },
	mB80: { marginBottom: 40 },
	nM: { margin: '0 !important' },
	nP: { padding: '0 !important' },
});

const useStyles = makeStyles(() => ({
	pdfViewerWrapper: {
		width: '100%',
		minWidth: 900,
		height: 700,
		border: '1px solid ' + color.gray.opacity,
	},
}));

const adjustDate = (stringDate, days) => {
	let dateString = dateFormat(stringDate);
	let checkDateArr = dateString.split('/');

	if (checkDateArr.length === 3) {
		let date = new Date(dateString);
		date.setDate(date.getDate() + days);
		let dateNew = date.toISOString().slice(0, 10).replace(/-/g, '/');
		let dateArr = dateNew.split('/');
		let dateFormatted = dateArr[1] + '/' + dateArr[2] + '/' + dateArr[0];
		return dateFormatted;
	}

	return 'INVALID DATE';
};

const Logo = () => {
	const [clientLogo, setLogo] = React.useState(null);

	const handleGetLogo = async () => {
		try {
			const serverLogo = await getLogo();

			if (serverLogo.correspondent.photo !== '') {
				setLogo(serverLogo.correspondent.photo);
			} else {
				setLogo(logo);
			}
		} catch (error) {
			setLogo(logo);
		}
	};
	handleGetLogo();
	const logoUri = clientLogo;
	return (
		<Image style={styles.imageLogo} src={{ uri: logoUri, method: 'GET' }} />
	);
};

const CheckBox = () => (
	<Svg style={{ width: 15, height: 15 }} viewBox="0 0 130 130" fill="none">
		<Rect style={{ width: 15, height: 15 }} rx="10" />
		<Rect
			width="130"
			height="130"
			fill="white"
			stroke="#373737"
			stroke-width="24"
			mask="url(#path-1-inside-1)"
		/>
		<Rect transform="translate(29 29)" fill="white" />
		<Path
			d="M82.6435 48.6296L58.2104 73.0954L48.3543 63.2078L48.3476 63.2011L48.3408 63.1945C47.9206 62.7886 47.4245 62.4694 46.881 62.2552C46.3374 62.041 45.757 61.936 45.1728 61.9461C44.5886 61.9562 44.0122 62.0812 43.4764 62.3141C42.9405 62.547 42.4558 62.8832 42.0499 63.3034C41.644 63.7236 41.3248 64.2196 41.1106 64.7632C40.8964 65.3068 40.7913 65.8872 40.8014 66.4714C40.8116 67.0555 40.9366 67.632 41.1695 68.1678C41.4012 68.7008 41.735 69.1833 42.1522 69.5879L55.1035 82.5392C55.1041 82.5398 55.1047 82.5404 55.1053 82.541C55.5116 82.9502 55.9949 83.2751 56.5272 83.4969C57.0604 83.719 57.6322 83.8333 58.2098 83.8333C58.7873 83.8333 59.3592 83.719 59.8923 83.4969C60.4247 83.2751 60.9079 82.9502 61.3143 82.541C61.3149 82.5404 61.3154 82.5398 61.316 82.5392L88.8398 55.0154C89.2765 54.6085 89.6255 54.1166 89.865 53.5697C90.1074 53.0162 90.2325 52.4186 90.2325 51.8144C90.2325 51.2102 90.1074 50.6125 89.865 50.0591C89.6255 49.5125 89.2768 49.0206 88.8404 48.6139C88.4369 48.2112 87.9586 47.8912 87.4323 47.6719C86.8992 47.4498 86.3273 47.3354 85.7498 47.3354C85.1722 47.3354 84.6004 47.4498 84.0672 47.6719C83.5347 47.8938 83.0513 48.2188 82.6449 48.6282C82.6444 48.6286 82.644 48.6291 82.6435 48.6296Z"
			fill="#292929"
			stroke="black"
			stroke-width="6"
		/>
	</Svg>
);

const PDFApplicationForm = (props) => {
	const classes = useStyles();

	const {
		wrapper,
		grdRow,
		grdCell,
		grdHeader,
		grdSubHeader,
		BT,
		BB,
		BL,
		BR,
		label,
		text,
		text2,
		textSmall,
		mT10,
		mT20,
		nM,
		nP,
		centerVH,
	} = styles;

	const {
		accessData,
		accountData,
		accountCoOfficerData,
		identificationData,
		coIdentificationData,
		employmentData,
		associationData,
		tradingData,
		investmentObjectiveData,
		eSignatureData,
		trustedContactData,
		customerAccountAgreementData,
		dynamicDisclosures,
	} = props?.data;

	const { firstName, middleName, lastName, email } = accessData;

	const {
		accountType,
		proofOfAddress,
		address1,
		address2,
		city,
		state,
		country,
		zipCode,
		phoneNumber,
		subAccountType,
		coFirstName,
		coLastName,
		coMiddleName,
		coEmail,
		coPhoneNumber,
		coAddress1,
		coAddress2,
		coCity,
		coState,
		coCountry,
		coZipCode,
		taxId,
		companyName,
		primaryOfficer,
		primaryOwner,
		ownershipPercentage,
		primaryOfficerFirstName,
		primaryOfficerLastName,
		primaryOfficerMiddleName,
		primaryOfficerPhoneNumber,
		primaryOfficerAddress1,
		primaryOfficerAddress2,
		primaryOfficerCity,
		primaryOfficerState,
		primaryOfficerCountry,
		primaryOfficerZipCode,
	} = accountData;

	const {
		birthDate,
		citizenship,
		citizenshipAddress,
		countryOfIssuance,
		expirationDate,
		idAddress,
		identificationNo,
		identificationType,
		issueDate,
		taxAddress,
		taxCountry,
		proofOfIdentity,
		ssnNo,
	} = identificationData;

	const { doingBussinessAs, employerName, employmentStatus, occupation } =
		employmentData;

	const {
		affiliatedPerson,
		affiliatedPersonPosition,
		isExecutive,
		isRegisteredBroker,
		stockSymbol,
	} = associationData;

	const { marginType, options, stocks, readMarginDisclosureStatement, readMarginAgreement } = tradingData;

	const { acceptCustomerAgreement } = customerAccountAgreementData;

	const {
		annualIncome,
		fund,
		investmentObjective,
		liquidNetWorth,
		riskTolerance,
		other,
	} = investmentObjectiveData;

	const {
		id: tcId,
		firstName: tcFirstName,
		middleName: tcMiddleName,
		lastName: tcLastName,
		email: tcEmail,
		phone: tcPhoneNumber,
		birthDate: tcBirthDate,
		city: tcCity,
		country: tcCountry,
		relationship: tcRelationShip,
		state: tcState,
		type: tcType,
		trustedContactAddress1,
		isTrustedContact,
	} = trustedContactData;

	const unfilteredCoOfficerOwnerData = [];

	const combineCoOfficerOwnerDataAndIdentityData = (account) => {
		if (account.coOwnershipPercentage >= 25) {
			const identity = coIdentificationData.filter(
				(v) => v.id === account.id
			)[0];

			let accountInfo = {
				address1: account.coOfficerAddress1,
				address2: account.coOfficerAddress2,
				city: account.coOfficerCity,
				country: account.coOfficerCountry,
				state: account.coOfficerState,
				zipCode: account.coOfficerZipCode,
				firstName: account.coOfficerFirstName,
				lastName: account.coOfficerLastName,
				middleName: account.coOfficerMiddleName,
				email: account.coOfficerEmail,
				phoneNumber: account.coOfficerPhoneNumber,
				primaryOfficer: account.coPrimaryOfficer,
				primaryOwner: account.coPrimaryOwner,
				ownershipPercentage: account.coOwnershipPercentage,
				id: account.id,
				proofOfIdentity: identity?.proofOfIdentity,
				identificationType: identity?.identificationType,
				identificationNo: identity.identificationNo,
				issueDate: identity.issueDate,
				expirationDate: identity.expirationDate,
				birthDate: identity.birthDate,
				ssnNo: identity.ssnNo,
				idAddress: identity.idAddress,
				taxAddress: identity.taxAddress,
				citizenshipAddress: identity.citizenshipAddress,
				countryOfIssuance: identity.countryOfIssuance,
				taxCountry: identity.taxCountry,
				citizenship: identity.citizenship,
			};

			unfilteredCoOfficerOwnerData.push(accountInfo);
		} else {
			let accountInfo = {
				address1: account.coOfficerAddress1,
				address2: account.coOfficerAddress2,
				city: account.coOfficerCity,
				country: account.coOfficerCountry,
				state: account.coOfficerState,
				zipCode: account.coOfficerZipCode,
				firstName: account.coOfficerFirstName,
				lastName: account.coOfficerLastName,
				middleName: account.coOfficerMiddleName,
				email: account.coOfficerEmail,
				phoneNumber: account.coOfficerPhoneNumber,
				primaryOfficer: account.coPrimaryOfficer,
				primaryOwner: account.coPrimaryOwner,
				ownershipPercentage: account.coOwnershipPercentage,
				id: account.id,
			};
			unfilteredCoOfficerOwnerData.push(accountInfo);
		}
	};

	accountCoOfficerData.forEach(combineCoOfficerOwnerDataAndIdentityData);

	const coOfficerOwnerData = removeDuplicate(
		unfilteredCoOfficerOwnerData,
		'id'
	);

	let accountTypeDisplay = ""

	switch (accountType) {
		case "I":
			accountTypeDisplay = "Individual";
			break;
		case "C":
			accountTypeDisplay = "Corporation";
			break;
		case "J":
			accountTypeDisplay = "Joint";
			break;
		case "IRA":
			accountTypeDisplay = "Individual Retirement Account";
			break;
		default:
			accountTypeDisplay = accountType;
	}

	const AccountInformation = () => (
		<View style={[grdRow]}>
			<View style={[grdCell]}>
				<View style={[grdHeader]}>
					<Text>Account Information</Text>
				</View>
				<View style={[grdRow, nM]}>
					<View style={[grdCell, BB, BL, { width: 33.33 }]}>
						<Text style={label}>First Name:</Text>
						<Text style={[text]}>{firstName}</Text>
					</View>
					<View style={[grdCell, BB, BL, { width: 33.33 }]}>
						<Text style={label}>Middle Name:</Text>
						<Text style={[text]}>{middleName}</Text>
					</View>
					<View style={[grdCell, BB, BL, BR, { width: 33.33 }]}>
						<Text style={label}>Last Name:</Text>
						<Text style={[text]}>{lastName}</Text>
					</View>
				</View>
				<View style={[grdRow, nM]}>
					<View style={[grdCell, BB, BL, { width: 33.33 }]}>
						<Text style={label}>Email Address:</Text>
						<Text style={[text]}>{email}</Text>
					</View>
					<View style={[grdCell, BB, BL, { width: 33.33 }]}>
						<Text style={label}>Phone Number:</Text>
						<Text style={[text]}>{phoneNumber}</Text>
					</View>
					<View style={[grdCell, BB, BL, BR, { width: 33.33 }]}>
						<Text style={label}>Birth Date:</Text>
						<Text style={[text]}>{adjustDate(dateFormat(birthDate), 1)}</Text>
					</View>
				</View>
				<View style={[grdRow, nM]}>
					<View style={[grdCell, BB, BL, { width: 50 }]}>
						<Text style={label}>Address:</Text>
						<Text style={[text]}>
							{address1} {address2}
						</Text>
					</View>
					<View style={[grdCell, BB, BL, BR, { width: 50 }]}>
						<Text style={label}>City:</Text>
						<Text style={[text]}>{city}</Text>
					</View>
				</View>
				<View style={[grdRow, nM]}>
					<View style={[grdCell, BB, BL, { width: 33.33 }]}>
						<Text style={label}>State:</Text>
						<Text style={[text]}>{state}</Text>
					</View>
					<View style={[grdCell, BB, BL, { width: 33.33 }]}>
						<Text style={label}>Zip Code:</Text>
						<Text style={[text]}>{zipCode}</Text>
					</View>
					<View style={[grdCell, BB, BL, BR, { width: 33.33 }]}>
						<Text style={label}>Country:</Text>
						<Text style={[text]}>{country}</Text>
					</View>
				</View>
				<View style={[grdRow, nM]}>
					<View style={[grdCell, BL, BR, { width: 50 }]}>
						<Text style={label}>Account Type:</Text>
						<Text style={[text]}>{accountTypeDisplay}</Text>
					</View>
					{(accountData.accountType === 'J' || accountData.accountType === 'C' || accountData.accountType === 'IRA') && (
						<View style={[grdCell, BR, { width: 50 }]}>
							<Text style={label}>Sub Account Type:</Text>
							<Text style={[text]}>{subAccountType}</Text>
						</View>
					)}
				</View>
				<View style={[grdSubHeader, { textAlign: 'center' }]}>
					<Text>Proof of Address:</Text>
				</View>
				<View style={[grdRow, nM]}>
					<View style={[grdCell, BB, BL, BR]}>
						{proofOfAddress ? (
							<Image
								style={styles.imageThumbnail}
								src={{ uri: proofOfAddress, method: 'GET' }}
							/>
						) : (
							<Text style={[{ textAlign: 'center' }]}>
								No proof of address provided.
							</Text>
						)}
					</View>
				</View>
			</View>
		</View>
	);

	const CoApplicantInformation = () => (
		<View style={[grdCell]}>
			<View style={[grdHeader]}>
				<Text>Co-Applicant Information</Text>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, { width: 33.33 }]}>
					<Text style={label}>First Name:</Text>
					<Text style={[text]}>{coFirstName}</Text>
				</View>
				<View style={[grdCell, BB, BL, { width: 33.33 }]}>
					<Text style={label}>Middle Name:</Text>
					<Text style={[text]}>{coMiddleName}</Text>
				</View>
				<View style={[grdCell, BB, BL, BR, { width: 33.33 }]}>
					<Text style={label}>Last Name:</Text>
					<Text style={[text]}>{coLastName}</Text>
				</View>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, { width: 50 }]}>
					<Text style={label}>Email Address:</Text>
					<Text style={[text]}>{coEmail}</Text>
				</View>
				<View style={[grdCell, BB, BL, BR, { width: 50 }]}>
					<Text style={label}>Phone Number:</Text>
					<Text style={[text]}>{coPhoneNumber}</Text>
				</View>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, { width: 50 }]}>
					<Text style={label}>Address:</Text>
					<Text style={[text]}>
						{coAddress1} {coAddress2}
					</Text>
				</View>
				<View style={[grdCell, BB, BL, BR, { width: 50 }]}>
					<Text style={label}>City:</Text>
					<Text style={[text]}>{coCity}</Text>
				</View>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, { width: 33.33 }]}>
					<Text style={label}>State:</Text>
					<Text style={[text]}>{coState}</Text>
				</View>
				<View style={[grdCell, BB, BL, { width: 33.33 }]}>
					<Text style={label}>Zip Code:</Text>
					<Text style={[text]}>{coZipCode}</Text>
				</View>
				<View style={[grdCell, BB, BL, BR, { width: 33.33 }]}>
					<Text style={label}>Country:</Text>
					<Text style={[text]}>{coCountry}</Text>
				</View>
			</View>
		</View>
	);

	const IdentityInformation = () => (
		<View style={[grdCell]}>
			<View style={[grdHeader]}>
				<Text>Identity</Text>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, { width: 25 }]}>
					<Text style={label}>Identification Type:</Text>
					<Text style={[text]}>{identificationType}</Text>
				</View>
				<View style={[grdCell, BB, BL, { width: 25 }]}>
					<Text style={label}>Identification No.:</Text>
					<Text style={[text]}>{identificationNo}</Text>
				</View>
				<View style={[grdCell, BB, BL, { width: 25 }]}>
					<Text style={label}>Issue Date:</Text>
					<Text style={[text]}>{adjustDate(issueDate, 1)}</Text>
				</View>
				<View style={[grdCell, BB, BL, BR, { width: 25 }]}>
					<Text style={label}>Expiration Date:</Text>
					<Text style={[text]}>
						{adjustDate(dateFormat(expirationDate), 1)}
					</Text>
				</View>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BL, { width: 50 }]}>
					<Text style={label}>Birth Date:</Text>
					<Text style={[text]}>{adjustDate(dateFormat(birthDate), 1)}</Text>
				</View>
				<View style={[grdCell, BL, BR, { width: 50 }]}>
					<Text style={label}>Social Security No.:</Text>
					<Text style={[text]}>{ssnNo}</Text>
				</View>
			</View>
			<View style={[grdSubHeader]}>
				<View style={[grdRow, nM]}>
					<View style={[grdCell, nP, { width: 250 }]}>
						<Text></Text>
					</View>
					<View style={[grdCell, nP, { width: 50, textAlign: 'center' }]}>
						<Text>Yes</Text>
					</View>
					<View style={[grdCell, nP, { width: 50, textAlign: 'center' }]}>
						<Text>No</Text>
					</View>
				</View>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, BR, { width: 250 }]}>
					<Text>My country of ID is the same as my physical address.</Text>
					{!idAddress && (
						<View style={[grdRow, nM]}>
							<Text style={[label, mT10]}>Country of Issuance: </Text>
							<Text style={[text, mT10]}>{countryOfIssuance}</Text>
						</View>
					)}
				</View>
				<View
					style={[
						grdCell,
						BB,
						BR,
						{
							width: 50,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						},
					]}
				>
					{idAddress && <CheckBox />}
				</View>
				<View
					style={[
						grdCell,
						BB,
						BR,
						{
							width: 50,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						},
					]}
				>
					{!idAddress && <CheckBox />}
				</View>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, BR, { width: 250 }]}>
					<Text>
						My country of taxation is the same as my physical address.
					</Text>
					{!taxAddress && (
						<View style={[grdRow, nM]}>
							<Text style={[label, mT10]}>Tax Country: </Text>
							<Text style={[text, mT10]}>{taxCountry}</Text>
						</View>
					)}
				</View>
				<View
					style={[
						grdCell,
						BB,
						BR,
						{
							width: 50,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						},
					]}
				>
					{taxAddress && <CheckBox />}
				</View>
				<View
					style={[
						grdCell,
						BB,
						BR,
						{
							width: 50,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						},
					]}
				>
					{!taxAddress && <CheckBox />}
				</View>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, BR, { width: 250 }]}>
					<Text>
						My country of citizenship is the same as my physical address.
					</Text>
					{!citizenshipAddress && (
						<View style={[grdRow, nM]}>
							<Text style={[label, mT10]}>Citizenship: </Text>
							<Text style={[text, mT10]}>{citizenship}</Text>
						</View>
					)}
				</View>
				<View
					style={[
						grdCell,
						BB,
						BR,
						{
							width: 50,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						},
					]}
				>
					{citizenshipAddress && <CheckBox />}
				</View>
				<View
					style={[
						grdCell,
						BB,
						BR,
						{
							width: 50,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						},
					]}
				>
					{!citizenshipAddress && <CheckBox />}
				</View>
			</View>
			<View style={[grdSubHeader, { textAlign: 'center' }]}>
				<Text>Proof of Identity:</Text>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, BR]}>
					{proofOfIdentity ? (
						<Image
							style={styles.imageThumbnail}
							src={{ uri: proofOfIdentity, method: 'GET' }}
						/>
					) : (
						<Text style={[{ textAlign: 'center' }]}>
							No proof of identity provided.
						</Text>
					)}
				</View>
			</View>
		</View>
	);

	const CorporationInformation = () => (
		<View style={[grdRow]}>
			<View style={[grdCell]}>
				<View style={[grdHeader]}>
					<Text>Account Information</Text>
				</View>
				<View style={[grdRow, nM]}>
					<View style={[grdCell, BB, BL, { width: 33.33 }]}>
						<Text style={label}>First Name:</Text>
						<Text style={[text]}>{firstName}</Text>
					</View>
					<View style={[grdCell, BB, BL, { width: 33.33 }]}>
						<Text style={label}>Middle Name:</Text>
						<Text style={[text]}>{middleName}</Text>
					</View>
					<View style={[grdCell, BB, BR, BL, { width: 33.33 }]}>
						<Text style={label}>Last Name:</Text>
						<Text style={[text]}>{lastName}</Text>
					</View>
				</View>
				<View style={[grdRow, nM]}>
					<View style={[grdCell, BB, BL, { width: 33.33 }]}>
						<Text style={label}>Email Address:</Text>
						<Text style={[text]}>{email}</Text>
					</View>
					<View style={[grdCell, BB, BL, { width: 33.33 }]}>
						<Text style={label}>Phone Number:</Text>
						<Text style={[text]}>{phoneNumber}</Text>
					</View>
					<View style={[grdCell, BB, BR, BL, { width: 33.33 }]}>
						<Text style={label}>Birth Date:</Text>
						<Text style={[text]}>{dateFormat(birthDate)}</Text>
					</View>
				</View>
				<View style={[grdRow, nM]}>
					<View style={[grdCell, BB, BL, { width: 10 }]}>
						<Text style={label}>Account Type:</Text>
						<Text style={[text]}>{accountType}</Text>
					</View>
					<View style={[grdCell, BB, BL, { width: 200 }]}>
						<Text style={label}>Company Name:</Text>
						<Text style={[text]}>{companyName}</Text>
					</View>
					<View style={[grdCell, BB, BL, BR, { width: 106 }]}>
						<Text style={label}>EIN or Tax ID:</Text>
						<Text style={[text]}>{taxId}</Text>
					</View>
				</View>
				<View style={[grdRow, nM]}>
					<View style={[grdCell, BL, BB, { width: 50 }]}>
						<Text style={label}>Address:</Text>
						<Text style={[text]}>
							{address1} {address2}
						</Text>
					</View>
					<View style={[grdCell, BB, BL, BR, { width: 50 }]}>
						<Text style={label}>City:</Text>
						<Text style={[text]}>{city}</Text>
					</View>
				</View>
				<View style={[grdRow, nM]}>
					<View style={[grdCell, BL, { width: 33.33 }]}>
						<Text style={label}>State:</Text>
						<Text style={[text]}>{state}</Text>
					</View>
					<View style={[grdCell, BL, { width: 33.33 }]}>
						<Text style={label}>Zip Code:</Text>
						<Text style={[text]}>{zipCode}</Text>
					</View>
					<View style={[grdCell, BL, BR, { width: 33.33 }]}>
						<Text style={label}>Country:</Text>
						<Text style={[text]}>{country}</Text>
					</View>
				</View>
				<View style={[grdSubHeader, { textAlign: 'center' }]}>
					<Text>Proof of Address:</Text>
				</View>
				<View style={[grdRow, nM]}>
					<View style={[grdCell, BB, BL, BR]}>
						{proofOfAddress ? (
							<Image
								style={styles.imageThumbnail}
								src={{ uri: proofOfAddress, method: 'GET' }}
							/>
						) : (
							<Text style={[{ textAlign: 'center' }]}>
								No proof of address provided.
							</Text>
						)}
					</View>
				</View>
			</View>
		</View>
	);

	const PrimaryOfficerInformation = () => (
		<View style={[grdCell]}>
			<View style={[grdHeader]}>
				<Text>
					Primary{' '}
					{primaryOfficer && primaryOwner
						? 'Officer/Owner'
						: primaryOwner
							? 'Owner'
							: 'Officer'}{' '}
					Information
				</Text>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, { width: 33.33 }]}>
					<Text style={label}>First Name:</Text>
					<Text style={[text]}>{primaryOfficerFirstName}</Text>
				</View>
				<View style={[grdCell, BB, BL, { width: 33.33 }]}>
					<Text style={label}>Middle Name:</Text>
					<Text style={[text]}>{primaryOfficerMiddleName}</Text>
				</View>
				<View style={[grdCell, BB, BL, BR, { width: 33.33 }]}>
					<Text style={label}>Last Name:</Text>
					<Text style={[text]}>{primaryOfficerLastName}</Text>
				</View>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, { width: 33.33 }]}>
					<Text style={label}>Phone Number:</Text>
					<Text style={[text]}>{primaryOfficerPhoneNumber}</Text>
				</View>
				<View style={[grdCell, BB, BL, BR, { width: 33.33 }]}>
					<Text style={label}>Type:</Text>
					<Text style={[text]}>
						{primaryOfficer ? 'Officer' : ''}
						{primaryOfficer && primaryOwner ? ', ' : ''}
						{primaryOwner ? 'Owner' : ''}
					</Text>
				</View>
				{primaryOwner && (
					<View style={[grdCell, BB, BR, { width: 33.33 }]}>
						<Text style={label}>Ownership Percentage:</Text>
						<Text style={[text]}>{ownershipPercentage} %</Text>
					</View>
				)}
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, { width: 50 }]}>
					<Text style={label}>Address:</Text>
					<Text style={[text]}>
						{primaryOfficerAddress1} {primaryOfficerAddress2}
					</Text>
				</View>
				<View style={[grdCell, BB, BL, BR, { width: 50 }]}>
					<Text style={label}>City:</Text>
					<Text style={[text]}>{primaryOfficerCity}</Text>
				</View>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BL, { width: 33.33 }]}>
					<Text style={label}>State:</Text>
					<Text style={[text]}>{primaryOfficerState}</Text>
				</View>
				<View style={[grdCell, BL, { width: 33.33 }]}>
					<Text style={label}>Zip Code:</Text>
					<Text style={[text]}>{primaryOfficerZipCode}</Text>
				</View>
				<View style={[grdCell, BL, BR, { width: 33.33 }]}>
					<Text style={label}>Country:</Text>
					<Text style={[text]}>{primaryOfficerCountry}</Text>
				</View>
			</View>
			<View style={[grdHeader]}>
				<Text>Identification</Text>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL]}>
					<Text style={label}>Identification Type:</Text>
					<Text style={[text]}>{identificationType}</Text>
				</View>
				<View style={[grdCell, BB, BL]}>
					<Text style={label}>Identification No.:</Text>
					<Text style={[text]}>{identificationNo}</Text>
				</View>
				<View style={[grdCell, BB, BL, BR]}>
					<Text style={label}>Issue Date:</Text>
					<Text style={[text]}>{dateFormat(issueDate)}</Text>
				</View>
				<View style={[grdCell, BB, BR]}>
					<Text style={label}>Expiration Date:</Text>
					<Text style={[text]}>{dateFormat(expirationDate)}</Text>
				</View>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BL, { width: 50 }]}>
					<Text style={label}>Birth Date:</Text>
					<Text style={[text]}>{dateFormat(birthDate)}</Text>
				</View>
				<View style={[grdCell, BL, BR, { width: 50 }]}>
					<Text style={label}>Social Security No.:</Text>
					<Text style={[text]}>{ssnNo}</Text>
				</View>
			</View>
			<View style={[grdSubHeader]}>
				<View style={[grdRow, nM]}>
					<View style={[grdCell, nP, { width: 250 }]}>
						<Text></Text>
					</View>
					<View style={[grdCell, nP, { width: 50, textAlign: 'center' }]}>
						<Text>Yes</Text>
					</View>
					<View style={[grdCell, nP, { width: 50, textAlign: 'center' }]}>
						<Text>No</Text>
					</View>
				</View>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, BR, { width: 250 }]}>
					<Text>My country of ID is the same as my physical address.</Text>
					{!idAddress && (
						<View style={[grdRow, nM, mT20]}>
							<Text style={[label]}>Country of Issuance: </Text>
							<Text style={[text]}>{countryOfIssuance}</Text>
						</View>
					)}
				</View>
				<View
					style={[
						grdCell,
						BB,
						BR,
						{
							width: 50,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						},
					]}
				>
					{idAddress && <CheckBox />}
				</View>
				<View
					style={[
						grdCell,
						BB,
						BR,
						{
							width: 50,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						},
					]}
				>
					{!idAddress && <CheckBox />}
				</View>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, BR, { width: 250 }]}>
					<Text>
						My country of taxation is the same as my physical address.
					</Text>
					{!taxAddress && (
						<View style={[grdRow, nM, mT20]}>
							<Text style={[label]}>Tax Country: </Text>
							<Text style={[text]}>{taxCountry}</Text>
						</View>
					)}
				</View>
				<View
					style={[
						grdCell,
						BB,
						BR,
						{
							width: 50,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						},
					]}
				>
					{taxAddress && <CheckBox />}
				</View>
				<View
					style={[
						grdCell,
						BB,
						BR,
						{
							width: 50,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						},
					]}
				>
					{!taxAddress && <CheckBox />}
				</View>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, BR, { width: 250 }]}>
					<Text>
						My country of citizenship is the same as my physical address.
					</Text>
					{!citizenshipAddress && (
						<View style={[grdRow, nM, mT20]}>
							<Text style={[label]}>Citizenship: </Text>
							<Text style={[text]}>{citizenship}</Text>
						</View>
					)}
				</View>
				<View
					style={[
						grdCell,
						BB,
						BR,
						{
							width: 50,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						},
					]}
				>
					{citizenshipAddress && <CheckBox />}
				</View>
				<View
					style={[
						grdCell,
						BB,
						BR,
						{
							width: 50,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						},
					]}
				>
					{!citizenshipAddress && <CheckBox />}
				</View>
			</View>
			<View style={[grdSubHeader, { textAlign: 'center' }]}>
				<Text>Proof of Identity:</Text>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, BR]}>
					{proofOfIdentity ? (
						<Image
							style={styles.imageThumbnail}
							src={{ uri: proofOfIdentity, method: 'GET' }}
						/>
					) : (
						<Text style={[{ textAlign: 'center' }]}>
							No proof of identity provided.
						</Text>
					)}
				</View>
			</View>
		</View>
	);

	const CoOfficerInformation = () =>
		coOfficerOwnerData.map((data, index) => (
			<Page key={index} size="A4" style={wrapper}>
				<View style={[grdCell]}>
					<View style={[grdHeader]}>
						<Text>
							Co{' '}
							{data.primaryOfficer && data.primaryOwner
								? 'Officer/Owner'
								: data.primaryOwner
									? 'Owner'
									: 'Officer'}{' '}
							Information
						</Text>
					</View>
					<View style={[grdRow, nM]}>
						<View style={[grdCell, BB, BL, { width: 33.33 }]}>
							<Text style={label}>First Name:</Text>
							<Text style={[text]}>{data.firstName}</Text>
						</View>
						<View style={[grdCell, BB, BL, { width: 33.33 }]}>
							<Text style={label}>Middle Name:</Text>
							<Text style={[text]}>{data.middleName}</Text>
						</View>
						<View style={[grdCell, BB, BL, BR, { width: 33.33 }]}>
							<Text style={label}>Last Name:</Text>
							<Text style={[text]}>{data.lastName}</Text>
						</View>
					</View>
					<View style={[grdRow, nM]}>
						<View style={[grdCell, BB, BL, { width: 33.33 }]}>
							<Text style={label}>Phone Number:</Text>
							<Text style={[text]}>{data.phoneNumber}</Text>
						</View>
						<View style={[grdCell, BB, BL, BR, { width: 33.33 }]}>
							<Text style={label}>Type:</Text>
							<Text style={[text]}>
								{data.primaryOfficer ? 'Officer' : ''}
								{data.primaryOfficer && data.primaryOwner ? ', ' : ''}
								{data.primaryOwner ? 'Owner' : ''}
							</Text>
						</View>
						{data.primaryOwner && (
							<View style={[grdCell, BB, BR, { width: 33.33 }]}>
								<Text style={label}>Ownership Percentage:</Text>
								<Text style={[text]}>{data.ownershipPercentage} %</Text>
							</View>
						)}
					</View>
					<View style={[grdRow, nM]}>
						<View style={[grdCell, BB, BL, { width: 50 }]}>
							<Text style={label}>Address:</Text>
							<Text style={[text]}>
								{data.address1} {data.address2}
							</Text>
						</View>
						<View style={[grdCell, BB, BL, BR, { width: 50 }]}>
							<Text style={label}>City:</Text>
							<Text style={[text]}>{data.city}</Text>
						</View>
					</View>
					<View style={[grdRow, nM]}>
						<View style={[grdCell, BB, BL, { width: 33.33 }]}>
							<Text style={label}>State:</Text>
							<Text style={[text]}>{data.state}</Text>
						</View>
						<View style={[grdCell, BB, BL, { width: 33.33 }]}>
							<Text style={label}>Zip Code:</Text>
							<Text style={[text]}>{data.zipCode}</Text>
						</View>
						<View style={[grdCell, BB, BL, BR, { width: 33.33 }]}>
							<Text style={label}>Country:</Text>
							<Text style={[text]}>{data.country}</Text>
						</View>
					</View>
					{data.proofOfIdentity && (
						<View>
							<View style={[grdHeader]}>
								<Text>Identification</Text>
							</View>
							<View style={[grdRow, nM]}>
								<View style={[grdCell, BB, BL]}>
									<Text style={label}>Identification Type:</Text>
									<Text style={[text]}>{data.identificationType}</Text>
								</View>
								<View style={[grdCell, BB, BL]}>
									<Text style={label}>Identification No.:</Text>
									<Text style={[text]}>{data.identificationNo}</Text>
								</View>
								<View style={[grdCell, BB, BL, BR]}>
									<Text style={label}>Issue Date:</Text>
									<Text style={[text]}>{dateFormat(data.issueDate)}</Text>
								</View>
								<View style={[grdCell, BB, BR]}>
									<Text style={label}>Expiration Date:</Text>
									<Text style={[text]}>{dateFormat(data.expirationDate)}</Text>
								</View>
							</View>
							<View style={[grdRow, nM]}>
								<View style={[grdCell, BL, { width: 50 }]}>
									<Text style={label}>Birth Date:</Text>
									<Text style={[text]}>{dateFormat(data.birthDate)}</Text>
								</View>
								<View style={[grdCell, BL, BR, { width: 50 }]}>
									<Text style={label}>Social Security No.:</Text>
									<Text style={[text]}>{data.ssnNo}</Text>
								</View>
							</View>
							<View style={[grdSubHeader]}>
								<View style={[grdRow, nM]}>
									<View style={[grdCell, nP, { width: 250 }]}>
										<Text></Text>
									</View>
									<View
										style={[grdCell, nP, { width: 50, textAlign: 'center' }]}
									>
										<Text>Yes</Text>
									</View>
									<View
										style={[grdCell, nP, { width: 50, textAlign: 'center' }]}
									>
										<Text>No</Text>
									</View>
								</View>
							</View>
							<View style={[grdRow, nM]}>
								<View style={[grdCell, BB, BL, BR, { width: 250 }]}>
									<Text>
										My country of ID is the same as my physical address.
									</Text>
									{!data.idAddress && (
										<View style={[grdRow, nM, mT20]}>
											<Text style={[label]}>Country of Issuance: </Text>
											<Text style={[text]}>{data.countryOfIssuance}</Text>
										</View>
									)}
								</View>
								<View
									style={[
										grdCell,
										BB,
										BR,
										{
											width: 50,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										},
									]}
								>
									{data.idAddress && <CheckBox />}
								</View>
								<View
									style={[
										grdCell,
										BB,
										BR,
										{
											width: 50,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										},
									]}
								>
									{!data.idAddress && <CheckBox />}
								</View>
							</View>
							<View style={[grdRow, nM]}>
								<View style={[grdCell, BB, BL, BR, { width: 250 }]}>
									<Text>
										My country of taxation is the same as my physical address.
									</Text>
									{!data.taxAddress && (
										<View style={[grdRow, nM, mT20]}>
											<Text style={[label]}>Tax Country: </Text>
											<Text style={[text]}>{data.taxCountry}</Text>
										</View>
									)}
								</View>
								<View
									style={[
										grdCell,
										BB,
										BR,
										{
											width: 50,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										},
									]}
								>
									{data.taxAddress && <CheckBox />}
								</View>
								<View
									style={[
										grdCell,
										BB,
										BR,
										{
											width: 50,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										},
									]}
								>
									{!data.taxAddress && <CheckBox />}
								</View>
							</View>
							<View style={[grdRow, nM]}>
								<View style={[grdCell, BB, BL, BR, { width: 250 }]}>
									<Text>
										My country of citizenship is the same as my physical
										address.
									</Text>
									{!data.citizenshipAddress && (
										<View style={[grdRow, nM, mT20]}>
											<Text style={[label]}>Citizenship: </Text>
											<Text style={[text]}>{data.citizenship}</Text>
										</View>
									)}
								</View>
								<View
									style={[
										grdCell,
										BB,
										BR,
										{
											width: 50,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										},
									]}
								>
									{data.citizenshipAddress && <CheckBox />}
								</View>
								<View
									style={[
										grdCell,
										BB,
										BR,
										{
											width: 50,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										},
									]}
								>
									{!data.citizenshipAddress && <CheckBox />}
								</View>
							</View>
							<View style={[grdSubHeader, { textAlign: 'center' }]}>
								<Text>Proof of Identity:</Text>
							</View>
							<View style={[grdRow, nM]}>
								<View style={[grdCell, BB, BL, BR]}>
									{data.proofOfIdentity ? (
										<Image
											style={styles.imageThumbnail}
											src={{
												uri: data.proofOfIdentity,
												method: 'GET',
											}}
										/>
									) : (
										<Text style={[{ textAlign: 'center' }]}>
											No proof of identity provided.
										</Text>
									)}
								</View>
							</View>
						</View>
					)}
				</View>
			</Page>
		));

	const TrustedContact = () => (
		<View style={[grdCell]}>
			<View style={[grdHeader]}>
				<Text>Trusted Contact</Text>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, BR]}>
					<Text style={label}>Complete Name</Text>
					<Text
						style={[text]}
					>{`${tcFirstName} ${tcMiddleName} ${tcLastName}`}</Text>
				</View>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, BR]}>
					<Text style={label}>Complete Address</Text>
					<Text
						style={[text]}
					>{`${trustedContactAddress1}, ${tcCity}, ${tcState}, ${tcCountry}`}</Text>
				</View>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, BR]}>
					<Text style={label}>Relationship</Text>
					<Text style={[text]}>{tcRelationShip}</Text>
				</View>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, BR]}>
					<Text style={label}>Birth Date</Text>
					<Text style={[text]}>{adjustDate(tcBirthDate, 1)}</Text>
				</View>
				<View style={[grdCell, BB, BL, BR]}>
					<Text style={label}>Identification Type</Text>
					<Text style={[text]}>{tcType}</Text>
				</View>
				<View style={[grdCell, BB, BL, BR]}>
					<Text style={label}>ID</Text>
					<Text style={[text]}>{tcId}</Text>
				</View>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, BR]}>
					<Text style={label}>Email</Text>
					<Text style={[text]}>{tcEmail}</Text>
				</View>
				<View style={[grdCell, BB, BL, BR]}>
					<Text style={label}>Contact Number</Text>
					<Text style={[text]}>{tcPhoneNumber}</Text>
				</View>
			</View>
		</View>
	);

	const EmploymentInfo = () => (
		<View style={[grdCell]}>
			<View style={[grdHeader]}>
				<Text>Employment</Text>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BT, BB, BL, BR]}>
					<Text style={label}>Employment Status:</Text>
					<Text style={[text]}>{employmentStatus}</Text>
				</View>
			</View>
			{['Employed', 'Self-Employed'].includes(employmentStatus) && (
				<View style={[grdRow, nM]}>
					<View style={[grdCell, BB, BL]}>
						<Text style={label}>
							{employmentStatus === 'Employed'
								? 'Employer Name:'
								: 'Doing Bussiness As:'}
						</Text>
						<Text style={[text]}>
							{employmentStatus === 'Employed'
								? employerName
								: doingBussinessAs}
						</Text>
					</View>
					<View style={[grdCell, BB, BL, BR]}>
						<Text style={label}>Occupation:</Text>
						<Text style={[text]}>{occupation}</Text>
					</View>
				</View>
			)}
		</View>
	);

	const AssociationInfo = () => (
		<View style={[grdCell]}>
			<View style={[grdHeader]}>
				<View style={[grdRow, nM]}>
					<View style={[grdCell, nP, { width: 250 }]}>
						<Text>Association</Text>
					</View>
					<View style={[grdCell, nP, { width: 50, textAlign: 'center' }]}>
						<Text>Yes</Text>
					</View>
					<View style={[grdCell, nP, { width: 50, textAlign: 'center' }]}>
						<Text>No</Text>
					</View>
				</View>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, BR, { width: 250 }]}>
					<Text>
						Are you or a family member a senior executive or 10% or greater
						shareholder of a publicly traded company?
					</Text>
					{isExecutive === 'Yes' && (
						<View>
							<Text style={[label, mT20]}>Stock Symbol:</Text>
							<Text style={[text]}>{stockSymbol}</Text>
						</View>
					)}
				</View>
				<View
					style={[
						grdCell,
						BB,
						BR,
						{
							width: 50,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						},
					]}
				>
					{isExecutive === 'Yes' && <CheckBox />}
				</View>
				<View
					style={[
						grdCell,
						BB,
						BR,
						{
							width: 50,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						},
					]}
				>
					{isExecutive === 'No' && <CheckBox />}
				</View>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, BR, { width: 250 }]}>
					<Text>
						Are you or a family member employed with a member firm of a stock
						exchange or Finra registered broker?
					</Text>
					{isRegisteredBroker === 'Yes' && (
						<View>
							<Text style={[label, mT20]}>Name of the Company:</Text>
							<Text style={[text]}>{associationData.companyName}</Text>
							<Text style={[label, mT10]}>Name of the Affiliated Person:</Text>
							<Text style={[text]}>{affiliatedPerson}</Text>
							<Text style={[label, mT10]}>
								Postion of the Affiliated Person:
							</Text>
							<Text style={[text]}>{affiliatedPersonPosition}</Text>
						</View>
					)}
				</View>
				<View
					style={[
						grdCell,
						BB,
						BR,
						{
							width: 50,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						},
					]}
				>
					{isRegisteredBroker === 'Yes' && <CheckBox />}
				</View>
				<View
					style={[
						grdCell,
						BB,
						BR,
						{
							width: 50,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						},
					]}
				>
					{isRegisteredBroker === 'No' && <CheckBox />}
				</View>
			</View>
		</View>
	);

	const TradingInfo = () => (
		<View style={[grdCell]}>
			<View style={[grdHeader]}>
				<Text>Trading</Text>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, BR]}>
					<Text style={label}>Margin Type:</Text>
					<Text style={[text]}>{marginType}</Text>
				</View>
				<View style={[grdCell, BB, BR]}>
					<Text style={label}>Trading Privileges:</Text>
					<Text style={[text]}>
						{stocks ? 'Stocks,' : ''}
						{options ? ', Options' : ''}
					</Text>
				</View>
			</View>
		</View>
	);

	const ObjectiveInfo = () => (
		<View style={[grdCell]}>
			<View style={[grdHeader]}>
				<Text>Objective</Text>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, BR]}>
					<Text style={label}>Investment Objective:</Text>
					<Text style={[text]}>{investmentObjective}</Text>
				</View>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, { width: 50 }]}>
					<Text style={label}>Annual Income:</Text>
					<Text style={[text]}>{annualIncome}</Text>
				</View>
				<View style={[grdCell, BB, BL, BR, { width: 50 }]}>
					<Text style={label}>Liquid Net Worth:</Text>
					<Text style={[text]}>{liquidNetWorth}</Text>
				</View>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, { width: 50 }]}>
					<Text style={label}>What are you using to fund this account?</Text>
					<Text style={[text]}>{fund}</Text>
				</View>
				<View style={[grdCell, BB, BL, BR, { width: 50 }]}>
					<Text style={label}>Risk Tolerance:</Text>
					<Text style={[text]}>{riskTolerance}</Text>
				</View>
			</View>
			<View style={[grdRow, nM]}>
				{fund === 'Others' && (
					<View style={[grdCell, BB, BL, BR]}>
						<Text style={label}>Other</Text>
						<Text style={[text]}>{other}</Text>
					</View>
				)}
			</View>
		</View>
	);

	const AgreementInfo = () => (
		<View style={[grdCell]}>
			<View style={[grdHeader]}>
				<View style={[grdRow, nM]}>
					<View style={[grdCell, nP, { width: 250 }]}>
						<Text>Signed Disclosures</Text>
					</View>
					<View style={[grdCell, nP, { width: 50, textAlign: 'center' }]}>
						<Text>Yes</Text>
					</View>
					<View style={[grdCell, nP, { width: 50, textAlign: 'center' }]}>
						<Text>No</Text>
					</View>
				</View>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, BR, { width: 250 }]}>
					<Text>
						Margin Agreement
					</Text>
				</View>
				<View
					style={[
						grdCell,
						BB,
						BR,
						{
							width: 50,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						},
					]}
				>
					{readMarginAgreement === true && <CheckBox />}
				</View>
				<View
					style={[
						grdCell,
						BB,
						BR,
						{
							width: 50,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						},
					]}
				>
					{readMarginAgreement === false && <CheckBox />}
				</View>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, BR, { width: 250 }]}>
					<Text>
						Margin Disclosure Statement
					</Text>
				</View>
				<View
					style={[
						grdCell,
						BB,
						BR,
						{
							width: 50,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						},
					]}
				>
					{readMarginDisclosureStatement === true && <CheckBox />}
				</View>
				<View
					style={[
						grdCell,
						BB,
						BR,
						{
							width: 50,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						},
					]}
				>
					{readMarginDisclosureStatement === false && <CheckBox />}
				</View>
			</View>
			<View style={[grdRow, nM]}>
				<View style={[grdCell, BB, BL, BR, { width: 250 }]}>
					<Text>
						Customer Account Agreement
					</Text>
				</View>
				<View
					style={[
						grdCell,
						BB,
						BR,
						{
							width: 50,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						},
					]}
				>
					{acceptCustomerAgreement === true && <CheckBox />}
				</View>
				<View
					style={[
						grdCell,
						BB,
						BR,
						{
							width: 50,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						},
					]}
				>
					{acceptCustomerAgreement === false && <CheckBox />}
				</View>
			</View>
			{dynamicDisclosures?.map((disclosure, index) => (
				<View break={index === 7} style={[grdRow, nM]}>
					<View style={[grdCell, BB, BL, BR, { width: 250 }, index === 7 && BT]}>
						<Text>
							{disclosure.title}
						</Text>
					</View>
					<View
						style={[
							grdCell,
							BB,
							BR,
							{
								width: 50,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							},
							index === 7 ? BT : null,
						]}
					>
						{disclosure.checked === true && <CheckBox />}
					</View>
					<View
						style={[
							grdCell,
							BB,
							BR,
							{
								width: 50,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							},
							index === 7 ? BT : null,
						]}
					>
						{disclosure.checked === false && <CheckBox />}
					</View>
				</View>
			))}
		</View>
	);

	const ESignature = () => (
		<View
			style={[
				grdRow,
				nM,
				{
					marginTop: 50,
					justifyContent: 'center',
					alignItems: 'flex-end',
				},
			]}
		>
			<View style={[centerVH, { margin: '0px 25px' }]}>
				<Text
					style={{
						padding: '5px 5px',
					}}
				>
					{eSignatureData.primaryApplicant}
				</Text>
				<Text
					style={{
						padding: '5px 30px',
						borderBottom: '1px solid black',
					}}
				>
					{firstName} {middleName} {lastName}
				</Text>
				<Text style={{ marginTop: 5 }}>Primary Applicant Signature</Text>
			</View>
			{accountType === 'J' && (
				<View style={[centerVH, { margin: '0px 25px' }]}>
					<Text
						style={{
							padding: '5px 5px',
						}}
					>
						{eSignatureData.coApplicant}
					</Text>
					<Text
						style={{
							padding: '5px 30px',
							borderBottom: '1px solid black',
						}}
					>
						{coFirstName} {coMiddleName} {coLastName}
					</Text>
					<Text style={{ marginTop: 5 }}>Co-Applicant Signature</Text>
				</View>
			)}
		</View>
	);

	const [mounted, setMounted] = React.useState(false);
	const [accountNo, setAccountNo] = React.useState('');
	const [correspondent, setCorrespondent] = React.useState('');
	let brokerName = 'Client'
	let brokerAddress = 'Address'
	let brokerCity = 'City'
	let brokerState = 'State'
	let brokerZip = 'Zip'

	const getInstance = async () => {

	};

	const init = async () => {
	

		setAccountNo(accountData.accountNo);
		setCorrespondent(accountData.correspondent);
	};

	React.useEffect(() => {
		if (!mounted) {
			init();
		}
		return () => {
			setMounted(true);
		};
		// eslint-disable-next-line
	}, [mounted]);

	React.useEffect(async () => {
		try {
			let url = window.location.origin
			await getCorrespondentByUrl(url).then((result) => {
				if (result.correspondent.brokerName !== '') {
					brokerName = result.correspondent.brokerName
				} else {
					brokerName = 'Client'
				}
			});

			//get correspondent
			const serverCorrespondent = await getCorrespondentByUrl(url);
			brokerAddress = serverCorrespondent.correspondent.address ? serverCorrespondent.correspondent.address : 'Address';
			brokerCity = serverCorrespondent.correspondent.city ? serverCorrespondent.correspondent.city : 'City';
			brokerState = serverCorrespondent.correspondent.state ? serverCorrespondent.correspondent.state : 'State';
			brokerZip = serverCorrespondent.correspondent.zip ? serverCorrespondent.correspondent.zip : 'Zip';

		} catch (error) {
			console.error(error)
			brokerName = 'Client'
		}
		const document = (
			<Document
				title={`${brokerName} Trading Application Form`}
				author="Software Algo Solutions"
				subject="Trading Form"
				keywords="Trading"
				producer="Software Algo Solutions"
				pdfVersion="1"
				creator="Software Algo Solutions"
				name="Software Algo Solutions"
				fileName="Software Algo Solution"
			>
				<Page size="A4" style={wrapper}>
					<View style={grdCell}>
						<View>
							<Logo />
							<Text style={[text2, { marginBottom: 3, fontSize: 9, textAlign: 'right' }]}>
								{brokerName}
							</Text>
							<Text style={[text2, { marginBottom: 3, fontSize: 9, textAlign: 'right' }]}>
								{brokerAddress}, {brokerCity}
							</Text>
							<Text style={[text2, { marginBottom: 5, fontSize: 9, textAlign: 'right' }]}>
								{brokerState}, {brokerZip}
							</Text>
							<Text style={[text2, { marginBottom: 10, fontSize: 10, textAlign: 'right' }]}>
								Member SIPC & FINRA
							</Text>
						</View>
						<View>
							<Text style={[text2, { marginBottom: 10, fontSize: 12 }]}>
								New Account Application
							</Text>
							<Text style={[text, { marginBottom: 5, fontSize: 12 }]}>
								Account:{' '}
								{accountData.correspondent
									? accountData.correspondent
									: correspondent}
								-{accountData.accountNo ? accountData.accountNo : accountNo}                          Account Title: {firstName ? firstName : ''} {lastName ? lastName : ''}
							</Text>
						</View>
						{accountType !== 'C' ? (
							<View>
								<AccountInformation />
								{accountType === 'J' && <CoApplicantInformation />}
							</View>
						) : (
							<View>
								<CorporationInformation />
							</View>
						)}
					</View>
				</Page>
				{accountType === 'C' && (
					<Page size="A4" style={wrapper}>
						<PrimaryOfficerInformation />
					</Page>
				)}
				{accountType === 'C' && <CoOfficerInformation />}
				{['I', 'J'].includes(accountType) && (
					<Page size="A4" style={wrapper}>
						<IdentityInformation />
					</Page>
				)}
				<Page size="A4" style={wrapper}>
					{isTrustedContact === 'Yes' && (
						<View>
							<TrustedContact />
						</View>
					)}
					{accountType !== 'C' && (
						<View>
							<EmploymentInfo />
						</View>
					)}
					<View>
						<AssociationInfo />
					</View>
					<View>
						<TradingInfo />
					</View>
					<View>
						<ObjectiveInfo />
					</View>
					<View>
						<AgreementInfo />
					</View>
					<ESignature />
				</Page>
			</Document>
		);

		if (props?.onboardingAttachment) {
			const docToOnboard = { ...document };
			const onboarding = pdf(docToOnboard).toBlob();

			props?.handleDocumentChange(document);

			onboarding.then(function (result) {
				const file = new File(
					[result],
					firstName + ' ' + lastName + ' Onboarding.pdf',
					{
						type: result.type,
					}
				);

				let newFiles = [file];

				props?.setAttachments(newFiles);
			});
		}
	}, [eSignatureData]);

	return (
		<div id="pdf">
			<PDFViewer className={classes.pdfViewerWrapper}>
				{props?.document}
			</PDFViewer>
		</div>
	);
};

export default PDFApplicationForm;
