import { geocodeByAddress } from 'react-places-autocomplete';

export const GetAddress = async (value) => {
  try {
    const results = await geocodeByAddress(value);
    const components = results[0].address_components;

    let address = {};
    address.state = GetValueBytype(components, 'administrative_area_level_1');
    address.city = GetValueBytype(components, 'locality');
    address.zipCode = GetValueBytype(components, 'postal_code');
    address.address1 =
      GetValueBytype(components, 'street_number') === undefined ||
      GetValueBytype(components, 'route') === undefined
        ? value
        : GetValueBytype(components, 'street_number') +
          ' ' +
          GetValueBytype(components, 'route');
    address.address2 = '';
    address.country = GetValueBytype(components, 'country');

    return address;
  } catch (error) {
    alert(error);
  }
};

const GetValueBytype = (components, type) => {
  const addrr = components.filter((item) => {
    return item?.types[0] === type;
  });
  // if (addrr[0]?.types[0] === "country") {
  //         return addrr[0]?.long_name;
  //     } else {
  //         return addrr[0]?.short_name;
  // }
  return addrr[0]?.short_name;
};
