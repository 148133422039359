// IN COLUMN SETTINGS
//  {
//   name: 'sdDebitCashBalance',
//   label: 'SD Debit Cash Balance',
//   type: 'quantity', //custom quantity/amount/percentage/date/dateTime
//   addFooter: true, //custom true/false
// },

//pending: filter retain values

import {
  FormGroup,
  TextField,
  Checkbox,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import MUIDataTable from 'mui-datatables';
import { MuiThemeProvider } from '@material-ui/core/styles';
import tableTheme from './TableStyle';
import TableBodyFooter from './TableBodyFooter';
import CsvDownload from './CsvDownload';
import ServerSideTableBodyFooter from './ServerSideTableBodyFooter';
import AutoCompleteFilter from './../AutoComplete/AutoCompleteFilter';

import {
  Save as SaveIcon,
  CheckCircle as CheckCircleIcon,
} from '@material-ui/icons';

import {
  getArrayKeyIndex,
  getArrayIndex,
  moveArrayIndex,
  tableDateTimeSortCompare,
  tableDateSortCompare,
  tableAmountSortCompare,
  tablePercentageSortCompare,
  tableQuantitySortCompare,
} from '../../lib/utils/utils';
import {
  formatPbDate,
  formatCurrency,
  formatCurrencySymbol,
  formatPercentage,
  formatQty,
  formatStatus,
  formatNote,
  twelveHourTimeFormat,
  formatDuration,
} from '../../lib/fmt2';
import {
  protoTimeSpanObjectToString,
  protoDateObjectToDate,
  protoDatTimeObjectToDate,
  dateStringToDate,
  stringToDateTime,
} from '../../services/ConvertService';
import authSvc from '../../services/AuthService';
import {
  updateTableSettings,
  readTableSettings,
  createTableSettings,
} from '../../services/TableSettingsService';

export const columnType = {
  date: 'date',
  dateTime: 'dateTime',
  quantity: 'quantity',
  percentage: 'percentage',
  amount: 'amount',
  amount6Decimal: 'amount6Decimal',
  amountBalance: 'amountBalance',
  text: 'text',
  buttons: 'buttons',
  bool: 'bool',
  status: 'status',
  note: 'note',
  time: 'time',
  duration: 'duration',
};

// Export to temporary fix the row executable buttons
// having different data index when being filter
// this happens because rows have different index/data when filtered
export let tempFilteredRows = [];

export default function Table({
  data: rows,
  columns,
  title,
  options,
  footerData,
  onSearch,
  pagination,
  setPagination,
  getCsvData,
  additionalCell,
  handleCustomSearch, //To Properly set serverside custom searching on tables
  modalTable, //for now, this is only applicable for activity page since we're reusing table component with caching
}) {
  const [filterList, setFilterList] = useState({});
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [serverSideSearch, setServerSideSearch] = useState('');
  const [visibleColumns, setVisibleColumns] = React.useState([]);
  const sortedColumns = sortColumnSettings([...columns]);

  const filterRows = () => {
    const filterKeys = Object.keys(filterList);
    if (!filterKeys.length) {
      return rows;
    }

    const filtered = rows.filter((r) => {
      let ok = true;
      filterKeys.forEach((key) => {
        if (filterList[key] !== r[key]) {
          ok = false;
          return false;
        }
      });
      return ok;
    });

    return filtered;
  };

  tempFilteredRows = filterRows();

  // FOR SERVER SIDE CUSTOM SEARCH
  if (serverSideSearch && options.serverSide && tempFilteredRows.length) {
    tempFilteredRows = serverSideCustomSearch(
      serverSideSearch,
      tempFilteredRows,
      visibleColumns,
      sortedColumns
    );
    //handleCustomSearch is a function to properly set filtered data by custom search.
    handleCustomSearch &&
      handleCustomSearch(tempFilteredRows, serverSideSearch);
  }
  const [rowsSelected] = React.useState([]);
  const [columnOrder, setColumnOrder] = React.useState(
    columns.map((a, index) => {
      return index;
    })
  );

  const user = authSvc.getCurrentUser();
  const params = {
    title: title,
    userId: user.UserId,
    visibleColumns: visibleColumns,
    columnOrder: columnOrder,
  };

  const [openCsvDownload, setOpenCsvDownload] = React.useState(false);
  const columnSettings = getColumnSettings(
    visibleColumns,
    tempFilteredRows,
    options,
    sortedColumns
  );

  const [clientSideRowsPerPage, setClientSideRowsPerPage] = React.useState(100);

  const optionSettings = getOptionSettings(
    options,
    columns,
    tempFilteredRows,
    title,
    columnOrder,
    setColumnOrder,
    visibleColumns,
    setVisibleColumns,
    rowsSelected,
    params,
    footerData,
    pagination,
    setPagination,
    setOpenCsvDownload,
    setFilterList,
    additionalCell,
    setServerSideSearch,
    clientSideRowsPerPage,
    setClientSideRowsPerPage,
    isFilterActive,
    setIsFilterActive,
    columnSettings
  );

  useEffect(() => {
    if (setPagination) {
      setPagination({
        count: 0,
        rowsPerPage: 100,
        pageNo: 0,
        reload: false,
        sortName: '',
        sortDirection: '',
        filterNames: [],
        filterValues: [],
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (pagination) {
      if (pagination.reload) {
        onSearch(true, modalTable);
      }
    }
    // eslint-disable-next-line
  }, [pagination]);

  useEffect(
    () => {
      params.visibleColumns = getVisibleColumns(columns);
      params.columnOrder = getColumnOrder(columns, columnSettings);

      const checkSettings = readTableSettings(params);

      checkSettings
        .then(function(res) {
          const currentColumnCount = params.columnOrder.length;
          const saveColumnCount = res.columnOrder.split(',').length;

          setVisibleColumns(
            currentColumnCount === saveColumnCount
              ? res.visibleColumns.split(',')
              : params.visibleColumns
          );
          setColumnOrder(
            currentColumnCount === saveColumnCount
              ? res.columnOrder.split(',')
              : params.columnOrder
          );
        })
        .catch((err) => {
          // Create table for settings when none is found
          const checkSettings = createTableSettings(params);
          checkSettings
            .then(function(res) {
              setVisibleColumns(res.tableSettings.visibleColumns.split(','));
              setColumnOrder(res.tableSettings.columnOrder.split(','));
            })
            .catch((err) => {
              console.error(err);
              return;
            });
          console.error(err);
          return;
        });
    },
    // eslint-disable-next-line
    []
  );

  const getFormattedCsvData = async () => {
    // const types = getColumnTypes(cols, columns);
    const raw = await getCsvData();
    if (!raw.length) {
      return [];
    }

    let keys = Object.keys(raw[0]);
    moveArrayIndex(keys, getArrayIndex(keys, 'accountId'), 0);
    moveArrayIndex(keys, getArrayIndex(keys, 'trnsId'), 0);

    let columnTypes = {};
    keys.forEach((key) => {
      const set = getColumnSettingsByName(columnSettings, key);
      columnTypes[key] = set ? set.type : undefined;
    });

    const formatted = raw.map((data) => {
      let r = {};
      keys.forEach((key) => {
        //Do not format price to 2 digits when downloading has to be 6 digits for real value
        if (['price'].includes(key)) {
          r[key] = getColumnValue(columnType.amount6Decimal, data[key]);
        } else {
          r[key] = getColumnValue(columnTypes[key], data[key]);
        }
      });

      return r;
    });

    return formatted;
  };

  return (
    <React.Fragment>
      {openCsvDownload && (
        <CsvDownload
          getData={getFormattedCsvData}
          filename={getCsvFileName(title)}
          open={openCsvDownload}
          onClose={() => {
            setOpenCsvDownload(false);
          }}
        />
      )}
      <MuiThemeProvider theme={tableTheme(title)}>
        <MUIDataTable
          title={title}
          data={tempFilteredRows}
          columns={columnSettings}
          options={optionSettings}
          components={{
            Checkbox: CustomCheckbox,
          }}
        />
      </MuiThemeProvider>
    </React.Fragment>
  );
}

const CustomCheckbox = (props) => {
  const bgColor =
    props['data-description'] === 'row-select-header' ? '' : 'whitesmoke';

  return (
    <Checkbox
      style={{
        backgroundColor: bgColor,
      }}
      {...props}
    />
  );
};

const getColumnSettings = (visibleColumns, rows, options, sortedColumns) => {
  return sortedColumns.map((col) => {
    col.options = col.options || {};

    //visibility state
    if (visibleColumns.includes(col.name)) {
      col.options.display = true;
    } else if (visibleColumns.length) {
      col.options.display = false;
    }
    switch (col.type) {
      case columnType.amountBalance:
        col.options.customBodyRenderLite = (dataIndex) => {
          const value = rows[dataIndex][col.name];
          const currency = col.currency;
          const formattedValue = formatCurrencySymbol(value, currency);
          return (
            <div align="right" style={{ color: value < 0 && '#f44336' }}>
              {formattedValue}
            </div>
          );
        };
        break;
      case columnType.date:
        if (!options.serverSide) {
          col.options.filterType = 'custom';
          col.options.filterList = [];
          col.options.customFilterListOptions = getCustomFilterListOptions(col);
          col.options.filterOptions = {
            names: [],
            logic(stringValue, filters) {
              const from = dateStringToDate(filters[0]);
              const to = dateStringToDate(filters[1]);
              const value = protoDateObjectToDate(stringValue);

              if (from && to) {
                return !(value >= from && value <= to);
              } else if (from) {
                return !(value >= from);
              } else if (to) {
                return !(value <= to);
              }

              return false;
            },
            display: (filterList, onChange, index, column) => {
              return dateRangeTemplate(
                filterList,
                onChange,
                index,
                column,
                col,
                'date'
              );
            },
          };
        } else {
          col.options.filter = false;
        }
        col.options.filter = false;
        col.options.customBodyRenderLite = (dataIndex) => {
          return getColumnValue(col.type, rows[dataIndex][col.name]);
        };

        col.options.sortCompare = tableDateSortCompare;
        break;
      case columnType.dateTime:
        if (!options.serverSide) {
          col.options.filterType = 'custom';
          col.options.filterList = [];
          col.options.customFilterListOptions = getCustomFilterListOptions(col);
          col.options.filterOptions = {
            fullWidth: true,
            names: [],
            logic(stringValue, filters) {
              const from = stringToDateTime(filters[0]);
              const to = stringToDateTime(filters[1]);
              const value = protoDatTimeObjectToDate(stringValue);

              if (from && to) {
                return !(value >= from && value <= to);
              } else if (from) {
                return !(value >= from);
              } else if (to) {
                return !(value <= to);
              }

              return false;
            },
            display: (filterList, onChange, index, column) => {
              return dateRangeTemplate(
                filterList,
                onChange,
                index,
                column,
                col,
                'datetime-local'
              );
            },
          };
        } else {
          col.options.filter = false;
        }
        col.options.filter = false;
        if (!col.options.customBodyRenderLite && rows?.length) {
          col.options.customBodyRenderLite = (dataIndex) => {
            return getColumnValue(col.type, rows[dataIndex][col.name]);
          };
        }

        col.options.sortCompare = tableDateTimeSortCompare;
        break;
      case columnType.amount:
        col.options.filterType = 'custom';
        col.options.customFilterListOptions = {
          render: (v) => {
            return v.toString() !== '' ? v.toString() : [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }
            return filterList;
          },
        };
        col.options.filterOptions = {
          logic: (value, filters, row) => {
            if (filters.length > 0 && filters[0] !== '') {
              return !filters.includes(value);
            }
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            return (
              <AutoCompleteFilter
                name={column.name}
                label={column.label}
                value={filterList[index][0] || ''}
                onChange={(event) => {
                  filterList[index][0] = event.currentTarget.value;
                  onChange(filterList[index], index, column);
                }}
                options={filterData[index]}
              />
            );
          },
        };
        col.options.customBodyRenderLite = (dataIndex) => {
          const value = rows[dataIndex][col.name];
          const formattedValue = getColumnValue(col.type, value);

          return (
            <div align="right" style={{ color: value < 0 && '#f44336' }}>
              {formattedValue}
            </div>
          );
        };

        col.options.sortCompare = tableAmountSortCompare;
        break;
      case columnType.amount6Decimal:
        col.options.filterType = 'custom';
        col.options.customFilterListOptions = {
          render: (v) => {
            return v.toString() !== '' ? v.toString() : [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }
            return filterList;
          },
        };
        col.options.filterOptions = {
          logic: (value, filters, row) => {
            if (filters.length > 0 && filters[0] !== '') {
              return !filters.includes(value);
            }
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            return (
              <AutoCompleteFilter
                name={column.name}
                label={column.label}
                value={filterList[index][0] || ''}
                onChange={(event) => {
                  filterList[index][0] = event.currentTarget.value;
                  onChange(filterList[index], index, column);
                }}
                options={filterData[index]}
              />
            );
          },
        };
        col.options.customBodyRenderLite = (dataIndex) => {
          const value = rows[dataIndex][col.name];
          const formattedValue = getColumnValue(col.type, value);

          return (
            <div align="right" style={{ color: value < 0 && '#f44336' }}>
              {formattedValue}
            </div>
          );
        };

        col.options.sortCompare = tableAmountSortCompare;
        break;

      case columnType.percentage:
        col.options.filterType = 'custom';
        col.options.customFilterListOptions = {
          render: (v) => {
            return v.toString() !== '' ? v.toString() : [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }
            return filterList;
          },
        };
        col.options.filterOptions = {
          logic: (value, filters, row) => {
            if (filters.length > 0 && filters[0] !== '') {
              return !filters.includes(value);
            }
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            return (
              <AutoCompleteFilter
                name={column.name}
                label={column.label}
                value={filterList[index][0] || ''}
                onChange={(event) => {
                  filterList[index][0] = event.currentTarget.value;
                  onChange(filterList[index], index, column);
                }}
                options={filterData[index]}
              />
            );
          },
        };
        col.options.customBodyRenderLite = (dataIndex) => {
          const value = rows[dataIndex][col.name];
          const formattedValue = getColumnValue(col.type, value);

          return (
            <div align="right" style={{ color: value < 0 && '#f44336' }}>
              {formattedValue}
            </div>
          );
        };

        col.options.sortCompare = tablePercentageSortCompare;
        break;
      case columnType.quantity:
        col.options.filterType = 'custom';
        col.options.customFilterListOptions = {
          render: (v) => {
            return v.toString() !== '' ? v.toString() : [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }
            return filterList;
          },
        };
        col.options.filterOptions = {
          logic: (value, filters, row) => {
            if (filters.length > 0 && filters[0] !== '') {
              return !filters.includes(value);
            }
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            return (
              <AutoCompleteFilter
                name={column.name}
                label={column.label}
                value={filterList[index][0] || ''}
                onChange={(event) => {
                  filterList[index][0] = event.currentTarget.value;
                  onChange(filterList[index], index, column);
                }}
                options={filterData[index]}
              />
            );
          },
        };
        col.options.customBodyRenderLite = (dataIndex) => {
          const value = rows[dataIndex][col.name];
          const formattedValue = getColumnValue(col.type, value);

          return (
            <div align="right" style={{ color: value < 0 && '#f44336' }}>
              {formattedValue}
            </div>
          );
        };

        col.options.sortCompare = tableQuantitySortCompare;
        break;
      case columnType.bool:
        col.options.customBodyRenderLite = (dataIndex) => {
          return (
            <div>{rows[dataIndex][col.name] ? <CheckCircleIcon /> : null}</div>
          );
        };
        break;
      case columnType.status:
        col.options.customBodyRenderLite = (dataIndex) => {
          const value = rows[dataIndex][col.name];
          const formattedValue = getColumnValue(col.type, value);

          return <div align="right">{formattedValue}</div>;
        };
        break;
      case columnType.note:
        col.options.customBodyRenderLite = (dataIndex) => {
          const value = rows[dataIndex][col.name];
          const formattedValue = getColumnValue(col.type, value);

          return <div dangerouslySetInnerHTML={{ __html: formattedValue }} />;
        };
        break;
      case columnType.time:
        col.options.customBodyRenderLite = (dataIndex) => {
          return getColumnValue(col.type, rows[dataIndex][col.name]);
        };
        break;
      case columnType.duration:
        col.options.customBodyRenderLite = (dataIndex) => {
          return getColumnValue(col.type, rows[dataIndex][col.name]);
        };
        break;
      case columnType.buttons:
        col.options = {
          ...{
            draggable: false,
            resizable: false,
            print: false,
            searchable: false,
            filter: false,
            sort: false,
            empty: true,
            viewColumns: false,
            download: false,
          },
          ...col.options,
        };
        break;
      default:
        col.options.filterType = 'custom';
        col.options.customFilterListOptions = {
          render: (v) => {
            return v.toString() !== '' ? v.toString() : [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }
            return filterList;
          },
        };
        col.options.filterOptions = {
          logic: (value, filters, row) => {
            if (filters.length > 0 && filters[0] !== '') {
              return !filters.includes(value);
            }
            return false;
          },
          display: (filterList, onChange, index, column, filterData) => {
            return (
              <AutoCompleteFilter
                name={column.name}
                label={column.label}
                value={filterList[index][0] || ''}
                onChange={(event) => {
                  filterList[index][0] = event.currentTarget.value;
                  onChange(filterList[index], index, column);
                }}
                options={filterData[index]}
              />
            );
          },
        };
    }
    return col;
  });
};

const getCustomFilterListOptions = (col) => {
  return {
    render: (value) => {
      if (value[0] && value[1]) {
        return `From ${col.label}: ${value[0]}, To ${col.label}: ${value[1]}`;
      } else if (value[0]) {
        return `From ${col.label}: ${value[0]}`;
      } else if (value[1]) {
        return `To ${col.label}: ${value[1]}`;
      }
      return [];
    },
    update: (filterList, filterPos, index) => {
      if (filterPos === 0) {
        filterList[index].splice(filterPos, 1, '');
      } else if (filterPos === 1) {
        filterList[index].splice(filterPos, 1);
      } else if (filterPos === -1) {
        filterList[index] = [];
      }

      return filterList;
    },
  };
};

const getCsvFileName = (title) => {
  return (
    title?.replace(/ /g, '') + '_' + moment().format('MMMM Do YYYY') + '.csv'
  );
};

const dateRangeTemplate = (
  filterList,
  onChange,
  index,
  column,
  col,
  dateType
) => (
  <div>
    <FormGroup row>
      <TextField
        label={'From ' + col.label}
        type={dateType}
        InputLabelProps={{ shrink: true }}
        value={filterList[index][0] || ''}
        onChange={(event) => {
          filterList[index][0] = event.target.value;
          onChange(filterList[index], index, column);
        }}
        style={{ width: '45%', marginRight: '5%' }}
        inputProps={{
          max: filterList[index][1],
        }}
      />
      <TextField
        label={'To ' + col.label}
        type={dateType}
        InputLabelProps={{ shrink: true }}
        value={filterList[index][1] || ''}
        onChange={(event) => {
          filterList[index][1] = event.target.value;
          onChange(filterList[index], index, column);
        }}
        style={{ width: '45%' }}
        inputProps={{
          min: filterList[index][0],
        }}
      />
    </FormGroup>
  </div>
);

const getOptionSettings = (
  options,
  columns,
  rows,
  title,
  columnOrder,
  setColumnOrder,
  visibleColumns,
  setVisibleColumns,
  rowsSelected,
  params,
  footerData,
  pagination,
  setPagination,
  setOpenCsvDownload,
  setFilterList,
  additionalCell,
  setServerSideSearch,
  clientSideRowsPerPage,
  setClientSideRowsPerPage,
  isFilterActive,
  setIsFilterActive,
  columnSettings
) => {
  const footerSettings = getFooterSettings(columns);

  let defaultSettings = {
    columnOrder: columnOrder,
    filterType: 'multiselect',
    responsive: 'standard',
    download: true,
    filter: true,
    search: true,
    print: false,
    sort: true,
    viewColumns: true,
    resizableColumns: false,
    draggableColumns: {
      enabled: true,
    },
    selectableRowsHeader: true,
    selectableRows: 'multiple',
    rowsSelected: rowsSelected,
    rowsPerPage: clientSideRowsPerPage,
    rowsPerPageOptions: [25, 50, 100, 500, 1000, 15000, 30000, 100000],
    downloadOptions: {
      filename: getCsvFileName(title),
    },
    fixedHeader: true,
    fixedSelectColumn: false,
    tableBodyHeight: title !== 'Locations' ? '400px' : 'auto',
    customSort: (data, colIndex, order) => {
      return data.sort((a, b) => {
        const valueA = a.data[colIndex];
        const valueB = b.data[colIndex];
    
        // Check if the values are numbers
        if (typeof valueA === 'number' && typeof valueB === 'number') {
          // Sort numbers directly
          return (valueA - valueB) * (order === 'desc' ? -1 : 1);
        }
    
        // For other data types, perform case-insensitive locale-based comparison
        const stringA = String(valueA);
        const stringB = String(valueB);
    
        return stringA.localeCompare(stringB, undefined, {
          sensitivity: 'base',
        }) * (order === 'desc' ? -1 : 1);
      });      
    },
    onColumnOrderChange: (newColumnOrder) => {
      setColumnOrder(newColumnOrder);
    },
    customTableBodyFooterRender: function(opts) {
      return (
        <TableBodyFooter
          columnOrder={columnOrder}
          columns={opts.columns}
          columnsWithAmt={footerSettings.columnsWithAmt}
          columnsWithQty={footerSettings.columnsWithQty}
          columnsWithPercentage={footerSettings.columnsWithPercentage}
          rows={rows}
          selectableRows={options.selectableRows}
          additionalCell={additionalCell}
        ></TableBodyFooter>
      );
    },
    onViewColumnsChange: (changedColumn, action) => {
      if (action === 'add') {
        if (changedColumn === 'a-selectAll') {
          showAllColumns(columns, setVisibleColumns);
        } else {
          setVisibleColumns([...visibleColumns, changedColumn]);
        }
      } else {
        let copy = [...visibleColumns];
        let index = copy.indexOf(changedColumn);

        if (changedColumn === 'a-selectAll') {
          copy = [''];
          setVisibleColumns(copy);
        } else {
          copy.splice(index, 1);
          setVisibleColumns(copy);
        }
      }
    },
    customSearch: (searchQuery, currentRow, cols) => {
      const types = getColumnTypes(cols, columns);
      for (let i = 0; i < currentRow.length; i++) {
        const value = currentRow[i];
        if (!value) continue;
        const clearedValue = value.toString().replace(/(<([^>]+)>)/gi, '');
        if (typeof value === 'string') {
          if (clearedValue.toLowerCase().includes(searchQuery.toLowerCase()))
            return true;
        }
        const formattedValue = getColumnValue(types[i], value);
        const clearedFormattedValue = formattedValue
          .toString()
          .replace(/(<([^>]+)>)/gi, '');
        if (
          clearedFormattedValue
            .toString()
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
          return true;
      }
      return false;
    },
    onDownload: (buildHead, buildBody, cols, data) => {
      const types = getColumnTypes(cols, columns);
      const body = data.map((row) => {
        row.data = row.data.map((value, index) => {
          return getColumnValue(types[index], value);
        });
        return row;
      });
      return '\uFEFF' + buildHead(cols) + buildBody(body).replace(/"'/g, '"');
    },
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton
            onClick={() => {
              updateTableSettings(params);
            }}
          >
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
  };

  const onTableChange = (action, tableState) => {
    switch (action) {
      case 'changePage':
        setPagination({
          ...pagination,
          pageNo: tableState.page,
          reload: true,
        });
        break;
      case 'changeRowsPerPage':
        setPagination({
          ...pagination,
          rowsPerPage: tableState.rowsPerPage,
          reload: true,
        });
        break;
      case 'sort':
        setPagination({
          ...pagination,
          sortDirection: tableState.sortOrder.direction,
          sortName: tableState.sortOrder.name,
          reload: true,
        });
        break;
      case 'filterChange':
        {
          let filterNames = [];
          let filterValues = [];

          tableState.filterList.forEach((item, index) => {
            if (item.length && item.toString() !== '') {
              if (item[0] !== undefined || item[0] !== '') {
                filterNames.push(tableState.columns[index].name);
                filterValues.push(item[0]);
              }
            }
          });

          setIsFilterActive(true);

          if (
            tableState.filterList.find(
              (v) => v.length && v.toString() !== ''
            ) !== undefined
          ) {
            setIsFilterActive(true);
          } else {
            setIsFilterActive(false);
          }

          if (rows.length > 0) {
            setPagination({
              ...pagination,
              sortDirection: tableState.sortOrder.direction,
              sortName: tableState.sortOrder.name,
              filterNames: filterNames,
              filterValues: filterValues,
              reload: true,
            });
          }
        }
        break;
      case 'search':
        if (options.serverSide) {
          setIsFilterActive(true);
          setServerSideSearch(tableState.searchText);

          if (
            tableState.searchText === null ||
            tableState.searchText === '' ||
            tableState.searchText === undefined
          ) {
            setIsFilterActive(false);
          }
        }
        break;
      case 'resetFilters':
        {
          let filter = {};

          tableState.filterList.forEach((item, index) => {
            if (item.length && item.toString() !== '') {
              filter[tableState.columns[index].name] = item[''];
            }
          });

          setFilterList(filter);
          setIsFilterActive(false);
        }
        break;
      default:
    }
  };

  const onTableChangeClientSide = (action, tableState) => {
    switch (action) {
      case 'changeRowsPerPage':
        setClientSideRowsPerPage(tableState.rowsPerPage);
        break;
      case 'filterChange':
        {
          let filter = {};

          tableState.filterList.forEach((item, index) => {
            if (item.length && item.toString() !== '') {
              if (item[0] !== undefined || item[0] !== '') {
                filter[tableState.columns[index].name] = item[0];
              }
            }
          });

          if (
            tableState.filterList.find(
              (v) => v.length && v.toString() !== ''
            ) !== undefined
          ) {
            setIsFilterActive(true);
          } else {
            setIsFilterActive(false);
          }

          setFilterList(filter);
        }
        break;
      default:
    }
  };

  if (options.serverSide) {
    defaultSettings.customSearch = undefined;
    defaultSettings.onDownload = () => {
      setOpenCsvDownload(true);
      return false;
    };
    defaultSettings.filterType = 'dropdown';
    defaultSettings.count = pagination.count;
    defaultSettings.page = pagination.pageNo;
    defaultSettings.rowsPerPage = pagination.rowsPerPage;
    defaultSettings.onTableChange = onTableChange;
    defaultSettings.customTableBodyFooterRender = function(opts) {
      if (!footerData) return null;
      return (
        <ServerSideTableBodyFooter
          columnOrder={columnOrder}
          columns={opts.columns}
          columnsWithAmt={footerSettings.columnsWithAmt}
          columnsWithQty={footerSettings.columnsWithQty}
          columnsWithPercentage={footerSettings.columnsWithPercentage}
          rows={rows}
          data={footerData}
          isFilterActive={isFilterActive}
          additionalCell={additionalCell}
        ></ServerSideTableBodyFooter>
      );
    };
  }

  if (options.clientSideTableListener) {
    defaultSettings.onTableChange = onTableChangeClientSide;
  }

  if (
    (!options.clientSideTableListener ||
      options.clientSideTableListener === '') &&
    (!options.serverSide || options.serverSide === '')
  ) {
    defaultSettings.onTableChange = onTableChangeClientSide;
  }

  if (!options) {
    return defaultSettings;
  }

  return { ...defaultSettings, ...options };
};

const getColumnTypes = (currentColumnSettings, baseColumnSettings) => {
  return currentColumnSettings.map((col) => {
    const columnSetting = getColumnSettingsByName(baseColumnSettings, col.name);
    return columnSetting.type;
  });
};

const getColumnValue = (type, value) => {
  switch (type) {
    case columnType.date: {
      const retValue = formatPbDate(value);
      return retValue !== '00/00/00' ? retValue : '';
    }
    case columnType.dateTime:
      return !['', undefined, null].includes(value)
        ? protoTimeSpanObjectToString(value, 'MM/DD/YYYY hh:mm:ss a')
        : '--';
    case columnType.quantity:
      return formatQty(value);
    case columnType.percentage:
      return formatPercentage(value);
    case columnType.amount:
      return formatCurrency(value);
    case columnType.amount6Decimal:
      return formatCurrency(value, 6);
    case columnType.amountBalance:
      return formatCurrencySymbol(value);
    case columnType.status:
      return formatStatus(value);
    case columnType.note:
      return value;
    case columnType.time:
      return twelveHourTimeFormat(value);
    case columnType.duration:
      return formatDuration(value);
    case columnType.text:
    case '':
    case columnType.buttons:
    case undefined:
      return value;
    default:
      break;
  }

  return value;
};

const getFooterSettings = (columns) =>
  columns.reduce(
    (settings, col) => {
      if (col.addFooter)
        switch (col.type) {
          case columnType.amount:
            settings.columnsWithAmt.push(col.name);
            break;
          case columnType.percentage:
            settings.columnsWithPercentage.push(col.name);
            break;
          case columnType.quantity:
            settings.columnsWithQty.push(col.name);
            break;
          default:
            console.error(
              "cannot add custom footer, object have invalid 'type' property"
            );
            break;
        }

      return settings;
    },
    {
      columnsWithAmt: [],
      columnsWithQty: [],
      columnsWithPercentage: [],
    }
  );

const getVisibleColumns = (columns) =>
  columns.reduce((filtered, col) => {
    if (
      col.options === undefined ||
      col.options.display === undefined ||
      col.options.display === true ||
      col.options.display === 'true'
    ) {
      filtered.push(col.name);
    }
    return filtered;
  }, []);

const getColumnOrder = (columns, columnsSettings) =>
  columns.map((col) => {
    return getArrayKeyIndex(columnsSettings, col.name, 'name');
  });

const getColumnSettingsByName = (columnsSettings, name) => {
  const index = getArrayKeyIndex(columnsSettings, name, 'name');
  return columnsSettings[index];
};

const sortColumnSettings = (columns) =>
  columns.sort(function(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

const showAllColumns = (col, setVisibleColumns) => {
  const showColumns = col.reduce((filtered, col) => {
    if (col.type === 'buttons') {
      filtered.push(col.name);
    }
    if (
      !col.options.hidden &&
      (col.options === undefined ||
        col.options.display === undefined ||
        col.options.display === false ||
        col.options.display === 'false')
    ) {
      col.options.display = true;
      filtered.push(col.name);
    }

    return filtered;
  }, []);

  setVisibleColumns(showColumns);
};

const serverSideCustomSearch = (
  searchQuery,
  rows,
  visibleColumns,
  sortedColumns
) => {
  const columnTypes = {};
  const test = sortedColumns.map((col) => {
    col.options = col.options || {};
    //visibility state
    if (visibleColumns.includes(col.name)) {
      col.options.display = true;
      columnTypes[col.name] = col?.type;
    }

    return col;
  });

  const keys = Object.keys(columnTypes);

  return rows.filter((r) => {
    for (let index = 0; index < keys.length; index++) {
      const colName = keys[index];
      const value = r[colName];
      if (!value) {
        continue;
      }

      if (typeof value === 'string') {
        if (value.toLowerCase().includes(searchQuery?.toLowerCase())) {
          return true;
        }
      }
      const formattedValue = getColumnValue(columnTypes[colName], value);
      if (
        formattedValue
          ?.toString()
          .toLowerCase()
          .includes(searchQuery?.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  });
};
