import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Paper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { lazyLoadAccount, accessibleRep } from '../../services/CommonService';
import color from '../../assets/css/colors';
import fontFamily from '../../assets/css/fonts';

const useStyles = makeStyles(() => ({
    autocompleteWrapper: {
        '& .MuiAutocomplete-inputRoot': {
            padding: '7px 15px 8px 15px !important',
            height: 48,
        },
        '& .MuiAutocomplete-option': {
            backgroundColor: 'red',
        },
        '& .MuiAutocomplete-endAdornment': {
            top: 'calc(50% - 15px)',
            '& svg': {
                width: '22px !important',
                height: '22px !important',
            }
        }
    },
    paper: {
        fontFamily: fontFamily.label,
        fontSize: 15,
        fontWeight: 600,
        backgroundColor: color.light.main,
    }
}));

export default function AutoCompleteRep(props) {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [inputValue, setInputValue] = React.useState(props.value);

    const setPropsValue = (value) => {
        props.onChange({
            currentTarget: {
                name: props.name,
                value: value,
            },
        });
    };
    
    const handleOnBlur = async (value) => {
        if (props.freeSolo) {
            setPropsValue(value);
            return;
        }

        if (options.find((o) => o.rep === value)) {
            setPropsValue(value);
            return;
        }

        // if selected value does not exist in options and is not a free solo
        setInputValue('');
        setPropsValue('');
    };

    //set local input value and get options
    const handleInputChange = async (event, value) => {
        if (!event) return;
        if (event.type === 'blur') return;
        if (event.type === 'click' && value) return;

        setInputValue(value);
        getOptions(value);
    };

    const getOptions = async (value) => {
        try {
            let data = null;
            let list = [];

            if (props.isAccessibleOnly) {
                data = await accessibleRep(props.correspondent, value);

                list = data.repsList;
            } 
            else {
                data = await lazyLoadAccount(value, 'rep', 'rep');

                list = data.accountsList.filter(v => v.rep !== null && v.rep !== '')
                    .reduce((unique, o) => {
                        if (!unique.some((obj) => obj.rep === o.rep && obj.value === o.value)) {
                            unique.push(o);
                        }
                        return unique;
                }, []);
            }
            
            setOptions(list);
        }
        catch (error) {
            console.log(error)
        }
    };

    React.useEffect(() => {
        if (open) {
            getOptions(props.value);
        }
    },
        // eslint-disable-next-line
        [open]
    );

    return (
        <Autocomplete
            className={classes.autocompleteWrapper}
            disabled={props.disabled}
            freeSolo={props.freeSolo}
            id={props.name}
            onInputChange={handleInputChange}
            onBlur={() => {handleOnBlur(inputValue);}}
            blurOnSelect={false}
            onChange={(_, v) => {
                const value = v ? v.rep : '';
                setPropsValue(value);
                setInputValue(value);
            }}
            getOptionSelected={(option, value) => option.rep === value.rep}
            getOptionLabel={(option) => option.rep}
            inputValue={inputValue ? inputValue : props.value}
            options={options}
            autoHighlight={true}
            clearOnEscape={true}
            open={open}
            onOpen={() => {setOpen(true);}}
            onClose={() => {setOpen(false);}}
            PaperComponent={({ children }) => (<Paper className={classes.paper}>{children}</Paper>)}
            renderInput={(params) => (
                <TextField
                {...params}
                error={props.error}
                required={props.required}
                disabled={props.disabled}
                label={'Rep'}
                placeholder={'Rep'}
                InputLabelProps={{ shrink: true }}
                inputProps={{ ...params.inputProps, maxLength: 50 }}
                />
            )}
        />
    );
}
