import {
   Link,
   PublicToken,
   AccessToken,
   PlaidServiceClient
  } from '../proto/plaidpb/plaidtoken_grpc_web_pb';
  import { auth } from '../lib/auth/Auth';
  
  const service = new PlaidServiceClient(
    window.env.GRPC_ENDPOINT,
    {},
    { ...auth }
  );
  
  export async function CreateLinkToken(param) {
    return new Promise((resolve, reject) => {
      let req = new Link();
      req.setName(param.name);
      req.setCountry(param.country);
      req.setUserId(param.id);
      req.setType(param.type);

      if (param.access_token) {
        req.setAccessToken(param.access_token);
      }
  
      service.createLinkToken(req, {}, (error, response) => {
        if (error) {
          reject(error);
        } else {
          resolve(response.toObject());
        }
      });
    });
  }
  

  export async function ExchangePublicToken(param) {
    return new Promise((resolve, reject) => {
      let req = new PublicToken();
      req.setPublicToken(param.public_token);
  
      service.exchangePublicToken(req, {}, (error, response) => {
        if (error) {
          reject(error);
        } else {
          resolve(response.toObject());
        }
      });
    });
  }
  

  export async function GetAuth(param) {
    return new Promise((resolve, reject) => {
      let req = new AccessToken();
      req.setAccessToken(param.access_token);
      // console.log("Parameter: ");
      // console.log(param);
  
      service.getAuth(req, {}, (error, response) => {
        if (error) {
          reject(error);
        } else {
          resolve(response.toObject());
        }
      });
    });
  }