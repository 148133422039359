import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  makeStyles,
} from '@material-ui/core';
import fontFamily from '../../../../assets/css/fonts';
import color from '../../../../assets/css/colors';
import TextField from '../../../../components/Textfields/TextField';
import SelectSystemCode from '../../../../components/Dropdown/SelectSystemCode';
import { notifySuccess, notifyError } from '../../../../components/Messages/Notification';
import {
  createAccount,
  readDefaultGlBank,
  updateAccount,
} from '../../../../services/BankAccountService';
import { listBankAddress } from '../../../../services/BankAddressService';
import { readCompleteAddress } from '../../../../services/BankAddressService';
import { readPrimaryOwner } from '../../../../services/CommonService';
import AutoCompleteCorrespondent from '../../../../components/AutoComplete/AutoCompleteCorrespondent';
import AutoCompleteAccountNo from '../../../../components/AutoComplete/AutoCompleteAccountNo';
import { getCurrentUser } from '../../../../services/AuthService';
import {
  readClient,
  getAccessibleClient,
} from '../../../../services/ClientAccountService';
import { usePlaidLink } from 'react-plaid-link';
import {
  CreateLinkToken,
  ExchangePublicToken,
  GetAuth,
} from '../../../../services/PlaidService';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 850,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: '30px 0px 0px 30px',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& > div:nth-child(1)': {
      flex: 'none',
      marginRight: 7,
      '& > svg': {
        width: 28,
        height: 28,
        '& path': {},
      },
    },
    '& div:nth-child(2)': {
      flex: 1,
    },
    '& div:nth-child(3)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {},
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 30px',
    maxHeight: 600,
    overflowY: 'auto',
    fontFamily: fontFamily.headers,
    color: color.default.main,
    '& p': {
      fontSize: 16,
      fontFamily: fontFamily.headers,
      fontWeight: 400,
      lineHeight: '26px',
      color: color.default.main,
      marginTop: 0,
    },
    '& hr': {
      margin: '30px 0px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 120,
    },
  },
  incompleteSteps: {
    fontSize: 16,
    lineHeight: '24px',
    marginTop: 20,
    '& > label': {
      fontWeight: 600,
      marginBottom: 10,
      display: 'block',
    },
    '& ul': {
      listStyle: 'none',
      margin: '0px 0px 0px -40px',
      lineHeight: '30px',
      '& > li > span': {
        position: 'relative',
        top: 4,
        '& > svg': {
          width: 20,
          height: 20,
        },
      },
    },
  },
  success: {
    '& > svg > path': {},
  },
  error: {
    '& > svg > path': {},
  },
  closeButton: {
    '& button': {
      marginTop: '-5px !important',
    },
  },
}));

export default function ManualInputModal({ onClose, iopen, value, profile }) {
  const classes = useStyles()
  const [profileName, setProfileName] = React.useState("");
  const [user, setUser] = React.useState([])
  const [defaultAccount, setDefaultAccount] = React.useState([])
  const [isEdit, setIsEdit] = React.useState(false)
  const [rowData, setRowData] = React.useState({
    token:value?.linktoken?.linkToken
  })

  const [mOpen, setMOpen] = React.useState(iopen)

  const [plaidData, setPlaidData] = React.useState({})
  const [accessToken, setAccessToken] = React.useState({})
  const [isPlaidOpen, setOpenPlaid] = React.useState(false)
  const [metaData, setMetadata] = React.useState({})
  const [inputMethod,setInputMethod] = React.useState('')
  const [modalData, setModalData] = useState(
    value || {
      bankAccountType: '',
      bankAddressId: 0,
      status: '',
      bankId: 0,
      glAccountId: 0,
      bankOwnerName: '',
      bankName: '',
      bankAccountNo: '',
      correspondent: '',
      accountNo: '',
      achRoutingNo: '',
      wireRoutingNo: '',
      plaidAccessToken: '',
      isInternational: false,
      bankIdentifierCode: '',
      approvedMethod: '',
    }
  );

  const [bankAddressIdPromiseTimeout, setBankAddressIdPromiseTimeout] = useState(0);
  const [bankOwnerNamePromiseTimeout, setBankOwnerNamePromiseTimeout] = useState(0);
  const isEnabled = !value || !value.bankId || value.status === 'Pending Approval';

  const setBankOwnerName = async (param) => {
    try {
      if (!param.correspondent || !param.accountNo) {
        return '';
      }

      const { fullName } = await readPrimaryOwner({
        correspondent: param.correspondent,
        accountNo: param.accountNo,
      });
      if (fullName) {
        setModalData({ ...modalData, bankOwnerName: fullName });
      }
    } catch (err) {
      if (err.message !== `-2:database error, 'read primary owner' record not found;`)
        notifyError(err.message);
    }

    return '';
  };

  const setBankAddressId = async (param) => {
    try {
      if (!param.bankName && param.bankAddressId) {
        return;
      }
      if (!param.achRoutingNo && !param.wireRoutingNo) {
        return;
      }
      if (param.achRoutingNo.length > 7 || param.wireRoutingNo.length > 7) {
        const { bankAddress } = await readCompleteAddress({
          bankRoutingNo: param.achRoutingNo
            ? param.achRoutingNo
            : param.wireRoutingNo || '',
          bankName: param.bankName,
        });

        if (bankAddress) {
          setModalData({ ...modalData, bankAddressId: bankAddress });
        }
      }
    } catch (err) {
      if (err.message !== `-3:'bank address' not found, bank name: ${param.bankName};`)
        notifyError(err.message);
    }

    return '';
  };

  useEffect(() => {
   
    if (iopen) {
      
      const initAccess = async () => { 
        let account_no = ''
        let correspondent = ''
  
        const loggeduser = getCurrentUser()
        setUser(loggeduser)
        if (loggeduser === null) return
        try {
          if (loggeduser.AccountId !== 0) {
            const account = await readClient(loggeduser.AccountId);
            account_no = account.client.accountNo;
            correspondent = account.client.correspondent;
          } else {
            const account = await getAccessibleClient(loggeduser.UserId);
            account_no = account.client.accountNo;
            correspondent = account.client.correspondent;
          }
          if (value) {
            setModalData({
              ...value,
              accountNo: account_no,
              correspondent: correspondent,
            });
          } else {
            setModalData({
              ...modalData,
              accountNo: account_no,
              correspondent: correspondent,
            });
          }
        } catch (error) {
          notifyError(error.message);
          console.error(error);
        }
      };

      initAccess();

      if (!modalData.glAccountId) {
        const init = async () => {
          try {
            const data = await readDefaultGlBank();
            if (value) {
              setModalData({
                ...value,
                glAccountId: data.accountId,
              });
            } else {
              setModalData({
                ...modalData,
                glAccountId: data.accountId,
              });
            }
          } catch (error) {
            notifyError(error.message);
            console.error(error);
          }
          //await loadData("Micro Deposit")
        }
      
        init()
      }
    }
    // eslint-disable-next-line
  }, [iopen]);

  
  useEffect(() => {
  }, [modalData]);

  useEffect(() => {
    if (bankOwnerNamePromiseTimeout) clearTimeout(bankOwnerNamePromiseTimeout);
    if (bankAddressIdPromiseTimeout) clearTimeout(bankAddressIdPromiseTimeout);

    setBankOwnerNamePromiseTimeout(
      setTimeout(() => {
        setBankOwnerName(modalData);
      }, 1000)
    );

    return () => {
      if (bankOwnerNamePromiseTimeout) clearTimeout(bankOwnerNamePromiseTimeout);
    };
    // eslint-disable-next-line
  }, [modalData.correspondent, modalData.accountNo, modalData.correspondent]);

  useEffect(() => {
    if (bankAddressIdPromiseTimeout) clearTimeout(bankAddressIdPromiseTimeout);
    if (bankOwnerNamePromiseTimeout) clearTimeout(bankOwnerNamePromiseTimeout);

    setBankAddressIdPromiseTimeout(
      setTimeout(() => {
        setBankAddressId(modalData);
      }, 1000)
    );

    return () => {
      if (bankAddressIdPromiseTimeout) clearTimeout(bankAddressIdPromiseTimeout);
    };
    // eslint-disable-next-line
  }, [modalData.bankName, modalData.achRoutingNo, modalData.wireRoutingNo]);

  const handleChange = async (e, checkboxValue) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;
    const modalDataCopy = { ...modalData };
    modalDataCopy[input.name] =
      checkboxValue === true || checkboxValue === false ? checkboxValue : input.value;

    if (['bankName', 'achRoutingNo', 'wireRoutingNo'].includes(input.name)) {
      if (typeof modalDataCopy.bankName === 'object') {
        modalDataCopy.bankAddressId = '';
        modalDataCopy.achRoutingNo = modalDataCopy.bankName.bankRoutingNo;
        modalDataCopy.bankName = modalDataCopy.bankName.bankName;
      }
    }

    setModalData(modalDataCopy)
    
    if (input.name == "approvedMethod" && (input.value == "Plaid" || input.value == "Micro Deposit")){
      //await loadData(input.value)
      setInputMethod(input.value )
      setMOpen(false)
      open()
    }
  };

  const loadData = async (type) => {
    const loggeduser = getCurrentUser();
    var linkToken;
    if (rowData.token) {
      setOpenPlaid(true);
      return;
    }
    if (loggeduser === null) return;

    try {
      const lt = await CreateLinkToken({
        name: 'SAS',
        country: 'US',
        id: loggeduser.UserId,
        type: type,
      });

      if (lt.errorCode !== '') {
        notifyError(`PLAID ERROR: ${lt.errorType} ${lt.errorCode} ${lt.errorMsg}`);
        console.error(lt);
        return;
      }

      linkToken = lt;
    } catch (e) {
      notifyError(e);
      console.error(e);
      return;
    }

    if (linkToken.linkToken !== '') {
      setRowData({
        ...rowData,
        token: linkToken.linkToken,
        user: loggeduser,
      });
      setOpenPlaid(true);
    }
  };

  const config = {
    onSuccess: async (public_token, metadata) => {
      var at;
      var auth_data;
      try {
        const actok = await ExchangePublicToken({
          public_token: public_token,
        });
        if (actok.errorCode !== '') {
          notifyError(actok.errorCode + ' ' + actok.errorMessage);
          console.error(actok);
          return;
        }
        at = actok;
      } catch (e) {
        notifyError(e);
        console.error(e);
        return;
      }
     
      
          try {
            const ad = await GetAuth({
              access_token: at.accessToken,
            });
    
            auth_data = ad;
          } catch (e) {
            notifyError(e);
            console.error(e);
            return;
          }
          const dataresp = JSON.parse(auth_data.json);
          if (dataresp.error_code && dataresp.error_code === 'PRODUCT_NOT_READY') { //micro deposit
            setModalData({...modalData,plaidAccessToken:at.accessToken, 
              approvedMethod:'Micro Deposit',
              status:'Pending Verification'
            })
          } else {
            const plaid_bank_data = dataresp.numbers.ach
            if (dataresp.error_code && dataresp.error_code !== '') {
              notifyError(dataresp.errorCode + ' ' + dataresp.errorMessage);
              console.error(dataresp);
              return;
            }
            setModalData({...modalData,plaidAccessToken:at.accessToken, 
              approvedMethod:plaid_bank_data[0]?.verification_status?'Micro Deposit':'Plaid',
              status:plaid_bank_data[0]?.verification_status?'Pending Verification':'Active'
            })
          }
          
          
          setOpenPlaid(false);
          exit();
          setMOpen(true)
          setPlaidData(dataresp);
          setAccessToken(at);
          
          
          setMetadata(metadata)
          
      
      
      // for (const data of plaid_bank_data) {
      //   var address_id = 0
      //   try {
      //     const address = await listBankAddress({
      //       bank_routing_no: data.routing,
      //     })
      //     address_id = address.bank_addresses[0].bank_address_id
      //   } catch (error) {
      //     address_id = 0
      //   }
      //   try {
      //     const param = {
      //       correspondent: modalData.correspondent,
      //      // masterAccountNo: modalData.masterAccountNo,
      //       accountNo: modalData.accountNo,
      //       bankAccountNo: data.account,
      //       plaidAccessToken: at.accessToken,
      //       bankName: metadata.institution.name,
      //       bankAddressId: address_id,
      //       // bank_identifier_code: metadata.institution.institution_id,
      //       bankAccountType: typeMap(
      //         getType(data.account_id, dataresp.accounts),
      //       ),
      //       bankOwnerName: getName(data.account_id, dataresp.accounts),
      //       achRoutingNo: data.routing,
      //       wireRoutingNo: data.wire_routing,
      //       approvedMethod: 'Plaid',
      //       status: 'Active',
      //     }
      //     await createAccount(param)
      //   } catch (error) {
      //     notifyError(error)
      //     console.error(error)
      //   }
      // }

      //setRowData({ ...rowData, token: '' })
      //init()
    },
    onExit: (err, metadata) => {
      setModalData({ ...modalData, approvedMethod: '' });
      setOpenPlaid(false);
      exit();
      setMOpen(true)
    },
    onEvent: (eventName, metadata) => {},
    token: rowData.token ? rowData.token : '',
    env: window.env.PLAID_ENVIRONMENT ? window.env.PLAID_ENVIRONMENT : 'sandbox',
    // required for OAuth:
    // receivedRedirectUri: window.location.href,
    // if not OAuth, set to null or do not include:
    //receivedRedirectUri: window.env.GRPC_ENDPOINT+ "/plaidoauth?oauth_state_id="+rowData.token,
    receivedRedirectUri: null,
  };


  
  const { open, ready, exit } = usePlaidLink(config)
  //  if (ready && isPlaidOpen && rowData.token) {
    
  //  }
  


  function typeMap(status) {
    var text;
    switch (status) {
      case 'checking':
        text = 'Checking';
        break;
      case 'savings':
        text = 'Savings';
        break;

      default:
        text = '';
    }
    return text;
  }

  function getType(account_id, ar_account) {
    var type = '';

    ar_account.forEach(function (data) {
      if (account_id === data.account_id) type = data.subtype;
    });
    return type;
  }

  function getName(account_id, ar_account) {
    var type = '';

    ar_account.forEach(function (data) {
      if (account_id === data.account_id) type = data.name;
    });
    return type;
  }

  const handleSave = async () => {
    let data = { ...modalData };

    if (!data.accountNo) {
      return notifyError('Account No is required.');
    }
    if (!data.correspondent) {
      return notifyError('Correspondent is required.');
    }
    if (!data.approvedMethod) {
      return notifyError('Approved Method is required.');
    }
    if (!data.plaidAccessToken && data.approvedMethod === 'Plaid') {
      return notifyError('Plaid Access Token is required.');
    }

    if (data.isInternational === true && data.bankIdentifierCode === '') {
      return notifyError('Bank Identifier Code (BIC) is required.');
    }

    if (typeof data.bankAddressId === 'object') {
      data.bankAddressId = data.bankAddressId.bankAddressId;
    }

    if (data.isInternational === false) {
      data.bankIdentifierCode = '';
    }

    try {
      if (data.bankId) {
        const { bankAccount } = await updateAccount(data);
        notifySuccess('Bank Account has been updated.');
        onClose(bankAccount, true);
      } else {
        // createAccount;
        //Via Plaid
        if (modalData.approvedMethod==="Plaid") {
          if (!plaidData.numbers) {
            return notifyError('PLAID data is not fetched.');
          }
        }
        var bankAccounts = [];
        if (modalData.approvedMethod==="Micro Deposit") {
          const param = {
            correspondent: modalData.correspondent,
            // masterAccountNo: modalData.masterAccountNo,
            accountNo: modalData.accountNo,
            bankAccountNo: data.account,
            plaidAccessToken: accessToken.accessToken,
            bankName: metaData.institution.name,
            bankAddressId: address_id,
            // bank_identifier_code: metadata.institution.institution_id,
            // bankAccountType: typeMap(getType(data.account_id, plaidData.accounts)),
            // bankOwnerName: getName(data.account_id, plaidData.accounts),
            // achRoutingNo: data.routing,
            // wireRoutingNo: data.wire_routing,
            approvedMethod: 'Micro Deposit',
            status: modalData.status,
          };
          await createAccount(param);
          bankAccounts.push(param);
          notifySuccess('Bank Account/s has been added.');
          onClose(bankAccounts);
          return
        }
        

        const plaid_bank_data = plaidData.numbers.ach;
        
        for (const data of plaid_bank_data) {
          var address_id = 0;
          try {
            const address = await listBankAddress({
              bank_routing_no: data.routing,
            });
            address_id = address.bank_addresses[0].bank_address_id;
          } catch (error) {
            address_id = 0;
          }
          try {
            const param = {
              correspondent: modalData.correspondent,
              // masterAccountNo: modalData.masterAccountNo,
              accountNo: modalData.accountNo,
              bankAccountNo: data.account,
              plaidAccessToken: accessToken.accessToken,
              bankName: metaData.institution.name,
              bankAddressId: address_id,
              // bank_identifier_code: metadata.institution.institution_id,
              bankAccountType: typeMap(getType(data.account_id, plaidData.accounts)),
              bankOwnerName: getName(data.account_id, plaidData.accounts),
              achRoutingNo: data.routing,
              wireRoutingNo: data.wire_routing,
              approvedMethod: 'Plaid',
              status: 'Active',
            };
            await createAccount(param);
            bankAccounts.push(param);
          } catch (error) {
            notifyError(error);
            console.error(error);
          }
        }
        notifySuccess('Bank Account/s has been added.');
        onClose(bankAccounts);
      }
    } catch (error) {
      console.error(error);
      return notifyError('Error:' + error.message);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={mOpen}
      onClose={() => onClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={mOpen}>
        <div style={{ width: 2000 }}>
          <Box mt={5}></Box>
          <form noValidate autoComplete="off">
            <input type="hidden" autoComplete="off" />
            <div className={classes.modalHeader}>
              <Typography
                id="transition-modal-title"
                variant="h2"
                className={classes.textBold}
                gutterBottom
              >
                {modalData.bankId ? 'Edit' : 'Add New'} Bank Account
              </Typography>
            </div>
            <div className={classes.modalBody}>
              <div>
                <div className="grd-row">
                  <div className="grd-cell">
                    <AutoCompleteCorrespondent
                      required={true}
                      disabled={!isEnabled}
                      isAccessibleOnly={true}
                      name="correspondent"
                      value={modalData.correspondent || ''}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="grd-cell">
                    <AutoCompleteAccountNo
                      required={true}
                      isAccessibleOnly={true}
                      disabled={!isEnabled}
                      name="accountNo"
                      value={modalData.accountNo || ''}
                      correspondent={modalData.correspondent}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="grd-row">
                <div className="grd-cell">
                  <SelectSystemCode
                    required={true}
                    name="approvedMethod"
                    label="Approved Method"
                    placeholder="Approved Method"
                    type="Approved Method"
                    value={modalData.approvedMethod || ''}
                    onChange={handleChange}
                    disabled={!isEnabled}
                    disabledItem={{
                      'N/A': true,
                    }}
                    addItemMenuTooltip
                  />
                </div>
                <div className="grd-cell">
                  <TextField
                    required={modalData.approvedMethod === 'Plaid'}
                    name="plaidAccessToken"
                    label="Plaid Access Token"
                    placeholder="Plaid Access Token"
                    type="text"
                    value={modalData.plaidAccessToken}
                    onChange={handleChange}
                    inputProps={{ maxLength: 60 }}
                    disabled={!isEnabled}
                  />
                </div>
              </div>

              <div className="grd-row">
                {/* 
                HIDDEN: kept for copy, remove if confirmed
                <div className="grd-cell">
                  <SelectSystemCode
                    name="status"
                    label="Status"
                    placeholder="Status"
                    type="Status"
                    subType="Bank Account"
                    value={modalData.status || 'Pending Approval'}
                    disabled
                    onChange={handleChange}
                  />
                </div> */}
                <div className="grd-cell">
                  <TextField
                    required={modalData.isInternational}
                    name="bankIdentifierCode"
                    label="Bank Identifier Code (BIC)"
                    placeholder="Bank Identifier Code (BIC)"
                    type="text"
                    value={modalData.bankIdentifierCode}
                    onChange={handleChange}
                    inputProps={{ maxLength: 30 }}
                    disabled={!isEnabled || !modalData.isInternational}
                  />
                </div>
              </div>
              {/* 
              HIDDEN: kept for copy, remove if confirmed
              <div className="grd-row">
                <div className="grd-cell" style={{ marginRight: 502 }}>
                  <SelectGLBankAccount
                    required={true}
                    disabled={!isEnabled}
                    name="glAccountId"
                    label="GL Account"
                    value={modalData.glAccountId}
                    onChange={handleChange}
                  ></SelectGLBankAccount>
                </div>
              </div> */}
            </div>
            <div className={classes.modalFooter}>
              <div className="grd-row">
                <div className="grd-cell" style={{ marginRight: 10 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => onClose()}
                  >
                    Close
                  </Button>
                </div>
                <div className="grd-cell">
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </div>
                <div className="grd-cell"></div>
              </div>
            </div>
          </form>
        </div>
      </Fade>
    </Modal>
  );
}
