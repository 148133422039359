// source: proto/accountpb/correspondent.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.accountpb.Correspondent', null, global);
goog.exportSymbol('proto.accountpb.CreateCorrespondentResponse', null, global);
goog.exportSymbol('proto.accountpb.DeleteCorrespondentRequest', null, global);
goog.exportSymbol('proto.accountpb.DeleteCorrespondentResponse', null, global);
goog.exportSymbol('proto.accountpb.ListCorrespondentAuditRequest', null, global);
goog.exportSymbol('proto.accountpb.ListCorrespondentAuditResponse', null, global);
goog.exportSymbol('proto.accountpb.ListCorrespondentRequest', null, global);
goog.exportSymbol('proto.accountpb.ListCorrespondentResponse', null, global);
goog.exportSymbol('proto.accountpb.ReadCorrespondentRequest', null, global);
goog.exportSymbol('proto.accountpb.ReadCorrespondentResponse', null, global);
goog.exportSymbol('proto.accountpb.UpdateCorrespondentResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.Correspondent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.Correspondent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.Correspondent.displayName = 'proto.accountpb.Correspondent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ListCorrespondentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ListCorrespondentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ListCorrespondentRequest.displayName = 'proto.accountpb.ListCorrespondentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ListCorrespondentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.accountpb.ListCorrespondentResponse.repeatedFields_, null);
};
goog.inherits(proto.accountpb.ListCorrespondentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ListCorrespondentResponse.displayName = 'proto.accountpb.ListCorrespondentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ReadCorrespondentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ReadCorrespondentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ReadCorrespondentRequest.displayName = 'proto.accountpb.ReadCorrespondentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ReadCorrespondentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ReadCorrespondentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ReadCorrespondentResponse.displayName = 'proto.accountpb.ReadCorrespondentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.CreateCorrespondentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.CreateCorrespondentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.CreateCorrespondentResponse.displayName = 'proto.accountpb.CreateCorrespondentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.UpdateCorrespondentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.UpdateCorrespondentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.UpdateCorrespondentResponse.displayName = 'proto.accountpb.UpdateCorrespondentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.DeleteCorrespondentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.DeleteCorrespondentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.DeleteCorrespondentRequest.displayName = 'proto.accountpb.DeleteCorrespondentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.DeleteCorrespondentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.DeleteCorrespondentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.DeleteCorrespondentResponse.displayName = 'proto.accountpb.DeleteCorrespondentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ListCorrespondentAuditRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ListCorrespondentAuditRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ListCorrespondentAuditRequest.displayName = 'proto.accountpb.ListCorrespondentAuditRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ListCorrespondentAuditResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.accountpb.ListCorrespondentAuditResponse.repeatedFields_, null);
};
goog.inherits(proto.accountpb.ListCorrespondentAuditResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ListCorrespondentAuditResponse.displayName = 'proto.accountpb.ListCorrespondentAuditResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.Correspondent.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.Correspondent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.Correspondent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.Correspondent.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondentId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    brokerName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mediantCorrespondentId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    participantNo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    address: jspb.Message.getFieldWithDefault(msg, 6, ""),
    city: jspb.Message.getFieldWithDefault(msg, 7, ""),
    state: jspb.Message.getFieldWithDefault(msg, 8, ""),
    zip: jspb.Message.getFieldWithDefault(msg, 9, ""),
    country: jspb.Message.getFieldWithDefault(msg, 10, ""),
    contactNo: jspb.Message.getFieldWithDefault(msg, 11, ""),
    photo: jspb.Message.getFieldWithDefault(msg, 12, ""),
    status: jspb.Message.getFieldWithDefault(msg, 13, ""),
    note: jspb.Message.getFieldWithDefault(msg, 14, ""),
    url: jspb.Message.getFieldWithDefault(msg, 15, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 16, ""),
    modifiedBy: jspb.Message.getFieldWithDefault(msg, 17, ""),
    isCash: jspb.Message.getBooleanFieldWithDefault(msg, 33, false),
    isMargin: jspb.Message.getBooleanFieldWithDefault(msg, 34, false),
    isHouse: jspb.Message.getBooleanFieldWithDefault(msg, 35, false),
    cashMinAmt: jspb.Message.getFieldWithDefault(msg, 36, ""),
    marginMinAmt: jspb.Message.getFieldWithDefault(msg, 37, ""),
    houseMinAmt: jspb.Message.getFieldWithDefault(msg, 38, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.Correspondent}
 */
proto.accountpb.Correspondent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.Correspondent;
  return proto.accountpb.Correspondent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.Correspondent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.Correspondent}
 */
proto.accountpb.Correspondent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorrespondentId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrokerName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMediantCorrespondentId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setParticipantNo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setZip(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactNo(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoto(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifiedBy(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCash(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMargin(value);
      break;
    case 35:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsHouse(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setCashMinAmt(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarginMinAmt(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setHouseMinAmt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.Correspondent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.Correspondent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.Correspondent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.Correspondent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondentId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBrokerName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMediantCorrespondentId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getParticipantNo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getZip();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getContactNo();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPhoto();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getModifiedBy();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getIsCash();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
  f = message.getIsMargin();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
  f = message.getIsHouse();
  if (f) {
    writer.writeBool(
      35,
      f
    );
  }
  f = message.getCashMinAmt();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getMarginMinAmt();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getHouseMinAmt();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
};


/**
 * optional uint32 correspondent_id = 1;
 * @return {number}
 */
proto.accountpb.Correspondent.prototype.getCorrespondentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.Correspondent} returns this
 */
proto.accountpb.Correspondent.prototype.setCorrespondentId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.accountpb.Correspondent.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Correspondent} returns this
 */
proto.accountpb.Correspondent.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string broker_name = 3;
 * @return {string}
 */
proto.accountpb.Correspondent.prototype.getBrokerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Correspondent} returns this
 */
proto.accountpb.Correspondent.prototype.setBrokerName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string mediant_correspondent_id = 4;
 * @return {string}
 */
proto.accountpb.Correspondent.prototype.getMediantCorrespondentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Correspondent} returns this
 */
proto.accountpb.Correspondent.prototype.setMediantCorrespondentId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string participant_no = 5;
 * @return {string}
 */
proto.accountpb.Correspondent.prototype.getParticipantNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Correspondent} returns this
 */
proto.accountpb.Correspondent.prototype.setParticipantNo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string address = 6;
 * @return {string}
 */
proto.accountpb.Correspondent.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Correspondent} returns this
 */
proto.accountpb.Correspondent.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string city = 7;
 * @return {string}
 */
proto.accountpb.Correspondent.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Correspondent} returns this
 */
proto.accountpb.Correspondent.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string state = 8;
 * @return {string}
 */
proto.accountpb.Correspondent.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Correspondent} returns this
 */
proto.accountpb.Correspondent.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string zip = 9;
 * @return {string}
 */
proto.accountpb.Correspondent.prototype.getZip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Correspondent} returns this
 */
proto.accountpb.Correspondent.prototype.setZip = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string country = 10;
 * @return {string}
 */
proto.accountpb.Correspondent.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Correspondent} returns this
 */
proto.accountpb.Correspondent.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string contact_no = 11;
 * @return {string}
 */
proto.accountpb.Correspondent.prototype.getContactNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Correspondent} returns this
 */
proto.accountpb.Correspondent.prototype.setContactNo = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string photo = 12;
 * @return {string}
 */
proto.accountpb.Correspondent.prototype.getPhoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Correspondent} returns this
 */
proto.accountpb.Correspondent.prototype.setPhoto = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string status = 13;
 * @return {string}
 */
proto.accountpb.Correspondent.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Correspondent} returns this
 */
proto.accountpb.Correspondent.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string note = 14;
 * @return {string}
 */
proto.accountpb.Correspondent.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Correspondent} returns this
 */
proto.accountpb.Correspondent.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string url = 15;
 * @return {string}
 */
proto.accountpb.Correspondent.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Correspondent} returns this
 */
proto.accountpb.Correspondent.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string created_by = 16;
 * @return {string}
 */
proto.accountpb.Correspondent.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Correspondent} returns this
 */
proto.accountpb.Correspondent.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string modified_by = 17;
 * @return {string}
 */
proto.accountpb.Correspondent.prototype.getModifiedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Correspondent} returns this
 */
proto.accountpb.Correspondent.prototype.setModifiedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional bool is_cash = 33;
 * @return {boolean}
 */
proto.accountpb.Correspondent.prototype.getIsCash = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 33, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Correspondent} returns this
 */
proto.accountpb.Correspondent.prototype.setIsCash = function(value) {
  return jspb.Message.setProto3BooleanField(this, 33, value);
};


/**
 * optional bool is_margin = 34;
 * @return {boolean}
 */
proto.accountpb.Correspondent.prototype.getIsMargin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Correspondent} returns this
 */
proto.accountpb.Correspondent.prototype.setIsMargin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 34, value);
};


/**
 * optional bool is_house = 35;
 * @return {boolean}
 */
proto.accountpb.Correspondent.prototype.getIsHouse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 35, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Correspondent} returns this
 */
proto.accountpb.Correspondent.prototype.setIsHouse = function(value) {
  return jspb.Message.setProto3BooleanField(this, 35, value);
};


/**
 * optional string cash_min_amt = 36;
 * @return {string}
 */
proto.accountpb.Correspondent.prototype.getCashMinAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Correspondent} returns this
 */
proto.accountpb.Correspondent.prototype.setCashMinAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string margin_min_amt = 37;
 * @return {string}
 */
proto.accountpb.Correspondent.prototype.getMarginMinAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Correspondent} returns this
 */
proto.accountpb.Correspondent.prototype.setMarginMinAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string house_min_amt = 38;
 * @return {string}
 */
proto.accountpb.Correspondent.prototype.getHouseMinAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Correspondent} returns this
 */
proto.accountpb.Correspondent.prototype.setHouseMinAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ListCorrespondentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ListCorrespondentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ListCorrespondentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListCorrespondentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    brokerName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    participantNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ListCorrespondentRequest}
 */
proto.accountpb.ListCorrespondentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ListCorrespondentRequest;
  return proto.accountpb.ListCorrespondentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ListCorrespondentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ListCorrespondentRequest}
 */
proto.accountpb.ListCorrespondentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrokerName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setParticipantNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ListCorrespondentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ListCorrespondentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ListCorrespondentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListCorrespondentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBrokerName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getParticipantNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.accountpb.ListCorrespondentRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListCorrespondentRequest} returns this
 */
proto.accountpb.ListCorrespondentRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string broker_name = 2;
 * @return {string}
 */
proto.accountpb.ListCorrespondentRequest.prototype.getBrokerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListCorrespondentRequest} returns this
 */
proto.accountpb.ListCorrespondentRequest.prototype.setBrokerName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string participant_no = 3;
 * @return {string}
 */
proto.accountpb.ListCorrespondentRequest.prototype.getParticipantNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListCorrespondentRequest} returns this
 */
proto.accountpb.ListCorrespondentRequest.prototype.setParticipantNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string status = 4;
 * @return {string}
 */
proto.accountpb.ListCorrespondentRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListCorrespondentRequest} returns this
 */
proto.accountpb.ListCorrespondentRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.accountpb.ListCorrespondentResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ListCorrespondentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ListCorrespondentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ListCorrespondentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListCorrespondentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondentsList: jspb.Message.toObjectList(msg.getCorrespondentsList(),
    proto.accountpb.Correspondent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ListCorrespondentResponse}
 */
proto.accountpb.ListCorrespondentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ListCorrespondentResponse;
  return proto.accountpb.ListCorrespondentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ListCorrespondentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ListCorrespondentResponse}
 */
proto.accountpb.ListCorrespondentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Correspondent;
      reader.readMessage(value,proto.accountpb.Correspondent.deserializeBinaryFromReader);
      msg.addCorrespondents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ListCorrespondentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ListCorrespondentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ListCorrespondentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListCorrespondentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.accountpb.Correspondent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Correspondent correspondents = 1;
 * @return {!Array<!proto.accountpb.Correspondent>}
 */
proto.accountpb.ListCorrespondentResponse.prototype.getCorrespondentsList = function() {
  return /** @type{!Array<!proto.accountpb.Correspondent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.accountpb.Correspondent, 1));
};


/**
 * @param {!Array<!proto.accountpb.Correspondent>} value
 * @return {!proto.accountpb.ListCorrespondentResponse} returns this
*/
proto.accountpb.ListCorrespondentResponse.prototype.setCorrespondentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.accountpb.Correspondent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.Correspondent}
 */
proto.accountpb.ListCorrespondentResponse.prototype.addCorrespondents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.accountpb.Correspondent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ListCorrespondentResponse} returns this
 */
proto.accountpb.ListCorrespondentResponse.prototype.clearCorrespondentsList = function() {
  return this.setCorrespondentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ReadCorrespondentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ReadCorrespondentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ReadCorrespondentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadCorrespondentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondent: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ReadCorrespondentRequest}
 */
proto.accountpb.ReadCorrespondentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ReadCorrespondentRequest;
  return proto.accountpb.ReadCorrespondentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ReadCorrespondentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ReadCorrespondentRequest}
 */
proto.accountpb.ReadCorrespondentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ReadCorrespondentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ReadCorrespondentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ReadCorrespondentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadCorrespondentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.accountpb.ReadCorrespondentRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ReadCorrespondentRequest} returns this
 */
proto.accountpb.ReadCorrespondentRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ReadCorrespondentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ReadCorrespondentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ReadCorrespondentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadCorrespondentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondent: (f = msg.getCorrespondent()) && proto.accountpb.Correspondent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ReadCorrespondentResponse}
 */
proto.accountpb.ReadCorrespondentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ReadCorrespondentResponse;
  return proto.accountpb.ReadCorrespondentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ReadCorrespondentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ReadCorrespondentResponse}
 */
proto.accountpb.ReadCorrespondentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Correspondent;
      reader.readMessage(value,proto.accountpb.Correspondent.deserializeBinaryFromReader);
      msg.setCorrespondent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ReadCorrespondentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ReadCorrespondentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ReadCorrespondentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadCorrespondentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.Correspondent.serializeBinaryToWriter
    );
  }
};


/**
 * optional Correspondent correspondent = 1;
 * @return {?proto.accountpb.Correspondent}
 */
proto.accountpb.ReadCorrespondentResponse.prototype.getCorrespondent = function() {
  return /** @type{?proto.accountpb.Correspondent} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.Correspondent, 1));
};


/**
 * @param {?proto.accountpb.Correspondent|undefined} value
 * @return {!proto.accountpb.ReadCorrespondentResponse} returns this
*/
proto.accountpb.ReadCorrespondentResponse.prototype.setCorrespondent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.ReadCorrespondentResponse} returns this
 */
proto.accountpb.ReadCorrespondentResponse.prototype.clearCorrespondent = function() {
  return this.setCorrespondent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.ReadCorrespondentResponse.prototype.hasCorrespondent = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.CreateCorrespondentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.CreateCorrespondentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.CreateCorrespondentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.CreateCorrespondentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondent: (f = msg.getCorrespondent()) && proto.accountpb.Correspondent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.CreateCorrespondentResponse}
 */
proto.accountpb.CreateCorrespondentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.CreateCorrespondentResponse;
  return proto.accountpb.CreateCorrespondentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.CreateCorrespondentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.CreateCorrespondentResponse}
 */
proto.accountpb.CreateCorrespondentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Correspondent;
      reader.readMessage(value,proto.accountpb.Correspondent.deserializeBinaryFromReader);
      msg.setCorrespondent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.CreateCorrespondentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.CreateCorrespondentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.CreateCorrespondentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.CreateCorrespondentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.Correspondent.serializeBinaryToWriter
    );
  }
};


/**
 * optional Correspondent correspondent = 1;
 * @return {?proto.accountpb.Correspondent}
 */
proto.accountpb.CreateCorrespondentResponse.prototype.getCorrespondent = function() {
  return /** @type{?proto.accountpb.Correspondent} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.Correspondent, 1));
};


/**
 * @param {?proto.accountpb.Correspondent|undefined} value
 * @return {!proto.accountpb.CreateCorrespondentResponse} returns this
*/
proto.accountpb.CreateCorrespondentResponse.prototype.setCorrespondent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.CreateCorrespondentResponse} returns this
 */
proto.accountpb.CreateCorrespondentResponse.prototype.clearCorrespondent = function() {
  return this.setCorrespondent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.CreateCorrespondentResponse.prototype.hasCorrespondent = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.UpdateCorrespondentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.UpdateCorrespondentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.UpdateCorrespondentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.UpdateCorrespondentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondent: (f = msg.getCorrespondent()) && proto.accountpb.Correspondent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.UpdateCorrespondentResponse}
 */
proto.accountpb.UpdateCorrespondentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.UpdateCorrespondentResponse;
  return proto.accountpb.UpdateCorrespondentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.UpdateCorrespondentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.UpdateCorrespondentResponse}
 */
proto.accountpb.UpdateCorrespondentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Correspondent;
      reader.readMessage(value,proto.accountpb.Correspondent.deserializeBinaryFromReader);
      msg.setCorrespondent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.UpdateCorrespondentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.UpdateCorrespondentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.UpdateCorrespondentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.UpdateCorrespondentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.Correspondent.serializeBinaryToWriter
    );
  }
};


/**
 * optional Correspondent correspondent = 1;
 * @return {?proto.accountpb.Correspondent}
 */
proto.accountpb.UpdateCorrespondentResponse.prototype.getCorrespondent = function() {
  return /** @type{?proto.accountpb.Correspondent} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.Correspondent, 1));
};


/**
 * @param {?proto.accountpb.Correspondent|undefined} value
 * @return {!proto.accountpb.UpdateCorrespondentResponse} returns this
*/
proto.accountpb.UpdateCorrespondentResponse.prototype.setCorrespondent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.UpdateCorrespondentResponse} returns this
 */
proto.accountpb.UpdateCorrespondentResponse.prototype.clearCorrespondent = function() {
  return this.setCorrespondent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.UpdateCorrespondentResponse.prototype.hasCorrespondent = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.DeleteCorrespondentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.DeleteCorrespondentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.DeleteCorrespondentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteCorrespondentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.DeleteCorrespondentRequest}
 */
proto.accountpb.DeleteCorrespondentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.DeleteCorrespondentRequest;
  return proto.accountpb.DeleteCorrespondentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.DeleteCorrespondentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.DeleteCorrespondentRequest}
 */
proto.accountpb.DeleteCorrespondentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.DeleteCorrespondentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.DeleteCorrespondentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.DeleteCorrespondentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteCorrespondentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string correspondent_id = 1;
 * @return {string}
 */
proto.accountpb.DeleteCorrespondentRequest.prototype.getCorrespondentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.DeleteCorrespondentRequest} returns this
 */
proto.accountpb.DeleteCorrespondentRequest.prototype.setCorrespondentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.DeleteCorrespondentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.DeleteCorrespondentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.DeleteCorrespondentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteCorrespondentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.DeleteCorrespondentResponse}
 */
proto.accountpb.DeleteCorrespondentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.DeleteCorrespondentResponse;
  return proto.accountpb.DeleteCorrespondentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.DeleteCorrespondentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.DeleteCorrespondentResponse}
 */
proto.accountpb.DeleteCorrespondentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.DeleteCorrespondentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.DeleteCorrespondentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.DeleteCorrespondentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteCorrespondentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string correspondent_id = 1;
 * @return {string}
 */
proto.accountpb.DeleteCorrespondentResponse.prototype.getCorrespondentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.DeleteCorrespondentResponse} returns this
 */
proto.accountpb.DeleteCorrespondentResponse.prototype.setCorrespondentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ListCorrespondentAuditRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ListCorrespondentAuditRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ListCorrespondentAuditRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListCorrespondentAuditRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondentId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ListCorrespondentAuditRequest}
 */
proto.accountpb.ListCorrespondentAuditRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ListCorrespondentAuditRequest;
  return proto.accountpb.ListCorrespondentAuditRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ListCorrespondentAuditRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ListCorrespondentAuditRequest}
 */
proto.accountpb.ListCorrespondentAuditRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorrespondentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ListCorrespondentAuditRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ListCorrespondentAuditRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ListCorrespondentAuditRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListCorrespondentAuditRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondentId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 correspondent_id = 1;
 * @return {number}
 */
proto.accountpb.ListCorrespondentAuditRequest.prototype.getCorrespondentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.ListCorrespondentAuditRequest} returns this
 */
proto.accountpb.ListCorrespondentAuditRequest.prototype.setCorrespondentId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.accountpb.ListCorrespondentAuditResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ListCorrespondentAuditResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ListCorrespondentAuditResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ListCorrespondentAuditResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListCorrespondentAuditResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondentList: jspb.Message.toObjectList(msg.getCorrespondentList(),
    proto.accountpb.Correspondent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ListCorrespondentAuditResponse}
 */
proto.accountpb.ListCorrespondentAuditResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ListCorrespondentAuditResponse;
  return proto.accountpb.ListCorrespondentAuditResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ListCorrespondentAuditResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ListCorrespondentAuditResponse}
 */
proto.accountpb.ListCorrespondentAuditResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Correspondent;
      reader.readMessage(value,proto.accountpb.Correspondent.deserializeBinaryFromReader);
      msg.addCorrespondent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ListCorrespondentAuditResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ListCorrespondentAuditResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ListCorrespondentAuditResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListCorrespondentAuditResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.accountpb.Correspondent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Correspondent correspondent = 1;
 * @return {!Array<!proto.accountpb.Correspondent>}
 */
proto.accountpb.ListCorrespondentAuditResponse.prototype.getCorrespondentList = function() {
  return /** @type{!Array<!proto.accountpb.Correspondent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.accountpb.Correspondent, 1));
};


/**
 * @param {!Array<!proto.accountpb.Correspondent>} value
 * @return {!proto.accountpb.ListCorrespondentAuditResponse} returns this
*/
proto.accountpb.ListCorrespondentAuditResponse.prototype.setCorrespondentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.accountpb.Correspondent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.Correspondent}
 */
proto.accountpb.ListCorrespondentAuditResponse.prototype.addCorrespondent = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.accountpb.Correspondent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ListCorrespondentAuditResponse} returns this
 */
proto.accountpb.ListCorrespondentAuditResponse.prototype.clearCorrespondentList = function() {
  return this.setCorrespondentList([]);
};


goog.object.extend(exports, proto.accountpb);
