import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../Buttons/Button";
import Text from "../Typography/Text";
import color from "../../assets/css/colors";
import { ReactComponent as PDF } from "../../assets/icons/pdf-file.svg";
import { ReactComponent as DOC } from "../../assets/icons/doc-file.svg";
import { ReactComponent as XLS } from "../../assets/icons/xls-file.svg";
import { ReactComponent as PPT } from "../../assets/icons/ppt-file.svg";
import { ReactComponent as TXT } from "../../assets/icons/txt-file.svg";
import fontFamily from "../../assets/css/fonts";

const useStyles = makeStyles(() => ({
    attachmentWrapper: {
        marginTop: 10,
        padding: 15,
        border: "1px dashed " + color.gray.light,
        borderRadius: 8,
        fontFamily: fontFamily.label,
        fontSize: 14,
        color: color.default.light,
    },
    button: {
        width: 150,
        display: "block",
    },
    buttonWrapper: {
        display: "flex",
        "& .grd-cell": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        "& .grd-cell-none:first-child": {
            marginRight: 10,
        },
    },
    fileWrapper: {
        display: "flex",
        alignItems: "flex-start",
        marginBottom: 20,
        "& div": {
            width: 125,
            flex: "none",
            marginRight: 20,
            textAlign: "center",
            wordBreak: "break-all",
            "& label": {
                marginTop: 10,
                display: "-webkit-box !important",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                whiteSpace: "normal",
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
        },
        "& svg": {
            width: 60,
            height: 60,
        },
    },
}));

const FileAttachments = (props) => {
    const classes = useStyles();

    const { attachments, title, onChange, setAttachments, required, msg, error, disabled, allowVideo } = props;

    let fileTypes = [];

    for (let i = 0; i < attachments.length; i++) {
        let fileName = attachments[i].name;
        let fileType = attachments[i].name.split(".").pop();

        fileTypes.push({ name: fileName, type: fileType });
    }
    
    const FileIcon = (file) => {
        if (file.type === "xlsx") {
            return <XLS />;
        } else if (file.type === "docx") {
            return <DOC />;
        } else if (file.type === "pdf") {
            return <PDF />;
        } else if (file.type === "ppt") {
            return <PPT />;
        } else {
            return <TXT />;
        }
    };

    const handleClear = () => {
        setAttachments([]);
    };

    return (
        <div>
            <Text mt={20} variant="subtitle1" label={title}  display={'inline-block'} error={error}/>
            {required && (
                <Text mt={20} ml={2} variant="subtitle1" label=" *" display={'inline-block'} error={required}/>
            )}
            <div className={classes.attachmentWrapper}>
                {attachments.length > 0 && (
                    <div className={classes.fileWrapper}>
                        {fileTypes?.map((value, index) => {
                            return (
                                <div key={index}>
                                    <FileIcon type={value.type} />
                                    <label>{value.name}</label>
                                </div>
                            );
                        })}
                    </div>
                )}
                <div className={classes.buttonWrapper}>
                    <div className="grd-cell-none">
                        <input
                            onChange={onChange}
                            accept={allowVideo ?  "image/*, .doc, .docx, .pdf, video/*" : "image/*, .doc, .docx, .pdf"}
                            style={{ display: "none" }}
                            id="attach-file"
                            multiple
                            type="file"
                            required={required}
                            disabled={disabled}
                        />
                        <label className={classes.button} htmlFor="attach-file">
                            <Button
                                type="attachment"
                                component="span"
                                label="Attach File"
                                disabled={disabled}
                            />
                        </label>
                    </div>
                    {attachments.length > 0 && (
                        <div className="grd-cell-none">
                            <Button onClick={handleClear} label="Clear" />
                        </div>
                    )}
                    <div className="grd-cell text-c">
                        <label>
                            {attachments.length > 0
                                ? "(" +
                                  attachments.length +
                                  ") attached file(s)"
                                : "No attached file(s)"}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default FileAttachments;
