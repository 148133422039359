import {
    ListEntryTypeRequest,
    EntryTypeServiceClient,
  } from '../proto/admpb/entrytype_grpc_web_pb';
  import { auth } from '../lib/auth/Auth';
  import { Pagination } from '../proto/utilspb/pagination_pb';
  
  const service = new EntryTypeServiceClient(
    window.env.GRPC_ENDPOINT,
    {},
    { ...auth }
  );
  
  export async function listEntryType(param, paging) {
    return new Promise((resolve, reject) => {
      let req = new ListEntryTypeRequest();
      req.setEntryType(param.entryType);
      req.setCorrespondent(param.correspondent);
      req.setAccountNo(param.accountNo);
      req.setScreenType(param.screenType);
      req.setStatus(param.status);

      if (paging) {
        let pg = new Pagination();
        pg.setPageSize(param.pageSize);
        pg.setPageNo(param.setPageNo);
        pg.setSortName(param.sortName);
        pg.setSortDirection(param.sortDirection);
        req.setPagination(pg);
      }
  
      service.listEntryType(req, {}, (error, response) => {
        if (error) {
          reject(error);
        } else {
          resolve(response.toObject());
        }
      });
    });
  }