import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, IconButton, Tooltip } from '@material-ui/core';
import PasswordRequirement from "../Misc/PasswordRequirement";
import color from '../../assets/css/colors'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InfoIcon from '@material-ui/icons/Info';
import clsx from 'clsx';
import MUIIconButton from '@material-ui/core/IconButton';
import LockIcon from '@material-ui/icons/Lock';
import SvgIcon from "@material-ui/core/SvgIcon";

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: color.light.main,
        maxWidth: 620,
        border: '1px solid ' + color.light.light,
        borderRadius: 10,
    },
}))(Tooltip);

const APWPasswordField = (props) => {


    const { classes } = props

    const [isFocus, setFocus] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);

    const startIcon = () => {
        return (<InputAdornment position="start">
            <MUIIconButton edge="start"><LockIcon className={classes.icon} fontSize="small" /></MUIIconButton>
        </InputAdornment>);
    }

    const endIcon = () => {
        return (
            <InputAdornment position="end">
                <IconButton
                    edge="end"
                    aria-label="toggle password visibility"
                    onClick={(e) => { setShowPassword(!showPassword) }}
                    onMouseDown={handleMouseDownPassword}
                >
                    {showPassword ? <VisibilityIcon className={classes.icon} fontSize="small" /> : <VisibilityOffIcon className={classes.icon} fontSize="small" />}
                </IconButton>
            </InputAdornment>
        );
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div className={classes.textFieldWrapper}>
            {(props.showPasswordRequirement && isFocus) ? (
                <div className={classes.passwordRequirementWrapper}>
                    <PasswordRequirement password={props.value} />
                </div>
            ) : (null)}
            <TextField
                className={clsx(classes.margin)}
                variant="outlined"
                margin='dense'
                fullWidth={true}
                autoFocus={props.autoFocus}
                error={props.error}
                required={props.required}
                disabled={props.disabled}
                id={props.id}
                name={props.name}
                label={props.label}
                placeholder={props.placeholder}
                helperText={props.error ? props.msg : ''}
                value={props.value || ''}
                onChange={(e) => {
                    const input = e.currentTarget.name ? e.currentTarget : e.target;
                    props.onChange(input.name, input.value.trimStart());
                }}
                onKeyUp={props.onKeyUp}
                onFocus={() => { setFocus(true) }}
                onBlur={() => { setFocus(false) }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    startAdornment: props.showIcon ? startIcon() : null,
                    endAdornment: ['password', 'confirmPassword', 'newPassword', 'lastPassword', 'oldPassword'].includes(props.name) ? endIcon() : null,
                }}
                inputProps={{
                    readOnly: props.readOnly ? true : false,
                    maxLength: props.max,
                    type: showPassword ? 'text' : 'password',
                    autoComplete: 'new-password',
                    color: 'primary',
                }}
            />
            {(props.tooltip || props.htmlTooltip) && (
                <div className={classes.tooltipWrapper} style={{ marginTop: props.mt ? 20 : 0 }}>
                    {props.tooltip ? (
                        <Tooltip
                            arrow
                            placement="top"
                            title={props.tooltip}
                        >
                            <InfoIcon fontSize="small" />
                        </Tooltip>
                    ) : (
                        <HtmlTooltip
                            placement="right"
                            title={<React.Fragment>{props.htmlTooltip}</React.Fragment>}
                        >
                            <InfoIcon fontSize="small" />
                        </HtmlTooltip>
                    )}
                </div>
            )}
        </div>
    );
}

export default withStyles((theme) => ({
    textFieldWrapper: {
        display: 'flex',
        position: 'relative',
    },
    passwordRequirementWrapper: {
        position: 'absolute',
        top: -195,
        left: 0,
        zIndex: 2,
        backgroundColor: color.dark.main,
        border: '1px solid ' + color.dark.dark,
        borderRadius: 5,
        padding: 20,
        width: '100%',
        boxSizing: 'border-box',
        '& > div:first-child': {
            margin: 0,
            '& > label': {
                marginBottom: 10,
                display: 'block',
            },
            '& > div:nth-child(2)': {
                flexDirection: 'column',
                '& > div': {
                    margin: 0,
                    '& > ul': {
                        marginBottom: 0,
                        marginTop: 0,
                    },
                },
            },
        },
    },
    tooltipWrapper: {
        position: 'absolute',
        top: 31,
        right: 1,
        padding: '9px 10px',
        backgroundColor: color.light.opacity,
        borderRadius: 5,
        '& svg': {
            width: 21,
            height: 21,
            cursor: 'help',
            '& path': {
                fill: color.primary.main,
            }
        },
    },
    margin: {},
    icon: {
        '&:hover': {
          backgroundColor: 'transparent !important', // Set the background color to transparent on hover
        },
      },
}), { withTheme: true, name: 'APWPasswordField' })(APWPasswordField);