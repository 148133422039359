import React, { useEffect, useState } from 'react';
import authSvc from '../../services/AuthService';

export default function Loginuser() {
    const [result, setResult]= useState('Loading...')
    useEffect(() => {
    
    
    const login = async () => {
        const data = {
            i_token :localStorage.getItem('i_token'),
            i_user : localStorage.getItem('i_user')
        }
        console.log(data);
        try{
            const userId = await authSvc.loginuser(data);
            window.location='/app';
        } catch (error) {
            setResult("Error: user not found")
            console.error(error)
            return;
          }

    }

   login();
        
    }, []);
  
   return(<>{result}</>)
  
   
  }
  