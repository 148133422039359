import React, { useState, useEffect } from 'react';
import {
  Switch,
  Route,
  withRouter,
  Redirect,
  NavLink,
  useHistory,
} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Menu,
  MenuItem,
  Divider,
  Modal,
  Backdrop,
  Fade,
} from '@material-ui/core';
import { getCorrespondentClientNoticeDisclosure } from '../../services/DisclosureService';
import Text from '../Typography/Text';
import { ReactComponent as User } from '../../assets/icons/user.svg';
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';
import { ContactSupport as ContactSupportIcon } from '@material-ui/icons';
import { Notification, notifyError } from '../Messages/Notification';
import SideBar from '../Misc/SideBar';
import ChangePasswordModal from '../Modals/ChangePasswordModal';
import MultiFactorAuthenticationModal from '../Modals/MultiFactorAuthenticationModal';
import ClientNoticeModal from '../Modals/ClientNoticeModal';
import HelpModal from '../Modals/HelpModal';
import Position from '../../pages/Reports/Position/Position';
import Balance from '../../pages/Reports/Balance/Balance';
import Activity from '../../pages/Reports/Activity/Activity';
import MarginRequirement from '../../pages/Reports/MarginRequirement/MarginRequirement';
import MarginInterestCalc from '../../pages/Reports/MarginInterestCalc/MarginInterestCalc';
import CallLog from '../../pages/Reports/CallLog/CallLog';
import TradeConfirm from '../../pages/Reports/TradeConfirm/TradeConfirm';
import Statement from '../../pages/Reports/Statement/Statement';
import Dashboard from '../../pages/Dashboard/Dashboard';
import BankAccount from '../../pages/Banking/BankAccount/BankAccount';
import BankSummary from '../../pages/Banking/BankSummary/BankSummary';
import AchWireRequest from '../../pages/Banking/AchWireRequest/AchWireRequest';
import Wallet from '../../pages/Banking/Wallet/Wallet';
import WalletAddressBook from '../../pages/Banking/WalletAddressBook/WalletAddressBook';
import DigitalCashRequest from '../../pages/Banking/DigitalCashRequest/DigitalCashRequest';
import AccountSetup from '../../pages/AccountSetup/ClientAccount';
import TaxFiles from '../../pages/TaxFiles/TaxFiles';
import Invoices from '../../pages/Invoices/Invoices';
import ExerciseDeclaration from '../../pages/Options/ExerciseDeclaration/ExerciseDeclaration';
import Entitlement from '../../pages/Reports/Entitlement/Entitlement';
import Modeling from '../../pages/Model/Modeling/Modeling';
import OrderEntry from '../../pages/Trade/OrderEntry/OrderEntry';
import FileManager from '../../pages/FileManager/FileManager';
import Form1042DIVNRA from '../../pages/TaxFiling/Form1042DIVNRA/Form1042DIVNRA'
import Form1099B from '../../pages/TaxFiling/Form1099B/Form1099B'
import Form1099INT from '../../pages/TaxFiling/Form1099INT/Form1099INT'
import Form1099DIV from '../../pages/TaxFiling/Form1099DIV/Form1099DIV'
import logo from '../../assets/images/sas-logo.png';
import color from '../../assets/css/colors';
import fontFamily from '../../assets/css/fonts';
import authSvc, { getCurrentUser, validateSession } from '../../services/AuthService';
import { readClient } from '../../services/ClientAccountService';
import { verifyToken } from 'authenticator';
import { readAdministrator, setKey } from '../../services/AdministratorService';
import { pages } from '../../lib/data/pages';
import moment from 'moment-timezone';
import { notifySuccess } from '../Messages/Notification';
import ReorgAnnouncement from '../../pages/Reports/ReorgAnnouncement/ReorgAnnouncement';
import ReportBalanceSummary from '../../pages/Reports/ReportBalanceSummary/ReportBalanceSummary';
import FeeManager from '../../pages/Reports/FeeManager/FeeManager';
import ContactUsModal from '../Modals/ContactAddUsModal';
import ContactUs from '../../pages/ContactUs/ContactUs';

import { getLogo } from '../../services/TemplateService';
import Ticket from '../../pages/Ticket/Ticket';
//for userguide
import { readUserGuide } from '../../services/UserGuideService';
import { readFieldDefinition } from '../../services/FieldDefinitionService';
import FieldDefinitionTableView from '../../pages/FieldDefinitionTableView/FieldDefinitionTableView';
import StockBorrowCharge from '../../pages/StockBorrowCharge/StockBorrowCharge';
import Administrator from '../../pages/Administrator/Administrator';
import RoleManager from '../../pages/RoleManager/RoleManager';
import { useConfirm } from 'material-ui-confirm';

const useStyles = makeStyles(() => ({
  menu: {
    flex: 1,
    '& ul': {
      margin: 0,
      listStyle: 'none',
      display: 'flex',
      '& li > a': {
        textDecoration: 'none',
        color: color.default.main,
        fontFamily: fontFamily.headers,
        fontWeight: 500,
        fontSize: 15,
        padding: 21,
        display: 'block',
        '&.active': {
          color: color.dark.opacity,
          backgroundColor: color.primary.main,
          '& svg > path': {
            fill: color.dark.opacity,
          },
        },
      },
    },
  },
  badge: {
    padding: '7px 10px',
    borderRadius: 4,
    fontFamily: fontFamily.headers,
    backgroundImage: 'unset',
    backgroundColor: color.primary.opacity,
    borderColor: color.primary.opacity,
    color: color.primary.main,
    border: '1px solid',
    borderImageSource: 'unset',
    '& span': {
      fontSize: 14,
      textTransform: 'capitalize',
    },
    '&:hover': {
      backgroundColor: color.primary.opacity,
      borderColor: color.primary.main,
      opacity: 1,
    },
    '&:active': {
      '& svg path': {
        fill: color.light.main,
      },
    },
  },
  avatar: {
    width: 30,
    height: 30,
    marginRight: 10,
    display: 'inline-block',
    backgroundColor: color.primary.main,
    borderRadius: 4,
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '30px',
    '& svg': {
      marginTop: 4,
      width: 20,
      hieght: 20,
      '& path': {
        fill: color.light.main,
      },
    },
  },
  userInfo: {
    padding: 17,
    fontFamily: fontFamily.label,
    fontSize: 14,
    '& label': {
      display: 'block',
    },
    '& label:first-child': {
      marginBottom: 5,
      fontWeight: 600,
    },
  },
  divider: {
    margin: '10px 0px',
  },
  userGuidePreview: {
    '& table': {
      '& thead': {
        '& th': {
          border: '1px solid #e1e1e1',
          borderBottom: '2px solid #333',
          padding: '.4em',
          backgroundClip: 'padding-box',
          backgroundColor: '#f3f3f3',
        },
      },
      '& tbody': {
        '& td': {
          border: '1px solid #e1e1e1',
          padding: '.4em',
          backgroundClip: 'padding-box',
        },
      },
      width: '100%',
      borderCollapse: 'collapse',
    },
  },
  userGuideButton: {
    backgroundColor: '#181b1e !important',
    borderTopLeftRadius: '5px !important',
    borderBottomLeftRadius: '5px !important',
    borderTopRightRadius: '0px !important',
    borderBottomRightRadius: '0px !important',
    color: '#ffffff !important',
    display: 'inline-block !important',
    padding: '5px !important',
    height: '32px !important',
    minWidth: '33px !important',
    position: 'absolute !important',
    right: 0,
    marginTop: '45px !important',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
    '&:hover, &.Mui-focusVisible': { backgroundColor: '#4055d0' },
  },
  contactUsButton: {
    backgroundColor: '#181b1e !important',
    borderTopLeftRadius: '5px !important',
    borderBottomLeftRadius: '5px !important',
    borderTopRightRadius: '0px !important',
    borderBottomRightRadius: '0px !important',
    color: '#ffffff !important',
    display: 'inline-block !important',
    padding: '5px !important',
    height: '32px !important',
    minWidth: '33px !important',
    position: 'absolute !important',
    right: 0,
    marginTop: '95px !important',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px',
    '&:hover, &.Mui-focusVisible': { backgroundColor: '#4055d0' },
  },
  pulse: {
    backgroundColor: '#1e7dff',
    width: 10,
    height: 10,
    borderRadius: 10,
    display: 'block',
    position: 'absolute',
    top: '-4px',
    left: '-4px',
    boxShadow: '0px 0px 4px #1e7dff',
  },
  fadeModalFull: {
    maxWidth: 1400,
    maxHeight: '90vh',
    width: '100%',
    padding: '25px 30px 30px 30px',
    margin: '0px auto',
    backgroundColor: '#ffffff',
    border: 'none',
    borderRadius: 5,
    boxSizing: 'border-box',
    outline: 'none',
    overflowY: 'auto',
  },
  textBold: {
    fontWeight: 'bold',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 1400,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
}));

function Main(props) {
  const { location } = props;

  const classes = useStyles();
  const history = useHistory();

  const MINUTE_MS = 10000;

  const activeUser = authSvc.getCurrentUser();
  const activeUserEmail = activeUser?.Username;
  const userType = activeUser?.UserType;
  const status = activeUser?.Status;

  const currentPage = location.pathname.split('/').pop().replaceAll('-', ' ');
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [open2FA, setOpen2FA] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);
  const [openContactUs, setOpenContactUs] = useState(false);
  const [openClientNotice, setOpenClientNotice] = useState(false);
  const [clientNotice, setClientNotice] = useState('');
  const [isClientNoticeFetched, setIsClientNoticeFetched] = useState(false);
  const [pageTitle, setPageTitle] = useState('');
  const [pageAccess, setPageAccess] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clientLogo, setLogo] = useState(null);
  const [toggled, setToggled] = useState(status === 'Active' ? true : false);

  //for userguide
  const [openModal, setOpenModal] = useState(false);
  const [selectedUserGuide, setSelectedUserGuide] = useState('');
  const [rows, setRows] = useState([]);
  const [pageName, setPageName] = React.useState('');
  const [clientStatus, setClientStatus] = useState('');

  const [landingPage, setLandingPage] = useState('/app/dashboard');
  const confirm = useConfirm();

  const reports = [
    'position',
    'balance',
    'balance summary',
    'activity',
    'margin requirement',
    'margin interest calc',
    'daily trade confirm',
    'monthly statement',
    'entitlement',
    'announcement',
  ];

  const textTransform = (words) => {
    let separateWord = words.toLowerCase().split(' ');

    for (let i = 0; i < separateWord.length; i++) {
      separateWord[i] =
        separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
    }

    return separateWord.join(' ');
  };

  const handleCheckAccessUpdate = async () => {
    try {
      await authSvc.refreshAccessPromise();

      const userAccess = authSvc.getUserAccess();
      const access = Object.entries(userAccess)
        .filter((value) => ['Full', 'Read'].includes(value[1]))
        .map((value) => {
          return {
            pageKey: value[0],
            access: value[1],
          };
        });

      setPageAccess(access);
      if (['Master Account', 'Counterparty', 'User'].includes(activeUser.UserType)) {
        setLandingPage('app/bank-summary');
      }
    } catch (error) {
      console.error(error);
      notifyError(error.message);
    }
  };

  const handleGetLogo = async () => {
    try {
      const serverLogo = await getLogo();
      if (serverLogo.correspondent.photo !== '') {
        setLogo(serverLogo.correspondent.photo);
      } else {
        setLogo(logo);
      }
    } catch (error) {
      setLogo(logo);
    }
  };

  const getClientNoticeDisclosure = async () => {
    const data = await getCorrespondentClientNoticeDisclosure();
    setClientNotice(data);
    setIsClientNoticeFetched(true);
  };

  useEffect(() => {
    getClientNoticeDisclosure();
  }, []);

  const getAccountClientStatus = async () => {
    try {
      const res = await readClient(activeUser.AccountId);
      setClientStatus(res.client.status);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAccountClientStatus();
  }, []);

  useEffect(() => {}, []);

  useEffect(() => {
    setPageName('');
    setSelectedUserGuide('');
    setRows([]);

    const url = props.location.pathname.split('/');

    setPageTitle(textTransform(currentPage));

    setPageName(textTransform(url[2].replaceAll('-', ' ')));

    handleCheckAccessUpdate();
    handleGetLogo();
    handleCheckClientNotice();

    // eslint-disable-next-line
  }, [currentPage]);

  const handleValidateSession = async () => {
    try {
      const accessToken = localStorage.getItem('access_token');

      const validateSessionParams = {
        usrId: getCurrentUser()?.UserId,
        email: getCurrentUser()?.Username,
        accessToken: accessToken,
      };

      await validateSession(validateSessionParams);
    } catch (error) {
      if (error.message === 'App not found') {
        handleLogout();
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.env.ACTIVATE_SINGLE_SIGNIN) {
        handleValidateSession();
      }
    }, MINUTE_MS);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    setOpenClientNotice(false);
    setOpenModal(false);
  };

  const handleCloseContactUs = () => {
    setOpenContactUs(false);
  };

  const handleChangePassword = () => {
    setOpen(true);
  };

  const handle2faOpen = () => {
    setOpen2FA(true);
  };

  const handleHelpOpen = () => {
    history.push('/app/ticket');
  };

  const handleHelpClose = async (data, action) => {
    setOpenHelp(false);
  };

  const handle2faClose = async (data, action) => {
    if (action === 'verify') {
      if (data.code === '') {
        notifyError('Please input verification code.');
      }
      if (verifyToken(data.secretKey, data.code) == null) {
        notifyError('Invalid verification code.');
      } else {
        setLoading(true);
        try {
          const resp = await setKey(data);
          var response = resp;
          response.createdAt = response.createdAt
            ? moment(new Date(response.createdAt.seconds * 1000)).format(
                'MM/DD/YYYY hh:mm'
              )
            : '--';
          response.password = '';
          notifySuccess('Setup account success.');

          setOpen2FA(false);
        } catch (error) {
          notifyError(error.message);
        }
        setLoading(false);
      }
    } else {
      setOpen2FA(false);
    }
  };

  const handleLogout = () => {
    confirm({
      description:
        "You are about to logout from the system.",
      confirmationText: 'Yes, logout',
    }).then(async () => {
      authSvc.logout();

      if (window.env.ACTIVATE_SINGLE_SIGNIN) {
        window.location.replace(window.env.COMMON_LOGIN_URL + '/logout');
      } else {
        window.location = '/login';
      }
      }); 
  };

  const handleCheckClientNotice = async () => {
    if (isClientNoticeFetched && !clientNotice) {
      return;
    }

    try {
      const { administrator } = await readAdministrator(activeUser.UserId);

      if (administrator.clientNotice) {
        setOpenClientNotice(true);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  if (!reports.includes(currentPage)) {
    localStorage.removeItem('search_data');
  }

  const accessibleRoute = (menu) => {
    const accessibleRoutes = [];

    pages
      .filter((page) => page.groupLabel === menu)
      .forEach((page) => {
        const key = (page.menu + page.groupLabel + page.page).replace(/ /g, '');
        pageAccess.forEach((access) => {
          if (access.pageKey === key) {
            accessibleRoutes.push(page.link);
          }
        });
      });
    return accessibleRoutes;
  };

  const handleOpen = async () => {
    setOpenModal(true);
    setSelectedUserGuide('Please wait while we check for records...');

    const param = await getParam();
    const resp = await readUserGuide(param ? param.pageId : 0);
    const resp2 = await readFieldDefinition(param ? param.userGuideId : 0);

    setSelectedUserGuide(
      resp.userGuide.userGuideId
        ? resp.userGuide.content
        : 'Sorry, no user guide is created for this page.'
    );

    setRows(resp2?.fieldDefinitionsList);
  };

  const getParam = async () => {
    // # TEMP FIX FOR USER GUIDE
    let pageLabel = pageName;
    if (pageName === 'Account Setup') {
      pageLabel = 'Client Account';
    } else if (pageName === 'Ach Wire Request') {
      pageLabel = 'ACH/Wire';
    }

    const params = props.pages
      .filter(function (v) {
        return [pageName.toUpperCase(), pageLabel.toUpperCase()].includes(
          v.pageName.toUpperCase()
        );
      })
      .map((v) => ({
        pageId: v.pageId,
        userGuideId: v.userGuideId,
      }));

    return params[0];
  };

  const isRouteAccessible = (menu, link) => {
    if (menu === 'Option'){
      return accessibleRoute(menu).includes(link) || accessibleRoute('Options').includes(link);
    }
    return accessibleRoute(menu).includes(link);
  };

  return (
    <div className="main-w">
      <Helmet>
        <title>SAS Client | {pageTitle}</title>
      </Helmet>
      <div
        className="main-h-c"
        style={{
          paddingLeft:
            toggled &&
            status === 'Active' &&
            (clientStatus === 'Active' || userType !== 'Client')
              ? '315px'
              : '40px',
        }}
      >
        <div>
          {status === 'Active' &&
            (clientStatus === 'Active' || userType !== 'Client') && (
              <SideBar
                userType={userType}
                pageAccess={pageAccess}
                checkAccessUpdate={handleCheckAccessUpdate}
                setToggled={setToggled}
                status={status}
              />
            )}
        </div>
        <div>
          <NavLink exact={true} to={landingPage}>
            <img
              src={clientLogo}
              alt="Logo"
              style={{ width: 'auto', maxHeight: '30px' }}
            />
          </NavLink>
        </div>
        <div className={classes.menu}></div>
        <div>
          <div className="badge-toggle">
            <Button className={classes.badge} onClick={handleClick}>
              <span className={classes.avatar}>
                <User />
              </span>{' '}
              {activeUser?.Name}
            </Button>
          </div>
          <Menu
            keepMounted
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className="header-menu-popup"
          >
            <div className={classes.userInfo}>
              <label>Email Address:</label>
              <label>{activeUserEmail}</label>
            </div>
            <Divider className={classes.divider} />
            <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
            <MenuItem onClick={handle2faOpen}>Setup MFA</MenuItem>
            <MenuItem onClick={handleHelpOpen}>Help</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </div>
      <div
        className="main-b-c"
        style={{
          paddingLeft:
            toggled &&
            status === 'Active' &&
            (clientStatus === 'Active' || userType !== 'Client')
              ? '280px'
              : '0px',
        }}
      >
        {pageAccess.length > 0 && (
          <>
            <Button className={classes.userGuideButton} onClick={handleOpen}>
              <span className={classes.pulse}></span>
              <InfoOutlinedIcon style={{ fontSize: 22 }} />
            </Button>
            <Button className={classes.contactUsButton} onClick={() => setOpenContactUs(true)}>
              <ContactSupportIcon style={{ fontSize: 22 }} />
            </Button>
            <Switch>
              <Route path="/app/dashboard" component={Dashboard} />
              <Route path="/app/ticket" component={Ticket} />
              <Route
                path="/app/position"
                render={(props) =>
                  isRouteAccessible('Reports', '/app/position') ? (
                    <Position {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/balance"
                render={(props) =>
                  isRouteAccessible('Reports', '/app/balance') ? (
                    <Balance {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/balance-summary"
                render={(props) =>
                  isRouteAccessible('Reports', '/app/balance-summary') ? (
                    <ReportBalanceSummary {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/activity"
                render={(props) =>
                  isRouteAccessible('Reports', '/app/activity') ? (
                    <Activity {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/margin-requirement"
                render={(props) =>
                  isRouteAccessible('Reports', '/app/margin-requirement') ? (
                    <MarginRequirement {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/call-log"
                render={(props) =>
                  isRouteAccessible('Reports', '/app/call-log') ? (
                    <CallLog {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/margin-interest-calc"
                render={(props) =>
                  isRouteAccessible('Reports', '/app/margin-interest-calc') ? (
                    <MarginInterestCalc {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/daily-trade-confirm"
                render={(props) =>
                  isRouteAccessible('Reports', '/app/daily-trade-confirm') ? (
                    <TradeConfirm {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/monthly-statement"
                render={(props) =>
                  isRouteAccessible('Reports', '/app/monthly-statement') ? (
                    <Statement {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/bank-summary"
                render={(props) =>
                  isRouteAccessible('Banking', '/app/bank-summary') ? (
                    <BankSummary {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/bank-account"
                render={(props) =>
                  isRouteAccessible('Banking', '/app/bank-account') ? (
                    <BankAccount {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/ach-wire-request"
                render={(props) =>
                  isRouteAccessible('Banking', '/app/ach-wire-request') ? (
                    <AchWireRequest {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/wallet"
                render={(props) =>
                  isRouteAccessible('Banking', '/app/wallet') ? (
                    <Wallet {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/digital-cash-request"
                render={(props) =>
                  isRouteAccessible('Banking', '/app/digital-cash-request') ? (
                    <DigitalCashRequest {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/wallet-address-book"
                render={(props) =>
                  isRouteAccessible('Banking', '/app/wallet-address-book') ? (
                    <WalletAddressBook {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/exercise-declaration"
                render={(props) =>
                  isRouteAccessible('Option', '/app/exercise-declaration') ? (
                    <ExerciseDeclaration {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/account-setup"
                render={(props) =>
                  isRouteAccessible('Account Setup', '/app/account-setup') ? (
                    <AccountSetup {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/entitlement"
                render={(props) =>
                  isRouteAccessible('Reports', '/app/entitlement') ? (
                    <Entitlement {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/announcement"
                render={(props) =>
                  isRouteAccessible('Reports', '/app/announcement') ? (
                    <ReorgAnnouncement {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/fee-manager"
                render={(props) =>
                  isRouteAccessible('Reports', '/app/fee-manager') ? (
                    <FeeManager {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/modeling"
                render={(props) =>
                  isRouteAccessible('Model', '/app/modeling') ? (
                    <Modeling {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/order-entry"
                render={(props) =>
                  userType !== 'Employer Member' ? (
                    <OrderEntry {...props} />
                  ) : (
                    <Redirect to="/app/order-entry" />
                  )
                }
              />
              {/* <Route
                path="/app/tax-files"
                render={(props) =>
                  userType !== 'Employer Member' ? (
                    <TaxFiles {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              /> */}
              <Route
                path="/app/invoices"
                render={(props) =>
                  userType !== 'Employer Member' ? (
                    <Invoices {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/stock-borrow"
                render={(props) =>
                  userType !== 'Employer Member' ? (
                    <StockBorrowCharge {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/file-manager"
                render={(props) =>
                  userType !== 'Employer Member' ? (
                    <FileManager {...props} />
                  ) : (
                    <Redirect to="/app/file-manager" />
                  )
                }
              />
              <Route
                path="/app/user"
                render={(props) =>
                  isRouteAccessible('Account Setup', '/app/user') ? (
                    <Administrator {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/role-manager"
                render={(props) =>
                  isRouteAccessible('Account Setup', '/app/role-manager') ? (
                    <RoleManager {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/form-1042-divnra"
                render={(props) =>
                  isRouteAccessible('Tax Filing', '/app/form-1042-divnra') ? (
                    <Form1042DIVNRA {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/form-1099-b"
                render={(props) =>
                  isRouteAccessible('Tax Filing', '/app/form-1099-b') ? (
                    <Form1099B {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/form-1099-int"
                render={(props) =>
                  isRouteAccessible('Tax Filing', '/app/form-1099-int') ? (
                    <Form1099INT {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/form-1099-div"
                render={(props) =>
                  isRouteAccessible('Tax Filing', '/app/form-1099-div') ? (
                    <Form1099DIV {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route
                path="/app/contact-us"
                render={(props) =>
                  isRouteAccessible('Contact Us', '/app/contact-us') ? (
                    <ContactUs {...props} />
                  ) : (
                    <Redirect to="/app/dashboard" />
                  )
                }
              />
              <Route path="/app/*" render={() => <Redirect to="/" />} />
            </Switch>
          </>
        )}
      </div>
      <ChangePasswordModal open={open} close={handleClose} />
      {open2FA && (
        <MultiFactorAuthenticationModal
          open={open2FA}
          onClose={handle2faClose}
          id={getCurrentUser()?.UserId}
          loading={loading}
        />
      )}
      {openHelp && (
        <HelpModal
          open={openHelp}
          onClose={handleHelpClose}
          id={getCurrentUser()?.UserId}
          loading={loading}
        />
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={openModal}>
          <div className={classes.fadeModalFull}>
            <div className={classes.modalHeader}>
              <Text
                id="transition-modal-title"
                variant="h2"
                label={'User Guide: ' + pageName}
              />
            </div>
            <div className={classes.modalBody}>
              <div className="grd-row">
                <div
                  className={classes.userGuidePreview}
                  dangerouslySetInnerHTML={{ __html: selectedUserGuide }}
                />
              </div>
              <div className="grd-row">
                {rows?.length !== 0 ? (
                  <FieldDefinitionTableView data={rows}></FieldDefinitionTableView>
                ) : null}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      {openClientNotice && clientNotice && (
        <ClientNoticeModal
          open={openClientNotice}
          close={handleClose}
          clientNotice={clientNotice}
        />
      )}

      {openContactUs && (
        <ContactUsModal open={openContactUs} close={handleCloseContactUs} />
      )}
      <Notification />
    </div>
  );
}
export default withRouter(Main);
