import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tabs as MUITabs, Tab as MUITab, Box as MUIBox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './tabStyles';
import APWTabPanel from './APWTabPanel';

function APWTab(props) {
    const { label, index, classes, ...other } = props;
    return (
        <MUITab
            index={index}
            label={label}
            id={`apw-tab-${index}`}
            aria-controls={`apw-tabpanel-${index}`}
            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
            {...other}
        ></MUITab>
    )
}

APWTab.propTypes = {
    label: PropTypes.any.isRequired,
};

class APWTabs extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { id, variant, classes, value, children, onChange, ...otherProps } = this.props;

        return (
            <React.Fragment>
                <MUITabs
                    dense="true"
                    value={value}
                    onChange={onChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                    variant={variant}
                    {...otherProps}
                    classes={{ root: classes.tabsRoot }}

                >
                    {React.Children.map(children, (child) =>
                        React.cloneElement(child, { classes })
                    )}
                </MUITabs>
            </React.Fragment>
        )

    }


}

APWTabs.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    variant: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func.isRequired,
};

APWTabs.defaultProps = {
    variant: 'fullWidth',
    value: 0,
};

APWTabs.Tab = APWTab
APWTabs.TabPanel = APWTabPanel

export default withStyles(styles.rootStyles, { withTheme: true, name: MUITabs.muiName })(APWTabs);
