import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const styles = (theme) => ({
  root: {
    padding: '4px',
  },
});

// Actions
const APWGridItem = ({ classes, children, provided, innerRef, dragFromItem, ...otherProps }) => {
  return (
    <Grid item {...otherProps} {...(provided && provided.draggableProps)} {...((provided && dragFromItem ) && provided.dragHandleProps)} ref={provided && provided.innerRef}>
      {children}
    </Grid>
  );
};

APWGridItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  provided: PropTypes.object, // make provided optional
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]), // make innerRef optional
  dragFromItem: PropTypes.bool
};

APWGridItem.defaultProps = {
  provided: null,
  innerRef: null,
  dragFromItem: true
};

// Apply styles using withStyles
const StyledAPWGridItem = withStyles(styles)(APWGridItem);

// Root

const APWGrid = ({ classes, children, noGutter, alignItems, provided, innerRef, ...otherProps }) => {
  return (
    <Grid
      container
      direction="row"
      ref={provided && provided.innerRef}
      {...(provided && provided.droppableProps)}
      alignItems={alignItems}
      spacing={2}
      classes={{root: clsx({
        [classes.root]: !noGutter, // always applies
      })}}
      {...otherProps}
    >
      {children}
    </Grid>
  );
};

APWGrid.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  noGutter: PropTypes.bool,
  alignItems: PropTypes.string,
  provided: PropTypes.object, // make provided optional
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]), // make innerRef optional
  muiClasses: PropTypes.object, // make provided optional
};

APWGrid.defaultProps = {
  noGutter: false,
  alignItems: 'center',
  provided: null,
  innerRef: null,
};

APWGrid.Item = StyledAPWGridItem;

export default withStyles(styles, { withTheme: true, name: Grid.muiName })(APWGrid);
