// source: proto/admpb/recurrence.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_type_date_pb = require('../../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.admpb.CreateRecurrenceResponse', null, global);
goog.exportSymbol('proto.admpb.DeleteRecurrenceRequest', null, global);
goog.exportSymbol('proto.admpb.DeleteRecurrenceResponse', null, global);
goog.exportSymbol('proto.admpb.ListRecurrenceRequest', null, global);
goog.exportSymbol('proto.admpb.ListRecurrenceResponse', null, global);
goog.exportSymbol('proto.admpb.Recurrence', null, global);
goog.exportSymbol('proto.admpb.UpdateRecurrenceRequest', null, global);
goog.exportSymbol('proto.admpb.UpdateRecurrenceResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.Recurrence = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.Recurrence, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.Recurrence.displayName = 'proto.admpb.Recurrence';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.ListRecurrenceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.ListRecurrenceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.ListRecurrenceRequest.displayName = 'proto.admpb.ListRecurrenceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.ListRecurrenceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admpb.ListRecurrenceResponse.repeatedFields_, null);
};
goog.inherits(proto.admpb.ListRecurrenceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.ListRecurrenceResponse.displayName = 'proto.admpb.ListRecurrenceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.CreateRecurrenceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.CreateRecurrenceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.CreateRecurrenceResponse.displayName = 'proto.admpb.CreateRecurrenceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.UpdateRecurrenceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.UpdateRecurrenceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.UpdateRecurrenceRequest.displayName = 'proto.admpb.UpdateRecurrenceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.UpdateRecurrenceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.UpdateRecurrenceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.UpdateRecurrenceResponse.displayName = 'proto.admpb.UpdateRecurrenceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.DeleteRecurrenceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.DeleteRecurrenceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.DeleteRecurrenceRequest.displayName = 'proto.admpb.DeleteRecurrenceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.DeleteRecurrenceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.DeleteRecurrenceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.DeleteRecurrenceResponse.displayName = 'proto.admpb.DeleteRecurrenceResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.Recurrence.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.Recurrence.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.Recurrence} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.Recurrence.toObject = function(includeInstance, msg) {
  var f, obj = {
    recurrenceId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    report: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reportDate: (f = msg.getReportDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    functionName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    linkId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    recurring: jspb.Message.getFieldWithDefault(msg, 6, ""),
    day: jspb.Message.getFieldWithDefault(msg, 7, 0),
    month: jspb.Message.getFieldWithDefault(msg, 8, ""),
    monday: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    tuesday: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    wednesday: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    thursday: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    friday: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    saturday: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    sunday: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    settleDate: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    tradeDate: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    calendarDate: jspb.Message.getBooleanFieldWithDefault(msg, 18, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.Recurrence}
 */
proto.admpb.Recurrence.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.Recurrence;
  return proto.admpb.Recurrence.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.Recurrence} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.Recurrence}
 */
proto.admpb.Recurrence.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecurrenceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReport(value);
      break;
    case 3:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setReportDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFunctionName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLinkId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecurring(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDay(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMonth(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMonday(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTuesday(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWednesday(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setThursday(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFriday(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSaturday(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSunday(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSettleDate(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTradeDate(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCalendarDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.Recurrence.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.Recurrence.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.Recurrence} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.Recurrence.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecurrenceId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getReport();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReportDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getFunctionName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLinkId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getRecurring();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getMonth();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMonday();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getTuesday();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getWednesday();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getThursday();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getFriday();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getSaturday();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getSunday();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getSettleDate();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getTradeDate();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getCalendarDate();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
};


/**
 * optional uint32 recurrence_id = 1;
 * @return {number}
 */
proto.admpb.Recurrence.prototype.getRecurrenceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.Recurrence} returns this
 */
proto.admpb.Recurrence.prototype.setRecurrenceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string report = 2;
 * @return {string}
 */
proto.admpb.Recurrence.prototype.getReport = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.Recurrence} returns this
 */
proto.admpb.Recurrence.prototype.setReport = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.type.Date report_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.admpb.Recurrence.prototype.getReportDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.admpb.Recurrence} returns this
*/
proto.admpb.Recurrence.prototype.setReportDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admpb.Recurrence} returns this
 */
proto.admpb.Recurrence.prototype.clearReportDate = function() {
  return this.setReportDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admpb.Recurrence.prototype.hasReportDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string function_name = 4;
 * @return {string}
 */
proto.admpb.Recurrence.prototype.getFunctionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.Recurrence} returns this
 */
proto.admpb.Recurrence.prototype.setFunctionName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 link_id = 5;
 * @return {number}
 */
proto.admpb.Recurrence.prototype.getLinkId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.Recurrence} returns this
 */
proto.admpb.Recurrence.prototype.setLinkId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string recurring = 6;
 * @return {string}
 */
proto.admpb.Recurrence.prototype.getRecurring = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.Recurrence} returns this
 */
proto.admpb.Recurrence.prototype.setRecurring = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint32 day = 7;
 * @return {number}
 */
proto.admpb.Recurrence.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.Recurrence} returns this
 */
proto.admpb.Recurrence.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string month = 8;
 * @return {string}
 */
proto.admpb.Recurrence.prototype.getMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.Recurrence} returns this
 */
proto.admpb.Recurrence.prototype.setMonth = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool monday = 9;
 * @return {boolean}
 */
proto.admpb.Recurrence.prototype.getMonday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admpb.Recurrence} returns this
 */
proto.admpb.Recurrence.prototype.setMonday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool tuesday = 10;
 * @return {boolean}
 */
proto.admpb.Recurrence.prototype.getTuesday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admpb.Recurrence} returns this
 */
proto.admpb.Recurrence.prototype.setTuesday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool wednesday = 11;
 * @return {boolean}
 */
proto.admpb.Recurrence.prototype.getWednesday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admpb.Recurrence} returns this
 */
proto.admpb.Recurrence.prototype.setWednesday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool thursday = 12;
 * @return {boolean}
 */
proto.admpb.Recurrence.prototype.getThursday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admpb.Recurrence} returns this
 */
proto.admpb.Recurrence.prototype.setThursday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool friday = 13;
 * @return {boolean}
 */
proto.admpb.Recurrence.prototype.getFriday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admpb.Recurrence} returns this
 */
proto.admpb.Recurrence.prototype.setFriday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool saturday = 14;
 * @return {boolean}
 */
proto.admpb.Recurrence.prototype.getSaturday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admpb.Recurrence} returns this
 */
proto.admpb.Recurrence.prototype.setSaturday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool sunday = 15;
 * @return {boolean}
 */
proto.admpb.Recurrence.prototype.getSunday = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admpb.Recurrence} returns this
 */
proto.admpb.Recurrence.prototype.setSunday = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool settle_date = 16;
 * @return {boolean}
 */
proto.admpb.Recurrence.prototype.getSettleDate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admpb.Recurrence} returns this
 */
proto.admpb.Recurrence.prototype.setSettleDate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool trade_date = 17;
 * @return {boolean}
 */
proto.admpb.Recurrence.prototype.getTradeDate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admpb.Recurrence} returns this
 */
proto.admpb.Recurrence.prototype.setTradeDate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool calendar_date = 18;
 * @return {boolean}
 */
proto.admpb.Recurrence.prototype.getCalendarDate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admpb.Recurrence} returns this
 */
proto.admpb.Recurrence.prototype.setCalendarDate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.ListRecurrenceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.ListRecurrenceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.ListRecurrenceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ListRecurrenceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    functionName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    linkId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    report: jspb.Message.getFieldWithDefault(msg, 3, ""),
    recurrenceId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.ListRecurrenceRequest}
 */
proto.admpb.ListRecurrenceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.ListRecurrenceRequest;
  return proto.admpb.ListRecurrenceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.ListRecurrenceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.ListRecurrenceRequest}
 */
proto.admpb.ListRecurrenceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFunctionName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLinkId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReport(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecurrenceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.ListRecurrenceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.ListRecurrenceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.ListRecurrenceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ListRecurrenceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFunctionName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLinkId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getReport();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRecurrenceId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional string function_name = 1;
 * @return {string}
 */
proto.admpb.ListRecurrenceRequest.prototype.getFunctionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.ListRecurrenceRequest} returns this
 */
proto.admpb.ListRecurrenceRequest.prototype.setFunctionName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 link_id = 2;
 * @return {number}
 */
proto.admpb.ListRecurrenceRequest.prototype.getLinkId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.ListRecurrenceRequest} returns this
 */
proto.admpb.ListRecurrenceRequest.prototype.setLinkId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string report = 3;
 * @return {string}
 */
proto.admpb.ListRecurrenceRequest.prototype.getReport = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.ListRecurrenceRequest} returns this
 */
proto.admpb.ListRecurrenceRequest.prototype.setReport = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 recurrence_id = 4;
 * @return {number}
 */
proto.admpb.ListRecurrenceRequest.prototype.getRecurrenceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.ListRecurrenceRequest} returns this
 */
proto.admpb.ListRecurrenceRequest.prototype.setRecurrenceId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admpb.ListRecurrenceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.ListRecurrenceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.ListRecurrenceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.ListRecurrenceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ListRecurrenceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recurrenceList: jspb.Message.toObjectList(msg.getRecurrenceList(),
    proto.admpb.Recurrence.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.ListRecurrenceResponse}
 */
proto.admpb.ListRecurrenceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.ListRecurrenceResponse;
  return proto.admpb.ListRecurrenceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.ListRecurrenceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.ListRecurrenceResponse}
 */
proto.admpb.ListRecurrenceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admpb.Recurrence;
      reader.readMessage(value,proto.admpb.Recurrence.deserializeBinaryFromReader);
      msg.addRecurrence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.ListRecurrenceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.ListRecurrenceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.ListRecurrenceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ListRecurrenceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecurrenceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.admpb.Recurrence.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Recurrence recurrence = 1;
 * @return {!Array<!proto.admpb.Recurrence>}
 */
proto.admpb.ListRecurrenceResponse.prototype.getRecurrenceList = function() {
  return /** @type{!Array<!proto.admpb.Recurrence>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.admpb.Recurrence, 1));
};


/**
 * @param {!Array<!proto.admpb.Recurrence>} value
 * @return {!proto.admpb.ListRecurrenceResponse} returns this
*/
proto.admpb.ListRecurrenceResponse.prototype.setRecurrenceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.admpb.Recurrence=} opt_value
 * @param {number=} opt_index
 * @return {!proto.admpb.Recurrence}
 */
proto.admpb.ListRecurrenceResponse.prototype.addRecurrence = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.admpb.Recurrence, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admpb.ListRecurrenceResponse} returns this
 */
proto.admpb.ListRecurrenceResponse.prototype.clearRecurrenceList = function() {
  return this.setRecurrenceList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.CreateRecurrenceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.CreateRecurrenceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.CreateRecurrenceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.CreateRecurrenceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recurrence: (f = msg.getRecurrence()) && proto.admpb.Recurrence.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.CreateRecurrenceResponse}
 */
proto.admpb.CreateRecurrenceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.CreateRecurrenceResponse;
  return proto.admpb.CreateRecurrenceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.CreateRecurrenceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.CreateRecurrenceResponse}
 */
proto.admpb.CreateRecurrenceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admpb.Recurrence;
      reader.readMessage(value,proto.admpb.Recurrence.deserializeBinaryFromReader);
      msg.setRecurrence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.CreateRecurrenceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.CreateRecurrenceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.CreateRecurrenceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.CreateRecurrenceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecurrence();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admpb.Recurrence.serializeBinaryToWriter
    );
  }
};


/**
 * optional Recurrence recurrence = 1;
 * @return {?proto.admpb.Recurrence}
 */
proto.admpb.CreateRecurrenceResponse.prototype.getRecurrence = function() {
  return /** @type{?proto.admpb.Recurrence} */ (
    jspb.Message.getWrapperField(this, proto.admpb.Recurrence, 1));
};


/**
 * @param {?proto.admpb.Recurrence|undefined} value
 * @return {!proto.admpb.CreateRecurrenceResponse} returns this
*/
proto.admpb.CreateRecurrenceResponse.prototype.setRecurrence = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admpb.CreateRecurrenceResponse} returns this
 */
proto.admpb.CreateRecurrenceResponse.prototype.clearRecurrence = function() {
  return this.setRecurrence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admpb.CreateRecurrenceResponse.prototype.hasRecurrence = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.UpdateRecurrenceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.UpdateRecurrenceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.UpdateRecurrenceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.UpdateRecurrenceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    recurrence: (f = msg.getRecurrence()) && proto.admpb.Recurrence.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.UpdateRecurrenceRequest}
 */
proto.admpb.UpdateRecurrenceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.UpdateRecurrenceRequest;
  return proto.admpb.UpdateRecurrenceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.UpdateRecurrenceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.UpdateRecurrenceRequest}
 */
proto.admpb.UpdateRecurrenceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admpb.Recurrence;
      reader.readMessage(value,proto.admpb.Recurrence.deserializeBinaryFromReader);
      msg.setRecurrence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.UpdateRecurrenceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.UpdateRecurrenceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.UpdateRecurrenceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.UpdateRecurrenceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecurrence();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admpb.Recurrence.serializeBinaryToWriter
    );
  }
};


/**
 * optional Recurrence recurrence = 1;
 * @return {?proto.admpb.Recurrence}
 */
proto.admpb.UpdateRecurrenceRequest.prototype.getRecurrence = function() {
  return /** @type{?proto.admpb.Recurrence} */ (
    jspb.Message.getWrapperField(this, proto.admpb.Recurrence, 1));
};


/**
 * @param {?proto.admpb.Recurrence|undefined} value
 * @return {!proto.admpb.UpdateRecurrenceRequest} returns this
*/
proto.admpb.UpdateRecurrenceRequest.prototype.setRecurrence = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admpb.UpdateRecurrenceRequest} returns this
 */
proto.admpb.UpdateRecurrenceRequest.prototype.clearRecurrence = function() {
  return this.setRecurrence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admpb.UpdateRecurrenceRequest.prototype.hasRecurrence = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.UpdateRecurrenceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.UpdateRecurrenceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.UpdateRecurrenceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.UpdateRecurrenceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recurrence: (f = msg.getRecurrence()) && proto.admpb.Recurrence.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.UpdateRecurrenceResponse}
 */
proto.admpb.UpdateRecurrenceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.UpdateRecurrenceResponse;
  return proto.admpb.UpdateRecurrenceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.UpdateRecurrenceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.UpdateRecurrenceResponse}
 */
proto.admpb.UpdateRecurrenceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admpb.Recurrence;
      reader.readMessage(value,proto.admpb.Recurrence.deserializeBinaryFromReader);
      msg.setRecurrence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.UpdateRecurrenceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.UpdateRecurrenceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.UpdateRecurrenceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.UpdateRecurrenceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecurrence();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admpb.Recurrence.serializeBinaryToWriter
    );
  }
};


/**
 * optional Recurrence recurrence = 1;
 * @return {?proto.admpb.Recurrence}
 */
proto.admpb.UpdateRecurrenceResponse.prototype.getRecurrence = function() {
  return /** @type{?proto.admpb.Recurrence} */ (
    jspb.Message.getWrapperField(this, proto.admpb.Recurrence, 1));
};


/**
 * @param {?proto.admpb.Recurrence|undefined} value
 * @return {!proto.admpb.UpdateRecurrenceResponse} returns this
*/
proto.admpb.UpdateRecurrenceResponse.prototype.setRecurrence = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admpb.UpdateRecurrenceResponse} returns this
 */
proto.admpb.UpdateRecurrenceResponse.prototype.clearRecurrence = function() {
  return this.setRecurrence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admpb.UpdateRecurrenceResponse.prototype.hasRecurrence = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.DeleteRecurrenceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.DeleteRecurrenceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.DeleteRecurrenceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.DeleteRecurrenceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    recurrenceId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.DeleteRecurrenceRequest}
 */
proto.admpb.DeleteRecurrenceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.DeleteRecurrenceRequest;
  return proto.admpb.DeleteRecurrenceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.DeleteRecurrenceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.DeleteRecurrenceRequest}
 */
proto.admpb.DeleteRecurrenceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRecurrenceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.DeleteRecurrenceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.DeleteRecurrenceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.DeleteRecurrenceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.DeleteRecurrenceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecurrenceId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 recurrence_id = 1;
 * @return {number}
 */
proto.admpb.DeleteRecurrenceRequest.prototype.getRecurrenceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.DeleteRecurrenceRequest} returns this
 */
proto.admpb.DeleteRecurrenceRequest.prototype.setRecurrenceId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.DeleteRecurrenceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.DeleteRecurrenceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.DeleteRecurrenceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.DeleteRecurrenceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    recurrence: (f = msg.getRecurrence()) && proto.admpb.Recurrence.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.DeleteRecurrenceResponse}
 */
proto.admpb.DeleteRecurrenceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.DeleteRecurrenceResponse;
  return proto.admpb.DeleteRecurrenceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.DeleteRecurrenceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.DeleteRecurrenceResponse}
 */
proto.admpb.DeleteRecurrenceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admpb.Recurrence;
      reader.readMessage(value,proto.admpb.Recurrence.deserializeBinaryFromReader);
      msg.setRecurrence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.DeleteRecurrenceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.DeleteRecurrenceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.DeleteRecurrenceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.DeleteRecurrenceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecurrence();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admpb.Recurrence.serializeBinaryToWriter
    );
  }
};


/**
 * optional Recurrence recurrence = 1;
 * @return {?proto.admpb.Recurrence}
 */
proto.admpb.DeleteRecurrenceResponse.prototype.getRecurrence = function() {
  return /** @type{?proto.admpb.Recurrence} */ (
    jspb.Message.getWrapperField(this, proto.admpb.Recurrence, 1));
};


/**
 * @param {?proto.admpb.Recurrence|undefined} value
 * @return {!proto.admpb.DeleteRecurrenceResponse} returns this
*/
proto.admpb.DeleteRecurrenceResponse.prototype.setRecurrence = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admpb.DeleteRecurrenceResponse} returns this
 */
proto.admpb.DeleteRecurrenceResponse.prototype.clearRecurrence = function() {
  return this.setRecurrence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admpb.DeleteRecurrenceResponse.prototype.hasRecurrence = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.admpb);
