import React from 'react'
import { IconButton } from '@material-ui/core'
import {
  Create as EditIcon, 
  Visibility as ViewIcon,
  Delete as DeleteIcon 
} from '@material-ui/icons'
import ClientGrid from '../../../../components/ClientGrid/ClientGrid'
import ClientGrid2 from '../../../../components/ClientGrid/ClientGrid2'
import Table, { columnType } from '../../../../components/ClearingTable/Table';
import { 
  listDividend,
  deleteDividend,
  updateDividend
} from '../../../../services/ReorgAnnouncement';
import { notifyError, notifySuccess, notifyInfo } from '../../../../components/Messages/Notification';
import { divindendColumns } from '../../../../lib/data/columns';
import DividendModal from './DividendModal';
import DetailModal from './DetailModal';
import {
  CustomBodyRenderCurrency,
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderDate,
  CustomBodyRenderDateTime,
  CustomBodyRenderBoolean,
  CustomBodyRenderAlign,
  CustomBodyRenderHtmlString,
  CustomBodyRenderCenter,
} from '../../../../components/Table/CustomBodyRender';
import { formatPbDate, formatPbDateTime } from '../../../../lib/fmt';

export default function DividendTable({
  newRows,
  searchData,
  announcementType,
  loading
}) {
const [rows, setRows] = React.useState([])
const [rowData, setRowData] = React.useState([])
const [open, setOpen] = React.useState({
  edit: false,
  view: false
})
const [saving, setSaving] = React.useState(false);

React.useEffect(() => {
  if (announcementType == 'dividend'){
    setRows([])
  }
}, [announcementType])

React.useEffect(() => {
  if (announcementType == 'dividend'){
    getRows()
  }
}, [newRows])

  const GridButtons = ({
    tableManager,
    value,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return (
      <div className='grd-row'>
        {/* <div className='grd-cell nm'>
          <IconButton aria-label="edit" onClick={() => handleOpen(data)}>
            <EditIcon style={{ height: 24, width: 24 }} />
          </IconButton>
        </div> */}
        <div className='grd-cell nm'>
          <IconButton aria-label="view" onClick={() => handleView(data)}>
            <ViewIcon style={{ height: 24, width: 24 }} />
          </IconButton>
        </div>
        {/* <div className='grd-cell nm'>
          <IconButton disabled={!isDeleteEnabled(data)} aria-label="delete" onClick={() => handleDelete(data)}>
            <DeleteIcon style={{ height: 24, width: 24 }} />
          </IconButton>
        </div> */}
      </div>
    )
  }

  const columns2 = [
    {
      id: '',
      label: '',
      width: '100px',
      cellRenderer: GridButtons,
    },
    ...divindendColumns
  ]

  const columns = [
    {
      name: 'actions',
      label: 'Actions',
      sort: false,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return(
            <div className='grd-row'>
              {/* <div className='grd-cell nm'>
                <IconButton aria-label="edit" onClick={() => handleOpen(data)}>
                  <EditIcon style={{ height: 24, width: 24 }} />
                </IconButton>
              </div> */}
              <div className='grd-cell nm'>
                <IconButton aria-label="view" onClick={() => handleView(rows[dataIndex])}>
                  <ViewIcon style={{ height: 24, width: 24 }} />
                </IconButton>
              </div>
              {/* <div className='grd-cell nm'>
                <IconButton disabled={!isDeleteEnabled(data)} aria-label="delete" onClick={() => handleDelete(data)}>
                  <DeleteIcon style={{ height: 24, width: 24 }} />
                </IconButton>
              </div> */}
            </div>
          );
        },
      },
    },
    {
      name: 'cashRate',
      label: 'Cash Rate',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      // Already converted to string in handlesearch
      name: 'declarationDate',
      label: 'Declaration Date',
    },
    {
      // Already converted to string in handlesearch
      name: 'expirationDate',
      label: 'Expiration Date',
    },
    {
      name: 'originalCusip',
      label: 'Original Cusip',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false
      }
    },
    {
      // Already converted to string in handlesearch
      name: 'payableDate',
      label: 'Payable Date',
    },
    {
      // Already converted to string in handlesearch
      name: 'positionDate',
      label: 'Position Date',
    },
    {
      // Already converted to string in handlesearch
      name: 'processDate',
      label: 'Process Date',
    },
    {
      name: 'qtyRate',
      label: 'Qty Rate',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      // Already converted to string in handlesearch
      name: 'recordDate',
      label: 'Record Date',
    },
    {
      name: 'symbol',
      label: 'Symbol',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      // Already converted to string in handlesearch
      name: 'systemDate',
      label: 'System Date',
    },
    {
      name: 'textNumber',
      label: 'Text Number',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'detail',
      label: 'Detail',
      options: {
        customBodyRender: CustomBodyRenderHtmlString,
        display: false,
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
  ];

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
  };

  const handleOpen = (data) => {
    setRowData(data);
    setOpen({
      ...open,
      edit: true
    })
  }

  const handleView = (data) => {
    setRowData(data);
    setOpen({
      ...open,
      view: true
    })
  }

  const handleClose = async (data) => {
    if (!data) {
      setOpen({
        view: false,
        edit: false
      })
      return;
    }

    setSaving(true)
    try{
      const {dividend} = await updateDividend(data);

      notifySuccess("Dividend has been updated");
    } catch(error){
      console.error(error)
      notifyError(error.message)
    }
    
    getRows()
    setSaving(false)
    setOpen({
      view: false,
      edit: false
    })
  }

  const isDeleteEnabled = (data) => {
    if( data.status == 'Active'){
      return true
    }
    return false
  }

  const handleDelete = async (data) => {
    try {
      await deleteDividend(data.dividendId);

      notifySuccess("Dividend has been deactivated");
      getRows(false);
    } catch(error){
      console.error(error);
      notifyError(error.message);
    }
  }

  const getRows = async (notify) => {
    const { dividendsList } = await listDividend(searchData);

    let rowsCopy = dividendsList;

    const rowsCopyOutput = rowsCopy.map((data) => {
      let tempDate = formatPbDate(data.expirationDate);
      data.expirationDate = tempDate

      let tempDate2 = formatPbDate(data.payableDate);
      data.payableDate = tempDate2

      let tempDate3 = formatPbDate(data.positionDate);
      data.positionDate = tempDate3

      let tempDate4 = formatPbDate(data.processDate);
      data.processDate = tempDate4

      let tempDate5 = formatPbDate(data.recordDate);
      data.recordDate = tempDate5

      let tempDate6 = formatPbDate(data.systemDate);
      data.systemDate = tempDate6

      let tempDate7 = formatPbDate(data.declarationDate);
      data.declarationDate = tempDate7

      return data;
    })
    
    if (notify){
      notifyInfo(dividendsList.length + ' search results.');
    }
    console.log("Date: " + JSON.stringify(rowsCopyOutput))
    setRows(rowsCopyOutput);
  }

  const getCsvData = async () => {
    const data = await listDividend(searchData)

    return data.dividendsList
  }

  return (
    <div className="mt-20">
      {/* <ClientGrid
        title='Dividend'
        rowIdField='dividendId'
        columns={columns2}
        rows={rows}
        getCsvData={getCsvData}
        isLoading={loading.search}
        setRows={setRows}
      /> */}
      {/* <ClientGrid2
          title="Dividend"
          data={rows}
          columns={columns}
          options={options}
        /> */}
      <Table
        title={'Dividend'}
        data={rows}
        columns={columns}
        options={options}
      />
      {open.edit && 
        <DividendModal
          data = {rowData}
          open = {open.edit}
          close = {handleClose}
          isEdit = {true}
          loading = {saving}
        />
      }
      
      {open.view && 
        <DetailModal
          data = {rowData}
          open = {open.view}
          close = {handleClose}
          title = {'Dividend'}
        />
      }
    </div>
  )
}
