import { createSelector } from 'reselect';
import {
  ListSystemCodeRequest,
  LazySystemCodeRequest,
  SystemCodeServiceClient,
} from '../proto/commonpb/systemcode_grpc_web_pb';
import {
  LazyAccountRequest,
  LazyAccountNoRequest,
  LazyLoadSecurityRequest,
  LazyLoadRequest,
  AccessibleRequest,
  LazyListServiceClient,
} from '../proto/commonpb/lazylist_grpc_web_pb';
import { FAQ, FAQServiceClient } from '../proto/admpb/faq_grpc_web_pb';
import {
  File,
  FileServiceClient,
  AttachFileRequest,
  DownloadFileRequest,
  DeleteFileRequest,
  ListFileRequest,
  UpdateFileRequest,
  DeleteOnboardingFileRequest,
} from '../proto/commonpb/file_grpc_web_pb';
import {
  GenerateAccountNoRequest,
  GenerateAccountNoServiceClient,
} from '../proto/accountpb/generateaccountno_grpc_web_pb';
import {
  ListServiceClient,
  ReadPrimaryOwnerRequest,
  ListBankNameRequest,
  ListBankAccountRequest,
  ListOriginalCusipRequest,
  ListPageRequest,
} from '../proto/commonpb/list_grpc_web_pb';

import download from './DownloadService';
import authSvc from './AuthService';
import { auth } from '../lib/auth/Auth';
import { stringToProtoDate } from './ConvertService';

const lazyService = new LazyListServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });
const systemCodeService = new SystemCodeServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);
const faqService = new FAQServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });
const fileService = new FileServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });
const generateService = new GenerateAccountNoServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);
const listService = new ListServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });

const userId = authSvc.getCurrentUser()?.UserId;

//list lazy account
export const lazyLoadAccount = createSelector(
  (key, orderBy, type) => (async () => await lazyAccountPromise(key, orderBy, type))(),
  (accounts) => accounts
);

const lazyAccountPromise = async (key, orderBy, type) => {
  return new Promise((resolve, reject) => {
    const req = new LazyAccountRequest();
    req.setKey(key);
    req.setLimit(50);
    req.setOrderBy(orderBy);
    req.setColType(type);
    req.setUsrId(userId);

    lazyService.lazyAccount(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

//list lazy security (symbol)
export const lazyLoadSecurity = createSelector(
  (key, assetType) => ({ key: key, assetType: assetType }),
  (p) => (async () => await lazyLoadSecurityPromise(p.key, 100, p.assetType))()
);

const lazyLoadSecurityPromise = async (key, limit, assetType) => {
  return new Promise((resolve, reject) => {
    let req = new LazyLoadSecurityRequest();
    req.setLimit(limit);
    req.setKey(key);
    req.setAssetType(assetType);

    lazyService.lazySecurity(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

//list lazy system code
export const lazyLoadSystemCode = createSelector(
  (type, subType, code, note) =>
    (async () => await lazyLoadSystemCodePromise(type, subType, code, note))(),
  (systemCode) => systemCode
);

const lazyLoadSystemCodePromise = async (type, subType, code, note) => {
  return new Promise((resolve, reject) => {
    const req = new LazySystemCodeRequest();
    req.setType(type);
    req.setSubType(subType);
    req.setCode(code);
    req.setNote(note);

    systemCodeService.lazySystemCode(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

//list lazy accessible correspondent per user - cached result when the same inputs occur again
export const accessibleCorrespondent = createSelector(
  (newCorrespondent) => newCorrespondent,
  (newCorrespondent) => (async () => await accessibleCorrespondentPromise())()
);

async function accessibleCorrespondentPromise() {
  return new Promise((resolve, reject) => {
    const req = new AccessibleRequest();
    req.setUsrId(userId);

    lazyService.accessibleCorrespondent(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

//list lazy accessible account no per user - cached result when the same inputs occur again
export const accessibleAccountNo = createSelector(
  (correspondent, key) =>
    (async () => await accessibleAccountNoPromise(key, correspondent))(),
  (correspondents) => correspondents
);

async function accessibleAccountNoPromise(key, correspondent) {
  return new Promise((resolve, reject) => {
    const req = new AccessibleRequest();
    req.setKey(key);
    req.setCorrespondent(correspondent);
    req.setUsrId(userId);
    lazyService.accessibleAccountNo(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

//list lazy accessible master account no per user - cached result when the same inputs occur again
export const accessibleMasterAccountNo = createSelector(
  (correspondent, key) =>
    (async () => await accessibleMasterAccountNoPromise(key, correspondent))(),
  (correspondents) => correspondents
);

async function accessibleMasterAccountNoPromise(key, correspondent) {
  return new Promise((resolve, reject) => {
    const req = new AccessibleRequest();
    req.setKey(key);
    req.setCorrespondent(correspondent);
    req.setUsrId(userId);
    lazyService.accessibleMasterAccountNo(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

//list lazy accessible account name per user - cached result when the same inputs occur again
export const accessibleAccountName = createSelector(
  (correspondent, key) =>
    (async () => await accessibleAccountNamePromise(key, correspondent))(),
  (correspondents) => correspondents
);

async function accessibleAccountNamePromise(key, correspondent) {
  return new Promise((resolve, reject) => {
    const req = new AccessibleRequest();
    req.setKey(key);
    req.setCorrespondent(correspondent);
    req.setUsrId(userId);
    lazyService.accessibleAccountName(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

//list lazy accessible rep per user - cached result when the same inputs occur again
export const accessibleRep = createSelector(
  (correspondent, key) => (async () => await accessibleRepPromise(key, correspondent))(),
  (correspondents) => correspondents
);

async function accessibleRepPromise(key, correspondent) {
  return new Promise((resolve, reject) => {
    const req = new AccessibleRequest();
    req.setKey(key);
    req.setCorrespondent(correspondent);
    req.setUsrId(userId);
    lazyService.accessibleRep(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

//list lazy accessible branch per user - cached result when the same inputs occur again
export const accessibleBranch = createSelector(
  (correspondent, key) =>
    (async () => await accessibleBranchPromise(key, correspondent))(),
  (correspondents) => correspondents
);

async function accessibleBranchPromise(key, correspondent) {
  return new Promise((resolve, reject) => {
    const req = new AccessibleRequest();
    req.setKey(key);
    req.setCorrespondent(correspondent);
    req.setUsrId(userId);
    lazyService.accessibleBranch(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

//list system code
export const listSystemCode = createSelector(
  (props) => (async () => await ListSystemCodePromise(props))(),
  (code) => code
);

export const ListSystemCodePromise = async (props) => {
  return new Promise((resolve, reject) => {
    const req = new ListSystemCodeRequest();
    req.setType(props.type);
    req.setSubType(props.subType);
    req.setOrderBy(props.orderBy);

    systemCodeService.listSystemCode(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

//list faqs
export const listFAQs = createSelector(
  (props) => (async () => await ListFAQsPromise(props))(),
  (code) => code
);

export const ListFAQsPromise = async (props) => {
  return new Promise((resolve, reject) => {
    const req = new FAQ();
    req.setPageName(props.pageName);

    faqService.listFAQ(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

//attachment
export async function attachFiles(linkId, linkType, files) {
  let response = [];

  try {
    let req = new AttachFileRequest();
    req.setLinkId(linkId);
    req.setLinkType(linkType);

    for (let i = 0; i < files.length; i++) {
      let f = new File();
      const fileBytes = await readFileAsArrayBuffer(files[i]);
      f.setMimeType(files[i].type);
      f.setFileBytes(fileBytes);
      f.setFileName(files[i].name);
      req.setFile(f);

      const res = await attachFilePromise(req);

      //notify success
      response.push(res);
    }
  } catch (error) {
    console.error(error);
    //notify error
  }

  return response;
}

async function attachFilePromise(req) {
  return new Promise((resolve, reject) => {
    fileService.attachFile(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

function readFileAsArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const bytes = new Uint8Array(arrayBuffer);
      resolve(bytes);
    };

    reader.onerror = (err) => {
      reject(err);
    };

    reader.readAsArrayBuffer(file);
  });
}

export const ListFiles = async (props) => {
  return new Promise((resolve, reject) => {
    const req = new ListFileRequest();
    req.setLinkId(props.linkId);
    req.setLinkType(props.linkType);

    fileService.listFile(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export async function downloadFile(id) {
  return new Promise((resolve, reject) => {
    const req = new DownloadFileRequest();
    req.setId(id);

    fileService.downloadFile(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        const fileName = download(response);
        resolve(fileName);
      }
    });
  });
}

export async function deleteFile(id) {
  return new Promise((resolve, reject) => {
    let req = new DeleteFileRequest();
    req.setId(id);

    fileService.deleteFile(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateFile(param) {
  return new Promise((resolve, reject) => {
    let req = new UpdateFileRequest();
    req.setId(param.id);
    req.setTag(param.tag);

    fileService.updateFile(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

//generate account no
export const generateAccountNo = async (param) => {
  return new Promise((resolve, reject) => {
    const req = new GenerateAccountNoRequest();
    // Generating the ending of the last name in generating account no
    const lastNameEnding =
      param.lastName.split(' ')[param.lastName.split(' ').length - 1];
    req.setFirstName(param.firstName);
    req.setLastName(lastNameEnding);
    req.setPhoneNumber(param.phoneNumber);

    generateService.generateAccountNo(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export async function listBankName(props) {
  return new Promise((resolve, reject) => {
    const req = new ListBankNameRequest();
    req.setKey(props.key);
    req.setLimit(props.limit);

    listService.listBankName(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function readPrimaryOwner(param) {
  return new Promise((resolve, reject) => {
    const req = new ReadPrimaryOwnerRequest();
    req.setCorrespondent(param.correspondent);
    req.setAccountNo(param.accountNo);

    listService.readPrimaryOwner(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listBankAccount(correspondent, accountNo) {
  return new Promise((resolve, reject) => {
    const req = new ListBankAccountRequest();
    req.setCorrespondent(correspondent);
    req.setAccountNo(accountNo);

    listService.listBankAccount(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export const lazyloadCusip = createSelector(
  (key) => key,
  (key) => (async () => await lazyloadCusipPromise(key, 100))()
);

const lazyloadCusipPromise = async (key, limit) => {
  return new Promise((resolve, reject) => {
    let req = new LazyLoadRequest();
    req.setLimit(limit);
    req.setKey(key);

    lazyService.lazyLoadCusip(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export async function listOriginalCusip(systemDate, symbol) {
  return new Promise((resolve, reject) => {
    const req = new ListOriginalCusipRequest();
    req.setSystemDate(stringToProtoDate(systemDate));
    req.setSymbol(symbol);

    listService.listOriginalCusip(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export const lazyAdministratorName = createSelector(
  (key) => key,
  (key) => (async () => await lazyAdministratorNamePromise(key, 100))()
);

const lazyAdministratorNamePromise = async (key, orderBy, type) => {
  return new Promise((resolve, reject) => {
    const req = new LazyAccountRequest();
    req.setKey(key);
    req.setLimit(50);
    req.setOrderBy(orderBy);
    req.setColType(type);
    req.setUsrId(userId);

    lazyService.lazyAdministratorName(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const lazyAdministratorEmail = createSelector(
  (key) => key,
  (key) => (async () => await lazyAdministratorEmailPromise(key, 100))()
);

const lazyAdministratorEmailPromise = async (key, orderBy, type) => {
  return new Promise((resolve, reject) => {
    const req = new LazyAccountRequest();
    req.setKey(key);
    req.setLimit(50);
    req.setOrderBy(orderBy);
    req.setColType(type);
    req.setUsrId(userId);

    lazyService.lazyAdministratorEmail(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

//memoization -  cached result when the same inputs occur again
export const lazyAccountNo = createSelector(
  (correspondent, key, accountType, entryType, status) =>
    (async () =>
      await lazyAccountNoPromise(
        key,
        correspondent,
        accountType,
        entryType,
        status
      ))(),
  (correspondents) => correspondents
);

const getLazyAccountRequest = (
  key,
  correspondent,
  accountType,
  entryType,
  status
) => {
  const req = new LazyAccountNoRequest();
  req.setKey(key);
  req.setLimit(50);
  req.setCorrespondent(correspondent);
  req.setType(accountType);
  req.setEntryType(entryType);
  if (status) {
    req.setStatus(status);
  }

  return req;
};

async function lazyAccountNoPromise(
  key,
  correspondent,
  accountType,
  entryType,
  status
) {
  return new Promise((resolve, reject) => {
    const req = getLazyAccountRequest(
      key,
      correspondent,
      accountType,
      entryType,
      status
    );
    lazyService.lazyAccountNo(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listPage(props) {
  return new Promise((resolve, reject) => {
    const req = new ListPageRequest();
    req.setKey(props.key);
    req.setLimit(props.limit);

    listService.listPage(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listMenu(props) {
  return new Promise((resolve, reject) => {
    const req = new ListPageRequest();
    req.setKey(props.key);
    req.setLimit(props.limit);

    listService.listMenu(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listSubMenu(props) {
  return new Promise((resolve, reject) => {
    const req = new ListPageRequest();
    req.setKey(props.key);
    req.setLimit(props.limit);

    listService.listSubMenu(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function deleteOnboardingFile(linkId, files) {
  let response = [];

  try {
    let req = new DeleteOnboardingFileRequest();
    req.setLinkId(linkId);

    for (let i = 0; i < files.length; i++) {
      let f = new File();

      f.setFileName(files[i].name);
      req.setFile(f);

      
      await deleteFilePromise(req);

    }
  } catch (error) {
    console.error(error);
    //notify error
  }

  return response;
}

async function deleteFilePromise(req) {
  return new Promise((resolve, reject) => {
    fileService.deleteOnboardingFile(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
