import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import Text from '../Typography/Text';
import Button from '../Buttons/Button';
import CloseButton from '../Buttons/CloseButton';
import fontFamily from '../../assets/css/fonts';
import color from '../../assets/css/colors';
import ClientGrid2 from '../../components/ClientGrid/ClientGrid2';
import { listFeeManagerReportDetails } from '../../services/FeeManagerReportService';

import moment from 'moment-timezone';
import { formatPbDate } from '../../lib/fmt';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 1500,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 30px',
    maxHeight: 600,
    overflowY: 'auto',
    fontFamily: fontFamily.headers,
    color: color.default.main,
    '& p': {
      fontSize: 16,
      fontFamily: fontFamily.headers,
      fontWeight: 400,
      lineHeight: '26px',
      color: color.default.main,
      marginTop: 0,
    },
    '& hr': {
      margin: '30px 0px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: 25,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
  paper: {
    position: 'absolute',
  },
}));

// Table Settings
const FeeManagerReportColumns = [
  {
    name: 'tradeDate',
    label: 'Trade Date',
    options: {
      display: false,
    },
  },
  {
    name: 'settleDate',
    label: 'Settle Date',
    options: {
      display: false,
    },
  },
  {
    name: 'systemDate',
    label: 'System Date',
    options: {
      display: false,
    },
  },
  {
    name: 'trnsId',
    label: 'Trns ID',
  },
  {
    name: 'accountId',
    label: 'Account ID',
  },
  {
    name: 'correspondent',
    label: 'Correspondent',
  },
  {
    name: 'branch',
    label: 'Branch',
  },
  {
    name: 'accountNo',
    label: 'Account No',
  },
  {
    name: 'masterAccountNo',
    label: 'Master Account No',
  },
  {
    name: 'rep',
    label: 'Rep',
  },
  {
    name: 'broker',
    label: 'Broker',
  },
  {
    name: 'accountName',
    label: 'Account Name',
  },
  {
    name: 'feeType',
    label: 'Fee Type',
  },
  {
    name: 'fee',
    label: 'Fee',
  },
];

const options = {
  responsive: 'standard',
  selectableRows: 'none',
};
// End Table Settings

export default function FeeManagerReportModal(props) {
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);

  const { open, close } = props;
  useEffect(() => {
    async function init() {
      let value = props.data;

      const data = await listFeeManagerReportDetails({
        feeType: value.feeType,
        dateType: value.dateType,
        fromDate: moment(new Date(formatPbDate(value.fromDate))).format('yyyy-MM-DD'),
        toDate: moment(new Date(formatPbDate(value.toDate))).format('yyyy-MM-DD'),
        accountId: value.accountId,
      });

      const rowsCopyOutput = data.feeManagerReportDetailsList.map((data) => {
        let tempDate = formatPbDate(data.tradeDate);
        data.tradeDate = tempDate;

        return data;
      });

      setRows(rowsCopyOutput);
    }

    init();
  }, []);

  const handleClose = () => {
    close();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div style={{ width: 1500 }}>
          <div className={classes.modalHeader}>
            <div>
              <Text mt={3} variant="h2" label="Fee Manager Report Details" />
            </div>
            <div className={classes.closeButton}>
              <CloseButton onClick={handleClose} />
            </div>
          </div>
          <div className={classes.modalBody}>
            <div>
              <ClientGrid2
                title="Fee Manager Report Details"
                data={rows}
                columns={FeeManagerReportColumns}
                options={options}
              />
            </div>
          </div>
          <div className={classes.modalFooter}>
            <Button label="Close" onClick={handleClose} />
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
