import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Create as EditIcon } from '@material-ui/icons';
import ClientGrid2 from '../../components/ClientGrid/ClientGrid2'
import Table, { columnType } from '../../components/ClearingTable/Table';
import Button from '../../components/Buttons/Button';
import AutoCompleteCorrespondent from '../../components/AutoComplete/AutoCompleteCorrespondent';
import AutoCompleteAccountNo from '../../components/AutoComplete/AutoCompleteAccountNo';
import AutoCompleteAccountName from '../../components/AutoComplete/AutoCompleteAccountName';
import AutoCompleteMasterAccountNo from '../../components/AutoComplete/AutoCompleteMasterAccountNo';
import AutoCompleteBranch from '../../components/AutoComplete/AutoCompleteBranch';
import AutoCompleteRep from '../../components/AutoComplete/AutoCompleteRep';
import SelectSystemCode from '../../components/Dropdown/SelectSystemCode';
import Text from '../../components/Typography/Text';
import AccountSetupModal from '../../components/Modals/AccountSetupModal';
import ClientAccountModal from '../../components/Modals/ClientAccountModal';
import AccountSetup from './AccountSetup';
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from '../../components/Messages/Notification';
// import {
//   updateClient,
//   listClientAccount,
//   readClient,
// } from '../../services/ClientAccountService';
import QueryParam from '../../services/QueryParamService';
import authSvc from '../../services/AuthService';
import {
  CustomBodyRenderAlign,
  CustomBodyRenderPercentage,
} from '../../components/Table/CustomBodyRender';

export default function ClientAccount() {
  const activeUser = authSvc.getCurrentUser();
  const accountStatus = activeUser.Status;

  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedAccountId, setSelectedAccountId] = React.useState(0);
  const [batchNo, setBatchNo] = React.useState('');
  const [rowData, setRowData] = React.useState({});
  const [validation, setValidation] = React.useState({});
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      correspondent: '',
      branch: '',
      accountNo: '',
      masterAccountNo: '',
      rep: '',
      accountName: '',
      broker: '',
      status: '',
    })
  );
  const options = {
    selectableRows: 'none',
    responsive: 'standard',
  };

  let onboardingPercent;

  const isEditEnabled = (data) => {
    if (['I', 'J', 'C'].includes(data.accountType)) {
      return true;
    } else {
      return false;
    }
  };
  const GridButtons = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    return (
      <div>
        <div style={{ padding: 5 }}>
          <IconButton
            aria-label="edit"
            disabled={!isEditEnabled(data)}
            onClick={() => {
              if (data.onboardingPercent === '100') {
                handleOpen(data);
              } else {
                handleOpen(data.accountId);
              }
            }}
          >
            <EditIcon style={{ height: 24, width: 24 }} />
          </IconButton>
        </div>
      </div>
    );
  };

  const columns = [
    {
      name: 'actions',
      label: 'Actions',
      sort: false,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return(
            <div align={'left'} className="grd-row" style={{ margin: 0, padding: 0 }}>
              <div align={'center'} className="grd-cell">
                <Tooltip title="Edit" arrow>
                  <IconButton aria-label='view' onClick={() => {
                    if(rows[dataIndex].onboardingPercent === '100') {
                      handleOpen(rows[dataIndex]);
                    } else {
                      handleOpen(rows[dataIndex].accountId)
                    }
                  }}>
                    <EditIcon style={{ height: 20, width: 20 }} />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'branch',
      label: 'Branch',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'onboardingPercent',
      label: 'Onboarding Percent',
      options: {
        display: false,
        customBodyRender: CustomBodyRenderPercentage,
      },
      type: 'percentage'
    },
    {
      name: 'accountNo',
      label: 'Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'rep',
      label: 'Rep',
      options: {
        display: false,
        customBodyRender: CustomBodyRenderAlign,
      },
    },
    {
      name: 'accountName',
      label: 'Account Name',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'broker',
      label: 'Broker',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'privacyConsent',
      label: 'Privacy Consent',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'taxCountry',
      label: 'Tax Country',
      options: {
        display: false,
        customBodyRender: CustomBodyRenderAlign,
      },
    },
    {
      name: 'accountType',
      label: 'Account Type',
      options: {
        display: false,
        customBodyRender: CustomBodyRenderAlign,
      },
    },
    {
      name: 'marginType',
      label: 'Margin Type',
      options: {
        display: false,
        customBodyRender: CustomBodyRenderAlign,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
  ];

  const handleSearchDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setSearchData({ ...searchData, [input.name]: input.value });
  };

  const handleSearch = async () => {
    try {
      setLoading(true);

      QueryParam.set(searchData);

      const data = await listClientAccount(searchData);
      setRows(data.clientsList);

      notifyInfo(
        data.clientsList.length > 0
          ? data.clientsList.length + ' search result(s)'
          : 'No records found.'
      );
    } catch (error) {
      setLoading(false);
      notifyError(error.message);
      console.error(error);
      return;
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = (data) => {
    setSelectedAccountId(data);
    setRowData(data);
    if (data.onboardingPercent === '100') {
      setOpenModal(true);
    } else {
      setOpen(true);
    }
  };

  const handleClose = async () => {
    setOpen(false);
  };

  const handleCloseModal = async (data, isEdit) => {
      if (!data) {
        setOpenModal(false);
        setValidation({});
        return;
      }

      let valid = true;

      setValidation({
        correspondent: !data.correspondent || data.correspondent.length !== 4,
        accountNo: !data.accountNo,
        accountName: !data.accountName,
        broker: !data.broker,
        legalEntity: !data.legalEntity,
        accountType: !data.accountType,
        marginType: !data.marginType,
        privacyConsent: !data.privacyConsent,
        tefra: !data.tefra,
        taxCountry: !data.taxCountry,
        w8w9: !data.w8w9,
        activatedAt: data.status === 'Active' ? !data.activatedAt : false,
        status: !data.status,
        stockOwnership: data.ownership === true ? !data.stockOwnership : false,
        brokerName: data.brokerMember === true ? !data.brokerName : false,
        affiliatePerson: data.brokerMember === true ? !data.affiliatePerson : false,
        affiliatePersonPosition:
          data.brokerMember === true ? !data.affiliatePersonPosition : false,
      });

      if (!data.correspondent || data.correspondent.replaceAll(' ', '').length !== 4) {
        notifyError('Correspondent should have 4 characters.');
        valid = false;
      }
      if (!data.accountNo) {
        notifyError('Account No is required.');
        valid = false;
      }
      if (!data.accountName) {
        notifyError('Account name is required.');
        valid = false;
      }
      if (!data.broker) {
        notifyError('Broker is required.');
        valid = false;
      }
      if (!data.legalEntity) {
        notifyError('Legal entity is required.');
        valid = false;
      }
      if (!data.accountType) {
        notifyError('Account type is required.');
        valid = false;
      }
      if (!data.marginType) {
        notifyError('Margin type is required.');
        valid = false;
      }
      if (!data.privacyConsent) {
        notifyError('Privacy Consent is required.');
        valid = false;
      }
      if (!data.tefra) {
        notifyError('Tefra is required.');
        valid = false;
      }
      if (!data.taxCountry) {
        notifyError('Tax Country is required.');
        valid = false;
      }

      if (
        ['Limited Liability Company', 'Partnership', 'Corporation', 'Trust'].includes(
          data.legalEntity
        ) ||
        /Corporation/.test(data.legalEntity)
      ) {
        if (!data.tin) {
          notifyError('EIN is required.');
          valid = false;
        }
      }
      if (!data.w8w9) {
        notifyError('W8W9 is required.');
        valid = false;
      }
      if (data.status === 'Active' && !data.activatedAt) {
        notifyError('Activated At is required.');
        valid = false;
      }
      if (!data.status) {
        notifyError('Status is required.');
        valid = false;
      }

      if (data.ownership === true) {
        if (!data.stockOwnership) {
          notifyError('Stock Symbol is required.');
          valid = false;
        }
      }

      if (data.brokerMember === true) {
        if (!data.brokerName) {
          notifyError('Company Name is required.');
          valid = false;
        }

        if (!data.affiliatePerson) {
          notifyError('Person Affiliated is required.');
          valid = false;
        }

        if (!data.affiliatePersonPosition) {
          notifyError('Position of Affiliated Person is required.');
          valid = false;
        }
      }

      if ([0, '0'].includes(data.multiplier)) {
        notifyError('0 Multiplier is not allowed. Min should be 1.');
        valid = false;
      }

      if (!valid) {
        return;
      }
      data.batchNo = batchNo;

      setLoading({ ...loading, save: true });

      try {
        if (isEdit) {
          //EDIT
          await updateClient(data).then((res) => {
            const rowsCopy = [...rows];
            const index = rows.indexOf(rowData);
            rowsCopy[index] = res.client;
            setRows(rowsCopy);
            notifySuccess('Client Account has been updated.');
          });
        }
        //else {
        //   //ADD
        //   await createClient(data).then((res) => {
        //     setRows([res.client, ...rows]);
        //     notifySuccess('New Client Account has been added.');

        //     //reload new correspondent in the dropdown
        //     lazyCorrespondent(data.correspondent);
        //   });
        // }

        setValidation({});
        setOpen(false);
        setOpenModal(false);
      } catch (error) {
        setLoading({ ...loading, save: false });
        notifyError(error.message);
      }

      setLoading({ ...loading, save: false });

      handleSearch(false, true);
  };

  const getCsvData = async () => {
    const data = await listClientAccount(searchData);

    return data.clientsList;
  };

  React.useEffect(async () => {
    try {
      const data = await readClient(activeUser.accountID);
      onboardingPercent = data.client.onboardingPercent;
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Account Setup" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading}
              loading={loading}
              type="search"
              label={loading ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <AutoCompleteCorrespondent
              isAccessibleOnly={true}
              name="correspondent"
              value={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteBranch
              isAccessibleOnly={true}
              name="branch"
              value={searchData.branch}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteAccountNo
              isAccessibleOnly={true}
              name="accountNo"
              value={searchData.accountNo}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteMasterAccountNo
              isAccessibleOnly={true}
              name="masterAccountNo"
              value={searchData.masterAccountNo}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteRep
              isAccessibleOnly={true}
              name="rep"
              value={searchData.rep}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteAccountName
              isAccessibleOnly={true}
              name="accountName"
              value={searchData.accountName}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <SelectSystemCode
              name="broker"
              label="Broker"
              placeholder="Broker"
              type="Broker"
              value={searchData.broker}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <SelectSystemCode
              name="status"
              label="Status"
              placeholder="Status"
              type="Status"
              subType="Client Setup"
              value={searchData.status}
              onChange={handleSearchDataChange}
            />
          </div>
        </div>
      </div>
      <div className="mt-20">
        {/* <ClientGrid2
            title="Client Account"
            data={rows}
            columns={columns}
            options={options}
        /> */}
        <Table
          title={'Client Account'}
          data={rows}
          columns={columns}
          options={options}
          getCsvData={getCsvData}
        />
      </div>
      <AccountSetupModal
        content={
          <AccountSetup
            accountId={selectedAccountId}
            close={handleClose}
            status={accountStatus}
          />
        }
        open={open}
        onClose={handleClose}
      />
      <ClientAccountModal openModal={openModal} onClose={handleCloseModal} value={rowData} />
    </div>
  );
}
