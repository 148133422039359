import {
  CorrespondentServiceClient,
  Correspondent,
} from "../proto/accountpb/correspondent_grpc_web_pb";


const service = new CorrespondentServiceClient(window.env.GRPC_ENDPOINT);


export async function getLogo(param) {
  return new Promise((resolve, reject) => {
    let req = new Correspondent();

    service.getLogo(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function getCorrespondentByUrl(url) {
  return new Promise((resolve, reject) => {
    let req = new Correspondent();
    req.setUrl(url);
    service.getCorrespondentByUrl(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
