
import {
  FileServiceClient,
  ListFileRequest,
  DownloadFileRequest,
} from '../proto/clientpb/file_grpc_web_pb';
import download from './DownloadService';
import { getCurrentUser } from './AuthService';
import { auth } from '../lib/auth/Auth';

const service = new FileServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });

export async function listFile(param) {
  return new Promise((resolve, reject) => {
    const req = new ListFileRequest();
    req.setUsrId(getCurrentUser().UserId);
    req.setType(param.type);
    req.setMonth(param.month);
    req.setYear(param.year);
    req.setKeyword(param.keyword);
    service.listFile(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function downloadFile(filePaths) {
  for (let filePath of filePaths) {
    const fileProto = {};
    const metaData = await downloadFileMetaData(filePath);
    const bytes = await downloadFileChunk(filePath);
    // mimeType, fileBytes, fileName
    fileProto.array = {
      mimeType: metaData.mimeType,
      fileBytes: bytes,
      fileName: metaData.fileName,
      // https://medium.com/swlh/making-objects-iterable-in-javascript-252d9e270be6
      [Symbol.iterator]() {
        let values = Object.values(this);
        let index = 0;
        return {
          next() {
            if (index < values.length) {
              let val = values[index];
              index++;
              return { value: val, done: false };
            } else return { done: true };
          }
        };
      }
    };
    download(fileProto);
  }
}

async function downloadFileMetaData(filePath) {
  return new Promise((resolve, reject) => {
    const req = new DownloadFileRequest();
    req.setFilePath(filePath);
    service.downloadFileMetaData(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

async function downloadFileChunk(filePath) {
  return new Promise((resolve, reject) => {
    const req = new DownloadFileRequest();
    req.setFilePath(filePath);
    const stream = service.downloadFileChunk(req, {})
    let fileByteSegments = [];
    let segmentIndex = 0;
    let segmentCounter = 0;

    // Divide into segment to save memory from copying large array data
    stream.on('data', function(response){
      if (segmentCounter === 0) {
        fileByteSegments[segmentIndex] = response.array[0];
      } else {
        fileByteSegments[segmentIndex] = [...fileByteSegments[segmentIndex], ...response.array[0]];
      }

      segmentCounter++;

      if (segmentCounter === 10) {
        segmentCounter = 0
        segmentIndex++;
      }
    });

    // Buoin na lng kapag kumpleto na
    stream.on('end', function() {
      console.log("segments", fileByteSegments.length)
      var fileBytes = []
      fileBytes = [...fileBytes, ...fileByteSegments[0]];
      for (let index = 1; index < fileByteSegments.length; index++) {
        setTimeout(function () { // Delay to conserve memory
          // Will throw TypeError: fileBytes is not iterable
          // fileBytes is an array, how is that even possible
          // Caused by setTimeout, setTimeout is required to avoid memory crash
          fileBytes = [...fileBytes, ...fileByteSegments[index]];
          fileByteSegments[index] = null; //deallocate
        }, 250);
      }
      const bytes = new Uint8Array(fileBytes);
      fileBytes = null; //deallocate
      resolve(bytes);
    });
  });
}