import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, InputLabel, Paper, colors } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Text from '../../../../components/Typography/Text';
import Button from '../../../../components/Buttons/Button';
import CloseButton from '../../../../components/Buttons/CloseButton';
import fontFamily from '../../../../assets/css/fonts';
import color from '../../../../assets/css/colors';
import { formatQty } from '../../../../lib/fmt';
import { CheckCircle as CheckCircleIcon, Cancel as CrossIcon } from '@material-ui/icons';
const styles = {
  chartContainer: {
    width: '40%',
    height: '20%',
    // top: "40%",
    // left: "40%",
    margin: 5,
    // position: "absolute",
    // transform: "translate(-40%, -40%)"
  },
  table: {
    width: '100%',
    maxWidth: '100%',
    marginBottom: '1rem',
    backgroundColor: 'transparent',
    borderCollapse: 'collapse',
    border: '4px solid #ccc',
  },
  tabletr: {
    //borderBottom: '1px solid #CCC',
    border: '1px solid #CCC',
    paddingBottom: '5px',
  },
  tabletdField: {
    // borderBottom: '1px solid #CCC',
    //margin: '0px 20px 20px 20px',
    // paddingBottom: '7px',
    // paddingTop: '7px',
    padding: '10px',
    fontColor: '#000',
    fontSize: '0.85em',
  },
  tabletdValue: {
    // borderBottom: '1px solid #CCC',
    padding: '10px',
    fontColor: '#000',
    fontSize: '0.85em',
    textAlign: 'right',
    fontWeight: 'bold',
  },
  card: {
    minWidth: 150,
    display: 'inline-block',
    borderRadius: 15,
    //margin: 20,
    background: '#FFF',
    fontSize: '2em',
    fontWeight: '600',
    padding: '20px 20px 0 20px',
    fontFamily: 'Inter',
  },
  cardTitle: {
    fontWeight: '600',
    // fontSize:'30px'
    fontFamily: 'Inter',
  },
  cardValue: {
    minWidth: 150,
    display: 'inline-block',
    borderRadius: 15,
    //margin: 20,
    background: '#FFF',
    fontSize: '2em',
    fontWeight: '600',
    padding: '20px 20px 0 40px',
    fontFamily: 'Inter',
  },
  link: {
    textDecoration: 'none',
    fontSize: '16px',
    color: '#292F4D',
    fontFamily: 'Inter',
  },
  stats: {
    display: 'block',
    borderBottom: '1px solid #CCC',
    //margin: '0px 20px 20px 20px',
    paddingBottom: '10px',
  },
  statsContainer: {
    display: 'block',
    marginTop: '10px',
  },
  relative: {
    position: 'relative',
  },
  countContainer: {
    minWidth: 100,
    background: '#d6dfeb',
    marginBottom: 10,
    padding: 15,
    borderRadius: 15,
  },
  countContainerSuccess: {
    minWidth: 100,
    background: '#5F2B5F',
    marginBottom: 10,
    padding: 15,
    borderRadius: 15,
    color: '#fff !important',
    fontSize: '14px',
    fontFamily: 'Inter',
  },
  countContainerDanger: {
    minWidth: 100,
    background: '#B9324C',
    marginBottom: 10,
    padding: 15,
    borderRadius: 15,
    color: '#fff !important',
    fontSize: '14px',
    fontFamily: 'Inter',
  },
  countContainerWarning: {
    minWidth: 100,
    background: '#28947C',
    marginBottom: 10,
    padding: 15,
    borderRadius: 15,
    color: '#fff !important',
    fontSize: '14px',
    fontFamily: 'Inter',
  },
};

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 1200,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    maxHeight: '75vh',
    overflowY: 'auto',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
}));

export default function OrderEntryMovePreviewModal(props) {
  const classes = useStyles();
  const { orderEntries, isPending, open, close, loading, count, handleClose } = props;

  React.useEffect(() => {
    console.log(orderEntries)
  }, [])

  const getAssetTypeName = (orderEntry) => {
    let assetTypeName = '';

    if (orderEntry.assetType === 'E') assetTypeName = 'Equity';
    else if (orderEntry.assetType === 'MF') assetTypeName = 'Mutual Funds';
    else if (orderEntry.assetType === 'F') assetTypeName = 'Fixed Income';
    else if (orderEntry.assetType === 'O') assetTypeName = 'Option';

    return assetTypeName;
  };

  const getOrderTypeName = (orderEntry) => {
    let orderTypeName = '';

    if (orderEntry.orderType === 'L') orderTypeName = 'Limit Order';
    else if (orderEntry.orderType === 'M') orderTypeName = 'Market Order';
    else if (orderEntry.orderType === 'S') orderTypeName = 'Stop Order';
    else if (orderEntry.orderType === 'SL') orderTypeName = 'Stop Limit Order';
    else if (orderEntry.orderType === 'TS$') orderTypeName = 'Trailing Stop - $';
    else if (orderEntry.orderType === 'TS%') orderTypeName = 'Trailing Stop - %';

    return orderTypeName;
  };

  const getTimeLimit = (orderEntry) => {
    let timeLimitName = '';

    if (orderEntry.tif === 'D') timeLimitName = 'Day';
    else if (orderEntry.tif === 'GTC') timeLimitName = "Good 'til Cancelled";

    return timeLimitName;
  };

  const getBoolean = (bool) => {
    return (
      <div style={{ textAlign: 'right', width: '100%' }}>
        {bool === true && <CheckCircleIcon />}
        {bool != true && <CrossIcon />}
      </div>
    );
  };

  const dateTimeString = (date) => {
    if(date) {
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      const day = date.substring(8, 10);
  
      let formatString = month + '/' + day + '/' + year;
  
      return <div>{formatString}</div>;
    }
   
    return ''
  };

  const getSymbolLabel = (orderEntry) => {
    if (orderEntry.assetType === 'F') return 'Cusip';
    if (orderEntry.action === 'ICE') return 'From Symbol';

    return 'Symbol';
  };

  const getQuantityLabel = (orderEntry) => {
    if (['Sell %', 'Sell All', 'ICE'].includes(orderEntry.action)) return 'Qty %';
    if (['Sell $', 'Buy $'].includes(orderEntry.action)) return 'Qty $';

    return 'Qty';
  };

  const getPutCall = (orderEntry) => {
    if (orderEntry.putCall === 'P') return 'Put';
    if (orderEntry.putCall === 'C') return 'Call';

    return '';
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => close()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div style={{ width: 500 }}>
          <div className={classes.modalHeader}>
            <div>
              <Text
                mt={4}
                variant="h2"
                label={isPending ? 'Move to Trades' : 'Move to Pending'}
              />
              <Text
                color={color.secondary.light}
                mt={10}
                variant="h5"
                label={
                  'Are you sure you want to move ' + (count) + ' items(s) to ' + (isPending ? 'trades' : 'pending') + '?'
                }
              />
            </div>
            <div className={classes.closeButton}>
              <CloseButton onClick={() => close()} />
            </div>
          </div>
          <div className={classes.modalBody}>
            {orderEntries && orderEntries.map((orderEntry) => {
              return (
                <table style={styles.table}>
                  {/* ACCOUNT DETAILS */}
                  <tr style={styles.tabletr}>
                    <td style={styles.tabletdField}>Account No:</td>
                    <td style={styles.tabletdValue}>{orderEntry.accountNo}</td>
                  </tr>

                  <tr style={styles.tabletr}>
                    <td style={styles.tabletdField}>Account Name:</td>
                    <td style={styles.tabletdValue}>{orderEntry.accountName}</td>
                  </tr>

                  <tr style={styles.tabletr}>
                    <td style={styles.tabletdField}>Rep:</td>
                    <td style={styles.tabletdValue}>{orderEntry.rep}</td>
                  </tr>

                  {/* ASSET TYPE */}
                  <tr style={styles.tabletr}>
                    <td style={styles.tabletdField}>Asset Type:</td>
                    <td style={styles.tabletdValue}>{getAssetTypeName(orderEntry)}</td>
                  </tr>

                  <tr style={styles.tabletr}>
                    <td style={styles.tabletdField}>Solicited:</td>
                    <td style={styles.tabletdValue}>{getBoolean(orderEntry.solicited)}</td>
                  </tr>

                  {/* ASSET ACTION */}
                  <tr style={styles.tabletr}>
                    <td style={styles.tabletdField}>Action:</td>
                    <td style={styles.tabletdValue}>{orderEntry.action}</td>
                  </tr>

                  {orderEntry.action === 'Sell $' && orderEntry.assetType === 'MF' && (
                    <tr style={styles.tabletr}>
                      <td style={styles.tabletdField}>Plus Commission:</td>
                      <td style={styles.tabletdValue}>
                        {getBoolean(orderEntry.plusCommission)}
                      </td>
                    </tr>
                  )}

                  <tr style={styles.tabletr}>
                    <td style={styles.tabletdField}>{getSymbolLabel(orderEntry) + ':'}</td>
                    <td style={styles.tabletdValue}>{orderEntry.symbol}</td>
                  </tr>

                  {orderEntry.action === 'ICE' && (
                    <tr style={styles.tabletr}>
                      <td style={styles.tabletdField}>To Symbol:</td>
                      <td style={styles.tabletdValue}>{orderEntry.symbol2}</td>
                    </tr>
                  )}

                  {orderEntry.assetType === 'F' && orderEntry.action !== 'Sell All' ? (
                    <tr style={styles.tabletr}>
                      <td style={styles.tabletdField}>{'Dollar: '}</td>
                      <td style={styles.tabletdValue}>${formatQty(orderEntry.dollar)}</td>
                    </tr>
                  ) : (
                    ((orderEntry.dollar &&
                      orderEntry.assetType === 'E' &&
                      ['Buy', 'Sell', 'Sell All', 'ICE'].includes(orderEntry.action)) ||
                      orderEntry.assetType == 'O') && (
                      <tr style={styles.tabletr}>
                        <td style={styles.tabletdField}>
                          {orderEntry.assetType === 'O' ? 'Contract:' : 'Qty:'}
                        </td>
                        <td style={styles.tabletdValue}>{orderEntry.share}</td>
                      </tr>
                    )
                  )}

                  {(orderEntry.assetType === 'MF' || orderEntry.assetType === 'E') &&
                    ['Buy $', 'Sell $', 'Sell Share'].includes(orderEntry.action) && (
                      <tr style={styles.tabletr}>
                        <td style={styles.tabletdField}>{getQuantityLabel(orderEntry)}</td>
                        <td style={styles.tabletdValue}>
                          {getQuantityLabel(orderEntry) === 'Qty $'
                            ? '$' + formatQty(orderEntry.qty)
                            : orderEntry.qty + '%'}
                        </td>
                      </tr>
                    )}

                  {orderEntry.action === 'Buy' ||
                    (orderEntry.action === 'Buy $' && (
                      <tr style={styles.tabletr}>
                        <td style={styles.tabletdField}>Div Rei</td>
                        <td style={styles.tabletdValue}>
                          {getBoolean(orderEntry.divRei)}
                        </td>
                      </tr>
                    ))}

                  {orderEntry.assetType === 'E' ||
                    (orderEntry.assetType === 'O' && (
                      <tr style={styles.tabletr}>
                        <td style={styles.tabletdField}>Timing:</td>
                        <td style={styles.tabletdValue}>
                          {getOrderTypeName(orderEntry)}
                        </td>
                      </tr>
                    ))}

                  {/* OPTION FIELDS */}

                  {orderEntry.assetType === 'O' && (
                    <tr style={styles.tabletr}>
                      <td style={styles.tabletdField}>Expiration Date:</td>
                      <td style={styles.tabletdValue}>
                        {dateTimeString(orderEntry.expirationDate)}
                      </td>
                    </tr>
                  )}

                  {orderEntry.assetType === 'O' && (
                    <tr style={styles.tabletr}>
                      <td style={styles.tabletdField}>Put or Call:</td>
                      <td style={styles.tabletdValue}>{getPutCall(orderEntry)}</td>
                    </tr>
                  )}

                  {orderEntry.assetType === 'O' && (
                    <tr style={styles.tabletr}>
                      <td style={styles.tabletdField}>Strike Price:</td>
                      <td style={styles.tabletdValue}>${orderEntry.strikePrice}</td>
                    </tr>
                  )}

                  {/* TIMING PRICE AND LIMIT */}
                  {(orderEntry.orderType === 'SL' || orderEntry.orderType === 'S') && (
                    <tr style={styles.tabletr}>
                      <td style={styles.tabletdField}>Stop Price: </td>
                      <td style={styles.tabletdValue}>
                        ${formatQty(orderEntry.stopPrice)}
                      </td>
                    </tr>
                  )}

                  {(orderEntry.orderType === 'L' || orderEntry.orderType === 'SL') && (
                    <tr style={styles.tabletr}>
                      <td style={styles.tabletdField}>Limit Price: </td>
                      <td style={styles.tabletdValue}>
                        ${formatQty(orderEntry.limitPrice)}
                      </td>
                    </tr>
                  )}

                  {(orderEntry.orderType === 'TS%' || orderEntry.orderType === 'TS$') && (
                    <tr style={styles.tabletr}>
                      <td style={styles.tabletdField}>
                        Trailing Stop - {orderEntry.orderType === 'TS%' ? '%' : '$'}:{' '}
                      </td>
                      <td style={styles.tabletdValue}>
                        {orderEntry.orderType === 'TS%' ? '' : '$'}
                        {orderEntry.trailingStop}
                      </td>
                    </tr>
                  )}

                  {orderEntry.assetType &&
                    orderEntry.assetType !== 'F' &&
                    orderEntry.orderType !== 'M' && (
                      <tr style={styles.tabletr}>
                        <td style={styles.tabletdField}>Time Limit: </td>
                        <td style={styles.tabletdValue}>{getTimeLimit(orderEntry)}</td>
                      </tr>
                    )}

                  <tr style={styles.tabletr}>
                    <td style={styles.tabletdField}>Notes to Trader: </td>
                    <td style={styles.tabletdValue}>{orderEntry.userNote}</td>
                  </tr>

                  {orderEntry.assetType && orderEntry.assetType === 'E' && (
                    <tr style={styles.tabletr}>
                      <td style={styles.tabletdField}>Special Instructions: </td>
                      <td style={styles.tabletdValue}>{orderEntry.settlementType}</td>
                    </tr>
                  )}
                </table>
              );
            })}
          </div>
          <div className={classes.modalFooter}>
            <div style={{ marginLeft: '10px' }}>
              <Button
                disabled={loading}
                loading={loading}
                label={loading ? 'MOVING...' : 'YES, MOVE'}
                onClick={handleClose}
              />
            </div>
            <div style={{ marginLeft: '10px' }}>
              <Button
                loading={loading}
                label={'NO, CANCEL'}
                onClick={() => {
                  close();
                }}
              />
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
