import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const APWSnackbar = React.forwardRef((props, ref) => {
    const {id, classes, severity} = props;
    const [open, setOpen] = useState(false);
    const [msg, setMsg] = useState("");

    const openSnackbar = (msg) => {
        setOpen(true);
        setMsg(msg)
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        if (props.onClose) {
            props.onClose()
        }

        setOpen(false);

    };

    React.useImperativeHandle(ref, () => ({
        openSnackbar,
      }));

    return (
            <Snackbar
                key={id}
                id={id}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    classes={{
                        root: classes.borderColor,
                        icon: classes.icon
                    }}
                    onClose={handleClose}
                    severity={severity}
                    action={
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleClose}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                >
                    {msg}
                </Alert>
            </Snackbar>
    );
});




APWSnackbar.propTypes = {
    classes: PropTypes.object.isRequired, // PropTypes for styles
    theme: PropTypes.object.isRequired,   // PropTypes for theme
    id: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    severity: PropTypes.string
};

APWSnackbar.defaultProps = {
    severity: "success"
}

export default withStyles((theme) => ({
    borderColor: (props) => ({
        padding: '6px 13px 6px 6px',
        backgroundColor: theme.palette[props.severity].opacity + ' !important',
        background: `linear-gradient(to right, ${theme.palette[props.severity].main} 34px, transparent 34px) !important`,
    }),
    icon: {
        color: 'white !important'
    },
}), { withTheme: true, name: 'APWSnackbar' })(APWSnackbar);
