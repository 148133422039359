import { Page, PageServiceClient } from '../proto/usrpb/pages_grpc_web_pb';
import { auth } from '../lib/auth/Auth';

const service = new PageServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

export async function listPage(param) {
  return new Promise((resolve, reject) => {
    const req = new Page();
    req.setPageId(param.pageId);
    req.setMenu(param.menu);
    req.setSubMenu(param.subMenu);
    req.setPageName(param.pageName);
    req.setStatus(param.status);

    service.listPage(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        let tempResp = response
          .toObject()
          .pagesList.filter((page) => page.menu !== 'Client Site');
        response.toObject().pagesList = tempResp;
        let resp = { pagesList: [{}] };
        resp.pagesList = tempResp;
        resolve(resp);
      }
    });
  });
}

export async function listPageTable(param) {
  return new Promise((resolve, reject) => {
    const req = new Page();
    req.setPageId(param.pageId);
    req.setMenu(param.menu);
    req.setSubMenu(param.subMenu);
    req.setPageName(param.pageName);
    req.setStatus(param.status);

    service.listPage(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function createPage(param) {
  return new Promise((resolve, reject) => {
    const req = new Page();
    req.setPageId(param.pageId);
    req.setMenu(param.menu);
    req.setSubMenu(param.subMenu);
    req.setPageName(param.pageName);
    req.setStatus(param.status);
    req.setComponent(param.component);

    service.createPage(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updatePage(param) {
  return new Promise((resolve, reject) => {
    const req = new Page();
    req.setPageId(param.pageId);
    req.setMenu(param.menu);
    req.setSubMenu(param.subMenu);
    req.setPageName(param.pageName);
    req.setStatus(param.status);
    req.setComponent(param.component);

    service.updatePage(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function deletePage(param) {
  return new Promise((resolve, reject) => {
    const req = new Page();
    req.setPageId(param.pageId);

    service.deletePage(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
