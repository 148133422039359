import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import color from './colors';
import fontFamily from './fonts';

export const theme = createMuiTheme({
  overrides: {
    MuiAvatar: {
      root: {},
    },
    MuiBadge: {
      root: {},
    },
    MuiBreadcrumbs: {
      root: {},
    },
    MuiButton: {
      root: {
        border: '1px solid',
        borderRadius: '4px',
        borderImageSlice: 1,
        borderWidth: 1,
        borderImageSource:
          'linear-gradient(30deg, ' +
          color.secondary.main +
          ', ' +
          color.primary.main +
          ')',
        fontSize: '0.938rem',
        minWidth: '7.5rem',
        padding: '13px 20px',
        textTransform: 'none',
      },
      contained: {
        backgroundImage:
          'linear-gradient(30deg, ' +
          color.secondary.main +
          ', ' +
          color.primary.main +
          ')',
        boxShadow: 'none',
        color: color.light.main,
        '&:hover': {
          border: '1px solid ' + color.primary.light,
          opacity: 0.7,
          boxShadow: 'none',
        },
        '&:active': {
          backgroundImage: 'unset',
          backgroundColor: color.transparent,
          boxShadow: 'none',
          color: color.primary.main,
          '& svg > path': {
            fill: color.primary.main,
          },
        },
        '&.Mui-disabled': {
          border: '1px solid ' + color.gray.light,
          backgroundImage: 'unset',
          backgroundColor: color.light.opacity,
          color: color.gray.light,
          '& span > span > .MuiCircularProgress-colorPrimary': {
            color: color.primary.main,
            marginRight: 5,
          },
        },
      },
      label: {
        fontFamily: fontFamily.buttons,
        textTransform: 'uppercase',
        fontSize: 13,
      },
      startIcon: {
        '& svg': {
          width: 16,
          height: 16,
          '& path': {
            fill: color.light.main,
          },
        },
      },
      outlinedSizeLarge: {
        padding: '13px 20px',
      }
    },
    MuiButtonGroup: {
      root: {},
    },
    MuiCard: {
      root: {},
    },
    MuiCardActions: {
      root: {},
    },
    MuiCardContent: {
      root: {},
    },
    MuiCardMedia: {
      root: {},
    },
    MuiCardHeader: {
      root: {},
    },
    MuiCheckbox: {
      root: {
        color: color.gray.dark,
      },
    },
    MuiChip: {
      root: {},
    },
    MuiDialog: {
      root: {},
      paper: {
        backgroundColor: color.light.main,
      },
    },
    MuiDialogActions: {
      root: {
        padding: 24,
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0px 24px',
      },
    },
    MuiDialogContentText: {
      root: {},
    },
    MuiDialogTitle: {
      root: {
        '& h2': {
          fontSize: 24,
          fontFamily: fontFamily.headers,
          fontWeight: 700,
          color: color.default.main,
        },
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: color.primary.light,
      },
      barColorPrimary: {
        backgroundColor: color.primary.main,
      },
    },
    MuiIconButton: {
      root: {
        padding: 3,
      },
      label: {
        '& svg': {
          height: 33,
          width: 33,
          '& path': {
            fill: color.default.main,
          },
        },
        '&:hover svg > path': {
          fill: color.primary.main,
        },
      },
    },
    MuiModal: {
      root: {},
    },
    MuiRadio: {
      root: {
        color: color.default.light,
      },
    },
    MuiRadioGroup: {
      root: {},
    },
    MuiSelect: {
      root: {},
    },
    MuiSnackbar: {
      root: {},
    },
    MuiTypography: {
      root: {
        textTransform: 'none',
      },
    },
    MuiTextField: {
      root: {
        '& label + .MuiInput-formControl': {
          marginTop: 28,
        },
      },
    },
    MuiFormControl: {
      root: {
        '& label + .MuiInput-formControl': {
          marginTop: 28,
        },
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: color.light.main,
      },
    },
    MuiInputBase: {
      root: {
        background: color.light.opacity,
        border: '1px solid ' + color.gray.opacity,
        borderRadius: 4,
        color: color.default.main,
        fontFamily: fontFamily.textField,
        fontSize: '14px',
        fontWeight: 600,
        height: 'auto',
        padding: '8px 15px',
        '&.Mui-focused': {
          border: '1px solid ' + color.primary.light,
        },
        '&.Mui-error': {
          border: '1px solid ' + color.error.light,
        },
      },
      input: {},
      multiline: {
        padding: 15,
        '& textarea': {
          lineHeight: '20px',
        },
      },
    },
    MuiInputLabel: {
      asterisk: {
        color: color.error.main,
      },
    },
    MuiInput: {
      input: {},
      underline: {
        '&::before': {
          content: '""',
          position: 'relative',
        },
        '&::after': {
          content: '""',
          position: 'relative',
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: color.default.main,
        fontFamily: fontFamily.label,
        fontSize: 18,
        fontWeight: 600,
        '&.Mui-focused': {
          color: color.primary.main,
        },
        '&.Mui-error': {
          color: color.error.main,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        '&.Mui-error': {
          fontFamily: fontFamily.label,
          fontSize: 13,
          color: color.error.main,
          position: 'absolute',
          right: 0,
          top: -3,
          fontWeight: 600,
          fontStyle: 'italic',
        },
      },
    },
    MuiInputAdornment: {
      root: {
        '& svg': {
          height: '20px !important',
          width: '20px !important',
          '& path': {
            fill: color.primary.main + ' !important',
          },
        },
      },
      positionStart: {
        marginRight: 10,
      },
    },
    MuiLink: {
      button: {
        color: color.primary.light,
        display: 'block',
        fontFamily: fontFamily.label,
        fontSize: 14,
        fontWeight: 600,
        '&:hover': {
          color: color.primary.main,
        },
      },
    },
    MuiCircularProgress: {
      root: {
        width: '16px !important',
        height: '16px !important',
      },
      colorPrimary: {
        color: color.primary.main,
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: 'none',
      },
    },
    MuiMenuItem: {
      root: {
        fontWeight: 600,
      },
    },
    MuiStepper: {
      root: {
        padding: 0,
      },
    },
    MuiStep: {
      root: {},
      completed: {
        color: color.success.main,
      },
    },
    MuiStepLabel: {
      iconContainer: {
        '& .MuiSvgIcon-root': {
          width: 25,
          height: 25,
          fontSize: 12,
        },
      },
      label: {
        fontFamily: fontFamily.headers,
        fontSize: 14,
        fontWeight: 500,
        color: color.gray.opacity + ' !important',
      },
      active: {
        color: color.default.main + ' !important',
        fontWeight: 700 + ' !important',
      },
      completed: {
        color: color.default.main + ' !important',
        fontWeight: 700 + ' !important',
      },
      labelContainer: {
        '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
          marginTop: 8,
        },
      },
    },
    MuiStepButton: {
      root: {
        margin: '-16px 0px',
        padding: 16,
        boxSizing: 'revert',
      },
    },
    MuiStepIcon: {
      root: {
        color: color.gray.opacity,
      },
      active: {
        color: color.primary.main + ' !important',
      },
      completed: {
        color: color.success.main + ' !important',
      },
    },
    MuiStepConnector: {
      alternativeLabel: {
        top: 12,
      },
      lineHorizontal: {
        borderColor: color.gray.opacity + ' !important',
      },
    },
    MuiSlider: {
      markLabel: {
        fontSize: 14,
      },
    },
    MuiDrawer: {
      paperAnchorLeft: {
        backgroundColor: color.light.main,
      },
    },
    MuiAutocomplete: {
      option: {
        backgroundColor: '#ffffff !important',
        '&[data-focus="true"]': {
          backgroundColor: color.light.light + '!important',
        },
      },
    },
  },
  props: {
    MuiAvatar: {},
    MuiBadge: {},
    MuiBreadcrumbs: {},
    MuiButton: {
      disableRipple: true,
      variant: 'contained',
    },
    MuiButtonGroup: {},
    MuiCard: {},
    MuiCardActions: {},
    MuiCardContent: {},
    MuiCardMedia: {},
    MuiCardHeader: {},
    MuiCheckbox: {},
    MuiChip: {},
    MuiDialog: {},
    MuiDialogActions: {},
    MuiDialogContent: {},
    MuiDialogContentText: {},
    MuiDialogTitle: {},
    MuiIconButton: {},
    MuiModal: {},
    MuiRadioGroup: {},
    MuiSelect: {},
    MuiSnackbar: {},
    MuiTooltip: {},
    MuiTypography: {},
  },
  typography: {
    h1: {
      color: color.default.main,
      fontFamily: fontFamily.headers,
      fontWeight: 900,
      fontSize: 32,
      lineHeight: '30px',
      letterSpacing: '',
    },
    h2: {
      color: color.default.main,
      fontFamily: fontFamily.headers,
      fontWeight: 900,
      fontSize: '',
      lineHeight: '20px',
      letterSpacing: '',
    },
    h3: {
      color: color.default.main,
      fontFamily: fontFamily.headers,
      fontWeight: 900,
      fontSize: '',
      lineHeight: '20px',
      letterSpacing: '',
    },
    h4: {
      fontFamily: '',
      fontWeight: '',
      fontSize: '',
      lineHeight: '',
      letterSpacing: '',
    },
    h5: {
      fontFamily: fontFamily.headers,
      fontWeight: 900,
      fontSize: 14,
      lineHeight: '',
      letterSpacing: '',
    },
    subtitle1: {
      color: color.default.main,
      fontFamily: fontFamily.label,
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '23px',
      letterSpacing: '',
    },
    subtitle2: {
      color: color.gray.main,
      fontFamily: fontFamily.label,
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '23px',
      letterSpacing: '',
    },
    body1: {
      color: color.default.main,
      fontFamily: fontFamily.label,
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '22px',
      letterSpacing: '',
    },
    body2: {
      color: color.default.main,
      fontFamily: fontFamily.label,
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '23px',
      letterSpacing: '',
    },
    button: {
      fontFamily: fontFamily.headers,
      fontWeight: 600,
      fontSize: '',
      lineHeight: '',
      letterSpacing: '',
    },
    caption: {
      fontFamily: '',
      fontWeight: '',
      fontSize: '',
      lineHeight: '',
      letterSpacing: '',
    },
  },
});
