// source: proto/reorgpb/entitlement.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_type_date_pb = require('../../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.reorgpb.Entitlement', null, global);
goog.exportSymbol('proto.reorgpb.ListEntitlementRequest', null, global);
goog.exportSymbol('proto.reorgpb.ListEntitlementResponse', null, global);
goog.exportSymbol('proto.reorgpb.UpdateEntitlementRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.Entitlement = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.Entitlement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.Entitlement.displayName = 'proto.reorgpb.Entitlement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.ListEntitlementRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.ListEntitlementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.ListEntitlementRequest.displayName = 'proto.reorgpb.ListEntitlementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.ListEntitlementResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reorgpb.ListEntitlementResponse.repeatedFields_, null);
};
goog.inherits(proto.reorgpb.ListEntitlementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.ListEntitlementResponse.displayName = 'proto.reorgpb.ListEntitlementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.UpdateEntitlementRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.UpdateEntitlementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.UpdateEntitlementRequest.displayName = 'proto.reorgpb.UpdateEntitlementRequest';
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.reorgpb.Entitlement.prototype.toObject = function (opt_includeInstance) {
    return proto.reorgpb.Entitlement.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.reorgpb.Entitlement} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.reorgpb.Entitlement.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        entitlementId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        textNumber: jspb.Message.getFieldWithDefault(msg, 2, ''),
        accountNo: jspb.Message.getFieldWithDefault(msg, 3, ''),
        accountName: jspb.Message.getFieldWithDefault(msg, 4, ''),
        correspondent: jspb.Message.getFieldWithDefault(msg, 5, ''),
        type: jspb.Message.getFieldWithDefault(msg, 6, ''),
        entryType: jspb.Message.getFieldWithDefault(msg, 7, ''),
        symbol: jspb.Message.getFieldWithDefault(msg, 8, ''),
        originalCusip: jspb.Message.getFieldWithDefault(msg, 9, ''),
        cusip: jspb.Message.getFieldWithDefault(msg, 10, ''),
        systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
        processDate: (f = msg.getProcessDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
        positionDate: (f = msg.getPositionDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
        recordDate: (f = msg.getRecordDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
        payableDate: (f = msg.getPayableDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
        declarationDate: (f = msg.getDeclarationDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
        expirationDate: (f = msg.getExpirationDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
        cashRate: jspb.Message.getFieldWithDefault(msg, 18, ''),
        qtyRate: jspb.Message.getFieldWithDefault(msg, 19, ''),
        dtccCashRate: jspb.Message.getFieldWithDefault(msg, 20, ''),
        noCil: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
        cash: jspb.Message.getFieldWithDefault(msg, 22, ''),
        qty: jspb.Message.getFieldWithDefault(msg, 23, ''),
        fractionalCash: jspb.Message.getFieldWithDefault(msg, 24, ''),
        fractionalQty: jspb.Message.getFieldWithDefault(msg, 25, ''),
        taxCountry: jspb.Message.getFieldWithDefault(msg, 26, ''),
        taxRate: jspb.Message.getFieldWithDefault(msg, 27, ''),
        status: jspb.Message.getFieldWithDefault(msg, 28, ''),
        description: jspb.Message.getFieldWithDefault(msg, 29, ''),
        detail: jspb.Message.getFieldWithDefault(msg, 30, ''),
        executionId: jspb.Message.getFieldWithDefault(msg, 31, ''),
        voluntary: jspb.Message.getFieldWithDefault(msg, 32, ''),
        voluntaryAction: jspb.Message.getFieldWithDefault(msg, 33, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.Entitlement}
 */
proto.reorgpb.Entitlement.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.Entitlement();
  return proto.reorgpb.Entitlement.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.Entitlement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.Entitlement}
 */
proto.reorgpb.Entitlement.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setEntitlementId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setTextNumber(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setAccountNo(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setAccountName(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setCorrespondent(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setType(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setEntryType(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setSymbol(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setOriginalCusip(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setCusip(value);
        break;
      case 11:
        var value = new google_type_date_pb.Date();
        reader.readMessage(value, google_type_date_pb.Date.deserializeBinaryFromReader);
        msg.setSystemDate(value);
        break;
      case 12:
        var value = new google_type_date_pb.Date();
        reader.readMessage(value, google_type_date_pb.Date.deserializeBinaryFromReader);
        msg.setProcessDate(value);
        break;
      case 13:
        var value = new google_type_date_pb.Date();
        reader.readMessage(value, google_type_date_pb.Date.deserializeBinaryFromReader);
        msg.setPositionDate(value);
        break;
      case 14:
        var value = new google_type_date_pb.Date();
        reader.readMessage(value, google_type_date_pb.Date.deserializeBinaryFromReader);
        msg.setRecordDate(value);
        break;
      case 15:
        var value = new google_type_date_pb.Date();
        reader.readMessage(value, google_type_date_pb.Date.deserializeBinaryFromReader);
        msg.setPayableDate(value);
        break;
      case 16:
        var value = new google_type_date_pb.Date();
        reader.readMessage(value, google_type_date_pb.Date.deserializeBinaryFromReader);
        msg.setDeclarationDate(value);
        break;
      case 17:
        var value = new google_type_date_pb.Date();
        reader.readMessage(value, google_type_date_pb.Date.deserializeBinaryFromReader);
        msg.setExpirationDate(value);
        break;
      case 18:
        var value = /** @type {string} */ (reader.readString());
        msg.setCashRate(value);
        break;
      case 19:
        var value = /** @type {string} */ (reader.readString());
        msg.setQtyRate(value);
        break;
      case 20:
        var value = /** @type {string} */ (reader.readString());
        msg.setDtccCashRate(value);
        break;
      case 21:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setNoCil(value);
        break;
      case 22:
        var value = /** @type {string} */ (reader.readString());
        msg.setCash(value);
        break;
      case 23:
        var value = /** @type {string} */ (reader.readString());
        msg.setQty(value);
        break;
      case 24:
        var value = /** @type {string} */ (reader.readString());
        msg.setFractionalCash(value);
        break;
      case 25:
        var value = /** @type {string} */ (reader.readString());
        msg.setFractionalQty(value);
        break;
      case 26:
        var value = /** @type {string} */ (reader.readString());
        msg.setTaxCountry(value);
        break;
      case 27:
        var value = /** @type {string} */ (reader.readString());
        msg.setTaxRate(value);
        break;
      case 28:
        var value = /** @type {string} */ (reader.readString());
        msg.setStatus(value);
        break;
      case 29:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      case 30:
        var value = /** @type {string} */ (reader.readString());
        msg.setDetail(value);
        break;
      case 31:
        var value = /** @type {string} */ (reader.readString());
        msg.setExecutionId(value);
        break;
      case 32:
        var value = /** @type {string} */ (reader.readString());
        msg.setVoluntary(value);
        break;
      case 33:
        var value = /** @type {string} */ (reader.readString());
        msg.setVoluntaryAction(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.Entitlement.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.Entitlement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.Entitlement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.Entitlement.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getEntitlementId();
  if (f !== 0) {
    writer.writeUint32(1, f);
  }
  f = message.getTextNumber();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
  f = message.getEntryType();
  if (f.length > 0) {
    writer.writeString(7, f);
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(8, f);
  }
  f = message.getOriginalCusip();
  if (f.length > 0) {
    writer.writeString(9, f);
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(10, f);
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(11, f, google_type_date_pb.Date.serializeBinaryToWriter);
  }
  f = message.getProcessDate();
  if (f != null) {
    writer.writeMessage(12, f, google_type_date_pb.Date.serializeBinaryToWriter);
  }
  f = message.getPositionDate();
  if (f != null) {
    writer.writeMessage(13, f, google_type_date_pb.Date.serializeBinaryToWriter);
  }
  f = message.getRecordDate();
  if (f != null) {
    writer.writeMessage(14, f, google_type_date_pb.Date.serializeBinaryToWriter);
  }
  f = message.getPayableDate();
  if (f != null) {
    writer.writeMessage(15, f, google_type_date_pb.Date.serializeBinaryToWriter);
  }
  f = message.getDeclarationDate();
  if (f != null) {
    writer.writeMessage(16, f, google_type_date_pb.Date.serializeBinaryToWriter);
  }
  f = message.getExpirationDate();
  if (f != null) {
    writer.writeMessage(17, f, google_type_date_pb.Date.serializeBinaryToWriter);
  }
  f = message.getCashRate();
  if (f.length > 0) {
    writer.writeString(18, f);
  }
  f = message.getQtyRate();
  if (f.length > 0) {
    writer.writeString(19, f);
  }
  f = message.getDtccCashRate();
  if (f.length > 0) {
    writer.writeString(20, f);
  }
  f = message.getNoCil();
  if (f) {
    writer.writeBool(21, f);
  }
  f = message.getCash();
  if (f.length > 0) {
    writer.writeString(22, f);
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(23, f);
  }
  f = message.getFractionalCash();
  if (f.length > 0) {
    writer.writeString(24, f);
  }
  f = message.getFractionalQty();
  if (f.length > 0) {
    writer.writeString(25, f);
  }
  f = message.getTaxCountry();
  if (f.length > 0) {
    writer.writeString(26, f);
  }
  f = message.getTaxRate();
  if (f.length > 0) {
    writer.writeString(27, f);
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(28, f);
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(29, f);
  }
  f = message.getDetail();
  if (f.length > 0) {
    writer.writeString(30, f);
  }
  f = message.getExecutionId();
  if (f.length > 0) {
    writer.writeString(31, f);
  }
  f = message.getVoluntary();
  if (f.length > 0) {
    writer.writeString(32, f);
  }
  f = message.getVoluntaryAction();
  if (f.length > 0) {
    writer.writeString(33, f);
  }
};

/**
 * optional uint32 entitlement_id = 1;
 * @return {number}
 */
proto.reorgpb.Entitlement.prototype.getEntitlementId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setEntitlementId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional string text_number = 2;
 * @return {string}
 */
proto.reorgpb.Entitlement.prototype.getTextNumber = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setTextNumber = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.reorgpb.Entitlement.prototype.getAccountNo = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setAccountNo = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string account_name = 4;
 * @return {string}
 */
proto.reorgpb.Entitlement.prototype.getAccountName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setAccountName = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string correspondent = 5;
 * @return {string}
 */
proto.reorgpb.Entitlement.prototype.getCorrespondent = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setCorrespondent = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional string type = 6;
 * @return {string}
 */
proto.reorgpb.Entitlement.prototype.getType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setType = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional string entry_type = 7;
 * @return {string}
 */
proto.reorgpb.Entitlement.prototype.getEntryType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setEntryType = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};

/**
 * optional string symbol = 8;
 * @return {string}
 */
proto.reorgpb.Entitlement.prototype.getSymbol = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setSymbol = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};

/**
 * optional string original_cusip = 9;
 * @return {string}
 */
proto.reorgpb.Entitlement.prototype.getOriginalCusip = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setOriginalCusip = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};

/**
 * optional string cusip = 10;
 * @return {string}
 */
proto.reorgpb.Entitlement.prototype.getCusip = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setCusip = function (value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};

/**
 * optional google.type.Date system_date = 11;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Entitlement.prototype.getSystemDate = function () {
  return /** @type{?proto.google.type.Date} */ (jspb.Message.getWrapperField(this, google_type_date_pb.Date, 11));
};

/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setSystemDate = function (value) {
  return jspb.Message.setWrapperField(this, 11, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.clearSystemDate = function () {
  return this.setSystemDate(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Entitlement.prototype.hasSystemDate = function () {
  return jspb.Message.getField(this, 11) != null;
};

/**
 * optional google.type.Date process_date = 12;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Entitlement.prototype.getProcessDate = function () {
  return /** @type{?proto.google.type.Date} */ (jspb.Message.getWrapperField(this, google_type_date_pb.Date, 12));
};

/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setProcessDate = function (value) {
  return jspb.Message.setWrapperField(this, 12, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.clearProcessDate = function () {
  return this.setProcessDate(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Entitlement.prototype.hasProcessDate = function () {
  return jspb.Message.getField(this, 12) != null;
};

/**
 * optional google.type.Date position_date = 13;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Entitlement.prototype.getPositionDate = function () {
  return /** @type{?proto.google.type.Date} */ (jspb.Message.getWrapperField(this, google_type_date_pb.Date, 13));
};

/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setPositionDate = function (value) {
  return jspb.Message.setWrapperField(this, 13, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.clearPositionDate = function () {
  return this.setPositionDate(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Entitlement.prototype.hasPositionDate = function () {
  return jspb.Message.getField(this, 13) != null;
};

/**
 * optional google.type.Date record_date = 14;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Entitlement.prototype.getRecordDate = function () {
  return /** @type{?proto.google.type.Date} */ (jspb.Message.getWrapperField(this, google_type_date_pb.Date, 14));
};

/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setRecordDate = function (value) {
  return jspb.Message.setWrapperField(this, 14, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.clearRecordDate = function () {
  return this.setRecordDate(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Entitlement.prototype.hasRecordDate = function () {
  return jspb.Message.getField(this, 14) != null;
};

/**
 * optional google.type.Date payable_date = 15;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Entitlement.prototype.getPayableDate = function () {
  return /** @type{?proto.google.type.Date} */ (jspb.Message.getWrapperField(this, google_type_date_pb.Date, 15));
};

/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setPayableDate = function (value) {
  return jspb.Message.setWrapperField(this, 15, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.clearPayableDate = function () {
  return this.setPayableDate(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Entitlement.prototype.hasPayableDate = function () {
  return jspb.Message.getField(this, 15) != null;
};

/**
 * optional google.type.Date declaration_date = 16;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Entitlement.prototype.getDeclarationDate = function () {
  return /** @type{?proto.google.type.Date} */ (jspb.Message.getWrapperField(this, google_type_date_pb.Date, 16));
};

/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setDeclarationDate = function (value) {
  return jspb.Message.setWrapperField(this, 16, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.clearDeclarationDate = function () {
  return this.setDeclarationDate(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Entitlement.prototype.hasDeclarationDate = function () {
  return jspb.Message.getField(this, 16) != null;
};

/**
 * optional google.type.Date expiration_date = 17;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Entitlement.prototype.getExpirationDate = function () {
  return /** @type{?proto.google.type.Date} */ (jspb.Message.getWrapperField(this, google_type_date_pb.Date, 17));
};

/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setExpirationDate = function (value) {
  return jspb.Message.setWrapperField(this, 17, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.clearExpirationDate = function () {
  return this.setExpirationDate(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Entitlement.prototype.hasExpirationDate = function () {
  return jspb.Message.getField(this, 17) != null;
};

/**
 * optional string cash_rate = 18;
 * @return {string}
 */
proto.reorgpb.Entitlement.prototype.getCashRate = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setCashRate = function (value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};

/**
 * optional string qty_rate = 19;
 * @return {string}
 */
proto.reorgpb.Entitlement.prototype.getQtyRate = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setQtyRate = function (value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};

/**
 * optional string dtcc_cash_rate = 20;
 * @return {string}
 */
proto.reorgpb.Entitlement.prototype.getDtccCashRate = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setDtccCashRate = function (value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};

/**
 * optional bool no_cil = 21;
 * @return {boolean}
 */
proto.reorgpb.Entitlement.prototype.getNoCil = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};

/**
 * @param {boolean} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setNoCil = function (value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};

/**
 * optional string cash = 22;
 * @return {string}
 */
proto.reorgpb.Entitlement.prototype.getCash = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setCash = function (value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};

/**
 * optional string qty = 23;
 * @return {string}
 */
proto.reorgpb.Entitlement.prototype.getQty = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setQty = function (value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};

/**
 * optional string fractional_cash = 24;
 * @return {string}
 */
proto.reorgpb.Entitlement.prototype.getFractionalCash = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setFractionalCash = function (value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};

/**
 * optional string fractional_qty = 25;
 * @return {string}
 */
proto.reorgpb.Entitlement.prototype.getFractionalQty = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setFractionalQty = function (value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};

/**
 * optional string tax_country = 26;
 * @return {string}
 */
proto.reorgpb.Entitlement.prototype.getTaxCountry = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setTaxCountry = function (value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};

/**
 * optional string tax_rate = 27;
 * @return {string}
 */
proto.reorgpb.Entitlement.prototype.getTaxRate = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setTaxRate = function (value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};

/**
 * optional string status = 28;
 * @return {string}
 */
proto.reorgpb.Entitlement.prototype.getStatus = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setStatus = function (value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};

/**
 * optional string description = 29;
 * @return {string}
 */
proto.reorgpb.Entitlement.prototype.getDescription = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setDescription = function (value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};

/**
 * optional string detail = 30;
 * @return {string}
 */
proto.reorgpb.Entitlement.prototype.getDetail = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setDetail = function (value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};

/**
 * optional string execution_id = 31;
 * @return {string}
 */
proto.reorgpb.Entitlement.prototype.getExecutionId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setExecutionId = function (value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};

/**
 * optional string voluntary = 32;
 * @return {string}
 */
proto.reorgpb.Entitlement.prototype.getVoluntary = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setVoluntary = function (value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};

/**
 * optional string voluntary_action = 33;
 * @return {string}
 */
proto.reorgpb.Entitlement.prototype.getVoluntaryAction = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.Entitlement} returns this
 */
proto.reorgpb.Entitlement.prototype.setVoluntaryAction = function (value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.reorgpb.ListEntitlementRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.reorgpb.ListEntitlementRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.reorgpb.ListEntitlementRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.reorgpb.ListEntitlementRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        textNumber: jspb.Message.getFieldWithDefault(msg, 1, ''),
        fromSystemDate: (f = msg.getFromSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
        toSystemDate: (f = msg.getToSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
        dateType: jspb.Message.getFieldWithDefault(msg, 4, ''),
        entryType: jspb.Message.getFieldWithDefault(msg, 5, ''),
        accountNo: jspb.Message.getFieldWithDefault(msg, 6, ''),
        accountName: jspb.Message.getFieldWithDefault(msg, 7, ''),
        symbol: jspb.Message.getFieldWithDefault(msg, 8, ''),
        status: jspb.Message.getFieldWithDefault(msg, 9, ''),
        type: jspb.Message.getFieldWithDefault(msg, 10, ''),
        cusip: jspb.Message.getFieldWithDefault(msg, 11, ''),
        usrId: jspb.Message.getFieldWithDefault(msg, 12, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.ListEntitlementRequest}
 */
proto.reorgpb.ListEntitlementRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.ListEntitlementRequest();
  return proto.reorgpb.ListEntitlementRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.ListEntitlementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.ListEntitlementRequest}
 */
proto.reorgpb.ListEntitlementRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTextNumber(value);
        break;
      case 2:
        var value = new google_type_date_pb.Date();
        reader.readMessage(value, google_type_date_pb.Date.deserializeBinaryFromReader);
        msg.setFromSystemDate(value);
        break;
      case 3:
        var value = new google_type_date_pb.Date();
        reader.readMessage(value, google_type_date_pb.Date.deserializeBinaryFromReader);
        msg.setToSystemDate(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setDateType(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setEntryType(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setAccountNo(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setAccountName(value);
        break;
      case 8:
        var value = /** @type {string} */ (reader.readString());
        msg.setSymbol(value);
        break;
      case 9:
        var value = /** @type {string} */ (reader.readString());
        msg.setStatus(value);
        break;
      case 10:
        var value = /** @type {string} */ (reader.readString());
        msg.setType(value);
        break;
      case 11:
        var value = /** @type {string} */ (reader.readString());
        msg.setCusip(value);
        break;
      case 12:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setUsrId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.ListEntitlementRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.ListEntitlementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.ListEntitlementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListEntitlementRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTextNumber();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getFromSystemDate();
  if (f != null) {
    writer.writeMessage(2, f, google_type_date_pb.Date.serializeBinaryToWriter);
  }
  f = message.getToSystemDate();
  if (f != null) {
    writer.writeMessage(3, f, google_type_date_pb.Date.serializeBinaryToWriter);
  }
  f = message.getDateType();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getEntryType();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(7, f);
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(8, f);
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(9, f);
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(10, f);
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(11, f);
  }
  f = message.getUsrId();
  if (f !== 0) {
    writer.writeUint32(12, f);
  }
};

/**
 * optional string text_number = 1;
 * @return {string}
 */
proto.reorgpb.ListEntitlementRequest.prototype.getTextNumber = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.ListEntitlementRequest} returns this
 */
proto.reorgpb.ListEntitlementRequest.prototype.setTextNumber = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional google.type.Date from_system_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListEntitlementRequest.prototype.getFromSystemDate = function () {
  return /** @type{?proto.google.type.Date} */ (jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};

/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListEntitlementRequest} returns this
 */
proto.reorgpb.ListEntitlementRequest.prototype.setFromSystemDate = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListEntitlementRequest} returns this
 */
proto.reorgpb.ListEntitlementRequest.prototype.clearFromSystemDate = function () {
  return this.setFromSystemDate(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListEntitlementRequest.prototype.hasFromSystemDate = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * optional google.type.Date to_system_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListEntitlementRequest.prototype.getToSystemDate = function () {
  return /** @type{?proto.google.type.Date} */ (jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3));
};

/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListEntitlementRequest} returns this
 */
proto.reorgpb.ListEntitlementRequest.prototype.setToSystemDate = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListEntitlementRequest} returns this
 */
proto.reorgpb.ListEntitlementRequest.prototype.clearToSystemDate = function () {
  return this.setToSystemDate(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListEntitlementRequest.prototype.hasToSystemDate = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional string date_type = 4;
 * @return {string}
 */
proto.reorgpb.ListEntitlementRequest.prototype.getDateType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.ListEntitlementRequest} returns this
 */
proto.reorgpb.ListEntitlementRequest.prototype.setDateType = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional string entry_type = 5;
 * @return {string}
 */
proto.reorgpb.ListEntitlementRequest.prototype.getEntryType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.ListEntitlementRequest} returns this
 */
proto.reorgpb.ListEntitlementRequest.prototype.setEntryType = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional string account_no = 6;
 * @return {string}
 */
proto.reorgpb.ListEntitlementRequest.prototype.getAccountNo = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.ListEntitlementRequest} returns this
 */
proto.reorgpb.ListEntitlementRequest.prototype.setAccountNo = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional string account_name = 7;
 * @return {string}
 */
proto.reorgpb.ListEntitlementRequest.prototype.getAccountName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.ListEntitlementRequest} returns this
 */
proto.reorgpb.ListEntitlementRequest.prototype.setAccountName = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};

/**
 * optional string symbol = 8;
 * @return {string}
 */
proto.reorgpb.ListEntitlementRequest.prototype.getSymbol = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.ListEntitlementRequest} returns this
 */
proto.reorgpb.ListEntitlementRequest.prototype.setSymbol = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};

/**
 * optional string status = 9;
 * @return {string}
 */
proto.reorgpb.ListEntitlementRequest.prototype.getStatus = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.ListEntitlementRequest} returns this
 */
proto.reorgpb.ListEntitlementRequest.prototype.setStatus = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};

/**
 * optional string type = 10;
 * @return {string}
 */
proto.reorgpb.ListEntitlementRequest.prototype.getType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.ListEntitlementRequest} returns this
 */
proto.reorgpb.ListEntitlementRequest.prototype.setType = function (value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};

/**
 * optional string cusip = 11;
 * @return {string}
 */
proto.reorgpb.ListEntitlementRequest.prototype.getCusip = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.ListEntitlementRequest} returns this
 */
proto.reorgpb.ListEntitlementRequest.prototype.setCusip = function (value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};

/**
 * optional uint32 usr_id = 12;
 * @return {number}
 */
proto.reorgpb.ListEntitlementRequest.prototype.getUsrId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};

/**
 * @param {number} value
 * @return {!proto.reorgpb.ListEntitlementRequest} returns this
 */
proto.reorgpb.ListEntitlementRequest.prototype.setUsrId = function (value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reorgpb.ListEntitlementResponse.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.reorgpb.ListEntitlementResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.reorgpb.ListEntitlementResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.reorgpb.ListEntitlementResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.reorgpb.ListEntitlementResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        entitlementsList: jspb.Message.toObjectList(
          msg.getEntitlementsList(),
          proto.reorgpb.Entitlement.toObject,
          includeInstance
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.ListEntitlementResponse}
 */
proto.reorgpb.ListEntitlementResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.ListEntitlementResponse();
  return proto.reorgpb.ListEntitlementResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.ListEntitlementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.ListEntitlementResponse}
 */
proto.reorgpb.ListEntitlementResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.reorgpb.Entitlement();
        reader.readMessage(value, proto.reorgpb.Entitlement.deserializeBinaryFromReader);
        msg.addEntitlements(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.ListEntitlementResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.ListEntitlementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.ListEntitlementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListEntitlementResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getEntitlementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(1, f, proto.reorgpb.Entitlement.serializeBinaryToWriter);
  }
};

/**
 * repeated Entitlement entitlements = 1;
 * @return {!Array<!proto.reorgpb.Entitlement>}
 */
proto.reorgpb.ListEntitlementResponse.prototype.getEntitlementsList = function () {
  return /** @type{!Array<!proto.reorgpb.Entitlement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reorgpb.Entitlement, 1)
  );
};

/**
 * @param {!Array<!proto.reorgpb.Entitlement>} value
 * @return {!proto.reorgpb.ListEntitlementResponse} returns this
 */
proto.reorgpb.ListEntitlementResponse.prototype.setEntitlementsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.reorgpb.Entitlement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reorgpb.Entitlement}
 */
proto.reorgpb.ListEntitlementResponse.prototype.addEntitlements = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reorgpb.Entitlement, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reorgpb.ListEntitlementResponse} returns this
 */
proto.reorgpb.ListEntitlementResponse.prototype.clearEntitlementsList = function () {
  return this.setEntitlementsList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.reorgpb.UpdateEntitlementRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.reorgpb.UpdateEntitlementRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.reorgpb.UpdateEntitlementRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.reorgpb.UpdateEntitlementRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        entitlementId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        voluntaryAction: jspb.Message.getFieldWithDefault(msg, 2, ''),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.UpdateEntitlementRequest}
 */
proto.reorgpb.UpdateEntitlementRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.UpdateEntitlementRequest();
  return proto.reorgpb.UpdateEntitlementRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.UpdateEntitlementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.UpdateEntitlementRequest}
 */
proto.reorgpb.UpdateEntitlementRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setEntitlementId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setVoluntaryAction(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.UpdateEntitlementRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.UpdateEntitlementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.UpdateEntitlementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.UpdateEntitlementRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getEntitlementId();
  if (f !== 0) {
    writer.writeUint32(1, f);
  }
  f = message.getVoluntaryAction();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional uint32 entitlement_id = 1;
 * @return {number}
 */
proto.reorgpb.UpdateEntitlementRequest.prototype.getEntitlementId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.reorgpb.UpdateEntitlementRequest} returns this
 */
proto.reorgpb.UpdateEntitlementRequest.prototype.setEntitlementId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional string voluntary_action = 2;
 * @return {string}
 */
proto.reorgpb.UpdateEntitlementRequest.prototype.getVoluntaryAction = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''));
};

/**
 * @param {string} value
 * @return {!proto.reorgpb.UpdateEntitlementRequest} returns this
 */
proto.reorgpb.UpdateEntitlementRequest.prototype.setVoluntaryAction = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

goog.object.extend(exports, proto.reorgpb);
