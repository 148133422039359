import {
  ModelingServiceClient,
  ListModelingRequest,
  CreateTradeSheetRequest
} from '../proto/tradepb/modeling_grpc_web_pb';
import { auth } from '../lib/auth/Auth';
import authSvc from './AuthService';

const service = new ModelingServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

const userId = authSvc.getCurrentUser()?.UserId;

export async function listModeling(param) {
  return new Promise((resolve, reject) => {
    const req = new ListModelingRequest();
    req.setAccountValue(param.accountValue);
    req.setModel(param.model);
    req.setPlatform(param.platform);
    req.setEquity(param.equity);
    req.setFixedIncomeI(param.fixedIncomeI);

    service.listModeling(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function createTradeSheet(param) {
  return new Promise((resolve, reject) => {
    let req = new CreateTradeSheetRequest();
    req.setAccountNo(param.accountNo);
    req.setAccountValue(param.accountValue);
    req.setModel(param.model);
    req.setPlatform(param.platform);
    req.setEquity(param.equity);
    req.setFixedIncomeI(param.fixedIncomeI);

    service.createTradeSheet(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

