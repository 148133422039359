import {
	ContactUs,
  ContactUsServiceClient,
} from '../proto/admpb/contactus_grpc_web_pb';
import { notifyError } from '../components/Messages/Notification';
import { auth } from '../lib/auth/Auth';

const service = new ContactUsServiceClient(
	window.env.GRPC_ENDPOINT,
	{},
	{ ...auth }
);

export async function createContactUs(param) {
	return new Promise((resolve, reject) => {
		const req = new ContactUs();
		req.setSubject(param.subject);
		req.setMessage(param.message);
		req.setUsrId(param.usrId);
		req.setPageId(param.pageId);

		service.createContactUs(req, {}, (error, response) => {
			if (error) {
				notifyError(error.message);
				reject(error);
			} else {
				resolve(response.toObject());
			}
		});
	});
}

export async function updateContactUs(param) {
	return new Promise((resolve, reject) => {
		const req = new ContactUs();
		req.setContactUsId(param.contactUsId);
		req.setStatus(param.status);
		req.setInternalNote(param.internalNote);

		service.updateContactUs(req, {}, (error, response) => {
			if (error) {
				notifyError(error.message);
				reject(error);
			} else {
				resolve(response.toObject());
			}
		});
	});
}

export async function listContactUs(param) {
	return new Promise((resolve, reject) => {
		const req = new ContactUs();
		req.setContactUsId(param.contactUsId);
		req.setPageId(param.pageId);
		req.setUsrId(param.usrId);
		req.setStatus(param.status);

		service.listContactUs(req, {}, (error, response) => {
			if (error) {
				notifyError(error.message);
				reject(error);
			} else {
				resolve(response.toObject());
			}
		});
	});
}
