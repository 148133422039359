import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { ReactComponent as Info } from '../../assets/icons/info.svg';
import color from '../../assets/css/colors';
import { listSystemCode } from '../../services/CommonService';

const useStyles = makeStyles(() => ({
  dropdownWrapper: {
    display: 'flex',
    position: 'relative',
  },
  tooltipWrapper: {
    position: 'absolute',
    top: 29,
    right: 16,
    padding: '10px 10px 7px 10px',
    backgroundColor: 'rgba(0,0,0,0)',
    borderRadius: 5,
    '& svg': {
      width: 24,
      height: 24,
      cursor: 'help',
      '& path': {
        fill: color.primary.main,
      },
    },
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
}));

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: color.light.main,
    maxWidth: 620,
    border: '1px solid ' + color.light.light,
    borderRadius: 10,
  },
}))(Tooltip);

const SelectSystemCode = (props) => {
  const classes = useStyles();

  const [systemCode, setSystemCode] = React.useState([]);
  const [mounted, setMounted] = React.useState(false);

  const getSystemCodeTypes = async () => {
    const { value, type, subType, orderBy, useFor, action } = props;

    let params = {
      type: type,
      subType: subType,
      orderBy: orderBy,
    };

    const { systemCodeList } = await listSystemCode(params);

    let systemCodeOptions = systemCodeList;

    if (useFor === 'Wallet' && value === 'Active') {
      systemCodeOptions = systemCodeList.filter((systemCode) =>
        ['Disabled', 'Active'].includes(systemCode.code)
      );
    }

    if (props.withBankStatusGroup) {
      systemCodeOptions.push({
        code: 'Failed',
        description: 'Failed',
        subType: 'Bank Request',
        note: '',
      });
      systemCodeOptions.push({
        code: 'Done',
        description: 'Done',
        subType: 'Bank Request',
        note: '',
      });
      systemCodeOptions.push({
        code: 'AllPending',
        description: 'AllPending',
        subType: 'Bank Request',
        note: '',
      });
    }

    if (type === 'Owner Type' && action === false) {
      setSystemCode(
        systemCodeOptions.filter((data) => data.code.includes('Beneficiary'))
      );
    } else {
      setSystemCode(systemCodeOptions);
    }
  };

  React.useEffect(() => {
    if (!systemCode.length && !mounted) {
      getSystemCodeTypes();
    }
    return () => {
      setMounted(true);
    };
    // eslint-disable-next-line
  }, [systemCode, mounted]);

  // to handle changing type/subType in a single select
  React.useEffect(() => {
    if (mounted) {
      getSystemCodeTypes();
    }
    // eslint-disable-next-line
  }, [props.type, props.subType]);

  return (
    <div className={classes.dropdownWrapper}>
      {systemCode.length && (
        <FormControl
          fullWidth
          error={props.error}
          style={{
            marginTop: props.mt ? props.mt : 0,
            marginBottom: props.mb ? props.mb : 0,
          }}
        >
          <InputLabel
            className={props.disabled ? classes.disabled : ''}
            shrink
            required={props.required}
          >
            {props.label}
          </InputLabel>
          <Select
            displayEmpty
            fullWidth
            defaultValue=""
            disabled={props.disabled}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
          >
            <MenuItem key="blank" value="">
              <span style={{ color: '#bbbdc5' }}>{props.placeholder}</span>
            </MenuItem>
            {systemCode.length ? (
              systemCode.map((item, index) => (
                <MenuItem
                  disabled={
                    item && props.disabledItem
                      ? props.disabledItem[item[props.valueType || 'code']]
                      : false
                  }
                  key={index}
                  value={item ? item[props.valueType || 'code'] : ''}
                >
                  {props.addItemMenuTooltip && item.note ? (
                    <Tooltip title={item.note} placement="right" arrow>
                      <div style={{ width: '100%' }}>
                        {item[props.displayValue || 'description']}
                      </div>
                    </Tooltip>
                  ) : (
                    <React.Fragment>
                      {item[props.displayValue || 'description']}{' '}
                    </React.Fragment>
                  )}
                </MenuItem>
              ))
            ) : (
              <MenuItem key="" value=""></MenuItem>
            )}
          </Select>
          {props.error && <FormHelperText>{props.error ? props.msg : ''}</FormHelperText>}
        </FormControl>
      )}
      {(props.tooltip || props.htmlTooltip) && (
        <div className={classes.tooltipWrapper}>
          {props.tooltip ? (
            <Tooltip arrow placement="top" title={props.tooltip}>
              <Info />
            </Tooltip>
          ) : (
            <HtmlTooltip
              placement="top"
              title={<React.Fragment>{props.htmlTooltip}</React.Fragment>}
            >
              <Info />
            </HtmlTooltip>
          )}
        </div>
      )}
    </div>
  );
};
export default SelectSystemCode;
