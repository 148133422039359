import {
  WalletAddressBookServiceClient,
  WalletAddressBook,
  DeleteWalletAddressBookRequest,
  ListWalletAddressBookRequest,
} from '../proto/bankpb/walletaddressbook_grpc_web_pb';
import { auth } from '../lib/auth/Auth';
import authSvc from './AuthService';

const service = new WalletAddressBookServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

const userId = authSvc.getCurrentUser()?.UserId;
export async function createWalletAddressBook(param) {
  return new Promise((resolve, reject) => {
    let req = new WalletAddressBook();
    req.setNickName(param.nickName);
    req.setAccountNo(param.accountNo);
    req.setCorrespondent(param.correspondent);
    req.setWalletId(param.walletID);
    req.setContraWalletId(param.contraWalletID);
    req.setStatus(param.status);

    service.createWalletAddressBook(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateWalletAddressBook(param) {
  return new Promise((resolve, reject) => {
    const req = new WalletAddressBook();
    req.setWalletAddressBookId(param.walletAddressBookId);
    req.setAccountId(param.accountId);
    req.setNickName(param.nickName);
    req.setAccountNo(param.accountNo);
    req.setCorrespondent(param.correspondent);
    req.setWalletId(param.walletId);
    req.setContraWalletId(param.contraWalletId);
    req.setStatus(param.status);
    req.setRecurrenceAmount(param.recurrenceAmount);

    service.updateWalletAddressBook(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function deleteWalletAddressBook(id) {
  return new Promise((resolve, reject) => {
    const req = new DeleteWalletAddressBookRequest();
    req.setWalletAddressBookId(id);

    service.deleteWalletAddressBook(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listWalletAddressBook(param) {
  return new Promise((resolve, reject) => {
    const req = new ListWalletAddressBookRequest();
    req.setCorrespondent(param.correspondent);
    req.setAccountNo(param.accountNo);
    req.setWalletId(param.walletID);
    req.setContraWalletId(param.contraWalletID);
    req.setStatus(param.status);
    req.setNickName(param.nickName);
    req.setUsrId(userId);

    service.listWalletAddressBook(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
