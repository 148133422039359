import React from 'react';
import { Button as MUIButton, CircularProgress } from '@material-ui/core';
import {ReactComponent as Compose} from '../../assets/icons/pen.svg';
import {ReactComponent as Paperclip} from '../../assets/icons/paperclip.svg';
import {ReactComponent as Camera} from '../../assets/icons/camera.svg';
import {ReactComponent as Search} from '../../assets/icons/search.svg';
import {ReactComponent as Plus} from '../../assets/icons/plus.svg';
import {ReactComponent as Failed} from '../../assets/icons/x-out.svg';
import Text from '../../components/Typography/Text'
import {
  Loop
} from '@material-ui/icons';



const Button = (props) => {
    const StartIcon = (button) => {
        if (button.type === 'message') {
            return <Compose/>
        } else if (button.type === 'search') {
            return (props.loading ? <CircularProgress/> : <Search/>);
        } else if (button.type === 'add') {
            return <Camera/>
        } else if (button.type === 'edit') {
            return <Compose/>
        } else if (button.type === 'attachment') {
            return <Paperclip/>
        } else if (button.type === 'photo') {
            return <Camera/>
        } else if (button.type === 'recurrence') {
            return <Loop/>
        } else if (button.type === 'plus') {
            return <Plus/>
        } else if (button.type === 'failed') {
            return <Failed/>
        } else {
            return <CircularProgress/>
        }
    } 

    return (
        <MUIButton
            style={{marginTop: props.mt ? 20 : 0, textAlign:'left',justifyContent:'left'}}
            className={props.className}
            fullWidth={true}
            disabled={props.disabled}
            component={props.component}
            name={props.name}
            onClick={props.onClick}
            variant="outlined"
            startIcon={props.loading || props.type ? <StartIcon type={props.type} /> : null || ''}
            color={props.color}
            
            //type Color = 'inherit' | 'primary' | 'secondary' | 'default';
            // endIcon={}
        >
            <Text mt={8} mr={8} variant="h1" label={props.count} style={{justifyContent:'left', textAlign:'left'}}
            className={props.className} />
            {props.label}
            
        </MUIButton>
    );
}
export default Button;