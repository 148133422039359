import React from 'react'
import { IconButton } from '@material-ui/core'
import { 
    Create as EditIcon,
    Visibility as ViewIcon,
} from '@material-ui/icons'
import ClientGrid from '../../../components/ClientGrid/ClientGrid'
import ClientGrid2 from '../../../components/ClientGrid/ClientGrid2'
import Table, { columnType } from '../../../components/ClearingTable/Table';
import Button from '../../../components/Buttons/Button'
import AutoCompleteAccountNo from '../../../components/AutoComplete/AutoCompleteAccountNo'
import AutoCompleteAccountName from '../../../components/AutoComplete/AutoCompleteAccountName'
import SelectSystemCode from '../../../components/Dropdown/SelectSystemCode'
import Text from '../../../components/Typography/Text'
import TextField from '../../../components/Textfields/TextField'
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from '../../../components/Messages/Notification'
import { entitlementColumns } from '../../../lib/data/columns'
import {
  listEntitlement,
  updateEntitlement,
} from '../../../services/EntitlementService';
import QueryParam from '../../../services/QueryParamService'
import AutoCompleteSystemCode from '../../../components/AutoComplete/AutoCompleteSystemCode'
import SelectCusip from '../../../components/AutoComplete/SelectCusip'
import EditEntitlementModal from './Components/EditEntitlementModal'
import ViewEntitlementModal from './Components/ViewEntitlementModal'
import AutoCompleteSymbol from '../../../components/AutoComplete/AutoCompleteSymbol'
import { getSystemDate } from '../../../services/ProfileService'
import {
  CustomBodyRenderCurrency,
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderDate,
  CustomBodyRenderDateTime,
  CustomBodyRenderBoolean,
  CustomBodyRenderAlign,
  CustomBodyRenderHtmlString,
  CustomBodyRenderCenter,
  CustomBodyRenderQty,
} from '../../../components/Table/CustomBodyRender';
import { formatPbDate, formatPbDateTime } from '../../../lib/fmt';

export default function Entitlement() {
  const [rows, setRows] = React.useState([])
  const [rowData, setRowData] = React.useState({})
  const [open, setOpen] = React.useState({
    edit: false,
    view: false
  })
  const [loading, setLoading] = React.useState({
    search: false,
    save: false,
  })
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
        textNumber: '',
        entryType: '',
        accountNo: '',
        accountName: '',
        accountType: '',
        cusip: '',
        symbol: '',
        fromSystemDate: '',
        toSystemDate: '',
        status: 'Pending',
        dateType: 'Process Date',
    }),
  )

  const GridButtons = ({
    tableManager,
    value,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return (
      <div className='grd-row'>
        <div className='grd-cell nm'>
          <IconButton aria-label="edit" onClick={() => handleOpen(data)}>
            <EditIcon style={{ height: 24, width: 24 }} />
          </IconButton>
        </div>
        <div className='grd-cell nm'>
          <IconButton aria-label="view" onClick={() => handleView(data)}>
            <ViewIcon style={{ height: 24, width: 24 }} />
          </IconButton>
        </div>
      </div>
    )
  }

  const columns2 = [
    {
      id: '',
      label: '',
      width: '60px',
      cellRenderer: GridButtons,
    },
    ...entitlementColumns,
  ]

  const columns = [
    {
      name: 'actions',
      label: 'Actions',
      sort: false,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return(
            <div className='grd-row'>
              <div className='grd-cell nm'>
                <IconButton aria-label="edit" onClick={() => handleOpen(rows[dataIndex])}>
                  <EditIcon style={{ height: 24, width: 24 }} />
                </IconButton>
              </div>
              <div className='grd-cell nm'>
                <IconButton aria-label="view" onClick={() => handleView(rows[dataIndex])}>
                  <ViewIcon style={{ height: 24, width: 24 }} />
                </IconButton>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'entitlementId',
      label: 'Entitlement ID',
      options: {
        display: false,
      }
    },
    {
      // Already converted to string in handlesearch
      name: 'processDate',
      label: 'Process Date',
    },
    {
      // Already converted to string in handlesearch
      name: 'payableDate',
      label: 'Payable Date',
    },
    {
      name: 'declarationDate',
      label: 'Declaration Date',
      options: {
        customBodyRender: CustomBodyRenderDate,
      }
    },
    {
      // Already converted to string in handlesearch
      name: 'recordDate',
      label: 'Record Date',
    },
    {
      name: 'expirationDate',
      label: 'Expiration Date',
      options: {
        customBodyRender: CustomBodyRenderDate,
      }
    },
    {
      // Already converted to string in handlesearch
      name: 'positionDate',
      label: 'Position Date',
    },
  
    {
      // Already converted to string in handlesearch
      name: 'systemDate',
      label: 'System Date',
    },
  
    {
      name: 'textNumber',
      label: 'Text Number',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'symbol',
      label: 'Symbol',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'entryType',
      label: 'Entry Type',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'accountNo',
      label: 'Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'accountName',
      label: 'Account Name',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      }
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'originalCusip',
      label: 'Original Cusip',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      }
    },
    {
      name: 'cusip',
      label: 'Cusip',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      }
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'qtyRate',
      label: 'Qty Rate',
      options: {
        customBodyRender: CustomBodyRenderQty,
        visible: false,
      },
      addFooter: true,
      type: 'qty',
    },
    {
      name: 'cashRate',
      label: 'Cash Rate',
      options: {
        customBodyRender: CustomBodyRenderQty,
        visible: false,
      },
      addFooter: true,
      type: 'qty',
    },
    {
      name: 'dtccCashRate',
      label: 'DTCC Cash Rate',
      options: {
        customBodyRender: CustomBodyRenderQty,
        visible: false,
      },
      addFooter: true,
      type: 'qty',
    },
    {
      name: 'cash',
      label: 'Cash',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'qty',
      label: 'Qty',
      options: {
        customBodyRender: CustomBodyRenderQty,
      },
      addFooter: true,
      type: 'qty',
    },
    {
      name: 'fractionalCash',
      label: 'Fractional Cash',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
        visible: false,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'fractionalQty',
      label: 'Fractional Qty',
      options: {
        customBodyRender: CustomBodyRenderQty,
        visible: false,
      },
      addFooter: true,
      type: 'qty',
    },
    {
      name: 'taxRate',
      label: 'Tax Rate',
      options: {
        customBodyRender: CustomBodyRenderQty,
        visible: false,
      },
      addFooter: true,
      type: 'qty',
    },
    {
      name: 'taxCountry',
      label: 'Tax Country',
      options: {
        visible: false,
      }
    },
    {
      name: 'noCil',
      label: 'No CIL',
      options: {
        visible: false,
      }
      // options: {
      //   customBodyRenderLite: (dataIndex) => {
      //     const f = rows[dataIndex].noCil;
      //     return (
      //       <div align="center">{f === true ? <CheckCircleIcon /> : null}</div>
      //     );
      //   },
      // },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        customBodyRender: CustomBodyRenderHtmlString,
        visible: false,
      }
    },
    {
      name: 'voluntary',
      label: 'Voluntary',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        visible: false,
      }
    },
    {
      name: 'voluntaryAction',
      label: 'Voluntary Action',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        visible: false,
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        visible: false,
      }
    },
    {
      name: 'detail',
      label: 'Detail',
      options: {
        customBodyRender: CustomBodyRenderHtmlString,
        visible: false,
      }
    },
    {
      name: 'executionId',
      label: 'Execution ID',
      options: {
        visible: false,
      }
    },
  ];

  const options = {
    responsive: 'standard',
  };

  const init = async () => {
    const searchParameters = localStorage.getItem('search_data')

    const {

      textNumber,
      entryType,
      accountNo,
      accountName,
      accountType,
      cusip,
      symbol,
      fromSystemDate,
      toSystemDate,
      status,
      dateType,

    } = searchParameters ? JSON.parse(searchParameters) : searchData

    const systemDate = await getSystemDate()

    setSearchData({
      ...searchData,
      
      textNumber: textNumber ? textNumber: '',
      entryType: entryType ? entryType: '',
      accountNo: accountNo ? accountNo: '',
      accountName: accountName ? accountName: '',
      accountType: accountType ? accountType: '',
      cusip: cusip ? cusip: '',
      symbol: symbol ? symbol: '',
      fromSystemDate: fromSystemDate ? fromSystemDate: systemDate,
      toSystemDate: toSystemDate ? toSystemDate: systemDate,
      status: status ? status: '',
      dateType: dateType ? dateType: '',

    })
  }

  React.useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  const handleSearchDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target

    setSearchData({ ...searchData, [input.name]: input.value })
  }

  const handleSearch = async () => {
    try {
      setLoading({ ...loading, search: true })

      QueryParam.set(searchData)

      const data = await listEntitlement(searchData)

      let rowsCopy = data.entitlementsList;

      const rowsCopyOutput = rowsCopy.map((data) => {
        let tempDate = formatPbDate(data.payableDate);
        data.payableDate = tempDate

        let tempDate2 = formatPbDate(data.positionDate);
        data.positionDate = tempDate2

        let tempDate3 = formatPbDate(data.processDate);
        data.processDate = tempDate3

        let tempDate4 = formatPbDate(data.recordDate);
        data.recordDate = tempDate4

        let tempDate5 = formatPbDate(data.systemDate);
        data.systemDate = tempDate5

        return data;
      })

      setRows(rowsCopyOutput)

      notifyInfo(
        data.entitlementsList.length > 0
          ? data.entitlementsList.length + ' search result(s)'
          : 'No records found.',
      )
    } catch (error) {
      notifyError(error.message)
      console.log(error)
      return
    } finally {
      setLoading({ ...loading, search: false })
    }
  }

  const handleView = async (data) => {
    setRowData(data)
    setOpen({
        ...open,
        view: true
    })
  };

  const handleOpen = async (data) => {
    setRowData(data)
    setOpen({
        ...open,
        edit: true
    })
  };

  const handleClose = async (data) => {
    if (!data) {
      setOpen({
          ...open,
          edit: false,
          view: false
      });
      return;
    }

    try {
      const resp = await updateEntitlement(data);
      handleSearch(false);
      notifySuccess(' Entitlement has been updated.');
    } catch (error) {
      console.error(error);
      notifyError(error.message);
    }

    setOpen({
        ...open,
        edit: false
    });
  };

  const getCsvData = async () => {
    const data = await listEntitlement(searchData)

    return data.entitlementsList
  }

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Entitlement" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading.search}
              loading={loading.search}
              type="search"
              label={loading.search ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <SelectSystemCode
              name="dateType"
              label="Date Type"
              placeholder="Blank"
              type="Date Type Announcement"
              value={searchData.dateType}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <TextField
              name="fromSystemDate"
              label="From System Date"
              type="date"
              value={searchData.fromSystemDate}
              onChange={handleSearchDataChange}
              inputProps={{
                max: searchData.toSystemDate,
              }}
            />
          </div>
          <div className="grd-cell">
            <TextField
              name="toSystemDate"
              label="To System Date"
              type="date"
              value={searchData.toSystemDate}
              onChange={handleSearchDataChange}
              inputProps={{
                min: searchData.fromSystemDate,
              }}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteSystemCode
                name="entryType"
                label="Entry Type"
                value={searchData.entryType}
                type={'Entry Type'}
                subType={'ReOrg'}
              onChange={handleSearchDataChange}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <AutoCompleteAccountNo
                  freeSolo={true}
              isAccessibleOnly={true}
              name="accountNo"
              type="client"
              value={searchData.accountNo}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteAccountName
                  freeSolo={true}
              isAccessibleOnly={true}
              name="accountName"
              value={searchData.accountName}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <SelectSystemCode
              name="type"
              label="Type"
              type="Sub Account Type"
              value={searchData.type}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteSymbol
                name="symbol"
                label="Symbol"
                value={searchData.symbol}
              onChange={handleSearchDataChange}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <SelectCusip
                name="cusip"
                label="Cusip"
                value={searchData.cusip}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <TextField
              name="textNumber"
              label="Text Number"
              value={searchData.textNumber}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <SelectSystemCode
              name="status"
              label="Status"
              placeholder="Blank"
              type="Date Type Entitlement"
              value={searchData.status}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell"> </div>
        </div>
      </div>
      <div className="mt-20">
        {/* <ClientGrid
          title="Entitlement"
          rowIdField="entitlementId"
          columns={columns2}
          rows={rows}
          getCsvData={getCsvData}
          isLoading={loading.search}
          setRows={setRows}
        /> */}
        {/* <ClientGrid2
          title="Entitlement"
          data={rows}
          columns={columns}
          options={options}
        /> */}
        <Table
          title={'Entitlement'}
          data={rows}
          columns={columns}
          options={options}
        />
      </div>
      {open.edit && <EditEntitlementModal
        data={rowData}
        open={open.edit}
        close={handleClose}
        loading={loading.save}>
      </EditEntitlementModal>}
      {open.view && <ViewEntitlementModal
        data={rowData}
        open={open.view}
        close={handleClose}
        loading={loading.save}>
      </ViewEntitlementModal>}
    </div>
  )
}
