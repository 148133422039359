import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Paper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { lazyLoadSystemCode } from '../../services/CommonService';
import color from '../../assets/css/colors';
import fontFamily from '../../assets/css/fonts';

const useStyles = makeStyles(() => ({
    autocompleteWrapper: {
        '& .MuiAutocomplete-inputRoot': {
            padding: '7px 15px 8px 15px !important',
            height: 48,
        },
        '& .MuiAutocomplete-option': {
            backgroundColor: 'red',
        },
        '& .MuiAutocomplete-endAdornment': {
            top: 'calc(50% - 15px)',
            '& svg': {
                width: '22px !important',
                height: '22px !important',
            }
        }
    },
    paper: {
        fontFamily: fontFamily.label,
        fontSize: 15,
        fontWeight: 600,
        backgroundColor: color.light.main,
    }
}));

export default function AutoCompleteState(props) {
    const classes = useStyles();

    const [options, setOptions] = React.useState([]);

    //get list from backend
    const getOptions = async (value) => {
        const { systemCodeList } = await lazyLoadSystemCode('State', '', value);
        setOptions(systemCodeList.filter((s) => s.code));
    };

    React.useEffect(() => {
        getOptions(props.value);
    }, [props.value]);

    return (
        <Autocomplete
            style={{marginTop: props.mt ? props.mt : 0, marginBottom: props.mb ? props.mb : 0}}
            className={classes.autocompleteWrapper}
            disabled={props.disabled}
            freeSolo={props.freeSolo}
            id={props.name}
            blurOnSelect={false}
            getOptionSelected={(option, value) => option.code !== props.value ? option : {}}
            getOptionLabel={(option) => option?.code ? (option.code + ' - ' + option.description) : ''}
            options={options}
            name={props.name}
            autoHighlight={true}
            clearOnEscape={true}
            value={options.find((o) => o.code === props.value) || {}}
            onChange={(_, obj) => props.onChange({currentTarget: { name: props.name, value: obj ? obj.code : '' }})}
            PaperComponent={({ children }) => (<Paper className={classes.paper}>{children}</Paper>)}
            renderInput={(params) => (
                <TextField
                {...params}
                error={props.error}
                helperText={props.error ? props.msg : ''}
                name={props.name}
                required={props.required}
                disabled={props.disabled}
                label={props.label}
                placeholder={props.placeholder}
                InputLabelProps={{ shrink: true }}
                />
            )}
        />
    );
}
