import {
  ReadDisclosureRequest,
  DisclosureServiceClient,
  FetchDisclosureRequest,
  ReadDisclosureByIdRequest,
  SaveClientDisclosureRequest,
} from '../proto/reportpb/disclosure_grpc_web_pb';
import { auth } from '../lib/auth/Auth';
import { getLogo, getCorrespondentByUrl } from './TemplateService';
import { readClient } from './ClientAccountService';
import authSvc from './AuthService';
import { notifyError } from '../components/Messages/Notification';

import { DisclosureServiceClient as DynamicDisclosureServiceClient, DownloadBlankReportRequest } from "../proto/admpb/disclosure_grpc_web_pb"
import download from "./DownloadService"

const service = new DisclosureServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });
const dynamicDisclosureService = new DynamicDisclosureServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth })
export async function readDisclosure(reportType, correspondent) {
  return new Promise((resolve, reject) => {
    let req = new ReadDisclosureRequest();
    req.setReportType(reportType);
    req.setCorrespondent(correspondent);

    service.readDisclosure(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function fetchDisclosures(userId, correspondent) {
  return new Promise((resolve, reject) => {
    let req = new FetchDisclosureRequest();
    req.setCorrespondent(correspondent);
    req.setUserId(userId);

    service.fetchDisclosures(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function readDisclosureById(disclosureId) {
  return new Promise((resolve, reject) => {
    let req = new ReadDisclosureByIdRequest();
    req.setDisclosureId(disclosureId);

    service.readDisclosureById(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function saveClientDisclosure(userId,disclosures) {
  return new Promise((resolve, reject) => {
    let req = new SaveClientDisclosureRequest();
    req.setDisclosureIdList(disclosures);
    req.setUserId(userId);

    service.saveClientDisclosure(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function downloadBlankReport(param, disclosure) {
	return new Promise((resolve, reject) => {
		const req = new DownloadBlankReportRequest()
		console.log(param, disclosure)
		req.setCorrespondent(param.correspondent)
		req.setReportType(param.reportType)
		req.setDisclosure(disclosure)

		dynamicDisclosureService.downloadBlankReport(req, {}, (error, response) => {
			if (error) {
				reject(error)
			} else {
				download(response)
				resolve(response.toObject())
			}
		})
	})
}


export const getCorrespondentClientNoticeDisclosure = async (reportType) => {
  let correspondent = '';

  //get correspondent
  try {
    let url = window.location.origin;
    const serverCorrespondent = await getCorrespondentByUrl(url);
    correspondent = serverCorrespondent.correspondent.correspondent;
  } catch (error) {
    console.error(error);
    notifyError(error.message);
    return '';
  }

  // get disclosure client notice
  try {
    const activeUser = authSvc.getCurrentUser();
    const {
      disclosure: { disclosure },
    } = await readDisclosure('Client Notice', correspondent);
    const { client, addressesList, contactInfosList, identificationsList } =
      await readClient(activeUser.AccountId);

    let name = disclosure.replace('[Account Name]', activeUser.Name);
    let email = name.replace(
      'email/telephone',
      contactInfosList[0]?.contactInfo + '/' + contactInfosList[1]?.contactInfo
    );
    let email2 = email.replace('[Primary Email]', '');
    let address = email2.replace(
      '[Full Address]',
      addressesList[0]?.address1 +
        ' ' +
        addressesList[0]?.address2 +
        ' ' +
        addressesList[0]?.city +
        ',' +
        addressesList[0]?.state,
      ',',
      addressesList[0]?.zipCode,
      ' ',
      addressesList[0]?.country
    );
    let accountNo = address.replace('[Account No]', client.accountNo);
    let id = accountNo.replace('[Identity No]', identificationsList[1]?.id);
    let idType = id.replace('[Identity Type]', identificationsList[1]?.type);

    return idType;
  } catch (error) {
    console.error('client notice error: ', error);
  }

  return '';
};
