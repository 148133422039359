import React from 'react'
import ClientGrid from '../../../components/ClientGrid/ClientGrid'
import ClientGrid2 from '../../../components/ClientGrid/ClientGrid2'
import Table, { columnType } from '../../../components/ClearingTable/Table';
import Button from '../../../components/Buttons/Button'
import DateTimePicker from '../../../components/Textfields/DateTimePicker'
import AutoCompleteCorrespondent from '../../../components/AutoComplete/AutoCompleteCorrespondent'
import AutoCompleteAccountName from '../../../components/AutoComplete/AutoCompleteAccountName'
import AutoCompleteMasterAccountNo from '../../../components/AutoComplete/AutoCompleteMasterAccountNo'
import { Checkbox as MUICheckbox, FormControlLabel } from '@material-ui/core'
import Text from '../../../components/Typography/Text'
import {
  notifyInfo,
  notifyError,
} from '../../../components/Messages/Notification'
import { requirementColumns } from '../../../lib/data/columns'
import { listRequirement } from '../../../services/MarginRequirementService'
import { getPreviousDate } from '../../../services/ProfileService'
import QueryParam from '../../../services/QueryParamService'
import {
  CustomBodyRenderCurrency,
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderDate,
  CustomBodyRenderDateTime,
  CustomBodyRenderBoolean,
  CustomBodyRenderAlign,
  CustomBodyRenderCenter,
  CustomBodyRenderQty,
  CustomBodyRenderHtmlString,
  CustomBodyRenderPercentage
} from '../../../components/Table/CustomBodyRender';

export default function MarginRequirement() {
  const [rows, setRows] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      tradeDate: '',
      correspondent: '',
      masterAccountNo: '',
      accountName: '',
      exchangeCall: false,
      fedCall: false,
      houseCall: false,
      buyingPowerCall: false,
      minBalanceCall: false,
    }),
  )

  const init = async () => {
    const searchParameters = localStorage.getItem('search_data')

    const {
      tradeDate,
      correspondent,
      masterAccountNo,
      accountName,
    } = searchParameters ? JSON.parse(searchParameters) : searchData

    const systemDate = await getPreviousDate()

    setSearchData({
      ...searchData,
      tradeDate: tradeDate ? tradeDate : systemDate,
      correspondent: correspondent ? correspondent : '',
      masterAccountNo: masterAccountNo ? masterAccountNo : '',
      accountName: accountName ? accountName : '',
    })
  }

  React.useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  const columns = [
    {
      name: 'tradeDate',
      label: 'Trade Date',
      options: {
        customBodyRender: CustomBodyRenderDate,
      }
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      name: 'accountName',
      label: 'Account Name',
    },
    {
      name: 'equity',
      label: 'Equity',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'exchangeReq',
      label: 'Exchange Req',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'exchangeCall',
      label: 'Exchange Call',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'fedReq',
      label: 'Fed Req',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'fedCall',
      label: 'Fed Call',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'houseReq',
      label: 'House Req',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'houseCall',
      label: 'House Call',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'buyingPowerCall',
      label: 'Buying Power Call',
      type: 'amount',
    },
    {
      name: 'minBalanceCall',
      label: 'Min Balance Call',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
  ];

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
  };

  const handleSearchDataChange = (e, checkboxValue) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target

    setSearchData({
      ...searchData,
      [input.name]: checkboxValue !== undefined ? checkboxValue : input.value,
    })
  }

  const handleSearch = async () => {
    try {
      setLoading(true)

      QueryParam.set(searchData)

      localStorage.setItem('search_data', JSON.stringify(searchData))

      const data = await listRequirement(searchData)

      setRows(data.requirementsList)

      notifyInfo(
        data.requirementsList.length > 0
          ? data.requirementsList.length + ' search result(s)'
          : 'No records found.',
      )
    } catch (error) {
      setLoading(false)
      notifyError(error.message)
      console.log(error)
      return
    } finally {
      setLoading(false)
    }
  }

  const getCsvData = async () => {
    const data = await listRequirement(searchData)

    return data.requirementsList
  }

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Margin Requirement" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading}
              loading={loading}
              type="search"
              label={loading ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell-none">
            <DateTimePicker
              type="date"
              name="tradeDate"
              label="Trade Date"
              value={searchData.tradeDate}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteCorrespondent
              freeSolo={true}
              isAccessibleOnly={true}
              name="correspondent"
              value={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteMasterAccountNo
              isAccessibleOnly={true}
              freeSolo={true}
              name="masterAccountNo"
              value={searchData.masterAccountNo}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteAccountName
              freeSolo={true}
              isAccessibleOnly={true}
              name="accountName"
              value={searchData.accountName}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
        </div>
        <div className="grd-row nm">
          <div className="grd-cell">
            <FormControlLabel
              control={<MUICheckbox color="primary" />}
              name="exchangeCall"
              checked={searchData.exchangeCall}
              onChange={handleSearchDataChange}
              label="Exchange Call"
            />
          </div>
          <div className="grd-cell">
            <FormControlLabel
              control={<MUICheckbox color="primary" />}
              name="fedCall"
              label="Fed Call"
              checked={searchData.fedCall}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <FormControlLabel
              control={<MUICheckbox color="primary" />}
              name="houseCall"
              label="House Call"
              checked={searchData.houseCall}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <FormControlLabel
              control={<MUICheckbox color="primary" />}
              name="buyingPowerCall"
              label="Buying Power Call"
              checked={searchData.buyingPowerCall}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <FormControlLabel
              control={<MUICheckbox color="primary" />}
              name="minBalanceCall"
              label="Min Balance Call"
              checked={searchData.minBalanceCall}
              onChange={handleSearchDataChange}
            />
          </div>
        </div>
      </div>
      <div className="mt-20">
        {/* <ClientGrid
          title="Margin Requirement"
          rowIdField="trnsId"
          columns={requirementColumns}
          rows={rows}
          getCsvData={getCsvData}
          isLoading={loading}
          setRows={setRows}
        /> */}
        {/* <ClientGrid2
          title="Margin Requirement"
          data={rows}
          columns={columns}
          options={options}
        /> */}
        <Table
          title={'Margin Requirement'}
          data={rows}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  )
}
