const validationMessage = `You have not provided valid data in some fields. 
    If you want to skip this step for now, you can use the navigation bar on top to select 
    which form to complete next. Please remember that you can only submit your application once 
    all forms are validated.`;

const submittionWarningMessage = `Please remember that you can only submit your application
    once you completed all the necessary steps required. Thank you!`;

const associationMessage = `As an employee of a member firm, you must notify your firm of 
    this account and provide written consent from your firm indicating you are able to open and 
    maintain this account. Additionally, Duplicate statements will need to be provided to your employer.`;

const accountTypeCorporateInformMessage = `Employment form are for Individual and Joint account type only. This is
    not applicable for account type Corporation. You can skip this step.`;

export { validationMessage, submittionWarningMessage, associationMessage, accountTypeCorporateInformMessage };