// source: proto/reportpb/position.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_utilspb_pagination_pb = require('../../proto/utilspb/pagination_pb.js');
goog.object.extend(proto, proto_utilspb_pagination_pb);
var google_type_date_pb = require('../../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.reportpb.ListPositionRequest', null, global);
goog.exportSymbol('proto.reportpb.ListPositionResponse', null, global);
goog.exportSymbol('proto.reportpb.ListPositionSummary', null, global);
goog.exportSymbol('proto.reportpb.Position', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.Position = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.Position, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.Position.displayName = 'proto.reportpb.Position';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListPositionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ListPositionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListPositionRequest.displayName = 'proto.reportpb.ListPositionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListPositionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListPositionResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListPositionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListPositionResponse.displayName = 'proto.reportpb.ListPositionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListPositionSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ListPositionSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListPositionSummary.displayName = 'proto.reportpb.ListPositionSummary';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.Position.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.Position.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.Position} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.Position.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    branch: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    rep: jspb.Message.getFieldWithDefault(msg, 7, ""),
    subAccountNo: jspb.Message.getFieldWithDefault(msg, 8, ""),
    broker: jspb.Message.getFieldWithDefault(msg, 9, ""),
    marginType: jspb.Message.getFieldWithDefault(msg, 10, ""),
    type: jspb.Message.getFieldWithDefault(msg, 11, ""),
    originalCusip: jspb.Message.getFieldWithDefault(msg, 12, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 13, ""),
    cusip: jspb.Message.getFieldWithDefault(msg, 14, ""),
    symbolDescription: jspb.Message.getFieldWithDefault(msg, 15, ""),
    assetType: jspb.Message.getFieldWithDefault(msg, 16, ""),
    closingPrice: jspb.Message.getFieldWithDefault(msg, 17, ""),
    qty: jspb.Message.getFieldWithDefault(msg, 18, ""),
    marketValue: jspb.Message.getFieldWithDefault(msg, 19, ""),
    costBasis: jspb.Message.getFieldWithDefault(msg, 20, ""),
    dateType: jspb.Message.getFieldWithDefault(msg, 21, ""),
    date: (f = msg.getDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    accountStatus: jspb.Message.getFieldWithDefault(msg, 23, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.Position}
 */
proto.reportpb.Position.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.Position;
  return proto.reportpb.Position.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.Position} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.Position}
 */
proto.reportpb.Position.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranch(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRep(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubAccountNo(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarginType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalCusip(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolDescription(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetType(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setClosingPrice(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketValue(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setCostBasis(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateType(value);
      break;
    case 22:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.Position.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.Position.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.Position} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.Position.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRep();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSubAccountNo();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getMarginType();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getSymbolDescription();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getAssetType();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getClosingPrice();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getMarketValue();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getCostBasis();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getDateType();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getAccountStatus();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
};


/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.reportpb.Position.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.reportpb.Position.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string branch = 3;
 * @return {string}
 */
proto.reportpb.Position.prototype.getBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_no = 4;
 * @return {string}
 */
proto.reportpb.Position.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_name = 5;
 * @return {string}
 */
proto.reportpb.Position.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string master_account_no = 6;
 * @return {string}
 */
proto.reportpb.Position.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string rep = 7;
 * @return {string}
 */
proto.reportpb.Position.prototype.getRep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setRep = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string sub_account_no = 8;
 * @return {string}
 */
proto.reportpb.Position.prototype.getSubAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setSubAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string broker = 9;
 * @return {string}
 */
proto.reportpb.Position.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string margin_type = 10;
 * @return {string}
 */
proto.reportpb.Position.prototype.getMarginType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setMarginType = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string type = 11;
 * @return {string}
 */
proto.reportpb.Position.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string original_cusip = 12;
 * @return {string}
 */
proto.reportpb.Position.prototype.getOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string symbol = 13;
 * @return {string}
 */
proto.reportpb.Position.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string cusip = 14;
 * @return {string}
 */
proto.reportpb.Position.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string symbol_description = 15;
 * @return {string}
 */
proto.reportpb.Position.prototype.getSymbolDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setSymbolDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string asset_type = 16;
 * @return {string}
 */
proto.reportpb.Position.prototype.getAssetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setAssetType = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string closing_price = 17;
 * @return {string}
 */
proto.reportpb.Position.prototype.getClosingPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setClosingPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string qty = 18;
 * @return {string}
 */
proto.reportpb.Position.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string market_value = 19;
 * @return {string}
 */
proto.reportpb.Position.prototype.getMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string cost_basis = 20;
 * @return {string}
 */
proto.reportpb.Position.prototype.getCostBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setCostBasis = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string date_type = 21;
 * @return {string}
 */
proto.reportpb.Position.prototype.getDateType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setDateType = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional google.type.Date date = 22;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.Position.prototype.getDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 22));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.Position} returns this
*/
proto.reportpb.Position.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.Position.prototype.hasDate = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional string account_status = 23;
 * @return {string}
 */
proto.reportpb.Position.prototype.getAccountStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Position} returns this
 */
proto.reportpb.Position.prototype.setAccountStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListPositionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListPositionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListPositionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListPositionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    unpricedSecurity: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    broker: jspb.Message.getFieldWithDefault(msg, 6, ""),
    type: jspb.Message.getFieldWithDefault(msg, 7, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 8, ""),
    cusip: jspb.Message.getFieldWithDefault(msg, 9, ""),
    fromDate: (f = msg.getFromDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    toDate: (f = msg.getToDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    branch: jspb.Message.getFieldWithDefault(msg, 12, ""),
    rep: jspb.Message.getFieldWithDefault(msg, 13, ""),
    usrId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    pagination: (f = msg.getPagination()) && proto_utilspb_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListPositionRequest}
 */
proto.reportpb.ListPositionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListPositionRequest;
  return proto.reportpb.ListPositionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListPositionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListPositionRequest}
 */
proto.reportpb.ListPositionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnpricedSecurity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 10:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromDate(value);
      break;
    case 11:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToDate(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranch(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setRep(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUsrId(value);
      break;
    case 15:
      var value = new proto_utilspb_pagination_pb.Pagination;
      reader.readMessage(value,proto_utilspb_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListPositionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListPositionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListPositionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListPositionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUnpricedSecurity();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getFromDate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToDate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getRep();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getUsrId();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto_utilspb_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.reportpb.ListPositionRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.reportpb.ListPositionRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_name = 3;
 * @return {string}
 */
proto.reportpb.ListPositionRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.reportpb.ListPositionRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool unpriced_security = 5;
 * @return {boolean}
 */
proto.reportpb.ListPositionRequest.prototype.getUnpricedSecurity = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setUnpricedSecurity = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string broker = 6;
 * @return {string}
 */
proto.reportpb.ListPositionRequest.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.reportpb.ListPositionRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string symbol = 8;
 * @return {string}
 */
proto.reportpb.ListPositionRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string cusip = 9;
 * @return {string}
 */
proto.reportpb.ListPositionRequest.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional google.type.Date from_date = 10;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.ListPositionRequest.prototype.getFromDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 10));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
*/
proto.reportpb.ListPositionRequest.prototype.setFromDate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.clearFromDate = function() {
  return this.setFromDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListPositionRequest.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.type.Date to_date = 11;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.ListPositionRequest.prototype.getToDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 11));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
*/
proto.reportpb.ListPositionRequest.prototype.setToDate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.clearToDate = function() {
  return this.setToDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListPositionRequest.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string branch = 12;
 * @return {string}
 */
proto.reportpb.ListPositionRequest.prototype.getBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string rep = 13;
 * @return {string}
 */
proto.reportpb.ListPositionRequest.prototype.getRep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setRep = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional uint32 usr_id = 14;
 * @return {number}
 */
proto.reportpb.ListPositionRequest.prototype.getUsrId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.setUsrId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional utilspb.Pagination pagination = 15;
 * @return {?proto.utilspb.Pagination}
 */
proto.reportpb.ListPositionRequest.prototype.getPagination = function() {
  return /** @type{?proto.utilspb.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_utilspb_pagination_pb.Pagination, 15));
};


/**
 * @param {?proto.utilspb.Pagination|undefined} value
 * @return {!proto.reportpb.ListPositionRequest} returns this
*/
proto.reportpb.ListPositionRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListPositionRequest} returns this
 */
proto.reportpb.ListPositionRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListPositionRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 15) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListPositionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListPositionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListPositionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListPositionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListPositionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    positionsList: jspb.Message.toObjectList(msg.getPositionsList(),
    proto.reportpb.Position.toObject, includeInstance),
    summary: (f = msg.getSummary()) && proto.reportpb.ListPositionSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListPositionResponse}
 */
proto.reportpb.ListPositionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListPositionResponse;
  return proto.reportpb.ListPositionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListPositionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListPositionResponse}
 */
proto.reportpb.ListPositionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.Position;
      reader.readMessage(value,proto.reportpb.Position.deserializeBinaryFromReader);
      msg.addPositions(value);
      break;
    case 2:
      var value = new proto.reportpb.ListPositionSummary;
      reader.readMessage(value,proto.reportpb.ListPositionSummary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListPositionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListPositionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListPositionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListPositionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.Position.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reportpb.ListPositionSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Position positions = 1;
 * @return {!Array<!proto.reportpb.Position>}
 */
proto.reportpb.ListPositionResponse.prototype.getPositionsList = function() {
  return /** @type{!Array<!proto.reportpb.Position>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.Position, 1));
};


/**
 * @param {!Array<!proto.reportpb.Position>} value
 * @return {!proto.reportpb.ListPositionResponse} returns this
*/
proto.reportpb.ListPositionResponse.prototype.setPositionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.Position=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.Position}
 */
proto.reportpb.ListPositionResponse.prototype.addPositions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.Position, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListPositionResponse} returns this
 */
proto.reportpb.ListPositionResponse.prototype.clearPositionsList = function() {
  return this.setPositionsList([]);
};


/**
 * optional ListPositionSummary summary = 2;
 * @return {?proto.reportpb.ListPositionSummary}
 */
proto.reportpb.ListPositionResponse.prototype.getSummary = function() {
  return /** @type{?proto.reportpb.ListPositionSummary} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.ListPositionSummary, 2));
};


/**
 * @param {?proto.reportpb.ListPositionSummary|undefined} value
 * @return {!proto.reportpb.ListPositionResponse} returns this
*/
proto.reportpb.ListPositionResponse.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListPositionResponse} returns this
 */
proto.reportpb.ListPositionResponse.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListPositionResponse.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListPositionSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListPositionSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListPositionSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListPositionSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalRows: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cashBalance: jspb.Message.getFieldWithDefault(msg, 2, ""),
    longMarketValue: jspb.Message.getFieldWithDefault(msg, 3, ""),
    shortMarketValue: jspb.Message.getFieldWithDefault(msg, 4, ""),
    equity: jspb.Message.getFieldWithDefault(msg, 5, ""),
    adjustedBalance: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListPositionSummary}
 */
proto.reportpb.ListPositionSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListPositionSummary;
  return proto.reportpb.ListPositionSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListPositionSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListPositionSummary}
 */
proto.reportpb.ListPositionSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRows(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCashBalance(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongMarketValue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortMarketValue(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdjustedBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListPositionSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListPositionSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListPositionSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListPositionSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalRows();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCashBalance();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEquity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAdjustedBalance();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional uint32 total_rows = 1;
 * @return {number}
 */
proto.reportpb.ListPositionSummary.prototype.getTotalRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.ListPositionSummary} returns this
 */
proto.reportpb.ListPositionSummary.prototype.setTotalRows = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string cash_balance = 2;
 * @return {string}
 */
proto.reportpb.ListPositionSummary.prototype.getCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionSummary} returns this
 */
proto.reportpb.ListPositionSummary.prototype.setCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string long_market_value = 3;
 * @return {string}
 */
proto.reportpb.ListPositionSummary.prototype.getLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionSummary} returns this
 */
proto.reportpb.ListPositionSummary.prototype.setLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string short_market_value = 4;
 * @return {string}
 */
proto.reportpb.ListPositionSummary.prototype.getShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionSummary} returns this
 */
proto.reportpb.ListPositionSummary.prototype.setShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string equity = 5;
 * @return {string}
 */
proto.reportpb.ListPositionSummary.prototype.getEquity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionSummary} returns this
 */
proto.reportpb.ListPositionSummary.prototype.setEquity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string adjusted_balance = 6;
 * @return {string}
 */
proto.reportpb.ListPositionSummary.prototype.getAdjustedBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListPositionSummary} returns this
 */
proto.reportpb.ListPositionSummary.prototype.setAdjustedBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


goog.object.extend(exports, proto.reportpb);
