const color = {
  primary: {
    main: '#6754ff',
    light: '#6754ff',
    dark: '#4167cc',
    opacity: '#edf2ff',
  },
  secondary: {
    main: '#5741ff',
    light: '#897aff',
    dark: '#4534cc',
    opacity: '#ddd9ff',
  },
  success: {
    main: '#62d05a',
    light: '#98e791',
    dark: '#4b9a45',
    opacity: '#f0fbef',
  },
  info: {
    main: '#518EF8',
    light: '#a8c6fb',
    dark: '#4071c6',
    opacity: '#edf3fe',
  },
  warning: {
    main: '#ff9d42',
    light: '#ffc48d',
    dark: '',
    opacity: '#ffebd9',
  },
  error: {
    main: '#ff425f',
    light: '#ff7a8f',
    dark: '',
    opacity: '#ffeaec',
  },
  default: {
    main: '#3a327f',
    light: '#4d468b',
    dark: '#3d3299',
    opacity: '',
  },
  light: {
    main: '#FFFFFF',
    light: '#e5e6f0',
    dark: '#ececec',
    opacity: '#faf9ff',
  },
  gray: {
    main: '#70737c',
    light: '#c5c7ca',
    dark: '#616369',
    opacity: '#c3c1d8',
  },
  dark: {
    main: '#272B43',
    light: '#767aa3',
    dark: '#23263c',
    opacity: '#E8EAFF',
  },
  transparent: 'rgba(0,0,0,0.0)',
  orange: {
    main: '#f9a64a'
  },
};

export default color;
