// source: proto/accountpb/address.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.accountpb.Address', null, global);
goog.exportSymbol('proto.accountpb.BatchCreateAddressRequest', null, global);
goog.exportSymbol('proto.accountpb.BatchCreateAddressResponse', null, global);
goog.exportSymbol('proto.accountpb.CreateAddressResponse', null, global);
goog.exportSymbol('proto.accountpb.DeleteAddressRequest', null, global);
goog.exportSymbol('proto.accountpb.DeleteOwnerAddressRequest', null, global);
goog.exportSymbol('proto.accountpb.DeleteOwnerAddressResponse', null, global);
goog.exportSymbol('proto.accountpb.ListAddressResponse', null, global);
goog.exportSymbol('proto.accountpb.UpdateAddressResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.Address = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.Address, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.Address.displayName = 'proto.accountpb.Address';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.CreateAddressResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.CreateAddressResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.CreateAddressResponse.displayName = 'proto.accountpb.CreateAddressResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.UpdateAddressResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.UpdateAddressResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.UpdateAddressResponse.displayName = 'proto.accountpb.UpdateAddressResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.DeleteAddressRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.DeleteAddressRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.DeleteAddressRequest.displayName = 'proto.accountpb.DeleteAddressRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.BatchCreateAddressRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.accountpb.BatchCreateAddressRequest.repeatedFields_, null);
};
goog.inherits(proto.accountpb.BatchCreateAddressRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.BatchCreateAddressRequest.displayName = 'proto.accountpb.BatchCreateAddressRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.BatchCreateAddressResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.accountpb.BatchCreateAddressResponse.repeatedFields_, null);
};
goog.inherits(proto.accountpb.BatchCreateAddressResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.BatchCreateAddressResponse.displayName = 'proto.accountpb.BatchCreateAddressResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ListAddressResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.accountpb.ListAddressResponse.repeatedFields_, null);
};
goog.inherits(proto.accountpb.ListAddressResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ListAddressResponse.displayName = 'proto.accountpb.ListAddressResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.DeleteOwnerAddressRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.DeleteOwnerAddressRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.DeleteOwnerAddressRequest.displayName = 'proto.accountpb.DeleteOwnerAddressRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.DeleteOwnerAddressResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.DeleteOwnerAddressResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.DeleteOwnerAddressResponse.displayName = 'proto.accountpb.DeleteOwnerAddressResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.Address.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.Address.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.Address} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.Address.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    addressType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    address1: jspb.Message.getFieldWithDefault(msg, 4, ""),
    address2: jspb.Message.getFieldWithDefault(msg, 5, ""),
    address3: jspb.Message.getFieldWithDefault(msg, 6, ""),
    city: jspb.Message.getFieldWithDefault(msg, 7, ""),
    state: jspb.Message.getFieldWithDefault(msg, 8, ""),
    zip: jspb.Message.getFieldWithDefault(msg, 9, ""),
    country: jspb.Message.getFieldWithDefault(msg, 10, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 11, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 12, ""),
    status: jspb.Message.getFieldWithDefault(msg, 13, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 14, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    modifiedBy: jspb.Message.getFieldWithDefault(msg, 16, ""),
    modifiedAt: (f = msg.getModifiedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    ownerId: jspb.Message.getFieldWithDefault(msg, 18, 0),
    imgProofOfAddress: jspb.Message.getFieldWithDefault(msg, 19, ""),
    address4: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.Address}
 */
proto.accountpb.Address.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.Address;
  return proto.accountpb.Address.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.Address} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.Address}
 */
proto.accountpb.Address.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAddressId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddressType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress1(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress2(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress3(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setZip(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifiedBy(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModifiedAt(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOwnerId(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setImgProofOfAddress(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress4(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.Address.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.Address.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.Address} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.Address.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getAddressType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddress1();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAddress2();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAddress3();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getZip();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModifiedBy();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getModifiedAt();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOwnerId();
  if (f !== 0) {
    writer.writeUint32(
      18,
      f
    );
  }
  f = message.getImgProofOfAddress();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getAddress4();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};


/**
 * optional uint32 address_id = 1;
 * @return {number}
 */
proto.accountpb.Address.prototype.getAddressId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.Address} returns this
 */
proto.accountpb.Address.prototype.setAddressId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 account_id = 2;
 * @return {number}
 */
proto.accountpb.Address.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.Address} returns this
 */
proto.accountpb.Address.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string address_type = 3;
 * @return {string}
 */
proto.accountpb.Address.prototype.getAddressType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Address} returns this
 */
proto.accountpb.Address.prototype.setAddressType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string address1 = 4;
 * @return {string}
 */
proto.accountpb.Address.prototype.getAddress1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Address} returns this
 */
proto.accountpb.Address.prototype.setAddress1 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string address2 = 5;
 * @return {string}
 */
proto.accountpb.Address.prototype.getAddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Address} returns this
 */
proto.accountpb.Address.prototype.setAddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string address3 = 6;
 * @return {string}
 */
proto.accountpb.Address.prototype.getAddress3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Address} returns this
 */
proto.accountpb.Address.prototype.setAddress3 = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string city = 7;
 * @return {string}
 */
proto.accountpb.Address.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Address} returns this
 */
proto.accountpb.Address.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string state = 8;
 * @return {string}
 */
proto.accountpb.Address.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Address} returns this
 */
proto.accountpb.Address.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string zip = 9;
 * @return {string}
 */
proto.accountpb.Address.prototype.getZip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Address} returns this
 */
proto.accountpb.Address.prototype.setZip = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string country = 10;
 * @return {string}
 */
proto.accountpb.Address.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Address} returns this
 */
proto.accountpb.Address.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string correspondent = 11;
 * @return {string}
 */
proto.accountpb.Address.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Address} returns this
 */
proto.accountpb.Address.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string account_no = 12;
 * @return {string}
 */
proto.accountpb.Address.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Address} returns this
 */
proto.accountpb.Address.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string status = 13;
 * @return {string}
 */
proto.accountpb.Address.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Address} returns this
 */
proto.accountpb.Address.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string created_by = 14;
 * @return {string}
 */
proto.accountpb.Address.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Address} returns this
 */
proto.accountpb.Address.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.accountpb.Address.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.accountpb.Address} returns this
*/
proto.accountpb.Address.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.Address} returns this
 */
proto.accountpb.Address.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.Address.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string modified_by = 16;
 * @return {string}
 */
proto.accountpb.Address.prototype.getModifiedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Address} returns this
 */
proto.accountpb.Address.prototype.setModifiedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional google.protobuf.Timestamp modified_at = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.accountpb.Address.prototype.getModifiedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.accountpb.Address} returns this
*/
proto.accountpb.Address.prototype.setModifiedAt = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.Address} returns this
 */
proto.accountpb.Address.prototype.clearModifiedAt = function() {
  return this.setModifiedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.Address.prototype.hasModifiedAt = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional uint32 owner_id = 18;
 * @return {number}
 */
proto.accountpb.Address.prototype.getOwnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.Address} returns this
 */
proto.accountpb.Address.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional string img_proof_of_address = 19;
 * @return {string}
 */
proto.accountpb.Address.prototype.getImgProofOfAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Address} returns this
 */
proto.accountpb.Address.prototype.setImgProofOfAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string address4 = 20;
 * @return {string}
 */
proto.accountpb.Address.prototype.getAddress4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Address} returns this
 */
proto.accountpb.Address.prototype.setAddress4 = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.CreateAddressResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.CreateAddressResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.CreateAddressResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.CreateAddressResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: (f = msg.getAddress()) && proto.accountpb.Address.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.CreateAddressResponse}
 */
proto.accountpb.CreateAddressResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.CreateAddressResponse;
  return proto.accountpb.CreateAddressResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.CreateAddressResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.CreateAddressResponse}
 */
proto.accountpb.CreateAddressResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Address;
      reader.readMessage(value,proto.accountpb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.CreateAddressResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.CreateAddressResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.CreateAddressResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.CreateAddressResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.Address.serializeBinaryToWriter
    );
  }
};


/**
 * optional Address address = 1;
 * @return {?proto.accountpb.Address}
 */
proto.accountpb.CreateAddressResponse.prototype.getAddress = function() {
  return /** @type{?proto.accountpb.Address} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.Address, 1));
};


/**
 * @param {?proto.accountpb.Address|undefined} value
 * @return {!proto.accountpb.CreateAddressResponse} returns this
*/
proto.accountpb.CreateAddressResponse.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.CreateAddressResponse} returns this
 */
proto.accountpb.CreateAddressResponse.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.CreateAddressResponse.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.UpdateAddressResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.UpdateAddressResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.UpdateAddressResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.UpdateAddressResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: (f = msg.getAddress()) && proto.accountpb.Address.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.UpdateAddressResponse}
 */
proto.accountpb.UpdateAddressResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.UpdateAddressResponse;
  return proto.accountpb.UpdateAddressResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.UpdateAddressResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.UpdateAddressResponse}
 */
proto.accountpb.UpdateAddressResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Address;
      reader.readMessage(value,proto.accountpb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.UpdateAddressResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.UpdateAddressResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.UpdateAddressResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.UpdateAddressResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.Address.serializeBinaryToWriter
    );
  }
};


/**
 * optional Address address = 1;
 * @return {?proto.accountpb.Address}
 */
proto.accountpb.UpdateAddressResponse.prototype.getAddress = function() {
  return /** @type{?proto.accountpb.Address} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.Address, 1));
};


/**
 * @param {?proto.accountpb.Address|undefined} value
 * @return {!proto.accountpb.UpdateAddressResponse} returns this
*/
proto.accountpb.UpdateAddressResponse.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.UpdateAddressResponse} returns this
 */
proto.accountpb.UpdateAddressResponse.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.UpdateAddressResponse.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.DeleteAddressRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.DeleteAddressRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.DeleteAddressRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteAddressRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.DeleteAddressRequest}
 */
proto.accountpb.DeleteAddressRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.DeleteAddressRequest;
  return proto.accountpb.DeleteAddressRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.DeleteAddressRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.DeleteAddressRequest}
 */
proto.accountpb.DeleteAddressRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.DeleteAddressRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.DeleteAddressRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.DeleteAddressRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteAddressRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.accountpb.DeleteAddressRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.DeleteAddressRequest} returns this
 */
proto.accountpb.DeleteAddressRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.accountpb.BatchCreateAddressRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.BatchCreateAddressRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.BatchCreateAddressRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.BatchCreateAddressRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.BatchCreateAddressRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressList: jspb.Message.toObjectList(msg.getAddressList(),
    proto.accountpb.Address.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.BatchCreateAddressRequest}
 */
proto.accountpb.BatchCreateAddressRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.BatchCreateAddressRequest;
  return proto.accountpb.BatchCreateAddressRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.BatchCreateAddressRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.BatchCreateAddressRequest}
 */
proto.accountpb.BatchCreateAddressRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Address;
      reader.readMessage(value,proto.accountpb.Address.deserializeBinaryFromReader);
      msg.addAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.BatchCreateAddressRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.BatchCreateAddressRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.BatchCreateAddressRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.BatchCreateAddressRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.accountpb.Address.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Address address = 1;
 * @return {!Array<!proto.accountpb.Address>}
 */
proto.accountpb.BatchCreateAddressRequest.prototype.getAddressList = function() {
  return /** @type{!Array<!proto.accountpb.Address>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.accountpb.Address, 1));
};


/**
 * @param {!Array<!proto.accountpb.Address>} value
 * @return {!proto.accountpb.BatchCreateAddressRequest} returns this
*/
proto.accountpb.BatchCreateAddressRequest.prototype.setAddressList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.accountpb.Address=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.Address}
 */
proto.accountpb.BatchCreateAddressRequest.prototype.addAddress = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.accountpb.Address, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.BatchCreateAddressRequest} returns this
 */
proto.accountpb.BatchCreateAddressRequest.prototype.clearAddressList = function() {
  return this.setAddressList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.accountpb.BatchCreateAddressResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.BatchCreateAddressResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.BatchCreateAddressResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.BatchCreateAddressResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.BatchCreateAddressResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressResponsesList: jspb.Message.toObjectList(msg.getAddressResponsesList(),
    proto.accountpb.Address.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.BatchCreateAddressResponse}
 */
proto.accountpb.BatchCreateAddressResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.BatchCreateAddressResponse;
  return proto.accountpb.BatchCreateAddressResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.BatchCreateAddressResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.BatchCreateAddressResponse}
 */
proto.accountpb.BatchCreateAddressResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Address;
      reader.readMessage(value,proto.accountpb.Address.deserializeBinaryFromReader);
      msg.addAddressResponses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.BatchCreateAddressResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.BatchCreateAddressResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.BatchCreateAddressResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.BatchCreateAddressResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.accountpb.Address.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Address address_responses = 1;
 * @return {!Array<!proto.accountpb.Address>}
 */
proto.accountpb.BatchCreateAddressResponse.prototype.getAddressResponsesList = function() {
  return /** @type{!Array<!proto.accountpb.Address>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.accountpb.Address, 1));
};


/**
 * @param {!Array<!proto.accountpb.Address>} value
 * @return {!proto.accountpb.BatchCreateAddressResponse} returns this
*/
proto.accountpb.BatchCreateAddressResponse.prototype.setAddressResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.accountpb.Address=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.Address}
 */
proto.accountpb.BatchCreateAddressResponse.prototype.addAddressResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.accountpb.Address, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.BatchCreateAddressResponse} returns this
 */
proto.accountpb.BatchCreateAddressResponse.prototype.clearAddressResponsesList = function() {
  return this.setAddressResponsesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.accountpb.ListAddressResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ListAddressResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ListAddressResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ListAddressResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListAddressResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressList: jspb.Message.toObjectList(msg.getAddressList(),
    proto.accountpb.Address.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ListAddressResponse}
 */
proto.accountpb.ListAddressResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ListAddressResponse;
  return proto.accountpb.ListAddressResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ListAddressResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ListAddressResponse}
 */
proto.accountpb.ListAddressResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Address;
      reader.readMessage(value,proto.accountpb.Address.deserializeBinaryFromReader);
      msg.addAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ListAddressResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ListAddressResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ListAddressResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListAddressResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.accountpb.Address.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Address address = 1;
 * @return {!Array<!proto.accountpb.Address>}
 */
proto.accountpb.ListAddressResponse.prototype.getAddressList = function() {
  return /** @type{!Array<!proto.accountpb.Address>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.accountpb.Address, 1));
};


/**
 * @param {!Array<!proto.accountpb.Address>} value
 * @return {!proto.accountpb.ListAddressResponse} returns this
*/
proto.accountpb.ListAddressResponse.prototype.setAddressList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.accountpb.Address=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.Address}
 */
proto.accountpb.ListAddressResponse.prototype.addAddress = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.accountpb.Address, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ListAddressResponse} returns this
 */
proto.accountpb.ListAddressResponse.prototype.clearAddressList = function() {
  return this.setAddressList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.DeleteOwnerAddressRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.DeleteOwnerAddressRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.DeleteOwnerAddressRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteOwnerAddressRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    addressId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ownerId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.DeleteOwnerAddressRequest}
 */
proto.accountpb.DeleteOwnerAddressRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.DeleteOwnerAddressRequest;
  return proto.accountpb.DeleteOwnerAddressRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.DeleteOwnerAddressRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.DeleteOwnerAddressRequest}
 */
proto.accountpb.DeleteOwnerAddressRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAddressId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOwnerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.DeleteOwnerAddressRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.DeleteOwnerAddressRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.DeleteOwnerAddressRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteOwnerAddressRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddressId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getOwnerId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 address_id = 1;
 * @return {number}
 */
proto.accountpb.DeleteOwnerAddressRequest.prototype.getAddressId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.DeleteOwnerAddressRequest} returns this
 */
proto.accountpb.DeleteOwnerAddressRequest.prototype.setAddressId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 owner_id = 2;
 * @return {number}
 */
proto.accountpb.DeleteOwnerAddressRequest.prototype.getOwnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.DeleteOwnerAddressRequest} returns this
 */
proto.accountpb.DeleteOwnerAddressRequest.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.DeleteOwnerAddressResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.DeleteOwnerAddressResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.DeleteOwnerAddressResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteOwnerAddressResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: (f = msg.getAddress()) && proto.accountpb.Address.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.DeleteOwnerAddressResponse}
 */
proto.accountpb.DeleteOwnerAddressResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.DeleteOwnerAddressResponse;
  return proto.accountpb.DeleteOwnerAddressResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.DeleteOwnerAddressResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.DeleteOwnerAddressResponse}
 */
proto.accountpb.DeleteOwnerAddressResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Address;
      reader.readMessage(value,proto.accountpb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.DeleteOwnerAddressResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.DeleteOwnerAddressResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.DeleteOwnerAddressResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteOwnerAddressResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.Address.serializeBinaryToWriter
    );
  }
};


/**
 * optional Address address = 1;
 * @return {?proto.accountpb.Address}
 */
proto.accountpb.DeleteOwnerAddressResponse.prototype.getAddress = function() {
  return /** @type{?proto.accountpb.Address} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.Address, 1));
};


/**
 * @param {?proto.accountpb.Address|undefined} value
 * @return {!proto.accountpb.DeleteOwnerAddressResponse} returns this
*/
proto.accountpb.DeleteOwnerAddressResponse.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.DeleteOwnerAddressResponse} returns this
 */
proto.accountpb.DeleteOwnerAddressResponse.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.DeleteOwnerAddressResponse.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.accountpb);
