import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment, IconButton, Tooltip } from '@material-ui/core';
import PasswordRequirement from "../Misc/PasswordRequirement";
import color from '../../assets/css/colors'
import {ReactComponent as Lock} from '../../assets/icons/lock.svg';
import {ReactComponent as Visibility} from '../../assets/icons/eye.svg';
import {ReactComponent as VisibilityOff} from '../../assets/icons/eye-slash.svg';
import {ReactComponent as Info} from '../../assets/icons/info.svg';

const useStyles = makeStyles(() => ({
    textFieldWrapper: {
        display: 'flex',
        position: 'relative',
    },
    passwordRequirementWrapper: {
        position: 'absolute',
        top: -195,
        left: 0,
        zIndex: 2,
        backgroundColor: color.dark.main,
        border: '1px solid ' + color.dark.dark,
        borderRadius: 5,
        padding: 20,
        width: '100%',
        boxSizing: 'border-box',
        '& > div:first-child': {
            margin: 0,
            '& > label': {
                marginBottom: 10,
                display: 'block',
            },
            '& > div:nth-child(2)': {
                flexDirection: 'column',
                '& > div': {
                    margin: 0,
                    '& > ul': {
                        marginBottom: 0,
                        marginTop: 0,
                    },
                },
            },
        },
    },
    tooltipWrapper: {
        position: 'absolute',
        top: 31,
        right: 1,
        padding: '9px 10px',
        backgroundColor: color.light.opacity,
        borderRadius: 5,
        '& svg': {
            width: 21,
            height: 21,
            cursor: 'help',
            '& path': {
                fill: color.primary.main,
            }
        },
    }
}));

const HtmlTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: color.light.main,
      maxWidth: 620,
      border: '1px solid ' + color.light.light,
      borderRadius: 10,
    },
}))(Tooltip);

const PasswordField = (props) => {
    const classes = useStyles();

    const [isFocus, setFocus] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false); 
    
    const startIcon = () => {
        return (<InputAdornment position="start"><Lock/></InputAdornment>);
    }

    const endIcon = () => {
        return (
            <InputAdornment position="end">
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => {setShowPassword(!showPassword)}}
                    onMouseDown={handleMouseDownPassword}
                >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
            </InputAdornment>
        );
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div className={classes.textFieldWrapper}>
            {(props.showPasswordRequirement && isFocus) ? (
                <div className={classes.passwordRequirementWrapper}>
                    <PasswordRequirement password={props.value} />
                </div>
            ) : (null)}
            <TextField
                style={{marginTop: props.mt ? 20 : 0, marginBottom: props.mb ? props.mb : 0}}
                fullWidth={true}
                autoFocus={props.autoFocus}
                error={props.error}
                required={props.required}
                disabled={props.disabled}
                id={props.id}
                name={props.name}
                label={props.label}
                placeholder={props.placeholder}
                helperText={props.error ? props.msg : ''}
                value={props.value || ''}
                onChange={props.onChange}
                onKeyUp={props.onKeyUp}
                onFocus={() => { setFocus(true) }}
                onBlur={() => { setFocus(false) }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    startAdornment: props.showIcon ? startIcon() : null,
                    endAdornment: ['password', 'confirmPassword', 'newPassword', 'lastPassword', 'oldPassword'].includes(props.name) ? endIcon() : null,
                }}
                inputProps={{ 
                    readOnly: props.readOnly ? true : false,
                    maxLength: props.max,
                    type: showPassword ? 'text' : 'password',
                    autoComplete: 'new-password',
                }}
            />
            {(props.tooltip || props.htmlTooltip) && (
                <div className={classes.tooltipWrapper} style={{marginTop: props.mt ? 20 : 0}}>
                    {props.tooltip ? (
                        <Tooltip 
                            arrow
                            placement="top"
                            title={props.tooltip}
                        >
                            <Info />
                        </Tooltip>
                    ) : (
                        <HtmlTooltip 
                            placement="right"
                            title={<React.Fragment>{props.htmlTooltip}</React.Fragment>}
                        >
                            <Info />
                        </HtmlTooltip> 
                    )}
                </div>
            )}
        </div>
    );
}
export default PasswordField;