import React from 'react';
import ClientGrid from '../ClientGrid/ClientGrid';
import Table, { columnType } from '../ClearingTable/Table';
import Button from '../Buttons/Button';
import Text from '../Typography/Text';
import SelectMonth from "../Dropdown/SelectMonth";
import TextField from "../Textfields/TextField";
import { fileColumns } from '../../lib/data/columns';
import QueryParam from '../../services/QueryParamService';
import { notifyInfo, notifyError } from '../../components/Messages/Notification';
import { listFile } from "../../services/FileService";
import { GridTitleFile } from '../contants/GridTitle';
import { CustomBodyRenderDate } from '../../components/Table/CustomBodyRender';
import FileDownload2 from '../ClientGrid/FileDownload2';

export default function Files({
  title: pageTitle,
  type: fileType
}) {
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [filePaths, setFilePaths] = React.useState([]);
  // let selectedIds = [];
  const [selectedIds, setSelectedIds] = React.useState([])

  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      type: fileType,
      keyword: '',
      month: '',
      year: '',
    })
  );

  const init = async () => {
    const searchParameters = localStorage.getItem('search_data');
    
    const {
      keyword, 
      month, 
      year,
    } = searchParameters ? JSON.parse(searchParameters) : searchData;
    
    setSearchData({
      ...searchData, 
      type: fileType,
      keyword: keyword ? keyword : '',
      month: month ? month : '', 
      year: year ? year : '',
    });
  }

  React.useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      name: 'createdDate',
      label: 'Created Date',
      options: {
        customBodyRender: CustomBodyRenderDate,
      },
    },
    {
      name: 'fileName',
      label: 'File Name',
    },
    {
      name: 'filePath',
      label: 'File Path',
    },
  ];

  const options = {
    responsive: 'standard',
    customToolbarSelect: (selectedRows) => {
      return (
        <div className="grd-row" style={{ marginRight: 15, padding: 0 }}>
          <div className="grd-cell" style={{ whiteSpace: 'nowrap' }}>
          <FileDownload2
            title={'File'}
            selectedRowsIds={filePaths}
            parameters={{}}
            rowsApi={[]}
          />
          </div>
        </div>
      );
    },
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      let tempFilePaths = [];

      rowsSelected.map((index) => {
        tempFilePaths.push(rows[index].filePath);
      })

      setFilePaths(tempFilePaths);
      setSelectedIds(rowsSelected);
    },
    rowsSelected: selectedIds,
  };

  const handleSearchDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setSearchData({...searchData, [input.name]: input.value });
  };

  const handleSearch = async () => {
    try {
      setLoading(true);

      localStorage.setItem('search-data', JSON.stringify(searchData));

      const data = await listFile(searchData);

      setRows(data.filesList);
      
      notifyInfo(data.filesList.length > 0 ? data.length + ' search result(s)' : 'No records found.');
    }
    catch (error) {
      setLoading(false);
      notifyError(error.message);
      console.log(error);
      return;
    } 
    finally {
      setLoading(false);
    }
  }

  const getCsvData = async () => {
    const data = await listFile(searchData);

    return data.filesList;
  }

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text
              mt={8}
              variant="h1"
              label={pageTitle}
            />
          </div>
          <div className="grd-cell-none">
            <Button 
              disabled={loading}
              loading={loading}
              type="search"
              label={loading ? "Searching..." : "Search"}
              onClick={handleSearch}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell-2">
            <TextField
              max={100}
              name="keyword"
              label="Search"
              placeholder="Search"
              type="text"
              value={searchData.keyword}
              onChange={handleSearchDataChange}
            />             
          </div>
          <div className="grd-cell">
            <SelectMonth
              name="month"
              label="Month"
              placeholder="Month"
              type="Month"
              value={searchData.month}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <TextField
              max={4}
              name="year"
              label="Year"
              placeholder="Year"
              type="text"
              value={searchData.year}
              onChange={handleSearchDataChange}
            />
          </div>
        </div>
      </div>
      <div className="mt-20">
        {/* <ClientGrid
          title={GridTitleFile}
          rowIdField="filePath"
          columns={fileColumns}
          rows={rows}
          getCsvData={getCsvData}
          isLoading={loading}
          setRows={setRows}
          showDownloadBatchAction={true}
        /> */}
        <Table
          title={'File'}
          rowIdField="filePath"
          data={rows}
          columns={columns}
          getCsvData={getCsvData}
          options={options}
        />
      </div>
    </div>
  );
}