import React, { useEffect } from 'react'
import { InputLabel } from '@material-ui/core'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'

const TextEditor = (props) => {
  
  useEffect(() => {}, [props.value])

  return (
    <>
      <InputLabel
        shrink
        style={{ marginBottom: 5 }}
        required={props.required}
        error={props.error}
      >
        {props.label}
      </InputLabel>
      <SunEditor
        setOptions={options}
        name={props.name}
        enableToolbar={props.enableToolbar}
        setContents={props.value}
        onChange={(value) => {
          console.log("Changed")
          props.onChange({ currentTarget: { name: props.name, value: value } })
        }}
        disable={props.disable}
      />
    </>
  )
}

const buttons = [
  ['undo', 'redo'],
  ['font', 'fontSize', 'formatBlock'],
  ['bold', 'underline', 'italic'],
  ['removeFormat'],
  ['fontColor', 'hiliteColor'],
  ['align', 'list', 'table'],
  ['link'],
]

const options = {
  height: 300,
  width: '100%',
  buttonList: buttons,
}

export default TextEditor
