import { TableRow, TableCell } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';
import Table, { columnType } from '../../../components/ClearingTable/Table'
import {
  CustomBodyRenderCurrency,
  CustomBodyRenderDate,
} from '../../../components/Table/CustomBodyRender';
import { SetCellPropsWidth } from '../../../components/Table/SetCellProps';
import {
  NestedTableTheme,
  DefaultTableTheme,
} from '../../../components/Table/TableThemes';
import BalanceSummaryExcelDownload from './BalanceSummaryExcelDownload';

export const BalanceSummaryTable = ({ data }) => {
  const accountHeaderColor = '#99ccff';
  const subAccountHeaderColor = '#6699ff';

  const balanceSummaryColumns = [
    {
      name: 'accountId',
      label: 'Account ID',
      options: {
        display: false,
      },
    },
    {
      name: 'dateType',
      label: 'Date Type',
    },
    {
      // Already converted to string in handlesearch
      name: 'date',
      label: 'Date',
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      name: 'cashBalance',
      label: 'Cash Balance',
      options: {
        setCellProps: SetCellPropsWidth(100),
        customBodyRender: CustomBodyRenderCurrency,
      },
    },
    {
      name: 'shortMarketValue',
      label: 'Short Market Value',
      options: {
        setCellProps: SetCellPropsWidth(100),
        customBodyRender: CustomBodyRenderCurrency,
      },
    },
    {
      name: 'longMarketValue',
      label: 'Long Market Value',
      options: {
        setCellProps: SetCellPropsWidth(100),
        customBodyRender: CustomBodyRenderCurrency,
      },
    },

    {
      name: 'equity',
      label: 'Equity',
      options: {
        setCellProps: SetCellPropsWidth(100),
        customBodyRender: CustomBodyRenderCurrency,
      },
    },
    {
      name: 'accountBalancesList',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        hidden: true,
      },
    },
  ];

  const accountColumns = [
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'accountName',
      label: 'Account Name',
    },
    {
      name: 'branch',
      label: 'Branch',
    },
    {
      name: 'rep',
      label: 'Rep',
    },
    {
      name: 'cashBalance',
      label: 'Cash Balance',
      options: {
        setCellProps: SetCellPropsWidth(100),
        customBodyRender: CustomBodyRenderCurrency,
      },
    },
    {
      name: 'shortMarketValue',
      label: 'Short Market Value',
      options: {
        setCellProps: SetCellPropsWidth(100),
        customBodyRender: CustomBodyRenderCurrency,
      },
    },

    {
      name: 'longMarketValue',
      label: 'Long Market Value',
      options: {
        setCellProps: SetCellPropsWidth(100),
        customBodyRender: CustomBodyRenderCurrency,
      },
    },
    {
      name: 'equity',
      label: 'Equity',
      options: {
        setCellProps: SetCellPropsWidth(100),
        customBodyRender: CustomBodyRenderCurrency,
      },
    },
    {
      name: 'subAccountBalancesList',
      options: { display: false, viewColumns: false, filter: false },
    },
    {
      name: 'balanceSummaryReportIndex',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
      },
    },
    {
      name: 'accountBalanceIndex',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
      },
    },
  ];

  const subAccountColumns = [
    {
      name: 'subAccountNo',
      label: 'Sub Account No',
    },
    {
      name: 'cashBalance',
      label: 'Cash Balance',
      addFooter: true,
      options: {
        setCellProps: SetCellPropsWidth(100),
        customBodyRender: CustomBodyRenderCurrency,
      },
    },
    {
      name: 'shortMarketValue',
      label: 'Short Market Value',
      addFooter: true,
      options: {
        setCellProps: SetCellPropsWidth(100),
        customBodyRender: CustomBodyRenderCurrency,
      },
    },
    {
      name: 'longMarketValue',
      label: 'Long Market Value',
      addFooter: true,
      options: {
        setCellProps: SetCellPropsWidth(100),
        customBodyRender: CustomBodyRenderCurrency,
      },
    },
    {
      name: 'equity',
      label: 'Equity',
      addFooter: true,
      options: {
        setCellProps: SetCellPropsWidth(100),
        customBodyRender: CustomBodyRenderCurrency,
      },
    },
  ];

  const nestedTableOptions = (expandMethod) => {
    const has_expandable_rows = expandMethod !== undefined;
    return {
      pagination: false,
      selectableRows: 'none',
      elevation: 0,
      filter: false,
      viewColumns: false,
      expandableRows: has_expandable_rows,
      expandableRowsOnClick: has_expandable_rows,
      expandableRowsHeader: true,
      renderExpandableRow: (rowData) => expandMethod(rowData),
      search: false,
      download: false,
      print: false,
      setTableProps: () => {
        return {
          size: 'small', // material ui v4 only
        };
      },
    };
  };

  const accountExpandableRows = (rowData, rowMeta) => {
    const rows = data[rowMeta.dataIndex].accountBalancesList;
    data.forEach((v) => {
      v.balanceSummaryReportIndex = rowMeta.dataIndex;
    });

    return (
      <TableRow key={data[rowMeta.dataIndex].masterAccountNo}>
        <TableCell colSpan={20} style={{ paddingLeft: 30 }}>
          <ThemeProvider theme={NestedTableTheme(subAccountHeaderColor)}>
            <MUIDataTable
              data={rows}
              columns={accountColumns}
              options={nestedTableOptions(subAccountExpandableRow)}
            />
          </ThemeProvider>
        </TableCell>
      </TableRow>
    );
  };

  const balanceSummaryTableOptions = {
    expandableRows: true,
    renderExpandableRow: accountExpandableRows,
    download: true,
    filter: true,
    search: true,
    print: false,
    sort: true,
    viewColumns: true,
    resizableColumns: false,
    draggableColumns: {
      enabled: false,
    },
    selectableRowsHeader: false,
    selectableRows: 'none',
    rowsPerPage: 20,
    rowsPerPageOptions: [20, 50, 100, 500],
    setTableProps: () => {
      return {
        size: 'small', // material ui v4 only
      };
    },
    onDownload: (buildHead, buildBody, cols, data) => {
      BalanceSummaryExcelDownload(data, cols, balanceSummaryColumns);
      return false;
    },
  };

  const subAccountExpandableRow = (rowData) => {
    const balanceSummaryReportIndex = rowData[10];
    const accountBalanceIndex = rowData[11];

    rowData =
      data[balanceSummaryReportIndex].accountBalancesList[accountBalanceIndex]
        .subAccountBalancesList;

    return (
      <TableRow>
        <TableCell colSpan={20} style={{ paddingLeft: 30 }}>
          <ThemeProvider theme={NestedTableTheme(accountHeaderColor)}>
            <MUIDataTable
              data={rowData}
              columns={subAccountColumns}
              options={nestedTableOptions()}
            />
          </ThemeProvider>
        </TableCell>
      </TableRow>
    );
  };

  return (
    // <ThemeProvider theme={DefaultTableTheme()}>
    //   <MUIDataTable
    //     title="Balance Summary"
    //     columns={balanceSummaryColumns}
    //     data={data}
    //     options={balanceSummaryTableOptions}
    //   />
    // </ThemeProvider>
    <Table
      title={'Balance Summary'}
      data={data}
      columns={balanceSummaryColumns}
      options={balanceSummaryTableOptions}
    />
  );
};
