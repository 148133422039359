import { createSelector } from "reselect"; //memoization
import {
  CreateBankAddressRequest,
  UpdateBankAddressRequest,
  ListBankAddressRequest,
  BankAddressServiceClient,
  DeleteBankAddressRequest,
  ReadBankAddressRequest,
  ReadComplateBankAddressRequest,
  LazyBankAddressRequest,
} from "../proto/bankpb/address_grpc_web_pb";

import { auth } from "../lib/auth/Auth";
const service = new BankAddressServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

export async function createBankAddress(param) {
  return new Promise((resolve, reject) => {
    const req = new CreateBankAddressRequest();
    req.setBankName(param.bankName);
    req.setBankRoutingNo(param.bankRoutingNo);
    req.setAddress(param.address);
    req.setCity(param.city);
    req.setZipCode(param.zipCode);
    req.setState(param.state);
    req.setStatus(param.status);
    req.setCountry(param.country);

    service.createBankAddress(req, {}, (error, response) => {
      if (error) {
        // notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateBankAddress(param) {
  return new Promise((resolve, reject) => {
    const req = new UpdateBankAddressRequest();
    req.setBankAddressId(param.bank_address_id);
    req.setAddress(param.address);
    req.setCity(param.city);
    req.setZipCode(param.zip_code);
    req.setState(param.state);
    req.setStatus(param.status);
    req.setCountry(param.country);

    service.updateBankAddress(req, {}, (error, response) => {
      if (error) {
        // notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listBankAddress(param) {
  return new Promise((resolve, reject) => {
    const req = new ListBankAddressRequest();
    req.setBankRoutingNo(param.bank_routing_no);
    req.setBankName(param.bank_name);

    service.listBankAddress(req, {}, (error, response) => {
      if (error) {
        // notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function deleteBankAddress(id) {
  return new Promise((resolve, reject) => {
    let req = new DeleteBankAddressRequest();
    req.setBankAddressId(id);

    service.deleteBankAddress(req, {}, (error, response) => {
      if (error) {
        // notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function readBankAddress(id) {
  return new Promise((resolve, reject) => {
    const req = new ReadBankAddressRequest();
    req.setBankAddressId(id.bankAddressId ? id.bankAddressId : id);

    service.readBankAddress(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject().bankAddress);
      }
    });
  });
}

export async function readCompleteAddress(param) {
  return new Promise((resolve, reject) => {
    const req = new ReadComplateBankAddressRequest();
    req.setBankRoutingNo(param.bankRoutingNo);
    req.setBankName(param.bankName);

    service.readCompleteBankAddress(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

//memoization -  cached result when the same inputs occur again
export const lazyBankAddress = createSelector(
  (a) => a,
  (a) => (async () => await lazyBankAddressPromise(a))()
);

async function lazyBankAddressPromise(value) {
  return new Promise((resolve, reject) => {
    let req = new LazyBankAddressRequest();
    req.setKey(value);
    req.setLimit(10);

    service.lazyBankAddress(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
