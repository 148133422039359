export default function download(fileProtoResponse) {
    const [mimeType, fileBytes, fileName] = fileProtoResponse.array;
  
    let blob = new Blob([fileBytes], { type: mimeType });
    let link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    return fileName;
}
  