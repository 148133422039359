import{
	NoteServiceClient,
	Note,
	ListNoteRequest,
} from '../proto/commonpb/note_grpc_web_pb';
import { auth } from '../lib/auth/Auth';


const service = new NoteServiceClient( 
	window.env.GRPC_ENDPOINT,
	{},
	{ ...auth }
);


export async function createNote(param) {
	  return new Promise((resolve, reject) => {
		const req = new Note();
		req.setLinkId(param.linkId);
		req.setNote(param.note);
		req.setUsrId(param.userId);

		service.createNote(req, {}, (error, response) => {
			if (error) {
				reject(error);
			} else {
				resolve(response.toObject());
			}
			});
		});
	}

export async function listNote(param) {
	 return new Promise((resolve, reject) => {
		const req = new ListNoteRequest();
		req.setLinkId(param.linkId);
		service.listNote(req, {}, (error, response) => {
			if (error) {
				reject(error);
			} else {
				resolve(response.toObject());
			}
			});
		});
	}