import React, { useState } from 'react'
import DoughnutChart from './Component/DoughnutChart'
import Panel from './Component/Panel'
import Text from '../../../components/Typography/Text'
import { Typography } from '@material-ui/core'
import PanelButton from '../../../components/Buttons/PanelButton'
import ClientGrid from '../../../components/ClientGrid/ClientGrid';
import ClientGrid2 from '../../../components/ClientGrid/ClientGrid2'
import Table, { columnType } from '../../../components/ClearingTable/Table';
import { clientAccountColumns } from '../../../lib/data/columns';
import {
  pbDate,
  currency,
  qty,
  number,
  htmlString,
  pbDateSorter,
} from '../../../components/ClientGrid/CellRenderer'
import { listClientAccount } from '../../../services/ClientAccountService';
import { listBankAccount } from '../../../services/CommonService';
import { listAccount } from '../../../services/BankAccountService';
import {
  listDigitalCashRequest
} from '../../../services/DigitalCashRequestService'
import { notifyError, } from '../../../components/Messages/Notification'


import {
  listWallet,
} from '../../../services/WalletService'

import { readSummary } from '../../../services/AchWireRequestService'
import { getProfileName } from '../../../services/ProfileService'
import { getCurrentUser } from '../../../services/AuthService'

import {
  CustomBodyRenderCurrency,
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderDate,
  CustomBodyRenderDateTime,
  CustomBodyRenderBoolean,
  CustomBodyRenderAlign,
  CustomBodyRenderCenter,
  CustomBodyRenderQty,
  CustomBodyRenderHtmlString
} from '../../../components/Table/CustomBodyRender';
import { formatPbDate, formatPbDateTime } from '../../../lib/fmt';

export default function BankSummary({ variant, name, number }) {

  const styles = {
    chartContainer: {
      width: '40%',
      height: '20%',
      // top: "40%",
      // left: "40%",
      margin: 5,
      // position: "absolute",
      // transform: "translate(-40%, -40%)"
    },
    panelContainer: {
      minWidth: 150,
      display: 'inline-block',
      borderRadius: 15,
      //margin: 20,
      background: '#FFF',
      fontSize: '2em',
      fontWeight: 'bold',
      padding:'20px 20px 0 20px'
    },
    card: {
      minWidth: 150,
      display: 'inline-block',
      borderRadius: 15,
      padding: 15,
      margin: 20,
      background: '#f0f3f8',
    },
    relative: {
      position: 'relative',
    },
    countContainer: {
      minWidth: 100,
      background: '#d6dfeb',
      marginBottom: 10,
      padding: 15,
      borderRadius: 15,
      
    },
    countContainerSuccess: {
      minWidth: 100,
      background: '#FAA74A',
      marginBottom: 10,
      padding: 15,
      borderRadius: 15,
      color:'#fff !important',
      fontSize: '14px',
      fontFamily: 'Inter',
    },
    countContainerInfo: {
      minWidth: 100,
      background: '#D9E8ED',
      marginBottom: 10,
      padding: 15,
      borderRadius: 15,
      color:'#000 !important',
      fontSize: '14px',
      fontFamily: 'Inter',
    },
    countContainerPrimary: {
      minWidth: 100,
      background: '#1F3861',
      marginBottom: 10,
      padding: 15,
      borderRadius: 15,
      color:'#fff !important',
      fontSize: '14px',
      fontFamily: 'Inter',
    },
    leftPanel: {
      
      flex:1,
    },
    middlePanel: {
      
      flex:5,
    },
    rightPanel: {
      
      flex:1,
    },
  }

  const options = {
    selectableRows: 'none',
    resposive: 'standard',
  }

  const clientColumns = [
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      },
    },
    {
      name: 'accountNo',
      label: 'Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'bankName',
      label: 'Bank Name',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'bankAccountNo',
      label: 'Bank Account Number',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      },
    },
    {
      name: 'achRoutingNo',
      label: 'ACH Routing No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'wireRoutingNo',
      label: 'Wire Routing No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'bankAccountType',
      label: 'Account Type',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'approvedMethod',
      label: 'Approved Method',
      options: {
        display: false,
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'plaidAccessToken',
      label: 'Plaid Access Token',
      options: {
        display: false,
        customBodyRender: CustomBodyRenderAlign,
      },
    },
    {
      // Already converted to string in handlesearch
      name: 'createdAt',
      label: 'Created At',
      options: {
        display: false,
      },
    },
    {
      name: 'createdBy',
      label: 'Created By',
      options: {
        display: false,
        customBodyRender: CustomBodyRenderAlign,
      },
    },
  ];


  const walletColumns = [
    {
      name: '',
      label: '',
      width: '40px',
    },
    {
      name: 'symbol',
      label: 'Symbol',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'symbolDescription',
      label: 'Symbol Description',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'accountNo',
      label: 'Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
  ]

  const failedColumns = [
    {
      name: '',
      label: '',
      width: '40px',
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'accountName',
      label: 'Account Name',
    },
    {
      name: 'amt',
      label: 'Amount',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'transferType',
      label: 'Transfer Type',
    },
    {
      // Already converted to string in handlesearch
      name: 'systemDate',
      label: 'System Date',
    },
    {
      name: 'status',
      label: 'Status',
    },
  ]

  
  const [clientRows, setClientRows] = React.useState([]);
  const [walletRows, setWalletRows] = React.useState([]);
  const [failedRows, setFailedRows] = React.useState([]);

  const [walletCount, setWalletCount] = React.useState(0);
  const [accountCount, setAccountCount] = React.useState(0);

  const [sendDataSet, setSendDataSet] = useState([])
  const [redeemDataSet, setRedeemDataSet] = useState([])
  const [depositDataSet, setDepositDataSet] = useState([])
  const [dataSet, setDataSet] = useState([])
  const [tableView, setTableView] = useState({
    account: false,
    wallet:false,
    failed:false
  })
  const [profileName, setProfileName] = React.useState('')
  const [user, setUser] = React.useState([])
  const [defaultAccount, setDefaultAccount] = React.useState([])

  const [sendSummaryData, setSendSummaryData] = useState({
    sendDone: 0,
    sendPending: 0,
    sendFailed:0,
  })

  const [redeemSummaryData, setRedeemSummaryData] = useState({
    redeemDone: 0,
    redeemPending: 0,
    redeemFailed:0,
  })

  const [depositSummaryData, setDepositSummaryData] = useState({
    depositDone: 0,
    depositPending: 0,
    depositFailed:0,
  })

  const init = async () => {
    readSummary().then((summaryDate) => {
      const { requestSummary } = summaryDate
      setDataSet(requestSummary);
      
      setSendDataSet([
        {
          data: [requestSummary.sendDone, requestSummary.sendPending, requestSummary.sendFailed],
          backgroundColor: ['#7ed320', '#d6e84c','#FFE414'],
        },
      ])
      setRedeemDataSet([
        {
          data: [requestSummary.redeemDone, requestSummary.redeemPending, requestSummary.redeemFailed],
          backgroundColor: ['#009bde', '#55c1e8','#FFE414'],
        },
      ])
      setDepositDataSet([
        {
          data: [requestSummary.depositDone, requestSummary.depositPending, requestSummary.depositFailed],
          backgroundColor: ['#7da2ff', '#687cb6','#3E5FC3'],
        },
      ])
      setSendSummaryData({
        sendDone: requestSummary.sendDone,
        sendPending: requestSummary.sendPending,
        sendFailed: requestSummary.sendFailed,
      })
      setRedeemSummaryData({
        redeemDone: requestSummary.redeemDone,
        redeemPending: requestSummary.redeemPending,
        redeemFailed: requestSummary.redeemFailed,
      })
      setDepositSummaryData({
        depositDone: requestSummary.depositDone,
        depositPending: requestSummary.depositPending,
        depositFailed: requestSummary.depositFailed,
      })
    })
    handleClickFailedTransaction()

    // Get wallet count
    const data = await listWallet({
      accountNo: '',
      symbol: '',
      status: 'Active'
    });
    setWalletCount(data.walletsList.length);

    // Get bank account count
    const data2 = await listAccount({
      correspondent: '',
      accountNo: '',
      masterAccountNo: '',
      achRoutingNo: '',
      bankName: '',
      bankAccountNo: '',
      bankOwnerName: '',
      status: '',
    });
    setAccountCount(data2.bankAccountsList.length);
  }

  
  const handleClickWallet = async(e) => {
    const searchData= {
      accountNo: '',
      symbol: '',
      status: 'Active'
    }
    try {
      const data = await listWallet(searchData)
      setWalletRows(data.walletsList)
    } catch (error) {
      console.log(error)
    } 
    setTableView({
      client : false,
      wallet: true,
      failed:false
    })
   }

   const handleClickAccounts = async (e) => {
     const searchData={
      correspondent: '',
      accountNo: '',
      masterAccountNo: '',
      achRoutingNo: '',
      bankName: '',
      bankAccountNo: '',
      bankOwnerName: '',
      status: '',
    }
    try {
      const data = await listAccount(searchData);

      let rowsCopy = data.bankAccountsList;

      const rowsCopyOutput = rowsCopy.map((data) => {
        let tempDate = formatPbDateTime(data.createdAt);
        data.createdAt = tempDate

        return data;
      })

      setClientRows(rowsCopyOutput);
    } catch(e) {
      console.log(e)
    }
     setTableView({
       client : true,
       wallet:false,
       failed:false
     })
   }

  const handleClickFailedTransaction = async(e) => {
    const searchData = {
      fromDate: '',
      toDate: '',
      correspondent: '',
      accountNo: '',
      symbol: '',
      externalId: '',
      masterAccountNo: '',
      transferType: '',
      status: 'Failed',
      sign: '',
      amount: '',
      search:'',
    }

    try {
      const data = await listDigitalCashRequest(searchData)

      let rowsCopy = data.dcRequestsList;

      const rowsCopyOutput = rowsCopy.map((data) => {
        let tempDate = formatPbDate(data.systemDate);
        data.systemDate = tempDate

        return data;
      })

      setFailedRows(rowsCopyOutput)
      
    } catch (error) {
      console.log(e)
    } 

    setTableView({
      client : false,
      wallet: false,
      failed: true
    })
  }

  const initAccess = async () => {
    const loggeduser = getCurrentUser()
    setUser(loggeduser)
    if (loggeduser === null) return
    try {

      const serverProfile = await getProfileName()
      setProfileName(serverProfile.toLowerCase())
    } catch (error) {
      notifyError(error.message)
      console.error(error)
    }
  }

  React.useEffect(() => {
    initAccess()
    init()
    // eslint-disable-next-line
  }, [])
  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Bank Summary" />
          </div>
        </div>
        <div className="grd-row" >
          <Panel
            title={'Send'}
            pendingCount={sendSummaryData.sendPending}
            doneCount={sendSummaryData.sendDone}
            failedCount={sendSummaryData.sendFailed}
            datasets={sendDataSet}
            transferType={"Transfer%20Send"}
          />
          <Panel
            title={'Redeem'}
            pendingCount={redeemSummaryData.redeemPending}
            doneCount={redeemSummaryData.redeemDone}
            failedCount={redeemSummaryData.redeemFailed}
            datasets={redeemDataSet}
            transferType={"Redeem"}

          />
          <Panel
            title={'Deposit'}
            pendingCount={depositSummaryData.depositPending}
            doneCount={depositSummaryData.depositDone}
            failedCount={depositSummaryData.depositFailed}
            datasets={depositDataSet}
            transferType={"Deposit"}

          />
          <div className="grd-cell" style= {styles.panelContainer} >
                <Typography variant="subtitle2" style={styles.cardTitle}>
                  Balance
                </Typography>
                <div className='grd-row'>
                  <div className="grd-cell" style= {styles.countContainerPrimary}>
                    <Typography  style={{ fontWeight: 600, color:'#fff' }}>
                      Beginning Balance 
                    </Typography>
                    <Typography  style={{ fontWeight: 600, color:'#fff' }}>
                      {dataSet.beginningBalance}
                    </Typography>
                  </div>
                </div>
                <div className='grd-row'>
                  <div className="grd-cell" style={styles.countContainerSuccess}>
                    <Typography  style={{ fontWeight: 600, color:'#fff' }}>
                      Available Balance 
                    </Typography>
                    <Typography style={{ fontWeight: 600, color:'#fff' }}>
                        {dataSet.availableBalance}
                    </Typography>
                  </div>
                </div>
                <div className='grd-row'>
                  <div className="grd-cell" style={styles.countContainerInfo}>
                    <Typography  style={{ fontWeight: 600, color:'#333' }}>
                      Blockchain Balance 
                      
                    </Typography>
                    <Typography  style={{ fontWeight: 600, color:'#333' }}>
                      
                      {dataSet.blockchainBalance}
                    </Typography>
                  </div>
              </div>
          </div>
        </div>
        
        <div className="grd-row">
          
          <div className="grd-cell" style={styles.leftPanel}>
            <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
              Statistics
            </Typography>
              <div className="grd-cell" style={{marginTop:'20px'}}>
                <PanelButton
                onClick={handleClickFailedTransaction}
                label="Failed Transactions"
                count={depositSummaryData.depositFailed + sendSummaryData.sendFailed +redeemSummaryData.redeemFailed }
                color="secondary"
                />
   
              </div>
            
            
              <div className="grd-cell" mt={8}>
              <PanelButton
                onClick={handleClickWallet}
                label="Wallet"
                count={walletCount}
                mt={8}
                color="primary"
                />
                
              </div>
            
            
              <div className="grd-cell">
              <PanelButton
                onClick={handleClickAccounts}
                label="Accounts"
                count={accountCount}
                mt={8}
                color="primary"
                />
                
             
              </div>
          </div>
          
          <div className="grd-cell" style={styles.middlePanel}>
                <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                  Details
                </Typography>
                <div style={{marginTop:'20px'}}>
                {tableView.client && (
                //   <ClientGrid
                //   title="Accounts"
                //   rowIdField="dcRequestId"
                //   columns={clientColumns}
                //   rows={clientRows}
                  
                // />
                // <ClientGrid2
                //     title="Accounts"
                //     data={clientRows}
                //     columns={clientColumns}
                //     options={options}
                // />
                <Table
                  title={'Accounts'}
                  data={clientRows}
                  columns={clientColumns}
                  options={options}
                  getCsvData={clientRows}
                />
                )}

                {tableView.wallet && (
                //   <ClientGrid
                //   title="Wallets"
                //   rowIdField="walletId"
                //   columns={walletColumns}
                //   rows={walletRows}
                  
                // />
                // <ClientGrid2
                //     title="Wallets"
                //     data={walletRows}
                //     columns={walletColumns}
                //     options={options}
                // />
                <Table
                  title={'Wallets'}
                  data={walletRows}
                  columns={walletColumns}
                  options={options}
                  getCsvData={walletRows}
                />
                )}

                {tableView.failed && (
                //   <ClientGrid
                //   title="Failed Transaction"
                //   rowIdField="dcRequestId"
                //   columns={failedColumns}
                //   rows={failedRows}
                  
                // />
                // <ClientGrid2
                //     title="Failed Transaction"
                //     data={failedRows}
                //     columns={failedColumns}
                //     options={options}
                // />
                <Table
                  title={'Failed Transaction'}
                  data={failedRows}
                  columns={failedColumns}
                  options={options}
                  getCsvData={failedRows}
                />
                )}

                </div>
          </div>
          
        </div>
      </div>
    </div>
  )
}
