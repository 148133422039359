import {
  FileServiceClient,
  ListFileRequest,
  FileRequest,
  File as ProtoFile,
  UploadRequest,
  StorageTypeRequest,
} from '../proto/filepb/manager_grpc_web_pb';

import { auth } from '../lib/auth/Auth';
import download from './DownloadService';

const service = new FileServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

export async function list(param) {
  return new Promise((resolve, reject) => {
    let req = new ListFileRequest();

    service.list(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function dl(param) {
  return new Promise((resolve, reject) => {
    let req = new FileRequest();
    req.setPath(param);

    service.download(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        console.log(response);
        const fileName = download(response);
        resolve(fileName);
        //resolve(response.toObject());
      }
    });
  });
}

export async function del(param) {
  return new Promise((resolve, reject) => {
    let req = new FileRequest();
    req.setPath(param);

    service.delete(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function uploadFiles(files, path) {
  let response = null;
  console.log(files);

  const req = new UploadRequest();
  req.setPath(path);
  let filesForUpload = [];
  let ctr = files.length;
  for (var i = 0; i < ctr; i++) {
    await readFileAsArrayBuffer(files[i]).then(async (items) => {
      // let fileUpload = new File();
      // fileUpload.setMimeType(files[i].type);
      // fileUpload.setFileBytes(items);
      // fileUpload.setFileName(files[i].name);
      let proto = new ProtoFile();
      proto.setFileName(files[i].name);
      proto.setFileBytes(items);
      proto.setMimeType(files[i].type);
      filesForUpload.push(proto);
    });
  }

  req.setFilesList(filesForUpload);
  // files.map(async function(file){

  try {
    await uploadPromise(req).then(async (resp) => {
      //notify success
      console.log(resp);
      response = resp;
    });
  } catch (e) {
    console.error(e);
  }

  //   try {
  //   await readFileAsArrayBuffer(param.personFile).then(async (items) => {
  //     let peronf = new File();
  //     peronf.setMimeType(param.personFile.type);
  //     peronf.setFileBytes(items);
  //     peronf.setFileName(param.personFile.name);
  //     req.setPerson(peronf);
  //     await readFileAsArrayBuffer(param.businessFile).then(async (items2) => {
  //       let businessf = new File();
  //       businessf.setMimeType(param.businessFile.type);
  //       businessf.setFileBytes(items2);
  //       businessf.setFileName(param.businessFile.name);
  //       req.setBusiness(businessf);

  //       await uploadPromise(req).then(async (items3) => {
  //         //notify success
  //         response = items3;
  //       });
  //     });
  //   });
  // } catch (error) {
  //   console.error(error);
  //   throw error;
  //   //notify error
  // }
  return response;
}

async function uploadPromise(req) {
  return new Promise((resolve, reject) => {
    service.upload(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

function readFileAsArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const bytes = new Uint8Array(arrayBuffer);
      resolve(bytes);
    };

    reader.onerror = (err) => {
      reject(err);
    };

    reader.readAsArrayBuffer(file);
  });
}

export async function checkStorageType(file) {
  const req = new StorageTypeRequest();
  return new Promise((resolve, reject) => {
    service.getStorageType(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
