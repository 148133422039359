import React, { useState } from 'react';
import { InputLabel, Select, MenuItem } from '@material-ui/core';
import { listDefaultAccessOption } from '../../services/AdministratorService';

export default function SelectDefaultPageAccess(props) {
  const [options, setOptions] = useState([]);
  const [mounted, setMounted] = React.useState(false);

  const getOptions = async () => {
    const setCache = props.setCache;
    const cache = props.cache;
    const setLoaded = props.setLoaded;
    const loaded = props.loaded;

    if (!loaded) {
      const data = await listDefaultAccessOption();
      setOptions(data.defaultAccessOptionsList.filter((name) => name));

      if (setCache) {
        setCache(data);
      }
      if (setLoaded) {
        setLoaded(true);
      }
    } else {
      setOptions(cache.defaultAccessOptionsList.filter((name) => name));
    }
  };

  React.useEffect(() => {
    if (!options.length && !mounted) {
      getOptions();
    }
    return () => {
      setMounted(true);
    };
    // eslint-disable-next-line
  }, [options, mounted]);

  return (
    <>
      <InputLabel shrink required={props.required} disabled={props.disabled} style={{marginBottom: 10}}>
        {props.label}
      </InputLabel>
      <Select
        displayEmpty
        fullWidth
        disabled={props.disabled}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
      >
        <MenuItem value="Full">Full</MenuItem>
        <MenuItem value="None">None</MenuItem>
        <MenuItem value="Read">Read</MenuItem>
      </Select>
    </>
  );
}
