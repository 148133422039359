import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Paper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import color from '../../assets/css/colors';
import fontFamily from '../../assets/css/fonts';

import { lazyListRole } from '../../services/RoleService';

const useStyles = makeStyles(() => ({
  autocompleteWrapper: {
    '& .MuiAutocomplete-inputRoot': {
      padding: '7px 15px 8px 15px !important',
      height: 48,
    },
    '& .MuiAutocomplete-option': {
      backgroundColor: 'red',
    },
    '& .MuiAutocomplete-endAdornment': {
      top: 'calc(50% - 15px)',
      '& svg': {
        width: '22px !important',
        height: '22px !important',
      },
    },
  },
  paper: {
    fontFamily: fontFamily.label,
    fontSize: 15,
    fontWeight: 600,
    backgroundColor: color.light.main,
  },
}));

export default function SelectRole(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState(props.value);
  const [roleId, setRoleId] = React.useState(0);
  const setPropsValue = (value, id) => {
    props.onChange({
      currentTarget: {
        name: props.name,
        value: value,
        roleId: id ? id : roleId,
      },
      target: {
        name: props.name,
        value: value,
        roleId: id ? id : roleId,
      },
    });
  };

  const handleOnBlur = async (value, id) => {
    if (props.freeSolo) {
      setPropsValue(value, id);
      return;
    }

    if (options.find((o) => o.roleName === value)) {
      setPropsValue(value, id);
      return;
    }

    // if selected value does not exist in options and is not a free solo
    setInputValue('');
    setPropsValue('');
  };

  //set local input value and get options
  const handleInputChange = async (event, value) => {
    if (!event) return;
    if (event.type === 'blur') return;
    if (event.type === 'click' && value) return;

    setInputValue(value);
    getOptions(value);
  };

  //get list from backend
  const getOptions = async (value) => {
    var { rolesList } = await lazyListRole({});
    setOptions(
      rolesList.sort((a, b) =>
        a.roleName > b.roleName ? 1 : b.roleName > a.roleName ? -1 : 0
      )
    );
  };

  React.useEffect(() => {
    if (!options.length && !props.value) {
      //removed this from if condition [open]
      getOptions(props);
    }
    // eslint-disable-next-line
  }, [props]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (
        e.target.value.length === 0 ||
        options.find((o) => o.roleName === e.target.value)
      ) {
        return;
      }
      if (!options.find((o) => o.roleName.indexOf(e.target.value) === -1)) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  };

  return (
    <Autocomplete
      className={classes.autocompleteWrapper}
      disabled={props.disabled}
      freeSolo={props.freeSolo}
      id={props.name}
      onInputChange={handleInputChange}
      onBlur={() => {
        handleOnBlur(inputValue, roleId);
      }}
      blurOnSelect={false}
      onChange={(_, v) => {
        const value = v ? v.roleName : '';

        setPropsValue(value);
        setInputValue(value);
        setRoleId(v ? v.roleId.toString() : 0);
      }}
      getOptionSelected={(option, value) => {
        return (
          option.roleName === value.roleName || options.find((o) => o.roleName !== value)
        );
      }}
      getOptionLabel={(option) => option.roleName || ''}
      inputValue={inputValue ? inputValue : props.value}
      options={options}
      autoHighlight={true}
      clearOnEscape={true}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={options.find((o) => o.roleId === props.value) || {}}
      name={props.name}
      PaperComponent={({ children }) => (
        <Paper className={classes.paper}>{children}</Paper>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          error={props.error}
          name={props.name}
          required={props.required}
          disabled={props.disabled}
          label={'Role Name'}
          placeholder={'Role Name'}
          InputLabelProps={{ shrink: true }}
          inputProps={{ ...params.inputProps, maxLength: 50 }}
        />
      )}
    />
  );
}
