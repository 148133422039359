import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, Tooltip } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import InfoIcon from '@material-ui/icons/Info';
import MUIInputAdornment from '@material-ui/core/InputAdornment';
import MUIIconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.light.main,
    maxWidth: 620,
    border: '1px solid ' + theme.palette.light.light,
  },
}))(Tooltip);

const APWTextField = (props) => {

  const { classes, startIcon, errMsg, ...otherProps } = props

  const startDefaultIcon = (name) => {
    if (name === 'email') {
      return <InputAdornment position="start"><MUIIconButton edge="start"><EmailIcon className={classes.icon} fontSize="small" /></MUIIconButton></InputAdornment>
    } else {
      return null
    }
  }

  const customizedStartIcon = (icon) => {
    return (
      <MUIInputAdornment position="start">
        <MUIIconButton edge="start">{icon}</MUIIconButton>
      </MUIInputAdornment>
    )
  }

  return (
    <div className={classes.textFieldWrapper}>
      <TextField
        {...otherProps}
        className={clsx(classes.margin)}
        onChange={(e) => {
          const input = e.currentTarget.name ? e.currentTarget : e.target;
          props.onChange(input.name, input.value.trimStart());
        }}
        variant="outlined"
        margin='dense'
        fullWidth={props.fullWidth}
        autoFocus={props.autoFocus}
        error={props.error}
        required={props.required}
        disabled={props.disabled}
        id={props.id}
        name={props.name}
        label={props.label}
        placeholder={props.placeholder}
        value={props.value || ''}
        onKeyUp={props.onKeyUp}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        InputLabelProps={{ shrink: true }}
        onInput={props.onInput}
        helperText={props.helperText}
        InputProps={props.InputProps || {
          startAdornment: startIcon ? customizedStartIcon(startIcon) : props.showIcon ? startDefaultIcon(props.name) : null,
        }}
        inputProps={{
          readOnly: props.readOnly ? true : false,
          maxLength: props.max,
          max: props.inputProps ? props.inputProps.max : null,
          min: props.inputProps ? props.inputProps.min : null,
          type: 'text',
          color: 'primary',
          autoComplete: 'no',
        }}
      />
      {(props.tooltip || props.htmlTooltip) && (
        <div className={classes.tooltipWrapper} style={{ marginTop: props.mt ? 20 : 0 }}>
          {props.tooltip ? (
            <Tooltip
              arrow
              placement="top"
              title={props.tooltip}
            >
              <InfoIcon fontSize="small" />
            </Tooltip>
          ) : (
            <HtmlTooltip
              placement="right"
              title={<React.Fragment>{props.htmlTooltip}</React.Fragment>}
            >
              <InfoIcon fontSize="small" />
            </HtmlTooltip>
          )}
        </div>
      )}
    </div>
  );
}

APWTextField.propTypes = {
  classes: PropTypes.object.isRequired, // PropTypes for styles
  theme: PropTypes.object.isRequired,   // PropTypes for theme
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  startIcon: PropTypes.node,
  errMsg: PropTypes.string,
};

APWTextField.defaultProps = {
  fullWidth: true,
  helperText: '',
  errMsg: ''
}

export default withStyles((theme) => ({
  textFieldWrapper: {
    display: 'flex',
    position: 'relative',
  },
  tooltipWrapper: {
    position: 'absolute',
    top: 32,
    right: 1,
    padding: '9px 10px',
    backgroundColor: 'rgba(0,0,0,0)',
    borderRadius: 5,
    '& svg': {
      width: 21,
      height: 21,
      cursor: 'help',
      '& path': {
        fill: theme.palette.primary.main,
      }
    },
  },
  margin: {

  },
  icon: {
    '&:hover': {
      backgroundColor: 'transparent !important', // Set the background color to transparent on hover
    },
  },
}), { withTheme: true, name: 'APWTextField' })(APWTextField);
