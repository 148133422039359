// source: proto/bankpb/request.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_type_date_pb = require('../../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.proto.CreateRequest', null, global);
goog.exportSymbol('proto.proto.CreateResponse', null, global);
goog.exportSymbol('proto.proto.EmptyRequest', null, global);
goog.exportSymbol('proto.proto.GetNewRequestIdRequest', null, global);
goog.exportSymbol('proto.proto.GetNewRequestIdResponse', null, global);
goog.exportSymbol('proto.proto.ListRequest', null, global);
goog.exportSymbol('proto.proto.ListRequestAuditRequest', null, global);
goog.exportSymbol('proto.proto.ListRequestAuditResponse', null, global);
goog.exportSymbol('proto.proto.ListResponse', null, global);
goog.exportSymbol('proto.proto.ReadCashBalanceResponse', null, global);
goog.exportSymbol('proto.proto.ReadMaximumWithdrawableRequest', null, global);
goog.exportSymbol('proto.proto.ReadMaximumWithdrawableResponse', null, global);
goog.exportSymbol('proto.proto.ReadRequest', null, global);
goog.exportSymbol('proto.proto.ReadResponse', null, global);
goog.exportSymbol('proto.proto.ReadSummaryRequest', null, global);
goog.exportSymbol('proto.proto.ReadSummaryResponse', null, global);
goog.exportSymbol('proto.proto.Request', null, global);
goog.exportSymbol('proto.proto.RequestSummary', null, global);
goog.exportSymbol('proto.proto.UpdateRequest', null, global);
goog.exportSymbol('proto.proto.UpdateResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.Request.displayName = 'proto.proto.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CreateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CreateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CreateRequest.displayName = 'proto.proto.CreateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.CreateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.CreateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.CreateResponse.displayName = 'proto.proto.CreateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.UpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpdateRequest.displayName = 'proto.proto.UpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.UpdateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.UpdateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.UpdateResponse.displayName = 'proto.proto.UpdateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReadRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ReadRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReadRequest.displayName = 'proto.proto.ReadRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReadResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ReadResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReadResponse.displayName = 'proto.proto.ReadResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListRequest.displayName = 'proto.proto.ListRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListResponse.displayName = 'proto.proto.ListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListRequestAuditRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListRequestAuditRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListRequestAuditRequest.displayName = 'proto.proto.ListRequestAuditRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListRequestAuditResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListRequestAuditResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListRequestAuditResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListRequestAuditResponse.displayName = 'proto.proto.ListRequestAuditResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReadMaximumWithdrawableRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ReadMaximumWithdrawableRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReadMaximumWithdrawableRequest.displayName = 'proto.proto.ReadMaximumWithdrawableRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReadMaximumWithdrawableResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ReadMaximumWithdrawableResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReadMaximumWithdrawableResponse.displayName = 'proto.proto.ReadMaximumWithdrawableResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReadSummaryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ReadSummaryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReadSummaryRequest.displayName = 'proto.proto.ReadSummaryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.RequestSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.RequestSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.RequestSummary.displayName = 'proto.proto.RequestSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReadSummaryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ReadSummaryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReadSummaryResponse.displayName = 'proto.proto.ReadSummaryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ReadCashBalanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ReadCashBalanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ReadCashBalanceResponse.displayName = 'proto.proto.ReadCashBalanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.EmptyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.EmptyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.EmptyRequest.displayName = 'proto.proto.EmptyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetNewRequestIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetNewRequestIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetNewRequestIdRequest.displayName = 'proto.proto.GetNewRequestIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.GetNewRequestIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.GetNewRequestIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.GetNewRequestIdResponse.displayName = 'proto.proto.GetNewRequestIdResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    accountId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    correspondent: jspb.Message.getFieldWithDefault(msg, 6, ""),
    branch: jspb.Message.getFieldWithDefault(msg, 7, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 8, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 9, ""),
    rep: jspb.Message.getFieldWithDefault(msg, 10, ""),
    bankId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    bankAccountNo: jspb.Message.getFieldWithDefault(msg, 12, ""),
    bankRoutingNo: jspb.Message.getFieldWithDefault(msg, 13, ""),
    bankName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    isInternational: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    requestType: jspb.Message.getFieldWithDefault(msg, 16, ""),
    transferType: jspb.Message.getFieldWithDefault(msg, 17, ""),
    amt: jspb.Message.getFieldWithDefault(msg, 18, ""),
    fee: jspb.Message.getFieldWithDefault(msg, 19, ""),
    fedNo: jspb.Message.getFieldWithDefault(msg, 20, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 21, ""),
    bank: jspb.Message.getFieldWithDefault(msg, 22, ""),
    bankNote: jspb.Message.getFieldWithDefault(msg, 23, ""),
    internalNote: jspb.Message.getFieldWithDefault(msg, 24, ""),
    status: jspb.Message.getFieldWithDefault(msg, 25, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 26, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    modifiedAt: (f = msg.getModifiedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    modifiedBy: jspb.Message.getFieldWithDefault(msg, 29, ""),
    sendTo: jspb.Message.getFieldWithDefault(msg, 30, ""),
    walletId: jspb.Message.getFieldWithDefault(msg, 31, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.Request}
 */
proto.proto.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.Request;
  return proto.proto.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.Request}
 */
proto.proto.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRequestId(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranch(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRep(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBankId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankAccountNo(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankRoutingNo(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankName(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsInternational(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestType(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransferType(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmt(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setFee(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setFedNo(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setBank(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankNote(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalNote(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 27:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 28:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModifiedAt(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifiedBy(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setSendTo(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWalletId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRep();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBankId();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getBankAccountNo();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getBankRoutingNo();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getBankName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getIsInternational();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getRequestType();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getTransferType();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getAmt();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getFee();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getFedNo();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getBank();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getBankNote();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getInternalNote();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModifiedAt();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModifiedBy();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getSendTo();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getWalletId();
  if (f !== 0) {
    writer.writeUint32(
      31,
      f
    );
  }
};


/**
 * optional uint32 request_id = 1;
 * @return {number}
 */
proto.proto.Request.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.type.Date system_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.proto.Request.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.proto.Request} returns this
*/
proto.proto.Request.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Request.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 account_id = 5;
 * @return {number}
 */
proto.proto.Request.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string correspondent = 6;
 * @return {string}
 */
proto.proto.Request.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string branch = 7;
 * @return {string}
 */
proto.proto.Request.prototype.getBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string account_no = 8;
 * @return {string}
 */
proto.proto.Request.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string master_account_no = 9;
 * @return {string}
 */
proto.proto.Request.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string rep = 10;
 * @return {string}
 */
proto.proto.Request.prototype.getRep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setRep = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional uint32 bank_id = 11;
 * @return {number}
 */
proto.proto.Request.prototype.getBankId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setBankId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string bank_account_no = 12;
 * @return {string}
 */
proto.proto.Request.prototype.getBankAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setBankAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string bank_routing_no = 13;
 * @return {string}
 */
proto.proto.Request.prototype.getBankRoutingNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setBankRoutingNo = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string bank_name = 14;
 * @return {string}
 */
proto.proto.Request.prototype.getBankName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setBankName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool is_international = 15;
 * @return {boolean}
 */
proto.proto.Request.prototype.getIsInternational = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setIsInternational = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional string request_type = 16;
 * @return {string}
 */
proto.proto.Request.prototype.getRequestType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setRequestType = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string transfer_type = 17;
 * @return {string}
 */
proto.proto.Request.prototype.getTransferType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setTransferType = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string amt = 18;
 * @return {string}
 */
proto.proto.Request.prototype.getAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string fee = 19;
 * @return {string}
 */
proto.proto.Request.prototype.getFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setFee = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string fed_no = 20;
 * @return {string}
 */
proto.proto.Request.prototype.getFedNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setFedNo = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string external_id = 21;
 * @return {string}
 */
proto.proto.Request.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string bank = 22;
 * @return {string}
 */
proto.proto.Request.prototype.getBank = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setBank = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string bank_note = 23;
 * @return {string}
 */
proto.proto.Request.prototype.getBankNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setBankNote = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string internal_note = 24;
 * @return {string}
 */
proto.proto.Request.prototype.getInternalNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setInternalNote = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string status = 25;
 * @return {string}
 */
proto.proto.Request.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string created_by = 26;
 * @return {string}
 */
proto.proto.Request.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 27;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Request.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 27));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Request} returns this
*/
proto.proto.Request.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Request.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional google.protobuf.Timestamp modified_at = 28;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.Request.prototype.getModifiedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 28));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.Request} returns this
*/
proto.proto.Request.prototype.setModifiedAt = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.clearModifiedAt = function() {
  return this.setModifiedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.Request.prototype.hasModifiedAt = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional string modified_by = 29;
 * @return {string}
 */
proto.proto.Request.prototype.getModifiedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setModifiedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string send_to = 30;
 * @return {string}
 */
proto.proto.Request.prototype.getSendTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setSendTo = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional uint32 wallet_id = 31;
 * @return {number}
 */
proto.proto.Request.prototype.getWalletId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.Request} returns this
 */
proto.proto.Request.prototype.setWalletId = function(value) {
  return jspb.Message.setProto3IntField(this, 31, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CreateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CreateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CreateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bankId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    requestType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    transferType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    amt: jspb.Message.getFieldWithDefault(msg, 6, ""),
    fee: jspb.Message.getFieldWithDefault(msg, 7, ""),
    fedNo: jspb.Message.getFieldWithDefault(msg, 8, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    bank: jspb.Message.getFieldWithDefault(msg, 10, ""),
    bankNote: jspb.Message.getFieldWithDefault(msg, 11, ""),
    internalNote: jspb.Message.getFieldWithDefault(msg, 12, ""),
    status: jspb.Message.getFieldWithDefault(msg, 13, ""),
    sendTo: jspb.Message.getFieldWithDefault(msg, 14, ""),
    walletId: jspb.Message.getFieldWithDefault(msg, 31, 0),
    requestId: jspb.Message.getFieldWithDefault(msg, 32, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CreateRequest}
 */
proto.proto.CreateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CreateRequest;
  return proto.proto.CreateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CreateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CreateRequest}
 */
proto.proto.CreateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBankId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransferType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFee(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFedNo(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBank(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankNote(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalNote(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSendTo(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWalletId(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CreateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CreateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CreateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBankId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getRequestType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTransferType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAmt();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFee();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFedNo();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBank();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBankNote();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getInternalNote();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSendTo();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getWalletId();
  if (f !== 0) {
    writer.writeUint32(
      31,
      f
    );
  }
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeUint32(
      32,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.proto.CreateRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateRequest} returns this
 */
proto.proto.CreateRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.proto.CreateRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateRequest} returns this
 */
proto.proto.CreateRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 bank_id = 3;
 * @return {number}
 */
proto.proto.CreateRequest.prototype.getBankId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateRequest} returns this
 */
proto.proto.CreateRequest.prototype.setBankId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string request_type = 4;
 * @return {string}
 */
proto.proto.CreateRequest.prototype.getRequestType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateRequest} returns this
 */
proto.proto.CreateRequest.prototype.setRequestType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string transfer_type = 5;
 * @return {string}
 */
proto.proto.CreateRequest.prototype.getTransferType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateRequest} returns this
 */
proto.proto.CreateRequest.prototype.setTransferType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string amt = 6;
 * @return {string}
 */
proto.proto.CreateRequest.prototype.getAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateRequest} returns this
 */
proto.proto.CreateRequest.prototype.setAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string fee = 7;
 * @return {string}
 */
proto.proto.CreateRequest.prototype.getFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateRequest} returns this
 */
proto.proto.CreateRequest.prototype.setFee = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string fed_no = 8;
 * @return {string}
 */
proto.proto.CreateRequest.prototype.getFedNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateRequest} returns this
 */
proto.proto.CreateRequest.prototype.setFedNo = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string external_id = 9;
 * @return {string}
 */
proto.proto.CreateRequest.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateRequest} returns this
 */
proto.proto.CreateRequest.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string bank = 10;
 * @return {string}
 */
proto.proto.CreateRequest.prototype.getBank = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateRequest} returns this
 */
proto.proto.CreateRequest.prototype.setBank = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string bank_note = 11;
 * @return {string}
 */
proto.proto.CreateRequest.prototype.getBankNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateRequest} returns this
 */
proto.proto.CreateRequest.prototype.setBankNote = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string internal_note = 12;
 * @return {string}
 */
proto.proto.CreateRequest.prototype.getInternalNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateRequest} returns this
 */
proto.proto.CreateRequest.prototype.setInternalNote = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string status = 13;
 * @return {string}
 */
proto.proto.CreateRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateRequest} returns this
 */
proto.proto.CreateRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string send_to = 14;
 * @return {string}
 */
proto.proto.CreateRequest.prototype.getSendTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.CreateRequest} returns this
 */
proto.proto.CreateRequest.prototype.setSendTo = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional uint32 wallet_id = 31;
 * @return {number}
 */
proto.proto.CreateRequest.prototype.getWalletId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 31, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateRequest} returns this
 */
proto.proto.CreateRequest.prototype.setWalletId = function(value) {
  return jspb.Message.setProto3IntField(this, 31, value);
};


/**
 * optional uint32 request_id = 32;
 * @return {number}
 */
proto.proto.CreateRequest.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.CreateRequest} returns this
 */
proto.proto.CreateRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 32, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.CreateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.CreateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.CreateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    request: (f = msg.getRequest()) && proto.proto.Request.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.CreateResponse}
 */
proto.proto.CreateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.CreateResponse;
  return proto.proto.CreateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.CreateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.CreateResponse}
 */
proto.proto.CreateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Request;
      reader.readMessage(value,proto.proto.Request.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.CreateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.CreateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.CreateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.CreateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Request.serializeBinaryToWriter
    );
  }
};


/**
 * optional Request request = 1;
 * @return {?proto.proto.Request}
 */
proto.proto.CreateResponse.prototype.getRequest = function() {
  return /** @type{?proto.proto.Request} */ (
    jspb.Message.getWrapperField(this, proto.proto.Request, 1));
};


/**
 * @param {?proto.proto.Request|undefined} value
 * @return {!proto.proto.CreateResponse} returns this
*/
proto.proto.CreateResponse.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.CreateResponse} returns this
 */
proto.proto.CreateResponse.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.CreateResponse.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    externalId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fedNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    internalNote: jspb.Message.getFieldWithDefault(msg, 4, ""),
    status: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpdateRequest}
 */
proto.proto.UpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpdateRequest;
  return proto.proto.UpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpdateRequest}
 */
proto.proto.UpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRequestId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFedNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalNote(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFedNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInternalNote();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional uint32 request_id = 1;
 * @return {number}
 */
proto.proto.UpdateRequest.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.UpdateRequest} returns this
 */
proto.proto.UpdateRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string external_id = 2;
 * @return {string}
 */
proto.proto.UpdateRequest.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateRequest} returns this
 */
proto.proto.UpdateRequest.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string fed_no = 3;
 * @return {string}
 */
proto.proto.UpdateRequest.prototype.getFedNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateRequest} returns this
 */
proto.proto.UpdateRequest.prototype.setFedNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string internal_note = 4;
 * @return {string}
 */
proto.proto.UpdateRequest.prototype.getInternalNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateRequest} returns this
 */
proto.proto.UpdateRequest.prototype.setInternalNote = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string status = 5;
 * @return {string}
 */
proto.proto.UpdateRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.UpdateRequest} returns this
 */
proto.proto.UpdateRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.UpdateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.UpdateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.UpdateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    request: (f = msg.getRequest()) && proto.proto.Request.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.UpdateResponse}
 */
proto.proto.UpdateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.UpdateResponse;
  return proto.proto.UpdateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.UpdateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.UpdateResponse}
 */
proto.proto.UpdateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Request;
      reader.readMessage(value,proto.proto.Request.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.UpdateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.UpdateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.UpdateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.UpdateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Request.serializeBinaryToWriter
    );
  }
};


/**
 * optional Request request = 1;
 * @return {?proto.proto.Request}
 */
proto.proto.UpdateResponse.prototype.getRequest = function() {
  return /** @type{?proto.proto.Request} */ (
    jspb.Message.getWrapperField(this, proto.proto.Request, 1));
};


/**
 * @param {?proto.proto.Request|undefined} value
 * @return {!proto.proto.UpdateResponse} returns this
*/
proto.proto.UpdateResponse.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.UpdateResponse} returns this
 */
proto.proto.UpdateResponse.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.UpdateResponse.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReadRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReadRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReadRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReadRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReadRequest}
 */
proto.proto.ReadRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReadRequest;
  return proto.proto.ReadRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReadRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReadRequest}
 */
proto.proto.ReadRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReadRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReadRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReadRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReadRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 request_id = 1;
 * @return {number}
 */
proto.proto.ReadRequest.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ReadRequest} returns this
 */
proto.proto.ReadRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReadResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReadResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReadResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReadResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    request: (f = msg.getRequest()) && proto.proto.Request.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReadResponse}
 */
proto.proto.ReadResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReadResponse;
  return proto.proto.ReadResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReadResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReadResponse}
 */
proto.proto.ReadResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Request;
      reader.readMessage(value,proto.proto.Request.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReadResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReadResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReadResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReadResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.Request.serializeBinaryToWriter
    );
  }
};


/**
 * optional Request request = 1;
 * @return {?proto.proto.Request}
 */
proto.proto.ReadResponse.prototype.getRequest = function() {
  return /** @type{?proto.proto.Request} */ (
    jspb.Message.getWrapperField(this, proto.proto.Request, 1));
};


/**
 * @param {?proto.proto.Request|undefined} value
 * @return {!proto.proto.ReadResponse} returns this
*/
proto.proto.ReadResponse.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReadResponse} returns this
 */
proto.proto.ReadResponse.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReadResponse.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    transferType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    status: jspb.Message.getFieldWithDefault(msg, 5, ""),
    requestType: jspb.Message.getFieldWithDefault(msg, 6, ""),
    fromDate: (f = msg.getFromDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    toDate: (f = msg.getToDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    branch: jspb.Message.getFieldWithDefault(msg, 9, ""),
    rep: jspb.Message.getFieldWithDefault(msg, 10, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    requestId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    sign: jspb.Message.getFieldWithDefault(msg, 13, ""),
    amount: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListRequest}
 */
proto.proto.ListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListRequest;
  return proto.proto.ListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListRequest}
 */
proto.proto.ListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransferType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestType(value);
      break;
    case 7:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromDate(value);
      break;
    case 8:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranch(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRep(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRequestId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSign(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTransferType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRequestType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFromDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRep();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getSign();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.proto.ListRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListRequest} returns this
 */
proto.proto.ListRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.proto.ListRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListRequest} returns this
 */
proto.proto.ListRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string master_account_no = 3;
 * @return {string}
 */
proto.proto.ListRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListRequest} returns this
 */
proto.proto.ListRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string transfer_type = 4;
 * @return {string}
 */
proto.proto.ListRequest.prototype.getTransferType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListRequest} returns this
 */
proto.proto.ListRequest.prototype.setTransferType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string status = 5;
 * @return {string}
 */
proto.proto.ListRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListRequest} returns this
 */
proto.proto.ListRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string request_type = 6;
 * @return {string}
 */
proto.proto.ListRequest.prototype.getRequestType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListRequest} returns this
 */
proto.proto.ListRequest.prototype.setRequestType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.type.Date from_date = 7;
 * @return {?proto.google.type.Date}
 */
proto.proto.ListRequest.prototype.getFromDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 7));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.proto.ListRequest} returns this
*/
proto.proto.ListRequest.prototype.setFromDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListRequest} returns this
 */
proto.proto.ListRequest.prototype.clearFromDate = function() {
  return this.setFromDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListRequest.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.type.Date to_date = 8;
 * @return {?proto.google.type.Date}
 */
proto.proto.ListRequest.prototype.getToDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 8));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.proto.ListRequest} returns this
*/
proto.proto.ListRequest.prototype.setToDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListRequest} returns this
 */
proto.proto.ListRequest.prototype.clearToDate = function() {
  return this.setToDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListRequest.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string branch = 9;
 * @return {string}
 */
proto.proto.ListRequest.prototype.getBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListRequest} returns this
 */
proto.proto.ListRequest.prototype.setBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string rep = 10;
 * @return {string}
 */
proto.proto.ListRequest.prototype.getRep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListRequest} returns this
 */
proto.proto.ListRequest.prototype.setRep = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string external_id = 11;
 * @return {string}
 */
proto.proto.ListRequest.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListRequest} returns this
 */
proto.proto.ListRequest.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional uint32 request_id = 12;
 * @return {number}
 */
proto.proto.ListRequest.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListRequest} returns this
 */
proto.proto.ListRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string sign = 13;
 * @return {string}
 */
proto.proto.ListRequest.prototype.getSign = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListRequest} returns this
 */
proto.proto.ListRequest.prototype.setSign = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string amount = 14;
 * @return {string}
 */
proto.proto.ListRequest.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListRequest} returns this
 */
proto.proto.ListRequest.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestsList: jspb.Message.toObjectList(msg.getRequestsList(),
    proto.proto.Request.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListResponse}
 */
proto.proto.ListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListResponse;
  return proto.proto.ListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListResponse}
 */
proto.proto.ListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Request;
      reader.readMessage(value,proto.proto.Request.deserializeBinaryFromReader);
      msg.addRequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.Request.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Request requests = 1;
 * @return {!Array<!proto.proto.Request>}
 */
proto.proto.ListResponse.prototype.getRequestsList = function() {
  return /** @type{!Array<!proto.proto.Request>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Request, 1));
};


/**
 * @param {!Array<!proto.proto.Request>} value
 * @return {!proto.proto.ListResponse} returns this
*/
proto.proto.ListResponse.prototype.setRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.Request=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Request}
 */
proto.proto.ListResponse.prototype.addRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.Request, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListResponse} returns this
 */
proto.proto.ListResponse.prototype.clearRequestsList = function() {
  return this.setRequestsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListRequestAuditRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListRequestAuditRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListRequestAuditRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListRequestAuditRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListRequestAuditRequest}
 */
proto.proto.ListRequestAuditRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListRequestAuditRequest;
  return proto.proto.ListRequestAuditRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListRequestAuditRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListRequestAuditRequest}
 */
proto.proto.ListRequestAuditRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListRequestAuditRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListRequestAuditRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListRequestAuditRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListRequestAuditRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 request_id = 1;
 * @return {number}
 */
proto.proto.ListRequestAuditRequest.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListRequestAuditRequest} returns this
 */
proto.proto.ListRequestAuditRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListRequestAuditResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListRequestAuditResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListRequestAuditResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListRequestAuditResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListRequestAuditResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestList: jspb.Message.toObjectList(msg.getRequestList(),
    proto.proto.Request.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListRequestAuditResponse}
 */
proto.proto.ListRequestAuditResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListRequestAuditResponse;
  return proto.proto.ListRequestAuditResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListRequestAuditResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListRequestAuditResponse}
 */
proto.proto.ListRequestAuditResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.Request;
      reader.readMessage(value,proto.proto.Request.deserializeBinaryFromReader);
      msg.addRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListRequestAuditResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListRequestAuditResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListRequestAuditResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListRequestAuditResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.Request.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Request request = 1;
 * @return {!Array<!proto.proto.Request>}
 */
proto.proto.ListRequestAuditResponse.prototype.getRequestList = function() {
  return /** @type{!Array<!proto.proto.Request>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.Request, 1));
};


/**
 * @param {!Array<!proto.proto.Request>} value
 * @return {!proto.proto.ListRequestAuditResponse} returns this
*/
proto.proto.ListRequestAuditResponse.prototype.setRequestList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.Request=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.Request}
 */
proto.proto.ListRequestAuditResponse.prototype.addRequest = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.Request, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListRequestAuditResponse} returns this
 */
proto.proto.ListRequestAuditResponse.prototype.clearRequestList = function() {
  return this.setRequestList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReadMaximumWithdrawableRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReadMaximumWithdrawableRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReadMaximumWithdrawableRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReadMaximumWithdrawableRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReadMaximumWithdrawableRequest}
 */
proto.proto.ReadMaximumWithdrawableRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReadMaximumWithdrawableRequest;
  return proto.proto.ReadMaximumWithdrawableRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReadMaximumWithdrawableRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReadMaximumWithdrawableRequest}
 */
proto.proto.ReadMaximumWithdrawableRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReadMaximumWithdrawableRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReadMaximumWithdrawableRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReadMaximumWithdrawableRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReadMaximumWithdrawableRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.proto.ReadMaximumWithdrawableRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReadMaximumWithdrawableRequest} returns this
 */
proto.proto.ReadMaximumWithdrawableRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.proto.ReadMaximumWithdrawableRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReadMaximumWithdrawableRequest} returns this
 */
proto.proto.ReadMaximumWithdrawableRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReadMaximumWithdrawableResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReadMaximumWithdrawableResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReadMaximumWithdrawableResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReadMaximumWithdrawableResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalAmount: jspb.Message.getFieldWithDefault(msg, 2, ""),
    withdrawableAmt: jspb.Message.getFieldWithDefault(msg, 3, ""),
    charges: jspb.Message.getFieldWithDefault(msg, 4, ""),
    pendingCallLog: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReadMaximumWithdrawableResponse}
 */
proto.proto.ReadMaximumWithdrawableResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReadMaximumWithdrawableResponse;
  return proto.proto.ReadMaximumWithdrawableResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReadMaximumWithdrawableResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReadMaximumWithdrawableResponse}
 */
proto.proto.ReadMaximumWithdrawableResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTotalAmount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setWithdrawableAmt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCharges(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPendingCallLog(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReadMaximumWithdrawableResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReadMaximumWithdrawableResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReadMaximumWithdrawableResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReadMaximumWithdrawableResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTotalAmount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWithdrawableAmt();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCharges();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPendingCallLog();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.proto.ReadMaximumWithdrawableResponse.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ReadMaximumWithdrawableResponse} returns this
 */
proto.proto.ReadMaximumWithdrawableResponse.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string total_amount = 2;
 * @return {string}
 */
proto.proto.ReadMaximumWithdrawableResponse.prototype.getTotalAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReadMaximumWithdrawableResponse} returns this
 */
proto.proto.ReadMaximumWithdrawableResponse.prototype.setTotalAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string withdrawable_amt = 3;
 * @return {string}
 */
proto.proto.ReadMaximumWithdrawableResponse.prototype.getWithdrawableAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReadMaximumWithdrawableResponse} returns this
 */
proto.proto.ReadMaximumWithdrawableResponse.prototype.setWithdrawableAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string charges = 4;
 * @return {string}
 */
proto.proto.ReadMaximumWithdrawableResponse.prototype.getCharges = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReadMaximumWithdrawableResponse} returns this
 */
proto.proto.ReadMaximumWithdrawableResponse.prototype.setCharges = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string pending_call_log = 5;
 * @return {string}
 */
proto.proto.ReadMaximumWithdrawableResponse.prototype.getPendingCallLog = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReadMaximumWithdrawableResponse} returns this
 */
proto.proto.ReadMaximumWithdrawableResponse.prototype.setPendingCallLog = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReadSummaryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReadSummaryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReadSummaryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReadSummaryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    usrId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReadSummaryRequest}
 */
proto.proto.ReadSummaryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReadSummaryRequest;
  return proto.proto.ReadSummaryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReadSummaryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReadSummaryRequest}
 */
proto.proto.ReadSummaryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUsrId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReadSummaryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReadSummaryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReadSummaryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReadSummaryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsrId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 usr_id = 1;
 * @return {number}
 */
proto.proto.ReadSummaryRequest.prototype.getUsrId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ReadSummaryRequest} returns this
 */
proto.proto.ReadSummaryRequest.prototype.setUsrId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.RequestSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.RequestSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.RequestSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RequestSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    sendDone: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sendPending: jspb.Message.getFieldWithDefault(msg, 2, 0),
    redeemDone: jspb.Message.getFieldWithDefault(msg, 3, 0),
    redeemPending: jspb.Message.getFieldWithDefault(msg, 4, 0),
    depositDone: jspb.Message.getFieldWithDefault(msg, 5, 0),
    depositPending: jspb.Message.getFieldWithDefault(msg, 6, 0),
    sendFailed: jspb.Message.getFieldWithDefault(msg, 7, 0),
    redeemFailed: jspb.Message.getFieldWithDefault(msg, 8, 0),
    depositFailed: jspb.Message.getFieldWithDefault(msg, 9, 0),
    wallet: jspb.Message.getFieldWithDefault(msg, 10, 0),
    account: jspb.Message.getFieldWithDefault(msg, 11, 0),
    beginningBalance: jspb.Message.getFieldWithDefault(msg, 12, ""),
    availableBalance: jspb.Message.getFieldWithDefault(msg, 13, ""),
    blockchainBalance: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.RequestSummary}
 */
proto.proto.RequestSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.RequestSummary;
  return proto.proto.RequestSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.RequestSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.RequestSummary}
 */
proto.proto.RequestSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSendDone(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSendPending(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRedeemDone(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRedeemPending(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDepositDone(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDepositPending(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSendFailed(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRedeemFailed(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDepositFailed(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWallet(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccount(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setBeginningBalance(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvailableBalance(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setBlockchainBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.RequestSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.RequestSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.RequestSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.RequestSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSendDone();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getSendPending();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getRedeemDone();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getRedeemPending();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getDepositDone();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getDepositPending();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getSendFailed();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getRedeemFailed();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getDepositFailed();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getWallet();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getAccount();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getBeginningBalance();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAvailableBalance();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getBlockchainBalance();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional uint32 send_done = 1;
 * @return {number}
 */
proto.proto.RequestSummary.prototype.getSendDone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.RequestSummary} returns this
 */
proto.proto.RequestSummary.prototype.setSendDone = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 send_pending = 2;
 * @return {number}
 */
proto.proto.RequestSummary.prototype.getSendPending = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.RequestSummary} returns this
 */
proto.proto.RequestSummary.prototype.setSendPending = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 redeem_done = 3;
 * @return {number}
 */
proto.proto.RequestSummary.prototype.getRedeemDone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.RequestSummary} returns this
 */
proto.proto.RequestSummary.prototype.setRedeemDone = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 redeem_pending = 4;
 * @return {number}
 */
proto.proto.RequestSummary.prototype.getRedeemPending = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.RequestSummary} returns this
 */
proto.proto.RequestSummary.prototype.setRedeemPending = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 deposit_done = 5;
 * @return {number}
 */
proto.proto.RequestSummary.prototype.getDepositDone = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.RequestSummary} returns this
 */
proto.proto.RequestSummary.prototype.setDepositDone = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 deposit_pending = 6;
 * @return {number}
 */
proto.proto.RequestSummary.prototype.getDepositPending = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.RequestSummary} returns this
 */
proto.proto.RequestSummary.prototype.setDepositPending = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 send_failed = 7;
 * @return {number}
 */
proto.proto.RequestSummary.prototype.getSendFailed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.RequestSummary} returns this
 */
proto.proto.RequestSummary.prototype.setSendFailed = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 redeem_failed = 8;
 * @return {number}
 */
proto.proto.RequestSummary.prototype.getRedeemFailed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.RequestSummary} returns this
 */
proto.proto.RequestSummary.prototype.setRedeemFailed = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 deposit_failed = 9;
 * @return {number}
 */
proto.proto.RequestSummary.prototype.getDepositFailed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.RequestSummary} returns this
 */
proto.proto.RequestSummary.prototype.setDepositFailed = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 wallet = 10;
 * @return {number}
 */
proto.proto.RequestSummary.prototype.getWallet = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.RequestSummary} returns this
 */
proto.proto.RequestSummary.prototype.setWallet = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 account = 11;
 * @return {number}
 */
proto.proto.RequestSummary.prototype.getAccount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.RequestSummary} returns this
 */
proto.proto.RequestSummary.prototype.setAccount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string beginning_balance = 12;
 * @return {string}
 */
proto.proto.RequestSummary.prototype.getBeginningBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestSummary} returns this
 */
proto.proto.RequestSummary.prototype.setBeginningBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string available_balance = 13;
 * @return {string}
 */
proto.proto.RequestSummary.prototype.getAvailableBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestSummary} returns this
 */
proto.proto.RequestSummary.prototype.setAvailableBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string blockchain_balance = 14;
 * @return {string}
 */
proto.proto.RequestSummary.prototype.getBlockchainBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.RequestSummary} returns this
 */
proto.proto.RequestSummary.prototype.setBlockchainBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReadSummaryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReadSummaryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReadSummaryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReadSummaryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestSummary: (f = msg.getRequestSummary()) && proto.proto.RequestSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReadSummaryResponse}
 */
proto.proto.ReadSummaryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReadSummaryResponse;
  return proto.proto.ReadSummaryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReadSummaryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReadSummaryResponse}
 */
proto.proto.ReadSummaryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.RequestSummary;
      reader.readMessage(value,proto.proto.RequestSummary.deserializeBinaryFromReader);
      msg.setRequestSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReadSummaryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReadSummaryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReadSummaryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReadSummaryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestSummary();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.RequestSummary.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequestSummary request_summary = 1;
 * @return {?proto.proto.RequestSummary}
 */
proto.proto.ReadSummaryResponse.prototype.getRequestSummary = function() {
  return /** @type{?proto.proto.RequestSummary} */ (
    jspb.Message.getWrapperField(this, proto.proto.RequestSummary, 1));
};


/**
 * @param {?proto.proto.RequestSummary|undefined} value
 * @return {!proto.proto.ReadSummaryResponse} returns this
*/
proto.proto.ReadSummaryResponse.prototype.setRequestSummary = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ReadSummaryResponse} returns this
 */
proto.proto.ReadSummaryResponse.prototype.clearRequestSummary = function() {
  return this.setRequestSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ReadSummaryResponse.prototype.hasRequestSummary = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ReadCashBalanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ReadCashBalanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ReadCashBalanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReadCashBalanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cashBalance: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ReadCashBalanceResponse}
 */
proto.proto.ReadCashBalanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ReadCashBalanceResponse;
  return proto.proto.ReadCashBalanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ReadCashBalanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ReadCashBalanceResponse}
 */
proto.proto.ReadCashBalanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCashBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ReadCashBalanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ReadCashBalanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ReadCashBalanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ReadCashBalanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCashBalance();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string cash_balance = 1;
 * @return {string}
 */
proto.proto.ReadCashBalanceResponse.prototype.getCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ReadCashBalanceResponse} returns this
 */
proto.proto.ReadCashBalanceResponse.prototype.setCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.EmptyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.EmptyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.EmptyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.EmptyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.EmptyRequest}
 */
proto.proto.EmptyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.EmptyRequest;
  return proto.proto.EmptyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.EmptyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.EmptyRequest}
 */
proto.proto.EmptyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.EmptyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.EmptyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.EmptyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.EmptyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetNewRequestIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetNewRequestIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetNewRequestIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetNewRequestIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetNewRequestIdRequest}
 */
proto.proto.GetNewRequestIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetNewRequestIdRequest;
  return proto.proto.GetNewRequestIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetNewRequestIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetNewRequestIdRequest}
 */
proto.proto.GetNewRequestIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetNewRequestIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetNewRequestIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetNewRequestIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetNewRequestIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.GetNewRequestIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.GetNewRequestIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.GetNewRequestIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetNewRequestIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.GetNewRequestIdResponse}
 */
proto.proto.GetNewRequestIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.GetNewRequestIdResponse;
  return proto.proto.GetNewRequestIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.GetNewRequestIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.GetNewRequestIdResponse}
 */
proto.proto.GetNewRequestIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.GetNewRequestIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.GetNewRequestIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.GetNewRequestIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.GetNewRequestIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 request_id = 1;
 * @return {number}
 */
proto.proto.GetNewRequestIdResponse.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.GetNewRequestIdResponse} returns this
 */
proto.proto.GetNewRequestIdResponse.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


goog.object.extend(exports, proto.proto);
