import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, InputLabel, Paper, colors } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Text from '../../../../components/Typography/Text';
import Button from '../../../../components/Buttons/Button';
import CloseButton from '../../../../components/Buttons/CloseButton';
import fontFamily from '../../../../assets/css/fonts';
import color from '../../../../assets/css/colors';
import { formatQty } from '../../../../lib/fmt';
import { CheckCircle as CheckCircleIcon, Cancel as CrossIcon } from '@material-ui/icons';
const styles = {
  chartContainer: {
    width: '40%',
    height: '20%',
    // top: "40%",
    // left: "40%",
    margin: 5,
    // position: "absolute",
    // transform: "translate(-40%, -40%)"
  },
  table: {
    width: '100%',
    maxWidth: '100%',
    marginBottom: '1rem',
    backgroundColor: 'transparent',
    borderCollapse: 'collapse',
  },
  tabletr: {
    //borderBottom: '1px solid #CCC',
    border: '1px solid #CCC',
    paddingBottom: '5px',
  },
  tabletdField: {
    // borderBottom: '1px solid #CCC',
    //margin: '0px 20px 20px 20px',
    // paddingBottom: '7px',
    // paddingTop: '7px',
    padding: '10px',
    fontColor: '#000',
    fontSize: '0.85em',
  },
  tabletdValue: {
    // borderBottom: '1px solid #CCC',
    padding: '10px',
    fontColor: '#000',
    fontSize: '0.85em',
    textAlign: 'right',
    fontWeight: 'bold',
  },
  card: {
    minWidth: 150,
    display: 'inline-block',
    borderRadius: 15,
    //margin: 20,
    background: '#FFF',
    fontSize: '2em',
    fontWeight: '600',
    padding: '20px 20px 0 20px',
    fontFamily: 'Inter',
  },
  cardTitle: {
    fontWeight: '600',
    // fontSize:'30px'
    fontFamily: 'Inter',
  },
  cardValue: {
    minWidth: 150,
    display: 'inline-block',
    borderRadius: 15,
    //margin: 20,
    background: '#FFF',
    fontSize: '2em',
    fontWeight: '600',
    padding: '20px 20px 0 40px',
    fontFamily: 'Inter',
  },
  link: {
    textDecoration: 'none',
    fontSize: '16px',
    color: '#292F4D',
    fontFamily: 'Inter',
  },
  stats: {
    display: 'block',
    borderBottom: '1px solid #CCC',
    //margin: '0px 20px 20px 20px',
    paddingBottom: '10px',
  },
  statsContainer: {
    display: 'block',
    marginTop: '10px',
  },
  relative: {
    position: 'relative',
  },
  countContainer: {
    minWidth: 100,
    background: '#d6dfeb',
    marginBottom: 10,
    padding: 15,
    borderRadius: 15,
  },
  countContainerSuccess: {
    minWidth: 100,
    background: '#5F2B5F',
    marginBottom: 10,
    padding: 15,
    borderRadius: 15,
    color: '#fff !important',
    fontSize: '14px',
    fontFamily: 'Inter',
  },
  countContainerDanger: {
    minWidth: 100,
    background: '#B9324C',
    marginBottom: 10,
    padding: 15,
    borderRadius: 15,
    color: '#fff !important',
    fontSize: '14px',
    fontFamily: 'Inter',
  },
  countContainerWarning: {
    minWidth: 100,
    background: '#28947C',
    marginBottom: 10,
    padding: 15,
    borderRadius: 15,
    color: '#fff !important',
    fontSize: '14px',
    fontFamily: 'Inter',
  },
};

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 1200,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    maxHeight: '80vh',
    overflowY: 'auto',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
}));

export default function OrderEntryAddEditPreviewModal(props) {
  const classes = useStyles();
  const { data, accountDetails, isEdit, open, close, loading, handleSave: handleClose } = props;
  const [modalData, setModalData] = React.useState({
    ...data,
  });

  const handleSave = () => {
    handleClose(modalData);
  };

  const getAssetTypeName = () => {
    let assetTypeName = '';

    if (modalData.assetType === 'E') assetTypeName = 'Equity';
    else if (modalData.assetType === 'MF') assetTypeName = 'Mutual Funds';
    else if (modalData.assetType === 'F') assetTypeName = 'Fixed Income';
    else if (modalData.assetType === 'O') assetTypeName = 'Option';

    return assetTypeName;
  };

  const getOrderTypeName = () => {
    let orderTypeName = '';

    if (modalData.orderType === 'L') orderTypeName = 'Limit Order';
    else if (modalData.orderType === 'M') orderTypeName = 'Market Order';
    else if (modalData.orderType === 'S') orderTypeName = 'Stop Order';
    else if (modalData.orderType === 'SL') orderTypeName = 'Stop Limit Order';
    else if (modalData.orderType === 'TS$') orderTypeName = 'Trailing Stop - $';
    else if (modalData.orderType === 'TS%') orderTypeName = 'Trailing Stop - %';

    return orderTypeName;
  };

  const getTimeLimit = () => {
    let timeLimitName = '';

    if (modalData.tif === 'D') timeLimitName = 'Day';
    else if (modalData.tif === 'GTC') timeLimitName = "Good 'til Cancelled";

    return timeLimitName;
  };

  const getBoolean = (bool) => {
    return (
      <div style={{ textAlign: 'right', width: '100%' }}>
        {bool === true && <CheckCircleIcon />}
        {bool != true && <CrossIcon />}
      </div>
    );
  };

  const dateTimeString = (date) => {
    if(date) {
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      const day = date.substring(8, 10);
  
      let formatString = month + '/' + day + '/' + year;
  
      return <div>{formatString}</div>;
    }
   
    return ''
  };

  const getSymbolLabel = () => {
    if (modalData.assetType === 'F') return 'Cusip';
    if (modalData.action === 'ICE') return 'From Symbol';

    return 'Symbol';
  };

  const getQuantityLabel = () => {
    if (['Sell %', 'Sell All', 'ICE'].includes(modalData.action)) return 'Qty %';
    if (['Sell $', 'Buy $'].includes(modalData.action)) return 'Qty $';

    return 'Qty';
  };

  const getPutCall = () => {
    if (modalData.putCall === 'P') return 'Put';
    if (modalData.putCall === 'C') return 'Call';

    return '';
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => close()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div style={{ width: 500 }}>
          <div className={classes.modalHeader}>
            <div>
              <Text
                mt={4}
                variant="h2"
                label={isEdit ? 'Preview Edit Order' : 'Preview Add Order'}
              />
                 <Text
                color={color.secondary.light}
                mt={10}
                variant="h5"
                label={'Are you sure you want to ' +  (isEdit ? 'edit' : 'add') + ' this order?'}
              />
            </div>
            <div className={classes.closeButton}>
              <CloseButton onClick={() => close()} />
            </div>
          </div>
          <div className={classes.modalBody}>
            <table style={styles.table}>
              {/* ACCOUNT DETAILS */}
              <tr style={styles.tabletr}>
                <td style={styles.tabletdField}>Account No:</td>
                <td style={styles.tabletdValue}>{modalData.accountNo}</td>
              </tr>

              <tr style={styles.tabletr}>
                <td style={styles.tabletdField}>Account Name:</td>
                <td style={styles.tabletdValue}>{accountDetails.accountName}</td>
              </tr>

              <tr style={styles.tabletr}>
                <td style={styles.tabletdField}>Rep:</td>
                <td style={styles.tabletdValue}>{accountDetails.rep}</td>
              </tr>

              <tr>
                <td style={styles.tabletdField}></td>
                <td style={styles.tabletdValue}></td>
              </tr>

              {/* ASSET TYPE */}
              <tr style={styles.tabletr}>
                <td style={styles.tabletdField}>Asset Type:</td>
                <td style={styles.tabletdValue}>{getAssetTypeName()}</td>
              </tr>

              <tr style={styles.tabletr}>
                <td style={styles.tabletdField}>Solicited:</td>
                <td style={styles.tabletdValue}>{getBoolean(modalData.solicited)}</td>
              </tr>

              <tr>
                <td style={styles.tabletdField}></td>
                <td style={styles.tabletdValue}></td>
              </tr>

              {/* ASSET ACTION */}
              <tr style={styles.tabletr}>
                <td style={styles.tabletdField}>Action:</td>
                <td style={styles.tabletdValue}>{modalData.action}</td>
              </tr>

              {modalData.action === 'Sell $' && modalData.assetType === 'MF' && (
                <tr style={styles.tabletr}>
                  <td style={styles.tabletdField}>Plus Commission:</td>
                  <td style={styles.tabletdValue}>
                    {getBoolean(modalData.plusCommission)}
                  </td>
                </tr>
              )}

              <tr style={styles.tabletr}>
                <td style={styles.tabletdField}>{getSymbolLabel() + ':'}</td>
                <td style={styles.tabletdValue}>{modalData.symbol}</td>
              </tr>

              {modalData.action === 'ICE' && (
                <tr style={styles.tabletr}>
                  <td style={styles.tabletdField}>To Symbol:</td>
                  <td style={styles.tabletdValue}>{modalData.symbol2}</td>
                </tr>
              )}

              {modalData.assetType === 'F' && modalData.action !== 'Sell All' ? (
                <tr style={styles.tabletr}>
                  <td style={styles.tabletdField}>{'Dollar: '}</td>
                  <td style={styles.tabletdValue}>${formatQty(modalData.dollar)}</td>
                </tr>
              ) : (
                ((modalData.dollar &&
                  modalData.assetType === 'E' &&
                  ['Buy', 'Sell', 'Sell All', 'ICE'].includes(modalData.action)) ||
                  modalData.assetType == 'O') && (
                  <tr style={styles.tabletr}>
                    <td style={styles.tabletdField}>
                      {modalData.assetType === 'O' ? 'Contract:' : 'Qty:'}
                    </td>
                    <td style={styles.tabletdValue}>{modalData.share}</td>
                  </tr>
                )
              )}

              {(modalData.assetType === 'MF' || modalData.assetType === 'E') &&
                ['Buy $', 'Sell $', 'Sell Share'].includes(data.action) && (
                  <tr style={styles.tabletr}>
                    <td style={styles.tabletdField}>{getQuantityLabel()}</td>
                    <td style={styles.tabletdValue}>
                      {getQuantityLabel() === 'Qty $'
                        ? '$' + formatQty(modalData.qty)
                        : modalData.qty + '%'}
                    </td>
                  </tr>
                )}

              {modalData.action === 'Buy' ||
                (modalData.action === 'Buy $' && (
                  <tr style={styles.tabletr}>
                    <td style={styles.tabletdField}>Div Rei</td>
                    <td style={styles.tabletdValue}>{getBoolean(modalData.divRei)}</td>
                  </tr>
                ))}

              {modalData.assetType === 'E' ||
                (modalData.assetType === 'O' && (
                  <tr style={styles.tabletr}>
                    <td style={styles.tabletdField}>Timing:</td>
                    <td style={styles.tabletdValue}>
                      {getOrderTypeName(modalData.orderType)}
                    </td>
                  </tr>
                ))}

              {/* OPTION FIELDS */}
              {modalData.assetType === 'O' && (
                <tr>
                  <td style={styles.tabletdField}></td>
                  <td style={styles.tabletdValue}></td>
                </tr>
              )}

              {modalData.assetType === 'O' && (
                <tr style={styles.tabletr}>
                  <td style={styles.tabletdField}>Expiration Date:</td>
                  <td style={styles.tabletdValue}>
                    {dateTimeString(modalData.expirationDate)}
                  </td>
                </tr>
              )}

              {modalData.assetType === 'O' && (
                <tr style={styles.tabletr}>
                  <td style={styles.tabletdField}>Put or Call:</td>
                  <td style={styles.tabletdValue}>{getPutCall(modalData.putCall)}</td>
                </tr>
              )}

              {modalData.assetType === 'O' && (
                <tr style={styles.tabletr}>
                  <td style={styles.tabletdField}>Strike Price:</td>
                  <td style={styles.tabletdValue}>${modalData.strikePrice}</td>
                </tr>
              )}

              {modalData.orderType !== 'M' && (
                <tr>
                  <td style={styles.tabletdField}></td>
                  <td style={styles.tabletdValue}></td>
                </tr>
              )}

              {/* TIMING PRICE AND LIMIT */}
              {(modalData.orderType === 'SL' || modalData.orderType === 'S') && (
                <tr style={styles.tabletr}>
                  <td style={styles.tabletdField}>Stop Price: </td>
                  <td style={styles.tabletdValue}>${formatQty(modalData.stopPrice)}</td>
                </tr>
              )}

              {(modalData.orderType === 'L' || modalData.orderType === 'SL') && (
                <tr style={styles.tabletr}>
                  <td style={styles.tabletdField}>Limit Price: </td>
                  <td style={styles.tabletdValue}>${formatQty(modalData.limitPrice)}</td>
                </tr>
              )}

              {(modalData.orderType === 'TS%' || modalData.orderType === 'TS$') && (
                <tr style={styles.tabletr}>
                  <td style={styles.tabletdField}>
                    Trailing Stop - {modalData.orderType === 'TS%' ? '%' : '$'}:{' '}
                  </td>
                  <td style={styles.tabletdValue}>
                    {modalData.orderType === 'TS%' ? '' : '$'}
                    {modalData.trailingStop}
                  </td>
                </tr>
              )}

              {modalData.assetType &&
                modalData.assetType !== 'F' &&
                modalData.orderType !== 'M' && (
                  <tr style={styles.tabletr}>
                    <td style={styles.tabletdField}>Time Limit: </td>
                    <td style={styles.tabletdValue}>{getTimeLimit(modalData.tif)}</td>
                  </tr>
                )}

              <tr>
                <td style={styles.tabletdField}></td>
                <td style={styles.tabletdValue}></td>
              </tr>

              <tr style={styles.tabletr}>
                <td style={styles.tabletdField}>Notes to Trader: </td>
                <td style={styles.tabletdValue}>{modalData.userNote}</td>
              </tr>

              {modalData.assetType && modalData.assetType === 'E' && (
                <tr style={styles.tabletr}>
                  <td style={styles.tabletdField}>Special Instructions: </td>
                  <td style={styles.tabletdValue}>{modalData.settlementType}</td>
                </tr>
              )}
            </table>
          </div>
          <div className={classes.modalFooter}>
            <div style={{ marginLeft: '10px' }}>
              <Button
                disabled={loading}
                loading={loading}
                label={loading ? 'Saving...' : 'Confirm'}
                onClick={handleSave}
              />
            </div>
            <div style={{ marginLeft: '10px' }}>
              <Button
                loading={loading}
                label={'Close'}
                onClick={() => {
                  close();
                }}
              />
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
