import React from 'react';
import { useConfirm } from 'material-ui-confirm';
import { IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import ClientGrid from '../../../../components/ClientGrid/ClientGrid';
import ClientGrid2 from '../../../../components/ClientGrid/ClientGrid2';
import Table, { columnType } from '../../../../components/ClearingTable/Table';
import Text from '../../../../components/Typography/Text';
import Button from '../../../../components/Buttons/Button';
import { pendingOrderEntryColumns } from '../../../../lib/data/columns';
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from '../../../../components/Messages/Notification';
import QueryParam from '../../../../services/QueryParamService';
import {
  deleteOrderEntry,
  getListOrdersByID,
  listPendingOrderEntry,
  listPendingBatchOrders,
  moveToTrades,
} from '../../../../services/OrderEntryService';
import OrderEntryMovePreviewModal from './OrderEntryMovePreviewModal';
import {
  CustomBodyRenderCurrency,
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderDate,
  CustomBodyRenderDateTime,
  CustomBodyRenderBoolean,
  CustomBodyRenderAlign,
  CustomBodyRenderHtmlString,
  CustomBodyRenderCenter,
  CustomBodyRenderMissing,
  CustomBodyRenderQty,
} from '../../../../components/Table/CustomBodyRender';

export default function PendingTable(props) {
  const confirm = useConfirm();
  const { setIsViewPending } = props;
  const [loading, setLoading] = React.useState(false);
  const [selectedRowsIds, setSelectedRowsIds] = React.useState([]);
  const [tradeRequestsList] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [openMovePreview, setOpenMovePreview] = React.useState(false);
  const [selectedOrderEntriesData, setSelectedOrderEntriesData] = React.useState([]);
  const columns2 = [
    {
      id: 'checkbox',
      visible: true,
      pinned: true,
      width: '54px',
      sortable: false,
    },
    {
      id: '',
      label: '',
      width: '40px',
      cellRenderer: ({ tableManager, value, data, column, colIndex, rowIndex }) => {
        return (
          <div>
            <div style={{ padding: 5 }}>
              <IconButton aria-label="delete" onClick={() => handleDeletePending(data)}>
                <DeleteIcon style={{ height: 24, width: 24 }} />
              </IconButton>
            </div>
          </div>
        );
      },
      sortable: false,
    },
    ...pendingOrderEntryColumns,
  ];

  const columns = [
    {
      name: 'actions',
      label: 'Actions',
      sort: false,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
              <div style={{ padding: 5 }}>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDeletePending(rows[dataIndex])}
                >
                  <DeleteIcon style={{ height: 24, width: 24 }} />
                </IconButton>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'orderEntryId',
      label: 'Row',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      },
    },
    {
      name: 'accountNo',
      label: 'Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      },
    },
    {
      name: 'accountName',
      label: 'Account Name',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      },
    },
    {
      name: 'trnsCount',
      label: 'TRNS Count',
      options: {
        customBodyRender: CustomBodyRenderQty,
      },
      addFooter: true,
      type: columnType.quantity,
    },
    {
      name: 'amt',
      label: 'Amt',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: columnType.amount,
    },
    {
      name: 'sell',
      label: 'Sell',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
    },
    {
      name: 'buy',
      label: 'Buy',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: columnType.amount,
    },
    {
      name: 'note',
      label: 'Note',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      },
    },
    {
      name: 'batchNo',
      label: 'Batch No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      },
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      },
    },
    {
      name: 'modifiedDate',
      label: 'Modified Date',
      options: {
        customBodyRender: CustomBodyRenderDateTime,
      },
    },
    {
      name: 'createdBy',
      label: 'Created By',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      },
    },
  ];

  const options = {
    responsive: 'standard',
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      setSelectedRowsIds(rowsSelected);
    },
    rowsSelected: selectedRowsIds,
  };

  React.useEffect(() => {
    handleRefresh(true);
  }, []);

  const handleViewOrders = async (hideAlert) => {
    QueryParam.set({ isViewPending: false });
    setIsViewPending(false);
  };

  const getCsvData = async () => {
    const data = await listPendingOrderEntry();
    return data.orderEntriesList;
  };

  const handleRefresh = async (hideAlert) => {
    try {
      setSelectedRowsIds([]);
      setLoading(true);

      const data = await listPendingOrderEntry();
      setRows(data.orderEntriesList);
      if (!hideAlert) {
        notifyInfo(
          data.orderEntriesList.length > 0
            ? data.orderEntriesList.length + ' search result(s)'
            : 'No pending records found.'
        );
      }
    } catch (error) {
      setLoading(false);
      notifyError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePending = async (data) => {
    let count = selectedRowsIds.length || 1;

    confirm({
      title: 'Delete',
      description: `Are you sure you want to delete ${count} pending items(s)?`,
      confirmationText: 'Yes, Delete',
      cancellationText: 'No, Cancel',
    })
      .then(async () => {
        try {
          setLoading(true);

          if (data) {
            await deleteOrderEntry(data.orderEntryId);
            notifySuccess('Pending order entry has been deleted.');
          } else {
            for (let index = 0; index < selectedRowsIds.length; index++) {
              const dataIndex = selectedRowsIds[index];
              await deleteOrderEntry(dataIndex);
            }

            notifySuccess(
              `${selectedRowsIds.length} pending order entry item(s) was deleted.`
            );
          }

          handleRefresh(true);
        } catch (err) {
          notifyError(err.message);
        } finally {
          setLoading(false);
        }
      })
      .catch(() => {
        console.error('error');
      });
  };

  const handleMoveToTrades = async () => {
    let batchOrders = {
      accountNos: [],
      batchNos: [],
    };

    for (let index = 0; index < selectedRowsIds.length; index++) {
      batchOrders.accountNos.push(rows[selectedRowsIds[index]].accountNo);
      batchOrders.batchNos.push(rows[selectedRowsIds[index]].batchNo);
    }

    const data = await listPendingBatchOrders({
      accountNo: batchOrders.accountNos,
      batchNo: batchOrders.batchNos,
    });

    setSelectedOrderEntriesData(data.orderEntriesList);
    setOpenMovePreview(true);
  };

  const handleMoveToTradesClose = async () => {
    try {
      setLoading(true);

      tradeRequestsList.splice(0);
      const tradeParam = {
        batchNos: [],
        accountNos: [],
      };

      for (let index = 0; index < selectedRowsIds.length; index++) {
        tradeParam.batchNos.push(rows[selectedRowsIds[index]].batchNo);
        tradeParam.accountNos.push(rows[selectedRowsIds[index]].accountNo);
        tradeRequestsList.push(rows[selectedRowsIds[index]]);
      }

      await moveToTrades({
        batchNo: tradeParam.batchNos,
        accountNo: tradeParam.accountNos,
        tradeRequests: tradeRequestsList,
      });

      setSelectedRowsIds([]);
      notifySuccess(`${selectedRowsIds.length} order entry item(s) was moved to trades.`);
      setOpenMovePreview(false);
      handleRefresh(true);
    } catch (err) {
      notifyError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Pending Orders" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading}
              loading={loading}
              label={loading ? 'Searching...' : 'Refresh'}
              onClick={() => handleRefresh(false)}
            />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading}
              loading={loading}
              label={loading ? 'Searching...' : 'View Trades'}
              onClick={handleViewOrders}
            />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={selectedRowsIds.length ? loading.save : true}
              label="Delete"
              onClick={() => handleDeletePending()}
            />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={selectedRowsIds.length ? loading.save : true}
              label="Move to Trades"
              onClick={() => handleMoveToTrades()}
            />
          </div>
        </div>

        <div className="mt-20">
          {/* <ClientGrid
            title="Pending Orders"
            rowIdField="orderEntryId"
            columns={columns2}
            rows={rows}
            getCsvData={getCsvData}
            isLoading={loading}
            onSelectedRowsChange={(selectedRowsIds) =>
              setSelectedRowsIds(selectedRowsIds)
            }
            selectedRowsIds={selectedRowsIds}
          /> */}
          {/* <ClientGrid2
            title="Pending Orders"
            data={rows}
            columns={columns}
            options={options}
          /> */}
          <Table
            title={'Pending Orders'}
            data={rows}
            columns={columns}
            options={options}
          />
        </div>

        {openMovePreview && (
          <OrderEntryMovePreviewModal
            orderEntries={selectedOrderEntriesData}
            count={selectedRowsIds.length}
            open={openMovePreview}
            close={() => {
              setOpenMovePreview(false);
            }}
            loading={loading}
            handleClose={handleMoveToTradesClose}
            isPending
          ></OrderEntryMovePreviewModal>
        )}
      </div>
    </React.Fragment>
  );
}
