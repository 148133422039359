// source: proto/accountpb/owner.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var proto_accountpb_address_pb = require('../../proto/accountpb/address_pb.js');
goog.object.extend(proto, proto_accountpb_address_pb);
var proto_accountpb_contactinfo_pb = require('../../proto/accountpb/contactinfo_pb.js');
goog.object.extend(proto, proto_accountpb_contactinfo_pb);
var proto_accountpb_identification_pb = require('../../proto/accountpb/identification_pb.js');
goog.object.extend(proto, proto_accountpb_identification_pb);
goog.exportSymbol('proto.accountpb.BatchCreateOwnerRequest', null, global);
goog.exportSymbol('proto.accountpb.BatchCreateOwnerResponse', null, global);
goog.exportSymbol('proto.accountpb.CreateOwnerResponse', null, global);
goog.exportSymbol('proto.accountpb.DeleteOwnerIdRequest', null, global);
goog.exportSymbol('proto.accountpb.DeleteOwnerIdResponse', null, global);
goog.exportSymbol('proto.accountpb.DeleteOwnerRequest', null, global);
goog.exportSymbol('proto.accountpb.ListOwnerAuditRequest', null, global);
goog.exportSymbol('proto.accountpb.ListOwnerAuditResponse', null, global);
goog.exportSymbol('proto.accountpb.ListOwnerAutoFillRequest', null, global);
goog.exportSymbol('proto.accountpb.ListOwnerAutoFillResponse', null, global);
goog.exportSymbol('proto.accountpb.Owner', null, global);
goog.exportSymbol('proto.accountpb.OwnerAutoFill', null, global);
goog.exportSymbol('proto.accountpb.OwnerDateObject', null, global);
goog.exportSymbol('proto.accountpb.ReadOwnerRequest', null, global);
goog.exportSymbol('proto.accountpb.ReadOwnerResponse', null, global);
goog.exportSymbol('proto.accountpb.UpdateOwnerResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.Owner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.accountpb.Owner.repeatedFields_, null);
};
goog.inherits(proto.accountpb.Owner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.Owner.displayName = 'proto.accountpb.Owner';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.OwnerDateObject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.OwnerDateObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.OwnerDateObject.displayName = 'proto.accountpb.OwnerDateObject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.CreateOwnerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.CreateOwnerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.CreateOwnerResponse.displayName = 'proto.accountpb.CreateOwnerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.UpdateOwnerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.UpdateOwnerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.UpdateOwnerResponse.displayName = 'proto.accountpb.UpdateOwnerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.DeleteOwnerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.DeleteOwnerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.DeleteOwnerRequest.displayName = 'proto.accountpb.DeleteOwnerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.DeleteOwnerIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.DeleteOwnerIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.DeleteOwnerIdRequest.displayName = 'proto.accountpb.DeleteOwnerIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.DeleteOwnerIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.DeleteOwnerIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.DeleteOwnerIdResponse.displayName = 'proto.accountpb.DeleteOwnerIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.BatchCreateOwnerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.accountpb.BatchCreateOwnerRequest.repeatedFields_, null);
};
goog.inherits(proto.accountpb.BatchCreateOwnerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.BatchCreateOwnerRequest.displayName = 'proto.accountpb.BatchCreateOwnerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.BatchCreateOwnerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.accountpb.BatchCreateOwnerResponse.repeatedFields_, null);
};
goog.inherits(proto.accountpb.BatchCreateOwnerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.BatchCreateOwnerResponse.displayName = 'proto.accountpb.BatchCreateOwnerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.OwnerAutoFill = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.OwnerAutoFill, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.OwnerAutoFill.displayName = 'proto.accountpb.OwnerAutoFill';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ListOwnerAutoFillRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ListOwnerAutoFillRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ListOwnerAutoFillRequest.displayName = 'proto.accountpb.ListOwnerAutoFillRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ListOwnerAutoFillResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.accountpb.ListOwnerAutoFillResponse.repeatedFields_, null);
};
goog.inherits(proto.accountpb.ListOwnerAutoFillResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ListOwnerAutoFillResponse.displayName = 'proto.accountpb.ListOwnerAutoFillResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ReadOwnerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ReadOwnerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ReadOwnerRequest.displayName = 'proto.accountpb.ReadOwnerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ReadOwnerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ReadOwnerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ReadOwnerResponse.displayName = 'proto.accountpb.ReadOwnerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ListOwnerAuditRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ListOwnerAuditRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ListOwnerAuditRequest.displayName = 'proto.accountpb.ListOwnerAuditRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ListOwnerAuditResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.accountpb.ListOwnerAuditResponse.repeatedFields_, null);
};
goog.inherits(proto.accountpb.ListOwnerAuditResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ListOwnerAuditResponse.displayName = 'proto.accountpb.ListOwnerAuditResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.accountpb.Owner.repeatedFields_ = [48,49,50];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.Owner.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.Owner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.Owner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.Owner.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    ownerType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    middleName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 8, ""),
    dateOfBirth: (f = msg.getDateOfBirth()) && proto.accountpb.OwnerDateObject.toObject(includeInstance, f),
    country: jspb.Message.getFieldWithDefault(msg, 10, ""),
    employmentStatus: jspb.Message.getFieldWithDefault(msg, 11, ""),
    employmentOccupation: jspb.Message.getFieldWithDefault(msg, 12, ""),
    employmentName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    ownership: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    stockOwnership: jspb.Message.getFieldWithDefault(msg, 15, ""),
    stockCompany: jspb.Message.getFieldWithDefault(msg, 16, ""),
    stockPosition: jspb.Message.getFieldWithDefault(msg, 17, ""),
    stockPerson: jspb.Message.getFieldWithDefault(msg, 18, ""),
    brokerMember: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    brokerName: jspb.Message.getFieldWithDefault(msg, 20, ""),
    affiliatePerson: jspb.Message.getFieldWithDefault(msg, 21, ""),
    affiliatePersonPosition: jspb.Message.getFieldWithDefault(msg, 22, ""),
    modifiedAt: jspb.Message.getFieldWithDefault(msg, 23, ""),
    modifiedBy: jspb.Message.getFieldWithDefault(msg, 24, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 25, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 26, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 27, ""),
    citizenship: jspb.Message.getFieldWithDefault(msg, 28, ""),
    ofacStatus: jspb.Message.getFieldWithDefault(msg, 29, ""),
    ofacDate: (f = msg.getOfacDate()) && proto.accountpb.OwnerDateObject.toObject(includeInstance, f),
    kycStatus: jspb.Message.getFieldWithDefault(msg, 31, ""),
    kycDate: (f = msg.getKycDate()) && proto.accountpb.OwnerDateObject.toObject(includeInstance, f),
    officer: jspb.Message.getBooleanFieldWithDefault(msg, 33, false),
    owner: jspb.Message.getBooleanFieldWithDefault(msg, 34, false),
    ownershipPercent: jspb.Message.getFieldWithDefault(msg, 35, ""),
    piiAccess: jspb.Message.getBooleanFieldWithDefault(msg, 36, false),
    webTermConditions: jspb.Message.getFieldWithDefault(msg, 37, ""),
    disclosureStatement: jspb.Message.getFieldWithDefault(msg, 38, ""),
    feeSchedule: jspb.Message.getFieldWithDefault(msg, 39, ""),
    investmentObjectiveCode: jspb.Message.getFieldWithDefault(msg, 40, ""),
    investmentObjectiveDate: (f = msg.getInvestmentObjectiveDate()) && proto.accountpb.OwnerDateObject.toObject(includeInstance, f),
    relationship: jspb.Message.getFieldWithDefault(msg, 42, ""),
    maritalStatus: jspb.Message.getFieldWithDefault(msg, 43, ""),
    natureOfBusiness: jspb.Message.getFieldWithDefault(msg, 44, ""),
    yearsEmployed: jspb.Message.getFieldWithDefault(msg, 45, 0),
    traderId: jspb.Message.getFieldWithDefault(msg, 46, ""),
    inactiveStatusReason: jspb.Message.getFieldWithDefault(msg, 47, ""),
    addressesList: jspb.Message.toObjectList(msg.getAddressesList(),
    proto_accountpb_address_pb.Address.toObject, includeInstance),
    contactInfosList: jspb.Message.toObjectList(msg.getContactInfosList(),
    proto_accountpb_contactinfo_pb.ContactInfo.toObject, includeInstance),
    identificationsList: jspb.Message.toObjectList(msg.getIdentificationsList(),
    proto_accountpb_identification_pb.Identification.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.Owner}
 */
proto.accountpb.Owner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.Owner;
  return proto.accountpb.Owner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.Owner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.Owner}
 */
proto.accountpb.Owner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOwnerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddleName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 9:
      var value = new proto.accountpb.OwnerDateObject;
      reader.readMessage(value,proto.accountpb.OwnerDateObject.deserializeBinaryFromReader);
      msg.setDateOfBirth(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmploymentStatus(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmploymentOccupation(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmploymentName(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOwnership(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setStockOwnership(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setStockCompany(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setStockPosition(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setStockPerson(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBrokerMember(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrokerName(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setAffiliatePerson(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setAffiliatePersonPosition(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifiedAt(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifiedBy(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setCitizenship(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfacStatus(value);
      break;
    case 30:
      var value = new proto.accountpb.OwnerDateObject;
      reader.readMessage(value,proto.accountpb.OwnerDateObject.deserializeBinaryFromReader);
      msg.setOfacDate(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setKycStatus(value);
      break;
    case 32:
      var value = new proto.accountpb.OwnerDateObject;
      reader.readMessage(value,proto.accountpb.OwnerDateObject.deserializeBinaryFromReader);
      msg.setKycDate(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOfficer(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOwner(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnershipPercent(value);
      break;
    case 36:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPiiAccess(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebTermConditions(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisclosureStatement(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeSchedule(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvestmentObjectiveCode(value);
      break;
    case 41:
      var value = new proto.accountpb.OwnerDateObject;
      reader.readMessage(value,proto.accountpb.OwnerDateObject.deserializeBinaryFromReader);
      msg.setInvestmentObjectiveDate(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setRelationship(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaritalStatus(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setNatureOfBusiness(value);
      break;
    case 45:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYearsEmployed(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.setTraderId(value);
      break;
    case 47:
      var value = /** @type {string} */ (reader.readString());
      msg.setInactiveStatusReason(value);
      break;
    case 48:
      var value = new proto_accountpb_address_pb.Address;
      reader.readMessage(value,proto_accountpb_address_pb.Address.deserializeBinaryFromReader);
      msg.addAddresses(value);
      break;
    case 49:
      var value = new proto_accountpb_contactinfo_pb.ContactInfo;
      reader.readMessage(value,proto_accountpb_contactinfo_pb.ContactInfo.deserializeBinaryFromReader);
      msg.addContactInfos(value);
      break;
    case 50:
      var value = new proto_accountpb_identification_pb.Identification;
      reader.readMessage(value,proto_accountpb_identification_pb.Identification.deserializeBinaryFromReader);
      msg.addIdentifications(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.Owner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.Owner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.Owner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.Owner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getOwnerType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMiddleName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDateOfBirth();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.accountpb.OwnerDateObject.serializeBinaryToWriter
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getEmploymentStatus();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getEmploymentOccupation();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getEmploymentName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getOwnership();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getStockOwnership();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getStockCompany();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getStockPosition();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getStockPerson();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getBrokerMember();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getBrokerName();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getAffiliatePerson();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getAffiliatePersonPosition();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getModifiedAt();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getModifiedBy();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getCitizenship();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getOfacStatus();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getOfacDate();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.accountpb.OwnerDateObject.serializeBinaryToWriter
    );
  }
  f = message.getKycStatus();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getKycDate();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      proto.accountpb.OwnerDateObject.serializeBinaryToWriter
    );
  }
  f = message.getOfficer();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
  f = message.getOwner();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
  f = message.getOwnershipPercent();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getPiiAccess();
  if (f) {
    writer.writeBool(
      36,
      f
    );
  }
  f = message.getWebTermConditions();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getDisclosureStatement();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getFeeSchedule();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getInvestmentObjectiveCode();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getInvestmentObjectiveDate();
  if (f != null) {
    writer.writeMessage(
      41,
      f,
      proto.accountpb.OwnerDateObject.serializeBinaryToWriter
    );
  }
  f = message.getRelationship();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getMaritalStatus();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getNatureOfBusiness();
  if (f.length > 0) {
    writer.writeString(
      44,
      f
    );
  }
  f = message.getYearsEmployed();
  if (f !== 0) {
    writer.writeInt32(
      45,
      f
    );
  }
  f = message.getTraderId();
  if (f.length > 0) {
    writer.writeString(
      46,
      f
    );
  }
  f = message.getInactiveStatusReason();
  if (f.length > 0) {
    writer.writeString(
      47,
      f
    );
  }
  f = message.getAddressesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      48,
      f,
      proto_accountpb_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getContactInfosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      49,
      f,
      proto_accountpb_contactinfo_pb.ContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getIdentificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      50,
      f,
      proto_accountpb_identification_pb.Identification.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 owner_id = 1;
 * @return {number}
 */
proto.accountpb.Owner.prototype.getOwnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 account_id = 2;
 * @return {number}
 */
proto.accountpb.Owner.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string owner_type = 3;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getOwnerType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setOwnerType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string first_name = 4;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string middle_name = 5;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getMiddleName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setMiddleName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string last_name = 6;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string status = 7;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string created_by = 8;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional OwnerDateObject date_of_birth = 9;
 * @return {?proto.accountpb.OwnerDateObject}
 */
proto.accountpb.Owner.prototype.getDateOfBirth = function() {
  return /** @type{?proto.accountpb.OwnerDateObject} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.OwnerDateObject, 9));
};


/**
 * @param {?proto.accountpb.OwnerDateObject|undefined} value
 * @return {!proto.accountpb.Owner} returns this
*/
proto.accountpb.Owner.prototype.setDateOfBirth = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.clearDateOfBirth = function() {
  return this.setDateOfBirth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.Owner.prototype.hasDateOfBirth = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string country = 10;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string employment_status = 11;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getEmploymentStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setEmploymentStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string employment_occupation = 12;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getEmploymentOccupation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setEmploymentOccupation = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string employment_name = 13;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getEmploymentName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setEmploymentName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bool ownership = 14;
 * @return {boolean}
 */
proto.accountpb.Owner.prototype.getOwnership = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setOwnership = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional string stock_ownership = 15;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getStockOwnership = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setStockOwnership = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string stock_company = 16;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getStockCompany = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setStockCompany = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string stock_position = 17;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getStockPosition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setStockPosition = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string stock_person = 18;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getStockPerson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setStockPerson = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional bool broker_member = 19;
 * @return {boolean}
 */
proto.accountpb.Owner.prototype.getBrokerMember = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setBrokerMember = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional string broker_name = 20;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getBrokerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setBrokerName = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string affiliate_person = 21;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getAffiliatePerson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setAffiliatePerson = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string affiliate_person_position = 22;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getAffiliatePersonPosition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setAffiliatePersonPosition = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string modified_at = 23;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getModifiedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setModifiedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string modified_by = 24;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getModifiedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setModifiedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string created_at = 25;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string correspondent = 26;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string account_no = 27;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string citizenship = 28;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getCitizenship = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setCitizenship = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string ofac_status = 29;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getOfacStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setOfacStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional OwnerDateObject ofac_date = 30;
 * @return {?proto.accountpb.OwnerDateObject}
 */
proto.accountpb.Owner.prototype.getOfacDate = function() {
  return /** @type{?proto.accountpb.OwnerDateObject} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.OwnerDateObject, 30));
};


/**
 * @param {?proto.accountpb.OwnerDateObject|undefined} value
 * @return {!proto.accountpb.Owner} returns this
*/
proto.accountpb.Owner.prototype.setOfacDate = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.clearOfacDate = function() {
  return this.setOfacDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.Owner.prototype.hasOfacDate = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional string kyc_status = 31;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getKycStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setKycStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional OwnerDateObject kyc_date = 32;
 * @return {?proto.accountpb.OwnerDateObject}
 */
proto.accountpb.Owner.prototype.getKycDate = function() {
  return /** @type{?proto.accountpb.OwnerDateObject} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.OwnerDateObject, 32));
};


/**
 * @param {?proto.accountpb.OwnerDateObject|undefined} value
 * @return {!proto.accountpb.Owner} returns this
*/
proto.accountpb.Owner.prototype.setKycDate = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.clearKycDate = function() {
  return this.setKycDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.Owner.prototype.hasKycDate = function() {
  return jspb.Message.getField(this, 32) != null;
};


/**
 * optional bool officer = 33;
 * @return {boolean}
 */
proto.accountpb.Owner.prototype.getOfficer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 33, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setOfficer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 33, value);
};


/**
 * optional bool owner = 34;
 * @return {boolean}
 */
proto.accountpb.Owner.prototype.getOwner = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setOwner = function(value) {
  return jspb.Message.setProto3BooleanField(this, 34, value);
};


/**
 * optional string ownership_percent = 35;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getOwnershipPercent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setOwnershipPercent = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional bool pii_access = 36;
 * @return {boolean}
 */
proto.accountpb.Owner.prototype.getPiiAccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 36, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setPiiAccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 36, value);
};


/**
 * optional string web_term_conditions = 37;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getWebTermConditions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setWebTermConditions = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string disclosure_statement = 38;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getDisclosureStatement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setDisclosureStatement = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional string fee_schedule = 39;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getFeeSchedule = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setFeeSchedule = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string investment_objective_code = 40;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getInvestmentObjectiveCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setInvestmentObjectiveCode = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional OwnerDateObject investment_objective_date = 41;
 * @return {?proto.accountpb.OwnerDateObject}
 */
proto.accountpb.Owner.prototype.getInvestmentObjectiveDate = function() {
  return /** @type{?proto.accountpb.OwnerDateObject} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.OwnerDateObject, 41));
};


/**
 * @param {?proto.accountpb.OwnerDateObject|undefined} value
 * @return {!proto.accountpb.Owner} returns this
*/
proto.accountpb.Owner.prototype.setInvestmentObjectiveDate = function(value) {
  return jspb.Message.setWrapperField(this, 41, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.clearInvestmentObjectiveDate = function() {
  return this.setInvestmentObjectiveDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.Owner.prototype.hasInvestmentObjectiveDate = function() {
  return jspb.Message.getField(this, 41) != null;
};


/**
 * optional string relationship = 42;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getRelationship = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setRelationship = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional string marital_status = 43;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getMaritalStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setMaritalStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * optional string nature_of_business = 44;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getNatureOfBusiness = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setNatureOfBusiness = function(value) {
  return jspb.Message.setProto3StringField(this, 44, value);
};


/**
 * optional int32 years_employed = 45;
 * @return {number}
 */
proto.accountpb.Owner.prototype.getYearsEmployed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 45, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setYearsEmployed = function(value) {
  return jspb.Message.setProto3IntField(this, 45, value);
};


/**
 * optional string trader_id = 46;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getTraderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 46, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setTraderId = function(value) {
  return jspb.Message.setProto3StringField(this, 46, value);
};


/**
 * optional string inactive_status_reason = 47;
 * @return {string}
 */
proto.accountpb.Owner.prototype.getInactiveStatusReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 47, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.setInactiveStatusReason = function(value) {
  return jspb.Message.setProto3StringField(this, 47, value);
};


/**
 * repeated Address addresses = 48;
 * @return {!Array<!proto.accountpb.Address>}
 */
proto.accountpb.Owner.prototype.getAddressesList = function() {
  return /** @type{!Array<!proto.accountpb.Address>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_accountpb_address_pb.Address, 48));
};


/**
 * @param {!Array<!proto.accountpb.Address>} value
 * @return {!proto.accountpb.Owner} returns this
*/
proto.accountpb.Owner.prototype.setAddressesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 48, value);
};


/**
 * @param {!proto.accountpb.Address=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.Address}
 */
proto.accountpb.Owner.prototype.addAddresses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 48, opt_value, proto.accountpb.Address, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.clearAddressesList = function() {
  return this.setAddressesList([]);
};


/**
 * repeated ContactInfo contact_infos = 49;
 * @return {!Array<!proto.accountpb.ContactInfo>}
 */
proto.accountpb.Owner.prototype.getContactInfosList = function() {
  return /** @type{!Array<!proto.accountpb.ContactInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_accountpb_contactinfo_pb.ContactInfo, 49));
};


/**
 * @param {!Array<!proto.accountpb.ContactInfo>} value
 * @return {!proto.accountpb.Owner} returns this
*/
proto.accountpb.Owner.prototype.setContactInfosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 49, value);
};


/**
 * @param {!proto.accountpb.ContactInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.ContactInfo}
 */
proto.accountpb.Owner.prototype.addContactInfos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 49, opt_value, proto.accountpb.ContactInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.clearContactInfosList = function() {
  return this.setContactInfosList([]);
};


/**
 * repeated Identification identifications = 50;
 * @return {!Array<!proto.accountpb.Identification>}
 */
proto.accountpb.Owner.prototype.getIdentificationsList = function() {
  return /** @type{!Array<!proto.accountpb.Identification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_accountpb_identification_pb.Identification, 50));
};


/**
 * @param {!Array<!proto.accountpb.Identification>} value
 * @return {!proto.accountpb.Owner} returns this
*/
proto.accountpb.Owner.prototype.setIdentificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 50, value);
};


/**
 * @param {!proto.accountpb.Identification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.Identification}
 */
proto.accountpb.Owner.prototype.addIdentifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 50, opt_value, proto.accountpb.Identification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.Owner} returns this
 */
proto.accountpb.Owner.prototype.clearIdentificationsList = function() {
  return this.setIdentificationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.OwnerDateObject.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.OwnerDateObject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.OwnerDateObject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.OwnerDateObject.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0),
    day: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.OwnerDateObject}
 */
proto.accountpb.OwnerDateObject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.OwnerDateObject;
  return proto.accountpb.OwnerDateObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.OwnerDateObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.OwnerDateObject}
 */
proto.accountpb.OwnerDateObject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.OwnerDateObject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.OwnerDateObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.OwnerDateObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.OwnerDateObject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 year = 1;
 * @return {number}
 */
proto.accountpb.OwnerDateObject.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.OwnerDateObject} returns this
 */
proto.accountpb.OwnerDateObject.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 month = 2;
 * @return {number}
 */
proto.accountpb.OwnerDateObject.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.OwnerDateObject} returns this
 */
proto.accountpb.OwnerDateObject.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 day = 3;
 * @return {number}
 */
proto.accountpb.OwnerDateObject.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.OwnerDateObject} returns this
 */
proto.accountpb.OwnerDateObject.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.CreateOwnerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.CreateOwnerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.CreateOwnerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.CreateOwnerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    owner: (f = msg.getOwner()) && proto.accountpb.Owner.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.CreateOwnerResponse}
 */
proto.accountpb.CreateOwnerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.CreateOwnerResponse;
  return proto.accountpb.CreateOwnerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.CreateOwnerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.CreateOwnerResponse}
 */
proto.accountpb.CreateOwnerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Owner;
      reader.readMessage(value,proto.accountpb.Owner.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.CreateOwnerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.CreateOwnerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.CreateOwnerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.CreateOwnerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.Owner.serializeBinaryToWriter
    );
  }
};


/**
 * optional Owner owner = 1;
 * @return {?proto.accountpb.Owner}
 */
proto.accountpb.CreateOwnerResponse.prototype.getOwner = function() {
  return /** @type{?proto.accountpb.Owner} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.Owner, 1));
};


/**
 * @param {?proto.accountpb.Owner|undefined} value
 * @return {!proto.accountpb.CreateOwnerResponse} returns this
*/
proto.accountpb.CreateOwnerResponse.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.CreateOwnerResponse} returns this
 */
proto.accountpb.CreateOwnerResponse.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.CreateOwnerResponse.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.UpdateOwnerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.UpdateOwnerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.UpdateOwnerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.UpdateOwnerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    owner: (f = msg.getOwner()) && proto.accountpb.Owner.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.UpdateOwnerResponse}
 */
proto.accountpb.UpdateOwnerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.UpdateOwnerResponse;
  return proto.accountpb.UpdateOwnerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.UpdateOwnerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.UpdateOwnerResponse}
 */
proto.accountpb.UpdateOwnerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Owner;
      reader.readMessage(value,proto.accountpb.Owner.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.UpdateOwnerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.UpdateOwnerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.UpdateOwnerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.UpdateOwnerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.Owner.serializeBinaryToWriter
    );
  }
};


/**
 * optional Owner owner = 1;
 * @return {?proto.accountpb.Owner}
 */
proto.accountpb.UpdateOwnerResponse.prototype.getOwner = function() {
  return /** @type{?proto.accountpb.Owner} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.Owner, 1));
};


/**
 * @param {?proto.accountpb.Owner|undefined} value
 * @return {!proto.accountpb.UpdateOwnerResponse} returns this
*/
proto.accountpb.UpdateOwnerResponse.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.UpdateOwnerResponse} returns this
 */
proto.accountpb.UpdateOwnerResponse.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.UpdateOwnerResponse.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.DeleteOwnerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.DeleteOwnerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.DeleteOwnerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteOwnerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.DeleteOwnerRequest}
 */
proto.accountpb.DeleteOwnerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.DeleteOwnerRequest;
  return proto.accountpb.DeleteOwnerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.DeleteOwnerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.DeleteOwnerRequest}
 */
proto.accountpb.DeleteOwnerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.DeleteOwnerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.DeleteOwnerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.DeleteOwnerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteOwnerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.accountpb.DeleteOwnerRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.DeleteOwnerRequest} returns this
 */
proto.accountpb.DeleteOwnerRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.DeleteOwnerIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.DeleteOwnerIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.DeleteOwnerIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteOwnerIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.DeleteOwnerIdRequest}
 */
proto.accountpb.DeleteOwnerIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.DeleteOwnerIdRequest;
  return proto.accountpb.DeleteOwnerIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.DeleteOwnerIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.DeleteOwnerIdRequest}
 */
proto.accountpb.DeleteOwnerIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOwnerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.DeleteOwnerIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.DeleteOwnerIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.DeleteOwnerIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteOwnerIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 owner_id = 1;
 * @return {number}
 */
proto.accountpb.DeleteOwnerIdRequest.prototype.getOwnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.DeleteOwnerIdRequest} returns this
 */
proto.accountpb.DeleteOwnerIdRequest.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.DeleteOwnerIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.DeleteOwnerIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.DeleteOwnerIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteOwnerIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    owner: (f = msg.getOwner()) && proto.accountpb.Owner.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.DeleteOwnerIdResponse}
 */
proto.accountpb.DeleteOwnerIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.DeleteOwnerIdResponse;
  return proto.accountpb.DeleteOwnerIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.DeleteOwnerIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.DeleteOwnerIdResponse}
 */
proto.accountpb.DeleteOwnerIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Owner;
      reader.readMessage(value,proto.accountpb.Owner.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.DeleteOwnerIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.DeleteOwnerIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.DeleteOwnerIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteOwnerIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.Owner.serializeBinaryToWriter
    );
  }
};


/**
 * optional Owner owner = 1;
 * @return {?proto.accountpb.Owner}
 */
proto.accountpb.DeleteOwnerIdResponse.prototype.getOwner = function() {
  return /** @type{?proto.accountpb.Owner} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.Owner, 1));
};


/**
 * @param {?proto.accountpb.Owner|undefined} value
 * @return {!proto.accountpb.DeleteOwnerIdResponse} returns this
*/
proto.accountpb.DeleteOwnerIdResponse.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.DeleteOwnerIdResponse} returns this
 */
proto.accountpb.DeleteOwnerIdResponse.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.DeleteOwnerIdResponse.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.accountpb.BatchCreateOwnerRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.BatchCreateOwnerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.BatchCreateOwnerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.BatchCreateOwnerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.BatchCreateOwnerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerList: jspb.Message.toObjectList(msg.getOwnerList(),
    proto.accountpb.Owner.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.BatchCreateOwnerRequest}
 */
proto.accountpb.BatchCreateOwnerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.BatchCreateOwnerRequest;
  return proto.accountpb.BatchCreateOwnerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.BatchCreateOwnerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.BatchCreateOwnerRequest}
 */
proto.accountpb.BatchCreateOwnerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Owner;
      reader.readMessage(value,proto.accountpb.Owner.deserializeBinaryFromReader);
      msg.addOwner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.BatchCreateOwnerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.BatchCreateOwnerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.BatchCreateOwnerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.BatchCreateOwnerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.accountpb.Owner.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Owner owner = 1;
 * @return {!Array<!proto.accountpb.Owner>}
 */
proto.accountpb.BatchCreateOwnerRequest.prototype.getOwnerList = function() {
  return /** @type{!Array<!proto.accountpb.Owner>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.accountpb.Owner, 1));
};


/**
 * @param {!Array<!proto.accountpb.Owner>} value
 * @return {!proto.accountpb.BatchCreateOwnerRequest} returns this
*/
proto.accountpb.BatchCreateOwnerRequest.prototype.setOwnerList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.accountpb.Owner=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.Owner}
 */
proto.accountpb.BatchCreateOwnerRequest.prototype.addOwner = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.accountpb.Owner, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.BatchCreateOwnerRequest} returns this
 */
proto.accountpb.BatchCreateOwnerRequest.prototype.clearOwnerList = function() {
  return this.setOwnerList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.accountpb.BatchCreateOwnerResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.BatchCreateOwnerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.BatchCreateOwnerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.BatchCreateOwnerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.BatchCreateOwnerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerResponsesList: jspb.Message.toObjectList(msg.getOwnerResponsesList(),
    proto.accountpb.Owner.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.BatchCreateOwnerResponse}
 */
proto.accountpb.BatchCreateOwnerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.BatchCreateOwnerResponse;
  return proto.accountpb.BatchCreateOwnerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.BatchCreateOwnerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.BatchCreateOwnerResponse}
 */
proto.accountpb.BatchCreateOwnerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Owner;
      reader.readMessage(value,proto.accountpb.Owner.deserializeBinaryFromReader);
      msg.addOwnerResponses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.BatchCreateOwnerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.BatchCreateOwnerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.BatchCreateOwnerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.BatchCreateOwnerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.accountpb.Owner.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Owner owner_responses = 1;
 * @return {!Array<!proto.accountpb.Owner>}
 */
proto.accountpb.BatchCreateOwnerResponse.prototype.getOwnerResponsesList = function() {
  return /** @type{!Array<!proto.accountpb.Owner>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.accountpb.Owner, 1));
};


/**
 * @param {!Array<!proto.accountpb.Owner>} value
 * @return {!proto.accountpb.BatchCreateOwnerResponse} returns this
*/
proto.accountpb.BatchCreateOwnerResponse.prototype.setOwnerResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.accountpb.Owner=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.Owner}
 */
proto.accountpb.BatchCreateOwnerResponse.prototype.addOwnerResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.accountpb.Owner, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.BatchCreateOwnerResponse} returns this
 */
proto.accountpb.BatchCreateOwnerResponse.prototype.clearOwnerResponsesList = function() {
  return this.setOwnerResponsesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.OwnerAutoFill.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.OwnerAutoFill.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.OwnerAutoFill} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.OwnerAutoFill.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    owner: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.OwnerAutoFill}
 */
proto.accountpb.OwnerAutoFill.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.OwnerAutoFill;
  return proto.accountpb.OwnerAutoFill.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.OwnerAutoFill} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.OwnerAutoFill}
 */
proto.accountpb.OwnerAutoFill.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOwnerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.OwnerAutoFill.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.OwnerAutoFill.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.OwnerAutoFill} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.OwnerAutoFill.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getOwner();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 owner_id = 1;
 * @return {number}
 */
proto.accountpb.OwnerAutoFill.prototype.getOwnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.OwnerAutoFill} returns this
 */
proto.accountpb.OwnerAutoFill.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string owner = 2;
 * @return {string}
 */
proto.accountpb.OwnerAutoFill.prototype.getOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.OwnerAutoFill} returns this
 */
proto.accountpb.OwnerAutoFill.prototype.setOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ListOwnerAutoFillRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ListOwnerAutoFillRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ListOwnerAutoFillRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListOwnerAutoFillRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ListOwnerAutoFillRequest}
 */
proto.accountpb.ListOwnerAutoFillRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ListOwnerAutoFillRequest;
  return proto.accountpb.ListOwnerAutoFillRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ListOwnerAutoFillRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ListOwnerAutoFillRequest}
 */
proto.accountpb.ListOwnerAutoFillRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ListOwnerAutoFillRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ListOwnerAutoFillRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ListOwnerAutoFillRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListOwnerAutoFillRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 account_id = 2;
 * @return {number}
 */
proto.accountpb.ListOwnerAutoFillRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.ListOwnerAutoFillRequest} returns this
 */
proto.accountpb.ListOwnerAutoFillRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.accountpb.ListOwnerAutoFillResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ListOwnerAutoFillResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ListOwnerAutoFillResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ListOwnerAutoFillResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListOwnerAutoFillResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerAutoFillList: jspb.Message.toObjectList(msg.getOwnerAutoFillList(),
    proto.accountpb.OwnerAutoFill.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ListOwnerAutoFillResponse}
 */
proto.accountpb.ListOwnerAutoFillResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ListOwnerAutoFillResponse;
  return proto.accountpb.ListOwnerAutoFillResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ListOwnerAutoFillResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ListOwnerAutoFillResponse}
 */
proto.accountpb.ListOwnerAutoFillResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.OwnerAutoFill;
      reader.readMessage(value,proto.accountpb.OwnerAutoFill.deserializeBinaryFromReader);
      msg.addOwnerAutoFill(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ListOwnerAutoFillResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ListOwnerAutoFillResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ListOwnerAutoFillResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListOwnerAutoFillResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerAutoFillList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.accountpb.OwnerAutoFill.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OwnerAutoFill owner_auto_fill = 1;
 * @return {!Array<!proto.accountpb.OwnerAutoFill>}
 */
proto.accountpb.ListOwnerAutoFillResponse.prototype.getOwnerAutoFillList = function() {
  return /** @type{!Array<!proto.accountpb.OwnerAutoFill>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.accountpb.OwnerAutoFill, 1));
};


/**
 * @param {!Array<!proto.accountpb.OwnerAutoFill>} value
 * @return {!proto.accountpb.ListOwnerAutoFillResponse} returns this
*/
proto.accountpb.ListOwnerAutoFillResponse.prototype.setOwnerAutoFillList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.accountpb.OwnerAutoFill=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.OwnerAutoFill}
 */
proto.accountpb.ListOwnerAutoFillResponse.prototype.addOwnerAutoFill = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.accountpb.OwnerAutoFill, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ListOwnerAutoFillResponse} returns this
 */
proto.accountpb.ListOwnerAutoFillResponse.prototype.clearOwnerAutoFillList = function() {
  return this.setOwnerAutoFillList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ReadOwnerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ReadOwnerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ReadOwnerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadOwnerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ReadOwnerRequest}
 */
proto.accountpb.ReadOwnerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ReadOwnerRequest;
  return proto.accountpb.ReadOwnerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ReadOwnerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ReadOwnerRequest}
 */
proto.accountpb.ReadOwnerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOwnerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ReadOwnerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ReadOwnerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ReadOwnerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadOwnerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 owner_id = 1;
 * @return {number}
 */
proto.accountpb.ReadOwnerRequest.prototype.getOwnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.ReadOwnerRequest} returns this
 */
proto.accountpb.ReadOwnerRequest.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 account_id = 2;
 * @return {number}
 */
proto.accountpb.ReadOwnerRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.ReadOwnerRequest} returns this
 */
proto.accountpb.ReadOwnerRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ReadOwnerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ReadOwnerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ReadOwnerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadOwnerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    owner: (f = msg.getOwner()) && proto.accountpb.Owner.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ReadOwnerResponse}
 */
proto.accountpb.ReadOwnerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ReadOwnerResponse;
  return proto.accountpb.ReadOwnerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ReadOwnerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ReadOwnerResponse}
 */
proto.accountpb.ReadOwnerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Owner;
      reader.readMessage(value,proto.accountpb.Owner.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ReadOwnerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ReadOwnerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ReadOwnerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadOwnerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.Owner.serializeBinaryToWriter
    );
  }
};


/**
 * optional Owner owner = 1;
 * @return {?proto.accountpb.Owner}
 */
proto.accountpb.ReadOwnerResponse.prototype.getOwner = function() {
  return /** @type{?proto.accountpb.Owner} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.Owner, 1));
};


/**
 * @param {?proto.accountpb.Owner|undefined} value
 * @return {!proto.accountpb.ReadOwnerResponse} returns this
*/
proto.accountpb.ReadOwnerResponse.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.ReadOwnerResponse} returns this
 */
proto.accountpb.ReadOwnerResponse.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.ReadOwnerResponse.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ListOwnerAuditRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ListOwnerAuditRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ListOwnerAuditRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListOwnerAuditRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ListOwnerAuditRequest}
 */
proto.accountpb.ListOwnerAuditRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ListOwnerAuditRequest;
  return proto.accountpb.ListOwnerAuditRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ListOwnerAuditRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ListOwnerAuditRequest}
 */
proto.accountpb.ListOwnerAuditRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOwnerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ListOwnerAuditRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ListOwnerAuditRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ListOwnerAuditRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListOwnerAuditRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 owner_id = 1;
 * @return {number}
 */
proto.accountpb.ListOwnerAuditRequest.prototype.getOwnerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.ListOwnerAuditRequest} returns this
 */
proto.accountpb.ListOwnerAuditRequest.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.accountpb.ListOwnerAuditResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ListOwnerAuditResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ListOwnerAuditResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ListOwnerAuditResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListOwnerAuditResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerList: jspb.Message.toObjectList(msg.getOwnerList(),
    proto.accountpb.Owner.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ListOwnerAuditResponse}
 */
proto.accountpb.ListOwnerAuditResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ListOwnerAuditResponse;
  return proto.accountpb.ListOwnerAuditResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ListOwnerAuditResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ListOwnerAuditResponse}
 */
proto.accountpb.ListOwnerAuditResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Owner;
      reader.readMessage(value,proto.accountpb.Owner.deserializeBinaryFromReader);
      msg.addOwner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ListOwnerAuditResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ListOwnerAuditResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ListOwnerAuditResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListOwnerAuditResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.accountpb.Owner.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Owner owner = 1;
 * @return {!Array<!proto.accountpb.Owner>}
 */
proto.accountpb.ListOwnerAuditResponse.prototype.getOwnerList = function() {
  return /** @type{!Array<!proto.accountpb.Owner>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.accountpb.Owner, 1));
};


/**
 * @param {!Array<!proto.accountpb.Owner>} value
 * @return {!proto.accountpb.ListOwnerAuditResponse} returns this
*/
proto.accountpb.ListOwnerAuditResponse.prototype.setOwnerList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.accountpb.Owner=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.Owner}
 */
proto.accountpb.ListOwnerAuditResponse.prototype.addOwner = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.accountpb.Owner, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ListOwnerAuditResponse} returns this
 */
proto.accountpb.ListOwnerAuditResponse.prototype.clearOwnerList = function() {
  return this.setOwnerList([]);
};


goog.object.extend(exports, proto.accountpb);
