import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

export default function AlertDialog({
  maxWidth,
  fullWidth,
  open,
  toggleDialog,
  title,
  content,
  actionButtons,
}) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick') {
            toggleDialog(false)
          }
        }}
        aria-labelledby="max-width-dialog-title"
        disableEscapeKeyDown={true}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <Divider variant="middle" />
        {content === undefined ? (
          <DialogContent sx={classes.center}>
            <DialogContentText>
              Body
            </DialogContentText>
          </DialogContent>
        ) : content}
        <DialogActions sx={classes.center} pt={0}>
          {actionButtons === undefined ? (<Button fullWidth={fullWidth} onClick={() => { toggleDialog(false) }} color="primary">
            Close
          </Button>) : actionButtons}
        </DialogActions>

      </Dialog>
    </React.Fragment>
  );
}

AlertDialog.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
};

AlertDialog.defaultProps = {
  title: 'Alert',
  open: false,
  toggleDialog: () => { },
  fullWidth: true,
  maxWidth: 'xs',
};