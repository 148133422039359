// src/components/FinancialIcons.js
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TradingIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.75 4V1.75H20.25V4L22 4L22 15H20.25V17.25H18.75V15H17L17 4L18.75 4ZM20.5 13.5H18.5V5.5H20.5V13.5Z" fill="#4A5560" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 6L3.75 3.75L5.25 3.75L5.25 6H7L7 17H5.25L5.25 19.25H3.75L3.75 17H2V6H3.75ZM5.5 15.5H3.5L3.5 7.5L5.5 7.5L5.5 15.5Z" fill="#4A5560" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.25 22.25V20H9.5L9.5 9H11.25V6.75H12.75V9H14.5L14.5 20H12.75V22.25H11.25ZM11 18.5H13L13 10.5H11L11 18.5Z" fill="#4A5560" />
    </SvgIcon>
);



export {
    TradingIcon
};
