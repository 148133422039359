// src/components/FinancialIcons.js
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const UsersIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.125 10.125C17.7819 10.125 19.125 8.78185 19.125 7.125C19.125 5.46815 17.7819 4.125 16.125 4.125C14.4681 4.125 13.125 5.46815 13.125 7.125C13.125 8.78185 14.4681 10.125 16.125 10.125ZM16.125 8.625C16.9534 8.625 17.625 7.95343 17.625 7.125C17.625 6.29657 16.9534 5.625 16.125 5.625C15.2966 5.625 14.625 6.29657 14.625 7.125C14.625 7.95343 15.2966 8.625 16.125 8.625Z" fill="#4A5560" />
        <path d="M21.375 16.875H19.875C19.875 14.8039 18.1961 13.125 16.125 13.125C14.6491 13.125 13.3723 13.9776 12.7604 15.2172C12.4079 14.8475 12.0138 14.5178 11.5857 14.2356C12.4955 12.6743 14.1877 11.625 16.125 11.625C19.0245 11.625 21.375 13.9755 21.375 16.875Z" fill="#4A5560" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.875 13.125C9.53185 13.125 10.875 11.7819 10.875 10.125C10.875 8.46815 9.53185 7.125 7.875 7.125C6.21815 7.125 4.875 8.46815 4.875 10.125C4.875 11.7819 6.21815 13.125 7.875 13.125ZM7.875 11.625C8.70343 11.625 9.375 10.9534 9.375 10.125C9.375 9.29657 8.70343 8.625 7.875 8.625C7.04657 8.625 6.375 9.29657 6.375 10.125C6.375 10.9534 7.04657 11.625 7.875 11.625Z" fill="#4A5560" />
        <path d="M11.625 19.875H13.125C13.125 16.9755 10.7745 14.625 7.875 14.625C4.97551 14.625 2.625 16.9755 2.625 19.875H4.125C4.125 17.8039 5.80393 16.125 7.875 16.125C9.94607 16.125 11.625 17.8039 11.625 19.875Z" fill="#4A5560" />
    </SvgIcon>
);

const UserIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 24 24">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 12C14.4853 12 16.5 9.98528 16.5 7.5C16.5 5.01472 14.4853 3 12 3C9.51472 3 7.5 5.01472 7.5 7.5C7.5 9.98528 9.51472 12 12 12ZM12 10.5C13.6569 10.5 15 9.15685 15 7.5C15 5.84315 13.6569 4.5 12 4.5C10.3431 4.5 9 5.84315 9 7.5C9 9.15685 10.3431 10.5 12 10.5Z" fill="#4A5560" />
        <path d="M19.5 21H18C18 17.6863 15.3137 15 12 15C8.68629 15 6 17.6863 6 21H4.5C4.5 16.8579 7.85786 13.5 12 13.5C16.1421 13.5 19.5 16.8579 19.5 21Z" fill="#4A5560" />
    </SvgIcon>
);

export {
    UsersIcon,
    UserIcon
};
