import React from 'react';
import { IconButton } from '@material-ui/core';
import { Create as EditIcon, Visibility as ViewIcon } from '@material-ui/icons';
import ClientGrid from '../../components/ClientGrid/ClientGrid';
import Table, { columnType } from '../../components/ClearingTable/Table';
import Button from '../../components/Buttons/Button';
import SelectSystemCode from '../../components/Dropdown/SelectSystemCode';
import Text from '../../components/Typography/Text';
import TextField from '../../components/Textfields/TextField';
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from '../../components/Messages/Notification';
import { contactUsColumns } from '../../lib/data/columns';
import {
  listContactUs,
  updateContactUs,
} from '../../services/ContactUsService';
import QueryParam from '../../services/QueryParamService';
import { getCurrentUser } from '../../services/AuthService';
import ContactUsModal from './Components/ContactUsModal';

export default function ContactUs() {
  const [rows, setRows] = React.useState([]);
  const [rowData, setRowData] = React.useState({});
  const [open, setOpen] = React.useState({
    add: false,
    edit: false,
  });
  const [loading, setLoading] = React.useState({
    search: false,
    save: false,
  });
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      status: '',
    })
  );
  const [user, setUser] = React.useState(getCurrentUser());

  const GridButtons = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    return (
      <div className="grd-row">
        <div className="grd-cell nm">
          <IconButton aria-label="edit" onClick={() => handleOpen(data)}>
            <EditIcon style={{ height: 24, width: 24 }} />
          </IconButton>
        </div>
      </div>
    );
  };

  const columns2 = [
    {
      id: '',
      label: '',
      width: '60px',
      cellRenderer: GridButtons,
    },
    ...contactUsColumns,
  ];

  const columns = [
    {
      name: 'actions',
      label: 'Actions',
      sort: false,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return(
            <div className="grd-row">
              <div className="grd-cell nm">
                <IconButton aria-label="edit" onClick={() => handleOpen(rows[dataIndex])}>
                  <EditIcon style={{ height: 24, width: 24 }} />
                </IconButton>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'subject',
      label: 'Subject',
    },
    {
      name: 'message',
      label: 'Message',
    },
    {
      name: 'status',
      label: 'Status',
    },
    {
      name: 'pageName',
      label: 'Page Name',
    },
    {
      name: 'name',
      label: 'User',
    },
  ];

  const handleSearchDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setSearchData({ ...searchData, [input.name]: input.value });
  };

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
  };

  const handleSearch = async (notify) => {
    try {
      setLoading({ ...loading, search: true });

      const newSearchData = {
        ...searchData,
        usrId: user.UserId,
      };
      QueryParam.set(newSearchData);

      const data = await listContactUs(newSearchData);
      setRows(data.contactUssList);

      if (notify) {
        notifyInfo(
          data.contactUssList.length > 0
            ? data.contactUssList.length + ' search result(s)'
            : 'No records found.'
        );
      }
    } catch (error) {
      notifyError(error.message);
      console.error(error);
      return;
    } finally {
      setLoading({ ...loading, search: false });
    }
  };

  const handleOpen = async (data) => {
    if (data) {
      setRowData(data);
      setOpen({
        ...open,
        edit: true,
        add: false,
      });
    } else {
      setRowData({});
      setOpen({
        ...open,
        add: true,
        edit: false,
      });
    }
  };

  const handleClose = async (data) => {
    if (!data || (user.UserType === 'User' && open.edit)) {
      setOpen({
        ...open,
        add: false,
        edit: false,
      });
      return;
    }

    try {
      const resp = await updateContactUs(data);
      notifySuccess('Feedback has been updated.');
      setRows([resp])
    } catch (error) {
      console.error(error);
      notifyError(error.message);
    }

    setOpen({
      ...open,
      add: false,
      edit: false,
    });
  };

  const getCsvData = async () => {
    return rows;
  };

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Contact Us" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading.search}
              loading={loading.search}
              type="search"
              label={loading.search ? 'Searching...' : 'Search'}
              onClick={() => {
                handleSearch(true);
              }}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <SelectSystemCode
              name="status"
              label="Status"
              placeholder="Blank"
              type="Status"
              subType="AI"
              value={searchData.status}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell"></div>
          <div className="grd-cell"></div>
          <div className="grd-cell"></div>
        </div>
      </div>
      <div className="mt-20">
        {/* <ClientGrid
          title="ContactUs"
          rowIdField="contactUsId"
          columns={columns2}
          rows={rows}
          getCsvData={getCsvData}
          isLoading={loading.search}
          setRows={setRows}
        /> */}
        <Table
          title={'Contact Us'}
          data={rows}
          columns={columns}
          options={options}
        />
      </div>
      {(open.edit || open.add) && (
        <ContactUsModal
          data={rowData}
          open={open.edit || open.add}
          isEdit={open.edit}
          user={user}
          close={handleClose}
          loading={loading.save}
        ></ContactUsModal>
      )}
    </div>
  );
}
