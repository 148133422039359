import { createTheme } from '@mui/material/styles';

export const DefaultTableTheme = () =>
  createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          head: {
            padding: 0,
            whiteSpace: 'nowrap',
          },
        },
      },
    },
  });

export const NestedTableTheme = (headerColor) =>
  createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          head: {
            backgroundColor: headerColor,
            padding: 0,
            whiteSpace: 'nowrap',
          },
        },
      },
    },
  });
