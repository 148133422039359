import React, { Component, Input, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Modal, Tooltip } from 'antd';
import Icon, {
  DeleteFilled,
  FolderAddFilled,
  FileAddFilled,
  DownloadOutlined,
} from '@ant-design/icons';
import { uniqueId } from 'lodash';
import FileManager, { Types } from 'react-file-manager'; //
// import FileManager from 'FileManager'
import EditText from '../FileManager/EditText';
import {
  dl,
  del,
  uploadFiles,
  checkStorageType,
} from '../../services/FileManagerService';
import { protoTimeSpanObjectToString } from '../../services/ConvertService';
import { notifyError } from '../Messages/Notification';
import Button2 from '../Buttons/Button2';

const ButtonGroup = Button.Group;
const { confirm } = Modal;

const images = {
  file:
    'https://raw.githubusercontent.com/exced/react-file-manager/master/public/images/file.png',
  folder:
    'https://raw.githubusercontent.com/exced/react-file-manager/master/public/images/folder.png',
};

export default class FileManagerWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav: [this.props.rootId],
      itemSelectedId: null,
      itemSelectedIndex: null,
      autoFocusId: null,
      idsToDelete: [],
      idsToDownload: [],
    };
  }

  deselect = () => {
    const { nav, itemSelectedId } = this.state;
    const index = nav.indexOf('0');

    this.setState({
      itemSelectedId: null,
      itemSelectedIndex: null,
      idsToDelete: [],
      nav: this.state.nav.slice(0, index),
    });
  };

  onAddFolder = (parentId) => {
    const { map, onChange } = this.props;
    const folder = {
      id: `@@${uniqueId()}`,
      title: 'New folder',
      type: Types.folder,
      children: [],
      parent: parentId,
    };
    onChange({
      ...map,
      [parentId]: {
        ...map[parentId],
        children: [...map[parentId].children, folder.id],
      },
      [folder.id]: folder,
    });
  };

  onClickRemoveFolder = (item) =>
    confirm({
      title: 'Remove this folder ?',
      content: item.title,
      onOk: () => this.onRemoveFolder(item.id),
      onCancel: () => {},
    });

  removeFolder = (map, id) => {
    const { [id]: folder, ...rest } = map;
    return folder.parent
      ? {
          ...rest,
          [folder.parent]: {
            ...map[folder.parent],
            children: map[folder.parent].children.filter((e) => e !== id),
          },
        }
      : rest;
  };

  cascadeRemoveFolder = (map, id) => {
    const { [id]: folder } = map;
    const c = folder.children.reduce(
      (a, e) =>
        a[e].type === Types.folder
          ? this.cascadeRemoveFolder(a, e)
          : this.removeFile(a, e),
      map
    );
    return this.removeFolder(c, id);
  };

  onRemoveFolder = async (id) => {
    const { map, onChange } = this.props;
    const parent = map[id].parent;

    this.app.deselect();
    try {
      const path = map[id].path;

      const response = await del(path);
      if (response.success) {
        onChange(this.cascadeRemoveFolder(map, id));
      } else {
        notifyError('Error Deleting File');
      }
    } catch (error) {
      notifyError('Error Deleting File:' + error.message);
      console.log(error);
    }
  };

  onChangeFolder = (id, input) => {
    const { map, onChange } = this.props;
    onChange({
      ...map,
      [id]: { ...map[id], ...input },
    });
  };

  onAddFile = async (parentId, event) => {
    const { map, onChange } = this.props;

    const files = event.target.files;
    const path = map[parentId].path;

    try {
      const res = await uploadFiles(files, path);
      var mp = map;

      for (var i = 0; i < res.filesList.length; i++) {
        const file = {
          id: `@@${uniqueId()}`,
          title: res.filesList[i].fileName,
          type: Types.file,
          children: [],
          parent: parentId,
          path: path + '/' + res.filesList[i].fileName,
        };
        mp = {
          ...mp,
          [parentId]: {
            ...mp[parentId],
            children: [...mp[parentId].children, file.id],
          },
          [file.id]: file,
        };
      }
    } catch (e) {
      console.log(e.message);
    }

    onChange(mp);
  };

  onClickRemoveFile = (item) =>
    confirm({
      title: 'Remove this file ?',
      content: item.title,
      onOk: () => this.onRemoveFile(item.id),
      onCancel: () => {},
    });

  removeFile = (map, id) => {
    const { [id]: file, ...rest } = map;

    return file.parent
      ? {
          ...rest,
          [file.parent]: {
            ...map[file.parent],
            children: map[file.parent].children.filter((e) => e !== id),
          },
        }
      : rest;
  };

  onRemoveFile = async (id) => {
    const { map, onChange } = this.props;
    const parent = map[id].parent;

    this.app.deselect();
    try {
      const path = map[id].path;

      const response = await del(path);
      if (response.success) {
        onChange(this.removeFile(map, id));
      } else {
        notifyError('Error Deleting File');
      }
    } catch (error) {
      notifyError('Error Deleting File:' + error.message);
      console.log(error);
    }
  };

  onChangeFile = (id, input) => {
    const { map, onChange } = this.props;
    onChange({
      ...map,
      [id]: { ...map[id], ...input },
    });
  };

  onDownload = async (item) => {
    try {
      const file = await dl(item.path);
    } catch (e) {
      console.log(e);
    }
  };

  handleCheckBox = (e, item) => {
    const checked = e.target.checked;

    if (checked) {
      this.state.idsToDelete.push(item.id);
      this.state.idsToDownload.push(item.id);
    } else {
      const deleteIndex = this.state.idsToDelete.indexOf(item.id);
      const downloadIndex = this.state.idsToDownload.indexOf(item.id);
      if (deleteIndex > -1) {
        this.state.idsToDelete.splice(deleteIndex, 1);
      }
      if (downloadIndex > -1) {
        this.state.idsToDownload.splice(downloadIndex, 1);
      }
    }
  };

  handleBatchDelete = () => {
    confirm({
      title: 'Remove this file ?',
      content:
        'Are you sure you want to Delete ' +
        this.state.idsToDelete.length +
        ' files?',
      onOk: () => this.onRemoveFileByBatch(),
      onCancel: () => {},
    });
  };
  handleBatchDownload = async () => {
    const { map, onChange } = this.props;
    var i;
    for (i = 0; i < this.state.idsToDownload.length; i++) {
      const path = map[this.state.idsToDownload[i]].path;
      try {
        await dl(path);
      } catch (error) {
        notifyError('Error Downloading Files:' + error.message);
        console.log(error);
      }
    }
  };

  removeFileByBatch = (map, id) => {
    const { [id]: file, ...rest } = map;

    return file.parent
      ? {
          ...rest,
          [file.parent]: {
            ...map[file.parent],
            children: map[file.parent].children.filter((e) => {
              return !this.state.idsToDelete.includes(e);
            }),
          },
        }
      : rest;
  };

  onRemoveFileByBatch = async () => {
    const { map, onChange } = this.props;

    var i;

    this.app.deselect();

    for (i = 0; i < this.state.idsToDelete.length; i++) {
      try {
        const path = map[this.state.idsToDelete[i]].path;

        const response = await del(path);
        if (response.success) {
          onChange(this.removeFileByBatch(map, this.state.idsToDelete[i]));
        } else {
          notifyError('Error Deleting File');
        }
      } catch (error) {
        notifyError('Error Deleting File:' + error.message);
        console.log(error);
      }
    }

    this.state.idsToDelete = [];
  };

  renderItem = (item, index) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 50,
        width: 300,
      }}
    >
      <div style={{ flex: 'none' }}>
        <img
          src={item.type === Types.folder ? images.folder : images.file}
          alt={item.title}
          style={{ width: 22, height: 22, float: 'left' }}
        />
      </div>
      <div style={{ flex: 4 }}>
        <span
          style={{
            width: 192,
            textAlign: 'left',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            display: 'block',
            textOverflow: 'ellipsis',
            padding: '0px 10px',
          }}
        >
          {item.title}
        </span>
      </div>
      {item.type !== Types.folder && (
        <div style={{ float: 'right', height: 15, width: 15, marginRight: 70 }}>
          <input
            type="checkbox"
            onClick={(e) => {
              this.handleCheckBox(e, item);
            }}
          />
        </div>
      )}
    </div>
  );

  renderPreview = (item, index) => {
    const { rootId, map } = this.props;
    // folder
    if (!item) return;
    if (item.type === Types.folder) {
      if (item.id === rootId) {
        return (
          <div
            style={{
              textAlign: 'center',
              margin: 'auto',
              marginTop: 170,
              width: 200,
              height: 200,
              border: '1px solid',
              borderRadius: 6,
              borderColor: '#ccc',
            }}
          >
            <img
              src={images.folder}
              alt={item.title}
              style={{
                width: 70,
                height: 70,
                margin: 'auto',
                display: 'block',
                marginTop: 40,
              }}
            />
            <span>{item.title}</span>
            <div style={{ marginTop: 10 }}>
              <ButtonGroup>
                {/* <Tooltip title="New folder">
									<Button onClick={() => this.onAddFolder(item.id)} icon={<FolderAddFilled/>} />
								</Tooltip> */}
                {/* <Tooltip title="Upload File">
									<Button onClick={() => this.onAddFile(item.id)} icon={<FileAddFilled/>} />
								</Tooltip> */}
              </ButtonGroup>
            </div>
          </div>
        );
      }
      return (
        <div
          style={{
            textAlign: 'center',
            margin: 'auto',
            marginTop: 170,
            width: 200,
            height: 250,
            border: '1px solid',
            borderRadius: 6,
            borderColor: '#ccc',
          }}
        >
          <img
            src={images.folder}
            alt={item.title}
            style={{
              width: 70,
              height: 70,
              margin: 'auto',
              display: 'block',
              marginTop: 40,
            }}
          />
          <EditText
            value={item.title}
            onChange={(title) => this.onChangeFolder(item.id, { title })}
            size="small"
            placeholder="Title"
          />
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <ButtonGroup>
              <div>
                <Tooltip title="Upload File">
                  <div>
                    <input
                      onChange={(event) => this.onAddFile(item.id, event)}
                      accept="*/*"
                      style={{ display: 'none' }}
                      id="file-manager-upload"
                      type="file"
                    />
                    <label htmlFor="file-manager-upload">
                      <Button2
                        color="secondary"
                        type="import"
                        component="span"
                        label="Upload"
                      />
                    </label>
                  </div>
                </Tooltip>
              </div>
            </ButtonGroup>
          </div>

          <div>
            <ButtonGroup>
              {/* <Tooltip title="New folder">
								<Button onClick={() => this.onAddFolder(item.id)} icon={<FolderAddFilled/>} />
							</Tooltip>
							 */}
              {/*Allowed Deletion of Local Storage Folder (Only on Empty Folders)*/}
              {item.type === Types.folder &&
                this.props.storageType === 'LOCAL' && (
                  <div>
                    <Tooltip title="Remove folder">
                      <Button2
                        onClick={() => this.onClickRemoveFolder(item)}
                        colorType="danger"
                        color="primary"
                        type="delete"
                        component="span"
                        label="Delete"
                      />
                    </Tooltip>
                  </div>
                )}
            </ButtonGroup>
          </div>
        </div>
      );
    }
    // file
    return (
      <div
        style={{
          textAlign: 'center',
          margin: 'auto',
          marginTop: 170,
          width: 255,
          height: 240,
          border: '1px solid',
          borderRadius: 6,
          borderColor: '#ccc',
          padding: '0px 10px',
        }}
      >
        {this.state.idsToDelete.length > 1 ? (
          //Do not change the label color back to white
          <label style={{ marginTop: 20, display: 'block' }}>
            {this.state.idsToDelete.length} Selected Item(s)
          </label>
        ) : (
          <div>
            <img
              src={images.file}
              alt={item.title}
              style={{
                width: 70,
                height: 70,
                margin: 'auto',
                display: 'block',
                marginTop: 40,
              }}
            />
            <EditText
              value={item.title}
              onChange={(title) => this.onChangeFile(item.id, { title })}
              size="small"
              placeholder="Title"
            />
            <div style={{ marginTop: 10 }}>
              <ButtonGroup>
                <Tooltip title="Download File">
                  <Button
                    onClick={() => this.onDownload(item)}
                    type="primary"
                    icon={<DownloadOutlined />}
                  />
                </Tooltip>
                <Tooltip title="Remove file">
                  <Button
                    onClick={() => this.onClickRemoveFile(item)}
                    type="danger"
                    icon={<DeleteFilled />}
                  />
                </Tooltip>
              </ButtonGroup>
            </div>
            <div style={{ marginTop: 10 }}>
              Date Modified {protoTimeSpanObjectToString(item.modifiedAt)}
            </div>
          </div>
        )}
        {this.state.idsToDownload.length > 1 && (
          <div style={{ marginTop: 20 }}>
            <Button2
              color="secondary"
              label="Download Files"
              onClick={this.handleBatchDownload}
            />
          </div>
        )}

        {this.state.idsToDelete.length > 1 && (
          <div style={{ marginTop: 40 }}>
            <Button2
              colorType="danger"
              color="primary"
              label="Delete Selected Files"
              onClick={this.handleBatchDelete}
            />
          </div>
        )}
      </div>
    );
  };

  render() {
    var { map, rootId, onChange, updateMap, storageType } = this.props;

    return (
      <FileManager
        ref={(ref) => (this.app = ref)}
        map={map}
        rootId={rootId}
        storageType={storageType}
        //onChange={onChange}
        onChange={onChange}
        updateMap={updateMap}
        onChangeRow={this.props.onChangeRow}
        onChangeColumn={this.props.onChangeColumn}
        onOutsideDrop={this.props.onOutsideDrop}
        dropzoneConfig={this.props.dropzoneConfig}
        renderItem={
          this.props.renderItem ? this.props.renderItem : this.renderItem
        }
        renderPreview={
          this.props.renderPreview
            ? this.props.renderPreview
            : this.renderPreview
        }
        itemSelectedColor={this.props.itemSelectedColor}
        dropBackgroundColor={this.props.dropBackgroundColor}
        autoFocusId={'0'}
      />
    );
  }
}

FileManagerWrapper.propTypes = {
  map: PropTypes.object.isRequired,
  rootId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onChangeRow: PropTypes.func,
  onChangeColumn: PropTypes.func,
};

FileManagerWrapper.defaultProps = {
  onChangeRow: (id, src, dest) => {},
  onChangeColumn: (id, src, dest) => {},
  onOutsideDrop: (id, files) => {},
  itemSelectedColor: '#1a53ff',
  dropBackgroundColor: '#cccdce',
  dropzoneConfig: {},
};
