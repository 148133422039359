import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { 
    Modal
  , Backdrop
  , Fade
  , Typography 
} from '@material-ui/core'
import TextField from '../../../../components/Textfields/TextField'
import MoneyField from '../../../../components/Textfields/MoneyField'
import AutoCompleteAccountNo from '../../../../components/AutoComplete/AutoCompleteAccountNo'
import AutoCompleteCorrespondent from '../../../../components/AutoComplete/AutoCompleteCorrespondent'
import SelectSystemCode from '../../../../components/Dropdown/SelectSystemCode'
import SelectStatus from '../../../../components/Dropdown/SelectStatus'
import SelectWallet from '../../../../components/Dropdown/SelectWallet'
import SelectContraWallet from '../../../../components/Dropdown/SelectContraWallet'
import SelectBankAccount from '../../../../components/Dropdown/SelectBankAccount'
import Button from '../../../../components/Buttons/Button'
import CloseButton from '../../../../components/Buttons/CloseButton'
import fontFamily from '../../../../assets/css/fonts'
import color from '../../../../assets/css/colors'
import RecurrenceModal from '../../../../components/Modals/RecurrenceModal'
import SelectWalletAddressBookModal from '../../../../components/Modals/SelectWalletAddressBookModal'
import { listRecurrence } from '../../../../services/RecurrenceService'
import { getCurrentUser, getAccess } from '../../../../services/AuthService'
import { getProfileName } from '../../../../services/ProfileService'
import {readClient} from '../../../../services/ClientAccountService'
import { notifyError } from '../../../../components/Messages/Notification'
import {readPositionQty} from '../../../../services/DigitalCashRequestService'
import { formatCurrency } from '../../../../lib/fmt/index';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: '30px 0px 0px 30px',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& > div:nth-child(1)': {
      flex: 'none',
      marginRight: 7,
      '& > svg': {
        width: 28,
        height: 28,
        '& path': {},
      },
    },
    '& div:nth-child(2)': {
      flex: 1,
    },
    '& div:nth-child(3)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {},
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 30px',
    maxHeight: 600,
    overflowY: 'auto',
    fontFamily: fontFamily.headers,
    color: color.default.main,
    '& p': {
      fontSize: 16,
      fontFamily: fontFamily.headers,
      fontWeight: 400,
      lineHeight: '26px',
      color: color.default.main,
      marginTop: 0,
    },
    '& hr': {
      margin: '30px 0px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-10px !important',
      marginRight: '5px !important',
    },
  },
}))

export default function DigitalCashRequestModal(props) {
  const classes = useStyles()

  const { data, open, close, loading } = props
  const [isEdit, setIsEdit] = React.useState(false);
  const { dcRequestId } = data
  const [isLoading, setIsLoading] = React.useState(false);
  const [openRecurring, setOpenRecurring] = React.useState(false);
  const [recurringData, setRecurringData] = React.useState({
    recurrenceId: 0,
    functionName: 'Bank',
    report: 'Wallet Address Book',
    linkId: 0,
    recurring: '',
    day: 0,
    month: '',
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    settleDate: false,
    tradeDate: false,
    calendarDate: false,
  });
  const [walletAddressBook, setWalletAddressBook] = React.useState({});
  const [addressBookOpen, setAddressBookOpen] = React.useState(false);

  const [modalData, setModalData] = React.useState({
    correspondent: '',
    accountNo: '',
    transferType: '',
    walletId: '',
    contraWalletId: '',
    amt: '',
    status: '',
    referenceId: '',
    confirmationId: '',
    internalId: '',
    externalId: '',
    privateDescription: '',
    publicDescription: '',
  })
  const [profileName, setProfileName] = React.useState("");
  const [positionQty, setPositionQty] = React.useState('$0');
  const [user, setUser] = React.useState([])
  const [defaultAccount, setDefaultAccount] = React.useState([])

  const getPrivateDescriptionValue = (internalValue) => {
    if (!internalValue) {
      return ''
    }

    return 'Add Info for Beneficiary: ' + internalValue
  }

  React.useEffect(() => {
    if (open) {
      const initAccess = async () => { 
  
        const loggeduser = getCurrentUser()
        setUser(loggeduser)
        if (loggeduser === null) return
        try {
          const serverProfile = await getProfileName()
          setProfileName(serverProfile.toLowerCase())
          console.log(serverProfile);
          console.log(data)
          setModalData({ 
            ...data, 
            privateDescription: getPrivateDescriptionValue(data.internalId),
          })
           const retPositionQty = await readPositionQty()
           setPositionQty(
            formatCurrency(
              !retPositionQty?.positionQty || retPositionQty?.positionQty === '' ? 0 : retPositionQty.positionQty)
            )
        } catch (error) {
          
          notifyError(error.message)
          console.error(error)
        }
        console.log(loggeduser);

      }
    
      initAccess()
      if(data.dcRequestId){
        setIsEdit(true);
      }
     
    }
  }, [open, data])

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target

    if (input.name === 'internalId') {
      modalData.privateDescription = getPrivateDescriptionValue(input.value)
    }

    setModalData({ ...modalData, [input.name]: input.value })
  }

  const getRecurrence = async (val) => {
    const { recurrenceList } = await listRecurrence({
      functionName: 'Bank',
      report: 'Wallet Address Book',
      linkId: val.walletAddressBookId,
    });
    if (recurrenceList.length != 0) {
      return recurrenceList[0];
    }

    return null;
  };

  const isTransferSend = () => {
    if (modalData.transferType == 'Transfer Send') {
      return true;
    } else {
      return false;
    }
  };
  
  const handleRecurringOpen = async () => {
    setOpenRecurring(true);
  };

  const handleRecurringClose = async (data) => {
    setIsLoading(true);
    if (!data) {
      setOpenRecurring(false);
      return;
    }
    setRecurringData(data);
    setOpenRecurring(false);
    setIsLoading(false);
  };
  
  const handleAddressBookOpen = () => {
    setAddressBookOpen(true);
  };

  const handleAddressBookClose = async (data) => {
    if (!data || !data.walletAddressBookId) {
      setAddressBookOpen(false);
      return;
    }

    const newData = {
      ...modalData,
      correspondent: data.correspondent,
      accountNo: data.accountNo,
      walletId: data.walletId,
      contraWalletId: data.contraWalletId,
      amt: data.recurrenceAmount,
      transferType: 'Transfer Send',
    };

    setModalData(newData);
    setWalletAddressBook(data);
    const dataRec =await getRecurrence(data)
    console.log(dataRec);
    setRecurringData(dataRec);

    setAddressBookOpen(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => close()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div style={{ width: 2000 }}>
          <div className={classes.modalHeader}>
              <Typography
                id="transition-modal-title"
                style={{ fontWeight: 'bold', fontSize: '30px' }}
                className={classes.textBold}
                gutterBottom
              >
                {(dcRequestId ? 'Edit' : 'Add') + ' Digital Cash Request'}
              </Typography>
                <div
                  className='grd-cell'
                  style={{
                    float: 'right',
                    marginRight: '20px',
                    textAlign: 'right',
                    fontWeight: 'Bold',
                  }}
                >
                </div>
            <div className={classes.closeButton}>
              <CloseButton onClick={() => close()} />
            </div>
          </div>
          <div className={classes.modalBody}>

            <div>
              <div className="grd-row">
                <div className="grd-cell">
                  <AutoCompleteCorrespondent
                    required={true}
                    disabled={dcRequestId ? true : false}
                    isAccessibleOnly={true}
                    name="correspondent"
                    value={modalData.correspondent}
                    onChange={handleChange}
                  />
                </div>
                <div className="grd-cell">
                  <AutoCompleteAccountNo
                    required={true}
                    disabled={dcRequestId ? true : false}
                    isAccessibleOnly={true}
                    correspondent={modalData.correspondent}
                    name="accountNo"
                    value={modalData.accountNo}
                    onChange={handleChange}
                  />
                </div>
                <div className="grd-cell">
                  <SelectSystemCode
                    required={true}
                    disabled={dcRequestId ? true : false}
                    name="transferType"
                    label="Transfer Type"
                    placeholder="Transfer Type"
                    type="Transfer Type"
                    value={modalData.transferType}
                    onChange={handleChange}
                  ></SelectSystemCode>
                </div>
              </div>
              <div className="grd-row">
                <div className="grd-cell">
                  <SelectWallet
                    required={true}
                    disabled={dcRequestId ? true : false}
                    name="walletId"
                    label="Wallet"
                    placeholder="Select Wallet"
                    accountNo={modalData.accountNo}
                    allowAddWallet={dcRequestId ? false : true}
                    value={modalData.walletId}
                    onChange={handleChange}
                  />
                </div>
                {modalData.transferType === 'Transfer Send' && (
                  <div className="grd-cell">
                    <SelectContraWallet
                      disabled={dcRequestId ? true : false}
                      name="contraWalletId"
                      label="Contra Wallet"
                      placeholder="Select Contra Wallet"
                      value={modalData.contraWalletId}
                      allowAddWallet={false}
                      walletId = {modalData.walletId}
                      onChange={handleChange}
                    />
                  </div>
                )}
              </div>
              
              {!isTransferSend() && modalData.transferType !== '' && (
                  <div className="grd-row">
                    <div className="grd-cell">
                      <SelectBankAccount
                        required={
                          !isTransferSend()
                        }
                        disabled={dcRequestId ? true : false}
                        name="bankId"
                        label="DDA Bank"
                        correspondent={modalData.correspondent}
                        accountNo={modalData.accountNo}
                        value={modalData.bankId}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                )}
                {!isTransferSend() && modalData.transferType !== '' && (
                  <div className="grd-row">
                    <div className="grd-cell">
                      <SelectSystemCode
                        required={true}
                        disabled={dcRequestId ? true : false}
                        name="requestType"
                        label="DDA Request Type"
                        type="Type"
                        subType="Request Type"
                        value={modalData.requestType}
                        onChange={handleChange}
                      ></SelectSystemCode>
                    </div>
                  </div>
                )}
              <div className="grd-row">
                <div className="grd-cell">
                  <MoneyField
                    required={true}
                    disabled={dcRequestId ? true : false}
                    name="amt"
                    label="Amount"
                    placeholder="$"
                    value={modalData.amt}
                    onChange={handleChange}
                  />
                </div>
                <div className="grd-cell">
                  <SelectStatus
                    disabled={!isEdit}
                    cancelOnly={isEdit}
                    name="status"
                    label="Status"
                    placeholder="Status"
                    type="Status"
                    subType="Bank Request"
                    value={modalData.status}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="grd-row">
                <div className="grd-cell">
                  <TextField
                    disabled={true}
                    name="referenceId"
                    label="Reference ID"
                    placeholder="Reference ID"
                    type="text"
                    value={modalData.referenceId}
                    onChange={handleChange}
                  />
                </div>
                <div className="grd-cell">
                  <TextField
                    disabled={true}
                    name="confirmationId"
                    label="Confirmation ID"
                    placeholder="Confirmation ID"
                    type="text"
                    value={modalData.confirmationId}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="grd-row">
                <div className="grd-cell">
                  <TextField
                    disabled={true}
                    name="internalId"
                    label="Internal ID"
                    placeholder="Internal ID"
                    type="text"
                    value={modalData.internalId}
                    onChange={handleChange}
                  />
                </div>
                <div className="grd-cell">
                  <TextField
                    disabled={true}
                    name="externalId"
                    label="External ID"
                    placeholder="External ID"
                    type="text"
                    value={modalData.externalId}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="grd-row">
                <div className="grd-cell">
                  <TextField
                    disabled={true}
                    name="privateDescription"
                    label="Private Description"
                    placeholder="Private Description"
                    type="text"
                    value={modalData.privateDescription}
                    onChange={handleChange}
                  />
                </div>
                {modalData.transferType === 'Transfer Send' && (
                  <div className="grd-cell">
                    <TextField
                      name="publicDescription"
                      label="Public Description"
                      placeholder="Public Description"
                      type="text"
                      value={modalData.publicDescription}
                      onChange={handleChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={classes.modalFooter}>
            <div className="grd-row">
              {(modalData.transferType === 'Transfer Send') && 
              <div
                className={classes.grdCellNone}
                style={{ marginRight: 10 }}
              >
                <Button
                  disabled={loading}
                  loading={loading}
                  type="recurrence"
                  label='Recurring'
                  onClick={() => handleRecurringOpen()}
                />
              </div>}
              {(modalData.transferType === 'Transfer Send') && 
              <div
                className={classes.grdCellNone}
                style={{ marginRight: 10 }}
              >
                <Button
                  disabled={loading}
                  loading={loading}
                  label='Set Address Book'
                  onClick={() => handleAddressBookOpen()}
                />
              </div>}
              <Button
                disabled={loading}
                loading={loading}
                label={loading ? 'Saving...' : 'Save'}
                onClick={() => close(modalData, dcRequestId ? true : false,recurringData)}
              />
              </div>
            </div>
            {openRecurring && (
              <RecurrenceModal
                onClose={handleRecurringClose}
                open={openRecurring}
                value={recurringData}
              ></RecurrenceModal>
            )}
            {addressBookOpen && (
              <SelectWalletAddressBookModal
                onClose={handleAddressBookClose}
                isOpen={addressBookOpen}
                value = {modalData}
              ></SelectWalletAddressBookModal>
            )}
            
        </div>
      </Fade>
    </Modal>
  )
}


