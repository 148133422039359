import React from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '../../../../components/Misc/Checkbox';
import AutoCompleteAccountNo from '../../../../components/AutoComplete/AutoCompleteAccountNo';
import AutoCompleteSymbol from '../../../../components/AutoComplete/AutoCompleteSymbol';
import TextField from '../../../../components/Textfields/TextField';
import SelectAssetType from '../../../../components/Dropdown/SelectAssetType';
import SelectAction from '../../../../components/Dropdown/SelectAction';
import SelectSystemCode from '../../../../components/Dropdown/SelectSystemCode';
import DateTimePicker from '../../../../components/Textfields/DateTimePicker';
import SelectPutCall from '../../../../components/Dropdown/SelectPutCall';
import { setClientInput } from '../../../../services/OrderEntryService';
import MoneyField from '../../../../components/Textfields/MoneyField';
import PercentageField from '../../../../components/Textfields/PercentageField';
import RadioButtonGroup from '../../../../components/Buttons/RadioButtonGroup';
import { dateFieldFormat } from '../../../../lib/format/format';
import AutoCompletePosition from '../../../../components/AutoComplete/AutoCompletePosition';

const useStyles = makeStyles(() => ({
  paper: {
    '&': {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 15,
      paddingRight: 15,
      marginBottom: 20,
    },
  },
}));
const currentDate = dateFieldFormat(new Date());

export default function OrderEntryModalFields(props) {
  const classes = useStyles();
  const { data, setData, retainData, setRetainData, isEdit, accountDetails, tradingPrivilege } = props;

  const actionChangeHandler = async (e) => {
    const action = e.target.value;

    let modifiedData = {
      ...data,
      action,
      qty: 0,
      plusCommission: false, // not currently used
    };

    if (action === 'Sell All' || action === 'ICE') {
      modifiedData.qty = 100;
      modifiedData.roundDown = '1';
    }

    setData(modifiedData);
  };

  const assetTypeChangeHandler = async (e) => {
    const assetType = e.target.value;
    let modifiedData = { ...data, assetType };

    if (!assetType) {
      modifiedData.qty = 0;
      modifiedData.plusCommission = false;
      modifiedData.action = '';
      modifiedData.symbol = '';
      modifiedData.qty = 0;
      modifiedData.share = 0;
    }

    if (['O', 'E', 'F'].includes(assetType)) {
      modifiedData.orderType = 'M';
    } else {
      modifiedData.orderType = '';
    }

    if (assetType === 'E') {
      modifiedData.divRei = false;
    }

    setData(modifiedData);
  };

  const dollarChangeHandler = async (e) => {
    const dollar = e.currentTarget.value;
    
    setData({ ...data, dollar, percentage: 0 });
    await handleSetClientInput('DOLLAR');
  };

  const qtyShareChangeHandler = async (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setData({ ...data, [name]: value });

    if (data.share % parseFloat(data.roundDown)) {
      await handleSetClientInput('SHARE');
    } else if (name === 'qty') {
      await handleSetClientInput('QUANTITY');
    } else {
      await handleSetClientInput('SHARE');
    }
  };

  const orderTypeChangeHandler = async (e) => {
    const orderType = e.target.value;
    let modifiedData = { ...data, orderType };

    if (['SL', 'S', 'L'].includes(orderType)) {
      modifiedData.tif = 'GTC';
    } else if (orderType === 'TS%') {
      modifiedData.trailingStop = Math.round(data.trailingStop);
    }

    setData(modifiedData);
  };

  const handleSetClientInput = async (clientInput) => {
    await setClientInput(data.orderEntryId, clientInput);
  };

  const handleChange = (e, checkboxValue) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;
    setData({
      ...data,
      [input.name]:
        checkboxValue === true || checkboxValue === false ? checkboxValue : input.value,
    });
  };

  const handleRetainChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;
    setRetainData({
      ...retainData,
      [input.name]: input.checked,
    });
  };

  const getShareLabel = () => {
    if (data.assetType === 'O') return 'Contract';

    return 'Qty';
  };

  const getQuantityLabel = () => {
    if (['Sell %', 'Sell All', 'ICE'].includes(data.action)) return 'Qty %';
    if (['Sell $', 'Buy $'].includes(data.action)) return 'Qty $';

    return 'Qty';
  };

  const getSymbolLabel = () => {
    if (data.assetType === 'F') return 'Cusip';
    if (data.action === 'ICE') return 'From Symbol';

    return 'Symbol';
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper} variant="outlined">
        <div className="grd-row nm">
          <div className="grd-cell">
            <AutoCompleteAccountNo
              isAccessibleOnly
              name="accountNo"
              value={data.accountNo}
              correspondent={data.correspondent}
              onChange={handleChange}
              required
              freeSolo
            />
          </div>
          <div className="grd-cell">
            <TextField label="Account Name" value={accountDetails.accountName} readonly />
          </div>
          <div className="grd-cell">
            <TextField label="Rep" value={accountDetails.rep} readonly />
          </div>
        </div>
      </Paper>
      <Paper className={classes.paper} variant="outlined">
        <div className="grd-row nm">
          <div className="grd-cell">
            <SelectAssetType
              required
              name="assetType"
              label="Asset Type"
              value={data.assetType}
              onChange={assetTypeChangeHandler}
              tradingPrivilege={tradingPrivilege}
            />
            <Checkbox
              mt={10}
              label="Retain"
              name="assetType"
              checked={retainData.assetType}
              onChange={handleRetainChange}
            />
          </div>
          <div className="grd-cell">
            <Checkbox
              mt={35}
              name="solicited"
              label="Solicited"
              checked={data.solicited}
              onChange={handleChange}
            />
          </div>
        </div>
      </Paper>
      {data.assetType && (
        <Paper className={classes.paper} variant="outlined">
          <div className="grd-row nm">
            <div className="grd-cell">
              <SelectAction
                required
                name="action"
                label="Action"
                assetType={data.assetType}
                value={data.action}
                onChange={actionChangeHandler}
              />
              <Checkbox
                mt={10}
                label="Retain"
                name="action"
                checked={retainData.action}
                onChange={handleRetainChange}
              />

              {['Sell $'].includes(data.action) && data.assetType === 'MF' && (
                <Checkbox
                  mt={10}
                  label="Plus Commission"
                  name="plusCommission"
                  checked={data.plusCommission} 
                  onChange={handleChange}
                />
              )}
            </div>
            <div className="grd-cell">
              {['Buy', 'Buy $', 'Buy Open'].includes(data.action) ? (
                <AutoCompleteSymbol
                  required
                  name="symbol"
                  value={data.symbol}
                  label={getSymbolLabel()}
                  onChange={handleChange}
                  maxLength={50}
                  freeSolo
                  accountNo={data.accountNo}
                  action={data.action}
                  assetType={data.assetType}
                  placeholder="Symbol"
                />
              ) : (
                <AutoCompletePosition
                  required
                  name="symbol"
                  label={getSymbolLabel()}
                  type="text"
                  freeSolo
                  value={data.symbol}
                  onChange={handleChange}
                  accountNo={data.accountNo}
                  action={data.action}
                  assetType={data.assetType}
                  placeholder="Symbol"
                />
              )}
            </div>
            {data.action === 'ICE' && (
              <div className="grd-cell">
                <AutoCompleteSymbol
                  required
                  name="symbol2"
                  value={data.symbol2}
                  label="To Symbol"
                  onChange={handleChange}
                  freeSolo
                  disabled={isEdit}
                  accountNo={data.accountNo}
                  action={data.action}
                  assetType={data.assetType}
                  placeholder="From Symbol"
                />

                <Checkbox
                  mt={10}
                  label="Div Rei"
                  name="divRei"
                  checked={data.divRei}
                  onChange={handleChange}
                />
              </div>
            )}

            {data.assetType === 'F' && (
              <div className="grd-cell">
                <MoneyField
                  required
                  name="dollar"
                  label="Dollar"
                  type="number"
                  value={data.dollar}
                  onChange={dollarChangeHandler}
                  disabled={['Sell All'].includes(data.action)}
                  placehoder="$"
                />
              </div>
            )}

            {((data.dollar &&
              data.assetType === 'E' &&
              ['Buy', 'Sell', 'Sell All', 'ICE'].includes(data.action)) ||
              data.assetType == 'O') && (
              <div className="grd-cell">
                <TextField
                  required={
                    !data.dollar ||
                    data.action === 'Buy' ||
                    data.action === 'Sell' ||
                    data.assetType == 'O'
                  }
                  name="share"
                  label={getShareLabel()}
                  type="number"
                  value={data.share}
                  onChange={qtyShareChangeHandler}
                  disabled={['Sell All', 'ICE'].includes(data.action)}
                />
                {['Buy'].includes(data.action) && (
                  <Checkbox
                    mt={10}
                    label="Retain"
                    name="qty"
                    checked={retainData.qty}
                    onChange={handleRetainChange}
                  />
                )}

                {['Buy'].includes(data.action) && (
                  <Checkbox
                    mt={10}
                    label="Div Rei"
                    name="divRei"
                    checked={data.divRei}
                    onChange={handleChange}
                  />
                )}
              </div>
            )}

            {(data.assetType === 'MF' ||
              (data.assetType === 'E' && ['Buy $', 'Sell $'].includes(data.action))) && (
              <div className="grd-cell">
                <MoneyField
                  required={
                    data.assetType === 'MF' ||
                    (data.assetType === 'E' &&
                      ['Sell $', 'Buy $'].includes(data.action) &&
                      !data.qty)
                  }
                  name="qty"
                  label={getQuantityLabel()}
                  type="number"
                  value={data.qty}
                  onChange={handleChange}
                  placeholder="$"
                  disabled={['Sell All', 'ICE'].includes(data.action)}
                />

                {['Buy $', 'Sell $'].includes(data.action) && (
                  <Checkbox
                    mt={10}
                    label="Retain"
                    name="qty"
                    checked={retainData.qty}
                    onChange={handleRetainChange}
                  />
                )}

                {['Buy $'].includes(data.action) && (
                  <Checkbox
                    mt={10}
                    label="Div Rei"
                    name="divRei"
                    checked={data.divRei}
                    onChange={handleChange}
                  />
                )}
              </div>
            )}

            <div className="grd-cell">
              {!['MF', 'F'].includes(data.assetType) && (
                <div>
                  <SelectSystemCode
                    type="Order Type"
                    required
                    name="orderType"
                    label="Timing"
                    value={data.orderType}
                    onChange={orderTypeChangeHandler}
                  />
                  <Checkbox
                    mt={10}
                    label="Retain"
                    name="timing"
                    checked={retainData.timing}
                    onChange={handleRetainChange}
                  />
                </div>
              )}
            </div>
          </div>
        </Paper>
      )}
      {data.assetType === 'O' && (
        <Paper className={classes.paper}>
          <div className="grd-row nm">
            <div className="grd-cell">
              <DateTimePicker
                required
                type="date"
                name="expirationDate"
                label="Expiration Date"
                value={data.expirationDate}
                onChange={handleChange}
                inputProps={{ inputProps: { min: currentDate } }}
              />

              <Checkbox
                mt={10}
                label="Retain"
                name="expirationDate"
                checked={retainData.expirationDate}
                onChange={handleRetainChange}
              />
            </div>
            <div className="grd-cell">
              <SelectPutCall
                required
                name="putCall"
                label="Put or Call"
                value={data.putCall}
                onChange={handleChange}
              />

              <Checkbox
                mt={10}
                label="Retain"
                name="putCall"
                checked={retainData.putCall}
                onChange={handleRetainChange}
              />
            </div>
            <div className="grd-cell">
              <MoneyField
                required
                name="strikePrice"
                label="Strike Price"
                value={data.strikePrice}
                onChange={handleChange}
                placehoder="$"
              />
            </div>
          </div>
        </Paper>
      )}

      {data.orderType && data.orderType !== 'M' && (
        <Paper className={classes.paper}>
          <div className="grd-row nm">
            {['SL', 'S'].includes(data.orderType) && (
              <div className="grd-cell">
                <MoneyField
                  required
                  name="stopPrice"
                  label="Stop Price"
                  value={data.stopPrice}
                  onChange={handleChange}
                  type="number"
                  placeholder="$"
                />
              </div>
            )}
            {data.orderType === 'TS%' && (
              <div className="grd-cell">
                <PercentageField
                  name="trailingStop"
                  label="Trailing Stop - %"
                  type="number"
                  value={data.trailingStop}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
            {data.orderType === 'TS$' && (
              <div className="grd-cell">
                <MoneyField
                  required
                  name="trailingStop"
                  label="Trailing Stop - $"
                  value={data.trailingStop}
                  onChange={handleChange}
                  type="number"
                  placeholder="$"
                />
              </div>
            )}
            {['SL', 'L'].includes(data.orderType) && (
              <div className="grd-cell">
                <MoneyField
                  required
                  name="limitPrice"
                  label="Limit Price"
                  value={data.limitPrice}
                  onChange={handleChange}
                  type="number"
                  placeholder="$"
                />
              </div>
            )}
            <div className="grd-cell">
              {data.assetType && data.assetType !== 'F' && (
                <SelectSystemCode
                  type="TIF"
                  required
                  name="tif"
                  label="Time Limit"
                  value={data.tif}
                  onChange={handleChange}
                />
              )}
            </div>
          </div>
        </Paper>
      )}
      <Paper className={classes.paper}>
        <div className="grd-row nm">
          <div className="grd-cell">
            <TextField
              max={100}
              name="userNote"
              label="Notes to Trader for this Specific Trade"
              type="text"
              value={data.userNote}
              onChange={handleChange}
            />
          </div>
        </div>
      </Paper>
      {data.assetType === 'E' && (
        <Paper className={classes.paper}>
          <div className="grd-row nm">
            <div className="grd-cell">
              <RadioButtonGroup
                name="settlementType"
                label="Special Instructions"
                value={data.settlementType}
                options={['None', 'Same Day Settle', 'Next Day Settle']}
                onChange={handleChange}
              />
            </div>
          </div>
        </Paper>
      )}
    </React.Fragment>
  );
}
