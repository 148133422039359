import React from 'react'
import ClientGrid2 from '../../../components/ClientGrid/ClientGrid2'
import Table, { columnType } from '../../../components/ClearingTable/Table';
import Button from '../../../components/Buttons/Button'
import AutoCompleteCorrespondent from '../../../components/AutoComplete/AutoCompleteCorrespondent'
import AutoCompleteAccountNo from '../../../components/AutoComplete/AutoCompleteAccountNo'
import AutoCompleteBranch from '../../../components/AutoComplete/AutoCompleteBranch'
import Text from '../../../components/Typography/Text'
import {
  notifyInfo,
  notifyError,
} from '../../../components/Messages/Notification'
import DownloadProgress from '../../../components/Progress/DownloadProgress'
import { TextField } from '@material-ui/core';
import { listBalance } from '../../../services/BalanceService'
import { getSystemDate } from '../../../services/ProfileService'
import QueryParam from '../../../services/QueryParamService'
import { dateProtoObjectToString } from '../../../services/ConvertService';
import { IconButton, Tooltip, makeStyles } from '@material-ui/core';
import { Visibility as ViewIcon, GetAppRounded as DownloadIcon, SystemUpdateAlt as DownloadCSVIcon} from '@material-ui/icons';
import {
    listForm1099Div,
    downloadDiv,
    generateDiv,
} from '../../../services/Form1099DIVService';
import Form1099DIVModal from '../../../components/Modals/Form1099DIVModal';
import {
  CustomBodyRenderCurrency,
  CustomBodyRenderDate,
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderBoolean,
} from '../../../components/Table/CustomBodyRender';
import color from '../../../assets/css/colors'
import fontFamily from '../../../assets/css/fonts'


const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottom:'1px solid #ccc',
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
    
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
}))

export default function Form1099DIV() {
  const classes = useStyles();
  
  let selectedIndexes = [];
  const [rows, setRows] = React.useState([])
  const [rowData, setRowData] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      year: '',
      correspondent: '',
      branch: '',
      accountNo: '',
    }),
  )
  const [openModal, setOpenModal] = React.useState(false);

// Table Settings  
const form1099DIVColumns = [
    {
      name: 'actions',
      label: 'Actions',
      sort: false,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return(
            <div align={'left'} className="grd-row" style={{ margin: 0, padding: 0 }}>
              <div align={'left'} className="grd-cell" style={{ display:'flex' }}>
                <Tooltip title="Download PDF" arrow>
                  <DownloadProgress
                    selected={rows[dataIndex]}
                    fileType="pdf"
                    rows={rows}
                    download={downloadDiv}
                    generate={generateDiv}
                    useSystemUpdateIcon={false}
                  />
                </Tooltip>
  
                <Tooltip title="Download CSV" arrow>
                  <DownloadProgress
                    selected={rows[dataIndex]}
                    fileType="csv"
                    rows={rows}
                    download={downloadDiv}
                    generate={generateDiv}
                    useSystemUpdateIcon={true}
                  />
                </Tooltip>
  
                <Tooltip title="View" arrow>
                  <IconButton aria-label='view' onClick={() => {handleOpenModal(dataIndex)}}>
                    <ViewIcon style={{ height: 25, width: 25 }}/>
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'year',
      label: 'Year',
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'office',
      label: 'Branch',
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'account',
      label: 'Account Name',
    },
    {
      name: 'taxCountry',
      label: 'Tax Country',
    },
    {
      name: 'div',
      label: 'Dividend',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft
      }
    },
    {
      name: 'taxAmount',
      label: 'Tax Amount',
      options: {
        display: false,
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'companyName',
      label: 'Company Name',
      options: {
        display: false,
      },
    },
    {
      name: 'address',
      label: 'Address',
      options: {
        display: false,
      },
    },
    {
      name: 'city',
      label: 'City',
      options: {
        display: false,
      },
    },
    {
      name: 'state',
      label: 'State',
      options: {
        display: false,
      },
    },
    {
      name: 'zip',
      label: 'Zip',
      options: {
        display: false,
      },
    },
    {
      name: 'country',
      label: 'Country',
      options: {
        display: false,
      },
    },
    {
      name: 'legalEntity',
      label: 'Legal Entity',
      options: {
        display: false,
      },
    },
    {
      name: 'governmentIdType',
      label: 'Government ID Type',
      options: {
        display: false,
      },
    },
  ];

  const options = {
    responsive: 'standard',
    customToolbarSelect: (selectedRows) => {
      return (
        <div className="grd-row" style={{ marginRight: 15, padding: 0 }}>
          <div className="grd-cell" style={{ marginRight: 15 }}>
            <Button
              id="batch"
              disabled={loading.search}
              loading={loading.search}
              type="search"
              label={'BATCH DOWNLOAD PDF'}
              onClick={() => {
                handleBatchDownload('pdf');
              }}
            />
          </div>
          <div className="grd-cell" style={{ whiteSpace: 'nowrap' }}>
            <Button
              id="batch"
              disabled={loading.search}
              loading={loading.search}
              type="search"
              label={'BATCH DOWNLOAD CSV'}
              onClick={() => {
                handleBatchDownload('csv');
              }}
            />
          </div>
        </div>
      );
    },
    onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
      console.log(allRowsSelected);
      let tempIds = [];

      allRowsSelected.map((row) => {
        tempIds.push(row.index);
      })

      selectedIndexes = tempIds;
    },
  };
  // End Table Settings

  const testRows = [
    { year: "2022", correspondent: "SAS", branch: "PH"},
  ];

  const init = async () => {
    const searchParameters = localStorage.getItem('search_data')

    const {
      year,
      correspondent,
      branch,
      accountNo,
    } = searchParameters ? JSON.parse(searchParameters) : searchData

    const systemDate = await getSystemDate()

    setSearchData({
      ...searchData,
      year: year ? year : '',
      correspondent: correspondent ? correspondent : '',
      branch: branch ? branch : '',
      accountNo: accountNo ? accountNo : '',
    })
  }

  React.useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  const handleSearchDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target

    setSearchData({ ...searchData, [input.name]: input.value })
  }

  const handleSearch = async () => {
    try {
      setLoading(true);

      QueryParam.set(searchData);
      const { form1099DivList } = await listForm1099Div(searchData);
      setRows(form1099DivList);
      console.log(form1099DivList)

      notifyInfo(form1099DivList.length + ' search results.');
    } catch (error) {
      notifyError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleBatchDownload = async (fileType) => {
    try{
      for(let i = 0; i< selectedIndexes.length; i++) {
        await downloadDiv(rows[selectedIndexes[i]], fileType, '', '')
      }
    } catch (error) {
      notifyError(error.message);
    }
  }

  const handleOpenModal = (selectedRow) => {
    setRowData(rows[selectedRow]);
    setOpenModal(true);
  }

  const closeModal = () => {
    setOpenModal(false);
  }

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Form 1099 DIV"/>
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading}
              loading={loading}
              type="search"
              label={loading ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <TextField
              fullWidth={true}
              name="year"
              label="Year"
              type="number"
              value={searchData.year}
              onChange={handleSearchDataChange}
              inputProps={{ maxLength: 300 }}
              InputLabelProps={{ shrink: true }}
              disabled={false}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteCorrespondent
              isAccessibleOnly={true}
              name="correspondent"
              value={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteBranch
              isAccessibleOnly={true}
              name="branch"
              value={searchData.branch}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteAccountNo
              isAccessibleOnly={true}
              name="accountNo"
              value={searchData.accountNo}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
        </div>
      </div>
      <div className="mt-20">
        <Table
          title={'Form 1099 DIV'}
          data={rows}
          columns={form1099DIVColumns}
          options={options}
        />
        {openModal && (
          <Form1099DIVModal open={openModal} close={closeModal} data={rowData}/>
        )}
      </div>
    </div>
  )
}
