import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal, Backdrop, Fade, Button } from '@material-ui/core'
import Text from '../../../../components/Typography/Text'
import TextField from '../../../../components/Textfields/TextField'
import fontFamily from '../../../../assets/css/fonts'
import color from '../../../../assets/css/colors'
import SelectGLBankAccount from '../../../../components/Dropdown/SelectGLBankAccount'
import { readBankAddress } from '../../../../services/BankAddressService'

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: '30px 0px 0px 30px',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& > div:nth-child(1)': {
      flex: 'none',
      marginRight: 7,
      '& > svg': {
        width: 28,
        height: 28,
        '& path': {},
      },
    },
    '& div:nth-child(2)': {
      flex: 1,
    },
    '& div:nth-child(3)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {},
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 30px',
    maxHeight: 600,
    overflowY: 'auto',
    fontFamily: fontFamily.headers,
    color: color.default.main,
    '& p': {
      fontSize: 16,
      fontFamily: fontFamily.headers,
      fontWeight: 400,
      lineHeight: '26px',
      color: color.default.main,
      marginTop: 0,
    },
    '& hr': {
      margin: '30px 0px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 120,
    },
  },
  incompleteSteps: {
    fontSize: 16,
    lineHeight: '24px',
    marginTop: 20,
    '& > label': {
      fontWeight: 600,
      marginBottom: 10,
      display: 'block',
    },
    '& ul': {
      listStyle: 'none',
      margin: '0px 0px 0px -40px',
      lineHeight: '30px',
      '& > li > span': {
        position: 'relative',
        top: 4,
        '& > svg': {
          width: 20,
          height: 20,
        },
      },
    },
  },
  success: {
    '& > svg > path': {},
  },
  error: {
    '& > svg > path': {},
  },
  closeButton: {
    '& button': {
      marginTop: '-5px !important',
    },
  },
}))

export default function ACHWireBankDetailsModal({
  onClose: handleClose,
  open: isOpen,
  value,
}) {
  const classes = useStyles()

  const [bankAddress, setBankAddress] = useState('')
  useEffect(() => {
    if (isOpen) {
      async function init() {
        const address = await readBankAddress(value.bankAddressId)
        const addressArray = [
          address.address,
          address.city,
          address.state,
          address.country,
          address.zipCode,
        ]

        setBankAddress(addressArray.filter((item) => item).join(', '))
      }
      init()
    }
  }, [isOpen, value])

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={() => {
        handleClose()
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div style={{ width: 800 }}>
          <div className={classes.modalHeader}>
            <div>
              <Text mt={4} variant="h2" label="View Bank Details" />
            </div>
          </div>
          <div className={classes.modalBody}>
            <div className="grd-row">
              <div className="grd-cell">
                <TextField
                  label="Correspondent"
                  value={value.correspondent}
                  disabled
                />
              </div>
              <div className="grd-cell">
                <TextField
                  label="Account No"
                  disabled
                  value={value.accountNo}
                />
              </div>
            </div>
            <div className="grd-row">
              <div className="grd-cell">
                <TextField
                  disabled
                  label="Bank Owner Name"
                  value={value.bankOwnerName}
                />
              </div>
              <div className="grd-cell">
                <TextField disabled label="Bank Name" value={value.bankName} />
              </div>
            </div>
            <div className="grd-row">
              <div className="grd-cell">
                <TextField
                  disabled
                  label="Bank Owner Name"
                  value={bankAddress}
                />
              </div>
            </div>
            <div className="grd-row">
              <div className="grd-cell">
                <TextField
                  disabled
                  label="Bank Account No"
                  value={value.bankAccountNo}
                />
              </div>
              <div className="grd-cell">
                <TextField
                  disabled
                  label="ACH Routing No"
                  value={value.achRoutingNo}
                />
              </div>
              <div className="grd-cell">
                <TextField
                  disabled
                  label="Wire Routing No"
                  value={value.wireRoutingNo}
                />
              </div>
            </div>
            <div className="grd-row">
              <div className="grd-cell">
                <TextField
                  disabled
                  label="Bank Account Type"
                  value={value.bankAccountType || ''}
                />
              </div>
              <div className="grd-cell">
                <TextField
                  disabled
                  label="Approved Method"
                  value={value.approvedMethod || ''}
                />
              </div>
              <div className="grd-cell">
                <TextField
                  disabled
                  label="Plaid Access Token"
                  value={value.plaidAccessToken}
                />
              </div>
            </div>
            <div className="grd-row">
              <div className="grd-cell">
                <TextField disabled label="Status" value={value.status || ''} />
              </div>
              <div className="grd-cell">
                <TextField
                  disabled
                  label="Bank Identifier Code (BIC)"
                  value={value.bankIdentifierCode}
                />
              </div>
              <div className="grd-cell">
                <SelectGLBankAccount
                  disabled
                  name="glAccountId"
                  label="GL Account"
                  value={value.glAccountId}
                />
              </div>
            </div>
          </div>
          <div className={classes.modalFooter}>
            <div className="grd-row">
              <div className="grd-cell" style={{ marginRight: 10 }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    handleClose()
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}
