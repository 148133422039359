import React, { useState } from 'react';
import { InputLabel, Select, MenuItem, FormControl, FormHelperText, Tooltip } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { listAccountType, listSubAccountType } from '../../services/ClientAccountService';
import { ReactComponent as Info } from '../../assets/icons/info.svg';
import color from '../../assets/css/colors';

const useStyles = makeStyles(() => ({
  dropdownWrapper: {
    display: 'flex',
    position: 'relative',
  },
  tooltipWrapper: {
    position: 'absolute',
    top: 29,
    right: 16,
    padding: '10px 10px 7px 10px',
    backgroundColor: color.light.opacity,
    borderRadius: 5,
    '& svg': {
      width: 24,
      height: 24,
      cursor: 'help',
      '& path': {
        fill: color.primary.main,
      },
    },
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
}));

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: color.light.main,
    maxWidth: 620,
    border: '1px solid ' + color.light.light,
    borderRadius: 10,
  },
}))(Tooltip);

function SelectItem(props) {
  const classes = useStyles();

  const [selectedAccountType, setSelectedAccountType] = useState('');
  const [mounted, setMounted] = React.useState(false);
  const [items, setItems] = useState([]);

  const getAccountTypeItems = async () => {
    const { itemsList } = await listAccountType();

    setItems(itemsList);
  };

  const getSubAccountTypeItems = async (accountType) => {
    const { itemsList } = await listSubAccountType(accountType);

    setItems(itemsList);
  };

  React.useEffect(() => {
    // Refresh
    if (mounted) {
      if (props.type === 'Sub Account Type') {
        if (
          selectedAccountType !== props.selectedAccountType &&
          props.selectedAccountType !== undefined
        ) {
          setSelectedAccountType(props.selectedAccountType);
          getSubAccountTypeItems(props.selectedAccountType);
        }
      }
    }
    // Initial load
    // In Client Account Modal, when switching tabs, all select fields state are wiped out
    if (!items.length && !mounted) {
      if (props.type === 'Account Type') {
        getAccountTypeItems();
      }
      if (props.type === 'Sub Account Type') {
        setSelectedAccountType(props.selectedAccountType);
        getSubAccountTypeItems(props.selectedAccountType);
      }
    }
    return () => {
      setMounted(true);
    };
    // eslint-disable-next-line
  }, [items, mounted, props.selectedAccountType]);

  return (
    <div className={classes.dropdownWrapper}>
      <FormControl
        fullWidth
        error={props.error}
        style={{
          marginTop: props.mt ? props.mt : 0,
          marginBottom: props.mb ? props.mb : 0,
        }}
      >

        <InputLabel
          className={props.disabled ? classes.disabled : ''}
          shrink
          required={props.required}
        >
          {props.label}
        </InputLabel>

        <Select
          displayEmpty
          fullWidth
          defaultValue=""
          disabled={props.disabled}
          name={props.name}
          value={props.value ? props.value : ''}
          onChange={props.onChange}
        >
          <MenuItem key="blank" value="" disabled={props.required}>
            Blank
          </MenuItem>
          {items.length ? (
            items.map((item, index) => (
              <MenuItem key={index} value={item?.value}>
                {item.label !== '' ? item?.label : 'Blank'}
              </MenuItem>
            ))
          ) : (
            <MenuItem key="" value=""></MenuItem>
          )}
        </Select>
        {props.error && <FormHelperText>{props.error ? props.msg : ''}</FormHelperText>}
      </FormControl>
      {(props.tooltip || props.htmlTooltip) && (
        <div className={classes.tooltipWrapper}>
          {props.tooltip ? (
            <Tooltip arrow placement="top" title={props.tooltip}>
              <Info />
            </Tooltip>
          ) : (
            <HtmlTooltip
              placement="top"
              title={<React.Fragment>{props.htmlTooltip}</React.Fragment>}
            >
              <Info />
            </HtmlTooltip>
          )}
        </div>
      )}
    </div>
  );
}

export function SelectAccountType(props) {
  return (
    <SelectItem
      required={props.required}
      disabled={props.disabled}
      label="Account Type"
      type="Account Type"
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      tooltip={props.tooltip}
      msg={props.msg}
      error={props.error}
    ></SelectItem>
  );
}

export function SelectSubAccountType(props) {
  return (
    <SelectItem
      required={props.required}
      label="Sub Account Type"
      type="Sub Account Type"
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      selectedAccountType={props.selectedAccountType}
      tooltip={props.tooltip}
      msg={props.msg}
      error={props.error}
    ></SelectItem>
  );
}
