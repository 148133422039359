import {
  DailyTradeConfirmServiceClient,
  ListDailyTradeConfirmRequest,
  DownloadDailyTradeConfirmationRequest,
  BatchDownloadDailyTradeConfirmationRequest,
} from "../proto/reportpb/dailytradeconfirm_grpc_web_pb";
import download from './DownloadService';
import { stringToProtoDate } from "./ConvertService";
import authSvc from './AuthService';
import { auth } from "../lib/auth/Auth";

const service = new DailyTradeConfirmServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });

const userId = authSvc.getCurrentUser()?.UserId;

export async function listDailyTradeConfirm(param) {
  return new Promise((resolve, reject) => {
    let req = new ListDailyTradeConfirmRequest();
    req.setFromDate(stringToProtoDate(param.fromDate));
    req.setToDate(stringToProtoDate(param.toDate));
    req.setCorrespondent(param.correspondent);
    req.setBranch(param.branch);
    req.setAccountNo(param.accountNo);
    req.setAccountName(param.accountName);
    req.setMasterAccountNo(param.masterAccountNo);
    req.setRep(param.rep);
    req.setUsrId(userId);

    service.listDailyTradeConfirm(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function downloadDailyTradeConfirmation(params) {
  return new Promise((resolve, reject) => {
    let req = new DownloadDailyTradeConfirmationRequest();
    req.setTradeDate(params.tradeDate);
    req.setAccountId(params.accountId);

    service.downloadDailyTradeConfirmation(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        download(response);
        resolve(response.toObject());
      }
    });
  });
}

const requestTradeConfirm = (data) => {
  let req = new DownloadDailyTradeConfirmationRequest();
  req.setTradeDate(data.tradeDate);
  req.setAccountId(data.accountId);

  return req;
};

export async function batchDownloadDailyTradeConfirmation(params) {
  return new Promise((resolve, reject) => {
    let batch = [];

    params.forEach(function(data) {
      const tradeConfirm = requestTradeConfirm(data);
      batch.push(tradeConfirm);
    });

    let req = new BatchDownloadDailyTradeConfirmationRequest();
    req.setTradeConfirmRequestsList(batch);
    
    service.batchDownloadDailyTradeConfirmation(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        download(response);
        resolve(response.toObject());
      }
    });
  });
}