import React, { useState, useEffect } from 'react';
import AdministratorModal from './AdministratorModal';
import MultiFactorAuthenticationModal from '../../../components/Modals/MultiFactorAuthenticationModal';
import { verifyToken } from 'authenticator';
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from '../../../components/Messages/Notification';
import {
  createAdministrator,
  listAdministrator,
  deleteAdministrator,
  updateAdministrator,
  createAccess,
  updateAccess,
  setKey,
  createCorrespondentAccess,
} from '../../../services/AdministratorService';

import { useConfirm } from 'material-ui-confirm';

import { CircularProgress, IconButton } from '@material-ui/core';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  VpnKey as LockIcon,
} from '@material-ui/icons';

import { validatePassword } from '../../../lib/validate/validate';

import AutoCompleteAdministratorEmail from '../../../components/AutoComplete/AutoCompleteAdministratorEmail';
import AutoCompleteAdministratorName from '../../../components/AutoComplete/AutoCompleteAdministratorName';
import SelectSystemCode from '../../../components/Dropdown/SelectSystemCode';
import ClientGrid from '../../../components/ClientGrid/ClientGrid';
import SelectRole from '../../../components/Dropdown/SelectRole';
import Button from '../../../components/Buttons/Button';
import {
  pbDateTime,
  pbDateTimeSorter,
} from '../../../components/ClientGrid/CellRenderer';

/*Moment JS*/
import moment from 'moment-timezone';

export default function AdministratorTable(params) {
  const [rowsChecked, setRowsChecked] = useState([]);
  const [selectedRowsIds, setSelectedRowsIds] = React.useState([]);
  const confirm = useConfirm();

  const options = {
    isRowSelectable: (dataIndex) => isDeleteEnabled(dataIndex),
    rowsSelected: rowsChecked,
    selectableRowsHeader: false,
    customToolbarSelect: (selectedRows, a, b) => {},
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      let currentIndex = currentRowsSelected[0].index;
      if (rowsSelected?.includes(currentIndex)) {
        updateAdmin(currentIndex, params.id);
      } else {
        updateAdmin(currentIndex, 0);
      }
    },
  };

  const GridButtons = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    return (
      <div align={'left'} className="grd-row" style={{ margin: 0, padding: 0 }}>
        <div className="grd-cell-none">
          <IconButton
            aria-label="delete"
            disabled={!isDeleteEnabled(rowIndex - 1) || loading.delete}
            onClick={() => {
              handleDelete([{ dataIndex: rowIndex - 1 }], [rowIndex - 1]);
            }}
          >
            {loading.delete && currentSelectedRowsIndex.includes(rowIndex - 1) ? (
              <CircularProgress style={{ color: '#1e7dff', height: 20, width: 20 }} />
            ) : (
              <DeleteIcon style={{ height: 20, width: 20 }} />
            )}
          </IconButton>
          <IconButton
            aria-label="edit"
            disabled={loading.delete}
            onClick={() => handleOpen(data)}
          >
            <EditIcon style={{ height: 20, width: 20 }} />
          </IconButton>
          <IconButton
            aria-label="2fa"
            disabled={loading.delete}
            onClick={() => handle2faOpen(data)}
          >
            <LockIcon style={{ height: 20, width: 20 }} />
          </IconButton>
        </div>
      </div>
    );
  };

  const GridCheckbox = ({
    tableManager,
    value,
    data,
    column,
    colIndex,
    rowIndex,
    onChange,
    disabled,
  }) => {
    const userIds = tableManager.config.additionalProps.header.props.rowsChecked;
    const checkedValue = userIds.includes(data.usrId);
    const rows = tableManager.rowsApi.rows;
    return (
      <div style={{ paddingLeft: 10 }}>
        <input
          key={rowIndex}
          type="checkbox"
          checked={checkedValue}
          disabled={disabled}
          column={column}
          onChange={(event) => {
            const checked = event.target.checked;
            if (checked) {
              updateAdmin(rowIndex - 1, params.id, rows);
            } else {
              updateAdmin(rowIndex - 1, 0, rows);
            }
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      id: 'user',
      width: '35px',
      visible: true,
      cellRenderer: GridCheckbox,
    },
    {
      id: '',
      field: '',
      width: '80px',
      cellRenderer: GridButtons,
    },
    {
      id: 'name',
      field: 'name',
      label: 'User',
    },
    {
      id: 'roleName',
      field: 'roleName',
      label: 'Role Name',
    },
    {
      id: 'email',
      field: 'email',
      label: 'Email',
      width: '300px',
    },
    {
      id: 'mobileNo',
      field: 'mobileNo',
      label: 'Mobile No',
    },
    {
      id: 'userType',
      field: 'userType',
      label: 'User Type',
    },
    {
      id: 'status',
      field: 'status',
      label: 'Status',
    },
    {
      id: 'authenticationMode',
      field: 'authenticationMode',
      label: 'Authentication Mode',
    },
    {
      id: 'createdAt',
      field: 'createdAt',
      label: 'Created At',
      cellRenderer: pbDateTime,
      sort: pbDateTimeSorter,
      visible: false,
    },
    {
      id: 'modifiedBy',
      field: 'modifiedBy',
      label: 'Modified By',
    },
  ];

  const isDeleteEnabled = (dataIndex) => {
    return rows[dataIndex]?.status !== 'Inactive';
  };

  const updateAdmin = async (dataIndex, newRoleId, rows) => {
    try {
      rows[dataIndex].roleId = newRoleId;
      rows[dataIndex].userType = '';
      await updateAdministrator(rows[dataIndex], rows);
    } catch (error) {
      console.error(error);
    }
    newRoleId === 0
      ? notifySuccess(
          rows[dataIndex].name + ' has been successfully removed from this Role'
        )
      : notifySuccess(rows[dataIndex].name + ' has been successfully added to this Role');

    handleSearch(true);
  };

  const [rowData, setRowData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [open2fa, set2faOpen] = React.useState(false);
  const [loading, setLoading] = React.useState({
    search: false,
    save: false,
    delete: false,
  });
  const [currentSelectedRowsIndex, setCurrentSelectedRowsIndex] = React.useState([]);
  const [showFilter, setShowFilter] = React.useState(true);
  const [rows, setRows] = useState([]);
  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [searchData, setSearchData] = React.useState({
    name: '',
    email: '',
    userType: '',
    status: 'Active',
    roleId: 0,
    roleName: '',
  });

  useEffect(() => {
    handleSearch();
  }, []);

  const handleChange = (e, x) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    if (['roleName'].includes(input.name)) {
      searchData.roleId = input.roleId;
      searchData.roleName = input.value;
    }

    setSearchData({
      ...searchData,
      [input.name]: input.value,
    });
  };

  const handleSearch = async (dontNotify) => {
    try {
      setLoading({ ...loading, search: true });

      const data = await listAdministrator(searchData);
      const rows = data.administratorsList.map((data) => ({
        usrId: data.usrId,
        roleName: data.roleName,
        roleId: data.roleId,
        name: data.name,
        email: data.email,
        createdAt: data.createdAt,
        access: data.access,
        // password: data.password,
        // passwordConfirm: data.password,
        defaultAccess: data.defaultAccess,
        mobileNo: data.mobileNo,
        userType: data.userType,
        status: data.status,
        admin: data.admin,
        correspondent: data.correspondent,
        authenticationMode: data.authenticationMode,
        secretKey: data.secretKey,
        authenticationText: data.authenticationMode.includes('Text'),
        authenticationEmail: data.authenticationMode.includes('Email'),
        authenticationAuthenticator: data.authenticationMode.includes('Authenticator'),
        modifiedBy: data.modifiedBy,
      }));
      setRows(rows);
      setRowsChecked(
        data.administratorsList.map((data) => {
          if (data.roleId === params.id) {
            return data.usrId;
          }
        })
      );
      if (!dontNotify) notifyInfo(data.administratorsList.length + ' search results.');
    } catch (error) {
      console.error(error);
      notifyError(error.message);
    } finally {
      setLoading({ ...loading, search: false });
    }
  };

  const handleOpen = (data, isAdd) => {
    if (isAdd) {
      data = {
        usrId: 0,
        name: '',
        email: '',
        password: '',
        passwordConfirm: '',
        defaultAccess: '',
        userType: 'Employee',
        status: 'Active',
        admin: false,
        correspondent: '',
        authenticationMode: 'Email',
        authenticationText: false,
        authenticationEmail: false,
        authenticationAuthenticator: false,
      };
      setModalAddAction(true);
    } else {
      data.correspondent = data.correspondent.trim();
      setModalAddAction(false);
    }
    setRowData(data);
    setOpen(true);
  };

  const handle2faOpen = (data) => {
    data.correspondent = data.correspondent.trim();
    data.otPath = '';
    data.code = '';
    setRowData(data);
    set2faOpen(true);
  };

  const handle2faClose = async (data, action) => {
    var valid = true;
    if (action === 'verify') {
      if (data.code === '') {
        notifyError('Please input verification code.');
        valid = false;
      }
      if (verifyToken(data.secretKey, data.code) == null) {
        notifyError('Invalid verification code.');
        valid = false;
      } else {
        try {
          const resp = await setKey(data);
          var response = resp;
          response.createdAt = response.createdAt
            ? moment(new Date(response.createdAt.seconds * 1000)).format(
                'MM/DD/YYYY hh:mm'
              )
            : '--';
          response.password = '';
          notifySuccess('Setup account success.');

          const rowsCopy = [...rows];
          const index = rows.indexOf(rowData);
          rowsCopy[index] = response;
          setRows(rowsCopy);
          set2faOpen(false);
        } catch (error) {
          notifyError(error.message);
        }
      }
    } else {
      set2faOpen(false);
    }
  };

  const handleClose = async (data, isAdd, access, originalAccess, correspondents) => {
    if (!data) {
      setOpen(false);
      return;
    }
    let valid = true;
    try {
      if (!data.name) {
        notifyError('Name is required.');
        valid = false;
      }

      if (!data.email) {
        notifyError('Email is required.');
        valid = false;
      }

      // if (data.userType != 'Client' && data.userType != 'Employer Member') {
      //   if (!data.defaultAccess || data.defaultAccess === '') {
      //     notifyError('Default Page Access is required.');
      //     valid = false;
      //   }
      // }

      if (!data.mobileNo) {
        notifyError('Mobile No is required.');
        valid = false;
      }

      if (!data.userType) {
        notifyError('User type is required.');
        valid = false;
      }

      if (!data.status) {
        notifyError('Status is required.');
        valid = false;
      }

      if (isAdd) {
        if (!data.password) {
          notifyError('Password is required.');
          valid = false;
        }

        if (data.password !== data.passwordConfirm) {
          notifyError('Password does not match.');
          valid = false;
        }

        if (validatePassword(data.password).err) {
          notifyError('Password does not meet the requirement.');
          valid = false;
        }

        if (validatePassword(data.passwordConfirm).err) {
          notifyError(' does not meet the requirement.');
          valid = false;
        }
      } else {
        if (data.password) {
          if (data.password !== data.passwordConfirm) {
            notifyError('Password does not match.');
            valid = false;
          }

          if (validatePassword(data.password).err) {
            notifyError('Password does not meet the requirement.');
            valid = false;
          }

          if (validatePassword(data.passwordConfirm).err) {
            notifyError('Confirm Password does not meet the requirement.');
            valid = false;
          }
        }
      }

      if (
        !data.authenticationEmail &&
        !data.authenticationText &&
        !data.authenticationAuthenticator
      ) {
        notifyError('At least 1 authentication mode is required.');
        valid = false;
      } else {
        var mode = '';
        mode = data.authenticationEmail ? 'Email,' : '';
        mode = mode + (data.authenticationText ? 'Text,' : '');
        mode = mode + (data.authenticationAuthenticator ? 'Authenticator,' : '');
        data.authenticationMode = mode;
      }

      if (data.userType !== 'Client') {
        data.admin = '';
      }

      if (data.userType !== 'Client') {
        data.correspondent = '';
      }

      if (!valid) {
        return;
      }

      setLoading({ ...loading, save: true });

      if (isAdd) {
        //ADD
        const resp = await createAdministrator(data);
        const response = resp.administrator;
        response.createdAt = response.createdAt
          ? moment(new Date(response.createdAt.seconds * 1000)).format('MM/DD/YYYY hh:mm')
          : '--';
        response.password = '';
        setRows([response, ...rows]);

        for (const r of access) {
          let newAccess = r;
          newAccess.usrId = response.usrId;
          await createAccess(newAccess);
        }

        if ((data.userType === 'Client' && data.admin) || data.userType === 'Employee') {
          // Save Correspondent Access
          for (const r of correspondents) {
            var newCorrespondentAccess = r;
            newCorrespondentAccess.usrId = response.usrId;
            await createCorrespondentAccess(newCorrespondentAccess);
          }
        }

        notifySuccess('New Administrator has been added.');
      } else {
        const resp = await updateAdministrator(data);
        const response = resp.administrator;

        response.createdAt = response.createdAt
          ? moment(new Date(response.createdAt.seconds * 1000)).format('MM/DD/YYYY hh:mm')
          : '--';
        response.password = '';
        response.authenticationText = response.authenticationMode.includes('Text');
        response.authenticationEmail = response.authenticationMode.includes('Email');
        response.authenticationAuthenticator =
          response.authenticationMode.includes('Authenticator');
        const rowsCopy = [...rows];
        const index = rows.indexOf(rowData);
        rowsCopy[index] = response;
        setRows(rowsCopy);

        const accessToUpdate = access.filter(
          (updatedAccess) =>
            !originalAccess.some(
              (origAccess) => updatedAccess.accessId === origAccess.accessId
            ) ||
            originalAccess.some(
              (origAccess) =>
                updatedAccess.accessId === origAccess.accessId &&
                (updatedAccess.access !== origAccess.access ||
                  updatedAccess.subAccess !== origAccess.subAccess)
            )
        );

        for (const r of accessToUpdate) {
          let newAccess = r;
          newAccess.usrId = response.usrId;
          await updateAccess(newAccess);
        }

        notifySuccess('An Administrator has been updated.');
      }
      setOpen(false);
    } catch (error) {
      console.error(error);
      setLoading({ ...loading, save: false });
      notifyError(error.message);
    }

    handleSearch();
    setLoading({ ...loading, save: false });
  };

  const handleDelete = (rowsToDelete, index) => {
    let messageKey = '';
    if (rowsToDelete.length === 1) {
      messageKey = rows[rowsToDelete[0].dataIndex].name;
    } else {
      messageKey = rowsToDelete.length + ' items';
    }
    let idsToDelete = [];
    confirm({
      description:
        "You are about to delete '" + messageKey + "'. Please confirm your action.",
      confirmationText: 'Yes, delete',
    }).then(async () => {
      const rowsCopy = [...rows];
      try {
        setCurrentSelectedRowsIndex(index);
        setLoading({ ...loading, delete: true });

        for (const r of rowsToDelete) {
          const index = r.dataIndex;
          await deleteAdministrator(rowsCopy[index].usrId);
          idsToDelete.push(rowsCopy[index].usrId);
        }
      } catch (error) {
        console.error(error);
        notifyError(error.message);
      } finally {
        for (let i = 0; i < rowsCopy.length; i++) {
          if (idsToDelete.includes(rowsCopy[i].usrId)) {
            rowsCopy[i].status = 'Inactive';
          }
        }
        setRows(rowsCopy);
        setLoading({ ...loading, delete: false });
        setCurrentSelectedRowsIndex([]);
        notifySuccess(messageKey + ' has been deleted');
      }
    });
  };

  const getCsvData = async () => {
    return rows;
  };

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell"></div>
          <div className="grd-cell-none">
            <Button
              disabled={loading.search}
              loading={loading.search}
              type="search"
              label={loading.search ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <AutoCompleteAdministratorName
              name="name"
              label="User"
              freeSolo={true}
              value={searchData.name}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => (e.keyCode === 13 ? handleSearch() : null)}
            ></AutoCompleteAdministratorName>
          </div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <AutoCompleteAdministratorEmail
              name="email"
              label="Email"
              freeSolo={true}
              value={searchData.email}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => (e.keyCode === 13 ? handleSearch() : null)}
            ></AutoCompleteAdministratorEmail>
          </div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <SelectSystemCode
              name="userType"
              label="User Type"
              type="User Type"
              value={searchData.userType}
              onChange={handleChange}
            ></SelectSystemCode>
          </div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <SelectRole
              name="roleName"
              label="Role Name"
              value={searchData.roleName}
              onChange={handleChange}
            ></SelectRole>
          </div>
          <div className="grd-cell">
            <SelectSystemCode
              name="status"
              label="Status"
              type="Status"
              subType="AI"
              value={searchData.status}
              onChange={handleChange}
            ></SelectSystemCode>
          </div>
        </div>
      </div>
      <div className="mt-20">
        <div
          className="grd-cell"
          style={{
            textAlign: 'right',
            marginTop: 30,
            color: '#1e7dff',
          }}
        >
          <small>
            Changes will be saved automatically, but will reflect on next search.
          </small>
        </div>
        <ClientGrid
          title="User"
          rowIdField="id"
          columns={columns}
          rows={rows}
          setRows={setRows}
          isLoading={loading.search}
          getCsvData={getCsvData}
          onSelectedRowsChange={(selectedRowsIds) => {
            setSelectedRowsIds(selectedRowsIds);
          }}
          selectedRowsIds={selectedRowsIds}
          rowsChecked={rowsChecked}
        />
      </div>
      {open && (
        <AdministratorModal
          onClose={handleClose}
          open={open}
          add={modalAddAction}
          value={rowData}
          loading={loading.save}
        ></AdministratorModal>
      )}
      {open2fa && (
        <MultiFactorAuthenticationModal
          onClose={handle2faClose}
          open={open2fa}
          add={modalAddAction}
          id={rowData?.usrId}
          loading={loading.save}
        ></MultiFactorAuthenticationModal>
      )}
    </div>
  );
}
