import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { 
    Modal, 
    Backdrop,
    Fade,
    InputAdornment, 
} from '@material-ui/core'
import Text from '../../../../components/Typography/Text'
import Button from '../../../../components/Buttons/Button'
import CloseButton from '../../../../components/Buttons/CloseButton'
import fontFamily from '../../../../assets/css/fonts'
import color from '../../../../assets/css/colors'
import TextField from '../../../../components/Textfields/TextField'
import AutoCompleteSymbol from '../../../../components/AutoComplete/AutoCompleteSymbol'
import SelectSystemCode from '../../../../components/Dropdown/SelectSystemCode'
import TextEditor from '../../../../components/TextEditor/TextEditor'
import { dateProtoObjectToString } from '../../../../services/ConvertService'
import { getSystemDate } from '../../../../services/ProfileService'
import { listOriginalCusip } from '../../../../services/CommonService'
import { notifyWarning } from '../../../../components/Messages/Notification'

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
}))

export default function MergerModal(props) {
  const classes = useStyles()

  const { data, open, close, isEdit, loading } = props

  const [modalData, setModalData] = React.useState({})

  React.useEffect(() => {
    async function init() {
      let modalDataCopy = {}
      if (data && data.textNumber !== '') {
        modalDataCopy = { ...data };

        modalDataCopy.systemDate = dateProtoObjectToString(
          modalDataCopy.systemDate
        );
        modalDataCopy.effectiveDate = dateProtoObjectToString(
          modalDataCopy.effectiveDate
        );
        modalDataCopy.processDate = dateProtoObjectToString(
          modalDataCopy.processDate
        );
        modalDataCopy.positionDate = dateProtoObjectToString(
          modalDataCopy.positionDate
        );
        modalDataCopy.recordDate = dateProtoObjectToString(
          modalDataCopy.recordDate
        );
      } else {
        const systemDate = await getSystemDate();
        modalDataCopy.systemDate = systemDate;
      }
      setModalData({ ...modalDataCopy });
    }
    if (open) {
      init();
    }
  }, [open, data]);

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target

    setModalData({ ...modalData, [input.name]: input.value })
  }

  const getTartgetOriginalCusip = async () => {
    if (modalData.targetSymbol) {
      await listOriginalCusip(modalData.systemDate, modalData.targetSymbol)
        .then((res) => {
          setModalData({
            ...modalData,
            targetOriginalCusip: res.originalCusip,
          });
        })
        .catch((err) => {
          notifyWarning(err);
        });
    }
  };

  const getInitiatingOriginalCusip = async () => {
    if (modalData.initiatingSymbol) {
      await listOriginalCusip(modalData.systemDate, modalData.initiatingSymbol)
        .then((res) => {
          setModalData({
            ...modalData,
            initiatingOriginalCusip: res.originalCusip,
          });
        })
        .catch((err) => {
          notifyWarning(err);
        });
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => close()}
      closeAfterTransition
      BackdropComponent={Backdrop} 
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div style={{ width: 800 }}>
          <div className={classes.modalHeader}>
            <div>
              <Text
                mt={4}
                variant="h2"
                label={(isEdit ? 'Edit ' : 'Add ')+ 'Merger'}
              />
            </div>
            <div className={classes.closeButton}>
              <CloseButton onClick={() => close()} />
            </div>
          </div>
          <div className={classes.modalBody}>
            <div className="grd-row nm">
              <div className="grd-cell">
                <TextField
                    required={true}
                    disabled={isEdit}
                    fullWidth
                    name="textNumber"
                    label="Text Number"
                    max={20}
                    value={modalData.textNumber}
                    onChange={handleChange}
                />
              </div>
              <div className="grd-cell">
                <TextField
                    required={true}
                    disabled={isEdit}
                    name="systemDate"
                    label="System Date"
                    type="date"
                    value={modalData.systemDate}
                    onChange={handleChange}
                />
              </div>
              <div className="grd-cell">
                <TextField
                    fullWidth
                    required={true}
                    disabled={isEdit}
                    name="effectiveDate"
                    label="Effective Date"
                    type="date"
                    value={modalData.effectiveDate}
                    onChange={handleChange}
                />
              </div>
            </div>
            <div className="grd-row">
              <div className="grd-cell">
                  <AutoCompleteSymbol
                    fullWidth
                    freeSolo={true}
                    required={true}
                    disabled={isEdit}
                    name="targetSymbol"
                    label="Target Symbol"
                    value={modalData.targetSymbol}
                    onBlur={getTartgetOriginalCusip}
                    onChange={handleChange}
                  />
              </div>
              <div className="grd-cell">
                <TextField
                    fullWidth
                    required={true}
                    name="targetOriginalCusip"
                    label="Target Original Cusip"
                    value={modalData.targetOriginalCusip || ''}
                    onChange={handleChange}
                    max={9}
                />
              </div>
              <div className="grd-cell">
                  <AutoCompleteSymbol
                    fullWidth
                    freeSolo={true}
                    name="initiatingSymbol"
                    label="Initiating Symbol"
                    value={modalData.initiatingSymbol}
                    onBlur={getTartgetOriginalCusip}
                    onChange={handleChange}
                  />
              </div>
            </div>
            <div className="grd-row">
              <div className="grd-cell">
                  <TextField
                    fullWidth
                    name="initiatingOriginalCusip"
                    label="Initiating Original Cusip"
                    value={modalData.initiatingOriginalCusip || ''}
                    onChange={handleChange}
                    max={9}
                  />
              </div>
              <div className="grd-cell">
                  <TextField
                    name="cash"
                    label="Cash"
                    type="number"
                    value={modalData.cash}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
              </div>
              <div className="grd-cell">
                  <TextField
                    fullWidth={true}
                    name="newRate"
                    label="New Qty"
                    type="number"
                    value={modalData.newRate}
                    onChange={handleChange}
                  />
              </div>
            </div>
            <div className="grd-row">
              <div className="grd-cell">
                  <TextField
                    fullWidth={true}
                    name="oldRate"
                    label="Old Qty"
                    type="number"
                    value={modalData.oldRate}
                    onChange={handleChange}
                  />
              </div>
              <div className="grd-cell">
                  <TextField
                    fullWidth
                    required={true}
                    name="recordDate"
                    label="Record Date"
                    type="date"
                    value={modalData.recordDate}
                    onChange={handleChange}
                  />
              </div>
              <div className="grd-cell">
                <TextField
                    required={true}
                    name="positionDate"
                    label="Position Date"
                    type="date"
                    value={modalData.positionDate}
                    onChange={handleChange}
                />
              </div>
            </div>
            <div className="grd-row">
              <div className="grd-cell">
                <TextField
                    required={true}
                    name="processDate"
                    label="Process Date"
                    type="date"
                    value={modalData.processDate}
                    onChange={handleChange}
                />
              </div>
                <div className="grd-cell">
                    <SelectSystemCode
                        required
                        name="status"
                        label="Status"
                        type="Status"
                        subType="AI"
                        placeholder="Blank"
                        value={modalData.status}
                        onChange={handleChange}
                    />
                </div>
                <div className="grd-cell"></div>
            </div>
            <div className="grd-row">
              <div className="grd-cell">
                <TextEditor
                    name="detail"
                    label="Details"
                    value={modalData.detail || ''}
                    onChange={handleChange}
                    enableToolbar={true}
                />
              </div>
            </div>
          </div>
          <div className={classes.modalFooter}>
            <Button
              disabled={loading}
              loading={loading}
              label={loading ? 'Saving...' : 'Save'}
              onClick={() => close(modalData)}
            />
          </div>
        </div>
      </Fade>
    </Modal>
  )
}
