/**
 * @fileoverview gRPC-Web generated client stub for commonpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.commonpb = require('./ticket_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.commonpb.TicketServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.commonpb.TicketServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.Ticket,
 *   !proto.commonpb.CreateTicketResponse>}
 */
const methodDescriptor_TicketService_CreateTicket = new grpc.web.MethodDescriptor(
  '/commonpb.TicketService/CreateTicket',
  grpc.web.MethodType.UNARY,
  proto.commonpb.Ticket,
  proto.commonpb.CreateTicketResponse,
  /**
   * @param {!proto.commonpb.Ticket} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.CreateTicketResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.commonpb.Ticket,
 *   !proto.commonpb.CreateTicketResponse>}
 */
const methodInfo_TicketService_CreateTicket = new grpc.web.AbstractClientBase.MethodInfo(
  proto.commonpb.CreateTicketResponse,
  /**
   * @param {!proto.commonpb.Ticket} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.CreateTicketResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.Ticket} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.CreateTicketResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.CreateTicketResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.TicketServiceClient.prototype.createTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.TicketService/CreateTicket',
      request,
      metadata || {},
      methodDescriptor_TicketService_CreateTicket,
      callback);
};


/**
 * @param {!proto.commonpb.Ticket} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.CreateTicketResponse>}
 *     A native promise that resolves to the response
 */
proto.commonpb.TicketServicePromiseClient.prototype.createTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.TicketService/CreateTicket',
      request,
      metadata || {},
      methodDescriptor_TicketService_CreateTicket);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.Ticket,
 *   !proto.commonpb.UpdateTicketResponse>}
 */
const methodDescriptor_TicketService_UpdateTicket = new grpc.web.MethodDescriptor(
  '/commonpb.TicketService/UpdateTicket',
  grpc.web.MethodType.UNARY,
  proto.commonpb.Ticket,
  proto.commonpb.UpdateTicketResponse,
  /**
   * @param {!proto.commonpb.Ticket} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.UpdateTicketResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.commonpb.Ticket,
 *   !proto.commonpb.UpdateTicketResponse>}
 */
const methodInfo_TicketService_UpdateTicket = new grpc.web.AbstractClientBase.MethodInfo(
  proto.commonpb.UpdateTicketResponse,
  /**
   * @param {!proto.commonpb.Ticket} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.UpdateTicketResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.Ticket} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.UpdateTicketResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.UpdateTicketResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.TicketServiceClient.prototype.updateTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.TicketService/UpdateTicket',
      request,
      metadata || {},
      methodDescriptor_TicketService_UpdateTicket,
      callback);
};


/**
 * @param {!proto.commonpb.Ticket} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.UpdateTicketResponse>}
 *     A native promise that resolves to the response
 */
proto.commonpb.TicketServicePromiseClient.prototype.updateTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.TicketService/UpdateTicket',
      request,
      metadata || {},
      methodDescriptor_TicketService_UpdateTicket);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.ListTicketRequest,
 *   !proto.commonpb.ListTicketResponse>}
 */
const methodDescriptor_TicketService_ListTicket = new grpc.web.MethodDescriptor(
  '/commonpb.TicketService/ListTicket',
  grpc.web.MethodType.UNARY,
  proto.commonpb.ListTicketRequest,
  proto.commonpb.ListTicketResponse,
  /**
   * @param {!proto.commonpb.ListTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.ListTicketResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.commonpb.ListTicketRequest,
 *   !proto.commonpb.ListTicketResponse>}
 */
const methodInfo_TicketService_ListTicket = new grpc.web.AbstractClientBase.MethodInfo(
  proto.commonpb.ListTicketResponse,
  /**
   * @param {!proto.commonpb.ListTicketRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.ListTicketResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.ListTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.commonpb.ListTicketResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.ListTicketResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.TicketServiceClient.prototype.listTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.TicketService/ListTicket',
      request,
      metadata || {},
      methodDescriptor_TicketService_ListTicket,
      callback);
};


/**
 * @param {!proto.commonpb.ListTicketRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.ListTicketResponse>}
 *     A native promise that resolves to the response
 */
proto.commonpb.TicketServicePromiseClient.prototype.listTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.TicketService/ListTicket',
      request,
      metadata || {},
      methodDescriptor_TicketService_ListTicket);
};


module.exports = proto.commonpb;

