import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment, Tooltip } from '@material-ui/core';
import color from '../../assets/css/colors'
import { ReactComponent as Code } from '../../assets/icons/table.svg';
import { ReactComponent as Info } from '../../assets/icons/info.svg';
import InfoIcon from '@material-ui/icons/Info';
import Timer from '../Timer/APWTimer';
import AppsIcon from '@material-ui/icons/Apps';
import PropTypes from 'prop-types';

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: color.light.main,
        maxWidth: 620,
        border: '1px solid ' + color.light.light,
        borderRadius: 10,
    },
}))(Tooltip);

const APWCodeField = (props) => {
    const { classes, timerInMinutes } = props

    const startIcon = () => {
        return (<InputAdornment position="start"><AppsIcon fontSize="small" /></InputAdornment>);
    }

    const endIcon = () => {
        return (<Timer minutes={timerInMinutes} seconds={0} setLoading={props.setLoading} countdownListener={props.countdownListener}/>);
    }

    return (
        <div className={classes.textFieldWrapper}>
            <TextField
                variant="outlined"
                margin='dense'
                fullWidth={props.fullWidth}
                autoFocus={props.autoFocus}
                autoComplete="off"
                error={props.error}
                required={props.required}
                disabled={props.disabled}
                id={props.id}
                name={props.name}
                label={props.label}
                placeholder={props.placeholder}
                helperText={props.error ? props.msg : ''}
                type="text"
                value={props.value || ''}
                onChange={(e) => {
                    const input = e.currentTarget.name ? e.currentTarget : e.target;
                    props.onChange(input.name, input.value.trimStart());
                }}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                onKeyUp={props.onKeyUp}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                    startAdornment: startIcon(),
                    endAdornment: props.startTimer ? endIcon() : null,
                }}
                inputProps={{
                    readOnly: props.readOnly ? true : false,
                    maxLength: props.max,
                    autoComplete: 'off',
                    color: 'primary',
                }}
            />
            {(props.tooltip || props.htmlTooltip) && (
                <div className={classes.tooltipWrapper} style={{ marginTop: props.mt ? 20 : 0 }}>
                    {props.tooltip ? (
                        <Tooltip
                            arrow
                            placement="top"
                            title={props.tooltip}
                        >
                            <InfoIcon fontSize="small" />
                        </Tooltip>
                    ) : (
                        <HtmlTooltip
                            placement="right"
                            title={<React.Fragment>{props.htmlTooltip}</React.Fragment>}
                        >
                            <InfoIcon fontSize="small" />
                        </HtmlTooltip>
                    )}
                </div>
            )}
        </div>
    );
}

APWCodeField.propTypes = {
    classes: PropTypes.object.isRequired, // PropTypes for styles
    theme: PropTypes.object.isRequired,   // PropTypes for theme
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    fullWidth: PropTypes.bool,
    startTimer: PropTypes.bool,
    timerInMinutes: (props, propName, componentName) => {
        if (props.startTimer !== undefined) {
          // Check if timerInMinutes is not provided or not a number
          if (props[propName] === undefined) {
            return new Error(
              `Required prop \`${propName}\` was not specified in \`${componentName}\` when \`startTimer\` is not null.`
            );
          }
          if (typeof props[propName] !== 'number') {
            return new Error(
              `Invalid prop \`${propName}\` supplied to \`${componentName}\`. It must be a number when \`startTimer\` is not null.`
            );
          }
        }
      },
      countdownListener: (props, propName, componentName) => {
        if (props.startTimer !== undefined) {
          // Check if countdownListener is not provided or not a function
          if (props[propName] === undefined) {
            return new Error(
              `Required prop \`${propName}\` was not specified in \`${componentName}\` when \`startTimer\` is not null.`
            );
          }
          if (typeof props[propName] !== 'function') {
            return new Error(
              `Invalid prop \`${propName}\` supplied to \`${componentName}\`. It must be a function when \`startTimer\` is not null.`
            );
          }
        }
      },
      setLoading: (props, propName, componentName) => {
        if (props.startTimer !== undefined) {
          // Check if countdownListener is not provided or not a function
          if (props[propName] === undefined) {
            return new Error(
              `Required prop \`${propName}\` was not specified in \`${componentName}\` when \`startTimer\` is not null.`
            );
          }
          if (typeof props[propName] !== 'function') {
            return new Error(
              `Invalid prop \`${propName}\` supplied to \`${componentName}\`. It must be a function when \`startTimer\` is not null.`
            );
          }
        }
      },
};

APWCodeField.defaultProps = {
    fullWidth: true,
    timerInMinutes: 2,
}

export default withStyles((theme) => ({
    textFieldWrapper: {
        display: 'flex',
        position: 'relative',
    },
    tooltipWrapper: {
        position: 'absolute',
        top: 31,
        right: 1,
        padding: '9px 10px',
        backgroundColor: color.light.opacity,
        borderRadius: 5,
        '& svg': {
            width: 21,
            height: 21,
            cursor: 'help',
            '& path': {
                fill: theme.palette.primary.main,
            }
        },
    },
    margin: {

    },
    icon: {
        '&:hover': {
            backgroundColor: 'transparent !important', // Set the background color to transparent on hover
        },
    },
}), { withTheme: true, name: 'APWCodeField' })(APWCodeField);