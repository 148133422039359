import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const QuestionCircleIcon = (props) => (
    <SvgIcon {...props} viewBox="0 0 23 24">
        <path d="M12 1.5C17.799 1.5 22.5 6.20101 22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5ZM12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM12 15.75C12.6213 15.75 13.125 16.2537 13.125 16.875C13.125 17.4963 12.6213 18 12 18C11.3787 18 10.875 17.4963 10.875 16.875C10.875 16.2537 11.3787 15.75 12 15.75ZM12 6C13.8102 6 15 7.33855 15 9C15 9.8325 14.6956 10.3363 13.8721 11.1854L13.6553 11.4053L13.3208 11.7499C12.9416 12.1568 12.789 12.3993 12.7567 12.6443L12.75 12.75V14.25H11.25V12.75L11.2565 12.5483C11.3043 11.8311 11.623 11.343 12.3779 10.5646L12.5947 10.3447L12.9292 10.0001C13.3626 9.53506 13.5 9.28483 13.5 9C13.5 8.1302 12.9398 7.5 12 7.5C11.2392 7.5 10.5979 8.23358 10.5102 8.85818L10.5 9H9L9.00779 8.80637C9.11635 7.44575 10.348 6 12 6Z" fill="#4A5560" />
    </SvgIcon>
);


export {
    QuestionCircleIcon
};
