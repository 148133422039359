import {
  Owner,
  DeleteOwnerRequest,
  OwnerServiceClient,
  BatchCreateOwnerRequest,
  ListOwnerAutoFillRequest,
} from '../proto/accountpb/owner_grpc_web_pb';
import { stringToProtoDate } from './ConvertService';
import { auth } from '../lib/auth/Auth';

const service = new OwnerServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });

export async function createOwner(param) {
  return new Promise((resolve, reject) => {
    const req = paramToOwner(param);

    service.createOwner(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateOwner(param) {
  return new Promise((resolve, reject) => {
    const req = paramToOwner(param);

    service.updateOwner(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function deleteOwner(id) {
  return new Promise((resolve, reject) => {
    const req = new DeleteOwnerRequest();
    req.setAccountId(id);

    service.deleteOwner(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

const paramToOwner = (param) => {
  const req = new Owner();
  req.setAccountId(param.accountId);
  req.setOwnerId(param.ownerId);
  req.setOwnerType(param.ownerType);
  req.setFirstName(param.firstName);
  req.setMiddleName(param.middleName);
  req.setLastName(param.lastName);
  req.setStatus(param.status);

  req.setDateOfBirth(stringToProtoDate(param.dateOfBirth));
  req.setCountry(param.country);
  req.setEmploymentStatus(param.employmentStatus);
  req.setEmploymentOccupation(param.employmentOccupation);
  req.setEmploymentName(param.employmentName);
  req.setPreviousEmploymentName(param.previousEmploymentName);

  req.setOwnership(param.ownership);
  req.setStockOwnership(param.stockOwnerShip);
  req.setBrokerMember(param.brokerMember);
  req.setBrokerName(param.brokerName);
  req.setAffiliatePerson(param.affiliatePerson);
  req.setAffiliatePersonPosition(param.affiliatePersonPosition);

  req.setOfacStatus(param.ofacStatus);
  req.setOfacDate(param.ofacDate);
  req.setKycStatus(param.kycStatus);
  req.setKycDate(param.kycDate);

  req.setAccountNo(param.accountNo);
  req.setCorrespondent(param.correspondent);
  req.setCitizenship(param.citizenship);

  req.setOfficer(param.officer);
  req.setOwner(param.owner);
  req.setOwnershipPercent('' + param.ownershipPercent + '');

  req.setMaritalStatus(param.maritalStatus);
  req.setNatureOfBusiness(param.natureOfBusiness);
  req.setYearsEmployed(param.yearsEmployed);
  req.setRelationship(param.relationship === 'Other' ? param.other : param.relationship);

  return req;
};

async function executeBatchCreateOwner(req) {
  return new Promise((resolve, reject) => {
    service.batchCreateOwner(req, {}, (error, response) => {
      if (error) {
        console.log(error);
        reject(error);
      } else {
        resolve(response.toObject());
        return response.toObject();
      }
    });
  });
}

export async function batchCreateOwner(param) {
  let listReq = requestOwnerParameter(param);
  let batchReq = new BatchCreateOwnerRequest();
  batchReq.setOwnerList(listReq);
  try {
    const res = await executeBatchCreateOwner(batchReq);
    return res.ownerResponsesList;
  } catch (err) {
    console.log(err);
    return err;
  }
}

const requestOwnerParameter = (list) => {
  let listReq = [];

  list.forEach(async (row) => {
    let req = paramToOwner(row);
    listReq.push(req);
  });

  return listReq;
};

export async function listOwnerAutoFill(account_id) {
  return new Promise((resolve, reject) => {
    const req = new ListOwnerAutoFillRequest();
    req.setAccountId(account_id);

    service.listOwnerAutoFill(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
