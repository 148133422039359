const palette = {
  axos: {
    primary: { 
      main: '#2F5B88',
      light: '#4D85B0',
      dark: '#051A3F',
      opacity: '#DEF4FF',
    },
    secondary: {
      main: '#f9a64a',
      light: '#693c00',
      dark: '#4a2800',
      opacity: '#ffdcbd',
    },
    success: {
      main: '#38D7A4', 
      light: '#5CEEBB',
      dark: '#00805B',
      opacity: '#EBFAF5',
    },
    info: {
      main: '#398DCB',
      light: '#6BBEED',
      dark: '#051A3F',
      opacity: '#CDEEFF',
    },
    warning: {
      main: '#FFCF99',
      light: '#FFDAB6',
      dark: 'D95F27',
      opacity: '#FFF3EB',
    },
    error: {
      main: '#E1315F',
      light: '#F46182',
      dark: '#97013A',
      opacity: '#FFE1E8',
    },
    default: {
      main: '#4A5560',
      light: '#D4D4D4',
      dark: '#1E262F',
      opacity: '#F4F4F4',
    },
    light: {
      main: '#FFFFFF',
      light: '#e5e6f0',
      dark: '#ececec',
      opacity: '#EBEBEB',
    },
    transparent: 'rgba(0,0,0,0.0)',
    specialty: {
      green100: '#EBFAF5',
      green200: '#9AFAD8',
      green300: '#5CEEBB',
      green400: '#38D7A4',
      green500: '#29B48B',
      green600: '#00805B',
      red100: '#FFE1E8',
      red200: '#FDA3B6',
      red300: '#F46182',
      red400: '#E1315F',
      red500: '#C01349',
      red600: '#97013A',
    },
    neutral: {
      white: '#FFFFFF',
      grey100: '#F4F4F4',
      grey200: '#EBEBEB',
      grey300: '#D4D4D4',
      grey400: '#BDBDBD',
      grey500: '#A6A6A6',
      grey600: '#8F8F8F',
      slate100: '#A0AAB3',
      slate200: '#7B858F',
      slate300: '#5E6A74',
      slate400: '#4A5560',
      slate500: '#333D46',
      slate600: '#1E262F',
      black100: '#787878',
      black200: '#616161',
      black300: '#494949',
      black400: '#323232',
      black500: '#1E1E1E',
      black600: '#000000',
    },
    semantic: {
      green: '#078761',
      red: '#D2272F',
      blue: '#0062B3',
    }
  },
  original: {
    primary: { 
      main: '#6754ff',
      light: '#6754ff',
      dark: '#4167cc',
      opacity: '#edf2ff',
    },
    secondary: {
      main: '#5741ff',
      light: '#897aff',
      dark: '#4534cc',
      opacity: '#ddd9ff',
    },
    success: {
      main: '#62d05a',
      light: '#98e791',
      dark: '#4b9a45',
      opacity: '#f0fbef',
    },
    info: {
      main: '#518EF8',
      light: '#a8c6fb',
      dark: '#4071c6',
      opacity: '#edf3fe',
    },
    warning: {
      main: '#ff9d42',
      light: '#ffc48d',
      dark: '',
      opacity: '#ffebd9',
    },
    error: {
      main: '#ff425f',
      light: '#ff7a8f',
      dark: '',
      opacity: '#ffeaec',
    },
    default: {
      main: '#3a327f',
      light: '#4d468b',
      dark: '#3d3299',
      opacity: '',
    },
    light: {
      main: '#FFFFFF',
      light: '#e5e6f0',
      dark: '#ececec',
      opacity: '#faf9ff',
    },
    gray: {
      main: '#70737c',
      light: '#c5c7ca',
      dark: '#616369',
      opacity: '#c3c1d8',
    },
    dark: {
      main: '#272B43',
      light: '#767aa3',
      dark: '#23263c',
      opacity: '#E8EAFF',
    },
    transparent: 'rgba(0,0,0,0.0)',
    orange: {
      main: '#f9a64a',
      light: '#fbe8c2',
      dark: '#e38739',
      opacity: 'rgba(249, 166, 74, 0.5)',
    },
  }
};

export const getPalette = (paletteName) => {
  return palette[paletteName] || palette['axos']; // Default to 'axos' if the palette name is not found
};
