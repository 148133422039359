import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import Text from '../../../../components/Typography/Text';
import Button from '../../../../components/Buttons/Button';
import CloseButton from '../../../../components/Buttons/CloseButton';
import fontFamily from '../../../../assets/css/fonts';
import color from '../../../../assets/css/colors';
import { notifyWarning } from '../../../../components/Messages/Notification';
import { amountBreakdownOrderEntryColumns } from '../../../../lib/data/columns';
import ClientGrid from '../../../../components/ClientGrid/ClientGrid';
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from '../../../../components/Messages/Notification';
import {
  getAmountBreakdownUpdateStatus,
  listAmountBreakdown,
} from '../../../../services/OrderEntryService';
import lodash from 'lodash';

const columns = [...amountBreakdownOrderEntryColumns];

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
}));

export default function OrderEntryAmountBreakdownModal(props) {
  const classes = useStyles();
  const { selectedRows, open, close } = props;
  const [modalData, setModalData] = React.useState(selectedRows);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    handleAmountBreakdown();
  }, []);

  const handleAmountBreakdown = async () => {
    try {
      setLoading(true);

      // Get Batch No, Update Status and Return Batch No

      // Get Amount Breakdown

      const rowsData = await listAmountBreakdown(selectedRows)
      console.log(rowsData.amountBreakdownsList);
      setRows(rowsData.amountBreakdownsList);

      // if (!hideAlert) {
      //   notifyInfo(
      //     rowsData.orderEntriesList.length > 0
      //       ? data.orderEntriesList.length + ' search result(s)'
      //       : 'No records found.'
      //   );
      // }

    } catch (error) {
      setLoading(false);
      notifyError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => close()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div style={{ width: 1500 }}>
          <div className={classes.modalHeader}>
            <div>
              <Text mt={4} variant="h2" label={'Amount Breakdown'} />
            </div>
            <div className={classes.closeButton}>
              <CloseButton onClick={() => close()} />
            </div>
          </div>
          <div className={classes.modalBody}>
            <div style={{ width: '100%', overflowX: 'scroll' }}>
              <div className="grd-row nm">
                <div className="mt-20">
                  <ClientGrid
                    title="Order Entry"
                    rowIdField="orderEntryId"
                    columns={columns}
                    rows={rows}
                    isLoading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.modalFooter}></div>
        </div>
      </Fade>
    </Modal>
  );
}
