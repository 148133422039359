import React from 'react';
// import OwnerModal from './OwnerModal';
import { CircularProgress, Box, IconButton } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import { notifySuccess, notifyError } from '../../../components/Messages/Notification';
import {
  Create as EditIcon,
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
} from '@material-ui/icons';
import Button from '../../../components/Buttons/Button';
import ClientGrid from '../../../components/ClientGrid/ClientGrid';
// import {
//   updateSubAccount,
//   createSubAccount,
//   deleteSubAccount,
// } from '../../../services/SubAccountService';

import { formatPbDate } from '../../../lib/fmt';

export default function SubAccountTable({
  accountId,
  list: rows,
  set: setRows,
  importMode: isImport,
}) {
  // const classes = useStyles();
  const confirm = useConfirm();
  const [rowData, setRowData] = React.useState({});
  const [validation, setValidation] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState({
    save: false,
    delete: false,
  });
  const [currentSelectedRowsIndex, setCurrentSelectedRowsIndex] = React.useState([]);

  const options = {
    isRowSelectable: (dataIndex) => isDeleteEnabled(dataIndex),
    onRowsDelete: (selectedItems) => {
      handleDelete(
        selectedItems.data,
        selectedItems.data.map((d) => d.dataIndex)
      );
      return false;
    },
  };

  const GridButtons = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    return (
      <div>
        <div className="grd-cell">
          <IconButton
            aria-label="delete"
            disabled={!isDeleteEnabled(data) || loading.delete}
            onClick={() => {
              handleDelete([{ data: data }], [data]);
            }}
          >
            {loading.delete ? (
              <CircularProgress style={{ color: '#1e7dff', height: 20, width: 20 }} />
            ) : (
              <DeleteIcon style={{ color: '#1e7dff', height: 20, width: 20 }} />
            )}
          </IconButton>
          <IconButton
            aria-label="edit"
            disabled={loading.delete}
            onClick={() => handleOpen(rows[data])}
          >
            <EditIcon style={{ color: '#1e7dff', height: 20, width: 20 }} />
          </IconButton>
        </div>
      </div>
    );
  };

  const columns = [
    // {
    //   id: 'checkbox',
    //   pinned: true,
    //   width: '54px',
    //   visible: true,
    // },
    // {
    //   id: '',
    //   field: '',
    //   width: '60px',
    //   cellRenderer: GridButtons,
    // },
    {
      id: 'subAccount',
      field: 'subAccount',
      label: 'Sub Account',
    },
    {
      id: 'status',
      field: 'status',
      label: 'Status',
    },
  ];
  const isDeleteEnabled = (dataIndex) => {
    // return rows[dataIndex].status !== 'Inactive';
  };

  const handleDelete = (rowsToDelete, index) => {
    let messageKey = '';
    if (rowsToDelete.length === 1) {
      messageKey = rows[rowsToDelete[0].dataIndex].subAccount;
    } else {
      messageKey = rowsToDelete.length + ' items';
    }

    confirm({
      description:
        "You are about to delete '" + messageKey + "'. Please confirm your action.",
      confirmationText: 'Yes, delete',
    }).then(async () => {
      const rowsCopy = [...rows];
      try {
        setCurrentSelectedRowsIndex(index);
        setLoading({ ...loading, delete: true });

        // for (const r of rowsToDelete) {
        //   const index = r.dataIndex;
        //   await deleteSubAccount(rows[index].subAccountId).then((res) => {
        //     rowsCopy[index] = res.subAccounts;
        //   });
        // }
      } catch (error) {
        notifyError(error.message);
      } finally {
        setCurrentSelectedRowsIndex([]);
        setLoading({ ...loading, delete: false });
        setRows(rowsCopy);
        notifySuccess(messageKey + ' Sub Account has been deleted');
      }
    });
  };

  const handleOpen = (data) => {
    if (!data) {
      //ADD default values
      data = {
        subAccount: '',
        accountName: '',
        status: 'Active',
      };
    }

    setRowData(data);
    setOpen(true);
  };

  const handleClose = async (data, isEdit) => {
    if (!data) {
      setValidation({});
      setOpen(false);
      return;
    }

    let valid = true;

    setValidation({ status: !data.status });

    if (!data.status) {
      notifyError('Status is required.');
      valid = false;
    }

    if (!valid) {
      return;
    }

    setLoading({ ...loading, save: true });

    // try {
    //   if (isEdit) {
    //     //EDIT
    //     const { subAccount } = await updateSubAccount(
    //       accountId,
    //       data,
    //       'client'
    //     );
    //     const index = rows.indexOf(rowData);
    //     // rows[index] = subAccount;
    //     const rowsCopy = [...rows];
    //     rowsCopy[index] = subAccount;
    //     setRows(rowsCopy);
    //     notifySuccess('Sub Account has been updated.');
    //   } else {
    //     //ADD
    //     const { subAccount } = await createSubAccount(
    //       accountId,
    //       data,
    //       'client'
    //     );
    //     setRows([subAccount, ...rows]);
    //     notifySuccess('New sub account has been added.');
    //   }

    //   setValidation({});
    //   setOpen(false);
    // } catch (error) {
    //   console.error(error);
    //   setLoading({ ...loading, save: false });
    //   notifyError(error.message);
    // }

    setLoading({ ...loading, save: false });
  };

  const getCsvData = async () => {
    // const data = await readOwner(searchData)
    // return data.ticketsList
  };

  return (
    <div className="page-c" style={{ marginTop: -50 }}>
      <Box component="search-c">
        <div className="grd-row nm">
          <div className="grd-cell-none">
            {/* <Button
              type="plus"
              label="Add New Sub Account"
              onClick={() => {
                handleOpen();
              }}
            /> */}
          </div>
        </div>
      </Box>
      <div className="mt-20">
        <ClientGrid
          title="SubAccounts"
          rowIdField="subAccountId"
          columns={columns}
          rows={rows}
          getCsvData={getCsvData}
          isLoading={loading}
          setRows={setRows}
        />
      </div>
      {/* {open && (
        <OwnerModal
          onClose={handleClose}
          open={open}
          value={rowData}
          importMode={isImport}
          editMode={editMode}
          validation={validation}
          loading={loading.save}
        ></OwnerModal>
      )}{' '} */}
    </div>
  );
}
