import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Paper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { lazyAdministratorEmail } from '../../services/CommonService';
import color from '../../assets/css/colors';
import fontFamily from '../../assets/css/fonts';

const useStyles = makeStyles(() => ({
  autocompleteWrapper: {
    '& .MuiAutocomplete-inputRoot': {
      padding: '7px 15px 8px 15px !important',
      height: 48,
    },
    '& .MuiAutocomplete-option': {
      backgroundColor: 'red',
    },
    '& .MuiAutocomplete-endAdornment': {
      top: 'calc(50% - 15px)',
      '& svg': {
        width: '22px !important',
        height: '22px !important',
      },
    },
  },
  paper: {
    fontFamily: fontFamily.label,
    fontSize: 15,
    fontWeight: 600,
    backgroundColor: color.light.main,
  },
}));

export default function AutoCompleteAdministratorEmail(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState(props.value);

  const setPropsValue = (value) => {
    props.onChange({
      currentTarget: {
        name: props.name,
        value: value,
      },
    });
  };

  const handleOnBlur = async (value) => {
    if (props.freeSolo) {
      setPropsValue(value);
      return;
    }

    if (options.find((o) => o === value)) {
      setPropsValue(value);
      return;
    }

    // if selected value does not exist in options and is not a free solo
    setInputValue('');
    setPropsValue('');
  };

  //set local input value and get options
  const handleInputChange = async (event, value) => {
    if (!event) return;
    if (event.type === 'blur') return;
    if (event.type === 'click' && value) return;

    setInputValue(value);
    getOptions(value);
  };

  const getOptions = async (value) => {
    const { administratorEmailsList } = await lazyAdministratorEmail(value);
    setOptions(administratorEmailsList);
  };

  React.useEffect(
    () => {
      if (open) {
        getOptions(props.value);
      }
    },
    // eslint-disable-next-line
    [open]
  );

  return (
    <Autocomplete
      className={classes.autocompleteWrapper}
      freeSolo={props.freeSolo}
      id={props.name}
      onInputChange={handleInputChange}
      onBlur={() => {
        handleOnBlur(inputValue);
      }}
      blurOnSelect={false}
      onChange={(_, value) => {
        setInputValue(value ? value : '');
        setPropsValue(value ? value : '');
      }}
      getOptionSelected={(option, value) => option === value}
      getOptionLabel={(option) => option}
      inputValue={inputValue}
      options={options}
      name={props.name}
      autoHighlight={true}
      clearOnEscape={true}
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      renderInput={(params) => {
        params.inputProps.onKeyDown = props.onKeyDown;
        return (
          <TextField
            {...params}
            name={props.name}
            required={props.required}
            label={props.label}
            placeholder={props.label}
            InputLabelProps={{ shrink: true }}
            inputProps={{ ...params.inputProps, maxLength: 300 }}
          />
        );
      }}
    />
  );
}
