import {
  ClientServiceClient,
  Client,
  GetNewClientIdRequest,
  GetAccountIdRequest,
  ReadClientRequest,
  ListClientRequest,
  ReadClientByUserRequest,
  ValidateAccountRequest,
  EmptyRequest,
  ListSubAccountTypeItemRequest,
} from '../proto/accountpb/client_grpc_web_pb';
import { ReadCorrespondentRequest } from '../proto/accountpb/correspondent_grpc_web_pb';
import { stringToProtoDate, stringToProtoTimeSpan } from './ConvertService';
import authSvc from './AuthService';
import { auth } from '../lib/auth/Auth';
import { Pagination } from '../proto/utilspb/pagination_pb';

const service = new ClientServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });

const userId = authSvc.getCurrentUser()?.UserId;

const saveRequestParameter = (param) => {
  let req = new Client();
  req.setAccountId(param.accountId);
  req.setCorrespondent(param.correspondent);
  req.setAccountName(param.accountName);
  req.setAccountNo(param.accountNo);
  req.setMasterAccountNo(param.masterAccountNo);
  req.setStatus(param.status);
  req.setBroker(param.broker);
  req.setPrivacyConsent(param.privacyContent);
  req.setDayTrader(param.dayTrader);
  req.setMultiplier(param.multiplier);
  req.setLegalEntity(param.legalEntity);
  req.setInvestmentObjectiveCode(param.investmentObjectiveCode);
  req.setTaxCountry(param.taxCountry);
  req.setTefra(param.tefra);
  req.setW8w9(param.w8w9);
  req.setInvestmentObjectiveDate(stringToProtoDate(param.investmentObjectiveDate));
  req.setW8w9ReceivedDate(stringToProtoDate(param.w8w9ReceivedDate));
  req.setActivatedAt(stringToProtoTimeSpan(param.activatedAt));
  req.setDisabledAt(stringToProtoTimeSpan(param.disabledAt));
  req.setAccreditedInvestor(param.accreditedInvestor);
  req.setFdidFlag(param.fdidFlag);
  req.setFdid(param.fdid);
  req.setFdidEndReason(param.fdidEndReason);
  req.setBranch(param.branch);
  req.setRep(param.rep);
  req.setAccountType(param.accountType);
  req.setMarginType(param.marginType);
  req.setLargeTraderId(param.largeTraderId);
  req.setFpslParticipant(param.fpslParticipant);
  req.setCommonId(param.commonId);
  req.setStatusReason(param.statusReason);
  req.setSubType(param.subType);
  req.setAllowLiquidInvestment(param.allowLiquidInvestment);
  req.setWebTermConditions(stringToProtoTimeSpan(param.webTermConditions));
  req.setDisclosureStatement(stringToProtoTimeSpan(param.disclosureStatement));
  req.setFeeSchedule(stringToProtoTimeSpan(param.feeSchedule));
  req.setBeneficiary(param.beneficiary);
  req.setPrimeBroker(param.primeBroker);
  req.setParticipantNo(param.participantNo);
  req.setExecutingBroker(param.executingBroker);
  req.setInstitutionNo(param.institutionNo);
  req.setAgentNo(param.agentNo);
  req.setAgentAccountNo(param.agentAccountNo);
  req.setTraderId(param.traderId);
  req.setMarginDisclosure(param.marginDisclosure);
  req.setMarginAgreement(param.marginAgreement);
  req.setStocks(param.stocks);
  req.setEtf(param.eft);
  req.setOption(param.option);
  req.setAnnualIncome(param.annualIncome);
  req.setLiquidNetWorth(param.liquidNetWorth);
  req.setSourceOfFunds(param.sourceOfFunds);
  req.setSigned(param.signed);
  req.setImgSignature(param.imgSignature);
  req.setKycStatus(param.kycStatus);
  req.setPromoCode(param.promoCode);
  req.setSecurityWordHint(param.securityWordHint);
  req.setSecurityWord(param.securityWord);
  req.setTaxBracket(param.taxBracket);
  req.setRiskTolerance(param.riskTolerance);
  req.setCustomerAccountAgreement(param.customerAccountAgreement);
  req.setOnboardingPercent(param.onboardingPercent?.toString());
  req.setTin(param.tin);
  return req;
};

export async function createClient(param) {
  return new Promise((resolve, reject) => {
    const req = saveRequestParameter(param);
    service.createClient(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function readClient(param) {
  return new Promise((resolve, reject) => {
    let req = new ReadClientRequest();
    req.setAccountId(param.accountId);
    // req.setOwnerId(param.ownerId);

    service.readClient(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function getAccessibleClient(usrId) {
  return new Promise((resolve, reject) => {
    let req = new ReadClientByUserRequest();
    req.setUsrId(usrId);

    service.getAccessibleClient(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listClient(param, paging) {
  return new Promise((resolve, reject) => {
    let req = new ListClientRequest();
    req.setCorrespondent(param.correspondent);
    req.setBranch(param.branch);
    req.setAccountNo(param.accountNo);
    req.setMasterAccountNo(param.masterAccountNo);
    req.setRep(param.rep);
    req.setAccountName(param.accountName);
    req.setBroker(param.broker);
    req.setStatus(param.status);
    // req.setUsrId(userId);
    req.setSubAccount(param.subAccount);
    req.setFromDate(stringToProtoDate(param.fromDate));
    req.setToDate(stringToProtoDate(param.toDate));
    
    if (paging) {
      let pg = new Pagination();
      pg.setPageSize(paging.pageSize);
      pg.setPageNo(paging.pageNo);
      pg.setSortName(paging.sortName);
      pg.setSortDirection(paging.sortdirection);
      pg.setFilterNamesList(paging.filterNames);
      pg.setFilterValuesList(paging.filteralues);
      req.setPagination(pg);
    }

    service.listClient(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function getNewClientId() {
  console.log('GENERATING NEW CLIENT ID')
  return new Promise((resolve, reject) => {
    let req = new GetNewClientIdRequest();

    service.getNewClientId(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function getAccountID(accountNo) {
  return new Promise((resolve, reject) => {
    let req = new GetAccountIdRequest();
    req.setAccountNo(accountNo);

    service.getAccountID(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function validateAccount(accountId) {
  return new Promise((resolve, reject) => {
    let req = new ValidateAccountRequest();
    req.setAccountId(accountId);

    service.validateAccount(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listAccountType() {
  return new Promise((resolve, reject) => {
    let req = new EmptyRequest();
    service.listAccountType(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listSubAccountType(accountType) {
  return new Promise((resolve, reject) => {
    let req = new ListSubAccountTypeItemRequest();
    req.setAccountType(accountType);
    service.listSubAccountType(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateClient(param) {
  return new Promise((resolve, reject) => {
    const req = saveRequestParameter(param);
    service.updateClient(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function readCorrespondent(correspondent) {
  return new Promise((resolve, reject) => {
    const req = ReadCorrespondentRequest(correspondent);
    service.readCorrespondent(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
