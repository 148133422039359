import React, { useEffect, useRef } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../../assets/images/sas-logo.png';
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import ForgotPasswordPreLogin from '../Forgot/ForgotPasswordPreLogin';
import axosDigital from '../../assets/images/axos-digital.png';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import authSvc from '../../services/AuthService';
import APWTextField from '../../components/Forms/APWTextField';
import APWPasswordField from '../../components/Forms/APWPasswordField';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme as themeFile } from '../../assets/css/theme'
import APWForm from '../../components/Forms/APWForm';
import APWAlertDialog from '../../components/Modals/APWAlertDialog';
import APWButton from '../../components/Buttons/APWButton';
import APWRadio from '../../components/Forms/APWRadio';
import {
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@material-ui/core'
import SMSAndEmailAuthentication from './SMSAndEmailAuthentication';
import APWSnackbar from '../../components/Snackbar/APWSnackbar';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Powered by © '}<img className="forgot-axos-logo" src={axosDigital} alt="Logo" />
    </Typography>
  );
}

const LoginIdentity = (props) => {

  const snackBarRef = useRef(null);

  const { classes, theme } = props;

  const SMS_DELIVERY_METHOD = "1"
  const EMAIL_DELIVERY_METHOD = "2"
  const GA_DELIVERY_METHOD = "3"

  const LOGIN_SCREEN = "login"
  const FORGOT_PASS_SCREEN = "forgotPassword"
  const AUTH_SCREEN = "authentication"

  const INIT_DATA = {
    username: '',
    password: '',
    mode: 'Log In',
    authentication: GA_DELIVERY_METHOD
  }

  const [data, setData] = React.useState(INIT_DATA);

  const history = useHistory();

  const location = useLocation();

  const [clientLogo, setLogo] = React.useState(null);

  const [loading, setLoading] = React.useState({
    login: false,
  });

  const [message, setMessage] = React.useState({
    type: '',
    value: '',
  });

  const [screen, setScreen] = React.useState({
    login: true,
    forgotPassword: false,
    authentication: false,
  });

  const initialAccountState = {
    username: '',
    userId: 0,
    deliveryMethod: SMS_DELIVERY_METHOD,
    deliveryAddress: '',
    deviceID: '',
  }
  // For account
  const [account, setAccount] = React.useState(initialAccountState);
  
  const [isInactive, setIsInactive] = React.useState(sessionStorage.getItem('inactive'));

  const handleShowAlert = () => {
    if (location?.state?.showAlert) {
      snackBarRef.current.openSnackbar("You have been logged out from APW.")
      history.replace({
        pathname: "/login",
        state: {}
      });
    }
  }

  const handleGetLogo = async () => {
    /*try {
      const serverLogo = await getLogo();

      if (serverLogo.correspondent.photo !== '') {
        setLogo(serverLogo.correspondent.photo);
      } else {
        setLogo(logo);
      }
    } catch (error) {
      //console.log(error)
      setLogo(logo);
    }
*/
    setLogo(logo);
  };

  const setDisplayScreenTo = (key) => {
    resetMessages();
    toggleAlert(false)
    setScreen(() => ({
      login: key === LOGIN_SCREEN ? true : false,
      forgotPassword: key === FORGOT_PASS_SCREEN ? true : false,
      authentication: key === AUTH_SCREEN ? true : false,
    }));
  };

  const handleChange = (name, value) => {
    //console.log("handleChange -> ", name, value)
    setData({ ...data, [name]: value });
  };

  const handle2FAChange = (event) => {
    //console.log("handle2FAChange ", event.target.value)
    setAccount({ ...account, deliveryMethod: event.target.value });
  };

  const resetMessages = () => {
    setMessage({
      type: '',
      value: '',
    });
  };

  const handleLogin = async () => {
    resetMessages();

    if (!data.username || !data.password) {
      showLoginErrorNotification("username_password_required")
      return;
    }

    try {
      setLoading({ ...loading, login: true });
      const adminUser = await authSvc.loginAPW({ ...data, username: data.username, deviceID: localStorage.getItem('device_id') || "" }); //TODO REMEMBER: add localstorage lib
      const userId = adminUser.UserId
      const canSkipOTP = userId !== 0 && adminUser.IsDeviceSaved //TODO REMEMBER: Replace with actual variable


      if (canSkipOTP) {
        localStorage.setItem('code_validity', true);
        window.location = '/';
        return;
      }

      const updatedAccountData = {
        ...account,
        username: data.username,
        userId: userId,
        deviceID: adminUser.DeviceID //TODO REMEMBER: Replace with actual variable 
      }

      if (userId !== 0 && account.deliveryMethod === GA_DELIVERY_METHOD) {
        //TODO I need the username and userID as response in Login
        setLoading({ ...loading, login: false });

        setAccount(updatedAccountData);
        setData(INIT_DATA);
        setDisplayScreenTo(AUTH_SCREEN);

      } else {
        //TODO I need the username and userID as response in Login
        authSvc.sendAuthenticationCode(updatedAccountData).then((response) => {
          if (response.success) {
            setLoading({ ...loading, login: false });
            updatedAccountData.deliveryAddress = response.deliveryAddress
            setAccount(updatedAccountData);
            setData(INIT_DATA)
            setDisplayScreenTo(AUTH_SCREEN)
          }
        }).catch((err) => {
          //console.log(err, " sendAuthenticationCode err")
          
          showLoginErrorNotification(err.errorCode)
          return;
        });
      }
    } catch (error) {
      console.log(error.message, " login err")
      showLoginErrorNotification(error.errorCode) //TODO I need the Error Code
      setMessage({ type: 'error', value: error.message });
      setLoading({ ...loading, login: false });
      return;
    }


  };

  /*Dialogs Functionalities*/

  const [alertDialog, setAlertDialog] = React.useState({
    open: false,
  })

  const toggleAlert = (toggle) => {
    setAlertDialog({ ...alertDialog, open: toggle })
  }

  const [dialog, setDialog] = React.useState({
    title: "",
    content: null,
    actions: null,
  })

  const showLoginErrorNotification = (code) => {
    console.log("Login error code", code)

    if (code === "username_password_required") {
      alertRequiredFields()
    } else if (code === "account_locked_error") {
      alertAccountLocked()
    } else if (code === "identity_user_notfound_error") {
      alertInvalidLogin()
    } else if (code === "err_phone_number_empty") {
      alertAccountHasNoPhones()
    } else if (code === "identity_profile_notfound_error" || code === "email_address_not_found") {
      // NON WL
      alertInvalidLogin()
    } else {
      internalError()
    }



    setLoading({ ...loading, login: false });
  }


  const alertInvalidLogin = () => {
    toggleAlert(true)
    const dlg = {
      title: "Let's try that again",
      content: (
        <APWAlertDialog.DialogContent>
          <APWAlertDialog.DialogContentText>
            {`
                    We don't recognize the credentials you entered. Please check your entry and try again.
                    `}
          </APWAlertDialog.DialogContentText>
        </APWAlertDialog.DialogContent>
      ),
      actions: (
        <React.Fragment>
          <APWForm>
            <APWForm.Item xs={12} sm={12} md={12} lg={12} xl={12}>
              <APWButton
                fullWidth={true}
                label="Try Again"
                onClick={() => {
                  toggleAlert(false)
                }}
              />
            </APWForm.Item>
            <APWForm.Item xs={12} sm={12} md={12} lg={12} xl={12}>
              <APWButton
                component={RouterLink}
                to={"/forgot-username"}
                variant="outlined"
                fullWidth={true}
                label="Forgot Username?"
                onClick={() => {
                  toggleAlert(false)
                }}
              />
            </APWForm.Item>
            <APWForm.Item xs={12} sm={12} md={12} lg={12} xl={12}>
              <APWButton
                variant="outlined"
                fullWidth={true}
                label="Forgot Password?"
                onClick={() => {
                  setDisplayScreenTo(FORGOT_PASS_SCREEN)
                }}
              />
            </APWForm.Item>
          </APWForm>
        </React.Fragment>

      ),
    }

    setDialog({ ...dialog, title: dlg.title, content: dlg.content, actions: dlg.actions })
  }

  const alertRequiredFields = () => {
    toggleAlert(true)
    const dlg = {
      title: "Required Fields",
      content: (
        <APWAlertDialog.DialogContent>
          <APWAlertDialog.DialogContentText>
            {`
                    Username and Password are required
                    `}
          </APWAlertDialog.DialogContentText>
        </APWAlertDialog.DialogContent>
      ),
      actions: (
        <React.Fragment>
          <APWButton
            fullWidth={true}
            label="Try Again"
            onClick={() => {
              toggleAlert(false)
            }}
          />
        </React.Fragment>

      ),
    }

    setDialog({ ...dialog, title: dlg.title, content: dlg.content, actions: dlg.actions })
  }

  const alertAccountLocked = () => {
    toggleAlert(true)
    const dlg = {
      title: "Account Locked",
      content: (
        <APWAlertDialog.DialogContent>
          <APWAlertDialog.DialogContentText>
            {`
              You’ve exceeded the allowed number of attempts. Your account is locked. Please try again in 1 hour or contact customer support at [#-###-###-####]
            `}
          </APWAlertDialog.DialogContentText>
        </APWAlertDialog.DialogContent>
      ),
      actions: (
        <React.Fragment>
          <APWButton
            fullWidth={true}
            label="Try Again"
            onClick={() => {
              toggleAlert(false)
            }}
          />
        </React.Fragment>

      ),
    }

    setDialog({ ...dialog, title: dlg.title, content: dlg.content, actions: dlg.actions })
  }

  const internalError = () => {
    toggleAlert(true)
    const dlg = {
      title: "Internal Server Error",
      content: (
        <APWAlertDialog.DialogContent>
          <APWAlertDialog.DialogContentText>
            {`Please try again`}
          </APWAlertDialog.DialogContentText>
        </APWAlertDialog.DialogContent>
      ),
    }

    setDialog({ ...dialog, title: dlg.title, content: dlg.content, actions: dlg.actions })
  }

  const alertAccountHasNoPhones = () => {
    toggleAlert(true)
    const dlg = {
      title: "Your account may not have a phone setup",
      content: (
        <APWAlertDialog.DialogContent>
          <APWAlertDialog.DialogContentText>
            {`Please call customer support at [#-###-###-####] to reset your password`}
          </APWAlertDialog.DialogContentText>
        </APWAlertDialog.DialogContent>
      ),
    }

    setDialog({ title: dlg.title, content: dlg.content, actions: dlg.actions })
  }

  /*END of Dialogs Functionalities*/

  useEffect(() => {
    console.log(isInactive)
    if (isInactive === "true"){
      snackBarRef.current.openSnackbar("You were automatically logged out due to inactivity.")
    }

    handleShowAlert();
    setLogo(logo);
    //sessionStorage.removeItem('inactive');
  }, []);


  return (
    <>
      <AppBar
        position="static"
        color="default"
        elevation={0}
        style={{
          borderTop: `5px solid ${theme.palette.primary.main}`,
        }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <img id="logo" src={axosDigital} alt="Logo" className={classes.firmLogo} />
        </Toolbar>
      </AppBar>
      {screen.login && (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.loginContainer}>
            <React.Fragment>
              <Typography component="div">
                <Box
                  textAlign="center"
                  fontSize="h6.fontSize"
                  style={{ flexGrow: 1, textTransform: 'uppercase' }}
                >
                  Advisor Workstation
                </Box>
                <Box
                  textAlign="center"
                  fontSize="h4.fontSize"
                  fontWeight="fontWeightBold"
                >
                  Welcome Back
                </Box>
                <Box
                  textAlign="center"
                  fontSize="body1.fontSize"
                >
                  Sign in your account
                </Box>
              </Typography>
              <form noValidate>
                <APWForm>
                  <APWForm.Item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <APWTextField
                      id="txt_username"
                      name="username"
                      label="Username"
                      value={data.username}
                      placeholder='Username'
                      onChange={handleChange}
                      onKeyUp={(e) => ((e.keyCode === 13 || e.key === 'Enter' || e.key === 'NumpadEnter') ? this.handleLogin() : null)}
                    />
                  </APWForm.Item>
                  <APWForm.Item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <APWPasswordField
                      mt={true}
                      name="password"
                      label="Password"
                      placeholder="Password"
                      type="password"
                      max={50}
                      value={data.password}
                      onChange={handleChange}
                      fullWidth={true}
                      onKeyUp={(e) => ((e.keyCode === 13 || e.key === 'Enter' || e.key === 'NumpadEnter') ? this.handleLogin() : null)}
                    />
                  </APWForm.Item>
                  <APWForm.Item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="authentication"
                        name="deliveryMethod"
                        value={account.deliveryMethod}
                        onChange={handle2FAChange}
                        row
                      >
                        <FormControlLabel value={SMS_DELIVERY_METHOD} control={<APWRadio />} label="SMS" />
                        <FormControlLabel value={EMAIL_DELIVERY_METHOD} control={<APWRadio />} label="Email" />
                        <FormControlLabel value={GA_DELIVERY_METHOD} control={<APWRadio />} label="Authenticator" />
                      </RadioGroup>
                    </FormControl>
                  </APWForm.Item>
                  <APWForm.Item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <APWButton
                      disabled={loading.login || (data.username === "" || data.password === "")}
                      loading={loading.login}
                      label={loading.login ? 'Verifying your account...' : 'Sign In'}
                      onClick={handleLogin}

                    />
                  </APWForm.Item>
                </APWForm>

                <Typography mb={40} variant="body1" align='right' style={{ margin: '30px 0px 40px 0px', textAlign: 'center' }}>
                  {'Forgot '} <RouterLink to={"/forgot-username"} style={{ color: theme.palette.primary.main }}>username</RouterLink> or <Link href={null} onClick={() => setDisplayScreenTo(FORGOT_PASS_SCREEN)}> {"password"}</Link>{'?'}
                </Typography>
              </form>
            </React.Fragment>


          </div>

        </Container>
      )}
      {(screen.authentication && (data.authentication === SMS_DELIVERY_METHOD || data.authentication === EMAIL_DELIVERY_METHOD || data.authentication === GA_DELIVERY_METHOD)) && (
        <SMSAndEmailAuthentication authType={data.authentication} account={account} backToSignin={() => setDisplayScreenTo(LOGIN_SCREEN)} />
      )}

      {screen.forgotPassword && (
        <ThemeProvider theme={themeFile}>
          <ForgotPasswordPreLogin backToSignin={() => setDisplayScreenTo(LOGIN_SCREEN)} />
        </ThemeProvider>)}
      <Container component="footer" maxWidth="xs">
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>

      <APWAlertDialog
        open={alertDialog.open}
        title={dialog.title}
        toggleDialog={toggleAlert}
        content={dialog.content}
        actionButtons={dialog.actions}
      />
      <Container>
        <APWSnackbar onClose={()=>{
          setIsInactive(false);
          sessionStorage.removeItem('inactive');
        }} ref={snackBarRef} id="alert_snack" />
      </Container>
    </>
  );
}

export default withStyles((theme) => ({
  loginContainer: {
    marginTop: theme.spacing(8),
  },

  forgotContainer: {
    alignItems: 'left'
  },

  center: {
    textAlign: 'center'
  },

  firmLogo: {
    marginLeft: theme.spacing(5),
    width: '9%', // Set the default width
    height: 'auto',
    // Media query for screens smaller than 600px (adjust the breakpoint as needed)
    [theme.breakpoints.down('lg')]: {
      width: '13%', // Adjust the width for small screens
    },
    [theme.breakpoints.down('xs')]: {
      width: '20%', // Adjust the width for small screens
    },
  },
}), { withTheme: true, name: 'LoginIdentity' })(LoginIdentity);