// source: proto/reportpb/activity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_utilspb_pagination_pb = require('../../proto/utilspb/pagination_pb.js');
goog.object.extend(proto, proto_utilspb_pagination_pb);
goog.exportSymbol('proto.reportpb.ActDateObj', null, global);
goog.exportSymbol('proto.reportpb.Activity', null, global);
goog.exportSymbol('proto.reportpb.ListActivitiesResponse', null, global);
goog.exportSymbol('proto.reportpb.ListActivityRequest', null, global);
goog.exportSymbol('proto.reportpb.ListActivityResponse', null, global);
goog.exportSymbol('proto.reportpb.ListActivitySummary', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.Activity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.Activity.repeatedFields_, null);
};
goog.inherits(proto.reportpb.Activity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.Activity.displayName = 'proto.reportpb.Activity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListActivityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ListActivityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListActivityRequest.displayName = 'proto.reportpb.ListActivityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListActivityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListActivityResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListActivityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListActivityResponse.displayName = 'proto.reportpb.ListActivityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ActDateObj = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ActDateObj, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ActDateObj.displayName = 'proto.reportpb.ActDateObj';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListActivitySummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListActivitySummary.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListActivitySummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListActivitySummary.displayName = 'proto.reportpb.ListActivitySummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListActivitiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListActivitiesResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListActivitiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListActivitiesResponse.displayName = 'proto.reportpb.ListActivitiesResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.Activity.repeatedFields_ = [100];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.Activity.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.Activity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.Activity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.Activity.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    effSettleDate: (f = msg.getEffSettleDate()) && proto.reportpb.ActDateObj.toObject(includeInstance, f),
    broker: jspb.Message.getFieldWithDefault(msg, 6, ""),
    type: jspb.Message.getFieldWithDefault(msg, 7, ""),
    capacity: jspb.Message.getFieldWithDefault(msg, 8, ""),
    effTradeDate: (f = msg.getEffTradeDate()) && proto.reportpb.ActDateObj.toObject(includeInstance, f),
    symbol: jspb.Message.getFieldWithDefault(msg, 10, ""),
    symbolDescription: jspb.Message.getFieldWithDefault(msg, 11, ""),
    cusip: jspb.Message.getFieldWithDefault(msg, 12, ""),
    assetType: jspb.Message.getFieldWithDefault(msg, 13, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 14, ""),
    systemDate: (f = msg.getSystemDate()) && proto.reportpb.ActDateObj.toObject(includeInstance, f),
    tradeDate: (f = msg.getTradeDate()) && proto.reportpb.ActDateObj.toObject(includeInstance, f),
    tradeAt: jspb.Message.getFieldWithDefault(msg, 17, ""),
    settleDate: (f = msg.getSettleDate()) && proto.reportpb.ActDateObj.toObject(includeInstance, f),
    entryType: jspb.Message.getFieldWithDefault(msg, 19, ""),
    side: jspb.Message.getFieldWithDefault(msg, 20, ""),
    qty: jspb.Message.getFieldWithDefault(msg, 21, ""),
    price: jspb.Message.getFieldWithDefault(msg, 22, ""),
    grossAmt: jspb.Message.getFieldWithDefault(msg, 23, ""),
    regFee: jspb.Message.getFieldWithDefault(msg, 24, ""),
    tafFee: jspb.Message.getFieldWithDefault(msg, 25, ""),
    fees: jspb.Message.getFieldWithDefault(msg, 26, ""),
    netAmt: jspb.Message.getFieldWithDefault(msg, 27, ""),
    description: jspb.Message.getFieldWithDefault(msg, 28, ""),
    batchNo: jspb.Message.getFieldWithDefault(msg, 29, ""),
    status: jspb.Message.getFieldWithDefault(msg, 30, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 31, ""),
    trnsId: jspb.Message.getFieldWithDefault(msg, 32, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 33, 0),
    executingVenue: jspb.Message.getFieldWithDefault(msg, 34, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 35, ""),
    orderId: jspb.Message.getFieldWithDefault(msg, 36, ""),
    vendor: jspb.Message.getFieldWithDefault(msg, 37, ""),
    contraAccountNo: jspb.Message.getFieldWithDefault(msg, 38, ""),
    commission: jspb.Message.getFieldWithDefault(msg, 39, ""),
    contraCorrespondent: jspb.Message.getFieldWithDefault(msg, 40, ""),
    contraAccountId: jspb.Message.getFieldWithDefault(msg, 41, 0),
    originalCusip: jspb.Message.getFieldWithDefault(msg, 42, ""),
    entryTypeDescription: jspb.Message.getFieldWithDefault(msg, 43, ""),
    rep: jspb.Message.getFieldWithDefault(msg, 44, ""),
    branch: jspb.Message.getFieldWithDefault(msg, 45, ""),
    compressType: jspb.Message.getFieldWithDefault(msg, 46, ""),
    compressCount: jspb.Message.getFieldWithDefault(msg, 47, 0),
    compressId: jspb.Message.getFieldWithDefault(msg, 48, 0),
    traderId: jspb.Message.getFieldWithDefault(msg, 49, ""),
    referenceId: jspb.Message.getFieldWithDefault(msg, 50, ""),
    previousTrnsId: jspb.Message.getFieldWithDefault(msg, 51, 0),
    product: jspb.Message.getFieldWithDefault(msg, 52, ""),
    accrual: jspb.Message.getFieldWithDefault(msg, 53, ""),
    couponRate: jspb.Message.getFieldWithDefault(msg, 54, ""),
    interestPayment: jspb.Message.getFieldWithDefault(msg, 55, ""),
    yield: jspb.Message.getFieldWithDefault(msg, 56, ""),
    orgType: jspb.Message.getFieldWithDefault(msg, 57, ""),
    orgTerm: (f = msg.getOrgTerm()) && proto.reportpb.ActDateObj.toObject(includeInstance, f),
    sameDayCancel: jspb.Message.getBooleanFieldWithDefault(msg, 59, false),
    contraBroker: jspb.Message.getFieldWithDefault(msg, 60, ""),
    taxWithholding: jspb.Message.getFieldWithDefault(msg, 61, ""),
    multiplier: jspb.Message.getFieldWithDefault(msg, 62, 0),
    contraSubAccountNo: jspb.Message.getFieldWithDefault(msg, 63, ""),
    subAccountNo: jspb.Message.getFieldWithDefault(msg, 64, ""),
    otherFees: jspb.Message.getFieldWithDefault(msg, 65, ""),
    year: jspb.Message.getFieldWithDefault(msg, 66, ""),
    participantNo: jspb.Message.getFieldWithDefault(msg, 67, ""),
    asOfDate: (f = msg.getAsOfDate()) && proto.reportpb.ActDateObj.toObject(includeInstance, f),
    registeredAdvisorCapacity: jspb.Message.getFieldWithDefault(msg, 69, ""),
    specialRemarks: jspb.Message.getFieldWithDefault(msg, 70, ""),
    timeOfExecution: jspb.Message.getFieldWithDefault(msg, 71, ""),
    principal: jspb.Message.getFieldWithDefault(msg, 72, ""),
    miscFee: jspb.Message.getFieldWithDefault(msg, 73, ""),
    otherFees2: jspb.Message.getFieldWithDefault(msg, 74, ""),
    location: jspb.Message.getFieldWithDefault(msg, 75, ""),
    discretion: jspb.Message.getFieldWithDefault(msg, 76, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 77, ""),
    currencyRate: jspb.Message.getFieldWithDefault(msg, 78, ""),
    currencyAmt: jspb.Message.getFieldWithDefault(msg, 79, ""),
    execId: jspb.Message.getFieldWithDefault(msg, 80, ""),
    legId: jspb.Message.getFieldWithDefault(msg, 81, ""),
    settlementMethod: jspb.Message.getFieldWithDefault(msg, 82, ""),
    isSolicited: jspb.Message.getBooleanFieldWithDefault(msg, 83, false),
    stepIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 84, false),
    comparisonIndicator: jspb.Message.getBooleanFieldWithDefault(msg, 85, false),
    noRenumeration: jspb.Message.getBooleanFieldWithDefault(msg, 86, false),
    whenIssue: jspb.Message.getBooleanFieldWithDefault(msg, 87, false),
    newIssue: jspb.Message.getBooleanFieldWithDefault(msg, 88, false),
    isMsrb: jspb.Message.getBooleanFieldWithDefault(msg, 89, false),
    isCorp: jspb.Message.getBooleanFieldWithDefault(msg, 90, false),
    ytc: jspb.Message.getFieldWithDefault(msg, 91, ""),
    ytm: jspb.Message.getFieldWithDefault(msg, 92, ""),
    markAmount: jspb.Message.getFieldWithDefault(msg, 93, ""),
    pmp: jspb.Message.getFieldWithDefault(msg, 94, ""),
    markRate: jspb.Message.getFieldWithDefault(msg, 95, ""),
    atsIdentifier: jspb.Message.getFieldWithDefault(msg, 96, ""),
    currencyGrossAmt: jspb.Message.getFieldWithDefault(msg, 97, ""),
    currencyFees: jspb.Message.getFieldWithDefault(msg, 98, ""),
    otherFeeValuesList: (f = jspb.Message.getRepeatedField(msg, 100)) == null ? undefined : f,
    euroclearId: jspb.Message.getFieldWithDefault(msg, 101, ""),
    settlementInstructionId: jspb.Message.getFieldWithDefault(msg, 102, 0),
    mapEntryType: jspb.Message.getFieldWithDefault(msg, 103, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.Activity}
 */
proto.reportpb.Activity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.Activity;
  return proto.reportpb.Activity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.Activity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.Activity}
 */
proto.reportpb.Activity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = new proto.reportpb.ActDateObj;
      reader.readMessage(value,proto.reportpb.ActDateObj.deserializeBinaryFromReader);
      msg.setEffSettleDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCapacity(value);
      break;
    case 9:
      var value = new proto.reportpb.ActDateObj;
      reader.readMessage(value,proto.reportpb.ActDateObj.deserializeBinaryFromReader);
      msg.setEffTradeDate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolDescription(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetType(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 15:
      var value = new proto.reportpb.ActDateObj;
      reader.readMessage(value,proto.reportpb.ActDateObj.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 16:
      var value = new proto.reportpb.ActDateObj;
      reader.readMessage(value,proto.reportpb.ActDateObj.deserializeBinaryFromReader);
      msg.setTradeDate(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setTradeAt(value);
      break;
    case 18:
      var value = new proto.reportpb.ActDateObj;
      reader.readMessage(value,proto.reportpb.ActDateObj.deserializeBinaryFromReader);
      msg.setSettleDate(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryType(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setSide(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setGrossAmt(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegFee(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setTafFee(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setFees(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetAmt(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchNo(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTrnsId(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutingVenue(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setVendor(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAccountNo(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommission(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraCorrespondent(value);
      break;
    case 41:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setContraAccountId(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalCusip(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryTypeDescription(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setRep(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranch(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompressType(value);
      break;
    case 47:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCompressCount(value);
      break;
    case 48:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCompressId(value);
      break;
    case 49:
      var value = /** @type {string} */ (reader.readString());
      msg.setTraderId(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceId(value);
      break;
    case 51:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPreviousTrnsId(value);
      break;
    case 52:
      var value = /** @type {string} */ (reader.readString());
      msg.setProduct(value);
      break;
    case 53:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccrual(value);
      break;
    case 54:
      var value = /** @type {string} */ (reader.readString());
      msg.setCouponRate(value);
      break;
    case 55:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterestPayment(value);
      break;
    case 56:
      var value = /** @type {string} */ (reader.readString());
      msg.setYield(value);
      break;
    case 57:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrgType(value);
      break;
    case 58:
      var value = new proto.reportpb.ActDateObj;
      reader.readMessage(value,proto.reportpb.ActDateObj.deserializeBinaryFromReader);
      msg.setOrgTerm(value);
      break;
    case 59:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSameDayCancel(value);
      break;
    case 60:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraBroker(value);
      break;
    case 61:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxWithholding(value);
      break;
    case 62:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMultiplier(value);
      break;
    case 63:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraSubAccountNo(value);
      break;
    case 64:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubAccountNo(value);
      break;
    case 65:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtherFees(value);
      break;
    case 66:
      var value = /** @type {string} */ (reader.readString());
      msg.setYear(value);
      break;
    case 67:
      var value = /** @type {string} */ (reader.readString());
      msg.setParticipantNo(value);
      break;
    case 68:
      var value = new proto.reportpb.ActDateObj;
      reader.readMessage(value,proto.reportpb.ActDateObj.deserializeBinaryFromReader);
      msg.setAsOfDate(value);
      break;
    case 69:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegisteredAdvisorCapacity(value);
      break;
    case 70:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpecialRemarks(value);
      break;
    case 71:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeOfExecution(value);
      break;
    case 72:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrincipal(value);
      break;
    case 73:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiscFee(value);
      break;
    case 74:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtherFees2(value);
      break;
    case 75:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 76:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscretion(value);
      break;
    case 77:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 78:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyRate(value);
      break;
    case 79:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyAmt(value);
      break;
    case 80:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecId(value);
      break;
    case 81:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegId(value);
      break;
    case 82:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlementMethod(value);
      break;
    case 83:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSolicited(value);
      break;
    case 84:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStepIndicator(value);
      break;
    case 85:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setComparisonIndicator(value);
      break;
    case 86:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoRenumeration(value);
      break;
    case 87:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWhenIssue(value);
      break;
    case 88:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNewIssue(value);
      break;
    case 89:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMsrb(value);
      break;
    case 90:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCorp(value);
      break;
    case 91:
      var value = /** @type {string} */ (reader.readString());
      msg.setYtc(value);
      break;
    case 92:
      var value = /** @type {string} */ (reader.readString());
      msg.setYtm(value);
      break;
    case 93:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarkAmount(value);
      break;
    case 94:
      var value = /** @type {string} */ (reader.readString());
      msg.setPmp(value);
      break;
    case 95:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarkRate(value);
      break;
    case 96:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtsIdentifier(value);
      break;
    case 97:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyGrossAmt(value);
      break;
    case 98:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencyFees(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.addOtherFeeValues(value);
      break;
    case 101:
      var value = /** @type {string} */ (reader.readString());
      msg.setEuroclearId(value);
      break;
    case 102:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSettlementInstructionId(value);
      break;
    case 103:
      var value = /** @type {string} */ (reader.readString());
      msg.setMapEntryType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.Activity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.Activity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.Activity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.Activity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEffSettleDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.reportpb.ActDateObj.serializeBinaryToWriter
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCapacity();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getEffTradeDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.reportpb.ActDateObj.serializeBinaryToWriter
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSymbolDescription();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAssetType();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.reportpb.ActDateObj.serializeBinaryToWriter
    );
  }
  f = message.getTradeDate();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.reportpb.ActDateObj.serializeBinaryToWriter
    );
  }
  f = message.getTradeAt();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getSettleDate();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.reportpb.ActDateObj.serializeBinaryToWriter
    );
  }
  f = message.getEntryType();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getSide();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getGrossAmt();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getRegFee();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getTafFee();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getFees();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getNetAmt();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getBatchNo();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getTrnsId();
  if (f !== 0) {
    writer.writeUint32(
      32,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      33,
      f
    );
  }
  f = message.getExecutingVenue();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getVendor();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getContraAccountNo();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getCommission();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getContraCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getContraAccountId();
  if (f !== 0) {
    writer.writeUint32(
      41,
      f
    );
  }
  f = message.getOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getEntryTypeDescription();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getRep();
  if (f.length > 0) {
    writer.writeString(
      44,
      f
    );
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getCompressType();
  if (f.length > 0) {
    writer.writeString(
      46,
      f
    );
  }
  f = message.getCompressCount();
  if (f !== 0) {
    writer.writeUint32(
      47,
      f
    );
  }
  f = message.getCompressId();
  if (f !== 0) {
    writer.writeUint32(
      48,
      f
    );
  }
  f = message.getTraderId();
  if (f.length > 0) {
    writer.writeString(
      49,
      f
    );
  }
  f = message.getReferenceId();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getPreviousTrnsId();
  if (f !== 0) {
    writer.writeUint32(
      51,
      f
    );
  }
  f = message.getProduct();
  if (f.length > 0) {
    writer.writeString(
      52,
      f
    );
  }
  f = message.getAccrual();
  if (f.length > 0) {
    writer.writeString(
      53,
      f
    );
  }
  f = message.getCouponRate();
  if (f.length > 0) {
    writer.writeString(
      54,
      f
    );
  }
  f = message.getInterestPayment();
  if (f.length > 0) {
    writer.writeString(
      55,
      f
    );
  }
  f = message.getYield();
  if (f.length > 0) {
    writer.writeString(
      56,
      f
    );
  }
  f = message.getOrgType();
  if (f.length > 0) {
    writer.writeString(
      57,
      f
    );
  }
  f = message.getOrgTerm();
  if (f != null) {
    writer.writeMessage(
      58,
      f,
      proto.reportpb.ActDateObj.serializeBinaryToWriter
    );
  }
  f = message.getSameDayCancel();
  if (f) {
    writer.writeBool(
      59,
      f
    );
  }
  f = message.getContraBroker();
  if (f.length > 0) {
    writer.writeString(
      60,
      f
    );
  }
  f = message.getTaxWithholding();
  if (f.length > 0) {
    writer.writeString(
      61,
      f
    );
  }
  f = message.getMultiplier();
  if (f !== 0) {
    writer.writeInt32(
      62,
      f
    );
  }
  f = message.getContraSubAccountNo();
  if (f.length > 0) {
    writer.writeString(
      63,
      f
    );
  }
  f = message.getSubAccountNo();
  if (f.length > 0) {
    writer.writeString(
      64,
      f
    );
  }
  f = message.getOtherFees();
  if (f.length > 0) {
    writer.writeString(
      65,
      f
    );
  }
  f = message.getYear();
  if (f.length > 0) {
    writer.writeString(
      66,
      f
    );
  }
  f = message.getParticipantNo();
  if (f.length > 0) {
    writer.writeString(
      67,
      f
    );
  }
  f = message.getAsOfDate();
  if (f != null) {
    writer.writeMessage(
      68,
      f,
      proto.reportpb.ActDateObj.serializeBinaryToWriter
    );
  }
  f = message.getRegisteredAdvisorCapacity();
  if (f.length > 0) {
    writer.writeString(
      69,
      f
    );
  }
  f = message.getSpecialRemarks();
  if (f.length > 0) {
    writer.writeString(
      70,
      f
    );
  }
  f = message.getTimeOfExecution();
  if (f.length > 0) {
    writer.writeString(
      71,
      f
    );
  }
  f = message.getPrincipal();
  if (f.length > 0) {
    writer.writeString(
      72,
      f
    );
  }
  f = message.getMiscFee();
  if (f.length > 0) {
    writer.writeString(
      73,
      f
    );
  }
  f = message.getOtherFees2();
  if (f.length > 0) {
    writer.writeString(
      74,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      75,
      f
    );
  }
  f = message.getDiscretion();
  if (f.length > 0) {
    writer.writeString(
      76,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      77,
      f
    );
  }
  f = message.getCurrencyRate();
  if (f.length > 0) {
    writer.writeString(
      78,
      f
    );
  }
  f = message.getCurrencyAmt();
  if (f.length > 0) {
    writer.writeString(
      79,
      f
    );
  }
  f = message.getExecId();
  if (f.length > 0) {
    writer.writeString(
      80,
      f
    );
  }
  f = message.getLegId();
  if (f.length > 0) {
    writer.writeString(
      81,
      f
    );
  }
  f = message.getSettlementMethod();
  if (f.length > 0) {
    writer.writeString(
      82,
      f
    );
  }
  f = message.getIsSolicited();
  if (f) {
    writer.writeBool(
      83,
      f
    );
  }
  f = message.getStepIndicator();
  if (f) {
    writer.writeBool(
      84,
      f
    );
  }
  f = message.getComparisonIndicator();
  if (f) {
    writer.writeBool(
      85,
      f
    );
  }
  f = message.getNoRenumeration();
  if (f) {
    writer.writeBool(
      86,
      f
    );
  }
  f = message.getWhenIssue();
  if (f) {
    writer.writeBool(
      87,
      f
    );
  }
  f = message.getNewIssue();
  if (f) {
    writer.writeBool(
      88,
      f
    );
  }
  f = message.getIsMsrb();
  if (f) {
    writer.writeBool(
      89,
      f
    );
  }
  f = message.getIsCorp();
  if (f) {
    writer.writeBool(
      90,
      f
    );
  }
  f = message.getYtc();
  if (f.length > 0) {
    writer.writeString(
      91,
      f
    );
  }
  f = message.getYtm();
  if (f.length > 0) {
    writer.writeString(
      92,
      f
    );
  }
  f = message.getMarkAmount();
  if (f.length > 0) {
    writer.writeString(
      93,
      f
    );
  }
  f = message.getPmp();
  if (f.length > 0) {
    writer.writeString(
      94,
      f
    );
  }
  f = message.getMarkRate();
  if (f.length > 0) {
    writer.writeString(
      95,
      f
    );
  }
  f = message.getAtsIdentifier();
  if (f.length > 0) {
    writer.writeString(
      96,
      f
    );
  }
  f = message.getCurrencyGrossAmt();
  if (f.length > 0) {
    writer.writeString(
      97,
      f
    );
  }
  f = message.getCurrencyFees();
  if (f.length > 0) {
    writer.writeString(
      98,
      f
    );
  }
  f = message.getOtherFeeValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      100,
      f
    );
  }
  f = message.getEuroclearId();
  if (f.length > 0) {
    writer.writeString(
      101,
      f
    );
  }
  f = message.getSettlementInstructionId();
  if (f !== 0) {
    writer.writeUint32(
      102,
      f
    );
  }
  f = message.getMapEntryType();
  if (f.length > 0) {
    writer.writeString(
      103,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_name = 3;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional ActDateObj eff_settle_date = 5;
 * @return {?proto.reportpb.ActDateObj}
 */
proto.reportpb.Activity.prototype.getEffSettleDate = function() {
  return /** @type{?proto.reportpb.ActDateObj} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.ActDateObj, 5));
};


/**
 * @param {?proto.reportpb.ActDateObj|undefined} value
 * @return {!proto.reportpb.Activity} returns this
*/
proto.reportpb.Activity.prototype.setEffSettleDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.clearEffSettleDate = function() {
  return this.setEffSettleDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.Activity.prototype.hasEffSettleDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string broker = 6;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string capacity = 8;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getCapacity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional ActDateObj eff_trade_date = 9;
 * @return {?proto.reportpb.ActDateObj}
 */
proto.reportpb.Activity.prototype.getEffTradeDate = function() {
  return /** @type{?proto.reportpb.ActDateObj} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.ActDateObj, 9));
};


/**
 * @param {?proto.reportpb.ActDateObj|undefined} value
 * @return {!proto.reportpb.Activity} returns this
*/
proto.reportpb.Activity.prototype.setEffTradeDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.clearEffTradeDate = function() {
  return this.setEffTradeDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.Activity.prototype.hasEffTradeDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string symbol = 10;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string symbol_description = 11;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getSymbolDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setSymbolDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string cusip = 12;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string asset_type = 13;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getAssetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setAssetType = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string created_at = 14;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional ActDateObj system_date = 15;
 * @return {?proto.reportpb.ActDateObj}
 */
proto.reportpb.Activity.prototype.getSystemDate = function() {
  return /** @type{?proto.reportpb.ActDateObj} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.ActDateObj, 15));
};


/**
 * @param {?proto.reportpb.ActDateObj|undefined} value
 * @return {!proto.reportpb.Activity} returns this
*/
proto.reportpb.Activity.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.Activity.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional ActDateObj trade_date = 16;
 * @return {?proto.reportpb.ActDateObj}
 */
proto.reportpb.Activity.prototype.getTradeDate = function() {
  return /** @type{?proto.reportpb.ActDateObj} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.ActDateObj, 16));
};


/**
 * @param {?proto.reportpb.ActDateObj|undefined} value
 * @return {!proto.reportpb.Activity} returns this
*/
proto.reportpb.Activity.prototype.setTradeDate = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.clearTradeDate = function() {
  return this.setTradeDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.Activity.prototype.hasTradeDate = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string trade_at = 17;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getTradeAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setTradeAt = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional ActDateObj settle_date = 18;
 * @return {?proto.reportpb.ActDateObj}
 */
proto.reportpb.Activity.prototype.getSettleDate = function() {
  return /** @type{?proto.reportpb.ActDateObj} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.ActDateObj, 18));
};


/**
 * @param {?proto.reportpb.ActDateObj|undefined} value
 * @return {!proto.reportpb.Activity} returns this
*/
proto.reportpb.Activity.prototype.setSettleDate = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.clearSettleDate = function() {
  return this.setSettleDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.Activity.prototype.hasSettleDate = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional string entry_type = 19;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getEntryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setEntryType = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string side = 20;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getSide = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setSide = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string qty = 21;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string price = 22;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string gross_amt = 23;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getGrossAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setGrossAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string reg_fee = 24;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getRegFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setRegFee = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string taf_fee = 25;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getTafFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setTafFee = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string fees = 26;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getFees = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setFees = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string net_amt = 27;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getNetAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setNetAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string description = 28;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string batch_no = 29;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getBatchNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setBatchNo = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string status = 30;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string created_by = 31;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional uint32 trns_id = 32;
 * @return {number}
 */
proto.reportpb.Activity.prototype.getTrnsId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setTrnsId = function(value) {
  return jspb.Message.setProto3IntField(this, 32, value);
};


/**
 * optional uint32 account_id = 33;
 * @return {number}
 */
proto.reportpb.Activity.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 33, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 33, value);
};


/**
 * optional string executing_venue = 34;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getExecutingVenue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setExecutingVenue = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string external_id = 35;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string order_id = 36;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string vendor = 37;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getVendor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setVendor = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string contra_account_no = 38;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getContraAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setContraAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional string commission = 39;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getCommission = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setCommission = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string contra_correspondent = 40;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getContraCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setContraCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional uint32 contra_account_id = 41;
 * @return {number}
 */
proto.reportpb.Activity.prototype.getContraAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 41, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setContraAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 41, value);
};


/**
 * optional string original_cusip = 42;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional string entry_type_description = 43;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getEntryTypeDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setEntryTypeDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * optional string rep = 44;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getRep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setRep = function(value) {
  return jspb.Message.setProto3StringField(this, 44, value);
};


/**
 * optional string branch = 45;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * optional string compress_type = 46;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getCompressType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 46, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setCompressType = function(value) {
  return jspb.Message.setProto3StringField(this, 46, value);
};


/**
 * optional uint32 compress_count = 47;
 * @return {number}
 */
proto.reportpb.Activity.prototype.getCompressCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 47, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setCompressCount = function(value) {
  return jspb.Message.setProto3IntField(this, 47, value);
};


/**
 * optional uint32 compress_id = 48;
 * @return {number}
 */
proto.reportpb.Activity.prototype.getCompressId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 48, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setCompressId = function(value) {
  return jspb.Message.setProto3IntField(this, 48, value);
};


/**
 * optional string trader_id = 49;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getTraderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 49, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setTraderId = function(value) {
  return jspb.Message.setProto3StringField(this, 49, value);
};


/**
 * optional string reference_id = 50;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional uint32 previous_trns_id = 51;
 * @return {number}
 */
proto.reportpb.Activity.prototype.getPreviousTrnsId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 51, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setPreviousTrnsId = function(value) {
  return jspb.Message.setProto3IntField(this, 51, value);
};


/**
 * optional string product = 52;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getProduct = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 52, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setProduct = function(value) {
  return jspb.Message.setProto3StringField(this, 52, value);
};


/**
 * optional string accrual = 53;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getAccrual = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 53, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setAccrual = function(value) {
  return jspb.Message.setProto3StringField(this, 53, value);
};


/**
 * optional string coupon_rate = 54;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getCouponRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 54, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setCouponRate = function(value) {
  return jspb.Message.setProto3StringField(this, 54, value);
};


/**
 * optional string interest_payment = 55;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getInterestPayment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 55, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setInterestPayment = function(value) {
  return jspb.Message.setProto3StringField(this, 55, value);
};


/**
 * optional string yield = 56;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getYield = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 56, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setYield = function(value) {
  return jspb.Message.setProto3StringField(this, 56, value);
};


/**
 * optional string org_type = 57;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getOrgType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 57, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setOrgType = function(value) {
  return jspb.Message.setProto3StringField(this, 57, value);
};


/**
 * optional ActDateObj org_term = 58;
 * @return {?proto.reportpb.ActDateObj}
 */
proto.reportpb.Activity.prototype.getOrgTerm = function() {
  return /** @type{?proto.reportpb.ActDateObj} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.ActDateObj, 58));
};


/**
 * @param {?proto.reportpb.ActDateObj|undefined} value
 * @return {!proto.reportpb.Activity} returns this
*/
proto.reportpb.Activity.prototype.setOrgTerm = function(value) {
  return jspb.Message.setWrapperField(this, 58, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.clearOrgTerm = function() {
  return this.setOrgTerm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.Activity.prototype.hasOrgTerm = function() {
  return jspb.Message.getField(this, 58) != null;
};


/**
 * optional bool same_day_cancel = 59;
 * @return {boolean}
 */
proto.reportpb.Activity.prototype.getSameDayCancel = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 59, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setSameDayCancel = function(value) {
  return jspb.Message.setProto3BooleanField(this, 59, value);
};


/**
 * optional string contra_broker = 60;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getContraBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 60, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setContraBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 60, value);
};


/**
 * optional string tax_withholding = 61;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getTaxWithholding = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 61, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setTaxWithholding = function(value) {
  return jspb.Message.setProto3StringField(this, 61, value);
};


/**
 * optional int32 multiplier = 62;
 * @return {number}
 */
proto.reportpb.Activity.prototype.getMultiplier = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 62, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setMultiplier = function(value) {
  return jspb.Message.setProto3IntField(this, 62, value);
};


/**
 * optional string contra_sub_account_no = 63;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getContraSubAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 63, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setContraSubAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 63, value);
};


/**
 * optional string sub_account_no = 64;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getSubAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 64, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setSubAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 64, value);
};


/**
 * optional string other_fees = 65;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getOtherFees = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 65, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setOtherFees = function(value) {
  return jspb.Message.setProto3StringField(this, 65, value);
};


/**
 * optional string year = 66;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getYear = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 66, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setYear = function(value) {
  return jspb.Message.setProto3StringField(this, 66, value);
};


/**
 * optional string participant_no = 67;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getParticipantNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 67, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setParticipantNo = function(value) {
  return jspb.Message.setProto3StringField(this, 67, value);
};


/**
 * optional ActDateObj as_of_date = 68;
 * @return {?proto.reportpb.ActDateObj}
 */
proto.reportpb.Activity.prototype.getAsOfDate = function() {
  return /** @type{?proto.reportpb.ActDateObj} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.ActDateObj, 68));
};


/**
 * @param {?proto.reportpb.ActDateObj|undefined} value
 * @return {!proto.reportpb.Activity} returns this
*/
proto.reportpb.Activity.prototype.setAsOfDate = function(value) {
  return jspb.Message.setWrapperField(this, 68, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.clearAsOfDate = function() {
  return this.setAsOfDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.Activity.prototype.hasAsOfDate = function() {
  return jspb.Message.getField(this, 68) != null;
};


/**
 * optional string registered_advisor_capacity = 69;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getRegisteredAdvisorCapacity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 69, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setRegisteredAdvisorCapacity = function(value) {
  return jspb.Message.setProto3StringField(this, 69, value);
};


/**
 * optional string special_remarks = 70;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getSpecialRemarks = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 70, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setSpecialRemarks = function(value) {
  return jspb.Message.setProto3StringField(this, 70, value);
};


/**
 * optional string time_of_execution = 71;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getTimeOfExecution = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 71, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setTimeOfExecution = function(value) {
  return jspb.Message.setProto3StringField(this, 71, value);
};


/**
 * optional string principal = 72;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getPrincipal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 72, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setPrincipal = function(value) {
  return jspb.Message.setProto3StringField(this, 72, value);
};


/**
 * optional string misc_fee = 73;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getMiscFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 73, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setMiscFee = function(value) {
  return jspb.Message.setProto3StringField(this, 73, value);
};


/**
 * optional string other_fees_2 = 74;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getOtherFees2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 74, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setOtherFees2 = function(value) {
  return jspb.Message.setProto3StringField(this, 74, value);
};


/**
 * optional string location = 75;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 75, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 75, value);
};


/**
 * optional string discretion = 76;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getDiscretion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 76, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setDiscretion = function(value) {
  return jspb.Message.setProto3StringField(this, 76, value);
};


/**
 * optional string currency = 77;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 77, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 77, value);
};


/**
 * optional string currency_rate = 78;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getCurrencyRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 78, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setCurrencyRate = function(value) {
  return jspb.Message.setProto3StringField(this, 78, value);
};


/**
 * optional string currency_amt = 79;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getCurrencyAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 79, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setCurrencyAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 79, value);
};


/**
 * optional string exec_id = 80;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getExecId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 80, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setExecId = function(value) {
  return jspb.Message.setProto3StringField(this, 80, value);
};


/**
 * optional string leg_id = 81;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getLegId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 81, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setLegId = function(value) {
  return jspb.Message.setProto3StringField(this, 81, value);
};


/**
 * optional string settlement_method = 82;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getSettlementMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 82, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setSettlementMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 82, value);
};


/**
 * optional bool is_solicited = 83;
 * @return {boolean}
 */
proto.reportpb.Activity.prototype.getIsSolicited = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 83, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setIsSolicited = function(value) {
  return jspb.Message.setProto3BooleanField(this, 83, value);
};


/**
 * optional bool step_indicator = 84;
 * @return {boolean}
 */
proto.reportpb.Activity.prototype.getStepIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 84, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setStepIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 84, value);
};


/**
 * optional bool comparison_indicator = 85;
 * @return {boolean}
 */
proto.reportpb.Activity.prototype.getComparisonIndicator = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 85, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setComparisonIndicator = function(value) {
  return jspb.Message.setProto3BooleanField(this, 85, value);
};


/**
 * optional bool no_renumeration = 86;
 * @return {boolean}
 */
proto.reportpb.Activity.prototype.getNoRenumeration = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 86, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setNoRenumeration = function(value) {
  return jspb.Message.setProto3BooleanField(this, 86, value);
};


/**
 * optional bool when_issue = 87;
 * @return {boolean}
 */
proto.reportpb.Activity.prototype.getWhenIssue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 87, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setWhenIssue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 87, value);
};


/**
 * optional bool new_issue = 88;
 * @return {boolean}
 */
proto.reportpb.Activity.prototype.getNewIssue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 88, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setNewIssue = function(value) {
  return jspb.Message.setProto3BooleanField(this, 88, value);
};


/**
 * optional bool is_msrb = 89;
 * @return {boolean}
 */
proto.reportpb.Activity.prototype.getIsMsrb = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 89, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setIsMsrb = function(value) {
  return jspb.Message.setProto3BooleanField(this, 89, value);
};


/**
 * optional bool is_corp = 90;
 * @return {boolean}
 */
proto.reportpb.Activity.prototype.getIsCorp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 90, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setIsCorp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 90, value);
};


/**
 * optional string ytc = 91;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getYtc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 91, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setYtc = function(value) {
  return jspb.Message.setProto3StringField(this, 91, value);
};


/**
 * optional string ytm = 92;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getYtm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 92, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setYtm = function(value) {
  return jspb.Message.setProto3StringField(this, 92, value);
};


/**
 * optional string mark_amount = 93;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getMarkAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 93, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setMarkAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 93, value);
};


/**
 * optional string pmp = 94;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getPmp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 94, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setPmp = function(value) {
  return jspb.Message.setProto3StringField(this, 94, value);
};


/**
 * optional string mark_rate = 95;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getMarkRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 95, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setMarkRate = function(value) {
  return jspb.Message.setProto3StringField(this, 95, value);
};


/**
 * optional string ats_identifier = 96;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getAtsIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 96, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setAtsIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 96, value);
};


/**
 * optional string currency_gross_amt = 97;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getCurrencyGrossAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 97, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setCurrencyGrossAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 97, value);
};


/**
 * optional string currency_fees = 98;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getCurrencyFees = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 98, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setCurrencyFees = function(value) {
  return jspb.Message.setProto3StringField(this, 98, value);
};


/**
 * repeated string other_fee_values = 100;
 * @return {!Array<string>}
 */
proto.reportpb.Activity.prototype.getOtherFeeValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 100));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setOtherFeeValuesList = function(value) {
  return jspb.Message.setField(this, 100, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.addOtherFeeValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 100, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.clearOtherFeeValuesList = function() {
  return this.setOtherFeeValuesList([]);
};


/**
 * optional string euroclear_id = 101;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getEuroclearId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 101, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setEuroclearId = function(value) {
  return jspb.Message.setProto3StringField(this, 101, value);
};


/**
 * optional uint32 settlement_instruction_id = 102;
 * @return {number}
 */
proto.reportpb.Activity.prototype.getSettlementInstructionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 102, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setSettlementInstructionId = function(value) {
  return jspb.Message.setProto3IntField(this, 102, value);
};


/**
 * optional string map_entry_type = 103;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getMapEntryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 103, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setMapEntryType = function(value) {
  return jspb.Message.setProto3StringField(this, 103, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListActivityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListActivityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListActivityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListActivityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contraAccountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    asoftrns: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    broker: jspb.Message.getFieldWithDefault(msg, 6, ""),
    type: jspb.Message.getFieldWithDefault(msg, 7, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 8, ""),
    cusip: jspb.Message.getFieldWithDefault(msg, 9, ""),
    status: jspb.Message.getFieldWithDefault(msg, 10, ""),
    dateType: jspb.Message.getFieldWithDefault(msg, 11, ""),
    fromDate: (f = msg.getFromDate()) && proto.reportpb.ActDateObj.toObject(includeInstance, f),
    toDate: (f = msg.getToDate()) && proto.reportpb.ActDateObj.toObject(includeInstance, f),
    entryType: jspb.Message.getFieldWithDefault(msg, 14, ""),
    branch: jspb.Message.getFieldWithDefault(msg, 15, ""),
    rep: jspb.Message.getFieldWithDefault(msg, 16, ""),
    searchType: jspb.Message.getFieldWithDefault(msg, 17, ""),
    compressId: jspb.Message.getFieldWithDefault(msg, 18, 0),
    side: jspb.Message.getFieldWithDefault(msg, 19, ""),
    pagination: (f = msg.getPagination()) && proto_utilspb_pagination_pb.Pagination.toObject(includeInstance, f),
    trnsId: jspb.Message.getFieldWithDefault(msg, 21, 0),
    assetType: jspb.Message.getFieldWithDefault(msg, 22, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 23, 0),
    description: jspb.Message.getFieldWithDefault(msg, 24, ""),
    referenceId: jspb.Message.getFieldWithDefault(msg, 25, ""),
    participantNo: jspb.Message.getFieldWithDefault(msg, 26, ""),
    batchNo: jspb.Message.getFieldWithDefault(msg, 27, ""),
    transactionSide: jspb.Message.getFieldWithDefault(msg, 28, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 29, ""),
    qtyOnly: jspb.Message.getBooleanFieldWithDefault(msg, 30, false),
    netAmtOnly: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
    breakdownOtherFees: jspb.Message.getBooleanFieldWithDefault(msg, 32, false),
    isClientView: jspb.Message.getBooleanFieldWithDefault(msg, 33, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListActivityRequest}
 */
proto.reportpb.ListActivityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListActivityRequest;
  return proto.reportpb.ListActivityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListActivityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListActivityRequest}
 */
proto.reportpb.ListActivityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAsoftrns(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateType(value);
      break;
    case 12:
      var value = new proto.reportpb.ActDateObj;
      reader.readMessage(value,proto.reportpb.ActDateObj.deserializeBinaryFromReader);
      msg.setFromDate(value);
      break;
    case 13:
      var value = new proto.reportpb.ActDateObj;
      reader.readMessage(value,proto.reportpb.ActDateObj.deserializeBinaryFromReader);
      msg.setToDate(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryType(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranch(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setRep(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchType(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCompressId(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setSide(value);
      break;
    case 20:
      var value = new proto_utilspb_pagination_pb.Pagination;
      reader.readMessage(value,proto_utilspb_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTrnsId(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetType(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceId(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setParticipantNo(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchNo(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionSide(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setQtyOnly(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNetAmtOnly(value);
      break;
    case 32:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBreakdownOtherFees(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsClientView(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListActivityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListActivityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListActivityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListActivityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContraAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAsoftrns();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDateType();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getFromDate();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.reportpb.ActDateObj.serializeBinaryToWriter
    );
  }
  f = message.getToDate();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.reportpb.ActDateObj.serializeBinaryToWriter
    );
  }
  f = message.getEntryType();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getRep();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getSearchType();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getCompressId();
  if (f !== 0) {
    writer.writeUint32(
      18,
      f
    );
  }
  f = message.getSide();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto_utilspb_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getTrnsId();
  if (f !== 0) {
    writer.writeUint32(
      21,
      f
    );
  }
  f = message.getAssetType();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      23,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getReferenceId();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getParticipantNo();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getBatchNo();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getTransactionSide();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getQtyOnly();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
  f = message.getNetAmtOnly();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getBreakdownOtherFees();
  if (f) {
    writer.writeBool(
      32,
      f
    );
  }
  f = message.getIsClientView();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string contra_account_no = 3;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getContraAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setContraAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool asOfTRNS = 5;
 * @return {boolean}
 */
proto.reportpb.ListActivityRequest.prototype.getAsoftrns = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setAsoftrns = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string broker = 6;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string symbol = 8;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string cusip = 9;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string status = 10;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string date_type = 11;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getDateType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setDateType = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional ActDateObj from_date = 12;
 * @return {?proto.reportpb.ActDateObj}
 */
proto.reportpb.ListActivityRequest.prototype.getFromDate = function() {
  return /** @type{?proto.reportpb.ActDateObj} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.ActDateObj, 12));
};


/**
 * @param {?proto.reportpb.ActDateObj|undefined} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
*/
proto.reportpb.ListActivityRequest.prototype.setFromDate = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.clearFromDate = function() {
  return this.setFromDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListActivityRequest.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional ActDateObj to_date = 13;
 * @return {?proto.reportpb.ActDateObj}
 */
proto.reportpb.ListActivityRequest.prototype.getToDate = function() {
  return /** @type{?proto.reportpb.ActDateObj} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.ActDateObj, 13));
};


/**
 * @param {?proto.reportpb.ActDateObj|undefined} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
*/
proto.reportpb.ListActivityRequest.prototype.setToDate = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.clearToDate = function() {
  return this.setToDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListActivityRequest.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string entry_type = 14;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getEntryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setEntryType = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string branch = 15;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string rep = 16;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getRep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setRep = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string search_type = 17;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getSearchType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setSearchType = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional uint32 compress_id = 18;
 * @return {number}
 */
proto.reportpb.ListActivityRequest.prototype.getCompressId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setCompressId = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional string side = 19;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getSide = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setSide = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional utilspb.Pagination pagination = 20;
 * @return {?proto.utilspb.Pagination}
 */
proto.reportpb.ListActivityRequest.prototype.getPagination = function() {
  return /** @type{?proto.utilspb.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_utilspb_pagination_pb.Pagination, 20));
};


/**
 * @param {?proto.utilspb.Pagination|undefined} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
*/
proto.reportpb.ListActivityRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListActivityRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional uint32 trns_id = 21;
 * @return {number}
 */
proto.reportpb.ListActivityRequest.prototype.getTrnsId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setTrnsId = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional string asset_type = 22;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getAssetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setAssetType = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional uint32 account_id = 23;
 * @return {number}
 */
proto.reportpb.ListActivityRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional string description = 24;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string reference_id = 25;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string participant_no = 26;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getParticipantNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setParticipantNo = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string batch_no = 27;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getBatchNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setBatchNo = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string transaction_side = 28;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getTransactionSide = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setTransactionSide = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string currency = 29;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional bool qty_only = 30;
 * @return {boolean}
 */
proto.reportpb.ListActivityRequest.prototype.getQtyOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setQtyOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 30, value);
};


/**
 * optional bool net_amt_only = 31;
 * @return {boolean}
 */
proto.reportpb.ListActivityRequest.prototype.getNetAmtOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setNetAmtOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional bool breakdown_other_fees = 32;
 * @return {boolean}
 */
proto.reportpb.ListActivityRequest.prototype.getBreakdownOtherFees = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 32, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setBreakdownOtherFees = function(value) {
  return jspb.Message.setProto3BooleanField(this, 32, value);
};


/**
 * optional bool is_client_view = 33;
 * @return {boolean}
 */
proto.reportpb.ListActivityRequest.prototype.getIsClientView = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 33, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setIsClientView = function(value) {
  return jspb.Message.setProto3BooleanField(this, 33, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListActivityResponse.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListActivityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListActivityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListActivityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListActivityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    activitiesList: jspb.Message.toObjectList(msg.getActivitiesList(),
    proto.reportpb.Activity.toObject, includeInstance),
    summary: (f = msg.getSummary()) && proto.reportpb.ListActivitySummary.toObject(includeInstance, f),
    otherFeeTypesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListActivityResponse}
 */
proto.reportpb.ListActivityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListActivityResponse;
  return proto.reportpb.ListActivityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListActivityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListActivityResponse}
 */
proto.reportpb.ListActivityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.Activity;
      reader.readMessage(value,proto.reportpb.Activity.deserializeBinaryFromReader);
      msg.addActivities(value);
      break;
    case 2:
      var value = new proto.reportpb.ListActivitySummary;
      reader.readMessage(value,proto.reportpb.ListActivitySummary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addOtherFeeTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListActivityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListActivityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListActivityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListActivityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.Activity.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reportpb.ListActivitySummary.serializeBinaryToWriter
    );
  }
  f = message.getOtherFeeTypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * repeated Activity activities = 1;
 * @return {!Array<!proto.reportpb.Activity>}
 */
proto.reportpb.ListActivityResponse.prototype.getActivitiesList = function() {
  return /** @type{!Array<!proto.reportpb.Activity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.Activity, 1));
};


/**
 * @param {!Array<!proto.reportpb.Activity>} value
 * @return {!proto.reportpb.ListActivityResponse} returns this
*/
proto.reportpb.ListActivityResponse.prototype.setActivitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.Activity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.Activity}
 */
proto.reportpb.ListActivityResponse.prototype.addActivities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.Activity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListActivityResponse} returns this
 */
proto.reportpb.ListActivityResponse.prototype.clearActivitiesList = function() {
  return this.setActivitiesList([]);
};


/**
 * optional ListActivitySummary summary = 2;
 * @return {?proto.reportpb.ListActivitySummary}
 */
proto.reportpb.ListActivityResponse.prototype.getSummary = function() {
  return /** @type{?proto.reportpb.ListActivitySummary} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.ListActivitySummary, 2));
};


/**
 * @param {?proto.reportpb.ListActivitySummary|undefined} value
 * @return {!proto.reportpb.ListActivityResponse} returns this
*/
proto.reportpb.ListActivityResponse.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListActivityResponse} returns this
 */
proto.reportpb.ListActivityResponse.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListActivityResponse.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated string other_fee_types = 3;
 * @return {!Array<string>}
 */
proto.reportpb.ListActivityResponse.prototype.getOtherFeeTypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reportpb.ListActivityResponse} returns this
 */
proto.reportpb.ListActivityResponse.prototype.setOtherFeeTypesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reportpb.ListActivityResponse} returns this
 */
proto.reportpb.ListActivityResponse.prototype.addOtherFeeTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListActivityResponse} returns this
 */
proto.reportpb.ListActivityResponse.prototype.clearOtherFeeTypesList = function() {
  return this.setOtherFeeTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ActDateObj.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ActDateObj.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ActDateObj} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ActDateObj.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0),
    day: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ActDateObj}
 */
proto.reportpb.ActDateObj.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ActDateObj;
  return proto.reportpb.ActDateObj.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ActDateObj} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ActDateObj}
 */
proto.reportpb.ActDateObj.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ActDateObj.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ActDateObj.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ActDateObj} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ActDateObj.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 year = 1;
 * @return {number}
 */
proto.reportpb.ActDateObj.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.ActDateObj} returns this
 */
proto.reportpb.ActDateObj.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 month = 2;
 * @return {number}
 */
proto.reportpb.ActDateObj.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.ActDateObj} returns this
 */
proto.reportpb.ActDateObj.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 day = 3;
 * @return {number}
 */
proto.reportpb.ActDateObj.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.ActDateObj} returns this
 */
proto.reportpb.ActDateObj.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListActivitySummary.repeatedFields_ = [11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListActivitySummary.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListActivitySummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListActivitySummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListActivitySummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalRows: jspb.Message.getFieldWithDefault(msg, 1, 0),
    qty: jspb.Message.getFieldWithDefault(msg, 2, ""),
    grossAmt: jspb.Message.getFieldWithDefault(msg, 3, ""),
    regFee: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tafFee: jspb.Message.getFieldWithDefault(msg, 5, ""),
    commission: jspb.Message.getFieldWithDefault(msg, 6, ""),
    fees: jspb.Message.getFieldWithDefault(msg, 7, ""),
    netAmt: jspb.Message.getFieldWithDefault(msg, 8, ""),
    otherFees2: jspb.Message.getFieldWithDefault(msg, 9, ""),
    taxWithholding: jspb.Message.getFieldWithDefault(msg, 10, ""),
    otherFeeTypesList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    otherFeeValuesList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListActivitySummary}
 */
proto.reportpb.ListActivitySummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListActivitySummary;
  return proto.reportpb.ListActivitySummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListActivitySummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListActivitySummary}
 */
proto.reportpb.ListActivitySummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRows(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGrossAmt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegFee(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTafFee(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommission(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFees(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetAmt(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtherFees2(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxWithholding(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addOtherFeeTypes(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addOtherFeeValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListActivitySummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListActivitySummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListActivitySummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListActivitySummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalRows();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGrossAmt();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRegFee();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTafFee();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCommission();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFees();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNetAmt();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOtherFees2();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTaxWithholding();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getOtherFeeTypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getOtherFeeValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
};


/**
 * optional uint32 total_rows = 1;
 * @return {number}
 */
proto.reportpb.ListActivitySummary.prototype.getTotalRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.setTotalRows = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string qty = 2;
 * @return {string}
 */
proto.reportpb.ListActivitySummary.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string gross_amt = 3;
 * @return {string}
 */
proto.reportpb.ListActivitySummary.prototype.getGrossAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.setGrossAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string reg_fee = 4;
 * @return {string}
 */
proto.reportpb.ListActivitySummary.prototype.getRegFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.setRegFee = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string taf_fee = 5;
 * @return {string}
 */
proto.reportpb.ListActivitySummary.prototype.getTafFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.setTafFee = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string commission = 6;
 * @return {string}
 */
proto.reportpb.ListActivitySummary.prototype.getCommission = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.setCommission = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string fees = 7;
 * @return {string}
 */
proto.reportpb.ListActivitySummary.prototype.getFees = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.setFees = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string net_amt = 8;
 * @return {string}
 */
proto.reportpb.ListActivitySummary.prototype.getNetAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.setNetAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string other_fees_2 = 9;
 * @return {string}
 */
proto.reportpb.ListActivitySummary.prototype.getOtherFees2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.setOtherFees2 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string tax_withholding = 10;
 * @return {string}
 */
proto.reportpb.ListActivitySummary.prototype.getTaxWithholding = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.setTaxWithholding = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated string other_fee_types = 11;
 * @return {!Array<string>}
 */
proto.reportpb.ListActivitySummary.prototype.getOtherFeeTypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.setOtherFeeTypesList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.addOtherFeeTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.clearOtherFeeTypesList = function() {
  return this.setOtherFeeTypesList([]);
};


/**
 * repeated string other_fee_values = 12;
 * @return {!Array<string>}
 */
proto.reportpb.ListActivitySummary.prototype.getOtherFeeValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.setOtherFeeValuesList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.addOtherFeeValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.clearOtherFeeValuesList = function() {
  return this.setOtherFeeValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListActivitiesResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListActivitiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListActivitiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListActivitiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListActivitiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    activitiesList: jspb.Message.toObjectList(msg.getActivitiesList(),
    proto.reportpb.Activity.toObject, includeInstance),
    otherFeeTypesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListActivitiesResponse}
 */
proto.reportpb.ListActivitiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListActivitiesResponse;
  return proto.reportpb.ListActivitiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListActivitiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListActivitiesResponse}
 */
proto.reportpb.ListActivitiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.Activity;
      reader.readMessage(value,proto.reportpb.Activity.deserializeBinaryFromReader);
      msg.addActivities(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addOtherFeeTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListActivitiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListActivitiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListActivitiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListActivitiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.Activity.serializeBinaryToWriter
    );
  }
  f = message.getOtherFeeTypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated Activity activities = 1;
 * @return {!Array<!proto.reportpb.Activity>}
 */
proto.reportpb.ListActivitiesResponse.prototype.getActivitiesList = function() {
  return /** @type{!Array<!proto.reportpb.Activity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.Activity, 1));
};


/**
 * @param {!Array<!proto.reportpb.Activity>} value
 * @return {!proto.reportpb.ListActivitiesResponse} returns this
*/
proto.reportpb.ListActivitiesResponse.prototype.setActivitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.Activity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.Activity}
 */
proto.reportpb.ListActivitiesResponse.prototype.addActivities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.Activity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListActivitiesResponse} returns this
 */
proto.reportpb.ListActivitiesResponse.prototype.clearActivitiesList = function() {
  return this.setActivitiesList([]);
};


/**
 * repeated string other_fee_types = 2;
 * @return {!Array<string>}
 */
proto.reportpb.ListActivitiesResponse.prototype.getOtherFeeTypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reportpb.ListActivitiesResponse} returns this
 */
proto.reportpb.ListActivitiesResponse.prototype.setOtherFeeTypesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reportpb.ListActivitiesResponse} returns this
 */
proto.reportpb.ListActivitiesResponse.prototype.addOtherFeeTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListActivitiesResponse} returns this
 */
proto.reportpb.ListActivitiesResponse.prototype.clearOtherFeeTypesList = function() {
  return this.setOtherFeeTypesList([]);
};


goog.object.extend(exports, proto.reportpb);
