import React, { useState, useEffect } from 'react';
import ClientGrid from '../../../components/ClientGrid/ClientGrid'
import ClientGrid2 from '../../../components/ClientGrid/ClientGrid2'
import Button from '../../../components/Buttons/Button'
import DateTimePicker from '../../../components/Textfields/DateTimePicker'
import AutoCompleteCorrespondent from '../../../components/AutoComplete/AutoCompleteCorrespondent'
import AutoCompleteAccountNo from '../../../components/AutoComplete/AutoCompleteAccountNo'
import AutoCompleteAccountName from '../../../components/AutoComplete/AutoCompleteAccountName'
import AutoCompleteMasterAccountNo from '../../../components/AutoComplete/AutoCompleteMasterAccountNo'
import AutoCompleteBranch from '../../../components/AutoComplete/AutoCompleteBranch'
import AutoCompleteRep from '../../../components/AutoComplete/AutoCompleteRep'
import Text from '../../../components/Typography/Text'
import {
  notifyInfo,
  notifyError,
} from '../../../components/Messages/Notification'
import { balanceColumns } from '../../../lib/data/columns'
import { listBalance } from '../../../services/BalanceService'
import { getSystemDate } from '../../../services/ProfileService'
import QueryParam from '../../../services/QueryParamService'
import {
  CustomBodyRenderCurrency,
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderDate,
  CustomBodyRenderDateTime,
  CustomBodyRenderBoolean,
  CustomBodyRenderAlign,
} from '../../../components/Table/CustomBodyRender';
import Table, { columnType } from '../../../components/ClearingTable/Table';
import { formatPbDate, formatPbDateTime } from '../../../lib/fmt';
import { formatCurrencySymbol } from '../../../lib/fmt2';
import moment from 'moment-timezone';

export default function Balance() {
  const [rows, setRows] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [footerData, setFooterData] = useState({});
  const [customData, setCustomData] = useState([]);
  const [pagination, setPagination] = useState({});
  const [isNotMovable, setIsNotMovable] = React.useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [searchDataCsv, setSearchDataCsv] = React.useState([]);
  const [previousRows, setPreviousRows] = useState(0);
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      fromDate: '',
      toDate: '',
      correspondent: '',
      branch: '',
      accountNo: '',
      masterAccountNo: '',
      rep: '',
      accountName: '',
    }),
  )

  const columns = [
    {
      // Already converted to string in handlesearch
      name: 'date',
      label: 'Date',
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
      options: {
        customBodyRender: CustomBodyRenderAlign
      }
    },
    {
      name: 'branch',
      label: 'Branch',
      options: {
        customBodyRender: CustomBodyRenderAlign
      }
    },
    {
      name: 'accountNo',
      label: 'Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign
      }
    },
    {
      name: 'accountName',
      label: 'Account Name',
      options: {
        customBodyRender: CustomBodyRenderAlign
      }
    },
    {
      name: 'accountStatus',
      label: 'Account Status',
      options: {
        customBodyRender: CustomBodyRenderAlign
      }
    },
    {
      name: 'tdCashBalance',
      label: 'TD Cash Balance',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'tdLongMarketValue',
      label: 'TD Long Market Value',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'tdShortMarketValue',
      label: 'TD Short Market Value',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'tdEquity',
      label: 'TD Equity',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'tdAdjustedBalance',
      label: 'TD Adjusted Balance',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'sdCashBalance',
      label: 'SD Cash Balance',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'sdLongMarketValue',
      label: 'SD Long Market Value',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'sdShortMarketValue',
      label: 'SD Short Market Value',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'sdEquity',
      label: 'SD Equity',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'sdAdjustedBalance',
      label: 'SD Adjusted Balance',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'a-selectAll',
      label: 'Select All',
      options: {
        display: true,
        setCellHeaderProps: () => ({
          style: {
            width: '0px',
            display: 'table-cell',
            padding: 0,
            pointerEvents: 'none',
            fontSize: 0,
          },
        }),
      },
    },
  ];

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
    serverSide: 'true',
  };

  const init = async () => {
    const searchParameters = localStorage.getItem('search_data')

    const {
      fromDate,
      toDate,
      correspondent,
      branch,
      accountNo,
      masterAccountNo,
      rep,
      accountName,
    } = searchParameters ? JSON.parse(searchParameters) : searchData

    const systemDate = await getSystemDate()

    setSearchData({
      ...searchData,
      fromDate: fromDate ? fromDate : systemDate,
      toDate: toDate ? toDate : systemDate,
      correspondent: correspondent ? correspondent : '',
      branch: branch ? branch : '',
      accountNo: accountNo ? accountNo : '',
      masterAccountNo: masterAccountNo ? masterAccountNo : '',
      rep: rep ? rep : '',
      accountName: accountName ? accountName : '',
    })
  }

  React.useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  const handleSearchDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target

    setSearchData({ ...searchData, [input.name]: input.value })
  }

  const handleSearch = async (paginationChange) => {
    try {
      setLoading(true)

      QueryParam.set(searchData)

      // localStorage.setItem('search_data', JSON.stringify(searchData))

      // const data = await listBalance(searchData)

      // let rowsCopy = data.balancesList;

      // const rowsCopyOutput = rowsCopy.map((data) => {
      //   let tempDate = formatPbDate(data.date);
      //   data.date = tempDate

      //   return data;
      // })

      // setRows(rowsCopyOutput)

      // notifyInfo(
      //   data.balancesList.length > 0
      //     ? data.balancesList.length + ' search result(s)'
      //     : 'No records found.',
      // )
      let paginationCopy = {
        ...pagination,
        reload: false,
        pageNo: paginationChange === true ? pagination.pageNo : 0,
        rowsPerPage: pagination.rowsPerPage || 100,
      };

      if(previousRows != 0){
        if (previousRows < pagination.rowsPerPage * pagination.pageNo) {
          paginationCopy.pageNo = 0;
        }
      } 
	    if(paginationChange !== true){
        paginationCopy.filterNames = [];
        paginationCopy.filterValues = [];
      }

      const data = await listBalance(searchData, paginationCopy);

      let rowsCopy = data.balancesList;

      const rowsCopyOutput = rowsCopy.map((data) => {
        let tempDate = formatPbDate(data.date);
        data.date = tempDate

        return data;
      })
      
      setSearchDataCsv(searchData);
      setPreviousRows(data.summary.totalRows);
      setTotalRows(data.summary.totalRows);      

      paginationCopy.count = data.summary.totalRows;
      setPagination(paginationCopy);
      setRows(rowsCopyOutput);

      if (paginationChange !== true) {
        notifyInfo(data.summary.totalRows + ' search results.');
      }      
console.log(data.summary.tdEquity);
      setFooterData({
        tdCashBalance: formatCurrencySymbol(data.summary.tdCashBalance),
        tdLongMarketValue: formatCurrencySymbol(data.summary.tdLongMarketValue),
        tdShortMarketValue: formatCurrencySymbol(data.summary.tdShortMarketValue),
        tdEquity: formatCurrencySymbol(data.summary.tdEquity),
        tdAdjustedBalance: formatCurrencySymbol(data.summary.tdAdjustedBalance),
        sdCashBalance: formatCurrencySymbol(data.summary.sdCashBalance),
        sdLongMarketValue: formatCurrencySymbol(data.summary.sdLongMarketValue),
        sdShortMarketValue: formatCurrencySymbol(data.summary.sdShortMarketValue),
        sdEquity: formatCurrencySymbol(data.summary.sdEquity),
        sdAdjustedBalance: formatCurrencySymbol(data.summary.sdAdjustedBalance),
      });
    } catch (error) {
      setLoading(false)
      notifyError(error.message)
      console.log(error)
      return
    } finally {
      setLoading(false)
    }
  }

  const getCsvData = async () => {
    let paginationCopy = {
      ...pagination,
      reload: false,
      pageNo: 0,
      rowsPerPage: totalRows,
    };

    // const data = await movePageBalance(searchData, paginationCopy);
    // return data.balancesList;

    const data = await listBalance(searchDataCsv, paginationCopy);
    console.log(data);
    const rows = data.balancesList.map((data) => ({
      accountName: data.accountName,
      accountNo: data.accountNo,
      adjustedBalance: data.adjustedBalance,      
      branch: data.branch,
      cashBalance: data.cashBalance,
      correspondent: data.correspondent,
      date: moment(data.date.month + '/' + data.date.day + '/' + data.date.year).format('MM/DD/YYYY'), //data.date.month,
      equity: data.equity,
      longMarketValue: data.longMarketValue,
      shortMarketValue: data.shortMarketValue,    
    }));
    return rows;
  }

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Balance" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading}
              loading={loading}
              type="search"
              label={loading ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell-none">
            <DateTimePicker
              type="date"
              name="fromDate"
              label="From Date"
              value={searchData.fromDate}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell-none">
            <DateTimePicker
              type="date"
              name="toDate"
              label="To Date"
              value={searchData.toDate}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteCorrespondent
              isAccessibleOnly={true}
              name="correspondent"
              value={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteBranch
              isAccessibleOnly={true}
              name="branch"
              value={searchData.branch}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteAccountNo
              isAccessibleOnly={true}
              name="accountNo"
              value={searchData.accountNo}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteMasterAccountNo
              isAccessibleOnly={true}
              name="masterAccountNo"
              value={searchData.masterAccountNo}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteRep
              isAccessibleOnly={true}
              name="rep"
              value={searchData.rep}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteAccountName
              isAccessibleOnly={true}
              name="accountName"
              value={searchData.accountName}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
        </div>
      </div>
      <div className="mt-20">
        {/* <ClientGrid
          title="Balance"
          rowIdField="accountId"
          columns={balanceColumns}
          rows={rows}
          getCsvData={getCsvData}
          isLoading={loading}
          setRows={setRows}
        /> */}
        {/* <ClientGrid2
            title="Balance"
            data={rows}
            columns={columns}
            options={options}
            style={{ width:100 }}
        /> */}
        <Table
          title={'Balance'}
          data={rows}
          columns={columns}
          options={options}
          footerData={footerData}
          onSearch={handleSearch}
          pagination={pagination}
          setPagination={setPagination}
          getCsvData={getCsvData}
          //additionalCell={isNotMovable ? false : true}
        />
      </div>
    </div>
  )
}
