import React, { useEffect } from 'react'
import { IconButton } from '@material-ui/core'
import { Create as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import ClientGrid from '../../../components/ClientGrid/ClientGrid'
import ClientGrid2 from '../../../components/ClientGrid/ClientGrid2'
import Table, { columnType } from '../../../components/ClearingTable/Table';
import Button from '../../../components/Buttons/Button'
import AutoCompleteAccountNo from '../../../components/AutoComplete/AutoCompleteAccountNo'
import AutoCompleteCorrespondent from '../../../components/AutoComplete/AutoCompleteCorrespondent'
import SelectSystemCode from '../../../components/Dropdown/SelectSystemCode'
import Text from '../../../components/Typography/Text'
import TextField from '../../../components/Textfields/TextField'
// import DigitalCashRequestModal from './Components/DigitalCashRequestModal'
import WalletAddressBookModal from '../../../components/Modals/WalletAddressBookModal'
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from '../../../components/Messages/Notification'
import { 
  walletAddressBookColumns,
} from '../../../lib/data/columns'
import {
  listWalletAddressBook,
  createWalletAddressBook,
  updateWalletAddressBook,
  deleteWalletAddressBook,
} from '../../../services/WalletAddressBookService'
import {
  createRecurrence,
  updateRecurrence,
  deleteRecurrence,
} from '../../../services/RecurrenceService';
import QueryParam from '../../../services/QueryParamService'
import { getProfileName } from '../../../services/ProfileService'
import { getCurrentUser, getAccess } from '../../../services/AuthService'
import {readClient, getAccessibleClient} from '../../../services/ClientAccountService'
import { listWalletSummary } from '../../../services/WalletService'
import {
  CustomBodyRenderCurrency,
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderDate,
  CustomBodyRenderDateTime,
  CustomBodyRenderBoolean,
  CustomBodyRenderAlign,
  CustomBodyRenderHtmlString,
  CustomBodyRenderCenter,
  CustomBodyRenderMissing,
} from '../../../components/Table/CustomBodyRender';

export default function WalletAddressBook() {
  const [rows, setRows] = React.useState([])
  const [rowData, setRowData] = React.useState({})
  const [isEdit, setIsEdit] = React.useState(false);
  const [profileName, setProfileName] = React.useState("")
  const [userType, setUserType] = React.useState({})
  const [user, setUser] = React.useState([])
  const [addLoading, setAddLoading] = React.useState(true)

  const [defaultWallet, setDefaultWallet] = React.useState([])
  const [defaultAccount, setDefaultAccount] = React.useState([])
  const [open, setOpen] = React.useState({
    add: false
  })

  const [loading, setLoading] = React.useState({
    search: false,
    save: false,
  })

  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      nickName: '',
      correspondent: '',
      accountNo: '',
      status: 'Active',
    }),
  )


  const handleSearchDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target

    setSearchData({ ...searchData, [input.name]: input.value })
  }

  const handleSearch = async () => {
    try {
      setLoading({ ...loading, search: true })

      QueryParam.set(searchData)

      const data = await listWalletAddressBook(searchData)
      console.log(data.walletAddressBooksList)
      setRows(data.walletAddressBooksList)

      notifyInfo(
        data.walletAddressBooksList.length > 0
          ? data.walletAddressBooksList.length + ' search result(s)'
          : 'No records found.',
      )
    } catch (error) {
      notifyError(error.message)
      console.log(error)
      return
    } finally {
      setLoading({ ...loading, search: false })
    }
  }

  const isDeleteEnabled = (data) => {
    if (data.status == 'Active') {
      return true;
    } else {
      return false;
    }
  };

  const handleDelete = async (data) => {
    try {
      const resp = await deleteWalletAddressBook(
        data.walletAddressBookId
      );
    } catch (error) {
      console.error(error);
      notifyError(error.message);
    } finally {
      notifySuccess('Wallet address book has been deactivated');
      handleSearch(false);
    }
  };

  const handleOpen = async (data) => {
    const correspondent=defaultAccount?.client?.correspondent?defaultAccount.client?.correspondent:'';
    const account_no=defaultAccount?.client?.accountNo?defaultAccount.client?.accountNo:'';
    const wallet_id=defaultWallet.length>0?defaultWallet[0].walletId:'';
    if (!data) {
      data = {
        accountId: '',
        correspondent: correspondent,
        accountNo: account_no,
        contraWalletId: '',
        status: 'Active',
        walletId: wallet_id,
        walletAddressBookId: '',
        recurrenceAmount: '',
        nickName: '',
      };

      const reservedData = await createWalletAddressBook(data);
      data.walletAddressBookId = reservedData.walletAddressBook.walletAddressBookId;
      setIsEdit(false);
    }else{
      setIsEdit(true);
    }
    setRowData(data);
    setOpen({add: true});
  };

  const validate = async (data, recData) => {
    let valid = true;

    if (
      (data.recurrenceAmount &&
      data.recurrenceAmount !== '' &&
      data.recurrenceAmount != 0) &&
      (recData.recurring == 'None' || recData.recurring == '')
    ) {
      notifyError('Recurring Pattern is required to enter recurrence amount');
      valid = false;
    }

    if (((!data.recurrenceAmount) || data.recurrenceAmount === '' || data.recurrenceAmount == 0) &&
      (recData.recurring !== 'None' && recData.recurring !== '')
    ) {
      notifyError('Recurrence Amount is required for recurrence');
      valid = false;
    }

    if (data.correspondent == '' || !data.correspondent) {
      notifyError('Correspondent is required');
      valid = false;
    }
    if (data.accountNo == '' || !data.accountNo) {
      notifyError('Account No is required');
      valid = false;
    }
    if (data.walletId == '' || !data.walletId) {
      notifyError('Wallet Id is required');
      valid = false;
    }
    if (data.contraWalletId == '' || !data.contraWalletId) {
      notifyError('Contra wallet Id is required');
      valid = false;
    }
    if (data.status == '' || !data.status) {
      notifyError('Status is required');
      valid = false;
    }

    return valid;
  };

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    let account_no = '';
    let correspondent = '';
    try {
      const profileName = await getProfileName()
      const loggeduser = getCurrentUser()

      if (loggeduser.AccountId != 0) {
        const account = await readClient(loggeduser.AccountId);
        setDefaultAccount(account);
        account_no = account.client.accountNo;
        correspondent = account.client.correspondent;
      } else {
        const account = await getAccessibleClient(loggeduser.UserId);
        setDefaultAccount(account);
        account_no = account.client.accountNo;
        correspondent = account.client.correspondent;
      }

      if(!getAccess("account_no", loggeduser.UserType)){
        setSearchData({
          ...searchData,
          accountNo: account_no,
          correspondent: correspondent,
        });
      }
      

      setUser(loggeduser)
      setProfileName(profileName.toLowerCase())
      setUserType(user.UserType);
    } catch (error) {
      notifyError(error.message)
    }
    setAddLoading(false);
  }
  
  const GridButtons = ({
    tableManager,
    value,
    data,
    column,
    colIndex,
    rowIndex,                
  }) => {                                 // Added classNames to arrange the buttons horizontally 
    return (
      <div className='grd-row nm'>                                                   
        <div className='grd-cell nm'>
            <IconButton aria-label="edit" onClick={() => handleOpen(data)}>
              <EditIcon style={{ height: 24, width: 24 }} />
            </IconButton>
        </div>
        <div className='grd-cell nm'>
          <IconButton disabled={!isDeleteEnabled(data)} aria-label="delete" onClick={() => handleDelete(data)}>
            <DeleteIcon style={{ height: 24, width: 24 }} />
          </IconButton>
        </div>
      </div>
    )
  }

  const columns2 = [
    {
      id: '',
      label: '',
      width: '60px',                       // Added more width to put enough space to the grid buttons
      cellRenderer: GridButtons,
    },
    ...walletAddressBookColumns,
  ]

  const columns = [
    {
      name: 'actions',
      label: 'Actions',
      sort: false,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return(
            <div className='grd-row nm'>                                                   
              <div className='grd-cell nm'>
                  <IconButton aria-label="edit" onClick={() => handleOpen(rows[dataIndex])}>
                    <EditIcon style={{ height: 24, width: 24 }} />
                  </IconButton>
              </div>
              <div className='grd-cell nm'>
                <IconButton disabled={!isDeleteEnabled(rows[dataIndex])} aria-label="delete" onClick={() => handleDelete(rows[dataIndex])}>
                  <DeleteIcon style={{ height: 24, width: 24 }} />
                </IconButton>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'accountId',
      label: 'Account Id',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      }
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'accountNo',
      label: 'Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'walletId',
      label: 'Wallet Id',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      }
    },
    {
      name: 'contraWalletId',
      label: 'Contra Wallet Id',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      }
    },
    {
      name: 'nickName',
      label: 'Nickname',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'recurrenceAmount',
      label: 'Recurrence Amount',
      options: {
        customBodyRender: CustomBodyRenderCurrency,
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'recurring',
      label: 'Recurrence',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
  ];

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
  };


  const handleClose = async (data, recData, isEdit) => {
    if (!data) {
      setOpen(false);
      return;
    }
    console.log(data);
    const valid = await validate(data, recData);
    if (!valid) {
      return;
    }

    try {
      const resp = await updateWalletAddressBook(data);
      if (recData) {
        recData.linkId = data.walletAddressBookId;
        recData.functionName = 'Bank';
        recData.report = 'Wallet Address Book';
        if (recData.recurrenceId != 0) {
          if (recData.recurring == 'None') {
            await deleteRecurrence(recData);
          } else {
            await updateRecurrence(recData);
          }
        } else {
          await createRecurrence(recData);
        }
      }

      handleSearch(false);

      if (isEdit) {
        notifySuccess(' Wallet Address Book has been updated.');
      } else {
        notifySuccess(' Wallet Address Book has been added.');
      }

      setOpen(false);
    } catch (error) {
      console.error(error);
      notifyError(error.message);
    }
  };


  const getCsvData = async () => {
    const data = await listWalletAddressBook(searchData)

    return data.walletAddressBooksList
  }

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Wallet Address Book" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading.search}
              loading={loading.search}
              type="search"
              label={loading.search ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
          <div className="grd-cell-none">
            <Button
              type="plus"
              label="Add Wallet Address Book"
              loading={addLoading}
              disabled={addLoading}
              onClick={() => handleOpen()}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <TextField
              name="nickName"
              label="Nickname"
              placeholder="Nickname"
              type="text"
              value={searchData.nickName}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteCorrespondent
              isAccessibleOnly={true}
              name="correspondent"
              label="Correspondent"
              value={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteAccountNo
              isAccessibleOnly={true}
              name="accountNo"
              value={searchData.accountNo}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <SelectSystemCode
              name="status"
              label="Status"
              placeholder="Status"
              type="Status"
              subType="AI"
              value={searchData.status}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell"></div>
          <div className="grd-cell"></div>
        </div>
      </div>

      <div className="mt-20">
        {/* <ClientGrid
          title="Bank Accounts"
          rowIdField="bankId"
          columns={columns2}
          rows={rows}
          getCsvData={getCsvData}
          setRows={setRows}
        /> */}
        {/* <ClientGrid2
          title="Bank Accounts"
          data={rows}
          columns={columns}
          options={options}
        /> */}
        <Table
          title={'Bank Accounts'}
          data={rows}
          columns={columns}
          options={options}
        />
      </div>

      {open.add && <WalletAddressBookModal
        data={rowData}
        open={open.add}
        profileName={profileName}
        close={handleClose}
        loading={loading.save}
        isEdit={isEdit}>
      </WalletAddressBookModal>}
    </div>
  )
}
