import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Paper } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { lazyLoadSystemCode } from '../../services/CommonService';
import color from '../../assets/css/colors';
import fontFamily from '../../assets/css/fonts';

const useStyles = makeStyles(() => ({
    autocompleteWrapper: {
        '& .MuiAutocomplete-inputRoot': {
            padding: '7px 15px 8px 15px !important',
            height: 48,
        },
        '& .MuiAutocomplete-option': {
            backgroundColor: 'red',
        },
        '& .MuiAutocomplete-endAdornment': {
            top: 'calc(50% - 15px)',
            '& svg': {
                width: '22px !important',
                height: '22px !important',
            }
        }
    },
    paper: {
        fontFamily: fontFamily.label,
        fontSize: 15,
        fontWeight: 600,
        backgroundColor: color.light.main,
    }
}));

export default function AutoCompleteSystemCode(props) {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [inputValue, setInputValue] = React.useState(props.value);

    const setPropsValue = (value) => {
        props.onChange({
          currentTarget: {
            name: props.name,
            value: value,
          },
          target: {
            name: props.name,
            value: value,
          },
        });
    };
    
    const handleOnBlur = async (value) => {
        if (props.freeSolo) {
          setPropsValue(value);
          return;
        }
    
        if (options.find((o) => o.code === value)) {
          setPropsValue(value);
          return;
        }
    
        // if selected value does not exist in options and is not a free solo
        setInputValue('');
        setPropsValue('');
    };

    //set local input value and get options
    const handleInputChange = async (event, value) => {
        if (!event) return;
        if (event.type === 'blur') return;
        if (event.type === 'click' && value) return;

        setInputValue(['Entry Type'].includes(props.type) ? value?.toUpperCase() : value);
        getOptions(value);
    };

    const getOptions = async (value) => {
        try {
            const { systemCodeList } = await lazyLoadSystemCode(props.type, props.subType, value);
            const nonTrade = { code: 'NON TRD', description: 'None Trade Entry' };
            if (props.type === 'Entry Type') {
                systemCodeList.push(nonTrade);
            }
            setOptions(systemCodeList.filter((s) => s.code));
        }
        catch (error) {
            console.log(error)
        }
    };

    React.useEffect(() => {
        if (open) {
            getOptions(props.value);
        }
    },
        // eslint-disable-next-line
        [open]
    );

    return (
        <Autocomplete
            className={classes.autocompleteWrapper}
            disabled={props.disabled}
            freeSolo={props.freeSolo}
            id={props.name}
            onInputChange={handleInputChange}
            onBlur={() => {handleOnBlur(inputValue);}}
            blurOnSelect={false}
            onChange={(_, v) => {
                const value = v ? v.code : '';
                setPropsValue(value);
                setInputValue(value);
              }}
            getOptionSelected={(option, value) => option.description === value.description || options.find((o) => o.code !== value)}
            getOptionLabel={(option) => {
                return  option?.code + ' - ' + option?.description;
            }}
            inputValue={inputValue ? inputValue : props.value}
            options={options}
            autoHighlight={true}
            clearOnEscape={true}
            open={open}
            onOpen={() => {setOpen(true);}}
            onClose={() => {setOpen(false);}}
            PaperComponent={({ children }) => (<Paper className={classes.paper}>{children}</Paper>)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    error={props.error}
                    helperText={props.error ? props.msg : ''}
                    required={props.required}
                    disabled={props.disabled}
                    label={props.label}
                    placeholder={props.label}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ ...params.inputProps, maxLength: 10 }}
                />
            )}
        />
    );
}
