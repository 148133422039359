import React from 'react';
import { Menu as MUIMenu } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = (theme) => ({
    root: {
        padding: '5px !important'
    },
});

const APWPopMenu = ({ classes, children, id, ...otherProps }) => {
    return (
        <MUIMenu
            {...otherProps}
            getcontentanchorel={null}
            className={classes.root}
            id={id}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            classes={{paper: classes.root}}
        >
            {children}
        </MUIMenu>
    );
};

APWPopMenu.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    id: PropTypes.string.isRequired,
};

APWPopMenu.defaultProps = {
}

export default withStyles(styles, { withTheme: true, name: MUIMenu.muiName })(APWPopMenu);
