import { notifyError } from '../components/Messages/Notification';
import {
  FeeManagerReportServiceClient,
  ListFeeManagerReportRequest,
  ListFeeManagerReportDetailsRequest,
} from '../proto/feepb/feemanagerreport_grpc_web_pb';
import { auth } from '../lib/auth/Auth';

import { stringToProtoDate } from './ConvertService';

const service = new FeeManagerReportServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

export async function listFeeManagerReport(param) {
  return new Promise((resolve, reject) => {
    const req = new ListFeeManagerReportRequest();

    req.setDateType(param.dateType);
    req.setFromDate(stringToProtoDate(param.fromDate));
    req.setToDate(stringToProtoDate(param.toDate));
    req.setFeeType(param.feeType);
    req.setBranch(param.branch);
    req.setAccountNo(param.accountNo);
    req.setMasterAccountNo(param.masterAccountNo);
    req.setRep(param.rep);
    req.setBroker(param.broker);
    req.setCorrespondent(param.correspondent);

    service.listFeeManagerReport(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listFeeManagerReportDetails(param) {
  return new Promise((resolve, reject) => {
    const req = new ListFeeManagerReportDetailsRequest();

    req.setDateType(param.dateType);
    req.setFeeType(param.feeType);
    req.setFromDate(stringToProtoDate(param.fromDate));
    req.setToDate(stringToProtoDate(param.toDate));
    req.setAccountId(param.accountId);

    service.listFeeManagerReportDetails(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
