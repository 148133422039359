import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    Typography,
    Modal,
    Backdrop,
    Fade,
    Box,
    FormControlLabel,
    Checkbox,
    InputLabel,
    MenuItem,
    Select,
    FormControl,
    RadioGroup,
    Radio,
    FormLabel,
    FormGroup,
    Grid,
  } from '@material-ui/core';
import Text from '../Typography/Text'
import Button from '../Buttons/Button'
import CloseButton from '../Buttons/CloseButton'
import fontFamily from '../../assets/css/fonts'
import color from '../../assets/css/colors'

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
}))

export default function RecurringModal({
    onClose: close,
    open,
    value,
  }) {
    const classes = useStyles();
    const [modalData, setModalData] = useState({
      recurrenceId: 0,
      functionName: '',
      report: '',
      linkId: 0,
      recurring: '',
      day: 0,
      month: '',
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      // sunday: false,
      settleDate: false,
      tradeDate: false,
      calendarDate: false,
    });
    const [loading, setLoading] = React.useState(false);
    const dailySelection = ['settleDate', 'tradeDate', 'calendarDate'];
    const recurringSelection = [
      // 'sunday',
      'None',
      'Daily',
      'Weekly',
      'Monthly',
      'Quarterly',
      'Yearly',
    ];
  
    useEffect(() => {
      console.log(open)
      if (open) {
        let modalValue = { ...value };
        setModalData(modalValue);
      }
      // eslint-disable-next-line
    }, [open]);
  
    const handleChange = (e, checkboxValue) => {
      const input = e.currentTarget.name ? e.currentTarget : e.target;
      console.log(input.name);
      if (input.name == 'recurring' && recurringSelection.includes(input.value)) {
        modalData.settleDate = false;
        modalData.tradeDate = false;
        modalData.calendarDate = false;
        modalData.monday = false;
        modalData.tuesday = false;
        modalData.wednesday = false;
        modalData.thursday = false;
        modalData.friday = false;
        modalData.saturday = false;
        modalData.day = 0;
        modalData.month = 0;
      }
  
      if (modalData.recurring === 'Daily') {
        if (dailySelection.includes(input.name)) {
          modalData.settleDate = false;
          modalData.tradeDate = false;
          modalData.calendarDate = false;
        }
      }
  
      setModalData({
        ...modalData,
        [input.name]:
          checkboxValue === true || checkboxValue === false
            ? checkboxValue
            : input.value,
      });
    };
  
    const daysDropdown = () => {
      var rows = [];
      for (var i = 1; i <= 31; i++) {
        rows.push(<MenuItem value={i}>{i}</MenuItem>);
      }
      return rows;
    };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => close()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div style={{ width: 800 }}>
          <div className={classes.modalHeader}>
            <div>
              <Text
                mt={4}
                variant="h2"
                label="Recurrence"
              />
            </div>
            <div className={classes.closeButton}>
              <CloseButton onClick={() => close()} />
            </div>
          </div>
          <div className={classes.modalBody}>
            <form className={classes.modalForm} noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Reccurence Pattern</FormLabel>
                    <RadioGroup
                      aria-label="recurring"
                      name="recurring"
                      value={modalData.recurring}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="None"
                        control={<Radio />}
                        label="None"
                      />
                      <FormControlLabel
                        value="Daily"
                        control={<Radio />}
                        label="Daily"
                      />
                      <FormControlLabel
                        value="Weekly"
                        control={<Radio />}
                        label="Weekly"
                      />
                      <FormControlLabel
                        value="Monthly"
                        control={<Radio />}
                        label="Monthly"
                      />
                      <FormControlLabel
                        value="Quarterly"
                        control={<Radio />}
                        label="Quarterly"
                      />
                      <FormControlLabel
                        value="Yearly"
                        control={<Radio />}
                        label="Yearly"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={9}>
                  {modalData.recurring === 'Daily' && (
                    <FormGroup
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={modalData.settleDate}
                            onChange={handleChange}
                            name="settleDate"
                            color="primary"
                          />
                        }
                        label="Settle Date"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={modalData.tradeDate}
                            onChange={handleChange}
                            name="tradeDate"
                            color="primary"
                          />
                        }
                        label="Trade Date"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={modalData.calendarDate}
                            onChange={handleChange}
                            name="calendarDate"
                            color="primary"
                          />
                        }
                        label="Calendar Date"
                      />
                    </FormGroup>
                  )}
                  {modalData.recurring === 'Weekly' && (
                    <FormGroup row>
                      {/* <FormControlLabel
                        control={
                          <Checkbox
                            checked={modalData.sunday}
                            onChange={handleChange}
                            name="sunday"
                            color="primary"
                          />
                        }
                        label="Sunday"
                      /> */}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={modalData.monday}
                            onChange={handleChange}
                            name="monday"
                            color="primary"
                          />
                        }
                        label="Monday"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={modalData.tuesday}
                            onChange={handleChange}
                            name="tuesday"
                            color="primary"
                          />
                        }
                        label="Tuesday"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={modalData.wednesday}
                            onChange={handleChange}
                            name="wednesday"
                            color="primary"
                          />
                        }
                        label="Wednesday"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={modalData.thursday}
                            onChange={handleChange}
                            name="thursday"
                            color="primary"
                          />
                        }
                        label="Thursday"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={modalData.friday}
                            onChange={handleChange}
                            name="friday"
                            color="primary"
                          />
                        }
                        label="Friday"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={modalData.saturday}
                            onChange={handleChange}
                            name="saturday"
                            color="primary"
                          />
                        }
                        label="Saturday"
                      />
                    </FormGroup>
                  )}
                  {(modalData.recurring === 'Monthly' ||
                    modalData.recurring === 'Quarterly') && (
                    <FormControl style={{ minWidth: 120 }}>
                      <InputLabel shrink>Day</InputLabel>
                      <Select
                        name="day"
                        fullWidth
                        value={modalData.day}
                        onChange={handleChange}
                      >
                        <MenuItem value={0}>
                          <em>Blank</em>
                        </MenuItem>
                        {daysDropdown()}
                      </Select>
                    </FormControl>
                  )}
                  {modalData.recurring === 'Yearly' && (
                    <div>
                      <FormControl style={{ minWidth: 120, marginRight: 30 }}>
                        <InputLabel shrink>Month</InputLabel>
                        <Select
                          fullWidth
                          name="month"
                          value={modalData.month}
                          onChange={handleChange}
                        >
                          <MenuItem value="">
                            <em>Blank</em>
                          </MenuItem>
                          <MenuItem value="1">January</MenuItem>
                          <MenuItem value="2">February</MenuItem>
                          <MenuItem value="3">March</MenuItem>
                          <MenuItem value="4">April</MenuItem>
                          <MenuItem value="5">May</MenuItem>
                          <MenuItem value="6">June</MenuItem>
                          <MenuItem value="7">July</MenuItem>
                          <MenuItem value="8">August</MenuItem>
                          <MenuItem value="9">September</MenuItem>
                          <MenuItem value="10">October</MenuItem>
                          <MenuItem value="11">November</MenuItem>
                          <MenuItem value="12">December</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl style={{ minWidth: 120 }}>
                        <InputLabel shrink>Day</InputLabel>
                        <Select
                          displayEmpty
                          name="day"
                          fullWidth
                          value={modalData.day}
                          onChange={handleChange}
                        >
                          <MenuItem value={0}>
                            <em>Blank</em>
                          </MenuItem>
                          {daysDropdown()}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                </Grid>
              </Grid>
            </form>
          </div>
          <div className={classes.modalFooter}>
            <Button
              disabled={loading}
              loading={loading}
              label={loading ? 'Saving...' : 'Save'}
              onClick={() => close(modalData)}
            />
          </div>
        </div>
      </Fade>
    </Modal>
  )
}
