import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';
import moment from 'moment-timezone';
import CsvDownload from './CsvDownload';

export default function Download(props) {
  const [open, setOpen] = React.useState(false);
  const fileName = props.title;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCsvFileName = (title) => {
    return (
      title.replace(/ /g, '') + '_' + moment().format('MMMM Do YYYY') + '.csv'
    );
  };

  const getFormattedCsvData = async () => {
    const raw = await props.getCsvData();

    return raw;
  };

  return (
    <>
      {open && (
        <CsvDownload
          getData={getFormattedCsvData}
          title={fileName}
          filename={handleCsvFileName(fileName)}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        />
      )}
      <Tooltip arrow title="Download CSV">
        <IconButton onClick={handleOpen}>
          <CloudDownloadRoundedIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}
