import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import Button from '../Buttons/Button';
import color from '../../assets/css/colors';
import fontFamily from '../../assets/css/fonts';
import { dateFormat } from '../../lib/format/format';
import html2pdf from 'html2pdf.js';
import { generateAccountNo } from '../../services/CommonService';
import { readDisclosure } from '../../services/MarginDisclosureService';
import { getCorrespondentByUrl } from '../../services/TemplateService';
import { downloadBlankReport } from '../../services/MarginDisclosureService';
import Text from '../Typography/Text';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 17,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 35px',
    maxHeight: '80vh',
    overflowY: 'auto',
    '& p': {
      fontSize: 16,
      fontFamily: fontFamily.headers,
      fontWeight: 400,
      lineHeight: '26px',
      color: color.default.main,
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: 17,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'max-content',
    },
  },
  wrapper: {
    margin: '0 auto',
    fontFamily: fontFamily.headers,
    fontSize: 15,
    '& h1': {
      color: color.default.main,
      marginTop: '0px !important',
    },
    '& h3': {
      color: color.default.main,
    },
    '& p': {
      color: color.default.main,
      lineHeight: '27px',
    },
    '& .MuiFormControl-root label': {
      fontFamily: fontFamily.headers,
      fontWeight: 400,
    },
    '& .MuiInputBase-root': {
      border: 'none',
      backgroundColor: 'unset',
      padding: 0,
      marginTop: '24px !important',
      fontFamily: fontFamily.headers,
      fontSize: 16,
      fontWeight: 700,
      '& input': {
        padding: 0,
      },
    },
  },
  maTable: {
    width: '100%',
    border: '1px solid #333333',
    borderCollapse: 'collapse',
    marginBottom: 30,
    color: color.default.main,
    '& tr td': {
      border: '1px solid #333333',
      padding: 10,
      '& label': {
        display: 'block',
      },
      '& label:first-child': {
        marginBottom: 7,
      },
      '& label:last-child': {
        fontWeight: 700,
        fontSize: 16,
      },
    },
  },
  buttons: {
    display: 'flex',
    marginTop: 20,
    width: 120,
    float: 'right',
  },
}));

export default function MarginAgreementModal(props) {
  const classes = useStyles();
  const [disclosure, setDisclosure] = React.useState('');

  const {
    open,
    onClose,
    handleChange,
    data,
    validateData,
    tradingData,
    accountData,
    setValidateData,
    setAttachments,
    setTradingData,
    applicant,
  } = props;

  const [mounted, setMounted] = React.useState(false);
  const [accountNo, setAccountNo] = React.useState('');
  const [loading, setLoading] = React.useState({
    disclosure: false,
    accept: false,
    download: false,
  });
  const modalData = {
    correspondent: 'SASS',
  };

  const init = async () => {
    data.rep = applicant.repCode ? applicant.repCode : '';

    if (accountNo === '' && ['', undefined].includes(applicant.accountNo)) {
      let param = {
        firstName: applicant.firstName,
        lastName: applicant.lastName,
        phoneNumber: applicant.phoneNumber,
      };

      const { account } = await generateAccountNo(param);
      setAccountNo(account.accountNo);
    } else {
      setAccountNo(applicant.accountNo);
    }
  };

  React.useEffect(() => {
    if (!mounted) {
      init();
    }
    return () => {
      setMounted(true);
    };
    // eslint-disable-next-line
  }, [mounted]);

  React.useEffect(() => {
    async function loadDisclosure() {
      let url = window.location.origin;
      //get correspondent
      const serverCorrespondent = await getCorrespondentByUrl(url);
      let correspondent = serverCorrespondent.correspondent.correspondent;
      console.log(serverCorrespondent);
      const data = await readDisclosure('Margin Agreement', correspondent);
      console.log("data: " + JSON.stringify(data))
      const disclosureHTML = data.disclosure.disclosure;

      const replacedDisclosureBrokerName = disclosureHTML.replaceAll(
        '[broker_name]',
        serverCorrespondent.correspondent.brokerName
      );
      const replacedDisclosureCorrespondent = replacedDisclosureBrokerName.replaceAll(
        '[correspondent]',
        correspondent
      );
      const replacedDisclosureAccountTitle = replacedDisclosureCorrespondent.replaceAll(
        '[account_title]',
        applicant.firstName + ' ' + applicant.lastName
      );

      let accountNoData;
      let clientName = applicant.firstName + ' ' + applicant.lastName;

      data.rep = applicant.repCode ? applicant.repCode : '';

      if (accountNo === '' && ['', undefined].includes(applicant.accountNo)) {
        let param = {
          firstName: applicant.firstName,
          lastName: applicant.lastName,
          phoneNumber: applicant.phoneNumber,
        };

        const { account } = await generateAccountNo(param);
        accountNoData = account.accountNo;
        setAccountNo(account.accountNo);
      } else {
        setAccountNo(applicant.accountNo);
      }

      const replacedDisclosureAccountNo = replacedDisclosureAccountTitle.replaceAll(
        '[account_no]',
        accountNoData ? accountNoData : applicant.accountNo
      );
      const replacedDisclosureClientName = replacedDisclosureAccountNo.replaceAll(
        '[client_name]',
        clientName ? clientName : '--'
      );
      const replacedDisclosureBrokerRep = replacedDisclosureClientName.replaceAll(
        '[broker_rep_code]',
        data.rep ? data.rep : '--'
      );
      const replacedDisclosureApplicantName = replacedDisclosureBrokerRep.replaceAll(
        '[applicant_name]',
        applicant.firstName + ' ' + applicant.lastName
      );
      const replacedDisclosureAccountHolder = replacedDisclosureApplicantName.replaceAll(
        '[account_holder]',
        clientName ? clientName : '--'
      );
      const replacedDisclosureDate = replacedDisclosureAccountHolder.replaceAll(
        '[date]',
        dateFormat(new Date())
      );
      const replacedLogo = replacedDisclosureDate.replaceAll(
        '[logo]', '<img style="width:200px;height:100px;" src="' +
        serverCorrespondent.correspondent.photo + '">'
      );
      const replacedAddress = replacedLogo.replaceAll(
        '[address]',
        serverCorrespondent.correspondent.address
      );
      const replacedCity = replacedAddress.replaceAll(
        '[city]',
        serverCorrespondent.correspondent.city + ', '
      );
      const replacedState = replacedCity.replaceAll(
        '[state]',
        serverCorrespondent.correspondent.state + ', '
      );
      const replacedZip = replacedState.replaceAll(
        '[zip]',
        serverCorrespondent.correspondent.zip
      );

      setDisclosure(replacedZip);
    }

    loadDisclosure();
  }, []);

  const acknowledgeMarginAgreement = () => {

    const required = 'Required';

    setValidateData({
      accountName: {
        err: !data.accountName,
        msg: required,
      },
      clientName: {
        err: !data.clientName,
        msg: required,
      },
    });

    const isValid = () => {
      if (!data.accountName || !data.clientName) {
        let modal = document.getElementById('marginAgreement');
        modal.scrollTop = 0;

        return false;
      }
      return true;
    };

    if (!isValid()) {
      return false;
    }

    try {
      setLoading({ ...loading, accept: true });
      const htmlElement = document.getElementById('marginAgreementForm');
      const options = {
        margin: [45, 45],
        filename: 'Margin Agreement Form.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, letterRendering: true },
        jsPDF: { unit: 'pt', format: 'letter', orientation: 'portrait' },
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
      };

      html2pdf()
        .set(options)
        .from(htmlElement)
        .outputPdf('blob')
        .then((result) => {
          const file = new File([result], 'Margin Agreement Form.pdf', {
            type: result.type,
          });

          let newFiles = [file];

          setAttachments(newFiles);
        });

      setTradingData({ ...tradingData, readMarginAgreement: true });

      onClose();
    } catch (error) {
      console.log(error?.message);
    } finally {
      setLoading({ ...loading, accept: false });
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div style={{ width: 800 }}>
          <div className={classes.modalHeader}>
            <div>
              <Text mt={3} variant="h2" label={'Margin Agreement'} />
            </div>
          </div>
          <div id="marginAgreement" className={classes.modalBody}>
            <div id="marginAgreementForm">
              <div className={classes.wrapper}>
                {disclosure !== '' ? (
                  <div dangerouslySetInnerHTML={{ __html: disclosure }}></div>
                ) : (
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      padding: '30px 0px',
                    }}
                  >
                    <p>
                      {loading.disclosure
                        ? 'Fetching margin agreement. Please wait...'
                        : 'No Margin Agreement Found.'}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={classes.modalFooter}>
            <div style={{ marginRight: 10 }}>
              <Button
                style={{ marginRight: 10 }}
                loading={loading.download}
                color="secondary"
                label={loading.download ? 'Downloading...' : 'Print PDF'}
                onClick={async () => {
                  setLoading({ download: true });
                  await downloadBlankReport({ ...modalData }, disclosure);
                  setLoading({ download: false });
                }}
              />
            </div>
            <div className={classes.buttons} style={{ marginRight: 10 }}>
              <div style={{ marginRight: 10 }}>
                <Button
                  label={'Close'}
                  onClick={onClose}
                />
              </div>
            </div>
            {tradingData.readMarginAgreement === false && disclosure.trim() !== '' ? (
              <div className={classes.buttons}>
                <Button
                  disabled={loading.accept || loading.disclosure || props.disabled}
                  loading={loading.accept}
                  label={loading.accept ? 'Accepting...' : 'I Accept'}
                  onClick={acknowledgeMarginAgreement} />
              </div>
            ) : (
              <div className={classes.buttons}>
                <div style={{ marginRight: 10 }}>
                  <Button
                    disabled
                    label={tradingData.readMarginAgreement === true ? 'Accepted' : disclosure.trim() === '' ? 'I Accept' : ''}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
