import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade, IconButton } from '@material-ui/core';
import {
  Visibility as ViewIcon,
  CloudDownload as DownloadIcon,
} from '@material-ui/icons';
import SelectSystemCode from '../../../components/Dropdown/SelectSystemCode';
import Text from '../../../components/Typography/Text';
import TextField from '../../../components/Textfields/TextField';
import Button from '../../../components/Buttons/Button';
import CloseButton from '../../../components/Buttons/CloseButton';
import fontFamily from '../../../assets/css/fonts';
import color from '../../../assets/css/colors';
import ClientGrid from '../../../components/ClientGrid/ClientGrid';
import { attachmentColumns } from '../../../lib/data/columns';
import AttachmentModal from './AttachmentModal';
import { attachFiles, downloadFile, ListFiles } from '../../../services/CommonService';
import { notifyError, notifySuccess } from '../../../components/Messages/Notification';

const useStyles = makeStyles(() => ({
  modal: {
    maxWidth: 900,
    maxHeight: '90vh',
    width: '100%',
    margin: '0px auto',
    marginTop: '30px',
    backgroundColor: '#ffffff',
    border: 'none',
    boxSizing: 'border-box',
    outline: 'none',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
}));

export default function ContactUsModal(props) {
  const classes = useStyles();
  const { data, open, close, loading, isEdit, user } = props;

  const [modalData, setModalData] = React.useState({});
  const [attachmentRows, setAttachmentRows] = React.useState([]);
  const [modalOpen, setModalOpen] = React.useState({
    addAttachment: false,
  });
  const [modalLoading, setModalLoading] = React.useState({
    noteLoading: false,
    attachmentLoading: false,
  });

  React.useEffect(() => {
    if (open) {
      setModalData(data);
      if (isEdit) {
        init(data);
      }
    }
  }, [open, data]);

  const init = async (data) => {
    const param = {
      linkId: data.contactUsId,
      linkType: 'ContactUs',
    };
    try {
      const attachmentsData = await ListFiles(param);
      setAttachmentRows(attachmentsData.attachedFilesList);
    } catch (err) {
      console.error(err);
      notifyError(err.message);
    }
  };

  const attachmentGridButtons = ({
    tableManager,
    value,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return (
      <div className="grd-row">
        <div className="grd-cell nm">
          <IconButton aria-label="view" onClick={() => handleAttachmentDownload(data)}>
            <DownloadIcon style={{ height: 24, width: 24 }} />
          </IconButton>
        </div>
      </div>
    );
  };

  const attachmentTableColumns = [
    {
      id: '',
      label: '',
      width: '40px',
      cellRenderer: attachmentGridButtons,
    },
    ...attachmentColumns,
  ];

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setModalData((prev) => ({ ...prev, [input.name]: input.value }));
  };

  const handleAttachmentClose = async (data) => {
    if (!data) {
      setModalOpen({
        ...modalOpen,
        addAttachment: false,
      });

      return;
    }

    try {
      const param = {
        linkId: modalData.contactUsId,
        linkType: 'ContactUs',
        files: data,
      };
      await attachFiles(param.linkId, param.linkType, param.files);

      notifySuccess('Attachment/s have been added.');
      init(modalData);
    } catch (error) {
      console.error(error);
      notifyError(error.message);
    }

    setModalOpen({
      ...modalOpen,
      addAttachment: false,
    });
  };

  const handleAttachmentDownload = async (data) => {
    if (!data) {
      return;
    }

    try {
      setModalLoading(true)
      await downloadFile(data.Id);

      notifySuccess('Downloading Attachment.');
    } catch (error) {
      console.error(error);
      notifyError(error.message);
    }
    
    setModalLoading(false)
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => close()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div style={{ width: 900 }}>
          <div className={classes.modalHeader}>
            <div>
              <Text
                mt={4}
                variant="h2"
                label={(isEdit ? 'Edit' : 'Add New') + ' Feedback'}
              />
            </div>
            <div className={classes.closeButton}>
              <CloseButton onClick={() => close()} />
            </div>
          </div>
          <div className={classes.modalBody}>
            <div className="grd-row nm">
              <div className="grd-cell">
                <TextField
                  disabled={isEdit}
                  name="subject"
                  label="Subject"
                  value={modalData.subject}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="grd-row">
              <div className="grd-cell">
                <TextField
                  disabled={isEdit}
                  name="pageName"
                  label="Page Name"
                  value={modalData.pageName}
                  onChange={handleChange}
                />
              </div>
              <div className="grd-cell">
                <SelectSystemCode
                  name="status"
                  label="Status"
                  placeholder="Blank"
                  type="Status"
                  subType="AI"
                  value={modalData.status}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="grd-row">
              <div className="grd-cell">
                <TextField
                  max={300}
                  showIcon={false}
                  name="message"
                  label="Message"
                  placeholder="Message..."
                  type="text"
                  multiline={true}
                  rows={5}
                  value={modalData.message}
                  onChange={handleChange}
                  disabled={isEdit}
                />
              </div>
            </div>
            <div className="grd-row">
              <div className="grd-cell">
                <TextField
                  max={300}
                  showIcon={false}
                  name="internalNote"
                  label="Internal Note"
                  placeholder="Internal Note..."
                  type="text"
                  multiline={true}
                  rows={5}
                  value={modalData.internalNote}
                  onChange={handleChange}
                />
              </div>
            </div>
            {isEdit && (
              <div>
                <div className="mt-20">
                  <ClientGrid
                    title="Attachment"
                    rowIdField="attachmentId"
                    columns={attachmentTableColumns}
                    rows={attachmentRows}
                    // getCsvData={getCsvData}
                    isLoading={loading.search}
                    setRows={setAttachmentRows}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={classes.modalFooter}>
            <Button
              disabled={loading}
              loading={loading}
              label={loading ? 'Saving...' : 'Save'}
              onClick={() => close(modalData)}
            />
          </div>
          {modalOpen.addAttachment && (
            <AttachmentModal
              data={modalData}
              open={modalOpen.addAttachment}
              close={handleAttachmentClose}
              loading={modalLoading.attachmentLoading}
            />
          )}
        </div>
      </Fade>
    </Modal>
  );
}
