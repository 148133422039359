import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal, Backdrop, Fade, Button } from '@material-ui/core'
import Text from '../../../../components/Typography/Text'
import TextField from '../../../../components/Textfields/TextField'
import fontFamily from '../../../../assets/css/fonts'
import color from '../../../../assets/css/colors'
import { formatCurrency } from '../../../../lib/fmt'

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: '30px 0px 0px 30px',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& > div:nth-child(1)': {
      flex: 'none',
      marginRight: 7,
      '& > svg': {
        width: 28,
        height: 28,
        '& path': {
          //   fill: color.info.main
        },
      },
    },
    '& div:nth-child(2)': {
      flex: 1,
    },
    '& div:nth-child(3)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          //   fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 30px',
    maxHeight: 600,
    overflowY: 'auto',
    fontFamily: fontFamily.headers,
    color: color.default.main,
    '& p': {
      fontSize: 16,
      fontFamily: fontFamily.headers,
      fontWeight: 400,
      lineHeight: '26px',
      color: color.default.main,
      marginTop: 0,
    },
    '& hr': {
      margin: '30px 0px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 120,
    },
  },
  incompleteSteps: {
    //   fontFamily: fontFamily.label,
    fontSize: 16,
    lineHeight: '24px',
    //   color: color.default.main,
    marginTop: 20,
    '& > label': {
      fontWeight: 600,
      marginBottom: 10,
      display: 'block',
    },
    '& ul': {
      listStyle: 'none',
      margin: '0px 0px 0px -40px',
      lineHeight: '30px',
      '& > li > span': {
        position: 'relative',
        top: 4,
        '& > svg': {
          width: 20,
          height: 20,
        },
      },
    },
  },
  success: {
    '& > svg > path': {
      // fill: color.success.main,
    },
  },
  error: {
    '& > svg > path': {
      // fill: color.error.main,
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-5px !important',
    },
  },
}))

export default function MaximumWithdrawableModal({
  onClose: handleClose,
  open: isOpen,
  value,
}) {
  const classes = useStyles()

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={() => {
        handleClose()
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div style={{ width: 500 }}>
          <div className={classes.modalHeader}>
            <div>
              <Text mt={4} variant="h2" label="Account Balance Details" />
            </div>
          </div>
          <div className={classes.modalBody}>
            <form autoComplete="off">
              <div className="grd-row">
                <div className="grd-cell">
                  <TextField
                    name="totalAmount"
                    label="Cash Balance"
                    placeholder="Cash Balance"
                    InputProps={{
                      readOnly: true,
                    }}
                    value={formatCurrency(value.totalAmount)}
                  />
                </div>
              </div>
              <div className="grd-row">
                <div className="grd-cell">
                  <TextField
                    name="charges"
                    label="Pending Charges"
                    placeholder="Pending Charges"
                    InputProps={{
                      readOnly: true,
                    }}
                    value={formatCurrency(value.charges)}
                  />
                </div>
              </div>
              <div className="grd-row">
                <div className="grd-cell">
                  <TextField
                    name="pendingCallLog"
                    label="With Pending Call"
                    placeholder="With Pending Call"
                    InputProps={{
                      readOnly: true,
                    }}
                    value={value.pendingCallLog > 0 ? 'Yes' : 'No'}
                  />
                </div>
              </div>
              <div className="grd-row">
                <div className="grd-cell">
                  <TextField
                    name="withdrawableAmount"
                    label="Maximum Withdrawable Amount"
                    placeholder="Maximum Withdrawable Amount"
                    InputProps={{
                      readOnly: true,
                    }}
                    value={formatCurrency(value.withdrawableAmount)}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className={classes.modalFooter}>
            <div className="grd-row">
              <div className="grd-cell" style={{ marginRight: 10 }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    handleClose()
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}
