// source: proto/plaidpb/plaidtoken.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_type_date_pb = require('../../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.plaidpb.AccessToken', null, global);
goog.exportSymbol('proto.plaidpb.Account', null, global);
goog.exportSymbol('proto.plaidpb.AuthData', null, global);
goog.exportSymbol('proto.plaidpb.BankAccount', null, global);
goog.exportSymbol('proto.plaidpb.CreateLinkRequest', null, global);
goog.exportSymbol('proto.plaidpb.CreateLinkResponse', null, global);
goog.exportSymbol('proto.plaidpb.Link', null, global);
goog.exportSymbol('proto.plaidpb.LinkToken', null, global);
goog.exportSymbol('proto.plaidpb.PlaidInfo', null, global);
goog.exportSymbol('proto.plaidpb.PublicToken', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.plaidpb.Link = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.plaidpb.Link, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.plaidpb.Link.displayName = 'proto.plaidpb.Link';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.plaidpb.PlaidInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.plaidpb.PlaidInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.plaidpb.PlaidInfo.displayName = 'proto.plaidpb.PlaidInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.plaidpb.PublicToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.plaidpb.PublicToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.plaidpb.PublicToken.displayName = 'proto.plaidpb.PublicToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.plaidpb.LinkToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.plaidpb.LinkToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.plaidpb.LinkToken.displayName = 'proto.plaidpb.LinkToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.plaidpb.AccessToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.plaidpb.AccessToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.plaidpb.AccessToken.displayName = 'proto.plaidpb.AccessToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.plaidpb.AuthData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.plaidpb.AuthData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.plaidpb.AuthData.displayName = 'proto.plaidpb.AuthData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.plaidpb.CreateLinkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.plaidpb.CreateLinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.plaidpb.CreateLinkRequest.displayName = 'proto.plaidpb.CreateLinkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.plaidpb.CreateLinkResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.plaidpb.CreateLinkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.plaidpb.CreateLinkResponse.displayName = 'proto.plaidpb.CreateLinkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.plaidpb.Account = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.plaidpb.Account, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.plaidpb.Account.displayName = 'proto.plaidpb.Account';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.plaidpb.BankAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.plaidpb.BankAccount.repeatedFields_, null);
};
goog.inherits(proto.plaidpb.BankAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.plaidpb.BankAccount.displayName = 'proto.plaidpb.BankAccount';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.plaidpb.Link.prototype.toObject = function(opt_includeInstance) {
  return proto.plaidpb.Link.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.plaidpb.Link} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.plaidpb.Link.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    country: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    type: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accessToken: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.plaidpb.Link}
 */
proto.plaidpb.Link.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.plaidpb.Link;
  return proto.plaidpb.Link.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.plaidpb.Link} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.plaidpb.Link}
 */
proto.plaidpb.Link.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.plaidpb.Link.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.plaidpb.Link.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.plaidpb.Link} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.plaidpb.Link.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.plaidpb.Link.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.Link} returns this
 */
proto.plaidpb.Link.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string country = 2;
 * @return {string}
 */
proto.plaidpb.Link.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.Link} returns this
 */
proto.plaidpb.Link.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 user_id = 3;
 * @return {number}
 */
proto.plaidpb.Link.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.plaidpb.Link} returns this
 */
proto.plaidpb.Link.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string type = 4;
 * @return {string}
 */
proto.plaidpb.Link.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.Link} returns this
 */
proto.plaidpb.Link.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string access_token = 5;
 * @return {string}
 */
proto.plaidpb.Link.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.Link} returns this
 */
proto.plaidpb.Link.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.plaidpb.PlaidInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.plaidpb.PlaidInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.plaidpb.PlaidInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.plaidpb.PlaidInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    publicToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.plaidpb.PlaidInfo}
 */
proto.plaidpb.PlaidInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.plaidpb.PlaidInfo;
  return proto.plaidpb.PlaidInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.plaidpb.PlaidInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.plaidpb.PlaidInfo}
 */
proto.plaidpb.PlaidInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicToken(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.plaidpb.PlaidInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.plaidpb.PlaidInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.plaidpb.PlaidInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.plaidpb.PlaidInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPublicToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string public_token = 1;
 * @return {string}
 */
proto.plaidpb.PlaidInfo.prototype.getPublicToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.PlaidInfo} returns this
 */
proto.plaidpb.PlaidInfo.prototype.setPublicToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.plaidpb.PlaidInfo.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.PlaidInfo} returns this
 */
proto.plaidpb.PlaidInfo.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.plaidpb.PlaidInfo.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.PlaidInfo} returns this
 */
proto.plaidpb.PlaidInfo.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.plaidpb.PublicToken.prototype.toObject = function(opt_includeInstance) {
  return proto.plaidpb.PublicToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.plaidpb.PublicToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.plaidpb.PublicToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    publicToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.plaidpb.PublicToken}
 */
proto.plaidpb.PublicToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.plaidpb.PublicToken;
  return proto.plaidpb.PublicToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.plaidpb.PublicToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.plaidpb.PublicToken}
 */
proto.plaidpb.PublicToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.plaidpb.PublicToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.plaidpb.PublicToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.plaidpb.PublicToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.plaidpb.PublicToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPublicToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string public_token = 1;
 * @return {string}
 */
proto.plaidpb.PublicToken.prototype.getPublicToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.PublicToken} returns this
 */
proto.plaidpb.PublicToken.prototype.setPublicToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.plaidpb.LinkToken.prototype.toObject = function(opt_includeInstance) {
  return proto.plaidpb.LinkToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.plaidpb.LinkToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.plaidpb.LinkToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    expiration: jspb.Message.getFieldWithDefault(msg, 1, ""),
    linkToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    requestId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    errorMsg: jspb.Message.getFieldWithDefault(msg, 4, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    errorType: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.plaidpb.LinkToken}
 */
proto.plaidpb.LinkToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.plaidpb.LinkToken;
  return proto.plaidpb.LinkToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.plaidpb.LinkToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.plaidpb.LinkToken}
 */
proto.plaidpb.LinkToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpiration(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMsg(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.plaidpb.LinkToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.plaidpb.LinkToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.plaidpb.LinkToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.plaidpb.LinkToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExpiration();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLinkToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getErrorMsg();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getErrorType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string expiration = 1;
 * @return {string}
 */
proto.plaidpb.LinkToken.prototype.getExpiration = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.LinkToken} returns this
 */
proto.plaidpb.LinkToken.prototype.setExpiration = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string link_token = 2;
 * @return {string}
 */
proto.plaidpb.LinkToken.prototype.getLinkToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.LinkToken} returns this
 */
proto.plaidpb.LinkToken.prototype.setLinkToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string request_id = 3;
 * @return {string}
 */
proto.plaidpb.LinkToken.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.LinkToken} returns this
 */
proto.plaidpb.LinkToken.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string error_msg = 4;
 * @return {string}
 */
proto.plaidpb.LinkToken.prototype.getErrorMsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.LinkToken} returns this
 */
proto.plaidpb.LinkToken.prototype.setErrorMsg = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string error_code = 5;
 * @return {string}
 */
proto.plaidpb.LinkToken.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.LinkToken} returns this
 */
proto.plaidpb.LinkToken.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string error_type = 6;
 * @return {string}
 */
proto.plaidpb.LinkToken.prototype.getErrorType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.LinkToken} returns this
 */
proto.plaidpb.LinkToken.prototype.setErrorType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.plaidpb.AccessToken.prototype.toObject = function(opt_includeInstance) {
  return proto.plaidpb.AccessToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.plaidpb.AccessToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.plaidpb.AccessToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accessToken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    requestId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    errorMsg: jspb.Message.getFieldWithDefault(msg, 4, ""),
    errorCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    errorType: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.plaidpb.AccessToken}
 */
proto.plaidpb.AccessToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.plaidpb.AccessToken;
  return proto.plaidpb.AccessToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.plaidpb.AccessToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.plaidpb.AccessToken}
 */
proto.plaidpb.AccessToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMsg(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.plaidpb.AccessToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.plaidpb.AccessToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.plaidpb.AccessToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.plaidpb.AccessToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getErrorMsg();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getErrorType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string item_id = 1;
 * @return {string}
 */
proto.plaidpb.AccessToken.prototype.getItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.AccessToken} returns this
 */
proto.plaidpb.AccessToken.prototype.setItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string access_token = 2;
 * @return {string}
 */
proto.plaidpb.AccessToken.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.AccessToken} returns this
 */
proto.plaidpb.AccessToken.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string request_id = 3;
 * @return {string}
 */
proto.plaidpb.AccessToken.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.AccessToken} returns this
 */
proto.plaidpb.AccessToken.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string error_msg = 4;
 * @return {string}
 */
proto.plaidpb.AccessToken.prototype.getErrorMsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.AccessToken} returns this
 */
proto.plaidpb.AccessToken.prototype.setErrorMsg = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string error_code = 5;
 * @return {string}
 */
proto.plaidpb.AccessToken.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.AccessToken} returns this
 */
proto.plaidpb.AccessToken.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string error_type = 6;
 * @return {string}
 */
proto.plaidpb.AccessToken.prototype.getErrorType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.AccessToken} returns this
 */
proto.plaidpb.AccessToken.prototype.setErrorType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.plaidpb.AuthData.prototype.toObject = function(opt_includeInstance) {
  return proto.plaidpb.AuthData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.plaidpb.AuthData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.plaidpb.AuthData.toObject = function(includeInstance, msg) {
  var f, obj = {
    json: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.plaidpb.AuthData}
 */
proto.plaidpb.AuthData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.plaidpb.AuthData;
  return proto.plaidpb.AuthData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.plaidpb.AuthData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.plaidpb.AuthData}
 */
proto.plaidpb.AuthData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.plaidpb.AuthData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.plaidpb.AuthData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.plaidpb.AuthData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.plaidpb.AuthData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJson();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string json = 1;
 * @return {string}
 */
proto.plaidpb.AuthData.prototype.getJson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.AuthData} returns this
 */
proto.plaidpb.AuthData.prototype.setJson = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.plaidpb.CreateLinkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.plaidpb.CreateLinkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.plaidpb.CreateLinkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.plaidpb.CreateLinkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    link: (f = msg.getLink()) && proto.plaidpb.Link.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.plaidpb.CreateLinkRequest}
 */
proto.plaidpb.CreateLinkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.plaidpb.CreateLinkRequest;
  return proto.plaidpb.CreateLinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.plaidpb.CreateLinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.plaidpb.CreateLinkRequest}
 */
proto.plaidpb.CreateLinkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.plaidpb.Link;
      reader.readMessage(value,proto.plaidpb.Link.deserializeBinaryFromReader);
      msg.setLink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.plaidpb.CreateLinkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.plaidpb.CreateLinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.plaidpb.CreateLinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.plaidpb.CreateLinkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLink();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.plaidpb.Link.serializeBinaryToWriter
    );
  }
};


/**
 * optional Link link = 1;
 * @return {?proto.plaidpb.Link}
 */
proto.plaidpb.CreateLinkRequest.prototype.getLink = function() {
  return /** @type{?proto.plaidpb.Link} */ (
    jspb.Message.getWrapperField(this, proto.plaidpb.Link, 1));
};


/**
 * @param {?proto.plaidpb.Link|undefined} value
 * @return {!proto.plaidpb.CreateLinkRequest} returns this
*/
proto.plaidpb.CreateLinkRequest.prototype.setLink = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.plaidpb.CreateLinkRequest} returns this
 */
proto.plaidpb.CreateLinkRequest.prototype.clearLink = function() {
  return this.setLink(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.plaidpb.CreateLinkRequest.prototype.hasLink = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.plaidpb.CreateLinkResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.plaidpb.CreateLinkResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.plaidpb.CreateLinkResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.plaidpb.CreateLinkResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    link: (f = msg.getLink()) && proto.plaidpb.Link.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.plaidpb.CreateLinkResponse}
 */
proto.plaidpb.CreateLinkResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.plaidpb.CreateLinkResponse;
  return proto.plaidpb.CreateLinkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.plaidpb.CreateLinkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.plaidpb.CreateLinkResponse}
 */
proto.plaidpb.CreateLinkResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.plaidpb.Link;
      reader.readMessage(value,proto.plaidpb.Link.deserializeBinaryFromReader);
      msg.setLink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.plaidpb.CreateLinkResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.plaidpb.CreateLinkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.plaidpb.CreateLinkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.plaidpb.CreateLinkResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLink();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.plaidpb.Link.serializeBinaryToWriter
    );
  }
};


/**
 * optional Link link = 1;
 * @return {?proto.plaidpb.Link}
 */
proto.plaidpb.CreateLinkResponse.prototype.getLink = function() {
  return /** @type{?proto.plaidpb.Link} */ (
    jspb.Message.getWrapperField(this, proto.plaidpb.Link, 1));
};


/**
 * @param {?proto.plaidpb.Link|undefined} value
 * @return {!proto.plaidpb.CreateLinkResponse} returns this
*/
proto.plaidpb.CreateLinkResponse.prototype.setLink = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.plaidpb.CreateLinkResponse} returns this
 */
proto.plaidpb.CreateLinkResponse.prototype.clearLink = function() {
  return this.setLink(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.plaidpb.CreateLinkResponse.prototype.hasLink = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.plaidpb.Account.prototype.toObject = function(opt_includeInstance) {
  return proto.plaidpb.Account.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.plaidpb.Account} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.plaidpb.Account.toObject = function(includeInstance, msg) {
  var f, obj = {
    bankId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bankOwnerName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    bankName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    achRoutingNo: jspb.Message.getFieldWithDefault(msg, 7, ""),
    wireRoutingNo: jspb.Message.getFieldWithDefault(msg, 8, ""),
    bankAccountNo: jspb.Message.getFieldWithDefault(msg, 9, ""),
    bankAccountType: jspb.Message.getFieldWithDefault(msg, 10, ""),
    approvedMethod: jspb.Message.getFieldWithDefault(msg, 11, ""),
    plaidAccessToken: jspb.Message.getFieldWithDefault(msg, 12, ""),
    status: jspb.Message.getFieldWithDefault(msg, 13, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdBy: jspb.Message.getFieldWithDefault(msg, 15, ""),
    systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    bankAddressId: jspb.Message.getFieldWithDefault(msg, 17, 0),
    bankIdentifierCode: jspb.Message.getFieldWithDefault(msg, 18, ""),
    glAccountId: jspb.Message.getFieldWithDefault(msg, 19, 0),
    glAccountNo: jspb.Message.getFieldWithDefault(msg, 20, ""),
    modifiedAt: (f = msg.getModifiedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    modifiedBy: jspb.Message.getFieldWithDefault(msg, 22, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.plaidpb.Account}
 */
proto.plaidpb.Account.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.plaidpb.Account;
  return proto.plaidpb.Account.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.plaidpb.Account} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.plaidpb.Account}
 */
proto.plaidpb.Account.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBankId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankOwnerName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAchRoutingNo(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setWireRoutingNo(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankAccountNo(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankAccountType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setApprovedMethod(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaidAccessToken(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 16:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBankAddressId(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setBankIdentifierCode(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGlAccountId(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setGlAccountNo(value);
      break;
    case 21:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setModifiedAt(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifiedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.plaidpb.Account.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.plaidpb.Account.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.plaidpb.Account} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.plaidpb.Account.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBankOwnerName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBankName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAchRoutingNo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getWireRoutingNo();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBankAccountNo();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBankAccountType();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getApprovedMethod();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPlaidAccessToken();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getBankAddressId();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
  f = message.getBankIdentifierCode();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getGlAccountId();
  if (f !== 0) {
    writer.writeUint32(
      19,
      f
    );
  }
  f = message.getGlAccountNo();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getModifiedAt();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getModifiedBy();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
};


/**
 * optional uint32 bank_id = 1;
 * @return {number}
 */
proto.plaidpb.Account.prototype.getBankId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.plaidpb.Account} returns this
 */
proto.plaidpb.Account.prototype.setBankId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.plaidpb.Account.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.Account} returns this
 */
proto.plaidpb.Account.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string master_account_no = 3;
 * @return {string}
 */
proto.plaidpb.Account.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.Account} returns this
 */
proto.plaidpb.Account.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_no = 4;
 * @return {string}
 */
proto.plaidpb.Account.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.Account} returns this
 */
proto.plaidpb.Account.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string bank_owner_name = 5;
 * @return {string}
 */
proto.plaidpb.Account.prototype.getBankOwnerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.Account} returns this
 */
proto.plaidpb.Account.prototype.setBankOwnerName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string bank_name = 6;
 * @return {string}
 */
proto.plaidpb.Account.prototype.getBankName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.Account} returns this
 */
proto.plaidpb.Account.prototype.setBankName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string ach_routing_no = 7;
 * @return {string}
 */
proto.plaidpb.Account.prototype.getAchRoutingNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.Account} returns this
 */
proto.plaidpb.Account.prototype.setAchRoutingNo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string wire_routing_no = 8;
 * @return {string}
 */
proto.plaidpb.Account.prototype.getWireRoutingNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.Account} returns this
 */
proto.plaidpb.Account.prototype.setWireRoutingNo = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string bank_account_no = 9;
 * @return {string}
 */
proto.plaidpb.Account.prototype.getBankAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.Account} returns this
 */
proto.plaidpb.Account.prototype.setBankAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string bank_account_type = 10;
 * @return {string}
 */
proto.plaidpb.Account.prototype.getBankAccountType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.Account} returns this
 */
proto.plaidpb.Account.prototype.setBankAccountType = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string approved_method = 11;
 * @return {string}
 */
proto.plaidpb.Account.prototype.getApprovedMethod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.Account} returns this
 */
proto.plaidpb.Account.prototype.setApprovedMethod = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string plaid_access_token = 12;
 * @return {string}
 */
proto.plaidpb.Account.prototype.getPlaidAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.Account} returns this
 */
proto.plaidpb.Account.prototype.setPlaidAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string status = 13;
 * @return {string}
 */
proto.plaidpb.Account.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.Account} returns this
 */
proto.plaidpb.Account.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.plaidpb.Account.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.plaidpb.Account} returns this
*/
proto.plaidpb.Account.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.plaidpb.Account} returns this
 */
proto.plaidpb.Account.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.plaidpb.Account.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string created_by = 15;
 * @return {string}
 */
proto.plaidpb.Account.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.Account} returns this
 */
proto.plaidpb.Account.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional google.type.Date system_date = 16;
 * @return {?proto.google.type.Date}
 */
proto.plaidpb.Account.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 16));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.plaidpb.Account} returns this
*/
proto.plaidpb.Account.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.plaidpb.Account} returns this
 */
proto.plaidpb.Account.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.plaidpb.Account.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional uint32 bank_address_id = 17;
 * @return {number}
 */
proto.plaidpb.Account.prototype.getBankAddressId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.plaidpb.Account} returns this
 */
proto.plaidpb.Account.prototype.setBankAddressId = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string bank_identifier_code = 18;
 * @return {string}
 */
proto.plaidpb.Account.prototype.getBankIdentifierCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.Account} returns this
 */
proto.plaidpb.Account.prototype.setBankIdentifierCode = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional uint32 gl_account_id = 19;
 * @return {number}
 */
proto.plaidpb.Account.prototype.getGlAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.plaidpb.Account} returns this
 */
proto.plaidpb.Account.prototype.setGlAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional string gl_account_no = 20;
 * @return {string}
 */
proto.plaidpb.Account.prototype.getGlAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.Account} returns this
 */
proto.plaidpb.Account.prototype.setGlAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional google.protobuf.Timestamp modified_at = 21;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.plaidpb.Account.prototype.getModifiedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 21));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.plaidpb.Account} returns this
*/
proto.plaidpb.Account.prototype.setModifiedAt = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.plaidpb.Account} returns this
 */
proto.plaidpb.Account.prototype.clearModifiedAt = function() {
  return this.setModifiedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.plaidpb.Account.prototype.hasModifiedAt = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional string modified_by = 22;
 * @return {string}
 */
proto.plaidpb.Account.prototype.getModifiedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.plaidpb.Account} returns this
 */
proto.plaidpb.Account.prototype.setModifiedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.plaidpb.BankAccount.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.plaidpb.BankAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.plaidpb.BankAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.plaidpb.BankAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.plaidpb.BankAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    bankAccountList: jspb.Message.toObjectList(msg.getBankAccountList(),
    proto.plaidpb.Account.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.plaidpb.BankAccount}
 */
proto.plaidpb.BankAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.plaidpb.BankAccount;
  return proto.plaidpb.BankAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.plaidpb.BankAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.plaidpb.BankAccount}
 */
proto.plaidpb.BankAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.plaidpb.Account;
      reader.readMessage(value,proto.plaidpb.Account.deserializeBinaryFromReader);
      msg.addBankAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.plaidpb.BankAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.plaidpb.BankAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.plaidpb.BankAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.plaidpb.BankAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBankAccountList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.plaidpb.Account.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Account bank_account = 1;
 * @return {!Array<!proto.plaidpb.Account>}
 */
proto.plaidpb.BankAccount.prototype.getBankAccountList = function() {
  return /** @type{!Array<!proto.plaidpb.Account>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.plaidpb.Account, 1));
};


/**
 * @param {!Array<!proto.plaidpb.Account>} value
 * @return {!proto.plaidpb.BankAccount} returns this
*/
proto.plaidpb.BankAccount.prototype.setBankAccountList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.plaidpb.Account=} opt_value
 * @param {number=} opt_index
 * @return {!proto.plaidpb.Account}
 */
proto.plaidpb.BankAccount.prototype.addBankAccount = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.plaidpb.Account, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.plaidpb.BankAccount} returns this
 */
proto.plaidpb.BankAccount.prototype.clearBankAccountList = function() {
  return this.setBankAccountList([]);
};


goog.object.extend(exports, proto.plaidpb);
