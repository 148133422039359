import {
  UsernameRequest,
  PasswordRequest,
  ForgotCredentialServiceClient
} from '../proto/usrpb/forgotcredentials_grpc_web_pb';

const service = new ForgotCredentialServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
);

export async function forgotUsername(param) {
console.log(param)
return new Promise((resolve, reject) => {
  const req = new UsernameRequest();
  req.setEmail(param.email)

  service.forgotUsername(req, {}, (error, response) => {
      if (error) {
          console.log(error)
          reject(error);
      } else {
          resolve(response.toObject());
      }
  });
});
}



export async function forgotPassword(param) {
  console.log(param)
  return new Promise((resolve, reject) => {
    const req = new PasswordRequest();
    req.setEmail(param.email)

    service.forgotPassword(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}