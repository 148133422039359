import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal, Backdrop, Fade } from '@material-ui/core'
import Text from '../Typography/Text'
import TextField from '../Textfields/TextField'
import MoneyField from '../Textfields/MoneyField'
import AutoCompleteAccountNo from '../AutoComplete/AutoCompleteAccountNo'
import AutoCompleteCorrespondent from '../AutoComplete/AutoCompleteCorrespondent'
import RecurrenceModal from './RecurrenceModal'
import SelectStatus from '../Dropdown/SelectStatus'
import SelectWallet from '../Dropdown/SelectWallet'
import Button from '../Buttons/Button'
import CloseButton from '../Buttons/CloseButton'
import fontFamily from '../../assets/css/fonts'
import color from '../../assets/css/colors'
import { listRecurrence } from '../../services/RecurrenceService';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
}))

export default function WalletAddressBookModal(props) {
  const classes = useStyles()

  const { data, open, close, loading, isEdit } = props
  const { dcRequestId } = data

  const [modalData, setModalData] = React.useState({
    walletAddressBookId: '',
    accountId: '',
    correspondent: '',
    accountNo: '',
    walletId: '',
    contraWalletId: '',
    nickName: '',
    recurrenceAmount: '',
    status: '',
  })
  const [isLoading, setLoading] = React.useState(false);
  const [profileName, setProfileName] = React.useState("");
  const [openRecurring, setOpenRecurring] = React.useState(false);
  const [recurringData, setRecurringData] = React.useState({
    recurrenceId: 0,
    functionName: 'Bank',
    report: 'Wallet Address Book',
    linkId: 0,
    recurring: '',
    day: 0,
    month: '',
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    settleDate: false,
    tradeDate: false,
    calendarDate: false,
  });

  React.useEffect(() => {
    if (open) {
      console.log(data);
      setModalData(data);
      getRecurrence(data);
      setProfileName(props.profileName || "")
      console.log(props.profileName);
    }
  }, [open, data]);

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target

    setModalData({ ...modalData, [input.name]: input.value })
  }

  const getRecurrence = async (value) => {
    const { recurrenceList } = await listRecurrence({
      functionName: 'Bank',
      report: 'Wallet Address Book',
      linkId: value.walletAddressBookId,
    });
    if (recurrenceList.length != 0) {
      setRecurringData(recurrenceList[0]);
    }
  };
  
  const handleRecurringOpen = async () => {
    setOpenRecurring(true);
  };

  const handleRecurringClose = async (data) => {
    setLoading(true);
    if (!data) {
      setOpenRecurring(false);
      return;
    }
    setRecurringData(data);
    setOpenRecurring(false);
    setLoading(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => close()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div style={{ width: 800 }}>
          <div className={classes.modalHeader}>
            <div>
              <Text
                mt={4}
                variant="h2"
                label={(isEdit ? 'Edit' : 'Add') + ' Wallet Address Book'}
              />
            </div>
            <div className={classes.closeButton}>
              <CloseButton onClick={() => close()} />
            </div>
          </div>
            <div className={classes.modalBody}>
              <div className="grd-row nm">
                <div className="grd-cell">
                  <TextField
                    name="nickName"
                    label="Nickname"
                    placeholder="Nickname"
                    type="text"
                    value={modalData.nickName}
                    onChange={handleChange}
                  />
                </div>
                <div className="grd-cell">
                  <AutoCompleteCorrespondent
                    required={true}
                    isAccessibleOnly={true}
                    name="correspondent"
                    value={modalData.correspondent}
                    onChange={handleChange}
                  />
                </div>
                <div className="grd-cell">
                  <AutoCompleteAccountNo
                    required={true}
                    isAccessibleOnly={true}
                    correspondent={modalData.correspondent}
                    name="accountNo"
                    value={modalData.accountNo}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="grd-row">
                <div className="grd-cell">
                  <SelectWallet
                    required={true}
                    name="walletId"
                    label="Wallet"
                    placeholder="Select Wallet"
                    accountNo={modalData.accountNo}
                    allowAddWallet={isEdit}
                    value={modalData.walletId}
                    onChange={handleChange}
                  />
                </div>
                <div className="grd-cell">
                  <SelectWallet
                    required={true}
                    name="contraWalletId"
                    label="Contra Wallet"
                    placeholder="Select Contra Wallet"
                    allowAddWallet={false}
                    value={modalData.contraWalletId}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="grd-row">
                <div className="grd-cell">
                  <MoneyField
                    required={(recurringData.recurring !== 'None' && recurringData.recurring !== '')}
                    // disabled={!isEdit}
                    name="recurrenceAmount"
                    label="Recurrence Amount"
                    placeholder="$"
                    value={modalData.recurrenceAmount}
                    onChange={handleChange}
                  />
                </div>
                <div className="grd-cell">
                  <SelectStatus
                    required
                    name="status"
                    label="Status"
                    placeholder="Status"
                    type="Status"
                    subType="AI"
                    value={modalData.status}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          <div className={classes.modalFooter}>
              <div
                className={classes.grdCellNone}
                style={{ marginRight: 10 }}
              >
                <Button
                  disabled={loading}
                  loading={loading}
                  type="recurrence"
                  label='Recurring'
                  onClick={() => handleRecurringOpen()}
                />
              </div>
            <Button
              disabled={loading}
              loading={loading}
              label={loading ? 'Saving...' : 'Save'}
              onClick={() => close(modalData,recurringData, isEdit)}
            />
          </div>
          {openRecurring && (
            <RecurrenceModal
              onClose={handleRecurringClose}
              open={openRecurring}
              value={recurringData}
            ></RecurrenceModal>
          )}
        </div>
      </Fade>
    </Modal>
  )
}
