import React from 'react';
import {Grid} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = (theme) => ({});

// Actions
const APWFormItem = ({ classes, children, ...otherProps }) => {
  return (
    <Grid item {...otherProps}>
      {children}
    </Grid>
  );
};

APWFormItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

APWFormItem.defaultProps = {}

// Apply styles using withStyles
const StyledAPWFormItem = withStyles(styles)(APWFormItem);

// Root

const APWForm = ({ classes, children, ...otherProps }) => {
  return (
    <Grid container spacing={1} {...otherProps}>
      {children}
    </Grid>
  );
};

APWForm.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

APWForm.defaultProps = {
}

APWForm.Item = StyledAPWFormItem

export default withStyles(styles, { withTheme: true, name: Grid.muiName })(APWForm);
