// source: proto/accountpb/subaccount.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.accountpb.CreateSubAccountResponse', null, global);
goog.exportSymbol('proto.accountpb.DeleteSubAccountRequest', null, global);
goog.exportSymbol('proto.accountpb.DeleteSubAccountResponse', null, global);
goog.exportSymbol('proto.accountpb.ListSubAccountRequest', null, global);
goog.exportSymbol('proto.accountpb.ListSubAccountResponse', null, global);
goog.exportSymbol('proto.accountpb.ReadSubAccountRequest', null, global);
goog.exportSymbol('proto.accountpb.ReadSubAccountResponse', null, global);
goog.exportSymbol('proto.accountpb.SubAccount', null, global);
goog.exportSymbol('proto.accountpb.UpdateSubAccountResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.SubAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.SubAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.SubAccount.displayName = 'proto.accountpb.SubAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.CreateSubAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.CreateSubAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.CreateSubAccountResponse.displayName = 'proto.accountpb.CreateSubAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.UpdateSubAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.UpdateSubAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.UpdateSubAccountResponse.displayName = 'proto.accountpb.UpdateSubAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ReadSubAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ReadSubAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ReadSubAccountRequest.displayName = 'proto.accountpb.ReadSubAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ReadSubAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ReadSubAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ReadSubAccountResponse.displayName = 'proto.accountpb.ReadSubAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.DeleteSubAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.DeleteSubAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.DeleteSubAccountRequest.displayName = 'proto.accountpb.DeleteSubAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.DeleteSubAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.DeleteSubAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.DeleteSubAccountResponse.displayName = 'proto.accountpb.DeleteSubAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ListSubAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ListSubAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ListSubAccountRequest.displayName = 'proto.accountpb.ListSubAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ListSubAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.accountpb.ListSubAccountResponse.repeatedFields_, null);
};
goog.inherits(proto.accountpb.ListSubAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ListSubAccountResponse.displayName = 'proto.accountpb.ListSubAccountResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.SubAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.SubAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.SubAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.SubAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    subAccountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    subAccount: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 5, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, ""),
    type: jspb.Message.getFieldWithDefault(msg, 8, ""),
    code: jspb.Message.getFieldWithDefault(msg, 9, ""),
    description: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.SubAccount}
 */
proto.accountpb.SubAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.SubAccount;
  return proto.accountpb.SubAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.SubAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.SubAccount}
 */
proto.accountpb.SubAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSubAccountId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubAccount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.SubAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.SubAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.SubAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.SubAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubAccountId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSubAccount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional uint32 sub_account_id = 1;
 * @return {number}
 */
proto.accountpb.SubAccount.prototype.getSubAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.SubAccount} returns this
 */
proto.accountpb.SubAccount.prototype.setSubAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 account_id = 2;
 * @return {number}
 */
proto.accountpb.SubAccount.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.SubAccount} returns this
 */
proto.accountpb.SubAccount.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string sub_account = 3;
 * @return {string}
 */
proto.accountpb.SubAccount.prototype.getSubAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.SubAccount} returns this
 */
proto.accountpb.SubAccount.prototype.setSubAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_name = 4;
 * @return {string}
 */
proto.accountpb.SubAccount.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.SubAccount} returns this
 */
proto.accountpb.SubAccount.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string correspondent = 5;
 * @return {string}
 */
proto.accountpb.SubAccount.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.SubAccount} returns this
 */
proto.accountpb.SubAccount.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string account_no = 6;
 * @return {string}
 */
proto.accountpb.SubAccount.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.SubAccount} returns this
 */
proto.accountpb.SubAccount.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string status = 7;
 * @return {string}
 */
proto.accountpb.SubAccount.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.SubAccount} returns this
 */
proto.accountpb.SubAccount.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string type = 8;
 * @return {string}
 */
proto.accountpb.SubAccount.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.SubAccount} returns this
 */
proto.accountpb.SubAccount.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string code = 9;
 * @return {string}
 */
proto.accountpb.SubAccount.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.SubAccount} returns this
 */
proto.accountpb.SubAccount.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string description = 10;
 * @return {string}
 */
proto.accountpb.SubAccount.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.SubAccount} returns this
 */
proto.accountpb.SubAccount.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.CreateSubAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.CreateSubAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.CreateSubAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.CreateSubAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    subAccount: (f = msg.getSubAccount()) && proto.accountpb.SubAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.CreateSubAccountResponse}
 */
proto.accountpb.CreateSubAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.CreateSubAccountResponse;
  return proto.accountpb.CreateSubAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.CreateSubAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.CreateSubAccountResponse}
 */
proto.accountpb.CreateSubAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.SubAccount;
      reader.readMessage(value,proto.accountpb.SubAccount.deserializeBinaryFromReader);
      msg.setSubAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.CreateSubAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.CreateSubAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.CreateSubAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.CreateSubAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.SubAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional SubAccount sub_account = 1;
 * @return {?proto.accountpb.SubAccount}
 */
proto.accountpb.CreateSubAccountResponse.prototype.getSubAccount = function() {
  return /** @type{?proto.accountpb.SubAccount} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.SubAccount, 1));
};


/**
 * @param {?proto.accountpb.SubAccount|undefined} value
 * @return {!proto.accountpb.CreateSubAccountResponse} returns this
*/
proto.accountpb.CreateSubAccountResponse.prototype.setSubAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.CreateSubAccountResponse} returns this
 */
proto.accountpb.CreateSubAccountResponse.prototype.clearSubAccount = function() {
  return this.setSubAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.CreateSubAccountResponse.prototype.hasSubAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.UpdateSubAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.UpdateSubAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.UpdateSubAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.UpdateSubAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    subAccount: (f = msg.getSubAccount()) && proto.accountpb.SubAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.UpdateSubAccountResponse}
 */
proto.accountpb.UpdateSubAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.UpdateSubAccountResponse;
  return proto.accountpb.UpdateSubAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.UpdateSubAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.UpdateSubAccountResponse}
 */
proto.accountpb.UpdateSubAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.SubAccount;
      reader.readMessage(value,proto.accountpb.SubAccount.deserializeBinaryFromReader);
      msg.setSubAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.UpdateSubAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.UpdateSubAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.UpdateSubAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.UpdateSubAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.SubAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional SubAccount sub_account = 1;
 * @return {?proto.accountpb.SubAccount}
 */
proto.accountpb.UpdateSubAccountResponse.prototype.getSubAccount = function() {
  return /** @type{?proto.accountpb.SubAccount} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.SubAccount, 1));
};


/**
 * @param {?proto.accountpb.SubAccount|undefined} value
 * @return {!proto.accountpb.UpdateSubAccountResponse} returns this
*/
proto.accountpb.UpdateSubAccountResponse.prototype.setSubAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.UpdateSubAccountResponse} returns this
 */
proto.accountpb.UpdateSubAccountResponse.prototype.clearSubAccount = function() {
  return this.setSubAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.UpdateSubAccountResponse.prototype.hasSubAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ReadSubAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ReadSubAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ReadSubAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadSubAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subAccountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ReadSubAccountRequest}
 */
proto.accountpb.ReadSubAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ReadSubAccountRequest;
  return proto.accountpb.ReadSubAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ReadSubAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ReadSubAccountRequest}
 */
proto.accountpb.ReadSubAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSubAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ReadSubAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ReadSubAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ReadSubAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadSubAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubAccountId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 sub_account_id = 1;
 * @return {number}
 */
proto.accountpb.ReadSubAccountRequest.prototype.getSubAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.ReadSubAccountRequest} returns this
 */
proto.accountpb.ReadSubAccountRequest.prototype.setSubAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.accountpb.ReadSubAccountRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ReadSubAccountRequest} returns this
 */
proto.accountpb.ReadSubAccountRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ReadSubAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ReadSubAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ReadSubAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadSubAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    subAccount: (f = msg.getSubAccount()) && proto.accountpb.SubAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ReadSubAccountResponse}
 */
proto.accountpb.ReadSubAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ReadSubAccountResponse;
  return proto.accountpb.ReadSubAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ReadSubAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ReadSubAccountResponse}
 */
proto.accountpb.ReadSubAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.SubAccount;
      reader.readMessage(value,proto.accountpb.SubAccount.deserializeBinaryFromReader);
      msg.setSubAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ReadSubAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ReadSubAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ReadSubAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadSubAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.SubAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional SubAccount sub_account = 1;
 * @return {?proto.accountpb.SubAccount}
 */
proto.accountpb.ReadSubAccountResponse.prototype.getSubAccount = function() {
  return /** @type{?proto.accountpb.SubAccount} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.SubAccount, 1));
};


/**
 * @param {?proto.accountpb.SubAccount|undefined} value
 * @return {!proto.accountpb.ReadSubAccountResponse} returns this
*/
proto.accountpb.ReadSubAccountResponse.prototype.setSubAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.ReadSubAccountResponse} returns this
 */
proto.accountpb.ReadSubAccountResponse.prototype.clearSubAccount = function() {
  return this.setSubAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.ReadSubAccountResponse.prototype.hasSubAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.DeleteSubAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.DeleteSubAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.DeleteSubAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteSubAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subAccountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.DeleteSubAccountRequest}
 */
proto.accountpb.DeleteSubAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.DeleteSubAccountRequest;
  return proto.accountpb.DeleteSubAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.DeleteSubAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.DeleteSubAccountRequest}
 */
proto.accountpb.DeleteSubAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSubAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.DeleteSubAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.DeleteSubAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.DeleteSubAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteSubAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubAccountId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 sub_account_id = 1;
 * @return {number}
 */
proto.accountpb.DeleteSubAccountRequest.prototype.getSubAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.DeleteSubAccountRequest} returns this
 */
proto.accountpb.DeleteSubAccountRequest.prototype.setSubAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.accountpb.DeleteSubAccountRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.DeleteSubAccountRequest} returns this
 */
proto.accountpb.DeleteSubAccountRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.DeleteSubAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.DeleteSubAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.DeleteSubAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteSubAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    subAccounts: (f = msg.getSubAccounts()) && proto.accountpb.SubAccount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.DeleteSubAccountResponse}
 */
proto.accountpb.DeleteSubAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.DeleteSubAccountResponse;
  return proto.accountpb.DeleteSubAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.DeleteSubAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.DeleteSubAccountResponse}
 */
proto.accountpb.DeleteSubAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.SubAccount;
      reader.readMessage(value,proto.accountpb.SubAccount.deserializeBinaryFromReader);
      msg.setSubAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.DeleteSubAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.DeleteSubAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.DeleteSubAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteSubAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubAccounts();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.SubAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional SubAccount sub_accounts = 1;
 * @return {?proto.accountpb.SubAccount}
 */
proto.accountpb.DeleteSubAccountResponse.prototype.getSubAccounts = function() {
  return /** @type{?proto.accountpb.SubAccount} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.SubAccount, 1));
};


/**
 * @param {?proto.accountpb.SubAccount|undefined} value
 * @return {!proto.accountpb.DeleteSubAccountResponse} returns this
*/
proto.accountpb.DeleteSubAccountResponse.prototype.setSubAccounts = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.DeleteSubAccountResponse} returns this
 */
proto.accountpb.DeleteSubAccountResponse.prototype.clearSubAccounts = function() {
  return this.setSubAccounts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.DeleteSubAccountResponse.prototype.hasSubAccounts = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ListSubAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ListSubAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ListSubAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListSubAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    type: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ListSubAccountRequest}
 */
proto.accountpb.ListSubAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ListSubAccountRequest;
  return proto.accountpb.ListSubAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ListSubAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ListSubAccountRequest}
 */
proto.accountpb.ListSubAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ListSubAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ListSubAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ListSubAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListSubAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.accountpb.ListSubAccountRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.ListSubAccountRequest} returns this
 */
proto.accountpb.ListSubAccountRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.accountpb.ListSubAccountRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListSubAccountRequest} returns this
 */
proto.accountpb.ListSubAccountRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.accountpb.ListSubAccountResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ListSubAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ListSubAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ListSubAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListSubAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    subAccountsList: jspb.Message.toObjectList(msg.getSubAccountsList(),
    proto.accountpb.SubAccount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ListSubAccountResponse}
 */
proto.accountpb.ListSubAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ListSubAccountResponse;
  return proto.accountpb.ListSubAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ListSubAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ListSubAccountResponse}
 */
proto.accountpb.ListSubAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.SubAccount;
      reader.readMessage(value,proto.accountpb.SubAccount.deserializeBinaryFromReader);
      msg.addSubAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ListSubAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ListSubAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ListSubAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListSubAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.accountpb.SubAccount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SubAccount sub_accounts = 1;
 * @return {!Array<!proto.accountpb.SubAccount>}
 */
proto.accountpb.ListSubAccountResponse.prototype.getSubAccountsList = function() {
  return /** @type{!Array<!proto.accountpb.SubAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.accountpb.SubAccount, 1));
};


/**
 * @param {!Array<!proto.accountpb.SubAccount>} value
 * @return {!proto.accountpb.ListSubAccountResponse} returns this
*/
proto.accountpb.ListSubAccountResponse.prototype.setSubAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.accountpb.SubAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.SubAccount}
 */
proto.accountpb.ListSubAccountResponse.prototype.addSubAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.accountpb.SubAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ListSubAccountResponse} returns this
 */
proto.accountpb.ListSubAccountResponse.prototype.clearSubAccountsList = function() {
  return this.setSubAccountsList([]);
};


goog.object.extend(exports, proto.accountpb);
