/**
 * @fileoverview gRPC-Web generated client stub for plaidpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_type_date_pb = require('../../google/type/date_pb.js')
const proto = {};
proto.plaidpb = require('./plaidtoken_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.plaidpb.PlaidServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.plaidpb.PlaidServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.plaidpb.Link,
 *   !proto.plaidpb.LinkToken>}
 */
const methodDescriptor_PlaidService_CreateLinkToken = new grpc.web.MethodDescriptor(
  '/plaidpb.PlaidService/CreateLinkToken',
  grpc.web.MethodType.UNARY,
  proto.plaidpb.Link,
  proto.plaidpb.LinkToken,
  /**
   * @param {!proto.plaidpb.Link} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.plaidpb.LinkToken.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.plaidpb.Link,
 *   !proto.plaidpb.LinkToken>}
 */
const methodInfo_PlaidService_CreateLinkToken = new grpc.web.AbstractClientBase.MethodInfo(
  proto.plaidpb.LinkToken,
  /**
   * @param {!proto.plaidpb.Link} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.plaidpb.LinkToken.deserializeBinary
);


/**
 * @param {!proto.plaidpb.Link} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.plaidpb.LinkToken)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.plaidpb.LinkToken>|undefined}
 *     The XHR Node Readable Stream
 */
proto.plaidpb.PlaidServiceClient.prototype.createLinkToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/plaidpb.PlaidService/CreateLinkToken',
      request,
      metadata || {},
      methodDescriptor_PlaidService_CreateLinkToken,
      callback);
};


/**
 * @param {!proto.plaidpb.Link} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.plaidpb.LinkToken>}
 *     Promise that resolves to the response
 */
proto.plaidpb.PlaidServicePromiseClient.prototype.createLinkToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/plaidpb.PlaidService/CreateLinkToken',
      request,
      metadata || {},
      methodDescriptor_PlaidService_CreateLinkToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.plaidpb.PublicToken,
 *   !proto.plaidpb.AccessToken>}
 */
const methodDescriptor_PlaidService_ExchangePublicToken = new grpc.web.MethodDescriptor(
  '/plaidpb.PlaidService/ExchangePublicToken',
  grpc.web.MethodType.UNARY,
  proto.plaidpb.PublicToken,
  proto.plaidpb.AccessToken,
  /**
   * @param {!proto.plaidpb.PublicToken} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.plaidpb.AccessToken.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.plaidpb.PublicToken,
 *   !proto.plaidpb.AccessToken>}
 */
const methodInfo_PlaidService_ExchangePublicToken = new grpc.web.AbstractClientBase.MethodInfo(
  proto.plaidpb.AccessToken,
  /**
   * @param {!proto.plaidpb.PublicToken} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.plaidpb.AccessToken.deserializeBinary
);


/**
 * @param {!proto.plaidpb.PublicToken} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.plaidpb.AccessToken)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.plaidpb.AccessToken>|undefined}
 *     The XHR Node Readable Stream
 */
proto.plaidpb.PlaidServiceClient.prototype.exchangePublicToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/plaidpb.PlaidService/ExchangePublicToken',
      request,
      metadata || {},
      methodDescriptor_PlaidService_ExchangePublicToken,
      callback);
};


/**
 * @param {!proto.plaidpb.PublicToken} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.plaidpb.AccessToken>}
 *     Promise that resolves to the response
 */
proto.plaidpb.PlaidServicePromiseClient.prototype.exchangePublicToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/plaidpb.PlaidService/ExchangePublicToken',
      request,
      metadata || {},
      methodDescriptor_PlaidService_ExchangePublicToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.plaidpb.AccessToken,
 *   !proto.plaidpb.AuthData>}
 */
const methodDescriptor_PlaidService_GetAuth = new grpc.web.MethodDescriptor(
  '/plaidpb.PlaidService/GetAuth',
  grpc.web.MethodType.UNARY,
  proto.plaidpb.AccessToken,
  proto.plaidpb.AuthData,
  /**
   * @param {!proto.plaidpb.AccessToken} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.plaidpb.AuthData.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.plaidpb.AccessToken,
 *   !proto.plaidpb.AuthData>}
 */
const methodInfo_PlaidService_GetAuth = new grpc.web.AbstractClientBase.MethodInfo(
  proto.plaidpb.AuthData,
  /**
   * @param {!proto.plaidpb.AccessToken} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.plaidpb.AuthData.deserializeBinary
);


/**
 * @param {!proto.plaidpb.AccessToken} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.plaidpb.AuthData)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.plaidpb.AuthData>|undefined}
 *     The XHR Node Readable Stream
 */
proto.plaidpb.PlaidServiceClient.prototype.getAuth =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/plaidpb.PlaidService/GetAuth',
      request,
      metadata || {},
      methodDescriptor_PlaidService_GetAuth,
      callback);
};


/**
 * @param {!proto.plaidpb.AccessToken} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.plaidpb.AuthData>}
 *     Promise that resolves to the response
 */
proto.plaidpb.PlaidServicePromiseClient.prototype.getAuth =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/plaidpb.PlaidService/GetAuth',
      request,
      metadata || {},
      methodDescriptor_PlaidService_GetAuth);
};


module.exports = proto.plaidpb;

