import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Text from '../Typography/Text';
import color from '../../assets/css/colors';
import {ReactComponent as Info} from '../../assets/icons/info.svg';
import fontFamily from '../../assets/css/fonts';

const useStyles = makeStyles(() => ({
    phoneFieldWrapper: {
        position: 'relative',
        '& .react-tel-input:focus': {
            backgroundColor: 'red',
        },
        '& .react-tel-input .form-control': {
            width: '100%',
            height: 'auto',
            padding: '15px 15px 15px 60px',
            backgroundColor: color.light.opacity,
            border: '1px solid ' + color.gray.opacity,
            borderRadius: 7,
            color: color.default.main,
            fontFamily: fontFamily.textField,
            fontWeight: 600,
            lineHeight: '1.1876em',
            marginTop: '28px !important',
            '&:focus': {
                border: '1px solid ' + color.primary.light + ' !important',
            }
          },
          '& .react-tel-input .flag-dropdown': {
            backgroundColor: 'unset',
            border: 'unset',
            borderRadius: 'unset',
            marginTop: '28px',
          },
          '& .react-tel-input .selected-flag': {
            width: 60,
            padding: '0px 17px 0 17px',
          },
          '& .react-tel-input .selected-flag:hover': {
            backgroundColor: 'unset',
          },
          '& .react-tel-input .selected-flag:focus': {
            backgroundColor: 'unset',
          },
          '& .react-tel-input .flag-dropdown.open .selected-flag': {
            backgroundColor: color.light.opacity,
            borderTop: '1px solid ' + color.gray.opacity,
            borderBottom: '1px solid ' + color.gray.opacity,
            borderLeft: '1px solid ' + color.gray.opacity,
            borderRight: 'unset',
            borderRadius: '7px 0 0 7px',
          },
          '& .react-tel-input .selected-flag .flag': {
            marginTop: -6,
          },
    },
    phoneLabel: {
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        width: '100%',
        '& h6': {
            fontWeight: 600,
            color: color.default.main,
            lineHeight: '17px',
        },
        '& .grd-cell-none > h6': {
            fontSize: 13,
            fontStyle: 'italic',
            lineHeight: '19px',
        }
    },
    tooltipWrapper: {
        position: 'absolute',
        top: 31,
        right: 1,
        padding: '9px 10px',
        backgroundColor: color.light.opacity,
        borderRadius: 5,
        '& svg': {
            width: 21,
            height: 21,
            cursor: 'help',
            '& path': {
                fill: color.primary.main,
            }
        },
    }
}));

const HtmlTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: color.light.main,
      maxWidth: 620,
      border: '1px solid ' + color.light.light,
      borderRadius: 10,
    },
}))(Tooltip);

const PhoneNumber = (props) => {
    const classes = useStyles();

    return (
        <div id="phoneWrapper" className={classes.phoneFieldWrapper} style={{ marginTop: props.mt ? 20 : 0 }}>
            <PhoneInput
                id="phoneNumber"
                country={'us'}
                name={props.name}
                value={props.value || ''}
                onChange={(value, country, e, formattedValue) => props.onChange({ currentTarget: { name: props.name, value: formattedValue }})}
                inputProps={{
                    required: props.required,
                    disabled: props.disabled,
                    autoFocus: props.autoFocus,
                    name: props.name,
                }}
            />
            <div className={classes.phoneLabel}>
                <div className="grd-cell">
                    <Text
                        required={props.required}
                        error={props.error}
                        variant="subtitle2"
                        label={props.label}
                    />
                </div>
                {props.error && (
                    <div className="grd-cell-none">
                        <Text
                            error={props.error}
                            variant="subtitle2"
                            label={props.msg}
                        />
                    </div>
                )}
            </div>
            {(props.tooltip || props.htmlTooltip) && (
                <div className={classes.tooltipWrapper} style={{marginTop: props.mt ? 20 : 0}}>
                    {props.tooltip ? (
                        <Tooltip 
                            arrow
                            placement="top"
                            title={props.tooltip}
                        >
                            <Info />
                        </Tooltip>
                    ) : (
                        <HtmlTooltip 
                            placement="right"
                            title={<React.Fragment>{props.htmlTooltip}</React.Fragment>}
                        >
                            <Info />
                        </HtmlTooltip> 
                    )}
                </div>
            )}
        </div>
    );
}
export default PhoneNumber;