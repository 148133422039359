import React, {useEffect} from 'react'
import { IconButton, Checkbox } from '@material-ui/core'
import { CheckBox, Create as EditIcon } from '@material-ui/icons'
import ClientGrid from '../../../components/ClientGrid/ClientGrid'
import ClientGrid2 from '../../../components/ClientGrid/ClientGrid2'
import Table, { columnType } from '../../../components/ClearingTable/Table';
import Button from '../../../components/Buttons/Button'
import AutoCompleteAccountNo from '../../../components/AutoComplete/AutoCompleteAccountNo'
import AutoCompleteSymbol from '../../../components/AutoComplete/AutoCompleteSymbol'
import AutoCompleteCorrespondent from '../../../components/AutoComplete/AutoCompleteCorrespondent'
import AutoCompleteMasterAccountNo from '../../../components/AutoComplete/AutoCompleteMasterAccountNo'
import SelectSystemCode from '../../../components/Dropdown/SelectSystemCode'
import Text from '../../../components/Typography/Text'

import TextField from '../../../components/Textfields/TextField'
import DateTimePicker from '../../../components/Textfields/DateTimePicker'
import MoneyField from '../../../components/Textfields/MoneyField'
import DigitalCashRequestModal from './Components/DigitalCashRequestModal'
import DigitalCashRequestSummaryModal from './Components/DigitalCashRequestSummaryModal'
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from '../../../components/Messages/Notification'
import { digitalCashRequestColumns,digitalCashRequestColumnsAxs } from '../../../lib/data/columns'
import {
  listDigitalCashRequest,
  createDigitalCashRequest,
  updateDigitalCashRequest,
} from '../../../services/DigitalCashRequestService'
import { getSystemDate } from '../../../services/ProfileService'
import QueryParam from '../../../services/QueryParamService'
import { 
  createRecurrence,
  updateRecurrence,
  deleteRecurrence,
} from '../../../services/RecurrenceService'
import {
  listWallet,
  createWallet,
  updateWallet,
  listWalletSummary,
} from '../../../services/WalletService'

import { getProfileName } from '../../../services/ProfileService'
import { getCurrentUser, getAccess } from '../../../services/AuthService'
import {readClient, getAccessibleClient} from '../../../services/ClientAccountService'

import {
  CustomBodyRenderCurrency,
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderDate,
  CustomBodyRenderDateTime,
  CustomBodyRenderBoolean,
  CustomBodyRenderAlign,
  CustomBodyRenderHtmlString,
  CustomBodyRenderCenter,
  CustomBodyRenderMissing,
} from '../../../components/Table/CustomBodyRender';

import { formatPbDate, formatPbDateTime } from '../../../lib/fmt';


export default function DigitalCashRequest() {
  const [rows, setRows] = React.useState([])
  const [rowData, setRowData] = React.useState({})

  const [open, setOpen] = React.useState({
    add: false,
    summary: false,
  })

  const [loading, setLoading] = React.useState({
    search: false,
    save: false,
  })

  const [profileName, setProfileName] = React.useState('')
  const [user, setUser] = React.useState([])
  const [defaultAccount, setDefaultAccount] = React.useState([])
  const [defaultWallet, setDefaultWallet] = React.useState([])
  const [addLoading, setAddLoading] = React.useState(true)

  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      fromDate: '',
      toDate: '',
      correspondent: '',
      accountNo: '',
      symbol: '',
      externalId: '',
      masterAccountNo: '',
      transferType: '',
      requestType: '',
      status: '',
      sign: '',
      amount: '',
      search:'',
    }),
  )

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    let account_no = ''
    let correspondent = ''
    try {
      const systemDate = await getSystemDate()

      const serverProfile = await getProfileName()
      const loggeduser = getCurrentUser()
      setProfileName(serverProfile.toLowerCase())
      setUser(loggeduser)
      //console.log(loggeduser)
      if(loggeduser.AccountId != 0) {
        const account = await readClient(loggeduser.AccountId)
        setDefaultAccount(account)
        console.log(account)
        account_no = account.client.accountNo 
        correspondent = account.client.correspondent
      } else {
        const account = await getAccessibleClient(loggeduser.UserId);
        setDefaultAccount(account);
        account_no = account.client.accountNo;
        correspondent = account.client.correspondent;
      }
      if(!getAccess("account_no", loggeduser.UserType)){
        setSearchData({
          ...searchData,
          fromDate: systemDate,
          toDate: systemDate,
          accountNo:account_no,
          correspondent: correspondent,
        })
      }else{
        setSearchData({
          ...searchData,
          fromDate: systemDate,
          toDate: systemDate,
        })
      }

      

      
    } catch (error) {
      notifyError(error.message)
    }
    
    if (searchData.search === true) {
          handleSearch();
    }
    setAddLoading(false);
  }

  React.useEffect(() => {
    if (!searchData.fromDate || !searchData.toDate) {
      //init()
    }
    // eslint-disable-next-line
  }, [])

  const GridButtons = ({
    tableManager,
    value,
    data,
    column,
    colIndex,
    rowIndex,
  }) => {
    return (
      <div>
        <div style={{ padding: 5 }}>
          <IconButton aria-label="edit" onClick={() => handleOpen(data)}>
            <EditIcon style={{ height: 24, width: 24 }} />
          </IconButton>
        </div>
      </div>
    )
  }

  const columns2 = [
    {
      id: '',
      label: '',
      width: '40px',
      cellRenderer: GridButtons,
    },
    ...digitalCashRequestColumns,
  ]

  const columns = [
    {
      name: 'actions',
      label: 'Actions',
      sort: false,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return(
            <div>
              <div style={{ padding: 5 }}>
                <IconButton aria-label="edit" onClick={() => handleOpen(rows[dataIndex])}>
                  <EditIcon style={{ height: 24, width: 24 }} />
                </IconButton>
              </div>
            </div>
          );
        },
      },
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'accountNo',
      label: 'Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'accountName',
      label: 'Account Name',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'broker',
      label: 'Broker',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'amt',
      label: 'Amount',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'transferType',
      label: 'Transfer Type',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'referenceId',
      label: 'Reference ID',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      }
    },
    {
      name: 'confirmationId',
      label: 'Confirmation ID',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'publicDescription',
      label: 'Public Description',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'privateDescription',
      label: 'Private Description',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'internalId',
      label: 'Internal ID',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false
      }
    },
    {
      name: 'externalId',
      label: 'External ID',
      options: {
        customBodyRender: CustomBodyRenderAlign,
        display: false,
      }
    },
    {
      name: 'bankId',
      label: 'DDA Bank',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'requestType',
      label: 'DDA Request Type',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
  
    {
      name: 'createdBy',
      label: 'Created By',
    },
    {
      name: 'systemDate',
      label: 'System Date',
      options: {
        customBodyRender: CustomBodyRenderDate,
      }
    },
  ];

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
  };

  const handleSearchDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target

    if (input.name === 'sign' && input.value === '') {
      searchData.amount = ''
    }

    setSearchData({ ...searchData, [input.name]: input.value })
  }

  const handleSearch = async () => {
    try {
      setLoading({ ...loading, search: true })

      QueryParam.set(searchData)

      const data = await listDigitalCashRequest(searchData)

      let rowsCopy = data.dcRequestsList;

      const rowsCopyOutput = rowsCopy.map((data) => {
        let tempDate = formatPbDate(data.systemDate);
        data.systemDate = tempDate

        return data;
      })
      
      setRows(rowsCopyOutput)

      notifyInfo(
        data.dcRequestsList.length > 0
          ? data.dcRequestsList.length + ' search result(s)'
          : 'No records found.',
      )
    } catch (error) {
      notifyError(error.message)
      return
    } finally {
      setLoading({ ...loading, search: false })
    }
  }

  const handleOpen = (data) => {
    let rowData = data
    const correspondent=defaultAccount?.client?.correspondent?defaultAccount.client.correspondent:'';
    const account_no=defaultAccount?.client?.accountNo?defaultAccount.client.accountNo:'';
    const wallet_id=defaultWallet.length>0?defaultWallet[0].walletId:'';

    if (!rowData) {
      rowData = {
        correspondent: correspondent,
        accountNo: account_no,
        transferType: '',
        walletId: wallet_id,
        contraWalletId: '',
        amt: '',
        status: 'Pending',
        referenceId: '',
        confirmationId: '',
        internalId: '',
        externalId: '',
        privateDescription: '',
        publicDescription: '',
      }
    }

    setRowData(rowData)
    setOpen({ ...open, add: true })
  }

  const handleClose = async (data, isEdit, recData) => {
    if (!data) {
      setOpen({ ...open, add: false })
      return
    }

    let valid = true

    if (!data.correspondent) {
      notifyError('Correspondent is required.')
      valid = false
    }

    if (!data.accountNo) {
      notifyError('Account No is required.')
      valid = false
    }

    if (!data.transferType) {
      notifyError('Transfer Type is required.')
      valid = false
    }

    // if (!data.walletId) {
    //   notifyError('Wallet is required.')
    //   valid = false
    // }

    if (!data.amt) {
      notifyError('Amount is required.')
      valid = false
    }

    if (!data.status) {
      notifyError('Status is required.')
      valid = false
    }

    if (data.transferType !== 'Transfer Send') {
      if (!data.bankId) {
        notifyError('DDA Bank is required.')
        valid = false
      }
      if (!data.requestType) {
        notifyError('DDA Request Type is required.')
        valid = false
      }
    } 

    if (!valid) {
      return
    }

    try {
      setLoading({ ...loading, save: true })
      let resp;
      if (isEdit) {
        resp = await updateDigitalCashRequest(data)
        const rowsCopy = [...rows]
        const index = rows.indexOf(rowData)

        rowsCopy[index] = resp.dcRequest

        setRows(rowsCopy)
        notifySuccess('Digital cash request has been updated.')
      } else {
        resp = await createDigitalCashRequest(data)

        setRows([resp.dcRequest, ...rows])
        notifySuccess('New Digital cash request has been added.')
      }
      if (recData && data.transferType === 'Transfer Send' && recData.linkId != 0) {
        if (recData.recurrenceId != 0) {
          if (recData.recurring == 'None') {
            await deleteRecurrence(recData);
          } else {
            await updateRecurrence(recData);
          }
        } else {
          await createRecurrence(recData);
        }
      }
    } catch (error) {
      setLoading({ ...loading, save: false })
      notifyError(error.message)
    } finally {
      setOpen({ ...open, add: false })
      setLoading({ ...loading, save: false })
    }
  }

  const handleOpenSummary = () => {
    setOpen({ ...open, summary: true })
  }

  const handleCloseSummary = () => {
    setOpen({ ...open, summary: false })
  }

  const getCsvData = async () => {
    const data = await listDigitalCashRequest(searchData)

    return data.dcRequestsList
  }


  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Digital Cash Request" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading.search}
              loading={loading.search}
              type="search"
              label={loading.search ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
          <div className="grd-cell-none">
            <Button label="Summary" onClick={() => handleOpenSummary()} />
          </div>
          <div className="grd-cell-none">
            <Button
              type="plus"
              label="Add Digital Cash Request"
              loading={addLoading}
              disabled={addLoading}
              onClick={() => handleOpen()}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <DateTimePicker
              type="date"
              name="fromDate"
              label="From Date"
              value={searchData.fromDate}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <DateTimePicker
              type="date"
              name="toDate"
              label="To Date"
              value={searchData.toDate}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteCorrespondent
              isAccessibleOnly={true}
              name="correspondent"
              label={"Correspondent"}
              value={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteAccountNo
              isAccessibleOnly={true}
              name="accountNo"
              value={searchData.accountNo}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteSymbol
              name="symbol"
              value={searchData.symbol}
              onChange={handleSearchDataChange}
              assetType="DA"
            />
          </div>
        
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <TextField
              name="externalId"
              label="External ID"
              placeholder="External ID"
              type="text"
              value={searchData.externalId}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteMasterAccountNo
              isAccessibleOnly={true}
              name="masterAccountNo"
              value={searchData.masterAccountNo}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className='grd-cell'>
            <SelectSystemCode
              name='requestType'
              label='Request Type'
              placeholder='Request Type'
              type='Type'
              subType='Request Type'
              value={searchData.requestType}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <SelectSystemCode
              name="transferType"
              label="Transfer Type"
              placeholder="Transfer Type"
              type="Client Site"
              subType="Transfer Type"
              value={searchData.transferType}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <SelectSystemCode
              name="status"
              label="Status"
              placeholder="Status"
              type="Status"
              subType="Bank Request"
              value={searchData.status}
              onChange={handleSearchDataChange}
              withBankStatusGroup={true}
            />
          </div>
          <div className="grd-cell">
            <div className="grd-row nm">
              <div className="grd-cell">
                <SelectSystemCode
                  name="sign"
                  label="Sign"
                  placeholder="Sign"
                  type="Sign"
                  subType="Bank Request"
                  value={searchData.sign}
                  onChange={handleSearchDataChange}
                />
              </div>
              <div className="grd-cell">
                <MoneyField
                  disabled={searchData.sign ? false : true}
                  name="amount"
                  label="Amount"
                  placeholder="$"
                  value={searchData.amount}
                  onChange={handleSearchDataChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

   
      <div className="mt-20">
        {/* <ClientGrid
          title="Digital Cash Request"
          rowIdField="dcRequestId"
          columns={columns}
          rows={rows}
          getCsvData={getCsvData}
          isLoading={loading.search}
          setRows={setRows}
        /> */}
        {/* <ClientGrid2
          title="Digital Cash Request"
          data={rows}
          columns={columns}
          options={options}
        /> */}
        <Table
          title={'Digital Cash Request'}
          data={rows}
          columns={columns}
          options={options}
        />
      </div>
 
      <DigitalCashRequestModal
        open={open.add}
        close={handleClose}
        data={rowData}
        loading={loading.save}
      />
      <DigitalCashRequestSummaryModal
        open={open.summary}
        close={handleCloseSummary}
        data={searchData}
      />
    </div>
  )
}
