import React, { useState, useEffect } from 'react';
import { Modal, Backdrop, Fade, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import color from '../../../assets/css/colors';
import fontFamily from '../../../assets/css/fonts';
import { listAccessors } from '../../../services/ClientAccountAccessService';
import ClientGrid from '../../../components/ClientGrid/ClientGrid';
import Button from '../../../components/Buttons/Button';
import Text from '../../../components/Typography/Text';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  fadeModalLg: {
    maxWidth: 900,
    maxHeight: '90vh',
    width: '100%',
    padding: '25px 30px 30px 30px',
    margin: '0px auto',
    backgroundColor: '#ffffff',
    border: 'none',
    borderRadius: 5,
    boxShadow: '0px 0px 10px #333333',
    boxSizing: 'border-box',
    outline: 'none',
    overflowY: 'auto',
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
}));

export default function ClientAccountAccessorModal({
  onClose: handleClose,
  open: isOpen,
  id,
}) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [mounted, setMounted] = React.useState(false);
  const options = {
    selectableRows: 'none',
  };

  const columns = [
    {
      id: 'name',
      field: 'name',
      label: 'Name',
    },
    {
      id: 'email',
      field: 'email',
      label: 'Email',
    },
    {
      id: 'mobileNo',
      field: 'mobileNo',
      label: 'Mobile No',
    },
  ];

  const setListOfAccessor = async () => {
    const data = await listAccessors(id);
    setRows(data.accountAccessorsList);
  };

  useEffect(() => {
    if (isOpen && !mounted) {
      setListOfAccessor();
    }
    // eslint-disable-next-line
    return () => {
      setMounted(true); // This worked for me
    };
    // eslint-disable-next-line
  }, [isOpen, mounted]);

  const getCsvData = async () => {
    return rows;
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalLg}>
          <div className="grd-row">
            <div className="grd-cell">
              <Text id="transition-modal-title" variant="h2" label={'Accessor Table'} />
            </div>
          </div>
          <Box mt={5}>
            <ClientGrid
              title="Accessors List"
              rows={rows}
              columns={columns}
              getCsvData={getCsvData}
            />
          </Box>
          <div className={classes.modalFooter} style={{ margin: 0 }}>
            <div className={classes.modalFooter}>
              <div className="grd-cell-none" style={{ marginRight: 10, marginTop: 20 }}>
                <Button
                  color="primary"
                  label="Close"
                  onClick={() => {
                    handleClose();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
