/**
 * @fileoverview gRPC-Web generated client stub for filepb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.filepb = require('./manager_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.filepb.FileServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.filepb.FileServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.filepb.ListFileRequest,
 *   !proto.filepb.ListFileResponse>}
 */
const methodDescriptor_FileService_List = new grpc.web.MethodDescriptor(
  '/filepb.FileService/List',
  grpc.web.MethodType.UNARY,
  proto.filepb.ListFileRequest,
  proto.filepb.ListFileResponse,
  /**
   * @param {!proto.filepb.ListFileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.filepb.ListFileResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.filepb.ListFileRequest,
 *   !proto.filepb.ListFileResponse>}
 */
const methodInfo_FileService_List = new grpc.web.AbstractClientBase.MethodInfo(
  proto.filepb.ListFileResponse,
  /**
   * @param {!proto.filepb.ListFileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.filepb.ListFileResponse.deserializeBinary
);


/**
 * @param {!proto.filepb.ListFileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.filepb.ListFileResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.filepb.ListFileResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.filepb.FileServiceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/filepb.FileService/List',
      request,
      metadata || {},
      methodDescriptor_FileService_List,
      callback);
};


/**
 * @param {!proto.filepb.ListFileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.filepb.ListFileResponse>}
 *     Promise that resolves to the response
 */
proto.filepb.FileServicePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/filepb.FileService/List',
      request,
      metadata || {},
      methodDescriptor_FileService_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.filepb.FileRequest,
 *   !proto.filepb.File>}
 */
const methodDescriptor_FileService_Download = new grpc.web.MethodDescriptor(
  '/filepb.FileService/Download',
  grpc.web.MethodType.UNARY,
  proto.filepb.FileRequest,
  proto.filepb.File,
  /**
   * @param {!proto.filepb.FileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.filepb.File.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.filepb.FileRequest,
 *   !proto.filepb.File>}
 */
const methodInfo_FileService_Download = new grpc.web.AbstractClientBase.MethodInfo(
  proto.filepb.File,
  /**
   * @param {!proto.filepb.FileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.filepb.File.deserializeBinary
);


/**
 * @param {!proto.filepb.FileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.filepb.File)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.filepb.File>|undefined}
 *     The XHR Node Readable Stream
 */
proto.filepb.FileServiceClient.prototype.download =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/filepb.FileService/Download',
      request,
      metadata || {},
      methodDescriptor_FileService_Download,
      callback);
};


/**
 * @param {!proto.filepb.FileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.filepb.File>}
 *     Promise that resolves to the response
 */
proto.filepb.FileServicePromiseClient.prototype.download =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/filepb.FileService/Download',
      request,
      metadata || {},
      methodDescriptor_FileService_Download);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.filepb.FileRequest,
 *   !proto.filepb.Result>}
 */
const methodDescriptor_FileService_Delete = new grpc.web.MethodDescriptor(
  '/filepb.FileService/Delete',
  grpc.web.MethodType.UNARY,
  proto.filepb.FileRequest,
  proto.filepb.Result,
  /**
   * @param {!proto.filepb.FileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.filepb.Result.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.filepb.FileRequest,
 *   !proto.filepb.Result>}
 */
const methodInfo_FileService_Delete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.filepb.Result,
  /**
   * @param {!proto.filepb.FileRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.filepb.Result.deserializeBinary
);


/**
 * @param {!proto.filepb.FileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.filepb.Result)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.filepb.Result>|undefined}
 *     The XHR Node Readable Stream
 */
proto.filepb.FileServiceClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/filepb.FileService/Delete',
      request,
      metadata || {},
      methodDescriptor_FileService_Delete,
      callback);
};


/**
 * @param {!proto.filepb.FileRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.filepb.Result>}
 *     Promise that resolves to the response
 */
proto.filepb.FileServicePromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/filepb.FileService/Delete',
      request,
      metadata || {},
      methodDescriptor_FileService_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.filepb.UploadRequest,
 *   !proto.filepb.UploadResponse>}
 */
const methodDescriptor_FileService_Upload = new grpc.web.MethodDescriptor(
  '/filepb.FileService/Upload',
  grpc.web.MethodType.UNARY,
  proto.filepb.UploadRequest,
  proto.filepb.UploadResponse,
  /**
   * @param {!proto.filepb.UploadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.filepb.UploadResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.filepb.UploadRequest,
 *   !proto.filepb.UploadResponse>}
 */
const methodInfo_FileService_Upload = new grpc.web.AbstractClientBase.MethodInfo(
  proto.filepb.UploadResponse,
  /**
   * @param {!proto.filepb.UploadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.filepb.UploadResponse.deserializeBinary
);


/**
 * @param {!proto.filepb.UploadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.filepb.UploadResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.filepb.UploadResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.filepb.FileServiceClient.prototype.upload =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/filepb.FileService/Upload',
      request,
      metadata || {},
      methodDescriptor_FileService_Upload,
      callback);
};


/**
 * @param {!proto.filepb.UploadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.filepb.UploadResponse>}
 *     Promise that resolves to the response
 */
proto.filepb.FileServicePromiseClient.prototype.upload =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/filepb.FileService/Upload',
      request,
      metadata || {},
      methodDescriptor_FileService_Upload);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.filepb.StorageTypeRequest,
 *   !proto.filepb.StorageTypeResponse>}
 */
const methodDescriptor_FileService_GetStorageType = new grpc.web.MethodDescriptor(
  '/filepb.FileService/GetStorageType',
  grpc.web.MethodType.UNARY,
  proto.filepb.StorageTypeRequest,
  proto.filepb.StorageTypeResponse,
  /**
   * @param {!proto.filepb.StorageTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.filepb.StorageTypeResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.filepb.StorageTypeRequest,
 *   !proto.filepb.StorageTypeResponse>}
 */
const methodInfo_FileService_GetStorageType = new grpc.web.AbstractClientBase.MethodInfo(
  proto.filepb.StorageTypeResponse,
  /**
   * @param {!proto.filepb.StorageTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.filepb.StorageTypeResponse.deserializeBinary
);


/**
 * @param {!proto.filepb.StorageTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.filepb.StorageTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.filepb.StorageTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.filepb.FileServiceClient.prototype.getStorageType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/filepb.FileService/GetStorageType',
      request,
      metadata || {},
      methodDescriptor_FileService_GetStorageType,
      callback);
};


/**
 * @param {!proto.filepb.StorageTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.filepb.StorageTypeResponse>}
 *     Promise that resolves to the response
 */
proto.filepb.FileServicePromiseClient.prototype.getStorageType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/filepb.FileService/GetStorageType',
      request,
      metadata || {},
      methodDescriptor_FileService_GetStorageType);
};


module.exports = proto.filepb;

