import React from 'react';
import { GetAppRounded as DownloadIcon } from '@material-ui/icons';
import { IconButton, makeStyles } from '@material-ui/core';
import ClientGrid from '../../../components/ClientGrid/ClientGrid';
import ClientGrid2 from '../../../components/ClientGrid/ClientGrid2'
import Table, { columnType } from '../../../components/ClearingTable/Table';
import Button from '../../../components/Buttons/Button';
import DateTimePicker from '../../../components/Textfields/DateTimePicker';
import AutoCompleteCorrespondent from '../../../components/AutoComplete/AutoCompleteCorrespondent';
import AutoCompleteAccountNo from '../../../components/AutoComplete/AutoCompleteAccountNo';
import AutoCompleteAccountName from '../../../components/AutoComplete/AutoCompleteAccountName';
import AutoCompleteMasterAccountNo from '../../../components/AutoComplete/AutoCompleteMasterAccountNo';
import AutoCompleteBranch from '../../../components/AutoComplete/AutoCompleteBranch';
import AutoCompleteRep from '../../../components/AutoComplete/AutoCompleteRep';
import Text from '../../../components/Typography/Text';
import ReportDownloadModal from '../../../components/Modals/ReportDownloadModal';
import { notifyInfo, notifyError, notifySuccess } from '../../../components/Messages/Notification';
import { tradeConfirmColumns } from '../../../lib/data/columns';
import { listDailyTradeConfirm } from '../../../services/DailyTradeConfirmService';
import { getSystemDate } from '../../../services/ProfileService';
import QueryParam from '../../../services/QueryParamService';
import { GridTitleTradeConfirm } from '../../../components/contants/GridTitle';
import {
  CustomBodyRenderCurrency,
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderDate,
  CustomBodyRenderDateTime,
  CustomBodyRenderBoolean,
  CustomBodyRenderAlign,
  CustomBodyRenderHtmlString,
  CustomBodyRenderCenter,
} from '../../../components/Table/CustomBodyRender';
import { downloadDailyTradeConfirmation } from "../../../services/DailyTradeConfirmService";
import color from '../../../assets/css/colors'
import fontFamily from '../../../assets/css/fonts'
import { formatPbDate, formatPbDateTime } from '../../../lib/fmt';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottom:'1px solid #ccc',
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
    
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
}))

export default function TradeConfirm() {
  const classes = useStyles();

  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const [loading, setLoading] = React.useState({
    search: false,
    download: false,
  });

  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      fromDate: '',
      toDate: '',
      correspondent: '',
      branch: '',
      accountNo: '',
      masterAccountNo: '',
      rep: '',
      accountName: '',
    })
  );

  const [downloadData, setDownloadData] = React.useState({
    accountId: '',
    tradeDate: '',
  });

  const init = async () => {
    const searchParameters = localStorage.getItem('search_data');

    const {
      fromDate,
      toDate,
      correspondent,
      branch,
      accountNo,
      masterAccountNo,
      rep,
      accountName,
    } = searchParameters ? JSON.parse(searchParameters) : searchData;

    const systemDate = await getSystemDate();

    setSearchData({
      ...searchData,
      fromDate: fromDate ? fromDate : systemDate,
      toDate: toDate ? toDate : systemDate,
      correspondent: correspondent ? correspondent : '',
      branch: branch ? branch : '',
      accountNo: accountNo ? accountNo : '',
      masterAccountNo: masterAccountNo ? masterAccountNo : '',
      rep: rep ? rep : '',
      accountName: accountName ? accountName : '',
    });
  };

  React.useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  const GridButtons = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    return (
      <div align={'left'}>
        <div align={'left'} style={{ padding: 5 }}>
          <IconButton
            aria-label="download"
            disabled={loading.download}
            onClick={() =>
              handleDownloadTradeConfirm(
                formatPbDate(data.tradeDate),
                data.accountId,
                rowIndex
              )
            }
          >
            <DownloadIcon style={{ height: 24, width: 24 }} />
          </IconButton>
        </div>
      </div>
    );
  };

  const columns2 = [
    {
      id: 'checkbox',
      pinned: true,
      width: '54px',
      visible: true,
    },
    {
      id: '',
      label: '',
      width: '40px',
      cellRenderer: GridButtons,
    },
    ...tradeConfirmColumns,
  ];

  const columns = [
    {
      name: 'actions',
      label: 'Actions',
      sort: false,
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return(
            <div align={'left'}>
              <div align={'left'} style={{ padding: 5 }}>
                <IconButton
                  aria-label="download"
                  disabled={loading.download}
                  onClick={() =>
                    handleDownloadTradeConfirm(
                      rows[dataIndex].tradeDate,
                      rows[dataIndex].accountId,
                      rowIndex
                    )
                  }
                >
                  <DownloadIcon style={{ height: 24, width: 24 }} />
                </IconButton>
              </div>
            </div>
          );
        },
      },
    },
    {
      // Already converted to string in handlesearch
      name: 'tradeDate',
      label: 'Trade Date',
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'branch',
      label: 'Branch',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'accountNo',
      label: 'Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'rep',
      label: 'Rep/Advisor',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'trnsCount',
      label: 'TRNS Count',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
  ];

  const options = {
    responsive: 'standard',
    customToolbarSelect: (selectedRows) => {
      return (
        <div className={classes.grdRow} style={{ marginRight: 30, padding: 0 }}>
          <div className={classes.grdCellNone}>
            <Button
              id="batch"
              disabled={loading.search}
              loading={loading.search}
              type="search"
              label={'BATCH DOWNLOAD'}
              onClick={() => {
                handleBatchDownloadTradeConfirm(
                  selectedRows.data.map((d) => d.dataIndex)
                );
              }}
            />
          </div>
        </div>
      );
    },
  };

  const handleSearchDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setSearchData({ ...searchData, [input.name]: input.value });
  };

  const handleSearch = async () => {
    try {
      setLoading({ ...loading, search: true });

      QueryParam.set(searchData);

      localStorage.setItem('search_data', JSON.stringify(searchData));

      const data = await listDailyTradeConfirm(searchData);

      let rowsCopy = data.dailyTradeConfirmationsList;

      const rowsCopyOutput = rowsCopy.map((data) => {
        let tempDate = formatPbDate(data.tradeDate);
        data.tradeDate = tempDate

        return data;
      })

      setRows(rowsCopyOutput);

      notifyInfo(
        data.dailyTradeConfirmationsList.length > 0
          ? data.dailyTradeConfirmationsList.length + ' search result(s)'
          : 'No records found.'
      );
    } catch (error) {
      setLoading({ ...loading, search: false });
      notifyError(error.message);
      console.log(error);
    } finally {
      setLoading({ ...loading, search: false });
    }
  };

  const handleDownloadTradeConfirm = async (tradeDate, accountId, dataIndex) => {
    setOpen(true);

    setDownloadData({
      accountId: accountId,
      tradeDate: tradeDate,
      dataIndex: dataIndex,
    });
  };

  const handleBatchDownloadTradeConfirm = async (selected) => {
    const selectedIds = [];
    const selectedTradeDates = [];

    selected.forEach((data) => {
      selectedIds.push(rows[data].accountId)
      selectedTradeDates.push(rows[data].tradeDate)
    });

    try {
      for(let i = 0; i < selectedIds.length; i++) {
        await downloadDailyTradeConfirmation({ tradeDate: selectedTradeDates[i], accountId: selectedIds[i]});
      }
      notifySuccess('Statement Download is Completed');
    } catch (error) {
      console.error(error);
      notifyError(error.message);
    }
  };

  const getCsvData = async () => {
    const data = await listDailyTradeConfirm(searchData);

    return data.dailyTradeConfirmationsList;
  };

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Daily Trade Confirm" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading.search}
              loading={loading.search}
              type="search"
              label={loading.search ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell-none">
            <DateTimePicker
              type="date"
              name="fromDate"
              label="From Date"
              value={searchData.fromDate}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell-none">
            <DateTimePicker
              type="date"
              name="toDate"
              label="To Date"
              value={searchData.toDate}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteCorrespondent
              isAccessibleOnly={true}
              name="correspondent"
              value={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteBranch
              isAccessibleOnly={true}
              name="branch"
              value={searchData.branch}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteAccountNo
              isAccessibleOnly={true}
              name="accountNo"
              value={searchData.accountNo}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteMasterAccountNo
              isAccessibleOnly={true}
              name="masterAccountNo"
              value={searchData.masterAccountNo}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteRep
              isAccessibleOnly={true}
              name="rep"
              value={searchData.rep}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteAccountName
              isAccessibleOnly={true}
              name="accountName"
              value={searchData.accountName}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
        </div>
      </div>
      <div className="mt-20">
        {/* <ClientGrid
          title={GridTitleTradeConfirm}
          rowIdField="accountId"
          columns={columns2}
          rows={rows}
          getCsvData={getCsvData}
          isLoading={loading}
          setRows={setRows}
          showDownloadBatchAction={true}
        /> */}
        {/* <ClientGrid2
          title="Trade Confirm"
          data={rows}
          columns={columns}
          options={options}
        /> */}
        <Table
          title={'Statement'}
          data={rows}
          columns={columns}
          options={options}
        />
      </div>
      <ReportDownloadModal
        title={GridTitleTradeConfirm}
        selectedIds={[downloadData.accountId]}
        rows={rows}
        parameters={downloadData}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </div>
  );
}
