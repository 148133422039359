import {
  StatementServiceClient,
  ListStatementRequest,
  DownloadStatementRequest,
  BatchDownloadStatementRequest,
} from "../proto/reportpb/statement_grpc_web_pb";
import download from './DownloadService';
import authSvc from './AuthService';
import { auth } from "../lib/auth/Auth";

const service = new StatementServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });

const userId = authSvc.getCurrentUser()?.UserId;

export async function listStatement(param) {
  return new Promise((resolve, reject) => {
    let req = new ListStatementRequest();
    req.setMonth(param.month.toUpperCase());
    req.setYear(param.year);
    req.setCorrespondent(param.correspondent);
    req.setBranch(param.branch);
    req.setAccountNo(param.accountNo);
    req.setAccountName(param.accountName);
    req.setMasterAccountNo(param.masterAccountNo);
    req.setRep(param.rep);
    req.setUsrId(userId);

    service.listStatement(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function downloadMonthlyStatement(accountId, params) {
  return new Promise((resolve, reject) => {
    let req = new DownloadStatementRequest();
    req.setMonth(params.month.toUpperCase());
    req.setYear(params.year);
    req.setAccountId(accountId);

    service.downloadStatement(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        download(response);
        resolve(response.toObject());
      }
    });
  });
}

export async function batchDownloadMonthlyStatement(accountIds, params) {
  return new Promise((resolve, reject) => {
    let req = new BatchDownloadStatementRequest();
    req.setMonth(params.month);
    req.setYear(params.year);
    req.setAccountIdsList(accountIds);

    service.batchDownloadStatement(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        download(response);
        resolve(response.toObject());
      }
    });
  });
}