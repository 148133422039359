import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Modal, Backdrop, Fade } from "@material-ui/core"
import Text from "../Typography/Text"
import Button from "../Buttons/Button"
import CloseButton from "../Buttons/CloseButton"
import fontFamily from "../../assets/css/fonts"
import color from "../../assets/css/colors"
import { readDisclosure, downloadBlankReport } from "../../services/MarginDisclosureService"
import { getCorrespondentByUrl } from '../../services/TemplateService';

const useStyles = makeStyles(() => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		maxWidth: 800,
		width: "100%",
		margin: "0 auto",
		float: "clear",
	},
	modalHeader: {
		backgroundColor: "white",
		padding: 25,
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
		display: "flex",
		"& div:nth-child(1)": {
			flex: 1,
		},
		"& div:nth-child(2)": {
			flex: "none",
			"& .MuiIconButton-root": {
				borderRadius: 5,
				padding: 10,
				"&:hover svg path": {
					fill: color.primary.main,
				},
			},
			"& svg:last-child": {
				width: 15,
				height: 15,
			},
		},
	},
	modalBody: {
		backgroundColor: "white",
		padding: "0px 30px",
		maxHeight: 600,
		overflowY: "auto",
		fontFamily: fontFamily.headers,
		color: color.default.main,
		wordBreak: "break-word",
		"& p": {
			fontSize: 16,
			fontFamily: fontFamily.headers,
			fontWeight: 400,
			lineHeight: "26px",
			color: color.default.main,
			marginTop: 0,
		},
		"& hr": {
			margin: "30px 0px",
		},
	},
	modalFooter: {
		display: "flex",
		alignItems: "flex-end",
		justifyContent: "flex-end",
		backgroundColor: "white",
		padding: 25,
		borderBottomLeftRadius: 10,
		borderBottomRightRadius: 10,
		position: "relative",
		"& button": {
			width: "auto",
		},
	},
	closeButton: {
		"& button": {
			marginTop: "-4px !important",
		},
	},
}))

export default function MarginDisclosureModal(props) {
	const classes = useStyles()
	const [disclosure, setDisclosure] = React.useState("")
	const [loading, setLoading] = React.useState({
		download: false,
	})
	const modalData = {
		correspondent: "SASS",
		reportType: "Margin Disclosure",
	}

	const { open, close, setTradingData, tradingData, } = props
	useEffect(() => {
		async function init() {
			let url = window.location.origin;
			//get correspondent
			const serverCorrespondent = await getCorrespondentByUrl(url);
			let correspondent = serverCorrespondent.correspondent.correspondent;

			const data = await readDisclosure("Margin Disclosure", correspondent)
			setDisclosure(data.disclosure.disclosure)
		}

		init()
	}, [])

	const handleClose = () => {
		close()
	}

	const acknowledgeMarginDisclosureStatement = () => {
		try {
			setLoading({ ...loading, accept: true });

			setTradingData({ ...tradingData, readMarginDisclosureStatement: true });
			close();
		} catch (error) {
			console.log(error?.message);
		} finally {
			setLoading({ ...loading, accept: false });
		}
	}

	return (
		<Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" className={classes.modal} open={open} onClose={close} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
			<Fade in={open}>
				<div style={{ width: 800 }}>
					<div className={classes.modalHeader}>
						<div>
							<Text mt={3} variant="h2" label="Disclosure" />
						</div>

						<div className={classes.closeButton}>
							<CloseButton onClick={handleClose} />
						</div>
					</div>
					<div className={classes.modalBody}>
						{disclosure !== "" ? (
							<div dangerouslySetInnerHTML={{ __html: disclosure }}></div>
						) : (
							<div style={{ width: "100%", textAlign: "center", padding: "30px 0px" }}>
								<p>
									{loading.disclosure
										? 'Fetching margin disclosure. Please wait...'
										: 'No Margin Disclosure Found.'}
								</p>
							</div>
						)}
					</div>
					<div className={classes.modalFooter}>
						<div style={{ marginRight: 10 }}>
							<Button
								style={{ marginRight: 10 }}
								loading={loading.download}
								color="secondary"
								label={loading.download ? "Downloading..." : "Print PDF"}
								onClick={async () => {
									setLoading({ download: true })
									await downloadBlankReport({ ...modalData }, disclosure)
									setLoading({ download: false })
								}}
							/>
						</div>
						<div style={{ marginRight: 10 }}>
							<Button label="Close" onClick={handleClose} />
						</div>
						{tradingData.readMarginDisclosureStatement === false && disclosure.trim() !== '' ? (
							<div className={classes.buttons}>
								<Button
									disabled={loading.accept || loading.disclosure || props.disabled}
									label="I Accept"
									onClick={acknowledgeMarginDisclosureStatement}
								/>
							</div>
						) : (
							<div className={classes.buttons}>
								<div style={{ marginRight: 10 }}>
									<Button
										disabled
										label={tradingData.readMarginDisclosureStatement === true ? 'Accepted' : disclosure.trim() === '' ? 'I Accept' : ''}
										onClick={acknowledgeMarginDisclosureStatement}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			</Fade>
		</Modal>
	)
}
