// source: proto/admpb/fielddefinition.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.admpb.CreateFieldDefinitionRequest', null, global);
goog.exportSymbol('proto.admpb.CreateFieldDefinitionResponse', null, global);
goog.exportSymbol('proto.admpb.DeleteFieldDefinitionRequest', null, global);
goog.exportSymbol('proto.admpb.FieldDefinition', null, global);
goog.exportSymbol('proto.admpb.ListFieldDefinitionRequest', null, global);
goog.exportSymbol('proto.admpb.ListFieldDefinitionResponse', null, global);
goog.exportSymbol('proto.admpb.ReadFieldDefinitionRequest', null, global);
goog.exportSymbol('proto.admpb.ReadFieldDefinitionResponse', null, global);
goog.exportSymbol('proto.admpb.UpdateFieldDefinitionRequest', null, global);
goog.exportSymbol('proto.admpb.UpdateFieldDefinitionResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.FieldDefinition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.FieldDefinition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.FieldDefinition.displayName = 'proto.admpb.FieldDefinition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.ListFieldDefinitionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.ListFieldDefinitionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.ListFieldDefinitionRequest.displayName = 'proto.admpb.ListFieldDefinitionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.ListFieldDefinitionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admpb.ListFieldDefinitionResponse.repeatedFields_, null);
};
goog.inherits(proto.admpb.ListFieldDefinitionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.ListFieldDefinitionResponse.displayName = 'proto.admpb.ListFieldDefinitionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.CreateFieldDefinitionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.CreateFieldDefinitionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.CreateFieldDefinitionRequest.displayName = 'proto.admpb.CreateFieldDefinitionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.CreateFieldDefinitionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.CreateFieldDefinitionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.CreateFieldDefinitionResponse.displayName = 'proto.admpb.CreateFieldDefinitionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.UpdateFieldDefinitionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.UpdateFieldDefinitionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.UpdateFieldDefinitionRequest.displayName = 'proto.admpb.UpdateFieldDefinitionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.UpdateFieldDefinitionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.UpdateFieldDefinitionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.UpdateFieldDefinitionResponse.displayName = 'proto.admpb.UpdateFieldDefinitionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.ReadFieldDefinitionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.ReadFieldDefinitionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.ReadFieldDefinitionRequest.displayName = 'proto.admpb.ReadFieldDefinitionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.ReadFieldDefinitionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admpb.ReadFieldDefinitionResponse.repeatedFields_, null);
};
goog.inherits(proto.admpb.ReadFieldDefinitionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.ReadFieldDefinitionResponse.displayName = 'proto.admpb.ReadFieldDefinitionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.DeleteFieldDefinitionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.DeleteFieldDefinitionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.DeleteFieldDefinitionRequest.displayName = 'proto.admpb.DeleteFieldDefinitionRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.FieldDefinition.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.FieldDefinition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.FieldDefinition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.FieldDefinition.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldDefinitionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userGuideId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fieldId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    ordinalPosition: jspb.Message.getFieldWithDefault(msg, 4, 0),
    field: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dataType: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sampleValue: jspb.Message.getFieldWithDefault(msg, 7, ""),
    description: jspb.Message.getFieldWithDefault(msg, 8, ""),
    selected: jspb.Message.getFieldWithDefault(msg, 9, 0),
    type: jspb.Message.getFieldWithDefault(msg, 10, ""),
    msg: jspb.Message.getFieldWithDefault(msg, 11, ""),
    pageName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    menu: jspb.Message.getFieldWithDefault(msg, 13, ""),
    subMenu: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.FieldDefinition}
 */
proto.admpb.FieldDefinition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.FieldDefinition;
  return proto.admpb.FieldDefinition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.FieldDefinition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.FieldDefinition}
 */
proto.admpb.FieldDefinition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFieldDefinitionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserGuideId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFieldId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrdinalPosition(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDataType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSampleValue(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelected(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setMsg(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageName(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setMenu(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubMenu(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.FieldDefinition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.FieldDefinition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.FieldDefinition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.FieldDefinition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldDefinitionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUserGuideId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getFieldId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getOrdinalPosition();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDataType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSampleValue();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSelected();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMsg();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPageName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getMenu();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSubMenu();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional uint32 field_definition_id = 1;
 * @return {number}
 */
proto.admpb.FieldDefinition.prototype.getFieldDefinitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.FieldDefinition} returns this
 */
proto.admpb.FieldDefinition.prototype.setFieldDefinitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 user_guide_id = 2;
 * @return {number}
 */
proto.admpb.FieldDefinition.prototype.getUserGuideId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.FieldDefinition} returns this
 */
proto.admpb.FieldDefinition.prototype.setUserGuideId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 field_id = 3;
 * @return {number}
 */
proto.admpb.FieldDefinition.prototype.getFieldId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.FieldDefinition} returns this
 */
proto.admpb.FieldDefinition.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 ordinal_position = 4;
 * @return {number}
 */
proto.admpb.FieldDefinition.prototype.getOrdinalPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.FieldDefinition} returns this
 */
proto.admpb.FieldDefinition.prototype.setOrdinalPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string field = 5;
 * @return {string}
 */
proto.admpb.FieldDefinition.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.FieldDefinition} returns this
 */
proto.admpb.FieldDefinition.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string data_type = 6;
 * @return {string}
 */
proto.admpb.FieldDefinition.prototype.getDataType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.FieldDefinition} returns this
 */
proto.admpb.FieldDefinition.prototype.setDataType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string sample_value = 7;
 * @return {string}
 */
proto.admpb.FieldDefinition.prototype.getSampleValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.FieldDefinition} returns this
 */
proto.admpb.FieldDefinition.prototype.setSampleValue = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string description = 8;
 * @return {string}
 */
proto.admpb.FieldDefinition.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.FieldDefinition} returns this
 */
proto.admpb.FieldDefinition.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional uint32 selected = 9;
 * @return {number}
 */
proto.admpb.FieldDefinition.prototype.getSelected = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.FieldDefinition} returns this
 */
proto.admpb.FieldDefinition.prototype.setSelected = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string type = 10;
 * @return {string}
 */
proto.admpb.FieldDefinition.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.FieldDefinition} returns this
 */
proto.admpb.FieldDefinition.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string msg = 11;
 * @return {string}
 */
proto.admpb.FieldDefinition.prototype.getMsg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.FieldDefinition} returns this
 */
proto.admpb.FieldDefinition.prototype.setMsg = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string page_name = 12;
 * @return {string}
 */
proto.admpb.FieldDefinition.prototype.getPageName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.FieldDefinition} returns this
 */
proto.admpb.FieldDefinition.prototype.setPageName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string menu = 13;
 * @return {string}
 */
proto.admpb.FieldDefinition.prototype.getMenu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.FieldDefinition} returns this
 */
proto.admpb.FieldDefinition.prototype.setMenu = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string sub_menu = 14;
 * @return {string}
 */
proto.admpb.FieldDefinition.prototype.getSubMenu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.FieldDefinition} returns this
 */
proto.admpb.FieldDefinition.prototype.setSubMenu = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.ListFieldDefinitionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.ListFieldDefinitionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.ListFieldDefinitionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ListFieldDefinitionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldDefinitionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userGuideId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    field: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.ListFieldDefinitionRequest}
 */
proto.admpb.ListFieldDefinitionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.ListFieldDefinitionRequest;
  return proto.admpb.ListFieldDefinitionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.ListFieldDefinitionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.ListFieldDefinitionRequest}
 */
proto.admpb.ListFieldDefinitionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFieldDefinitionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserGuideId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.ListFieldDefinitionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.ListFieldDefinitionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.ListFieldDefinitionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ListFieldDefinitionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldDefinitionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUserGuideId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 field_definition_id = 1;
 * @return {number}
 */
proto.admpb.ListFieldDefinitionRequest.prototype.getFieldDefinitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.ListFieldDefinitionRequest} returns this
 */
proto.admpb.ListFieldDefinitionRequest.prototype.setFieldDefinitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 user_guide_id = 2;
 * @return {number}
 */
proto.admpb.ListFieldDefinitionRequest.prototype.getUserGuideId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.ListFieldDefinitionRequest} returns this
 */
proto.admpb.ListFieldDefinitionRequest.prototype.setUserGuideId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string field = 3;
 * @return {string}
 */
proto.admpb.ListFieldDefinitionRequest.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.ListFieldDefinitionRequest} returns this
 */
proto.admpb.ListFieldDefinitionRequest.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admpb.ListFieldDefinitionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.ListFieldDefinitionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.ListFieldDefinitionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.ListFieldDefinitionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ListFieldDefinitionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldDefinitionsList: jspb.Message.toObjectList(msg.getFieldDefinitionsList(),
    proto.admpb.FieldDefinition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.ListFieldDefinitionResponse}
 */
proto.admpb.ListFieldDefinitionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.ListFieldDefinitionResponse;
  return proto.admpb.ListFieldDefinitionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.ListFieldDefinitionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.ListFieldDefinitionResponse}
 */
proto.admpb.ListFieldDefinitionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admpb.FieldDefinition;
      reader.readMessage(value,proto.admpb.FieldDefinition.deserializeBinaryFromReader);
      msg.addFieldDefinitions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.ListFieldDefinitionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.ListFieldDefinitionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.ListFieldDefinitionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ListFieldDefinitionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldDefinitionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.admpb.FieldDefinition.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FieldDefinition field_definitions = 1;
 * @return {!Array<!proto.admpb.FieldDefinition>}
 */
proto.admpb.ListFieldDefinitionResponse.prototype.getFieldDefinitionsList = function() {
  return /** @type{!Array<!proto.admpb.FieldDefinition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.admpb.FieldDefinition, 1));
};


/**
 * @param {!Array<!proto.admpb.FieldDefinition>} value
 * @return {!proto.admpb.ListFieldDefinitionResponse} returns this
*/
proto.admpb.ListFieldDefinitionResponse.prototype.setFieldDefinitionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.admpb.FieldDefinition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.admpb.FieldDefinition}
 */
proto.admpb.ListFieldDefinitionResponse.prototype.addFieldDefinitions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.admpb.FieldDefinition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admpb.ListFieldDefinitionResponse} returns this
 */
proto.admpb.ListFieldDefinitionResponse.prototype.clearFieldDefinitionsList = function() {
  return this.setFieldDefinitionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.CreateFieldDefinitionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.CreateFieldDefinitionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.CreateFieldDefinitionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.CreateFieldDefinitionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userGuideId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fieldId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.CreateFieldDefinitionRequest}
 */
proto.admpb.CreateFieldDefinitionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.CreateFieldDefinitionRequest;
  return proto.admpb.CreateFieldDefinitionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.CreateFieldDefinitionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.CreateFieldDefinitionRequest}
 */
proto.admpb.CreateFieldDefinitionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserGuideId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFieldId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.CreateFieldDefinitionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.CreateFieldDefinitionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.CreateFieldDefinitionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.CreateFieldDefinitionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserGuideId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getFieldId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 user_guide_id = 1;
 * @return {number}
 */
proto.admpb.CreateFieldDefinitionRequest.prototype.getUserGuideId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.CreateFieldDefinitionRequest} returns this
 */
proto.admpb.CreateFieldDefinitionRequest.prototype.setUserGuideId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 field_id = 2;
 * @return {number}
 */
proto.admpb.CreateFieldDefinitionRequest.prototype.getFieldId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.CreateFieldDefinitionRequest} returns this
 */
proto.admpb.CreateFieldDefinitionRequest.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.CreateFieldDefinitionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.CreateFieldDefinitionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.CreateFieldDefinitionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.CreateFieldDefinitionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldDefinition: (f = msg.getFieldDefinition()) && proto.admpb.FieldDefinition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.CreateFieldDefinitionResponse}
 */
proto.admpb.CreateFieldDefinitionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.CreateFieldDefinitionResponse;
  return proto.admpb.CreateFieldDefinitionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.CreateFieldDefinitionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.CreateFieldDefinitionResponse}
 */
proto.admpb.CreateFieldDefinitionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admpb.FieldDefinition;
      reader.readMessage(value,proto.admpb.FieldDefinition.deserializeBinaryFromReader);
      msg.setFieldDefinition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.CreateFieldDefinitionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.CreateFieldDefinitionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.CreateFieldDefinitionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.CreateFieldDefinitionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldDefinition();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admpb.FieldDefinition.serializeBinaryToWriter
    );
  }
};


/**
 * optional FieldDefinition field_definition = 1;
 * @return {?proto.admpb.FieldDefinition}
 */
proto.admpb.CreateFieldDefinitionResponse.prototype.getFieldDefinition = function() {
  return /** @type{?proto.admpb.FieldDefinition} */ (
    jspb.Message.getWrapperField(this, proto.admpb.FieldDefinition, 1));
};


/**
 * @param {?proto.admpb.FieldDefinition|undefined} value
 * @return {!proto.admpb.CreateFieldDefinitionResponse} returns this
*/
proto.admpb.CreateFieldDefinitionResponse.prototype.setFieldDefinition = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admpb.CreateFieldDefinitionResponse} returns this
 */
proto.admpb.CreateFieldDefinitionResponse.prototype.clearFieldDefinition = function() {
  return this.setFieldDefinition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admpb.CreateFieldDefinitionResponse.prototype.hasFieldDefinition = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.UpdateFieldDefinitionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.UpdateFieldDefinitionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.UpdateFieldDefinitionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.UpdateFieldDefinitionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldDefinitionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userGuideId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    ordinalPosition: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sampleValue: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.UpdateFieldDefinitionRequest}
 */
proto.admpb.UpdateFieldDefinitionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.UpdateFieldDefinitionRequest;
  return proto.admpb.UpdateFieldDefinitionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.UpdateFieldDefinitionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.UpdateFieldDefinitionRequest}
 */
proto.admpb.UpdateFieldDefinitionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFieldDefinitionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserGuideId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrdinalPosition(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSampleValue(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.UpdateFieldDefinitionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.UpdateFieldDefinitionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.UpdateFieldDefinitionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.UpdateFieldDefinitionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldDefinitionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUserGuideId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getOrdinalPosition();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getSampleValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional uint32 field_definition_id = 1;
 * @return {number}
 */
proto.admpb.UpdateFieldDefinitionRequest.prototype.getFieldDefinitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.UpdateFieldDefinitionRequest} returns this
 */
proto.admpb.UpdateFieldDefinitionRequest.prototype.setFieldDefinitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 user_guide_id = 2;
 * @return {number}
 */
proto.admpb.UpdateFieldDefinitionRequest.prototype.getUserGuideId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.UpdateFieldDefinitionRequest} returns this
 */
proto.admpb.UpdateFieldDefinitionRequest.prototype.setUserGuideId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 ordinal_position = 3;
 * @return {number}
 */
proto.admpb.UpdateFieldDefinitionRequest.prototype.getOrdinalPosition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.UpdateFieldDefinitionRequest} returns this
 */
proto.admpb.UpdateFieldDefinitionRequest.prototype.setOrdinalPosition = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string sample_value = 4;
 * @return {string}
 */
proto.admpb.UpdateFieldDefinitionRequest.prototype.getSampleValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.UpdateFieldDefinitionRequest} returns this
 */
proto.admpb.UpdateFieldDefinitionRequest.prototype.setSampleValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.admpb.UpdateFieldDefinitionRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.UpdateFieldDefinitionRequest} returns this
 */
proto.admpb.UpdateFieldDefinitionRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.UpdateFieldDefinitionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.UpdateFieldDefinitionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.UpdateFieldDefinitionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.UpdateFieldDefinitionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldDefinition: (f = msg.getFieldDefinition()) && proto.admpb.FieldDefinition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.UpdateFieldDefinitionResponse}
 */
proto.admpb.UpdateFieldDefinitionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.UpdateFieldDefinitionResponse;
  return proto.admpb.UpdateFieldDefinitionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.UpdateFieldDefinitionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.UpdateFieldDefinitionResponse}
 */
proto.admpb.UpdateFieldDefinitionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admpb.FieldDefinition;
      reader.readMessage(value,proto.admpb.FieldDefinition.deserializeBinaryFromReader);
      msg.setFieldDefinition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.UpdateFieldDefinitionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.UpdateFieldDefinitionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.UpdateFieldDefinitionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.UpdateFieldDefinitionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldDefinition();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admpb.FieldDefinition.serializeBinaryToWriter
    );
  }
};


/**
 * optional FieldDefinition field_definition = 1;
 * @return {?proto.admpb.FieldDefinition}
 */
proto.admpb.UpdateFieldDefinitionResponse.prototype.getFieldDefinition = function() {
  return /** @type{?proto.admpb.FieldDefinition} */ (
    jspb.Message.getWrapperField(this, proto.admpb.FieldDefinition, 1));
};


/**
 * @param {?proto.admpb.FieldDefinition|undefined} value
 * @return {!proto.admpb.UpdateFieldDefinitionResponse} returns this
*/
proto.admpb.UpdateFieldDefinitionResponse.prototype.setFieldDefinition = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admpb.UpdateFieldDefinitionResponse} returns this
 */
proto.admpb.UpdateFieldDefinitionResponse.prototype.clearFieldDefinition = function() {
  return this.setFieldDefinition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admpb.UpdateFieldDefinitionResponse.prototype.hasFieldDefinition = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.ReadFieldDefinitionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.ReadFieldDefinitionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.ReadFieldDefinitionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ReadFieldDefinitionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldDefinitionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userGuideId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    field: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.ReadFieldDefinitionRequest}
 */
proto.admpb.ReadFieldDefinitionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.ReadFieldDefinitionRequest;
  return proto.admpb.ReadFieldDefinitionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.ReadFieldDefinitionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.ReadFieldDefinitionRequest}
 */
proto.admpb.ReadFieldDefinitionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFieldDefinitionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserGuideId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.ReadFieldDefinitionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.ReadFieldDefinitionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.ReadFieldDefinitionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ReadFieldDefinitionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldDefinitionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUserGuideId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 field_definition_id = 1;
 * @return {number}
 */
proto.admpb.ReadFieldDefinitionRequest.prototype.getFieldDefinitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.ReadFieldDefinitionRequest} returns this
 */
proto.admpb.ReadFieldDefinitionRequest.prototype.setFieldDefinitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 user_guide_id = 2;
 * @return {number}
 */
proto.admpb.ReadFieldDefinitionRequest.prototype.getUserGuideId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.ReadFieldDefinitionRequest} returns this
 */
proto.admpb.ReadFieldDefinitionRequest.prototype.setUserGuideId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string field = 3;
 * @return {string}
 */
proto.admpb.ReadFieldDefinitionRequest.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.ReadFieldDefinitionRequest} returns this
 */
proto.admpb.ReadFieldDefinitionRequest.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admpb.ReadFieldDefinitionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.ReadFieldDefinitionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.ReadFieldDefinitionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.ReadFieldDefinitionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ReadFieldDefinitionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldDefinitionsList: jspb.Message.toObjectList(msg.getFieldDefinitionsList(),
    proto.admpb.FieldDefinition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.ReadFieldDefinitionResponse}
 */
proto.admpb.ReadFieldDefinitionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.ReadFieldDefinitionResponse;
  return proto.admpb.ReadFieldDefinitionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.ReadFieldDefinitionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.ReadFieldDefinitionResponse}
 */
proto.admpb.ReadFieldDefinitionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admpb.FieldDefinition;
      reader.readMessage(value,proto.admpb.FieldDefinition.deserializeBinaryFromReader);
      msg.addFieldDefinitions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.ReadFieldDefinitionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.ReadFieldDefinitionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.ReadFieldDefinitionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ReadFieldDefinitionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldDefinitionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.admpb.FieldDefinition.serializeBinaryToWriter
    );
  }
};


/**
 * repeated FieldDefinition field_definitions = 1;
 * @return {!Array<!proto.admpb.FieldDefinition>}
 */
proto.admpb.ReadFieldDefinitionResponse.prototype.getFieldDefinitionsList = function() {
  return /** @type{!Array<!proto.admpb.FieldDefinition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.admpb.FieldDefinition, 1));
};


/**
 * @param {!Array<!proto.admpb.FieldDefinition>} value
 * @return {!proto.admpb.ReadFieldDefinitionResponse} returns this
*/
proto.admpb.ReadFieldDefinitionResponse.prototype.setFieldDefinitionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.admpb.FieldDefinition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.admpb.FieldDefinition}
 */
proto.admpb.ReadFieldDefinitionResponse.prototype.addFieldDefinitions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.admpb.FieldDefinition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admpb.ReadFieldDefinitionResponse} returns this
 */
proto.admpb.ReadFieldDefinitionResponse.prototype.clearFieldDefinitionsList = function() {
  return this.setFieldDefinitionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.DeleteFieldDefinitionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.DeleteFieldDefinitionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.DeleteFieldDefinitionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.DeleteFieldDefinitionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldDefinitionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userGuideId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fieldId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.DeleteFieldDefinitionRequest}
 */
proto.admpb.DeleteFieldDefinitionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.DeleteFieldDefinitionRequest;
  return proto.admpb.DeleteFieldDefinitionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.DeleteFieldDefinitionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.DeleteFieldDefinitionRequest}
 */
proto.admpb.DeleteFieldDefinitionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFieldDefinitionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserGuideId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFieldId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.DeleteFieldDefinitionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.DeleteFieldDefinitionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.DeleteFieldDefinitionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.DeleteFieldDefinitionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldDefinitionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUserGuideId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getFieldId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 field_definition_id = 1;
 * @return {number}
 */
proto.admpb.DeleteFieldDefinitionRequest.prototype.getFieldDefinitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.DeleteFieldDefinitionRequest} returns this
 */
proto.admpb.DeleteFieldDefinitionRequest.prototype.setFieldDefinitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 user_guide_id = 2;
 * @return {number}
 */
proto.admpb.DeleteFieldDefinitionRequest.prototype.getUserGuideId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.DeleteFieldDefinitionRequest} returns this
 */
proto.admpb.DeleteFieldDefinitionRequest.prototype.setUserGuideId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 field_id = 3;
 * @return {number}
 */
proto.admpb.DeleteFieldDefinitionRequest.prototype.getFieldId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.admpb.DeleteFieldDefinitionRequest} returns this
 */
proto.admpb.DeleteFieldDefinitionRequest.prototype.setFieldId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


goog.object.extend(exports, proto.admpb);
