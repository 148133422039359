import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Text from '../Typography/Text';
import color from '../../assets/css/colors';
import fontFamily from '../../assets/css/fonts';
import {ReactComponent as Scale} from '../../assets/icons/scale.svg';
import {ReactComponent as Money} from '../../assets/icons/money.svg';
import {ReactComponent as Check} from '../../assets/icons/check.svg';
import {ReactComponent as Cross} from '../../assets/icons/x-out.svg';
import { formatCurrency, formatQty } from '../../lib/fmt';

const useStyles = makeStyles(() => ({
    radioGroupWrapper: {
        display: 'flex',
        '& label:last-child': {
            marginRight: 0,
        },
        '& svg': {
            width: 50,
            height: 50,
            display: 'block',
            marginRight: 5,
            '& path': {
                fill: color.default.main,
            }
        },
    },
    radioButton: {
        marginRight: 15,
        border: '1px solid ' + color.gray.opacity,
        borderRadius: 7,
        cursor: 'pointer',
        flex: 1,
        '& .grd-row': {
            padding: '20px 25px 0px 25px',
            borderTopLeftRadius: 7,
            borderTopRightRadius: 7,
        },
        '& label, li, span': {
            fontFamily: fontFamily.label,
            color: color.default.main,
            display: 'block',
            cursor: 'pointer',
        },
        '& span': {
            fontWeight: 600,
        },
        '& input': {
            display: 'none',
        },
        '& input[type="radio"]:checked ~ div': {
            backgroundColor: color.primary.main,
        },
        '& input[type="radio"]:checked + div div label, input[type="radio"]:checked + div div span': {
            color: color.light.dark,
        },
        '& input[type="radio"]:checked + div div svg path': {
            fill: color.light.dark,
        },
        '& input[type="radio"]:checked ~ div li': {
            color: color.light.dark,
        },
        '& input[type="radio"]:checked ~ div li svg path': {
            fill: color.light.dark,
        },
        '&:hover': {
            border: '1px solid ' + color.primary.main,
            '& label, span': {
                color: color.primary.main,
            },
            '& svg path': {
                fill: color.primary.main,
            },
        },
        maxWidth: '400px',
        minHeight: '268px',
    },
    radioGroupLabel: {
        fontWeight: 600,
        color: color.default.main,
        lineHeight: '17px',
        display: 'flex',
        width: '100%',
        marginBottom: 10,
        '& h6': {
            fontWeight: 600,
            color: color.default.main,
            lineHeight: '17px',
        },
        '& .grd-cell-none > h6': {
            fontSize: 13,
            fontStyle: 'italic',
            lineHeight: '19px',
        }
    },
    radioLabel: {
        fontSize: 22,
        fontWeight: 600,
        color: color.default.main,
        marginBottom: 4,
    },
    info: {
        fontSize: 14,
        fontWeight: 500,
        padding: '20px 25px 20px 25px',
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
        '& ul': {
            listStyle: 'none',
            margin: '0px 0px 0px -40px',
        },
        '& ul > li': {
            lineHeight: '23px',
        },
        '& ul > li > svg': {
            width: 19,
            height: 19,
            marginRight: 3,
            float: 'left',
            position: 'relative',
            top: 2,
        }
    },
}));

export default function MarginTypeRadioGroup(props) {
  const classes = useStyles();

  // Set amounts
  const marginAmt = formatCurrency(props.correspondent?.marginMinAmt);
  const cashAmt = props.correspondent?.cashMinAmt > 0 ? formatCurrency(props.correspondent?.cashMinAmt) : "No min & max amount";

  return (
    <FormControl
        fullWidth
        style={{marginTop: props.mt ? props.mt : 0, marginBottom: props.mb ? props.mb : 0}}
        component="fieldset"
    >
        <div className={classes.radioGroupLabel}>
            <div className="grd-cell">
                <Text
                    required={props.required}
                    error={props.error}
                    variant="subtitle2"
                    label={props.label}
                />
            </div>
            {props.error && (
                <div className="grd-cell-none">
                    <Text
                        error={props.error}
                        variant="subtitle2"
                        label={props.msg}
                    />
                </div>
            )}
        </div>
        <div className={classes.radioGroupWrapper}>
            {props.options.map((value, index) => {
                return (
                    <label className={classes.radioButton} key={index}>
                        <input id={value} type="radio" name={props.name} value={value} onChange={props.onChange} checked={props.value === value}/>
                        {value === 'Margin Account' && props.correspondent.isMargin ? (
                            <div className="grd-row nm">
                                <div className="grd-cell-none"><Scale/></div>
                                <div className="grd-cell">
                                    <label className={classes.radioLabel} htmlFor={value}>{value}</label>
                                    <span>{marginAmt}</span>
                                </div>
                            </div>
                        ) : (
                            <div className="grd-row nm">
                                <div className="grd-cell-none"><Money/></div>
                                <div className="grd-cell">
                                    <label className={classes.radioLabel} htmlFor={value}>{value}</label>
                                    <span>{cashAmt}</span>
                                </div>
                            </div>
                        )}
                        {value === 'Margin Account' && props.correspondent.isMargin ? (
                            <div className={classes.info}>
                                <div>
                                    <ul>
                                        <li><Check/> Short stocks</li>
                                        <li><Check/> 2x equity for standard margin accounts</li>
                                    </ul>
                                    <ul>
                                        <li><b>Day Trading Buying Power</b></li>
                                        <li><Check/> 4x Equity for Pattern Day Trader accounts</li>
                                        <li><Check/> Pattern Day Traders are identified by 4 day trades in a 5 day period</li>
                                    </ul>
                                </div>
                            </div>
                        ) : (
                            <div className={classes.info}>
                                <div>
                                    <ul>
                                        <li><Cross/> Cannot short stocks</li>
                                        <li><Check/> Trade with settled funds</li>
                                        <li><Check/> Great way to start investing</li>
                                    </ul>
                                </div>
                            </div>
                        )}
                    </label>
                )
            })}
        </div> 
    </FormControl>
  );
}