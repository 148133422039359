/**
 * @fileoverview gRPC-Web generated client stub for authpb
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.14.0
// source: proto/authpb/auth.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var proto_usrpb_useraccess_pb = require('../../proto/usrpb/useraccess_pb.js')

const proto = {};
proto.authpb = require('./auth_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.authpb.AuthServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.authpb.AuthServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authpb.LoginRequest,
 *   !proto.authpb.LoginResponse>}
 */
const methodDescriptor_AuthService_Login = new grpc.web.MethodDescriptor(
  '/authpb.AuthService/Login',
  grpc.web.MethodType.UNARY,
  proto.authpb.LoginRequest,
  proto.authpb.LoginResponse,
  /**
   * @param {!proto.authpb.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authpb.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.authpb.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authpb.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authpb.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authpb.AuthServiceClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authpb.AuthService/Login',
      request,
      metadata || {},
      methodDescriptor_AuthService_Login,
      callback);
};


/**
 * @param {!proto.authpb.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authpb.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.authpb.AuthServicePromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authpb.AuthService/Login',
      request,
      metadata || {},
      methodDescriptor_AuthService_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authpb.LoginUserRequest,
 *   !proto.authpb.LoginResponse>}
 */
const methodDescriptor_AuthService_Loginuser = new grpc.web.MethodDescriptor(
  '/authpb.AuthService/Loginuser',
  grpc.web.MethodType.UNARY,
  proto.authpb.LoginUserRequest,
  proto.authpb.LoginResponse,
  /**
   * @param {!proto.authpb.LoginUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authpb.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.authpb.LoginUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authpb.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authpb.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authpb.AuthServiceClient.prototype.loginuser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authpb.AuthService/Loginuser',
      request,
      metadata || {},
      methodDescriptor_AuthService_Loginuser,
      callback);
};


/**
 * @param {!proto.authpb.LoginUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authpb.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.authpb.AuthServicePromiseClient.prototype.loginuser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authpb.AuthService/Loginuser',
      request,
      metadata || {},
      methodDescriptor_AuthService_Loginuser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authpb.RefreshTokenRequest,
 *   !proto.authpb.LoginResponse>}
 */
const methodDescriptor_AuthService_RefreshToken = new grpc.web.MethodDescriptor(
  '/authpb.AuthService/RefreshToken',
  grpc.web.MethodType.UNARY,
  proto.authpb.RefreshTokenRequest,
  proto.authpb.LoginResponse,
  /**
   * @param {!proto.authpb.RefreshTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authpb.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.authpb.RefreshTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authpb.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authpb.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authpb.AuthServiceClient.prototype.refreshToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authpb.AuthService/RefreshToken',
      request,
      metadata || {},
      methodDescriptor_AuthService_RefreshToken,
      callback);
};


/**
 * @param {!proto.authpb.RefreshTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authpb.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.authpb.AuthServicePromiseClient.prototype.refreshToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authpb.AuthService/RefreshToken',
      request,
      metadata || {},
      methodDescriptor_AuthService_RefreshToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authpb.ValidateCodeRequest,
 *   !proto.authpb.ValidateCodeResponse>}
 */
const methodDescriptor_AuthService_ValidateCode = new grpc.web.MethodDescriptor(
  '/authpb.AuthService/ValidateCode',
  grpc.web.MethodType.UNARY,
  proto.authpb.ValidateCodeRequest,
  proto.authpb.ValidateCodeResponse,
  /**
   * @param {!proto.authpb.ValidateCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authpb.ValidateCodeResponse.deserializeBinary
);


/**
 * @param {!proto.authpb.ValidateCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authpb.ValidateCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authpb.ValidateCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authpb.AuthServiceClient.prototype.validateCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authpb.AuthService/ValidateCode',
      request,
      metadata || {},
      methodDescriptor_AuthService_ValidateCode,
      callback);
};


/**
 * @param {!proto.authpb.ValidateCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authpb.ValidateCodeResponse>}
 *     Promise that resolves to the response
 */
proto.authpb.AuthServicePromiseClient.prototype.validateCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authpb.AuthService/ValidateCode',
      request,
      metadata || {},
      methodDescriptor_AuthService_ValidateCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authpb.ChangePasswordRequest,
 *   !proto.authpb.ChangePasswordResponse>}
 */
const methodDescriptor_AuthService_ChangePassword = new grpc.web.MethodDescriptor(
  '/authpb.AuthService/ChangePassword',
  grpc.web.MethodType.UNARY,
  proto.authpb.ChangePasswordRequest,
  proto.authpb.ChangePasswordResponse,
  /**
   * @param {!proto.authpb.ChangePasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authpb.ChangePasswordResponse.deserializeBinary
);


/**
 * @param {!proto.authpb.ChangePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authpb.ChangePasswordResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authpb.ChangePasswordResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authpb.AuthServiceClient.prototype.changePassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authpb.AuthService/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_AuthService_ChangePassword,
      callback);
};


/**
 * @param {!proto.authpb.ChangePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authpb.ChangePasswordResponse>}
 *     Promise that resolves to the response
 */
proto.authpb.AuthServicePromiseClient.prototype.changePassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authpb.AuthService/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_AuthService_ChangePassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authpb.RefreshAccessRequest,
 *   !proto.authpb.RefreshAccessResponse>}
 */
const methodDescriptor_AuthService_RefreshAccess = new grpc.web.MethodDescriptor(
  '/authpb.AuthService/RefreshAccess',
  grpc.web.MethodType.UNARY,
  proto.authpb.RefreshAccessRequest,
  proto.authpb.RefreshAccessResponse,
  /**
   * @param {!proto.authpb.RefreshAccessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authpb.RefreshAccessResponse.deserializeBinary
);


/**
 * @param {!proto.authpb.RefreshAccessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authpb.RefreshAccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authpb.RefreshAccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authpb.AuthServiceClient.prototype.refreshAccess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authpb.AuthService/RefreshAccess',
      request,
      metadata || {},
      methodDescriptor_AuthService_RefreshAccess,
      callback);
};


/**
 * @param {!proto.authpb.RefreshAccessRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authpb.RefreshAccessResponse>}
 *     Promise that resolves to the response
 */
proto.authpb.AuthServicePromiseClient.prototype.refreshAccess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authpb.AuthService/RefreshAccess',
      request,
      metadata || {},
      methodDescriptor_AuthService_RefreshAccess);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authpb.SessionRequest,
 *   !proto.authpb.SessionResponse>}
 */
const methodDescriptor_AuthService_ValidateSession = new grpc.web.MethodDescriptor(
  '/authpb.AuthService/ValidateSession',
  grpc.web.MethodType.UNARY,
  proto.authpb.SessionRequest,
  proto.authpb.SessionResponse,
  /**
   * @param {!proto.authpb.SessionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authpb.SessionResponse.deserializeBinary
);


/**
 * @param {!proto.authpb.SessionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authpb.SessionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authpb.SessionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authpb.AuthServiceClient.prototype.validateSession =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authpb.AuthService/ValidateSession',
      request,
      metadata || {},
      methodDescriptor_AuthService_ValidateSession,
      callback);
};


/**
 * @param {!proto.authpb.SessionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authpb.SessionResponse>}
 *     Promise that resolves to the response
 */
proto.authpb.AuthServicePromiseClient.prototype.validateSession =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authpb.AuthService/ValidateSession',
      request,
      metadata || {},
      methodDescriptor_AuthService_ValidateSession);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authpb.GetModeRequest,
 *   !proto.authpb.GetModeResponse>}
 */
const methodDescriptor_AuthService_GetAuthMode = new grpc.web.MethodDescriptor(
  '/authpb.AuthService/GetAuthMode',
  grpc.web.MethodType.UNARY,
  proto.authpb.GetModeRequest,
  proto.authpb.GetModeResponse,
  /**
   * @param {!proto.authpb.GetModeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authpb.GetModeResponse.deserializeBinary
);


/**
 * @param {!proto.authpb.GetModeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authpb.GetModeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authpb.GetModeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authpb.AuthServiceClient.prototype.getAuthMode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authpb.AuthService/GetAuthMode',
      request,
      metadata || {},
      methodDescriptor_AuthService_GetAuthMode,
      callback);
};


/**
 * @param {!proto.authpb.GetModeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authpb.GetModeResponse>}
 *     Promise that resolves to the response
 */
proto.authpb.AuthServicePromiseClient.prototype.getAuthMode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authpb.AuthService/GetAuthMode',
      request,
      metadata || {},
      methodDescriptor_AuthService_GetAuthMode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authpb.GetKeyRequest,
 *   !proto.authpb.GetKeyResponse>}
 */
const methodDescriptor_AuthService_GetKey = new grpc.web.MethodDescriptor(
  '/authpb.AuthService/GetKey',
  grpc.web.MethodType.UNARY,
  proto.authpb.GetKeyRequest,
  proto.authpb.GetKeyResponse,
  /**
   * @param {!proto.authpb.GetKeyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authpb.GetKeyResponse.deserializeBinary
);


/**
 * @param {!proto.authpb.GetKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authpb.GetKeyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authpb.GetKeyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authpb.AuthServiceClient.prototype.getKey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authpb.AuthService/GetKey',
      request,
      metadata || {},
      methodDescriptor_AuthService_GetKey,
      callback);
};


/**
 * @param {!proto.authpb.GetKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authpb.GetKeyResponse>}
 *     Promise that resolves to the response
 */
proto.authpb.AuthServicePromiseClient.prototype.getKey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authpb.AuthService/GetKey',
      request,
      metadata || {},
      methodDescriptor_AuthService_GetKey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authpb.ValidateAccountRequest,
 *   !proto.authpb.ValidateAccountResponse>}
 */
const methodDescriptor_AuthService_ValidateAccount = new grpc.web.MethodDescriptor(
  '/authpb.AuthService/ValidateAccount',
  grpc.web.MethodType.UNARY,
  proto.authpb.ValidateAccountRequest,
  proto.authpb.ValidateAccountResponse,
  /**
   * @param {!proto.authpb.ValidateAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authpb.ValidateAccountResponse.deserializeBinary
);


/**
 * @param {!proto.authpb.ValidateAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authpb.ValidateAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authpb.ValidateAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authpb.AuthServiceClient.prototype.validateAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authpb.AuthService/ValidateAccount',
      request,
      metadata || {},
      methodDescriptor_AuthService_ValidateAccount,
      callback);
};


/**
 * @param {!proto.authpb.ValidateAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authpb.ValidateAccountResponse>}
 *     Promise that resolves to the response
 */
proto.authpb.AuthServicePromiseClient.prototype.validateAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authpb.AuthService/ValidateAccount',
      request,
      metadata || {},
      methodDescriptor_AuthService_ValidateAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authpb.SendAuthicationCodeRequest,
 *   !proto.authpb.SendAuthicationCodeResponse>}
 */
const methodDescriptor_AuthService_SendAuthenticationCode = new grpc.web.MethodDescriptor(
  '/authpb.AuthService/SendAuthenticationCode',
  grpc.web.MethodType.UNARY,
  proto.authpb.SendAuthicationCodeRequest,
  proto.authpb.SendAuthicationCodeResponse,
  /**
   * @param {!proto.authpb.SendAuthicationCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authpb.SendAuthicationCodeResponse.deserializeBinary
);


/**
 * @param {!proto.authpb.SendAuthicationCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authpb.SendAuthicationCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authpb.SendAuthicationCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authpb.AuthServiceClient.prototype.sendAuthenticationCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authpb.AuthService/SendAuthenticationCode',
      request,
      metadata || {},
      methodDescriptor_AuthService_SendAuthenticationCode,
      callback);
};


/**
 * @param {!proto.authpb.SendAuthicationCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authpb.SendAuthicationCodeResponse>}
 *     Promise that resolves to the response
 */
proto.authpb.AuthServicePromiseClient.prototype.sendAuthenticationCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authpb.AuthService/SendAuthenticationCode',
      request,
      metadata || {},
      methodDescriptor_AuthService_SendAuthenticationCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authpb.ValidateAuthicationCodeRequest,
 *   !proto.authpb.ValidateAuthicationCodeResponse>}
 */
const methodDescriptor_AuthService_ValidateAuthenticationCode = new grpc.web.MethodDescriptor(
  '/authpb.AuthService/ValidateAuthenticationCode',
  grpc.web.MethodType.UNARY,
  proto.authpb.ValidateAuthicationCodeRequest,
  proto.authpb.ValidateAuthicationCodeResponse,
  /**
   * @param {!proto.authpb.ValidateAuthicationCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authpb.ValidateAuthicationCodeResponse.deserializeBinary
);


/**
 * @param {!proto.authpb.ValidateAuthicationCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authpb.ValidateAuthicationCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authpb.ValidateAuthicationCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authpb.AuthServiceClient.prototype.validateAuthenticationCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authpb.AuthService/ValidateAuthenticationCode',
      request,
      metadata || {},
      methodDescriptor_AuthService_ValidateAuthenticationCode,
      callback);
};


/**
 * @param {!proto.authpb.ValidateAuthicationCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authpb.ValidateAuthicationCodeResponse>}
 *     Promise that resolves to the response
 */
proto.authpb.AuthServicePromiseClient.prototype.validateAuthenticationCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authpb.AuthService/ValidateAuthenticationCode',
      request,
      metadata || {},
      methodDescriptor_AuthService_ValidateAuthenticationCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authpb.ValidateAuthenticationGACodeRequest,
 *   !proto.authpb.ValidateAuthicationCodeResponse>}
 */
const methodDescriptor_AuthService_ValidateGAAuthenticationCode = new grpc.web.MethodDescriptor(
  '/authpb.AuthService/ValidateGAAuthenticationCode',
  grpc.web.MethodType.UNARY,
  proto.authpb.ValidateAuthenticationGACodeRequest,
  proto.authpb.ValidateAuthicationCodeResponse,
  /**
   * @param {!proto.authpb.ValidateAuthenticationGACodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authpb.ValidateAuthicationCodeResponse.deserializeBinary
);


/**
 * @param {!proto.authpb.ValidateAuthenticationGACodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authpb.ValidateAuthicationCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authpb.ValidateAuthicationCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authpb.AuthServiceClient.prototype.validateGAAuthenticationCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authpb.AuthService/ValidateGAAuthenticationCode',
      request,
      metadata || {},
      methodDescriptor_AuthService_ValidateGAAuthenticationCode,
      callback);
};


/**
 * @param {!proto.authpb.ValidateAuthenticationGACodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authpb.ValidateAuthicationCodeResponse>}
 *     Promise that resolves to the response
 */
proto.authpb.AuthServicePromiseClient.prototype.validateGAAuthenticationCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authpb.AuthService/ValidateGAAuthenticationCode',
      request,
      metadata || {},
      methodDescriptor_AuthService_ValidateGAAuthenticationCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authpb.ChangePasswordPreLoginRequest,
 *   !proto.authpb.ChangePasswordPreLoginResponse>}
 */
const methodDescriptor_AuthService_ChangePasswordPreLogin = new grpc.web.MethodDescriptor(
  '/authpb.AuthService/ChangePasswordPreLogin',
  grpc.web.MethodType.UNARY,
  proto.authpb.ChangePasswordPreLoginRequest,
  proto.authpb.ChangePasswordPreLoginResponse,
  /**
   * @param {!proto.authpb.ChangePasswordPreLoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authpb.ChangePasswordPreLoginResponse.deserializeBinary
);


/**
 * @param {!proto.authpb.ChangePasswordPreLoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authpb.ChangePasswordPreLoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authpb.ChangePasswordPreLoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authpb.AuthServiceClient.prototype.changePasswordPreLogin =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authpb.AuthService/ChangePasswordPreLogin',
      request,
      metadata || {},
      methodDescriptor_AuthService_ChangePasswordPreLogin,
      callback);
};


/**
 * @param {!proto.authpb.ChangePasswordPreLoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authpb.ChangePasswordPreLoginResponse>}
 *     Promise that resolves to the response
 */
proto.authpb.AuthServicePromiseClient.prototype.changePasswordPreLogin =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authpb.AuthService/ChangePasswordPreLogin',
      request,
      metadata || {},
      methodDescriptor_AuthService_ChangePasswordPreLogin);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.authpb.VerifySecurityAnswerRequest,
 *   !proto.authpb.VerifySecurityAnswerResponse>}
 */
const methodDescriptor_AuthService_VerifySecurityAnswer = new grpc.web.MethodDescriptor(
  '/authpb.AuthService/VerifySecurityAnswer',
  grpc.web.MethodType.UNARY,
  proto.authpb.VerifySecurityAnswerRequest,
  proto.authpb.VerifySecurityAnswerResponse,
  /**
   * @param {!proto.authpb.VerifySecurityAnswerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.authpb.VerifySecurityAnswerResponse.deserializeBinary
);


/**
 * @param {!proto.authpb.VerifySecurityAnswerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.authpb.VerifySecurityAnswerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.authpb.VerifySecurityAnswerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.authpb.AuthServiceClient.prototype.verifySecurityAnswer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/authpb.AuthService/VerifySecurityAnswer',
      request,
      metadata || {},
      methodDescriptor_AuthService_VerifySecurityAnswer,
      callback);
};


/**
 * @param {!proto.authpb.VerifySecurityAnswerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.authpb.VerifySecurityAnswerResponse>}
 *     Promise that resolves to the response
 */
proto.authpb.AuthServicePromiseClient.prototype.verifySecurityAnswer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/authpb.AuthService/VerifySecurityAnswer',
      request,
      metadata || {},
      methodDescriptor_AuthService_VerifySecurityAnswer);
};


module.exports = proto.authpb;

