import {
  //ADMINISTRATOR
  dataToClientAdministrator,

  //CLIENT
  accountDataToAccountClient,

  //OWNER
  dataToOwner,
  coApplicantToOwner,
  coOfficerToOwner,
  trustedContactToOwner,

  //ADDRESS
  accountToAddress,
  accountPrimaryOfficerToAddress,
  accountCoApplicantToAddress,
  accountCoOfficerToAddress,
  accountToTrustedContactAddress,

  //CONTACT INFO
  accessToContactInfo,
  accountToContactInfoPhoneNumber,
  accountPrimaryOfficerToContactInfoPhoneNumber,
  accountCoApplicantToContactInfoEmail,
  accountCoApplicantToContactInfoPhoneNumber,
  accountCoOfficerToContactInfo,
  accountCoOfficerToContactInfoEmail,
  accountToTrustedContactInfoPhoneNumber,
  accountToTrustedContactInfoEmail,

  //IDENTIFICATION
  ToidentificationTax,
  Toidentification,
  ToidentificationSSN,
  accountCoOfficerToidentification,
  accountCoOfficerToidentificationSSN,
  trustedContactIdentification,
} from '../AccountSetup/registerDataMapping';

import { updateAdministrator } from '../../services/AdministratorService';
import { createClient, updateClient } from '../../services/ClientAccountService';
import { createOwner, deleteOwner, updateOwner } from '../../services/OwnerService';
import { createContactInfo, deleteContactInfo } from '../../services/ContactInfoService';
import {
  createIdentification,
  deleteIdentification,
} from '../../services/IdentificationService';
import { createAddress, deleteAddress } from '../../services/AccountAddressService';
import { attachFiles, deleteOnboardingFile } from '../../services/CommonService';

export const SaveClient = async (
  accountData,
  accountCoOfficerData,
  identificationData,
  employmentData,
  associationData,
  attachments,
  marginAgreementAttachment,
  customerAccountAgreementAttachment,
  onboardingAttachment,
  tradingData,
  investmentObjectiveData,
  eSignatureData,
  coIdentificationData,
  marginAgreementData,
  incomplete,
  accountId,
  trustedContactData,
  customerAccountAgreementData,
  progress
) => {
  const { usrId, accountType, firstName, middleName, lastName, phoneNumber, email } =
    accountData;

  const accessData = {
    usrId: usrId,
    firstName: firstName,
    middleName: middleName,
    lastName: lastName,
    phoneNumber: phoneNumber,
    email: email,
    password: '',
    contactInfoIds: accountData.contactInfoIds,
  };

  //RESET OWNER, IDENTIFICATION, CONTACT INFO, AND ADDRESS TABLE
  try {
    await deleteOwner(accountId);
    await deleteAddress(accountId);
    await deleteIdentification(accountId);
    await deleteContactInfo(accountId);
  } catch (error) {
    console.log(error);
  }

  //GENERATE ACCOUNT NO==============================
  //const { account } = await generateAccountNo({ firstName: firstName, lastName: lastName, phoneNumber: phoneNumber });

  //ADMINISTRATOR====================================
  // const administrator = dataToClientAdministrator(accessData, accountData, incomplete, accountId);
  // await updateAdministrator(administrator);

  //CLIENT===========================================
  const clientData = accountDataToAccountClient(
    accountId,
    accessData,
    accountData,
    identificationData,
    investmentObjectiveData,
    tradingData,
    eSignatureData,
    marginAgreementData,
    customerAccountAgreementData,
    progress,
    incomplete
  );
  await updateClient(clientData);

  //OWNER============================================
  const ownerData = dataToOwner(
    accountId,
    accessData,
    accountData,
    identificationData,
    employmentData,
    associationData,
    investmentObjectiveData
  );
  const owner = await createOwner(ownerData);
  const ownerId = owner.owner.ownerId;

  //IDENFICATION=====================================
  const clientIdentification = Toidentification(
    accountId,
    ownerId,
    accountData,
    identificationData
  );
  await createIdentification(clientIdentification);

  const ssnIdentification = ToidentificationSSN(
    accountId,
    ownerId,
    accountData,
    identificationData
  );
  await createIdentification(ssnIdentification);

  if (accountType === 'C') {
    const taxIdentification = ToidentificationTax(
      accountId,
      ownerId,
      accountData,
      identificationData
    );
    await createIdentification(taxIdentification);
  }

  //CONTACT INFO======================================
  const accessEmail = accessToContactInfo(accountId, ownerId, accountData, accessData);
  await createContactInfo(accessEmail);

  const accountPhoneNumber = accountToContactInfoPhoneNumber(
    accountId,
    ownerId,
    accountData
  );
  await createContactInfo(accountPhoneNumber);

  //ADDRESS===========================================
  const accountAddress = accountToAddress(accountId, ownerId, accountData);
  await createAddress(accountAddress);

  //JOINT=============================================
  if (accountType === 'J') {
    const coOwnerData = coApplicantToOwner(accountId, accountData);
    const coOwner = await createOwner(coOwnerData);
    const coOwnerId = coOwner.owner.ownerId;

    //CONTACT INFO=================================
    const accountCoApplicantEmail = accountCoApplicantToContactInfoEmail(
      accountId,
      coOwnerId,
      accountData
    );
    await createContactInfo(accountCoApplicantEmail);

    const accountCoApplicantPhoneNumber = accountCoApplicantToContactInfoPhoneNumber(
      accountId,
      coOwnerId,
      accountData
    );
    await createContactInfo(accountCoApplicantPhoneNumber);

    //ADDRESS=======================================
    const coApplicantAddress = accountCoApplicantToAddress(
      accountId,
      coOwnerId,
      accountData
    );
    await createAddress(coApplicantAddress);
  }

  //CORPORATION=========================================
  if (accountType === 'C') {
    const primaryOfficerPhoneNumber = accountPrimaryOfficerToContactInfoPhoneNumber(
      accountId,
      ownerId,
      accountData
    );
    await createContactInfo(primaryOfficerPhoneNumber);

    const primaryOfficerAddress = accountPrimaryOfficerToAddress(
      accountId,
      ownerId,
      accountData
    );
    await createAddress(primaryOfficerAddress);

    for (let i = 0; i < accountCoOfficerData.length; i++) {
      //OWNER=========================================
      const coOfficerOwner = coOfficerToOwner(
        accountId,
        accountCoOfficerData[i],
        coIdentificationData[i]
      );
      const coOfficerOwnerRes = await createOwner(coOfficerOwner);
      const coOfficerOwnerId = coOfficerOwnerRes.owner.ownerId;

      if (accountCoOfficerData[i].coOwnershipPercentage >= 25) {
        const coOfficerIdentification = accountCoOfficerToidentification(
          accountId,
          coOfficerOwnerId,
          accountCoOfficerData[i],
          coIdentificationData[i]
        );
        await createIdentification(coOfficerIdentification);

        const coOfficerIdentificationSSN = accountCoOfficerToidentificationSSN(
          accountId,
          coOfficerOwnerId,
          accountCoOfficerData[i],
          coIdentificationData[i]
        );
        await createIdentification(coOfficerIdentificationSSN);
      }

      //CONTACTINFO====================================
      const coOfficerPhoneNumber = accountCoOfficerToContactInfo(
        accountId,
        coOfficerOwnerId,
        accountCoOfficerData[i]
      );
      await createContactInfo(coOfficerPhoneNumber);

      const coOfficerEmail = accountCoOfficerToContactInfoEmail(
        accountId,
        coOfficerOwnerId,
        accountCoOfficerData[i]
      );
      await createContactInfo(coOfficerEmail);

      //ADDRESS========================================
      const coOfficerAddress = accountCoOfficerToAddress(
        accountId,
        coOfficerOwnerId,
        accountCoOfficerData[i]
      );
      await createAddress(coOfficerAddress);
    }
  }

  //TRUSTED CONTACT OWNER============================================
  if (trustedContactData.firstName && trustedContactData.lastName) {
    const trustedContactOwnerData = trustedContactToOwner(
      accountId,
      accessData,
      accountData,
      identificationData,
      employmentData,
      associationData,
      investmentObjectiveData,
      trustedContactData
    );

    const trustedContact = await createOwner(trustedContactOwnerData);

    const updatedTrustContactOwnerData = {
      ...trustedContactOwnerData,
      ownerId: trustedContact.owner.ownerId,
    };

    await updateOwner(updatedTrustContactOwnerData);

    const trustedContactOwnerId = trustedContact.owner.ownerId;

    //TRUSTED CONTACT OWNER PHONE============================================
    const trustedContactPhoneNumber = accountToTrustedContactInfoPhoneNumber(
      accountId,
      trustedContactOwnerId,
      accountData,
      trustedContactData
    );
    await createContactInfo(trustedContactPhoneNumber);

    //TRUSTED CONTACT OWNER Email============================================
    const trustedContactEmail = accountToTrustedContactInfoEmail(
      accountId,
      trustedContactOwnerId,
      accountData,
      trustedContactData
    );
    await createContactInfo(trustedContactEmail);

    //TRUSTED CONTACT OWNER ID============================================
    const trustedContactId = trustedContactIdentification(
      accountId,
      trustedContactOwnerId,
      accountData,
      trustedContactData
    );
    await createIdentification(trustedContactId);

    //TRUSTED CONTACT OWNER ADDRESS============================================
    const trustedContactAddress = accountToTrustedContactAddress(
      accountId,
      trustedContactOwnerId,
      accountData,
      trustedContactData
    );
    await createAddress(trustedContactAddress);
  }
  // //IDENFICATION=====================================
  // const clientIdentification = Toidentification(accountId, ownerId, accountData, identificationData);
  // await createIdentification(clientIdentification);

  //ATTACHMENTS======================================
  await attachFiles(accountId, 'ClientAccount', attachments);
  await attachFiles(accountId, 'ClientAccount', marginAgreementAttachment);
  await deleteOnboardingFile(accountId, onboardingAttachment);
  await attachFiles(accountId, 'ClientAccount', onboardingAttachment);
  await attachFiles(accountId, 'ClientAccount', customerAccountAgreementAttachment);
};
