import {
  OrderEntryServiceClient,
  Empty,
  OrderEntry,
  MoveTradesRequest,
  TradeRequest,
  MovePendingRequest,
  GetOrderEntryRequest,
  GetAccountDetailsRequest,
  GetOptionDetailsRequest,
  GetSymbolDetailsRequest,
  ClientInputRequest,
  GetPositionDetailsRequest,
  GetIceSymbolRequest,
  GetPositionsRequest,
  ListOrderEntryRequest,
  ListAmountBreakdownRequest,
  DuplicateTradeRequest,
  GetTradingPrivilegeRequest,
  ListOrdersByIDRequest,
  ListPendingBatchOrdersRequest,
} from '../proto/tradepb/orderentry_grpc_web_pb';
import { auth } from '../lib/auth/Auth';
import { stringToProtoDate } from './ConvertService';

const service = new OrderEntryServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });

export async function setClientInput(orderEntryId, clientInput) {
  return new Promise((resolve, reject) => {
    let req = new ClientInputRequest();
    req.setOrderEntryId(orderEntryId);
    req.setClientInput(clientInput);

    service.setClientInput(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function getLastTrades() {
  return new Promise((resolve, reject) => {
    let req = new Empty();

    service.getLastTrades(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function getPositions(accountNo, assetType) {
  return new Promise((resolve, reject) => {
    let req = new GetPositionsRequest();
    req.setAccountNo(accountNo);
    req.setAssetType(assetType);

    service.getPositions(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function getTotals() {
  return new Promise((resolve, reject) => {
    let req = new Empty();

    service.getTotals(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function getOrderEntry(orderEntryId) {
  return new Promise((resolve, reject) => {
    let req = new GetOrderEntryRequest();
    req.setOrderEntryId(orderEntryId);

    service.getOrderEntry(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function getAccountDetails(accountNo) {
  return new Promise((resolve, reject) => {
    let req = new GetAccountDetailsRequest();
    req.setAccountNo(accountNo);

    service.getAccountDetails(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function getOptionDetails(symbol) {
  return new Promise((resolve, reject) => {
    let req = new GetOptionDetailsRequest();
    req.setSymbol(symbol);

    service.getOptionDetails(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function getSymbolDetails(symbol) {
  return new Promise((resolve, reject) => {
    let req = new GetSymbolDetailsRequest();
    req.setSymbol(symbol);

    service.getSymbolDetails(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function getPositionDetails(symbol, assetType, accountNo) {
  return new Promise((resolve, reject) => {
    let req = new GetPositionDetailsRequest();
    req.setSymbol(symbol);
    req.setAccountNo(accountNo);
    req.setAssetType(assetType);

    service.getPositionDetails(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function getIceSymbol(symbol) {
  return new Promise((resolve, reject) => {
    let req = new GetIceSymbolRequest();
    req.setSymbol(symbol);

    service.getIceSymbol(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listOrderEntry(param) {
  return new Promise((resolve, reject) => {
    let req = new ListOrderEntryRequest();

    req.setAccountNo(param.accountNo);
    // req.setMasterAccountNo(param.masterAccountNo);
    req.setRep(param.rep);
    req.setAccountName(param.accountName);
    req.setAssetType(param.assetType);
    req.setSymbol(param.symbol);
    // req.setSide(param.side);

    service.listOrderEntry(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listPendingOrderEntry() {
  return new Promise((resolve, reject) => {
    let req = new Empty();

    service.listPendingOrderEntry(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function createOrderEntry() {
  return new Promise((resolve, reject) => {
    let req = new OrderEntry();
    service.createOrderEntry(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateOrderEntry(param) {
  return new Promise((resolve, reject) => {
    const req = setParameters(param);

    service.updateOrderEntry(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function deleteOrderEntry(orderEntryId) {
  return new Promise((resolve, reject) => {
    let req = new OrderEntry();
    req.setOrderEntryId(orderEntryId);

    service.deleteOrderEntry(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function moveToTrades(param) {
  return new Promise((resolve, reject) => {
    let tradeRequestsList = tradeRequestParam(param.tradeRequests);
    let req = new MoveTradesRequest();
    req.setTradeRequestList(tradeRequestsList);

    service.moveToTrades(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

const tradeRequestParam = (list) => {
  let listReq = [];

  list.forEach(async (row) => {
    let req = new TradeRequest();
    req.setAccountNo(row.accountNo);
    req.setBatchNo(row.batchNo);
    listReq.push(req);
  });

  return listReq;
};

export async function moveToPending(orderEntryIds) {
  return new Promise((resolve, reject) => {
    let req = new MovePendingRequest();
    req.setOrderEntryIdsList(orderEntryIds);
    service.moveToPending(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listAmountBreakdown(selectedRows) {
  return new Promise((resolve, reject) => {
    let req = new ListAmountBreakdownRequest();
    let orderEntries = [];

    for (let i = 0; i < selectedRows.length; i++) {
      const order = setParameters(selectedRows[i]);
      orderEntries.push(order);
    }

    req.setOrderEntriesList(orderEntries);

    service.listAmountBreakdown(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function duplicateTrade(data) {
  return new Promise((resolve, reject) => {
    let req = new DuplicateTradeRequest();
    req.setOrderEntryIdsList(data.selectedRowsIds);
    req.setAccountNo(data.accountNo);
    service.duplicateTrade(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function getListOrdersByID(orderEntryIds) {
  return new Promise((resolve, reject) => {
    let req = new ListOrdersByIDRequest();
    req.setOrderEntryIdsList(orderEntryIds);

    service.getListOrdersByIDRequest(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function listPendingBatchOrders(param) {
  return new Promise((resolve, reject) => {
    let req = new ListPendingBatchOrdersRequest();
    req.setAccountNoList(param.accountNo);
    req.setBatchNoList(param.batchNo);

    service.listPendingBatchOrders(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function getTradingPrivilege(accountNo) {
  return new Promise((resolve, reject) => {
    let req = new GetTradingPrivilegeRequest();
    req.setAccountNo(accountNo);
    service.getTradingPrivilege(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

const setParameters = (param) => {
  let req = new OrderEntry();

  req.setOrderEntryId(param.orderEntryId);
  req.setAccountNo(param.accountNo);
  req.setAssetType(param.assetType);
  req.setAction(param.action);
  req.setSymbol(param.symbol);
  req.setQty(param.qty);
  req.setShare(param.share);
  req.setOrderType(param.orderType);
  req.setUserNote(param.userNote);

  // the fields below may not be used in EDIT
  req.setRep(param.rep);
  req.setPositionQty(param.positionQty);

  req.setStatus(param.status);
  req.setDollar(param.dollar);
  req.setSymbolDescription(param.symbolDescription);
  req.setDivRei(param.divRei);
  req.setPlusCommission(param.plusCommission);
  req.setStopPrice(param.stopPrice);
  req.setLimitPrice(param.limitPrice);
  req.setTif(param.tif);
  req.setAccountName(param.accountName);
  req.setPrice(param.price);
  req.setAmt(param.amt);
  req.setCashBalance(param.cashBalance);
  req.setRoundDown(param.roundDown);
  req.setNote(param.note);
  req.setClientInput(param.clientInput);
  req.setUnderlyingPosition(param.underlyingPosition);
  req.setBatchNo(param.batchNo);
  req.setPositionValue(param.positionValue);
  req.setSettlementType(param.settlementType);
  req.setLinkedTradeId(param.linkedTradeId);
  req.setSolicited(param.solicited);
  req.setSymbol2(param.symbol2);
  req.setAccountId(param.accountId);
  req.setBatchNo2(param.batchNo2);
  req.setTrailingStop(param.trailingStop);
  req.setNextDayExecution(param.nextDayExecution);
  req.setOption(param.option);
  req.setPrimeBroker(param.primeBroker);
  req.setMarginAgreement(param.marginAgreement);
  req.setStage(param.stage);
  req.setCostPerShare(param.costPerShare);
  req.setSecurityType(param.securityType);
  req.setAccountType(param.accountType);
  req.setEstRealizedPl(param.estRealizedPl);
  req.setPriceSrc(param.priceSrc);
  req.setCredit(param.credit);
  req.setMaturity(param.maturity);
  req.setType(param.type);
  req.setState(param.state);
  req.setYtc(param.ytc);
  req.setYtw(param.ytw);
  req.setMaturityPeriod(param.maturityPeriod);
  req.setCoupon(param.coupon);
  req.setEarlySettlementClientApproved(param.earlySettlementClientApproved);
  req.setRestricted(param.restricted);
  req.setSymbolConverted(param.symbolConverted);
  req.setTradeConfirm(param.tradeConfirm);
  req.setLastPrice(param.lastPrice);
  req.setExpirationDate(stringToProtoDate(param.expirationDate));
  req.setPutCall(param.putCall);
  req.setStrikePrice(param.strikePrice);

  return req;
};
