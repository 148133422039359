// source: proto/reportpb/balance.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_utilspb_pagination_pb = require('../../proto/utilspb/pagination_pb.js');
goog.object.extend(proto, proto_utilspb_pagination_pb);

goog.exportSymbol('proto.reportpb.AccountBalance', null, global);
goog.exportSymbol('proto.reportpb.BalDateObject', null, global);
goog.exportSymbol('proto.reportpb.Balance', null, global);
goog.exportSymbol('proto.reportpb.BalanceSummaryReport', null, global);
goog.exportSymbol('proto.reportpb.ListBalanceRequest', null, global);
goog.exportSymbol('proto.reportpb.ListBalanceResponse', null, global);
goog.exportSymbol('proto.reportpb.ListBalanceSummary', null, global);
goog.exportSymbol('proto.reportpb.ListBalanceSummaryReportResponse', null, global);
goog.exportSymbol('proto.reportpb.Movement', null, global);
goog.exportSymbol('proto.reportpb.SubAccountBalance', null, global);
goog.exportSymbol('proto.reportpb.Values', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.Balance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.Balance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.Balance.displayName = 'proto.reportpb.Balance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListBalanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ListBalanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListBalanceRequest.displayName = 'proto.reportpb.ListBalanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListBalanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListBalanceResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListBalanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListBalanceResponse.displayName = 'proto.reportpb.ListBalanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.Movement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.Movement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.Movement.displayName = 'proto.reportpb.Movement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.Values = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.Values, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.Values.displayName = 'proto.reportpb.Values';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListBalanceSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ListBalanceSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListBalanceSummary.displayName = 'proto.reportpb.ListBalanceSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListBalanceSummaryReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListBalanceSummaryReportResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListBalanceSummaryReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListBalanceSummaryReportResponse.displayName = 'proto.reportpb.ListBalanceSummaryReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.BalanceSummaryReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.BalanceSummaryReport.repeatedFields_, null);
};
goog.inherits(proto.reportpb.BalanceSummaryReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.BalanceSummaryReport.displayName = 'proto.reportpb.BalanceSummaryReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.AccountBalance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.AccountBalance.repeatedFields_, null);
};
goog.inherits(proto.reportpb.AccountBalance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.AccountBalance.displayName = 'proto.reportpb.AccountBalance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.SubAccountBalance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.SubAccountBalance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.SubAccountBalance.displayName = 'proto.reportpb.SubAccountBalance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.BalDateObject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.BalDateObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.BalDateObject.displayName = 'proto.reportpb.BalDateObject';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.Balance.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.Balance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.Balance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.Balance.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    broker: jspb.Message.getFieldWithDefault(msg, 7, ""),
    type: jspb.Message.getFieldWithDefault(msg, 8, ""),
    dateType: jspb.Message.getFieldWithDefault(msg, 9, ""),
    date: (f = msg.getDate()) && proto.reportpb.BalDateObject.toObject(includeInstance, f),
    rep: jspb.Message.getFieldWithDefault(msg, 11, ""),
    branch: jspb.Message.getFieldWithDefault(msg, 12, ""),
    marginType: jspb.Message.getFieldWithDefault(msg, 13, ""),
    regFee: jspb.Message.getFieldWithDefault(msg, 14, ""),
    tafFee: jspb.Message.getFieldWithDefault(msg, 15, ""),
    commission: jspb.Message.getFieldWithDefault(msg, 16, ""),
    fees: jspb.Message.getFieldWithDefault(msg, 17, ""),
    haircut: jspb.Message.getFieldWithDefault(msg, 18, ""),
    sdCashBalance: jspb.Message.getFieldWithDefault(msg, 19, ""),
    sdShortMarketValue: jspb.Message.getFieldWithDefault(msg, 20, ""),
    sdLongMarketValue: jspb.Message.getFieldWithDefault(msg, 21, ""),
    sdEquity: jspb.Message.getFieldWithDefault(msg, 22, ""),
    sdAdjustedBalance: jspb.Message.getFieldWithDefault(msg, 23, ""),
    sdRegFee: jspb.Message.getFieldWithDefault(msg, 24, ""),
    sdTafFee: jspb.Message.getFieldWithDefault(msg, 25, ""),
    sdCommission: jspb.Message.getFieldWithDefault(msg, 26, ""),
    sdFees: jspb.Message.getFieldWithDefault(msg, 27, ""),
    sdHaircut: jspb.Message.getFieldWithDefault(msg, 28, ""),
    moneyMarket: jspb.Message.getFieldWithDefault(msg, 29, ""),
    sdMoneyMarket: jspb.Message.getFieldWithDefault(msg, 30, ""),
    location: jspb.Message.getFieldWithDefault(msg, 31, ""),
    accountStatus: jspb.Message.getFieldWithDefault(msg, 32, ""),
    participantNo: jspb.Message.getFieldWithDefault(msg, 33, ""),
    tdCashBalance: jspb.Message.getFieldWithDefault(msg, 34, ""),
    tdMoneyMarket: jspb.Message.getFieldWithDefault(msg, 35, ""),
    tdShortMarketValue: jspb.Message.getFieldWithDefault(msg, 36, ""),
    tdLongMarketValue: jspb.Message.getFieldWithDefault(msg, 37, ""),
    tdEquity: jspb.Message.getFieldWithDefault(msg, 38, ""),
    tdAdjustedBalance: jspb.Message.getFieldWithDefault(msg, 39, ""),
    tdDebitBalanceAge: jspb.Message.getFieldWithDefault(msg, 40, ""),
    sdDebitBalanceAge: jspb.Message.getFieldWithDefault(msg, 41, ""),
    accountCurrency: jspb.Message.getFieldWithDefault(msg, 42, ""),
    contraBroker: jspb.Message.getFieldWithDefault(msg, 43, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.Balance}
 */
proto.reportpb.Balance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.Balance;
  return proto.reportpb.Balance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.Balance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.Balance}
 */
proto.reportpb.Balance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateType(value);
      break;
    case 10:
      var value = new proto.reportpb.BalDateObject;
      reader.readMessage(value,proto.reportpb.BalDateObject.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setRep(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranch(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarginType(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegFee(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTafFee(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommission(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setFees(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setHaircut(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdCashBalance(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdShortMarketValue(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdLongMarketValue(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdEquity(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdAdjustedBalance(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdRegFee(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdTafFee(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdCommission(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdFees(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdHaircut(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setMoneyMarket(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdMoneyMarket(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountStatus(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setParticipantNo(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdCashBalance(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdMoneyMarket(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdShortMarketValue(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdLongMarketValue(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdEquity(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdAdjustedBalance(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdDebitBalanceAge(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdDebitBalanceAge(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountCurrency(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraBroker(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.Balance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.Balance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.Balance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.Balance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDateType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.reportpb.BalDateObject.serializeBinaryToWriter
    );
  }
  f = message.getRep();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getMarginType();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getRegFee();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getTafFee();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCommission();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getFees();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getHaircut();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getSdCashBalance();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getSdShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getSdLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getSdEquity();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getSdAdjustedBalance();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getSdRegFee();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getSdTafFee();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getSdCommission();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getSdFees();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getSdHaircut();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getMoneyMarket();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getSdMoneyMarket();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getAccountStatus();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getParticipantNo();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getTdCashBalance();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getTdMoneyMarket();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getTdShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getTdLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getTdEquity();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getTdAdjustedBalance();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getTdDebitBalanceAge();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getSdDebitBalanceAge();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getAccountCurrency();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getContraBroker();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
};


/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.reportpb.Balance.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sub_account_no = 4;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getSubAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setSubAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_name = 5;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string master_account_no = 6;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string broker = 7;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string type = 8;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string date_type = 9;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getDateType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setDateType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional BalDateObject date = 10;
 * @return {?proto.reportpb.BalDateObject}
 */
proto.reportpb.Balance.prototype.getDate = function() {
  return /** @type{?proto.reportpb.BalDateObject} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.BalDateObject, 10));
};


/**
 * @param {?proto.reportpb.BalDateObject|undefined} value
 * @return {!proto.reportpb.Balance} returns this
*/
proto.reportpb.Balance.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.Balance.prototype.hasDate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string rep = 11;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getRep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setRep = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string branch = 12;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string margin_type = 13;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getMarginType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setMarginType = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string reg_fee = 14;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getRegFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setRegFee = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string taf_fee = 15;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getTafFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setTafFee = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string commission = 16;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getCommission = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setCommission = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string fees = 17;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getFees = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setFees = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string haircut = 18;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getHaircut = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setHaircut = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string sd_cash_balance = 19;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getSdCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setSdCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string sd_short_market_value = 20;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getSdShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setSdShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string sd_long_market_value = 21;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getSdLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setSdLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string sd_equity = 22;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getSdEquity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setSdEquity = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string sd_adjusted_balance = 23;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getSdAdjustedBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setSdAdjustedBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string sd_reg_fee = 24;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getSdRegFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setSdRegFee = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string sd_taf_fee = 25;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getSdTafFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setSdTafFee = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string sd_commission = 26;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getSdCommission = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setSdCommission = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string sd_fees = 27;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getSdFees = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setSdFees = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string sd_haircut = 28;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getSdHaircut = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setSdHaircut = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string money_market = 29;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getMoneyMarket = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setMoneyMarket = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string sd_money_market = 30;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getSdMoneyMarket = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setSdMoneyMarket = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string location = 31;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string account_status = 32;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getAccountStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setAccountStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string participant_no = 33;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getParticipantNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setParticipantNo = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string td_cash_balance = 34;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getTdCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setTdCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string td_money_market = 35;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getTdMoneyMarket = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setTdMoneyMarket = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string td_short_market_value = 36;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getTdShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setTdShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string td_long_market_value = 37;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getTdLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setTdLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string td_equity = 38;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getTdEquity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setTdEquity = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional string td_adjusted_balance = 39;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getTdAdjustedBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setTdAdjustedBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string td_debit_balance_age = 40;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getTdDebitBalanceAge = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setTdDebitBalanceAge = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string sd_debit_balance_age = 41;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getSdDebitBalanceAge = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setSdDebitBalanceAge = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional string account_currency = 42;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getAccountCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setAccountCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional string contra_broker = 43;
 * @return {string}
 */
proto.reportpb.Balance.prototype.getContraBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Balance} returns this
 */
proto.reportpb.Balance.prototype.setContraBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListBalanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListBalanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListBalanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListBalanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subAccountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    branch: jspb.Message.getFieldWithDefault(msg, 6, ""),
    rep: jspb.Message.getFieldWithDefault(msg, 7, ""),
    broker: jspb.Message.getFieldWithDefault(msg, 8, ""),
    type: jspb.Message.getFieldWithDefault(msg, 9, ""),
    date: (f = msg.getDate()) && proto.reportpb.BalDateObject.toObject(includeInstance, f),
    dateType: jspb.Message.getFieldWithDefault(msg, 11, ""),
    pagination: (f = msg.getPagination()) && proto_utilspb_pagination_pb.Pagination.toObject(includeInstance, f),
    mode: jspb.Message.getFieldWithDefault(msg, 13, ""),
    hideZero: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    fromDate: (f = msg.getFromDate()) && proto.reportpb.BalDateObject.toObject(includeInstance, f),
    toDate: (f = msg.getToDate()) && proto.reportpb.BalDateObject.toObject(includeInstance, f),
    debitBalance: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    participantNo: jspb.Message.getFieldWithDefault(msg, 18, ""),
    location: jspb.Message.getFieldWithDefault(msg, 19, ""),
    accountCurrency: jspb.Message.getFieldWithDefault(msg, 20, ""),
    unsecuredDebitBalance: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    contraBroker: jspb.Message.getFieldWithDefault(msg, 22, ""),
    rollUp: jspb.Message.getFieldWithDefault(msg, 23, ""),
    range: jspb.Message.getFieldWithDefault(msg, 24, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListBalanceRequest}
 */
proto.reportpb.ListBalanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListBalanceRequest;
  return proto.reportpb.ListBalanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListBalanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListBalanceRequest}
 */
proto.reportpb.ListBalanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranch(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRep(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 10:
      var value = new proto.reportpb.BalDateObject;
      reader.readMessage(value,proto.reportpb.BalDateObject.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateType(value);
      break;
    case 12:
      var value = new proto_utilspb_pagination_pb.Pagination;
      reader.readMessage(value,proto_utilspb_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setMode(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideZero(value);
      break;
    case 15:
      var value = new proto.reportpb.BalDateObject;
      reader.readMessage(value,proto.reportpb.BalDateObject.deserializeBinaryFromReader);
      msg.setFromDate(value);
      break;
    case 16:
      var value = new proto.reportpb.BalDateObject;
      reader.readMessage(value,proto.reportpb.BalDateObject.deserializeBinaryFromReader);
      msg.setToDate(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDebitBalance(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setParticipantNo(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountCurrency(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnsecuredDebitBalance(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraBroker(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setRollUp(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setRange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListBalanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListBalanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListBalanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListBalanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRep();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.reportpb.BalDateObject.serializeBinaryToWriter
    );
  }
  f = message.getDateType();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto_utilspb_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getMode();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getHideZero();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getFromDate();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.reportpb.BalDateObject.serializeBinaryToWriter
    );
  }
  f = message.getToDate();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.reportpb.BalDateObject.serializeBinaryToWriter
    );
  }
  f = message.getDebitBalance();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getParticipantNo();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getAccountCurrency();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getUnsecuredDebitBalance();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getContraBroker();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getRollUp();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getRange();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.reportpb.ListBalanceRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.reportpb.ListBalanceRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sub_account_no = 3;
 * @return {string}
 */
proto.reportpb.ListBalanceRequest.prototype.getSubAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setSubAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_name = 4;
 * @return {string}
 */
proto.reportpb.ListBalanceRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string master_account_no = 5;
 * @return {string}
 */
proto.reportpb.ListBalanceRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string branch = 6;
 * @return {string}
 */
proto.reportpb.ListBalanceRequest.prototype.getBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string rep = 7;
 * @return {string}
 */
proto.reportpb.ListBalanceRequest.prototype.getRep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setRep = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string broker = 8;
 * @return {string}
 */
proto.reportpb.ListBalanceRequest.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string type = 9;
 * @return {string}
 */
proto.reportpb.ListBalanceRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional BalDateObject date = 10;
 * @return {?proto.reportpb.BalDateObject}
 */
proto.reportpb.ListBalanceRequest.prototype.getDate = function() {
  return /** @type{?proto.reportpb.BalDateObject} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.BalDateObject, 10));
};


/**
 * @param {?proto.reportpb.BalDateObject|undefined} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
*/
proto.reportpb.ListBalanceRequest.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListBalanceRequest.prototype.hasDate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string date_type = 11;
 * @return {string}
 */
proto.reportpb.ListBalanceRequest.prototype.getDateType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setDateType = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional utilspb.Pagination pagination = 12;
 * @return {?proto.utilspb.Pagination}
 */
proto.reportpb.ListBalanceRequest.prototype.getPagination = function() {
  return /** @type{?proto.utilspb.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_utilspb_pagination_pb.Pagination, 12));
};


/**
 * @param {?proto.utilspb.Pagination|undefined} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
*/
proto.reportpb.ListBalanceRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListBalanceRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string mode = 13;
 * @return {string}
 */
proto.reportpb.ListBalanceRequest.prototype.getMode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setMode = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bool hide_zero = 14;
 * @return {boolean}
 */
proto.reportpb.ListBalanceRequest.prototype.getHideZero = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setHideZero = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional BalDateObject from_date = 15;
 * @return {?proto.reportpb.BalDateObject}
 */
proto.reportpb.ListBalanceRequest.prototype.getFromDate = function() {
  return /** @type{?proto.reportpb.BalDateObject} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.BalDateObject, 15));
};


/**
 * @param {?proto.reportpb.BalDateObject|undefined} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
*/
proto.reportpb.ListBalanceRequest.prototype.setFromDate = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.clearFromDate = function() {
  return this.setFromDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListBalanceRequest.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional BalDateObject to_date = 16;
 * @return {?proto.reportpb.BalDateObject}
 */
proto.reportpb.ListBalanceRequest.prototype.getToDate = function() {
  return /** @type{?proto.reportpb.BalDateObject} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.BalDateObject, 16));
};


/**
 * @param {?proto.reportpb.BalDateObject|undefined} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
*/
proto.reportpb.ListBalanceRequest.prototype.setToDate = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.clearToDate = function() {
  return this.setToDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListBalanceRequest.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional bool debit_balance = 17;
 * @return {boolean}
 */
proto.reportpb.ListBalanceRequest.prototype.getDebitBalance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setDebitBalance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional string participant_no = 18;
 * @return {string}
 */
proto.reportpb.ListBalanceRequest.prototype.getParticipantNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setParticipantNo = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string location = 19;
 * @return {string}
 */
proto.reportpb.ListBalanceRequest.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string account_currency = 20;
 * @return {string}
 */
proto.reportpb.ListBalanceRequest.prototype.getAccountCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setAccountCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional bool unsecured_debit_balance = 21;
 * @return {boolean}
 */
proto.reportpb.ListBalanceRequest.prototype.getUnsecuredDebitBalance = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setUnsecuredDebitBalance = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional string contra_broker = 22;
 * @return {string}
 */
proto.reportpb.ListBalanceRequest.prototype.getContraBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setContraBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string roll_up = 23;
 * @return {string}
 */
proto.reportpb.ListBalanceRequest.prototype.getRollUp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setRollUp = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string range = 24;
 * @return {string}
 */
proto.reportpb.ListBalanceRequest.prototype.getRange = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceRequest} returns this
 */
proto.reportpb.ListBalanceRequest.prototype.setRange = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListBalanceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListBalanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListBalanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListBalanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListBalanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    balancesList: jspb.Message.toObjectList(msg.getBalancesList(),
    proto.reportpb.Balance.toObject, includeInstance),
    summary: (f = msg.getSummary()) && proto.reportpb.ListBalanceSummary.toObject(includeInstance, f),
    movement: (f = msg.getMovement()) && proto.reportpb.Movement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListBalanceResponse}
 */
proto.reportpb.ListBalanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListBalanceResponse;
  return proto.reportpb.ListBalanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListBalanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListBalanceResponse}
 */
proto.reportpb.ListBalanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.Balance;
      reader.readMessage(value,proto.reportpb.Balance.deserializeBinaryFromReader);
      msg.addBalances(value);
      break;
    case 2:
      var value = new proto.reportpb.ListBalanceSummary;
      reader.readMessage(value,proto.reportpb.ListBalanceSummary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    case 3:
      var value = new proto.reportpb.Movement;
      reader.readMessage(value,proto.reportpb.Movement.deserializeBinaryFromReader);
      msg.setMovement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListBalanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListBalanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListBalanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListBalanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBalancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.Balance.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reportpb.ListBalanceSummary.serializeBinaryToWriter
    );
  }
  f = message.getMovement();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.reportpb.Movement.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Balance balances = 1;
 * @return {!Array<!proto.reportpb.Balance>}
 */
proto.reportpb.ListBalanceResponse.prototype.getBalancesList = function() {
  return /** @type{!Array<!proto.reportpb.Balance>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.Balance, 1));
};


/**
 * @param {!Array<!proto.reportpb.Balance>} value
 * @return {!proto.reportpb.ListBalanceResponse} returns this
*/
proto.reportpb.ListBalanceResponse.prototype.setBalancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.Balance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.Balance}
 */
proto.reportpb.ListBalanceResponse.prototype.addBalances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.Balance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListBalanceResponse} returns this
 */
proto.reportpb.ListBalanceResponse.prototype.clearBalancesList = function() {
  return this.setBalancesList([]);
};


/**
 * optional ListBalanceSummary summary = 2;
 * @return {?proto.reportpb.ListBalanceSummary}
 */
proto.reportpb.ListBalanceResponse.prototype.getSummary = function() {
  return /** @type{?proto.reportpb.ListBalanceSummary} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.ListBalanceSummary, 2));
};


/**
 * @param {?proto.reportpb.ListBalanceSummary|undefined} value
 * @return {!proto.reportpb.ListBalanceResponse} returns this
*/
proto.reportpb.ListBalanceResponse.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListBalanceResponse} returns this
 */
proto.reportpb.ListBalanceResponse.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListBalanceResponse.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Movement movement = 3;
 * @return {?proto.reportpb.Movement}
 */
proto.reportpb.ListBalanceResponse.prototype.getMovement = function() {
  return /** @type{?proto.reportpb.Movement} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.Movement, 3));
};


/**
 * @param {?proto.reportpb.Movement|undefined} value
 * @return {!proto.reportpb.ListBalanceResponse} returns this
*/
proto.reportpb.ListBalanceResponse.prototype.setMovement = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListBalanceResponse} returns this
 */
proto.reportpb.ListBalanceResponse.prototype.clearMovement = function() {
  return this.setMovement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListBalanceResponse.prototype.hasMovement = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.Movement.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.Movement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.Movement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.Movement.toObject = function(includeInstance, msg) {
  var f, obj = {
    today: (f = msg.getToday()) && proto.reportpb.Values.toObject(includeInstance, f),
    past: (f = msg.getPast()) && proto.reportpb.Values.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.Movement}
 */
proto.reportpb.Movement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.Movement;
  return proto.reportpb.Movement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.Movement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.Movement}
 */
proto.reportpb.Movement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.Values;
      reader.readMessage(value,proto.reportpb.Values.deserializeBinaryFromReader);
      msg.setToday(value);
      break;
    case 2:
      var value = new proto.reportpb.Values;
      reader.readMessage(value,proto.reportpb.Values.deserializeBinaryFromReader);
      msg.setPast(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.Movement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.Movement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.Movement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.Movement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToday();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reportpb.Values.serializeBinaryToWriter
    );
  }
  f = message.getPast();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reportpb.Values.serializeBinaryToWriter
    );
  }
};


/**
 * optional Values today = 1;
 * @return {?proto.reportpb.Values}
 */
proto.reportpb.Movement.prototype.getToday = function() {
  return /** @type{?proto.reportpb.Values} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.Values, 1));
};


/**
 * @param {?proto.reportpb.Values|undefined} value
 * @return {!proto.reportpb.Movement} returns this
*/
proto.reportpb.Movement.prototype.setToday = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.Movement} returns this
 */
proto.reportpb.Movement.prototype.clearToday = function() {
  return this.setToday(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.Movement.prototype.hasToday = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Values past = 2;
 * @return {?proto.reportpb.Values}
 */
proto.reportpb.Movement.prototype.getPast = function() {
  return /** @type{?proto.reportpb.Values} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.Values, 2));
};


/**
 * @param {?proto.reportpb.Values|undefined} value
 * @return {!proto.reportpb.Movement} returns this
*/
proto.reportpb.Movement.prototype.setPast = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.Movement} returns this
 */
proto.reportpb.Movement.prototype.clearPast = function() {
  return this.setPast(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.Movement.prototype.hasPast = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.Values.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.Values.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.Values} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.Values.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    percentage: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.Values}
 */
proto.reportpb.Values.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.Values;
  return proto.reportpb.Values.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.Values} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.Values}
 */
proto.reportpb.Values.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.Values.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.Values.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.Values} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.Values.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getPercentage();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double value = 1;
 * @return {number}
 */
proto.reportpb.Values.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.Values} returns this
 */
proto.reportpb.Values.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double percentage = 2;
 * @return {number}
 */
proto.reportpb.Values.prototype.getPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.Values} returns this
 */
proto.reportpb.Values.prototype.setPercentage = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListBalanceSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListBalanceSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListBalanceSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListBalanceSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalRows: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cashBalance: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortMarketValue: jspb.Message.getFieldWithDefault(msg, 3, ""),
    longMarketValue: jspb.Message.getFieldWithDefault(msg, 4, ""),
    equity: jspb.Message.getFieldWithDefault(msg, 5, ""),
    adjustedBalance: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sdCashBalance: jspb.Message.getFieldWithDefault(msg, 7, ""),
    sdShortMarketValue: jspb.Message.getFieldWithDefault(msg, 8, ""),
    sdLongMarketValue: jspb.Message.getFieldWithDefault(msg, 9, ""),
    sdEquity: jspb.Message.getFieldWithDefault(msg, 10, ""),
    sdAdjustedBalance: jspb.Message.getFieldWithDefault(msg, 11, ""),
    sdMoneyMarket: jspb.Message.getFieldWithDefault(msg, 12, ""),
    moneyMarket: jspb.Message.getFieldWithDefault(msg, 13, ""),
    tdCashBalance: jspb.Message.getFieldWithDefault(msg, 14, ""),
    tdMoneyMarket: jspb.Message.getFieldWithDefault(msg, 15, ""),
    tdShortMarketValue: jspb.Message.getFieldWithDefault(msg, 16, ""),
    tdLongMarketValue: jspb.Message.getFieldWithDefault(msg, 17, ""),
    tdEquity: jspb.Message.getFieldWithDefault(msg, 18, ""),
    tdAdjustedBalance: jspb.Message.getFieldWithDefault(msg, 19, ""),
    tdDebitBalanceAge: jspb.Message.getFieldWithDefault(msg, 20, ""),
    sdDebitBalanceAge: jspb.Message.getFieldWithDefault(msg, 21, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListBalanceSummary}
 */
proto.reportpb.ListBalanceSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListBalanceSummary;
  return proto.reportpb.ListBalanceSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListBalanceSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListBalanceSummary}
 */
proto.reportpb.ListBalanceSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRows(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCashBalance(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortMarketValue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongMarketValue(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquity(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdjustedBalance(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdCashBalance(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdShortMarketValue(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdLongMarketValue(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdEquity(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdAdjustedBalance(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdMoneyMarket(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setMoneyMarket(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdCashBalance(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdMoneyMarket(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdShortMarketValue(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdLongMarketValue(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdEquity(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdAdjustedBalance(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdDebitBalanceAge(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdDebitBalanceAge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListBalanceSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListBalanceSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListBalanceSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListBalanceSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalRows();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCashBalance();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEquity();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAdjustedBalance();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSdCashBalance();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSdShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSdLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSdEquity();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSdAdjustedBalance();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getSdMoneyMarket();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getMoneyMarket();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getTdCashBalance();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getTdMoneyMarket();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getTdShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getTdLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTdEquity();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getTdAdjustedBalance();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getTdDebitBalanceAge();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getSdDebitBalanceAge();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
};


/**
 * optional uint32 total_rows = 1;
 * @return {number}
 */
proto.reportpb.ListBalanceSummary.prototype.getTotalRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setTotalRows = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string cash_balance = 2;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string short_market_value = 3;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string long_market_value = 4;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string equity = 5;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getEquity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setEquity = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string adjusted_balance = 6;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getAdjustedBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setAdjustedBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string sd_cash_balance = 7;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getSdCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setSdCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string sd_short_market_value = 8;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getSdShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setSdShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string sd_long_market_value = 9;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getSdLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setSdLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string sd_equity = 10;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getSdEquity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setSdEquity = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string sd_adjusted_balance = 11;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getSdAdjustedBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setSdAdjustedBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string sd_money_market = 12;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getSdMoneyMarket = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setSdMoneyMarket = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string money_market = 13;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getMoneyMarket = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setMoneyMarket = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string td_cash_balance = 14;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getTdCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setTdCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string td_money_market = 15;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getTdMoneyMarket = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setTdMoneyMarket = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string td_short_market_value = 16;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getTdShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setTdShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string td_long_market_value = 17;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getTdLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setTdLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string td_equity = 18;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getTdEquity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setTdEquity = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string td_adjusted_balance = 19;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getTdAdjustedBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setTdAdjustedBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string td_debit_balance_age = 20;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getTdDebitBalanceAge = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setTdDebitBalanceAge = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string sd_debit_balance_age = 21;
 * @return {string}
 */
proto.reportpb.ListBalanceSummary.prototype.getSdDebitBalanceAge = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListBalanceSummary} returns this
 */
proto.reportpb.ListBalanceSummary.prototype.setSdDebitBalanceAge = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListBalanceSummaryReportResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListBalanceSummaryReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListBalanceSummaryReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListBalanceSummaryReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListBalanceSummaryReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    balanceSummaryReportsList: jspb.Message.toObjectList(msg.getBalanceSummaryReportsList(),
    proto.reportpb.BalanceSummaryReport.toObject, includeInstance),
    summary: (f = msg.getSummary()) && proto.reportpb.ListBalanceSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListBalanceSummaryReportResponse}
 */
proto.reportpb.ListBalanceSummaryReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListBalanceSummaryReportResponse;
  return proto.reportpb.ListBalanceSummaryReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListBalanceSummaryReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListBalanceSummaryReportResponse}
 */
proto.reportpb.ListBalanceSummaryReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.BalanceSummaryReport;
      reader.readMessage(value,proto.reportpb.BalanceSummaryReport.deserializeBinaryFromReader);
      msg.addBalanceSummaryReports(value);
      break;
    case 2:
      var value = new proto.reportpb.ListBalanceSummary;
      reader.readMessage(value,proto.reportpb.ListBalanceSummary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListBalanceSummaryReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListBalanceSummaryReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListBalanceSummaryReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListBalanceSummaryReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBalanceSummaryReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.BalanceSummaryReport.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reportpb.ListBalanceSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BalanceSummaryReport balance_summary_reports = 1;
 * @return {!Array<!proto.reportpb.BalanceSummaryReport>}
 */
proto.reportpb.ListBalanceSummaryReportResponse.prototype.getBalanceSummaryReportsList = function() {
  return /** @type{!Array<!proto.reportpb.BalanceSummaryReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.BalanceSummaryReport, 1));
};


/**
 * @param {!Array<!proto.reportpb.BalanceSummaryReport>} value
 * @return {!proto.reportpb.ListBalanceSummaryReportResponse} returns this
*/
proto.reportpb.ListBalanceSummaryReportResponse.prototype.setBalanceSummaryReportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.BalanceSummaryReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.BalanceSummaryReport}
 */
proto.reportpb.ListBalanceSummaryReportResponse.prototype.addBalanceSummaryReports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.BalanceSummaryReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListBalanceSummaryReportResponse} returns this
 */
proto.reportpb.ListBalanceSummaryReportResponse.prototype.clearBalanceSummaryReportsList = function() {
  return this.setBalanceSummaryReportsList([]);
};


/**
 * optional ListBalanceSummary summary = 2;
 * @return {?proto.reportpb.ListBalanceSummary}
 */
proto.reportpb.ListBalanceSummaryReportResponse.prototype.getSummary = function() {
  return /** @type{?proto.reportpb.ListBalanceSummary} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.ListBalanceSummary, 2));
};


/**
 * @param {?proto.reportpb.ListBalanceSummary|undefined} value
 * @return {!proto.reportpb.ListBalanceSummaryReportResponse} returns this
*/
proto.reportpb.ListBalanceSummaryReportResponse.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListBalanceSummaryReportResponse} returns this
 */
proto.reportpb.ListBalanceSummaryReportResponse.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListBalanceSummaryReportResponse.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.BalanceSummaryReport.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.BalanceSummaryReport.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.BalanceSummaryReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.BalanceSummaryReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.BalanceSummaryReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    accountBalancesList: jspb.Message.toObjectList(msg.getAccountBalancesList(),
    proto.reportpb.AccountBalance.toObject, includeInstance),
    broker: jspb.Message.getFieldWithDefault(msg, 8, ""),
    type: jspb.Message.getFieldWithDefault(msg, 9, ""),
    dateType: jspb.Message.getFieldWithDefault(msg, 10, ""),
    date: (f = msg.getDate()) && proto.reportpb.BalDateObject.toObject(includeInstance, f),
    cashBalance: jspb.Message.getFieldWithDefault(msg, 12, ""),
    shortMarketValue: jspb.Message.getFieldWithDefault(msg, 13, ""),
    longMarketValue: jspb.Message.getFieldWithDefault(msg, 14, ""),
    equity: jspb.Message.getFieldWithDefault(msg, 15, ""),
    adjustedBalance: jspb.Message.getFieldWithDefault(msg, 16, ""),
    balanceSummaryReportIndex: jspb.Message.getFieldWithDefault(msg, 17, 0),
    accountCurrency: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.BalanceSummaryReport}
 */
proto.reportpb.BalanceSummaryReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.BalanceSummaryReport;
  return proto.reportpb.BalanceSummaryReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.BalanceSummaryReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.BalanceSummaryReport}
 */
proto.reportpb.BalanceSummaryReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 7:
      var value = new proto.reportpb.AccountBalance;
      reader.readMessage(value,proto.reportpb.AccountBalance.deserializeBinaryFromReader);
      msg.addAccountBalances(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateType(value);
      break;
    case 11:
      var value = new proto.reportpb.BalDateObject;
      reader.readMessage(value,proto.reportpb.BalDateObject.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCashBalance(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortMarketValue(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongMarketValue(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquity(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdjustedBalance(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBalanceSummaryReportIndex(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.BalanceSummaryReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.BalanceSummaryReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.BalanceSummaryReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.BalanceSummaryReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAccountBalancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.reportpb.AccountBalance.serializeBinaryToWriter
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDateType();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.reportpb.BalDateObject.serializeBinaryToWriter
    );
  }
  f = message.getCashBalance();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getEquity();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getAdjustedBalance();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getBalanceSummaryReportIndex();
  if (f !== 0) {
    writer.writeUint32(
      17,
      f
    );
  }
  f = message.getAccountCurrency();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.reportpb.BalanceSummaryReport.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.BalanceSummaryReport} returns this
 */
proto.reportpb.BalanceSummaryReport.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.reportpb.BalanceSummaryReport.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.BalanceSummaryReport} returns this
 */
proto.reportpb.BalanceSummaryReport.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.reportpb.BalanceSummaryReport.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.BalanceSummaryReport} returns this
 */
proto.reportpb.BalanceSummaryReport.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sub_account_no = 4;
 * @return {string}
 */
proto.reportpb.BalanceSummaryReport.prototype.getSubAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.BalanceSummaryReport} returns this
 */
proto.reportpb.BalanceSummaryReport.prototype.setSubAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_name = 5;
 * @return {string}
 */
proto.reportpb.BalanceSummaryReport.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.BalanceSummaryReport} returns this
 */
proto.reportpb.BalanceSummaryReport.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string master_account_no = 6;
 * @return {string}
 */
proto.reportpb.BalanceSummaryReport.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.BalanceSummaryReport} returns this
 */
proto.reportpb.BalanceSummaryReport.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated AccountBalance account_balances = 7;
 * @return {!Array<!proto.reportpb.AccountBalance>}
 */
proto.reportpb.BalanceSummaryReport.prototype.getAccountBalancesList = function() {
  return /** @type{!Array<!proto.reportpb.AccountBalance>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.AccountBalance, 7));
};


/**
 * @param {!Array<!proto.reportpb.AccountBalance>} value
 * @return {!proto.reportpb.BalanceSummaryReport} returns this
*/
proto.reportpb.BalanceSummaryReport.prototype.setAccountBalancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.reportpb.AccountBalance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.AccountBalance}
 */
proto.reportpb.BalanceSummaryReport.prototype.addAccountBalances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.reportpb.AccountBalance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.BalanceSummaryReport} returns this
 */
proto.reportpb.BalanceSummaryReport.prototype.clearAccountBalancesList = function() {
  return this.setAccountBalancesList([]);
};


/**
 * optional string broker = 8;
 * @return {string}
 */
proto.reportpb.BalanceSummaryReport.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.BalanceSummaryReport} returns this
 */
proto.reportpb.BalanceSummaryReport.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string type = 9;
 * @return {string}
 */
proto.reportpb.BalanceSummaryReport.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.BalanceSummaryReport} returns this
 */
proto.reportpb.BalanceSummaryReport.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string date_type = 10;
 * @return {string}
 */
proto.reportpb.BalanceSummaryReport.prototype.getDateType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.BalanceSummaryReport} returns this
 */
proto.reportpb.BalanceSummaryReport.prototype.setDateType = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional BalDateObject date = 11;
 * @return {?proto.reportpb.BalDateObject}
 */
proto.reportpb.BalanceSummaryReport.prototype.getDate = function() {
  return /** @type{?proto.reportpb.BalDateObject} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.BalDateObject, 11));
};


/**
 * @param {?proto.reportpb.BalDateObject|undefined} value
 * @return {!proto.reportpb.BalanceSummaryReport} returns this
*/
proto.reportpb.BalanceSummaryReport.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.BalanceSummaryReport} returns this
 */
proto.reportpb.BalanceSummaryReport.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.BalanceSummaryReport.prototype.hasDate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string cash_balance = 12;
 * @return {string}
 */
proto.reportpb.BalanceSummaryReport.prototype.getCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.BalanceSummaryReport} returns this
 */
proto.reportpb.BalanceSummaryReport.prototype.setCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string short_market_value = 13;
 * @return {string}
 */
proto.reportpb.BalanceSummaryReport.prototype.getShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.BalanceSummaryReport} returns this
 */
proto.reportpb.BalanceSummaryReport.prototype.setShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string long_market_value = 14;
 * @return {string}
 */
proto.reportpb.BalanceSummaryReport.prototype.getLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.BalanceSummaryReport} returns this
 */
proto.reportpb.BalanceSummaryReport.prototype.setLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string equity = 15;
 * @return {string}
 */
proto.reportpb.BalanceSummaryReport.prototype.getEquity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.BalanceSummaryReport} returns this
 */
proto.reportpb.BalanceSummaryReport.prototype.setEquity = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string adjusted_balance = 16;
 * @return {string}
 */
proto.reportpb.BalanceSummaryReport.prototype.getAdjustedBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.BalanceSummaryReport} returns this
 */
proto.reportpb.BalanceSummaryReport.prototype.setAdjustedBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional uint32 balance_summary_report_index = 17;
 * @return {number}
 */
proto.reportpb.BalanceSummaryReport.prototype.getBalanceSummaryReportIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.BalanceSummaryReport} returns this
 */
proto.reportpb.BalanceSummaryReport.prototype.setBalanceSummaryReportIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string account_currency = 18;
 * @return {string}
 */
proto.reportpb.BalanceSummaryReport.prototype.getAccountCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.BalanceSummaryReport} returns this
 */
proto.reportpb.BalanceSummaryReport.prototype.setAccountCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.AccountBalance.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.AccountBalance.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.AccountBalance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.AccountBalance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.AccountBalance.toObject = function(includeInstance, msg) {
  var f, obj = {
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 1, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cashBalance: jspb.Message.getFieldWithDefault(msg, 5, ""),
    shortMarketValue: jspb.Message.getFieldWithDefault(msg, 6, ""),
    longMarketValue: jspb.Message.getFieldWithDefault(msg, 7, ""),
    equity: jspb.Message.getFieldWithDefault(msg, 8, ""),
    subAccountBalancesList: jspb.Message.toObjectList(msg.getSubAccountBalancesList(),
    proto.reportpb.SubAccountBalance.toObject, includeInstance),
    balanceSummaryReportIndex: jspb.Message.getFieldWithDefault(msg, 10, 0),
    accountBalanceIndex: jspb.Message.getFieldWithDefault(msg, 11, 0),
    rep: jspb.Message.getFieldWithDefault(msg, 12, ""),
    branch: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.AccountBalance}
 */
proto.reportpb.AccountBalance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.AccountBalance;
  return proto.reportpb.AccountBalance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.AccountBalance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.AccountBalance}
 */
proto.reportpb.AccountBalance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCashBalance(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortMarketValue(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongMarketValue(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquity(value);
      break;
    case 9:
      var value = new proto.reportpb.SubAccountBalance;
      reader.readMessage(value,proto.reportpb.SubAccountBalance.deserializeBinaryFromReader);
      msg.addSubAccountBalances(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBalanceSummaryReportIndex(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountBalanceIndex(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setRep(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.AccountBalance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.AccountBalance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.AccountBalance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.AccountBalance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCashBalance();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEquity();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSubAccountBalancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.reportpb.SubAccountBalance.serializeBinaryToWriter
    );
  }
  f = message.getBalanceSummaryReportIndex();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getAccountBalanceIndex();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getRep();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string master_account_no = 1;
 * @return {string}
 */
proto.reportpb.AccountBalance.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountBalance} returns this
 */
proto.reportpb.AccountBalance.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.reportpb.AccountBalance.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountBalance} returns this
 */
proto.reportpb.AccountBalance.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.reportpb.AccountBalance.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountBalance} returns this
 */
proto.reportpb.AccountBalance.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_name = 4;
 * @return {string}
 */
proto.reportpb.AccountBalance.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountBalance} returns this
 */
proto.reportpb.AccountBalance.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string cash_balance = 5;
 * @return {string}
 */
proto.reportpb.AccountBalance.prototype.getCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountBalance} returns this
 */
proto.reportpb.AccountBalance.prototype.setCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string short_market_value = 6;
 * @return {string}
 */
proto.reportpb.AccountBalance.prototype.getShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountBalance} returns this
 */
proto.reportpb.AccountBalance.prototype.setShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string long_market_value = 7;
 * @return {string}
 */
proto.reportpb.AccountBalance.prototype.getLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountBalance} returns this
 */
proto.reportpb.AccountBalance.prototype.setLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string equity = 8;
 * @return {string}
 */
proto.reportpb.AccountBalance.prototype.getEquity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountBalance} returns this
 */
proto.reportpb.AccountBalance.prototype.setEquity = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated SubAccountBalance sub_account_balances = 9;
 * @return {!Array<!proto.reportpb.SubAccountBalance>}
 */
proto.reportpb.AccountBalance.prototype.getSubAccountBalancesList = function() {
  return /** @type{!Array<!proto.reportpb.SubAccountBalance>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.SubAccountBalance, 9));
};


/**
 * @param {!Array<!proto.reportpb.SubAccountBalance>} value
 * @return {!proto.reportpb.AccountBalance} returns this
*/
proto.reportpb.AccountBalance.prototype.setSubAccountBalancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.reportpb.SubAccountBalance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.SubAccountBalance}
 */
proto.reportpb.AccountBalance.prototype.addSubAccountBalances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.reportpb.SubAccountBalance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.AccountBalance} returns this
 */
proto.reportpb.AccountBalance.prototype.clearSubAccountBalancesList = function() {
  return this.setSubAccountBalancesList([]);
};


/**
 * optional uint32 balance_summary_report_index = 10;
 * @return {number}
 */
proto.reportpb.AccountBalance.prototype.getBalanceSummaryReportIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.AccountBalance} returns this
 */
proto.reportpb.AccountBalance.prototype.setBalanceSummaryReportIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 account_balance_index = 11;
 * @return {number}
 */
proto.reportpb.AccountBalance.prototype.getAccountBalanceIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.AccountBalance} returns this
 */
proto.reportpb.AccountBalance.prototype.setAccountBalanceIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string rep = 12;
 * @return {string}
 */
proto.reportpb.AccountBalance.prototype.getRep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountBalance} returns this
 */
proto.reportpb.AccountBalance.prototype.setRep = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string branch = 13;
 * @return {string}
 */
proto.reportpb.AccountBalance.prototype.getBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountBalance} returns this
 */
proto.reportpb.AccountBalance.prototype.setBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.SubAccountBalance.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.SubAccountBalance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.SubAccountBalance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.SubAccountBalance.toObject = function(includeInstance, msg) {
  var f, obj = {
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subAccountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cashBalance: jspb.Message.getFieldWithDefault(msg, 4, ""),
    shortMarketValue: jspb.Message.getFieldWithDefault(msg, 5, ""),
    longMarketValue: jspb.Message.getFieldWithDefault(msg, 6, ""),
    equity: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.SubAccountBalance}
 */
proto.reportpb.SubAccountBalance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.SubAccountBalance;
  return proto.reportpb.SubAccountBalance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.SubAccountBalance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.SubAccountBalance}
 */
proto.reportpb.SubAccountBalance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCashBalance(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortMarketValue(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongMarketValue(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.SubAccountBalance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.SubAccountBalance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.SubAccountBalance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.SubAccountBalance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCashBalance();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEquity();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string master_account_no = 1;
 * @return {string}
 */
proto.reportpb.SubAccountBalance.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SubAccountBalance} returns this
 */
proto.reportpb.SubAccountBalance.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.reportpb.SubAccountBalance.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SubAccountBalance} returns this
 */
proto.reportpb.SubAccountBalance.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sub_account_no = 3;
 * @return {string}
 */
proto.reportpb.SubAccountBalance.prototype.getSubAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SubAccountBalance} returns this
 */
proto.reportpb.SubAccountBalance.prototype.setSubAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string cash_balance = 4;
 * @return {string}
 */
proto.reportpb.SubAccountBalance.prototype.getCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SubAccountBalance} returns this
 */
proto.reportpb.SubAccountBalance.prototype.setCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string short_market_value = 5;
 * @return {string}
 */
proto.reportpb.SubAccountBalance.prototype.getShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SubAccountBalance} returns this
 */
proto.reportpb.SubAccountBalance.prototype.setShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string long_market_value = 6;
 * @return {string}
 */
proto.reportpb.SubAccountBalance.prototype.getLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SubAccountBalance} returns this
 */
proto.reportpb.SubAccountBalance.prototype.setLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string equity = 7;
 * @return {string}
 */
proto.reportpb.SubAccountBalance.prototype.getEquity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SubAccountBalance} returns this
 */
proto.reportpb.SubAccountBalance.prototype.setEquity = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.BalDateObject.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.BalDateObject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.BalDateObject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.BalDateObject.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0),
    day: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.BalDateObject}
 */
proto.reportpb.BalDateObject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.BalDateObject;
  return proto.reportpb.BalDateObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.BalDateObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.BalDateObject}
 */
proto.reportpb.BalDateObject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.BalDateObject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.BalDateObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.BalDateObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.BalDateObject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 year = 1;
 * @return {number}
 */
proto.reportpb.BalDateObject.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.BalDateObject} returns this
 */
proto.reportpb.BalDateObject.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 month = 2;
 * @return {number}
 */
proto.reportpb.BalDateObject.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.BalDateObject} returns this
 */
proto.reportpb.BalDateObject.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 day = 3;
 * @return {number}
 */
proto.reportpb.BalDateObject.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.BalDateObject} returns this
 */
proto.reportpb.BalDateObject.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


goog.object.extend(exports, proto.reportpb);
