// source: proto/accountpb/client.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_accountpb_owner_pb = require('../../proto/accountpb/owner_pb.js');
goog.object.extend(proto, proto_accountpb_owner_pb);
var proto_accountpb_address_pb = require('../../proto/accountpb/address_pb.js');
goog.object.extend(proto, proto_accountpb_address_pb);
var proto_accountpb_contactinfo_pb = require('../../proto/accountpb/contactinfo_pb.js');
goog.object.extend(proto, proto_accountpb_contactinfo_pb);
var proto_accountpb_identification_pb = require('../../proto/accountpb/identification_pb.js');
goog.object.extend(proto, proto_accountpb_identification_pb);
var proto_accountpb_note_pb = require('../../proto/accountpb/note_pb.js');
goog.object.extend(proto, proto_accountpb_note_pb);
var proto_accountpb_subaccount_pb = require('../../proto/accountpb/subaccount_pb.js');
goog.object.extend(proto, proto_accountpb_subaccount_pb);
var proto_utilspb_pagination_pb = require('../../proto/utilspb/pagination_pb.js');
goog.object.extend(proto, proto_utilspb_pagination_pb);
goog.exportSymbol('proto.accountpb.AuditLog', null, global);
goog.exportSymbol('proto.accountpb.Client', null, global);
goog.exportSymbol('proto.accountpb.CreateClientResponse', null, global);
goog.exportSymbol('proto.accountpb.DateObject', null, global);
goog.exportSymbol('proto.accountpb.EmptyRequest', null, global);
goog.exportSymbol('proto.accountpb.EmptyResponse', null, global);
goog.exportSymbol('proto.accountpb.GetAccountDetailsRequest', null, global);
goog.exportSymbol('proto.accountpb.GetAccountDetailsResponse', null, global);
goog.exportSymbol('proto.accountpb.GetAccountIdRequest', null, global);
goog.exportSymbol('proto.accountpb.GetAccountIdResponse', null, global);
goog.exportSymbol('proto.accountpb.GetNewClientIdRequest', null, global);
goog.exportSymbol('proto.accountpb.GetNewClientIdResponse', null, global);
goog.exportSymbol('proto.accountpb.ListClientAuditRequest', null, global);
goog.exportSymbol('proto.accountpb.ListClientAuditResponse', null, global);
goog.exportSymbol('proto.accountpb.ListClientRequest', null, global);
goog.exportSymbol('proto.accountpb.ListClientResponse', null, global);
goog.exportSymbol('proto.accountpb.ListClientSummary', null, global);
goog.exportSymbol('proto.accountpb.ListSelectionItemResponse', null, global);
goog.exportSymbol('proto.accountpb.ListSubAccountTypeItemRequest', null, global);
goog.exportSymbol('proto.accountpb.ReadClientByUserRequest', null, global);
goog.exportSymbol('proto.accountpb.ReadClientRequest', null, global);
goog.exportSymbol('proto.accountpb.ReadClientResponse', null, global);
goog.exportSymbol('proto.accountpb.SelectionItem', null, global);
goog.exportSymbol('proto.accountpb.UpdateClientResponse', null, global);
goog.exportSymbol('proto.accountpb.ValidateAccountRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.Client = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.Client, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.Client.displayName = 'proto.accountpb.Client';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.DateObject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.DateObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.DateObject.displayName = 'proto.accountpb.DateObject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ReadClientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ReadClientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ReadClientRequest.displayName = 'proto.accountpb.ReadClientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ReadClientByUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ReadClientByUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ReadClientByUserRequest.displayName = 'proto.accountpb.ReadClientByUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ReadClientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.accountpb.ReadClientResponse.repeatedFields_, null);
};
goog.inherits(proto.accountpb.ReadClientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ReadClientResponse.displayName = 'proto.accountpb.ReadClientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.CreateClientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.CreateClientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.CreateClientResponse.displayName = 'proto.accountpb.CreateClientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.UpdateClientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.UpdateClientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.UpdateClientResponse.displayName = 'proto.accountpb.UpdateClientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.GetNewClientIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.GetNewClientIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.GetNewClientIdRequest.displayName = 'proto.accountpb.GetNewClientIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.GetNewClientIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.GetNewClientIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.GetNewClientIdResponse.displayName = 'proto.accountpb.GetNewClientIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.GetAccountIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.GetAccountIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.GetAccountIdRequest.displayName = 'proto.accountpb.GetAccountIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.GetAccountIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.GetAccountIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.GetAccountIdResponse.displayName = 'proto.accountpb.GetAccountIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ListClientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ListClientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ListClientRequest.displayName = 'proto.accountpb.ListClientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.AuditLog = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.AuditLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.AuditLog.displayName = 'proto.accountpb.AuditLog';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ListClientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.accountpb.ListClientResponse.repeatedFields_, null);
};
goog.inherits(proto.accountpb.ListClientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ListClientResponse.displayName = 'proto.accountpb.ListClientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ValidateAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ValidateAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ValidateAccountRequest.displayName = 'proto.accountpb.ValidateAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.EmptyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.EmptyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.EmptyResponse.displayName = 'proto.accountpb.EmptyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.EmptyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.EmptyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.EmptyRequest.displayName = 'proto.accountpb.EmptyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.SelectionItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.SelectionItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.SelectionItem.displayName = 'proto.accountpb.SelectionItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ListSelectionItemResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.accountpb.ListSelectionItemResponse.repeatedFields_, null);
};
goog.inherits(proto.accountpb.ListSelectionItemResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ListSelectionItemResponse.displayName = 'proto.accountpb.ListSelectionItemResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ListSubAccountTypeItemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ListSubAccountTypeItemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ListSubAccountTypeItemRequest.displayName = 'proto.accountpb.ListSubAccountTypeItemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ListClientAuditRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ListClientAuditRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ListClientAuditRequest.displayName = 'proto.accountpb.ListClientAuditRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ListClientAuditResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.accountpb.ListClientAuditResponse.repeatedFields_, null);
};
goog.inherits(proto.accountpb.ListClientAuditResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ListClientAuditResponse.displayName = 'proto.accountpb.ListClientAuditResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.GetAccountDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.GetAccountDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.GetAccountDetailsRequest.displayName = 'proto.accountpb.GetAccountDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.GetAccountDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.accountpb.GetAccountDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.accountpb.GetAccountDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.GetAccountDetailsResponse.displayName = 'proto.accountpb.GetAccountDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ListClientSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ListClientSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ListClientSummary.displayName = 'proto.accountpb.ListClientSummary';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.Client.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.Client.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.Client} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.Client.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    status: jspb.Message.getFieldWithDefault(msg, 6, ""),
    activatedAt: jspb.Message.getFieldWithDefault(msg, 7, ""),
    disabledAt: jspb.Message.getFieldWithDefault(msg, 8, ""),
    broker: jspb.Message.getFieldWithDefault(msg, 9, ""),
    fpslParticipant: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    privacyConsent: jspb.Message.getFieldWithDefault(msg, 11, ""),
    dayTrader: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    multiplier: jspb.Message.getFieldWithDefault(msg, 13, 0),
    investmentObjectiveCode: jspb.Message.getFieldWithDefault(msg, 14, ""),
    investmentObjectiveDate: (f = msg.getInvestmentObjectiveDate()) && proto.accountpb.DateObject.toObject(includeInstance, f),
    legalEntity: jspb.Message.getFieldWithDefault(msg, 16, ""),
    taxCountry: jspb.Message.getFieldWithDefault(msg, 17, ""),
    w8w9: jspb.Message.getFieldWithDefault(msg, 18, ""),
    w8w9ReceivedDate: (f = msg.getW8w9ReceivedDate()) && proto.accountpb.DateObject.toObject(includeInstance, f),
    tefra: jspb.Message.getFieldWithDefault(msg, 20, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 21, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 22, ""),
    accreditedInvestor: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    fdidFlag: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    fdid: jspb.Message.getFieldWithDefault(msg, 25, ""),
    fdidEndReason: jspb.Message.getFieldWithDefault(msg, 26, ""),
    branch: jspb.Message.getFieldWithDefault(msg, 27, ""),
    rep: jspb.Message.getFieldWithDefault(msg, 28, ""),
    accountType: jspb.Message.getFieldWithDefault(msg, 29, ""),
    marginType: jspb.Message.getFieldWithDefault(msg, 30, ""),
    largeTraderId: jspb.Message.getFieldWithDefault(msg, 31, ""),
    subAccounts: jspb.Message.getFieldWithDefault(msg, 32, ""),
    batchNo: jspb.Message.getFieldWithDefault(msg, 33, ""),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 34, ""),
    validationStatus: jspb.Message.getFieldWithDefault(msg, 35, ""),
    commonId: jspb.Message.getFieldWithDefault(msg, 36, ""),
    modifiedAt: jspb.Message.getFieldWithDefault(msg, 37, ""),
    modifiedBy: jspb.Message.getFieldWithDefault(msg, 38, ""),
    subType: jspb.Message.getFieldWithDefault(msg, 39, ""),
    statusReason: jspb.Message.getFieldWithDefault(msg, 40, ""),
    allowLiquidInvestment: jspb.Message.getBooleanFieldWithDefault(msg, 41, false),
    webTermConditions: jspb.Message.getFieldWithDefault(msg, 42, ""),
    disclosureStatement: jspb.Message.getFieldWithDefault(msg, 43, ""),
    feeSchedule: jspb.Message.getFieldWithDefault(msg, 44, ""),
    beneficiary: jspb.Message.getFieldWithDefault(msg, 45, ""),
    primeBroker: jspb.Message.getFieldWithDefault(msg, 46, ""),
    participantNo: jspb.Message.getFieldWithDefault(msg, 47, ""),
    executingBroker: jspb.Message.getFieldWithDefault(msg, 48, ""),
    institutionNo: jspb.Message.getFieldWithDefault(msg, 49, ""),
    agentNo: jspb.Message.getFieldWithDefault(msg, 50, ""),
    agentAccountNo: jspb.Message.getFieldWithDefault(msg, 51, ""),
    traderId: jspb.Message.getFieldWithDefault(msg, 52, ""),
    marginDisclosure: jspb.Message.getBooleanFieldWithDefault(msg, 53, false),
    stocks: jspb.Message.getBooleanFieldWithDefault(msg, 54, false),
    etf: jspb.Message.getBooleanFieldWithDefault(msg, 55, false),
    option: jspb.Message.getBooleanFieldWithDefault(msg, 56, false),
    annualIncome: jspb.Message.getFieldWithDefault(msg, 57, ""),
    liquidNetWorth: jspb.Message.getFieldWithDefault(msg, 58, ""),
    sourceOfFunds: jspb.Message.getFieldWithDefault(msg, 59, ""),
    signed: jspb.Message.getBooleanFieldWithDefault(msg, 60, false),
    internalStatus: jspb.Message.getFieldWithDefault(msg, 61, ""),
    imgSignature: jspb.Message.getFieldWithDefault(msg, 62, ""),
    marginAgreement: jspb.Message.getBooleanFieldWithDefault(msg, 63, false),
    kycStatus: jspb.Message.getFieldWithDefault(msg, 64, ""),
    tin: jspb.Message.getFieldWithDefault(msg, 65, ""),
    formationCountry: jspb.Message.getFieldWithDefault(msg, 66, ""),
    formationState: jspb.Message.getFieldWithDefault(msg, 67, ""),
    formationDate: (f = msg.getFormationDate()) && proto.accountpb.DateObject.toObject(includeInstance, f),
    currency: jspb.Message.getFieldWithDefault(msg, 69, ""),
    serviceBureau: jspb.Message.getFieldWithDefault(msg, 70, ""),
    promoCode: jspb.Message.getFieldWithDefault(msg, 71, ""),
    securityWord: jspb.Message.getFieldWithDefault(msg, 72, ""),
    securityWordHint: jspb.Message.getFieldWithDefault(msg, 73, ""),
    taxBracket: jspb.Message.getFieldWithDefault(msg, 74, 0),
    riskProfile: jspb.Message.getFieldWithDefault(msg, 75, ""),
    cashSweepParticipant: jspb.Message.getBooleanFieldWithDefault(msg, 76, false),
    plType: jspb.Message.getFieldWithDefault(msg, 77, ""),
    onboardingPercent: jspb.Message.getFieldWithDefault(msg, 78, ""),
    mutualFunds: jspb.Message.getBooleanFieldWithDefault(msg, 79, false),
    fixedIncome: jspb.Message.getBooleanFieldWithDefault(msg, 80, false),
    emailExist: jspb.Message.getBooleanFieldWithDefault(msg, 81, false),
    previousAccountNo: jspb.Message.getFieldWithDefault(msg, 82, ""),
    statement: jspb.Message.getFieldWithDefault(msg, 83, ""),
    tradeConfirms: jspb.Message.getFieldWithDefault(msg, 84, ""),
    proxyAndProspectus: jspb.Message.getFieldWithDefault(msg, 85, ""),
    taxForms: jspb.Message.getFieldWithDefault(msg, 86, ""),
    miscLetters: jspb.Message.getFieldWithDefault(msg, 87, ""),
    emailAddress: jspb.Message.getFieldWithDefault(msg, 88, ""),
    isRestricted: jspb.Message.getBooleanFieldWithDefault(msg, 89, false),
    restrictedReason: jspb.Message.getFieldWithDefault(msg, 90, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 91, ""),
    sweepType: jspb.Message.getFieldWithDefault(msg, 92, ""),
    accountDiscretion: jspb.Message.getFieldWithDefault(msg, 93, ""),
    nsccAccountLine: jspb.Message.getFieldWithDefault(msg, 94, ""),
    customerAccountAgreement: jspb.Message.getBooleanFieldWithDefault(msg, 95, false),
    accountPurpose: jspb.Message.getFieldWithDefault(msg, 96, ""),
    accountPurposeDescription: jspb.Message.getFieldWithDefault(msg, 97, ""),
    sourceOfFundsDescription: jspb.Message.getFieldWithDefault(msg, 98, ""),
    tradingFrequency: jspb.Message.getFieldWithDefault(msg, 99, ""),
    totalNetWorth: jspb.Message.getFieldWithDefault(msg, 100, ""),
    investmentExperience: jspb.Message.getFieldWithDefault(msg, 101, ""),
    custodialType: jspb.Message.getFieldWithDefault(msg, 102, ""),
    margin: jspb.Message.getBooleanFieldWithDefault(msg, 103, false),
    cash: jspb.Message.getBooleanFieldWithDefault(msg, 104, false),
    limitedMargin: jspb.Message.getBooleanFieldWithDefault(msg, 105, false),
    w8w9Certified: jspb.Message.getBooleanFieldWithDefault(msg, 106, false),
    receipentCode: jspb.Message.getFieldWithDefault(msg, 107, ""),
    irsCodeCh3: jspb.Message.getFieldWithDefault(msg, 108, ""),
    irsCodeCh4: jspb.Message.getFieldWithDefault(msg, 109, ""),
    taxRateOverrideRate: jspb.Message.getFieldWithDefault(msg, 110, ""),
    fatca: jspb.Message.getBooleanFieldWithDefault(msg, 111, false),
    exemptPayeeCode: jspb.Message.getFieldWithDefault(msg, 112, ""),
    fatcaExemptCode: jspb.Message.getFieldWithDefault(msg, 113, ""),
    disregardEntity: jspb.Message.getFieldWithDefault(msg, 114, ""),
    w8Type: jspb.Message.getFieldWithDefault(msg, 115, ""),
    isFrozen: jspb.Message.getBooleanFieldWithDefault(msg, 116, false),
    governmentIdType: jspb.Message.getFieldWithDefault(msg, 117, ""),
    onboardingError: jspb.Message.getFieldWithDefault(msg, 118, ""),
    tenant: jspb.Message.getFieldWithDefault(msg, 119, ""),
    inactiveAt: jspb.Message.getFieldWithDefault(msg, 120, ""),
    registrationType: jspb.Message.getFieldWithDefault(msg, 121, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.Client}
 */
proto.accountpb.Client.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.Client;
  return proto.accountpb.Client.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.Client} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.Client}
 */
proto.accountpb.Client.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivatedAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisabledAt(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFpslParticipant(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivacyConsent(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDayTrader(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMultiplier(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvestmentObjectiveCode(value);
      break;
    case 15:
      var value = new proto.accountpb.DateObject;
      reader.readMessage(value,proto.accountpb.DateObject.deserializeBinaryFromReader);
      msg.setInvestmentObjectiveDate(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalEntity(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxCountry(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setW8w9(value);
      break;
    case 19:
      var value = new proto.accountpb.DateObject;
      reader.readMessage(value,proto.accountpb.DateObject.deserializeBinaryFromReader);
      msg.setW8w9ReceivedDate(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setTefra(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAccreditedInvestor(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFdidFlag(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setFdid(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setFdidEndReason(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranch(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setRep(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountType(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarginType(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setLargeTraderId(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubAccounts(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchNo(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setValidationStatus(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommonId(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifiedAt(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifiedBy(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubType(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusReason(value);
      break;
    case 41:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowLiquidInvestment(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebTermConditions(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisclosureStatement(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeSchedule(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setBeneficiary(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrimeBroker(value);
      break;
    case 47:
      var value = /** @type {string} */ (reader.readString());
      msg.setParticipantNo(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutingBroker(value);
      break;
    case 49:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstitutionNo(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentNo(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentAccountNo(value);
      break;
    case 52:
      var value = /** @type {string} */ (reader.readString());
      msg.setTraderId(value);
      break;
    case 53:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMarginDisclosure(value);
      break;
    case 54:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStocks(value);
      break;
    case 55:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEtf(value);
      break;
    case 56:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOption(value);
      break;
    case 57:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnnualIncome(value);
      break;
    case 58:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiquidNetWorth(value);
      break;
    case 59:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceOfFunds(value);
      break;
    case 60:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSigned(value);
      break;
    case 61:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalStatus(value);
      break;
    case 62:
      var value = /** @type {string} */ (reader.readString());
      msg.setImgSignature(value);
      break;
    case 63:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMarginAgreement(value);
      break;
    case 64:
      var value = /** @type {string} */ (reader.readString());
      msg.setKycStatus(value);
      break;
    case 65:
      var value = /** @type {string} */ (reader.readString());
      msg.setTin(value);
      break;
    case 66:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormationCountry(value);
      break;
    case 67:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormationState(value);
      break;
    case 68:
      var value = new proto.accountpb.DateObject;
      reader.readMessage(value,proto.accountpb.DateObject.deserializeBinaryFromReader);
      msg.setFormationDate(value);
      break;
    case 69:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 70:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceBureau(value);
      break;
    case 71:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromoCode(value);
      break;
    case 72:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecurityWord(value);
      break;
    case 73:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecurityWordHint(value);
      break;
    case 74:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTaxBracket(value);
      break;
    case 75:
      var value = /** @type {string} */ (reader.readString());
      msg.setRiskProfile(value);
      break;
    case 76:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCashSweepParticipant(value);
      break;
    case 77:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlType(value);
      break;
    case 78:
      var value = /** @type {string} */ (reader.readString());
      msg.setOnboardingPercent(value);
      break;
    case 79:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMutualFunds(value);
      break;
    case 80:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFixedIncome(value);
      break;
    case 81:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmailExist(value);
      break;
    case 82:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreviousAccountNo(value);
      break;
    case 83:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatement(value);
      break;
    case 84:
      var value = /** @type {string} */ (reader.readString());
      msg.setTradeConfirms(value);
      break;
    case 85:
      var value = /** @type {string} */ (reader.readString());
      msg.setProxyAndProspectus(value);
      break;
    case 86:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxForms(value);
      break;
    case 87:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiscLetters(value);
      break;
    case 88:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    case 89:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRestricted(value);
      break;
    case 90:
      var value = /** @type {string} */ (reader.readString());
      msg.setRestrictedReason(value);
      break;
    case 91:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 92:
      var value = /** @type {string} */ (reader.readString());
      msg.setSweepType(value);
      break;
    case 93:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountDiscretion(value);
      break;
    case 94:
      var value = /** @type {string} */ (reader.readString());
      msg.setNsccAccountLine(value);
      break;
    case 95:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCustomerAccountAgreement(value);
      break;
    case 96:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountPurpose(value);
      break;
    case 97:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountPurposeDescription(value);
      break;
    case 98:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceOfFundsDescription(value);
      break;
    case 99:
      var value = /** @type {string} */ (reader.readString());
      msg.setTradingFrequency(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.setTotalNetWorth(value);
      break;
    case 101:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvestmentExperience(value);
      break;
    case 102:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustodialType(value);
      break;
    case 103:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMargin(value);
      break;
    case 104:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCash(value);
      break;
    case 105:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLimitedMargin(value);
      break;
    case 106:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setW8w9Certified(value);
      break;
    case 107:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceipentCode(value);
      break;
    case 108:
      var value = /** @type {string} */ (reader.readString());
      msg.setIrsCodeCh3(value);
      break;
    case 109:
      var value = /** @type {string} */ (reader.readString());
      msg.setIrsCodeCh4(value);
      break;
    case 110:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxRateOverrideRate(value);
      break;
    case 111:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFatca(value);
      break;
    case 112:
      var value = /** @type {string} */ (reader.readString());
      msg.setExemptPayeeCode(value);
      break;
    case 113:
      var value = /** @type {string} */ (reader.readString());
      msg.setFatcaExemptCode(value);
      break;
    case 114:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisregardEntity(value);
      break;
    case 115:
      var value = /** @type {string} */ (reader.readString());
      msg.setW8Type(value);
      break;
    case 116:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFrozen(value);
      break;
    case 117:
      var value = /** @type {string} */ (reader.readString());
      msg.setGovernmentIdType(value);
      break;
    case 118:
      var value = /** @type {string} */ (reader.readString());
      msg.setOnboardingError(value);
      break;
    case 119:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenant(value);
      break;
    case 120:
      var value = /** @type {string} */ (reader.readString());
      msg.setInactiveAt(value);
      break;
    case 121:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistrationType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.Client.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.Client.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.Client} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.Client.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getActivatedAt();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDisabledAt();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getFpslParticipant();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getPrivacyConsent();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDayTrader();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getMultiplier();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getInvestmentObjectiveCode();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getInvestmentObjectiveDate();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.accountpb.DateObject.serializeBinaryToWriter
    );
  }
  f = message.getLegalEntity();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getTaxCountry();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getW8w9();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getW8w9ReceivedDate();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.accountpb.DateObject.serializeBinaryToWriter
    );
  }
  f = message.getTefra();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getAccreditedInvestor();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getFdidFlag();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getFdid();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getFdidEndReason();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getRep();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getAccountType();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getMarginType();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getLargeTraderId();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getSubAccounts();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getBatchNo();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getValidationStatus();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getCommonId();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getModifiedAt();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getModifiedBy();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getSubType();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getStatusReason();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getAllowLiquidInvestment();
  if (f) {
    writer.writeBool(
      41,
      f
    );
  }
  f = message.getWebTermConditions();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getDisclosureStatement();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getFeeSchedule();
  if (f.length > 0) {
    writer.writeString(
      44,
      f
    );
  }
  f = message.getBeneficiary();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getPrimeBroker();
  if (f.length > 0) {
    writer.writeString(
      46,
      f
    );
  }
  f = message.getParticipantNo();
  if (f.length > 0) {
    writer.writeString(
      47,
      f
    );
  }
  f = message.getExecutingBroker();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getInstitutionNo();
  if (f.length > 0) {
    writer.writeString(
      49,
      f
    );
  }
  f = message.getAgentNo();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getAgentAccountNo();
  if (f.length > 0) {
    writer.writeString(
      51,
      f
    );
  }
  f = message.getTraderId();
  if (f.length > 0) {
    writer.writeString(
      52,
      f
    );
  }
  f = message.getMarginDisclosure();
  if (f) {
    writer.writeBool(
      53,
      f
    );
  }
  f = message.getStocks();
  if (f) {
    writer.writeBool(
      54,
      f
    );
  }
  f = message.getEtf();
  if (f) {
    writer.writeBool(
      55,
      f
    );
  }
  f = message.getOption();
  if (f) {
    writer.writeBool(
      56,
      f
    );
  }
  f = message.getAnnualIncome();
  if (f.length > 0) {
    writer.writeString(
      57,
      f
    );
  }
  f = message.getLiquidNetWorth();
  if (f.length > 0) {
    writer.writeString(
      58,
      f
    );
  }
  f = message.getSourceOfFunds();
  if (f.length > 0) {
    writer.writeString(
      59,
      f
    );
  }
  f = message.getSigned();
  if (f) {
    writer.writeBool(
      60,
      f
    );
  }
  f = message.getInternalStatus();
  if (f.length > 0) {
    writer.writeString(
      61,
      f
    );
  }
  f = message.getImgSignature();
  if (f.length > 0) {
    writer.writeString(
      62,
      f
    );
  }
  f = message.getMarginAgreement();
  if (f) {
    writer.writeBool(
      63,
      f
    );
  }
  f = message.getKycStatus();
  if (f.length > 0) {
    writer.writeString(
      64,
      f
    );
  }
  f = message.getTin();
  if (f.length > 0) {
    writer.writeString(
      65,
      f
    );
  }
  f = message.getFormationCountry();
  if (f.length > 0) {
    writer.writeString(
      66,
      f
    );
  }
  f = message.getFormationState();
  if (f.length > 0) {
    writer.writeString(
      67,
      f
    );
  }
  f = message.getFormationDate();
  if (f != null) {
    writer.writeMessage(
      68,
      f,
      proto.accountpb.DateObject.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      69,
      f
    );
  }
  f = message.getServiceBureau();
  if (f.length > 0) {
    writer.writeString(
      70,
      f
    );
  }
  f = message.getPromoCode();
  if (f.length > 0) {
    writer.writeString(
      71,
      f
    );
  }
  f = message.getSecurityWord();
  if (f.length > 0) {
    writer.writeString(
      72,
      f
    );
  }
  f = message.getSecurityWordHint();
  if (f.length > 0) {
    writer.writeString(
      73,
      f
    );
  }
  f = message.getTaxBracket();
  if (f !== 0) {
    writer.writeInt32(
      74,
      f
    );
  }
  f = message.getRiskProfile();
  if (f.length > 0) {
    writer.writeString(
      75,
      f
    );
  }
  f = message.getCashSweepParticipant();
  if (f) {
    writer.writeBool(
      76,
      f
    );
  }
  f = message.getPlType();
  if (f.length > 0) {
    writer.writeString(
      77,
      f
    );
  }
  f = message.getOnboardingPercent();
  if (f.length > 0) {
    writer.writeString(
      78,
      f
    );
  }
  f = message.getMutualFunds();
  if (f) {
    writer.writeBool(
      79,
      f
    );
  }
  f = message.getFixedIncome();
  if (f) {
    writer.writeBool(
      80,
      f
    );
  }
  f = message.getEmailExist();
  if (f) {
    writer.writeBool(
      81,
      f
    );
  }
  f = message.getPreviousAccountNo();
  if (f.length > 0) {
    writer.writeString(
      82,
      f
    );
  }
  f = message.getStatement();
  if (f.length > 0) {
    writer.writeString(
      83,
      f
    );
  }
  f = message.getTradeConfirms();
  if (f.length > 0) {
    writer.writeString(
      84,
      f
    );
  }
  f = message.getProxyAndProspectus();
  if (f.length > 0) {
    writer.writeString(
      85,
      f
    );
  }
  f = message.getTaxForms();
  if (f.length > 0) {
    writer.writeString(
      86,
      f
    );
  }
  f = message.getMiscLetters();
  if (f.length > 0) {
    writer.writeString(
      87,
      f
    );
  }
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      88,
      f
    );
  }
  f = message.getIsRestricted();
  if (f) {
    writer.writeBool(
      89,
      f
    );
  }
  f = message.getRestrictedReason();
  if (f.length > 0) {
    writer.writeString(
      90,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      91,
      f
    );
  }
  f = message.getSweepType();
  if (f.length > 0) {
    writer.writeString(
      92,
      f
    );
  }
  f = message.getAccountDiscretion();
  if (f.length > 0) {
    writer.writeString(
      93,
      f
    );
  }
  f = message.getNsccAccountLine();
  if (f.length > 0) {
    writer.writeString(
      94,
      f
    );
  }
  f = message.getCustomerAccountAgreement();
  if (f) {
    writer.writeBool(
      95,
      f
    );
  }
  f = message.getAccountPurpose();
  if (f.length > 0) {
    writer.writeString(
      96,
      f
    );
  }
  f = message.getAccountPurposeDescription();
  if (f.length > 0) {
    writer.writeString(
      97,
      f
    );
  }
  f = message.getSourceOfFundsDescription();
  if (f.length > 0) {
    writer.writeString(
      98,
      f
    );
  }
  f = message.getTradingFrequency();
  if (f.length > 0) {
    writer.writeString(
      99,
      f
    );
  }
  f = message.getTotalNetWorth();
  if (f.length > 0) {
    writer.writeString(
      100,
      f
    );
  }
  f = message.getInvestmentExperience();
  if (f.length > 0) {
    writer.writeString(
      101,
      f
    );
  }
  f = message.getCustodialType();
  if (f.length > 0) {
    writer.writeString(
      102,
      f
    );
  }
  f = message.getMargin();
  if (f) {
    writer.writeBool(
      103,
      f
    );
  }
  f = message.getCash();
  if (f) {
    writer.writeBool(
      104,
      f
    );
  }
  f = message.getLimitedMargin();
  if (f) {
    writer.writeBool(
      105,
      f
    );
  }
  f = message.getW8w9Certified();
  if (f) {
    writer.writeBool(
      106,
      f
    );
  }
  f = message.getReceipentCode();
  if (f.length > 0) {
    writer.writeString(
      107,
      f
    );
  }
  f = message.getIrsCodeCh3();
  if (f.length > 0) {
    writer.writeString(
      108,
      f
    );
  }
  f = message.getIrsCodeCh4();
  if (f.length > 0) {
    writer.writeString(
      109,
      f
    );
  }
  f = message.getTaxRateOverrideRate();
  if (f.length > 0) {
    writer.writeString(
      110,
      f
    );
  }
  f = message.getFatca();
  if (f) {
    writer.writeBool(
      111,
      f
    );
  }
  f = message.getExemptPayeeCode();
  if (f.length > 0) {
    writer.writeString(
      112,
      f
    );
  }
  f = message.getFatcaExemptCode();
  if (f.length > 0) {
    writer.writeString(
      113,
      f
    );
  }
  f = message.getDisregardEntity();
  if (f.length > 0) {
    writer.writeString(
      114,
      f
    );
  }
  f = message.getW8Type();
  if (f.length > 0) {
    writer.writeString(
      115,
      f
    );
  }
  f = message.getIsFrozen();
  if (f) {
    writer.writeBool(
      116,
      f
    );
  }
  f = message.getGovernmentIdType();
  if (f.length > 0) {
    writer.writeString(
      117,
      f
    );
  }
  f = message.getOnboardingError();
  if (f.length > 0) {
    writer.writeString(
      118,
      f
    );
  }
  f = message.getTenant();
  if (f.length > 0) {
    writer.writeString(
      119,
      f
    );
  }
  f = message.getInactiveAt();
  if (f.length > 0) {
    writer.writeString(
      120,
      f
    );
  }
  f = message.getRegistrationType();
  if (f.length > 0) {
    writer.writeString(
      121,
      f
    );
  }
};


/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.accountpb.Client.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.accountpb.Client.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.accountpb.Client.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_name = 4;
 * @return {string}
 */
proto.accountpb.Client.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string master_account_no = 5;
 * @return {string}
 */
proto.accountpb.Client.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string status = 6;
 * @return {string}
 */
proto.accountpb.Client.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string activated_at = 7;
 * @return {string}
 */
proto.accountpb.Client.prototype.getActivatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setActivatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string disabled_at = 8;
 * @return {string}
 */
proto.accountpb.Client.prototype.getDisabledAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setDisabledAt = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string broker = 9;
 * @return {string}
 */
proto.accountpb.Client.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool fpsl_participant = 10;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getFpslParticipant = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setFpslParticipant = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string privacy_consent = 11;
 * @return {string}
 */
proto.accountpb.Client.prototype.getPrivacyConsent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setPrivacyConsent = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool day_trader = 12;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getDayTrader = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setDayTrader = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional int32 multiplier = 13;
 * @return {number}
 */
proto.accountpb.Client.prototype.getMultiplier = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setMultiplier = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string investment_objective_code = 14;
 * @return {string}
 */
proto.accountpb.Client.prototype.getInvestmentObjectiveCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setInvestmentObjectiveCode = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional DateObject investment_objective_date = 15;
 * @return {?proto.accountpb.DateObject}
 */
proto.accountpb.Client.prototype.getInvestmentObjectiveDate = function() {
  return /** @type{?proto.accountpb.DateObject} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.DateObject, 15));
};


/**
 * @param {?proto.accountpb.DateObject|undefined} value
 * @return {!proto.accountpb.Client} returns this
*/
proto.accountpb.Client.prototype.setInvestmentObjectiveDate = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.clearInvestmentObjectiveDate = function() {
  return this.setInvestmentObjectiveDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.Client.prototype.hasInvestmentObjectiveDate = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string legal_entity = 16;
 * @return {string}
 */
proto.accountpb.Client.prototype.getLegalEntity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setLegalEntity = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string tax_country = 17;
 * @return {string}
 */
proto.accountpb.Client.prototype.getTaxCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setTaxCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string w8w9 = 18;
 * @return {string}
 */
proto.accountpb.Client.prototype.getW8w9 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setW8w9 = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional DateObject w8w9_received_date = 19;
 * @return {?proto.accountpb.DateObject}
 */
proto.accountpb.Client.prototype.getW8w9ReceivedDate = function() {
  return /** @type{?proto.accountpb.DateObject} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.DateObject, 19));
};


/**
 * @param {?proto.accountpb.DateObject|undefined} value
 * @return {!proto.accountpb.Client} returns this
*/
proto.accountpb.Client.prototype.setW8w9ReceivedDate = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.clearW8w9ReceivedDate = function() {
  return this.setW8w9ReceivedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.Client.prototype.hasW8w9ReceivedDate = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional string tefra = 20;
 * @return {string}
 */
proto.accountpb.Client.prototype.getTefra = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setTefra = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string created_at = 21;
 * @return {string}
 */
proto.accountpb.Client.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string created_by = 22;
 * @return {string}
 */
proto.accountpb.Client.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional bool accredited_investor = 23;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getAccreditedInvestor = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setAccreditedInvestor = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional bool fdid_flag = 24;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getFdidFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setFdidFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional string fdid = 25;
 * @return {string}
 */
proto.accountpb.Client.prototype.getFdid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setFdid = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string fdid_end_reason = 26;
 * @return {string}
 */
proto.accountpb.Client.prototype.getFdidEndReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setFdidEndReason = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string branch = 27;
 * @return {string}
 */
proto.accountpb.Client.prototype.getBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string rep = 28;
 * @return {string}
 */
proto.accountpb.Client.prototype.getRep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setRep = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string account_type = 29;
 * @return {string}
 */
proto.accountpb.Client.prototype.getAccountType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setAccountType = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string margin_type = 30;
 * @return {string}
 */
proto.accountpb.Client.prototype.getMarginType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setMarginType = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string large_trader_id = 31;
 * @return {string}
 */
proto.accountpb.Client.prototype.getLargeTraderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setLargeTraderId = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string sub_accounts = 32;
 * @return {string}
 */
proto.accountpb.Client.prototype.getSubAccounts = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setSubAccounts = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string batch_no = 33;
 * @return {string}
 */
proto.accountpb.Client.prototype.getBatchNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setBatchNo = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string error_message = 34;
 * @return {string}
 */
proto.accountpb.Client.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string validation_status = 35;
 * @return {string}
 */
proto.accountpb.Client.prototype.getValidationStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setValidationStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string common_id = 36;
 * @return {string}
 */
proto.accountpb.Client.prototype.getCommonId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setCommonId = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string modified_at = 37;
 * @return {string}
 */
proto.accountpb.Client.prototype.getModifiedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setModifiedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string modified_by = 38;
 * @return {string}
 */
proto.accountpb.Client.prototype.getModifiedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setModifiedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional string sub_type = 39;
 * @return {string}
 */
proto.accountpb.Client.prototype.getSubType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setSubType = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string status_reason = 40;
 * @return {string}
 */
proto.accountpb.Client.prototype.getStatusReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setStatusReason = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional bool allow_liquid_investment = 41;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getAllowLiquidInvestment = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 41, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setAllowLiquidInvestment = function(value) {
  return jspb.Message.setProto3BooleanField(this, 41, value);
};


/**
 * optional string web_term_conditions = 42;
 * @return {string}
 */
proto.accountpb.Client.prototype.getWebTermConditions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setWebTermConditions = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional string disclosure_statement = 43;
 * @return {string}
 */
proto.accountpb.Client.prototype.getDisclosureStatement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setDisclosureStatement = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * optional string fee_schedule = 44;
 * @return {string}
 */
proto.accountpb.Client.prototype.getFeeSchedule = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setFeeSchedule = function(value) {
  return jspb.Message.setProto3StringField(this, 44, value);
};


/**
 * optional string beneficiary = 45;
 * @return {string}
 */
proto.accountpb.Client.prototype.getBeneficiary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setBeneficiary = function(value) {
  return jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * optional string prime_broker = 46;
 * @return {string}
 */
proto.accountpb.Client.prototype.getPrimeBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 46, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setPrimeBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 46, value);
};


/**
 * optional string participant_no = 47;
 * @return {string}
 */
proto.accountpb.Client.prototype.getParticipantNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 47, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setParticipantNo = function(value) {
  return jspb.Message.setProto3StringField(this, 47, value);
};


/**
 * optional string executing_broker = 48;
 * @return {string}
 */
proto.accountpb.Client.prototype.getExecutingBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setExecutingBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional string institution_no = 49;
 * @return {string}
 */
proto.accountpb.Client.prototype.getInstitutionNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 49, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setInstitutionNo = function(value) {
  return jspb.Message.setProto3StringField(this, 49, value);
};


/**
 * optional string agent_no = 50;
 * @return {string}
 */
proto.accountpb.Client.prototype.getAgentNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setAgentNo = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional string agent_account_no = 51;
 * @return {string}
 */
proto.accountpb.Client.prototype.getAgentAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setAgentAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 51, value);
};


/**
 * optional string trader_id = 52;
 * @return {string}
 */
proto.accountpb.Client.prototype.getTraderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 52, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setTraderId = function(value) {
  return jspb.Message.setProto3StringField(this, 52, value);
};


/**
 * optional bool margin_disclosure = 53;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getMarginDisclosure = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 53, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setMarginDisclosure = function(value) {
  return jspb.Message.setProto3BooleanField(this, 53, value);
};


/**
 * optional bool stocks = 54;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getStocks = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 54, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setStocks = function(value) {
  return jspb.Message.setProto3BooleanField(this, 54, value);
};


/**
 * optional bool etf = 55;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getEtf = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 55, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setEtf = function(value) {
  return jspb.Message.setProto3BooleanField(this, 55, value);
};


/**
 * optional bool option = 56;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getOption = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 56, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setOption = function(value) {
  return jspb.Message.setProto3BooleanField(this, 56, value);
};


/**
 * optional string annual_income = 57;
 * @return {string}
 */
proto.accountpb.Client.prototype.getAnnualIncome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 57, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setAnnualIncome = function(value) {
  return jspb.Message.setProto3StringField(this, 57, value);
};


/**
 * optional string liquid_net_worth = 58;
 * @return {string}
 */
proto.accountpb.Client.prototype.getLiquidNetWorth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 58, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setLiquidNetWorth = function(value) {
  return jspb.Message.setProto3StringField(this, 58, value);
};


/**
 * optional string source_of_funds = 59;
 * @return {string}
 */
proto.accountpb.Client.prototype.getSourceOfFunds = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 59, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setSourceOfFunds = function(value) {
  return jspb.Message.setProto3StringField(this, 59, value);
};


/**
 * optional bool signed = 60;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getSigned = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 60, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setSigned = function(value) {
  return jspb.Message.setProto3BooleanField(this, 60, value);
};


/**
 * optional string internal_status = 61;
 * @return {string}
 */
proto.accountpb.Client.prototype.getInternalStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 61, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setInternalStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 61, value);
};


/**
 * optional string img_signature = 62;
 * @return {string}
 */
proto.accountpb.Client.prototype.getImgSignature = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 62, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setImgSignature = function(value) {
  return jspb.Message.setProto3StringField(this, 62, value);
};


/**
 * optional bool margin_agreement = 63;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getMarginAgreement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 63, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setMarginAgreement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 63, value);
};


/**
 * optional string kyc_status = 64;
 * @return {string}
 */
proto.accountpb.Client.prototype.getKycStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 64, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setKycStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 64, value);
};


/**
 * optional string tin = 65;
 * @return {string}
 */
proto.accountpb.Client.prototype.getTin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 65, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setTin = function(value) {
  return jspb.Message.setProto3StringField(this, 65, value);
};


/**
 * optional string formation_country = 66;
 * @return {string}
 */
proto.accountpb.Client.prototype.getFormationCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 66, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setFormationCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 66, value);
};


/**
 * optional string formation_state = 67;
 * @return {string}
 */
proto.accountpb.Client.prototype.getFormationState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 67, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setFormationState = function(value) {
  return jspb.Message.setProto3StringField(this, 67, value);
};


/**
 * optional DateObject formation_date = 68;
 * @return {?proto.accountpb.DateObject}
 */
proto.accountpb.Client.prototype.getFormationDate = function() {
  return /** @type{?proto.accountpb.DateObject} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.DateObject, 68));
};


/**
 * @param {?proto.accountpb.DateObject|undefined} value
 * @return {!proto.accountpb.Client} returns this
*/
proto.accountpb.Client.prototype.setFormationDate = function(value) {
  return jspb.Message.setWrapperField(this, 68, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.clearFormationDate = function() {
  return this.setFormationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.Client.prototype.hasFormationDate = function() {
  return jspb.Message.getField(this, 68) != null;
};


/**
 * optional string currency = 69;
 * @return {string}
 */
proto.accountpb.Client.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 69, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 69, value);
};


/**
 * optional string service_bureau = 70;
 * @return {string}
 */
proto.accountpb.Client.prototype.getServiceBureau = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 70, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setServiceBureau = function(value) {
  return jspb.Message.setProto3StringField(this, 70, value);
};


/**
 * optional string promo_code = 71;
 * @return {string}
 */
proto.accountpb.Client.prototype.getPromoCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 71, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setPromoCode = function(value) {
  return jspb.Message.setProto3StringField(this, 71, value);
};


/**
 * optional string security_word = 72;
 * @return {string}
 */
proto.accountpb.Client.prototype.getSecurityWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 72, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setSecurityWord = function(value) {
  return jspb.Message.setProto3StringField(this, 72, value);
};


/**
 * optional string security_word_hint = 73;
 * @return {string}
 */
proto.accountpb.Client.prototype.getSecurityWordHint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 73, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setSecurityWordHint = function(value) {
  return jspb.Message.setProto3StringField(this, 73, value);
};


/**
 * optional int32 tax_bracket = 74;
 * @return {number}
 */
proto.accountpb.Client.prototype.getTaxBracket = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 74, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setTaxBracket = function(value) {
  return jspb.Message.setProto3IntField(this, 74, value);
};


/**
 * optional string risk_profile = 75;
 * @return {string}
 */
proto.accountpb.Client.prototype.getRiskProfile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 75, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setRiskProfile = function(value) {
  return jspb.Message.setProto3StringField(this, 75, value);
};


/**
 * optional bool cash_sweep_participant = 76;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getCashSweepParticipant = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 76, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setCashSweepParticipant = function(value) {
  return jspb.Message.setProto3BooleanField(this, 76, value);
};


/**
 * optional string pl_type = 77;
 * @return {string}
 */
proto.accountpb.Client.prototype.getPlType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 77, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setPlType = function(value) {
  return jspb.Message.setProto3StringField(this, 77, value);
};


/**
 * optional string onboarding_percent = 78;
 * @return {string}
 */
proto.accountpb.Client.prototype.getOnboardingPercent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 78, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setOnboardingPercent = function(value) {
  return jspb.Message.setProto3StringField(this, 78, value);
};


/**
 * optional bool mutual_funds = 79;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getMutualFunds = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 79, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setMutualFunds = function(value) {
  return jspb.Message.setProto3BooleanField(this, 79, value);
};


/**
 * optional bool fixed_income = 80;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getFixedIncome = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 80, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setFixedIncome = function(value) {
  return jspb.Message.setProto3BooleanField(this, 80, value);
};


/**
 * optional bool email_exist = 81;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getEmailExist = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 81, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setEmailExist = function(value) {
  return jspb.Message.setProto3BooleanField(this, 81, value);
};


/**
 * optional string previous_account_no = 82;
 * @return {string}
 */
proto.accountpb.Client.prototype.getPreviousAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 82, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setPreviousAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 82, value);
};


/**
 * optional string statement = 83;
 * @return {string}
 */
proto.accountpb.Client.prototype.getStatement = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 83, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setStatement = function(value) {
  return jspb.Message.setProto3StringField(this, 83, value);
};


/**
 * optional string trade_confirms = 84;
 * @return {string}
 */
proto.accountpb.Client.prototype.getTradeConfirms = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 84, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setTradeConfirms = function(value) {
  return jspb.Message.setProto3StringField(this, 84, value);
};


/**
 * optional string proxy_and_prospectus = 85;
 * @return {string}
 */
proto.accountpb.Client.prototype.getProxyAndProspectus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 85, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setProxyAndProspectus = function(value) {
  return jspb.Message.setProto3StringField(this, 85, value);
};


/**
 * optional string tax_forms = 86;
 * @return {string}
 */
proto.accountpb.Client.prototype.getTaxForms = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 86, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setTaxForms = function(value) {
  return jspb.Message.setProto3StringField(this, 86, value);
};


/**
 * optional string misc_letters = 87;
 * @return {string}
 */
proto.accountpb.Client.prototype.getMiscLetters = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 87, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setMiscLetters = function(value) {
  return jspb.Message.setProto3StringField(this, 87, value);
};


/**
 * optional string email_address = 88;
 * @return {string}
 */
proto.accountpb.Client.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 88, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 88, value);
};


/**
 * optional bool is_restricted = 89;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getIsRestricted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 89, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setIsRestricted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 89, value);
};


/**
 * optional string restricted_reason = 90;
 * @return {string}
 */
proto.accountpb.Client.prototype.getRestrictedReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 90, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setRestrictedReason = function(value) {
  return jspb.Message.setProto3StringField(this, 90, value);
};


/**
 * optional string external_id = 91;
 * @return {string}
 */
proto.accountpb.Client.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 91, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 91, value);
};


/**
 * optional string sweep_type = 92;
 * @return {string}
 */
proto.accountpb.Client.prototype.getSweepType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 92, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setSweepType = function(value) {
  return jspb.Message.setProto3StringField(this, 92, value);
};


/**
 * optional string account_discretion = 93;
 * @return {string}
 */
proto.accountpb.Client.prototype.getAccountDiscretion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 93, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setAccountDiscretion = function(value) {
  return jspb.Message.setProto3StringField(this, 93, value);
};


/**
 * optional string nscc_account_line = 94;
 * @return {string}
 */
proto.accountpb.Client.prototype.getNsccAccountLine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 94, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setNsccAccountLine = function(value) {
  return jspb.Message.setProto3StringField(this, 94, value);
};


/**
 * optional bool customer_account_agreement = 95;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getCustomerAccountAgreement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 95, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setCustomerAccountAgreement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 95, value);
};


/**
 * optional string account_purpose = 96;
 * @return {string}
 */
proto.accountpb.Client.prototype.getAccountPurpose = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 96, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setAccountPurpose = function(value) {
  return jspb.Message.setProto3StringField(this, 96, value);
};


/**
 * optional string account_purpose_description = 97;
 * @return {string}
 */
proto.accountpb.Client.prototype.getAccountPurposeDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 97, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setAccountPurposeDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 97, value);
};


/**
 * optional string source_of_funds_description = 98;
 * @return {string}
 */
proto.accountpb.Client.prototype.getSourceOfFundsDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 98, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setSourceOfFundsDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 98, value);
};


/**
 * optional string trading_frequency = 99;
 * @return {string}
 */
proto.accountpb.Client.prototype.getTradingFrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 99, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setTradingFrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 99, value);
};


/**
 * optional string total_net_worth = 100;
 * @return {string}
 */
proto.accountpb.Client.prototype.getTotalNetWorth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 100, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setTotalNetWorth = function(value) {
  return jspb.Message.setProto3StringField(this, 100, value);
};


/**
 * optional string investment_experience = 101;
 * @return {string}
 */
proto.accountpb.Client.prototype.getInvestmentExperience = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 101, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setInvestmentExperience = function(value) {
  return jspb.Message.setProto3StringField(this, 101, value);
};


/**
 * optional string custodial_type = 102;
 * @return {string}
 */
proto.accountpb.Client.prototype.getCustodialType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 102, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setCustodialType = function(value) {
  return jspb.Message.setProto3StringField(this, 102, value);
};


/**
 * optional bool margin = 103;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getMargin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 103, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setMargin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 103, value);
};


/**
 * optional bool cash = 104;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getCash = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 104, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setCash = function(value) {
  return jspb.Message.setProto3BooleanField(this, 104, value);
};


/**
 * optional bool limited_margin = 105;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getLimitedMargin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 105, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setLimitedMargin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 105, value);
};


/**
 * optional bool w8w9_certified = 106;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getW8w9Certified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 106, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setW8w9Certified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 106, value);
};


/**
 * optional string receipent_code = 107;
 * @return {string}
 */
proto.accountpb.Client.prototype.getReceipentCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 107, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setReceipentCode = function(value) {
  return jspb.Message.setProto3StringField(this, 107, value);
};


/**
 * optional string irs_code_ch3 = 108;
 * @return {string}
 */
proto.accountpb.Client.prototype.getIrsCodeCh3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 108, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setIrsCodeCh3 = function(value) {
  return jspb.Message.setProto3StringField(this, 108, value);
};


/**
 * optional string irs_code_ch4 = 109;
 * @return {string}
 */
proto.accountpb.Client.prototype.getIrsCodeCh4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 109, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setIrsCodeCh4 = function(value) {
  return jspb.Message.setProto3StringField(this, 109, value);
};


/**
 * optional string tax_rate_override_rate = 110;
 * @return {string}
 */
proto.accountpb.Client.prototype.getTaxRateOverrideRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 110, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setTaxRateOverrideRate = function(value) {
  return jspb.Message.setProto3StringField(this, 110, value);
};


/**
 * optional bool fatca = 111;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getFatca = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 111, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setFatca = function(value) {
  return jspb.Message.setProto3BooleanField(this, 111, value);
};


/**
 * optional string exempt_payee_code = 112;
 * @return {string}
 */
proto.accountpb.Client.prototype.getExemptPayeeCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 112, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setExemptPayeeCode = function(value) {
  return jspb.Message.setProto3StringField(this, 112, value);
};


/**
 * optional string fatca_exempt_code = 113;
 * @return {string}
 */
proto.accountpb.Client.prototype.getFatcaExemptCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 113, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setFatcaExemptCode = function(value) {
  return jspb.Message.setProto3StringField(this, 113, value);
};


/**
 * optional string disregard_entity = 114;
 * @return {string}
 */
proto.accountpb.Client.prototype.getDisregardEntity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 114, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setDisregardEntity = function(value) {
  return jspb.Message.setProto3StringField(this, 114, value);
};


/**
 * optional string w8_type = 115;
 * @return {string}
 */
proto.accountpb.Client.prototype.getW8Type = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 115, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setW8Type = function(value) {
  return jspb.Message.setProto3StringField(this, 115, value);
};


/**
 * optional bool is_frozen = 116;
 * @return {boolean}
 */
proto.accountpb.Client.prototype.getIsFrozen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 116, false));
};


/**
 * @param {boolean} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setIsFrozen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 116, value);
};


/**
 * optional string government_id_type = 117;
 * @return {string}
 */
proto.accountpb.Client.prototype.getGovernmentIdType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 117, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setGovernmentIdType = function(value) {
  return jspb.Message.setProto3StringField(this, 117, value);
};


/**
 * optional string onboarding_error = 118;
 * @return {string}
 */
proto.accountpb.Client.prototype.getOnboardingError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 118, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setOnboardingError = function(value) {
  return jspb.Message.setProto3StringField(this, 118, value);
};


/**
 * optional string tenant = 119;
 * @return {string}
 */
proto.accountpb.Client.prototype.getTenant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 119, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setTenant = function(value) {
  return jspb.Message.setProto3StringField(this, 119, value);
};


/**
 * optional string inactive_at = 120;
 * @return {string}
 */
proto.accountpb.Client.prototype.getInactiveAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 120, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setInactiveAt = function(value) {
  return jspb.Message.setProto3StringField(this, 120, value);
};


/**
 * optional string registration_type = 121;
 * @return {string}
 */
proto.accountpb.Client.prototype.getRegistrationType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 121, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.Client} returns this
 */
proto.accountpb.Client.prototype.setRegistrationType = function(value) {
  return jspb.Message.setProto3StringField(this, 121, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.DateObject.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.DateObject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.DateObject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DateObject.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0),
    day: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.DateObject}
 */
proto.accountpb.DateObject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.DateObject;
  return proto.accountpb.DateObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.DateObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.DateObject}
 */
proto.accountpb.DateObject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.DateObject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.DateObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.DateObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DateObject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 year = 1;
 * @return {number}
 */
proto.accountpb.DateObject.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.DateObject} returns this
 */
proto.accountpb.DateObject.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 month = 2;
 * @return {number}
 */
proto.accountpb.DateObject.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.DateObject} returns this
 */
proto.accountpb.DateObject.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 day = 3;
 * @return {number}
 */
proto.accountpb.DateObject.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.DateObject} returns this
 */
proto.accountpb.DateObject.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ReadClientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ReadClientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ReadClientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadClientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    usrId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ReadClientRequest}
 */
proto.accountpb.ReadClientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ReadClientRequest;
  return proto.accountpb.ReadClientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ReadClientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ReadClientRequest}
 */
proto.accountpb.ReadClientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUsrId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ReadClientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ReadClientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ReadClientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadClientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUsrId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.accountpb.ReadClientRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.ReadClientRequest} returns this
 */
proto.accountpb.ReadClientRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 usr_id = 2;
 * @return {number}
 */
proto.accountpb.ReadClientRequest.prototype.getUsrId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.ReadClientRequest} returns this
 */
proto.accountpb.ReadClientRequest.prototype.setUsrId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ReadClientByUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ReadClientByUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ReadClientByUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadClientByUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    usrId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ReadClientByUserRequest}
 */
proto.accountpb.ReadClientByUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ReadClientByUserRequest;
  return proto.accountpb.ReadClientByUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ReadClientByUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ReadClientByUserRequest}
 */
proto.accountpb.ReadClientByUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUsrId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ReadClientByUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ReadClientByUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ReadClientByUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadClientByUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsrId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 usr_id = 1;
 * @return {number}
 */
proto.accountpb.ReadClientByUserRequest.prototype.getUsrId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.ReadClientByUserRequest} returns this
 */
proto.accountpb.ReadClientByUserRequest.prototype.setUsrId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.accountpb.ReadClientResponse.repeatedFields_ = [2,3,4,5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ReadClientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ReadClientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ReadClientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadClientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    client: (f = msg.getClient()) && proto.accountpb.Client.toObject(includeInstance, f),
    ownersList: jspb.Message.toObjectList(msg.getOwnersList(),
    proto_accountpb_owner_pb.Owner.toObject, includeInstance),
    addressesList: jspb.Message.toObjectList(msg.getAddressesList(),
    proto_accountpb_address_pb.Address.toObject, includeInstance),
    contactInfosList: jspb.Message.toObjectList(msg.getContactInfosList(),
    proto_accountpb_contactinfo_pb.ContactInfo.toObject, includeInstance),
    identificationsList: jspb.Message.toObjectList(msg.getIdentificationsList(),
    proto_accountpb_identification_pb.Identification.toObject, includeInstance),
    notesList: jspb.Message.toObjectList(msg.getNotesList(),
    proto_accountpb_note_pb.Note.toObject, includeInstance),
    subAccountsList: jspb.Message.toObjectList(msg.getSubAccountsList(),
    proto_accountpb_subaccount_pb.SubAccount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ReadClientResponse}
 */
proto.accountpb.ReadClientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ReadClientResponse;
  return proto.accountpb.ReadClientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ReadClientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ReadClientResponse}
 */
proto.accountpb.ReadClientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Client;
      reader.readMessage(value,proto.accountpb.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 2:
      var value = new proto_accountpb_owner_pb.Owner;
      reader.readMessage(value,proto_accountpb_owner_pb.Owner.deserializeBinaryFromReader);
      msg.addOwners(value);
      break;
    case 3:
      var value = new proto_accountpb_address_pb.Address;
      reader.readMessage(value,proto_accountpb_address_pb.Address.deserializeBinaryFromReader);
      msg.addAddresses(value);
      break;
    case 4:
      var value = new proto_accountpb_contactinfo_pb.ContactInfo;
      reader.readMessage(value,proto_accountpb_contactinfo_pb.ContactInfo.deserializeBinaryFromReader);
      msg.addContactInfos(value);
      break;
    case 5:
      var value = new proto_accountpb_identification_pb.Identification;
      reader.readMessage(value,proto_accountpb_identification_pb.Identification.deserializeBinaryFromReader);
      msg.addIdentifications(value);
      break;
    case 6:
      var value = new proto_accountpb_note_pb.Note;
      reader.readMessage(value,proto_accountpb_note_pb.Note.deserializeBinaryFromReader);
      msg.addNotes(value);
      break;
    case 7:
      var value = new proto_accountpb_subaccount_pb.SubAccount;
      reader.readMessage(value,proto_accountpb_subaccount_pb.SubAccount.deserializeBinaryFromReader);
      msg.addSubAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ReadClientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ReadClientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ReadClientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadClientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.Client.serializeBinaryToWriter
    );
  }
  f = message.getOwnersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_accountpb_owner_pb.Owner.serializeBinaryToWriter
    );
  }
  f = message.getAddressesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto_accountpb_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getContactInfosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto_accountpb_contactinfo_pb.ContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getIdentificationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto_accountpb_identification_pb.Identification.serializeBinaryToWriter
    );
  }
  f = message.getNotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto_accountpb_note_pb.Note.serializeBinaryToWriter
    );
  }
  f = message.getSubAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto_accountpb_subaccount_pb.SubAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional Client client = 1;
 * @return {?proto.accountpb.Client}
 */
proto.accountpb.ReadClientResponse.prototype.getClient = function() {
  return /** @type{?proto.accountpb.Client} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.Client, 1));
};


/**
 * @param {?proto.accountpb.Client|undefined} value
 * @return {!proto.accountpb.ReadClientResponse} returns this
*/
proto.accountpb.ReadClientResponse.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.ReadClientResponse} returns this
 */
proto.accountpb.ReadClientResponse.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.ReadClientResponse.prototype.hasClient = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Owner owners = 2;
 * @return {!Array<!proto.accountpb.Owner>}
 */
proto.accountpb.ReadClientResponse.prototype.getOwnersList = function() {
  return /** @type{!Array<!proto.accountpb.Owner>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_accountpb_owner_pb.Owner, 2));
};


/**
 * @param {!Array<!proto.accountpb.Owner>} value
 * @return {!proto.accountpb.ReadClientResponse} returns this
*/
proto.accountpb.ReadClientResponse.prototype.setOwnersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.accountpb.Owner=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.Owner}
 */
proto.accountpb.ReadClientResponse.prototype.addOwners = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.accountpb.Owner, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ReadClientResponse} returns this
 */
proto.accountpb.ReadClientResponse.prototype.clearOwnersList = function() {
  return this.setOwnersList([]);
};


/**
 * repeated Address addresses = 3;
 * @return {!Array<!proto.accountpb.Address>}
 */
proto.accountpb.ReadClientResponse.prototype.getAddressesList = function() {
  return /** @type{!Array<!proto.accountpb.Address>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_accountpb_address_pb.Address, 3));
};


/**
 * @param {!Array<!proto.accountpb.Address>} value
 * @return {!proto.accountpb.ReadClientResponse} returns this
*/
proto.accountpb.ReadClientResponse.prototype.setAddressesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.accountpb.Address=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.Address}
 */
proto.accountpb.ReadClientResponse.prototype.addAddresses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.accountpb.Address, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ReadClientResponse} returns this
 */
proto.accountpb.ReadClientResponse.prototype.clearAddressesList = function() {
  return this.setAddressesList([]);
};


/**
 * repeated ContactInfo contact_infos = 4;
 * @return {!Array<!proto.accountpb.ContactInfo>}
 */
proto.accountpb.ReadClientResponse.prototype.getContactInfosList = function() {
  return /** @type{!Array<!proto.accountpb.ContactInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_accountpb_contactinfo_pb.ContactInfo, 4));
};


/**
 * @param {!Array<!proto.accountpb.ContactInfo>} value
 * @return {!proto.accountpb.ReadClientResponse} returns this
*/
proto.accountpb.ReadClientResponse.prototype.setContactInfosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.accountpb.ContactInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.ContactInfo}
 */
proto.accountpb.ReadClientResponse.prototype.addContactInfos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.accountpb.ContactInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ReadClientResponse} returns this
 */
proto.accountpb.ReadClientResponse.prototype.clearContactInfosList = function() {
  return this.setContactInfosList([]);
};


/**
 * repeated Identification identifications = 5;
 * @return {!Array<!proto.accountpb.Identification>}
 */
proto.accountpb.ReadClientResponse.prototype.getIdentificationsList = function() {
  return /** @type{!Array<!proto.accountpb.Identification>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_accountpb_identification_pb.Identification, 5));
};


/**
 * @param {!Array<!proto.accountpb.Identification>} value
 * @return {!proto.accountpb.ReadClientResponse} returns this
*/
proto.accountpb.ReadClientResponse.prototype.setIdentificationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.accountpb.Identification=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.Identification}
 */
proto.accountpb.ReadClientResponse.prototype.addIdentifications = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.accountpb.Identification, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ReadClientResponse} returns this
 */
proto.accountpb.ReadClientResponse.prototype.clearIdentificationsList = function() {
  return this.setIdentificationsList([]);
};


/**
 * repeated Note notes = 6;
 * @return {!Array<!proto.accountpb.Note>}
 */
proto.accountpb.ReadClientResponse.prototype.getNotesList = function() {
  return /** @type{!Array<!proto.accountpb.Note>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_accountpb_note_pb.Note, 6));
};


/**
 * @param {!Array<!proto.accountpb.Note>} value
 * @return {!proto.accountpb.ReadClientResponse} returns this
*/
proto.accountpb.ReadClientResponse.prototype.setNotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.accountpb.Note=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.Note}
 */
proto.accountpb.ReadClientResponse.prototype.addNotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.accountpb.Note, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ReadClientResponse} returns this
 */
proto.accountpb.ReadClientResponse.prototype.clearNotesList = function() {
  return this.setNotesList([]);
};


/**
 * repeated SubAccount sub_accounts = 7;
 * @return {!Array<!proto.accountpb.SubAccount>}
 */
proto.accountpb.ReadClientResponse.prototype.getSubAccountsList = function() {
  return /** @type{!Array<!proto.accountpb.SubAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_accountpb_subaccount_pb.SubAccount, 7));
};


/**
 * @param {!Array<!proto.accountpb.SubAccount>} value
 * @return {!proto.accountpb.ReadClientResponse} returns this
*/
proto.accountpb.ReadClientResponse.prototype.setSubAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.accountpb.SubAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.SubAccount}
 */
proto.accountpb.ReadClientResponse.prototype.addSubAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.accountpb.SubAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ReadClientResponse} returns this
 */
proto.accountpb.ReadClientResponse.prototype.clearSubAccountsList = function() {
  return this.setSubAccountsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.CreateClientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.CreateClientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.CreateClientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.CreateClientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    client: (f = msg.getClient()) && proto.accountpb.Client.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.CreateClientResponse}
 */
proto.accountpb.CreateClientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.CreateClientResponse;
  return proto.accountpb.CreateClientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.CreateClientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.CreateClientResponse}
 */
proto.accountpb.CreateClientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Client;
      reader.readMessage(value,proto.accountpb.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.CreateClientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.CreateClientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.CreateClientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.CreateClientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.Client.serializeBinaryToWriter
    );
  }
};


/**
 * optional Client client = 1;
 * @return {?proto.accountpb.Client}
 */
proto.accountpb.CreateClientResponse.prototype.getClient = function() {
  return /** @type{?proto.accountpb.Client} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.Client, 1));
};


/**
 * @param {?proto.accountpb.Client|undefined} value
 * @return {!proto.accountpb.CreateClientResponse} returns this
*/
proto.accountpb.CreateClientResponse.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.CreateClientResponse} returns this
 */
proto.accountpb.CreateClientResponse.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.CreateClientResponse.prototype.hasClient = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.UpdateClientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.UpdateClientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.UpdateClientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.UpdateClientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    client: (f = msg.getClient()) && proto.accountpb.Client.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.UpdateClientResponse}
 */
proto.accountpb.UpdateClientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.UpdateClientResponse;
  return proto.accountpb.UpdateClientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.UpdateClientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.UpdateClientResponse}
 */
proto.accountpb.UpdateClientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Client;
      reader.readMessage(value,proto.accountpb.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.UpdateClientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.UpdateClientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.UpdateClientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.UpdateClientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.Client.serializeBinaryToWriter
    );
  }
};


/**
 * optional Client client = 1;
 * @return {?proto.accountpb.Client}
 */
proto.accountpb.UpdateClientResponse.prototype.getClient = function() {
  return /** @type{?proto.accountpb.Client} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.Client, 1));
};


/**
 * @param {?proto.accountpb.Client|undefined} value
 * @return {!proto.accountpb.UpdateClientResponse} returns this
*/
proto.accountpb.UpdateClientResponse.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.UpdateClientResponse} returns this
 */
proto.accountpb.UpdateClientResponse.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.UpdateClientResponse.prototype.hasClient = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.GetNewClientIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.GetNewClientIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.GetNewClientIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.GetNewClientIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.GetNewClientIdRequest}
 */
proto.accountpb.GetNewClientIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.GetNewClientIdRequest;
  return proto.accountpb.GetNewClientIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.GetNewClientIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.GetNewClientIdRequest}
 */
proto.accountpb.GetNewClientIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.GetNewClientIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.GetNewClientIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.GetNewClientIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.GetNewClientIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.GetNewClientIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.GetNewClientIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.GetNewClientIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.GetNewClientIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.GetNewClientIdResponse}
 */
proto.accountpb.GetNewClientIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.GetNewClientIdResponse;
  return proto.accountpb.GetNewClientIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.GetNewClientIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.GetNewClientIdResponse}
 */
proto.accountpb.GetNewClientIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.GetNewClientIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.GetNewClientIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.GetNewClientIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.GetNewClientIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.accountpb.GetNewClientIdResponse.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.GetNewClientIdResponse} returns this
 */
proto.accountpb.GetNewClientIdResponse.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.GetAccountIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.GetAccountIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.GetAccountIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.GetAccountIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountNo: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.GetAccountIdRequest}
 */
proto.accountpb.GetAccountIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.GetAccountIdRequest;
  return proto.accountpb.GetAccountIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.GetAccountIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.GetAccountIdRequest}
 */
proto.accountpb.GetAccountIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.GetAccountIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.GetAccountIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.GetAccountIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.GetAccountIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string account_no = 1;
 * @return {string}
 */
proto.accountpb.GetAccountIdRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.GetAccountIdRequest} returns this
 */
proto.accountpb.GetAccountIdRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.GetAccountIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.GetAccountIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.GetAccountIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.GetAccountIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.GetAccountIdResponse}
 */
proto.accountpb.GetAccountIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.GetAccountIdResponse;
  return proto.accountpb.GetAccountIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.GetAccountIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.GetAccountIdResponse}
 */
proto.accountpb.GetAccountIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.GetAccountIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.GetAccountIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.GetAccountIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.GetAccountIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.accountpb.GetAccountIdResponse.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.GetAccountIdResponse} returns this
 */
proto.accountpb.GetAccountIdResponse.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ListClientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ListClientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ListClientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListClientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    branch: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    rep: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, ""),
    broker: jspb.Message.getFieldWithDefault(msg, 8, ""),
    subAccount: jspb.Message.getFieldWithDefault(msg, 9, ""),
    field: jspb.Message.getFieldWithDefault(msg, 10, ""),
    fieldValue: jspb.Message.getFieldWithDefault(msg, 11, ""),
    fromDate: (f = msg.getFromDate()) && proto.accountpb.DateObject.toObject(includeInstance, f),
    toDate: (f = msg.getToDate()) && proto.accountpb.DateObject.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto_utilspb_pagination_pb.Pagination.toObject(includeInstance, f),
    usrId: jspb.Message.getFieldWithDefault(msg, 15, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ListClientRequest}
 */
proto.accountpb.ListClientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ListClientRequest;
  return proto.accountpb.ListClientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ListClientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ListClientRequest}
 */
proto.accountpb.ListClientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranch(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRep(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubAccount(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldValue(value);
      break;
    case 12:
      var value = new proto.accountpb.DateObject;
      reader.readMessage(value,proto.accountpb.DateObject.deserializeBinaryFromReader);
      msg.setFromDate(value);
      break;
    case 13:
      var value = new proto.accountpb.DateObject;
      reader.readMessage(value,proto.accountpb.DateObject.deserializeBinaryFromReader);
      msg.setToDate(value);
      break;
    case 14:
      var value = new proto_utilspb_pagination_pb.Pagination;
      reader.readMessage(value,proto_utilspb_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUsrId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ListClientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ListClientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ListClientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListClientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRep();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSubAccount();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getFieldValue();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getFromDate();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.accountpb.DateObject.serializeBinaryToWriter
    );
  }
  f = message.getToDate();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.accountpb.DateObject.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto_utilspb_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getUsrId();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.accountpb.ListClientRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListClientRequest} returns this
 */
proto.accountpb.ListClientRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string branch = 2;
 * @return {string}
 */
proto.accountpb.ListClientRequest.prototype.getBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListClientRequest} returns this
 */
proto.accountpb.ListClientRequest.prototype.setBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.accountpb.ListClientRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListClientRequest} returns this
 */
proto.accountpb.ListClientRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_name = 4;
 * @return {string}
 */
proto.accountpb.ListClientRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListClientRequest} returns this
 */
proto.accountpb.ListClientRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string master_account_no = 5;
 * @return {string}
 */
proto.accountpb.ListClientRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListClientRequest} returns this
 */
proto.accountpb.ListClientRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string rep = 6;
 * @return {string}
 */
proto.accountpb.ListClientRequest.prototype.getRep = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListClientRequest} returns this
 */
proto.accountpb.ListClientRequest.prototype.setRep = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string status = 7;
 * @return {string}
 */
proto.accountpb.ListClientRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListClientRequest} returns this
 */
proto.accountpb.ListClientRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string broker = 8;
 * @return {string}
 */
proto.accountpb.ListClientRequest.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListClientRequest} returns this
 */
proto.accountpb.ListClientRequest.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string sub_account = 9;
 * @return {string}
 */
proto.accountpb.ListClientRequest.prototype.getSubAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListClientRequest} returns this
 */
proto.accountpb.ListClientRequest.prototype.setSubAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string field = 10;
 * @return {string}
 */
proto.accountpb.ListClientRequest.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListClientRequest} returns this
 */
proto.accountpb.ListClientRequest.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string field_value = 11;
 * @return {string}
 */
proto.accountpb.ListClientRequest.prototype.getFieldValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListClientRequest} returns this
 */
proto.accountpb.ListClientRequest.prototype.setFieldValue = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional DateObject from_date = 12;
 * @return {?proto.accountpb.DateObject}
 */
proto.accountpb.ListClientRequest.prototype.getFromDate = function() {
  return /** @type{?proto.accountpb.DateObject} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.DateObject, 12));
};


/**
 * @param {?proto.accountpb.DateObject|undefined} value
 * @return {!proto.accountpb.ListClientRequest} returns this
*/
proto.accountpb.ListClientRequest.prototype.setFromDate = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.ListClientRequest} returns this
 */
proto.accountpb.ListClientRequest.prototype.clearFromDate = function() {
  return this.setFromDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.ListClientRequest.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional DateObject to_date = 13;
 * @return {?proto.accountpb.DateObject}
 */
proto.accountpb.ListClientRequest.prototype.getToDate = function() {
  return /** @type{?proto.accountpb.DateObject} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.DateObject, 13));
};


/**
 * @param {?proto.accountpb.DateObject|undefined} value
 * @return {!proto.accountpb.ListClientRequest} returns this
*/
proto.accountpb.ListClientRequest.prototype.setToDate = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.ListClientRequest} returns this
 */
proto.accountpb.ListClientRequest.prototype.clearToDate = function() {
  return this.setToDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.ListClientRequest.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional utilspb.Pagination pagination = 14;
 * @return {?proto.utilspb.Pagination}
 */
proto.accountpb.ListClientRequest.prototype.getPagination = function() {
  return /** @type{?proto.utilspb.Pagination} */ (
    jspb.Message.getWrapperField(this, proto_utilspb_pagination_pb.Pagination, 14));
};


/**
 * @param {?proto.utilspb.Pagination|undefined} value
 * @return {!proto.accountpb.ListClientRequest} returns this
*/
proto.accountpb.ListClientRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.ListClientRequest} returns this
 */
proto.accountpb.ListClientRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.ListClientRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional uint32 usr_id = 15;
 * @return {number}
 */
proto.accountpb.ListClientRequest.prototype.getUsrId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.ListClientRequest} returns this
 */
proto.accountpb.ListClientRequest.prototype.setUsrId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.AuditLog.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.AuditLog.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.AuditLog} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.AuditLog.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountNo: jspb.Message.getFieldWithDefault(msg, 1, ""),
    category: jspb.Message.getFieldWithDefault(msg, 2, ""),
    field: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fromValue: jspb.Message.getFieldWithDefault(msg, 4, ""),
    toValue: jspb.Message.getFieldWithDefault(msg, 5, ""),
    modifiedAt: jspb.Message.getFieldWithDefault(msg, 6, ""),
    modifiedBy: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.AuditLog}
 */
proto.accountpb.AuditLog.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.AuditLog;
  return proto.accountpb.AuditLog.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.AuditLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.AuditLog}
 */
proto.accountpb.AuditLog.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromValue(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setToValue(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifiedAt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifiedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.AuditLog.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.AuditLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.AuditLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.AuditLog.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFromValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getToValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getModifiedAt();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getModifiedBy();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string account_no = 1;
 * @return {string}
 */
proto.accountpb.AuditLog.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.AuditLog} returns this
 */
proto.accountpb.AuditLog.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category = 2;
 * @return {string}
 */
proto.accountpb.AuditLog.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.AuditLog} returns this
 */
proto.accountpb.AuditLog.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string field = 3;
 * @return {string}
 */
proto.accountpb.AuditLog.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.AuditLog} returns this
 */
proto.accountpb.AuditLog.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string from_value = 4;
 * @return {string}
 */
proto.accountpb.AuditLog.prototype.getFromValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.AuditLog} returns this
 */
proto.accountpb.AuditLog.prototype.setFromValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string to_value = 5;
 * @return {string}
 */
proto.accountpb.AuditLog.prototype.getToValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.AuditLog} returns this
 */
proto.accountpb.AuditLog.prototype.setToValue = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string modified_at = 6;
 * @return {string}
 */
proto.accountpb.AuditLog.prototype.getModifiedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.AuditLog} returns this
 */
proto.accountpb.AuditLog.prototype.setModifiedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string modified_by = 7;
 * @return {string}
 */
proto.accountpb.AuditLog.prototype.getModifiedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.AuditLog} returns this
 */
proto.accountpb.AuditLog.prototype.setModifiedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.accountpb.ListClientResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ListClientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ListClientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ListClientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListClientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    clientsList: jspb.Message.toObjectList(msg.getClientsList(),
    proto.accountpb.Client.toObject, includeInstance),
    summary: (f = msg.getSummary()) && proto.accountpb.ListClientSummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ListClientResponse}
 */
proto.accountpb.ListClientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ListClientResponse;
  return proto.accountpb.ListClientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ListClientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ListClientResponse}
 */
proto.accountpb.ListClientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Client;
      reader.readMessage(value,proto.accountpb.Client.deserializeBinaryFromReader);
      msg.addClients(value);
      break;
    case 2:
      var value = new proto.accountpb.ListClientSummary;
      reader.readMessage(value,proto.accountpb.ListClientSummary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ListClientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ListClientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ListClientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListClientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClientsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.accountpb.Client.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.accountpb.ListClientSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Client clients = 1;
 * @return {!Array<!proto.accountpb.Client>}
 */
proto.accountpb.ListClientResponse.prototype.getClientsList = function() {
  return /** @type{!Array<!proto.accountpb.Client>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.accountpb.Client, 1));
};


/**
 * @param {!Array<!proto.accountpb.Client>} value
 * @return {!proto.accountpb.ListClientResponse} returns this
*/
proto.accountpb.ListClientResponse.prototype.setClientsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.accountpb.Client=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.Client}
 */
proto.accountpb.ListClientResponse.prototype.addClients = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.accountpb.Client, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ListClientResponse} returns this
 */
proto.accountpb.ListClientResponse.prototype.clearClientsList = function() {
  return this.setClientsList([]);
};


/**
 * optional ListClientSummary summary = 2;
 * @return {?proto.accountpb.ListClientSummary}
 */
proto.accountpb.ListClientResponse.prototype.getSummary = function() {
  return /** @type{?proto.accountpb.ListClientSummary} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.ListClientSummary, 2));
};


/**
 * @param {?proto.accountpb.ListClientSummary|undefined} value
 * @return {!proto.accountpb.ListClientResponse} returns this
*/
proto.accountpb.ListClientResponse.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.ListClientResponse} returns this
 */
proto.accountpb.ListClientResponse.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.ListClientResponse.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ValidateAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ValidateAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ValidateAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ValidateAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ValidateAccountRequest}
 */
proto.accountpb.ValidateAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ValidateAccountRequest;
  return proto.accountpb.ValidateAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ValidateAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ValidateAccountRequest}
 */
proto.accountpb.ValidateAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ValidateAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ValidateAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ValidateAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ValidateAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.accountpb.ValidateAccountRequest.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.ValidateAccountRequest} returns this
 */
proto.accountpb.ValidateAccountRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.EmptyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.EmptyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.EmptyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.EmptyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.EmptyResponse}
 */
proto.accountpb.EmptyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.EmptyResponse;
  return proto.accountpb.EmptyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.EmptyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.EmptyResponse}
 */
proto.accountpb.EmptyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.EmptyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.EmptyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.EmptyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.EmptyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.EmptyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.EmptyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.EmptyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.EmptyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.EmptyRequest}
 */
proto.accountpb.EmptyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.EmptyRequest;
  return proto.accountpb.EmptyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.EmptyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.EmptyRequest}
 */
proto.accountpb.EmptyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.EmptyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.EmptyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.EmptyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.EmptyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.SelectionItem.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.SelectionItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.SelectionItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.SelectionItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.SelectionItem}
 */
proto.accountpb.SelectionItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.SelectionItem;
  return proto.accountpb.SelectionItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.SelectionItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.SelectionItem}
 */
proto.accountpb.SelectionItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.SelectionItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.SelectionItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.SelectionItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.SelectionItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.accountpb.SelectionItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.SelectionItem} returns this
 */
proto.accountpb.SelectionItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.accountpb.SelectionItem.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.SelectionItem} returns this
 */
proto.accountpb.SelectionItem.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.accountpb.ListSelectionItemResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ListSelectionItemResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ListSelectionItemResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ListSelectionItemResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListSelectionItemResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.accountpb.SelectionItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ListSelectionItemResponse}
 */
proto.accountpb.ListSelectionItemResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ListSelectionItemResponse;
  return proto.accountpb.ListSelectionItemResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ListSelectionItemResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ListSelectionItemResponse}
 */
proto.accountpb.ListSelectionItemResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.SelectionItem;
      reader.readMessage(value,proto.accountpb.SelectionItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ListSelectionItemResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ListSelectionItemResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ListSelectionItemResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListSelectionItemResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.accountpb.SelectionItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SelectionItem items = 1;
 * @return {!Array<!proto.accountpb.SelectionItem>}
 */
proto.accountpb.ListSelectionItemResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.accountpb.SelectionItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.accountpb.SelectionItem, 1));
};


/**
 * @param {!Array<!proto.accountpb.SelectionItem>} value
 * @return {!proto.accountpb.ListSelectionItemResponse} returns this
*/
proto.accountpb.ListSelectionItemResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.accountpb.SelectionItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.SelectionItem}
 */
proto.accountpb.ListSelectionItemResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.accountpb.SelectionItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ListSelectionItemResponse} returns this
 */
proto.accountpb.ListSelectionItemResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ListSubAccountTypeItemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ListSubAccountTypeItemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ListSubAccountTypeItemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListSubAccountTypeItemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountType: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ListSubAccountTypeItemRequest}
 */
proto.accountpb.ListSubAccountTypeItemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ListSubAccountTypeItemRequest;
  return proto.accountpb.ListSubAccountTypeItemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ListSubAccountTypeItemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ListSubAccountTypeItemRequest}
 */
proto.accountpb.ListSubAccountTypeItemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ListSubAccountTypeItemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ListSubAccountTypeItemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ListSubAccountTypeItemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListSubAccountTypeItemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string account_type = 1;
 * @return {string}
 */
proto.accountpb.ListSubAccountTypeItemRequest.prototype.getAccountType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListSubAccountTypeItemRequest} returns this
 */
proto.accountpb.ListSubAccountTypeItemRequest.prototype.setAccountType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ListClientAuditRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ListClientAuditRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ListClientAuditRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListClientAuditRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromDate: (f = msg.getFromDate()) && proto.accountpb.DateObject.toObject(includeInstance, f),
    toDate: (f = msg.getToDate()) && proto.accountpb.DateObject.toObject(includeInstance, f),
    accountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    category: jspb.Message.getFieldWithDefault(msg, 4, ""),
    field: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ListClientAuditRequest}
 */
proto.accountpb.ListClientAuditRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ListClientAuditRequest;
  return proto.accountpb.ListClientAuditRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ListClientAuditRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ListClientAuditRequest}
 */
proto.accountpb.ListClientAuditRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.DateObject;
      reader.readMessage(value,proto.accountpb.DateObject.deserializeBinaryFromReader);
      msg.setFromDate(value);
      break;
    case 2:
      var value = new proto.accountpb.DateObject;
      reader.readMessage(value,proto.accountpb.DateObject.deserializeBinaryFromReader);
      msg.setToDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setField(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ListClientAuditRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ListClientAuditRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ListClientAuditRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListClientAuditRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.DateObject.serializeBinaryToWriter
    );
  }
  f = message.getToDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.accountpb.DateObject.serializeBinaryToWriter
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getField();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional DateObject from_date = 1;
 * @return {?proto.accountpb.DateObject}
 */
proto.accountpb.ListClientAuditRequest.prototype.getFromDate = function() {
  return /** @type{?proto.accountpb.DateObject} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.DateObject, 1));
};


/**
 * @param {?proto.accountpb.DateObject|undefined} value
 * @return {!proto.accountpb.ListClientAuditRequest} returns this
*/
proto.accountpb.ListClientAuditRequest.prototype.setFromDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.ListClientAuditRequest} returns this
 */
proto.accountpb.ListClientAuditRequest.prototype.clearFromDate = function() {
  return this.setFromDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.ListClientAuditRequest.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DateObject to_date = 2;
 * @return {?proto.accountpb.DateObject}
 */
proto.accountpb.ListClientAuditRequest.prototype.getToDate = function() {
  return /** @type{?proto.accountpb.DateObject} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.DateObject, 2));
};


/**
 * @param {?proto.accountpb.DateObject|undefined} value
 * @return {!proto.accountpb.ListClientAuditRequest} returns this
*/
proto.accountpb.ListClientAuditRequest.prototype.setToDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.ListClientAuditRequest} returns this
 */
proto.accountpb.ListClientAuditRequest.prototype.clearToDate = function() {
  return this.setToDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.ListClientAuditRequest.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.accountpb.ListClientAuditRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListClientAuditRequest} returns this
 */
proto.accountpb.ListClientAuditRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string category = 4;
 * @return {string}
 */
proto.accountpb.ListClientAuditRequest.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListClientAuditRequest} returns this
 */
proto.accountpb.ListClientAuditRequest.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string field = 5;
 * @return {string}
 */
proto.accountpb.ListClientAuditRequest.prototype.getField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ListClientAuditRequest} returns this
 */
proto.accountpb.ListClientAuditRequest.prototype.setField = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.accountpb.ListClientAuditResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ListClientAuditResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ListClientAuditResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ListClientAuditResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListClientAuditResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditLogsList: jspb.Message.toObjectList(msg.getAuditLogsList(),
    proto.accountpb.AuditLog.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ListClientAuditResponse}
 */
proto.accountpb.ListClientAuditResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ListClientAuditResponse;
  return proto.accountpb.ListClientAuditResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ListClientAuditResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ListClientAuditResponse}
 */
proto.accountpb.ListClientAuditResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.AuditLog;
      reader.readMessage(value,proto.accountpb.AuditLog.deserializeBinaryFromReader);
      msg.addAuditLogs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ListClientAuditResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ListClientAuditResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ListClientAuditResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListClientAuditResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditLogsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.accountpb.AuditLog.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AuditLog audit_logs = 1;
 * @return {!Array<!proto.accountpb.AuditLog>}
 */
proto.accountpb.ListClientAuditResponse.prototype.getAuditLogsList = function() {
  return /** @type{!Array<!proto.accountpb.AuditLog>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.accountpb.AuditLog, 1));
};


/**
 * @param {!Array<!proto.accountpb.AuditLog>} value
 * @return {!proto.accountpb.ListClientAuditResponse} returns this
*/
proto.accountpb.ListClientAuditResponse.prototype.setAuditLogsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.accountpb.AuditLog=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.AuditLog}
 */
proto.accountpb.ListClientAuditResponse.prototype.addAuditLogs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.accountpb.AuditLog, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ListClientAuditResponse} returns this
 */
proto.accountpb.ListClientAuditResponse.prototype.clearAuditLogsList = function() {
  return this.setAuditLogsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.GetAccountDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.GetAccountDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.GetAccountDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.GetAccountDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.GetAccountDetailsRequest}
 */
proto.accountpb.GetAccountDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.GetAccountDetailsRequest;
  return proto.accountpb.GetAccountDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.GetAccountDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.GetAccountDetailsRequest}
 */
proto.accountpb.GetAccountDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.GetAccountDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.GetAccountDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.GetAccountDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.GetAccountDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.accountpb.GetAccountDetailsRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.GetAccountDetailsRequest} returns this
 */
proto.accountpb.GetAccountDetailsRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.accountpb.GetAccountDetailsRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.GetAccountDetailsRequest} returns this
 */
proto.accountpb.GetAccountDetailsRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.accountpb.GetAccountDetailsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.GetAccountDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.GetAccountDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.GetAccountDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.GetAccountDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    client: (f = msg.getClient()) && proto.accountpb.Client.toObject(includeInstance, f),
    subAccountsList: jspb.Message.toObjectList(msg.getSubAccountsList(),
    proto_accountpb_subaccount_pb.SubAccount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.GetAccountDetailsResponse}
 */
proto.accountpb.GetAccountDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.GetAccountDetailsResponse;
  return proto.accountpb.GetAccountDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.GetAccountDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.GetAccountDetailsResponse}
 */
proto.accountpb.GetAccountDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.Client;
      reader.readMessage(value,proto.accountpb.Client.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 2:
      var value = new proto_accountpb_subaccount_pb.SubAccount;
      reader.readMessage(value,proto_accountpb_subaccount_pb.SubAccount.deserializeBinaryFromReader);
      msg.addSubAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.GetAccountDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.GetAccountDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.GetAccountDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.GetAccountDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.Client.serializeBinaryToWriter
    );
  }
  f = message.getSubAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto_accountpb_subaccount_pb.SubAccount.serializeBinaryToWriter
    );
  }
};


/**
 * optional Client client = 1;
 * @return {?proto.accountpb.Client}
 */
proto.accountpb.GetAccountDetailsResponse.prototype.getClient = function() {
  return /** @type{?proto.accountpb.Client} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.Client, 1));
};


/**
 * @param {?proto.accountpb.Client|undefined} value
 * @return {!proto.accountpb.GetAccountDetailsResponse} returns this
*/
proto.accountpb.GetAccountDetailsResponse.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.GetAccountDetailsResponse} returns this
 */
proto.accountpb.GetAccountDetailsResponse.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.GetAccountDetailsResponse.prototype.hasClient = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SubAccount sub_accounts = 2;
 * @return {!Array<!proto.accountpb.SubAccount>}
 */
proto.accountpb.GetAccountDetailsResponse.prototype.getSubAccountsList = function() {
  return /** @type{!Array<!proto.accountpb.SubAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_accountpb_subaccount_pb.SubAccount, 2));
};


/**
 * @param {!Array<!proto.accountpb.SubAccount>} value
 * @return {!proto.accountpb.GetAccountDetailsResponse} returns this
*/
proto.accountpb.GetAccountDetailsResponse.prototype.setSubAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.accountpb.SubAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.SubAccount}
 */
proto.accountpb.GetAccountDetailsResponse.prototype.addSubAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.accountpb.SubAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.GetAccountDetailsResponse} returns this
 */
proto.accountpb.GetAccountDetailsResponse.prototype.clearSubAccountsList = function() {
  return this.setSubAccountsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ListClientSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ListClientSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ListClientSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListClientSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalRows: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ListClientSummary}
 */
proto.accountpb.ListClientSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ListClientSummary;
  return proto.accountpb.ListClientSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ListClientSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ListClientSummary}
 */
proto.accountpb.ListClientSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ListClientSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ListClientSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ListClientSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListClientSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalRows();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 total_rows = 1;
 * @return {number}
 */
proto.accountpb.ListClientSummary.prototype.getTotalRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.accountpb.ListClientSummary} returns this
 */
proto.accountpb.ListClientSummary.prototype.setTotalRows = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


goog.object.extend(exports, proto.accountpb);
