import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { listSystemCode } from '../../services/CommonService';
import color from '../../assets/css/colors';
import fontFamily from '../../assets/css/fonts';

const useStyles = makeStyles(() => ({
  autocompleteWrapper: {
      '& .MuiAutocomplete-inputRoot': {
          padding: '7px 15px 8px 15px !important',
          height: 48,
      },
      '& .MuiAutocomplete-option': {
          backgroundColor: 'red',
      },
      '& .MuiAutocomplete-endAdornment': {
          top: 'calc(50% - 15px)',
          '& svg': {
              width: '22px !important',
              height: '22px !important',
          }
      }
  },
  paper: {
      fontFamily: fontFamily.label,
      fontSize: 15,
      fontWeight: 600,
      backgroundColor: color.light.main,
  }
}));

export default function SelectTaxCountry(props) {
  const classes = useStyles();
  
  const [options, setOptions] = React.useState([]);
  const [inputValue, setinputValue] = React.useState(props.value || '');

  //get list from backend
  const getOptions = async (value) => {
    const { systemCodeList } = await listSystemCode({
      type: 'Country',
      code: value,
      limit: 10,
    });
    setOptions(systemCodeList.filter((s) => s.code));
  };

  React.useEffect(() => {
    getOptions(inputValue);
  }, [inputValue]);

  return (
    <Autocomplete
    className={classes.autocompleteWrapper}
      inputValue={inputValue}
      onInputChange={(e, selectedValue) => {
        if (!e) return;

        const value = e?.target?.value || '';

        if (value != selectedValue) {
          setinputValue(selectedValue.split(' - ')[0]);
        } else {
          setinputValue(value);
        }
      }}
      disabled={props.disabled}
      freeSolo={props.freeSolo}
      id={props.name}
      blurOnSelect={false}
      getOptionSelected={(option, value) => {
        return option.code === props.value;
      }}
      getOptionLabel={(option) => {
        if (option?.code) return option.code + ' - ' + option.description;
        return '';
      }}
      options={options}
      name={props.name}
      autoHighlight={true}
      value={options.find((o) => o.code === props.value) || {}}
      onChange={(_, obj) =>
        props.onChange(obj || { code: '', description: '' })
      }
      // open={open}
      renderInput={(params) => (
        <TextField
          {...params}
          error={props.error}
          name={props.name}
          required={props.required}
          disabled={props.disabled}
          label={props.label}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
}
