import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import Text from '../Typography/Text';
import Button from '../Buttons/Button';
import CloseButton from '../Buttons/CloseButton';
import { ReactComponent as Info } from '../../assets/icons/info.svg';
import { ReactComponent as Error } from '../../assets/icons/error.svg';
import { ReactComponent as Success } from '../../assets/icons/check-circle.svg';
import fontFamily from '../../assets/css/fonts';
import color from '../../assets/css/colors';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 550,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& > div:nth-child(1)': {
      flex: 'none',
      marginRight: 7,
      '& > svg': {
        width: 28,
        height: 28,
        '& path': {
          fill: color.info.main,
        },
      },
    },
    '& div:nth-child(2)': {
      flex: 1,
    },
    '& div:nth-child(3)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 120,
    },
  },
  incompleteSteps: {
    fontFamily: fontFamily.label,
    fontSize: 16,
    lineHeight: '24px',
    color: color.default.main,
    marginTop: 20,
    '& > label': {
      fontWeight: 600,
      marginBottom: 10,
      display: 'block',
    },
    '& ul': {
      listStyle: 'none',
      margin: '0px 0px 0px -40px',
      lineHeight: '30px',
      '& > li > span': {
        position: 'relative',
        top: 4,
        '& > svg': {
          width: 20,
          height: 20,
        },
      },
    },
  },
  success: {
    '& > svg > path': {
      fill: color.success.main,
    },
  },
  error: {
    '& > svg > path': {
      fill: color.error.main,
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-5px !important',
    },
  },
}));

export default function MessageModal(props) {
  const classes = useStyles();

  const { open, onClose, modalTitle, coverImage, content, completed, navigate, accountType, editMode } = props;

  const handleNavigateForm = () => {
    const { account, identification, employment, association, trading, objective, customerAccountAgreement, disclosures } =
      completed;

    if (!account) {
      navigate(editMode ? 0 : 1);
    } else if (!identification) {
      navigate(editMode ? 1 : 2);
    } else if (['I', 'J'].includes(accountType) && !employment) {
      navigate(editMode ? 2 : 3);
    } else if (!association) {
      navigate(editMode ? 3 : 4);
    } else if (!trading) {
      navigate(editMode ? 4 : 5);
    } else if (!objective) {
      navigate(editMode ? 5 : 6);
    } else if (!customerAccountAgreement) {
      navigate(editMode ? 6 : 7);
    } else if (!disclosures) {
      navigate(editMode ? 7 : 8);
    }

    onClose();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div>
          <div className={classes.modalHeader}>
            <div>
              <Info />
            </div>
            <div>
              <Text mt={4} variant="h2" label={modalTitle} />
            </div>
            <div className={classes.closeButton}>
              <CloseButton onClick={onClose} />
            </div>
          </div>
          <div className={classes.modalBody}>
            {coverImage && <div className="stepper-guide"></div>}
            <Text variant="body1" label={content} />
            {completed && (
              <div className={classes.incompleteSteps}>
                <label>Completion Progress:</label>
                <div className="grd-row nm">
                  <div className="grd-cell-none" style={{ marginRight: 30 }}>
                    <ul>
                      <li>
                        <span className={completed.account ? classes.success : classes.error}>
                          {completed.account ? <Success /> : <Error />}
                        </span>{' '}
                        Account
                      </li>
                      <li>
                        <span className={completed.identification ? classes.success : classes.error}>
                          {completed.identification ? <Success /> : <Error />}
                        </span>{' '}
                        Identification
                      </li>
                      <li>
                        <span className={completed.association ? classes.success : classes.error}>
                          {completed.association ? <Success /> : <Error />}
                        </span>{' '}
                        Association
                      </li>
                      {['I', 'J'].includes(accountType) && (
                        <li>
                          <span className={completed.employment ? classes.success : classes.error}>
                            {completed.employment ? <Success /> : <Error />}
                          </span>{' '}
                          Employment
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="grd-cell">
                    <ul>
                      <li>
                        <span className={completed.trustedContact ? classes.success : classes.error}>
                          {completed.trustedContact ? <Success /> : <Error />}
                        </span>{' '}
                        Trusted Contact
                      </li>
                      <li>
                        <span className={completed.trading ? classes.success : classes.error}>
                          {completed.trading ? <Success /> : <Error />}
                        </span>{' '}
                        Trading
                      </li>
                      <li>
                        <span className={completed.objective ? classes.success : classes.error}>
                          {completed.objective ? <Success /> : <Error />}
                        </span>{' '}
                        Objective
                      </li>
                      <li>
                        <span className={completed.customerAccountAgreement ? classes.success : classes.error}>
                          {completed.customerAccountAgreement ? <Success /> : <Error />}
                        </span>{' '}
                        Customer Account Agreement
                      </li>
                      <li>
                        <span className={completed.disclosures ? classes.success : classes.error}>
                          {completed.disclosures ? <Success /> : <Error />}
                        </span>{' '}
                        Disclosures
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={classes.modalFooter}>
            <Button label="Okay" onClick={completed ? handleNavigateForm : onClose} />
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
