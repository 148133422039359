import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '../../components/Textfields/TextField';
import FormMessage from '../../components/Messages/FormMessage';
import Button from '../../components/Buttons/Button';
import PasswordField from '../../components/Textfields/PasswordField';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { validatePassword } from '../../lib/validate/validate';
import authSvc from '../../services/AuthService';
import { hasSecurityQuestions, getSecurityQuestion } from '../../services/AdministratorService';
import CodeField from '../../components/Textfields/CodeField';
import {
    Typography,
    Box,
    Link,
    Grid,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button as MUIButton
} from '@material-ui/core'
import AlertDialog from '../../components/Modals/AlertDialog';
import { Link as RouterLink } from "react-router-dom";
import APWGrid from '../../components/Grid/APWGrid';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginLeft: theme.spacing(8),
        marginTop: theme.spacing(8),
        textAlign: 'left'
    },

    backButtonWrapper: {
        marginBottom: "12px",
        display: "flex",
        alignItems: "center",
        '& .backIcon': {
            width: '12px',
        }
    }

}));

export default function ForgotPasswordPreLogin(props) {

    useEffect(() => {
        resetMessages();
    }, []);

    const classes = useStyles();

    const { backToSignin } = props;

    const [loading, setLoading] = React.useState({
        verifyUsername: false,
        verifyAuthCode: false,
        securityQuestion: false,
        changePassword: false,
        resendAuthCode: false,
    });

    const [timerLoading, setTimerLoading] = React.useState({
        timer: false,
    });

    const [message, setMessage] = React.useState({
        type: '',
        value: '',
    });

    const [display, setDisplay] = React.useState({
        usernameVerification: true,
        otpVerification: false,
        securityQuestion: false,
        changePassword: false,
    });

    const resetMessages = () => {
        setMessage({
            type: '',
            value: '',
        });
    };

    const changeDisplay = (usernameVerification, otpVerification, securityQuestion, changePassword) => {
        resetMessages();
        setDisplay({ ...display, usernameVerification, otpVerification, securityQuestion, changePassword });
    };

    const internalError = () => {
        toggleAlert(true)
        const dlg = {
            title: "Internal Server Error",
            content: (
                <DialogContent>
                    <DialogContentText>
                        {`Please try again`}
                    </DialogContentText>
                </DialogContent>
            ),
        }

        setDialog({ ...dialog, title: dlg.title, content: dlg.content, actions: dlg.actions })
    }

    /***********************************/


    /*Verify Username Functionalities*/

    const SMS_DELIVERY_METHOD = "1"
    const EMAIL_DELIVERY_METHOD = "2"

    const initialAccountState = {
        username: '',
        userId: 0,
        deliveryMethod: SMS_DELIVERY_METHOD,
        deliveryAddress: '',
    }
    // For account
    const [account, setAccount] = React.useState(initialAccountState);

    // For validation of username
    const initialVerifyUsernameFormState = {
        username: '',
    }

    const [verifyUsernameForm, setVerifyUsernameForm] = React.useState(initialVerifyUsernameFormState);

    const handleVerifyUsername = () => {
        if (!verifyUsernameForm.username) {
            setMessage({ type: 'error', value: 'Username is required.' });
            return;
        }

        resetMessages();
        setStartTimer(false);
        setLoading({ ...loading, verifyUsername: true });
        authSvc.validateAccount(verifyUsernameForm).then((response) => {
            if (response.userId !== 0) {
                const updatedAccountData = { ...account, username: verifyUsernameForm.username, userId: response.userId }


                authSvc.sendAuthenticationCode(updatedAccountData).then((response) => {
                    if (response.success) {
                        setStartTimer(true);
                        setLoading({ ...loading, verifyUsername: false });
                        setTimerLoading({ ...timerLoading, timer: true })
                        setVerifyUsernameForm(initialVerifyUsernameFormState)
                        setVerifyOTPForm({ ...verifyOTPForm, otp: '' })
                        updatedAccountData.deliveryAddress = response.deliveryAddress
                        setAccount(updatedAccountData);
                        changeDisplay(false, true, false, false);
                    }
                }).catch((err) => {
                    //setMessage({ type: 'error', value: err.message });
                    setLoading({ ...loading, verifyUsername: false });
                    showUsernameErrorNotification(err.errorCode)
                    return;
                });
            }
        }).catch((err) => {

            console.log("ok ", err)
            //setMessage({ type: 'error', value: err.message });
            setLoading({ ...loading, verifyUsername: false });
            showUsernameErrorNotification(err.errorCode)
            return;
        });


    };

    const showUsernameErrorNotification = (code) => {
        //console.log(code)

        if (code === "identity_user_notfound_error") {
            alertInvalidUsername()
        } else if (code === "account_locked") {
            alertAccountLocked()
        } else if (code === "account_closed" || code === "account_inactive") {
            alertAccountSuspended()
        } else if (code === "multiple_attempts") {
            alertMultipleAttempts()
        } else if (code === "err_phone_number_empty") {
            alertAccountHasNoPhones()
        } else {
            internalError()
        }
    }

    const alertInvalidUsername = () => {
        toggleAlert(true)
        const dlg = {
            title: "Let's try that again",
            content: (
                <DialogContent>
                    <DialogContentText>
                        {`
                        The username you provided isn't recognized. Try entering
                        your username again or call customer support at [#-###-###-
                        ####] to reset your password.
                        `}
                    </DialogContentText>
                </DialogContent>
            ),
            actions: (
                <React.Fragment>
                    <MUIButton
                        fullWidth={true}
                        component={RouterLink}
                        to={"/forgot-username"}
                        variant="outlined"
                        color="primary"
                        size="large"
                    >
                        Forgot Username?
                    </MUIButton>
                    <Button
                        fullWidth={true}
                        label="Try Again"
                        onClick={() => {
                            toggleAlert(false)
                        }}
                    />
                </React.Fragment>

            ),
        }

        setDialog({ ...dialog, title: dlg.title, content: dlg.content, actions: dlg.actions })
    }

    const alertMultipleAttempts = () => {
        toggleAlert(true)
        const dlg = {
            title: "Let's try that again",
            content: (
                <DialogContent>
                    <DialogContentText>
                        {`
                        We don't have the appropriate information on file. In
                        order to reset your password, please call customer
                        service at [#-###-###-####] to verify your
                        information. 
                        `}
                    </DialogContentText>
                </DialogContent>
            ),
            actions: (
                <Button
                    mt={true}
                    label="Back to Sign"
                    onClick={() => {
                        backToSignin()
                    }}
                />

            ),
        }

        setDialog({ ...dialog, title: dlg.title, content: dlg.content, actions: dlg.actions })
    }

    const alertAccountLocked = () => {
        toggleAlert(true)
        const dlg = {
            title: "Account locked",
            content: (
                <DialogContent>
                    <DialogContentText>
                        {`Your account is locked. Please try again in <1 hour>
                        or contact customer support at [#-###-###-####]`}
                    </DialogContentText>
                </DialogContent>
            ),
            actions: (
                <Button
                    mt={true}
                    label="Back to Sign"
                    onClick={() => {
                        backToSignin()
                    }}
                />
            ),
        }

        setDialog({ ...dialog, title: dlg.title, content: dlg.content, actions: dlg.actions })
    }

    const alertAccountSuspended = () => {
        toggleAlert(true)
        const dlg = {
            title: "Your account may be suspended",
            content: (
                <DialogContent>
                    <DialogContentText>
                        {`Please call customer support at [#-###-###-####] to reset your password`}
                    </DialogContentText>
                </DialogContent>
            ),
            actions: (
                <Button
                    mt={true}
                    label="Back to Sign"
                    onClick={() => {
                        backToSignin()
                    }}
                />
            ),
        }

        setDialog({ ...dialog, title: dlg.title, content: dlg.content, actions: dlg.actions })
    }

    const alertAccountHasNoPhones = () => {
        toggleAlert(true)
        const dlg = {
            title: "Your account may not have a phone setup",
            content: (
                <DialogContent>
                    <DialogContentText>
                        {`Please call customer support at [#-###-###-####] to reset your password`}
                    </DialogContentText>
                </DialogContent>
            ),
            actions: (
                <Button
                    mt={true}
                    label="Back to Sign"
                    onClick={() => {
                        backToSignin()
                    }}
                />
            ),
        }

        setDialog({ ...dialog, title: dlg.title, content: dlg.content, actions: dlg.actions })
    }

    /*END of Verify Username Functionalities*/


    /***********************************/


    /*Verify OTP Functionalities*/

    const [startTimer, setStartTimer] = React.useState(false);

    // For validation of OTP
    const [verifyOTPForm, setVerifyOTPForm] = React.useState({
        otp: '',
    });

    const initialAttempts = 5
    const [invalidOTPAttempts, setInvalidOTPAttempts] = React.useState(initialAttempts);

    const handleVerifyOTP = () => {
        if (!verifyOTPForm.otp) {
            setMessage({ type: 'error', value: 'Authentication Code is required.' });
            return;
        }

        setLoading({ ...loading, verifyAuthCode: true });

        authSvc.validateAuthenticationCode({
            username: account.username,
            authenticationCode: verifyOTPForm.otp,
            deliveryMethod: account.deliveryMethod,
        }).then((response) => {
            //console.log(response, "in hasSecurityQuestions ")
            if (response.success) {
                return hasSecurityQuestions(account.userId)
            }
        }).then((response) => {
            setUserHasSecurityQuestions(response.hasSecurityQuestions)

            if (response.hasSecurityQuestions) {
                return getSecurityQuestion(account.username)
            } else {
                //TODO Alert some error here
            }
        }).then((response) => {
            resetMessages();
            changeDisplay(false, false, true, false);
            setSecurityQuestionData({ ...securityQuestionData, questionID: response.questionId, questionText: response.questionText })
        }).catch((error) => {
            //console.log("showOTPErrorNotification ", error)
            //setMessage({ type: 'error', value: error.message });
            setLoading({ ...loading, verifyAuthCode: false });
            showOTPErrorNotification(error.errorCode)
            return;
        })

    };

    const handleResendNewCode = () => {
        setInvalidOTPAttempts(initialAttempts)
        setStartTimer(false);
        setVerifyOTPForm({ ...verifyOTPForm, otp: '' })
        setLoading({ ...loading, resendAuthCode: true })
        authSvc.sendAuthenticationCode(account).then((response) => {
            if (response.success) {
                setStartTimer(true);
                setTimerLoading({ ...timerLoading, timer: true })
                setLoading({ ...loading, resendAuthCode: false })
            }
        }).catch((err) => {
            //setMessage({ type: 'error', value: err.message });
            return;
        });
    }

    const showOTPErrorNotification = (code) => {
        //console.log(code)

        if (code === "identity_invalid_otp_error") {
            alertIncorrectOTP()
        } else if (code === "identity_invalid_otp_exceeded_error") {
            alertAccountLockedMultipleOTP();
        } else {
            internalError()
        }
    }

    const alertIncorrectOTP = () => {
        const attempts = invalidOTPAttempts
        toggleAlert(true)
        const dlg = {
            title: "Let's try that again",
            content: (
                <DialogContent>
                    <DialogContentText>
                        {`The verification code you entered is incorrect. Please verify
                            your code and try again.`}
                    </DialogContentText>
                    {attempts !== 5 && (
                        <React.Fragment>
                            {`You have ${attempts} more attempts`}
                        </React.Fragment>
                    )}
                </DialogContent>
            ),
            actions: (
                <React.Fragment>
                    <Button
                        mt={true}
                        label="Send me a new code"
                        onClick={() => {
                            handleResendNewCode()
                            toggleAlert(false)
                        }}
                    />
                    <Button
                        mt={true}
                        label="Try Again"
                        onClick={() => {
                            toggleAlert(false)
                        }}
                    />
                </React.Fragment>
            ),
        }

        setDialog({ ...dialog, title: dlg.title, content: dlg.content, actions: dlg.actions })
        setInvalidOTPAttempts(invalidOTPAttempts - 1)
    }

    const alertAccountLockedMultipleOTP = () => {
        toggleAlert(true)
        const dlg = {
            title: "Account Locked",
            content: (
                <DialogContent>
                    <DialogContentText>
                        {`
                        You’ve exceeded the allowed number of attempts.
                        Your account is locked. Please try again in 1 hour or
                        contact customer support at [# ### ### ####].
                        `}
                    </DialogContentText>
                </DialogContent>
            ),
            actions: (
                <Button
                    mt={true}
                    label="Back to Sign"
                    onClick={() => {
                        backToSignin()
                    }}
                />
            ),
        }

        setDialog({ ...dialog, title: dlg.title, content: dlg.content, actions: dlg.actions })
    }


    /*END of Verify OTP Functionalities*/


    /***********************************/


    /*Verify Security Question Functionalities*/

    const [userHasSecurityQuestions, setUserHasSecurityQuestions] = React.useState(false);

    const initialSecurityState = {
        questionID: '',
        answer: '',
        questionText: '',
    }
    // For account
    const [securityQuestionData, setSecurityQuestionData] = React.useState(initialSecurityState);

    const handleVerifySecurityAnswer = () => {
        if (!securityQuestionData.answer) {
            setMessage({ type: 'error', value: 'Please input security answer' });
            return;
        }

        setStartTimer(false);
        setLoading({ ...loading, securityQuestion: true });
        setTimerLoading({ ...timerLoading, timer: false })
        authSvc.verifySecurityAnswer(securityQuestionData).then((response) => {
            //console.log(response, "verifySecurityAnswer")
            if (response.success) {
                resetMessages();
                changeDisplay(false, false, false, true);
            }
        }).catch((error) => {
            //console.log("Security ", error)
            //setMessage({ type: 'error', value: error.message });
            setLoading({ ...loading, securityQuestion: false });
            showSecurityErrorNotification(error.errorCode);
        });
    }

    const handleSecurityDataChange = (e) => {
        const input = e.currentTarget.name ? e.currentTarget : e.target;

        setSecurityQuestionData({
            ...securityQuestionData,
            [input.name]: input.value,
        });
    };

    const showSecurityErrorNotification = (code) => {
        //console.log(code)

        if (code === "identity_incorrect_security_answer_error") {
            alertInvalidSecurityAnswer()
        } else if (code === "identity_invalid_answer_exceeded_error") {
            alertAccountLockedInvalidAnswer();
        } else {
            internalError()
        }
    }

    const alertInvalidSecurityAnswer = () => {
        toggleAlert(true)
        const dlg = {
            title: "Let's try that again",
            content: (
                <DialogContent>
                    <DialogContentText>
                        {`
                        The answer you provided is incorrect. Please try again.
                        `}
                    </DialogContentText>
                </DialogContent>
            ),
            actions: (
                <Button
                    mt={true}
                    label="Try Again"
                    onClick={() => {
                        toggleAlert(false)
                    }}
                />
            ),
        }

        setDialog({ ...dialog, title: dlg.title, content: dlg.content, actions: dlg.actions })
    }

    const alertAccountLockedInvalidAnswer = () => {
        toggleAlert(true)
        const dlg = {
            title: "Account Locked",
            content: (
                <DialogContent>
                    <DialogContentText>
                        {`
                        You’ve exceeded the allowed number of attempts.
                        Your account is locked. Please try again in 1 hour or
                        contact customer support at [# ### ### ####].
                        `}
                    </DialogContentText>
                </DialogContent>
            ),
            actions: (
                <Button
                    mt={true}
                    label="Back to Sign"
                    onClick={() => {
                        backToSignin()
                    }}
                />
            ),
        }

        setDialog({ ...dialog, title: dlg.title, content: dlg.content, actions: dlg.actions })
    }


    /*END of Verify Security Question Functionalities*/


    /***********************************/


    /*Change Password Functionalities*/

    const forgotPasswordState = {
        newPassword: '',
        confirmPassword: '',
    }
    const [forgotPasswordData, setForgotPasswordDataData] = React.useState(forgotPasswordState);

    const handleForgotPasswordDataChange = (e) => {
        const input = e.currentTarget.name ? e.currentTarget : e.target;

        setForgotPasswordDataData({
            ...forgotPasswordData,
            [input.name]: input.value,
        });
    };

    const handleChangePassword = () => {
        if (!forgotPasswordData.newPassword) {
            setMessage({ type: 'error', value: 'New Password is required.' });
            return;
        }
        if (!forgotPasswordData.confirmPassword) {
            setMessage({ type: 'error', value: 'Confirm Password is required.' });
            return;
        }
        if (validatePassword(forgotPasswordData.newPassword).err) {
            setMessage({
                type: 'error',
                value: 'New Password does not meet the requirement.',
            });
            return;
        }
        if (validatePassword(forgotPasswordData.confirmPassword).err) {
            setMessage({
                type: 'error',
                value: 'Confirm Password does not meet the requirement.',
            });
            return;
        }
        if (forgotPasswordData.newPassword !== forgotPasswordData.confirmPassword) {
            setMessage({ type: 'error', value: 'Password does not match.' });
            return;
        }

        setStartTimer(false);
        setLoading({ ...loading, changePassword: true });
        setTimerLoading({ ...timerLoading, timer: false })

        authSvc.changePasswordPreLogin({
            username: account.username,
            password: forgotPasswordData.newPassword,
        }).then((response) => {
            if (response.success) {
                setForgotPasswordDataData(forgotPasswordState)
                setAccount(initialAccountState);
                setMessage({ type: 'success', value: 'Successfully Changed Password' });
                setLoading({ ...loading, changePassword: false });
                setTimerLoading({ ...timerLoading, timer: false })
                alertPasswordChangedSuccess();
            }
        }).catch((error) => {
            console.log(error, " errorerrorerror")
            //setMessage({ type: 'error', value: error.message });
            setLoading({ ...loading, changePassword: false });
            resetMessages();
            showChangePassErrorNotification(error.errorCode)
            return;
        })
    };

    const alertPasswordChangedSuccess = () => {
        toggleAlert(true)
        const dlg = {
            title: "Success",
            content: (
                <DialogContent>
                    <DialogContentText>
                        {`
                        Your password has been updated successfully.
                        `}
                    </DialogContentText>
                </DialogContent>
            ),
            actions: (
                <Button
                    mt={true}
                    label="Back to Sign"
                    onClick={() => {
                        backToSignin()
                    }}
                />
            ),
        }

        setDialog({ ...dialog, title: dlg.title, content: dlg.content, actions: dlg.actions })
    }

    const alertCannotUsePasswordHistory = () => {
        toggleAlert(true)
        const dlg = {
            title: "Let's try that again",
            content: (
                <DialogContent>
                    <DialogContentText>
                        {`
                        Cannot use the previous passwords
                        `}
                    </DialogContentText>
                </DialogContent>
            ),
            actions: (
                <Button
                    fullWidth={true}
                    label="Try Again"
                    onClick={() => {
                        toggleAlert(false)
                    }}
                />

            ),
        }

        setDialog({ ...dialog, title: dlg.title, content: dlg.content, actions: dlg.actions })
    }

    const showChangePassErrorNotification = (code) => {
        //console.log(code)

        if (code === "identity_password_history_error") {
            alertCannotUsePasswordHistory()
        } else {
            internalError()
        }
    }


    /*END of Change Password Functionalities*/


    /***********************************/


    /*Dialogs Functionalities*/

    const [alertDialog, setAlertDialog] = React.useState({
        open: false,
    })

    const toggleAlert = (toggle) => {
        setAlertDialog({ ...alertDialog, open: toggle })
    }

    const [dialog, setDialog] = React.useState({
        title: "",
        content: null,
        actions: null,
    })

    /*END of Dialogs Functionalities*/

    return (
        <Box id="forgot_password_container" className={classes.root}>
            <APWGrid>
                <APWGrid.Item lg={4} md={4} sm={6}>
                    <AlertDialog
                        open={alertDialog.open}
                        title={dialog.title}
                        toggleDialog={toggleAlert}
                        content={dialog.content}
                        actionButtons={dialog.actions}
                    />
                    {display.usernameVerification && (
                        <Link
                            component="button"
                            variant="body2"
                            onClick={() => {
                                backToSignin(); setStartTimer(false);
                                setTimerLoading({ ...timerLoading, timer: false })
                            }}
                        >
                            <Box className={classes.backButtonWrapper}>
                                <ArrowBackIosNewRoundedIcon className="backIcon" fontSize="small" /> Back to Signin
                            </Box>

                        </Link>
                    )}

                    {(display.otpVerification || display.securityQuestion) && (<Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                            changeDisplay(true, false, false, false);
                            setStartTimer(false);
                            setTimerLoading({ ...timerLoading, timer: false })
                        }}
                    >
                        <Box className={classes.backButtonWrapper}>
                            <ArrowBackIosNewRoundedIcon className="backIcon" fontSize="small" /> Forgot Password
                        </Box>

                    </Link>
                    )}
                    {display.usernameVerification && (<div id="username_verification_container">
                        <Box sx={{ display: 'block' }}>
                            <Typography variant="h2">Forgot your password?</Typography>
                            <Typography variant="body1" style={{ marginBottom: 10 }}>
                                Enter the username associated with your account and we'll send you a code to reset your password
                            </Typography>
                        </Box>


                        {message.value !== '' ? <FormMessage id="lbl_err_username_verification" type={message.type} message={message.value} /> : null}
                        <TextField
                            id="txt_username"
                            autoFocus={true}
                            name="username"
                            label="Username"
                            placeholder="Username"
                            type="text"
                            max={50}
                            showIcon={true}
                            value={verifyUsernameForm.username}
                            fullWidth={true}
                            onChange={(e) => {
                                const input = e.currentTarget.name ? e.currentTarget : e.target;
                                setVerifyUsernameForm({ ...verifyUsernameForm, [input.name]: input.value });
                            }}
                            onKeyUp={(e) => ((e.keyCode === 13 || e.key === 'Enter' || e.key === 'NumpadEnter') ? handleVerifyUsername() : null)}
                        />
                        <RouterLink to={"/forgot-username"}>
                            <Link
                                component="button"
                                variant="body2"
                                underline="always"
                            >
                                Forgot username?
                            </Link>
                        </RouterLink>

                        <Button
                            fullWidth={false}
                            id="btn_verify_username"
                            mt={true}
                            disabled={loading.verifyUsername || verifyUsernameForm.username === ''}
                            loading={loading.verifyUsername}
                            label={loading.verifyUsername ? 'Verifying your username...' : 'Next'}
                            onClick={handleVerifyUsername}
                        />
                    </div>)}

                    {display.otpVerification && (
                        <>
                            <Box sx={{ display: 'block' }}>
                                <Typography variant="h2">We just sent you a text</Typography>
                                <Typography variant="body1" style={{ marginBottom: 10 }}>
                                    {`Enter the verification code sent to (XXX) XXX-${account.deliveryAddress}`}
                                </Typography>
                            </Box>

                            {message.value !== '' ? <FormMessage id="lbl_err_auth_verification" type={message.type} message={message.value} /> : null}
                            <CodeField
                                id="txt_auth_code"
                                mt={true}
                                max={36}
                                showIcon={false}
                                startTimer={startTimer}
                                setLoading={setTimerLoading}
                                name="otp"
                                label="Verification Code"
                                placeholder={`${(account.deliveryMethod === SMS_DELIVERY_METHOD || account.deliveryMethod === EMAIL_DELIVERY_METHOD) ? "6 Alphanumeric Code" : "36 Alphanumeric Code"}`}
                                type="text"
                                value={verifyOTPForm.otp}
                                onChange={(e) => {
                                    const input = e.currentTarget.name ? e.currentTarget : e.target;
                                    setVerifyOTPForm({ ...verifyOTPForm, [input.name]: input.value });
                                }}
                                onKeyUp={(e) => ((e.keyCode === 13 || e.key === 'Enter' || e.key === 'NumpadEnter') ? handleVerifyOTP() : null)}
                            />
                            <Link
                                disabled={loading.verifyAuthCode}
                                component="button"
                                variant="body2"
                                underline="always"
                                onClick={handleResendNewCode}
                            >
                                {loading.resendAuthCode ? 'Sending new code...' : 'Send me a new code'}
                            </Link>
                            <Button
                                fullWidth={false}
                                id="btn_verify_auth_Code"
                                mt={true}
                                disabled={loading.verifyAuthCode || !timerLoading.timer}
                                loading={loading.verifyAuthCode}
                                label={loading.verifyAuthCode ? 'Verifying OTP...' : 'Submit'}
                                onClick={handleVerifyOTP}
                            />

                        </>)}

                    {display.securityQuestion && (
                        <>
                            <Box sx={{ display: 'block' }}>
                                <Typography variant="h2">Please answer your security question</Typography>
                                <Typography variant="body1" style={{ marginBottom: 10 }}>
                                    {`Please answer a security question to reset password`}
                                </Typography>
                            </Box>

                            {message.value !== '' ? <FormMessage id="lbl_err_Change_pass" type={message.type} message={message.value} /> : null}
                            <Typography variant="body2" style={{ marginBottom: 10 }}>
                                {securityQuestionData.questionText}?
                            </Typography>
                            <TextField
                                id="txt_security_answer"
                                required={true}
                                autoFocus={true}
                                max={50}
                                showIcon={false}
                                name="answer"
                                label="Answer"
                                placeholder="Answer"
                                type="text"
                                value={securityQuestionData.answer}
                                onChange={handleSecurityDataChange}
                                onKeyUp={(e) => ((e.keyCode === 13 || e.key === 'Enter' || e.key === 'NumpadEnter') ? handleVerifySecurityAnswer() : null)}
                            />

                            <Button
                                fullWidth={false}
                                id="btn_change_password"
                                mt={true}
                                disabled={loading.securityQuestion}
                                loading={loading.securityQuestion}
                                label={loading.securityQuestion ? 'Verifying Answer...' : 'Next'}
                                onClick={handleVerifySecurityAnswer}
                            />
                        </>)}

                    {display.changePassword && (
                        <>
                            <Box sx={{ display: 'block' }}>
                                <Typography variant="h2">Reset your password</Typography>
                                <Typography variant="body1" style={{ marginBottom: 10 }}>
                                    {`Your new password must be different from your last five passwords`}
                                </Typography>
                            </Box>

                            {message.value !== '' ? <FormMessage id="lbl_err_Change_pass" type={message.type} message={message.value} /> : null}
                            <PasswordField
                                id="txt_new_password"
                                required={true}
                                mt={true}
                                max={50}
                                showIcon={true}
                                showPasswordRequirement={true}
                                name="newPassword"
                                label="Create Password"
                                placeholder="Create Password"
                                type="password"
                                value={forgotPasswordData.newPassword}
                                onChange={handleForgotPasswordDataChange}
                                onKeyUp={(e) => ((e.keyCode === 13 || e.key === 'Enter' || e.key === 'NumpadEnter') ? handleChangePassword() : null)}
                            />
                            <PasswordField
                                id="txt_confirm_password"
                                required={true}
                                mt={true}
                                max={50}
                                showIcon={true}
                                showPasswordRequirement={true}
                                name="confirmPassword"
                                label="Confirm Password"
                                placeholder="Confirm Password"
                                type="password"
                                value={forgotPasswordData.confirmPassword}
                                onChange={handleForgotPasswordDataChange}
                                onKeyUp={(e) => ((e.keyCode === 13 || e.key === 'Enter' || e.key === 'NumpadEnter') ? handleChangePassword() : null)}
                            />
                            <Button
                                fullWidth={false}
                                id="btn_change_password"
                                mt={true}
                                disabled={loading.changePassword}
                                loading={loading.changePassword}
                                label={loading.changePassword ? 'Updating Password...' : 'Update Password'}
                                onClick={handleChangePassword}
                            />
                        </>)}
                </APWGrid.Item>
            </APWGrid>
        </Box>
    );
}