import React, { useState } from "react";
import { InputLabel, Select, MenuItem, FormControl } from "@material-ui/core";
import { listGlBank } from "../../services/BankAccountService";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  disabled: {
    color: "rgba(0, 0, 0, 0.38)",
  },
}));

export default function SelectGLBankAccount(props) {
  const classes = useStyles();

  const [options, setOptions] = useState([]);
  const [mounted, setMounted] = React.useState(false);

  const getOptions = async () => {
    const data = await listGlBank();
    setOptions(data.glBanksList);
  };

  React.useEffect(() => {
    if (!options.length && !mounted) {
      getOptions();
    }
    return () => {
      setMounted(true);
    };
    // eslint-disable-next-line
  }, [options, mounted]);

  return (
    <FormControl
      fullWidth
      error={props.error}
      style={{
        marginTop: props.mt ? props.mt : 0,
        marginBottom: props.mb ? props.mb : 0,
      }}
    >
      <InputLabel
        className={props.disabled ? classes.disabled : ""}
        shrink
        required={props.required}
      >
        {props.label}
      </InputLabel>
      <Select
        displayEmpty
        fullWidth
        disabled={props.disabled}
        name={props.name}
        value={props.value ? props.value : ""}
        onChange={props.onChange}
      >
        <MenuItem value="">
          <em>Blank</em>
        </MenuItem>
        {options.length ? (
          options.map((item, index) => (
            <MenuItem key={index} value={item?.accountId}>
              {item?.accountNo}
            </MenuItem>
          ))
        ) : (
          <MenuItem key="" value=""></MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
