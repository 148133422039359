/* eslint-disable no-inner-declarations */
import React, { useState, useEffect } from 'react';
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import { notifySuccess, notifyError } from '../../../components/Messages/Notification';
import ClientGrid from '../../../components/ClientGrid/ClientGrid';

import { listUsrAccess, updateAmountLimit } from '../../../services/UsrAccessService';
import { createAccess, deleteAccess } from '../../../services/AdministratorService';

function numberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

numberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function RoleFunctionTable({ id }) {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(0);
  const [rowsSelected, setRowsSelected] = React.useState([]);
  const [selectedValues, setSelectedValues] = React.useState([]);
  const [newRowsSelected, setNewRowsSelected] = React.useState([]);
  const [selectedListStatus, setSelectedListStatus] = React.useState('Show Selected');

  const [pagination, setPagination] = React.useState({
    pageNo: 0,
    rowsPerPage: 100,
    count: 2000,
  });

  async function fetchData(selectedStatus) {
    const params = {
      roleId: id,
      requestType: selectedStatus,
    };

    const data = await listUsrAccess(params);
    let selected = [];

    if (selectedStatus === 'Show All') {
      data.usrAccessesList.filter(function (v, index) {
        if (v.roleId !== 0) {
          selected.push(index);
        }
      });

      setRows(data.usrAccessesList);
      setRowsSelected(selected);
    } else if (selectedStatus === 'Show Selected') {
      let showSelected = [];

      data.usrAccessesList.filter(function (v, index) {
        if (v.roleId !== 0) {
          showSelected.push(v);
        }
      });

      showSelected.filter(function (v, index) {
        selected.push(index);
      });

      setRows(showSelected);
      setRowsSelected(selected);
    } else {
      let showUnselected = [];

      data.usrAccessesList.filter(function (v, index) {
        if (v.roleId === 0) {
          showUnselected.push(v);
        }
      });

      setRows(showUnselected);
      setRowsSelected([]);
    }
  }

  useEffect(() => {
    initializeTable();
  }, []);

  const options = {
    serverSide: false,
    // selectableRowsOnClick: true,
    selectableRows: 'multiple',
    // selectableRowsHeader: true,
    selectToolbarPlacement: 'none',
    rowsSelected: rowsSelected,
    customToolbarSelect: (selectedRows, a, b) => {
      return null;
    },
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      let selected = [];

      if (currentRowsSelected.length === 1 && rows.length !== 0) {
        let dataIndex = currentRowsSelected[0].index;
        const param = {
          roleId: id,
          accessId: rows[dataIndex].accessId,
          access: rows[dataIndex].access,
          subAccess: rows[dataIndex].subAccess,
        };

        if (rows[dataIndex].roleId !== 0) {
          rows[dataIndex].roleId = 0;
          handleRemove(param, false, 0);
          if (selectedListStatus === 'Show Selected') {
            rows.splice(dataIndex, 1);

            rows.filter(function (v, index) {
              if (v.roleId !== 0) {
                selected.push(index);
              }
            });
            setNewRowsSelected(selected);
          }
        } else {
          rows[dataIndex].roleId = id;
          handleAdd(param, false, 0);

          if (selectedListStatus === 'Show Unselected') {
            rows.splice(dataIndex, 1);
            setNewRowsSelected([]);
          }
        }
      }

      if (selectedListStatus === 'Show All') {
        setRowsSelected(rowsSelected);
      } else if (selectedListStatus === 'Show Selected') {
        setRowsSelected(selected);
      } else {
        setRowsSelected(newRowsSelected);
      }

      if (currentRowsSelected.length === 0) {
        function removeMultipleAccountaccess(item, index) {
          const param = {
            accessId: rows[index].accessId,
          };

          rows[index].roleId = 0;
          handleRemove(param, true, index);
        }
        rows.forEach(removeMultipleAccountaccess);

        setRowsSelected(rowsSelected);

        if (selectedListStatus === 'Show Selected') {
          rows.splice(0, rows.length);
        }
      }
      if (
        rowsSelected.length === rows.length &&
        (selectedListStatus === 'Show All' || selectedListStatus === 'Show Unselected')
      ) {
        let count = 0;

        function addMultipleAccountaccess(index) {
          count++;

          const param = {
            roleId: id,
            accessId: rows[index].accessId,
            access: rows[index].access,
            subAccess: rows[index].subAccess,
          };

          rows[index].roleId = id;
          handleAdd(param, true, index, count);
        }

        if (rows.length > 1) {
          rowsSelected.forEach(addMultipleAccountaccess);
        }

        if (selectedListStatus === 'Show Unselected') {
          rows.splice(0, rows.length);
        }
      }
    },
  };

  const handleChangeAmountLimit = async (data, amountLimit, rows, dataIndex) => {
    if (!amountLimit) {
      amountLimit = '0';
    }

    try {
      setLoading(true);
      await updateAmountLimit(data.accessId, amountLimit.replaceAll(',', ''));
      const copy = [...rows];
      copy[dataIndex].amountLimit = amountLimit;
      setRows(copy);
      if (amountLimit !== '')
        notifySuccess(
          'Amount limit for ' +
            copy[dataIndex].access +
            ' - ' +
            copy[dataIndex].subAccess +
            ' is successfully updated to $' +
            amountLimit
        );
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const GridButtons = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    const rows = tableManager.rowsApi.rows;
    const rowsSelected = tableManager.config.additionalProps.header.props.rowsSelected;
    const checkboxValue = rowsSelected.includes(rowIndex - 1);
    return (
      <div
        align={'left'}
        className="grd-row"
        style={{
          margin: 0,
          padding: 0,
          width: 200,
          textAlign: 'center',
          backgroundColor: 'whitesmoke',
        }}
      >
        {data?.access === 'Bank Request' && data?.subAccess === 'Pending' && checkboxValue && (
          <TextField
            fullWidth
            name="amountLimit"
            disabled={loading}
            value={data.amountLimit === '0' ? '' : data.amountLimit}
            onBlur={(e) => {
              handleChangeAmountLimit(data, e.target.value, rows, rowIndex - 1);
            }}
            placeholder="0.00"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: numberFormatCustom,
              startAdornment: <InputAdornment position="start">{'$'}</InputAdornment>,
            }}
          />
        )}
      </div>
    );
  };

  const GridCheckbox = ({
    tableManager,
    value,
    data,
    column,
    colIndex,
    rowIndex,
    onChange,
    disabled,
  }) => {
    const rowsSelected = tableManager.config.additionalProps.header.props.rowsSelected;
    const selectedStatus =
      tableManager.config.additionalProps.header.props.selectedListStatus;
    const checkboxValue = rowsSelected.includes(rowIndex - 1);
    return (
      <div style={{ paddingLeft: 20 }}>
        <input
          key={rowIndex}
          type="checkbox"
          checked={checkboxValue}
          disabled={disabled}
          column={column}
          onChange={(event) => {
            const checked = event.target.checked;
            const param = {
              roleId: id,
              accessId: data.accessId,
              access: data.access,
              subAccess: data.subAccess,
            };
            if (checked) {
              handleAdd(param, selectedStatus);
            } else {
              param.roleId = 0;
              handleRemove(param, selectedStatus);
            }
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      id: 'usrAccess',
      width: '60px',
      visible: true,
      cellRenderer: GridCheckbox,
    },
    {
      id: 'access',
      field: 'access',
      label: 'Access',
    },
    {
      id: 'subAccess',
      field: 'subAccess',
      label: 'Sub Access',
    },
    {
      id: 'amountLimit',
      field: 'amountLimit',
      label: 'Amount Limit',
      cellRenderer: GridButtons,
    },
  ];

  const initializeTable = async () => {
    const params = {
      roleId: id,
      requestType: 'Show Selected',
    };

    const data = await listUsrAccess(params);
    let selected = [];
    let selectedVals = [];

    let showSelected = [];

    data.usrAccessesList.filter(function (v, index) {
      if (v.roleId !== 0) {
        showSelected.push(v);
        selectedVals.push({ access: v.access, subAccess: v.subAccess });
      }
    });

    showSelected.filter(function (v, index) {
      selected.push(index);
    });

    setRows(showSelected);
    setRowsSelected(selected);
    setSelectedValues(selectedVals);

    setSelectedListStatus('Show Selected');
  };

  const handleListStatusChange = async (e) => {
    const selectedOption = e.target.value;
    const params = {
      requestType: selectedOption,
      roleId: id,
    };
    const data = await listUsrAccess(params);
    let selected = [];
    let selectedVals = [];

    if (selectedOption === 'Show All') {
      data.usrAccessesList.filter(function (v, index) {
        if (v.roleId !== 0) {
          selected.push(index);
          selectedVals.push({ access: v.access, subAccess: v.subAccess });
        }
      });

      setRows(data.usrAccessesList);
      setRowsSelected(selected);
    } else if (selectedOption === 'Show Selected') {
      let showSelected = [];

      data.usrAccessesList.filter(function (v, index) {
        if (v.roleId !== 0) {
          showSelected.push(v);
          selectedVals.push({ access: v.access, subAccess: v.subAccess });
        }
      });

      showSelected.filter(function (v, index) {
        selected.push(index);
      });

      setRows(showSelected);
      setRowsSelected(selected);
    } else {
      let showUnselected = [];

      data.usrAccessesList.filter(function (v, index) {
        if (v.roleId === 0) {
          showUnselected.push(v);
        }
      });

      setRows(showUnselected);
      setRowsSelected([]);
    }

    setSelectedListStatus(selectedOption);
    setSelectedValues(selectedVals);
  };

  const handleAdd = async (param, selectedStatus) => {
    const message = param.subAccess;

    try {
      await createAccess(param);
      notifySuccess(message + ' has been added.');
    } catch (error) {
      console.error(error);
      notifyError(error.message);
    }
    fetchData(selectedStatus);
  };

  const handleRemove = async (param, selectedStatus) => {
    const message = param.subAccess;

    try {
      await deleteAccess(param.accessId);
        notifySuccess(message + ' has been removed.');
    } catch (error) {
      console.error(error);
      notifyError(error.message);
    }
    fetchData(selectedStatus);
  };

  const getCsvData = async () => {
    return rows;
  };

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <RadioGroup
              style={{ flexDirection: 'row' }}
              name="listStatus"
              value={selectedListStatus}
              onChange={handleListStatusChange}
            >
              <FormControlLabel
                name="showAll"
                label="Show All"
                value="Show All"
                control={<Radio />}
              />
              <FormControlLabel
                name="showSelected"
                label="Show Selected"
                value="Show Selected"
                control={<Radio />}
              />
              <FormControlLabel
                name="showUnselected"
                label="Show Unselected"
                value="Show Unselected"
                control={<Radio />}
              />
            </RadioGroup>
          </div>
        </div>
      </div>
      <div className="mt-20">
        <ClientGrid
          title="Functions List"
          rowIdField="id"
          columns={columns}
          rows={rows}
          setRows={setRows}
          getCsvData={getCsvData}
          rowsSelected={rowsSelected}
          selectedListStatus={selectedListStatus}
        />
      </div>
    </div>
  );
}
