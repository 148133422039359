import React, { useState, useEffect } from 'react';
import { notifyInfo, notifyError } from '../../../components/Messages/Notification';
import { TextField } from '@material-ui/core';

import { listMarginInterestCalc } from '../../../services/MarginInterestCalcService';
import { getSystemDate } from '../../../services/ProfileService';

import AutoCompleteMasterAccountNo from '../../../components/AutoComplete/AutoCompleteMasterAccountNo';
import AutoCompleteCorrespondent from '../../../components/AutoComplete/AutoCompleteCorrespondent';
import AutoCompleteAccountName from '../../../components/AutoComplete/AutoCompleteAccountName';
import AutoCompleteAccountNo from '../../../components/AutoComplete/AutoCompleteAccountNo';
import AutoCompleteBranch from '../../../components/AutoComplete/AutoCompleteBranch';
import AutoCompleteRep from '../../../components/AutoComplete/AutoCompleteRep';
import SelectSystemCode from '../../../components/Dropdown/SelectSystemCode';
import ClientGrid from '../../../components/ClientGrid/ClientGrid';
import ClientGrid2 from '../../../components/ClientGrid/ClientGrid2'
import Table, { columnType } from '../../../components/ClearingTable/Table';
import QueryParam from '../../../services/QueryParamService';
import Button from '../../../components/Buttons/Button';
import Text from '../../../components/Typography/Text';
import {
  pbDateTime,
  pbDateTimeSorter,
  currency,
  percentage,
} from '../../../components/ClientGrid/CellRenderer';
import {
  CustomBodyRenderCurrency,
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderDate,
  CustomBodyRenderDateTime,
  CustomBodyRenderBoolean,
  CustomBodyRenderAlign,
  CustomBodyRenderCenter,
  CustomBodyRenderQty,
  CustomBodyRenderHtmlString,
  CustomBodyRenderPercentage
} from '../../../components/Table/CustomBodyRender';
import { formatPbDate, formatPbDateTime } from '../../../lib/fmt';

export default function MarginInterestCalc() {
  // const columns2 = [
  //   {
  //     id: 'settleDate',
  //     field: 'settleDate',
  //     label: 'Settle Date',
  //     cellRenderer: pbDateTime,
  //     sort: pbDateTimeSorter,
  //     visible: false,
  //   },
  //   {
  //     id: 'groupBy',
  //     field: 'groupBy',
  //     label: 'Group By',
  //   },
  //   {
  //     id: 'sdCashBalance',
  //     field: 'sdCashBalance',
  //     label: 'SD Cash Balance',
  //     cellRenderer: currency,
  //   },
  //   {
  //     id: 'rate',
  //     field: 'rate',
  //     label: 'Rate',
  //     cellRenderer: percentage,
  //   },
  //   {
  //     id: 'marginInt',
  //     field: 'marginInt',
  //     label: 'Margin Interest',
  //     cellRenderer: currency,
  //   },
  //   {
  //     id: 'feeAccount',
  //     field: 'feeAccount',
  //     label: 'Fee Account',
  //   },
  //   {
  //     id: 'status',
  //     field: 'status',
  //     label: 'Status',
  //   },
  // ];

  const columns = [
    {
      // Already converted to string in handlesearch
      name: 'settleDate',
      label: 'Settle Date',
      options: {
        display: false,
      }
    },
    {
      name: 'groupBy',
      label: 'Group By',
    },
    {
      name: 'sdCashBalance',
      label: 'SD Cash Balance',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'rate',
      label: 'Rate',
      options: {
        customBodyRender: CustomBodyRenderPercentage,
      },
      addFooter: true,
      type: 'percentage',
    },
    {
      name: 'marginInt',
      label: 'Margin Interest',
      options: {
        customBodyRender: CustomBodyRenderCurrencyLeft,
      },
      addFooter: true,
      type: 'amount',
    },
    {
      name: 'feeAccount',
      label: 'Fee Account',
    },
    {
      name: 'status',
      label: 'Status',
    },
  ];

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
  };

  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = useState([]);
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      fromDate: '',
      toDate: '',
      correspondent: '',
      accountNo: '',
      accountName: '',
      masterAccountNo: '',
      branch: '',
      rep: '',
      status: '',
    })
  );

  const handleChange = (e, x) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setSearchData({
      ...searchData,
      [input.name]: input.value,
    });
  };

  const handleSearch = async () => {
    try {
      setLoading(true);
      QueryParam.set(searchData);
      const data = await listMarginInterestCalc(searchData);

      let rowsCopy = data.marginIntsList;

      const rowsCopyOutput = rowsCopy.map((data) => {
        let tempDate = formatPbDate(data.settleDate);
        data.settleDate = tempDate

        return data;
      })

      setRows(rowsCopyOutput);
      notifyInfo(data.marginIntsList.length + ' search results.');
    } catch (error) {
      console.error(error);
      notifyError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(
    () => {
      async function init() {
        if (searchData.fromDate && searchData.toDate) return;

        const searchDataCopy = { ...searchData };
        const systemDate = await getSystemDate();
        if (!searchData.fromDate) {
          searchDataCopy.fromDate = systemDate;
        }

        if (!searchData.toDate) {
          searchDataCopy.toDate = systemDate;
        }

        setSearchData(searchDataCopy);
      }

      init();
    },
    // eslint-disable-next-line
    []
  );

  const getCsvData = async () => {
    return rows;
  };

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Margin Interest Calc" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading}
              loading={loading}
              type="search"
              label={loading ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <TextField
              name="fromDate"
              fullWidth
              label="From Date"
              type="date"
              value={searchData.fromDate}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <TextField
              name="toDate"
              fullWidth
              label="To Date"
              type="date"
              value={searchData.toDate}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <AutoCompleteCorrespondent
              freeSolo={true}
              name="correspondent"
              value={searchData.correspondent}
              onChange={handleChange}
              isAccessibleOnly={true}
              onKeyDown={(e) => (e.keyCode === 13 ? handleSearch() : null)}
            ></AutoCompleteCorrespondent>
          </div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <AutoCompleteAccountNo
              freeSolo={true}
              isAccessibleOnly={true}
              name="accountNo"
              label="Account No"
              value={searchData.accountNo}
              correspondent={searchData.correspondent}
              onChange={handleChange}
              onKeyDown={(e) => (e.keyCode === 13 ? handleSearch() : null)}
            ></AutoCompleteAccountNo>
          </div>
          <div className="grd-cell">
            <AutoCompleteAccountName
              freeSolo={true}
              required={false}
              label="Account Name"
              name="accountName"
              value={searchData.accountName}
              onChange={handleChange}
              isAccessibleOnly={true}
              onKeyDown={(e) => (e.keyCode === 13 ? handleSearch() : null)}
            ></AutoCompleteAccountName>
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <AutoCompleteMasterAccountNo
              freeSolo={true}
              isAccessibleOnly={true}
              name="masterAccountNo"
              label="Master Account No"
              value={searchData.masterAccountNo}
              onChange={handleChange}
              onKeyDown={(e) => (e.keyCode === 13 ? handleSearch() : null)}
            ></AutoCompleteMasterAccountNo>
          </div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <AutoCompleteBranch
              isAccessibleOnly={true}
              freeSolo={true}
              name="branch"
              label="Branch"
              value={searchData.branch}
              onChange={handleChange}
              onKeyDown={(e) => (e.keyCode === 13 ? handleSearch() : null)}
            ></AutoCompleteBranch>
          </div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <AutoCompleteRep
              freeSolo={true}
              isAccessibleOnly={true}
              name="rep"
              label="Rep"
              value={searchData.rep}
              onChange={handleChange}
              onKeyDown={(e) => (e.keyCode === 13 ? handleSearch() : null)}
            ></AutoCompleteRep>
          </div>
          <div className="grd-cell" style={{ marginRight: 30 }}>
            <SelectSystemCode
              name="status"
              label="Status"
              type="Status"
              subType="Margin Int"
              value={searchData.status}
              onChange={handleChange}
            ></SelectSystemCode>
          </div>
          <div className="grd-cell">
          </div>
        </div>
      </div>
      <div className="mt-20">
        {/* <ClientGrid
          title="Margin Interest Calc"
          rowIdField="marginIntId"
          columns={columns2}
          rows={rows}
          setRows={setRows}
          isLoading={loading}
          getCsvData={getCsvData}
        /> */}
        {/* <ClientGrid2
          title="Margin Interest Calc"
          data={rows}
          columns={columns}
          options={options}
        /> */}
        <Table
          title={'Margin Interest Calc'}
          data={rows}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
}
