import React, { useEffect } from 'react';
import { Create as EditIcon } from '@material-ui/icons';
import VerifiedIcon from '@mui/icons-material/Verified';
import { IconButton } from '@material-ui/core';
import Text from '../../../components/Typography/Text';
import { getCurrentUser, getAccess } from '../../../services/AuthService';
import ClientGrid from '../../../components/ClientGrid/ClientGrid';
import ClientGrid2 from '../../../components/ClientGrid/ClientGrid2'
import Table, { columnType } from '../../../components/ClearingTable/Table';
import Button from '../../../components/Buttons/Button';
import { notifyError, notifyInfo } from '../../../components/Messages/Notification';
import ManualInputModal from './Component/ManualInputModal';
import { usePlaidLink } from 'react-plaid-link';
import { listAccountAccess } from '../../../services/ClientAccountAccessService';
import {
  CreateLinkToken,
  ExchangePublicToken,
  GetAuth,
} from '../../../services/PlaidService';
import {
  listAccount,
  createAccount,
  listBankAccount,
  updateAccount,
} from '../../../services/BankAccountService';
import { listBankAddress } from '../../../services/BankAddressService';
import { pbDateTime, pbDateSorter } from '../../../components/ClientGrid/CellRenderer';
import AutoCompleteAccountNo from '../../../components/AutoComplete/AutoCompleteAccountNo';
import AutoCompleteMasterAccountNo from '../../../components/AutoComplete/AutoCompleteMasterAccountNo';
import AutoCompleteCorrespondent from '../../../components/AutoComplete/AutoCompleteCorrespondent';
import SelectSystemCode from '../../../components/Dropdown/SelectSystemCode';
import TextField from '../../../components/Textfields/TextField';
import QueryParam from '../../../services/QueryParamService';
import { readClient, getAccessibleClient } from '../../../services/ClientAccountService';
import {
  CustomBodyRenderCurrency,
  CustomBodyRenderCurrencyLeft,
  CustomBodyRenderDate,
  CustomBodyRenderDateTime,
  CustomBodyRenderBoolean,
  CustomBodyRenderAlign,
  CustomBodyRenderHtmlString,
  CustomBodyRenderCenter,
  CustomBodyRenderMissing,
} from '../../../components/Table/CustomBodyRender';
import { formatPbDate, formatPbDateTime } from '../../../lib/fmt';

export default function Balance() {
  const [rows, setRows] = React.useState([]);
  const [initialized, setInit] = React.useState(false);
  const [isOpen, setOpen] = React.useState(false);
  const [isPlaidOpen, setOpenPlaid] = React.useState(false);
  const [manualBankAccountModal, setManualBankAccountModal] = React.useState({
    open: false,
    data: {
      approvedMethod: 'N/A',
      linktoken: '',
    },
  });
  const [rowData, setRowData] = React.useState({});
  const [searchData, setSearchData] = React.useState(
    QueryParam.get({
      correspondent: '',
      accountNo: '',
      masterAccountNo: '',
      achRoutingNo: '',
      bankName: '',
      bankAccountNo: '',
      bankOwnerName: '',
      status: '',
    })
  );
  const [loading, setLoading] = React.useState({
    search: false,
    save: false,
  });

  useEffect(() => {
    if (!initialized) setInit(true);
    if (!initialized) {
      init();
    }

    // eslint-disable-next-line
  }, [initialized]);

  const init = async () => {
    var data;
    let account_no = '';
    let correspondent = '';
    const loggeduser = getCurrentUser();

    if (loggeduser === null) return;
    try {
      const b = await listBankAccount(loggeduser.UserId);
      
      if (loggeduser.AccountId !== 0) {
        const account = await readClient(loggeduser.AccountId);
        account_no = account.client.accountNo;
        correspondent = account.client.correspondent;
      } else {
        const account = await getAccessibleClient(loggeduser.UserId);
        account_no = account.client.accountNo;
        correspondent = account.client.correspondent;
      }
      if (!getAccess('account_no', loggeduser.UserType)) {
        setSearchData({
          ...searchData,
          accountNo: account_no,
          correspondent: correspondent,
        });
      }

      data = b;
      setRows(data.bankAccountsList);
    } catch (error) {
      notifyError(error.message);
      console.error(error);
    }
  };

  const getCsvData = () => {
    let rowsCopy = [...rows];

    const csvData = rowsCopy.map((data) => {
      let tempCreatedAt = formatPbDateTime(data.createdAt);
      data.createdAt = tempCreatedAt

      console.log(tempCreatedAt)
      console.log(data.createdAt)

      return data;
    })

    return csvData;
  };

  const handleAdd = async () => {
    
    await loadData();
    setOpen(true);
  };

  const handleEdit = (data) => {
    setManualBankAccountModal({
      open: true,
      data,
    });
  };

  const handlePlaidVerification = async (data) => {
    //setRowData(data)
    const loggeduser = getCurrentUser();
    var linkToken;
    
    try {
      const lt = await CreateLinkToken({
        name: 'SAS',
        country: 'US',
        id: loggeduser.UserId,
        type: 'Micro Deposit',
        access_token: data.plaidAccessToken
      });

      if (lt.errorCode !== '') {
        notifyError(`PLAID ERROR: ${lt.errorType} ${lt.errorCode} ${lt.errorMsg}`);
        console.error(lt);
        return;
      }

      linkToken = lt;
    } catch (e) {
      notifyError(e);
      console.error(e);
      return;
    }
    console.log(linkToken)
    if (linkToken.linkToken !== '') {
      setRowData({
        ...data,
        token: linkToken.linkToken,
        user: loggeduser,
      });
      setOpenPlaid(true);
    }
  };

  const GridButtons = ({ tableManager, value, field, data }) => {
    console.log(data)
    return (
      <div>
        {data.status === 'Pending Approval' && (
          <IconButton onClick={() => handleEdit(data)}>
            <EditIcon />
          </IconButton>
        )}
        {data.status === 'Pending Verification' && (
          
          <IconButton onClick={() => handleEdit(data)}>
            <EditIcon />
          </IconButton>
        )}
        <>
        {data.status
        }
          </>
        
        
      </div>
    );
  };

  const columns = [
        {
      id: '',
      name:'actions',
      label: 'Actions',
      width: '40px',
      sort: false,
      options: {
        customBodyRenderLite: (dataIndex) => {
          return(
            <div>
              {rows[dataIndex].status ===  'Pending Verification' && (
                <IconButton onClick={() => handlePlaidVerification(rows[dataIndex])}>
                  <VerifiedIcon style={{ height: 25, width: 25 }}/>
                </IconButton>
              )}
            </div>
          );
        },
      },
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      },
    },
    {
      name: 'accountNo',
      label: 'Account No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'bankName',
      label: 'Bank Name',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'bankAccountNo',
      label: 'Bank Account Number',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      },
    },
    {
      name: 'achRoutingNo',
      label: 'ACH Routing No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'wireRoutingNo',
      label: 'Wire Routing No',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'bankAccountType',
      label: 'Account Type',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'approvedMethod',
      label: 'Approved Method',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: CustomBodyRenderAlign,
      }
    },
    {
      name: 'plaidAccessToken',
      label: 'Plaid Access Token',
      options: {
        display: false,
        customBodyRender: CustomBodyRenderAlign,
      },
    },
    {
      // Already converted to string in handlesearch
      name: 'createdAt',
      label: 'Created At',
      options: {
        display: false,
      },
    },
    {
      name: 'createdBy',
      label: 'Created By',
      options: {
        display: false,
        customBodyRender: CustomBodyRenderAlign,
      },
    },
  ];

  const options = {
    responsive: 'standard',
  };

  const handleSearch = async () => {
    try {
      setLoading({ ...loading, search: true });
      QueryParam.set(searchData);
      const data = await listAccount(searchData);
      console.log(data)
      let rowsCopy = data.bankAccountsList;

      const rowsCopyOutput = rowsCopy.map((data) => {
        let tempCreatedAt = formatPbDateTime(data.createdAt);
        data.createdAt = tempCreatedAt

        return data;
      })

      setRows(rowsCopyOutput);

      notifyInfo(
        data.bankAccountsList.length > 0
          ? data.bankAccountsList.length + ' search result(s)'
          : 'No records found.'
      );
    } catch (error) {
      notifyError(error.message);
      return;
    } finally {
      setLoading({ ...loading, search: false });
    }
  };

  const handleSearchDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;
    setSearchData({ ...searchData, [input.name]: input.value });
  };
  const handleCloseManualModal = (bankAccount, isEdit) => {
    if (bankAccount) {
      if (isEdit) {
        const rowsCopy = [...rows];
        const index = rows.indexOf(manualBankAccountModal.data);
        rowsCopy[index] = bankAccount;
        setRows(rowsCopy);
      } else {
        var isArr = Array.isArray(bankAccount);
        if (isArr) {
          setRows([...bankAccount, ...rows]);
        } else {
          setRows([bankAccount, ...rows]);
        }
      }
    }

    setManualBankAccountModal({
      open: false,
    });
    setOpen(false);
  };

  const handleClose = async (data) => {
    if (data) {

      if (data.value === 'Plaid') {
        await loadData();
      } else {
        // manual
        setManualBankAccountModal({
          open: true,
        });
      }
    }
    setOpen(false);
  };

  const loadData = async () => {
    const loggeduser = getCurrentUser();
    var linkToken;
    var client;
    var bankAccount;

    if (loggeduser === null) return;
    try {
      const lt = await CreateLinkToken({
        name: 'SAS',
        country: 'US',
        id: loggeduser.UserId,
        type: 'Micro Deposit',
      });
      if (lt.errorCode !== '') {
        notifyError(lt.errorCode + ' ' + lt.errorMessage);
        console.error(lt);
        return;
      }
      linkToken = lt;
    } catch (e) {
      notifyError(e);
      console.error(e);
      return;
    }
    try {
      const c = await listAccountAccess({
        usrId: loggeduser.UserId,
      });
      client = c;
    } catch (e) {
      notifyError(e);
      console.error(e);
      return;
    }

    try {
      const ba = await listAccount({
        correspondent: client.accountAccessesList[0].correspondent,
        // master_account_no:client.accountAccessesList[0].masterAccountNo,
        accountNo: client.accountAccessesList[0].accountNo,
        // correspondent:'',
        // master_account_no:'',
        // account_no:'',
      });
      bankAccount = ba;
    } catch (e) {
      notifyError(e);
      console.error(e);
      return;
    }

    let account_no = '';
    let correspondent = '';

    if (loggeduser === null) return;
    try {
      const b = await listBankAccount(loggeduser.UserId);

      if (loggeduser.AccountId !== 0) {
        const account = await readClient(loggeduser.AccountId);
        account_no = account.client.accountNo;
        correspondent = account.client.correspondent;
      } else {
        const account = await getAccessibleClient(loggeduser.UserId);
        account_no = account.client.accountNo;
        correspondent = account.client.correspondent;
      }
    } catch (error) {
    }

    if (linkToken.linkToken !== '') {
      setRowData({
        ...rowData,
        token: linkToken.linkToken,
        user: loggeduser,
        client: client.accountAccessesList[0],
        bankAccount: bankAccount.bankAccountsList,
      });
      const d = manualBankAccountModal;
      setManualBankAccountModal({
        open: manualBankAccountModal.open,
        data: {
          ...manualBankAccountModal.data,
          linktoken: linkToken,
          correspondent: correspondent,
          accountNo: account_no,
        },
      });
      // setIsOpen(true);
      // isOauth = true
      //setOpenPlaid(true);
    }
  };

  const config = {
    onSuccess: async (public_token, metadata) => {
      var at;
      var auth_data;

      if (rowData.accessToken != "") {
        at.accessToken = rowData.accessToken
      } else {
        try {
          const actok = await ExchangePublicToken({
            public_token: public_token,
          });
          if (actok.errorCode !== '') {
            notifyError(actok.errorCode + ' ' + actok.errorMessage);
            console.error(actok);
            return;
          }
          at = actok;
        } catch (e) {
          notifyError(e);
          console.error(e);
          return;
        }
      }

     
      try {
        const ad = await GetAuth({
          access_token: at.accessToken,
        });

        auth_data = ad;
      } catch (e) {
        notifyError(e);
        console.error(e);
        return;
      }
      const dataresp = JSON.parse(auth_data.json);
      const plaid_bank_data = dataresp.numbers.ach;
      if (dataresp.errorCode && dataresp.errorCode !== '') {
        notifyError(dataresp.errorCode + ' ' + dataresp.errorMessage);
        console.error(dataresp);
        return;
      }

      for (const data of plaid_bank_data) {
        var address_id = 0;
        try {
          const address = await listBankAddress({
            bank_routing_no: data.routing,
          });
          address_id = address.bank_addresses[0].bank_address_id;
        } catch (error) {
          address_id = 0;
        }

        try {
          const param = {
            correspondent: rowData.client.correspondent,
            masterAccountNo: rowData.client.masterAccountNo,
            accountNo: rowData.client.accountNo,
            bankAccountNo: data.account,
            plaidAccessToken: at.accessToken,
            bankName: metadata.institution.name,
            bankAddressId: address_id,
            // bank_identifier_code: metadata.institution.institution_id,
            bankAccountType: typeMap(getType(data.account_id, dataresp.accounts)),
            bankOwnerName: getName(data.account_id, dataresp.accounts),
            achRoutingNo: data.routing,
            wireRoutingNo: data.wire_routing,
            approvedMethod: rowData.approvedMethod? rowData.approvedMethod===""?'Plaid': rowData.approvedMethod: 'Plaid',
            status: 'Active',
          };

          if (rowData.bankId !==0) {
            param.bankId = rowData.bankId
            await updateAccount(param);
          } else {
            await createAccount(param);
          }

          
        } catch (error) {
          notifyError(error);
          console.error(error);
        }
      }

      setRowData({ ...rowData, token: '' });
      if (rowData.bankId !==0) {
        handleSearch();
      } else {
        init();
      }
      
    },
    onExit: (err, metadata) => {
      setOpenPlaid(false);
    },
    onEvent: (eventName, metadata) => {},
    token: rowData.token ? rowData.token : '',
    env: window.env.PLAID_ENVIRONMENT ? window.env.PLAID_ENVIRONMENT : 'sandbox',
    // required for OAuth:
    // receivedRedirectUri: window.location.href,
    // if not OAuth, set to null or do not include:
    receivedRedirectUri: null,
  };

  const { open, ready } = usePlaidLink(config)
  if (ready && isPlaidOpen) {
    open()
  }

  function typeMap(status) {
    var text;
    switch (status) {
      case 'checking':
        text = 'Checking';
        break;
      case 'savings':
        text = 'Savings';
        break;

      default:
        text = '';
    }
    return text;
  }

  function getType(account_id, ar_account) {
    var type = '';

    ar_account.forEach(function (data) {
      if (account_id === data.account_id) type = data.subtype;
    });
    return type;
  }

  function getName(account_id, ar_account) {
    var type = '';

    ar_account.forEach(function (data) {
      if (account_id === data.account_id) type = data.official_name;
    });
    return type;
  }

  return (
    <div className="page-c">
      <div className="search-c">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text mt={8} variant="h1" label="Bank Account" />
          </div>
          <div className="grd-cell-none">
            <Button
              disabled={loading.search}
              loading={loading.search}
              type="search"
              label={loading.search ? 'Searching...' : 'Search'}
              onClick={handleSearch}
            />
          </div>

          <div className="grd-cell-none">
            <Button type="plus" label="Add Bank Account" onClick={handleAdd} />
          </div>
        </div>

        <div className="grd-row">
          <div className="grd-cell">
            <AutoCompleteCorrespondent
              isAccessibleOnly={true}
              name="correspondent"
              label={'Correspondent'}
              value={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
          <div className="grd-cell">
            <AutoCompleteAccountNo
              isAccessibleOnly={true}
              name="accountNo"
              value={searchData.accountNo}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>

          <div className="grd-cell">
            <AutoCompleteMasterAccountNo
              isAccessibleOnly={true}
              name="masterAccountNo"
              value={searchData.masterAccountNo}
              correspondent={searchData.correspondent}
              onChange={handleSearchDataChange}
            />
          </div>
        </div>
        <div className="grd-row">
          <div className="grd-cell">
            <TextField
              fullWidth
              name="achRoutingNo"
              label="ACH Routing No"
              value={searchData.achRoutingNo}
              onChange={handleSearchDataChange}
              inputProps={{ maxLength: 10 }}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => (e.keyCode === 13 ? handleSearch() : null)}
            />
          </div>

          <div className="grd-cell">
            <TextField
              fullWidth
              name="bankName"
              label="Bank Name"
              value={searchData.bankName}
              onChange={handleSearchDataChange}
              inputProps={{ maxLength: 300 }}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => (e.keyCode === 13 ? handleSearch() : null)}
            />
          </div>
          <div className="grd-cell">
            <TextField
              fullWidth
              name="bankAccountNo"
              label="Bank Account No"
              value={searchData.bankAccountNo}
              onChange={handleSearchDataChange}
              inputProps={{ maxLength: 50 }}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => (e.keyCode === 13 ? handleSearch() : null)}
            />
          </div>
          <div className="grd-cell">
            <TextField
              fullWidth
              name="bankOwnerName"
              label="Bank Owner Name"
              value={searchData.bankOwnerName}
              onChange={handleSearchDataChange}
              inputProps={{ maxLength: 300 }}
              InputLabelProps={{ shrink: true }}
              onKeyDown={(e) => (e.keyCode === 13 ? handleSearch() : null)}
            />
          </div>
          <div className="grd-cell">
            <SelectSystemCode
              name="status"
              label="Status"
              placeholder="Status"
              type="Status"
              subType="Bank Account"
              value={searchData.status}
              onChange={handleSearchDataChange}
            />
          </div>
        </div>
      </div>

      <div className="mt-20">
        {/* <ClientGrid
          title="Bank Accounts"
          rowIdField="bankId"
          columns={columns}
          rows={rows}
          getCsvData={getCsvData}
          setRows={setRows}
        /> */}
        {/* <ClientGrid2
          title="Bank Accounts"
          data={rows}
          columns={columns}
          options={options}
        /> */}
        <Table
          title={'Bank Accounts'}
          data={rows}
          columns={columns}
          options={options}
          getCsvData={getCsvData}
        />
      </div>
      {isOpen && (
        <ManualInputModal
          onClose={handleCloseManualModal}
          iopen={isOpen}
          value={manualBankAccountModal.data}
        ></ManualInputModal>
      )}
    </div>
  );
}
