/**
 * @fileoverview gRPC-Web generated client stub for reorgpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_type_date_pb = require('../../google/type/date_pb.js')

var proto_utilspb_pagination_pb = require('../../proto/utilspb/pagination_pb.js')
const proto = {};
proto.reorgpb = require('./spinoff_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.reorgpb.SpinOffServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.reorgpb.SpinOffServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reorgpb.ListSpinOffRequest,
 *   !proto.reorgpb.ListSpinOffResponse>}
 */
const methodDescriptor_SpinOffService_ListSpinOff = new grpc.web.MethodDescriptor(
  '/reorgpb.SpinOffService/ListSpinOff',
  grpc.web.MethodType.UNARY,
  proto.reorgpb.ListSpinOffRequest,
  proto.reorgpb.ListSpinOffResponse,
  /**
   * @param {!proto.reorgpb.ListSpinOffRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reorgpb.ListSpinOffResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.reorgpb.ListSpinOffRequest,
 *   !proto.reorgpb.ListSpinOffResponse>}
 */
const methodInfo_SpinOffService_ListSpinOff = new grpc.web.AbstractClientBase.MethodInfo(
  proto.reorgpb.ListSpinOffResponse,
  /**
   * @param {!proto.reorgpb.ListSpinOffRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reorgpb.ListSpinOffResponse.deserializeBinary
);


/**
 * @param {!proto.reorgpb.ListSpinOffRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.reorgpb.ListSpinOffResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reorgpb.ListSpinOffResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reorgpb.SpinOffServiceClient.prototype.listSpinOff =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reorgpb.SpinOffService/ListSpinOff',
      request,
      metadata || {},
      methodDescriptor_SpinOffService_ListSpinOff,
      callback);
};


/**
 * @param {!proto.reorgpb.ListSpinOffRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reorgpb.ListSpinOffResponse>}
 *     A native promise that resolves to the response
 */
proto.reorgpb.SpinOffServicePromiseClient.prototype.listSpinOff =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reorgpb.SpinOffService/ListSpinOff',
      request,
      metadata || {},
      methodDescriptor_SpinOffService_ListSpinOff);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reorgpb.SpinOff,
 *   !proto.reorgpb.CreateSpinOffResponse>}
 */
const methodDescriptor_SpinOffService_CreateSpinOff = new grpc.web.MethodDescriptor(
  '/reorgpb.SpinOffService/CreateSpinOff',
  grpc.web.MethodType.UNARY,
  proto.reorgpb.SpinOff,
  proto.reorgpb.CreateSpinOffResponse,
  /**
   * @param {!proto.reorgpb.SpinOff} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reorgpb.CreateSpinOffResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.reorgpb.SpinOff,
 *   !proto.reorgpb.CreateSpinOffResponse>}
 */
const methodInfo_SpinOffService_CreateSpinOff = new grpc.web.AbstractClientBase.MethodInfo(
  proto.reorgpb.CreateSpinOffResponse,
  /**
   * @param {!proto.reorgpb.SpinOff} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reorgpb.CreateSpinOffResponse.deserializeBinary
);


/**
 * @param {!proto.reorgpb.SpinOff} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.reorgpb.CreateSpinOffResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reorgpb.CreateSpinOffResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reorgpb.SpinOffServiceClient.prototype.createSpinOff =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reorgpb.SpinOffService/CreateSpinOff',
      request,
      metadata || {},
      methodDescriptor_SpinOffService_CreateSpinOff,
      callback);
};


/**
 * @param {!proto.reorgpb.SpinOff} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reorgpb.CreateSpinOffResponse>}
 *     A native promise that resolves to the response
 */
proto.reorgpb.SpinOffServicePromiseClient.prototype.createSpinOff =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reorgpb.SpinOffService/CreateSpinOff',
      request,
      metadata || {},
      methodDescriptor_SpinOffService_CreateSpinOff);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reorgpb.SpinOff,
 *   !proto.reorgpb.UpdateSpinOffResponse>}
 */
const methodDescriptor_SpinOffService_UpdateSpinOff = new grpc.web.MethodDescriptor(
  '/reorgpb.SpinOffService/UpdateSpinOff',
  grpc.web.MethodType.UNARY,
  proto.reorgpb.SpinOff,
  proto.reorgpb.UpdateSpinOffResponse,
  /**
   * @param {!proto.reorgpb.SpinOff} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reorgpb.UpdateSpinOffResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.reorgpb.SpinOff,
 *   !proto.reorgpb.UpdateSpinOffResponse>}
 */
const methodInfo_SpinOffService_UpdateSpinOff = new grpc.web.AbstractClientBase.MethodInfo(
  proto.reorgpb.UpdateSpinOffResponse,
  /**
   * @param {!proto.reorgpb.SpinOff} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reorgpb.UpdateSpinOffResponse.deserializeBinary
);


/**
 * @param {!proto.reorgpb.SpinOff} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.reorgpb.UpdateSpinOffResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reorgpb.UpdateSpinOffResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reorgpb.SpinOffServiceClient.prototype.updateSpinOff =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reorgpb.SpinOffService/UpdateSpinOff',
      request,
      metadata || {},
      methodDescriptor_SpinOffService_UpdateSpinOff,
      callback);
};


/**
 * @param {!proto.reorgpb.SpinOff} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reorgpb.UpdateSpinOffResponse>}
 *     A native promise that resolves to the response
 */
proto.reorgpb.SpinOffServicePromiseClient.prototype.updateSpinOff =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reorgpb.SpinOffService/UpdateSpinOff',
      request,
      metadata || {},
      methodDescriptor_SpinOffService_UpdateSpinOff);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reorgpb.SpinOff,
 *   !proto.reorgpb.DeleteSpinOffResponse>}
 */
const methodDescriptor_SpinOffService_DeleteSpinOff = new grpc.web.MethodDescriptor(
  '/reorgpb.SpinOffService/DeleteSpinOff',
  grpc.web.MethodType.UNARY,
  proto.reorgpb.SpinOff,
  proto.reorgpb.DeleteSpinOffResponse,
  /**
   * @param {!proto.reorgpb.SpinOff} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reorgpb.DeleteSpinOffResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.reorgpb.SpinOff,
 *   !proto.reorgpb.DeleteSpinOffResponse>}
 */
const methodInfo_SpinOffService_DeleteSpinOff = new grpc.web.AbstractClientBase.MethodInfo(
  proto.reorgpb.DeleteSpinOffResponse,
  /**
   * @param {!proto.reorgpb.SpinOff} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reorgpb.DeleteSpinOffResponse.deserializeBinary
);


/**
 * @param {!proto.reorgpb.SpinOff} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.reorgpb.DeleteSpinOffResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reorgpb.DeleteSpinOffResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reorgpb.SpinOffServiceClient.prototype.deleteSpinOff =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reorgpb.SpinOffService/DeleteSpinOff',
      request,
      metadata || {},
      methodDescriptor_SpinOffService_DeleteSpinOff,
      callback);
};


/**
 * @param {!proto.reorgpb.SpinOff} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reorgpb.DeleteSpinOffResponse>}
 *     A native promise that resolves to the response
 */
proto.reorgpb.SpinOffServicePromiseClient.prototype.deleteSpinOff =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reorgpb.SpinOffService/DeleteSpinOff',
      request,
      metadata || {},
      methodDescriptor_SpinOffService_DeleteSpinOff);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reorgpb.ListSpinOffRequest,
 *   !proto.reorgpb.ListSpinOffResponse>}
 */
const methodDescriptor_SpinOffService_MovePageSpinOff = new grpc.web.MethodDescriptor(
  '/reorgpb.SpinOffService/MovePageSpinOff',
  grpc.web.MethodType.UNARY,
  proto.reorgpb.ListSpinOffRequest,
  proto.reorgpb.ListSpinOffResponse,
  /**
   * @param {!proto.reorgpb.ListSpinOffRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reorgpb.ListSpinOffResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.reorgpb.ListSpinOffRequest,
 *   !proto.reorgpb.ListSpinOffResponse>}
 */
const methodInfo_SpinOffService_MovePageSpinOff = new grpc.web.AbstractClientBase.MethodInfo(
  proto.reorgpb.ListSpinOffResponse,
  /**
   * @param {!proto.reorgpb.ListSpinOffRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reorgpb.ListSpinOffResponse.deserializeBinary
);


/**
 * @param {!proto.reorgpb.ListSpinOffRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.reorgpb.ListSpinOffResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reorgpb.ListSpinOffResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reorgpb.SpinOffServiceClient.prototype.movePageSpinOff =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reorgpb.SpinOffService/MovePageSpinOff',
      request,
      metadata || {},
      methodDescriptor_SpinOffService_MovePageSpinOff,
      callback);
};


/**
 * @param {!proto.reorgpb.ListSpinOffRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reorgpb.ListSpinOffResponse>}
 *     A native promise that resolves to the response
 */
proto.reorgpb.SpinOffServicePromiseClient.prototype.movePageSpinOff =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reorgpb.SpinOffService/MovePageSpinOff',
      request,
      metadata || {},
      methodDescriptor_SpinOffService_MovePageSpinOff);
};


module.exports = proto.reorgpb;

