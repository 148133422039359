import {
  Role,
  ListRoleRequest,
  RoleServiceClient,
  DeleteRoleRequest,
} from '../proto/usrpb/role_grpc_web_pb';
import { auth } from '../lib/auth/Auth';

const service = new RoleServiceClient(
  window.env.GRPC_ENDPOINT,
  {},
  { ...auth }
);

export async function listRole(param) {
  console.log(param)
  return new Promise((resolve, reject) => {
    const req = new ListRoleRequest();
    req.setRoleId(param.roleId);
    req.setUsrId(param.usrId);
    req.setAccountId(param.accountId);
    req.setPageId(param.pageId);

    service.listRole(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function lazyListRole(param) {
  return new Promise((resolve, reject) => {
    const req = new ListRoleRequest();
    req.setRoleId(param.role);

    service.lazyListRole(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function createRole(param) {
  return new Promise((resolve, reject) => {
    let req = new Role();
    req.setRoleId(param.roleId);
    req.setRoleName(param.roleName);

    service.createRole(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function updateRole(param) {
  return new Promise((resolve, reject) => {
    let req = new Role();
    req.setRoleId(param.roleId);
    req.setRoleName(param.roleName);

    service.updateRole(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}

export async function deleteRole(param) {
  return new Promise((resolve, reject) => {
    let req = new DeleteRoleRequest();
    req.setRoleId(param.roleId);

    service.deleteRole(req, {}, (error, response) => {
      if (error) {
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
