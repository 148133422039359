import React from 'react';
import Text from '../../../components/Typography/Text';

export default function FormHeader({ header, subHeader }) {
  return (
    <div className="ls-h">
      <Text mb={12} variant="h2" label={header} />
      <Text label={subHeader} />
    </div>
  );
}
