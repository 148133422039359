import { CheckCircle as CheckCircleIcon, Cancel as CrossIcon } from '@material-ui/icons';
import {
  formatPbDate,
  formatPbDateTime,
  formatCurrency,
  formatPercentage,
  formatQty,
} from '../../lib/fmt';
import color from '../../assets/css/colors';

const amountColor = (v) => {
  return { color: v < 0 && '#f44336' };
};

export const CustomBodyRenderDate = (value) =>  {
  return (
    <div>
      {formatPbDate(value)}
    </div>
  )
};

export const CustomBodyRenderDateTime = (value) => {
  return (
    <div style={{ padding: '0px 20px' }}>{formatPbDateTime(value)}</div>
  );
}

export const CustomBodyRenderCurrency = (value) => (
  <div align="right" style={amountColor(value)}>
    <div>
     {formatCurrency(value)}
    </div>
  </div>
);

export const CustomBodyRenderCurrencyLeft = (value) => (
  <div align="left" style={amountColor(value)}>
    <div>
      {formatCurrency(value)}
    </div>
  </div>
);

export const CustomBodyRenderBoolean = (value) => {
  return(
    <div style={{ textAlign: 'center', width: '100%' }}>
      {value === true && <CheckCircleIcon />}
      {value != true && <CrossIcon />}
    </div>
  )
}

// Align columns with header
export const CustomBodyRenderAlign = (value) => {
  return (
    <div>
      {value}
    </div>
  )
}

// Align columns center
export const CustomBodyRenderCenter = (value) => {
  return (
    <div style={{ textAlign: 'center' }}>
      {value}
    </div>
  )
}

export const CustomBodyRenderQty = (value) => {
  const v = parseInt(value);

  return (
    <div
      style={{
        padding: '10px 20px',
        textAlign: 'right',
        width: '100%',
        height: '100%',
        fontWeight: 600,
        // backgroundColor:
        //   v === 0
        //     ? color.info.opacity
        //     : v > 0
        //     ? color.success.opacity
        //     : color.error.opacity,
        color: v === 0 ? color.info.main : v > 0 ? color.success.main : color.error.main,
      }}
    >
      {formatQty(value)}
    </div>
  );
}

export const CustomBodyRenderHtmlString = (value) => {
  return (
    <div
      style={{ padding: '0px 20px' }}
      dangerouslySetInnerHTML={{ __html: value }}
    ></div>
  );
}

export const CustomBodyRenderPercentage = (value) => {
  const v = parseFloat(value);

  return (
    <div
      style={{
        padding: '20px 15px',
        textAlign: 'left',
        width: '100%',
        height: '100%',
        fontWeight: 600,
        // backgroundColor:
        //   v === parseFloat(0)
        //     ? color.info.opacity
        //     : v > parseFloat(0)
        //     ? color.success.opacity
        //     : color.error.opacity,
        color:
          v === parseFloat(0)
            ? color.info.main
            : v > parseFloat(0)
            ? color.success.main
            : color.error.main,
      }}
    >
      {formatPercentage(value)}
    </div>
  )
}

export const CustomBodyRenderMissing = (value) => {
  return (
    <div
      style={{
        padding: '10px 20px',
        textAlign: 'right',
        width: '100%',
        height: '100%',
        fontWeight: 600,
        textAlign: 'right',
      }}
    >
      {value}
    </div>
  );
};