import React from 'react';
import { Grid } from '@material-ui/core';
import Text from '../Typography/Text';
import { ReactComponent as Coins } from '../../assets/icons/coins.svg';
import { ReactComponent as ArrowGrow } from '../../assets/icons/arrow-growth.svg';
import { ReactComponent as ArrowShrink } from '../../assets/icons/arrow-shrink.svg';

import { formatCurrency } from '../../lib/fmt';

const BalanceCard = (props) => {
  const {
    accountName = '--',
    accountNo = '--',
    cashBalance = 0,
    longMarketValue = 0,
    shortMarketValue = 0,
  } = props.data;

  return (
    <div
      style={{
        backgroundColor: props.isDragging ? '#2b4066' : '#f3f2ff',
      }}
      className="card"
    >
      <div className="card-info">
        <div className="grd-row nm">
          <div className="grd-cell">
            <Text variant="subtitle2" label={accountNo} />
            <Text mt={8} variant="h2" label={accountName} />
            <div className="mt-30">
              <Text mt={8} variant="h1" label={formatCurrency(cashBalance)} />
            </div>
          </div>
          <div className="grd-cell-none">
            <div className="icon-container">
              <Coins />
            </div>
          </div>
        </div>
        <div className="mt-20">
          <Grid container spacing={3}>
            <Grid item sm={12} md={6}>
              <div className="grd-row nm sub-header-icon">
                <div className="grd-cell-none">
                  <ArrowGrow />
                </div>
                <div className="grd-cell">
                  <Text variant="subtitle2" label="Long Market Value" />
                </div>
              </div>
              <Text mt={8} variant="h2" label={formatCurrency(longMarketValue)} />
            </Grid>
            <Grid item sm={12} md={6}>
              <div className="grd-row nm sub-header-icon">
                <div className="grd-cell-none">
                  <ArrowShrink />
                </div>
                <div className="grd-cell">
                  <Text variant="subtitle2" label="Short Market Value" />
                </div>
              </div>
              <Text mt={8} variant="h2" label={formatCurrency(shortMarketValue)} />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default BalanceCard;
