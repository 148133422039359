/**
 * @fileoverview gRPC-Web generated client stub for bankpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.bankpb = require('./walletaddressbook_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.bankpb.WalletAddressBookServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.bankpb.WalletAddressBookServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.bankpb.WalletAddressBook,
 *   !proto.bankpb.CreateWalletAddressBookResponse>}
 */
const methodDescriptor_WalletAddressBookService_CreateWalletAddressBook = new grpc.web.MethodDescriptor(
  '/bankpb.WalletAddressBookService/CreateWalletAddressBook',
  grpc.web.MethodType.UNARY,
  proto.bankpb.WalletAddressBook,
  proto.bankpb.CreateWalletAddressBookResponse,
  /**
   * @param {!proto.bankpb.WalletAddressBook} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.bankpb.CreateWalletAddressBookResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.bankpb.WalletAddressBook,
 *   !proto.bankpb.CreateWalletAddressBookResponse>}
 */
const methodInfo_WalletAddressBookService_CreateWalletAddressBook = new grpc.web.AbstractClientBase.MethodInfo(
  proto.bankpb.CreateWalletAddressBookResponse,
  /**
   * @param {!proto.bankpb.WalletAddressBook} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.bankpb.CreateWalletAddressBookResponse.deserializeBinary
);


/**
 * @param {!proto.bankpb.WalletAddressBook} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.bankpb.CreateWalletAddressBookResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.bankpb.CreateWalletAddressBookResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.bankpb.WalletAddressBookServiceClient.prototype.createWalletAddressBook =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/bankpb.WalletAddressBookService/CreateWalletAddressBook',
      request,
      metadata || {},
      methodDescriptor_WalletAddressBookService_CreateWalletAddressBook,
      callback);
};


/**
 * @param {!proto.bankpb.WalletAddressBook} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.bankpb.CreateWalletAddressBookResponse>}
 *     A native promise that resolves to the response
 */
proto.bankpb.WalletAddressBookServicePromiseClient.prototype.createWalletAddressBook =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/bankpb.WalletAddressBookService/CreateWalletAddressBook',
      request,
      metadata || {},
      methodDescriptor_WalletAddressBookService_CreateWalletAddressBook);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.bankpb.WalletAddressBook,
 *   !proto.bankpb.UpdateWalletAddressBookResponse>}
 */
const methodDescriptor_WalletAddressBookService_UpdateWalletAddressBook = new grpc.web.MethodDescriptor(
  '/bankpb.WalletAddressBookService/UpdateWalletAddressBook',
  grpc.web.MethodType.UNARY,
  proto.bankpb.WalletAddressBook,
  proto.bankpb.UpdateWalletAddressBookResponse,
  /**
   * @param {!proto.bankpb.WalletAddressBook} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.bankpb.UpdateWalletAddressBookResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.bankpb.WalletAddressBook,
 *   !proto.bankpb.UpdateWalletAddressBookResponse>}
 */
const methodInfo_WalletAddressBookService_UpdateWalletAddressBook = new grpc.web.AbstractClientBase.MethodInfo(
  proto.bankpb.UpdateWalletAddressBookResponse,
  /**
   * @param {!proto.bankpb.WalletAddressBook} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.bankpb.UpdateWalletAddressBookResponse.deserializeBinary
);


/**
 * @param {!proto.bankpb.WalletAddressBook} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.bankpb.UpdateWalletAddressBookResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.bankpb.UpdateWalletAddressBookResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.bankpb.WalletAddressBookServiceClient.prototype.updateWalletAddressBook =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/bankpb.WalletAddressBookService/UpdateWalletAddressBook',
      request,
      metadata || {},
      methodDescriptor_WalletAddressBookService_UpdateWalletAddressBook,
      callback);
};


/**
 * @param {!proto.bankpb.WalletAddressBook} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.bankpb.UpdateWalletAddressBookResponse>}
 *     A native promise that resolves to the response
 */
proto.bankpb.WalletAddressBookServicePromiseClient.prototype.updateWalletAddressBook =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/bankpb.WalletAddressBookService/UpdateWalletAddressBook',
      request,
      metadata || {},
      methodDescriptor_WalletAddressBookService_UpdateWalletAddressBook);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.bankpb.ListWalletAddressBookRequest,
 *   !proto.bankpb.ListWalletAddressBookResponse>}
 */
const methodDescriptor_WalletAddressBookService_ListWalletAddressBook = new grpc.web.MethodDescriptor(
  '/bankpb.WalletAddressBookService/ListWalletAddressBook',
  grpc.web.MethodType.UNARY,
  proto.bankpb.ListWalletAddressBookRequest,
  proto.bankpb.ListWalletAddressBookResponse,
  /**
   * @param {!proto.bankpb.ListWalletAddressBookRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.bankpb.ListWalletAddressBookResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.bankpb.ListWalletAddressBookRequest,
 *   !proto.bankpb.ListWalletAddressBookResponse>}
 */
const methodInfo_WalletAddressBookService_ListWalletAddressBook = new grpc.web.AbstractClientBase.MethodInfo(
  proto.bankpb.ListWalletAddressBookResponse,
  /**
   * @param {!proto.bankpb.ListWalletAddressBookRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.bankpb.ListWalletAddressBookResponse.deserializeBinary
);


/**
 * @param {!proto.bankpb.ListWalletAddressBookRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.bankpb.ListWalletAddressBookResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.bankpb.ListWalletAddressBookResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.bankpb.WalletAddressBookServiceClient.prototype.listWalletAddressBook =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/bankpb.WalletAddressBookService/ListWalletAddressBook',
      request,
      metadata || {},
      methodDescriptor_WalletAddressBookService_ListWalletAddressBook,
      callback);
};


/**
 * @param {!proto.bankpb.ListWalletAddressBookRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.bankpb.ListWalletAddressBookResponse>}
 *     A native promise that resolves to the response
 */
proto.bankpb.WalletAddressBookServicePromiseClient.prototype.listWalletAddressBook =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/bankpb.WalletAddressBookService/ListWalletAddressBook',
      request,
      metadata || {},
      methodDescriptor_WalletAddressBookService_ListWalletAddressBook);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.bankpb.DeleteWalletAddressBookRequest,
 *   !proto.bankpb.DeleteWalletAddressBookResponse>}
 */
const methodDescriptor_WalletAddressBookService_DeleteWalletAddressBook = new grpc.web.MethodDescriptor(
  '/bankpb.WalletAddressBookService/DeleteWalletAddressBook',
  grpc.web.MethodType.UNARY,
  proto.bankpb.DeleteWalletAddressBookRequest,
  proto.bankpb.DeleteWalletAddressBookResponse,
  /**
   * @param {!proto.bankpb.DeleteWalletAddressBookRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.bankpb.DeleteWalletAddressBookResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.bankpb.DeleteWalletAddressBookRequest,
 *   !proto.bankpb.DeleteWalletAddressBookResponse>}
 */
const methodInfo_WalletAddressBookService_DeleteWalletAddressBook = new grpc.web.AbstractClientBase.MethodInfo(
  proto.bankpb.DeleteWalletAddressBookResponse,
  /**
   * @param {!proto.bankpb.DeleteWalletAddressBookRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.bankpb.DeleteWalletAddressBookResponse.deserializeBinary
);


/**
 * @param {!proto.bankpb.DeleteWalletAddressBookRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.bankpb.DeleteWalletAddressBookResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.bankpb.DeleteWalletAddressBookResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.bankpb.WalletAddressBookServiceClient.prototype.deleteWalletAddressBook =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/bankpb.WalletAddressBookService/DeleteWalletAddressBook',
      request,
      metadata || {},
      methodDescriptor_WalletAddressBookService_DeleteWalletAddressBook,
      callback);
};


/**
 * @param {!proto.bankpb.DeleteWalletAddressBookRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.bankpb.DeleteWalletAddressBookResponse>}
 *     A native promise that resolves to the response
 */
proto.bankpb.WalletAddressBookServicePromiseClient.prototype.deleteWalletAddressBook =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/bankpb.WalletAddressBookService/DeleteWalletAddressBook',
      request,
      metadata || {},
      methodDescriptor_WalletAddressBookService_DeleteWalletAddressBook);
};


module.exports = proto.bankpb;

