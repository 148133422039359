import React, { useEffect } from 'react';
// import { Types } from 'components/FileManagerWrapper/config';
import FileManagerWrapper from './FileManagerWrapper';

import FileManager, { Types } from 'react-file-manager';
import {
  checkStorageType,
  uploadFiles,
  list,
} from '../../services/FileManagerService';
import { uniqueId } from 'lodash';

import { Box } from '@material-ui/core';
import 'antd/dist/antd.css';
// import useStyles from '../../../styles';
// import axios from 'axios';
// ../../../components/Button/Search
// const Types = {
// 	folder: "folder",
//     file: "file",
// }

const initial = {
  '0': {
    id: '0',
    title: 'Root',
    type: Types.folder,
    children: ['1'],
    parent: null,
    path: '',
  },
  '1': {
    id: '1',
    title: 'loading files',
    type: Types.folder,
    children: ['2', '3'],
    parent: '0',
    path: '',
  },
  '2': {
    id: '2',
    title: 'title2',
    type: Types.file,
    children: [],
    parent: '1',
    path: '',
  },
  '3': {
    id: '3',
    title: 'title3',
    type: Types.folder,
    children: ['4', '5'],
    parent: '1',
    path: '',
  },
  '4': {
    id: '4',
    title: 'title4',
    type: Types.file,
    children: [],
    parent: '3',
    path: '',
  },
  '5': {
    id: '5',
    title: 'title5',
    type: Types.file,
    children: [],
    parent: '3',
    path: '',
  },
};

// export default class FileManagerComp extends Component {
export default function FileManagerComp() {
  //   const classes = useStyles();
  const [map, setMap] = React.useState(initial);
  const [isLoaded, setLoaded] = React.useState(false);
  const [storageType, setStorageType] = React.useState('');
  useEffect(() => {
    if (!isLoaded) {
      fetchData();
    }
  }, [isLoaded]);

  async function fetchData() {
    try {
      const check = await checkStorageType();
      setStorageType(check.fileType);
      const lst = await list();
      console.log(lst)
      var filesMap = {};
      var ls = lst.filesList;
      //   console.log(ls);
      for (var i = 0; i < ls.length; i++) {
        var x = {
          id: ls[i].id,
          children: ls[i].childrenList,
          title: ls[i].name,
          parent: ls[i].parent,
          type: ls[i].type,
          path: ls[i].path,
          modifiedAt: ls[i].modifiedAt,
          show: 1,
        };
        filesMap[ls[i].id] = x;
      }
      //console.log(filesMap);
      setLoaded(true);
      setMap(filesMap);
    } catch (e) {
      console.log(e);
    }
  }

  const onOutsideDrop = async (parentId, files) => {
    // if (files.length <= 3) {
    // 	// let data = new FormData();
    // 	// for (let i = 0; i < files.length; i++) {
    // 	// 	data.append('files', files[i]);
    // 	// }
    // 	// console.log(parentId);
    // 	// console.log(files);
    // 	// axios
    // 	// 	.post('http://localhost:8000/upload', data)
    // 	// 	.then(res => {
    // 	// 		console.log(res);
    // 	// 	})
    // 	// 	.catch(err => {
    // 	// 		console.log(err);
    // 	// 	});
    // }
    //const { map, onChange } = this.props;

    // const files = event.target.files;
    const path = map[parentId].path;

    // console.log("Lenght:"+files.length );
    // this.app.deselect();
    try {
      const res = await uploadFiles(files, path);
      var mp = map;
      // console.log("res");
      // console.log(res);
      for (var i = 0; i < res.filesList.length; i++) {
        const file = {
          id: `@@${uniqueId()}`,
          title: res.filesList[i].fileName,
          type: Types.file,
          children: [],
          parent: parentId,
          path: path + '/' + res.filesList[i].fileName,
        };
        mp = {
          ...mp,
          [parentId]: {
            ...mp[parentId],
            children: [...mp[parentId].children, file.id],
          },
          [file.id]: file,
        };
      }
    } catch (e) {
      console.log(e.message);
    }

    // console.log(mp);
    handleChange(mp);
  };

  const handleChange = (mp) => {
    // console.log("handleChange");
    // let map={
    // 	map:mp
    // }
    // console.log(mp);
    setMap(mp);
    // fetchData();
    // setLoaded(true);
  };

  //render() {
  //const { map } = this.state;

  return (
    <div>
      <Box component="div" mt={5}>
        <FileManagerWrapper
          map={map}
          rootId="0"
          storageType={storageType}
          // onChange={map => this.setState({ map })}
          //onOutsideDrop={this.onOutsideDrop}
          updateMap={handleChange}
          onChange={handleChange}
          onOutsideDrop={onOutsideDrop}
          dropzoneConfig={{
            name: 'files',
            inputProps: {
              type: 'file',
              encType: 'multipart/form-data',
              action: '/files',
              method: 'post',
            },
          }}
          fetchData={fetchData}
        />
      </Box>
    </div>
  );
  //}
}
