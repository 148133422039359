import { notifyError } from '../components/Messages/Notification';
import {
  CustomerTypeServiceClient,
  ListCustomerTypeRequest,
} from '../proto/accountpb/customertype_grpc_web_pb';
import { auth } from '../lib/auth/Auth';

const service = new CustomerTypeServiceClient(window.env.GRPC_ENDPOINT, {}, { ...auth });

export async function listCustomerType(param) {
  return new Promise((resolve, reject) => {
    const req = new ListCustomerTypeRequest();
    req.setAccountId(param);

    service.listCustomerType(req, {}, (error, response) => {
      if (error) {
        notifyError(error.message);
        reject(error);
      } else {
        resolve(response.toObject());
      }
    });
  });
}
