import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Modal, Backdrop, Fade } from '@material-ui/core'
import ClientGrid from '../../../../components/ClientGrid/ClientGrid'
import Text from '../../../../components/Typography/Text'
import CloseButton from '../../../../components/Buttons/CloseButton'
import fontFamily from '../../../../assets/css/fonts'
import color from '../../../../assets/css/colors'
import { digitalCashRequestSummaryColumns } from '../../../../lib/data/columns'
import { listDigitalCashRequestSummary } from '../../../../services/DigitalCashRequestService'
import { notifyError } from '../../../../components/Messages/Notification'

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90vw',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px',
    fontFamily: fontFamily.headers,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
    '& .rgt-wrapper': {
      boxShadow: 'none',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
}))

export default function DigitalCashRequestSummaryModal(props) {
  const classes = useStyles()

  const { data, open, close } = props

  const [rows, setRows] = React.useState([])
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    async function init() {
      try {
        setLoading(true)
        const { dcRequestsSummaryList } = await listDigitalCashRequestSummary(
          data,
        )

        setRows(dcRequestsSummaryList)
      } catch (error) {
        notifyError(error.message)
      } finally {
        setLoading(false)
      }
    }

    if (open) {
      init()
    }
  }, [open, data])

  const getCsvData = async () => {
    const { dcRequestsSummaryList } = await listDigitalCashRequestSummary(data)

    return dcRequestsSummaryList
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => close()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div style={{ width: '100%' }}>
          <div className={classes.modalHeader}>
            <div>
              <Text mt={4} variant="h2" label="Digital Cash Request Summary" />
            </div>
            <div className={classes.closeButton}>
              <CloseButton onClick={() => close()} />
            </div>
          </div>
          <div className={classes.modalBody}>
            <ClientGrid
              title="Digital Cash Request Summary"
              rowIdField="dcRequestId"
              columns={digitalCashRequestSummaryColumns}
              rows={rows}
              getCsvData={getCsvData}
              isLoading={loading}
              setRows={setRows}
            />
          </div>
        </div>
      </Fade>
    </Modal>
  )
}
