import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import fontFamily from '../../../assets/css/fonts';
import color from '../../../assets/css/colors';
import {
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import TextFieldMobileNoAreaCode from '../../../components/Textfields/TextFieldMobileNoAreaCode.js';
import SelectSystemCode from '../../../components/Dropdown/SelectSystemCode';
import PasswordField from '../../../components/Textfields/PasswordField';
import SelectRole from '../../../components/Dropdown/SelectRole';
import Button from '../../../components/Buttons/Button';
import Text from '../../../components/Typography/Text';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    width: '100%',
    margin: '0 auto',
    float: 'clear',
  },
  modalHeader: {
    backgroundColor: 'white',
    padding: 25,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: 'flex',
    '& div:nth-child(1)': {
      flex: 1,
    },
    '& div:nth-child(2)': {
      flex: 'none',
      '& .MuiIconButton-root': {
        borderRadius: 5,
        padding: 10,
        '&:hover svg path': {
          fill: color.primary.main,
        },
      },
      '& svg:last-child': {
        width: 15,
        height: 15,
      },
    },
  },
  fadeModalLg: {
    maxWidth: 900,
    maxHeight: '90vh',
    width: '100%',
    padding: '25px 30px 30px 30px',
    margin: '0px auto',
    backgroundColor: '#ffffff',
    border: 'none',
    borderRadius: 5,
    boxShadow: '0px 0px 10px #333333',
    boxSizing: 'border-box',
    outline: 'none',
    overflowY: 'auto',
  },
  modalBody: {
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    fontFamily: fontFamily.headers,
    '& p': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    padding: '0px 25px 25px 25px',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
    '& button': {
      width: 'auto',
    },
  },
  closeButton: {
    '& button': {
      marginTop: '-4px !important',
    },
  },
}));

export default function AdministratorModal({
  onClose: handleClose,
  open: isOpen,
  add: isAdd,
  value,
  loading: saving,
}) {
  const classes = useStyles();
  const [modalData, setModalData] = useState({
    usrId: '',
    roleId: 2,
    roleName: 'Default Client',
    name: '',
    email: '',
    password: '',
    passwordConfirm: '',
    defaultAccess: 'None',
    mobileNo: '',
    admin: false,
    authenticationMode: 'Email',
    correspondent: '',
  });

  const [rows, setRows] = useState([]);

  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Add New');

  useEffect(() => {
    if (isOpen) {
      setModalData({ ...value, roleId: 0, roleName: '' });
      setRows([]);

      if (isAdd) {
        setModalAddAction(true);
        setModalTitleValue('Add New');
      } else {
        setModalAddAction(false);
        setModalTitleValue('Edit');
      }
    }
  }, [isOpen, value, isAdd]);

  const handleContactInfoChange = (value, data, event, formattedValue) => {
    setModalData({
      ...modalData,
      mobileNo: formattedValue,
    });
  };

  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    if (['roleName'].includes(input.name)) {
      modalData.roleId = input.roleId;
      modalData.roleName = input.value;
    }

    setModalData({
      ...modalData,
      [input.name]: input.value,
    });
  };

  const handleCheckboxToggle = (e, checkboxValue) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setModalData({
      ...modalData,
      [input.name]:
        checkboxValue === true || checkboxValue === false ? checkboxValue : input.value,
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={isOpen}>
        <div className={classes.fadeModalLg}>
          <div className="grd-row">
            <div className="grd-cell">
              <Text
                id="transition-modal-title"
                variant="h2"
                label={modalTitleValue}
                User
              />
            </div>
          </div>
          <Box mt={5}>
            <form noValidate autoComplete="off">
              <div className="grd-row">
                <div className="grd-cell" style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    required
                    name="name"
                    label="Name"
                    value={modalData.name}
                    onChange={handleChange}
                    inputProps={{ maxLength: 100 }}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className="grd-cell" style={{ marginRight: 30 }}>
                  <TextField
                    fullWidth
                    required
                    name="email"
                    label="Email"
                    value={modalData.email}
                    onChange={handleChange}
                    inputProps={{
                      maxLength: 100,
                      autoComplete: 'new-password',
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
              </div>
              <div className="grd-row">
                <div className="grd-cell" style={{ marginRight: 30 }}>
                  <PasswordField
                    required={isAdd ? true : false}
                    max={50}
                    showPasswordRequirement={true}
                    position={'bottom'}
                    name="password"
                    label="Password"
                    value={modalData.password}
                    onChange={handleChange}
                  />
                </div>
                <div className="grd-cell" style={{ marginRight: 30 }}>
                  <PasswordField
                    required={isAdd ? true : false}
                    max={50}
                    showPasswordRequirement={true}
                    position={'bottom'}
                    name="passwordConfirm"
                    label="Confirm Password"
                    value={modalData.passwordConfirm}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="grd-row">
                <div className="grd-cell" style={{ marginRight: 30 }}>
                  <SelectRole
                    fullWidth
                    required={true}
                    name="roleName"
                    label="Role Name"
                    value={modalData.roleName}
                    onChange={handleChange}
                  ></SelectRole>
                </div>
                <div className="grd-cell" style={{ marginRight: 30 }}>
                  <TextFieldMobileNoAreaCode
                    required={true}
                    name="mobileNo"
                    label="Mobile No"
                    value={modalData.mobileNo}
                    onChange={handleContactInfoChange}
                  ></TextFieldMobileNoAreaCode>
                </div>
              </div>
              <div className="grd-row">
                <div className="grd-cell" style={{ marginRight: 30 }}>
                  <SelectSystemCode
                    required={true}
                    name="userType"
                    label="User Type"
                    type="User Type"
                    value={modalData.userType}
                    onChange={handleChange}
                  ></SelectSystemCode>
                </div>
                <div className="grd-cell" style={{ marginRight: 30 }}>
                  <SelectSystemCode
                    required={true}
                    name="status"
                    label="Status"
                    type="Status"
                    subType="AI"
                    value={modalData.status}
                    onChange={handleChange}
                  ></SelectSystemCode>
                </div>
              </div>
              <div className="grd-row">
                <div className="grd-cell" style={{ marginRight: 30 }}>
                  {modalData.userType === 'Client' && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="admin"
                          checked={modalData.admin}
                          onChange={handleCheckboxToggle}
                        />
                      }
                      label="Admin"
                    />
                  )}
                </div>
                <div className="grd-cell" style={{ marginRight: 30 }}>
                  <InputLabel>Send code authentication via:</InputLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="authenticationText"
                        checked={modalData.authenticationText}
                        onChange={handleCheckboxToggle}
                      />
                    }
                    label="Text"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="authenticationEmail"
                        checked={modalData.authenticationEmail}
                        onChange={handleCheckboxToggle}
                      />
                    }
                    label="Email"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="authenticationAuthenticator"
                        checked={modalData.authenticationAuthenticator}
                        onChange={handleCheckboxToggle}
                      />
                    }
                    label="Authenticator App"
                  />
                </div>
              </div>
              <div className={classes.modalFooter}>
                <div className="grd-cell-none" style={{ marginRight: 10, marginTop: 20 }}>
                  <Button
                    disabled={saving}
                    color="primary"
                    label="Close"
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </div>
                <div className="grd-cell-none">
                  <Button
                    disabled={saving}
                    loading={saving}
                    color="secondary"
                    label={saving ? 'Saving...' : 'Save'}
                    onClick={() => {
                      handleClose(
                        modalData,
                        modalAddAction,
                      );
                    }}
                  />
                </div>
              </div>
            </form>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
