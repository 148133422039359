/**
 * @fileoverview gRPC-Web generated client stub for kycpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_type_date_pb = require('../../google/type/date_pb.js')
const proto = {};
proto.kycpb = require('./verify_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.kycpb.KycServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.kycpb.KycServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kycpb.GetUrlRequest,
 *   !proto.kycpb.GetUrlResponse>}
 */
const methodDescriptor_KycService_GetVerifyUrl = new grpc.web.MethodDescriptor(
  '/kycpb.KycService/GetVerifyUrl',
  grpc.web.MethodType.UNARY,
  proto.kycpb.GetUrlRequest,
  proto.kycpb.GetUrlResponse,
  /**
   * @param {!proto.kycpb.GetUrlRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kycpb.GetUrlResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.kycpb.GetUrlRequest,
 *   !proto.kycpb.GetUrlResponse>}
 */
const methodInfo_KycService_GetVerifyUrl = new grpc.web.AbstractClientBase.MethodInfo(
  proto.kycpb.GetUrlResponse,
  /**
   * @param {!proto.kycpb.GetUrlRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kycpb.GetUrlResponse.deserializeBinary
);


/**
 * @param {!proto.kycpb.GetUrlRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.kycpb.GetUrlResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kycpb.GetUrlResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kycpb.KycServiceClient.prototype.getVerifyUrl =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kycpb.KycService/GetVerifyUrl',
      request,
      metadata || {},
      methodDescriptor_KycService_GetVerifyUrl,
      callback);
};


/**
 * @param {!proto.kycpb.GetUrlRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kycpb.GetUrlResponse>}
 *     A native promise that resolves to the response
 */
proto.kycpb.KycServicePromiseClient.prototype.getVerifyUrl =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kycpb.KycService/GetVerifyUrl',
      request,
      metadata || {},
      methodDescriptor_KycService_GetVerifyUrl);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.kycpb.GetStatusRequest,
 *   !proto.kycpb.GetStatusResponse>}
 */
const methodDescriptor_KycService_GetStatus = new grpc.web.MethodDescriptor(
  '/kycpb.KycService/GetStatus',
  grpc.web.MethodType.UNARY,
  proto.kycpb.GetStatusRequest,
  proto.kycpb.GetStatusResponse,
  /**
   * @param {!proto.kycpb.GetStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kycpb.GetStatusResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.kycpb.GetStatusRequest,
 *   !proto.kycpb.GetStatusResponse>}
 */
const methodInfo_KycService_GetStatus = new grpc.web.AbstractClientBase.MethodInfo(
  proto.kycpb.GetStatusResponse,
  /**
   * @param {!proto.kycpb.GetStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.kycpb.GetStatusResponse.deserializeBinary
);


/**
 * @param {!proto.kycpb.GetStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.kycpb.GetStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.kycpb.GetStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.kycpb.KycServiceClient.prototype.getStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/kycpb.KycService/GetStatus',
      request,
      metadata || {},
      methodDescriptor_KycService_GetStatus,
      callback);
};


/**
 * @param {!proto.kycpb.GetStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.kycpb.GetStatusResponse>}
 *     A native promise that resolves to the response
 */
proto.kycpb.KycServicePromiseClient.prototype.getStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/kycpb.KycService/GetStatus',
      request,
      metadata || {},
      methodDescriptor_KycService_GetStatus);
};


module.exports = proto.kycpb;

