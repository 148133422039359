import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../Buttons/Button';
import Text from '../Typography/Text';
import color from '../../assets/css/colors';
import fontFamily from '../../assets/css/fonts';
import {ReactComponent as Cross} from '../../assets/icons/x-out.svg';

const useStyles = makeStyles(() => ({
    attachmentWrapper: {
        marginTop: 11,
        padding: 15,
        border: '1px dashed ' + color.gray.light,
        borderRadius: 8,
        fontFamily: fontFamily.label,
        fontSize: 14,
        fontWeight: 600,
        color: color.default.light,
        width: 260,
        height: 207
    },
    attachmentLabel: {
        fontWeight: 600,
        color: color.default.main,
        lineHeight: '17px',
        display: 'flex',
        width: '100%',
        marginBottom: 10,
        '& h6': {
            fontWeight: 600,
            color: color.default.main,
            lineHeight: '17px',
        },
        '& .grd-cell-none > h6': {
            fontSize: 13,
            fontStyle: 'italic',
            lineHeight: '19px',
        }
    },
    thumbnail: {
        backgroundColor: color.gray.opacity,
        borderRadius: 8,
        height: props => props.hidButton ? 207 : 147,
        marginBottom: props => props.hidButton ? 0 : 15,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            borderRadius: 8,
        },
        '& span.close': {
            position: 'absolute',
            top: 11,
            right: 11,
            display: 'block',
            backgroundColor: color.gray.opacity,
            padding: 4,
            borderRadius: 4,
            width: 18,
            height: 18,
            zIndex: 2,
            cursor: 'pointer',
            boxShadow: '0px 0px 7px #73767a',
            '& svg': {
                position: 'relative',
                top: -2,
                left: 0,
                fill: color.default.main,
            },
            '&:hover': {
                backgroundColor: color.primary.main,
                '& svg': {
                    fill: color.light.light,
                }
            }
        }
    },
    button: {
        width: '100%',
        display: 'block',
    },
    buttonWrapper: {
    
    },
}));

const ImageAttachment = (props) => {
    const {
        id, 
        required,
        hidButton, 
        error, 
        msg, 
        label, 
        thumbnail, 
        handleAttach, 
        setNewData, 
        data, 
        form, 
        index,
        disabled
    } = props;
    const classes = useStyles(props);

    const handleClear = () => {
        if (form === 2) {
            if (index !== undefined) {
                const coIdentity = [...data];
                
                coIdentity[index]['proofOfIdentity'] = '';
                
                setNewData(coIdentity);
            } else {
                setNewData({...data, proofOfIdentity: ''});
            }
        }
        if (form === 1) {
            setNewData({...data, proofOfAddress: ''});
        }
    }

    const handleReset = (e) => {
        e.target.value = ''
    }
 
    return (
        <div>
            <div className={classes.attachmentLabel}>
                <div className="grd-cell">
                    <Text
                        required={required}
                        error={error}
                        variant="subtitle1"
                        label={label}
                    />
                </div>
                {error && (
                    <div className="grd-cell-none">
                        <Text
                            error={error}
                            variant="subtitle1"
                            label={msg}
                        />
                    </div>
                )}
            </div>
            <div className={classes.attachmentWrapper}>
                <div className={classes.thumbnail}>
                    {thumbnail
                        ? (<img src={thumbnail} alt='proof-of-identity' />) 
                        : (<label>No Photo</label>)
                    }
                    {!hidButton && (
                        <span 
                            className="close" 
                            onClick={() => {handleClear()}} 
                            style={{ display: thumbnail ? 'block' : 'none' }}>
                            <Cross/>
                        </span>
                    )}
                </div>
                {!hidButton && (
                    <div className={classes.buttonWrapper}>
                        <div className="grd-cell-none">
                            <input
                                style={{ display: 'none' }}
                                id={id}
                                type="file"
                                accept="image/*"
                                onClick={handleReset}
                                onChange={(e) => {handleAttach(e, index)}} 
                            />
                            <label 
                                className={classes.button} 
                                htmlFor={id}
                            >
                                <Button
                                    type="photo"
                                    component="span" 
                                    label="Upload Photo"
                                    disabled={disabled}
                                />
                            </label>
                        </div>
                    </div> 
                )}
            </div>
        </div>
    );
}
export default ImageAttachment;