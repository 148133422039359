import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import TextField from '../../components/Textfields/TextField';
import PasswordField from '../../components/Textfields/PasswordField';
import CodeField from '../../components/Textfields/CodeField';
import Button from '../../components/Buttons/Button';
import SocialMediaButtons from '../../components/Buttons/SocialMediaButtons';
import BackButton from '../../components/Buttons/BackButton';
import LinkButton from '../../components/Buttons/LinkButton';
import RadioButtonGroup from '../../components/Buttons/RadioButtonGroup';
import Text from '../../components/Typography/Text';
import FormMessage from '../../components/Messages/FormMessage';
import logo from '../../assets/images/sas-logo.png';
import axosDigital from '../../assets/images/axos-digital.png';
import { validateEmail, validatePassword } from '../../lib/validate/validate';
import authSvc from '../../services/AuthService';
import QueryParam from '../../services/QueryParamService';
import { getLogo } from '../../services/TemplateService';
import IdleLoginModal from '../../components/Modals/IdleLoginModal';
import SelectSystemCode from "../../components/Dropdown/SelectSystemCode";
import { hasSecurityWord } from '../../services/AdministratorService';

const useStyles = makeStyles(() => ({
  authTitle: {
    fontWeight: '600',
    marginTop: '16px',
  },
}));

export default function Login() {
  const [data, setData] = React.useState({
    email: '',
    password: '',
    code: '',
    usrId: '',
    mode: 'Log In',
    authenticationMode: 'Email',
  });
  const [isAxpay, setIsAxpay] = useState(window.location.href.indexOf('axpay.softwarealgo.com') > -1);

  const classes = useStyles();

  const [mode, setMode] = React.useState(['Text', 'Email', 'Authenticator']);

  const [clientLogo, setLogo] = React.useState(null);

  const [openIdleLoginModal, setOpenIdleLoginModal] = useState(false);

  const [forgotPasswordData, setForgotPasswordDataData] = React.useState({
    email: '',
    lastPassword: '',
    newPassword: '',
    confirmPassword: '',
    code: '',
    usrId: '',
    mode: 'Forgot Password',
    authenticationMode: 'Email',
    securityWord: '',
    securityWordHint: '',
  });

  const [screen, setScreen] = React.useState({
    login: true,
    authentication: false,
    forgotPassword: false,
    createAccount: false,
  });

  const [message, setMessage] = React.useState({
    type: '',
    value: '',
  });

  const [loading, setLoading] = React.useState({
    login: false,
    sendCode: false,
    resetPassword: false,
    createAccount: false,
    timer: false,
  });

  const [startTimer, setStartTimer] = React.useState(false);

  const [showSecurityWordHint, setShowSecurityWordHint] = React.useState(false);

  useEffect(() => {
    if (window.env.ACTIVATE_SINGLE_SIGNIN) {
      const encryptedKey = window.location.href.split('?')[1];

      if (['', undefined, null].includes(encryptedKey)) {
        const loginUrl = window.env.COMMON_LOGIN_URL + '/login?';

        const urlParams =
          'app_id=' +
          window.env.APP_ID +
          '&app_secret=' +
          window.env.APP_SECRET +
          '&redirect_url=' +
          window.env.REDIRECT_URL;

        const url = loginUrl + urlParams;

        window.location.replace(url);
      } else {
        var params = QueryParam.getDecryptedUrlParameter(
          {
            usr_id: '',
            email: '',
            access_token: '',
          },
          encryptedKey,
          'SAS220304-QJRT'
        );

        const loginViaToken = async () => {
          try {
            const loginParams = {
              ...params,
              mode: 'Log In Via Token',
            };

            const res = await authSvc.login(loginParams);

            if (res !== 0) {
              window.location = '/';
            }
          } catch (error) {
            console.log(error.message);
          }
        };

        if (params.access_token) {
          if (!localStorage.getItem('access_token')) {
            loginViaToken();
          }

          localStorage.setItem('access_token', params.access_token);
        }
      }
    }

    const isInactive = () => {
      setOpenIdleLoginModal(sessionStorage.getItem('inactive'));
    };

    handleGetLogo();
    isInactive();
  }, []);

  const handleCloseIdleLoginModal = () => {
    setOpenIdleLoginModal(false);
    sessionStorage.removeItem('inactive');
  };

  window.onbeforeunload = () => {
    sessionStorage.removeItem('inactive');
  };
  
  const handleChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setData({ ...data, [input.name]: input.value });
  };

  const handleForgotPasswordDataChange = (e) => {
    const input = e.currentTarget.name ? e.currentTarget : e.target;

    setForgotPasswordDataData({
      ...forgotPasswordData,
      [input.name]: input.value,
    });
  };

  const handleGetLogo = async () => {
    try {
      const serverLogo = await getLogo();

      if (serverLogo.correspondent.photo !== '') {
        setLogo(serverLogo.correspondent.photo);
      } else {
        setLogo(logo);
      }
    } catch (error) {
      //console.log(error)
      setLogo(logo);
    }
  };

  const handleLogin = async () => {
    if (!data.email) {
      setMessage({ type: 'error', value: 'Email address is required.' });
      return;
    }
    if (data.email) {
      if (validateEmail(data.email)) {
        setMessage({ type: 'error', value: 'Invalid email address.' });
        return;
      }
    }
    if (!data.password) {
      setMessage({ type: 'error', value: 'Password is required.' });
      return;
    }
    if (data.authenticationMode === 'Authenticator') {
      try {
        const auth = await authSvc.getAuthMode(data);
        if (auth.authenticationMode.includes(data.authenticationMode) === false) {
          setMessage({ type: 'error', value: 'Not Allowed. Please use other authentication mode' });
          setLoading({ ...loading, login: false });
          return;
        }
      } catch (error) {
        setMessage({ type: 'error', value: error.message });
        setLoading({ ...loading, login: false });
        return;
      }
    }

    try {
      setLoading({ ...loading, login: true });

      const userId = await authSvc.login(data);
      if (data.authenticationMode === 'Authenticator') {
        data.userId = userId;
        const key = await authSvc.getKey(data);
        if (key.secretKey == '') {
          setMessage({
            type: 'error',
            value: '2FA have not been set-up. please choose a different authentication method',
          });
          setLoading({ ...loading, login: false });
          return;
        }
        data.key = key.secretKey;
      }

      setData({ ...data, usrId: userId });
      setLoading({ ...loading, login: false });
      setScreen({ ...screen, login: false, authentication: true });
    } catch (error) {
      setMessage({ type: 'error', value: error.message });
      setLoading({ ...loading, login: false });
      return;
    }

    resetMessages();
  };

  const handleCreateAccount = () => {
    localStorage.clear();

    window.location = '/register';
  };

  const handleSubmitCode = async (e) => {
    e.preventDefault();
    
    if (data.code === '') {
      setMessage({ type: 'error', value: 'Verfication code is required.' });
      return;
    }

    try {
      setLoading({ ...loading, sendCode: true });
      if (data.authenticationMode == 'Authenticator') {
        const key = await authSvc.validateAuthenticatorCode(data);
        if (key != 'Success') {
          setLoading({ ...loading, sendCode: false });
          setMessage({ type: 'error', value: key });
          return;
        }
      } else {
        const res = await authSvc.validateAuthCode(data);
        if (res.status !== 'Success') {
          setLoading({ ...loading, sendCode: false });
          setMessage({ type: 'error', value: res.msg });
          return;
        }
      }

      setLoading({ ...loading, sendCode: false });

      window.location = '/';
      resetMessages();
    } catch (error) {
      if (error.message) {
        setMessage({ type: 'error', value: error.message });
        setLoading({ ...loading, sendCode: false });
        return;
      }
    }
  };

  const handleResendCode = () => {
    const resetTimer = document.getElementById('timerReset');
    const timerValue = resetTimer.firstElementChild.innerHTML;

    if (timerValue !== 'Code Expired') {
      return;
    }

    handleLogin();
    resetTimer.click();
  };

  const handleForgotPassword = () => {
    resetMessages();
    setScreen({ ...screen, login: false, forgotPassword: true });
  };

  const handleSendCode = async () => {
    if (forgotPasswordData.email === '') {
      setMessage({
        type: 'error',
        value: 'Email address is required for us to send the code.',
      });
      return;
    }

    try {
      setStartTimer(false);

      const userId = await authSvc.login(forgotPasswordData);
      handleVerifySecurityWord(userId)

      setForgotPasswordDataData({ ...forgotPasswordData, usrId: userId });
      setLoading({ ...loading, timer: true });
      setStartTimer(true);
      resetMessages();

      authSvc.logout();
    } catch (error) {
      setMessage({ type: 'error', value: error.message });
      return;
    }
  };

  const handleChangePassword = async () => {
    if (forgotPasswordData.email === '') {
      setMessage({ type: 'error', value: 'Email address is required.' });
      return;
    }
    if (forgotPasswordData.email) {
      if (validateEmail(forgotPasswordData.email)) {
        setMessage({ type: 'error', value: 'Invalid email address.' });
        return;
      }
    }
    // if (!forgotPasswordData.lastPassword) {
    //   setMessage({
    //     type: 'error',
    //     value: 'Last Password You Remember is required.',
    //   });
    //   return;
    // }
    if (!forgotPasswordData.newPassword) {
      setMessage({ type: 'error', value: 'New Password is required.' });
      return;
    }
    if (!forgotPasswordData.confirmPassword) {
      setMessage({ type: 'error', value: 'Confirm Password is required.' });
      return;
    }
    if (validatePassword(forgotPasswordData.newPassword).err) {
      setMessage({
        type: 'error',
        value: 'New Password does not meet the requirement.',
      });
      return;
    }
    if (validatePassword(forgotPasswordData.confirmPassword).err) {
      setMessage({
        type: 'error',
        value: 'Confirm Password does not meet the requirement.',
      });
      return;
    }
    if (forgotPasswordData.newPassword !== forgotPasswordData.confirmPassword) {
      setMessage({ type: 'error', value: 'Password does not match.' });
      return;
    }
    if (!forgotPasswordData.code) {
      setMessage({ type: 'error', value: 'Authentication Code is required.' });
      return;
    }

    try {
      const res = await authSvc.validateAuthCode(forgotPasswordData);

      if (res.msg === 'Code expired') {
        setMessage({ type: 'error', value: 'Your code expired.' });
        return;
      }
      if (res.msg === 'Invalid Code') {
        setMessage({ type: 'error', value: 'Your code is invalid.' });
        return;
      }

      authSvc.logout();
    } catch (error) {
      setMessage({ type: 'error', value: error.message });
      return;
    }

    try {
      setLoading({ ...loading, resetPassword: true });

      const res = await authSvc.changePassword(forgotPasswordData);

      if (res.status !== 'Success') {
        setMessage({ type: 'error', value: res.msg });
        setLoading({ ...loading, resetPassword: false });
        return;
      } else {
        setForgotPasswordDataData({
          ...forgotPasswordData,
          email: '',
          lastPassword: '',
          newPassword: '',
          confirmPassword: '',
          code: '',
          usrId: '',
        });

        setMessage({ type: 'success', value: res.msg });
        setLoading({ ...loading, resetPassword: false, timer: false });
        setStartTimer(false);
      }
    } catch (error) {
      setMessage({ type: 'error', value: error.message });
      return;
    }
  };

  const handleBack = () => {
    setData({
      email: '',
      password: '',
      code: '',
      usrId: '',
      mode: 'Log In',
      authenticationMode: 'Email',
    });

    setForgotPasswordDataData({
      email: '',
      lastPassword: '',
      newPassword: '',
      confirmPassword: '',
      code: '',
      mode: 'Forgot Password',
      authenticationMode: 'Email',
    });

    setScreen({
      login: true,
      authentication: false,
      forgotPassword: false,
      createAccount: false,
    });

    setLoading({ ...loading, timer: false });

    setStartTimer(false);

    setShowSecurityWordHint(false);

    resetMessages();
  };

  const handleVerifySecurityWord = async (id) => {
    const res = await hasSecurityWord(id);
    setShowSecurityWordHint(res.hasSecurityWord);
  }

  const resetMessages = () => {
    setMessage({
      type: '',
      value: '',
    });
  };

  const selectedScreen = (screen) => {
    if (screen.login) {
      return (
        <form autoComplete="off">
          <Text mb={40} variant="h2" label="Login Account" />
          {screen.login && message.value !== '' ? <FormMessage type={message.type} message={message.value} /> : null}
          <TextField
            autoFocus={true}
            name="email"
            label="Email Address"
            placeholder="youremail@website.com"
            type="text"
            max={50}
            showIcon={true}
            value={data.email}
            onChange={handleChange}
          />
          <PasswordField
            mt={true}
            name="password"
            label="Password"
            placeholder="Password"
            type="password"
            max={50}
            showIcon={true}
            value={data.password}
            onChange={handleChange}
          />
          <div className="grd-row nm">
            <div className="grd-cell">
              <Typography id="authenticationlabel" className={classes.authTitle}>
                Send authentication code via:
              </Typography>
            </div>
            <div className="grd-cell-none">
              <LinkButton mt={16} label="Forgot Password" onClick={handleForgotPassword} />
            </div>
          </div>
          <div className="grd-row nm">
            <div className="grd-cell">
              <RadioButtonGroup
                name="authenticationMode"
                value={data.authenticationMode}
                onChange={handleChange}
                options={mode}
                style={{ display: 'inline' }}
              />
            </div>
          </div>
          <Button
            mt={true}
            disabled={loading.login}
            loading={loading.login}
            label={loading.login ? 'Verifying your account...' : 'Login'}
            onClick={handleLogin}
          />
          <div className="text-divider">
            <Text variant="subtitle2" label="Don't have an account?" />
            <Divider />
          </div>
          <Button
            disabled={loading.createAccount}
            loading={loading.createAccount}
            label={'Create an Account'}
            onClick={handleCreateAccount}
          />
          <SocialMediaButtons />
        </form>
      );
    } else if (screen.authentication) {
      return (
        <form autoComplete="off" onSubmit={handleSubmitCode}>
          <div className="grd-row nm">
            <div className="grd-cell">
              <Text mb={40} variant="h2" label="Authentication" />
            </div>
            <div className="grd-cell-none">
              <BackButton onClick={handleBack} />
            </div>
          </div>
          {screen.authentication && message.value !== '' ? (
            <FormMessage type={message.type} message={message.value} />
          ) : null}
          <CodeField
            autoFocus={true}
            max={6}
            showIcon={true}
            startTimer={data.authenticationMode !== 'Authenticator'}
            setLoading={setLoading}
            name="code"
            label="Authentication Code"
            placeholder="6 Alphanumeric Code"
            type="text"
            value={data.code}
            onChange={handleChange}
          />
          <Button
            mt={true}
            disabled={loading.sendCode}
            loading={loading.sendCode}
            label={loading.sendCode ? 'Submitting...' : 'Submit Code'}
            onClick={handleSubmitCode}
          />
          {data.authenticationMode !== 'Authenticator' ? (
            <div className="grd-row auth-acc">
              <div className="grd-cell-none">
                <Text variant="subtitle1" label="Didn't receive your code?" />
              </div>
              <div className="grd-cell">
                <LinkButton
                  tooltipTitle="New code will not be generated and send until the code expired"
                  type="button"
                  label="Resend code."
                  onClick={handleResendCode}
                />
              </div>
            </div>
          ) : null}
        </form>
      );
    } else if (screen.forgotPassword) {
      return (
        <form autoComplete="off">
          <div className="grd-row nm">
            <div className="grd-cell">
              <Text mb={40} variant="h2" label="Forgot Password" />
            </div>
            <div className="grd-cell-none">
              <BackButton onClick={handleBack} />
            </div>
          </div>
          {screen.forgotPassword && message.value !== '' ? (
            <FormMessage type={message.type} message={message.value} />
          ) : null}
          <TextField
            autoFocus={true}
            max={50}
            showIcon={false}
            name="email"
            label="Email Address"
            placeholder="youremail@website.com"
            type="text"
            value={forgotPasswordData.email}
            onChange={handleForgotPasswordDataChange}
          />
          {/* <PasswordField
            mt={true}
            max={50}
            showIcon={false}
            name="lastPassword"
            label="Last Password You Remember"
            placeholder="Last Password You Remember"
            type="password"
            value={forgotPasswordData.lastPassword}
            onChange={handleForgotPasswordDataChange}
          /> */}
          <PasswordField
            mt={true}
            max={50}
            showIcon={false}
            showPasswordRequirement={true}
            name="newPassword"
            label="New Password"
            placeholder="New Password"
            type="password"
            value={forgotPasswordData.newPassword}
            onChange={handleForgotPasswordDataChange}
          />
          <PasswordField
            mt={true}
            max={50}
            showIcon={false}
            showPasswordRequirement={true}
            name="confirmPassword"
            label="Confirm Password"
            placeholder="Confirm Password"
            type="password"
            value={forgotPasswordData.confirmPassword}
            onChange={handleForgotPasswordDataChange}
          />
          {showSecurityWordHint && (
            <>
              <div style={{marginTop:20}}></div>
              <SelectSystemCode
                mt={true}
                name="securityWordHint"
                label="Security Word Hint"
                placeholder="Security Word"
                type="Security Question"
                subType="Client Site"
                value={forgotPasswordData.securityWordHint}
                onChange={handleForgotPasswordDataChange}
              />
              <div style={{marginTop:20}}></div>
              <TextField
                autoFocus={true}
                max={50}
                showIcon={false}
                name="securityWord"
                label="Security Word"
                placeholder="Security Word"
                type="text"
                value={forgotPasswordData.securityWord}
                onChange={handleForgotPasswordDataChange}
              />
            </>
          )}
          <CodeField
            mt={true}
            max={36}
            showIcon={false}
            startTimer={startTimer}
            setLoading={setLoading}
            name="code"
            label="Authentication Code"
            placeholder="36 Alphanumeric Code"
            type="text"
            value={forgotPasswordData.code}
            onChange={handleForgotPasswordDataChange}
          />
          <div className="grd-row">
            <div className="grd-cell">
              <Button
                disabled={loading.sendCode || loading.timer}
                loading={loading.sendCode}
                label={loading.sendCode ? 'Sending...' : 'Send Code'}
                onClick={handleSendCode}
              />
            </div>
            <div className="grd-cell">
              <Button
                disabled={loading.resetPassword || !forgotPasswordData.code}
                loading={loading.resetPassword}
                label={loading.resetPassword ? 'Submiting...' : 'Submit'}
                onClick={handleChangePassword}
              />
            </div>
          </div>
        </form>
      );
    } else {
      return <>nope!</>;
    }
  };

  return (
    <div className="login-w">
      <div className="login-ls">
        <div className="login-fw">
          <div className="login-h">
            <img src={clientLogo} alt="Logo" style={{ maxHeight: '100px', maxWidth: '350px' }} />
          </div>
          <div className="login-b">
            <div>{selectedScreen(screen)}</div>
          </div>
          <div className="login-f">
            <Text variant="subtitle2" label="Powered by: Software Algo Solutions" />
          </div>
        </div>
      </div>
      <div className="login-rs">
        {isAxpay && (
          <div style={{ textAlign: 'right', paddingTop: '40px' }}>
            <img src={axosDigital} alt="Logo" style={{ maxHeight: '100px', maxWidth: '200px', marginRight: '20px' }} />
          </div>
        )}
        <div className="graphics-w"></div>
      </div>
      {openIdleLoginModal && (
        <IdleLoginModal
          open={openIdleLoginModal}
          close={handleCloseIdleLoginModal}
        ></IdleLoginModal>
      )}
    </div>
  );
}
